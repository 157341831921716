<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Accounting Preferences For: Your Company Name Here <a style="color: red;">{{this.partyId}}</a> </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>

            </div>
            <div class=" row w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:1px">
                        <li [ngClass]="activeCategoryMain==1?'active':''" (click)="AccountingInfo()"><a>
                        Available Internal Accounting Organizations</a></li>
                        <li [ngClass]="activeCategoryMain==2?'active':''" (click)="AccountingCompany()"><a>
                        Create new accounting company</a></li>


                    </ul>
                </div>

                <div class="w-100 w3-card-4 classCard" style="margin: 16px;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <!-- <li [ngClass]="activeMain==1?'active':''" (click)="setup()"><a>
                            Setup</a></li> -->
                            <li [ngClass]="activeMain==2?'active':''" (click)="accounting()"><a>
                            Accounting</a></li>


                        </ul>
                    </div>
                    <div class=" bg-white color-grey" style="padding: 8px 15px;">
                        <div class="port-header-contact create-lead font-medium mb-0">
                            <div class="suppliers-wrapper">

                            </div>
                        </div>
                        <div class="w3-card-4 classCard">
                            <div class="container-fluid">
                                <ul class="tabbing-section tabbing-accordians tabClass">

                                    <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                    Accounts Summary</a></li>
                                    <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                                    Account Reconciliation
                                </a></li>

                                    <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                                    Account Reconciliations</a></li>
                                    <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                                    Accounting Transaction </a></li>
                                    <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategoryFunction(8)"><a>
                                    Accounting Transaction Entries </a></li>
                                    <!--  <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                                    Reports </a></li> -->
                                    <!-- <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategoryFunction(7)"><a>
                                    Check </a></li> -->



                                </ul>
                            </div>
                            <div>
                                <div class="container">
                                    <div class="row">
                                        <form class="w-100">
                                            <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                                <div class="w-100">
                                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                        <div *ngIf="activeCategory==2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling">
                                                                    Accounting Summary
                                                                </h4>


                                                            </div>
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="row">

                                                                            <div>
                                                                                <span style="cursor: pointer;" (click)="createSalesAccount();" class="color-grey pl-20 col-lg-10 col-12">Create
                                                                            Sales Accounting Transaction</span><br>

                                                                                <span style="cursor: pointer;" (click)="createSalesAccount();" class="color-grey pl-20 col-lg-10 col-12">Create
                                                                            Payment Accounting Transaction</span><br>
                                                                                <span style="cursor: pointer;" (click)="unpostedList()" class="color-grey pl-20 col-lg-10 col-12">Un-Posted
                                                                            Accounting Transaction</span><br>
                                                                                <span style="cursor: pointer;" (click)="trialBalanceCheck();" class="color-grey pl-20 col-lg-10 col-12">Trial
                                                                            Balance</span><br>
                                                                                <span style="cursor: pointer;" (click)="incomeStatemetList();" class="color-grey pl-20 col-lg-10 col-12">Income
                                                                            Statement</span><br>
                                                                                <span style="cursor: pointer;" (click)="balanceSheetList();" class="color-grey pl-20 col-lg-10 col-12">
                                                                            Balance Sheet</span>









                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div *ngIf="activeCategory==3">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling">
                                                                    Find Account Reconciliation
                                                                </h4>


                                                            </div>
                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100" [formGroup]="createAccountRecon">
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Gl Account ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Gl Account ID" filter="true" formControlName="glAccountId" [options]="assignGLPartyIdArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>


                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button" style="margin-left: 10%;">
                                                                                <button type="submit" (click)="findReconciliationsAccount();" class="btn btn-secondary submit-btn">Find</button> &nbsp;
                                                                                <button type="submit" (click)="resetReconciliationsAccount();" class="btn btn-secondary submit-btn">Reset</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div class="header-tabs">
                                                                                <h4 class=" common-styling">
                                                                                    Account Reconciliation
                                                                                </h4>
                                                                                <span class="edit-buttons ">
                                                                            <a
                                                                                (click)="createAccount()">Create</a></span>

                                                                            </div>


                                                                            <div class="panel-body">
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                    <div class="form-group">
                                                                                        <div class="card own-account-table">
                                                                                            <p-table [value]="ReconciliationsAccount" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                <ng-template pTemplate="header">
                                                                                                    <tr style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>


                                                                                                                Acctg Trans ID
                                                                                                                <p-sortIcon field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Acctg Trans Entry Seq ID
                                                                                                                <p-sortIcon field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>



                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                Gl Account ID
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                Party ID
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                Product ID
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                Organization Party ID
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                Amount
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" (click)="selectAllDepositWith($event)" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>

                                                                                                                Select
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>





                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                    <tr>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.acctgTransId}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.acctgTransEntrySeqId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.glAccountId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.partyId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.productId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.organizationPartyId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.amount}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" (click)="depositCheckboxClickWith(product);" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                        </td>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No Record
                                                                                                            Found
                                                                                                        </h4>
                                                                                                    </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>

                                                        </div>
                                                        <div *ngIf="activeCategory==4">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling">
                                                                    Find Account Reconciliations
                                                                </h4>


                                                            </div>
                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100" [formGroup]="createReconAccountForm">
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Gl Account ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Gl Account ID" filter="true" formControlName="glAccountId" [options]="assignGLPartyIdArray" optionlabel="label">

                                                                                                </p-dropdown>

                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            From Date</label>


                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Org Party ID">
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Through Date</label>

                                                                                            </div>

                                                                                            <div class="col-lg-2">

                                                                                                <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Org Party ID">

                                                                                            </div>


                                                                                        </div>
                                                                                    </div>




                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button" style="margin-left: 51%;">
                                                                                <button type="submit" (click)="postfindReconciliationsAccount();" class="btn btn-secondary submit-btn">Find</button> &nbsp;
                                                                                <button type="submit" (click)="resetRconlForm();" class="btn btn-secondary submit-btn">Reset</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div class="header-tabs">
                                                                                <h4 class=" common-styling">
                                                                                    Account Reconciliation
                                                                                </h4>

                                                                            </div>


                                                                            <div class="panel-body">
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                    <div class="form-group">
                                                                                        <div class="card own-account-table">
                                                                                            <p-table [value]="postfindReconciliation" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                <ng-template pTemplate="header">
                                                                                                    <tr style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>


                                                                                                                Gl Reconciliation ID
                                                                                                                <p-sortIcon field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Gl Reconciliation Name
                                                                                                                <p-sortIcon field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>



                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                Description
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                Created By User
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                Last Modified By User
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                Gl Account ID
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                Organization Party ID
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                            <div style="color: white;">
                                                                                                                Reconciled Balance
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>





                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                    <tr>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.glReconciliationId}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.glReconciliationName}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.description}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.createdByUserLogin}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.lastModifiedByUserLogin}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.glAccountId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.organizationPartyId}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.reconciledBalance}}
                                                                                                        </td>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No Record
                                                                                                            Found
                                                                                                        </h4>
                                                                                                    </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>

                                                        </div>
                                                        <div *ngIf="activeCategory==5">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling">
                                                                    Find Accounting Transactions
                                                                </h4>
                                                                <span class="edit-buttons ">

                                                            <a data-toggle="modal" data-target="#exampleModalAccTrans">
                                                                Create an Accounting Transaction

                                                            </a>| <a data-toggle="modal" data-target="#exampleModalcreateQuickAccount" >
                                                                Quicke Create an Accounting Transaction

                                                            </a>| <a data-toggle="modal" data-target="#exampleModalunpostedList">
                                                                Unposted Accounting Transaction
                                                                

                                                            </a>| <a (click)="downloadFullPDF()">PDF

                                                            </a></span>


                                                            </div>

                                                            <br>
                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100" [formGroup]="createTransForm">
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">

                                                                                            Acctg Trans ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Acctg Trans ID" formControlName="acctTrans">

                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Transaction Type</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Transaction Type" filter="true" formControlName="transType" [options]="accTransactionTypeArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Fiscal Gl Type</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Fiscal Gl Type" filter="true" formControlName="fiscalType" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                </p-dropdown>

                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Gl Journal ID</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Gl Journal ID" filter="true" formControlName="glJournal" [options]="glJournalArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Is Posted</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Is Posted" filter="true" formControlName="posted" [options]="posted" optionlabel="label">

                                                                                                </p-dropdown>

                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Invoice ID</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Invoice ID" filter="true" formControlName="invoiceId" [options]="invoiceListArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Payment ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Payment ID" filter="true" formControlName="paymentId" [options]="getPaymentsListArray" optionlabel="label">

                                                                                                </p-dropdown>

                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Product ID</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Product ID" filter="true" formControlName="productId" [options]="ProductsArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Work Effort ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Work Effort ID" filter="true" formControlName="workEffortId" [options]="workEffortArray" optionlabel="label">

                                                                                                </p-dropdown>

                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Shipment ID</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Shipment ID" filter="true" formControlName="shipId" [options]="FacilityShipmentsArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">
                                                                                            From Date</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Org Party ID" formControlName="fromDate">

                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Through Date</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Org Party ID" formControlName="throughDate">
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>



                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button" style="margin-left: 52%;">
                                                                                <button type="submit" (click)="findTransactionDetails();" class="btn btn-secondary submit-btn">Find</button> &nbsp;

                                                                                <button type="submit" (click)="resetTransForm();" class="btn btn-secondary submit-btn">Reset</button>


                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <br>
                                                                <br>

                                                                <br>
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Accounting Transactions
                                                                    </h4>

                                                                </div>
                                                                <br>

                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="transDeatils" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>



                                                                                                    Acctg Trans ID
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Transaction Date
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>



                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Acctg Trans Type
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Description
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Fiscal Gl Type
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Invoice ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Payment ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">Work Effort ID

                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Shipment ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Is Posted

                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Posted Date
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Action
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>






                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    <span (click)="detailTransaction(product.acctgTransId)">{{product.acctgTransId}}</span>


                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.transactionDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.acctgTransType}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.description}} </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.fiscalGlType}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.invoiceId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.paymentId}} </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.workEffortId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.shipmentId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.isPosted}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.postedDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <span (click)="downloadPDF(product.acctgTransId)">PDF</span>
                                                                                                <svg class="hover" *ngIf="product.isPosted!='Y'" (click)="updateEditTransactin(product.acctgTransId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                            <g
                                                                                                transform="translate(0 -0.004)">
                                                                                                <g
                                                                                                    transform="translate(0 1.52)">
                                                                                                    <path
                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                        transform="translate(0 -34.137)" />
                                                                                                </g>
                                                                                                <g
                                                                                                    transform="translate(4.548 0.004)">
                                                                                                    <g
                                                                                                        transform="translate(0 0)">
                                                                                                        <path
                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                            </td>







                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page

                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div *ngIf="activeCategory==8">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling">
                                                                    Find Accounting Transaction Entries
                                                                </h4>


                                                            </div>

                                                            <br>
                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100" [formGroup]="createTransEntForm">
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInputB">
                                                                                                <label for="exampleInputEmail1">

                                                                                            Acctg Trans ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Acctg Trans ID" formControlName="acctEnt">
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Gl Account ID</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Gl Account ID" filter="true" formControlName="glAccEnt" [options]="assignGLPartyIdArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInputB">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Transaction Type</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Transaction Type" filter="true" formControlName="transTypeEnt" [options]="accTransactionTypeArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Fiscal Gl Type</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Fiscal Gl Type" filter="true" formControlName="fiscalTypeEnt" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInputB">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Gl Journal ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Gl Journal ID" filter="true" formControlName="glJournalEnt" [options]="glJournalArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Is Posted</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Is Posted" filter="true" formControlName="postedEnt" [options]="posted" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInputB">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Party ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Party ID" filter="true" formControlName="partyEnt" [options]="partyIdArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Invoice ID</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Invoice ID" filter="true" formControlName="invoiceIdEnt" [options]="invoiceListArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInputB">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Payment ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Payment ID" filter="true" formControlName="paymentIdEnt" [options]="getPaymentsListArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Product ID</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Product ID" filter="true" formControlName="productIdEnt" [options]="ProductsArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInputB">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Work Effort ID</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Work Effort ID" filter="true" formControlName="workEffortIdEnt" [options]="workEffortArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Shipment ID</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Shipment ID" filter="true" formControlName="shipIdEnt" [options]="FacilityShipmentsArray" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInputB">
                                                                                                <label for="exampleInputEmail1">
                                                                                            From Date</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Org Party ID" formControlName="fromDateEnt">
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Through Date</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Org Party ID" formControlName="throughDateEnt">
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                <label for="exampleInputEmail1">
                                                                                            Report Type</label>
                                                                                            </div>

                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown placeholder="Enter Report Type" filter="true" formControlName="report" [options]="reportType" optionlabel="label">

                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button" style="margin-left: 53%;">
                                                                                <button type="submit" (click)="findAccountTransactionsEntries()" class="btn btn-secondary submit-btn">Find</button> &nbsp;


                                                                                <button type="submit" (click)="resetTransFormEntry();" class="btn btn-secondary submit-btn">Reset</button>


                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <br>

                                                                <br>
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Accounting Transactions Entries By Account
                                                                    </h4>

                                                                </div>
                                                                <br>

                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="accountTransactionDetail" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>



                                                                                                    Acctg Trans ID
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">Acctg Trans Entry Seq ID
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Transaction Date
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>



                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Acctg Trans Type
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Fiscal Gl Type
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Invoice ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Payment ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Work Effort ID

                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Shipment ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Party ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Product ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>


                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Is Posted

                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Posted Date
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Debit Credit Flag
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    Amount
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>






                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    <span>{{product.acctgTransId}}</span>


                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.acctgTransEntrySeqId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.transactionDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.acctgTransTypeId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.glFiscalTypeId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.invoiceId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.paymentId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.workEffortId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.shipmentId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.partyId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.productId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.isPosted}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.postedDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.debitCreditFlag}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.amount}}</td>







                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page

                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div *ngIf="activeCategory==6">

                                                            <div class="w3-card-4 classCard">
                                                                <div class="container-fluid">
                                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="
                                                   
                                                    display: flex !important;
                                                   ">

                                                                        <li [ngClass]="activeCategoryValue==2?'active':''" (click)="changeactiveCategoryFunctionSubMenu(2)"><a>
                                                                        Trial Balance</a></li>
                                                                        <li [ngClass]="activeCategoryValue==3?'active':''" (click)="changeactiveCategoryFunctionSubMenu(3)"><a>
                                                                        Transaction Total
                                                                    </a></li>

                                                                        <li [ngClass]="activeCategoryValue==4?'active':''" (click)="changeactiveCategoryFunctionSubMenu(4)"><a>
                                                                        Income Statement</a></li>
                                                                        <li [ngClass]="activeCategoryValue==5?'active':''" (click)="changeactiveCategoryFunctionSubMenu(5)"><a>
                                                                        Cash Flow Statement </a></li>
                                                                        <li [ngClass]="activeCategoryValue==6?'active':''" (click)="changeactiveCategoryFunctionSubMenu(6)"><a>
                                                                        Balance Sheet</a></li>
                                                                        <li [ngClass]="activeCategoryValue==7?'active':''" (click)="changeactiveCategoryFunctionSubMenu(7)"><a>
                                                                        Comparative Income Statement</a></li>
                                                                        <li [ngClass]="activeCategoryValue==8?'active':''" (click)="changeactiveCategoryFunctionSubMenu(8)"><a>
                                                                        Comparative Cash Flow Statement </a></li>
                                                                        <li [ngClass]="activeCategoryValue==9?'active':''" (click)="changeactiveCategoryFunctionSubMenu(9)"><a>
                                                                        Comparative Balance Sheet</a></li>
                                                                        <li [ngClass]="activeCategoryValue==10?'active':''" (click)="changeactiveCategoryFunctionSubMenu(10)">
                                                                            <a>
                                                                        GL Account Trial Balance</a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryValue==11?'active':''" (click)="changeactiveCategoryFunctionSubMenu(11)">
                                                                            <a>
                                                                        Inventory Valuation </a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryValue==12?'active':''" (click)="changeactiveCategoryFunctionSubMenu(12)">
                                                                            <a>
                                                                        Cost Centers
                                                                    </a>
                                                                        </li>





                                                                    </ul>
                                                                </div>

                                                                <div *ngIf="activeCategoryValue==2">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            Find Trial Balance

                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a (click)="getGenerateCsvTrialBalance()">Export as
                                                                        CSV

                                                                    </a></span>


                                                                    </div>
                                                                    <br>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100" [formGroup]="trialBalance">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputC">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Custom Time Period
                                                                                                ID</label>
                                                                                                    </div>

                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter Custom Time Period ID" filter="true" formControlName="customeTime" [options]="timePerodArray" optionlabel="label">

                                                                                                        </p-dropdown>

                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>

                                                                                        </div>


                                                                                    </form>
                                                                                    <div class="main-submit-button" style="margin-left: 24%;">
                                                                                        <button type="submit" (click)="getTrialBalance();" class="btn btn-secondary submit-btn">Submit</button>



                                                                                    </div>
                                                                                </div>
                                                                                <div>


                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                        <div class="form-group">
                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="trialBalanceList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>




                                                                                                                    Account Code
                                                                                                                    <p-sortIcon field="code">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Account Name
                                                                                                                    <p-sortIcon field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>


                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Opening Balance
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Dr
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Cr
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Ending Balance
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>





                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                                                                                    {{product.accountCode}}

                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.accountName}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                US${{product.openingBalance}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                US${{product.postedDebits}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                US${{product.postedCredits}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                US${{product.endingBalance}}
                                                                                                            </td>






                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
                                                                                                <p class="paginate_data">
                                                                                                    View per page

                                                                                                </p>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label>Dr:
                                                                                            US${{this.postedDebitsTotal}}</label>
                                                                                                <label>Cr:
                                                                                            US${{this.postedCreditsTotal}}</label>
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryValue==3">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            Find Transaction Totals

                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a (click)="getGenerateCsvtransactionTotals()">Export
                                                                        as CSV

                                                                    </a></span>

                                                                    </div>
                                                                    <br>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100" [formGroup]="totalTransaction">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Month</label>
                                                                                                    </div>

                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter Month" filter="true" [options]="months" optionlabel="label" formControlName="monthTotal">

                                                                                                        </p-dropdown>

                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                From Date</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="fromDateTotal">
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Through Date</label>
                                                                                                    </div>

                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="througDateTotal">

                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Fiscal Gl Type</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter Fiscal Gl Type" filter="true" formControlName="FiscalGlTypeTotal" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                        </p-dropdown>
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>

                                                                                        </div>


                                                                                    </form>
                                                                                    <div class="main-submit-button" style="margin-left: 61%;">
                                                                                        <button type="submit" (click)="gettransactionTotals();" class="btn btn-secondary submit-btn">Submit</button>



                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Posted Totals
                                                                                            </h4>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">



                                                                                        <div class="form-group">
                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="postedTransactionTotals" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>




                                                                                                                    Account Code
                                                                                                                    <p-sortIcon field="code">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Account Name
                                                                                                                    <p-sortIcon field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>


                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Opening D
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Opening C
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Dr
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Cr
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Closing D
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Closing C
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>





                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                                                                                    {{product.accountCode}}

                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                                                                {{product.accountName}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.openingD}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.openingC}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                US${{product.D}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                US${{product.C}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                            </td>






                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
                                                                                                <p class="paginate_data">
                                                                                                    View per page

                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                UnPosted Totals
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="unpostedTransactionTotals" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Opening D
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Opening C
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Dr
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Cr
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Closing D
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Closing C
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>





                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">

                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.D}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.C}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                </td>






                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>

                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Posted And Unposted Totals
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="allTransactionTotals" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Opening D
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Opening C
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Dr
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Cr
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Closing D
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Closing C
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>





                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.openingD}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.openingC}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.D}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.C}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.D}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.C}}
                                                                                                                </td>






                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page
                                                                                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                                                                                            <span style="margin-left: 400px;">No
                                                                                                        Record
                                                                                                        Found!....</span>
                                                                                                        </ng-container>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryValue==4">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            Find Income Statement

                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a (click)="getgenerateCsvIncomeStatements()">Export
                                                                        as CSV

                                                                    </a></span>

                                                                    </div>
                                                                    <br>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100" [formGroup]="incomeStatement">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Month</label>

                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <p-dropdown placeholder="Enter Month" filter="true" [options]="months" optionlabel="label" formControlName="monthTotalIncome">

                                                                                                        </p-dropdown>

                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                From Date</label>

                                                                                                    </div>
                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="fromDateIncome">
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Through Date</label>


                                                                                                    </div>

                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="throughDateIncome">

                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Fiscal Gl Type</label>



                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter Fiscal Gl Type" filter="true" formControlName="FiscalGlTypeIncome" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                        </p-dropdown>
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>


                                                                                        </div>


                                                                                    </form>
                                                                                    <div class="main-submit-button" style="margin-left: 60%;">
                                                                                        <button type="submit" (click)="getIncomeStatements();" class="btn btn-secondary submit-btn">Submit</button>



                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Accounting Revenues
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="incomeStatementRevenues" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>

                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Expenses
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="incomeStatementExpenses" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>






                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div class="header-tabs">
                                                                                        <h4 class=" common-styling">
                                                                                            Income
                                                                                        </h4>

                                                                                    </div>
                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                        <div class="form-group">
                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="incomeStatementIncome" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>




                                                                                                                    Account Code
                                                                                                                    <p-sortIcon field="code">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Account Name
                                                                                                                    <p-sortIcon field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>


                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Balance
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>






                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                                                                                    {{product.accountCode}}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.accountName}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                US${{product.balance}}
                                                                                                            </td>







                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
                                                                                                <p class="paginate_data">
                                                                                                    View per page

                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Total
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="balanceTotals" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Total
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <p-sortIcon field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.totalName}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance}}
                                                                                                                </td>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryValue==5">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            Find Cash Flow Statement

                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a (click)="getgenerateCsvcashflowStatements()">Export
                                                                        as CSV

                                                                    </a></span>

                                                                    </div>
                                                                    <br>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100" [formGroup]="cashFlowStatement">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Month</label>

                                                                                                    </div>

                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter Month" filter="true" [options]="months" optionlabel="label" formControlName="monthTotalCash">

                                                                                                        </p-dropdown>

                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                From Date</label>

                                                                                                    </div>
                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="fromDateCash">

                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Through Date</label>

                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="throughDateCash">

                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Fiscal Gl Type</label>


                                                                                                    </div>
                                                                                                    <div class="col-lg-2">

                                                                                                        <p-dropdown placeholder="Enter Fiscal Gl Type" filter="true" formControlName="FiscalGlTypeCash" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                        </p-dropdown>
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>


                                                                                        </div>


                                                                                    </form>
                                                                                    <div class="main-submit-button" style="margin-left: 60%;">
                                                                                        <button type="submit" (click)="getcashflowStatements();" class="btn btn-secondary submit-btn">Submit</button>



                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Opening Cash Balance
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="openingCashBalance" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Period Cash Balance
                                                                                            </h4>

                                                                                        </div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="periodCashBalance" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Total Debit (Receipts)
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Total Credit (Disbursement)
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>






                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Closing Cash Balance
                                                                                            </h4>

                                                                                        </div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="closingCashBalance" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>






                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Total
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="balanceTotalsCash" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Total
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <p-sortIcon field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.totalName}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance}}
                                                                                                                </td>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryValue==6">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            Find Balance Sheet

                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a (click)="getCSvbalanceSheet()">Export as CSV

                                                                    </a></span>

                                                                    </div>
                                                                    <br>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100" [formGroup]="balanceSheetStatement">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Through Date</label>

                                                                                                    </div>

                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="throughDateBalance">


                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Fiscal Gl Type</label>

                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter Fiscal Gl Type" filter="true" formControlName="FiscalGlTypeBalance" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                        </p-dropdown>
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>



                                                                                        </div>


                                                                                    </form>
                                                                                    <div class="main-submit-button" style="margin-left: 60%;">
                                                                                        <button type="submit" (click)="getbalanceSheet();" class="btn btn-secondary submit-btn">Submit</button>



                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Assets
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="balanceSheetAssets" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Liabilities
                                                                                            </h4>

                                                                                        </div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="balanceSheetLiabilities" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance}}
                                                                                                                </td>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Equities
                                                                                            </h4>

                                                                                        </div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="balanceSheetEquities" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>






                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page
                                                                                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                                                                                            <span style="margin-left: 400px;">No
                                                                                                        Record
                                                                                                        Found!....</span>
                                                                                                        </ng-container>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Total
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="balanceTotalsBalance" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Total
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <p-sortIcon field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.totalName}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance}}
                                                                                                                </td>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryValue==7">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            Find Comparative Income Statement

                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a (click)="getCSvcomparativeIncomeStatements()">Export
                                                                        as CSV

                                                                    </a></span>

                                                                    </div>
                                                                    <br>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100" [formGroup]="comparativeIncomeStatement">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputD">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period1 From
                                                                                                Date</label>


                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="period1fromDate">
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period2 From
                                                                                                Date</label>


                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="period2fromDate">
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputD">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period1 Through
                                                                                                Date</label>

                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="period1throughDate">
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period2 Through
                                                                                                Date</label>

                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="period2throughDate">
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputD">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period1 Gl Fiscal Type
                                                                                                ID</label>

                                                                                                    </div>

                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter Period1 Gl Fiscal Type ID" filter="true" formControlName="period1FiscalType" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period2 Gl Fiscal Type
                                                                                                ID</label>

                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter Period2 Gl Fiscal Type ID" filter="true" formControlName="period2FiscalType" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                        </p-dropdown>
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>




                                                                                        </div>


                                                                                    </form>
                                                                                    <div class="main-submit-button" style="margin-left: 67%;">
                                                                                        <button type="submit" (click)="getcomparativeIncomeStatements();" class="btn btn-secondary submit-btn">Submit</button>



                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                AccountingRevenues
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="comparativeIncomeStatementRevenues" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color:white">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance1
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance2
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Expenses
                                                                                            </h4>

                                                                                        </div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="comparativeIncomeStatementExpenses" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance1
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance2
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Income
                                                                                            </h4>

                                                                                        </div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="comparativeIncomeStatementIncome" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance1
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance2
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>






                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Total
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="comparativeBalanceTotals" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>





                                                                                                                        Total Name
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Period1
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Period2
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.totalName}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>









                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryValue==8">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            Find Comparative Cash Flow Statement

                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a
                                                                        (click)="getgenerateCsvcomparativeCashFlowStatement()">Export
                                                                        as CSV

                                                                    </a></span>

                                                                    </div>
                                                                    <br>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100" [formGroup]="comparativeCashFlowStatement">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputD">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period1 From
                                                                                                Date</label>


                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="period1fromDateCash">
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period2 From
                                                                                                Date</label>


                                                                                                    </div>
                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="period2fromDateCash">
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputD">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period1 Through
                                                                                                Date</label>

                                                                                                    </div>

                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="period1ThroughDateCash">


                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period2 Through
                                                                                                Date</label>

                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="period2ThroughDateCash">


                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputD">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period1 Gl Fiscal Type
                                                                                                ID</label>


                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <p-dropdown placeholder="Enter Period1 Gl Fiscal Type ID" filter="true" formControlName="period1FiscalTypeCash" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                        </p-dropdown>


                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period2 Gl Fiscal Type
                                                                                                ID</label>

                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter Period2 Gl Fiscal Type ID" filter="true" formControlName="period2FiscalTypeCash" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                        </p-dropdown>

                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>


                                                                                        </div>


                                                                                    </form>
                                                                                    <div class="main-submit-button" style="margin-left: 67%;">
                                                                                        <button type="submit" (click)="getcomparativeCashFlowStatement();" class="btn btn-secondary submit-btn">Submit</button>



                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Opening Cash Balance
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="openingCashBalanceComp" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color:white">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance1
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance2
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Period Cash Balance
                                                                                            </h4>

                                                                                        </div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="periodCashBalanceComp" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Period 1 Debit (Receipts)
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Period 1 Credit (Disbursement)
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance1
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Period 2 Debit (Receipts)
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Period 2 Credit (Disbursement)
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance2
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>









                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Closing Cash Balance
                                                                                            </h4>

                                                                                        </div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="closingCashBalanceComp" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance1
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance2
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>






                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Total
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="balanceTotalsComp" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color:white">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>





                                                                                                                        Total Name
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Period1
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Period2
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.totalName}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>









                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryValue==9">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            Find Comparative Balance Sheet

                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a
                                                                        (click)="getcomparativeGenerateCSVBalanceSheet()">Export
                                                                        as CSV

                                                                    </a></span>

                                                                    </div>
                                                                    <br>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100" [formGroup]="comparativeBalanceSheetStatement">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputD">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period1 Through
                                                                                                Date</label>


                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="period1ThrougDateBalce">

                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period2 Through
                                                                                                Date</label>


                                                                                                    </div>
                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="period2ThrougDateBalce">
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputD">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period1 Gl Fiscal Type
                                                                                                ID</label>


                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <p-dropdown placeholder="Enter Period1 Gl Fiscal Type ID" filter="true" formControlName="period1FiscalTypeBalalnce" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                        </p-dropdown>


                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Period2 Gl Fiscal Type
                                                                                                ID</label>

                                                                                                    </div>
                                                                                                    <div class="col-lg-2">

                                                                                                        <p-dropdown placeholder="Enter Period2 Gl Fiscal Type ID" filter="true" formControlName="period2FiscalTypeBalalnce" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                        </p-dropdown>


                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>




                                                                                        </div>


                                                                                    </form>
                                                                                    <div class="main-submit-button" style="margin-left: 67%;">
                                                                                        <button type="submit" (click)="getcomparativeBalanceSheet();" class="btn btn-secondary submit-btn">Submit</button>



                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Assets
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="balanceSheetAssetsComp" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance1
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance2
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Liabilities
                                                                                            </h4>

                                                                                        </div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="balanceSheetLiabilitiesComp" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance1
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance2
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Equities
                                                                                            </h4>

                                                                                        </div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="balanceSheetEquitiesComp" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>




                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance1
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                    <div style="color: white;">
                                                                                                                        Balance2
                                                                                                                        <p-sortIcon field="category">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>






                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.accountCode}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Total
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="balanceTotalsBalComp" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>





                                                                                                                        Total Name
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Period1
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Period2
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.totalName}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance1}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.balance2}}
                                                                                                                </td>









                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryValue==10">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            GL Account Trial Balance

                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a (click)="getgeneratePdfglAccountTrialBalance()">PDF

                                                                    </a></span>

                                                                    </div>
                                                                    <br>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100" [formGroup]="trialBalanceForm">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">

                                                                                                GL Account ID</label>

                                                                                                    </div>

                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter GL Account ID" filter="true" formControlName="glAccEntGl" [options]="assignGLPartyIdArray" optionlabel="label">

                                                                                                        </p-dropdown>



                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Time Period</label>

                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter Time Period" filter="true" formControlName="customeTime" [options]="timePerodArray" optionlabel="label">

                                                                                                        </p-dropdown>
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Is Posted</label>

                                                                                                    </div>

                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown placeholder="Enter Is Posted" filter="true" formControlName="postedY" [options]="postedAll" optionlabel="label">

                                                                                                        </p-dropdown>




                                                                                                    </div>


                                                                                                </div>
                                                                                            </div>




                                                                                        </div>


                                                                                    </form>
                                                                                    <div class="main-submit-button" style="margin-left: 60%;">
                                                                                        <button type="submit" (click)="getglAccountTrialBalance();" class="btn btn-secondary submit-btn">Submit</button>



                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                GL Account Trial Balance
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select" *ngIf="this.getTrialListDetails">


                                                                                            <div class="form-group">
                                                                                                <label> Subsidiary
                                                                                            Ledger</label>
                                                                                                <label> Company Name : {{
                                                                                            this.getTrialListDetails.currentOrganization}}</label>
                                                                                                <label> Time Period : {{
                                                                                            this.getTrialListDetails.fromDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}} To {{
                                                                                            this.getTrialListDetails.thruDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}</label>
                                                                                                <label> Gl account code and Gl
                                                                                            account name : {{
                                                                                            this.getTrialListDetails.glAccount.accountCode}}
                                                                                            {{
                                                                                            this.getTrialListDetails.glAccount.accountName}}</label>
                                                                                                <br>


                                                                                            </div>
                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="transactionEntries" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>



                                                                                                                    Transaction Date
                                                                                                                    <p-sortIcon field="code">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Account Transaction ID
                                                                                                                    <p-sortIcon field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>



                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Description
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Type of the Currency
                                                                                                                    <br> The balance of last year

                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Original Currency
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Debit Amount
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Credit Amount
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Debit Or Credit Of The Balance
                                                                                                                    <br>Cr
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                                <div style="color: white;">
                                                                                                                    Balance Of The Account
                                                                                                                    <p-sortIcon field="category">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </div>
                                                                                                            </th>








                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr *ngFor="let data of product.acctgTransAndEntries">
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                                                                                    <span>{{data.transactionDate}}</span>


                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{data.transactionId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{data.description}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{data.typeOfTheCurrency}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{data.originalCurrency}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{data.debitAmount}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{data.creditAmount}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{data.debitOrCreditOfBalance}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{data.balanceOfTheAccount}}
                                                                                                            </td>








                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align">

                                                                                                                    <span></span>


                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                Total of the current month
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.totalOfYearToDateDebit}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.balanceOfTheAcctgForYear}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                Cr
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.balance}}
                                                                                                            </td>








                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                <div class="checkbox-align">

                                                                                                                    <span></span>


                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                Total of Year To Date
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.totalOfYearToDateDebit}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.totalOfYearToDateCredit}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                Cr
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                {{product.balance}}
                                                                                                            </td>








                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
                                                                                                <p class="paginate_data">
                                                                                                    View per page

                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryValue==11">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            Find Inventory Valuation

                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a (click)="getgenerateCsvinventoryValuation()">Export
                                                                        as CSV

                                                                    </a></span>

                                                                    </div>
                                                                    <br>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100" [formGroup]="inventoryValuation">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Facility ID</label>


                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <p-dropdown placeholder="Enter Facility ID" filter="true" formControlName="facilityId" [options]="facilities" optionlabel="label">

                                                                                                        </p-dropdown>


                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Product ID</label>


                                                                                                    </div>
                                                                                                    <div class="col-lg-2">


                                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="productId">
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Through Date</label>


                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="throughDate">

                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>



                                                                                        </div>


                                                                                    </form>
                                                                                    <div class="main-submit-button" style="margin-left: 61%;">
                                                                                        <button type="submit" (click)="getinventoryValuation();" class="btn btn-secondary submit-btn">Submit</button>



                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Inventory Valuation List
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="inventoryValuationData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>





                                                                                                                        Product ID
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Unit Cost
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Accounting Quantity Sum
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Value
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>








                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.productId}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.unitCost}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountingQuantitySum}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.value}}
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryValue==12">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            Find Cost Centers
                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a (click)="getgeneratePdfcostCenterAcc()">Export as
                                                                        PDF

                                                                    </a></span>

                                                                    </div>
                                                                    <br>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100" [formGroup]="costCenterForm">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Form Date</label>

                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="costFromDate">


                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Through Date</label>


                                                                                                    </div>
                                                                                                    <div class="col-lg-2">

                                                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" formControlName="costThroughDate">

                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">

                                                                                                    <div class="col-lg-3 form-group classInputB">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                Fiscal Gl Type</label>

                                                                                                    </div>

                                                                                                    <div class="col-lg-2">

                                                                                                        <p-dropdown placeholder="Enter Fiscal Gl Type" filter="true" formControlName="costFiscal" [options]="glFiscalTypeArray" optionlabel="label">

                                                                                                        </p-dropdown>

                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>



                                                                                        </div>


                                                                                    </form>
                                                                                    <div class="main-submit-button" style="margin-left: 61%;">
                                                                                        <button type="submit" (click)="getcostCenterAcc();" class="btn btn-secondary submit-btn">Submit</button>



                                                                                    </div>
                                                                                </div>
                                                                                <div>

                                                                                    <div>
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Cost Centers
                                                                                            </h4>

                                                                                        </div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="costCenterValue" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>



                                                                                                                        Gl Account ID
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Code
                                                                                                                        <p-sortIcon field="price">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                                    <div style="color: white;">
                                                                                                                        Account Name
                                                                                                                        <p-sortIcon field="price">
                                                                                                                        </p-sortIcon>

                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                                    <div style="color: white;">
                                                                                                                        Posted Balance
                                                                                                                        <p-sortIcon field="price">
                                                                                                                        </p-sortIcon>

                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price" *ngFor="let data of glAccountCategories;">
                                                                                                                    <div style="color: white;">
                                                                                                                        {{data.description}}
                                                                                                                        <p-sortIcon field="price">
                                                                                                                        </p-sortIcon>

                                                                                                                    </div>
                                                                                                                </th>


                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'200px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.glAccountId}}
                                                                                                                    </div>
                                                                                                                </td>


                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountCode}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.accountName}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.balance}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}" *ngFor="let data of product.costCenterList;">
                                                                                                                    {{data.value| number : '1.2-2'}}
                                                                                                                </td>




                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>





                                                        </div>
                                                        <div *ngIf="activeCategory==7">
                                                            <br>
                                                            <div class="w3-card-4 classCard">
                                                                <div class="container-fluid">
                                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;
                                                   ">

                                                                        <li [ngClass]="activeCategoryCheck==2?'active':''" (click)="changeactiveCategoryFunctionSubMenuCheck(2)">
                                                                            <a>
                                                                        Checks to Print</a>
                                                                        </li>
                                                                        <li [ngClass]="activeCategoryCheck==3?'active':''" (click)="changeactiveCategoryFunctionSubMenuCheck(3)">
                                                                            <a>
                                                                        Mark Check Sent
                                                                    </a>
                                                                        </li>






                                                                    </ul>
                                                                </div>
                                                                <br>

                                                                <div *ngIf="activeCategoryCheck==2">

                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling HLeft">
                                                                            Checks to Print
                                                                        </h4>
                                                                        <span class="edit-buttons  HRight">

                                                                    <a (click)="generatePdfmarkChecksSent()">Print PDF


                                                                    </a></span>


                                                                    </div>

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">

                                                                                <div>

                                                                                    <div>
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="getCheckDetailsValue" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>





                                                                                                                        Payment ID
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Party To
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Amount
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Effective Date
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox (click)="markCheckPrintData($event)" _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>







                                                                                                                        <div style="color: white;">
                                                                                                                            Select
                                                                                                                        </div>
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>

                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>









                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.paymentId}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.partyIdToDesc}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.amount}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.effectiveDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <p-checkbox (click)="paymentData(product.paymentId)" _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>

                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategoryCheck==3">

                                                                    <div class="panel-body">
                                                                        <div>
                                                                            <div class="container-fluid">

                                                                                <div>

                                                                                    <div>

                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                            <div class="form-group">
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="getCheckDetailsValue" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>





                                                                                                                        Payment ID
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Party To
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Amount
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Effective Date
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Reference Number
                                                                                                                        <p-sortIcon field="name">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox (click)="markCheckPrint($event)" _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>





                                                                                                                        Select
                                                                                                                        <p-sortIcon field="code">
                                                                                                                        </p-sortIcon>
                                                                                                                    </div>

                                                                                                                    <div>
                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                    </div>
                                                                                                                </th>









                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.paymentId}}

                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.partyIdToDesc}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    US${{product.amount}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    {{product.effectiveDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <input type="email" [(ngModel)]="product.paymentRefNum" [ngModelOptions]="{standalone: true}">
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                                                    <p-checkbox (click)="payment(product.paymentId)" _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                                                                                </td>







                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                                                                    <p class="paginate_data">
                                                                                                        View per page

                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <button _ngcontent-cta-c128="" style="font-size: 12px!important;margin-left: 80%;" type="submit" class="btn btn-secondary submit-btn" (click)="sendmarkChecksSent();">Send</button>&nbsp;
                                                                                        </div>
                                                                                    </div>
                                                                                    <br>

                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>






                                                        </div>




                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <br> <br> <br> <br> <br>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalAccTrans" tabindex="-1" role="dialog" aria-labelledby="exampleModalAccTransTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">   Create an Accounting Transaction</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                                        Add Accounting Transaction</a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                        Update Accounting Transaction</a></li>

                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="accountTransaction">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Acctg Trans Type<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Acctg Trans Type" filter="true" formControlName="AcctgTransType" [options]="accTransactionTypeArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA" *ngIf="show">
                                                        <label for="exampleInputEmail1">Acctg Trans Id</label>
                                                    </div>

                                                    <div class="col-lg-2" *ngIf="show">
                                                        <label>{{this.acctran}}</label>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Fiscal Gl Type<span
                                                            style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Fiscal Gl Type" filter="true" formControlName="FiscalGlType" [options]="glFiscalTypeArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Gl Journal ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Gl Journal ID" filter="true" formControlName="GlJournalID" [options]="glJournalArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Group Status</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Group Status" filter="true" formControlName="GroupStatus" [options]="groupStatusArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Fin Account Trans ID
    
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="FinAccountTrasID" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fin Account Trans ID">

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Party ID </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Party ID" filter="true" formControlName="PartyID" [options]="partyIdArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Role Type ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Role Type ID" filter="true" formControlName="RoleTypeID" [options]="allroleArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Transaction Date
    
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="TransactionDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1"> Scheduled Posting Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="ScheduledPostingDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Scheduled Posting Date">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Posted Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="PostedDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Posted Date">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Is Posted</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Is Posted" filter="true" formControlName="IsPosted" [options]="posted" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Inventory Item ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="InventoryItemID" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Inventory Item ID">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Physical Inventory ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="PhysicalInventoryID" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Physical Inventory ID">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Invoice ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Invoice ID" filter="true" formControlName="InvoiceID" [options]="invoiceListArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Payment ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Payment ID" filter="true" formControlName="PaymentID" [options]="getPaymentsListArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Product ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Product ID" filter="true" formControlName="ProductID" [options]="ProductsArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Work Effort ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Work Effort ID" filter="true" formControlName="WorkEffortID" [options]="workEffortArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Shipment ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Shipment ID" filter="true" formControlName="ShipmentID" [options]="FacilityShipmentsArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Fixed Asset ID" filter="true" formControlName="FixedAssetID" [options]="fixedAssetArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Receipt ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="ReceiptID" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Receipt ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Their Acctg Trans ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="TheirAcctgTransID" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Their Acctg Trans ID">

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Voucher Ref</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="VoucheRef" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Voucher Ref">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Voucher Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="VoucherDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Voucher Date">

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="Description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="CreatedDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Created Date">

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="LastModifiedDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Modified Date">
                                                    </div>




                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 65%;">
                                        <button type="submit" (click)="onUpdateCreate();" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" (click)="onSubmitCreate();" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalcreateQuickAccount" tabindex="-1" role="dialog" aria-labelledby="exampleModalcreateQuickAccountTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Quicke Create an Accounting Transaction </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                                        Add Quick Accounting Transaction</a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                        Update Quick Accounting Transaction</a></li>

                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="accountQuickForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputF">
                                                        <label for="exampleInputEmail1">Accounting Transaction Type<span
                                                            style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Acctg Trans Type" filter="true" formControlName="AcctgTransType" [options]="accTransactionTypeArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Fiscal Gl Type<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Fiscal Gl Type" filter="true" formControlName="FiscalGlType" [options]="glFiscalTypeArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputF">
                                                        <label for="exampleInputEmail1">Party ID </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Party ID" filter="true" formControlName="PartyID" [options]="partyIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Role Type ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Role Type ID" filter="true" formControlName="RoleTypeID" [options]="allroleArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputF">
                                                        <label for="exampleInputEmail1">Invoice ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Invoice ID" filter="true" formControlName="InvoiceID" [options]="invoiceListArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Payment ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Payment ID" filter="true" formControlName="PaymentID" [options]="getPaymentsListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputF">
                                                        <label for="exampleInputEmail1">Product ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Product ID" filter="true" formControlName="ProductID" [options]="ProductsArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Work Effort ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Work Effort ID" filter="true" formControlName="WorkEffortID" [options]="workEffortArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputF">
                                                        <label for="exampleInputEmail1">Shipment ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Shipment ID" filter="true" formControlName="ShipmentID" [options]="FacilityShipmentsArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Fixed Asset ID" filter="true" formControlName="FixedAssetID" [options]="fixedAssetArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputF">
                                                        <label for="exampleInputEmail1">Debit Gl Account ID<span
                                                            style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Debit Gl Account ID" filter="true" formControlName="DebitGlAccountID" [options]="assignGLPartyIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Credit Gl Account ID<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Credit Gl Account ID" filter="true" formControlName="CreditGlAccountID" [options]="assignGLPartyIdArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputF">
                                                        <label for="exampleInputEmail1">Amount<span
                                                            style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="Amount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Amount">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Transaction Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="TransactionDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputF">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="Description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>


                                                </div>
                                            </div>



                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 72%;">
                                        <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" (click)="onSubmitQuick()" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>