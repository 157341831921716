import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Injectable({
    providedIn: 'root'
})
export class AccountingApService {
    constructor(
        readonly _ApiService: ApiService,
        readonly _HttpClient: HttpClient
    ) { }
    getInvoiceTypeAndStatus(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/invoice/invoiceTypeStatus`);
    }
    searchInvoice(pagination: { pageNo: number, pageSize: number }, searchFilter: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ar/invoice/search?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter);
    }
    getPurchasedInvoiceTypes(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/invoice/purchaseInvoiceType`);
    }
    createInvoice(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ar/invoice`, formData);
    }
    getInvoiceById(orderId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/invoice/${orderId}`);
    }
    getInvoiceRole(invoiceId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/invoice/role/${invoiceId}`);
    }
    createInvoiceRole(invoiceId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ar/invoice/role/${invoiceId}`, formData);
    }
    getRoleType(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/catalog/carrierShipmentMethod/roleType`);
    }
    deleteInvoiceRole(invoiceId: string, partyId: string, roleTypeId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/ar/invoice/role/${invoiceId}/${partyId}/${roleTypeId}`);
    }
    getTermType(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/invoice/terms/types`);
    }
    getInvoiceTerms(invoiceId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/invoice/terms/invoice/${invoiceId}?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    createInvoiceTerm(invoiceId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest(`/phyCorp/control/api/v1/ar/invoice/terms/invoice/${invoiceId}`, formData);
    }
    getInvoiceTermById(invoiceTermId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/invoice/terms/${invoiceTermId}`);
    }
    updateInvoiceTerm(invoiceId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/ar/invoice/terms/invoice/${invoiceId}`, formData);
    }
    deleteInvoiceTerm(invoiceTermId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/ar/invoice/terms/${invoiceTermId}`);
    }
    getInvoiceItems(invoiceId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/invoice/invoiceItem/${invoiceId}`);
    }
    getInvoiceItemTypes(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/invoice/invoiceItemTypes`);
    }
    createInvoiceItem(invoiceId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ar/invoice/invoiceItem/${invoiceId}`, formData);
    }
    updateInvoiceItem(invoiceId: string, invoiceItemSeqId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/ar/invoice/invoiceItem/${invoiceId}/${invoiceItemSeqId}`, formData);
    }
    deleteInvoiceItem(invoiceId: string, invoiceItemSeqId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/ar/invoice/invoiceItem/${invoiceId}/${invoiceItemSeqId}`);
    }
    getInvoiceItemById(invoiceId: string, invoiceItemSeqId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/invoice/invoiceItemById/${invoiceId}/${invoiceItemSeqId}`);
    }
    sendEmail(invoiceId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/ar/invoice/${invoiceId}`, formData);
    }
    getBillingAccount(pagination: { pageNo: number, pageSize: number }, searchFilter: {
        accountLimit: string,
        billingAccountId: string,
        billingAccountIdSearchType: string,
        description: string,
        descriptionSearchType: string,
        fromDate: string,
        thruDate: string,
    }): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/billingAccount/search?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter);
    }
    updateInvoice(invoiceId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/ar/invoice/${invoiceId}`, formData);
    }
    generateInvoicePDF(invoiceId: string): Observable<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/ar/invoice/generatePdf?invoiceId=${invoiceId}`, { responseType: 'blob' });
    }
    setInvoiceStatus(invoiceId: string, statusId: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/ar/invoice/invoiceStatus?invoiceId=${invoiceId}&statusId=${statusId}`, {});
    }
    // Credit Memo
    getCreditMemoStatus(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accountingPayable/creditMemo/invoiceStatus`);
    }
    getCreditMemoList(pagination: { pageNo: number, pageSize: number }, searchFilter): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accountingPayable/creditMemo/{search}?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter);
    }
    createCreditMemo(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accountingPayable/creditMemo`, formData);
    }
    creditMemoSummary(invoiceId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accountingPayable/creditMemo/${invoiceId}`);
    }
    creditMemoPostalAddress(partyId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accountingPayable/creditMemo/postalAddressByPartyId?partyId=${partyId}`);
    }
    updateCreditMemo(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accountingPayable/creditMemo`, formData);
    }
    // Agreement 

    getAgreementById(agreementId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreement/${agreementId}`);
    }
    // Agreement Item
    getAgreementItemList(agreementId: string, pagination: { pageNo: any, pageSize: any }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItem?agreementId=${agreementId}&pageNo=${""}&pageSize=${""}`);
    }
    getAgreementItemTypeList(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItem/agreementItemTypes`);
    }
    createAgreementItem(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItem`, formData);
    }
    getAgreementItemById(agreementId: string, agreementItemId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItem/${agreementItemId}?agreementId=${agreementId}`);
    }
    updateAgreementItem(agreementItemSeqId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItem/${agreementItemSeqId}`, formData);
    }
    deleteAgreementItem(agreementId: string, agreementItemSeqId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItem/${agreementItemSeqId}?agreementId=${agreementId}`);
    }
    // Agreement Role
    getAgreementRoleList(agreementId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementRole?agreementId=${agreementId}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    createAgreementRole(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementRole`, formData);
    }
    removeAgreementRole(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementRole/remove`, formData);
    }
    // Agreement Content
    getAgreementContentList(agreementId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest(`/phyCorp/control/api/v1/accounting/agreementContent?agreementId=${agreementId}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    createAgreementContent(file: any, agreementId: string, agreementContentTypeId: string, agreementItemSeqId: string, contentTypeId: string, fromDate: string, statusId: string, thruDate: string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementContent?agreementContentTypeId=${agreementContentTypeId}&agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}&contentTypeId=${contentTypeId}&fromDate=${fromDate}&statusId=${statusId}&thruDate=${thruDate}`, file);
    }
    getContentStatusId(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementContent/statusIds`);
    }
    getAgreementContentTypeIds(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementContent/agreementContentTypeIds`);
    }
    getContentIds(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementContent/contentTypeIds`);
    }
    removeAgreementContent(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementContent/remove`, formData);
    }
    downloadAgreementContent(contentId: string): Observable<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/accounting/agreementContent/downloadAgreementContentFile?contentId=${contentId}`, { responseType: 'blob' });
    }
    // Agreement Item Promotions
    getAgreementItemPromotions(agreementId: string, agreementItemSeqId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemPromotion?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    createAgreementItemPromotions(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemPromotion`, formData);
    }
    updateAgreementItemPromotions(formData: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemPromotion`, formData);
    }
    deleteAgreementItemPromotions(agreementId: string, agreementItemSeqId: string, fromDate: string, productPromoId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemPromotion?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}&fromDate=${fromDate}&productPromoId=${productPromoId}`);
    }
    getProductPromoIds(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemPromotion/productPromoIds`);
    }
    getProductPromoById(agreementId: string, agreementItemSeqId: string, fromDate: string, productPromoId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemPromotion/productPromoId?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}&fromDate=${fromDate}&productPromoId=${productPromoId}`);
    }

    // Agreement Item Terms 
    getAgreementItemTerms(agreementId: string, agreementItemSeqId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemTerm?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    createAgreementItemTerm(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemTerm`, formData);
    }
    updateAgreementItemTerm(formData: any, agreementTermId: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemTerm/${agreementTermId}`, formData);
    }
    getTermTypeIds(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemTerm/termTypeIds`);
    }
    deleteAgreementItemTerm(agreementId: string, agreementItemSeqId: string, agreementTermId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemTerm/${agreementTermId}?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}`);
    }
    getInvoiceItemTypeIds(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementTerms/invoiceItemTypes`);
    }
    getAgreementItemTermById(agreementId: string, agreementItemSeqId: string, agreementTermId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemTerm/${agreementTermId}?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}`);
    }

    // Agreement Item Product 
    getAgreementItemProductSales(agreementId: string, agreementItemSeqId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    getAgreementItemProductPurchased(agreementId: string, agreementItemSeqId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct/agreementItemSupplierProduct?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    supplierPrefOrderId(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct/supplierPrefOrderID`);
    }
    getQuantityUomId(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct/quantityUomId`);
    }
    createAgreementItemProductPurchased(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct/agreementItemSupplierProduct`, formData);
    }
    createAgreementItemProductSale(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct`, formData);
    }
    deleteAgreementItemProductSale(agreementId: string, agreementItemSeqId: string, productId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}&productId=${productId}`);
    }
    deleteAgreementItemPurchased(formData: {
        agreementId: string,
        agreementItemSeqId: string,
        availableFromDate: string,
        currencyUomId: string,
        minimumOrderQuantity: number,
        partyId: string,
        productId: string
    }
    ): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct/agreementItemSupplierProduct`, formData);
    }
    getItemProductSaleById(agreementId: string, agreementItemSeqId: string, productId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct/productId?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}&productId=${productId}`)
    }
    updateAgreementItemProductSale(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct`, formData);
    }
    updateAgreementItemProductPurchased(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct/agreementItemSupplierProduct`, formData);
    }

    getItemProductPurchasedById(formData: {
        agreementId: string,
        agreementItemSeqId: string,
        availableFromDate: string,
        currencyUomId: string,
        minimumOrderQuantity: number,
        partyId: string,
        productId: string
    }
    ): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemProduct/agreementItemSupplierProduct/product`, formData);
    }

    // Agreement Item Gio 

    getAgreementItemGioList(agreementId: string, agreementItemSeqId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemGio?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    deleteAgreementItemGio(formData: {
        agreementId: string,
        agreementItemSeqId: string,
        geoId: string
    }): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemGio/remove`, formData);
    }
    createAgreementItemGio(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemGio`, formData);
    }
    getAgreementItemGeoIds(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemGio/geoIds`);
    }

    // Agreement Item Facility 

    getAgreementItemFacilityList(agreementId: string, agreementItemSeqId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemFacilities?agreementId=${agreementId}&agreementItemSeqId=${agreementItemSeqId}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    createAgreementItemFacility(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemFacilities`, formData);
    }
    deleteAgreementItemFacility(facilityValue: { agreementId: string, agreementItemSeqId: string, facilityId: string }): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/agreementItemFacilities/remove`, facilityValue);
    }
    // Commission Invoice

    getCommissionInvoices(pagination: { pageNo: number, pageSize: number }, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accountingPayable/commissionInvoice/search?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, formData);
    }
    createCommissionInvoice(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accountingPayable/commissionInvoice`, formData);
    }
    getCommissionInvoiceById(invoiceId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accountingPayable/commissionInvoice/${invoiceId}`);
    }

    // Report 
    getPayableReport(organizationPartyId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accountingPayable/reports?organizationPartyId=${organizationPartyId}`);
    }
    getArReport(organizationPartyId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/ar/reports?organizationPartyId=${organizationPartyId}`);
    }
    // Inventory Item 

    getInventoryItem(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/facility/inventoryItem/inventoryItemIds`);
    }
}