<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12" style="padding: 0;">

            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Sales</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard" style="border-width: 0px; box-shadow: none;">
                    <div class="container-fluid" style="border: 1px solid #0d3769; border-left-width: 0px;">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 102.6%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategories==1?'active':''" (click)="toAccount()"><a>
                                    Account</a></li>
                            <li [ngClass]="activeCategories==2?'active':''" (click)="toContact()"><a>
                                    Contact</a></li>
                            <li [ngClass]="activeCategories==7?'active':''" (click)="toCases()"><a>
                                    Cases</a></li>
                            <li [ngClass]="activeCategories==9?'active':''" (click)="toTasks()"><a>
                                    Tasks</a></li>
                            <li [ngClass]="activeCategories==4?'active':''" (click)="toActivities()"><a>
                                    Activities </a></li>
                            <li [ngClass]="activeCategories==5?'active':''" (click)="toQuotes()"><a>
                                    Quotes </a></li>
                            <li [ngClass]="activeCategories==6?'active':''" (click)="toSalesOrder()"><a>
                                    Sales order</a></li>
                            <li [ngClass]="activeCategories==11?'active':''" (click)="toDocuments()"><a>
                                    Documents </a></li>
                            <li [ngClass]="activeCategories==12?'active':''" (click)="toNotes()"><a>
                                    Notes </a></li>
                            <li [ngClass]="activeCategories==13?'active':''" (click)="toCalendar()"><a>
                                    Calendar </a></li>
                            <li [ngClass]="activeCategories==14?'active':''" (click)="toEmails()"><a>
                                    Emails </a></li>
                        </ul>
                    </div>

                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategories==4">
                                        <div class="w3-card-4 classCard">
                                            <div class="container-fluid">
                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                    style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                                                    <li [ngClass]="activeCategoies1==1?'active':''">
                                                        <a (click)="openEvents()">Events</a>
                                                    </li>
                                                    <!-- <li [ngClass]="activeCategoies1==2?'active':''">
                                                        <a (click)="openTasks()">Tasks</a>
                                                    </li> -->
                                                </ul>
                                            </div>
                                            <div class="create-new-leade mt-2">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <form class="w-100">
                                                            <div
                                                                class="port-header-contact create-lead font-medium mb-0 w-100">

                                                                <div class="w3-card-4 w-100">
                                                                    <div class="accordion classCard" id="myAccordion">
                                                                        <div class="accordion-item">
                                                                            <h2 class="accordion-header"
                                                                                id="headingOne">
                                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                                                    style="background:#F5F7FA!important; padding: 4px !important;white-space: nowrap;">
                                                                                    <span
                                                                                        style="margin-left: 12px!important;">
                                                                                        Find Events</span>
                                                                                    <button _ngcontent-cta-c128=""
                                                                                        type="submit"
                                                                                        class="btn btn-secondary submit-btn ml-2"
                                                                                        data-toggle="modal"
                                                                                        data-target="#exampleModalCenter">Create 
                                                                                        New Event</button>
                                                                                    <!-- <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active"
                                                                                routerLink="/crm/events/create-events">Create New Event</button> -->
                                                                                    <button type="button"
                                                                                        class="btn btn-outline-secondary active"
                                                                                        *ngIf="show"
                                                                                        (click)="onFirstChange()"
                                                                                        [routerLinkActive]="'is-active'"
                                                                                        routerLink="/crm/events">Assigned
                                                                                        To Me</button>
                                                                                    <button type="button"
                                                                                        class="btn btn-outline-secondary active"
                                                                                        *ngIf="!show"
                                                                                        (click)="onChange()">Assigned By
                                                                                        Me</button>
                                                                                    <button type="button"
                                                                                        class="btn btn-outline-secondary active"
                                                                                        routerLinkActive="active"
                                                                                        routerLink="/crm/events/all-events">All
                                                                                        Events</button>
                                                                                    <button type="button"
                                                                                        class="accordion-button  collapsed"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseOne"
                                                                                        style="margin-left: 48%!important; padding-top: 11px !important;
                                                                                padding-bottom: 11px !important;"></button>

                                                                                </div>

                                                                            </h2>
                                                                            <div id="collapseOne"
                                                                                class="accordion-collapse collapse"
                                                                                data-bs-parent="#myAccordion">
                                                                                <div class="card-body">

                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div
                                                                                                    class="col-lg-3 form-group rightForm">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">WorkEffort
                                                                                                        ID</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2"
                                                                                                    style="display: none;">
                                                                                                    <p-dropdown
                                                                                                        styleClass="small-dropdown"
                                                                                                        filter="true"
                                                                                                        [(ngModel)]="req.workEffortIdSearchType"
                                                                                                        [ngModelOptions]="{standalone:true}"
                                                                                                        name="party"
                                                                                                        [options]="opportunityIdArray"
                                                                                                        optionlabel="label">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email"
                                                                                                        class="form-control small-input"
                                                                                                        [(ngModel)]="req.workEffortId"
                                                                                                        name="workEffortId"
                                                                                                        [ngModelOptions]="{standlone:true}"
                                                                                                        id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Work Effort Id">
                                                                                                </div>

                                                                                                <div
                                                                                                    class="col-lg-3 form-group rightFormA">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Work
                                                                                                        Effort
                                                                                                        Name</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2"
                                                                                                    style="display: none;">
                                                                                                    <p-dropdown
                                                                                                        styleClass="small-dropdown"
                                                                                                        filter="true"
                                                                                                        [(ngModel)]="req.workEffortNameSearchType"
                                                                                                        [ngModelOptions]="{standalone:true}"
                                                                                                        name="party"
                                                                                                        [options]="opportunityIdArray"
                                                                                                        optionlabel="label">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email"
                                                                                                        class="form-control small-input"
                                                                                                        [(ngModel)]="req.workEffortName"
                                                                                                        name="workEffortName"
                                                                                                        [ngModelOptions]="{standlone:true}"
                                                                                                        id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Work Effort Name">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div
                                                                                                    class="col-lg-3 form-group rightForm">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Description</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2"
                                                                                                    style="display: none;">
                                                                                                    <p-dropdown
                                                                                                        styleClass="small-dropdown"
                                                                                                        filter="true"
                                                                                                        [(ngModel)]="req.descriptionSearchType"
                                                                                                        [ngModelOptions]="{standalone:true}"
                                                                                                        name="party"
                                                                                                        [options]="opportunityIdArray"
                                                                                                        optionlabel="label">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email"
                                                                                                        class="form-control small-input"
                                                                                                        [(ngModel)]="req.description"
                                                                                                        name="description"
                                                                                                        [ngModelOptions]="{standlone:true}"
                                                                                                        id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Description">
                                                                                                </div>

                                                                                                <div
                                                                                                    class="col-lg-3 form-group rightFormA">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Purpose</label>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-2 dropdowns">
                                                                                                    <p-dropdown
                                                                                                        styleClass="small-dropdown"
                                                                                                        filter="true"
                                                                                                        [options]="purposeArray"
                                                                                                        optionlabel="label"
                                                                                                        (onChange)="onPurposeChange($event)"
                                                                                                        placeholder="Select Purpose">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div
                                                                                                    class="col-lg-3 form-group rightForm">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Party
                                                                                                        ID</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2"
                                                                                                    *ngIf="!show">
                                                                                                    <input type="email"
                                                                                                        class="form-control small-input"
                                                                                                        [(ngModel)]="req.assignedToPartyId"
                                                                                                        name="assignedToPartyId"
                                                                                                        [ngModelOptions]="{standlone:true}"
                                                                                                        id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Party Id">
                                                                                                </div>
                                                                                                <div class="col-lg-2"
                                                                                                    *ngIf="show">
                                                                                                    <input type="email"
                                                                                                        class="form-control small-input"
                                                                                                        [(ngModel)]="req.assignedByPartyId"
                                                                                                        name="assignedByPartyId"
                                                                                                        [ngModelOptions]="{standlone:true}"
                                                                                                        id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Party Id">
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-3 form-group rightFormA">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Priority</label>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-2 dropdowns">
                                                                                                    <p-dropdown
                                                                                                        styleClass="small-dropdown"
                                                                                                        filter="true"
                                                                                                        [options]="priorityArray"
                                                                                                        (onChange)="onPriorityChange($event)"
                                                                                                        optionlabel="label"
                                                                                                        placeholder="Select Priority">
                                                                                                    </p-dropdown>
                                                                                                </div>


                                                                                            </div>
                                                                                        </div>


                                                                                    </div>

                                                                                    <ng-container *ngIf="!show">
                                                                                        <!-- for assigned to me -->
                                                                                        <div class="col-lg-10 col-10 main-submit-button "
                                                                                            style="margin-left: -5%;">
                                                                                            <button type="submit"
                                                                                                (click)="assignedToMe()"
                                                                                                class="btn btn-secondary submit-btn">Find</button>
                                                                                            <button type="submit"
                                                                                                class="btn btn-danger ml-2"
                                                                                                (click)="resetFilter()"
                                                                                                style="margin-right: 16%;">Reset</button>
                                                                                        </div>

                                                                                    </ng-container>

                                                                                    <ng-container *ngIf="show">
                                                                                        <div class="col-lg-12 col-12 main-submit-button "
                                                                                            style="margin-left: -29%;">
                                                                                            <button type="submit"
                                                                                                (click)="assignedByMe()"
                                                                                                class="btn btn-secondary submit-btn">Find</button>
                                                                                        </div>
                                                                                    </ng-container>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <!-- <div class="w3-card-4 classCard">
                                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                        <p class="h-text">Find Events</p>
                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Event</button>
                                                                         <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active"
                                                                        routerLink="/crm/events/create-events">Create New Event</button>
                                                                        <button type="button" class="btn btn-outline-secondary active" *ngIf="show" (click)="onFirstChange()" [routerLinkActive]="'is-active'" routerLink="/crm/events">Assigned
                                                                    To Me</button>
                                                                        <button type="button" class="btn btn-outline-secondary active" *ngIf="!show" (click)="onChange()">Assigned By Me</button>
                                                                        <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active" routerLink="/crm/events/all-events">All
                                                                    Events</button>
                                                                        <button type="button" class="btn btn-outline-secondary text-white bg-brown">Import</button> 
                        
                                                                    </div>
                                                                    <br>
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightForm">
                                                                                    <label for="exampleInputEmail1">WorkEffort ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="req.workEffortIdSearchType" [ngModelOptions]="{standalone:true}" name="party" [options]="opportunityIdArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control small-input" [(ngModel)]="req.workEffortId" name="workEffortId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Work Effort Id">
                                                                                </div>
                        
                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label for="exampleInputEmail1">Work Effort Name</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="req.workEffortNameSearchType" [ngModelOptions]="{standalone:true}" name="party" [options]="opportunityIdArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control small-input" [(ngModel)]="req.workEffortName" name="workEffortName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Work Effort Name">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                        
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightForm">
                                                                                    <label for="exampleInputEmail1">Description</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="req.descriptionSearchType" [ngModelOptions]="{standalone:true}" name="party" [options]="opportunityIdArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control small-input" [(ngModel)]="req.description" name="description" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                                                </div>
                        
                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label for="exampleInputEmail1">Purpose</label>
                                                                                </div>
                                                                                <div class="col-lg-2 dropdowns">
                                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="purposeArray" optionlabel="label" (onChange)="onPurposeChange($event)" placeholder="Select Purpose">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                        
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightForm">
                                                                                    <label for="exampleInputEmail1">Party ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2" *ngIf="!show">
                                                                                    <input type="email" class="form-control small-input" [(ngModel)]="req.assignedToPartyId" name="assignedToPartyId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party Id">
                                                                                </div>
                                                                                <div class="col-lg-2" *ngIf="show">
                                                                                    <input type="email" class="form-control small-input" [(ngModel)]="req.assignedByPartyId" name="assignedByPartyId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party Id">
                                                                                </div>
                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label for="exampleInputEmail1">Priority</label>
                                                                                </div>
                                                                                <div class="col-lg-2 dropdowns">
                                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="priorityArray" (onChange)="onPriorityChange($event)" optionlabel="label" placeholder="Select Priority">
                                                                                    </p-dropdown>
                                                                                </div>
                        
                        
                                                                            </div>
                                                                        </div>
                        
                        
                                                                    </div>
                                                                    <ng-container *ngIf="!show">
                                                                        <div class="col-lg-10 col-10 main-submit-button " style="margin-left: -5%;">
                                                                            <button type="submit" (click)="assignedToMe()" class="btn btn-secondary submit-btn">Find</button>
                                                                            <button type="submit" class="btn btn-danger ml-2" (click)="resetFilter()" style="margin-right: 16%;">Reset</button>
                                                                        </div>
                        
                                                                    </ng-container>
                        
                                                                    <ng-container *ngIf="show">
                                                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -29%;">
                                                                            <button type="submit" (click)="assignedByMe()" class="btn btn-secondary submit-btn">Find</button>
                                                                        </div>
                                                                    </ng-container>
                                                                </div> -->

                                                            </div>
                                                            <div
                                                                class="port-header-contact create-lead font-medium mb-0 w-100">
                                                                <div class="w3-card-4 classCard w-100">
                                                                    <div *ngIf="show">
                                                                        <div class="header-tabs"
                                                                            style="background:#F5F7FA!important;">
                                                                            <h4 class=" common-styling h4Margin">
                                                                                Open Tasks Assigned by me
                                                                            </h4>
                                                                        </div>
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">

                                                                                    <div class="card own-account-table">
                                                                                        <p-table
                                                                                            [value]="assignedByMeData"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr
                                                                                                    style="background: #0d3769;">
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div class="checkbox-align"
                                                                                                            style="color: white;">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            Event ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Event Name
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Party Id
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Purpose
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Description
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="quantity">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Priority
                                                                                                            <p-sortIcon
                                                                                                                field="quantity">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="price">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Start Date
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="price">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Completion
                                                                                                            Date
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Assigned by
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Created At
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Complete
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Action
                                                                                                            <p-sortIcon
                                                                                                                field="price">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>


                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}"
                                                                                                        (click)="eventDetails(product.workEffortId)">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            <span
                                                                                                                class="account-button">{{product.workEffortId}}</span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.workEffortName}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.toPartyId}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.workEffortPurposeType}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.description}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.priority}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.estimatedStartDate
                                                                                                        |
                                                                                                        date:'yyyy-MM-dd
                                                                                                        HH:mm:ss'}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.estimatedCompletionDate
                                                                                                        |
                                                                                                        date:'yyyy-MM-dd
                                                                                                        HH:mm:ss'}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.assignedTo}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.createdStamp
                                                                                                        |
                                                                                                        date:"yyyy-MM-dd
                                                                                                        HH:mm:ss"}}
                                                                                                    </td>

                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            class="complete"
                                                                                                            (click)="complete(product.workEffortId)"
                                                                                                            class="btn btn-secondary submit-btn">Complete</button>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            (click)="updateEvent(product.workEffortId)"
                                                                                                            class="btn btn-secondary submit-btn">Update</button>

                                                                                                        <!-- <svg class="hover" (click)="updateEvent(product.workEffortId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                                                    <g
                                                                                                                        transform="translate(0 -0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 1.52)">
                                                                                                                            <path
                                                                                                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                                transform="translate(0 -34.137)" />
                                                                                                                        </g>
                                                                                                                        <g
                                                                                                                            transform="translate(4.548 0.004)">
                                                                                                                            <g
                                                                                                                                transform="translate(0 0)">
                                                                                                                                <path
                                                                                                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                    transform="translate(-102.409 -0.004)" />
                                                                                                                            </g>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                        </svg> -->
                                                                                                    </td>
                                                                                                    <!-- <svg (click)="onDelete(product.workEffortId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg> -->


                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <!-- <p class="paginate_data">
                                                                    View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="!show">
                                                                        <!-- this will show first -->
                                                                        <div class="header-tabs"
                                                                            style="background:#F5F7FA!important;">
                                                                            <h4 class=" common-styling h4Margin">
                                                                                Open Tasks Assigned to me
                                                                            </h4>
                                                                        </div>
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">


                                                                                    <div class="card own-account-table">
                                                                                        <!-- This is assigned to me data and component -->
                                                                                        <p-table
                                                                                            [value]="assignedToMeData"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr
                                                                                                    style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align"
                                                                                                            style="color: white;">
                                                                                                            <p-checkbox
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            Event ID
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th pSortableColumn="name"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Event Name
                                                                                                            <p-sortIcon
                                                                                                                field="name"></p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th pSortableColumn="name"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Party Id
                                                                                                            <p-sortIcon
                                                                                                                field="name"></p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th pSortableColumn="name"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Purpose
                                                                                                            <p-sortIcon
                                                                                                                field="name"></p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th pSortableColumn="category"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Description
                                                                                                            <p-sortIcon
                                                                                                                field="category"></p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th pSortableColumn="quantity"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Priority
                                                                                                            <p-sortIcon
                                                                                                                field="quantity"></p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Estimated
                                                                                                            Start Date
                                                                                                            <p-sortIcon
                                                                                                                field="price"></p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Estimated
                                                                                                            Completion
                                                                                                            Date
                                                                                                            <p-sortIcon
                                                                                                                field="price"></p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Assigned by
                                                                                                            <p-sortIcon
                                                                                                                field="price"></p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Created At
                                                                                                            <p-sortIcon
                                                                                                                field="price"></p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th pSortableColumn="price"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Complete
                                                                                                            <p-sortIcon
                                                                                                                field="price"></p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Action
                                                                                                            <p-sortIcon
                                                                                                                field="price"></p-sortIcon>
                                                                                                        </div>
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <!-- <td (click)="eventDetail(product.workEffortId)" [ngStyle]="{'width':'170px'}"> -->
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>

                                                                                                            <span
                                                                                                                (click)="detailedPage(product.workEffortId)"
                                                                                                                class="account-button">
                                                                                                                {{product.workEffortId}}
                                                                                                            </span>


                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <!-- <td [ngStyle]="{'width':'170px'}"
                                                                                                                    (click)="eventDetail(product.workEffortId)">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox 
                                                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div
                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined"
                                                                                                                                        value="undefined"></div>
                                                                                                                                <div role="checkbox"
                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span
                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        {{product.workEffortId}}
                                                                                                                        </div>
                                                                                                                    </td> -->
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.workEffortName}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.toPartyId}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.workEffortPurposeType}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.description}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.priority}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.estimatedStartDate
                                                                                                        |
                                                                                                        date:'yyyy-MM-dd
                                                                                                        HH:mm:ss'}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.estimatedCompletionDate
                                                                                                        |
                                                                                                        date:'yyyy-MM-dd
                                                                                                        HH:mm:ss'}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.assignedBy}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.createdStamp
                                                                                                        |
                                                                                                        date:"yyyy-MM-dd
                                                                                                        HH:mm:ss"}}
                                                                                                    </td>
                                                                                                    <td class="color"
                                                                                                        (click)="completeEvent(product.workEffortId)"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        Complete</td>

                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            (click)="updateEventBy(product.workEffortId)"
                                                                                                            class="btn btn-secondary submit-btn">Update</button>

                                                                                                        <!-- <svg class="hover" (click)="updateEventBy(product.workEffortId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                                            <g transform="translate(0 -0.004)">
                                                                                                                <g transform="translate(0 1.52)">
                                                                                                                    <path
                                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                        transform="translate(0 -34.137)" />
                                                                                                                </g>
                                                                                                                <g transform="translate(4.548 0.004)">
                                                                                                                    <g transform="translate(0 0)">
                                                                                                                        <path
                                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </svg> -->

                                                                                                    </td>


                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                </div>

                                                                <!-- <app-assign-by-me *ngIf="!show">
                                        </app-assign-by-me> -->
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Tasks &amp; Events</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCategory==1?'active':''">
                                <a (click)="openEvents()">Events</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''">
                                <a (click)="openTasks()">Tasks</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCard">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Find Events</p>
                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Event</button>
                                                <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active"
                                                routerLink="/crm/events/create-events">Create New Event</button> 
                                                <button type="button" class="btn btn-outline-secondary active" *ngIf="show" (click)="onFirstChange()" [routerLinkActive]="'is-active'" routerLink="/crm/events">Assigned
                                            To Me</button>
                                                <button type="button" class="btn btn-outline-secondary active" *ngIf="!show" (click)="onChange()">Assigned By Me</button>
                                                <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active" routerLink="/crm/events/all-events">All
                                            Events</button>
                                                 <button type="button" class="btn btn-outline-secondary text-white bg-brown">Import</button> 

                                            </div>
                                            <br>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">WorkEffort ID</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="req.workEffortIdSearchType" [ngModelOptions]="{standalone:true}" name="party" [options]="opportunityIdArray" optionlabel="label">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control small-input" [(ngModel)]="req.workEffortId" name="workEffortId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Work Effort Id">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightFormA">
                                                            <label for="exampleInputEmail1">Work Effort Name</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="req.workEffortNameSearchType" [ngModelOptions]="{standalone:true}" name="party" [options]="opportunityIdArray" optionlabel="label">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control small-input" [(ngModel)]="req.workEffortName" name="workEffortName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Work Effort Name">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">Description</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown styleClass="small-dropdown" filter="true" [(ngModel)]="req.descriptionSearchType" [ngModelOptions]="{standalone:true}" name="party" [options]="opportunityIdArray" optionlabel="label">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control small-input" [(ngModel)]="req.description" name="description" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightFormA">
                                                            <label for="exampleInputEmail1">Purpose</label>
                                                        </div>
                                                        <div class="col-lg-2 dropdowns">
                                                            <p-dropdown styleClass="small-dropdown" filter="true" [options]="purposeArray" optionlabel="label" (onChange)="onPurposeChange($event)" placeholder="Select Purpose">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">Party ID</label>
                                                        </div>
                                                        <div class="col-lg-2" *ngIf="!show">
                                                            <input type="email" class="form-control small-input" [(ngModel)]="req.assignedToPartyId" name="assignedToPartyId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party Id">
                                                        </div>
                                                        <div class="col-lg-2" *ngIf="show">
                                                            <input type="email" class="form-control small-input" [(ngModel)]="req.assignedByPartyId" name="assignedByPartyId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party Id">
                                                        </div>
                                                        <div class="col-lg-3 form-group rightFormA">
                                                            <label for="exampleInputEmail1">Priority</label>
                                                        </div>
                                                        <div class="col-lg-2 dropdowns">
                                                            <p-dropdown styleClass="small-dropdown" filter="true" [options]="priorityArray" (onChange)="onPriorityChange($event)" optionlabel="label" placeholder="Select Priority">
                                                            </p-dropdown>
                                                        </div>


                                                    </div>
                                                </div>


                                            </div>
                                            <ng-container *ngIf="!show">
                                                for assigned to me 
                                                <div class="col-lg-10 col-10 main-submit-button " style="margin-left: -5%;">
                                                    <button type="submit" (click)="assignedToMe()" class="btn btn-secondary submit-btn">Find</button>
                                                    <button type="submit" class="btn btn-danger ml-2" (click)="resetFilter()" style="margin-right: 16%;">Reset</button>
                                                </div>

                                            </ng-container>

                                            <ng-container *ngIf="show">
                                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -29%;">
                                                    <button type="submit" (click)="assignedByMe()" class="btn btn-secondary submit-btn">Find</button>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCard w-100">
                                            <div *ngIf="show">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Open Tasks Assigned by me
                                                    </h4>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="assignedByMeData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Event ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">Event Name
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">Party Id
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Purpose
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Description
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                <div style="color: white;">
                                                                                    Priority
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                <div style="color: white;">Start Date
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                <div style="color: white;">
                                                                                    Completion Date
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">
                                                                                    Assigned by
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">
                                                                                    Created At
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">
                                                                                    Complete
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}" (click)="eventDetails(product.workEffortId)">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    {{product.workEffortId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.workEffortName}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.toPartyId}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.workEffortPurposeType}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.description}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.priority}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.estimatedStartDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.estimatedCompletionDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.assignedTo}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.createdStamp | date:"yyyy-MM-dd HH:mm:ss"}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button type="submit" class="complete" (click)="complete(product.workEffortId)" class="btn btn-secondary submit-btn">Complete</button>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button type="submit" (click)="updateEvent(product.workEffortId)" class="btn btn-secondary submit-btn">Update</button>

                                                                                <svg class="hover" (click)="updateEvent(product.workEffortId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                            <g
                                                                                                transform="translate(0 -0.004)">
                                                                                                <g
                                                                                                    transform="translate(0 1.52)">
                                                                                                    <path
                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                        transform="translate(0 -34.137)" />
                                                                                                </g>
                                                                                                <g
                                                                                                    transform="translate(4.548 0.004)">
                                                                                                    <g
                                                                                                        transform="translate(0 0)">
                                                                                                        <path
                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                </svg> 
                                                                            </td>
                                                                            <svg (click)="onDelete(product.workEffortId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg>


                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                 <p class="paginate_data">
                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> 
                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="!show">
                                                 this will show first 
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Open Tasks Assigned to me
                                                    </h4>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">


                                                            <div class="card own-account-table">
                                                                This is assigned to me data and component 
                                                                <p-table [value]="assignedToMeData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Event ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Event Name
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Party Id
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Purpose
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="category" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Description
                                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="quantity" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;"> Priority
                                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Estimated Start Date
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Estimated Completion Date
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Assigned by
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Created At
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                            </th>

                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Complete
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                <div style="color: white;">Action
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td (click)="eventDetail(product.workEffortId)" [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    
                                                                                    <span (click)="detailedPage(product.workEffortId)"  class="account-button"  >
                                                                                        {{product.workEffortId}} </span>
        
          
                                                                                </div>
                                                                            </td>
                                                                             <td [ngStyle]="{'width':'170px'}"
                                                                                            (click)="eventDetail(product.workEffortId)">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox 
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                {{product.workEffortId}}
                                                                                                </div>
                                                                                            </td> 
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.workEffortName}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.toPartyId}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.workEffortPurposeType}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.description}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.priority}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.estimatedStartDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.estimatedCompletionDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.assignedBy}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.createdStamp | date:"yyyy-MM-dd HH:mm:ss"}}
                                                                            </td>
                                                                            <td class="color" (click)="completeEvent(product.workEffortId)" [ngStyle]="{'width':'170px'}">
                                                                                Complete</td>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button type="submit" (click)="updateEventBy(product.workEffortId)" class="btn btn-secondary submit-btn">Update</button>

                                                                                <svg class="hover" (click)="updateEventBy(product.workEffortId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                    <g transform="translate(0 -0.004)">
                                                                                        <g transform="translate(0 1.52)">
                                                                                            <path
                                                                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                transform="translate(0 -34.137)" />
                                                                                        </g>
                                                                                        <g transform="translate(4.548 0.004)">
                                                                                            <g transform="translate(0 0)">
                                                                                                <path
                                                                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                    transform="translate(-102.409 -0.004)" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg> 

                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>

                                        <!-- <app-assign-by-me *ngIf="!show">
                </app-assign-by-me> 
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div class="main-submit-button">
            <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
            <button type="submit" class="btn btn-degault cncl-btn">Cancel</button>
           </div> 

        </div>
    </div>
</div> -->


<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="!this.show" class="color-black pl-1"> Create-Event</span> 
                <span *ngIf="this.show" class="color-black pl-1"> Update-Event</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">  
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create Event</a></li>
                                <li *ngIf="this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update User</a></li>

                            </ul>
                        </div>

                        <div class="container-fluid main-container-dialog">
                            <div class="row">
                                <div class="col-12">
                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form *ngIf="createEventFormService.create_event_form"
                                                    [formGroup]="createEventFormService.create_event_form"
                                                    class="w-100">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">Event
                                                                        Name<span style="color:red">*</span></label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" id="exampleInputEmail1"
                                                                        class="form-control small-input"
                                                                        formControlName="workEffortName"
                                                                        estimatedStartDate id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Event name">
                                                                    <ng-container
                                                                        *ngIf="this.createEventFormService.create_event_form.controls['workEffortName'].invalid && (this.createEventFormService.create_event_form.controls['workEffortName'].dirty || this.createEventFormService.create_event_form.controls['workEffortName'].touched)">
                                                                        <p class="validate-field"
                                                                            *ngIf="this.createEventFormService.create_event_form.controls['workEffortName'].errors.required">
                                                                            Event Name required!</p>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Description</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control small-input"
                                                                        formControlName="description"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Description">
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">Priority</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown"
                                                                        [(ngModel)]="preSelectedPriority"
                                                                        [options]="priorityArray" optionlabel="label"
                                                                        formControlName="priority">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">To Party
                                                                        ID</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown"
                                                                        filter="true" formControlName="partyId"
                                                                        [options]="partygroupArray" optionlabel="label"
                                                                        placeholder="Select To Party ID">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">Purpose</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown"
                                                                        filter="true" [options]="purposeArray"
                                                                        formControlName="workEffortPurposeTypeId"
                                                                        optionlabel="label"
                                                                        placeholder="Select Purpose">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Estimated
                                                                        Start Date</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-calendar formControlName="estimatedStartDate"
                                                                        id="exampleInputEmail1"
                                                                        placeholder="Select Estimated Start Date">
                                                                    </p-calendar>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">Estimated
                                                                        Completion Date</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-calendar id="exampleInputEmail1"
                                                                        formControlName="estimatedCompletionDate"
                                                                        placeholder="Select Completion Date">
                                                                    </p-calendar>
                                                                </div>


                                                            </div>
                                                        </div>






                                                    </div>

                                                    <div class="main-submit-button" style="margin-right: 16%;">
                                                        <button type="submit" *ngIf="show" (click)="onUpdate()"
                                                            class="btn btn-secondary submit-btn">Update
                                                        </button>
                                                        <button style="width:130px" type="submit" (click)="onSubmit()"
                                                            *ngIf="!show" class="btn btn-secondary submit-btn">Create
                                                        </button>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<!-- <router-outlet></router-outlet> -->

<ngx-spinner></ngx-spinner>