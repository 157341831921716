import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ReallocationsService {

  constructor(private http:HttpClient) { }
  getRelocationList(size,data):Observable<any>{
    const getRelocationList = URLS.getRelocationDetails + "?pageNo=" + size.pageNo + "&pageSize=" + size.pageSize;
    return this.http.post(getRelocationList,data);
  }
  getTrainigList(size,data):Observable<any>{
    const getTrainingDetail = URLS.getTrainingDetail + "?pageNo=" + size.pageNo + "&pageSize=" + size.pageSize;
    return this.http.post(getTrainingDetail,data);
  }
  getEmployeePosition(lists,emplPosition):Observable<any> {
    const getEmployeePosition = URLS.getEmplPositions + "?pageNo=" + lists.pageNo + "&pageSize=" + lists.pageSize;
    return this.http.post(getEmployeePosition,emplPosition);
  } 
 
  
}
