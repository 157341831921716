import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { TrainingApprovalService } from 'src/app/human-resource/training/training-approvals/training-approval.service';
import { FinancialService } from 'src/app/services/financial.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-task-list-summary',
  templateUrl: './task-list-summary.component.html',
  styleUrls: ['./task-list-summary.component.css']
})
export class TaskListSummaryComponent implements OnInit {
  createTaskList: any;
  workEffortId: any;
  FixedAssetByWorkEffortId: any;

  constructor(
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly activatedRoute:ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,
    readonly trainingApprovalService: TrainingApprovalService,
    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
    private _location: Location,
  ) {
    this.createTaskList = this._FormBuilder.group({
      EstimatedStartDate: [''],
      EstimatedCompletionDate:[''],
      Status:[''],
      MaintenanceType:[''],
      ProductMaintenance:[''],
      IntervalMeterType: [''],
      MaintenanceTemplate:[''],
      PurchaseOrderID:[''],
      IntervalQuantity:[''],
      PartyID:[''],
      IntervalUom:[''],
      ActualCompletionDate:[''],
      ActualStartDate:['']
     
      
    });
   }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.workEffortId = params["workEffortId"];
     
    });
    this.getFixedAssetByWorkEffortId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  getFixedAssetByWorkEffortId() {
    this.spinner.show();
    this.accountsService.getFixedAssetByWorkEffortId(this.workEffortId).subscribe(res => {
      this.FixedAssetByWorkEffortId = res.data[0];
      this.spinner.hide();
  
    })
  }
  
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
