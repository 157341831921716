<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Distribution</span>
  
  
               <span class="">
  
                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
  
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
  
                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategories==1?'active':''" (click)="DistributorDetails()"><a>
                                    Distributor Details</a></li>
                            <li [ngClass]="activeCategories==2?'active':''" (click)="salesOrder()"><a>
                              Distributor Order</a></li>
   
                            <li [ngClass]="activeCategories==3?'active':''" (click)="trackDistribution()"><a>
                                    Track Distribution</a></li>
  
                        </ul>
                    </div>
  
  
               
                    <div *ngIf="activeCategories==2">
                       
                        <div class=" bg-white color-grey m-2">
                            <div>
                              <div class="container-fluid">
                                <div class="row">
                    
                    
                                  <ng-container *ngIf="isSales">
                                    <div class="w3-card-4 classCard m-0 p-0">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs px-2" >
                                          <h4 class=" common-styling tabClass">
                                            Distributor Order Form
                                          </h4>
                    
                                          <button type="submit" (click)="toCRM()"
                                              class="btn btn-secondary submit-btn"
                                              style="margin-left: 5%!important">
                                              Home</button>
                    
                                        </div>
                                      </header>
                                    <div class="cardWidthA">
                    
                                        <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                          <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                              <div class="panel panel-default">
                    
                    
                                                <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                                                  <div class="panel-body panelB">
                                                    <div class="mt-2">
                                                      <div class="">
                                                        <div class="row">
                                                          <form class="w-100">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                    
                                                              <div class="col-lg-12">
                                                                <div class="row">
                                                                  <div class="col-lg-6 form-group rightForm">
                                                                    <label class="common-labels">Product Store
                                                                    </label>
                                                                  </div>
                    
                                                                  <div class="col-lg-5">
                                                                    <p-dropdown filter="true" [options]="productStoresListArray"
                                                                      appendTo="body" [ngModelOptions]="{standalone: true}"
                                                                      [(ngModel)]="createOrderSales.productStoreId" optionlabel="label"
                                                                      placeholder="Select Product Store">
                                                                    </p-dropdown>
                                                                  </div>
                                                                </div>
                                                              </div>
                    
                                                              <div class="col-lg-12">
                                                                <div class="row">
                                                                  <div class="col-lg-6 form-group rightForm">
                                                                    <label class="common-labels">Sales Channel
                                                                    </label>
                                                                  </div>
                                                                  <div class="col-lg-5">
                                                                    <p-dropdown filter="true" [options]="salesOrderListArray"
                                                                      appendTo="body" optionlabel="label"
                                                                      [ngModelOptions]="{standalone: true}"
                                                                      [(ngModel)]="createOrderSales.salesChannelEnumId"
                                                                      placeholder="Select Sales Channel">
                                                                    </p-dropdown>
                                                                  </div>
                                                                </div>
                                                              </div>
                    
                                                              <div class="col-lg-12">
                                                                <div class="row">
                    
                                                                  <div class="col-lg-6 form-group rightForm">
                                                                    <label class="common-labels">User Login ID</label>
                                                                  </div>
                    
                                                                  <div class="col-lg-5">
                                                                    <p-dropdown filter="true" [options]="userLoginArray" appendTo="body"
                                                                      [ngModelOptions]="{standalone: true}"
                                                                      [(ngModel)]="createOrderSales.userLoginId" optionlabel="label"
                                                                      placeholder="Select User Login ID">
                                                                    </p-dropdown>
                                                                  </div>
                    
                                                                </div>
                                                              </div>
                                                              <div class="col-lg-12">
                                                                <div class="row">
                                                                  <div class="col-lg-6 form-group rightForm">
                                                                    <label class="common-labels">Distributor</label>
                                                                  </div>
                    
                                                                  <div class="col-lg-5">
                                                                    <p-dropdown filter="true" [options]="customerArray" appendTo="body"
                                                                      [ngModelOptions]="{standalone: true}"
                                                                      [(ngModel)]="createOrderSales.partyId" optionlabel="label"
                                                                      placeholder="Select Customer">
                                                                    </p-dropdown>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </form>
                                                          <div class="main-submit-button buttonSales">
                                                            <button type="submit" (click)="continueSalesOrder()"
                                                              class="btn btn-secondary submit-btn">
                                                              Continue</button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                    
                                            </div>
                                          </div>
                                        </div>
                    
                    
                    
                                      </div>
                                      <div class="cardWidthB">
                                        <div class="w3-card-4 classCardB px-1 pr-2">
                                          <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians " style="background: #F5F7FA!important;
                                            margin-top: 0%;">
                    
                                              <li class="aSize" [ngClass]="activeCategoryValue==2?'active':''">
                                                Enter Details</li>
                                             
                                              <li class="aSize" [ngClass]="activeCategoryValue==8?'active':''">
                                                Parties</li>
                                              <li class="aSize" [ngClass]="activeCategoryValue==9?'active':''">
                                                Review Order</li>
                                            </ul>
                                          </div>
                                          <div *ngIf="activeCategoryValue==2">
                                            <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                                              <div class="panel-body panelB">
                                                <div class="mt-2">
                                                  <div class="">
                                                    <div class="row">
                                                      <form class="w-100">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select userListValue">
                                                          <div class="col-lg-12">
                                                            <div class="row">
                                                              <div class="col-lg-2 form-group rightFormB">
                                                                <label class="common-labels">Order Name </label>
                                                              </div>
                                                              <div class="col-lg-2">
                                                                <input type="text" class="form-control" [(ngModel)]="soContinue.orderName"
                                                                  [ngModelOptions]="{standalone: true}" placeholder="Enter Order Name	">
                                                              </div>
                    
                                                              <div class="col-lg-2 form-group rightFormA">
                                                                <label class="common-labels">PO Number </label>
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                  [(ngModel)]="soContinue.correspondingPoId"
                                                                  [ngModelOptions]="{standalone: true}" placeholder="Enter PO Number">
                                                              </div>
                    
                                                              <div class="col-lg-2 form-group rightFormA">
                                                                <label class="common-labels">Total </label>
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                                <input disabled type="text" class="form-control"
                                                                  placeholder="Enter Total">
                                                              </div>
                    
                                                            </div>
                                                          </div>
                    
                    
                                                          <div class="col-lg-12">
                                                            <div class="row">
                                                              <div class="col-lg-2 form-group rightFormB">
                                                                <label class="common-labels">Currency
                                                                </label>
                                                              </div>
                                                              <div class="col-lg-2">
                                                                <p-dropdown filter="true" [options]="currencyArray" appendTo="body"
                                                                  [ngModelOptions]="{standalone: true}"
                                                                  [(ngModel)]="soContinue.currencyUomId" optionlabel="label"
                                                                  placeholder="Select Currency"></p-dropdown>
                                                              </div>
                    
                                                              <div class="col-lg-2 form-group rightFormA">
                                                                <label class="common-labels">Choose Catalog
                                                                </label>
                                                              </div>
                                                              <div class="col-lg-2">
                                                                <p-dropdown filter="true" [options]="catalogueArray" appendTo="body"
                                                                  [ngModelOptions]="{standalone: true}"
                                                                  [(ngModel)]="soContinue.CURRENT_CATALOG_ID" optionlabel="label"
                                                                  placeholder="Select Catalogue"></p-dropdown>
                                                              </div>
                    
                                                              <div class="col-lg-2 form-group rightFormA">
                                                                <label class="common-labels">Discount </label>
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                                <input disabled type="text" class="form-control"
                                                                  placeholder="Enter Discount">
                                                              </div>
                    
                                                            </div>
                                                          </div>
                    
                    
                                                          <div class="col-lg-12">
                                                            <div class="row">
                                                              <div class="col-lg-2 form-group rightFormB">
                                                                <label class="common-labels">WorkEffort ID
                                                                </label>
                                                              </div>
                                                              <div class="col-lg-2">
                                                                <p-dropdown filter="true" [options]="workEffortArray" appendTo="body"
                                                                  [ngModelOptions]="{standalone: true}"
                                                                  [(ngModel)]="soContinue.workEffortId" optionlabel="label"
                                                                  placeholder="Select WorkEffort ID"></p-dropdown>
                                                              </div>
                    
                                                              <div class="col-lg-2 form-group rightFormA">
                                                                <label for="exampleInputEmail1">Ship After Date </label>
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                                <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                                                  hourFormat="24" dateFormat="dd.mm.yy"
                                                                  [(ngModel)]="soContinue.shipAfterDate"
                                                                  [ngModelOptions]="{standalone: true}"></p-calendar>
                                                              </div>
                    
                                                              <div class="col-lg-2 form-group rightFormA">
                                                                <label class="common-labels">Total Discount</label>
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                                <input disabled type="text" class="form-control"
                                                                  placeholder="Enter Total Discount">
                                                              </div>
                                                            </div>
                                                          </div>
                    
                                                          <div class="col-lg-12">
                                                            <div class="row">
                                                              <div class="col-lg-2 form-group rightFormB">
                    
                                                                <label for="exampleInputEmail1">Ship Before Date </label>
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                                <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                                                  hourFormat="24" dateFormat="dd.mm.yy"
                                                                  [(ngModel)]="soContinue.shipBeforeDate"
                                                                  [ngModelOptions]="{standalone: true}"></p-calendar>
                                                              </div>
                    
                    
                                                              <div class="col-lg-2 form-group rightFormA">
                                                                <label for="exampleInputEmail1">After Before Date</label>
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                                <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                                                  hourFormat="24" dateFormat="dd.mm.yy"
                                                                  [(ngModel)]="soContinue.reserveAfterDate"
                                                                  [ngModelOptions]="{standalone: true}"></p-calendar>
                                                              </div>
                    
                                                              <div class="col-lg-2 form-group rightFormA">
                                                                <label class="common-labels">Total Tax </label>
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                                <input disabled type="text" class="form-control"
                                                                  placeholder="Enter Total Tax">
                                                              </div>
                    
                                                            </div>
                                                          </div>
                    
                                                          <div class="col-lg-12">
                                                            <div class="row" style="margin-top: -2%;">
                                                              <div class="col-lg-2 form-group rightFormB">
                    
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                              </div>
                    
                    
                                                              <div class="col-lg-2 form-group rightFormA">
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                              </div>
                    
                                                              <div class="col-lg-2 form-group rightFormA " >
                                                                <label class="common-labels">Final Prize </label>
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                                <input disabled type="text" class="form-control"
                                                                   placeholder="Enter Final Prize">
                                                              </div>
                    
                                                            </div>
                                                          </div>
                    
                                                          <div class="col-lg-12">
                                                            <div class="row">
                                                              <div class="col-lg-2 form-group rightFormB">
                    
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                              </div>
                    
                    
                                                              <div class="col-lg-2 form-group rightFormA">
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                              </div>
                    
                                                              <div class="col-lg-2 form-group rightFormA">
                                                                <label class="common-labels">Profit (%)</label>
                                                              </div>
                    
                                                              <div class="col-lg-2">
                                                                <input disabled type="text" class="form-control"
                                                                   placeholder="Enter Profit">
                                                              </div>
                    
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                      <div *ngIf="this.showButton" class="main-submit-button buttonB">
                                                        <button type="submit" (click)="continueSalesOrderCreation()"
                                                          class="btn btn-secondary submit-btn" >Continue</button>
                    
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                         
                                          <div *ngIf="activeCategoryValue==8">
                                            <div>
                                              <div class="container-fluid">
                                                <div class="container-fluid">
                                                  <ul class="tabbing-section  tabbing-accordians paddingSales">
                                                    <li class="liOrderItem" [ngClass]="activeCategoryParty==1?'active':''"><a>
                                                        Sales Order : Additional Party Entry </a></li>
                    
                    
                    
                                                  </ul>
                                                </div>
                                                <div class="row">
                                                  <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                                    <div class="w-100">
                                                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                        <div class="panel panel-default">
                                                          <div *ngIf="activeCategoryParty==1">
                                                            <div class="header-tabs">
                                                              <h4 class=" common-styling sOrder" style="margin-left: 1%;">
                                                                Sales Order : Additional Party Entry
                                                              </h4>
                                                              <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                                                                href="#seo-collapse">
                                                              </span>
                                                              <span class="edit-buttons orderItem" routerLinkActive="active"
                                                                (click)="orderTabRefresh()"> Order Items</span>
                                                              <span class="edit-buttons sOrder" routerLinkActive="active"
                                                                (click)="orderTabSalesFinal()">Shipping</span>&nbsp;
                                                              <span class="edit-buttons sOrder">Options</span>&nbsp;
                                                              <span class="edit-buttons sOrder">Order Terms</span>
                                                              <span class="edit-buttons sOrder">Payment</span>&nbsp;
                                                              <span class="edit-buttons sOrder"> Parties</span>&nbsp;
                                                              <span class="edit-buttons sOrder"> Reviews Order</span>
                                                              <span class="edit-buttons sOrder"
                                                                (click)="finalizeOrderAdditonal()">
                                                                Continue</span>
                    
                    
                    
                                                            </div>
                                                            <div class="panel-body">
                                                              <div class="mt-2">
                                                                <div class="">
                                                                  <h4 class="detail-heading">1) Select type of party to associate to order :
                                                                  </h4>
                                                                  <div class="radios-sections">
                                                                    <div class="col-lg-9 pl-0 col-12">
                                                                      <div class="form-group  d-flex radio-boxes-wrapper">
                                                                        <input type="radio" id="Guaranteed" name="radio" value="Guaranteed"
                                                                          (click)="setPartyId(true)">
                                                                        <label class="common-labels" for="Guaranteed">Person</label>
                                                                      </div>
                                                                    </div>
                                                                    <div class="col-lg-9 pl-0 col-12">
                                                                      <div class="form-group d-flex radio-boxes-wrapper">
                                                                        <input type="radio" id="Air" name="radio" value="Air"
                                                                          (click)="setPartyId(false)">
                                                                        <label class="common-labels" for="Air">Group</label>
                                                                      </div>
                                                                    </div>
                                                                    <div class="col-lg-9 pl-0 col-12">
                                                                      <div class="form-group d-flex radio-boxes-wrapper">
                                                                        <input type="radio" id="Ground" name="radio" value="Ground"
                                                                          (click)="setPartyId(false)">
                                                                        <label class="common-labels" for="Ground">I do not wish to add
                                                                          additional parties.
                                                                        </label>
                                                                      </div>
                                                                    </div>
                                                                    <ng-container *ngIf="isParty">
                                                                      <h4 class="detail-heading">2) Find Order :</h4>
                                                                      <div class="col-lg-4 col-12">
                                                                        <div class="form-group">
                                                                          <label class="common-labels identifier-label">Identifier
                                                                          </label>
                                                                          <p-dropdown [options]="partyIds" filter="true"
                                                                            [(ngModel)]="additionalPartyForm.additionalPartyId"
                                                                            [ngModelOptions]="{standAlone:true}"></p-dropdown>
                                                                        </div>
                                                                      </div>
                                                                      <div class="col-lg-12 col-12 main-submit-button">
                                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                                          (click)="isRole=true">Apply</button>
                                                                      </div>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="isRole">
                                                                      <h4 class="detail-heading">3) Select Role :</h4>
                                                                      <div class="col-lg-4 col-12">
                                                                        <div class="form-group">
                                                                          <label class="common-labels identifier-label">Role
                                                                          </label>
                                                                          <p-dropdown [options]="roleValue" filter="true"
                                                                            [(ngModel)]="additionalPartyForm.additionalRoleTypeId"
                                                                            [ngModelOptions]="{standALone:true}"></p-dropdown>
                                                                        </div>
                                                                      </div>
                                                                      <div class="col-lg-12 col-12 main-submit-button">
                                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                                          (click)="addAdditionalParty()">Add</button>
                                                                      </div>
                                                                    </ng-container>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                    
                    
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div *ngIf="activeCategoryValue==9">
                                            <div>
                                              <div class="container-fluid">
                                                <div class="container-fluid">
                                                  <ul class="tabbing-section  tabbing-accordians paddingSales">
                                                    <li class="liOrderItem" [ngClass]="activeCategoryComfirm==1?'active':''"
                                                      (click)="changeactiveCategoryFunctionConfirm(1)"><a>
                                                        Sales Order : Order Confirmation</a></li>
                                                    <li class="liOrderItem" [ngClass]="activeCategoryComfirm==2?'active':''"
                                                      (click)="changeactiveCategoryFunctionConfirm(2)"><a>
                                                        Payment Information </a></li>
                                                    <li class="liOrderItem" [ngClass]="activeCategoryComfirm==3?'active':''"
                                                      (click)="changeactiveCategoryFunctionConfirm(3)"><a>
                                                        Delivery Information </a></li>
                                                    <li class="liOrderItem" [ngClass]="activeCategoryComfirm==4?'active':''"
                                                      (click)="changeactiveCategoryFunctionConfirm(4)"><a>
                                                        Order Items </a></li>
                    
                                                  </ul>
                                                </div>
                                                <div class="row">
                                                  <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                                    <div class="w-100">
                                                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                        <div class="panel panel-default">
                                                          <div *ngIf="activeCategoryComfirm==1">
                                                            <div class="header-tabs">
                                                              <h4 class=" common-styling sOrder" style="margin-left: 1%;">
                                                                Sales Order : Order Confirmation
                                                              </h4>
                                                              <a class="arrow-toggle toggle-arrow detail-created port-header tabs-header collapsed"
                                                                data-toggle="collapse" href="#Order">
                                                              </a>
                                                              <span class="edit-buttons orderItem" routerLinkActive="active"
                                                                (click)="orderTabRefresh()">Order Items</span>
                                                              <span class="edit-buttons sOrder">Shipping</span>&nbsp;
                                                              <span class="edit-buttons sOrder">Options</span>&nbsp;
                                                              <span class="edit-buttons sOrder">Order Terms</span>
                                                              <span class="edit-buttons sOrder">Payment</span>&nbsp;
                                                              <span class="edit-buttons sOrder"> Parties</span>&nbsp;
                                                              <span class="edit-buttons sOrder"> Review Order</span>
                    
                    
                    
                                                              <span class="edit-buttons sOrder" routerLinkActive="active"
                                                                (click)="processOrder()">Create Order</span>
                    
                    
                    
                                                            </div>
                                                            <div class="panel-body">
                                                              <div class="mt-2">
                                                                <div class="">
                                                                  <div class="row">
                                                                    <div
                                                                      class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                                                      style="padding-left:15px;">
                                                                      <div class="d-flex flex-column">
                                                                        <strong class="common-bold-properties" style="margin-right:10px;">
                                                                          Order Name
                                                                        </strong>
                                                                        <p class="common-regular-properties">
                                                                          {{reviewOrderValue?.orderHeaderInfo.orderName}}
                                                                        </p>
                                                                      </div>
                                                                      <div class="d-flex flex-column">
                    
                                                                        <strong class="common-bold-properties" style="margin-right:10px;">
                                                                          Po Number
                                                                        </strong>
                                                                        <p class="common-regular-properties">
                                                                          {{reviewOrderValue?.poNumber ? reviewOrderValue?.poNumber : 0}}
                                                                        </p>
                                                                      </div>
                                                                      <div class="d-flex flex-column">
                                                                        <strong class="common-bold-properties" style="margin-right:10px;">
                                                                          Order Terms
                                                                        </strong>
                                                                        <div class="common-regular-properties">
                                                                          <table class="table">
                                                                            <thead>
                                                                              <tr>
                                                                                <th scope="col">Term Type</th>
                                                                                <th scope="col">Term Value</th>
                                                                                <th scope="col">Term Days</th>
                                                                                <th scope="col">Text Value</th>
                                                                                <th scope="col">Description</th>
                                                                              </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                              <ng-template ngFor let-term
                                                                                [ngForOf]="reviewOrderValue?.orderTerms">
                                                                                <tr>
                                                                                  <th scope="row">{{term.termTypeID}}</th>
                                                                                  <td>{{term.termValue}}</td>
                                                                                  <td>{{term.termDays}}</td>
                                                                                  <td>{{term.textValue}}</td>
                                                                                  <td>{{term.description}}</td>
                                                                                </tr>
                                                                              </ng-template>
                                                                            </tbody>
                                                                          </table>
                                                                        </div>
                                                                      </div>
                                                                      <div class="d-flex flex-column">
                                                                        <strong class="common-bold-properties" style="margin-right:10px;">
                                                                          Splitting
                                                                          Preference </strong>
                                                                        <p class="common-regular-properties"> N/A
                                                                        </p>
                                                                      </div>
                                                                      <div class="d-flex flex-column">
                    
                                                                        <strong class="common-bold-properties" style="margin-right:10px;">
                                                                          Gift </strong>
                                                                        <p class="common-regular-properties"> N/A
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div *ngIf="activeCategoryComfirm==2">
                                                            <div class="header-tabs">
                                                              <h4 class=" common-styling sOrder">
                                                                Payment Information
                                                              </h4>
                    
                                                            </div>
                                                            <div class="panel-body">
                                                              <div class="mt-2">
                                                                <div class="">
                                                                  <div class="row">
                                                                    <form class="w-100">
                                                                      <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <p class="col-lg-12  col-12" style="color:#65787e;font-size:14px;">
                                                                          No order items to
                                                                          display.</p>
                                                                      </div>
                                                                    </form>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div *ngIf="activeCategoryComfirm==3">
                                                            <div class="header-tabs">
                                                              <h4 class=" common-styling sOrder">
                                                                Delivery Information
                                                              </h4>
                    
                                                            </div>
                                                            <div class="panel-body a">
                                                              <div class="mt-2">
                                                                <div class="">
                                                                  <div class="row m-0">
                                                                    <div class="card own-account-table">
                                                                      <p-table [resizableColumns]="true" columnResizeMode="expand"
                                                                        scrollWidth="100%" [paginator]="false" [rows]="rows"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true"
                                                                        [totalRecords]="total"
                                                                        [value]="reviewOrderValue?.orderShippingInformation"
                                                                        [scrollable]="true"
                                                                        styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                                                        <ng-template pTemplate="colgroup" let-columns>
                                                                          <colgroup>
                                                                            <col *ngFor="let col of offerShowingLineColoumnsConfirm">
                                                                          </colgroup>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="header">
                                                                          <tr class="activity-rows">
                                                                            <th id="tableHead"
                                                                              *ngFor="let col of offerShowingLineColoumnsConfirm">
                                                                              <ng-container>
                                                                                {{col.header}}
                                                                              </ng-container>
                                                                            </th>
                                                                          </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-i="rowIndex" let-activity>
                                                                          <tr class="activity-data">
                                                                            <td *ngFor="let col of offerShowingLineColoumnsConfirm">
                                                                              <ng-container *ngIf="col.field === 'Destination'">
                                                                                <span>{{activity.address.toName}},{{activity.address.address1}},{{activity.address.countryGeoId}},
                                                                                  {{activity.address.postalCode}}</span>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="col.field === 'Supplier'">
                                                                                <span class="d-flex red-border align-items-center">
                                                                                  <div class="name_text">
                                                                                    <a>
                                                                                      {{activity.supplier}}
                                                                                    </a>
                                                                                  </div>
                                                                                </span>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="col.field === 'ShipmentMethod'">
                                                                                <span class="d-block align-items-center">
                                                                                  {{activity.method.description}}
                                                                                  {{activity.method.shipmentMethodTypeId}}
                                                                                </span>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="col.field === 'Items'"><span>
                                                                                  {{activity.shipItems[0].item}}</span>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="col.field === 'Quantity'">
                                                                                <span>{{activity.shipItems[0].qty}}</span>
                                                                              </ng-container>
                    
                                                                            </td>
                                                                          </tr>
                                                                        </ng-template>
                                                                      </p-table>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div *ngIf="activeCategoryComfirm==4">
                                                            <div class="header-tabs">
                                                              <h4 class=" common-styling sOrder">
                                                                Order Items
                                                              </h4>
                    
                                                            </div>
                                                            <div class="panel-body a">
                                                              <div class="mt-2">
                                                                <div class="">
                                                                  <div class="row m-0">
                                                                    <div class="card own-account-table">
                                                                      <p-table [resizableColumns]="true" columnResizeMode="expand"
                                                                        scrollWidth="100%" [paginator]="false" [rows]="rows"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true"
                                                                        [totalRecords]="total" [value]="reviewOrderValue?.orderItemsMap"
                                                                        [scrollable]="true"
                                                                        styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                                                        <ng-template pTemplate="colgroup" let-columns>
                                                                          <colgroup>
                                                                            <col *ngFor="let col of orderItemsColumns">
                                                                          </colgroup>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="header">
                                                                          <tr class="activity-rows">
                                                                            <th id="tableHead" *ngFor="let col of orderItemsColumns">
                                                                              <ng-container>
                                                                                {{col.header}}
                                                                              </ng-container>
                                                                            </th>
                                                                          </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-rowData let-i="rowIndex"
                                                                          let-activity>
                                                                          <tr class="activity-data">
                                                                            <td *ngFor="let col of orderItemsColumns">
                                                                              <ng-container *ngIf="col.field === 'status'">
                                                                                <span>{{activity.itemDescription}}</span>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="col.field === 'name'">
                                                                                <span class="d-flex red-border align-items-center">
                                                                                  <div class="name_text">
                                                                                    <a>
                                                                                      {{activity.quantity}}
                                                                                    </a>
                                                                                  </div>
                                                                                </span>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="col.field === 'email'">
                                                                                <span class="d-block align-items-center">
                                                                                  <div class="name_text">
                                                                                    {{activity.unitPrice}}
                                                                                  </div>
                                                                                </span>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="col.field === 'phone'"><span>
                                                                                  {{activity.orderItemAdjustment}}</span>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="col.field === 'type'">
                                                                                <span>{{activity.orderItemSubTotal}}</span>
                                                                              </ng-container>
                                                                            </td>
                                                                          </tr>
                                                                        </ng-template>
                                                                      </p-table>
                                                                    </div>
                    
                                                                    <div class="row w-100" style="padding-top:10px;">
                                                                      <div class="col-md-10 text-right">
                                                                        <strong
                                                                          class="common-bold-properties confirm-price-text d-block">Sub
                                                                          Total</strong>
                                                                        <strong
                                                                          class="common-bold-properties  confirm-price-text d-block">Promotion</strong>
                                                                        <strong
                                                                          class="common-bold-properties confirm-price-text  d-block">Shipping
                                                                          and
                                                                          Handling</strong>
                                                                        <strong
                                                                          class="common-bold-properties confirm-price-text  d-block">VAT</strong>
                                                                        <br>
                                                                        <strong
                                                                          class="common-bold-properties confirm-price-text d-block">Grand
                                                                          Total</strong>
                                                                      </div>
                                                                      <div class="col-md-2 text-right">
                                                                        <p class="common-regular-properties confirm-price-text  d-block">
                                                                          US${{reviewOrderValue?.orderHeaderInfo.commonTotal}}</p>
                                                                        <p class="common-regular-properties  confirm-price-text  d-block">
                                                                          -US$0</p>
                                                                        <p class="common-regular-properties confirm-price-text d-block">
                                                                          US${{reviewOrderValue?.shippingAndHandling}}</p>
                                                                        <p class="common-regular-properties confirm-price-text d-block">
                                                                          US${{reviewOrderValue?.orderSalesTax}}</p>
                                                                        <br>
                                                                        <p class="common-regular-properties confirm-price-text d-block">
                                                                          US${{reviewOrderValue?.orderGrandTotal}}</p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                    
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                    
                                                </div>
                    
                                              </div>
                                            </div>
                                          </div>
                    
                    
                                        </div>
                    
                                      </div>
                    
                                    </div>
                                    <br>
                                    <div class="w3-card-4 classCard mt-3" style="    margin: 1.5px;
                                    width: 100%;">
                                      <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians" style="background: #F5F7FA!important;
                                        margin-top: 0%;">
                    
                                         
                                          <li class="aSize" [ngClass]="activeCategoryValueMain==3?'active':''">
                                            Create Order
                                          </li>
                    
                                          <li class="aSize" [ngClass]="activeCategoryValueMain==4?'active':''">
                                            Shipping</li>
                                          <li class="aSize" [ngClass]="activeCategoryValueMain==5?'active':''">
                                            Option</li>
                                          <li class="aSize" [ngClass]="activeCategoryValueMain==6?'active':''">
                                            Term</li>
                                          <li class="aSize" [ngClass]="activeCategoryValueMain==7?'active':''">
                                            Payment</li>
                                        
                    
                    
                    
                    
                                        </ul>
                                      </div>
                                   
                                      <div *ngIf="activeCategoryValueMain==3">
                                        <div>
                                          <div class="container-fluid">
                                            <div class="container-fluid">
                                              <ul class="tabbing-section  tabbing-accordians paddingSales">
                                                <li class="aSalesOrderTag f12" [ngClass]="activeCategoryOrderTab==1?'active':''"
                                                  (click)="changeactiveCategoryFunctionOrderTab(1)"><a>
                                                    Create Distributor Order </a></li>
                                                <li class="aSalesOrderTag f12" [ngClass]="activeCategoryOrderTab==2?'active':''"
                                                  (click)="changeactiveCategoryFunctionOrderTab(2)"><a>
                                                    Order Items </a></li>
                                                <li class="aSalesOrderTag f12" [ngClass]="activeCategoryOrderTab==3?'active':''"
                                                  (click)="changeactiveCategoryFunctionOrderTab(3)"><a>
                                                    Promotion/Coupon codes</a></li>
                                                <li class="aSalesOrderTag f12" [ngClass]="activeCategoryOrderTab==4?'active':''"
                                                  (click)="changeactiveCategoryFunctionOrderTab(4)"><a>
                                                    Special Offers </a></li>
                    
                                              </ul>
                                            </div>
                                            <div class="row">
                                              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                                <div class="w-100">
                                                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div class="panel panel-default">
                                                      <div *ngIf="activeCategoryOrderTab==1">
                                                        <div class="header-tabs">
                                                          <h4 style="padding-left: 28px;" class=" f12 common-styling sOrder">
                                                            Create Distributor Order
                                                          </h4>
                                                          <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                                                            href="#seo-collapse">
                                                          </span>
                                                          <span class="edit-buttons sCleaer f12" routerLinkActive="active"
                                                            (click)="clearOrder()">Clear Order</span>
                                                          <span class="edit-buttons sOrder f12">Remove Selected</span>
                                                          <span class="edit-buttons sOrder f12"
                                                            (click)="recalculateOrder()">Recalculate
                                                            Order</span>
                                                          <span class="edit-buttons sOrder f12" routerLinkActive="active"
                                                            (click)="finalizeOrder()">Finalize Order</span>
                                                          <span class="edit-buttons sOrder sOrder1 f12"
                                                            (click)="finalizeOrderWithDefault()">Finalize Order With
                                                            Default Options</span>
                                                        </div>
                                                        <div class="panel-body panelB">
                                                          <form class="w-100">
                                                            <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                              <div class="col-lg-12 col-12">
                                                                <div class="form-group table-responsive">
                                                            <table class="table align-middle">
                                                              <thead>
                                                                <tr style="background: #0d3769;">
                                                                  <th style="color: white;font-size: 12px;padding: 14px 20px !important;" >
                                                                    S.No
                                                                   
                                                                  </th>
                                                                  <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;" >
                                                                   Product Id
                                                                    
                                                                  </th>
                                                                  <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                  Quantity 
                                                                
                                                                  </th>
                                                                  <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                    Unit Price
                                                                  
                                                                   </th>
                                                                   <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                    Comments
                                                                     
                                                                    
                                                                    </th>
                                            
                                                                  <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                    Desired Delivery Date 
                                                                   
                                                                  </th>
                                                                  <th  style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                   Ship After Date
                                                                    
                                                                  </th>
                                                                  <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;" >
                                                                    Ship Before Date
                                                              
                                                                   
                                                                  </th>
                                                                  <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;" >
                                                                   Reserve After Date
                                                                
                                                                  
                                                                  </th>
                                                                 
                                                                 
                                                                  <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                  Item Total
                                                                   
                                                                  
                                                                  </th>
                                                                  <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                   Action
                                                                   
                                                                   
                                                                  </th>
                                            
                                            
                                            
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                              
                                                                <tr class="align-bottom" *ngFor="let product of orderItems;let i = index;">
                                                                  <td style="    color: black;
                                                                  font-weight: 100;
                                                                  font-size: 12px;
                                                                
                                                                " >
                                                                   
                                                                      {{i+1}}
                                                                   
                                                                  </td>
                                                                    <td style="    color: black;
                                                                    font-weight: 100;
                                                                    font-size: 12px;
                                                                    min-width: 155px;">
                                                                    
                                                                        {{product.productId}}
                                                                   
                                                                    </td>
                                                                    <td  style="    color: black;
                                                                    font-weight: 100;
                                                                    font-size: 12px;
                                                                    min-width: 155px;">
                                                                      {{product.quantity}}
                                                                    </td>
                                                                    <td  style="color: black;
                                                                    font-weight: 100;
                                                                    font-size: 12px; 
                                                                    min-width: 155px;">
                                                                      {{product.unitPrice}}
                                                                    </td>
                                                                    <td  style="color: black;
                                                                    font-weight: 100;
                                                                    font-size: 12px;
                                                                    min-width: 155px;">
                                                                      {{product.commonComment}}
                                                                    </td>
                                                                    <td  style="    color: black;
                                                                    font-weight: 100;
                                                                    font-size: 12px;
                                                                    min-width: 155px;">
                                                                    <!--   {{product.quantity}} -->
                                                                    </td>
                                                                    
                                                                    <td  style="    color: black;
                                                                    font-weight: 100;
                                                                    font-size: 12px;
                                                                    min-width: 155px;">
                                                                      {{product.shipAfterDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                     
                                                                    </td>
                                                                    <td  style="    color: black;  min-width: 155px;
                                                                    font-weight: 100;
                                                                    font-size: 12px;
                                                                  ">
                                                                      {{product.shipBeforeDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>
                                                                    <td  style=" color: black;  min-width: 155px;
                                                                    font-weight: 100;
                                                                    font-size: 12px;
                                                                    ">
                                                                    
                                                                    </td>
                                                                   
                                                                    
                                                                    <td  style="color: black;  min-width: 155px;
                                                                    font-weight: 100;
                                                                    font-size: 12px; 
                                                                  ">
                                                                      {{product.itemTotal}}
                                                                    </td>
                                                                    <td  style="    color: black;  min-width: 155px;
                                                                        font-weight: 100;
                                                                        font-size: 12px;
                                                                      ">
                                                                        
                                                                        </td>
                                                                    
                                                                </tr>
                                                            
                                                                <tr class="align-bottom">
                                                                  <td >
                                                                   
                                                                  </td>
                                                                  <td style="min-width: 155px;">
                                                                   
                                                                      <p-dropdown filter="true" [options]="productListArray"
                                                                      appendTo="body" [(ngModel)]="soFormData.productId"
                                                                      [ngModelOptions]="{standalone: true}" optionlabel="label"
                                                                      placeholder="Select Product">
                                                                    </p-dropdown>
                                                                  
                                                                  </td>
                                                                  <td style="min-width: 155px;">
                                                                    <input  type="text" class="form-control" placeholder="Enter Quantity"
                                                                    [(ngModel)]="soFormData.quantity"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                  </td>
                                                                  <td style="min-width: 155px;">
                                                                   
                                                                  </td>
                                                                  <td style="min-width: 155px;">
                                                                    <textarea maxlength="1500" [rows]="2" [cols]="30"
                                                                    autoResize="autoResize" [(ngModel)]="soFormData.itemComment"
                                                                    [ngModelOptions]="{standalone: true}" style="width: 105%;" ></textarea>
                                                                  </td>
                                          
                                                                  <td style="min-width: 155px;">
                                                                    <p-calendar appendTo="body" showSeconds="true" placholder="Select Date"
                                                                                  showTime="true" hourFormat="24" dateFormat="dd.mm.yy"
                                                                                  [(ngModel)]="soFormData.itemDesiredDeliveryDate"
                                                                                  [ngModelOptions]="{standalone: true}"></p-calendar>
                                                                  </td>
                                                                  <td style="min-width: 155px;">
                                                                    <p-calendar appendTo="body" showSeconds="true" placholder="Select Date"
                                                                    showTime="true" hourFormat="24" dateFormat="dd.mm.yy"
                                                                    [(ngModel)]="soFormData.shipAfterDate"
                                                                    [ngModelOptions]="{standalone: true}"></p-calendar>
                                                                  </td>
                                                                  <td style="min-width: 155px;">
                                                                    <p-calendar appendTo="body" showSeconds="true" placholder="Select Date"
                                                                    showTime="true" hourFormat="24" dateFormat="dd.mm.yy"
                                                                    [(ngModel)]="soFormData.shipBeforeDate"
                                                                    [ngModelOptions]="{standalone: true}"></p-calendar>
                                                                  </td>
                                                                  <td style="min-width: 155px;">
                                                                    <p-calendar appendTo="body" showSeconds="true" placholder="Select Date"
                                                                    showTime="true" hourFormat="24" dateFormat="dd.mm.yy"
                                                                    [(ngModel)]="soFormData.reserveAfterDate"
                                                                    [ngModelOptions]="{standalone: true}"></p-calendar>
                                                                  </td>
                                                                  
                                                                 
                                                                  <td style="min-width: 155px;">
                                                                   
                                                                  </td>
                                                                  <td style="min-width: 155px;">
                                                                    <button type="submit"
                                                                    (click)="addToOrder()" class="btn btn-secondary submit-btn" >Add To
                                                                    Order</button>
                                                                  </td>
                                                                </tr>
                                                            
                                                           
                                                            </tbody>
                                                            </table>
                                                           
                                                            </div></div></div>
                                                          </form>
                                                        </div>
                                                      </div>
                    
                                                      <div *ngIf="activeCategoryOrderTab==2">
                                                        <div class="header-tabs" style="margin-left: 7px;">
                                                          <h4 class=" common-styling sOrder">
                                                            Order Items
                                                          </h4>
                    
                                                        </div>
                                                        <div class="panel-body panelB">
                    
                                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <p class="col-lg-12 mb-0 col-12" style="color:#65787e;font-size:14px;">No
                                                              order
                                                              items to display : <span class="text-parimary">{{orderItems.length ?
                                                                orderItems.length :0}}</span></p>
                                                          </div>
                                                          <ng-template ngFor let-order [ngForOf]="orderItems" let-index="index">
                                                            <div class="row">
                                                              <div class="col-md-9 d-flex align-items-center">
                                                                <div class=" justify-content-start submit-btn-sales main-submit-button"
                                                                  style="padding-left:15px;margin-top:-8px">
                                                                  <button type="submit"
                                                                    class="btn btn-secondary submit-btn">{{order.productId}}</button>
                                                                </div>
                                                                <div class="col-lg-3 col-12">
                                                                  <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="Enter Name"
                                                                      [value]="order.description">
                                                                  </div>
                                                                </div>
                                                                <div class="col-lg-4 col-12">
                                                                  <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="Quantity"
                                                                      [value]="order.quantity">
                                                                  </div>
                                                                </div>
                                                                <div class="col-lg-3 col-12">
                                                                  <div class="form-group">
                                                                    <input type="text" class="form-control" placeholder="Enter Price"
                                                                      [value]="order.unitPrice">
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div class="col-md-3 d-flex justify-content-end">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                  <p class="col-lg-12 mb-0 col-12 pOrder">
                                                                    US${{order.orderAmount}} US${{order.itemTotal}}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </div>
                    
                                                            <div class="row">
                                                              <div class="col-md-12 d-flex align-items-center">
                                                                <div class=" justify-content-start submit-btn-sales main-submit-button"
                                                                  style="padding-left:15px;margin-top:-8px">
                                                                  <button type="submit"
                                                                    class="btn btn-secondary  submit-btn">Inventory</button>
                                                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <p class="col-lg-12 mb-0 col-12"
                                                                      style="color:#65787e;font-size:14px;">ATP
                                                                      =
                                                                      {{order.inventorySummary.availableToPromiseMap[order.productId]}},
                                                                      QOH =
                                                                      {{order.inventorySummary.quantityOnHandMap[order.productId]}}</p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div class="col-md-3 d-flex">
                                                                <div class="form-group">
                                                                  <div class="flex-column">
                                                                    <label for="afterDate">Ship After Date</label>
                                                                    <p-calendar></p-calendar>
                                                                  </div>
                                                                </div>
                                                                <div class="form-group">
                                                                  <div class="flex-column">
                                                                    <label for="beforeDate">Ship Before Date</label>
                                                                    <p-calendar></p-calendar>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="row">
                                                              <div class="col-md-9 d-flex align-items-center">
                                                                <div class=" justify-content-start submit-btn-sales main-submit-button"
                                                                  style="padding-left:15px;margin-top:-8px">
                                                                  <button type="submit" class="btn btn-secondary  submit-btn">Quick
                                                                    Loop</button>
                                                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <p class="col-lg-12 mb-0 col-12"
                                                                      style="color:#65787e;font-size:14px;">
                                                                      Associated Products</p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div class="col-md-3 d-flex">
                    
                                                              </div>
                                                            </div>
                                                            <ng-template [ngIf]="order.orderAssociatedProducts" ngFor
                                                              let-productAssociate [ngForOf]="order.orderAssociatedProducts"
                                                              let-j="index">
                                                              <div class="row">
                                                                <div class="col-md-9 d-flex align-items-center">
                                                                  <div
                                                                    class=" justify-content-start submit-btn-sales main-submit-button"
                                                                    style="padding-left:15px;margin-top:-8px">
                                                                    <button type="submit"
                                                                      class="btn btn-secondary submit-btn">{{productAssociate.productId}}</button>
                                                                    <div
                                                                      class="all-users-infomation font-13 font-medium own-user-select">
                                                                      <p class="col-lg-12 mb-0 col-12"
                                                                        style="color:#65787e;font-size:14px;">
                                                                        {{productAssociate.description}}(Also Bought)</p>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div class="col-md-3 d-flex">
                    
                                                                </div>
                                                              </div>
                                                            </ng-template>
                    
                    
                                                          </ng-template>
                    
                    
                                                          <div class="row">
                                                            <div class="col-md-9 d-flex align-items-center">
                                                            </div>
                                                            <div class="col-md-3 d-flex justify-content-end">
                                                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <p class="col-lg-12  col-12"
                                                                  style="text-align:right;color:#65787e;margin-bottom:5px;font-size:12px;">
                                                                  Sub
                                                                  Total:
                                                                  US${{totalAmount?.orderSubTotal}}</p>
                                                                <p class="col-lg-12 col-12"
                                                                  style="text-align:right;color:#65787e;margin-bottom:5px;font-size:12px;">
                                                                  Adjustment - Promotion:
                                                                  -US${{totalAmount?.orderAdjustment.adjustmentAmount}}</p>
                                                                <p class="col-lg-12 col-12"
                                                                  style="text-align:right;color:#65787e;margin-bottom:5px;font-size:12px;">
                                                                  Cart
                                                                  Total:
                                                                  US${{totalAmount?.cartTotal}}</p>
                                                              </div>
                                                            </div>
                                                          </div>
                    
                                                        </div>
                                                      </div>
                    
                                                      <div *ngIf="activeCategoryOrderTab==3">
                                                        <div class="header-tabs" style="margin-left: 7px;">
                                                          <h4 class=" common-styling sOrder">
                                                            Promotion/Coupan Codes
                                                          </h4>
                    
                                                        </div>
                                                        <div class="panel-body panelB">
                    
                                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-4 col-12">
                                                              <div class="form-group">
                                                                <p-dropdown filter="true" [options]="promotionValue"
                                                                  [(ngModel)]="promoId" [ngModelOptions]="{standAlone:true}"
                                                                  placeholder="Enter Promo">
                                                                </p-dropdown>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="col-lg-12 col-12 main-submit-button">
                                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                              (click)="addPromotion()">Add Code</button>
                                                          </div>
                    
                                                        </div>
                                                      </div>
                    
                                                      <div *ngIf="activeCategoryOrderTab==4">
                                                        <div class="header-tabs" style="margin-left: 7px;">
                                                          <h4 class=" common-styling sOrder">
                                                            Special Offers
                                                          </h4>
                    
                                                        </div>
                                                        <div class="panel-body">
                    
                                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <ng-template ngFor let-promo [ngForOf]="promotions" let-index="index">
                                                              <div class="col-lg-12 col-12 details-offer">
                                                                <a class="detail-btn cursor-pointer" routerLink="/order/sales-product"
                                                                  [queryParams]="{promoId:promo.productPromoId}">Details</a>
                                                                <p>{{promo.promoText}}</p>
                                                              </div>
                                                            </ng-template>
                    
                                                          </div>
                    
                    
                                                        </div>
                                                      </div>
                    
                                                    </div>
                    
                                                  </div>
                    
                                                  <!-- order-items -->
                    
                                                  <!--  order-items -->
                    
                                                  <!--Promotion/Coupon Codes-->
                    
                                                  <!--  Promotion/Coupon Codes -->
                    
                                                  <!--Special Offers-->
                    
                                                  <!--  Special Offers -->
                                                </div>
                                              </div>
                                            </div>
                    
                                          </div>
                                        </div>
                                      </div>
                                      <div *ngIf="activeCategoryValueMain==4">
                                        <div class="container-fluid">
                                          <div class="container-fluid">
                                            <ul class="tabbing-section  tabbing-accordians paddingSales">
                                              <li class="liOrderItem" [ngClass]="activeCategoryShip==1?'active':''"
                                                (click)="changeactiveCategoryShiping(1)"><a>
                                                  Sales Order : Order Entry Ship-To Settings </a></li>
                                              <li class="liOrderItem" [ngClass]="activeCategoryShip==2?'active':''"
                                                (click)="changeactiveCategoryShiping(2)"><a>
                                                  Ship to Another Party </a></li>
                    
                    
                                            </ul>
                                          </div>
                                          <div class="row">
                                            <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                              <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                  <div class="panel panel-default">
                                                    <div *ngIf="activeCategoryShip==1">
                                                      <div class="header-tabs">
                                                        <h4 class=" common-styling sOrder">
                                                          Sales Order : Order Entry Ship-To Settings
                                                        </h4>
                                                        <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                                                          href="#seo-collapse">
                                                        </span>
                                                        <span class="edit-buttons orderItem" routerLinkActive="active"
                                                          (click)="orderTabRefresh()">Order Items</span>
                                                        <span class="edit-buttons sOrder" routerLinkActive="active"
                                                          (click)="orderTabSalesFinal()">Shipping</span>&nbsp;
                                                        <span class="edit-buttons sOrder" routerLinkActive="active"
                                                          (click)="orderTabSalesFinal()">Options</span>&nbsp;
                                                        <span class="edit-buttons sOrder">Order Terms</span>
                                                        <span class="edit-buttons sOrder"> Payment</span>&nbsp;
                                                        <span class="edit-buttons sOrder">Parties</span>&nbsp;
                                                        <span class="edit-buttons sOrder">Review Order</span>&nbsp;
                                                        <span class="edit-buttons sOrder" routerLinkActive="active"
                                                          (click)="finalizeOrderShip()">Continue</span>
                    
                    
                    
                                                      </div>
                                                      <div class="panel-body panelB">
                                                        <div class="d-flex justify-content-end">
                                                          <div class="col-lg-4 col-12 main-submit-button">
                                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                              routerLinkActive="active" routerLink="/order/sales-order-options">
                                                              Create New Ship Group
                                                            </button>
                                                          </div>
                                                          <div class="main-submit-button">
                                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                              routerLinkActive="active" routerLink="/order/new-shipping-address">
                                                              Create New Shipping Address
                                                            </button>
                                                          </div>
                                                        </div>
                                                        <div class="mt-2">
                                                          <div class="">
                                                            <div class="row">
                                                              <ng-template ngFor let-address [ngForOf]="addressList" let-index="index">
                                                                <form class="w-100 form-border">
                                                                  <h2 class="col-lg-12 col-12 address-heading">Ship Group Nbr
                                                                    {{address.shipGroupNumber}}</h2>
                                                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-4 col-12">
                                                                      <div class="form-group">
                                                                        <label class="common-labels">Distributor
                                                                        </label>
                                                                        <p-dropdown [options]="supplierList"
                                                                          placeholder="Select Supplier" [name]="index+'supplier'">
                                                                        </p-dropdown>
                                                                      </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-12">
                                                                      <div class="form-group">
                                                                        <label class="common-labels">Agreement
                                                                        </label>
                                                                        <p-dropdown [options]="agreementList"
                                                                          placeholder="Select Agreement" [name]="index+'agreement'">
                                                                        </p-dropdown>
                                                                      </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-12">
                                                                      <div class="form-group">
                                                                        <label class="common-labels">Reserve inventory from facility
                                                                        </label>
                                                                        <p-dropdown [options]="true" placeholder="Select  Inventory"
                                                                          [name]="index+'inventory'"></p-dropdown>
                                                                      </div>
                                                                    </div>
                                                                    <ng-template ngFor let-po [ngForOf]="address.shippingAddresses"
                                                                      let-i="index">
                                                                      <div class="col-lg-12 col-12 d-flex align-items-center flex-wrap"
                                                                        style="margin-top:10px;">
                                                                        <div class="w-100">
                                                                          <p-checkbox name="selectContacts" [id]="i+'checkbox'"
                                                                            styleClass="custom-checkbox" [value]="po.geoPointId"
                                                                            (onChange)="setShipping($event,po.geoPointId)">
                                                                          </p-checkbox>
                                                                          <p class="mb-0 d-inline-block"
                                                                            style="color: #65787e;font-size: 13px;font-weight:400">
                                                                            <span style="font-weight:700;">To: </span>{{po.toName}}
                                                                          </p>
                                                                        </div>
                                                                        <div style="color: #65787e;
                                                          font-size: 13px;
                                                          font-weight: 400;">
                                                                          <span class="address-values"><span
                                                                              style="font-weight:700">Attn
                                                                              :</span>
                                                                            {{po.address1}}
                                                                          </span>
                                                                          <span class="address-values">
                                                                            {{po.houseNumber}}
                                                                          </span>
                                                                          <span class="address-values">{{po.address2}}</span>
                                                                          <span class="address-values">{{po.city}}</span>
                                                                          <span class="address-values">{{po.postalCode}}</span>
                                                                          <span class="address-values">{{po.countryGeoId}}</span>
                                                                        </div>
                                                                      </div>
                                                                    </ng-template>
                                                                  </div>
                                                                  <div class="col-lg-12 col-12 main-submit-button"><button type="submit"
                                                                      class="btn btn-secondary submit-btn">Update</button></div>
                                                                </form>
                                                              </ng-template>
                    
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                    
                                                    <div *ngIf="activeCategoryShip==2">
                                                      <div class="header-tabs">
                                                        <h4 class=" common-styling sOrder">
                                                          Ship to Another Party
                                                        </h4>
                    
                                                      </div>
                                                      <div class="panel-body panelB">
                    
                    
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                          <div class="col-lg-4 col-12">
                                                            <div class="form-group">
                                                              <label class="common-labels">Party Id
                                                              </label>
                                                              <p-dropdown [options]="partyIds" placeholder="Select Party Id"
                                                                [(ngModel)]="partyIdValue" [ngModelOptions]="{standAlone:true}">
                                                              </p-dropdown>
                                                            </div>
                    
                                                          </div>
                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button"><button type="submit"
                                                            class="btn btn-secondary submit-btn">Continue</button></div>
                    
                    
                                                      </div>
                                                    </div>
                    
                                                  </div>
                    
                                                </div>
                                                <!--Promotion/Coupon Codes-->
                    
                                                <!--  Promotion/Coupon Codes -->
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div *ngIf="activeCategoryValueMain==5">
                                        <div class="container-fluid">
                                          <div class="container-fluid">
                                            <ul class="tabbing-section  tabbing-accordians paddingSales">
                                              <li class="liOrderItem" [ngClass]="activeCategorySalesOrder==1?'active':''"><a>
                                                  Sales Order : Order Option Settings </a></li>
                    
                    
                    
                                            </ul>
                                          </div>
                                          <div class="row">
                                            <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                              <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                  <div class="panel panel-default">
                                                    <div *ngIf="activeCategorySalesOrder==1">
                                                      <div class="header-tabs">
                                                        <h4 class=" common-styling sOrder">
                                                          Sales Order : Order Option Settings
                                                        </h4>
                                                        <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                                                          href="#seo-collapse">
                                                        </span>
                                                        <span class="edit-buttons orderItem" routerLinkActive="active"
                                                          (click)="orderTabRefresh()"> Order Items</span>
                                                        <span class="edit-buttons sOrder" routerLinkActive="active"
                                                          (click)="orderTabSalesFinal()">Shipping</span>&nbsp;
                                                        <span class="edit-buttons sOrder">Options</span>&nbsp;
                                                        <span class="edit-buttons sOrder">Order Terms</span>
                                                        <span class="edit-buttons sOrder">Payment </span>&nbsp;
                                                        <span class="edit-buttons sOrder">Parties</span>&nbsp;
                                                        <span class="edit-buttons sOrder">Review Order</span>
                                                        <span class="edit-buttons sOrder" routerLinkActive="active"
                                                          (click)="finalizeOrderOption()"> Continue</span>
                    
                    
                    
                    
                                                      </div>
                                                      <div class="panel-body panelB">
                                                        <div class="mt-2">
                                                          <div class="">
                                                            <div class="row">
                                                              <form class="w-100">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                  <div class="col-lg-4 col-12">
                                                                    <div class="form-group">
                                                                      <label class="common-labels">Internal Note
                                                                      </label>
                                                                      <input type="text" class="form-control"
                                                                        [(ngModel)]="notes.internalNote"
                                                                        [ngModelOptions]="{standalone:true}" placeholder="Enter Note">
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-4 col-12">
                                                                    <div class="form-group">
                                                                      <label class="common-labels">Shipping Notes
                                                                      </label>
                                                                      <input type="text" class="form-control"
                                                                        [(ngModel)]="notes.shippingNote"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        placeholder="Enter Shipping Notes">
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </form>
                                                            </div>
                                                            <h3 class="top-heading">Ship Group Nbr 1</h3>
                                                            <h4 class="detail-heading">Shipment Method
                                                            </h4>
                                                            <div class="radios-sections">
                                                              <ng-template ngFor let-option [ngForOf]="carrierShipments"
                                                                let-index="index">
                                                                <div class="col-lg-9 pl-0 col-12">
                                                                  <div class="form-group  d-flex radio-boxes-wrapper">
                                                                    <input type="radio" [id]="index+'Guaranteed'" name="radio"
                                                                      (change)="setOption(option.shipmentMethodTypeId,option.partyId,option.shippingEst)">
                                                                    <label class="common-labels"
                                                                      [for]="index+'Guaranteed'">{{option.partyId}}
                                                                      {{option.description}} - US${{option.shippingEst}}</label>
                                                                  </div>
                                                                </div>
                                                              </ng-template>
                    
                                                              <h4 class="detail-heading">Ship all at once, or 'as available'?</h4>
                                                              <div class="col-lg-9 pl-0 col-12">
                                                                <div class="form-group d-flex radio-boxes-wrapper">
                                                                  <input type="radio" id="until" name="radio-btns" value="until"
                                                                    [(ngModel)]="shipmentCustom.shipAtOnce"
                                                                    [ngModelOptions]="{standalone:true}">
                                                                  <label class="common-labels" for="until">Please wait until the entire
                                                                    order is ready
                                                                    before shipping.</label>
                                                                </div>
                                                              </div>
                                                              <div class="col-lg-9 pl-0 col-12">
                                                                <div class="form-group d-flex radio-boxes-wrapper">
                                                                  <input type="radio" id="ship items" name="radio-btns"
                                                                    value="ship items" [(ngModel)]="shipmentCustom.shipAtOnce"
                                                                    [ngModelOptions]="{standalone:true}">
                                                                  <label class="common-labels" for="ship items">Please ship items I
                                                                    ordered as they
                                                                    become available (you may incur additional shipping charges). -
                                                                    US$0.00</label>
                                                                </div>
                                                              </div>
                                                              <div class="col-lg-4 pl-0 col-12">
                                                                <div class="form-group">
                                                                  <label style="color: #65787E !important;"
                                                                    for="exampleInputEmail1">Ship
                                                                    Before Date
                                                                  </label>
                                                                  <div class="">
                                                                    <p-calendar showSeconds="true" placeholder="Select Date"
                                                                      showTime="true" hourFormat="24" dateFormat="dd.mm.yy"
                                                                      [(ngModel)]="shipmentCustom.shipDateBefore"
                                                                      [ngModelOptions]="{standalone:true}"></p-calendar>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div class="col-lg-4 pl-0 col-12">
                                                                <div class="form-group">
                                                                  <label style="color: #65787E !important;"
                                                                    for="exampleInputEmail1">Ship
                                                                    After Date
                                                                  </label>
                                                                  <div class="">
                                                                    <p-calendar [(ngModel)]="shipmentCustom.shipDateAfter"
                                                                      [ngModelOptions]="{standalone:true}" showSeconds="true"
                                                                      placeholder="Select Date" showTime="true" hourFormat="24"
                                                                      dateFormat="dd.mm.yy">
                                                                    </p-calendar>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div class="col-lg-4 pl-0 col-12">
                                                                <div class="form-group">
                                                                  <label style="color: #65787E !important;"
                                                                    for="exampleInputEmail1">Special
                                                                    Instructions</label>
                                                                  <div>
                                                                    <textarea name="" id="exampleInputEmail1" class="form-control"
                                                                      cols="30" [ngModelOptions]="{standalone:true}"
                                                                      [(ngModel)]="shipmentCustom.specialInstruction"
                                                                      rows="10"></textarea>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <h4 class="detail-heading">Is This a Gift? </h4>
                                                              <div class="col-lg-9 pl-0 col-12">
                                                                <div class="form-group d-flex radio-boxes-wrapper">
                                                                  <input type="radio" id="Yes" name="Yes-btns" value="Yes"
                                                                    [(ngModel)]="shipmentCustom.isGift"
                                                                    [ngModelOptions]="{standalone:true}">
                                                                  <label class="common-labels" for="Yes">Yes</label>
                                                                </div>
                                                              </div>
                                                              <div class="col-lg-9 pl-0 col-12">
                                                                <div class="form-group d-flex radio-boxes-wrapper">
                                                                  <input type="radio" id="No" name="Yes-btns" value="No"
                                                                    [(ngModel)]="shipmentCustom.isGift"
                                                                    [ngModelOptions]="{standalone:true}">
                                                                  <label class="common-labels" for="No">No</label>
                                                                </div>
                                                              </div>
                    
                                                              <div class="col-lg-4 pl-0 col-12">
                                                                <div class="form-group">
                                                                  <label style="color: #65787E !important;"
                                                                    for="exampleInputEmail1">Gift
                                                                    Message</label>
                                                                  <div>
                                                                    <textarea name="" id="exampleInputEmail1" class="form-control"
                                                                      cols="30" [(ngModel)]="shipmentCustom.giftMessage"
                                                                      [ngModelOptions]="{standalone:true}" rows="10"></textarea>
                                                                  </div>
                                                                </div>
                                                              </div>
                    
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                    
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div *ngIf="activeCategoryValueMain==6">
                                        <div>
                                          <div class="container-fluid">
                                            <div class="container-fluid">
                                              <ul class="tabbing-section  tabbing-accordians paddingSales">
                                                <li class="liOrderItem" [ngClass]="activeCategoryTerm==1?'active':''"><a>
                                                    Sales Order : Order Entry Order Terms</a></li>
                    
                    
                    
                                              </ul>
                                            </div>
                                            <div class="row">
                                              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                                <div class="w-100">
                                                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div class="panel panel-default">
                                                      <div *ngIf="activeCategoryTerm==1">
                                                        <div class="header-tabs">
                                                          <h4 class=" common-styling sOrder" style="    margin-left: 1%;">
                                                            Sales Order : Order Entry Order Terms
                                                          </h4>
                                                          <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                                                            href="#seo-collapse">
                                                          </span>
                                                          <span class="edit-buttons orderItem" routerLinkActive="active"
                                                            *ngIf="isSearchPage" (click)="isSearchPage=false"> Create</span>&nbsp;
                                                          <span class="edit-buttons sOrder" routerLinkActive="active"
                                                            (click)="orderTabRefresh()"> Order Items</span>
                                                          <span class="edit-buttons sOrder" routerLinkActive="active"
                                                            (click)="orderTabSalesFinal()">Shipping</span>&nbsp;
                                                          <span class="edit-buttons sOrder">Options</span>&nbsp;
                                                          <span class="edit-buttons sOrder">Order Terms</span>
                                                          <span class="edit-buttons sOrder">Payment </span>&nbsp;
                                                          <span class="edit-buttons sOrder">Parties</span>&nbsp;
                                                          <span class="edit-buttons sOrder">Review Order</span>
                                                          <span class="edit-buttons sOrder" routerLinkActive="active"
                                                            (click)="navigateToPayment()"> Continue</span>
                    
                    
                    
                                                        </div>
                                                        <div class="panel-body">
                                                          <div class="mt-2">
                                                            <div class="">
                                                              <div class="row" style="padding: 14px 28px;">
                                                                <ng-container *ngIf="!isSearchPage;else searchPage">
                                                                  <form class="w-100" [formGroup]="orderTermForm">
                                                                    <div
                                                                      class="all-users-infomation font-13 font-medium own-user-select">
                                                                      <div class="col-lg-4 col-12">
                                                                        <div class="form-group">
                                                                          <label class="common-labels">Term Type
                                                                          </label>
                                                                          <p-dropdown [options]="orderTermType"
                                                                            placeholder="Select Term Type" name="term"
                                                                            formControlName="termTypeId"></p-dropdown>
                                                                        </div>
                                                                      </div>
                                                                      <div class="col-lg-4 col-12">
                                                                        <div class="form-group">
                                                                          <label class="common-labels">Term Value
                                                                          </label>
                                                                          <input type="text" class="form-control"
                                                                            formControlName="termValue" placeholder="Enter Name">
                                                                        </div>
                                                                      </div>
                                                                      <div class="col-lg-4 col-12">
                                                                        <div class="form-group">
                                                                          <label class="common-labels">Term Days
                                                                          </label>
                                                                          <input type="text" class="form-control"
                                                                            formControlName="termDays" placeholder="Enter Term Days">
                                                                        </div>
                                                                      </div>
                                                                      <div class="col-lg-4 col-12">
                                                                        <div class="form-group">
                                                                          <label class="common-labels">Text Value
                                                                          </label>
                                                                          <input type="text" class="form-control"
                                                                            formControlName="textValue" placeholder="Enter Text Value">
                                                                        </div>
                                                                      </div>
                                                                      <div class="col-lg-4 col-12">
                                                                        <div class="form-group">
                                                                          <label style="color: #65787E !important;"
                                                                            for="exampleInputEmail1">Description</label>
                                                                          <div>
                                                                            <textarea name="" id="exampleInputEmail1"
                                                                              class="form-control" cols="30" rows="10"
                                                                              formControlName="description"></textarea>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div class="col-lg-12 col-12 main-submit-button">
                                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                                          (click)="submit()">{{editMode ? 'Update' :'Add'}}</button>
                                                                      </div>
                                                                    </div>
                                                                  </form>
                                                                </ng-container>
                                                                <ng-template #searchPage>
                                                                  <div class="card own-account-table">
                                                                    <p-table [value]="orderTerms" [paginator]="true" [rows]="rows"
                                                                      scrollWidth="100%"
                                                                      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                      [totalRecords]="total" [scrollable]="true"
                                                                      (sortFunction)="customSort($event)" [customSort]="true">
                                                                      <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align">
                                                                              <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                  <div class="ui-helper-hidden-accessible"><input
                                                                                      type="checkbox" name="undefined"
                                                                                      value="undefined">
                                                                                  </div>
                                                                                  <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                  </div>
                                                                                </div>
                                                                                <!---->
                                                                              </p-checkbox>
                                                                            <div style="color: white;">Term Type </div>  <p-sortIcon field="code"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                              value="" autocomplete="off"
                                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                              role="textbox" aria-disabled="false"
                                                                              aria-readonly="false">
                                                                          </th>
                                                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="name"> <div style="color: white;">Term
                                                                            Value</div>
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                              value="" autocomplete="off"
                                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                              role="textbox" aria-disabled="false"
                                                                              aria-readonly="false">
                                                                          </th>
                                                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Term Days</div>
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                              value="" autocomplete="off"
                                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                              role="textbox" aria-disabled="false"
                                                                              aria-readonly="false">
                                                                          </th>
                                                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                            <div style="color: white;">  Text Value</div> <p-sortIcon field="quantity">
                                                                            </p-sortIcon>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                              value="" autocomplete="off"
                                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                              role="textbox" aria-disabled="false"
                                                                              aria-readonly="false">
                                                                          </th>
                                                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                            <div style="color: white;">  Action</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                              value="" autocomplete="off"
                                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                              role="textbox" aria-disabled="false"
                                                                              aria-readonly="false">
                                                                          </th>
                                                                        </tr>
                                                                      </ng-template>
                                                                      <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                          <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                              <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                  <div class="ui-helper-hidden-accessible"><input
                                                                                      type="checkbox" name="undefined"
                                                                                      value="undefined">
                                                                                  </div>
                                                                                  <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                  </div>
                                                                                </div>
                                                                              </p-checkbox>
                                                                              <span
                                                                                 class="account-button" >{{product.termTypeId}}
                                                                              </span>
                                                                            </div>
                                                                          </td>
                                                                          <td [ngStyle]="{'width':'190px'}">{{product.termValue}}</td>
                                                                          <td [ngStyle]="{'width':'190px'}">{{product.termDays}}</td>
                                                                          <td [ngStyle]="{'width':'190px'}">{{product.textValue}}</td>
                                                                          <td [ngStyle]="{'width':'190px'}">{{product.description}}</td>
                                                                          <td [ngStyle]="{'width':'190px'}">
                                                                            <fa-icon [icon]="faEdit"  class="account-button" 
                                                                              (click)="makeOrderTermEditable(product.termIndex)">
                                                                            </fa-icon>
                                                                            <fa-icon [icon]="faTrash" class="text-danger cursor-pointer"
                                                                              (click)="deleteOrderTerm(product.termIndex)"></fa-icon>
                                                                          </td>
                                                                        </tr>
                                                                      </ng-template>
                                                                      <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                          <td>
                                                                            <span class="text-center">
                                                                              <h4 class="ml-2">No Record Found</h4>
                                                                            </span>
                                                                          </td>
                                                                        </tr>
                                                                      </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                      View per page </p>
                                                                  </div>
                                                                </ng-template>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                    
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div *ngIf="activeCategoryValueMain==7">
                                        <div>
                                          <div class="container-fluid">
                                            <div class="container-fluid">
                                              <ul class="tabbing-section  tabbing-accordians paddingSales">
                                                <li class="liOrderItem" [ngClass]="activeCategoryPayment==1?'active':''"><a>
                                                    Sales Order : Order Entry Payment Settings </a></li>
                    
                    
                    
                                              </ul>
                                            </div>
                                            <div class="row">
                                              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                                <div class="w-100">
                                                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div class="panel panel-default">
                                                      <div *ngIf="activeCategoryPayment==1">
                                                        <div class="header-tabs">
                                                          <h4 class=" common-styling sOrder" style="margin-left: 1%;">
                                                            Sales Order : Order Entry Payment Settings
                                                          </h4>
                                                          <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                                                            href="#seo-collapse">
                                                          </span>
                                                          <span class="edit-buttons orderItem" routerLinkActive="active"
                                                            (click)="orderTabRefresh()"> Order Items</span>
                                                          <span class="edit-buttons sOrder" routerLinkActive="active"
                                                            (click)="orderTabSalesFinal()">Shipping</span>&nbsp;
                                                          <span class="edit-buttons sOrder">Options</span>&nbsp;
                                                          <span class="edit-buttons sOrder">Order Terms</span>
                                                          <span class="edit-buttons sOrder">Payment</span>&nbsp;
                                                          <span class="edit-buttons sOrder"> Parties</span>&nbsp;
                                                          <span class="edit-buttons sOrder"> Reviews Order</span>
                    
                    
                    
                    
                                                          <span class="edit-buttons sOrder" routerLinkActive="active"
                                                            (click)="finalizeOrderPayment()">
                                                            Continue</span>
                    
                    
                    
                                                        </div>
                                                        <div class="panel-body">
                                                          <div class="mt-2" style="
                                                          margin-left: 2%;
                                                      ">
                                                            <div class="">
                                                              <h4 class="detail-heading">Select Payment Mehtod
                                                              </h4>
                                                              <div class="radios-sections">
                                                                <ng-template ngFor let-payment [ngForOf]="paymentMethodTypes"
                                                                  let-index="index">
                                                                  <div class="col-lg-9 pl-0 col-12">
                                                                    <div class="form-group  d-flex radio-boxes-wrapper">
                                                                      <input type="radio" [id]="index+'Guaranteed'" name="radio"
                                                                        [value]="payment.paymentMethodTypeId"
                                                                        (change)="setPaymentMethod($event.target.value)">
                                                                      <label class="common-labels"
                                                                        [for]="index+'Guaranteed'">{{payment.paymentMethodType}}</label>
                                                                    </div>
                                                                  </div>
                                                                </ng-template>
                    
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                    
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    
                    
                    
                                    </div>
                                    <br>
                                  
                                    <br>   <br>   <br>
                                   
                                    <br> <br> <br>
                    
                    
                                  </ng-container>
                    
                    
                    
                                  <!-- 2 -->
                                  <ng-container *ngIf="isPurchase">
                                  <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white" style="display: none;">
                                      <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                          <div class="panel panel-default">
                                            <div class="container-fluid">
                                              <ul class="tabbing-section tabbing-accordians">
                    
                                                <li [ngClass]="activeCategoryPurchase==2?'active':''"><a>
                                                    Purchase Order</a></li>
                    
                    
                                              </ul>
                                            </div>
                    
                                            <div id="purchase-order" class="port-body custom-group-wrapper collpase show inner-sections">
                                              <div class="panel-body">
                                                <div class="mt-2">
                                                  <div class="">
                                                    <div class="row">
                                                      <form class="w-100">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                    
                                                          <div class="col-lg-4 col-12">
                                                            <div class="form-group">
                                                              <label class="common-labels">Internal Organization
                                                              </label>
                                                              <p-dropdown filter="true" [options]="organisationsArray" appendTo="body"
                                                                [ngModelOptions]="{standalone: true}"
                                                                [(ngModel)]="createOrderPurchase.billToCustomerPartyId" optionlabel="label"
                                                                placeholder="Select Internal Organization">
                                                              </p-dropdown>
                                                            </div>
                                                          </div>
                                                          <div class="col-lg-4 col-12">
                                                            <div class="form-group">
                                                              <label class="common-labels">User Login ID</label>
                                                              <p-dropdown filter="true" [options]="userLoginArray" appendTo="body"
                                                                [ngModelOptions]="{standalone: true}"
                                                                [(ngModel)]="createOrderPurchase.userLoginId" optionlabel="label"
                                                                placeholder="Select User Login ID">
                                                              </p-dropdown>
                                                            </div>
                                                          </div>
                                                          <div class="col-lg-4 col-12">
                                                            <div class="form-group">
                                                              <label class="common-labels">Distributor</label>
                                                              <p-dropdown filter="true" [options]="supplierArray" appendTo="body"
                                                                [ngModelOptions]="{standalone: true}"
                                                                [(ngModel)]="createOrderPurchase.supplierPartyId" optionlabel="label"
                                                                placeholder="Select Supplier">
                                                              </p-dropdown>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="main-submit-button" style="margin-left: 80%;">
                                                          <button type="submit" (click)="continuePurchaseOrder()"
                                                            class="btn btn-secondary submit-btn">Continue</button>
                    
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                    
                                        </div>
                                      </div>
                                    </div>
                                  </ng-container>
                                  <!-- 2 -->
                    
                                </div>
                    
                              </div>
                            </div>
                          </div>
                        
                        
                        <ngx-spinner></ngx-spinner>
                    </div>
  
                    <div *ngIf="activeCategories==3">
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
  
                <span class="color-black pl-1"> Create-New-Vender</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                <div class=" bg-white color-grey" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">
  
                                            <li [ngClass]="activeCategory==2?'active':''"
                                                ><a>
                                                    General</a></li>
                                            <li [ngClass]="activeCategory==3?'active':''"
                                               ><a>
                                                    Contact</a></li>
                                            <!-- <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                                                    Address</a></li> -->
                                            <li [ngClass]="activeCategory==5?'active':''"
                                               ><a>
                                                    Financial</a></li>
  
  
  
                                        </ul>
                                    </div>
  
                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="createVendor">
                                                    <div *ngIf="activeCategory==2">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Distributor
                                                                            ID<span style="color:red">*</span></label>
                                                                    </div>
                                                                    <!-- <div class="col-lg-3" *ngIf="!show">
                                                                        <p-dropdown formControlName="partyId" (onChange)="getVendorPartyIdListById($event.value)" placeholder="Enter Party ID" filter="true" [options]="listVendorsIntialArray" optionlabel="label">
  
                                                                        </p-dropdown>
                                                                    </div> -->
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown formControlName="partyId"
                                                                            placeholder="Choose Distributor ID" filter="true"
                                                                            [options]="partyIdArray"
                                                                            optionlabel="label">
  
                                                                        </p-dropdown>
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Name
  
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="Name"
                                                                            formControlName="Name" class="form-control"
                                                                            placeholder="Enter Name">
                                                                    </div>
  
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Type of Product
                                                                            or Service Offered
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="type"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Type of Product or Service Offered"
                                                                            class="form-control">
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Distributor Name
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorName"
                                                                            placeholder="Enter Distributor Name"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
  
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Distributor
                                                                            Email
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorEmail"
                                                                            placeholder="Enter Distributor Email"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Company Website
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="CompanyWebsite"
                                                                            id="exampleInputEmail1" placeholder="Enter Company Website "
                                                                            class="form-control">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Distributor is
                                                                            Active
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorisActive"
                                                                            id="exampleInputEmail1" placeholder="Enter Distributor is Active"
                                                                            class="form-control">
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Individual
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="text" formControlName="individual"
                                                                            id="exampleInputEmail1" placeholder="Enter Individual"
                                                                            class="form-control">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Distributor
                                                                            Forms
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorForms"
                                                                            placeholder="Enter Distributor Forms" id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Map
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="Map"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Map"
                                                                            class="form-control">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
  
  
  
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Manifest Company
                                                                            Title
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestCompanyTitle"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Company Title">
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Manifest
                                                                            Policies
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestPolicies"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Policies">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Manifest Company
                                                                            Name
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestCompanyName"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Company Name">
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Manifest Logo
                                                                            Url
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestLogoUrl"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Logo Url">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
  
  
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Phone
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email" class="form-control"
                                                                            formControlName="Phone"
                                                                            placeholder="Enter Phone"
                                                                            id="exampleInputEmail1">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
  
  
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right:11%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(3)"
                                                                class="btn btn-secondary submit-btn">Continue</button>
  
                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategory==3">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
  
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInputP">
                                                                        <label for="exampleInputEmail1">Distributor
                                                                            Phone
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorPhone"
                                                                            placeholder="Enter Distributor Phone"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Distributor Fax
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorFax"
                                                                            placeholder="Enter Distributor Fax"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
  
                            
  
  
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 13%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(2)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(5)"
                                                                class="btn btn-secondary submit-btn">Continue</button>
  
                                                        </div>
                                                    </div>
                                                    <!-- <div *ngIf="activeCategory==4">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInputD">
                                                                        <label for="exampleInputEmail1">Address
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email" disabled [(ngModel)]="this.addressVendor" [ngModelOptions]="{standalone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                                                                    </div>
  
  
  
  
                                                                </div>
                                                            </div>
  
  
  
  
  
  
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right:64%;">
                                                            <button type="submit" (click)="changeactiveCategoryFunction(5)" class="btn btn-secondary submit-btn">Continue</button>
  
                                                        </div>
                                                    </div> -->
                                                    <div *ngIf="activeCategory==5">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
  
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Credit Limit
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="CreditLimit"
                                                                            placeholder="Enter Credit Limit"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Billing Rate
                                                                            Level
  
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="billingRateLevel"
                                                                            id="exampleInputEmail1" class="form-control"
                                                                            placeholder="Enter Billing Rate Level">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Company
  
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
  
                                                                        <input type="email" formControlName="company"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Company"
                                                                            class="form-control">
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Company Tax ID
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="CompanyTaxID"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Company Tax ID"
                                                                            class="form-control">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Distributor
                                                                            Eligible
                                                                           
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Distributor Eligible"
                                                                            formControlName="EligibleFor"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Terms of
                                                                            Agreements
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Terms of Agreements"
                                                                            formControlName="agreement"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
  
  
                                                            <div class="col-lg-12">
                                                                <div class="row">
  
  
                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Default Payables
                                                                            Account
  
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Default Payables Account"
                                                                            formControlName="accountNo"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
  
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Print Check As
  
  
                                                                        </label>
                                                                    </div>
  
                                                                    <div class="col-lg-3">
                                                                        <input type="email" placeholder="Print Check As"
                                                                            formControlName="PrintCheckAs"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
  
  
                                                                </div>
                                                            </div>
  
  
  
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(3)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit" (click)="onSubmit()"
                                                                class="btn btn-secondary submit-btn">Save</button>
  
                                                        </div>
                                                    </div>
  
  
  
                                                </form>
  
                                            </div>
                                        </div>
  
                                    </div>
                                </div>
  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <ngx-spinner></ngx-spinner>