<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"></span>

            </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="editPaycheck">
                                <div class="w3-card-4 classCard">
                                    <div
                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <!-- <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                                            data-target="#exampleModalCenter">Create New Fixed Asset</button>  -->
                                        Edit Paycheck
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Status</label>
                                                </div>

                                                <div class="col-lg-2 dropdowns">
                                                    <input type="email" class="form-control small-input" disabled
                                                        [(ngModel)]="this.PaycheckEdit.StatusItem[0].description" id="exampleInputEmail1"
                                                        name="Amount" formControlName="status"
                                                        aria-describedby="emailHelp">


                                                </div>
                                                <div class="col-lg-3 form-group classInputC">
                                                    <label for="exampleInputEmail1">Paycheck Type</label>
                                                </div>

                                                <div class="col-lg-2 dropdowns">
                                                    <input type="email" class="form-control small-input" disabled
                                                    [(ngModel)]="this.PaycheckEdit.PayChecks.paycheckType" id="exampleInputEmail1"
                                                        formControlName="PaycheckType"
                                                        aria-describedby="emailHelp">


                                                </div>
                                            </div>
                                        </div>

                                      

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Pay to Party
                                                        <span style="color: red;">*</span>
                                                    </label>
                                                </div>

                                                <div class="col-lg-2 dropdowns">
                                                    <input type="email" class="form-control small-input" disabled
                                                    [(ngModel)]="this.PaycheckEdit.PayChecks.partyIdTo" id="exampleInputEmail1"
                                                        name="Amount" formControlName="PaytoParty"
                                                        aria-describedby="emailHelp">

                                                </div>


                                                <div class="col-lg-3 form-group classInputC">
                                                    <label for="exampleInputEmail1">Payment Method</label>
                                                </div>

                                                <div class="col-lg-2 dropdowns">
                                                    <p-dropdown filter="true" formControlName="PaymentMethod"
                                                    [(ngModel)]="this.PaycheckEdit.PayChecks.paymentMethodId"
                                                        [options]="PaymentMethodIDArray" name="PaymentMethod"
                                                        optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>

                                      


                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Gross Amount
                                                        <span style="color: red;">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control small-input"
                                                    [(ngModel)]="this.PaycheckEdit.PayChecks.amount"
                                                        id="exampleInputEmail1" name="Amount" formControlName="Amount"
                                                        aria-describedby="emailHelp">


                                                </div>

                                                <div class="col-lg-2 dropdowns">
                                                    <p-dropdown filter="true" name="GrossAmount" optionlabel="label"
                                                        formControlName="GrossAmount" [options]="currencyArray"
                                                        [(ngModel)]="this.PaycheckEdit.PayChecks.currencyUomId">

                                                    </p-dropdown>
                                                </div>


                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Effective Date
                                                        <span style="color: red;">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control small-input"
                                                        id="exampleInputEmail1" name="EffectiveDate"
                                                        [(ngModel)]="this.PaycheckEdit.effectiveDate"
                                                        formControlName="EffectiveDate" aria-describedby="emailHelp">


                                                </div>
                                                <div class="col-lg-1 time">
                                                    <p-dropdown filter="true" [options]="hours" name="hour"
                                                        optionlabel="label" formControlName="hour"
                                                        > :

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-1 time">
                                                    <p-dropdown filter="true" [options]="minute" name="minute"
                                                        optionlabel="label" formControlName="minute">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-1 time">
                                                    <p-dropdown filter="true" formControlName="amPm" [options]="amPM"
                                                        name="amPm" optionlabel="label">

                                                    </p-dropdown>
                                                </div>






                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Comments</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control small-input"
                                                        id="exampleInputEmail1" name="Comments"
                                                        [(ngModel)]="this.PaycheckEdit.PayChecks.comments"
                                                        formControlName="Comments" aria-describedby="emailHelp">


                                                </div>
                                                <div class="col-lg-3 form-group classInputC">
                                                    <label for="exampleInputEmail1">Check/Ref Num</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control small-input"
                                                    [(ngModel)]="this.PaycheckEdit.PayChecks.paymentRefNum"
                                                        id="exampleInputEmail1" name="RefNum" formControlName="RefNum"
                                                        aria-describedby="emailHelp">
                                                </div>



                                            </div>
                                        </div>

                                       

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Number of Days</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control small-input"
                                                        id="exampleInputEmail1" name="numberDays" formControlName="numberDays"
                                                        aria-describedby="emailHelp">
                                                </div>

                                                <div class="col-lg-3 form-group classInputC">
                                                    <label for="exampleInputEmail1">Number of Hours</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control small-input"
                                                        id="exampleInputEmail1" name="numberHours" formControlName="numberHours"
                                                        aria-describedby="emailHelp">
                                                </div>



                                            </div>
                                        </div>

                                       

                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button"
                                        style="    margin-left: 56%;height: 21px;font-size: 12px; width: 172px;">
                                        <button type="submit" class="btn btn-secondary submit-btn" 
                                             (click)="onSubmit()">Edit
                                            PayCheck</button>&nbsp;

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
</div>