import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fsm-customers',
  templateUrl: './fsm-customers.component.html',
  styleUrls: ['./fsm-customers.component.css']
})
export class FsmCustomersComponent implements OnInit {

  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) { }

  ngOnInit(): void {
  }

  toNotifications()
  {
    this._Router.navigate(['/fsm/fsmSetting/customer-notifications'])
  }

  toNewWorkOrder()
  {
    this._Router.navigate(['fsm/newWorkOrder'])
  }

  toCustomer()
  {
    this._Router.navigate(['fsm/customer'])
  }

  toReports()
  {
    this._Router.navigate(['/fsm/reports'])
  }

  toInvoices()
  {
    this._Router.navigate(['/fsm/invoices'])

  }

  toWorkOrder()
  {
    this._Router.navigate(['/fsm/main'])
  }

  toPeople()
  {
    this._Router.navigate(['/fsm/people'])
  }

  toTimeSheet()
  {
    this._Router.navigate(['/fsm/timesheet'])
  }

  toSetting()
  {
    this._Router.navigate(['/fsm/fsmSetting/work-order'])
  }


}
