import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-app-content',
  templateUrl: './app-content.component.html',
  styleUrls: ['./app-content.component.css']
})
export class AppContentComponent implements OnInit {

  public token : any;

  constructor(public cRef: ChangeDetectorRef) {}


  ngOnInit(): void {
    $('.navbar-toggler').click(function(){
      $('.main-container-wrapper').css('background-color","rgba(0,0,0,0.5)');
      $('.bg-white.color-grey ').css('opacity","0.3');
      $('.port-header-contact').css('opacity","0.3');
      $('.app-sidebar').toggle();
    });
    this.token = localStorage.getItem('token');
    this.cRef.detectChanges();
   
    }

}
