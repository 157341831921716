<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Timesheets</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>

            </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords">Find Timesheets</span>
                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2"  data-toggle="modal" data-target="#exampleModalCenter">Create New Timesheet</button>

                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 66%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>	
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Timesheet ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.timesheetIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="timesheetIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.timesheetId" name="timesheetId" [ngModelOptions]="{standlone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Timesheet ID">
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1"> Employee ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.partyIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="partyIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.partyId" name="partyId" [ngModelOptions]="{standlone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter  Employee ID">
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Approver Party ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.clientPartyIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="clientPartyIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.clientPartyId" name="clientPartyId" [ngModelOptions]="{standlone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Approver Party ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Approved By User Login ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.approvedByUserIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="approvedByUserIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.approvedByUserId" name="approvedByUserId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" placeholder="Enter Approved By User Login ID">
                                                                </div>
                
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">From Date</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.fromDateFromSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="fromDateFromSearchType" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" name="fromDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" [(ngModel)]="advanceSearch.fromDateFrom" >
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.fromDateToSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="fromDateToSearchType" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" name="fromDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" [(ngModel)]="advanceSearch.fromDateTo" >
                                                                </div>
                
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Through Date</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.thruDateFromSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="thruDateFromSearchType" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" name="throughDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" [(ngModel)]="advanceSearch.throughDateFrom" >
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.thruDateToSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="thruDateToSearchType" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" name="throughDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" [(ngModel)]="advanceSearch.throughDateTo" >
                                                                </div>
                
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Status ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.statusIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="statusIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.statusId" name="statusId" [ngModelOptions]="{standlone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Status ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Comments</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.commentsSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="commentsSearchType" [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.comments" name="comments" [ngModelOptions]="{standlone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                                </div>
                
                                                               
                
                
                                                            </div>
                                                        </div>
                
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button" style="
                                                        margin-left: -28%;"><button _ngcontent-cta-c128="" (click)="advanceSearchContacts(false)" type="submit" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset();">Reset</button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <!-- <div class="w3-card-4 classCard">
                                    <div class="port-header-contact create-lead font-medium mb-0" style="    background: #F5F7FA!important;
                                    margin-top: 0%;">
                                        <div class="suppliers-wrapper" style="    margin-top: 11px;">
                                            <p class="h-text">Find Timesheets</p>
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" style="margin-left:150px" data-toggle="modal" data-target="#exampleModalCenter">Create New Timesheet</button>
                                            
                                        </div>
                                    </div>

                                    <br>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Timesheet ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.timesheetIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="timesheetIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.timesheetId" name="timesheetId" [ngModelOptions]="{standlone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Timesheet ID">
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1"> Employee ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.partyIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="partyIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.partyId" name="partyId" [ngModelOptions]="{standlone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter  Employee ID">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Approver Party ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.clientPartyIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="clientPartyIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.clientPartyId" name="clientPartyId" [ngModelOptions]="{standlone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Approver Party ID">
                                                </div>
                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Approved By User Login ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.approvedByUserIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="approvedByUserIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.approvedByUserId" name="approvedByUserId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" placeholder="Enter Approved By User Login ID">
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">From Date</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.fromDateFromSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="fromDateFromSearchType" [options]="dateIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" name="fromDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" [(ngModel)]="advanceSearch.fromDateFrom" >
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.fromDateToSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="fromDateToSearchType" [options]="dateIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" name="fromDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" [(ngModel)]="advanceSearch.fromDateTo" >
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Through Date</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.thruDateFromSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="thruDateFromSearchType" [options]="dateIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" name="throughDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" [(ngModel)]="advanceSearch.throughDateFrom" >
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.thruDateToSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="thruDateToSearchType" [options]="dateIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" name="throughDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" [(ngModel)]="advanceSearch.throughDateTo" >
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Status ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.statusIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="statusIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.statusId" name="statusId" [ngModelOptions]="{standlone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Status ID">
                                                </div>
                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Comments</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.commentsSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="commentsSearchType" [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.comments" name="comments" [ngModelOptions]="{standlone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                </div>

                                               


                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button" style="
                                        margin-left: -28%;"><button _ngcontent-cta-c128="" (click)="advanceSearchContacts(false)" type="submit" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                        <button type="button" class="btn btn-danger ml-2" (click)="reset();">Reset</button>
                                    </div>
                                </div> -->

                                <br>
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Timesheets List
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="timeSheetList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th pSortableColumn="code" [ngStyle]="{'width':'150px'}">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        Timesheet ID
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="name" [ngStyle]="{'width':'150px'}">
                                                                    <div style="color: white;"> Employee ID
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'150px'}">
                                                                    <div style="color: white;"> Approver Party ID
                                                                        <p-sortIcon field="category"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="quantity" [ngStyle]="{'width':'150px'}">
                                                                    <div style="color: white;"> From Date
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'150px'}">
                                                                    <div style="color: white;"> Through Date
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'150px'}">
                                                                    <div style="color: white;"> Status ID
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>

                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Approved By User Login ID</div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Comments</div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Created Date</div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <!-- <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                <div style="color: white;">   Action</div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th> -->
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'150px','text-decoration':'none'}" routerLinkActive="active" (click)="employeeDetail(product.timesheetId)">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span class="account-button">    {{product.timesheetId}}</span>
                                                                    
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'150px'}">{{product.partyId}}</td>
                                                                <td [ngStyle]="{'width':'150px'}">{{product.clientPartyId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'150px'}">
                                                                    {{product.fromDate | date :"yyyy-MM-dd HH:mm:ss"}}</td>
                                                                <td [ngStyle]="{'width':'150px'}">
                                                                    {{product.thruDate | date :"yyyy-MM-dd HH:mm:ss"}}</td>
                                                                <td [ngStyle]="{'width':'150px'}">{{product.statusId}}</td>
                                                                <td [ngStyle]="{'width':'150px'}">{{product.partyId}}</td>
                                                                <td [ngStyle]="{'width':'150px'}">{{product.comments}}</td>
                                                                <td [ngStyle]="{'width':'150px'}">
                                                                    {{product.createdStamp |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                </td>
                                                                <!-- <td [ngStyle]="{'width':'150px'}">
                                                                <div>
                                                                    <svg class="hover"
                                                                        (click)="onUpdate(product.timesheetId)"
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>

                                                                </div>
                                                            </td> -->
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                    <p class="paginate_data">
                                                        View per page</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add New Timesheet</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width:100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                Create New Employee Timesheet</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="createTimesheetFormService.create_timesheet_form" [formGroup]="createTimesheetFormService.create_timesheet_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Employee ID <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" placeholder="Enter Employee ID" formControlName="partyId" [options]="partyIdArray" optionlabel="label"></p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createTimesheetFormService.create_timesheet_form.controls.partyId.invalid && !!createTimesheetFormService.create_timesheet_form.controls.partyId.touched"> Employee Id   is required!</small>



                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Approver Party </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="clientPartyId" [options]="partyIdArray" optionlabel="label" placeholder="Enter Approver Party"></p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="From Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Through Date">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="statusId" [options]="statusArray" optionlabel="label" placeholder="Enter Status"></p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createTimesheetFormService.create_timesheet_form.controls.statusId.invalid && !!createTimesheetFormService.create_timesheet_form.controls.statusId.touched">Status  is required!</small>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Comments </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Approved By User Login ID </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="approvedByUserLoginId" [options]="userLoginArray" optionlabel="label" placeholder="Approved by user login Id"></p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 7%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                            <!-- <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button> -->

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>