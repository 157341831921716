<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Config Item For: </span>

               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Config Item
                                </a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a> Config Options</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                <a>Content</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                <a>Product</a>
                            </li>

                        </ul>
                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2 mt-2" style="margin: 0;" data-toggle="modal" data-target="#newConfigItem_Popup"> [New Config Item]</button>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==1" class="w-100">
                                            <h4 class="ml-2" style="font-weight:500;">Config Item For:[ID: {{this.configItemId}} ]</h4>

                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                    <p class="h-text">Edit Config Item</p>
                                                    <span>
                                                       
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#updateConfigItemPopup">Update</button>

                                                    </span>
                                                </div>
                                                <form>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Config Item</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.editProductConfigItem">
                                                                    <span>{{this.editProductConfigItem.configItemId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Config Item Type</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.editProductConfigItem">
                                                                    <span>{{this.editProductConfigItem.configItemTypeId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Config Item Name </p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.editProductConfigItem">
                                                                    <span>{{this.editProductConfigItem.configItemName}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Description</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.editProductConfigItem">
                                                                    <span>{{this.editProductConfigItem.description}}</span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==2" class="w-100 mt-2">
                                            <h4 class="ml-2" style="font-weight:500;">Config Options For:[ID: {{this.configItemId}} ]</h4>
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                    <p class="h-text">Config Option List </p>
                                                    <span>
                                                     
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                        (click)="resetConfigOption()"   data-target="#configOptions">Create New Config Option </button>

                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="productConfigOption" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Config Option
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Description
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Sequence Num
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Remove
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button"  >
                                                                                        {{product.configOptionId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.fromDate | date :'yyyy-MM-dd hh:mm:ss'}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.thruDate | date :'yyyy-MM-dd hh:mm:ss'}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.description}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.sequenceNum}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="submit" (click)="updateConfigOption(product)" data-toggle="modal" data-target="#configOptions" class="btn btn-secondary submit-btn ml-2" style="margin: 0;">Update</button>
                                                                                <button *ngIf="!this.Hidebtn" type="submit" (click)="deleteProductConfigOption(product)" class="btn btn-secondary submit-btn ml-2" style="margin: 0;">Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w3-card-4 classCard w-100 mt-2" *ngIf="this.showComponentTable">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                    <p class="h-text">Components </p>
                                                    <span>
                                                     
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal" (click)="resetConfigComponent()"
                                                            data-target="#productComponentPopup">Create New Component</button>

                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="productConfigProductById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Product ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Quantity
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Sequence Num
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Remove
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button"  >
                                                                                        {{product.productId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.quantity}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.sequenceNum}}

                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="submit" (click)="updateComponent(product)" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#productComponentPopup" style="margin: 0;">Update</button>
                                                                                <button *ngIf="!this.Hidebtn" type="submit" (click)="deleteProductConfigProduct(product)" class="btn btn-secondary submit-btn ml-2" style="margin: 0;">Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==3" class="w-100">
                                            <h4 class="ml-2" style="font-weight:500;">Content For:[ID: {{this.configItemId}} ]</h4>
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                    <p class="h-text"> Content For: {{this.configItemId}} </p>
                                                    <span>
                                                   
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#itemContentPopUp">Create New Product</button> 
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#AddContentPopUp">Add Content</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#OverrideContentPopUp">Override Fields</button>

                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="simpleTextContent" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Content
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Type
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span (click)="editProductContent(product)" 
                                                                                     data-target="#updateSimpleTextPopup"  data-toggle="modal"
                                                                                       class="account-button"  >
                                                                                        {{product.contentId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.confItemContentTypeId}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.fromDate | date:'yyyy-dd-mm hh:mm:ss'}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.thruDate | date:'yyyy-dd-mm hh:mm:ss'}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" 
                                                                              data-target="#updateSimpleTextPopup"  data-toggle="modal" (click)="editProductContent(product)">Update</button>

                                                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                                                                                 (click)="removeContentFromProductConfigItem(product)">Delete</button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==4" class="w-100">
                                            <h4 class="ml-2" style="font-weight:500;"> For:[ID: {{this.configItemId}} ]</h4>
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                    <p class="h-text"> For: {{this.configItemId}} </p>


                                                </div>
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="productTableData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" id="none" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Product ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" id="none" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Name
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" id="none" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Pieces Included
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button"  

                                                                                    routerLink="/facilities/catalog/products/detail-product-summary/{{product.productData.productId}}"  
                                                                                    [queryParams]="{configRedirect:this.configRedirect}" >
                                                                                        {{product.productData.productId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.productData.productName}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.productData.piecesIncluded}}

                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade updatePopUP" id="updateConfigItemPopup" tabindex="-1" role="dialog" aria-labelledby="updateConfigItemPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Config Item</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                Edit Config Item</li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form [formGroup]="updateconfigForm" class="w-100">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 col-3 classInput ">
                                                            <label for="exampleInputEmail1">Config Item </label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            {{configItemId}}
                                                        </div>
                                                        <div class="col-lg-3 form-group rightForm1">
                                                            <label for="exampleInputEmail1">Config Item Type
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            <p-dropdown placeholder="Enter Config Item Type" filter="true" [options]="dropdownValue" optionlabel="label" formControlName="ConfigItemType">

                                                            </p-dropdown>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 col-3 classInput">
                                                            <label for="exampleInputEmail1">Config Item Name</label>
                                                        </div>
                                                        <div class="col-lg-3 col-3">
                                                            <input type="email" class="form-control" formControlName="ConfigItemName" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                        </div>
                                                        <div class="col-lg-3 col-3 form-group  rightForm1">
                                                            <label for="exampleInputEmail1">Description</label>
                                                        </div>
                                                        <div class="col-lg-3 col-3">
                                                            <input type="email" class="form-control" formControlName="Description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="main-submit-button" style="margin-right: 13%;">
                                                <button type="submit" class="btn btn-secondary  submit-btn ml-3" (click)="updateProductConfigItem()">Update</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="newConfigItem_Popup" tabindex="-1" role="dialog" aria-labelledby="newConfigItem_Popup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> New Config Item</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                New Config Item</li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form [formGroup]="configForm" class="w-100">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm1">
                                                            <label for="exampleInputEmail1">Config Item Type
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            <p-dropdown placeholder="Select Config Item Type" filter="true" 
                                                            [options]="dropdownValue" optionlabel="label" formControlName="ConfigItemType">

                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-3 col-3  classInput__new_changes">
                                                            <label for="exampleInputEmail1">Config Item Name</label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            <input type="email" class="form-control" formControlName="ConfigItemName" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Config Item Name">
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 col-3 form-group  rightForm1">
                                                            <label for="exampleInputEmail1">Description</label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            <input type="email" class="form-control" formControlName="Description" 
                                                            id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="main-submit-button" style="margin-right: 14%;">
                                                <button type="submit" class="btn btn-secondary  submit-btn ml-3" (click)="createProductConfigItem()">Create</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="AddContentPopUp"  tabindex="-1" role="dialog" aria-labelledby="AddContentPopUp" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Content to Product Config Item
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                Add Content to Product Config Item
                            </li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form [formGroup]="addContentForm" class="w-100">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">Content ID

                                                            </label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            <p-dropdown placeholder="Select Content ID" filter="true" [options]="ContentIdIDArray" 
                                                            optionlabel="label" formControlName="ContentID">

                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Product Config Item Content
                                                                Type ID
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            <p-dropdown placeholder="Select Config Item Type" filter="true" 
                                                            [options]="productConfigTypeIDArray" optionlabel="label" formControlName="ProductConfigItemContentTypeID">

                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 col-3 form-group  rightForm">
                                                            <label for="exampleInputEmail1">From Date</label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            <input type="date" class="form-control" formControlName="fromdate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                        </div>
                                                        <div class="col-lg-3 col-3 form-group  classInput">
                                                            <label for="exampleInputEmail1">Through Date</label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            <input type="date" class="form-control" formControlName="throughdate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="main-submit-button" style="margin-right: 15%;">
                                                <button type="submit" class="btn btn-secondary  submit-btn ml-3" 
                                                (click)="addContentToProductConfigItem()">Add </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="itemContentPopUp" tabindex="-1" role="dialog" aria-labelledby="itemContentPopUp" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Product Config Item Content</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                Create New Product Config Item Content</li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form [formGroup]="contentForm" class="w-100">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Product Config Item Content
                                                                Type ID
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            <p-dropdown placeholder="Select Config Item Type" filter="true" [options]="productConfigTypeIDArray" optionlabel="label" formControlName="ProductConfigItemContentTypeID">

                                                            </p-dropdown>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div class="main-submit-button" style="margin-right: 51%;">
                                                <button type="submit" class="btn btn-secondary  submit-btn ml-3"
                                                 (click)="prepareAddContentToProductConfigItem()">Prepare Create</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="OverrideContentPopUp" tabindex="-1" role="dialog" aria-labelledby="OverrideContentPopUp" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Override Simple Fields

                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                Edit Override Simple Fields
                            </li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form [formGroup]="overrideForm" class="w-100">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm">
                                                            <label for="exampleInputEmail1">Description

                                                            </label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">

                                                            <input type="email" class="form-control" formControlName="Description" 
                                                            id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description ">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Long Description	
                                                              
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">

                                                            <input type="email" class="form-control" formControlName="longDescription" 
                                                            id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Long Description">
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 col-3 form-group  rightForm">
                                                            <label for="exampleInputEmail1">Small Image</label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            <input type="email" class="form-control" formControlName="smallImage" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                             placeholder="Enter Small Image">
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div class="main-submit-button" style="margin-right: 15%;">
                                                <button type="submit" class="btn btn-secondary  submit-btn ml-3" (click)="updateProductConfigItemContent()">Update </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="UploadImagePopUp" tabindex="-1" role="dialog" aria-labelledby="UploadImagePopUp" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Upload Image

                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                Upload Image
                            </li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form [formGroup]="uploadImageForm" class="w-100">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 col-3 form-group  classInput">
                                                            <label for="exampleInputEmail1">Image</label>
                                                        </div>
                                                        <div class="col-lg-3 col-3 ">
                                                            <input type="file" class="form-control" formControlName="image" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Choose Image">
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div class="main-submit-button" style="margin-right: 1%;">
                                                <button type="submit" class="btn btn-secondary  submit-btn ml-3" (click)="UploadProductConfigItemImage()">Upload Image </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="configOptions" tabindex="-1" role="dialog" aria-labelledby="configOptions" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Config Options</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Create New Config Options</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="configOptionForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="FromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date	">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="ThroughDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date ">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Config Option Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" formControlName="ConfigOptionName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Config Option Name	">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" formControlName="Description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description ">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" formControlName="SequenceNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num	">
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!this.showProductOptions" (click)="createProductConfigOption()">Create</button>
                                            <button type="submit" class="btn btn-secondary submit-btn" *ngIf="this.showProductOptions" (click)="updateProductConfigOption()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="productComponentPopup" tabindex="-1" role="dialog" aria-labelledby="productComponentPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Component</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Component</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="ProductComponentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product ID	

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" *ngIf="!this.showComponent" formControlName="productID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product ID">

                                                        <input type="email" class="form-control" disabled *ngIf="this.showComponent" formControlName="productID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="Quantity" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity ">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" formControlName="SequenceNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num	">
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!this.showComponent" (click)="createProductConfigProduct()">Create</button>
                                            <button type="submit" class="btn btn-secondary submit-btn" *ngIf="this.showComponent" (click)="updateProductConfigProduct()">
                                            Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="updateSimpleTextPopup" tabindex="-1" role="dialog" aria-labelledby="updateSimpleTextPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Simple Text</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton13>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Simple Text</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="TextContentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control"
                                                            formControlName="ThroughDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Product Description		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control"
                                                            formControlName="ProductDescription" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Product Description">
                                                    </div>
                                                   
               
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Locale String			
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control"
                                                            formControlName="LocaleString" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Locale String">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Text		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control"
                                                            formControlName="Text" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control"  
                                                            formControlName="FromDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date	">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA"  >
                                                        <label for="exampleInputEmail1">Content ID		
            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"  >
                                                        <input type="email" class="form-control" disabled
                                                            formControlName="ContentID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Content ID	">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                           
                                              <button type="submit" class="btn btn-secondary submit-btn" 
                                              (click)="updateSimpleTextContentForProductConfigItem()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>