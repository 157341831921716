import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { WarehouseService } from '../../warehouse/warehouse.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-create-new-inventory-item',
    templateUrl: './create-new-inventory-item.component.html'
})
export class CreateNewInventoryItemComponent implements OnInit {
    total=0;
    activeCategory = 2;
    rowNumber=0;
    pageSize = 10;
    rows = 50;
    inventoryItemTypeIds: any[];
    inventoryItemForm: FormGroup;
    editMode: boolean;
    currencyIds: any[];
    products: any[];
    partyIds: any[];
    statusIds: any[];
    facilityIds: any[];
    facilityLocations: any[];
    facilityId: string;
    inventoryItemId: string;
    inventoryItem: any;
    physicalVarianceInventoryForm: FormGroup;
    varianceReasons: any[];
    physicalVarianceInventories: any[];
    inventoryReservations: any[];
    url: string;
    constructor(
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router,
        readonly _FormBuilder: FormBuilder,
        readonly _WarehouseService: WarehouseService,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _MainCatalogService: MainCatalogService,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute
    ) {
        this.url='/facilities/warehouse/detailed-summary-facilities'
        this.inventoryItemForm = this._FormBuilder.group({
            accountingQuantityTotal: [''],
            activationNumber: [''],
            activationValidThru: [new Date()],
            binNumber: [''],
            comments: [''],
            containerId: [''],
            currencyUomId: [null],
            datetimeManufactured: [new Date()],
            datetimeReceived: [new Date()],
            expireDate: [new Date()],
            facilityId: [null],
            fixedAssetId: [''],
            inventoryItemTypeId: [null],
            locationSeqId: [null],
            lotId: [''],
            ownerPartyId: [null],
            partyId: [null],
            productId: [null, [Validators.required]],
            serialNumber: [''],
            softIdentifier: [''],
            statusId: [null],
            unitCost: [''],
            uomId: ['']
        });
        this.inventoryReservations = [];
        this.editMode = false;
        this.inventoryItemTypeIds = [];
        this.currencyIds = [];
        this.products = [];
        this.partyIds = [];
        this.statusIds = [];
        this.facilityIds = [];
        this.facilityLocations = [];
        this.facilityId = '';
        this.inventoryItemId = '';
        this.constructorExt();
    }
    constructorExt(): void {
        this.physicalVarianceInventoryForm = this._FormBuilder.group({
            availableToPromiseVar: [''],
            comments: [''],
            quantityOnHandVar: [''],
            varianceReasonId: [null]
        });
        this.varianceReasons = [];
        this.physicalVarianceInventories = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.facilityId = this._ActivatedRoute.snapshot.queryParams.facilityId;
        this.inventoryItemId = this._ActivatedRoute.snapshot.queryParams.inventoryItemId;
        if (this._ActivatedRoute.snapshot.queryParams.productId) {
            this.inventoryItemForm.patchValue({
                productId: this._ActivatedRoute.snapshot.queryParams.productId
            });
        }
        if (this.inventoryItemId) {
            this.editMode = true;
            this.getInventoryById();
        }
        this.getInventoryTypeIds();
        this.getCurrency();
        this.getProducts();
        this.getPartyIds();
        this.getStatusIds();
        this.getFacilityIds();
        this.getFacilityLocation();
        this.getVarianceReason();
        this.getPhysicalVariance();
        this.getInventoryReservations();
    }
    getInventoryReservations(): void {
        this.spinner.show();
        this._WarehouseService.getInventoryReservation(this.inventoryItemId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.inventoryReservations = data.data;
                }
            });
           
    }

    getVarianceReason(): void {
        this.spinner.show();
        this._WarehouseService.getVarianceReasonId()
            .then(data => {
                this.spinner.hide();
                this.varianceReasons = data.data;
            });
           
    }
    getInventoryById(): void {
        this.spinner.show();
        this._WarehouseService.getInventoryItemById(this.inventoryItemId)
            .then(data => {
                this.inventoryItem = data.data;
                this.spinner.hide();
                this.inventoryItemForm.patchValue({
                    ...data.data, ...{
                        activationValidThru: data.data.activationValidThru ? new Date(data.data.activationValidThru) : '',
                        datetimeManufactured: data.data.datetimeManufactured ? new Date(data.data.datetimeManufactured) : '',
                        datetimeReceived: data.data.datetimeReceived ? new Date(data.data.datetimeReceived) : '',
                        expireDate: data.data.expireDate ? new Date(data.data.expireDate) : '',
                    }
                });
            });
          
    }
    getCurrency(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.currencyIds = data.data.currencyList;
                }
            });
           
    }
    getInventoryTypeIds(): void {
        this.spinner.show();
        this._WarehouseService.defaultInventory()
            .then(data => {
                this.spinner.hide();
                this.inventoryItemTypeIds = data.data;
            });
          
    }
    getProducts(): void {
        this.spinner.show();
        this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
            .then(data => {
                this.spinner.hide();
                this.products = data.data;
            });
           
    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups;
            });
           
    }
    getStatusIds(): void {
        this.spinner.show();
        this._WarehouseService.inventoryStatusId()
            .then(data => {
                this.spinner.hide();
                this.statusIds = data.data;
            });
          
    }
    getFacilityIds(): void {
        this.spinner.show();
        this._WarehouseService.getFacility()
            .then(data => {
                this.spinner.hide();
                this.facilityIds = data.data;
            });
           
    }

    getFacilityLocation(): void {
        this.spinner.show();
        this._WarehouseService.getFacilityLocations(this.facilityId, { pageNo: 1, pageSize: 200 }, {
            locationSeqId: '',
            aisle: '',
            area: '',
            level: '',
            position: '',
            section: ''
        })
            .then(data => {
                this.facilityLocations = data.data;
                this.spinner.hide();
            });
            
    }
    update(): void {
        this.spinner.show();
        if (this.inventoryItemForm.valid) {
            this._WarehouseService.updateInventoryItem(this.inventoryItemId, {
                ...this.inventoryItem, ...this.inventoryItemForm.value, ...{
                    activationValidThru: this.inventoryItemForm.value.activationValidThru ? moment(this.inventoryItemForm.value.activationValidThru).format('YYYY-MM-DD HH:MM:ss') : '',
                    datetimeManufactured: this.inventoryItemForm.value.datetimeManufactuate ? moment(this.inventoryItemForm.value.datetimeManufactuate).format('YYYY-MM-DD HH:MM:ss') : '',
                    datetimeReceived: this.inventoryItemForm.value.datetimeReceiate ? moment(this.inventoryItemForm.value.datetimeReceiate).format('YYYY-MM-DD HH:MM:ss') : '',
                    expireDate: this.inventoryItemForm.value.expireDate ? moment(this.inventoryItemForm.value.expireDate).format('YYYY-MM-DD HH:MM:ss') : ''
                }
            })
                .then(data => {
                    if (data.success) {

                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                        this.inventoryItemForm.reset();
                    }
                });
        }
       
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
            this.spinner.hide();
        } else {
            if (this.inventoryItemForm.valid) {
                this._WarehouseService.createInventoryItem({
                    ...this.inventoryItemForm.value, ...{
                        activationValidThru: moment(this.inventoryItemForm.value.activationValidThru).format('YYYY-MM-DD HH:MM:ss'),
                        datetimeManufactured: moment(this.inventoryItemForm.value.datetimeManufactuate).format('YYYY-MM-DD HH:MM:ss'),
                        datetimeReceived: moment(this.inventoryItemForm.value.datetimeReceiate).format('YYYY-MM-DD HH:MM:ss'),
                        expireDate: moment(this.inventoryItemForm.value.expireDate).format('YYYY-MM-DD HH:MM:ss')
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.spinner.hide();
                            this.inventoryItemForm.reset();
                            this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                        }
                    });
            }
        }
       
    }

    cancel(data) {
        this.facilityId = data;
        this.inventoryItemForm.reset();
        this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
    }


    createPhysicalVariance(): void {
        this.spinner.show();
        this._WarehouseService.createPhysicalVariance(this.inventoryItemId, this.physicalVarianceInventoryForm.value)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Added');
                    this.spinner.hide();
                    this.physicalVarianceInventoryForm.reset();
                    this.getPhysicalVariance();
                }
            });
           
    }
    getPhysicalVariance(): void {
        this.spinner.show();
        this._WarehouseService.getPhysicalVarianceByInventoryItemId(this.inventoryItemId)
            .then(data => {
                if (data.success) {
                    this.physicalVarianceInventories = data.data;
                    this.spinner.hide();
                }
            });
           
    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    activeCategoryTab(tab: number): void {
        this.activeCategory = tab;
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}
