import { Component, OnInit, ViewChild } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';
import { FinancialService } from 'src/app/services/financial.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2';
import { CustomerAgreementsService } from 'src/app/accounting/accounting-ar/customer-agreements/customer-agreements.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-fsm-contract',
  templateUrl: './fsm-contract.component.html',
  styleUrls: ['./fsm-contract.component.css']
})
export class FsmContractComponent implements OnInit {
  isShown = false; 
  @ViewChild('closebutton') closebutton;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  CustomerAgreements: FormGroup;
  PersonsAndPartyGroupsList: any = [];
  agreementType: string;
  productList: any = [];
  AgreementTypeList: any = [];
  RoleTypesList: any = [];
  editMode: boolean;
  opportunityIdArray: any = [];
  fromDateType: any = [];
  toDateType: any = [];
  agreementList: any = [];
  activeCategory=2;
  createCustomerAgreements: FormGroup;
  activeCategoryMainTs=1;
  activeCategoryA = 1;
  agreementDate: any;
  thruDate: any;
  fromDate: any;
  AgreementTypeListArray: any[]=[];
  PersonsAndPartyGroupsListArray: any[]=[];
  productListArray: any[]=[];

// Start Code RA
  employeeList: any;
  Department: any;
  DepartmentArray: any[] = [];
  EmployeeArray: any[] = [];
  Employee: any;
  JobPosition: any;
  JobPositionArray: any[] = [];
  ContractTypeArray: any[] = [];
  ContractType: any;
  Company: any;
  CompanyArray: any[] = [];
  abc: any;
  SalaryStructure: any;
  SalaryStructureArray: any[] = [];
  ResponsibleArray: any[] = [];
  Responsible: any;
  AnalyticArray: any[] = [];
  Analytic: any;
  NewDocumentTemplate: any;
  newContractDocumentTemplateArray: any[] = [];
  ContractUpdateDocument: any;
  ContractUpdateDocumentArray: any[] = [];
  ContractTemplateArray: any[] = [];
  ContractTemplate: any;
  contractId = "";
  StartDate: string;
  end_date: string;
  contractorDetailsForm: FormGroup;
  contractDetailsId = " ";
  WorkingScheduleArray: any[] = [];
  WorkingSchedule: any;
  WorkingScheduleTable: any;
  workingHoursTable: any;
  workingScheduleId: any;
  bsModalRef: any;
  DayPeriod: any;
  DayPeriodArray: any[] = [];
  DayOfWeek: any;
  DayOfWeekArray: any[] = [];
  workEntryArray: any[] = [];
  workEntry: any;
  workingHoursId: any;
  workingSchedule: FormGroup;
  workingscheduleId = "";
  workingHours: FormGroup;
  actualendDate: string;
  actualstartDate: string;
  WorkSchedule: any;
  workingScheduleGet: FormGroup;
  totalworkingHoursId: any;
  createworkingHoursId= " ";
  createWorkingHour: FormGroup;
  totalHours: any;
  TotalHours: any;
  contractorForm: FormGroup;
  WorkingScheduleForm: FormGroup;
  findByName: { partnerID: string; partnerName: string; };
  findByPhone: { phone1: string; phone2: string; phone3: string; };
  findByemail: { email: string; };
  createPartner: any;
  EmailPartnerList: any;
  PhonePartnerList: any;
  NamePartnerList: any;
  AdvancePartnerList: any;
  countryId: any;
  stateArray: any=[];
  statesArray: any=[];
  stateData: any;
  array: any = [];
  currencyList: any[];
  state: any;
  industryList: any;
  industryListArray: any[]=[];
  ownerShip: any;
  ownerShipArray: any[]=[];
  classificationBookmarkPartnerArray: any[]=[];
  classificationBookmarkPartner: any;
  findByAdvanced: { classification: string; toName: string; attentionName: string; addressLine1: string; city: string; country: string; state: string; postalCode: string; email: string; phone1: string; partnerID: string; partnerName: string; };
  countryID: any;
  countryArray: any =[];
  statesData: any;
  countryGeoId: any;
  stateProvinceGeoId: any;
  constructor(
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly toastr: ToastrService,
    readonly myContactsService: MyContactsService,
    readonly datePipe: DatePipe,
    readonly _FormBuilder: FormBuilder,
    readonly CustomerAgreementsService: CustomerAgreementsService,

    readonly _GlobalResourceService: GlobalResourceService,
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly _MainCatalogService: MainCatalogService,
    readonly AccountsService: AccountsService,
    readonly _FinancialService: FinancialService,
    readonly accountsService: AccountsService,
    private _location: Location,

    readonly _Router: Router,
    readonly _ToastrService: ToastrService,
  ) {
    this.CustomerAgreements = this._FormBuilder.group({
      agreementId: [''],
      agreementIdSearchType: ['Contains'],
      agreementTypeId: [''],
      fromDateFrom: [''],
      fromDateFromSearchType: ['Greater Than'],
      fromDateTo: [''],
      fromDateToSearchType: ['Less Than'],
      partyIdFrom: [''],
      partyIdTo: [''],
      productId: [''],
    });
    this.createCustomerAgreements = this._FormBuilder.group({
      statusId: [''],
      agreementTypeId: ['', [Validators.required]],
      agreementDate: [''],
      description: [''],
      fromDate: [''],
      partyIdFrom: [''],
      partyIdTo: [''],
      productId: [''],
      roleTypeIdFrom: [''],
      roleTypeIdTo: [''],
      textData: [''],
      thruDate: ['']
    });
    this.findByAdvanced = {
      classification: "",
      toName: "",
      attentionName: "",
      addressLine1: "",
      city: "",
      country: "",
      state: "",
      postalCode: "",
      email: "",
      phone1: "",
      partnerID: "",
      partnerName: ""

    };
    this.findByName = {
      partnerID: "",
      partnerName: ""
    };
    this.findByPhone = {
      phone1: "",
      phone2: "",
      phone3: ""
    };
    this.findByemail = {
      email: "",
    };
    this.createPartner = this._FormBuilder.group({
      partnerName: [''],
      localName: [''],
      siteName: [''],
      annualRevenue: [''],
      preferredCurrency: [''],
      industry: [''],
      numberOfEmployees: [''],
      ownership: [''],
      sitCode: [''],
      tickerSymbol: [''],
      countryCode: [''],
      areaCode: [''],
      phoneNumber: [''],
      extension: [''],
      personToAskFor: [''],
      emailAddress: [''],
      webURL: [''],
      toName: [''],
      attentionName: [''],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      country: [''],
      zipExtension: [''],
    })

    // Added by RA

    this.contractorForm = this._FormBuilder.group({
      Employee: [''],
      StartDate: [''],
      Department: [''],
      endDate: [''],
      JobPosition: [''],
      WorkingSchedule: [''],
      Company: [''],
      Responsible: [''],
      ContractType: [''],
      Analytic: [''],
      SalaryStructure: [''],
    });
    this.WorkingScheduleForm = this._FormBuilder.group({
      Company: [''],
      FullTime: [''],
      AverageHourDay: [''],
      workingSchedule: [''],

    });

    this.contractorDetailsForm = this._FormBuilder.group({
      ContractTemplate: [''],
      NewDocumentTemplate: [''],
      ContractUpdateDocument: [''],

    });

    this.workingSchedule = this._FormBuilder.group({
      WorkingScheduleName: [''],
      Company: [''],
      FullTime: [''],
      AverageHourperDay: [''],

    });

    this.workingScheduleGet = this._FormBuilder.group({
      WorkingScheduleName: [''],
      Company: [''],
      FullTime: [''],
      AverageHourperDay: [''],

    });

    this.workingHours = this._FormBuilder.group({
      Name: [''],
      dayOfWeek: [''],
      dayPeriod: [''],
      workFrom: [''],
      WorkTo: [''],
      startDate: [''],
      endDate: [''],
      WorkEntryType: [''],

    });

    this.createWorkingHour = this._FormBuilder.group({
      names: [''],
      dayOfWeek: [''],
      dayPeriod: [''],
      workFrom: [''],
      WorkTo: [''],
      startDate: [''],
      endDate: [''],
      WorkEntryType: [''],

    });

    this.editMode = false;
    this.agreementType = '';
    this.CustomerAgreements = this._FormBuilder.group({
      agreementId: [''],
      agreementIdSearchType: ['Contains'],
      agreementTypeId: [''],
      fromDateFrom: [''],
      fromDateFromSearchType: ['Greater Than'],
      fromDateTo: [''],
      fromDateToSearchType: ['Less Than'],
      partyIdFrom: [''],
      partyIdTo: [''],
      productId: [''],
    });
    this.editMode = false;
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      }, {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Not Equal',
        vaLue: 'Not Equal'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.fromDateType = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Greater Than',
        value: 'Greater Than'
      },
      {
        label: 'Greater Than Equals',
        value: ' Greater Than Equal To'
      },

    ];
    this.agreementType = '';
    this.toDateType = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Less Than Equals',
        value: ' Less Than Equal To'
      },

    ];
   }

  ngOnInit(): void {
    this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
   if(this.agreementType=='payable'){
     this.activeCategory=1;
   }
   else{
    this.activeCategory=2;
   }
    this.getPersonsAndPartyGroups();
    this.getProducts();
    this.getAgreementTypeList();
    this.submit();
    this.getPersonsAndPartyGroupsData();
    this.getProductsData();
    this.getRoleTypes();
    this.getAgreementTypeListData();

    // Added by RA
    this.getDepartment();
    this.getEmployee();
    this.getJobPosition();
    this.getContractType();
    this.getCompany();
    this.getPayCheck();
    this.getAnalyticAccount();
    this.getHRResponsible();
    this.getNewContractDocumentTemplate();
    this.getContractUpdateDocumentTemplate();
    this.getContractTemplate();
    this.getWorkingSchedule();
    this.getDayPeriod();
    this.getDayOfWeek();
    this.getWorkingScheduleList();
    this.getWorkingHoursList();
    this.getWorkEntryType();
    this.getWorkSchedulePage();

    this.createPartner.controls.country.valueChanges.subscribe(data => {
      if (data) {
        this.countryId = data;
        this.getStateList();
      }
    });
    this.getCountryList();
    this.getfindByEmailPartner();
    this.getfindByPhonePartner();
    this.getfindByNamePartner();
    this.getIndustry();
    this.getCurrency();
    this.getOwnerShip();
    this.getClassificationBookmarkPartner();
    this.getfindByAdvancePartner();

  }
  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }
  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this.router.navigate(['/fsm/Overview']);
}
  onStateChange(event) {
    this.stateProvinceGeoId = event;
  }
  resetPartner() {
    this.spinner.show();
    this.findByAdvanced.addressLine1 = "";
    this.findByAdvanced.attentionName = "";
    this.findByAdvanced.city = "";
    this.findByAdvanced.country = "";
    this.findByAdvanced.postalCode = "";
    this.findByAdvanced.state = "";
    this.findByAdvanced.email = "";
    this.findByAdvanced.phone1 = "";
    this.findByAdvanced.partnerID = "";
    this.findByAdvanced.partnerName = "";
    this.findByAdvanced.toName = "";
    this.findByAdvanced.classification = "";
  
    const formData = {
     
      "address1": "",
      "attnName": "",
      "city": "",
      "contactNumber": "",
      "countryGeoId": "",
      "emailAddress": "",
      "partnerId": "",
      "partnerName": "",
      "postalCode": "",
      "stateProvinceGeoId": "",
      "toName": ""
    }
    this.myContactsService.findFinalPartner(formData).subscribe(res => {
      this.AdvancePartnerList = res.data;
      this.spinner.hide();
    })
  }

  findByAdvancePartner() {
    this.spinner.show();
    this.AdvancePartnerList = [];
    const formData = {
      "address1": this.findByAdvanced.addressLine1 ? this.findByAdvanced.addressLine1 : '',
      "attnName":  this.findByAdvanced.attentionName ? this.findByAdvanced.attentionName : '',
      "city": this.findByAdvanced.city ? this.findByAdvanced.city : '',
      "countryGeoId": this.findByAdvanced.country ? this.findByAdvanced.country : '',
      "postalCode": this.findByAdvanced.postalCode ? this.findByAdvanced.postalCode : '',
      "toName":  this.findByAdvanced.toName ? this.findByAdvanced.toName : '',
      "contactNumber": this.findByAdvanced.phone1 ? this.findByAdvanced.phone1 : '',
      "emailAddress":  this.findByAdvanced.email ? this.findByAdvanced.email : '',
      "partnerId":  this.findByAdvanced.partnerID ? this.findByAdvanced.partnerID : '',
      "partnerName":  this.findByAdvanced.partnerName ? this.findByAdvanced.partnerName : '',
      "stateProvinceGeoId":  this.findByAdvanced.state ? this.findByAdvanced.state : '',
    }
    this.myContactsService.findFinalPartner(formData).subscribe(res => {
      this.AdvancePartnerList = res.data;
      this.spinner.hide();
        
      })
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.currencyList = data.data.currencyList.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        }
      });
  }

  
  getCountryList() {
    this.spinner.show();
    this.AccountsService.getCountry().subscribe(res => {
      var Country = res.data.countryList;
      this.spinner.hide();
      Country.forEach(element => {
        this.array.push({
          label: element.split(":")[0],
          value: element.split(":").pop().trim()
        })
      })
    })
  }


  getStateList() {
    this.stateArray = [];
    this.spinner.show();
    this.AccountsService.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })

    })


  }
  getIndustry() {
    this.spinner.show();
    this.myContactsService.getIndustry().subscribe(res => {
      this.industryList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.industryList) {
        this.industryListArray.push({
          label: value.industryDescription,
          value: value.industryValue
        })
      }
    })
  }
  getOwnerShip() {
    this.spinner.show();
    this.myContactsService.getOwnerShip().subscribe(res => {
      this.ownerShip = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ownerShip) {
        this.ownerShipArray.push({
          label: value.ownerShipDescription,
          value: value.ownerShipValue
        })
      }
    })
  }
  getClassificationBookmarkPartner() {
    this.spinner.show();
    this.myContactsService.getClassificationBookmarkPartner().subscribe(res => {
      this.classificationBookmarkPartner = res.data[0].data;
      this.spinner.hide();
      for (const value of this.classificationBookmarkPartner) {
        this.classificationBookmarkPartnerArray.push({
          label: value.description,
          value: value.classificationEnumId
        })
      }
    })
  }

  createPartners(){
    this.spinner.show();
    const requestData = {
      "address1":  this.createPartner.value.addressLine1,
      "address2":  this.createPartner.value.addressLine2,
      "annualRevenue":  this.createPartner.value.annualRevenue,
      "areaCode":  this.createPartner.value.areaCode,
      "attnName":  this.createPartner.value.attentionName,
      "city":  this.createPartner.value.city,
      "country":  this.createPartner.value.country,
      "countryCode":  this.createPartner.value.countryCode,
      "description":  "",
      "emailAddress":  this.createPartner.value.emailAddress,
      "extension":  this.createPartner.value.extension,
      "importantNote":  "",
      "industry":  this.createPartner.value.industry,
      "localName":  this.createPartner.value.localName,
      "numberOfEmployees":  this.createPartner.value.numberOfEmployees,
      "ownership":  this.createPartner.value.ownership,
      "partnerId":  "",
      "partnerName":  this.createPartner.value.partnerName,
      "personToAskFor":  this.createPartner.value.personToAskFor,
      "phoneNumber":  this.createPartner.value.phoneNumber,
      "postalCode":  this.createPartner.value.zipCode,
      "postalCodeExt":  this.createPartner.value.zipExtension,
      "preferredCurrency": this.createPartner.value.preferredCurrency,
      "sicCode":  this.createPartner.value.sitCode,
      "siteName":  this.createPartner.value.siteName,
      "stateProvince":  this.createPartner.value.state,
      "tickerSymbol":  this.createPartner.value.tickerSymbol,
      "toName":  this.createPartner.value.toName,
      "webUrl":  this.createPartner.value.webURL,
    }
    this.myContactsService.createPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.createPartner.reset();
       this.findByAdvancePartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getfindByEmailPartner() {
    this.spinner.show();
    this.myContactsService.getfindByEmailPartner().subscribe(res => {
      this.EmailPartnerList = res.data;
      this.spinner.hide();
  
    })
  }
  getfindByPhonePartner() {
    this.spinner.show();
    this.myContactsService.getfindByPhonePartner().subscribe(res => {
      this.PhonePartnerList = res.data;
      this.spinner.hide();
  
    })
  }
  getfindByNamePartner() {
    this.spinner.show();
    this.myContactsService.getfindByNamePartner().subscribe(res => {
      this.NamePartnerList = res.data;
      this.spinner.hide();
  
    })
  }
  getfindByAdvancePartner() {
    this.spinner.show();
    const formData = {
      "address1": "",
      "attnName": "",
      "city": "",
      "contactNumber": "",
      "countryGeoId": "",
      "emailAddress": "",
      "partnerId": "",
      "partnerName": "",
      "postalCode": "",
      "stateProvinceGeoId": "",
      "toName": ""
    }
    this.myContactsService.findFinalPartner(formData).subscribe(res => {
      this.AdvancePartnerList = res.data;
      this.spinner.hide();
  
    })
  }
 
  detailPage(id){
    this._Router.navigate(["/financial/payable/customer-agreeement/viewPartner-Competitor"],
    {queryParams : {partyId :id}})
  }
  changeactiveCategoryA(tab: number) {
    this.activeCategoryA = tab;
  } 
  restForm(){
    this.CustomerAgreements.reset();
    this.CustomerAgreements.reset();
  }

  submitData(): void {
    this.spinner.show();
    if (this.createCustomerAgreements.valid) {
      const agreementDate = this.createCustomerAgreements.get('agreementDate').value;
      this.agreementDate = this.datePipe.transform(agreementDate, "yyyy-MM-dd hh:mm:ss");
      const thruDate = this.createCustomerAgreements.get('thruDate').value;
      this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
      const fromDate = this.createCustomerAgreements.get('fromDate').value;
      this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
      const requestData = {
        agreementDate: this.agreementDate,
        agreementTypeId: this.createCustomerAgreements.value.agreementTypeId.agreementTypeId,
        description: this.createCustomerAgreements.value.description,
        fromDate: this.fromDate,
        partyIdFrom: this.createCustomerAgreements.value.partyIdFrom.partyId,
        partyIdTo: this.createCustomerAgreements.value.partyIdTo.partyId,
        productId: this.createCustomerAgreements.value.productId.productId,
        roleTypeIdFrom: this.createCustomerAgreements.value.roleTypeIdFrom.roleTypeId,
        roleTypeIdTo: this.createCustomerAgreements.value.roleTypeIdTo.roleTypeId,
        textData: this.createCustomerAgreements.value.textData,
        thruDate: this.thruDate
      };
      this.CustomerAgreementsService.createAgreementAccnAR(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getAgreementTypeList();
         // this.router.navigate([`/financial/${this.agreementType}/customer-agreeement`]);
        } }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          } } });
    } else {
      this.spinner.hide();
      this.toastr.error('Please fill required field');
    }}

  getPersonsAndPartyGroupsData() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.spinner.hide();
      this.PersonsAndPartyGroupsList = responce.data.PersonsGroups;
    });
  
  }

  getProductsData() {
    this.spinner.show();
    const requestData = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains"
    };
    this.CustomerAgreementsService.getProducts(requestData).subscribe((responce) => {
      this.spinner.hide();
      this.productList = responce.data;
    });
   
  }

  getRoleTypes() {
    this.spinner.show();
    this.CustomerAgreementsService.getRoleTypes().subscribe((responce) => {
      this.spinner.hide();
      this.RoleTypesList = responce.data;
    });
   
  }

  getAgreementTypeListData() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {
      this.spinner.hide();
      this.AgreementTypeList = responce.data;
    });

  }
  invoiceType(){
    this.isShown = !this.isShown;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  Vendor(){
    this.router.navigate(["/financial/payable/customer-agreeement"])
  }
  main(){
    this.router.navigate(["/financial/main-contract"])
  }
 
  Customer(){
    this.router.navigate(["/financial/receiveable/customer-agreeement"])
  }
  Commission(){
    this.router.navigate(["/financial/receiveable/commission-agreeement"])
  }


  
  
  getPersonsAndPartyGroups() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.PersonsAndPartyGroupsListArray = responce.data.PersonsGroups.map(value => {
        return {
          label: value.partyId,
          value: value.partyId
        };
      });
    });
    this.spinner.hide();
  }

  getProducts() {
    this.spinner.show();
    const requestData = {
      internalName: '',
      internalNameSearchType: 'Contains',
      productId: '',
      productIdSearchType: 'Contains'
    };
    this.CustomerAgreementsService.getProducts(requestData).subscribe((responce) => {

      this.productListArray = responce.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
    });
    this.spinner.hide();
  }


  getAgreementTypeList() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {

      this.AgreementTypeListArray = responce.data.map(value => {
        return {
          label: value.description,
          value: value.agreementTypeId
        };
      });
    });
    this.spinner.hide();
  }

  submit(): void {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementList({
      agreementDate: this.CustomerAgreements.value.agreementDate ? moment(this.CustomerAgreements.value.agreementDate).format('YYYY-MM-DD HH:mm:ss') : '',
      agreementId: this.CustomerAgreements.value.agreementId?this.CustomerAgreements.value.agreementId:'',
      agreementIdSearchType: this.CustomerAgreements.value.agreementIdSearchType?this.CustomerAgreements.value.agreementIdSearchType:'',
      agreementTypeId: this.CustomerAgreements.value.agreementTypeId?this.CustomerAgreements.value.agreementTypeId:'',
      fromDateFrom: this.CustomerAgreements.value.fromDateFrom ? moment(this.CustomerAgreements.value.fromDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
      fromDateFromSearchType: this.CustomerAgreements.value.fromDateFromSearchType?this.CustomerAgreements.value.fromDateFromSearchType:'',
      fromDateTo: this.CustomerAgreements.value.fromDateTo ? moment(this.CustomerAgreements.value.fromDateTo).format('YYYY-MM-DD HH:mm:ss') : '',
      fromDateToSearchType: this.CustomerAgreements.value.fromDateToSearchType?this.CustomerAgreements.value.fromDateToSearchType:'',
      partyIdFrom: this.CustomerAgreements.value.partyIdFrom?this.CustomerAgreements.value.partyIdFrom:'',
      partyIdTo: this.CustomerAgreements.value.partyIdTo?this.CustomerAgreements.value.partyIdTo:'',
      productId: this.CustomerAgreements.value.productId? this.CustomerAgreements.value.productId:''
    }).subscribe((res: any) => {
     
      if (res.success) {
       
        this.agreementList = res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
    });
   
  }
  reset(): void {
    this.CustomerAgreements.reset();
    this.submit();
  }
  update(agreementID) {
    this.router.navigate([`/financial/${this.agreementType}/customer-agreeement/update-customer-agreeement`], { queryParams: { agreementID } });
  }

  summary(agreementID, toPartyId: string) {
    window.localStorage.setItem('toPartyId', toPartyId);
    this.router.navigate([`/fsm/summary-customer-agreeement`], { queryParams: { agreementID } });
  }

  cancelAgreement(agreementId: string): void {
    this.spinner.show();
    this.CustomerAgreementsService.cancelAgreement(agreementId)
      .then(data => {
        if (data.success) {
          this.toastr.success('Cancelled');
          this.spinner.hide();
          this.submit();
        }
      });
    
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }

      // Added by RA

      edit() {


        setTimeout(() => {
          const formValue = this.WorkingScheduleForm;
          formValue.patchValue({
            workingSchedule: this.WorkSchedule.workingSchedule,
    
          });
        }, 2000);
      }
    
      getWorkSchedulePage() {
        this.accountsService.getWorkSchedulePage(this.contractorForm.value.WorkingSchedule).subscribe((res: any) => {
          this.spinner.show();
          this.WorkSchedule = res.data[0];
          this.edit();
          this.spinner.hide();
        })
      }
    
    
      oncreateWorkingHours() {
        this.spinner.show();
    
        const endDate = this.createWorkingHour.get('endDate').value;
        this.actualendDate = this.datePipe.transform(endDate, "yyyy-MM-dd hh:mm:ss");
    
        const startDate = this.createWorkingHour.get('startDate').value;
        this.actualstartDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    
        const formData = {
    
          "dayOfWeek":  this.createWorkingHour.value.dayOfWeek,
          "dayPeriod":  this.createWorkingHour.value.dayPeriod,
          "endDate":  this.actualendDate ,
          "name": this.createWorkingHour.value.names,
          "startDate":this.actualstartDate,
          "workEntryType":  this.createWorkingHour.value.WorkEntryType,
          "workFrom":  this.createWorkingHour.value.workFrom,
          "workTo":  this.createWorkingHour.value.WorkTo,
          "workingHoursId": this.createworkingHoursId
        }
        this.accountsService.createWorkingHours(formData).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Created Successfully");
            this.spinner.hide();
            this.getWorkingHoursList();
    
          }
        }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          }
          else {
            this.toastr.error(err.error.message);
          }
        });
      }
    
      onWorkingSchedule() {
        this.spinner.show();
        const formData = {
          "averageHourPerDay": this.workingSchedule.value.AverageHourperDay,
          "fullTime": this.workingSchedule.value.FullTime,
          "partyId": this.workingSchedule.value.Company,
          "workingSchedule": this.workingSchedule.value.WorkingScheduleName,
          "workingScheduleId": this.workingScheduleId
        }
        this.accountsService.createWorkingSchedule(formData).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Created Successfully");
            this.spinner.hide();
            this.getWorkingScheduleList();
    
          }
        }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          }
          else {
            this.toastr.error(err.error.message);
          }
        });
      }
    
      deleteWorkingHours() {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this data.!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            const req = {
              "workingHoursId":this.totalworkingHoursId 
    
            }
            this.accountsService.deleteWorkingHours(req).subscribe((res: any) => {
              this.getWorkingHoursList();
            }, (err) => {
              this.toastr.error(err.error.message);
            })
            Swal.fire(
              'Deleted!',
              'Deleted Successfully.',
              'success'
            )
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your data is safe :)',
              'error'
            )
          }
        })
      }
    
    
    
      updateWorkingHours(product) {
        this.spinner.show();
    
        //const endDate = this.workingHoursTable.get('product.data.endDate').value;
       // this.actualendDate = this.datePipe.transform(endDate, "yyyy-MM-dd hh:mm:ss");
    
       // const startDate = this.workingHoursTable.get('product.data.startDate').value;
       // this.actualstartDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    
    
        const formdata = {
          "dayOfWeek": product.data.dayOfWeek,
          "dayPeriod": product.data.dayPeriod,
          "name": product.data.name,
          "workEntryType": product.data.workEntryType,
          "workFrom": product.data.workFrom,
          "workTo": product.data.workTo,
          "workingHoursId": this.totalworkingHoursId,
          "endDate": product.data.endDate,
          "startDate": product.data.startDate
        }
    
        this.accountsService.updateWorkingHours(formdata).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success(res.data.responseMessage);
            this.spinner.hide();
            this.getWorkingHoursList();
    
          }
        }
          , (err) => {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + " " + value.fieldError);
            }
          }
        )
        this.spinner.hide();
      }
    
      updateWorkingSchedule(product) {
        this.spinner.show();
        //this.resumeDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    
        const formdata = {
          "averageHourPerDay": product.averageHourPerDay,
          "fullTime": product.fullTime,
          "partyId": product.partyId,
          "workingSchedule": product.workingSchedule,
          "workingScheduleId": product.workingScheduleId,
    
    
        }
    
        this.accountsService.updateWorkingSchedule(formdata).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success(res.data.responseMessage);
            this.spinner.hide();
            this.getWorkingScheduleList();
    
          }
        }
          , (err) => {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + " " + value.fieldError);
            }
          }
        )
        this.spinner.hide();
      }
    
      getWorkingScheduleList() {
        this.accountsService.getWorkingScheduleList().subscribe((res: any) => {
          this.spinner.show();
          this.WorkingScheduleTable = res.data;
       //   this.workingScheduleId = res.data.workingScheduleId;
          this.spinner.hide();
        })
      }
    
      getTotalHours() {
        this.accountsService.getTotalHours().subscribe((res: any) => {
          this.spinner.show();
          this.TotalHours = res.data[0].hours;
          this.spinner.hide();
        })
      }
    
      getWorkingHoursList() {
        this.accountsService.getWorkingHoursList().subscribe((res: any) => {
          this.spinner.show();
          this.workingHoursTable = res.data;
          this.totalworkingHoursId = res.data[0].data.workingHoursId;
          this.spinner.hide();
          this.getTotalHours();
        })
      }
    
      discardPopUp(): void {
        this.closebutton.nativeElement.click();
    
      }
    
    
      onsubmitWorkingSchedule() {
        this.spinner.show();
        const formData = {
          "averageHourPerDay": this.WorkingScheduleForm.value.AverageHourDay,
          "fullTime": this.WorkingScheduleForm.value.FullTime,
          "partyId": this.WorkingScheduleForm.value.Company,
          "workingSchedule": this.WorkingScheduleForm.value.workingSchedule,
          "workingScheduleId": this.workingScheduleId,
    
        }
        this.accountsService.createWorkingSchedule(formData).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Created Successfully");
            this.spinner.hide();
            this.closebutton.nativeElement.click();
    
          }
        }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          }
          else {
            this.toastr.error(err.error.message);
          }
        });
      }
    
      onCreateContractDetails() {
        this.spinner.show();
        const formData = {
          "contractDetailsId": this.contractDetailsId,
          "contractTemplate": this.contractorDetailsForm.value.ContractTemplate,
          "contractUpdateDocumentTemplate": this.contractorDetailsForm.value.ContractUpdateDocument,
          "newContractDocumentTemplate": this.contractorDetailsForm.value.NewDocumentTemplate,
    
        }
        this.accountsService.createContractDetails(formData).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Created Successfully");
            this.spinner.hide();
          }
        }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          }
          else {
            this.toastr.error(err.error.message);
          }
        });
      }
    
      onCreateContract() {
    
        this.spinner.show();
        const EndDate = this.contractorForm.get('endDate').value;
        this.end_date = this.datePipe.transform(EndDate, "yyyy-MM-dd hh:mm:ss");
    
        const startDate = this.contractorForm.get('StartDate').value;
        this.StartDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    
        const formData = {
    
    
          "analyticAccount": this.contractorForm.value.Analytic,
          "contractId": this.contractId,
          "contractType": this.contractorForm.value.ContractType,
          "departmentName": this.contractorForm.value.Department,
          "employeeName": this.contractorForm.value.Employee,
          "endDate": this.end_date,
          "hrResponsible": this.contractorForm.value.Responsible,
          "jobPosition": this.contractorForm.value.JobPosition,
          "partyId": this.contractorForm.value.Company,
          "paycheckType": this.contractorForm.value.SalaryStructure,
          "startDate": this.StartDate,
          "workingSchedule": this.contractorForm.value.WorkingSchedule,
    
    
        }
        this.accountsService.createContract(formData).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Created Successfully");
    
            this.spinner.hide();
    
    
    
          }
        }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          }
          else {
            this.toastr.error(err.error.message);
          }
        });
    
      }
    
    
      getNewContractDocumentTemplate() {
    
        this.spinner.show();
        this.accountsService.getNewContractDocumentTemplate().subscribe(res => {
          this.NewDocumentTemplate = res.data;
          this.spinner.hide();
          for (const value of this.NewDocumentTemplate) {
            this.newContractDocumentTemplateArray.push({
              label: value.newContractDocumentTemplate,
              value: value.newContractDocumentTemplate
            })
          }
        })
      }
      getContractTemplate() {
    
        this.spinner.show();
        this.accountsService.getContractTemplate().subscribe(res => {
          this.ContractTemplate = res.data;
          this.spinner.hide();
          for (const value of this.ContractTemplate) {
            this.ContractTemplateArray.push({
              label: value.contractTemplate,
              value: value.contractTemplate
            })
          }
        })
      }
    
      getContractUpdateDocumentTemplate() {
    
        this.spinner.show();
        this.accountsService.getContractUpdateDocumentTemplate().subscribe(res => {
          this.ContractUpdateDocument = res.data;
          this.spinner.hide();
          for (const value of this.ContractUpdateDocument) {
            this.ContractUpdateDocumentArray.push({
              label: value.contractUpdateDocumentTemplate,
              value: value.contractUpdateDocumentTemplate
            })
          }
        })
      }
    
    
      getWorkingSchedule() {
    
        this.spinner.show();
        this.accountsService.getWorkingSchedule().subscribe(res => {
          this.WorkingSchedule = res.data;
          this.spinner.hide();
          for (const value of this.WorkingSchedule) {
            this.WorkingScheduleArray.push({
              label: value.workingSchedule,
              value: value.workingSchedule
            })
          }
        })
      }
      getDayPeriod() {
    
        this.spinner.show();
        this.accountsService.getDayPeriod().subscribe(res => {
          this.DayPeriod = res.data;
          this.spinner.hide();
          for (const value of this.DayPeriod) {
            this.DayPeriodArray.push({
              label: value.DayPeriod,
              value: value.DayPeriod
            })
          }
        })
      }
    
      getWorkEntryType() {
    
        this.spinner.show();
        this.accountsService.getWorkEntryType().subscribe(res => {
          this.workEntry = res.data;
          this.spinner.hide();
          for (const value of this.workEntry) {
            this.workEntryArray.push({
              label: value.WorkEntryType,
              value: value.WorkEntryType
            })
          }
        })
      }
      getDayOfWeek() {
    
        this.spinner.show();
        this.accountsService.getDayOfWeek().subscribe(res => {
          this.DayOfWeek = res.data;
          this.spinner.hide();
          for (const value of this.DayOfWeek) {
            this.DayOfWeekArray.push({
              label: value.DayOfWeek,
              value: value.DayOfWeek
            })
          }
        })
      }
    
      getAnalyticAccount() {
    
        this.spinner.show();
        this.accountsService.getAnalyticAccount().subscribe(res => {
          this.Analytic = res.data;
          this.spinner.hide();
          for (const value of this.Analytic) {
            this.AnalyticArray.push({
              label: value.analyticAccount,
              value: value.analyticAccount
            })
          }
        })
      }
      getHRResponsible() {
    
        this.spinner.show();
        this.accountsService.getHRResponsible().subscribe(res => {
          this.Responsible = res.data;
          this.spinner.hide();
          for (const value of this.Responsible) {
            this.ResponsibleArray.push({
              label: value.hrResponsible,
              value: value.hrResponsible
            })
          }
        })
      }
      getEmployee() {
        this.spinner.show();
        this.accountsService.getEmployee().subscribe(res => {
          this.Employee = res.data;
          this.spinner.hide();
          for (const value of this.Employee) {
            this.EmployeeArray.push({
              label: value.firstName + " " + value.lastName,
              value: value.firstName + " " + value.lastName
            })
          }
        })
      }
      getDepartment() {
    
        this.spinner.show();
        this.accountsService.getDepartment().subscribe(res => {
          this.Department = res.data;
          this.spinner.hide();
          for (const value of this.Department) {
            this.DepartmentArray.push({
              label: value.departmentName,
              value: value.departmentId
            })
          }
        })
      }
      getJobPosition() {
    
        this.spinner.show();
        this.accountsService.getJobPosition().subscribe(res => {
          this.JobPosition = res.data;
          this.spinner.hide();
          for (const value of this.JobPosition) {
            this.JobPositionArray.push({
              label: value.description,
              value: value.emplPositionTypeId
            })
          }
        })
      }
      getContractType() {
    
        this.spinner.show();
        this.accountsService.getContractType().subscribe(res => {
          this.ContractType = res.data;
          this.spinner.hide();
          for (const value of this.ContractType) {
            this.ContractTypeArray.push({
              label: value.ContractType,
              value: value.ContractType
            })
          }
        })
      }
    
      getCompany() {
    
        this.spinner.show();
        this.accountsService.getCompany().subscribe(res => {
          this.Company = res.data;
          this.spinner.hide();
          for (const value of this.Company) {
            this.CompanyArray.push({
              label: value.partyId,
              value: value.partyId
            })
          }
        })
      }
      getPayCheck() {
    
        this.spinner.show();
        this.accountsService.getPayCheck().subscribe(res => {
          this.SalaryStructure = res.data;
          this.spinner.hide();
          for (const value of this.SalaryStructure) {
            this.SalaryStructureArray.push({
              label: value.paycheckType,
              value: value.paycheckType
            })
          }
        })
      }

changeactiveCategory(tab: number)
{
  this.activeCategory = tab;

}
    
}