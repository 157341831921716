<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="reset()">Feature Group</span>
               <span class="d-flex">
     
                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
               
            </div>
            <div class=" bg-white color-grey">
              
                <div  class="port-header-contact create-lead font-medium mb-0">
                    Update Product Features Group Application
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="featureTypeForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Product Feature Type ID</label>
                                            </div>
                                         
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                            formControlName="productFeatureId" aria-describedby="emailHelp"
                                            placeholder="Enter Product Feature Type ID" disabled>
                                    </div>
                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">From Date
                                        </label>
                                    </div>
                                 
                                <div class="col-lg-2">
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                    formControlName="fromDate" aria-describedby="emailHelp"
                                    placeholder="Enter From Date" disabled>
                            </div>
                                    </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Sequence Number</label>
                                            </div>
                                         
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                            formControlName="sequenceNum" aria-describedby="emailHelp"
                                            placeholder="Enter Sequence Number">
                                    </div>
                                    </div>
                                    </div>
                                 
                                  
                                </div>
                                <div class="main-submit-button float-right" style="    margin-right: 31%;">
                                    <button type="submit" class="btn btn-secondary submit-btn"
                                        (click)="submit()">Update</button>
                                    
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
          
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>