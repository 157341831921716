<div class="container" *ngIf="!token">
	<div class="d-flex justify-content-center own-login-container">
		<div class="card" style="border:none!important;height:500px!important">
			<div class="card-header" style="background-color: white!important; border:none!important">
				<div class="d-flex justify-content-center">
					<img style="width:100%!important;height:120px!important;" src="assets/images/FULLBLACkWHITE.jpg">
				</div>
			</div>
			<div *ngIf="!webPos" class="card-body" style="padding: 0.5rem!important;background-color: white!important;">
				<form *ngIf="loginReactiveService.login_form" [formGroup]="loginReactiveService.login_form">
					<div class="mb-5">
						<div class="input-group form-group">
							<div class="input-group-prepend">
							</div>
							<input type="text" formControlName="username" class="form-control" name="username"
								[(ngModel)]="loginUser.username" placeholder="Email/Username" style="border-radius: 0px !important;
								border-top: none!important;border-left: none!important;border-right: none!important;"
								>
							<!-- { pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"} -->
						</div>
						<ng-container
							*ngIf="loginReactiveService.login_form.controls['username'].invalid && (loginReactiveService.login_form.controls['username'].dirty || loginReactiveService.login_form.controls['username'].touched)">
							 <p class="validate-field"
								*ngIf="loginReactiveService.login_form.controls['username'].errors.required">
								Username is required!</p>
								<!-- <p class="validate-field"
								*ngIf="loginReactiveService.login_form.controls['username'].errors.pattern">
								Please enter a valid email</p> -->
								
							<!-- 	<p class="validate-field"
								[hidden]="!loginUser.username.errors.pattern">Please enter a valid email</p> -->
						</ng-container>
					</div>
					<div class="mb-5">
						<div class="input-group form-group">
							<div class="input-group-prepend">
							</div>
							<input type="password" class="form-control" formControlName="password" name="password"
								[(ngModel)]="loginUser.password" placeholder="Password" style="border-radius: 0px !important;
								border-top: none!important;border-left: none!important;border-right: none!important;">
	
						</div>
						<ng-container
							*ngIf="loginReactiveService.login_form.controls['password'].invalid && (loginReactiveService.login_form.controls['password'].dirty || loginReactiveService.login_form.controls['password'].touched)">
							<p class="validate-field"
								*ngIf="loginReactiveService.login_form.controls['password'].errors.required">
								Password is required!</p>
						</ng-container>
					</div>
					<div *ngIf="!webPos" class="input-group form-group">
						<div class="input-group-prepend">
						</div>
						<input type="text" class="form-control" formControlName="userTenantId" name="password"
							[(ngModel)]="loginUser.userTenantId" placeholder="Tenant Id" style="border-radius: 0px !important;
							border-top: none!important;border-left: none!important;border-right: none!important;">

					</div>
					<div *ngIf="webPos" class="input-group form-group">
						<div class="input-group-prepend">
						</div>
		
						<p-dropdown filter="true" placeholder="Choose Terminal"
						optionlabel="label" 
						formControlName="webposTerminalId" 
						style="border-radius:0px !important; width: 400px!important"
						[options]="posTerminalArray">
						</p-dropdown>


					</div>
				
					<div *ngIf="!webPos" class="mb-4" style="margin-bottom: 5rem!important;">
						<div class="float-left">
							<div class="d-flex">
								<div><input type="checkbox"></div>
								<div style="padding:5px!important"><label>Remember Me</label></div>
							</div>
						</div>
						<div class="float-right">
							<a routerLink="/reset-password">Forgot your password?</a>
						</div>
					</div>
					<div class="form-group">
						<button *ngIf="!webPos" type="submit" (click)="loginuser()" value="Login" [ngClass]="loginUser.username && loginUser.password!='' ? 'btn-primary' : 'btn-secondary'"
							class="btn btnHover" style="border-radius: 7px !important;background-color: #0d3769;
							height: 41px!important;width: 384px!important;font-size: 20px!important;">Login</button>
						<button *ngIf="webPos" type="submit" (click)="LoginWebPos()" value="Login" [ngClass]="loginUser.username && loginUser.password!='' ? 'btn-primary' : 'btn-secondary'"
						class="btn btnHover" style="border-radius: 7px !important;background-color: #0d3769;
						height: 41px!important;width: 384px!important;font-size: 20px!important;color:#fff">POS Login</button>
				</div>
				</form>
			</div>
			<div *ngIf="webPos" class="card-body" style="padding: 0.5rem!important;background-color: white!important;">
				<form [formGroup]="webPosloginForm">
					<div class="mb-5">
						<div class="input-group form-group">
							<div class="input-group-prepend">
							</div>
							<input type="text" formControlName="username" class="form-control"
								 placeholder="Email/Username" style="border-radius: 0px !important;
								border-top: none!important;border-left: none!important;border-right: none!important;"
								>
						</div>
						
					</div>
					<div class="mb-5">
						<div class="input-group form-group">
							<div class="input-group-prepend">
							</div>
							<input type="password" class="form-control" formControlName="password" 
								 placeholder="Password" style="border-radius: 0px !important;
								border-top: none!important;border-left: none!important;border-right: none!important;">
	
						</div>
						
					</div>
					
					<div class="input-group form-group">
						<div class="input-group-prepend">
						</div>
		
						<p-dropdown filter="true" placeholder="Choose Terminal"
						optionlabel="label" 
						formControlName="webposTerminalId" 
						[options]="posTerminalArray">
						</p-dropdown>


					</div>
				
					
					<div class="form-group">

						<button *ngIf="webPos" type="submit" (click)="LoginWebPos()" value="Login"
						class="btn btnHover" style="border-radius: 7px !important;background-color: #0d3769;
						height: 41px!important;width: 384px!important;font-size: 20px!important;color:#fff">POS Login</button>
				</div>
				</form>
			</div>
			<!-- <div class="card-footer">
				<div class="d-flex justify-content-center links text-white">
					Don't have an account?<a href="#">Sign Up</a>
				</div>
				<div class="d-flex justify-content-center">
					
				</div>
			</div> -->
		</div>
	</div>
</div>

<div class="app-base-layout" *ngIf="token">
	<app-sidebar></app-sidebar>
	<div class="w-100 main-container-row">
		<app-header></app-header>
		<app-main-container></app-main-container>
		<app-footer></app-footer>
	</div>
</div>