import { Component, OnInit, OnDestroy } from '@angular/core';
// import { LoginService } from './login.service';
import { ToastrService } from 'ngx-toastr';
// import { LoginReactiveService } from './login-service'
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-webpos-login-page',
  templateUrl: './webpos-login-page.component.html',
  styleUrls: ['./webpos-login-page.component.css']
})
export class WebposLoginPageComponent implements OnInit {


  LoginWebPOS: FormGroup;
  posTerminalArray: { label: string; value: string; }[];
  posToken: any;
  previousToken: string;
  productStoreId: any;
  verifyTok: void;
  userLoginId: any;
  posDataRecord: any;
  posDataRecordAll: string;
  posDataRecordAllData: any;
  posTerminalId: any;
  checkPOSToken: string;
  productStoreIdNew: string;
  posTerminalIdNew: string;
  posUserId: any;

  constructor(
    // readonly loginService: LoginService,
    // public loginReactiveService: LoginReactiveService,
    readonly router: Router,
    readonly toastr: ToastrService,
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly _Router: Router
  ) { 
    this.posTerminalArray = 
    [
      {
        label: 'Pos Terminal 1',
        value: 'pos-1'
      },
      {
        label: 'Pos Terminal 2',
        value: 'pos-2'
      },
      {
        label: 'Pos Terminal 3',
        value: 'pos-3'
      },
    ];
    this.LoginWebPOS = this._FormBuilder.group({
      username: [''],
      password: [''],
      posTerminalId: ['']
    })
  }

  ngOnInit(): void {
    this.checkToken();    
  }

  checkToken(){
    this.checkPOSToken = localStorage.getItem('posToken');
    if(this.checkPOSToken){
      this.productStoreIdNew = localStorage.getItem('productStoreId');
      this.posTerminalIdNew = localStorage.getItem('posTerminalId');
      this.router.navigate(["/webpos/control/main"],{queryParams:{ posTerminalId :  this.posTerminalIdNew, productStoreId:this.productStoreIdNew}});
    }
  }

  LoginWebPos(): void {
    this.spinner.show();
    const requestData ={
      "password": this.LoginWebPOS.value.password,
      "posTerminalId": this.LoginWebPOS.value.posTerminalId,
      "username": this.LoginWebPOS.value.username,
      }
     
    this.accountsService.LoginWebPos(requestData).subscribe((res: any) => { 
      console.log("webposloginresponse",res);
      if (res.success) {
        this.toastr.success("POS Login Successfully");
        this.posToken = res.data.token;
        this.posUserId=res.data.userLogin.userLoginId
        this.userLoginId = res.data.userLogin.userLoginId;
        this.posTerminalId = this.LoginWebPOS.value.posTerminalId
        this.productStoreId = res.data.productStoreId[0].productStoreId;
        this.previousToken = localStorage.getItem('token');
        localStorage.removeItem('token');
        localStorage.setItem('token', (this.posToken));
        localStorage.setItem('posUserId', (this.posUserId));
        localStorage.setItem('posToken', (this.posToken));
        localStorage.setItem('previousToken', this.previousToken);
        localStorage.setItem('productStoreId', this.productStoreId);
        localStorage.setItem('posTerminalId', this.posTerminalId);
        this.router.navigate(["/webpos/control/main"],{queryParams:{ posTerminalId :  this.posTerminalId, productStoreId:this.productStoreId}});
        this.spinner.hide();
        this.setIntrvl();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  setIntrvl(){
    setInterval(() => 
    this.getVerifyToken(),1500000);
  }

  getVerifyToken(): void {
    const requestData ={
      "authToken": this.posToken,
      "userLoginId": this.userLoginId
      }
    this.accountsService.getVerifyToken(requestData).subscribe((res: any) => { 
      console.log("posverfifytoken",res);
      this.posDataRecord = res.data[0];
      localStorage.setItem('posDataRecord', (this.posDataRecord))
      console.log("Login works");
      if (res.data[0].VerifyToken == "Token is expired") {
        this.router.navigate(["/webpos/login"]);
        this.toastr.success("POS Token is expired");
        this.previousToken = localStorage.getItem('previousToken');
        localStorage.removeItem('token');
        localStorage.removeItem('posToken');
        localStorage.removeItem('productStoreId');
        localStorage.removeItem('posTerminalId');
        localStorage.setItem('token', (this.previousToken));
        localStorage.removeItem('previousToken');
      }
    }, (err) => {
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

}
