
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CreatePaymentModel } from './create-payment-model';

@Injectable({
  providedIn: 'root'
})


export class CreatePaymentFormService {
  create_payment_form: FormGroup;


  constructor(public _formBuilder: FormBuilder,
    public createPaymentModel: CreatePaymentModel
  ) { }

  createEventForm(data: any): void {
    if (!this.create_payment_form) {
      this.create_payment_form = this._formBuilder.group(this.createPaymentModel.mapDataToModel(data));

    }

  }

}












