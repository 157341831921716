<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Data Resource</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                Find</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="navigateOpen()" ><a>
                                Navigate</a></li>
                                
                            <button type="submit" class="btn btn-secondary submit-btn ml-2 mt-2" style="margin: 0;" data-toggle="modal" data-target="#creatContentDataResourcePopup">Create Content Data Resource</button>
                            

                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">

                                    <div *ngIf="activeCategory==1">
                                        <div class="create-new-leade mt-2">
                                            <div class="">
                                                <div class="">
                                                    <form class="w-100">

                                                        <div class="w3-card-4  w-100">
                                                            <div class="accordion classCard" id="myAccordion">
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id="headingOne">
                                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                            <span class="headingAccords"> Data Resource</span>
                                                                            <!-- <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                                            data-target="#creatContentPopup" data-toggle="modal">Create Content</button>
                                                                 -->
                                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 82%!important; padding-top: 11px !important;
                                                                                padding-bottom: 11px !important;">
                                                                            </button>									
                                
                                                                        </div>
                                    
                                                                    </h2>
                                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                        <div class="card-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <form class="w-100" [formGroup]="dataResourceForm">
                                                                                
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                
                                                                                        <div class="col-lg-3 form-group classInput" >
                                                                                            <label for="exampleInputEmail1">
                                                                                                Data Resource ID</label>
                                                                                        </div>
                                                                                        
                                                                                        <div class="col-lg-2" >
                                                                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp" placeholder="Enter Data Resource ID"
                                                                                            formControlName="dataResourceID">
                
                                                                                            
                                                                                        </div>
                                                                                        
                                                                                        <div class="col-lg-3 form-group classInputA">
                                                                                            <label for="exampleInputEmail1">Data Resource Name		
                                                                                            </label>
                                                                                        </div>
                                                                                        
                                                                                        <div class="col-lg-2" >
                                                                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp" placeholder="Enter Data Resource Name"
                                                                                            formControlName="dataResourceName">
                                                                                        
                                                                                        </div>
                                                                
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
            
                                                                                        <div class="col-lg-3 form-group classInput" >
                                                                                            <label for="accountLimit">Data Resource Type ID</label>
                                                                                        </div>
                                                                                        
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true" placeholder="Select Data Resource Type ID"
                                                                                                [options]="DataResourceTypeArray"  optionlabel="label"  formControlName="dataResourceTypeID">
                                                                                        </p-dropdown>
                                                                                        </div>
                                                                
                                                                                        <div class="col-lg-3 form-group classInputA" >
                                                                                            <label for="accountLimit">Mime Type ID</label>
                                                                                        </div>
                                                                                        
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true" placeholder="Select Mime Type ID"
                                                                                            [options]="mimeTypeIdDataArray"optionlabel="label"  formControlName="mimeTypeID">
                                                                                        </p-dropdown>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                
                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Status ID</label>
                                                                                        </div>
                                                                                        
                                                                                        
                                                                                        <div class="col-lg-2">
            
                                                                                            <p-dropdown filter="true" placeholder="Select Status ID"
                                                                                            [options]="dataResourceStatusIdArray"  optionlabel="label"  formControlName="statusID">
                                                                                            </p-dropdown>
                                                                                            
                                                                                        </div>
                                                                
            
                                                                                        <div class="col-lg-3 form-group classInputA" >
                                                                                            <label for="exampleInputEmail1">Locale String	
                                                                                            </label>
                                                                                        </div>
                                                                                        
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true" placeholder="Select Locale String"
                                                                                            [options]="localStringArray"   optionlabel="label"  formControlName="localeString">
                                                                                            </p-dropdown>
                                                                                        </div>
                
                                                                                        
                                                                
                                                                                    </div>
                                                                                </div>
                
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
            
                                                                                        <div class="col-lg-3 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Created By User	
                                                                                            </label>
                                                                                        </div>
                                                                                        
                                                                                        
                                                                                        <div class="col-lg-2">
            
                                                                                            <p-dropdown filter="true" placeholder="Select Created By User"
                                                                                            [options]="DataResourceTypeArray"optionlabel="label"  formControlName="createdByUser">
                                                                                            </p-dropdown>
            
                                                                                            
                                                                                        </div>
                                                                                        
                                                                
                                                                                        <div class="col-lg-3 form-group classInputA" >
                                                                                            <label for="exampleInputEmail1">Data Category ID	
                                                                                            </label>
                                                                                        </div>
                                                                                        
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true" placeholder="Select Data Category ID"
                                                                                            [options]="dataCategoryArray"optionlabel="label"  formControlName="dataCategoryID">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                        
                                                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left:-25%;">
                                                                            <button _ngcontent-cta-c128="" type="submit" 
                                                                            class="btn btn-secondary submit-btn" (click)="findDataResource()" >Find</button>&nbsp;
                                                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                        </div>
                        
                        
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>

                                                        <!-- <div class="w3-card-4 classCard">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                Data Resource
                                                                 <button type="button" class="btn btn-outline-secondary active"
                                                                    data-toggle="modal" data-target="#dataResourcePopup">Create New Data Resource</button>
                                                                
                                                                </div><br>

                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <form class="w-100" [formGroup]="dataResourceForm">
                                                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">
                                                                                    Data Resource ID</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Enter Data Resource ID"
                                                                                formControlName="dataResourceID">
    
                                                                                
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Data Resource Name		
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Enter Data Resource Name"
                                                                                formControlName="dataResourceName">
                                                                            
                                                                            </div>
                                                    
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="accountLimit">Data Resource Type ID</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" placeholder="Select Data Resource Type ID"
                                                                                    [options]="DataResourceTypeArray"  optionlabel="label"  formControlName="dataResourceTypeID">
                                                                            </p-dropdown>
                                                                            </div>
                                                    
                                                                            <div class="col-lg-3 form-group classInputA" >
                                                                                <label for="accountLimit">Mime Type ID</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" placeholder="Select Mime Type ID"
                                                                                [options]="mimeTypeIdDataArray"optionlabel="label"  formControlName="mimeTypeID">
                                                                            </p-dropdown>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Status ID</label>
                                                                            </div>
                                                                            
                                                                            
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true" placeholder="Select Status ID"
                                                                                [options]="dataResourceStatusIdArray"  optionlabel="label"  formControlName="statusID">
                                                                                </p-dropdown>
                                                                                
                                                                            </div>
                                                    

                                                                            <div class="col-lg-3 form-group classInputA" >
                                                                                <label for="exampleInputEmail1">Locale String	
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" placeholder="Select Locale String"
                                                                                [options]="localStringArray"   optionlabel="label"  formControlName="localeString">
                                                                                </p-dropdown>
                                                                            </div>
    
                                                                            
                                                    
                                                                        </div>
                                                                    </div>
    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Created By User	
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true" placeholder="Select Created By User"
                                                                                [options]="DataResourceTypeArray"optionlabel="label"  formControlName="createdByUser">
                                                                                </p-dropdown>

                                                                                
                                                                            </div>
                                                                            
                                                    
                                                                            <div class="col-lg-3 form-group classInputA" >
                                                                                <label for="exampleInputEmail1">Data Category ID	
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" placeholder="Select Data Category ID"
                                                                                [options]="dataCategoryArray"optionlabel="label"  formControlName="dataCategoryID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left:-25%;">
                                                                <button _ngcontent-cta-c128="" type="submit" 
                                                                class="btn btn-secondary submit-btn" (click)="findDataResource()" >Find</button>&nbsp;
                                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                            </div>
                                                        </div> -->
                                                        
                                                        <br>
                
                                                        <div class="w3-card-4 classCardB" style="    width: 100%;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                <h4 class=" common-styling h4M">
                                                                    Data Resource
                                                                </h4>
                                                                </div>
                                                            </header>
                                                        <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select" style="display: block!important;">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="findDataResourceData" [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'250px'}">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input
                                                                                                            type="checkbox" name="undefined"
                                                                                                            value="undefined"></div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Data Resource Name	 <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}"><div style="color: white;">Data Resource Type ID	<p-sortIcon field="name">
                                                                                        </p-sortIcon></div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'170px'}"><div style="color: white;"> Mime Type ID	 <p-sortIcon
                                                                                            field="category">
                                                                                        </p-sortIcon></div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Status ID	</div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Locale String	
                                                                                    </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Created By User	
                                                                                    </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>

                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Data Category ID	
                                                                                    </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'250px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input
                                                                                                            type="checkbox" name="undefined"
                                                                                                            value="undefined"></div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                
                                                                                            </p-checkbox>
                                                                                            <span  
                                                                                                >{{product.dataNodes.dataResourceName}} 
                                                                                                <b class="account-button"
                                                                                                (click)="detailPage(product.dataNodes.dataResourceId)">
                                                                                                        {{product.dataNodes.dataResourceId}}</b> </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.dataNodes.dataResourceTypeId}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.dataNodes.mimeTypeId}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.dataNodes.statusId}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.dataNodes.localeString}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.dataNodes.createdByUserLogin}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.dataNodes.dataCategoryId}}</td>
                                                                                </tr>
                                                                                
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>

 <div class="modal fade" id="creatContentDataResourcePopup" tabindex="-1" role="dialog" aria-labelledby="creatContentDataResourcePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Content Data Resource</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Content Data Resource</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createContentDataResourceForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Resource ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="dataResourceID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Data Resource ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Resource Type ID</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="dataResourceTypeID" [options]="DataResourceTypeArray"
                                                        placeholder="Select Data Resource Type ID">

                                                        </p-dropdown>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Template Type ID		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="dataTemplateTypeID"  [options]="dataTemplateTypeArray"
                                                        placeholder="Select Data Template Type ID">

                                                        </p-dropdown>
                                                        
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Category ID		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="dataCategoryID"  [options]="dataCategoryArray"
                                                        placeholder="Select Data Category ID">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Source ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="dataSourceID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Data Source ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Resource Name		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="dataResourceName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Data Resource Name">
                                               
                                                       
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Locale String	
	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="localeString"  [options]="localStringArray"
                                                        placeholder="Select Locale String	
                                                        ">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Mime Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="mimeTypeID"  [options]="mimeTypeIdDataArray"
                                                        placeholder="Select Mime Type ID	
                                                        ">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Character Set ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="characterSetID"  [options]="CharacterSetIdArray"
                                                        placeholder="Select Character Set ID	
                                                        ">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Object Info	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="objectInfo" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Object Info">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Survey ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="surveyID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Survey ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Survey Response ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="surveyResponseID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Survey Response ID">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Related Detail ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="relatedDetailID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Related Detail ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Is Public	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="isPublic"[options]="yesNoArray"
                                                        placeholder="Select Is Public	
                                                        ">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="createdDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" >
                                           
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Created By User	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="createdByUser" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Created By User">
                                           
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="lastModifiedDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" >
                                           
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Last Modified By User</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="lastModifiedByUser" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Last Modified By User">
                                           
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>

                                          
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createDataResource()">Create</button>
                                                <!-- <button type="submit" class="btn btn-secondary submit-btn">Update</button> -->
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="dataResourcePopup" tabindex="-1" role="dialog" aria-labelledby="dataResourcePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Data Resource


                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Data Resource


                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="dataResourceForm">

                                        <div class="all-users-infomation font-13 font-medium own-user-rrr">
                                           
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">
                                                            Data Resource ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Data Resource ID"
                                                        formControlName="dataResourceID">

                                                        
                                                    </div>
                                                    
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Resource Name		
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Data Resource Name"
                                                        formControlName="dataResourceName">
                                                    
                                                    </div>
                            
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Data Resource Type ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Select Data Resource Type ID"
                                                            optionlabel="label"  formControlName="dataResourceTypeID">
                                                    </p-dropdown>
                                                    </div>
                            
                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Mime Type ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Select Mime Type ID"
                                                            optionlabel="label"  formControlName="mimeTypeID">
                                                    </p-dropdown>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Status ID</label>
                                                    </div>
                                                    
                                                    
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" placeholder="Select Status ID"
                                                        optionlabel="label"formControlName="statusID">
                                                        </p-dropdown>
                                                      
                                                    </div>
                            

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="exampleInputEmail1">Locale String	
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Select Locale String"
                                                        optionlabel="label"  formControlName="localeString">
                                                        </p-dropdown>
                                                    </div>

                                                    
                            
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Created By User	
                                                        </label>
                                                    </div>
                                                    
                                                    
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" placeholder="Select Created By User"
                                                        optionlabel="label"  formControlName="createdByUser">
                                                        </p-dropdown>

                                                       
                                                    </div>
                                                    
                            
                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="exampleInputEmail1">Data Category ID	
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Select Data Category ID"
                                                        optionlabel="label"  formControlName="dataCategoryID">
                                                        </p-dropdown>
                                                    </div>

                                                    
                                                    
                            
                                                </div>
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    
                                
                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left:-8%;">
                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" >Create</button>&nbsp;
                                        <button type="submit" class="btn btn-secondary ml-2 submit-btn">Update</button>
                                    </div>
                                
                                
                                
                                

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

 <ngx-spinner></ngx-spinner> 

