import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {Router,ActivatedRoute} from "@angular/router";
import {CreateDeparmentsService} from "./create-departments.service";
import {CreateDepartmentFormService} from "./create-departments-service";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddLeaveEmpService } from '../leave/add-new-leaves/add-new-leaves.service';



@Component({
  selector: 'app-create-departments',
  templateUrl: './create-departments.component.html',
  styleUrls: ['./create-departments.component.css']
})
export class CreateDepartmentsComponent implements OnInit {
  companyName: any;
  activeCategory = 2;
  array: any=[];
  deptId: any;
  show =false;
  companyData: any;
  companyArray: any=[];
  showError: any;
  departmentList: any;
  departmentArray: any=[];
  pageNo = 1;
  pageSize = 10;
  
  readonly employessSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,
  
  }
  partyId: any;
  partyIdArray: any[]=[];
  constructor(readonly createDeparmentsService:CreateDeparmentsService,
    public createDepartmentFormService:CreateDepartmentFormService,
    readonly activatedRoute:ActivatedRoute,
    readonly addLeaveEmpService: AddLeaveEmpService,
    readonly spinner:NgxSpinnerService,
    readonly router:Router,
    readonly toastr:ToastrService) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
     this.getAllAccount();
     this.getdepartmentsList();
    this.activatedRoute.queryParams.subscribe(params=> {
      this.deptId=params["updateId"];
      if( this.deptId) {
        this.show=true;
        this.getdepartmentsListById();
      }
      else {
        this.show=false;
      }
    })
   
    this.createDepartmentFormService.job_requistion_form=null;
    this.createDepartmentFormService.jobRequistionForm(this.createDepartmentFormService.job_requistion_form)
  this.getCompanyName();
  this.getPartyId();
  }
  getPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getCompanyName() {
    this.spinner.show();
    const req={
      "companyId": "",
      "companyIdSearchType": "Contains",
      "departmentId": "",
      "departmentIdSearchType": "Contains",
      "departmentName": "",
      "departmentNameSearchType": "Contains"
    }
    this.createDeparmentsService.getDepartment(this.employessSize,req).subscribe((res:any)=> {
      this.companyName = res.data;
      this.spinner.hide();
      this.companyName.forEach(value=> {
        for(const value of this.companyName){
        this.array.push({
          label:value.parentDepartment,
          value:value.parentDepartmentId
        })
      }
      })
    })
  
  }
 
  getdepartmentsList() {
    this.spinner.show();
    const req={
      "companyId": "",
      "companyIdSearchType": "Contains",
      "departmentId": "",
      "departmentIdSearchType": "Contains",
      "departmentName": "",
      "departmentNameSearchType": "Contains"
    }
     this.createDeparmentsService.getDepartment(this.employessSize,req).subscribe((res:any)=> {
       this.departmentList = res.data;
       this.spinner.hide();
       for(const value of this.departmentList) {
         this.departmentArray.push({
           label:value.departmentName,
           value:value.departmentId
         })
       }
     })
    
      }
      getdepartmentsListById() {
       
        const req={
          "companyId": "",
          "companyIdSearchType": "Contains",
          "departmentId": this.deptId,
          "departmentIdSearchType": "Contains",
          "departmentName": "",
          "departmentNameSearchType": "Contains"
        }
         this.createDeparmentsService.getDepartment(this.employessSize,req).subscribe((res:any)=> {
          this.spinner.show();
          setTimeout(() => {
            const formValue = this.createDepartmentFormService.job_requistion_form;
            formValue.patchValue({
              departmentName:res.data[0].departmentName,
              active:res.data[0].active,
              parentDepartmentId:res.data[0].parentDepartmentId,
              companyNameId:res.data[0].partyId,
            
            })
            this.spinner.hide();
          }, 3000);
         
            })
           
          }
  prevScrenn(){
    this.router.navigate(['/hr/departments'])
  }
  onSubmit(){
    this.spinner.show();
    this.createDepartmentFormService.job_requistion_form.markAllAsTouched();
    const formData={...this.createDepartmentFormService.job_requistion_form.value};
    this.createDeparmentsService.createDepartMent(formData).subscribe(res=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/hr/departments'])
      }
      else if(res.success == false) {
        this.spinner.hide();
        this.toastr.error("error");
      }
    },(err) => {
      this.spinner.hide();
      this.showError=(err.error.errors[0])
      this.toastr.error(this.showError.fieldName + " " +this.showError.fieldError)

   })
  
  }
  onUpdate() {
    this.spinner.show();
    const formData = {...this.createDepartmentFormService.job_requistion_form.value};
    this.createDeparmentsService.updateDept(formData,this.deptId).subscribe(res=> {
      if(res.success) {
        this.toastr.success("Form Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/hr/departments'])
      }
      else if(res.success == false) {
        this.spinner.hide();
        this.toastr.error("error");
      }
    },(err) => {
      this.spinner.hide();
      this.showError=(err.error.errors[0])
      this.toastr.error(this.showError.fieldName + " " +this.showError.fieldError)

   })
 
  }
  getAllAccount(){
    this.spinner.show();
    const req={
      "contactMechTypeId": "",
      "groupName": "",
      "groupNameSearchType": "Contains",
      "partyId": "",
      "partyIdSearchType": "Contains"
    }
    let all = "true"
    this.createDeparmentsService.getAllAccount(this.employessSize,req,all).subscribe((res:any)=> {
      this.companyData = res.data;
      this.spinner.hide();
      for(const value of this.companyData) {
        this.companyArray.push({
          label:value.groupName,
          value:value.partyId
        })
      }
    })
   
  }
  
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
