<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Requests</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">

                            <div class="w3-card-4">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords"> Find Requests

                                                    </span>
                                                    
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                                        data-target="#exampleRequestList">New Request
                                                    </button>

                                                    <span>
                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 958%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;"></button>									

                                                    </span>
                                            
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Cust Request ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceRequest.CustRequestID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="CustRequestID"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Cust Request ID">
                                                                            </div>
                    
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">Cust Request Type ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                    [(ngModel)]="advanceRequest.CustRequestTypeID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    name="CustRequestTypeID" id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    [options]="CustRequestTypeIDArray"
                                                                                    placeholder="Enter Cust Request Type ID">
                                                                                </p-dropdown>
                                                                            </div>
                    
                    
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Status ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceRequest.StatusID"
                                                                                    [options]="StatusiD"
                                                                                    [ngModelOptions]="{standalone:true}" name="StatusID"
                                                                                    optionlabel="label" placeholder="Choose Status ID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">From Party ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceRequest.FromPartyID"
                                                                                    [ngModelOptions]="{standalone:true}" name="FromPartyID"
                                                                                    optionlabel="label" [options]="partyIdArray"
                                                                                    placeholder="Choose From Party ID">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Cust Request Date
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control"
                                                                                    [(ngModel)]="advanceRequest.CustRequestDate"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="CustRequestDate"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Cust Request Date">
                    
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="productId">Response Required Date
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control"
                                                                                    [(ngModel)]="advanceRequest.ResponseRequiredDate"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="ResponseRequiredDate"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Response Required Date">
                    
                    
                                                                            </div>
                                                                        </div>
                                                                    </div>
                    
                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                    
                    
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="productId">Cust Request Name
                    
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceRequest.CustRequestName"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="CustRequestName"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Cust Request Name">
                    
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">Sales Channel
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceRequest.SalesChannel"
                                                                                    [ngModelOptions]="{standalone:true}" name="SalesChannel"
                                                                                    optionlabel="label" [options]="salesChannelArray"
                                                                                    placeholder="Choose Sales Channel">
                                                                                </p-dropdown>
                    
                                                                            </div>
                                                                        </div>
                                                                    </div>
                    
                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                    
                    
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Product Store
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [(ngModel)]="advanceRequest.ProductStore"
                                                                                    [ngModelOptions]="{standalone:true}" name="ProductStore"              
                                                                                    optionlabel="label"
                                                                                    [options]="productStoreIdReportArray"
                                                                                    placeholder="Choose Product Store">
                                                                                </p-dropdown>
                                                                            </div>
                    
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">Open Date Time
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                    
                                                                                <input type="date" class="form-control"
                                                                                    [(ngModel)]="advanceRequest.OpenDateTime"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="OpenDateTime"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Open Date Time">
                    
                                                                            </div>
                                                                        </div>
                                                                    </div>
                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                    
                    
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="productId">Closed Date Time
                    
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control"
                                                                                    [(ngModel)]="advanceRequest.ClosedDateTime"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="ClosedDateTime"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Closed Date Time">
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="productId">Internal Comment
                    
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceRequest.Comment"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="Comment"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Internal Comment">
                    
                                                                            </div>
                                                                        </div>
                                                                    </div>
                    
                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                    
                    
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="productId">Reason
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceRequest.Reason"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="Reason"
                                                                                    aria-describedby="emailHelp" placeholder="Enter Reason">
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="productId">Cust Estimated Milli Seconds
                    
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceRequest.MilliSeconds"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="MilliSeconds"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Cust Estimated Milli Seconds">
                    
                                                                            </div>
                                                                        </div>
                                                                    </div>
                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="productId">Cust Sequence Num
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceRequest.CustSequenceNum"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="CustSequenceNum"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Cust Sequence Num">
                    
                    
                                                                            </div>
                    
                                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="productId">Parent Cust Request ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceRequest.ParentCustRequestID"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    id="exampleInputEmail1" name="ParentCustRequestID"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Parent Cust Request ID">
                    
                    
                                                                            </div>
                                                                        </div>
                                                                    </div>
                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Billed
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                    
                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                    [(ngModel)]="advanceRequest.Billed"
                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                    [options]="yesNOArray" id="exampleInputEmail1"
                                                                                    name="Billed" placeholder="Choose Billed">
                    
                                                                                </p-dropdown>
                    
                                                                            </div>
                                                                        </div>
                                                                    </div>
                    
                    
                                                                </div>
                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-12 col-12 main-submit-button"
                                                        style="margin-left: -25%;">
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="FindOrderRequest()">Find</button>
                                                        <button type="submit" class="btn btn-danger ml-2"
                                                            (click)="reset()">Reset</button>
                                                    </div>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                    
                                </div>
                            </div>
                            <!-- <div class="w3-card-4 classCardView mt-2 w-100">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Find Requests
                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                        data-target="#exampleRequestList">New Request
                                    </button>
                                </div>
                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Cust Request ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="advanceRequest.CustRequestID"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="CustRequestID"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Cust Request ID">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Cust Request Type ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label"
                                                                [(ngModel)]="advanceRequest.CustRequestTypeID"
                                                                [ngModelOptions]="{standalone:true}"
                                                                name="CustRequestTypeID" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                [options]="CustRequestTypeIDArray"
                                                                placeholder="Enter Cust Request Type ID">
                                                            </p-dropdown>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Status ID
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true"
                                                                [(ngModel)]="advanceRequest.StatusID"
                                                                [options]="StatusiD"
                                                                [ngModelOptions]="{standalone:true}" name="StatusID"
                                                                optionlabel="label" placeholder="Choose Status ID">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">From Party ID
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true"
                                                                [(ngModel)]="advanceRequest.FromPartyID"
                                                                [ngModelOptions]="{standalone:true}" name="FromPartyID"
                                                                optionlabel="label" [options]="partyIdArray"
                                                                placeholder="Choose From Party ID">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Cust Request Date
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="date" class="form-control"
                                                                [(ngModel)]="advanceRequest.CustRequestDate"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="CustRequestDate"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Cust Request Date">

                                                        </div>
                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="productId">Response Required Date
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="date" class="form-control"
                                                                [(ngModel)]="advanceRequest.ResponseRequiredDate"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="ResponseRequiredDate"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Response Required Date">


                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="productId">Cust Request Name

                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="advanceRequest.CustRequestName"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="CustRequestName"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Cust Request Name">

                                                        </div>
                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Sales Channel
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true"
                                                                [(ngModel)]="advanceRequest.SalesChannel"
                                                                [ngModelOptions]="{standalone:true}" name="SalesChannel"
                                                                optionlabel="label" [options]="salesChannelArray"
                                                                placeholder="Choose Sales Channel">
                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Product Store
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true"
                                                                [(ngModel)]="advanceRequest.ProductStore"
                                                                [ngModelOptions]="{standalone:true}" name="ProductStore"              
                                                                optionlabel="label"
                                                                [options]="productStoreIdReportArray"
                                                                placeholder="Choose Product Store">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Open Date Time
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <input type="date" class="form-control"
                                                                [(ngModel)]="advanceRequest.OpenDateTime"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="OpenDateTime"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Open Date Time">

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="productId">Closed Date Time

                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="date" class="form-control"
                                                                [(ngModel)]="advanceRequest.ClosedDateTime"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="ClosedDateTime"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Closed Date Time">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="productId">Internal Comment

                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="advanceRequest.Comment"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="Comment"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Internal Comment">

                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="productId">Reason
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="advanceRequest.Reason"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="Reason"
                                                                aria-describedby="emailHelp" placeholder="Enter Reason">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="productId">Cust Estimated Milli Seconds

                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="advanceRequest.MilliSeconds"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="MilliSeconds"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Cust Estimated Milli Seconds">

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="productId">Cust Sequence Num
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="advanceRequest.CustSequenceNum"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="CustSequenceNum"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Cust Sequence Num">


                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="productId">Parent Cust Request ID
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="advanceRequest.ParentCustRequestID"
                                                                [ngModelOptions]="{standalone:true}"
                                                                id="exampleInputEmail1" name="ParentCustRequestID"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Parent Cust Request ID">


                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Billed
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <p-dropdown filter="true" optionlabel="label"
                                                                [(ngModel)]="advanceRequest.Billed"
                                                                [ngModelOptions]="{standalone:true}"
                                                                [options]="yesNOArray" id="exampleInputEmail1"
                                                                name="Billed" placeholder="Choose Billed">

                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-lg-12 col-12 main-submit-button"
                                                    style="margin-left: -25%;">
                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                        (click)="FindOrderRequest()">Find</button>
                                                    <button type="submit" class="btn btn-danger ml-2"
                                                        (click)="reset()">Reset</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="w3-card-4">

                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select pt-0">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                            <form class="w-100">
                                                <div class="port-header-contact create-lead font-medium mb-0 w-100 pl-0">
                                                    <div class="w3-card-4 classCardView mt-2 w-100">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import create-lead font-medium mb-0 suppliers-wrapper">
                                                            Search Results
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="findOrderList" [paginator]="true"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Cust Request ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Cust Request Type ID
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Product Store ID</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Cust Request Name</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Priority
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Cust Request Date
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Response Required Date
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            From Party ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Status ID</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Last Modified Date</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'100px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Reject</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'300px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Create Task From Request</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>



                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                                 class="account-button"  
                                                                                                (click)="summaryPage(product.custRequest.custRequestId, 
                                                                                                                    product.custRequest.custRequestName)">
                                                                                                {{product.custRequest.custRequestId}}</span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.custRequest.custRequestTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.custRequest.productStoreId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.custRequest.custRequestName}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.custRequest.priority}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.custRequest.custRequestDate
                                                                                        | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.custRequest.responseRequiredDate
                                                                                        | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}"
                                                                                         class="account-button"  
                                                                                        (click)="myProfilePage(product.custRequest.fromPartyId)">
                                                                                        {{product.custRequest.fromPartyId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.custRequest.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.custRequest.lastModifiedDate
                                                                                        | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                                        <button type="submit"
                                                                                            (click)="setCustRequestStatus(product)"
                                                                                            class="btn btn-secondary submit-btn ml-2">
                                                                                            Reject
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No
                                                                                                Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="exampleRequestList" tabindex="-1" role="dialog" aria-labelledby="exampleRequestListTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Request
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                        Request
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newrequestForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Cust Request Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="CustRequestTypeIDArray"
                                                            placeholder="Choose Cust Request Name"
                                                            formControlName="CustRequestTypeID">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="exampleInputEmail1">Cust Request Category ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="custRequestCategoryIdArray"
                                                            placeholder="Choose Cust Request Category ID"
                                                            formControlName="CustRequestCategoryID">

                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Requesting Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray"
                                                            placeholder="Choose Requesting Party"
                                                            formControlName="RequestingParty">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="exampleInputEmail1">Priority
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            placeholder="Choose Priority " [options]="PriorityListArray"
                                                            formControlName="Priority">

                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Request Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Enter Request Date "
                                                            aria-describedby="emailHelp" formControlName="RequestDate">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="productId">Response Required Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Response Required Date"
                                                            formControlName="ResponseRequiredDate">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Cust Request Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Choose Cust Request Name"
                                                            formControlName="CustRequestName">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="productId">Description

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Enter Description "
                                                            aria-describedby="emailHelp" formControlName="Description">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Maximum Amount Uom ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="currencyArray"
                                                            placeholder="Choose Maximum Amount Uom ID "
                                                            formControlName="MaximumAmountUomID">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="exampleInputEmail1">Product Store
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="productStoresListArray"
                                                            placeholder="Choose Product Store"
                                                            formControlName="ProductStore">
                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Sales Channel
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="salesChannelArray"
                                                            placeholder="Choose Sales Channel"
                                                            formControlName="SalesChannel">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="exampleInputEmail1">Fulfill Contact Mech ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ContactMechIdArray"
                                                            placeholder="Choose Fulfill Contact Mech ID "
                                                            formControlName="FulfillContactMechID">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>




                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Currency
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="currencyArray" placeholder="Choose Currency"
                                                            formControlName="Currency">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="productId">Open Date Time

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Open Date Time"
                                                            formControlName="OpenDateTime">

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Closed Date Time

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Enter Closed Date Time"
                                                            aria-describedby="emailHelp"
                                                            formControlName="ClosedDateTime">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="productId">Internal Comment

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Enter Internal Comment"
                                                            aria-describedby="emailHelp"
                                                            formControlName="InternalComment">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Reason

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Reason"
                                                            formControlName="Reason">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="productId">Cust Estimated Milli Seconds
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter  Cust Estimated Milli Seconds"
                                                            formControlName="CustEstimatedMilliSeconds">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Cust Sequence Num

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Cust Sequence Num"
                                                            formControlName="CustSequenceNum">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="productId">Parent Cust Request ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ParentCustRequestIdArray"
                                                            placeholder="Choose ParentCustRequestID"
                                                            formControlName="ParentCustRequestID">

                                                        </p-dropdown>


                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Billed
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            placeholder="Choose Billed" [options]="yesNOArray"
                                                            formControlName="Billed">

                                                        </p-dropdown>

                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="productId">Story

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Enter Story" aria-describedby="emailHelp"
                                                            formControlName="Story">

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="productId">Web Site ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Enter Web Site ID" aria-describedby="emailHelp"
                                                            formControlName="WebSiteID">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -23%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createRequest()">Submit</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ngx-spinner></ngx-spinner>