import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { FinancialService } from 'src/app/services/financial.service';
import {TreeNode} from 'primeng/api';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Location } from '@angular/common';

import {
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';

import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';
import { TrainingApprovalService } from 'src/app/human-resource/training/training-approvals/training-approval.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';


declare const $: any;

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-edit-fixed-asset',
  templateUrl: './edit-fixed-asset.component.html',
  styleUrls: ['./edit-fixed-asset.component.css']
})
export class EditFixedAssetComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  total=0;
  activeTabbing = 1;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  options: any;
  public trainingSize = {
    "pageNo": this.pageNo,
    "pageSize": this.pageSize
  };
 

  trainingApprovalForm: FormGroup;
  dateIdArray = [
    {
      label: 'Less Than',
      value: 'LessThan'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },

    {
      label: 'Greater Than',
      value: 'GreaterThan'
    },
    {
      label: 'Greater Than Equal To',
      value: 'GreaterThanEqualTo'
    },
    {
      label: 'Less Than Equal To',
      value: 'LessThanEqualTo'
    },
    {
      label: 'Is Empty',
      value: 'IsEmpty'
    }

  ];
  opportunityIdArray = [
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];
  calendarValue: any;
  trainingApproval: any;
  trainingApprovalList: any[];
  trainingClassTypes: any[];
  isForm: boolean;
  isButton: boolean;
  isUpdate: boolean;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  calendarEventForm: FormGroup;
  partyIds: any[];
  workEffortId: string;
  partyId: string;
  date: {
    startDate: string,
    endDate: string
  };
  assignList: any[];

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonB') closebuttonB;
  @ViewChild('closebuttonC') closebuttonC;
  @ViewChild('closebuttonD') closebuttonD;
  @ViewChild('closebuttonG') closebuttonG;
  @ViewChild('closebuttonF')closebuttonF;
  @ViewChild('closebuttonJ')closebuttonJ;
  activeCategory = 1;
  resData: any;
  
  editFixedAsset: any;
  linkProduct: FormGroup;
  addStandardCost: FormGroup;
  addIdentification: FormGroup;
  addRegistration: FormGroup;
  addMeterReadings: FormGroup;
  addMaintenance: FormGroup;
  addAssignment: FormGroup;
  addDepreciation: FormGroup;
  addGL: FormGroup;
  products3: any;
 
  search= "";
  advanceSearch: {

    assetType: string,
    fixedAssetId: string,
    fixedAssetIdSearchType: string,
    fixedAssetName: string,
    fixedAssetNameSearchType: string


  };
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
 
  fixedAssetsInitialDataArray: any[] = [];
  getFixedAssets: any;
  show: boolean;
  createFixAssetForm: FormGroup;
  fixedAssetTypes: any[];
  classEnumId: any[];
  calendar: any[];

  productIds: any[];
  fixAssetIds: any[];
  uomIds: any[];
  roleTypeIds: any[];
  facilityId: any[];
  facilityLocation: any[];
  fixAssetId: string;
  fixAsset: any;
  editMode: boolean;
  fixedAssetId: any;
  fixedAssetProductData: any;
  productTypeIds: any[];
  actualfromdate: string;
  thrufromdate: string;
  showProduct: boolean;
  getEstimateFrom: string;
  getEstimateTo: string;
  StandardCostList: any;
  showStandard: boolean;
  StandardCostTypeArray: any[]=[];
  StandardCostType: any;
  currencyArray: any[]=[];
  actualStandardfromdate: string;
  thruStandardfromdate: string;
  getStandardEstimateFrom: string;
  getStandardEstimateTo: string;
  IdentificationsList: any;
  showIdentification: boolean;
  IdentificationsType: any;
  IdentificationsTypeArray: any[]=[];
  FixedAssetRegistrationList: any;
  showRegist: boolean;
  actualRegisfromdate: string;
  thruRegisfromdate: string;
  regDate: string;
  registrationDate: string;
  getRegEstimateTo: string;
  getRegEstimateFrom: string;
  regFromDate: any;
  AssignmentList: any;
  showAssign: boolean;
  statusIds: any;
  actualAssignfromdate: string;
  thruAssignfromdate: string;
  regAssignDate: string;
  assignFromDate: any;
  getAssignEstimateFrom: string;
  getAssignEstimateTo: string;
  registrationAssignDate: string;
  MeterReadingList: any;
  intervalMeterType: any;
  intervalMeterTypeArray: any[]=[];
  actualMeterRegisfromdate: string;
  meterShow: boolean;
  getRegMeterEstimateFrom: string;
  meterReadingDate: any;
  DepreciationCustomMethodList: any;
  actualDepromdate: string;
  thruDepfromdate: string;
  DepreciationCustomMethodArray: any[]=[];
  ListFixedAssetMaints: any;
  
  createTax:FormGroup;
  exemption: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  getGeoTy: any;
  getGeoTyArray: any[]=[];
  party: any;
  geo: any;
 

  TaxAuthorities: any;
  AssignedTaskList: any;
  StatusList: any;
  StatusListArray: any[]=[];
  MaintenanceType: any;
  MaintenanceTypeArray: any[]=[];
  MaintenanceTemplateId: any;
  MaintenanceTemplateIdArray: any[]=[];
  
  PurchaseOrderId: any;
  PurchaseOrderIdArray: any[]=[];
  IntervalUom: any;
  IntervalUomArray: any[]=[];
  ActualCompletionDate: any;
  ActualStartDate: any;
  EstimatedCompletionDate: any;
  EstimatedStartDate: any;
  createTaskList:FormGroup;
  GeoLocation: any;
  FixedAssetChildren: TreeNode[];
  calendarEventForm1: FormGroup;
  EventType: any;
  EventTypeArray: any[]=[];
  ScopeList: any;
  ScopeListArray: any[]=[];
  PartyId: any;
  PartyIdArray: any[]=[];
  ParticipantsForm: FormGroup;
  CalenderRoleArray: any[]=[];
  CalenderRole: any;
  estStart: string;
  estCompletion: string;
  CalStatusList: any;
  CalStatusListArray: any[]=[];
  participants: any;
  participantsList: any;
  ParticipantsList: any;
  productMaintAll: any;
  productMaintAllArray: any[]=[];
  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,
    readonly trainingApprovalService: TrainingApprovalService,
    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
   
    readonly _AccountingApService: AccountingApService,
    readonly _WarehouseService: WarehouseService,
    readonly addSkillService: AddSkillService,
    private _location: Location,
    ) {
      this.productTypeIds = [];
      this.statusIds=[];
    this.createFixAssetForm = this._FormBuilder.group({
      acquireOrderId: [''], acquireOrderItemSeqId: [''],actualEndOfLife: [''],
      calendarId: [''],classEnumId: [''], dateAcquired: [''],dateLastServiced: [''],dateNextService: [''],
      depreciation: [''],expectedEndOfLife: [''],fixedAssetId: [''],fixedAssetName: [''],
      fixedAssetTypeId: ['COMPUTER_HARDWARE'],
      instanceOfProductId: [''],locatedAtFacilityId: [''],locatedAtLocationSeqId: [''],
      parentFixedAssetId: [''],partyId: [''],productionCapacity: [''],purchaseCost: [''],
      roleTypeId: [''],
      salvageValue: [''],
      serialNumber: [''],
      uomId: ['']
    });
    this.linkProduct = this._FormBuilder.group({
      productID: [''],
      typeUsage: [''],
      fromDate: [''],
      thruDate: [''],
      comments: [''],
      seqNum: [''],
      quantity: [''],
      quantityUOMID: [''],
    });
    this.addStandardCost = this._FormBuilder.group({
      costType: [''],
      fromDate: [''],
      thruDate: [''],
      amountUOMID: [''],
      amount: [''],
    });
    this.addIdentification = this._FormBuilder.group({
      identificationType: [''],
      value: [''],
    });
    this.addRegistration = this._FormBuilder.group({
      fromDate: [''],
      thruDate: [''],
      regDate: [''],
      govAgency: [''],
      regNumber: [''],
      licenseNumber: [''],
    });
    this.addMeterReadings = this._FormBuilder.group({
      meterTypeID: [''],
      readingDate: [''],
      meterValue: [''],
      enumID: [''],
      workEffortID: [''],
    });
    this.addMaintenance = this._FormBuilder.group({
      startDate: [''],
      compDate: [''],
      status: [''],
      maintenanceType: [''],
      proMaintenance: [''],
      maintenanceTemp: [''],
      meterType: [''],
      intervalQuantity: [''],
      intervalUOM: [''],
      orderID: [''],
      actualStartDate: [''],
      actualCompDate: [''],
      partyID: [''],
    });
    this.addAssignment = this._FormBuilder.group({
      partyID: [''],
      roleTypeID: [''],
      fromDate: [''],
      throughDate: [''],
      allocatedDate: [''],
      statusID: [''],
      comments: [''],
    });
    this.addDepreciation = this._FormBuilder.group({
      custMethod: [''],
      fromDate: [''],
      throughDate: [''],
    });
    this.addGL = this._FormBuilder.group({
      glAcc: [''],
      accumulatedDep: [''],
      depGLAcc: [''],
      profitAcc: [''],
      lossAcc: [''],
    });
    this.calendarEventForm1 = this._FormBuilder.group({
      eventName: [''],
      desc: [''],
      eventType: [''],
      status: [''],
      scope: [''],
      startDate: [''],
      compDate: [''],
    });
    this.date = {
      startDate: '',
      endDate: ''
    };
    this.assignList = [];
    this.trainingApprovalList = [];
    this.trainingClassTypes = [];
    this.isForm = false;
    this.isUpdate = false;
    this.partyIds = [];
    this.calendarEventForm = this._FormBuilder.group({
      description: ['', [Validators.required]],
      estimatedCompletionDate: [''],
      estimatedStartDate: [''],
      workEffortName: ['']
    });
    this.partyId = '';
    this.workEffortId = '';
    this.createTaskList = this._FormBuilder.group({
      EstimatedStartDate: [''],
      EstimatedCompletionDate:[''],
      Status:[''],
      MaintenanceType:[''],
      ProductMaintenance:[''],
      IntervalMeterType: [''],
      MaintenanceTemplate:[''],
      PurchaseOrderID:[''],
      IntervalQuantity:[''],
      PartyID:[''],
      IntervalUom:[''],
      ActualCompletionDate:[''],
      ActualStartDate:['']
     
      
    });
    this.ParticipantsForm = this._FormBuilder.group({
      PartyId: [''],
      RoleTypeID: [''],
    });
  }

 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.fixedAssetId = params["fixedAssetId"];
    });
   // this.fixedAssetId = this._ActivatedRoute.snapshot.queryParams.fixedAssetId;

    this.fixedAssetsInitialData();

    this.getInitialData();this.getProductIds(); this.getPartyIds();this.getFixAssetIds();this.getUomIds();
    this.getRoleTypeIds();this.getFacilityIds();
    this.createFixAssetForm.controls.locatedAtFacilityId.valueChanges.subscribe(data => {
      if (data) {
        this.getFacilityLocations(data);
      }
    });
    this.isButton = true;
    this.edit();
    this.getFixedAssetChildren();
    this.fixedAssetProduct();
    this.getDepreciationCustomMethod();
    this.getProductTypeIds();
    this.getStandardCostList();
    this.getStandardCostType();
    this.getCurrency();
    this.getIdentificationsList();
    this.getIdentificationsType();
    this.getFixedAssetRegistrationList();
    this.getAssignmentList();
    this.getMeterReadingList();
    this.getStatusIds();
    this.getIntervalMeterType();
    this.getDepreciationCustomMethodList();
    this.getEvents();
    this.getListFixedAssetMaints();
    this.getGeoLocation();
    //this.getTrainingApprovalList();
    this.options = {

      defaultDate: '2017-02-01',
      plugins: [dayGridPlugin],
      initialView: 'dayGridMonth',

      header: {
        left: 'prev,next',
        center: 'title',
        right: 'timeGridMonth,timeGridWeek,timeGridDay'
      }
    }
    this.getPartyIds();
  
    this.getAssignedTaskList();
    this.assetMaintenanceStatus();
    this.getMaintenanceType();
    this.getMaintenanceTemplateId();
    this.getIntervalMeterType();
    this.getPurchaseOrderId();
    this.getPartyId();
    this.getIntervalUom();
   
    this.getCalenderRole();
    this.getPartyIdList();
    this.getScopeList();
    this.getEventType();
    this.getScopeList();
    this.getCalStatusList();
    this.productMaint();
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/hub/Dashboard']);
}
  productMaint() {
    this.spinner.show();
    this.accountsService.productMaint(this.fixedAssetId).subscribe(res => {
      this.productMaintAll = res.data[0].data;
      this.spinner.hide();
      for (const value of this.productMaintAll) {
        this.productMaintAllArray.push({
          label: value.productMaintSeqId,
          value: value.productMaintSeqId
        })
      }
    })
  }
  cancelCalenderEvent(): void {
    this.spinner.show();
   
    const requestData ={
      
        "currentStatusId": "CAL_CANCELLED",
        "workEffortId": this.workEffortId
      
      }
     
    this.myContactsService.cancelCalenderEvent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
      
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  deleteMyPortalAParticipants(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
         

"fromDate": this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
"partyId":product.partyId,
"roleTypeId": product.roleTypeId,
"statusId": product.statusId,
"workEffortId": this.workEffortId
         
        }
        this.myContactsService.deleteMyPortalAParticipants(req).subscribe(res => {
        
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
getCalStatusList() {
  this.spinner.show();
  this.accountsService.getCalStatusList().subscribe(res => {
    this.CalStatusList = res.data[0].StatusItem;
    this.spinner.hide();
    for (const value of this.CalStatusList) {
      this.CalStatusListArray.push({
        label: value.transitionName,
        value: value.statusIdTo
      })
    }
  })

}

getParticipants() {
  this.spinner.show();
  this.myContactsService.getParticipants(this.workEffortId).subscribe(res => {
    this.ParticipantsList = res.data[0].data;
    this.spinner.hide();

  })
}
   getCalenderRole() {
    this.spinner.show();
    this.myContactsService.getCalenderRole().subscribe(res => {
      this.CalenderRole = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CalenderRole) {
        this.CalenderRoleArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
   createMyPortalParticipants() {
    this.spinner.show();
    const formData = {
      "partyId": this.ParticipantsForm.value.PartyId,
      "roleTypeId": this.ParticipantsForm.value.RoleTypeID,
      "statusId": "PRTYASGN_ASSIGNED",
      "workEffortId": this.workEffortId
   
    }
    this.myContactsService.createMyPortalParticipants(formData).subscribe((res: any) => {
  
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.ParticipantsForm.reset();
        this.getParticipants();
        this.isForm = false;
        this.isUpdate = false;
        this.activeCategory=10;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  
  }
   getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }

  submit(): void {
    this.spinner.show();
    if (this.isUpdate) {
      this.updateMyPortalCalendarEvent();

    } else {
      this.createMyPortalCalendarEvent();
    }
  }
   updateMyPortalCalendarEvent(): void {
    this.spinner.show();
    const fromActualdate=this.calendarEventForm1.get('startDate').value;
    this.estStart=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.calendarEventForm1.get('compDate').value;
    this.estCompletion=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "currentStatusId": this.calendarEventForm1.value.status,
      "description": this.calendarEventForm1.value.desc ,
      "estimatedCompletionDate": this.datePipe.transform(this.calendarEventForm1.value.compDate,"yyyy-MM-dd"),
      "estimatedCompletionDate_c_compositeType": "Timestamp",
      "estimatedCompletionDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.compDate,"hh"),
      "estimatedCompletionDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.compDate,"mm"),
      "estimatedStartDate": this.datePipe.transform(this.calendarEventForm1.value.startDate,"yyyy-MM-dd"),
      "estimatedStartDate_c_compositeType": "Timestamp",
      "estimatedStartDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.startDate,"hh"),
      "estimatedStartDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.startDate,"mm"),
      "fixedAssetId": this.fixedAssetId,
      "partyId": "admin",
      "roleTypeId": "CAL_OWNER",
      "scopeEnumId":this.calendarEventForm1.value.scope,
      "start": "",
      "statusId": "PRTYASGN_ASSIGNED",
      "workEffortName":this.calendarEventForm1.value.eventName ,
      "workEffortTypeId":this.calendarEventForm1.value.eventType ,
  
      "actualCompletionDate": this.datePipe.transform(this.calendarEventForm1.value.actualCompletionDate,"yyyy-MM-dd"),
      "actualCompletionDate_c_compositeType": "Timestamp",
      "actualCompletionDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.actualCompletionDate,"hh"),
      "actualCompletionDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.actualCompletionDate,"mm"),
      "actualStartDate": this.datePipe.transform(this.calendarEventForm1.value.actualStartDate,"yyyy-MM-dd"),
      "actualStartDate_c_compositeType": "Timestamp",
      "actualStartDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.actualStartDate,"hh"),
      "actualStartDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.actualStartDate,"mm"),
      "workEffortId": this.workEffortId,
  
      }
     
    this.myContactsService.updateMyPortalCalendarEvent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.calendarEventForm1.reset();
        this.getEvents();
       this.isUpdate = false;
        this.isForm = false;
        this.activeCategory=10;
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
   createMyPortalCalendarEvent(): void {
    this.spinner.show();
    const fromActualdate=this.calendarEventForm1.get('startDate').value;
    this.estStart=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.calendarEventForm1.get('compDate').value;
    this.estCompletion=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    
    const requestData ={
  
      "currentStatusId": this.calendarEventForm1.value.status,
      "description": this.calendarEventForm1.value.desc ,
      "estimatedCompletionDate": this.datePipe.transform(this.calendarEventForm1.value.compDate,"yyyy-MM-dd"),
      "estimatedCompletionDate_c_compositeType": "Timestamp",
      "estimatedCompletionDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.compDate,"hh"),
      "estimatedCompletionDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.compDate,"mm"),
      "estimatedStartDate": this.datePipe.transform(this.calendarEventForm1.value.startDate,"yyyy-MM-dd"),
      "estimatedStartDate_c_compositeType": "Timestamp",
      "estimatedStartDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.startDate,"hh"),
      "estimatedStartDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.startDate,"mm"),
      "fixedAssetId": this.fixedAssetId,
      "partyId": "admin",
      "roleTypeId": "CAL_OWNER",
      "scopeEnumId":this.calendarEventForm1.value.scope,
      "start": "",
      "statusId": "PRTYASGN_ASSIGNED",
      "workEffortName":this.calendarEventForm1.value.eventName ,
      "workEffortTypeId":this.calendarEventForm1.value.eventType ,
  
      }
     
    this.myContactsService.createMyPortalCalendarEvent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.calendarEventForm1.reset();
        this.getEvents();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
   getScopeList() {
    this.spinner.show();
    this.myContactsService.getScopeList().subscribe(res => {
      this.ScopeList = res.data;
      this.spinner.hide();
      for (const value of this.ScopeList) {
        this.ScopeListArray.push({
          label: value.scopeData.description,
          value: value.scopeData.enumId
        })
      }
    })
  }
   getEventType() {
    this.spinner.show();
    this.addSkillService.getEventType().subscribe(res => {
      this.EventType = res.data[0].data;
      this.spinner.hide();
      for (const value of this.EventType) {
        this.EventTypeArray.push({
          label: value.description,
          value: value.workEffortTypeId
        })
      }
    })
  }

  getAssignedTaskList() {
    this.spinner.show();
    this.accountsService.getAssignedTaskList().subscribe(res => {
      this.AssignedTaskList = res.data;
      this.spinner.hide();
  
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getPurchaseOrderId() {
    this.spinner.show();
    this.accountsService.getPurchaseOrderId().subscribe(res => {
      this.PurchaseOrderId = res.data[0].PurchaseOrderData;
      this.spinner.hide();
      for (const value of this.PurchaseOrderId) {
        this.PurchaseOrderIdArray.push({
          label: value.orderId,
          value: value.orderId
        })
      }
    })
  }
  nodeSelect(event){
    
    this.fixedAssetId=event.node.data;
    this.activeCategory=2;
    this._Router.navigate(["assetMaint/fixed-asset/edit-fixed-asset"], { queryParams: { fixedAssetId: this.fixedAssetId } })
    this.fixedAssetsInitialData();

    this.getInitialData();this.getProductIds(); this.getPartyIds();this.getFixAssetIds();this.getUomIds();
    this.getRoleTypeIds();this.getFacilityIds();
  
    this.isButton = true;
    this.edit();
    this.getFixedAssetChildren();
    this.fixedAssetProduct();
    this.getDepreciationCustomMethod();
    this.getProductTypeIds();
    this.getStandardCostList();
    this.getStandardCostType();
    this.getCurrency();
    this.getIdentificationsList();
    this.getIdentificationsType();
    this.getFixedAssetRegistrationList();
    this.getAssignmentList();
    this.getMeterReadingList();
    this.getStatusIds();
    this.getIntervalMeterType();
    this.getDepreciationCustomMethodList();
    this.getEvents();
    this.getListFixedAssetMaints();
    this.getGeoLocation();
   
    this.getPartyIds();
  
    this.getAssignedTaskList();
    this.assetMaintenanceStatus();
    this.getMaintenanceType();
    this.getMaintenanceTemplateId();
    this.getIntervalMeterType();
    this.getPurchaseOrderId();
    this.getPartyId();
    this.getIntervalUom();
   
    this.getCalenderRole();
    this.getPartyIdList();
    this.getScopeList();
    this.getEventType();
    this.getScopeList();
    this.getCalStatusList();
  }
  
  getIntervalUom() {
    this.spinner.show();
    this.accountsService.getIntervalUom().subscribe(res => {
      this.IntervalUom = res.data;
      this.spinner.hide();
      for (const value of this.IntervalUom) {
        this.IntervalUomArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getIntervalMeterType() {
    this.spinner.show();
    this.accountsService.getIntervalMeterType().subscribe(res => {
      this.intervalMeterType = res.data[0].intervalMeterType;
      this.spinner.hide();
      for (const value of this.intervalMeterType) {
        this.intervalMeterTypeArray.push({
          label: value.productMeterTypeId,
          value: value.productMeterTypeId
        })
      }
    })
  }
  getMaintenanceTemplateId() {
    this.spinner.show();
    this.accountsService.getMaintenanceTemplateId().subscribe(res => {
      this.MaintenanceTemplateId = res.data[0].maintenanceTemplateData;
      this.spinner.hide();
      for (const value of this.MaintenanceTemplateId) {
        this.MaintenanceTemplateIdArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
  }
  getMaintenanceType() {
    this.spinner.show();
    this.accountsService.getMaintenanceType().subscribe(res => {
      this.MaintenanceType = res.data[0].maintenanceTypeData;
      this.spinner.hide();
      for (const value of this.MaintenanceType) {
        this.MaintenanceTypeArray.push({
          label: value.description,
          value: value.productMaintTypeId
        })
      }
    })
  }
  assetMaintenanceStatus() {
    this.spinner.show();
    this.accountsService.assetMaintenanceStatus().subscribe(res => {
      this.StatusList = res.data[0].statusData;
      this.spinner.hide();
      for (const value of this.StatusList) {
        this.StatusListArray.push({
          label: value.statusCode,
          value: value.statusId
        })
      }
    })
  }
  createFixedAssetMaintUpdateWorkEffort(): void {
    this.spinner.show();
    const ActualCompletionDate=this.createTaskList.get('ActualCompletionDate').value;
    this.ActualCompletionDate=this.datePipe.transform(ActualCompletionDate,"yyyy-MM-dd hh:mm:ss");
    const ActualStartDate=this.createTaskList.get('ActualStartDate').value;
    this.ActualStartDate=this.datePipe.transform(ActualStartDate,"yyyy-MM-dd hh:mm:ss");
    const EstimatedCompletionDate=this.createTaskList.get('EstimatedCompletionDate').value;
    this.EstimatedCompletionDate=this.datePipe.transform(EstimatedCompletionDate,"yyyy-MM-dd hh:mm:ss");
    const EstimatedStartDate=this.createTaskList.get('EstimatedStartDate').value;
    this.EstimatedStartDate=this.datePipe.transform(EstimatedStartDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "actualCompletionDate":this.ActualCompletionDate,
      "actualStartDate": this.ActualStartDate,
      "estimatedCompletionDate":  this.EstimatedCompletionDate,
      "estimatedStartDate":this.EstimatedStartDate,
      "fixedAssetId":'',
      "intervalMeterTypeId": this.createTaskList.value.IntervalMeterType,
      "intervalQuantity":this.createTaskList.value.IntervalQuantity,
      "intervalUomId": this.createTaskList.value.IntervalUom,
      "maintTemplateWorkEffortId": this.createTaskList.value.MaintenanceTemplate,
      "productMaintSeqId":this.createTaskList.value.ProductMaintenance,
      "productMaintTypeId": this.createTaskList.value.MaintenanceType,
      "purchaseOrderId": this.createTaskList.value.PurchaseOrderID,
      "quickAssignPartyId": this.createTaskList.value.PartyID,
      "scheduleWorkEffortId":'',
      "statusId": this.createTaskList.value.Status,

   
      }
     
    this.accountsService.createFixedAssetMaintUpdateWorkEffort(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonJ.nativeElement.click();
        this.getListFixedAssetMaints();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this._ToastrService.error(err.error.message);
      }
    }); }
 
  getStatusIds(): void {
    this.spinner.show();
    this._FinancialService.getInitialAssignmentData()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.statusIds = data.data.status.map(value => {
            return {
              label: value.description,
              value: value.statusId
            };
          })
        }
      });
     
  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  
  getIdentificationsType() {
    this.spinner.show();
    this.accountsService.getIdentificationsType().subscribe(res => {
      this.IdentificationsType = res.data[0].data;
      this.spinner.hide();
      for (const value of this.IdentificationsType) {
        this.IdentificationsTypeArray.push({
          label: value.description,
          value: value.fixedAssetIdentTypeId
        })
      }
    })
  }
  getStandardCostType() {
    this.spinner.show();
    this.accountsService.getStandardCostType().subscribe(res => {
      this.StandardCostType = res.data[0].data;
      this.spinner.hide();
      for (const value of this.StandardCostType) {
        this.StandardCostTypeArray.push({
          label: value.description,
          value: value.fixedAssetStdCostTypeId
        })
      }
    })
  }
  
  getProductTypeIds(): void {
    this.spinner.show();
    this._FinancialService.getProductInitialData()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.productTypeIds = data.data.fixedAssetProductTypes.map(value => {
            return {
              label: value.description,
              value: value.fixedAssetProductTypeId
            };
          });
        }
      });
     
  }
  onUpdate() {
    this.spinner.show();
    this._FinancialService.updateFixAsset({
      ...this.fixAsset, ...this.createFixAssetForm.value, ...{
        dateAcquired: this.createFixAssetForm.value.dateAcquired ? moment(this.createFixAssetForm.value.dateAcquired).format('YYYY-MM-DD HH:mm:ss') : '',
        dateLastServiced: this.createFixAssetForm.value.dateLastServiced ? moment(this.createFixAssetForm.value.dateLastServiced).format('YYYY-MM-DD HH:mm:ss') : '',
        dateNextService: this.createFixAssetForm.value.dateNextService ? moment(this.createFixAssetForm.value.dateNextService).format('YYYY-MM-DD HH:mm:ss') : '',
        actualEndOfLife: this.createFixAssetForm.value.actualEndOfLife ? moment(this.createFixAssetForm.value.actualEndOfLife).format('YYYY-MM-DD HH:mm:ss') : '',
        expectedEndOfLife: this.createFixAssetForm.value.expectedEndOfLife ? moment(this.createFixAssetForm.value.expectedEndOfLife).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.createFixAssetForm.controls.fixedAssetId.enable();
          this.createFixAssetForm.controls.fixedAssetId.updateValueAndValidity();
          this._Router.navigate(["assetMaint/fixed-asset/fixed-asset"])
          //this.closebutton.nativeElement.click();
        //  this.getFixAssetById();
        }
      });
 
  }
  edit(){
    if (this.fixedAssetId) {
      this._FinancialService.getFixAssetById(this.fixedAssetId)
        .then(data => {
          if (data.success) {
            setTimeout(() => {
            this.fixAsset = data.data;
            this.createFixAssetForm.patchValue({
              ...data.data, ...{
                dateAcquired: data.data.dateAcquired ? moment(data.data.dateAcquired).format('YYYY-MM-DD') : '',
                dateLastServiced: data.data.dateLastServiced ? moment(data.data.dateLastServiced).format('YYYY-MM-DD') : '',
                dateNextService: data.data.dateNextService ? moment(data.data.dateNextService).format('YYYY-MM-DD') : '',
                actualEndOfLife: data.data.actualEndOfLife ? moment(data.data.actualEndOfLife).format('YYYY-MM-DD') : '',
                expectedEndOfLife: data.data.expectedEndOfLife ? moment(data.data.expectedEndOfLife).format('YYYY-MM-DD') : '',
              }
            });
            this.createFixAssetForm.controls.fixedAssetId.disable();
            this.createFixAssetForm.controls.fixedAssetId.updateValueAndValidity();this.editMode = true;
            this.getFacilityLocations(data.data.locatedAtFacilityId);
         
          }, 2000); }
          
        
        });
    }
  }
  resetRegistartion(){
    this.showRegist=false;
    this.addRegistration.reset();
  }
  resetMeter(){
    this.addMeterReadings.reset();
    this.meterShow=false;
  }
  resetIdent(){
    this.showIdentification=false;
    this.addIdentification.reset();
  }
  resetStandard(){
    this.showStandard=false;
    this.addStandardCost.reset();
  }
  resetAssign(){
    this.showAssign=false;
    this.addAssignment.reset();
  }
  productReset(){
    this.showProduct=false;
    this.linkProduct.reset();
  }
  updateIdentificationData(product){
    this.showIdentification=true;
    this.addIdentification.reset();
      setTimeout(() => {
       
        const formValue = this.addIdentification;
        formValue.patchValue({
          identificationType:product.data.fixedAssetIdentTypeId,
          value: product.data.idValue,
        })
      
      }, 2000);
  
  }
  updateMeterData(product){
    this.meterShow=true;
    this.addMeterReadings.reset();
    this.meterReadingDate=product.readingDate;
    const fromdate=product.readingDate
    this.getRegMeterEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
      setTimeout(() => {
       
        const formValue = this.addMeterReadings;
        formValue.patchValue({
          meterTypeID: product.data.productMeterTypeId,
          readingDate:  this.getRegMeterEstimateFrom,
          meterValue:product.data.meterValue,
          enumID:product.data.readingReasonEnumId,
          workEffortID:product.data.workEffortId,
        })
      
      }, 2000);
  
  }
  updateRegistartion(product){
    this.showRegist=true;
    this.addRegistration.reset();
    this.regFromDate=product.fromDate;
      setTimeout(() => {
        const fromdate=product.fromDate
        this.getRegEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=product.thruDate
        this.getRegEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const registrationDate=product.registrationDate
        this.registrationDate=this.datePipe.transform(registrationDate,"yyyy-MM-dd");
        const formValue = this.addRegistration;
        formValue.patchValue({
          fromDate: this.getRegEstimateFrom,
          thruDate: this.getRegEstimateTo,
          regDate:this.registrationDate,
          govAgency:product.data.govAgencyPartyId,
          regNumber: product.data.registrationNumber,
          licenseNumber:product.data.licenseNumber,
        })
      
      }, 2000);
  
  }
  updateAssignMent(product){
    this.showAssign=true;
    this.addAssignment.reset();
    this.assignFromDate=product.fromDate;
      setTimeout(() => {
        const fromdate=product.fromDate
        this.getAssignEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=product.thruDate
        this.getAssignEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const registrationDate=product.allocatedDate
        this.registrationAssignDate=this.datePipe.transform(registrationDate,"yyyy-MM-dd");
        const formValue = this.addAssignment;
        formValue.patchValue({
          partyID: product.partyId,
          roleTypeID: product.roleTypeId,
          fromDate: this.getAssignEstimateFrom,
          throughDate:  this.getAssignEstimateTo,
          allocatedDate:  this.registrationAssignDate,
          statusID:  product.statusId,
          comments: product.comments,
          
        })
      
      }, 2000);
  
  }

  createFixedAssetDepMethod(): void {
    this.spinner.show();
   
    const fromActualdate=this.addDepreciation.get('fromDate').value;
    this.actualDepromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.addDepreciation.get('throughDate').value;
    this.thruDepfromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    
    const requestData ={
      "depreciationCustomMethodId": this.addDepreciation.value.custMethod,
      "fromDate":  this.actualDepromdate,
      "thruDate": this.thruDepfromdate,
      "fixedAssetId":this.fixedAssetId,
      

      }
     
    this.accountsService.createFixedAssetDepMethod(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonF.nativeElement.click();
        this.addDepreciation.reset();
        this.getDepreciationCustomMethodList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  updateFixedAssetRegistration(): void {
    this.spinner.show();
   
    const fromActualdate=this.addRegistration.get('fromDate').value;
    this.actualRegisfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.addRegistration.get('thruDate').value;
    this.thruRegisfromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const regDate=this.addRegistration.get('regDate').value;
    this.regDate=this.datePipe.transform(regDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "fixedAssetId":this.fixedAssetId,
      "fromDate": this.regFromDate,
      "govAgencyPartyId":this.addRegistration.value.govAgency,
      "licenseNumber":this.addRegistration.value.licenseNumber,
      "registrationDate": this.regDate,
      "registrationNumber": this.addRegistration.value.regNumber,
      "thruDate":this.thruRegisfromdate

      }
     
    this.accountsService.updateFixedAssetRegistration(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonC.nativeElement.click();
        this.getFixedAssetRegistrationList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    putAssignmentList(): void {
      this.spinner.show();
     
      const fromActualdate=this.addAssignment.get('fromDate').value;
      this.actualAssignfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.addAssignment.get('throughDate').value;
      this.thruAssignfromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      const regDate=this.addAssignment.get('allocatedDate').value;
      this.regAssignDate=this.datePipe.transform(regDate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
        "allocatedDate":  this.regAssignDate,
  "comments": this.addAssignment.value.comments,
  "fromDate":this.assignFromDate,
  "partyId": this.addAssignment.value.partyID,
  "roleTypeId": this.addAssignment.value.roleTypeID,
  "statusId":this.addAssignment.value.statusID,
  "thruDate":  this.thruAssignfromdate
  
        }
       
      this.accountsService.putAssignmentList(this.fixedAssetId,requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebuttonD.nativeElement.click();
          this.getFixedAssetRegistrationList(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
    postAssignmentList(): void {
      this.spinner.show();
     
      const fromActualdate=this.addAssignment.get('fromDate').value;
      this.actualAssignfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.addAssignment.get('throughDate').value;
      this.thruAssignfromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      const regDate=this.addAssignment.get('allocatedDate').value;
      this.regAssignDate=this.datePipe.transform(regDate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
        "allocatedDate":  this.regAssignDate,
  "comments": this.addAssignment.value.comments,
  "fromDate":this.actualAssignfromdate,
  "partyId": this.addAssignment.value.partyID,
  "roleTypeId": this.addAssignment.value.roleTypeID,
  "statusId":this.addAssignment.value.statusID,
  "thruDate":  this.thruAssignfromdate
  
        }
       
      this.accountsService.postAssignmentList(this.fixedAssetId,requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.closebuttonD.nativeElement.click();
          this.getFixedAssetRegistrationList(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
  createFixedAssetRegistration(): void {
    this.spinner.show();
   
    const fromActualdate=this.addRegistration.get('fromDate').value;
    this.actualRegisfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.addRegistration.get('thruDate').value;
    this.thruRegisfromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const regDate=this.addRegistration.get('regDate').value;
    this.regDate=this.datePipe.transform(regDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "fixedAssetId":this.fixedAssetId,
      "fromDate": this.actualRegisfromdate,
      "govAgencyPartyId":this.addRegistration.value.govAgency,
      "licenseNumber":this.addRegistration.value.licenseNumber,
      "registrationDate": this.regDate,
      "registrationNumber": this.addRegistration.value.regNumber,
      "thruDate":this.thruRegisfromdate

      }
     
    this.accountsService.createFixedAssetRegistration(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonC.nativeElement.click();
        this.getFixedAssetRegistrationList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    updateFixedAssetMeter(): void {
      this.spinner.show();
     
      const fromActualdate=this.addMeterReadings.get('readingDate').value;
      this.actualMeterRegisfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    
      const requestData ={
        "fixedAssetId":this.fixedAssetId,
        "meterValue": this.addMeterReadings.value.meterValue,
        "productMeterTypeId": this.addMeterReadings.value.meterTypeID,
        "readingDate":this.meterReadingDate,
       
  
        }
       
      this.accountsService.updateFixedAssetMeter(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebuttonG.nativeElement.click();
          this.getMeterReadingList(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
    createFixedAssetMeter(): void {
      this.spinner.show();
     
      const fromActualdate=this.addMeterReadings.get('readingDate').value;
      this.actualMeterRegisfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    
      const requestData ={
        "fixedAssetId":this.fixedAssetId,
        "meterValue": this.addMeterReadings.value.meterValue,
        "productMeterTypeId": this.addMeterReadings.value.meterTypeID,
        "readingDate": this.actualMeterRegisfromdate,
        "readingReasonEnumId":this.addMeterReadings.value.enumID,
        "workEffortId": this.addMeterReadings.value.workEffortID,
  
        }
       
      this.accountsService.createFixedAssetMeter(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.closebuttonG.nativeElement.click();
          this.getMeterReadingList(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }

  updateProductData(product){
    this.showProduct=true;
    this.linkProduct.reset();
      setTimeout(() => {
        const fromdate=product.fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=product.thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const formValue = this.linkProduct;
        formValue.patchValue({
      productID:product.productId,
      typeUsage: product.fixedAssetProductTypeId,
      fromDate: this.getEstimateFrom,
      thruDate:this.getEstimateTo,
      comments:product.comments,
      seqNum:product.sequenceNum,
      quantity:product.quantity,
      quantityUOMID:product.quantityUomId,
        })
      
      }, 2000);
  
  }
  
  updateStandardData(product){
    this.showStandard=true;
    this.addStandardCost.reset();
      setTimeout(() => {
        const fromdate=product.fromDate
        this.getStandardEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=product.thruDate
        this.getStandardEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const formValue = this.addStandardCost;
        formValue.patchValue({
          costType:product.data.fixedAssetStdCostTypeId,
          fromDate:  this.getStandardEstimateFrom,
          thruDate: this.getStandardEstimateTo,
          amountUOMID:product.data.amountUomId,
          amount: product.data.amount,
        })
      
      }, 2000);
  
  }
  putfixedAssetProduct(): void {
    this.spinner.show();
   
    const fromActualdate=this.linkProduct.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.linkProduct.get('thruDate').value;
    this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "comments": this.linkProduct.value.comments,
      "fixedAssetProductTypeId":this.linkProduct.value.typeUsage,
      "fromDate": this.actualfromdate,
      "productId": this.linkProduct.value.productID,
      "quantity": this.linkProduct.value.quantity,
      "quantityUomId":this.linkProduct.value.quantityUOMID,
      "sequenceNum": this.linkProduct.value.seqNum,
      "thruDate":this.thrufromdate

      }
     
    this.accountsService.putfixedAssetProduct(this.fixedAssetId,requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.fixedAssetProduct(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  postfixedAssetProduct(): void {
    this.spinner.show();
   
    const fromActualdate=this.linkProduct.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.linkProduct.get('thruDate').value;
    this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "comments": this.linkProduct.value.comments,
      "fixedAssetProductTypeId":this.linkProduct.value.typeUsage,
      "fromDate": this.actualfromdate,
      "productId": this.linkProduct.value.productID,
      "quantity": this.linkProduct.value.quantity,
      "quantityUomId":this.linkProduct.value.quantityUOMID,
      "sequenceNum": this.linkProduct.value.seqNum,
      "thruDate":this.thrufromdate

      }
     
    this.accountsService.postfixedAssetProduct(this.fixedAssetId,requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.fixedAssetProduct(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    updateFixedAssetStdCost(): void {
      this.spinner.show();
    
      const fromActualdate=this.addStandardCost.get('fromDate').value;
      this.actualStandardfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.addStandardCost.get('thruDate').value;
      this.thruStandardfromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
        "amount": this.addStandardCost.value.amount,
        "amountUomId": this.addStandardCost.value.amountUOMID,
        "fixedAssetId": this.fixedAssetId,
        "fixedAssetStdCostTypeId": this.addStandardCost.value.costType,
        "fromDate":  this.actualStandardfromdate,
        "thruDate":  this.thruStandardfromdate
  
        }
       
      this.accountsService.updateFixedAssetStdCost(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebuttonA.nativeElement.click();
          this.getStandardCostList(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
    createFixedAssetStdCost(): void {
      this.spinner.show();
    
      const fromActualdate=this.addStandardCost.get('fromDate').value;
      this.actualStandardfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.addStandardCost.get('thruDate').value;
      this.thruStandardfromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
        "amount": this.addStandardCost.value.amount,
        "amountUomId": this.addStandardCost.value.amountUOMID,
        "fixedAssetId": this.fixedAssetId,
        "fixedAssetStdCostTypeId": this.addStandardCost.value.costType,
        "fromDate":  this.actualStandardfromdate,
        "thruDate":  this.thruStandardfromdate
  
        }
       
      this.accountsService.createFixedAssetStdCost(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.closebuttonA.nativeElement.click();
          this.getStandardCostList(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
    
    cancelFixedAssetStdCost(product) {
    
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={
            "fixedAssetId":this.fixedAssetId,
  "fixedAssetStdCostTypeId":product.data.fixedAssetStdCostTypeId,
  "fromDate": product.fromDate,
  "view_INDEX_1": "",
  "view_SIZE_1": ""
           
          }
          this.accountsService.cancelFixedAssetStdCost(req).subscribe(res => {
            this.getStandardCostList();
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
  removefixedAssetProduct(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fixedAssetId":this.fixedAssetId,
          "fixedAssetProductTypeId": product.fixedAssetProductTypeId,
          "fromDate": product.fromDate,
          "productId":product.productId
         
        }
        this.accountsService.removefixedAssetProduct(req).subscribe(res => {
          this.fixedAssetProduct();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  removeFixedAssetIdent(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fixedAssetId":this.fixedAssetId,
          "fixedAssetIdentTypeId": product.data.fixedAssetIdentTypeId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
         
        }
        this.accountsService.removeFixedAssetIdent(req).subscribe(res => {
          this.getIdentificationsList();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  deleteFixedAssetRegistration(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fixedAssetId":this.fixedAssetId,
  "fromDate":product.fromDate,
  "view_INDEX_1": "",
  "view_SIZE_1": ""
         
        }
        this.accountsService.deleteFixedAssetRegistration(req).subscribe(res => {
          this.getFixedAssetRegistrationList();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  updateFixedAssetIdent(): void {
    this.spinner.show();
   
    const requestData ={
      "fixedAssetId": this.fixedAssetId,
      "fixedAssetIdentTypeId":this.addIdentification.value.identificationType,
      "idValue": this.addIdentification.value.value,

      }
     
    this.accountsService.updateFixedAssetIdent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonB.nativeElement.click();
        this.getIdentificationsList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  createFixedAssetIdent(): void {
    this.spinner.show();
   
    const requestData ={
      "fixedAssetId": this.fixedAssetId,
      "fixedAssetIdentTypeId":this.addIdentification.value.identificationType,
      "idValue": this.addIdentification.value.value,

      }
     
    this.accountsService.createFixedAssetIdent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonB.nativeElement.click();
        this.getIdentificationsList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    getFixedAssetRegistrationList() {
      this.spinner.show();
      this.accountsService.getFixedAssetRegistrationList(this.fixedAssetId).subscribe(res => {
        this.FixedAssetRegistrationList = res.data;
        this.spinner.hide();
    
      })
    }
    
    deleteAssignmentList(product) {
    
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={
            "fixedAssetId":this.fixedAssetId,
            "fromDate": product.fromDate,
            "partyId": product.partyId,
            "roleTypeId": product.roleTypeId,
           
          }
          this.accountsService.deleteFixedAssetRegistration(req).subscribe(res => {
            this.getAssignmentList();
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    
    deleteFixedAssetMeter(product) {
    
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={
            "fixedAssetId":this.fixedAssetId,
            "maintHistSeqId": product.data.maintHistSeqId,
            "productMeterTypeId":product.data.productMeterTypeId,
            "readingDate":product.readingDate,
            "view_INDEX_1": "",
            "view_SIZE_1": ""
           
          }
          this.accountsService.deleteFixedAssetMeter(req).subscribe(res => {
            this.getMeterReadingList();
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    getMeterReadingList() {
      this.spinner.show();
      this.accountsService.getMeterReadingList(this.fixedAssetId).subscribe(res => {
        this.MeterReadingList = res.data;
        this.spinner.hide();
    
      })
    }
    
      
    deleteFixedAssetDepMethod(product) {
    
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={
            "fixedAssetId":this.fixedAssetId,
            "depreciationCustomMethodId": product.data.depreciationCustomMethodId,
  "fromDate": product.fromDate,
  "view_INDEX_1": "",
  "view_SIZE_1": ""
           
          }
          this.accountsService.deleteFixedAssetDepMethod(req).subscribe(res => {
            this.getDepreciationCustomMethodList();
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    getDepreciationCustomMethodList() {
      this.spinner.show();
      this.accountsService.getDepreciationCustomMethodList(this.fixedAssetId).subscribe(res => {
        this.DepreciationCustomMethodList = res.data;
        this.spinner.hide();
    
      })
    }
    getAssignmentList() {
      this.spinner.show();
      this.accountsService.getAssignmentList(this.fixedAssetId).subscribe(res => {
        this.AssignmentList = res.data;
        this.spinner.hide();
    
      })
    }
  getIdentificationsList() {
    this.spinner.show();
    this.accountsService.getIdentificationsList(this.fixedAssetId).subscribe(res => {
      this.IdentificationsList = res.data;
      this.spinner.hide();
  
    })
  }
  getStandardCostList() {
    this.spinner.show();
    this.accountsService.getStandardCostList(this.fixedAssetId).subscribe(res => {
      this.StandardCostList = res.data;
      this.spinner.hide();
  
    })
  }
  getListFixedAssetMaints() {
    this.spinner.show();
    this.accountsService.getListFixedAssetMaints(this.fixedAssetId).subscribe(res => {
      this.ListFixedAssetMaints = res.data;
      this.spinner.hide();
  
    })
  }
  
  fixedAssetProduct() {
    this.spinner.show();
    this.accountsService.fixedAssetProduct(this.fixedAssetId).subscribe(res => {
      this.fixedAssetProductData = res.data;
      this.spinner.hide();
  
    })
  }
  
  
  getFixedAssetChildren() {
    this.spinner.show();
    this.accountsService.getFixedAssetChildren(this.fixedAssetId).subscribe(res => {
      this.FixedAssetChildren = res.data;
      this.spinner.hide();
  
    })
  }
  getGeoLocation() {
    this.spinner.show();
    this.accountsService.getGeoLocation(this.fixedAssetId).subscribe(res => {
      this.GeoLocation = res.data[0];
      this.spinner.hide();
  
    })
  }
  
  getDepreciationCustomMethod() {
    this.spinner.show();
    this.accountsService.getDepreciationCustomMethod().subscribe(res => {
      this.spinner.hide();
      const DepreciationCustomMethod = res.data[0].data;
      for (const value of DepreciationCustomMethod) {
        this.DepreciationCustomMethodArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
    })
   
  }
  fixedAssetsInitialData() {
    this.spinner.show();
    this.accountsService.fixedAssetsInitialData().subscribe(res => {
      this.spinner.hide();
      const fixedAssetTypes = res.data.fixedAssetTypes;
      for (const value of fixedAssetTypes) {
        this.fixedAssetsInitialDataArray.push({
          label: value.description,
          value: value.fixedAssetTypeId
        })
      }
    })
   
  }
  getFacilityLocations(facilityId: string): void {
    this.spinner.show();
    this._WarehouseService.getFacilityLocations(facilityId, { pageNo: 1, pageSize: 200 }, {
      aisle: '',
      area: '',
      level: '',
      locationSeqId: '',
      position: '',
      section: ''
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.facilityLocation = data.data.map(value => {
            return {
              label: value.locationSeqId,
              value: value.locationSeqId
            };
          });
        }
      });
     
  }
  getFacilityIds(): void {
    this.spinner.show();
    this._WarehouseService.getFacility()
      .then(data => {
        this.spinner.hide();
        this.facilityId = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }
  getUomIds(): void {
    this.spinner.show();
    this._AccountingApService.getTermType()
      .then(data => {
        this.spinner.hide();
        this.uomIds = data.data.uom.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
     
  }
  getRoleTypeIds(): void {
    this.spinner.show();
    this._FinancialService.getRoleTypeIds('all')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.roleTypeIds = data.data.map(value => {
            return {
              label: value.description,
              value: value.roleTypeId
            };
          });
        }
      });
     
  }
  getFixAssetIds(): void {
    this.spinner.show();
    this._FinancialService.getAssets({ pageNo: 1, pageSize: 200 }, { assetType: '', fixedAssetId: '', fixedAssetIdSearchType: 'Contains', fixedAssetName: '', fixedAssetNameSearchType: 'Contains' })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.fixAssetIds = data.data.map(value => {
            return {
              label: value.fixedAssetName,
              value: value.fixedAssetId
            }
          });
        }
      });
     
  }
  getInitialData(): void {
    this.spinner.show();
    this._FinancialService.getInitialAssetData()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.calendar = data.data.calendar.map(value => {
            return {
              label: value.description,
              value: value.calendarId
            };
          });
          this.classEnumId = data.data.classEnumId.map(value => {
            return {
              label: value.description,
              value: value.enumId
            };
          });
          this.fixedAssetTypes = data.data.fixedAssetTypes.map(value => {
            return {
              label: value.description,
              value: value.fixedAssetTypeId
            };
          });
        }
      });
     
  }
  getProductIds(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.productIds = data.data.map(value => {
            return {
              label: value.productId,
              value: value.productId
            };
          });
        }
      });
     
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.partyIds = data.data.PersonsGroups.map(value => {
            return {
              label: value.partyId,
              value: value.partyId
            };
          });
        }
      });
     
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }


  detailPage(id) {
    this.router.navigate(["/hr/training-approvals/training-summary"], { queryParams: { trainingId: id } })
  }
 
 
  getEvents(): void {
    this.spinner.show();
    
    switch (this.view) {
      case 'month': this.date = {
        startDate: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'day': this.date = {
        startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'week': this.date = {
        startDate: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
    }
    this.trainingApprovalService.getCalenderEntries(this.fixedAssetId)
      .then(data => {
        if (data.success) {
          
          this.spinner.hide();
          this.events = data.data.map(value => {
            console.log("events", this.events)
            console.log("value", value)
            return {
              ...value, ...{
                start: new Date(value.estimatedStartDate),
                end: new Date(value.estimatedCompletionDate),
                title: value.workEffortName,
                color: colors.red,
                actions: this.actions,
                allDay: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
                draggable: true,
              }
            }
          });
        }
      });
     
  } 




 
  cancel(): void {
    this.calendarEventForm.reset();
    this.isForm = false;
    this.isUpdate = false;
  }

 /*  assignEvent(): void {
    this.spinner.show();
    this.trainingApprovalService.assignTraining({
      fromDate: this.calendarEventForm.value.estimatedStartDate ? moment(this.calendarEventForm.value.estimatedStartDate).format('YYYY-MM-DD HH:mm:ss') : '',
      partyId: this.partyId,
      thruDate: this.calendarEventForm.value.estimatedCompletionDate ? moment(this.calendarEventForm.value.estimatedCompletionDate).format('YYYY-MM-DD HH:mm:ss') : '',
      trainingClassTypeId: this.calendarEventForm.value.workEffortName,
      workEffortId: this.workEffortId
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Assigned');
          this.calendarEventForm.reset();
          this.isForm = false;
          this.isUpdate = false;
          this.spinner.hide();
          this.trainingApprovalForm.patchValue({ workEffortId: this.workEffortId });
          this.getTrainingApprovalList();
          this.activeTabbing = 2;
        }
      });
     
  } */
 /*  update(): void {
    this.spinner.show();
    this.trainingApprovalService.updateTrainingCalendar(this.workEffortId, {
      ...this.calendarEventForm.value, ...{
        estimatedCompletionDate: this.calendarEventForm.value.estimatedCompletionDate ? moment(this.calendarEventForm.value.estimatedCompletionDate).format('YYYY-MM-DD HH:mm:ss') : '',
        estimatedStartDate: this.calendarEventForm.value.estimatedStartDate ? moment(this.calendarEventForm.value.estimatedStartDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Event Updated Successfully');
          this.spinner.hide();
          this.calendarEventForm.reset();
          this.isForm = false;
          this.isUpdate = false;
          this.getEvents();
        }
      });
     
  } */
 /*  submit(): void {
    this.spinner.show();
    if (this.isUpdate) {
      this.update();
    } else {

      if (this.calendarEventForm.valid) {
        this.trainingApprovalService.createTrainingCalendar({
          ...this.calendarEventForm.value, ...{
            estimatedCompletionDate: this.calendarEventForm.value.estimatedCompletionDate ? moment(this.calendarEventForm.value.estimatedCompletionDate).format('YYYY-MM-DD HH:mm:ss') : '',
            estimatedStartDate: this.calendarEventForm.value.estimatedStartDate ? moment(this.calendarEventForm.value.estimatedStartDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }
        })
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Event Created Successfully');
              this.spinner.hide();
              this.calendarEventForm.reset();
              this.isForm = false;
              this.getEvents();
            }
          });
      }
      
    }
  
  } */



  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt" (click)="update()"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    }
   
  ];

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];
  
  

  activeDayIsOpen: boolean = true;


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {
    

    this.workEffortId = event.workEffortId
    this.calendarValue = {
      eventName: event.workEffortName,
      // desc: "",
      /// eventType:  "",
      // status:  event.,
      // scope: event.,
       startDate:  event.estimatedStartDate? moment(event.estimatedStartDate).format('yyyy-MM-DDTHH:mm') : '',
       compDate:  event.estimatedCompletionDate? moment(event.estimatedCompletionDate).format('yyyy-MM-DDTHH:mm') : '',
    };
    this.isForm = true;
    this.isUpdate = true;
   
    this.calendarEventForm1.patchValue({
      eventName: event.workEffortName,
     // desc: "",
     /// eventType:  "",
     // status:  event.,
     // scope: event.,
      startDate:  event.estimatedStartDate? moment(event.estimatedStartDate).format('yyyy-MM-DDTHH:mm') : '',
      compDate:  event.estimatedCompletionDate? moment(event.estimatedCompletionDate).format('yyyy-MM-DDTHH:mm') : '',

    
    });
    this.getParticipants();
   
  }

 /*  getEventById(): void {
    this.spinner.show();
    this.trainingApprovalService.getCalendarEventById(this.workEffortId)
      .then(data => {
        if (data.success) {
          this.assignList = data.data.participantsList;
        }
      });
      this.spinner.hide();
  } */
  addEvent(): void {
    this.isForm = true;
    this.isButton = false;
  }
  addNew(): void {
    this.isForm = true;
    this.isButton = false;
    this.isUpdate = false;
  }
  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
 
  addNewLeaves(): void {
    this.router.navigateByUrl('/add-new-leaves');
  }
 /*  getTrainingApprovalList(): void {
    this.spinner.show();
    this.trainingApprovalService.getApprovedTrainingList({ pageNo: 1, pageSize: 200 }, {
      ...this.trainingApprovalForm.value, ...{
        fromDateFrom: this.trainingApprovalForm.value.fromDateFrom ? moment(this.trainingApprovalForm.value.fromDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
        fromDateTo: this.trainingApprovalForm.value.fromDateTo ? moment(this.trainingApprovalForm.value.fromDateTo).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDateFrom: this.trainingApprovalForm.value.thruDateFrom ? moment(this.trainingApprovalForm.value.thruDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDateTo: this.trainingApprovalForm.value.thruDateTo ? moment(this.trainingApprovalForm.value.thruDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.trainingApprovalList = data.data;
        }
      })
    
  } */
  tabbingFunction(tab: number) {
    this.activeTabbing = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}


