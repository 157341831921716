<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/financial/{{agreementType}}/customer-agreeement">Agreements
                </span>
                <span class="color-gry cursor-pointer"
                    routerLink="/financial/{{agreementType}}/customer-agreeement/summary-customer-agreeement"
                    [queryParams]="{agreementID:agreement.agreementId}">>Agreements Summary </span>
                <span class="color-gry cursor-pointer"
                    routerLink="/financial/{{agreementType}}/customer-agreeement/agreement-summary-item"
                    [queryParams]="{agreementId :agreement.agreementId,agreementItemSeqId:agreement.agreementItemSeqId }">>Item
                    Summary </span>
                <span class="color-black pl-1"> > Item Product</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians" style="margin-top: 0px!important;background: #cce0e95e!important;">
                                          
                                                      <li [ngClass]="activeCategory==1?'active':''" ><a>
                                                        Item Product</a></li>
                                                
                                                    </ul>
                                                  </div>
                                             
                                                <div >
                                                    <br>
                                                    <div class="panel-body">
                                                        <ng-container *ngIf="isPurchasedAgreement;else sales">
                                                            <form [formGroup]="purchasedItemProductForm">
                                                                <div class="row">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -13%;
                                                                            font-size: 10px;">
                                                                                <label
                                                                                for="agreementInvoiceType">Product
                                                                                ID</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="productIds"
                                                                                    optionlabel="label"
                                                                                    formControlName="productId"
                                                                                    placeholder="select Product ">
                                                                                </p-dropdown>
                                                                            </div>
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                          
                                                                            font-size: 13px;">
                                                                                <label for="currency">Available From
                                                                                    Date</label>
                                                                            </div>
                                                                           
                                                                            <div class="col-lg-2">
                                                                                <p-calendar
                                                                                    formControlName="availableFromDate">
                                                                                </p-calendar>
                                                                            </div>
                                        
                                        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -13%;
                                                                            font-size: 10px;">
                                                                                <label for="agreementText">Available
                                                                                    Through
                                                                                    Date</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2">
                                                                                <p-calendar
                                                                                    formControlName="availableThruDate">
                                                                                </p-calendar>
                                                                            </div>
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                         
                                                                            font-size: 10px;">
                                                                                <label
                                                                                    for="agreementInvoiceType">Supplier
                                                                                    Pref Order ID</label>
                                                                            </div>
                                                                           
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="supplierRefOrderIds"
                                                                                    optionlabel="label"
                                                                                    formControlName="supplierProductId"
                                                                                    placeholder="select Supplier ">
                                                                                </p-dropdown>
                                                                            </div>
                                        
                                        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -13%;
                                                                            font-size: 10px;">
                                                                                <label for="agreementImage">Standard Lead
                                                                                    Time
                                                                                    Days</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                formControlName="standardLeadTimeDays">
                                                                            </div>
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                        
                                                                            font-size: 10px;">
                                                                                <label for="agreementImage">Min Order
                                                                                    Qty</label>
                                                                            </div>
                                                                           
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                formControlName="minimumOrderQuantity">
                                                                            </div>
                                        
                                        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -13%;
                                                                            font-size: 10px;">
                                                                                <label for="agreementImage">Order Qty
                                                                                    Increments</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                formControlName="orderQtyIncrements">
                                                                            </div>
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                       
                                                                            font-size: 10px;">
                                                                                <label for="agreementImage">Units Included
                                                                                </label>
                                                                            </div>
                                                                           
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                formControlName="unitsIncluded">
                                                                            </div>
                                        
                                        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -13%;
                                                                            font-size: 10px;">
                                                                                <label
                                                                                for="agreementInvoiceType">Quantity
                                                                                UomId</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="quantityUomIds"
                                                                                    optionlabel="label"
                                                                                    formControlName="quantityUomId"
                                                                                    placeholder="select Quantity">
                                                                                </p-dropdown>
                                                                            </div>
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                          
                                                                            font-size: 10px;">
                                                                                <label for="agreementImage">Last
                                                                                    Price</label>
                                                                            </div>
                                                                           
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                formControlName="lastPrice">
                                                                            </div>
                                        
                                        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -13%;
                                                                            font-size: 10px;">
                                                                                <label for="agreementImage">Shipping
                                                                                    Price</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                formControlName="shippingPrice">
                                                                            </div>
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                       
                                                                            font-size: 10px;">
                                                                                <label for="agreementImage">Supplier Product
                                                                                    Name</label>
                                                                            </div>
                                                                           
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                formControlName="supplierProductName">
                                                                            </div>
                                        
                                        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -13%;
                                                                            font-size: 10px;">
                                                                                <label for="agreementImage">Supplier Product
                                                                                    ID</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                formControlName="supplierProductId">
                                                                            </div>
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                        
                                                                            font-size: 10px;">
                                                                                <label for="agreementInvoiceType">Can
                                                                                    Drop
                                                                                    Ship</label>
                                                                            </div>
                                                                           
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="array"
                                                                                    optionlabel="label"
                                                                                    formControlName="canDropShip"
                                                                                    placeholder="select Drop">
                                                                                </p-dropdown>
                                                                            </div>
                                        
                                        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -13%;
                                                                            font-size: 10px;">
                                                                                <label for="agreementImage">Comments</label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                formControlName="comments">
                                                                            </div>
                                        
                                                                           
                                        
                                        
                                                                        </div>
                                                                    </div>
                                                                  
                                                                </div>




                                                                <div class="main-submit-button">
                                                                    <button type="submit" (click)="submitPurchased()"
                                                                        class="btn btn-secondary submit-btn">{{editMode ? 'Update' : 'Create'}}</button>
                                                                    <button class="btn btn-danger cursor-pointer ml-2"
                                                                        (click)="reset()">Cancel </button>
                                                                </div>
                                                            </form>
                                                        </ng-container>
                                                        <ng-template #sales>
                                                            <form [formGroup]="salesItemProductForm">
                                                                <div class="row">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -13%;
                                                                            font-size: 10px;">
                                                                                <label
                                                                                for="agreementInvoiceType">Product
                                                                                ID <span
                                                                                    class="text-danger">*</span></label>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                [options]="productIds"
                                                                                optionlabel="label"
                                                                                formControlName="productId"
                                                                                placeholder="select Product ">
                                                                            </p-dropdown>

                                                                            <small class="validate-field"
                                                                                *ngIf="!!salesItemProductForm.controls.productId.invalid && !!salesItemProductForm.controls.productId.touched">
                                                                                Product ID is required!</small>
                                                                            </div>
                                        
                                                                            <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                         
                                                                            font-size: 10px;">
                                                                                <label for="price">Price</label>
                                                                            </div>
                                                                           
                                                                            <div class="col-lg-2">
                                                                                <input type="text" formControlName="price"
                                                                                class="form-control">
                                                                            </div>
                                        
                                        
                                                                        </div>
                                                                    </div>
                                                                 
                                                                </div>
                                                                <div class="main-submit-button" style="    margin-right: 31%;">
                                                                    <button type="submit" (click)="submitSale()"
                                                                        class="btn btn-secondary submit-btn">{{editMode ? 'Update' : 'Create'}}</button>
                                                                    <button class="btn btn-danger cursor-pointer ml-2"
                                                                        (click)="reset()">Cancel </button>
                                                                </div>
                                                            </form>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 