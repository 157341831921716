import { Component, OnInit, OnDestroy,ViewChild } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { DropdownModule } from 'primeng/dropdown';
import { OrderManagementService } from '../order-management.service';
import { ToastrService } from 'ngx-toastr';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';


declare const $: any;
@Component({
  selector: 'app-sales-order-view-page',
  templateUrl: './sales-order-view-page.component.html',
  styleUrls: ['./sales-order-view-page.component.css']
})
export class SalesOrderViewPageComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;

  products3: any;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  orderId: string;
  noteList: any[];
  faTrash = faTrash;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ''
  };
  salesSummaryValue: any;
  createPaymentMethod:FormGroup;
  conversationList: any[];
  conversationForm: FormGroup;
  communicationEventPurposeIds: any[];
  partyIds: any[];
  activeCategory = 1;
  orderPaymentPreference: any;
  paymentGatewayResponses: any;
  paymnetMethods: any;
  paymnetMethodsArray: any[]=[];
  scheduleDelivery: FormGroup;
  statusIdArray: { label: string; value: string; }[];
  CubicMeasurementListDataArray: any[]=[];
  WeightMeasurementListDataArray: any[]=[];
  CubicMeasurementListData:any;
  WeightMeasurementListData:any;
  constructor(
    readonly _FormBuilder: FormBuilder,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _OrderManagementService: OrderManagementService,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _AccountingApService: AccountingApService,
    private _location: Location,
  ) {
    this.orderId = '';
    this.noteList = [];
    this.conversationList = [];
    this.scheduleDelivery = this._FormBuilder.group({
      cartons:[''],
      estimatedReadyDate: [''],
      orderId: [''],
      skidsPallets:[''],
      statusId: [''],
      totalCubicSize: [''],
      totalCubicUomId: [''],
      totalWeight: [''],
      totalWeightUomId: [''],
      unitsPieces: ['']
    })
    this.conversationForm = this._FormBuilder.group({
      communicationEventPrpTypId: [''],
      content: [''],
      orderId: [''],
      parentCommEventId: [''],
      partyIdTo: [''],
      subject: ['']
    });
    this.createPaymentMethod = this._FormBuilder.group({
      PaymentMethod: [''],
      Amount: [''],
   
    });
    this.communicationEventPurposeIds = [];
    this.partyIds = [];
    this.statusIdArray =[
      {
        label:"Supplier Submitted",
        value:"ODS_SUBMITTED",

      },
      {
        label:"Accepted",
        value:"ODS_ACCEPTED",

      },
      {
        label:"Schedule Approved",
        value:"ODS_APPROVED",

      },
      {
        label:"Delivery Arranged",
        value:"ODS_ARRANGED",

      },
      {
        label:"Delivery Cancelled",
        value:"ODS_CANCELLED",

      },
    ]
  }
  offerShowingLineColoumns = [
    { field: 'status', header: 'Role Type ID' },
    { field: 'name', header: 'Party ID' },
  ];

  offerShowingLineData = [{
    status: '[BILL_FROM_VENDOR] Bill-From-Vendor',
    name: '[admin] THE ADMINISTRATOR',
  },
  {
    status: '[BILL_FROM_VENDOR] Bill-From-Vendor',
    name: '[admin] THE ADMINISTRATOR',
  },
  {
    status: '[BILL_FROM_VENDOR] Bill-From-Vendor',
    name: '[admin] THE ADMINISTRATOR',
  },
  {
    status: '[BILL_FROM_VENDOR] Bill-From-Vendor',
    name: '[admin] THE ADMINISTRATOR',
  },
  {
    status: '[BILL_FROM_VENDOR] Bill-From-Vendor',
    name: '[admin] THE ADMINISTRATOR',
  },
  ];
  orderItemsColumns = [
    { field: 'status', header: 'Product' },
    { field: 'name', header: 'Status' },
    { field: 'email', header: 'Quantity' },
    { field: 'phone', header: 'Unit / List	' },
    { field: 'type', header: 'Adjustments' },
    { field: 'total', header: 'Sub Total' },
  ];

  orderItemsData = [{
    status: 'GZ-1000 - Tiny Gizmo',
    name: 'Reserve After Date	',
    email: 'US$15.99',
    phone: 'US$0.00',
    type: 'US$15.99  ',
    inven: 'Inventory',
    stats1: 'Required for SO	49',
    stats2: 'In Inventory  QOH	65 (ATP: 96)',
    stats3: 'In Inventory  QOH	65 (ATP: 96)',
    stats4: 'On Order	0',
    stats5: 'In Production	0',
    stats6: 'Unplanned	0',
    name1: 'Reserve After Date	',
    name2: 'Current Approved',
    name3: ' 30/11/20 14:57:00  Approved ',
    name4: '30/11/20 14:56:59  Created',
    qty1: 'Ordered	1	Ship Request	1',
    qty2: 'Cancelled	0	Qty Picked	0',
    qty3: 'Remaining	1	Qty Shipped ',
    qty4: 'Shortfalled	0	Outstanding	1',
    qty5: 'Invoiced	0	Returned	0',
  },
  {
    status: '',
    name: '',
    email: '1',
    phone: 'US$0.00',
    type: 'US$15.99  ',
    id: '10002',
    text_row: 'Inventory',
    rowstat1: 'Sales Tax (1% OFB _NA_ Tax) ',
    rowstat2: 'Not Applicable [_NA_] Rate 1	 ',
    rowstat3: '[00001] Sector A Officers Hill Colony',
    rowstat4: '10002 Ship Group 00001	1 ',
    adjust1: 'Adjustment',
    adjust2: 'Jurisdiction',
    adjust3: 'Ship Group',
  },
  ]
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
    this.orderId = this._ActivatedRoute.snapshot.queryParams.orderId;
    this.orderSummary();
    this.getNoteList();
    this.getConversationList();
    this.getCommunicationEvents();
    this.getPartyIds();
    this.getCubicMeasurementList();
    this.getWeightMeasurementList();
  }
  getCubicMeasurementList() {
    this.spinner.show();
    this.accountsService.getCubicMeasurementList().subscribe(res => {
      this.CubicMeasurementListData = res.data;
      this.spinner.hide();
      for (const value of this.CubicMeasurementListData) {
        this.CubicMeasurementListDataArray.push({
          label: value.description,
          value: value.totalCubicUomId
        })
      }
    })

  }
  getWeightMeasurementList() {
    this.spinner.show();
    this.accountsService.getWeightMeasurementList().subscribe(res => {
      this.WeightMeasurementListData = res.data;
      this.spinner.hide();
      for (const value of this.WeightMeasurementListData) {
        this.WeightMeasurementListDataArray.push({
          label: value.description,
          value: value.totalWeightUomId
        })
      }
    })

  }
  changesTab(){
    this.activeCategory = 7;

  }
  addOrderItemShipGroup() {
    this.spinner.show();
    this.accountsService.addOrderItemShipGroup(this.orderId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success('Created Successfully')
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  editOrderItemDetails() {
    this.spinner.show();
    this.accountsService.editOrderItemDetails(this.orderId).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "cartons": this.scheduleDelivery.value.cartons,
      "estimatedReadyDate": this.scheduleDelivery.value.estimatedReadyDate,
      "orderId": this.orderId,
      "skidsPallets":this.scheduleDelivery.value.skidsPallets,
      "statusId":this.scheduleDelivery.value.statusId,
      "totalCubicSize":this.scheduleDelivery.value.totalCubicSize,
      "totalCubicUomId":this.scheduleDelivery.value.totalCubicUomId,
      "totalWeight":this.scheduleDelivery.value.totalWeight,
      "totalWeightUomId":this.scheduleDelivery.value.totalWeightUomId,
      'unitsPieces':this.scheduleDelivery.value.unitsPieces,

    }
    this.accountsService.createOrUpdateOrderDeliverySchedule(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.scheduleDelivery.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/order/main-order']);
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  getCommunicationEvents(): void {
    this.spinner.show();
    this._OrderManagementService.getEventPurposeTypeId()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.communicationEventPurposeIds = data.data.map(value => {
            return {
              label: value.description,
              value: value.communicationEventPrpTypId
            };
          });
        }
      });
    
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
     
  }
  viewPayment(id){
    this.router.navigate(['financial/view-gateway-response'],{ queryParams: { id: id} });
}

addPaymentMethodToOrder(): void {
  this._OrderManagementService.addPaymentMethodToOrder(this.createPaymentMethod.value.Amount,this.orderId,this.createPaymentMethod.value.PaymentMethod).then(data => {
    if (data.success) {
      this._ToastrService.success("Created Successfully");
      this.orderSummary();
    }
  }, (err) => {
    for (const value of err.error.errors) {
      this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
    }
  });

}
  orderSummary(): void {
    this.spinner.show();
    this._OrderManagementService.orderSummary(this.orderId)
      .then(data => {
       
        this.salesSummaryValue = data.data;
        
        this.orderPaymentPreference = data.data.orderPaymentPreference.orderPaymentPreference;
        this.paymentGatewayResponses = data.data.orderPaymentPreference.paymentGatewayResponses;
        this.paymnetMethods=data.data.paymnetMethods;
        for (const value of this.paymnetMethods) {
          this.paymnetMethodsArray.push({
            label: value.description,
            value: value.paymentMethodId
          })
        }
        this.spinner.hide();
      });
    
  }
  authorize(data){
    this.router.navigate(['financial/authorize-transaction'],{ queryParams: { orderId: data.orderId,orderPaymentPreferenceId:data.orderPaymentPreferenceId,amount:data.maxAmount} });
  }
  capture(data){
    this.router.navigate(['financial/capture'],{ queryParams: { orderId: data.orderId,orderPaymentPreferenceId:data.orderPaymentPreferenceId,amount:data.maxAmount} });
  }
  changeOrder(statusId: string): void {
    this.spinner.show();
    this._OrderManagementService.setStatus(this.orderId, statusId)
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Status Changed');
          this.spinner.hide();
          this.orderSummary();
        }
      });
      this.spinner.hide();
  }
  getNoteList(): void {
    this.spinner.show();
    this._OrderManagementService.getNoteList(this.orderId)
      .then(data => {
        this.noteList = data.data;
        this.spinner.hide();
      });
     
  }
  deleteNote(noteId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._OrderManagementService.deleteNote(this.orderId, noteId)
        .then(data => {
          if (data.success) {
           
            this.getNoteList();
          }
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
   
     
  }
  updateNote(internalNote: string, noteId: string, note: string): void {
    this.spinner.show();
    this._OrderManagementService.updateNote(noteId, {
      internalNote, note,
      orderId: this.orderId
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Updated');
          this.getNoteList();
          this.spinner.hide();
        }
      });
      this.spinner.hide();
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  createConversation(): void {
    this.spinner.show();
    this._OrderManagementService.createConversation({
      ...this.conversationForm.value, ...{
        orderId: this.orderId
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.spinner.hide();
          $('#exampleOrder').modal('hide');
          this.getConversationList();
        }
      });
      this.spinner.hide();
  }
  getConversationList(): void {
    this.spinner.show();
    this._OrderManagementService.getConversationList(this.orderId)
      .then(data => {
        this.spinner.hide();
        this.conversationList = data.data;
      });
     
  }
  getOrderPDF(): void {
    this.spinner.show();
    this._OrderManagementService.getOrderPDF(this.orderId)
      .subscribe(value => {
        const blob = new Blob([value], { type: 'application/application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = `${this.orderId}.pdf`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }, (err) => {
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
          }
        }
      });
      this.spinner.hide();
  }
  printSlip(facilityId: string, shipmentId: string): void {
    this.spinner.show();
    this._OrderManagementService.generatePickingPDF(facilityId, this.orderId)
      .subscribe(value => {
        const blob = new Blob([value], { type: 'application/application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = `${shipmentId}.pdf`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }, (err) => {
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
          }
        }
      });
      this.spinner.hide();
  }

  downloadInvoice(invoiceId: string): void {
    this.spinner.show();
    this._AccountingApService.generateInvoicePDF(invoiceId).subscribe(value => {
      const blob = new Blob([value], { type: 'application/application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download = `${invoiceId}.pdf`;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }, (err) => {
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
    });
    this.spinner.hide();
  }
} 
