import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class EditAccountServices {

  constructor(readonly http: HttpClient) { }

  getAccountByPartyId(id):Observable<any>{
   
      const getAccountByPartyId = URLS.getAccountById.replace(":partyId",id);
     return this.http.get(getAccountByPartyId)
   }

  updatePartyGroup(id,formData):Observable<any>{
   
    const updatePartyInformation = URLS.updatePartyGroup.replace(":partyId",id);
      return this.http.put(updatePartyInformation,formData);
  }
  getCurrency(): Observable<any> {
    const getCurrency = URLS.getCurrency;
    return this.http.get(getCurrency);
  }
  getCrmStatus():Observable<any>{
    const getCrmStatus = URLS.getCRMStatus;
    return this.http.get(getCrmStatus);
  }

 }


