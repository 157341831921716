import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe, NgIf } from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-root-page',
  templateUrl: './new-root-page.component.html',
  styleUrls: ['./new-root-page.component.css']
})
export class NewRootPageComponent implements OnInit {
  activeCategory=5;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  createNewCompDocForm: FormGroup;
  contentIdDoc: any;
  contentRevisionSeqId: any;

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
private _location: Location,
  )
  {
    this.createNewCompDocForm=this._FormBuilder.group({
      compDocTemplateName:"",
      
    })

  }

  ngOnInit(): void {
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/hub/Dashboard']);
}
  createRootCompDocTemplate(): void {
    this.spinner.show();
    const requestData ={
      "contentName": this.createNewCompDocForm.value.compDocTemplateName,
      "contentTypeId": "COMPDOC_TEMPLATE",
      "rootTemplateContentId": "",
      "rootTemplateRevSeqId": ""
      }
     
    this.accountsService.createRootCompDocTemplate(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.contentIdDoc=res.data.contentId;
        this.contentRevisionSeqId=res.data.contentRevisionSeqId;
        this.router.navigate(['/content/compDoc/edit'], { queryParams: { contentId: this.contentIdDoc , contentRevisionSeqId: this.contentRevisionSeqId , compDoc: "created" } });
        this.spinner.hide();
        this.createNewCompDocForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}
openViewTree()
  {
    this.toastr.error("Content Id Not Found");
    // this._Router.navigate(['/content/compDoc/view-tree']);
  }
  openEdit()
  {
    this.toastr.error("Content Id Not Found");
    // this._Router.navigate(['/content/compDoc/edit']);
  }
  openApprovals()
  {
    if(this.contentIdDoc){
      this.router.navigate(['/content/compDoc/approvals'], { queryParams: { contentId: this.contentIdDoc , contentRevisionSeqId: this.contentRevisionSeqId } });
    }

  }
  openRevisions()
  {
    this.toastr.error("Content Id Not Found");
    // this._Router.navigate(['/content/compDoc/revisions']);
  }
  openCreateNewRoot()
  {
    this.toastr.error("Content Id Not Found");
    // this._Router.navigate(['/content/compDoc/new-root-compdoc-template']);
  }
  openViewWaitingApprovals()
  {
    this._Router.navigate(['/content/compDoc/view-waiting-approvals']);
  }


ngOnDestroy(): void {
  this.spinner.hide();
    }

  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  
  }


}
