import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  createUser: FormGroup;
  activeCategory = 2;
  securityGroup: any;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  rows = 50;
  pageNo=1;
  search="";
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  arraySecurity: any[]=[];
  partyId: any;
  loginId: any;
  show: boolean;
  enabled: any;
  curentPassword: any;
  url: string;
  constructor(readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService, readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder) {
      this.url='/users/user-list'
    this.createUser = this._FormBuilder.group({
      firstName: ['',[Validators.required]],
      lastName: ['',[Validators.required]],
      email: ['',[Validators.required]],
      password: ['',[Validators.required]],
      groupId: ['',[Validators.required]],
     
      
    });
   }
   ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.findGroupId();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params['partyId'];
      this.loginId = params['loginId'];
    });
    if(this.partyId && this.loginId){
      this.show=true;
      this.getUserIdSummary();
    }
    else{
      this.show=false
    }
  }
  getUserIdSummary(){
    this.spinner.show();
    this.accountsService.getUserId(this.partyId, this.loginId).subscribe((res:any)=> {
      setTimeout(() => {
        this.enabled=res.data.enabled;
        this.curentPassword=res.data.currentPassword 
        const formValue = this.createUser;
        formValue.patchValue({
      firstName: res.data.firstName,
      lastName: res.data.lastName,
      email:res.data.email,
      password: res.data.password,
      groupId: res.data.groupId,
  
  
        })
      
      }, 2000);
      this.spinner.hide();
  
    })
   
   }
  submit(): void {
    this.spinner.show();
if(this.createUser.value.password.length>=5){
    const requestData ={
      
        "email": this.createUser.value.email,
        "firstName": this.createUser.value.firstName,
        "groupId":this.createUser.value.groupId,
        "lastName":this.createUser.value.lastName,
        "password":this.createUser.value.password,
      }
     
    this.accountsService.createUser(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate([this.url])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }else{
    this.spinner.hide();
    this.toastr.error("Password must be at least 5 characters long.");
  } }
  update(): void {
    this.spinner.show();
    const requestData ={
      "enabled": this.enabled==true?'Y':'N',
        "email": this.createUser.value.email,
        "firstName": this.createUser.value.firstName,
        "groupId":this.createUser.value.groupId,
        "lastName":this.createUser.value.lastName,
        "password": this.curentPassword,
        "partyId": this.partyId,
        "userLoginId":  this.loginId
      }
    this.accountsService.updateUser(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate([this.url])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  findGroupId(){
    this.spinner.show();
   let  req={
      
        "description": "",
        "descriptionSearchType": "Contains",
        "groupId": "",
        "groupIdSearchType": "Contains"
      
     }
    this.accountsService.findGroupId(this.user,req).subscribe((res:any)=> {
      this.securityGroup = res.data;
      this.spinner.hide();
      for(const value of this.securityGroup){
        this.arraySecurity.push({
          label:value.groupId,
          value:value.groupId
        })
      }

    })
   
   }
  cancel(){
    this.router.navigate([this.url])
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
