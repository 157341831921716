import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-jobshop-material',
  templateUrl: './create-jobshop-material.component.html',
  styleUrls: ['./create-jobshop-material.component.css']
})
export class CreateJobshopMaterialComponent implements OnInit {
  createJob: FormGroup;
  activeCategory = 2;
  productListArray: any[]=[];
  workEffortId: any;
  routingTaskList: any;
  routingTaskListArray: any[]=[];
  productId: any;
  estimatedQuantity: any;
  workId: any;
  show: boolean;

  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
  
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) {
    this.createJob = this._FormBuilder.group({
      ProductID: [''],
      EstimatedQuantity: [''],
      RoutingTask: [''],


    });

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
      this.productId = params["productId"];
      this.estimatedQuantity = params["estimatedQuantity"];
      this.workId = params["workId"];
  
    });
    this.getProducts();
    this.getProductionMaterialRoutingTaskIds();
    if(this.productId) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.createJob;
      formValue.patchValue({
        ProductID: this.productId,
        EstimatedQuantity: this.estimatedQuantity,
        RoutingTask: this.workId,
       
      })
      }, 3000);
     
    }
    else {
      this.show = false;
    }

  }
  getProductionMaterialRoutingTaskIds() {
    this.spinner.show();
    
    this.productionService.getProductionMaterialRoutingTaskIds(this.workEffortId).subscribe(res => {
      this.routingTaskList = res.data;
      this.spinner.hide();
      for (const value of this.routingTaskList) {
        this.routingTaskListArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
    
  }
 
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
   
    });
  
  }
  
  onSubmit() {
    const requestData = {
      "estimatedQuantity":  this.createJob.value.EstimatedQuantity,
      "productId":  this.createJob.value.ProductID,
      "workEffortId":  this.createJob.value.RoutingTask

    }

    this.productionService.postProductionMaterial(this.workEffortId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId:this.workEffortId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdate() {
    const requestData = {
      "estimatedQuantity": this.createJob.value.EstimatedQuantity,
      "productId": this.createJob.value.ProductID,
      "workEffortId": this.createJob.value.RoutingTask

    }

    this.productionService.updateProductionMaterial(this.workEffortId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId:this.workEffortId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  cancelSubmit(){
    this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
