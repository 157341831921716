import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }


  getSearchedContacts(req): Observable<any> {
    const getMyContactList = URLS.getMyContactList;
    return this.http.post(getMyContactList,req);
  }

  exportContacts(): Observable<any> {
    return this.http.get(URLS.exportContacts, { responseType: 'blob' })
  }





}
