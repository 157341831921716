<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" routerLink="/financial/payable/payment-payables" class="cursor-pointer">Payment</span>
                <span class="color-black pl-1"> > Create Outgoing Payment</span>
                <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger buttonclass" 
                (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Create Outgoing Payment</a></li>
                       
                   
          
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="payablePaymentForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Organization Party ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="array"
                                                    formControlName="partyIdFrom" optionlabel="label"
                                                    placeholder="Select Party ID">
                                                </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">To Party ID
                                                    <span class="text-danger">*</span></label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="partyIds"
                                                optionlabel="label" placeholder="Select PartyID"
                                                formControlName="partyIdTo">
                                            </p-dropdown>
                                            <small class="text-danger"
                                                *ngIf="!!payablePaymentForm.controls.partyIdTo.invalid && !!payablePaymentForm.controls.partyIdTo.touched">To
                                                Party ID is required</small>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Payment Type</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="paymentTypeList"
                                                optionlabel="label" placeholder="Select Payment Type"
                                                formControlName="paymentTypeId">
                                            </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Payment Method ID</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="paymentMethods"
                                                optionlabel="label" placeholder="Select Payment Method ID"
                                                formControlName="paymentMethodId">
                                            </p-dropdown>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1"> Override Gl Account ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="GIAccounts"
                                                optionlabel="label" formControlName="overrideGlAccountId"
                                                placeholder="Select Override Gl Account ID">
                                            </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Amount <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="amount" aria-describedby="emailHelp"
                                                placeholder="Amount">
                                       
                                        <small class="text-danger"
                                            *ngIf="!!payablePaymentForm.controls.amount.invalid && !!payablePaymentForm.controls.amount.touched">Amount
                                            is required</small>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Comments</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="comments" aria-describedby="emailHelp"
                                                placeholder="Comments">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Reference No</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="paymentRefNum" aria-describedby="emailHelp"
                                                placeholder="Reference No">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="editMode">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1"> Currency</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="currencyUomID"
                                                optionlabel="label" formControlName="currencyUomId"
                                                placeholder="Select Currency">
                                            </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Actual Currency Amount</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                    formControlName="actualCurrencyAmount" aria-describedby="emailHelp"
                                                    placeholder="Actual Currency Amount">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="editMode">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1"> Actual Currency Uom ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="currencyUomID"
                                                optionlabel="label" formControlName="actualCurrencyUomId"
                                                placeholder="Select Currency">
                                            </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="effectiveDate">Effective Date</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-calendar placeholder="Select Date"
                                                        formControlName="effectiveDate"></p-calendar>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="editMode">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">File Account Trans ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="finAccountTransId" aria-describedby="emailHelp"
                                                placeholder="File Account Trans Id">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Status Id</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p>{{payablePaymentForm.get('statusId').value}}</p>
                                            </div>


                                        </div>
                                    </div>
                                    
                                 
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="
                                margin-left: -24%;">
                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn "
                                        (click)="submit()">{{editMode ? 'Update':'Create'}}</button>
                                  

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 