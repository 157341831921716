<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Week Id : [{{ this.calendarWeekId}}]</span> <span></span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''"><a>
                Week Information</a></li>





                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">


                                                <div *ngIf="activeCategory==1">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Week Info

                                                        </h4>
                                                        <span class="edit-buttons hCreate">
                               
                              <a  data-toggle="modal"
                              data-target="#exampleModalCenter" (click)="getProdWeekById()">Update</a>
                            </span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Calendar Week ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.calendarWeekId}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Description
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.description}}</span>

                                                                        </div>







                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>

                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="main-submit-button">
                    <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
                </div> -->
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength"> 
            <div class="modal-header">

                <span class="color-black pl-1">  Create Calender</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="!this.show"><a>
                            Add Week
    
                        </a></li>
                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="this.show"><a>
                            Update Week
    
                        </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createWeek">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Calendar Week ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!this.show">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Calendar Week ID" formControlName="CalendarWeekID">

                                                    </div>
                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Calendar Week ID" formControlName="CalendarWeekID" readonly>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Monday Start Time</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Monday Start Time" formControlName="MondayStartTime">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Monday Capacity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Monday Capacity" formControlName="MondayCapacity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Tuesday Start Time</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tuesday Start Time" formControlName="TuesdayStartTime">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Tuesday Capacity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tuesday Capacity" formControlName="TuesdayCapacity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Wednesday Start Time</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Wednesday Start Time" formControlName="WednesdayStartTime">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Wednesday Capacity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Wednesday Capacity" formControlName="WednesdayCapacity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Thursday Start Time</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Thursday Start Time" formControlName="ThursdayStartTime">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Thursday Capacity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Thursday Capacity" formControlName="ThursdayCapacity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Friday Start Time</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Friday Start Time" formControlName="FridayStartTime">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Friday Capacity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Friday Capacity" formControlName="FridayCapacity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Saturday Start Time</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Saturday Start Time" formControlName="SaturdayStartTime">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Saturday Capacity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Saturday Capacity" formControlName="SaturdayCapacity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRight">
                                                        <label for="exampleInputEmail1">
                                                        Sunday Start Time</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sunday Start Time" formControlName="SundayStartTime">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                        Sunday Capacity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sunday Capacity" formControlName="SundayCapacity">
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -8% !important;">
                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>

                                        <button type="submit" *ngIf="this.show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>