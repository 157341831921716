import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AddLeaveService } from 'src/app/human-resource/leave/add-new-leaves/add-new-leaves-service';
import { AddLeaveEmpService } from 'src/app/human-resource/leave/add-new-leaves/add-new-leaves.service';
import { EmployeeLeaveService } from 'src/app/human-resource/leave/employee-leave/employee-leave.service';

@Component({
  selector: 'app-create-leave',
  templateUrl: './create-leave.component.html',
  styleUrls: ['./create-leave.component.css']
})
export class CreateLeaveComponent implements OnInit {
  leaveTypeId: any;
  activeCategory = 2;
  leaveId: any = [];
  leaveReason: any;
  leaveReasonArray: any = [];
  partyIdArray: any = [];
  partyId: any;
  leaveTypeIds: any;
  fromDate: any;
  show = false;
  leaves: {};
  isApproval: boolean;
  updateefromdate: string;
  updateetodate: string;
  thruDate: any;
  AppId: any;
  Description: any;
  Reasonid: any;
  partyIdEdit: any;
  leaveStatusReoort: any;
  leaveStatusReoortArray: any[]=[];
  statusId: any;
  leave: boolean=false;
  partyIdData: string;

  constructor(public addLeaveService: AddLeaveService,
    readonly addLeaveEmpService: AddLeaveEmpService,
    readonly toastr: ToastrService,
    readonly router: Router,
    public datePipe: DatePipe,
    readonly employeeLeaveService: EmployeeLeaveService,
    readonly activatedRoute: ActivatedRoute,
    readonly spinner:NgxSpinnerService,) {
    this.isApproval = false;
    this.partyIdData=localStorage.getItem("partyId")
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.addLeaveService.add_leave_form = null;
    this.addLeaveService.addLeaveForm(this.addLeaveService.add_leave_form);
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdEdit = params["partyId"];
      this.leaveTypeId = params["leaveTypeId"];
      this.fromDate = params["fromDate"];
      this.thruDate = params["thruDate"];
      this.AppId = params["AppId"]
      this.Description = params["Description"]
      this.Reasonid = params["Reasonid"];
      this.statusId=params["leaveStatus"]
     
    })
    
       
    if (this.leaveTypeId) {
      this.show = true;
      let fromDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd')
      let toDate = this.datePipe.transform(this.thruDate, 'yyyy-MM-dd')
      setTimeout(() => {

        const formValue = this.addLeaveService.add_leave_form;
        formValue.patchValue({
          partyId: this.partyIdEdit,
          leaveTypeId: this.leaveTypeId,
          emplLeaveReasonTypeId: this.Reasonid,
          approverPartyId: this.AppId,
          description: this.Description,
          thruDate: toDate,
          fromDate: fromDate,
          leaveStatus:this.statusId
           

        })
      }, 3000);

    }
    else {
      this.show = false
    }

    this.getLeaveReasonId();
    this.getPartyId();
    this.leavereason();
    this.leaveStatus();

  }
  leaveStatus() {
    this.spinner.show();
    this.employeeLeaveService.getLeaveStatus().subscribe(res => {
      this.leaveStatusReoort = res.data;
      this.spinner.hide();
      for (const value of this.leaveStatusReoort) {
        this.leaveStatusReoortArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
   
  }
  navigatePrev() {
    this.router.navigate(['/myPortal/my-portal-my-leave'])
  }
  cancelLeave() {
    this.router.navigate(['/myPortal/my-portal-my-leave'])
  }
  onSubmit() {
    this.spinner.show();
    const fromdate = this.addLeaveService.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.addLeaveService.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.addLeaveService.add_leave_form.value.approverPartyId,
      description: this.addLeaveService.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.addLeaveService.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.addLeaveService.add_leave_form.value.leaveTypeId,
      partyId: this.partyIdData,
      thruDate: this.updateetodate,
      leaveStatus:'LEAVE_CREATED',
      
    }
    this.addLeaveEmpService.AddLeave(formDate).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
      
        this.router.navigate(['/myPortal/my-portal-my-leave'])
      
      }
      else if (res.success == false) {
        this.spinner.hide();
        this.toastr.error(res.message.debugMessage);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      
    })
   
   
  }
  onUpdate() {
    this.spinner.show();
    const fromdate = this.addLeaveService.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.addLeaveService.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.addLeaveService.add_leave_form.value.approverPartyId,
      description: this.addLeaveService.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.addLeaveService.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.addLeaveService.add_leave_form.value.leaveTypeId,
      partyId:this.partyIdData,
      thruDate: this.updateetodate,
      leaveStatus: this.addLeaveService.add_leave_form.value.leaveStatus,
    }
  
    this.addLeaveEmpService.update(formDate, this.partyIdEdit, this.leaveTypeId, this.updateefromdate).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.successMessage);
        this.router.navigate(['/myPortal/my-portal-my-leave'])
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
   

  }
  onUpdateApproval() {
    this.spinner.show();
    const fromdate = this.addLeaveService.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.addLeaveService.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.addLeaveService.add_leave_form.value.approverPartyId,
      description: this.addLeaveService.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.addLeaveService.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.addLeaveService.add_leave_form.value.leaveTypeId,
      partyId: this.addLeaveService.add_leave_form.value.partyId,
      thruDate: this.updateetodate,
      leaveStatus: this.addLeaveService.add_leave_form.value.leaveStatus,
    }
  
    this.addLeaveEmpService.postLeaveStatus(formDate).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/myPortal/my-portal-my-leave'])
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();

      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
   
  }
  getLeaveReasonId() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReasonType().subscribe(res => {
      this.leaveTypeIds = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveTypeIds) {
        this.leaveId.push({
          label: value.description,
          value: value.leaveTypeId
        })
      }
    })
 
  }
  leavereason() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReason().subscribe(res => {
      this.leaveReason = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveReason) {
        this.leaveReasonArray.push({
          label: value.description,
          value: value.emplLeaveReasonTypeId
        })
      }
    })
 
  }

  getPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

