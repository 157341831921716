<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100 mt-2">
                <div class="container-fluid">
                    <div class="row"> 
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight justify-content-between port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Backup Warehouse

                                    <span class="">
 
                                        <button type="submit" (click)="homeButton();"
                                            class=" btn btn-outline-secondary mr-2">Home</button>
                                
                                        <button type="submit" (click)="cancelSubmit();"
                                            class="btn btn-danger buttonclass ">Back</button>
                                    </span> 
                                </div>
                                <div class="create-new-leade">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w-100">
                                                <div class="w3-card-4 classCard w-100 mt-2">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                        style="justify-content: space-between;">
                                                        <p class="h-text">Summary</p>
                                                        <span>
                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                style="margin: 0;" data-toggle="modal"
                                                                data-target="#addNewPopup">Update</button>
                                                            
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        
                                                        <div class="color-black container" *ngIf="planningWareHousesById">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Primary Warehouse:</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{planningWareHousesById.primaryWarehouse}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Name:
                                                                            </p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{planningWareHousesById.name}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Owner
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{planningWareHousesById.partyId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Currency
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{planningWareHousesById.currencyUomId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Inventory Item Type
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{planningWareHousesById.inventoryItemType}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Initial Manager
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{planningWareHousesById.userLoginId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Priority:</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{planningWareHousesById.priority}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">From
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{planningWareHousesById.fromDate|date:"yyyy-MM-dd hh:mm:ss"}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Thru
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{planningWareHousesById.thruDate|date:"yyyy-MM-dd hh:mm:ss"}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addNewPopup" tabindex="-1" role="dialog" aria-labelledby="addNewPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add New Backup Warehouse
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add New Backup Warehouse

                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addNewForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Primary Warehouse<span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="primaryWarehouse"
                                                         class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Primary Warehouse">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Name<span
                                                             style="color: red;">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="Name" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Name">
                                               
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Owner<span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="Owner"  [options]="partyIdArray"
                                                        placeholder="Choose Owner">

                                                        </p-dropdown>

                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Currency<span style="color: red;">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="Currency"  [options]="currencyUomIds"
                                                        placeholder="Choose Currency">

                                                        </p-dropdown>
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Inventory Item Type<span style="color: red;">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="inventoryItemType" [options]="inventoryItemArray"
                                                        placeholder="Choose Inventory Item Type">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Priority<span style="color: red;">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="Priority" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Priority">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
         
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">From<span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="From" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter From">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Thru</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="Thru" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Thru">
                                                        </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                 (click)="UpdatePlanningWareHouse()" >Update</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner> 
