import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-detail-subscription-resource',
  templateUrl: './detail-subscription-resource.component.html',
  styleUrls: ['./detail-subscription-resource.component.css']
})
export class DetailSubscriptionResourceComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  activeCategory = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  addSubsResource: any;
  addSubResource: any;
  webSiteIdArray: any[] = [];
  webSiteId: any;
  ContentId: any;
  ContentIdArray: any[] = [];
  ProductIdArray: any[] = [];
  subscriptionResourceList: any;
  subscriptionResourceId: any;
  ProductId: any;
  showResource: boolean;
  productResource: any;
  subscriptionProductList: any;
  showResourceById: boolean;
  UseTimeUomIdArray: any[]=[];
  UseTimeUomId: any;
  RoleTypeId: any;
  RoleTypeIdArray: any[]=[];
  yesNoArray: { label: string; value: string; }[];

  constructor(public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) {
    this.addSubsResource = this._FormBuilder.group({
      ProductID: [''],
      fromDate: [''],
      throughDate: [''],
      purchaseFromDate: [''],
      purchaseThroughDate: [''],
      maxLifeTime: [''],
      lifeTimeUomID: [''],
      availTime: [''],
      availUomID: [''],
      countLimit: [''],
      useTime: [''],
      useUomID: [''],
      useRoleTypeID: [''],
      extendPeriod: [''],
      autoExtension: [''],
      measureUnit: [''],
      graceExpiry: [''],
      graceExpiryUomID: [''],
    });
    this.addSubResource = this._FormBuilder.group({
      SubscriptionResourceID: [''],
      description: [''],
      contentID: [''],
      websiteID: [''],
      sericeNameExpiry: [''],
    });
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.subscriptionResourceId = params.subscriptionResourceId;
    })
    this.getWebSiteId();
    this.getContentIdResource();
    this.getSubscriptionResourceById();
    this.getProductIds();
    this.getSubscriptionProductList();
    this.getUseTimeUomId();
    this.getUseRoleTypeId();
  }
  getUseRoleTypeId() {
    this.spinner.show();
    this.myContactsService.getUseRoleTypeId().subscribe(res => {
      this.RoleTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  getUseTimeUomId() {
    this.spinner.show();
    this.myContactsService.getUseTimeUomId().subscribe(res => {
      this.UseTimeUomId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.UseTimeUomId) {
        this.UseTimeUomIdArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  mainPage(){
    this._Router.navigate(["/facilities/catalog/subscriptions"])
  }
  resetResource(){
    this.showResource = false;
    this.addSubResource.reset();
  }
  updateResource(product) {
    this.showResource = true;
    this.productResource = product;
    setTimeout(() => {
      const formValue = this.addSubsResource;
      formValue.patchValue({
        ProductID: product.productId,
        fromDate: product.fromDate,
        throughDate: product.thruDate,
        purchaseFromDate: product.purchaseFromDate,
        purchaseThroughDate: product.purchaseThruDate,
        maxLifeTime: product.maxLifeTime,
        lifeTimeUomID: product.maxLifeTimeUomId,
        availTime: product.availableTime,
        availUomID: product.availableTimeUomId,
        countLimit: product.useCountLimit,
        useTime: product.useTime,
        useUomID: product.useTimeUomId,
        useRoleTypeID: product.useRoleTypeId,
        extendPeriod: product.automaticExtend,
        autoExtension: product.canclAutmExtTime,
        measureUnit: product.measureUnit,
        graceExpiry: product.gracePeriodOnExpiry,
        graceExpiryUomID: product.gracePeriodOnExpiryUomId
      })

    }, 2000);
  }
  
  deleteProductSubscriptionResource(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fromDate": this.datePipe.transform(product.fromDate,"yyyy-MM-dd HH:mm:ss"), 
          "productId": product.productId,
          "subscriptionResourceId": product.subscriptionResourceId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.deleteProductSubscriptionResource(req).subscribe((res: any) => {
          if (res.success) {
            this.getSubscriptionProductList()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getSubscriptionProductList() {
    this.spinner.show();
    this.myContactsService.getSubscriptionProductList().subscribe(res => {
      this.subscriptionProductList = res.data[0].data;
      this.spinner.hide();
    })
  }
  createProductSubscriptionResourceSr(): void {
    this.spinner.show();

    const requestData = {
      "automaticExtend": this.addSubsResource.value.extendPeriod,
      "availableTime": this.addSubsResource.value.availTime,
      "availableTimeUomId": this.addSubsResource.value.availUomID,
      "canclAutmExtTime": this.addSubsResource.value.autoExtension,
      "canclAutmExtTimeUomId": this.addSubsResource.value.measureUnit,
      "fromDate": this.datePipe.transform(this.addSubsResource.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "gracePeriodOnExpiry": this.addSubsResource.value.graceExpiry,
      "gracePeriodOnExpiryUomId": this.addSubsResource.value.graceExpiryUomID,
      "maxLifeTime": this.addSubsResource.value.maxLifeTime,
      "maxLifeTimeUomId": this.addSubsResource.value.lifeTimeUomID,
      "productId": this.addSubsResource.value.ProductID,
      "purchaseFromDate": this.datePipe.transform(this.addSubsResource.value.purchaseFromDate, "yyyy-MM-dd HH:mm:ss"),
      "purchaseThruDate": this.datePipe.transform(this.addSubsResource.value.purchaseThroughDate, "yyyy-MM-dd HH:mm:ss"),
      "subscriptionResourceId": this.subscriptionResourceId,
      "thruDate": this.datePipe.transform(this.addSubsResource.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
      "useCountLimit": this.addSubsResource.value.countLimit,
      "useRoleTypeId": this.addSubsResource.value.useRoleTypeID,
      "useTime": this.addSubsResource.value.useTime,
      "useTimeUomId": this.addSubsResource.value.useUomID,
    }
    this.myContactsService.createProductSubscriptionResourceSr(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.addSubsResource.reset();
        this.getSubscriptionResourceById();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductSubscriptionResource(): void {
    this.spinner.show();
    const requestData = {
      "automaticExtend": this.addSubsResource.value.extendPeriod,
      "availableTime": this.addSubsResource.value.availTime,
      "availableTimeUomId": this.addSubsResource.value.availUomID,
      "canclAutmExtTime": this.addSubsResource.value.autoExtension,
      "canclAutmExtTimeUomId": this.addSubsResource.value.measureUnit,
      "fromDate": this.datePipe.transform(this.addSubsResource.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "gracePeriodOnExpiry": this.addSubsResource.value.graceExpiry,
      "gracePeriodOnExpiryUomId": this.addSubsResource.value.graceExpiryUomID,
      "maxLifeTime": this.addSubsResource.value.maxLifeTime,
      "maxLifeTimeUomId": this.addSubsResource.value.lifeTimeUomID,
      "productId": this.addSubsResource.value.ProductID,
      "purchaseFromDate": this.datePipe.transform(this.addSubsResource.value.purchaseFromDate, "yyyy-MM-dd HH:mm:ss"),
      "purchaseThruDate": this.datePipe.transform(this.addSubsResource.value.purchaseThroughDate, "yyyy-MM-dd HH:mm:ss"),
      "subscriptionResourceId": this.subscriptionResourceId,
      "thruDate": this.datePipe.transform(this.addSubsResource.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
      "useCountLimit": this.addSubsResource.value.countLimit,
      "useRoleTypeId": this.addSubsResource.value.useRoleTypeID,
      "useTime": this.addSubsResource.value.useTime,
      "useTimeUomId": this.addSubsResource.value.useUomID,  
    }
    this.myContactsService.updateProductSubscriptionResource(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.addSubsResource.reset();
        this.getSubscriptionResourceById();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId
        })
      }
    })
  }
  reset(){
    this.addSubResource.reset();
    this.showResourceById = false;
  }
  createSubscriptionResource(): void {
    this.spinner.show();
    const requestData = {
      "contentId":  this.addSubResource.value.contentID,
      "description":  this.addSubResource.value.description,
      "serviceNameOnExpiry": this.addSubResource.value.sericeNameExpiry,
      "webSiteId":  this.addSubResource.value.websiteID,
     
    }
    this.myContactsService.createSubscriptionResource(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addSubResource.reset();
        this.getSubscriptionResourceById();
        this._Router.navigate(["/facilities/catalog/subscriptions/detail-subscription-resource"],
        {queryParams:{subscriptionResourceId:res.data.subscriptionResourceId}})
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateSubscriptionResource(): void {
    this.spinner.show();
    const requestData = {
      "contentId": this.addSubResource.value.contentID,
      "description": this.addSubResource.value.description,
      "serviceNameOnExpiry": this.addSubResource.value.sericeNameExpiry,
      "webSiteId": this.addSubResource.value.websiteID,
      "subscriptionResourceId": this.subscriptionResourceId,

    }
    this.myContactsService.updateSubscriptionResource(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addSubResource.reset();
        this.getSubscriptionResourceById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getSubscriptionResourceById() {
    this.spinner.show();
    this.showResourceById = true;
    this.myContactsService.getSubscriptionResourceById(this.subscriptionResourceId).subscribe(res => {
      this.subscriptionResourceList = res.data[0].data[0];
      setTimeout(() => {
        const formValue = this.addSubResource;
        formValue.patchValue({
          SubscriptionResourceID: this.subscriptionResourceId,
          description: this.subscriptionResourceList.description,
          contentID: this.subscriptionResourceList.contentId,
          websiteID: this.subscriptionResourceList.webSiteId,
          sericeNameExpiry: this.subscriptionResourceList.serviceNameOnExpiry,
        })

      }, 2000);
      this.spinner.hide();
    })
  }
  getContentIdResource() {
    this.spinner.show();
    this.myContactsService.getContentIdResource().subscribe(res => {
      this.ContentId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContentId) {
        this.ContentIdArray.push({
          label: value.contentId,
          value: value.contentId
        })
      }
    })
  }
  getWebSiteId() {
    this.spinner.show();
    this.myContactsService.getWebSiteId().subscribe(res => {
      this.webSiteId = res.data[0].getWebSiteId;
      this.spinner.hide();
      for (const value of this.webSiteId) {
        this.webSiteIdArray.push({
          label: value.siteName,
          value: value.webSiteId
        })
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
