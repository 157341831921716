<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancel();" style="cursor: pointer;">Requisition </span>
                <span class="color-black pl-1"> > Job Requisition</span>
                <button type="submit" (click)="onCancel()" class="btn btn-danger buttonclass" 
                                                (click)="cancel();">Cancel</button>
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Add New Job Requisition </a></li>
                        
                     
                    </ul>
                  </div>
                  <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians">
          
                      <li  [ngClass]="activeCategoryTab==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                        Add Job Information </a></li>
                        <li  [ngClass]="activeCategoryTab==3?'active':''" (click)="jobInfo(newJobRequistionService.job_requistion_form.value.jobLocation,
                        newJobRequistionService.job_requistion_form.value.noOfResources,newJobRequistionService.job_requistion_form.value.gender,
                        newJobRequistionService.job_requistion_form.value.qualification)"><a>
                           Skills Required </a></li>
                       
                     
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form *ngIf="newJobRequistionService.job_requistion_form"
                                [formGroup]="newJobRequistionService.job_requistion_form" class="w-100">
                                <div *ngIf="activeCategoryTab==2">
                                    <div class="header-tabs">
                                      <h4 class=" common-styling marginLeft">
                                        Add Job Information
                                      </h4>
                                    
            
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Job Location <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="jobLocation" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter Job Location">
                                                        <small class="text-danger"
                                                        *ngIf="!!newJobRequistionService.job_requistion_form.controls.jobLocation.invalid && !!newJobRequistionService.job_requistion_form.controls.jobLocation.touched">Location is required!</small>
                                                  
                                               
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes" >
                                                        <label for="exampleInputEmail1">Age</label>
                                                    </div>
                                                    <div class="col-lg-2" >
                                                        <input type="email" (keypress)="numberOnly($event)" formControlName="age"
                                                        class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter age">
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">No Of Resources <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="noOfResources" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter no Of Resources">
                                                   
                                                            <small class="text-danger"
                                                            *ngIf="!!newJobRequistionService.job_requistion_form.controls.noOfResources.invalid && !!newJobRequistionService.job_requistion_form.controls.noOfResources.touched">No Of Resources is required!</small>
                                                      
                                              
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes" >
                                                        <label for="exampleInputEmail1">Gender <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true" [options]="Gender" formControlName="gender"
                                                        optionlabel="label" placeholder="Enter Gender">
    
                                                    </p-dropdown>

                                                    <small class="text-danger"
                                                    *ngIf="!!newJobRequistionService.job_requistion_form.controls.gender.invalid && !!newJobRequistionService.job_requistion_form.controls.gender.touched">Gender is required!
                                                    </small>
                                              
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Duration Months</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input style="width: 100%;" (keypress)="numberOnly($event)" formControlName="durationMonths"
                                                            type="text" placeholder="Duration (In Months)">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes" >
                                                        <label for="exampleInputEmail1">Qualification<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true" [options]="qualArray"
                                                        formControlName="qualification" optionlabel="label"
                                                        placeholder="Enter Qualification">
                                                    </p-dropdown>
                                                    <small class="text-danger"
                                                    *ngIf="!!newJobRequistionService.job_requistion_form.controls.qualification.invalid && !!newJobRequistionService.job_requistion_form.controls.qualification.touched">Qualification is required!</small>
                                              
                                          
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Exam Type Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="enumArray"
                                                        formControlName="examTypeEnumId" optionlabel="label"
                                                        placeholder="Enter Exam Type Enum ID">
                                                    </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                  
                                                </div>
                                            </div>
                                          
                                        </div>
                                      <div class="main-submit-button" style="
                                      margin-right: 28%;">
                                          <button type="submit" (click)="jobInfo(newJobRequistionService.job_requistion_form.value.jobLocation,
                                          newJobRequistionService.job_requistion_form.value.noOfResources,newJobRequistionService.job_requistion_form.value.gender,
                                          newJobRequistionService.job_requistion_form.value.qualification)"
                                              class="btn btn-secondary submit-btn">Continue</button>
                                        
                                      </div>
                                      <br>
                                      <br>
                                    </div>
            
                                  </div>
                                  <div *ngIf="activeCategoryTab==3">
                                    <div class="header-tabs">
                                      <h4 class=" common-styling marginLeft">
                                        Skills Required
                                      </h4>
                                    
            
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Skill Type ID <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="skillTypeArray"
                                                        formControlName="skillTypeId" optionlabel="label"
                                                        placeholder="Enter Skill Type ID">
    
                                                    </p-dropdown>
                                                    <small class="text-danger"
                                                    *ngIf="!!newJobRequistionService.job_requistion_form.controls.skillTypeId.invalid && !!newJobRequistionService.job_requistion_form.controls.skillTypeId.touched">Skill TypeId is required!</small>
                                              
                                              
                                               
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes" >
                                                        <label for="exampleInputEmail1">Experience in months <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" >
                                                        <input type="email" (keypress)="numberOnly($event)"
                                                        formControlName="experienceInMonths" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter Experience in months">
                                               
                                                        <small class="text-danger"
                                                        *ngIf="!!newJobRequistionService.job_requistion_form.controls.experienceInMonths.invalid && !!newJobRequistionService.job_requistion_form.controls.experienceInMonths.touched">Experience In Months is required!</small>
                                                  
                                               
                                                    </div>
                                                 
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Experience in years <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" (keypress)="numberOnly($event)"
                                                        formControlName="experienceInYears" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter Experience in years">
                                                        <small class="text-danger"
                                                        *ngIf="!!newJobRequistionService.job_requistion_form.controls.experienceInYears.invalid && !!newJobRequistionService.job_requistion_form.controls.experienceInYears.touched">Experience In Year is required!</small>
                                                  
                                             
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                          
                                          
                                        </div>
                                        <div class="main-submit-button" style="
                                        margin-right: 28%;">
                                            <button type="submit" (click)="onSubmit()" *ngIf="!show"
                                                class="btn btn-secondary submit-btn">Create</button>
                                            <button type="submit" (click)="onUpdate()" *ngIf="show"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            
                            
                                        </div>
                                    </div>
            
                                  </div>
                               
                              
                            </form>
                        </div>
                    </div>

                </div>

            </div>
            </div>
          
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>