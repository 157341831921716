<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/financial/{{agreementType}}/customer-agreeement">Agreements
                </span>
                <span class="color-gry cursor-pointer"
                    routerLink="/financial/{{agreementType}}/customer-agreeement/summary-customer-agreeement"
                    [queryParams]="{agreementID:agreement.agreementId}">>Agreements Summary </span>
                <span class="color-gry cursor-pointer"
                    routerLink="/financial/{{agreementType}}/customer-agreeement/agreement-summary-item"
                    [queryParams]="{agreementId :agreement.agreementId,agreementItemSeqId:agreement.agreementItemSeqId }">>Item
                    Summary </span>
                <span class="color-black pl-1"> > Item Facility</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians" style="margin-top: 0px!important;background: #cce0e95e!important;">
                                          
                                                      <li [ngClass]="activeCategory==1?'active':''" ><a>
                                                        Item Facility</a></li>
                                                
                                                    </ul>
                                                  </div>
                                               
                                                <div >
                                                    <br>
                                                    <div class="panel-body">
                                                        <form [formGroup]="createItemFacilityForm">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                    
                                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -13%;
                                                                        font-size: 10px;">
                                                                            <label for="agreementInvoiceType">Facility
                                                                                ID<span
                                                                                    class="text-danger">*</span></label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                [options]="facilityIds"
                                                                                optionlabel="label"
                                                                                formControlName="facilityId"
                                                                                placeholder="select Facility ">
                                                                            </p-dropdown>

                                                                            <small class="validate-field"
                                                                                *ngIf="!!createItemFacilityForm.controls.facilityId.invalid && !!createItemFacilityForm.controls.facilityId.touched">
                                                                                Facility ID is required!</small>
                                                                        </div>
                                    
                                                                    </div>
                                                                </div>
                                                             
                                                            </div>
                                                            <div class="main-submit-button" style="    margin-right: 74%;">
                                                                <button type="submit" (click)="submit()"
                                                                    class="btn btn-secondary submit-btn">Submit</button>
                                                                <button class="btn btn-danger cursor-pointer ml-2"
                                                                    (click)="reset()">Cancel</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 