import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DetaledLeadService } from '../detaled-lead/detaled-lead.service';
import { Location } from '@angular/common';




@Component({
  selector: 'app-crm-new-document',
  templateUrl: './crm-new-document.component.html',
  styleUrls: ['./crm-new-document.component.css']
})
export class CrmNewDocumentComponent implements OnInit {
  Editform: FormGroup;
  partyContentTypeId: any;
  partyContentData: any;
  partyContentArray: any[]=[];
  fileData: File;
  partyId: any;



  constructor(
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly detaledLeadService: DetaledLeadService,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    private _location: Location,

  ) { 
  
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.partyId = params['leadId'];
    });
    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
      partyContentTypeId: new FormControl('')
    });
    this.getPartyContent();
  
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }
  onpartyContent(event) {
    this.partyContentTypeId = event;
  }
  getPartyContent() {
    this.spinner.show();
    this.detaledLeadService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        })
      }
    })
    
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  onSubmit() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.fileData);
    this.partyContentTypeId = this.Editform.get('partyContentTypeId').value;
    this.detaledLeadService.attachPartyContent(formData, this.partyContentTypeId, this.partyId)
      .subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          // window.location.reload();
          // this.getLeadById()

        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      });
     
  }

 

 
}
