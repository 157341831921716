<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Open Tasks Assigned to me.
                </span>
  

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                    
                    <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Assign an existing Task to me.
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="assignTask">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                   
                                   
                                   
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Task Name</label>
                                                <input type="text"  formControlName="TaskName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Task Name" readonly>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group" >
                                            <label for="exampleInputEmail1">
                                                Role Type ID</label>
                                                
                                                <p-dropdown [options]="RoleByIdArray" filter="true" formControlName="RoleTypeID"
                                                placeholder="Enter Role Type ID"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                        </div>
                                      
                                    </div>
                                 
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 80%;">
                               
                                <button type="submit" (click)="onSubmitTask();"  class="btn btn-secondary submit-btn">Add</button>
                                 
                               
                                
                              
                            </div>
                        </div>
                      
                    </div>

                </div>
                </div>
             

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>




