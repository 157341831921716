<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div style="margin: 4%;font-size: 11px;">
          <ng-wizard [config]="config">

            <ng-wizard-step [title]="'Step 1'" [description]="'Sales Order'"> 
            </ng-wizard-step>

            <ng-wizard-step [title]="'Step 2'" [description]="' Enter Order Currency, Agreements, and Ship Dates '">  
            </ng-wizard-step>
           
          <ng-wizard-step [title]="'Step 3'" [description]="'Create Sales Order'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 4'" [description]="'Shipping'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 5'" [description]="'Option'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 6'" [description]="'Order Term'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 7'" [description]="'Payment'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 8'" [description]="'Parties'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 9'" [description]="'Review Order'">     
            </ng-wizard-step>
           
          
         
          </ng-wizard>
        </div>
        <div>
          <div class="container-fluid">
            <div class="container-fluid">
              <ul class="tabbing-section  tabbing-accordians" style="padding-left: 9px;">
                <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                  Sales Order : Order Entry Ship-To Settings </a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                  Ship to Another Party </a></li>
               
              
              </ul>
            </div>
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div *ngIf="activeCategory==1">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Sales Order : Order Entry Ship-To Settings
                          </h4>
                          <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                          href="#seo-collapse">
                        </span>
                        <span class="edit-buttons" style="margin-left: 24%!important" routerLinkActive="active"
                          routerLink="/order/create-sales-continue">Order Items</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/sales-finalize-order">Shipping</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/sales-finalize-order">Options</span>
                        <span class="edit-buttons">Order Terms</span>
                        <span class="edit-buttons"> Payment</span>
                        <span class="edit-buttons">Parties</span>
                        <span class="edit-buttons">Review Order</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          (click)="finalizeOrder()">Continue</span>


                         
                        </div>
                        <div class="panel-body">
                          <div class="d-flex justify-content-end">
                            <div class="col-lg-4 col-12 main-submit-button">
                              <button type="submit" class="btn btn-secondary submit-btn" routerLinkActive="active"
                                routerLink="/order/sales-order-options">
                                Create New Ship Group
                              </button>
                            </div>
                            <div class="main-submit-button">
                              <button type="submit" class="btn btn-secondary submit-btn" routerLinkActive="active"
                                routerLink="/order/new-shipping-address">
                                Create New Shipping Address
                              </button>
                            </div>
                          </div>
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <ng-template ngFor let-address [ngForOf]="addressList" let-index="index">
                                  <form class="w-100 form-border">
                                    <h2 class="col-lg-12 col-12 address-heading">Ship Group Nbr
                                      {{address.shipGroupNumber}}</h2>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Supplier
                                          </label>
                                          <p-dropdown [options]="supplierList" placeholder="Select Supplier"
                                            [name]="index+'supplier'"></p-dropdown>
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Agreement
                                          </label>
                                          <p-dropdown [options]="agreementList" placeholder="Select Agreement"
                                            [name]="index+'agreement'"></p-dropdown>
                                        </div>
                                      </div>
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Reserve inventory from facility
                                          </label>
                                          <p-dropdown [options]="true" placeholder="Select  Inventory"
                                            [name]="index+'inventory'"></p-dropdown>
                                        </div>
                                      </div>
                                      <ng-template ngFor let-po [ngForOf]="address.shippingAddresses" let-i="index">
                                        <div class="col-lg-12 col-12 d-flex align-items-center flex-wrap"
                                          style="margin-top:10px;">
                                          <div class="w-100">
                                            <p-checkbox name="selectContacts" [id]="i+'checkbox'"
                                              styleClass="custom-checkbox" [value]="po.geoPointId"
                                              (onChange)="setShipping($event,po.geoPointId)">
                                            </p-checkbox>
                                            <p class="mb-0 d-inline-block"
                                              style="color: #65787e;font-size: 13px;font-weight:400">
                                              <span style="font-weight:700;">To: </span>{{po.toName}}
                                            </p>
                                          </div>
                                          <div>
                                            <span class="address-values"><span style="font-weight:700">Attn :</span>
                                              {{po.address1}}
                                            </span>
                                            <span class="address-values">
                                              {{po.houseNumber}}
                                            </span>
                                            <span class="address-values">{{po.address2}}</span>
                                            <span class="address-values">{{po.city}}</span>
                                            <span class="address-values">{{po.postalCode}}</span>
                                            <span class="address-values">{{po.countryGeoId}}</span>
                                          </div>
                                        </div>
                                      </ng-template>
                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button"><button type="submit"
                                        class="btn btn-secondary submit-btn">Update</button></div>
                                  </form>
                                </ng-template>
                              
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="activeCategory==2">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Ship to Another Party
                          </h4>
                        
                        </div>
                        <div class="panel-body">
                          
                               
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Party Id
                                        </label>
                                        <p-dropdown [options]="partyIds" placeholder="Select Party Id"
                                          [(ngModel)]="partyIdValue" [ngModelOptions]="{standAlone:true}">
                                        </p-dropdown>
                                      </div>

                                    </div>
                                  </div>
                                  <div class="col-lg-12 col-12 main-submit-button"><button type="submit"
                                      class="btn btn-secondary submit-btn">Continue</button></div>
                             
                            
                        </div>
                      </div>
                    
                    </div>

                  </div>
                  <!--Promotion/Coupon Codes-->
               
                  <!--  Promotion/Coupon Codes -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner> 