<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Order</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>
      <div class=" bg-white color-grey create-new-table">
        <div class="w3-card-4 classCard" style="margin-top: 11px;">
          <div class="container-fluid" style="overflow:auto;background: #cce0e95e!important;">
            <ul class="tabbing-section  tabbing-accordians tabClass" style="padding-left: 9px;white-space: nowrap;">
              <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                  Sales Order Summary </a></li>
              <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                  Payment Information</a></li>
              <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                  Contact Information </a></li>
              <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                  Roles </a></li>
              <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                  Action </a></li>
              <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                  Shipment Information</a></li>
              <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategoryFunction(7)"><a>
                  Order Items</a></li>
              <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategoryFunction(8)"><a>
                  Notes</a></li>
              <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategoryFunction(9)"><a>
                  All Communication Events </a></li>


            </ul>
          </div>
          <div>
            <div class="container-fluid">
              <div class="row">
                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                  <div class="w-100">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div *ngIf="activeCategory==1">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Sales Order Number {{salesSummaryValue?.orderId}} <span class="cursor-pointer"
                                (click)="getOrderPDF()">[ PDF ]</span>
                            </h4>
                            <ng-container
                              *ngIf="!salesSummaryValue?.statusHistory.currentStatus.toLowerCase().includes('cancelled')">
                              <ng-container
                                *ngIf="!salesSummaryValue?.statusHistory.currentStatus.toLowerCase().includes('completed')">
                                <span class="edit-buttons" (click)="changeOrder('ORDER_CANCELLED')">Cancel
                                  Order</span>
                                <span class="edit-buttons" (click)="changeOrder('ORDER_APPROVED')"
                                  *ngIf="!salesSummaryValue?.statusHistory.currentStatus.toLowerCase().includes('approve')">
                                  Approve Order</span>
                                <span class="edit-buttons"
                                  *ngIf="!salesSummaryValue?.statusHistory.currentStatus.toLowerCase().includes('Held') && salesSummaryValue?.statusHistory.currentStatus.toLowerCase().includes('approve')"
                                  (click)="changeOrder('ORDER_HOLD')"> Hold
                                  Order</span>
                              </ng-container>
                            </ng-container>
                          </div>
                          <div class="panel-body">
                            <div class="mt-2">
                              <div class="">
                                <div class="row">
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left: 90px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Status
                                      History</strong>
                                    <div class="col-md-4">
                                      <p class="w-100 common-regular-properties">Current Status:
                                        {{salesSummaryValue?.statusHistory.currentStatus}}
                                      </p>
                                      <p class="w-100 common-regular-properties"> Created -
                                        {{salesSummaryValue?.orderDate |date :'yyyy-MM-dd hh:mm:ss'}}
                                      </p>
                                      <p class="w-100 common-regular-properties">By -
                                        {{salesSummaryValue?.createdBy}}
                                      </p>
                                    </div>

                                    <strong class="common-bold-properties" style="margin-right:10px;"> Date Ordered
                                    </strong>
                                    <div class="col-md-4">
                                      <p class="common-regular-properties">
                                        {{salesSummaryValue?.orderDate |date :'yyyy-MM-dd hh:mm:ss'}}
                                      </p>
                                    </div>
                                  </div>
                                  <!-- <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Date Ordered
                                    </strong>
                                    <div class="col-md-9">
                                      <p class="common-regular-properties">
                                        {{salesSummaryValue?.orderDate |date :'yyyy-MM-dd hh:mm:ss'}}
                                      </p>
                                    </div>
                                  </div> -->
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left: 90px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Currency</strong>

                                    <div class="col-md-4">
                                      <p class="common-regular-properties ml-35">
                                        {{salesSummaryValue?.currencyUom }}
                                      </p>
                                    </div>

                                    <strong class="common-bold-properties" style="margin-right:10px;margin-left: 22px;"> Sales Channel
                                    </strong>
                                    <div class="col-md-4">
                                      <p class="common-regular-properties">{{salesSummaryValue?.salesChannel}}
                                      </p>
                                    </div>
                                  </div>
                                  <!-- <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                   
                                  </div> -->
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left: 90px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Product Store
                                    </strong>
                                    <div class="col-md-4">
                                      <p class="common-regular-properties">
                                        {{salesSummaryValue?.productStore.storeName}}<a  class="account-button" >
                                          ({{salesSummaryValue?.productStore.productStoreId}})</a>
                                      </p>

                                    </div>
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Origin Facility
                                    </strong>
                                    <div class="col-md-4">
                                      <p class="common-regular-properties">{{salesSummaryValue?.originFacilityId}}
                                      </p>
                                    </div>
                                  </div>
                                  <!-- <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                  
                                  </div> -->
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left: 90px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Created By
                                    </strong>
                                    <div class="col-md-4">
                                      <p class="common-regular-properties"> <a  class="account-button" >
                                          {{salesSummaryValue?.createdBy}}</a>
                                      </p>
                                    </div>
                                    <strong class="common-bold-properties" style="margin-right:10px;margin-left: 22px;">
                                      Attachments</strong>
                                    <div class="col-md-3">
                                      <p class="common-regular-properties">No Attachments
                                      </p>
                                    </div>
                                    <div class="col-md-2">
                                      <button type="submit" class="btn btn-secondary submit-btn ">Add
                                        Attachment</button>
                                    </div>
                                  </div>
                                  <!-- <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select pb-0"
                                    style="padding-left:15px;">
                                    
                                    
                                  </div> -->
                                  <!-- <div class="main-submit-button btn-attachment">
                                   
                                    </div> -->
                                    <div class="col-lg-12">
                                      <div class="row mt-3">
                                          <div class="col-lg-3 form-group rightFormCA">
                                              <label for="exampleInputEmail1">Priority</label>
                                          </div>
                                          <div class="col-lg-3">
                                            <p-dropdown [options]="true" placeholder="Select Priority" optionLabel="name">
                                            </p-dropdown>
                                            <div class="mt-3" style="text-align: end;">
                                              <button type="submit" class="btn btn-secondary submit-btn ">
                                                Reserve Inventory
                                              </button>
                                            </div>

                                          </div>

                                          <div class="col-lg-3 form-group rightFormCB">
                                              <label for="exampleInputEmail1">Invoice Per Shipment                                              </label>
                                          </div>
                                          <div class="col-lg-3">
                                            <p-dropdown [options]="true" placeholder="Select Invoice" optionLabel="name">
                                            </p-dropdown>
                                            <div class="mt-3" style="text-align: end;">
                                              <button type="submit" class="btn btn-secondary submit-btn ">
                                                Update
                                              </button>
                                            </div>
                                            

                                          </div>




                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="row mt-3">
                                          <div class="col-lg-3 form-group rightFormCA">
                                              <label for="exampleInputEmail1"> Viewed</label>
                                          </div>
                                          <div class="col-lg-3">
                                            <p class="common-regular-properties">
                                              Yes
                                            </p>
                                            

                                          </div>

                                      </div>
                                    </div>
                                  <!-- <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Priority
                                    </strong>
                                    <div class="col-md-4 pl-0">
                                      <p class="common-regular-properties">
                                        <p-dropdown [options]="true" placeholder="Select Priority" optionLabel="name">
                                        </p-dropdown>
                                      </p>
                                    </div>
                                    <div class="">
                                      <button type="submit" class="btn btn-secondary submit-btn ">
                                        Reserve Inventory
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;">
                                      Invoice Per Shipment </strong>
                                    <div class="col-md-4 pl-0" style="margin-top:6px;">
                                      <p class="common-regular-properties">
                                        <p-dropdown [options]="true" placeholder="Select Invoice" optionLabel="name">
                                        </p-dropdown>
                                      </p>
                                    </div>
                                    <div class="">
                                      <button type="submit" class="btn btn-secondary submit-btn ">
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Viewed
                                    </strong>
                                    <div class="col-md-9">
                                      <p class="common-regular-properties">
                                        Yes
                                      </p>
                                    </div>
                                  </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="activeCategory==2">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Payment Information
                            </h4>

                          </div>
                          <div class="panel-body">
                            <div class="mt-2">
                              <div class="">
                                <div class="row">
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Status
                                      History</strong>
                                    <ng-template ngFor let-payment [ngForOf]="salesSummaryValue?.paymentStatusList">
                                      <div class="col-md-6 ml-22">
                                        <p class="w-100 common-regular-properties">
                                          {{payment.status}}
                                        </p>
                                        <p class="w-100 common-regular-properties">
                                          {{payment.dateTime |date:'yyyy-MM-d hh:mm:ss'}}
                                        </p>
                                        <p class="w-100 common-regular-properties">By - [{{payment.by}}]
                                        </p>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;" *ngFor="let data of orderPaymentPreference">
                                    <span style="font-weight: 400;
                                color: #65787e !important;
                                font-size: 12px;" *ngIf="data.paymentMethodTypeId=='CREDIT_CARD'">Order Payment
                                      Preference Id:{{data.orderPaymentPreferenceId}}
                                      &nbsp;Amount:{{data.maxAmount}}&nbsp;<a *ngIf="data.statusId!='PAYMENT_SETTLED'">
                                        <button _ngcontent-cta-c128="" type="submit"
                                          class="btn btn-secondary submit-btn fontMenu"
                                          (click)="authorize(data);">Authorize</button></a>&nbsp;
                                      <a *ngIf="data.statusId=='PAYMENT_AUTHORIZED'"> <button _ngcontent-cta-c128=""
                                          type="submit" class="btn btn-secondary submit-btn fontMenu"
                                          (click)="capture(data);">Capture</button>&nbsp;</a>
                                    </span>


                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;">
                                      {{ salesSummaryValue?.paymentList[salesSummaryValue?.paymentList.length
                                      -1].paymentType }}
                                    </strong>
                                    <div class="col-md-9">
                                      <p class="common-regular-properties">Max Amount:
                                        US${{ salesSummaryValue?.paymentList[salesSummaryValue?.paymentList.length
                                        -1].maxAmount}}
                                      </p>
                                      <p class="common-regular-properties">
                                        {{ salesSummaryValue?.paymentList[salesSummaryValue?.paymentList.length
                                        -1].status}}
                                      </p>



                                      <p class="common-regular-properties">Payment ID:
                                        {{ salesSummaryValue?.paymentList[0].payments[0]?
                                        salesSummaryValue?.paymentList[0].payments[0].paymentId : ''}}
                                      </p>

                                      <p *ngIf="salesSummaryValue?.invoices[0]"
                                        class="common-regular-properties text-black">Invoice:
                                        {{ salesSummaryValue?.invoices[0]}} <span><button
                                            style="margin-top: 1.5%!important;"
                                            class="btn btn-primary cursor-pointer ml-2"
                                            (click)="downloadInvoice(salesSummaryValue?.invoices[0])">PDF</button></span>
                                      </p>

                                    </div>
                                    <br> <br>
                                    <div class="card own-account-table" style="margin-top: 3%;">
                                      <p-table [value]="paymentGatewayResponses" [paginator]="true" [rows]="rows"
                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                        [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                        [customSort]="true">
                                        <ng-template pTemplate="header">
                                          <tr style="background: #0d3769;">
                                            <th pSortableColumn="code">
                                              <div class="checkbox-align" style="color: white;">

                                                Transaction Code <p-sortIcon field="code"></p-sortIcon>
                                              </div>
                                            </th>
                                            <th pSortableColumn="name">
                                              <div style="color: white;">Transaction Date <p-sortIcon field="name">
                                                </p-sortIcon>
                                              </div>
                                            </th>
                                            <th pSortableColumn="category">
                                              <div style="color: white;">Amount<p-sortIcon field="category">
                                                </p-sortIcon>
                                              </div>
                                            </th>
                                            <th pSortableColumn="quantity">
                                              <div style="color: white;">Reference<p-sortIcon field="quantity">
                                                </p-sortIcon>
                                              </div>
                                            </th>
                                            <th pSortableColumn="price">
                                              <div style="color: white;">AVS <p-sortIcon field="price"></p-sortIcon>
                                              </div>
                                            </th>
                                            <th pSortableColumn="price">
                                              <div style="color: white;">Score <p-sortIcon field="price"></p-sortIcon>
                                              </div>
                                            </th>
                                            <th pSortableColumn="price">
                                              <div style="color: white;">Action <p-sortIcon field="price"></p-sortIcon>
                                              </div>
                                            </th>

                                          </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                          <tr>
                                            <td style="cursor: pointer;">
                                              <div class="checkbox-align">

                                                <a  class="account-button"  routerLinkActive="active">
                                                  {{product.transactionCode}}</a>


                                              </div>
                                            </td>
                                            <td>{{product.transactionDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                            <td>{{product.amount}}</td>
                                            <td>{{product.referenceNum}}</td>
                                            <td>{{product.gatewayAvsResult}}</td>
                                            <td>{{product.gatewayScoreResult}}</td>
                                            <td>
                                              <span style="color: #007bff;cursor: pointer;"
                                                (click)="viewPayment(product.paymentGatewayResponseId)">Detail</span>
                                            </td>

                                          </tr>
                                        </ng-template>
                                      </p-table>

                                      <p class="paginate_data" style="margin: 0px 0px -9px -11px !important;">
                                        View per page </p>
                                    </div>



                                    <div *ngIf="this.salesSummaryValue.showAddPaymentMethodToOrderForm=='Y'">
                                      <form class="w-100" [formGroup]="createPaymentMethod">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                          <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                              <label for="exampleInputEmail1">
                                                Payment Method</label>
                                              <br>
                                              <p-dropdown placeholder="Enter Payment Method" filter="true"
                                                formControlName="PaymentMethod" [options]="paymnetMethodsArray"
                                                optionlabel="label">

                                              </p-dropdown>


                                            </div>


                                          </div>
                                          <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                              <label for="exampleInputEmail1">
                                                Amount</label>
                                              <br>
                                              <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Amount"
                                                formControlName="Amount">


                                            </div>


                                          </div>




                                        </div>


                                      </form>
                                      <div class="main-submit-button add-btn">

                                        <button type="submit" (click)="addPaymentMethodToOrder()"
                                          class="btn btn-secondary submit-btn">Add</button>

                                      </div>
                                    </div>

                                    <div class="w-100 col-md-12 text-right main-submit-button pay-rec-btn">
                                      <button type="submit" class="btn btn-primary ml5"
                                        routerLink="/order/receive-payment"
                                        [queryParams]="{orderId:this.orderId,isSales:false}">
                                        Receive Payment
                                      </button>
                                    </div>
                                  </div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==3">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Contact Information
                            </h4>

                          </div>
                          <div class="panel-body">
                            <div class="mt-2">
                              <div class="">
                                <div class="row">
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;">Name</strong>
                                    <div class="col-md-9">
                                      <p class="w-100 common-regular-properties">
                                        {{salesSummaryValue?.orderContactInformation[0].contactMech.contactMechId}} <a
                                           class="account-button" >Admin</a>
                                      </p>


                                    </div>
                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties"
                                      style="margin-right:10px;margin-bottom:10px;">
                                      Order Notification Email Address
                                    </strong>
                                    <div class="col-md-9">
                                      <p class="common-regular-properties">
                                        {{salesSummaryValue?.orderContactInformation[0].contactMech.infoString}}
                                        <a  class="account-button"  routerLinkActive="active"
                                          routerLink="/order/confirmation-email" style="margin-left:20px;"> (Send a
                                          confirmation email)</a>
                                      </p>
                                    </div>
                                    <div class="col-md-12 pl-0">
                                      <p-dropdown [options]="salesSummaryValue?.orderContactInformation"
                                        optionLabel="contactMech.infoString" optionValue="contactMech.infoString"
                                        placeholder="Select Email">
                                      </p-dropdown>
                                    </div>
                                    <div class="w-100 col-md-12 text-right main-submit-button">
                                      <button type="submit" class="btn btn-secondary submit-btn ">
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties"
                                      style="margin-right:10px;margin-bottom:10px;">
                                      Shipping Destination Address
                                    </strong>
                                    <div class="col-md-9">
                                      <p class="common-regular-properties">
                                        <strong class="common-bold-properties">To </strong>
                                        {{
                                        salesSummaryValue?.orderContactInformation[0].postalAddress?salesSummaryValue?.orderContactInformation[0].postalAddress.toName
                                        :salesSummaryValue?.orderContactInformation[1].postalAddress.toName}}
                                        <span
                                          class="d-block">{{salesSummaryValue?.orderContactInformation[0].postalAddress?
                                          salesSummaryValue?.orderContactInformation[0].postalAddress.address1:salesSummaryValue?.orderContactInformation[1].postalAddress.address1}}</span>
                                        <span
                                          class="d-block">{{salesSummaryValue?.orderContactInformation[0].postalAddress
                                          ? salesSummaryValue?.orderContactInformation[0].postalAddress.city
                                          :salesSummaryValue?.orderContactInformation[1].postalAddress.city}}</span>
                                        <span
                                          class="d-block">{{salesSummaryValue?.orderContactInformation[0].postalAddress
                                          ?
                                          salesSummaryValue?.orderContactInformation[0].postalAddress.countryGeoId:salesSummaryValue?.orderContactInformation[1].postalAddress.countryGeoId
                                          }}</span>
                                      </p>
                                    </div>
                                    <div class="col-md-12 pl-0" style="margin-top:10px;">
                                      <p-dropdown [options]="salesSummaryValue?.orderContactInformation"
                                        placeholder="Select Address" optionLabel="postalAddress.address1"
                                        optionValue="postalAddress.address1">
                                      </p-dropdown>
                                    </div>
                                    <div class="w-100 col-md-12 text-right main-submit-button">
                                      <button type="submit" class="btn btn-secondary submit-btn ">
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==4">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Roles
                            </h4>

                          </div>
                          <div class="panel-body a">
                            <div class="mt-2">
                              <div class="">
                                <div class="row m-0">
                                  <div class="card confimation-table own-account-tables">
                                    <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%"
                                      [paginator]="false" [rows]="rows"
                                      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true"
                                      [totalRecords]="total" [value]="salesSummaryValue?.orderRole" [scrollable]="true"
                                      styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                      <ng-template pTemplate="colgroup" let-columns>
                                        <colgroup>
                                          <col *ngFor="let col of offerShowingLineColoumns">
                                        </colgroup>
                                      </ng-template>
                                      <ng-template pTemplate="header">
                                        <tr class="activity-rows">
                                          <th id="tableHead" class="tabTh" *ngFor="let col of offerShowingLineColoumns">
                                            <ng-container style="color: white!important;">

                                              {{col.header}}
                                            </ng-container>
                                          </th>
                                        </tr>
                                      </ng-template>
                                      <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity>
                                        <tr class="activity-data">
                                          <td *ngFor="let col of offerShowingLineColoumns" class="font14">
                                            <ng-container *ngIf="col.field === 'status'">
                                              <span>[{{activity.roleTypeId}}]{{activity.roleType}}</span>
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'name'">
                                              <span class="d-flex red-border align-items-center">
                                                <div class="name_text">
                                                  <a>
                                                    [{{activity.partyId}}] {{activity.partyName}}
                                                  </a>
                                                </div>
                                              </span>
                                            </ng-container>
                                          </td>
                                        </tr>
                                      </ng-template>
                                    </p-table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==5">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Actions
                            </h4>
                            <span>
                              <button type="submit" class="btn btn-secondary submit-btn mr-2" 
                              data-toggle="modal" data-target="#OrderDelivery">View/Edit Delivery Schedule Info</button>
                              <button type="submit" class="btn btn-secondary submit-btn mr-2" 
                             (click)="editOrderItemDetails()">Edit Items</button>
                              <button type="submit" class="btn btn-secondary submit-btn mr-2" 
                              (click)="addOrderItemShipGroup()">Create New Ship Group</button>
                               <button type="submit" class="btn btn-secondary submit-btn mr-2" 
                              routerLink="/order/sales-order-entry-terms">Create as new order</button>
                               <button type="submit" class="btn btn-secondary submit-btn mr-2" 
                              routerLink="/order/view-order-history">View Order History</button>
                               <button type="submit" class="btn btn-secondary submit-btn mr-2" 
                              (click)="changesTab()">View ship group by order item</button>
  
                            </span> 
                           
                          </div>
                          <div class="panel-body">
                            <div class="mt-2">
                              <div class="">
                                <div class="row">
                                  <form class="w-100">
                                    <div class="all-users-infomation popup-infos font-13 font-medium own-user-select">
                                      <p class="col-lg-12  col-12" style="color:#65787e;font-size:14px;">
                                        No Actions Performed.
                                      </p>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==6">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Shipment Information - 00001
                            </h4>
                            <span class="edit-buttons"
                              *ngIf="salesSummaryValue?.statusHistory.currentStatus.toLowerCase().includes('approve')"
                              routerLink="/facilities/warehouse/detailed-summary-facilities"
                              [queryParams]="{facilityId:salesSummaryValue?.originFacilityId,orderId:salesSummaryValue?.orderId,shipGroupSeqId:salesSummaryValue?.shippingInformation[0].shipGroupSeqId}">Pack
                              Shipment For Ship Group</span>
                            <span class="edit-buttons"> New Shipment For Ship Group</span>


                          </div>
                          <div class="panel-body">
                            <div class="mt-2">
                              <div class="">
                                <div class="row">
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Address </strong>
                                    <div class="col-md-12 pl-0" style="margin-top:6px;">
                                      <p class="common-regular-properties">
                                        <p-dropdown [options]="salesSummaryValue?.orderContactInformation"
                                          placeholder="Select Address" optionLabel="postalAddress.address1"
                                          optionValue="postalAddress.address1">
                                        </p-dropdown>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Method</strong>
                                    <div class="col-md-12 pl-0" style="margin-top:6px;">
                                      <p class="common-regular-properties">
                                        <p-dropdown [options]="true" placeholder="Select Method" optionLabel="name">
                                        </p-dropdown>
                                      </p>
                                    </div>
                                    <div class="w-100 col-md-12 text-right main-submit-button">
                                      <button type="submit" class="btn btn-secondary submit-btn ">
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Splitting
                                      Preference</strong>
                                    <div class="col-md-9">
                                      <p class="w-100 common-regular-properties">Please wait until the entire order is
                                        ready before shipping
                                        <a  class="account-button" > Allow Split</a>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;">
                                      Instruction</strong>
                                    <div class="col-md-9">
                                      <p class="w-100 common-regular-properties">
                                        <a  class="account-button" > Add</a>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Gift
                                      Message</strong>
                                    <div class="col-md-9">
                                      <p class="w-100 common-regular-properties">
                                        <a  class="account-button" > Add</a>
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-12">
                                    <div class="form-group radio-boxes-wrapper">
                                      <label class="common-bold-properties" for="exampleInputEmail1">Ship After
                                        Date</label>
                                      <div class="">
                                        <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                          hourFormat="24" dateFormat="dd.mm.yy"></p-calendar>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-12">
                                    <div class="form-group radio-boxes-wrapper">
                                      <label class="common-bold-properties" for="exampleInputEmail1">Ship Before
                                        Date</label>
                                      <div class="">
                                        <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                          hourFormat="24" dateFormat="dd.mm.yy"></p-calendar>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Estimated Ship
                                      Date
                                    </strong>
                                    <div class="col-md-9">
                                      <p class="common-regular-properties">

                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Estimated
                                      Delivery
                                      Date
                                    </strong>
                                    <div class="col-md-9">
                                      <p class="common-regular-properties">

                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                    style="padding-left:15px;">
                                    <strong class="common-bold-properties" style="margin-right:10px;"> Shipment Id
                                    </strong>
                                    <div class="col-md-9">
                                      <div class="d-flex flex-column">
                                        <p class="common-regular-properties">
                                          {{ salesSummaryValue?.shipmentsData.length ?
                                          salesSummaryValue?.shipmentsData[0].shipmentId : ''}}
                                        </p>

                                      </div>
                                    </div>
                                    <button class="btn btn-primary cursor-pointer ml-2"
                                      *ngIf="salesSummaryValue?.shipmentsData[0]"
                                      (click)="printSlip(salesSummaryValue?.originFacilityId,salesSummaryValue?.shipmentsData[0].shipmentId)">Packing
                                      Slip</button>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==7">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Order Item
                            </h4>


                          </div>
                          <div class="panel-body a">
                            <div class="mt-2">
                              <div class="">
                                <div class="row m-0">
                                  <div class="card order-view-table own-account-tables">
                                    <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%"
                                      [paginator]="false" [rows]="rows"
                                      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true"
                                      [totalRecords]="total" [value]="salesSummaryValue?.orderItems" [scrollable]="true"
                                      styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                      <ng-template pTemplate="colgroup" let-columns>
                                        <colgroup>
                                          <col *ngFor="let col of orderItemsColumns">
                                        </colgroup>
                                      </ng-template>
                                      <ng-template pTemplate="header">
                                        <tr class="activity-rows">
                                          <th id="tableHead" class="tabTh" *ngFor="let col of orderItemsColumns">
                                            <ng-container style="color: white!important;">

                                              {{col.header}}
                                            </ng-container>
                                          </th>
                                        </tr>
                                      </ng-template>
                                      <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity>
                                        <tr class="activity-data">
                                          <td *ngFor="let col of orderItemsColumns">
                                            <ng-container *ngIf="col.field === 'status'">
                                              <span>{{activity.productId}} </span>
                                              <a class="d-block">Inventory</a>
                                              <p> Required for SO {{activity.inventoryMap.requiredForSO}}</p>
                                              <p> In Inventory [{{activity.facilityName}}] QOH
                                                {{activity.inventoryMap.qohQuantityByFacility ?
                                                activity.inventoryMap.qohQuantityByFacility:0}}
                                                (ATP:
                                                {{activity.inventoryMap.atpQuantityByFacility ?
                                                activity.inventoryMap.atpQuantityByFacility:0}})
                                              </p>
                                              <p>In Inventory [All Facilities] QOH
                                                {{activity.inventoryMap.inventoryAllQOH}}
                                                (ATP: {{activity.inventoryMap.inventoryAllATP}})</p>
                                              <p>On Order {{activity.inventoryMap.onOrderQuantity}}</p>
                                              <p>In Production {{activity.inventoryMap.inProductionQuantity}}</p>
                                              <p>Unplanned {{activity.inventoryMap.unplannedQuantity}}</p>



                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'name'">
                                              <span class="d-flex red-border align-items-center ">
                                                <div class="name_text">
                                                  <span>
                                                    {{activity.statusId}}
                                                  </span>
                                                  <p> Reserve After Date
                                                  </p>
                                                  <p>Current {{activity.currentItemStatus.statusCode}}
                                                  </p>
                                                  <p>
                                                    {{activity.currentItemStatus.createdStamp |date :'yyyy-MM-dd HH:mm:ss'}}
                                                  </p>
                                                  <p>{{activity.currentItemStatus.description }}</p>
                                                </div>
                                              </span>
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'email'">
                                              <span class="d-block align-items-center">
                                                <p>Ordered {{activity.OrderShipRequest}} Ship Request
                                                  {{activity.OrderShipRequest}}</p>
                                                <p>Cancelled {{activity.cancelQuantity ? activity.cancelQuantity : 0}}
                                                  Qty
                                                  Picked {{activity.cancelQuantity ? activity.cancelQuantity : 0}}</p>
                                                <p>Remaining 1 Qty Shipped </p>
                                                <p>Shortfall 0 Outstanding 1</p>
                                                <p>Invoiced 0 Returned 0</p>
                                              </span>
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'phone'"><span>
                                                US${{activity.unitPrice}}/US${{activity.unitListPrice}}</span>
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'type'">
                                              <span>US$0</span>
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'total'">
                                              <span>{{activity.orderItemSubTotal}}</span>
                                            </ng-container>
                                          </td>
                                        </tr>
                                      </ng-template>
                                    </p-table>
                                  </div>

                                  <div class="row w-100" style="padding-top:10px;">
                                    <div class="col-md-10 text-right">
                                      <strong class="common-bold-properties confirm-price-text d-block">Items SubTotal
                                      </strong>
                                      <strong class="common-bold-properties  confirm-price-text d-block">Total Other
                                        Order
                                        Adjustments </strong>
                                      <strong class="common-bold-properties confirm-price-text  d-block">Total Shipping
                                        and Handling </strong>
                                      <strong class="common-bold-properties confirm-price-text  d-block">Total Sales Tax
                                      </strong>
                                      <br>
                                      <strong class="common-bold-properties confirm-price-text d-block">Total Due
                                      </strong>
                                    </div>
                                    <div class="col-md-2 text-right">
                                      <p class="common-regular-properties confirm-price-text  d-block">
                                        US${{salesSummaryValue?.orderSubTotal}}</p>
                                      <p class="common-regular-properties  confirm-price-text  d-block">
                                        -US${{salesSummaryValue?.otherOrderAdjustments}}</p>
                                      <p class="common-regular-properties confirm-price-text d-block">
                                        US${{salesSummaryValue?.totalShippingAndHandling}}</p>
                                      <p class="common-regular-properties confirm-price-text d-block">
                                        US${{salesSummaryValue?.taxAmount}}</p>
                                      <br>
                                      <p class="common-regular-properties confirm-price-text d-block">
                                        US${{salesSummaryValue?.grandTotal}}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==8">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Notes
                            </h4>
                            <span class="edit-buttons" routerLinkActive="active"
                              routerLink="/order/create-note" [queryParams]="{orderId:this.orderId,isSales:true}">
                              Create New Note</span>

                          </div>
                          <div class="panel-body">
                            <div class="mt-2">
                              <div class="">
                                <div class="row">
                                  <form class="w-100">
                                    <div class="all-users-infomation popup-infos font-13 font-medium own-user-select">

                                      <div class="card own-account-table">
                                        <p-table [value]="noteList" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                          [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                          [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">

                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'190px'}">
                                                <div class="d-flex flex-column">
                                                  <p>By: {{product.notePartyName}}</p>
                                                  <p>At: {{product.noteDateTime |date:'yyyy-MM-d hh:mm:ss'}}</p>
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'190px'}">{{product.noteInfo}}</td>
                                              <td [ngStyle]="{'width':'190px'}">

                                                <fa-icon [icon]="faTrash" class="text-danger cursor-pointer"
                                                  (click)="deleteNote(product.noteId)"></fa-icon>
                                              </td>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                              <td>
                                                <span class="text-center">
                                                  <h4 class="ml-2">No Record Found</h4>
                                                </span>
                                              </td>
                                            </tr>
                                          </ng-template>
                                        </p-table>
                                        <p class="paginate_data">
                                          View per page </p>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="activeCategory==9">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              All Communication Events
                            </h4>
                            <span class="edit-buttons" data-toggle="modal" data-target="#exampleOrder">
                              New Conversation</span>



                          </div>
                          <div class="panel-body">
                            <div class="mt-2">
                              <div class="">
                                <div class="row">
                                  <form class="w-100">
                                    <div class="all-users-infomation popup-infos font-13 font-medium own-user-select px-3">

                                      <ng-template ngFor let-conversation [ngForOf]="conversationList"
                                        let-index="index">
                                        <div class="card text-black w-100">
                                          <div class="card-header">
                                            <h5>{{conversation.entryDate |date:'yyyy-MM-d hh:mm:ss'}}
                                              {{conversation.subject}}</h5>
                                          </div>
                                          <div class="card-body">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="d-flex flex-column">
                                                  <span>Subject : {{conversation.subject}}</span>
                                                  <span>From : {{conversation.partyIdFromName}}</span>
                                                  <span>Mail From :</span>
                                                  <span>Date: {{conversation.entryDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                  </span>
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="d-flex flex-column">
                                                  <span>To : {{conversation.partyIdToName}}</span>
                                                  <span>Mail To : New Answer</span>
                                                </div>
                                              </div>
                                              <div class="col-md-12">
                                                <p class="text-center">
                                                  {{conversation.content}}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-template>
                                      <div class="modal fade" id="exampleOrder" tabindex="-1" role="dialog"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                          <div class="modal-content">
                                            <div class="modal-header popup-header">
                                              <h5 class="modal-title popup-title" id="exampleModalLabel"> New
                                                Conversation
                                              </h5>
                                              <button type="button" class="close" data-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                              </button>
                                            </div>
                                            <div class="modal-body" style="height: calc(100vh - 276px);overflow-y:auto;">
                                              <form [formGroup]="conversationForm">
                                                <div class="row">
                                                  <div class="col-lg-12">
                                                    <div class="row">
                                                      <div class="col-lg-6 form-group rightForm">
                                                        <label for="exampleInputEmail1">Communication Event Purpose Type
                                                          ID
                                                        </label>
                                                      </div>
                                                      <div class="col-lg-5">
                                                        <p-dropdown [options]="communicationEventPurposeIds"
                                                          filter="true" placeholder="Select Id"
                                                          formControlName="communicationEventPrpTypId"></p-dropdown>
                                                      </div>
                                                    </div>
                                                  </div>



                                                  <div class="col-lg-12">
                                                    <div class="row">

                                                      <div class="col-lg-6 form-group rightForm">
                                                        <label for="exampleInputEmail1">Party To</label>
                                                      </div>

                                                      <div class="col-lg-5">
                                                        <p-dropdown [options]="partyIds" filter="true"
                                                          placeholder="Select Party Id" formControlName="partyIdTo">
                                                        </p-dropdown>
                                                      </div>

                                                    </div>
                                                  </div>

                                                  <div class="col-lg-12">
                                                    <div class="row">
                                                      <div class="col-lg-6 form-group rightForm">
                                                        <label class="common-labels">Subject</label>
                                                      </div>

                                                      <div class="col-lg-5">
                                                        <input type="text" class="form-control"
                                                          placeholder="Enter Subject" formControlName="subject">
                                                      </div>
                                                    </div>
                                                  </div>


                                                  <div class="col-lg-12">
                                                    <div class="row">

                                                      <div class="col-lg-6 form-group rightForm">
                                                        <label style="color: #000 !important;"
                                                          for="exampleInputEmail1">Message</label>
                                                      </div>

                                                      <div class="col-lg-5">
                                                        <textarea name="" id="exampleInputEmail1"
                                                          formControlName="content" class="textarea-popup form-control"
                                                          cols="30" rows="10"></textarea>
                                                      </div>

                                                    </div>
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                            <div
                                              class="modal-footer popup-footer w-100 col-md-12 text-right main-submit-button">
                                              <button type="submit" class="btn btn-secondary submit-btn "
                                                (click)="createConversation()" style="margin-right: 40px;">Send</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>











              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="main-submit-button">
                <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
            </div> -->
  </div>

  <div class="modal fade" id="OrderDelivery" tabindex="-1" role="dialog" aria-labelledby="profilePartyAttributeTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLengthone">
            <div class="modal-header">
  
                <span class="color-black pl-1">Schedule Delivery</span>
  
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
  
                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                  Schedule Delivery</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade ">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="scheduleDelivery">
                                        <div class="mt-3 all-users-infomation font-13 font-medium own-user-select">
                                          <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightFormCA">
                                                    <label for="exampleInputEmail1">
                                                      Estimated Ready Date
                                                    </label>
                                                </div>
                                                <div class="col-lg-3">
                                                  <input type="date"
                                                  formControlName="estimatedReadyDate"
                                                  class="form-control"
                                                  id="exampleInputEmail1"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter Date">
                                                    <!-- <p-dropdown filter="true"
                                                        optionlabel="label" [options]=""
                                                        formControlName="dataResourceID"
                                                        placeholder="Select Data Resource ID	">

                                                    </p-dropdown> -->
                                                </div>

                                                <div class="col-lg-3 form-group rightFormCB">
                                                    <label for="exampleInputEmail1">Cartons</label>
                                                </div>
                                                <div class="col-lg-3">
                                                  <input type="text"
                                                  formControlName="cartons"
                                                  class="form-control"
                                                  id="exampleInputEmail1"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter Cartons">
                                                </div>




                                            </div>
                                          </div>

                                          <div class="col-lg-12">
                                              <div class="row">
                                                  <div class="col-lg-3 form-group rightFormCA">
                                                      <label for="exampleInputEmail1">Skids Pallets</label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <input type="text"
                                                          formControlName="skidsPallets"
                                                          class="form-control"
                                                          id="exampleInputEmail1"
                                                          aria-describedby="emailHelp"
                                                          placeholder="Enter Skids">

                                                  </div>

                                                  <div class="col-lg-3 form-group rightFormCB">
                                                      <label for="exampleInputEmail1">Units Pieces</label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                    <input type="text"
                                                    formControlName="unitsPieces"
                                                    class="form-control"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Units Pieces">
                                                    

                                                  </div>




                                              </div>
                                          </div>

                                          <div class="col-lg-12">
                                              <div class="row">
                                                  <div class="col-lg-3 form-group rightFormCA">
                                                      <label for="exampleInputEmail1">Total Cubic Size</label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                    <input type="text"
                                                    formControlName="totalCubicSize"
                                                    class="form-control"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Cubic Size">
                                                  </div>

                                                  <div class="col-lg-3 form-group rightFormCB">
                                                      <label for="exampleInputEmail1">Cubic Measurement</label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <p-dropdown filter="true"
                                                          optionlabel="label" [options]="CubicMeasurementListDataArray"
                                                          formControlName="totalCubicUomId"
                                                          placeholder="Select Cubic Measurement">

                                                      </p-dropdown>

                                                  </div>




                                              </div>
                                          </div>
                                          <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightFormCA">
                                                    <label for="exampleInputEmail1">Total Weight</label>
                                                </div>
                                                <div class="col-lg-3">
                                                  <input type="text"
                                                  formControlName="totalWeight"
                                                  class="form-control"
                                                  id="exampleInputEmail1"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter Total Weight">
                                                </div>

                                                <div class="col-lg-3 form-group rightFormCB">
                                                    <label for="exampleInputEmail1">Weight Measurement</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p-dropdown filter="true"
                                                        optionlabel="label" [options]="WeightMeasurementListDataArray"
                                                        formControlName="totalWeightUomId"
                                                        placeholder="Select Weight Measurement">

                                                    </p-dropdown>

                                                </div>




                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                          <div class="row">
                                              <div class="col-lg-3 form-group rightFormCA">
                                                  <label for="exampleInputEmail1">Status ID</label>
                                              </div>
                                              <div class="col-lg-3">
                                                <p-dropdown filter="true"
                                                    optionlabel="label" [options]="statusIdArray"
                                                    formControlName="statusId"
                                                    placeholder="Select Status ID">

                                                </p-dropdown>
                                              </div>

                                          </div>
                                        </div>
  
                                            
                                     
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 59%;">
                                            <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>