<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Cost</span>
                <span class="color-black pl-1" *ngIf="!show"> >Add New Cost Component Calc </span>
                <span class="color-black pl-1" *ngIf="show"> >Update New Cost Component Calc</span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                                    Add New Cost Component Calc</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                    Update New Cost Component Calc</a></li>


                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="createCost">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Description

                                                    </label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Description"
                                                        formControlName="description">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Cost Gl Account Type ID<span
                                                            style="color:red">*</span> </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Cost Gl Account Type ID"
                                                        filter="true" formControlName="costGlAccountTypeId"
                                                        [options]="glAccountTypeArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>



                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Offsetting Gl Account Type
                                                        ID</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Offsetting Gl Account Type ID"
                                                        filter="true" formControlName="offsettingGlAccountTypeId"
                                                        [options]="glAccountTypeArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Fixed Cost

                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Fixed Cost"
                                                        formControlName="fixedCost">
                                                </div>



                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Variable Cost</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Variable Cost"
                                                        formControlName="variableCost">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Per Milli Second </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Per Milli Second	"
                                                        formControlName="perMilliSecond">
                                                </div>



                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Currency<span
                                                            style="color:red">*</span> </label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Currency" filter="true"
                                                        formControlName="currencyUomId" [options]="uomArray"
                                                        optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Cost Custom Method ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Cost Custom Method ID" filter="true"
                                                        formControlName="costCustomMethodId"
                                                        [options]="costCustomMethodIDArray" optionlabel="label">

                                                    </p-dropdown>

                                                </div>



                                            </div>
                                        </div>


                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 74%;">
                                    <button type="submit" *ngIf="show" (click)="onUpdate()"
                                        class="btn btn-secondary submit-btn">Update</button>
                                    <button type="submit" *ngIf="!show" (click)="onSubmit()"
                                        class="btn btn-secondary submit-btn">Submit</button>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>