import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { FinancialService } from 'src/app/services/financial.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-create-accounting-transaction',
  templateUrl: './create-accounting-transaction.component.html',
  styleUrls: ['./create-accounting-transaction.component.css']
})
export class CreateAccountingTransactionComponent implements OnInit {
  show: boolean;
  activeCategory=2;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  accountTransaction:FormGroup;
  partyId: any;
  glFiscalType: any;
  glJournal: any;
  fixedAsset: any;
  groupStatus: any;
  accTransactionType: any;
  glFiscalTypeArray: any[]=[];
  glJournalArray: any[]=[];
  fixedAssetArray: any[]=[];
  groupStatusArray: any[]=[];
  accTransactionTypeArray: any[]=[];
  Products: any;
  ProductsArray: any[]=[];
  workEffortArray: any[]=[];
  FacilityShipments: any;
  FacilityShipmentsArray: any[]=[];
  PaymentsList: any;
  getPaymentsListArray: any[]=[];
  invoiceList: any;
  invoiceListArray: any[]=[];
  posted: { label: string; value: string; }[];
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  CreatedDate: any;
  LastModifiedDate: any;
  PostedDate: any;
  ScheduledPostingDate: any;
  VoucherDate: any;
  TransactionDate: any;
  acctran: any;
  getEstimateFrom: string;
  getEstimateFromDate: string;
  voucherDate: string;
  scheduledPostingDate: string;
  lastModifiedDate: string;
  createdTxStamp: string;
  constructor(
    
    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
    readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,
    readonly _ActivatedRoute: ActivatedRoute,
  ) { 
    this.accountTransaction = this._FormBuilder.group({
      AcctgTransType: [''], FiscalGlType: [''],
      GlJournalID: [''],GroupStatus: [''], FinAccountTrasID: [''], PartyID: [''], RoleTypeID: [''], TransactionDate: [''],
      ScheduledPostingDate: [''],PostedDate: [''],IsPosted: [''],
      InventoryItemID: [''], PhysicalInventoryID: [''],
      InvoiceID: [''],
      PaymentID: [''],
      ProductID: [''],
      WorkEffortID: [''],
      ShipmentID: [''],
      FixedAssetID: [''],
      ReceiptID: [''],
      TheirAcctgTransID: [''],
      VoucheRef: [''],
      VoucherDate: [''],
      Description: [''],
      CreatedDate: [''],
      LastModifiedDate: ['']
    });
    this.posted=[{
      'label':'Yes',
      'value':'Y'
    },
    {
      'label':'No',
      'value':'N'
    }]
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.acctran=params["acctran"];
    });
    

    
    this.getProducts();
    this.getWorkEffortList();
    this.getInvoices();
    this.getPaymentsList();
    this.getFacilityShipments();
    this.initialTransData();
    this.getRoleAllType();
    this.getPartyId();
    if(this.acctran){
      this.show=true;
      this.findTransactionDetails();
    }else{
      this.show=false;
    }
  }
  findTransactionDetails() {
    this.spinner.show();
    this.accountsService.getTransacDetails(this.partyId,this.acctran).subscribe((res: any) => {
      setTimeout(() => {
        const fromdate=res.data.postedDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=res.data.transactionDate
        this.getEstimateFromDate=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const voucherDate=res.data.voucherDate;
        this.voucherDate=this.datePipe.transform(voucherDate,"yyyy-MM-dd");
        const scheduledPostingDate=res.data.scheduledPostingDate;
        this.scheduledPostingDate=this.datePipe.transform(scheduledPostingDate,"yyyy-MM-dd");
        const lastModifiedDate=res.data.lastModifiedDate;
        this.lastModifiedDate=this.datePipe.transform(lastModifiedDate,"yyyy-MM-dd");
        const createdTxStamp=res.data.createdTxStamp;
        this.createdTxStamp=this.datePipe.transform(createdTxStamp,"yyyy-MM-dd");
        const formValue = this.accountTransaction;
        formValue.patchValue({
          AcctgTransType: res.data.acctgTransTypeId,FiscalGlType: res.data.glFiscalTypeId,GlJournalID: res.data.glJournalId,
          GroupStatus: res.data.groupStatusId,FinAccountTrasID: res.data.finAccountTransId,PartyID:res.data.partyId,
          RoleTypeID: res.data.roleTypeId,TransactionDate: this.getEstimateFromDate,ScheduledPostingDate:  this.scheduledPostingDate,
          PostedDate:this.getEstimateFrom,IsPosted: res.data.isPosted,InventoryItemID:res.data.inventoryItemId,PhysicalInventoryID: res.data.physicalInventoryId,
          InvoiceID:res.data.invoiceId,PaymentID:res.data.paymentId, WorkEffortID: res.data.workEffortId,
          ShipmentID: res.data.shipmentId,FixedAssetID:res.data.fixedAssetId,ReceiptID: res.data.receiptId,TheirAcctgTransID: res.data.theirAcctgTransId,VoucheRef: res.data.voucherRef,
          VoucherDate:this.voucherDate, Description:res.data.description,CreatedDate: this.createdTxStamp,LastModifiedDate:this.lastModifiedDate
        }) }, 3000);
      this.spinner.hide();
    }) }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    this.spinner.hide();
  }
 
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  getWorkEffortList(): void {
    this.spinner.show();
    this.accountsService.getWorkEfforts().subscribe((res) => {
      const workEfforts = res.data;
      this.spinner.hide();
      for (const value of workEfforts) {
        this.workEffortArray.push({
          label: value.workEffortName + " [" + value.workEffortId + "]",
          value: value.workEffortId,
        });
      }
    });
   
  }
  
  getInvoices() {
    this.spinner.show();
    const req = {
      "description": "",
      "descriptionSearchType": "Contains",
      "fromPartyId": "",
      "invoiceDateFrom": "",
      "invoiceDateFromSearchType": "Contains",
      "invoiceDateTo": "",
      "invoiceDateToSearchType": "Contains",
      "invoiceId": "",
      "invoiceIdSearchType": "Contains",
      "invoiceType": "PURCHASE_INVOICE",
      "statusId": "",
      "toPartyId": ""
    }
    this.accountsService.getInvoices(this.finSize, req).subscribe((res: any) => {
      this.invoiceList = res.data;
      this.spinner.hide();
      for (const value of this.invoiceList) {
        this.invoiceListArray.push({
          label: value.invoiceId,
          value: value.invoiceId
        })
      }
    })
  }
  getPaymentsList() {
    this.spinner.show();
    const req = {
      "amount": "",
  "comments": "",
  "commentsSearchType": "Contains",
  "fromPartyId": "",
  "paymentId": "",
  "paymentIdSearchType": "Contains",
  "paymentType": "",
  "status": "",
  "toPartyId": ""
    }
    this.accountsService.getPaymentsList(this.finSize, req).subscribe((res: any) => {

      this.PaymentsList = res.data;
      this.spinner.hide();
      for (const value of this.PaymentsList) {
        this.getPaymentsListArray.push({
          label: value.paymentId,
          value: value.paymentId
        })
      }
    })
  }
  getFacilityShipments() {
    this.spinner.show();
    const req = {
      "destinationFacility": "",
  "entryDateFrom": "",
  "entryDateFromSearchType": "Contains",
  "entryDateTo": "",
  "entryDateToSearchType": "Contains",
  "estimatedShipDateFrom": "",
  "estimatedShipDateFromSearchType": "Contains",
  "estimatedShipDateTo": "",
  "estimatedShipDateToSearchType": "Contains",
  "originFacility": "",
  "shipmentId": "",
  "shipmentIdSearchType": "Contains",
  "shipmentType": "",
  "statusId": [
    ""
  ]
    }
    this.accountsService.getFacilityShipments(this.finSize, req).subscribe((res: any) => {
      this.FacilityShipments = res.data;
      this.spinner.hide();
      for (const value of this.FacilityShipments) {
        this.FacilityShipmentsArray.push({
          label: value.shipmentId,
          value: value.shipmentId
        })}
    }) }
  getProducts() {
    this.spinner.show();
    const req = {
      "internalName": "",
      "internalNameSearchType": "Contains",
      "productId": "",
      "productIdSearchType": "Contains"
     

    }
    this.accountsService.getProducts(this.finSize, req).subscribe((res: any) => {

      this.Products = res.data;
      this.spinner.hide();
      for (const value of this.Products) {
        this.ProductsArray.push({
          label: value.productName,
          value: value.productId
        })
      }


    })
   

  }
  initialTransData() {
    this.spinner.show();
    this.accountsService.initialTransData().subscribe((res: any) => {
      this.glFiscalType = res.data.glFiscalType;this.glJournal = res.data.glJournal;this.fixedAsset = res.data.fixedAsset;this.groupStatus = res.data.groupStatus;this.accTransactionType = res.data.accTransactionType;
      this.spinner.hide();
      for (const value of this.glFiscalType) {
        this.glFiscalTypeArray.push({
          label: value.description,
          value: value.glFiscalTypeId
        }) }
      for (const value of this.glJournal) {
        this.glJournalArray.push({
          label: value.glJournalName,
          value: value.glJournalId
        })}
      for (const value of this.fixedAsset) {
        this.fixedAssetArray.push({
          label: value.fixedAssetId,
          value: value.fixedAssetId
        })}
      for (const value of this.groupStatus) {
        this.groupStatusArray.push({
          label: value.description,
          value: value.statusId
        })}
      for (const value of this.accTransactionType) {
        this.accTransactionTypeArray.push({
          label: value.description,
          value: value.acctgTransTypeId
        }) }})}
 
  onSubmit() {
    this.spinner.show();
    const CreatedDate=this.accountTransaction.get('CreatedDate').value;
    this.CreatedDate=this.datePipe.transform(CreatedDate,"yyyy-MM-dd hh:mm:ss");
    const LastModifiedDate=this.accountTransaction.get('LastModifiedDate').value;
    this.LastModifiedDate=this.datePipe.transform(LastModifiedDate,"yyyy-MM-dd hh:mm:ss");
    const PostedDate=this.accountTransaction.get('PostedDate').value;
    this.PostedDate=this.datePipe.transform(PostedDate,"yyyy-MM-dd hh:mm:ss");
    const ScheduledPostingDate=this.accountTransaction.get('ScheduledPostingDate').value;
    this.ScheduledPostingDate=this.datePipe.transform(ScheduledPostingDate,"yyyy-MM-dd hh:mm:ss");
    const TransactionDate=this.accountTransaction.get('TransactionDate').value;
    this.TransactionDate=this.datePipe.transform(TransactionDate,"yyyy-MM-dd hh:mm:ss");
    const VoucherDate=this.accountTransaction.get('VoucherDate').value;
    this.VoucherDate=this.datePipe.transform(VoucherDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "acctgTransTypeId": this.accountTransaction.value.AcctgTransType,
      "createdDate": this.CreatedDate,
      "description": this.accountTransaction.value.Description,
      "finAccountTransId": this.accountTransaction.value.FinAccountTrasID,
      "fixedAssetId": this.accountTransaction.value.FixedAssetID,
      "glFiscalTypeId": this.accountTransaction.value.FiscalGlType,
      "glJournalId": this.accountTransaction.value.GlJournalID,
      "groupStatusId": this.accountTransaction.value.GroupStatus,
      "inventoryItemId": this.accountTransaction.value.InventoryItemID,
      "invoiceId": this.accountTransaction.value.InvoiceID,
      "isPosted": this.accountTransaction.value.IsPosted,
      "lastModifiedDate": this.LastModifiedDate,
      "partyId": this.accountTransaction.value.PartyID,
      "paymentId": this.accountTransaction.value.PaymentID,
      "physicalInventoryId": this.accountTransaction.value.PhysicalInventoryID,
      "postedDate": this.PostedDate,
      "productId": this.accountTransaction.value.ProductID,
      "receiptId":this.accountTransaction.value.ReceiptID,
      "roleTypeId":this.accountTransaction.value.RoleTypeID,
      "scheduledPostingDate": this.ScheduledPostingDate,
      "shipmentId": this.accountTransaction.value.ShipmentID,
      "theirAcctgTransId": this.accountTransaction.value.TheirAcctgTransID,
      "transactionDate":  this.TransactionDate,
      "voucherDate":  this.VoucherDate,
      "voucherRef": this.accountTransaction.value.VoucheRef,
      "workEffortId": this.accountTransaction.value.WorkEffortID,
    }
    this.accountsService.createAccountTransaction(this.partyId,requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdate() {
    this.spinner.show();
    const CreatedDate=this.accountTransaction.get('CreatedDate').value;
    this.CreatedDate=this.datePipe.transform(CreatedDate,"yyyy-MM-dd hh:mm:ss");
    const LastModifiedDate=this.accountTransaction.get('LastModifiedDate').value;
    this.LastModifiedDate=this.datePipe.transform(LastModifiedDate,"yyyy-MM-dd hh:mm:ss");
    const PostedDate=this.accountTransaction.get('PostedDate').value;
    this.PostedDate=this.datePipe.transform(PostedDate,"yyyy-MM-dd hh:mm:ss");
    const ScheduledPostingDate=this.accountTransaction.get('ScheduledPostingDate').value;
    this.ScheduledPostingDate=this.datePipe.transform(ScheduledPostingDate,"yyyy-MM-dd hh:mm:ss");
    const TransactionDate=this.accountTransaction.get('TransactionDate').value;
    this.TransactionDate=this.datePipe.transform(TransactionDate,"yyyy-MM-dd hh:mm:ss");
    const VoucherDate=this.accountTransaction.get('VoucherDate').value;
    this.VoucherDate=this.datePipe.transform(VoucherDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "acctgTransTypeId": this.accountTransaction.value.AcctgTransType,
      "createdDate": this.CreatedDate,
      "description": this.accountTransaction.value.Description,
      "finAccountTransId": this.accountTransaction.value.FinAccountTrasID,
      "fixedAssetId": this.accountTransaction.value.FixedAssetID,
      "glFiscalTypeId": this.accountTransaction.value.FiscalGlType,
      "glJournalId": this.accountTransaction.value.GlJournalID,
      "groupStatusId": this.accountTransaction.value.GroupStatus,
      "inventoryItemId": this.accountTransaction.value.InventoryItemID,
      "invoiceId": this.accountTransaction.value.InvoiceID,
      "isPosted": this.accountTransaction.value.IsPosted,
      "lastModifiedDate": this.LastModifiedDate,
      "partyId": this.accountTransaction.value.PartyID,
      "paymentId": this.accountTransaction.value.PaymentID,
      "physicalInventoryId": this.accountTransaction.value.PhysicalInventoryID,
      "postedDate": this.PostedDate,
      "productId": this.accountTransaction.value.ProductID,
      "receiptId":this.accountTransaction.value.ReceiptID,
      "roleTypeId":this.accountTransaction.value.RoleTypeID,
      "scheduledPostingDate": this.ScheduledPostingDate,
      "shipmentId": this.accountTransaction.value.ShipmentID,
      "theirAcctgTransId": this.accountTransaction.value.TheirAcctgTransID,
      "transactionDate":  this.TransactionDate,
      "voucherDate":  this.VoucherDate,
      "voucherRef": this.accountTransaction.value.VoucheRef,
      "workEffortId": this.accountTransaction.value.WorkEffortID,
    }
    this.accountsService.updateAccountTransaction(this.partyId, this.acctran,requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });
  }
  
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId:  this.partyId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
