import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
@Component({
  selector: 'app-quick-add-shopping',
  templateUrl: './quick-add-shopping.component.html',
  styleUrls: ['./quick-add-shopping.component.css']
})
export class QuickAddShoppingComponent implements OnInit {
  createContact:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search = ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }

  allrole: any;
  allroleArray: any[]=[];
  roleTypeId: any;
  sequenceNumber: any;
  productStoresListArray: any[]=[];
  productStoreId: any;
  shoppingListId: any;
  productArray: any[]=[];
  productId: any;
  quantity: any;
  seqId: any;
  quantityPurchase: any;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    public createCont: CreateContactsService,
    readonly accountsService: AccountsService,) { 
    
      this.createContact = this._FormBuilder.group({
        QuantityPurchased: [''],
        Quantity: [''],
        Product:[''],
 
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

this.lookupProduct();
this._ActivatedRoute.queryParams.subscribe(params => {
  this.shoppingListId = params["shoppingListId"];
  this.party=params["party"];
  this.productId=params["productId"];
  this.quantity=params["quantity"];
  this.seqId=params["seqId"];
  this.quantityPurchase=params["quantityPurchase"]
 
});

    if(this.seqId) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.createContact;
      formValue.patchValue({
        QuantityPurchased:this.quantityPurchase,
        Quantity:this.quantity,
        Product:this.productId
      })
      }, 3000); 
    }
    else {
      
      this.show = false;
    }
 
  }
  lookupProduct(): void {
    this.spinner.show();
    this.accountsService.lookupProduct(this.finSize).subscribe((res) => {
      let product = res.data;
      this.spinner.hide();
      for (const value of product) {
        this.productArray.push({
          label: value.lookupData.productId,
          value: value.lookupData.productId,
        });
      }
    });
   
  }

     
  onSubmit() {
    this.spinner.show();
   
    const requestData = {
      "partyId":this.party,
      "productId":this.createContact.value.Product,
      "quantity":this.createContact.value.Quantity,
      "shoppingListId": this.shoppingListId

    }
    this.accountsService.addItemToShoppingList(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
   onUpdate() {
    this.spinner.show();

    const requestData = {
     
      "quantity": this.createContact.value.Quantity,
      "quantityPurchased": this.createContact.value.QuantityPurchased,
      "shoppingListId": this.shoppingListId,
      "shoppingListItemSeqId":this.seqId
 
    }
    this.accountsService.updateShoppingListItem(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }   
   
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }}


