import { Component, OnInit, OnDestroy } from "@angular/core";
import { HeaderService } from "../../header/header.service";
import { SortEvent } from "primeng/api";
import { Router } from "@angular/router";
import { AccountsService } from "../../crm/accounts/accounts.service";
import { DropdownModule } from "primeng/dropdown";
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { OrderManagementService } from '../order-management.service';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-create-order",
  templateUrl: "./create-sales-continue.component.html",
  styleUrls: ["./create-sales-continue.component.css"],
})
export class CreateSalesContinueComponent implements OnInit {
  activeCategory = 1;
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 2,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };
  products3: any;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  productListArray: any = [];
  soFormData: any = {};
  orderItems: any[];
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: "",
  };
  totalAmount: any;
  promotions: any[];
  promoId: string;
  promotionValue: any[];
  constructor(
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _OrderManagementService: OrderManagementService
  ) {
    this.soFormData = {
      productId: "",
      quantity: "",
      itemDesiredDeliveryDate: "",
      useAsDefaultDesiredDeliveryDate: true,
      shipAfterDate: "",
      shipBeforeDate: "",
      reserveAfterDate: "",
      itemComment: "",
      useAsDefaultComment: true
    };
    this.orderItems = [];
    this.promotions = [];
    this.promotionValue = [];
    this.promoId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails();
   
    this.getProducts();
    this.getPromotions();
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  getPromotions(): void {
    this.spinner.show();
    this._OrderManagementService.getAllPromotion()
      .then(data => {
        this.spinner.hide();
        this.promotions = data.data;
        this.promotionValue = data.data.map(value => {
          return {
            label: value.productPromoId,
            value: value.productPromoId
          };
        });
      });
     
  }
  addPromotion(): void {
    this.spinner.show();
    if (this.promoId) {
      this._OrderManagementService.addPromo(this.promoId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Promotion added successfully');
            this.promoId = '';
          }
        });
    }
    this.spinner.hide();
  }
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
   
    });
  
  }

  addToOrder(): void {
    this.spinner.show();
    const data = this.soFormData;
    data.itemDesiredDeliveryDate = data.itemDesiredDeliveryDate ? moment(data.itemDesiredDeliveryDate).format('YYYY-MM-DD HH:mm:ss') : '';
    data.reserveAfterDate = data.reserveAfterDate ? moment(data.reserveAfterDate).format('YYYY-MM-DD HH:mm:ss') : '';
    data.shipAfterDate = data.shipAfterDate ? moment(data.shipAfterDate).format('YYYY-MM-DD HH:mm:ss') : '';
    data.shipBeforeDate = data.shipBeforeDate ? moment(data.shipBeforeDate).format('YYYY-MM-DD HH:mm:ss') : '';

    this.accountsService.addToOrder(data).subscribe((res) => {
      if (res.success) {
        if (res.data.errorMessage) {
          this._ToastrService.error(res.data.errorMessage);
        } else {
          this._ToastrService.success('Order added successfully');
          this.spinner.hide();
        }
        this.totalAmount = res.data.orderItems[res.data.orderItems.length - 1];
        for (var i = 0; i <= (res.data.orderItems.length - 2); i++) {
          this.orderItems.push(res.data.orderItems[i]);
        }
        this.soFormData = {
          productId: "",
          quantity: "",
          itemDesiredDeliveryDate: "",
          useAsDefaultDesiredDeliveryDate: true,
          shipAfterDate: "",
          shipBeforeDate: "",
          reserveAfterDate: "",
          itemComment: "",
          useAsDefaultComment: true,
        };
      }
    });
    this.spinner.hide();
  }

  clearOrder(): void {
    this.spinner.show();
    this._OrderManagementService.clearOrder()
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Order Cleared');
          this.spinner.hide();
          this.router.navigate(['/order/create-sales-order']);
        }
      });
      this.spinner.hide();
  }
  finalizeOrderWithDefault(): void {
    this.spinner.show();
    if (this.orderItems.length) {
      this._OrderManagementService.finalizeOrder('', 'default', '')
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Finalize Order With Default');
            switch (data.data.responseMessage) {
              case 'shipping': this.router.navigate(['/order/sales-finalize-order']);
                break;
              case 'options': this.router.navigate(['/order/sales-order-options']);
                break;
              case 'term': this.router.navigate(['/order/sales-order-entry-terms']);
                break;
              case 'payment': this.router.navigate(['/order/sales-payment-options']);
                break;
              case 'addparty': this.router.navigate(['/order/sales-order-additional-party-entry']);
                break;
              case 'sales': this.router.navigate(['/order/sales-order-confirmation']);
                break;
            }
          
          }
        });
    } else {
      this._ToastrService.error('Please add product');
    }
    this.spinner.hide();
  }
  finalizeOrder(): void {
    this.spinner.show();
    if (this.orderItems.length) {
      this._OrderManagementService.finalizeOrderWithDynamicBody({
        finalizeMode: 'init'
      })
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Order finalization initiated');
            switch (data.data.responseMessage) {
              case 'shipping': this.router.navigate(['/order/sales-finalize-order']);
                break;
              case 'options': this.router.navigate(['/order/sales-order-options']);
                break;
              case 'term': this.router.navigate(['/order/sales-order-entry-terms']);
                break;
              case 'payment': this.router.navigate(['/order/sales-payment-options']);
                break;
              case 'addparty': this.router.navigate(['/order/sales-order-additional-party-entry']);
                break;
              case 'sales': this.router.navigate(['/order/sales-order-confirmation']);
                break;
            }
          }
        });
    } else {
      this._ToastrService.error('Please add product');
    }
    this.spinner.hide();
  }
  recalculateOrder(): void {
    this.spinner.show();
    if (this.orderItems.length) {
      this._OrderManagementService.recalculateOrder(
        this.orderItems.map(value => {
          return {
            description: value.description,
            gwall: '',
            price: value.itemTotal,
            shipAfterDate: '',
            shipBeforeDate: '',
            updateQty: value.quantity
          };
        }))
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Recalculated');
          }
        });
    }
    this.spinner.hide();
  }
}
