import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-customer-cases',
  templateUrl: './customer-cases.component.html',
  styleUrls: ['./customer-cases.component.css']
})
export class CustomerCasesComponent implements OnInit {
 

  constructor(
    
  ) { 
  
  }

  ngOnInit(): void {
  }
 
 
  
}
