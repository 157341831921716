
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CategoryModel } from './category-model';

@Injectable({
  providedIn: 'root'
})


export class CategoryFormService {
  category_form: FormGroup;
  constructor(public _formBuilder: FormBuilder,
    public categoryModel: CategoryModel
  ) { }

  categoryForm(data: any): void {
    if (!this.category_form) {
      this.category_form = this._formBuilder.group(this.categoryModel.mapDataToModel(data));

    }

  }

}









//   prepareTransactionRefferalForm(data:any): void {
//     if (!this.referral_information_form) {
//       this.referral_information_form = this._formBuilder.group(data);
//     }
//   }









