<!-- <div class="container-fluid main-container-wrapper">
    <br>
    <div class="row">
      <div class="col-12">
        <div class="w3-card-4 classCard">
        <div class="container-fluid">
          <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
              New Party Note</a></li>
          </ul>
        </div>
        <div class="panel-group">
  
          <div *ngIf="activeCategory==1">
           
              <div class="panel-body">
                <form [formGroup]="fileUploadForm">
                  <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12">
                      <div class="row">
  
                          <div class="col-lg-3 form-group classInput" >
                            <label for="exampleInputEmail1">Note Name</label>
                          </div>
                       
                          <div class="col-lg-2">
                            <input type="email" [(ngModel)]="noteName" formControlName="noteName" class="form-control"
                            id="exampleInputEmail1" aria-describedby="emailHelp" >
                        
                          </div>
                          <div class="col-lg-3 form-group classInputA">
                            <label for="exampleInputEmail1">Note</label>
                          </div>
                         
                          <div class="col-lg-2">
                            <textarea style="height:100%!important;" id="w3review" [(ngModel)]="note" formControlName="note" rows="3" cols="30">
                            </textarea> 
                          </div>
  
                         
  
                      </div>
                  </div>

                  </div>
                
                  <div class="proper" style="margin-right: 25%;">
                    <div class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn">
                      <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                      <button type="submit" (click)="navigateFromCreate()" class="btn btn-danger ">Cancel</button>
                    </div>
                    <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateBtn">
                      <button type="submit" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                      <button type="submit" (click)="navigate()" class="btn btn-danger ">Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
          </div>
        </div>
        </div>
  
      </div>
    </div>
  </div> -->

<div class="modal fade" id="noteModalCenter" tabindex="-1" role="dialog" aria-labelledby="noteModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content cssModelLength">
      <div class="modal-header">

        <span class="color-black pl-1">New Party Note</span>
        <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton>&times;</span>
        </button>
      </div>
      
      <div class="modal-body d-flex">
        <div class=" bg-white color-grey" style="width: 100%;">
          <div class="w3-card-4 classCardPopUp" style="width: 100%;">
            <div class="container-fluid">
              <ul class="tabbing-section tabbing-accordians tabClass">

                <li class="active" (click)="changeactiveCategoryFunction(1)"><a>
                    New Party Note</a></li>
              </ul>
            </div>
            <div class="panel-group">

              <div>

                <div class="panel-body">
                  <form [formGroup]="fileUploadForm">
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                      <div class="col-lg-12">
                        <div class="row">

                          <div class="col-lg-4 form-group classInput">
                            <label for="exampleInputEmail1">Note Name</label>
                          </div>

                          <div class="col-lg-2">
                            <input type="email" [(ngModel)]="noteName" formControlName="noteName" class="form-control"
                            placeholder="Enter Note Name" id="exampleInputEmail1" aria-describedby="emailHelp" >

                          </div>
                          <div class="col-lg-3 form-group classInputA">
                            <label for="exampleInputEmail1">Note</label>
                          </div>

                          <div class="col-lg-2">
                             <input type="email" [(ngModel)]="note" formControlName="note" class="form-control"
                            placeholder="Enter Note" id="exampleInputEmail1" aria-describedby="emailHelp" >



                            <!-- <textarea style="height:100%!important;" id="w3review" [(ngModel)]="note"
                             placeholder="Enter Note" formControlName="note" rows="3" cols="30">
                                          </textarea> -->
                          </div>



                        </div>
                      </div>

                    </div>

                    <div class="proper" style="margin-right: 25%;">
                      <div class="col-lg-12 col-12 main-submit-button">
                        <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                       
                      </div>
                      <!-- <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateBtn">
                        <button type="submit" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                        <button type="submit" (click)="navigate()" class="btn btn-danger ">Cancel</button>
                      </div> -->
                    </div>
                  </form>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>