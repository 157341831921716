import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreateEventServices } from '../../create-events/create-event.service';
import { CreateEventFormService } from '../../create-events/create-event-service';
import { DatePipe, formatDate } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';


@Component({
  selector: 'app-detailed-event-summary',
  templateUrl: './detailed-event-summary.component.html',
  styleUrls: ['./detailed-event-summary.component.css']
})
export class DetailedEventSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  getPurposeList: any;
  activeCategory = 2;
  purposeArray: any = [];
  updateEventId: any;
  show = false;
  resData: any;
  partygroup: any;
  partygroupArray: any = [];
  priority: any;
  priorityArray = [];
  preSelectedPriority;
  workEffortTypeId: any;
  eventId: any;
  // activeCategory=1;

  // show: boolean = true;
  updateId: any;
  postId: any;
  eventDetail: any;

  constructor(public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly toastr: ToastrService,
    readonly activatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly datePipe: DatePipe,
    private _location: Location,
  ) {
    this.priorityArray = [
      
      {
        label: '1(High)',
        text: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        text: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9(Low)',
        value: '9'
      },

      
    ];
   }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.createEventFormService.create_event_form = null;
    this.createEventFormService.createEventForm(this.createEventFormService.create_event_form);
    this.activatedRoute.queryParams.subscribe(params => {
      this.eventId = params['eventId'];

    });
    this.getEventPartyId();
    this.getPurpose();
 


    this.show = true;
    this.getEventDetailData();

  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }
  cancelSubmit() {
    this.router.navigate(['/crm/events'])
  }
  getEventDetailData() {
    this.spinner.show();
    this.createEventServices.getEventById(this.eventId).subscribe(res => {

      this.eventDetail = res.data[0];
      this.spinner.hide();
   
    })


  }
  eventDetailEditData() {
    
    this.spinner.show();
    this.createEventServices.eventDetail(this.eventId).subscribe(res => {
   
     this.preSelectedPriority=res.data[0].priority;
     this.workEffortTypeId=res.data[0].workEffortTypeId
     
      const patchData = this.createEventFormService.create_event_form;
      this.spinner.show();
      setTimeout(() => {
        patchData.patchValue({
          partyId: res.data[0].toPartyId,
          workEffortName: res.data[0].workEffortName,
        
          description: res.data[0].description,
          workEffortPurposeTypeId: res.data[0].workEffortPurposeTypeId,
          estimatedStartDate: res.data[0].estimatedStartDate ? new Date(res.data[0].estimatedStartDate) : '',
          estimatedCompletionDate: res.data[0].estimatedCompletionDate ? new Date(res.data[0].estimatedCompletionDate) : ''
  
        })
      
      }, 3000);
      this.spinner.hide();
    
    })
   
  }


  getPurpose() {
    this.spinner.show();
    this.createEventServices.getEventPurpose().subscribe(res => {
      this.getPurposeList = res.data;
      this.spinner.hide();
      for (const value of this.getPurposeList.eventPurposeList) {
        this.purposeArray.push({
          label: value.description,
          value: value.workEffortPurposeTypeId
        })
      }
    })

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  getEventPartyId() {
    this.spinner.show();
    this.createEventServices.getPersonAndPartyGroup().subscribe(res => {
      this.partygroup = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partygroup) {
        this.partygroupArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  
  onSubmit() {
    this.spinner.show();
    const formData = { ...this.createEventFormService.create_event_form.value };
    const estimatedCompletionDate = this.datePipe.transform(formData.estimatedCompletionDate, "yyyy-MM-dd HH:mm:ss");
    const estimatedStartDate = this.datePipe.transform(formData.estimatedStartDate, "yyyy-MM-dd HH:mm:ss");
    const form = {
      description: formData.description,
      estimatedCompletionDate: estimatedCompletionDate,
      estimatedStartDate: estimatedStartDate,
      partyId: formData.partyId,
      priority: formData.priority,
      workEffortName: formData.workEffortName,
      workEffortPurposeTypeId: formData.workEffortPurposeTypeId
    }
    this.createEventServices.createEvent(form).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.router.navigate(['/crm/events']);
      }
    }, (err) => {

      if (err.error.success == false) {
        if (err.error.errors) {
          this.spinner.hide();
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }
        }
        else if (err.error.success == false) {
          this.spinner.hide();
          this.toastr.error(err.error.debugMessage);
        }
      }
    })

  }
  previousEvent() {
    this.router.navigate(['/crm/events'])
  }
  onUpdate() {
    this.spinner.show();
    const updateForm = { ...this.createEventFormService.create_event_form.value };
    const estimatedCompletionDate = this.datePipe.transform(updateForm.estimatedCompletionDate, "yyyy-MM-dd HH:mm:ss");
    const estimatedStartDate = this.datePipe.transform(updateForm.estimatedStartDate, "yyyy-MM-dd HH:mm:ss");
    const updatedform = {
      "communicationEventId": "",
      "currentStatusId": "CAL_ACCEPTED",
      "custRequestId": "",
      "description": updateForm.description,
      "estimatedCompletionDate": estimatedCompletionDate,
      "estimatedStartDate": estimatedStartDate,
      "fromDate": "",
      "partyId": updateForm.partyId,
      "priority": updateForm.priority,
      "quickAssignPartyId": "",
      "requirementId": "",
      "roleTypeId": "",
      "statusId": "",
      "thruDate": "",
      "webSiteId": "",
      "workEffortName": updateForm.workEffortName,
      "workEffortPurposeTypeId": updateForm.workEffortPurposeTypeId,
      "workEffortTypeId": this.workEffortTypeId


    }
    this.createEventServices.updateEvent(updatedform, this.eventId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.router.navigate(['/crm/events']);
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.debugMessage);
    })

  }

  //   }
  //   this.createEventServices.updateEvent(updatedform, this.updateEventId).subscribe((res: any) => {
  //     if (res.success) {
  //       this.toastr.success(res.data);
  //       this.spinner.hide();
  //       this.router.navigate(['/crm/events']);
  //     }
  //   }, (err) => {
  //     this.spinner.hide();
  //     this.toastr.error(err.error.debugMessage);
  //   })

  // }



  ngOnDestroy(): void {
    this.spinner.hide();
  }
}

