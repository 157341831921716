import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-view-configuration',
  templateUrl: './list-view-configuration.component.html',
  styleUrls: ['./list-view-configuration.component.css']
})
export class ListViewConfigurationComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;

  addTeamMember: any;
  editTeam: any;
  TeamByPartyId: any;
  party: any;
  partyId: any;
  Role: any;
  RoleArray: any[]=[];
  show: boolean;
  TeamMemberByPartyId: any;
  updateTeamMem: any;
  Deactivated: boolean;
  personId: any;
  partyIdArray: any[]=[];
  teamMember: any;
  teamMemberArray: any[]=[];

  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 
  {
    this.addTeamMember = this._FormBuilder.group({
      newTeamMember: [''],
      role: [''],
    });
    this.editTeam = this._FormBuilder.group({
      name: [''],
      comments: [''],
    })
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params.party;
    })
    this.Deactivated= false;
    this.getTeamByPartyId();
    this.getTeamMemberByPartyId();
    this.getRole();
    this.getTeamMember();
    this.getPartyId();
  }

  getTeamMember() {
    this.spinner.show();
    this.myContactsService.getTeamMember().subscribe(res => {
      this.teamMember = res.data;
      this.spinner.hide();
      for (const value of this.teamMember) {
        this.teamMemberArray.push({
          label: value.data.name,
          value: value.data.partyId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getTeamMemberByPartyId() {
    this.spinner.show();
    this.myContactsService.getTeamMemberByPartyId(this.partyId).subscribe(res => {
      this.TeamMemberByPartyId = res.data.multiNode ;
      this.spinner.hide();
    })
  }

  delete(autoId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
      
        this.myContactsService.deleteTeamMemberInviewTeamByAutoId(autoId).subscribe(res => {
        this.getTeamMemberByPartyId();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }

  cancelSubmit(){
    this._Router.navigate(["/crm/Teams"])
  }

  updateTeamMemberInviewTeam(){
    this.spinner.show();
    const requestData = {
      "autoId": this.updateTeamMem.autoId,
      "securityGroupId": this.updateTeamMem.securityGroupId,
    }

    this.myContactsService.updateTeamMemberInviewTeam(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addTeamMember.reset();
        this.getTeamMemberByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  reset(){
    this.show = false;
    this.addTeamMember.reset();
  }
  update(product){
    this.show = true;
    this.updateTeamMem = product;
    setTimeout(() => {
      const formValue = this.addTeamMember;
      formValue.patchValue({
        newTeamMember:product.teamMemberPartyId,
        role: product.securityGroupId 
      })

    }, 2000);

  }
  getRole() {
    this.spinner.show();
    this.myContactsService.getRoleTeam().subscribe(res => {
      this.Role = res.data;
      this.spinner.hide();
      for (const value of this.Role) {
        this.RoleArray.push({
          label: value.roleDescription,
          value: value.roleValue
        })
      }
    })
  }
  addTeamMemberInviewTeam(){
    this.spinner.show();
    const requestData = {
      "accountTeamPartyId":  this.partyId,
      "autoId": "",
      "partyId":  this.partyId,
      "securityGroupId": this.addTeamMember.value.role ,
      "teamMemberPartyId": this.addTeamMember.value.newTeamMember 

    }
    this.myContactsService.addTeamMemberInviewTeam(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addTeamMember.reset();
       // this.getTeamByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deactivatedTeamByPartyId(){
    this.spinner.show();

    this.myContactsService.deactivatedTeamByPartyId(this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getTeamByPartyId();
        this.Deactivated= true;
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getTeamByPartyId() {
    this.spinner.show();
    this.myContactsService.getTeamByPartyId(this.partyId).subscribe(res => {
      this.TeamByPartyId = res.data.singleNode;
      setTimeout(() => {
        const formValue = this.editTeam;
        formValue.patchValue({
          name: this.TeamByPartyId.name ,
          comments:  this.TeamByPartyId.comments 
        })
  
      }, 2000);
      this.spinner.hide();

    })
  }
  updateTeam(){
    this.spinner.show();
    const requestData = {
      "comments": this.editTeam.value.comments,
      "isDeactivated": "",
      "name":  this.editTeam.value.name,
      "partyId": this.partyId
     
    }

    this.myContactsService.updateTeam(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.editTeam.reset();
        this.getTeamByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  

  ngOnDestroy(): void {
    this.spinner.hide();
  }


}
