<div class="container-fluid main-container-wrapper pad_0">
    <br>
    <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
           <span class="color-black pl-1 titlePanels" *ngIf="this.agreementType=='payable'">Payables

            </span>
           <span class="color-black pl-1 titlePanels" *ngIf="this.agreementType=='receiveable'">Receivables

            </span>
        </div>
        <div class="w3-card-4 classCard classCardType  border_wdth_1" >
        <div class="container-fluid" *ngIf="this.agreementType=='payable'">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategory==1?'active':''" (click)="enterBills()"><a>
                    Enter Bills </a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="payableMakePayment()"><a>
                    Make Payments</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="payableMakeGroupPayment()"><a>
                        Make Group Payments</a></li>
            </ul>
        </div>
        <div class="container-fluid" *ngIf="this.agreementType=='receiveable'">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategory==1?'active':''" (click)="salesInvoice()"><a>
                    Sales Invoice </a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="receivePayment()"><a>
                    Receive Payments</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="receiveGroupPayments()"><a>
                        Receive Group Payments</a></li>
            </ul>
        </div>

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
              
                   
                                <div class="panel-group">
                                    <div *ngIf="activeCategory==1">
                                      
                                       
                                        <div class="panel-collapse">
                                            <br>
                                            <div class="w3-card-4 classCard classCardType" *ngIf="this.agreementType=='payable'">
                                                <div class="container-fluid" >
                                                    <ul class="tabbing-section tabbing-accordians tabClass" >
                                          
                                                      <li [ngClass]="activeCategoryMain==2?'active':''" (click)="changeactiveCategoryFunctionPurchae()"><a>
                                                        Purchases Invoices</a></li>
                                                        <li [ngClass]="activeCategoryMain==4?'active':''" (click)="changeactiveCommission()"><a>
                                                            Commission Invoices</a></li>
                                                        
                                                        <li [ngClass]="activeCategoryMain==3?'active':''" (click)="changeactiveCategoryFunctionCredit()"><a>
                                                            Credit Memo</a></li>
                                                   
                                          
                                                    </ul>
                                                </div>
                                                    <div class="create-new-leade mt-2">
                                                        <div class="container-fluid">
                                                            <div class="row" style="    padding: 7px;">
                                                                <form class="w-100" [formGroup]="creditMemoForm">
                                                                    <div class="w3-card-4  w-100">
                                                                        <div class="accordion classCard" id="myAccordion">
                                                                            <div class="accordion-item">
                                                                                <h2 class="accordion-header" id="headingOne">
                                                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                                        <button type="button" class="btn btn-outline-secondary active marginHeader" routerLinkActive="active" 
                                    
                                                                                        data-target="#createNewCreditMemo_Popup" data-toggle="modal">Create New
                                                                                        Credit Memo</button>
                                                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 75%!important; padding-top: 11px !important;
                                                                                            padding-bottom: 11px !important;">
                                                                                        </button>									
                                        
                                                                                    </div>
                                                
                                                                                </h2>
                                                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                                    <div class="card-body">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                        
                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                        <label for="exampleInputEmail1">Invoice ID</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" name="party"
                                                                                                        [options]="opportunityIdArray" optionlabel="label"
                                                                                                        formControlName="invoiceIdSearchType">
                                                                                                    </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp" placeholder="Invoice ID"
                                                                                                        formControlName="invoiceId" class="form-control">
                                                                                                    </div>
                                                        
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">Item Description</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" name="party"
                                                                                                                formControlName="descriptionSearchType"
                                                                                                                [options]="opportunityIdArray" optionlabel="label">
                                                                                                            </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" id="exampleInputEmail1"
                                                                                                                formControlName="description" aria-describedby="emailHelp"
                                                                                                                placeholder="Item Description" class="form-control">
                                                                                                    </div>
                                                        
                                                        
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                        
                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                        <label for="exampleInputEmail1">Customer Id</label>
                                                                                                    </div>
                                                                                                   
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                                        formControlName="fromPartyId" placeholder="Select PartyId"
                                                                                                        optionlabel="label">
                                                                                                    </p-dropdown>
                                                                                                    </div>
                                                        
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">Status ID</label>
                                                                                                    </div>
                                                                                                   
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true" name="party" [options]="statusIds"
                                                                                                        formControlName="statusId" placeholder="Select Status"
                                                                                                        optionlabel="label">
                                                                                                    </p-dropdown>
                                                                                                    </div>
                                                        
                                                        
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                        
                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                        <label for="exampleInputEmail1">Invoice Date</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" class="mr-2" name="party"
                                                                                                            [options]="greaterArray" formControlName="invoiceDateFromSearchType"
                                                                                                            optionlabel="label" placeholder="Select ">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="    margin-left: -1%;">
                                                                                                        <p-calendar class="mr-2 col-lg-2" placeholder="Select Date"
                                                                                                        formControlName="invoiceDateFrom"></p-calendar>
                                                                                                    </div>
                                                        
                                                                                                   
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" class="mr-2" name="party"
                                                                                                        [options]="smallerArray" formControlName="invoiceDateToSearchType"
                                                                                                        optionlabel="label" placeholder="Select">
                                                                                                    </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-calendar formControlName="invoiceDateTo" placeholder="Select Date" class="col-lg-2">
                                                                                                        </p-calendar>
                                                                                                    </div>
                                                        
                                                        
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                        
                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                        <label for="exampleInputEmail1">Due Date</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" class="ml-2" name="party"
                                                                                                            [options]="greaterArray" formControlName="dueDateFromSearchType"
                                                                                                            optionlabel="label" placeholder="Select ">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="    margin-left: -2%;">
                                                                                                        <p-calendar placeholder="Select Date" formControlName="dueDateFrom"
                                                                                                        class="ml-2 col-lg-2"></p-calendar>
                                                                                                    </div>
                                                        
                                                                                                   
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" name="party" [options]="smallerArray"
                                                                                                        class="ml-2" formControlName="dueDateToSearchType"
                                                                                                        optionlabel="label" placeholder="Select">
                                                                                                    </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-calendar placeholder="Select Date" formControlName="dueDateTo"
                                                                                                            class="ml-2 col-lg-2"></p-calendar>
                                                                                                    </div>
                                                        
                                                        
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                        
                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                        <label for="exampleInputEmail1">Paid Date</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" class="ml-2" name="party"
                                                                                                            [options]="greaterArray" formControlName="paidDateFromSearchType"
                                                                                                            optionlabel="label" placeholder="Select ">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="    margin-left: -2%;">
                                                                                                        <p-calendar formControlName="paidDateFrom" placeholder="Select Date"
                                                                                                            class="ml-2 col-lg-2"></p-calendar>
                                                                                                    </div>
                                                        
                                                                                                   
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" name="party" [options]="smallerArray"
                                                                                                            formControlName="paidDateToSearchType" optionlabel="label"
                                                                                                            class="ml-2" placeholder="Select">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-calendar formControlName="paidDateTo" placeholder="Select Date"
                                                                                                        class="ml-2 col-lg-2"></p-calendar>
                                                                                                    </div>
                                                        
                                                        
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                        
                                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                                        <label for="exampleInputEmail1">Ref Num</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" name="party"
                                                                                                                [options]="opportunityIdArray" optionlabel="label"
                                                                                                                formControlName="referenceNumberSearchType">
                                                                                                            </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                        formControlName="referenceNumber" aria-describedby="emailHelp"
                                                                                                        placeholder="Ref Num">
                                                                                                    </div>
                                                        
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">Message</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" name="party"
                                                                                                        formControlName="invoiceMessageSearchType"
                                                                                                        [options]="opportunityIdArray" optionlabel="label">
                                                                                                    </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                                formControlName="invoiceMessage" aria-describedby="emailHelp"
                                                                                                                placeholder="Message">
                                                                                                    </div>
                                                        
                                                        
                                                                                                </div>
                                                                                            </div>
                                                                                           
                                                                                           
                                                                                        </div>
    
                                                                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -31%;"><button _ngcontent-cta-c128=""
                                                                                            type="submit" class="btn btn-secondary submit-btn"
                                                                                            (click)="searchCreditMemo()">Find</button>&nbsp;
                                                                                        <button type="button" class="btn btn-danger"  (click)="reset()">Reset</button>
                                                                                        </div>
                                                                                        
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>

                                                                    <!-- <div class="w3-card-4 classCard" >
                                                              
                                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                            <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active" 
                                    
                                                                            data-target="#createNewCreditMemo_Popup" data-toggle="modal">Create New
                                                                            Credit Memo</button>
                                                                        </div>
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                    
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Invoice ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" name="party"
                                                                                    [options]="opportunityIdArray" optionlabel="label"
                                                                                    formControlName="invoiceIdSearchType">
                                                                                </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp" placeholder="Invoice ID"
                                                                                    formControlName="invoiceId" class="form-control">
                                                                                </div>
                                    
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail1">Item Description</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" name="party"
                                                                                            formControlName="descriptionSearchType"
                                                                                            [options]="opportunityIdArray" optionlabel="label">
                                                                                        </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" id="exampleInputEmail1"
                                                                                            formControlName="description" aria-describedby="emailHelp"
                                                                                            placeholder="Item Description" class="form-control">
                                                                                </div>
                                    
                                    
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                    
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Customer Id</label>
                                                                                </div>
                                                                               
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                    formControlName="fromPartyId" placeholder="Select PartyId"
                                                                                    optionlabel="label">
                                                                                </p-dropdown>
                                                                                </div>
                                    
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail1">Status ID</label>
                                                                                </div>
                                                                               
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" name="party" [options]="statusIds"
                                                                                    formControlName="statusId" placeholder="Select Status"
                                                                                    optionlabel="label">
                                                                                </p-dropdown>
                                                                                </div>
                                    
                                    
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                    
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Invoice Date</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" class="mr-2" name="party"
                                                                                        [options]="greaterArray" formControlName="invoiceDateFromSearchType"
                                                                                        optionlabel="label" placeholder="Select ">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2" style="    margin-left: -1%;">
                                                                                    <p-calendar class="mr-2 col-lg-2" placeholder="Select Date"
                                                                                    formControlName="invoiceDateFrom"></p-calendar>
                                                                                </div>
                                    
                                                                               
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" class="mr-2" name="party"
                                                                                    [options]="smallerArray" formControlName="invoiceDateToSearchType"
                                                                                    optionlabel="label" placeholder="Select">
                                                                                </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-calendar formControlName="invoiceDateTo" placeholder="Select Date" class="col-lg-2">
                                                                                    </p-calendar>
                                                                                </div>
                                    
                                    
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                    
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Due Date</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" class="ml-2" name="party"
                                                                                        [options]="greaterArray" formControlName="dueDateFromSearchType"
                                                                                        optionlabel="label" placeholder="Select ">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2" style="    margin-left: -2%;">
                                                                                    <p-calendar placeholder="Select Date" formControlName="dueDateFrom"
                                                                                    class="ml-2 col-lg-2"></p-calendar>
                                                                                </div>
                                    
                                                                               
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" name="party" [options]="smallerArray"
                                                                                    class="ml-2" formControlName="dueDateToSearchType"
                                                                                    optionlabel="label" placeholder="Select">
                                                                                </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-calendar placeholder="Select Date" formControlName="dueDateTo"
                                                                                        class="ml-2 col-lg-2"></p-calendar>
                                                                                </div>
                                    
                                    
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                    
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Paid Date</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" class="ml-2" name="party"
                                                                                        [options]="greaterArray" formControlName="paidDateFromSearchType"
                                                                                        optionlabel="label" placeholder="Select ">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2" style="    margin-left: -2%;">
                                                                                    <p-calendar formControlName="paidDateFrom" placeholder="Select Date"
                                                                                        class="ml-2 col-lg-2"></p-calendar>
                                                                                </div>
                                    
                                                                               
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" name="party" [options]="smallerArray"
                                                                                        formControlName="paidDateToSearchType" optionlabel="label"
                                                                                        class="ml-2" placeholder="Select">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-calendar formControlName="paidDateTo" placeholder="Select Date"
                                                                                    class="ml-2 col-lg-2"></p-calendar>
                                                                                </div>
                                    
                                    
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                    
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Ref Num</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" name="party"
                                                                                            [options]="opportunityIdArray" optionlabel="label"
                                                                                            formControlName="referenceNumberSearchType">
                                                                                        </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                    formControlName="referenceNumber" aria-describedby="emailHelp"
                                                                                    placeholder="Ref Num">
                                                                                </div>
                                    
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail1">Message</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" name="party"
                                                                                    formControlName="invoiceMessageSearchType"
                                                                                    [options]="opportunityIdArray" optionlabel="label">
                                                                                </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                            formControlName="invoiceMessage" aria-describedby="emailHelp"
                                                                                            placeholder="Message">
                                                                                </div>
                                    
                                    
                                                                            </div>
                                                                        </div>
                                                                       
                                                                       
                                                                    </div>
                                                                    <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -31%;"><button _ngcontent-cta-c128=""
                                                                            type="submit" class="btn btn-secondary submit-btn"
                                                                            (click)="searchCreditMemo()">Find</button>&nbsp;
                                                                        <button type="button" class="btn btn-danger"  (click)="resetForm()">Reset</button>
                                                                    </div>
                                                                </div> -->
                                                                    <br>
                                                                    
                                                                <div class="w3-card-4 classCardB" >
                                                                        <header class="w3-container w3-blue">
                                                                          <div class="header-tabs">
                                                                            <h4 class=" common-styling h4Margin">
                                                                                Credit Memo List
                                                                            </h4>
                                                                         
                                                                          </div>
                                                                        </header>
                                                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                        <div class="col-lg-12 col-12">
                                                                            <div class="form-group">
                                                                               
                                    
                                                                                <div class="card own-account-table">
                                                                                    <p-table [value]="creditMemos" [paginator]="true" [rows]="rows"
                                                                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                        [totalRecords]="total" [scrollable]="true"
                                                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined"
                                                                                                                        value="undefined"></div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        Invoice ID <p-sortIcon field="code"></p-sortIcon>
                                                                                                    </div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                                  <div style="color: white;">  Description<p-sortIcon field="name">
                                                                                                    </p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                                    pSortableColumn="category"><div style="color: white;"> Ref Num
                                                                                                    <p-sortIcon field="category"></p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                    
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">  Invoice Date <p-sortIcon field="price"></p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">  Due Date<p-sortIcon field="price"></p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">  Status</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                    
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">Customer Id</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">   Amount</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">   Outstanding</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">   Created Date</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">  Action</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'200px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined"
                                                                                                                        value="undefined"></div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        <span style="cursor: pointer;"
                                                                                                            routerLink="/financial/payable/credit-memo/summary-credit-memo"
                                                                                                            [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                             class="account-button">{{product.invoiceId}}</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">{{product.description}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.referenceNumber}}</td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.invoiceDate |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.dueDate |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                    
                                                                                                <td [ngStyle]="{'width':'190px'}">{{product.status}}</td>
                                                                                                <td [ngStyle]="{'width':'190px'}">{{product.partyFrom}}</td>
                                    
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.total |currency :'USD'}}</td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.outstanding |currency :'USD'}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    <div>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                                            *ngIf="product.statusId==='INVOICE_IN_PROCESS'"
                                                                                                            routerLink="/financial/payable/credit-memo/create-credit-memo"
                                                                                                            [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                            height="16" viewBox="0 0 21.223 21.222">
                                                                                                            <g transform="translate(0 -0.004)">
                                                                                                                <g transform="translate(0 1.52)">
                                                                                                                    <path
                                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                        transform="translate(0 -34.137)" />
                                                                                                                </g>
                                                                                                                <g transform="translate(4.548 0.004)">
                                                                                                                    <g transform="translate(0 0)">
                                                                                                                        <path
                                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </svg>
                                    
                                                                                                    </div>
                                                                                                </td>
                                    
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                               <h4 class="ml-2">No Record Found</h4>
                                                                                                           </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                  
                                                                                    <p class="paginate_data">
                                                                                        View per page </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </form>
                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                             
                                               
                                                    <div class="container-fluid" *ngIf="this.agreementType=='receiveable'">
                                                     
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100" [formGroup]="creditMemoForm">
                                                                        <div class="w3-card-4 classCard" >
                                                                  
                                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active" style="width: 137px!important"
                                        
                                                                    routerLink="/financial/payable/credit-memo/create-credit-memo">Create
                                                                    Credit Memo</button>
                                                            </div>
                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                        
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Invoice ID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party"
                                                                                        [options]="opportunityIdArray" optionlabel="label"
                                                                                        formControlName="invoiceIdSearchType">
                                                                                    </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp" placeholder="Invoice ID"
                                                                                        formControlName="invoiceId" class="form-control">
                                                                                    </div>
                                        
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Item Description</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party"
                                                                                                formControlName="descriptionSearchType"
                                                                                                [options]="opportunityIdArray" optionlabel="label">
                                                                                            </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" id="exampleInputEmail1"
                                                                                                formControlName="description" aria-describedby="emailHelp"
                                                                                                placeholder="Item Description" class="form-control">
                                                                                    </div>
                                        
                                        
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                        
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">From Party</label>
                                                                                    </div>
                                                                                   
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                        formControlName="fromPartyId" placeholder="Select PartyId"
                                                                                        optionlabel="label">
                                                                                    </p-dropdown>
                                                                                    </div>
                                        
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Status ID</label>
                                                                                    </div>
                                                                                   
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" name="party" [options]="statusIds"
                                                                                        formControlName="statusId" placeholder="Select Status"
                                                                                        optionlabel="label">
                                                                                    </p-dropdown>
                                                                                    </div>
                                        
                                        
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                        
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Invoice Date</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" class="mr-2" name="party"
                                                                                            [options]="greaterArray" formControlName="invoiceDateFromSearchType"
                                                                                            optionlabel="label" placeholder="Select ">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="    margin-left: -1%;">
                                                                                        <p-calendar class="mr-2 col-lg-2" placeholder="Select Date"
                                                                                        formControlName="invoiceDateFrom"></p-calendar>
                                                                                    </div>
                                        
                                                                                   
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" class="mr-2" name="party"
                                                                                        [options]="smallerArray" formControlName="invoiceDateToSearchType"
                                                                                        optionlabel="label" placeholder="Select">
                                                                                    </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-calendar formControlName="invoiceDateTo" placeholder="Select Date" class="col-lg-2">
                                                                                        </p-calendar>
                                                                                    </div>
                                        
                                        
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                        
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Due Date</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" class="ml-2" name="party"
                                                                                            [options]="greaterArray" formControlName="dueDateFromSearchType"
                                                                                            optionlabel="label" placeholder="Select ">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="    margin-left: -1%;">
                                                                                        <p-calendar placeholder="Select Date" formControlName="dueDateFrom"
                                                                                        class="ml-2 col-lg-2"></p-calendar>
                                                                                    </div>
                                        
                                                                                   
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party" [options]="smallerArray"
                                                                                        class="ml-2" formControlName="dueDateToSearchType"
                                                                                        optionlabel="label" placeholder="Select">
                                                                                    </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-calendar placeholder="Select Date" formControlName="dueDateTo"
                                                                                            class="ml-2 col-lg-2"></p-calendar>
                                                                                    </div>
                                        
                                        
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                        
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Paid Date</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" class="ml-2" name="party"
                                                                                            [options]="greaterArray" formControlName="paidDateFromSearchType"
                                                                                            optionlabel="label" placeholder="Select ">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="    margin-left: -1%;">
                                                                                        <p-calendar formControlName="paidDateFrom" placeholder="Select Date"
                                                                                            class="ml-2 col-lg-2"></p-calendar>
                                                                                    </div>
                                        
                                                                                   
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party" [options]="smallerArray"
                                                                                            formControlName="paidDateToSearchType" optionlabel="label"
                                                                                            class="ml-2" placeholder="Select">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-calendar formControlName="paidDateTo" placeholder="Select Date"
                                                                                        class="ml-2 col-lg-2"></p-calendar>
                                                                                    </div>
                                        
                                        
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                        
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Ref Num</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party"
                                                                                                [options]="opportunityIdArray" optionlabel="label"
                                                                                                formControlName="referenceNumberSearchType">
                                                                                            </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                        formControlName="referenceNumber" aria-describedby="emailHelp"
                                                                                        placeholder="Ref Num">
                                                                                    </div>
                                        
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Message</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party"
                                                                                        formControlName="invoiceMessageSearchType"
                                                                                        [options]="opportunityIdArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                formControlName="invoiceMessage" aria-describedby="emailHelp"
                                                                                                placeholder="Message">
                                                                                    </div>
                                        
                                        
                                                                                </div>
                                                                            </div>
                                                                           
                                                                           
                                                                        </div>
                                                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -31%;"><button _ngcontent-cta-c128=""
                                                                                type="submit" class="btn btn-secondary submit-btn"
                                                                                (click)="searchCreditMemo()">Find</button>&nbsp;
                                                                            <button type="button" class="btn btn-danger"  (click)="reset()">Reset</button>
                                                                        </div></div><br>
                                                                        <div class="w3-card-4 classCardB" >
                                                                            <header class="w3-container w3-blue">
                                                                              <div class="header-tabs">
                                                                                <h4 class=" common-styling h4Margin">
                                                                                    Credit Memo List
                                                                                </h4>
                                                                             
                                                                              </div>
                                                                            </header>
                                                                        <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">
                                                                                   
                                        
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="creditMemos" [paginator]="true" [rows]="rows"
                                                                                            scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total" [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined"
                                                                                                                            value="undefined"></div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            Invoice ID <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                                      <div style="color: white;">  Description<p-sortIcon field="name">
                                                                                                        </p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                        pSortableColumn="category"><div style="color: white;"> Ref Num
                                                                                                        <p-sortIcon field="category"></p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                        
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">  Invoice Date <p-sortIcon field="price"></p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">  Due Date<p-sortIcon field="price"></p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">  Status</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                        
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">  From Party</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">   Amount</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">   Outstanding</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">   Created Date</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">  Action</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined"
                                                                                                                            value="undefined"></div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            <span
                                                                                                                routerLink="/financial/payable/credit-memo/summary-credit-memo"
                                                                                                                [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                                 class="account-button">{{product.invoiceId}}</span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.description}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.referenceNumber}}</td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.invoiceDate |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.dueDate |date:'yyyy-MM-d hh:mm:ss'}}</td>
                                        
                                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.status}}</td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.partyFrom}}</td>
                                        
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.total |currency :'USD'}}</td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.outstanding |currency :'USD'}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        <div>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                                                *ngIf="product.statusId==='INVOICE_IN_PROCESS'"
                                                                                                                routerLink="/financial/payable/credit-memo/create-credit-memo"
                                                                                                                [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                                height="16" viewBox="0 0 21.223 21.222">
                                                                                                                <g transform="translate(0 -0.004)">
                                                                                                                    <g transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g transform="translate(4.548 0.004)">
                                                                                                                        <g transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                        
                                                                                                        </div>
                                                                                                    </td>
                                        
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                      
                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </form>
                                        
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                        </div>
                                    </div>
                                 


                                </div>
                          
            
            </div>
        </div>
        </div>
    </div>

    
</div>
<div class="modal fade" id="createNewCreditMemo_Popup" tabindex="-1" role="dialog" aria-labelledby="createNewCreditMemo_Popup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Credit Memo</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonN>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                Create Credit Memo</li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100">

                                            <div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    

                                                    <form class="w-100" [formGroup]="createCreditMemoForm">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                        
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Customer Id</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                            formControlName="partyIdFrom" optionlabel="label"
                                                                            placeholder="Select Party Id">
                                                                        </p-dropdown>
                                                                    </div>
                        
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Currency <span
                                                                            class="text-danger">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="party" [options]="currencyList"
                                                                            formControlName="currencyUomId" placeholder="Select Currency"
                                                                            optionlabel="label">
                                                                        </p-dropdown>
                                                                        <small class="validate-field"
                                                                            *ngIf="!!createCreditMemoForm.controls.currencyUomId.invalid && !!createCreditMemoForm.controls.currencyUomId.touched">
                                                                            Currency is required!</small>
                                                                    </div>
                        
                        
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                        
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Invoice Date</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-calendar formControlName="invoiceDate"></p-calendar>
                                                                    </div>
                        
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Due Date</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-calendar formControlName="dueDate"></p-calendar>
                                                                    </div>
                        
                        
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                        
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Ref Num</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                        aria-describedby="emailHelp" formControlName="referenceNumber"
                                                                        placeholder="Ref Num">
                                                                    </div>
                        
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="description">Description</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control" formControlName="description"
                                                                        placeholder="Description">
                                                                    </div>
                        
                        
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                        
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Message</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                        placeholder="Message" class="form-control"
                                                                        formControlName="invoiceMessage">
                                                                    </div>
                        
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Organization Party
                                                                            ID <span style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="party" [options]="array"
                                                                            formControlName="partyId" optionlabel="label" placeholder="select">
                                                                        </p-dropdown>
                                                                        <small class="validate-field"
                                                                            *ngIf="!!createCreditMemoForm.controls.partyId.invalid && !!createCreditMemoForm.controls.partyId.touched">
                                                                            Organization Party ID is required!</small>
                                                                    </div>
                        
                        
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12" *ngIf="editMode">
                                                                <div class="row">
                        
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="shippingAddress">Shipping Address</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="party" [options]="postalAddress"
                                                                                placeholder="Select Address" optionlabel="label">
                                                                            </p-dropdown>
                                                                    </div>
                        
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="shippingAddress">Billing Address</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" name="party" placeholder="Select Address"
                                                                                optionlabel="label" [options]="postalAddress">
                                                                            </p-dropdown>
                                                                    </div>
                        
                        
                                                                </div>
                                                            </div>
                                                           
                                                            
                                                            <div class="col-lg-12 col-12 main-submit-button " style="
                                                            margin-left: -31%; ">
                                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                                    (click)="submit()">{{editMode ?'Update':'Create'}}</button>
                                                               
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>


<ngx-spinner></ngx-spinner> 