import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from '../accounts/accounts.service';
@Component({
  selector: 'app-create-event-purpose',
  templateUrl: './create-event-purpose.component.html',
  styleUrls: ['./create-event-purpose.component.css']
})
export class CreateEventPurposeComponent implements OnInit {
  activeCategory=2;
  createEventPurpose:FormGroup
  eventPurposeArray: any[]=[];
  commEventId: any;
  constructor(readonly router: Router,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,

    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly accountsService: AccountsService,
    
    readonly spinner: NgxSpinnerService,) { 
    this.createEventPurpose = this._FormBuilder.group({
      eventPurposeId: [''],
     
 
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); } 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.commEventId = params['commEventId'];
     
    });
    this.getCrmEventPurpose();
  }
  getCrmEventPurpose() {
    this.spinner.show();
    this.accountsService.getCrmEventPurpose().subscribe(res => {
      const eventPurpose = res.data;
      this.spinner.hide();
       for (const value of eventPurpose) {
        this.eventPurposeArray.push({
          label: value.description,
          value: value.communicationEventPrpTypId
        })
      } 
    })
   
  }

  onSubmit() {
    this.spinner.show();
    const requestData = {
      "communicationEventPrpTypId": this.createEventPurpose.value.eventPurposeId,
      "description":''
    }

    this.accountsService.postCrmEventPurposeListData( this.commEventId,requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:  this.commEventId} });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  
  
  cancelSubmit(){
    this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:  this.commEventId} });
  }
  ngOnDestroy(): void {

    this.spinner.hide();
      } 
}
