import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-approvals-page',
  templateUrl: './approvals-page.component.html',
  styleUrls: ['./approvals-page.component.css']
})
export class ApprovalsPageComponent implements OnInit {
  activeCategory=3;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  approvalsForm: FormGroup;
  contentId: any;
  contentRevisionSeqId: any;
  show: boolean;
  ListContentApprovalAll: any;
  personId: any;
  partyIdArray: any[]=[];
  idnew: string;
  allrole: any;
  allroleArray: any[]=[];
  throughDateCate: string;
  surveyIdValue=true;
  updateContentApprovalPat: any;

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addEmployeePositionService: AddEmployeePositionService,

private _location: Location,

  ) 
  {
    this.approvalsForm=this._FormBuilder.group({
      contentRevisionSeqID:"",
      partyID:"",
      roleTypeID:"",
      approvalDate:"",
      sequenceNum:"",
      comments:"",
    })
   
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.contentId = params["contentId"];
      this.contentRevisionSeqId = params["contentRevisionSeqId"];

    });
    this.show=true;

    this.getRoleAllType();
    this.getPartyId();
    this.ListContentApproval();

  }

backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/hub/Dashboard']);
}
  removeContentApproval(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "contentApprovalId": product.contentApprovalId,
          "rootContentId": this.contentId,
          "rootContentRevisionSeqId": this.contentRevisionSeqId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.removeContentApproval(req).subscribe(res => {
          this.ListContentApproval();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateContentApproval(): void {
    this.spinner.show();
    const requestData ={
      "comments": this.approvalsForm.value.comments,
      "contentApprovalId": this.updateContentApprovalPat.contentApprovalId,
      "contentId": this.contentId,
      "contentRevisionSeqId": this.approvalsForm.value.contentRevisionSeqID,
      "deleteButton": '',
      "partyId": this.approvalsForm.value.partyID,
      "roleTypeId": this.approvalsForm.value.roleTypeID,
      "rootContentId": this.contentId,
      "sequenceNum": this.approvalsForm.value.sequenceNum,
      }
     
    this.accountsService.updateContentApproval(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.ListContentApproval();
        this.closebutton2.nativeElement.click();
        this.show=true;
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateContentApprovalPatch(product) {
    this.show=false;
    this.updateContentApprovalPat  = product;
    const throughDateCate=this.updateContentApprovalPat.approvalDate;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd");
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.approvalsForm;
        formValue.patchValue({
            contentRevisionSeqID:this.contentRevisionSeqId,
            partyID:this.updateContentApprovalPat.partyId,
            roleTypeID:this.updateContentApprovalPat.roleTypeId,
            approvalDate:this.throughDateCate,
            sequenceNum:this.updateContentApprovalPat.sequenceNum,
            comments:this.updateContentApprovalPat.comments,
        })
      }, 2000);
      this.spinner.hide();
  }
  createContentApprovalPatch() {
    this.approvalsForm.reset();
    this.show=true;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.approvalsForm;
        formValue.patchValue({
          contentRevisionSeqID:this.contentRevisionSeqId
        })
      }, 2000);
      this.spinner.hide();
  }
  createContentApproval(): void {
    this.spinner.show();
    const throughDateCate=this.approvalsForm.get('approvalDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "approvalDate": this.throughDateCate,
      "comments": this.approvalsForm.value.comments,
      "contentId": this.contentId,
      "contentRevisionSeqId": this.approvalsForm.value.contentRevisionSeqID,
      "partyId": this.approvalsForm.value.partyID,
      "roleTypeId": this.approvalsForm.value.roleTypeID,
      "sequenceNum": this.approvalsForm.value.sequenceNum,
      }
     
    this.accountsService.createContentApproval(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.ListContentApproval();
        this.approvalsForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getRoleAllType(){
    this.spinner.show();
    this.idnew="ALL"
      this.accountsService.getRoleMainType(this.idnew).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  ListContentApproval() {
    this.spinner.show();
    this.accountsService.ListContentApproval(this.contentRevisionSeqId,this.contentId).subscribe(res => {
      this.ListContentApprovalAll = res.data[0].ListContentApproval;
      this.spinner.hide();
    })
  }

  openViewTree()
  {
    this._Router.navigate(['/content/compDoc/view-tree'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openEdit()
  {
    this._Router.navigate(['/content/compDoc/edit'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openApprovals()
  {
    this.router.navigate(['/content/compDoc/approvals'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });

  }
  openRevisions()
  {
    this.router.navigate(['/content/compDoc/revisions'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openCreateNewRoot()
  {
    this._Router.navigate(['/content/compDoc/new-root-compdoc-template'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openViewWaitingApprovals()
  {
    this._Router.navigate(['/content/compDoc/view-waiting-approvals']);
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  
  }


}
