import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-tracking-marketing',
  templateUrl: './tracking-marketing.component.html',
  styleUrls: ['./tracking-marketing.component.css']
})
export class TrackingMarketingComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  CreateTrackingCodeForm: FormGroup;
  CreateTrackingCodeTypeForm: FormGroup;
  TrackingCodeTypeId: any;
  TrackingCodeTypeIdArray: any[]=[];
  MarketingCampaignIdsList: any;
  MarketingCampaignIdsListArray: any[]=[];
  ProductCatalogIdArray: any[]=[];
  ProductCatalogId: any;
  TrackingCodeList: any;
  show: boolean;
  actualfromdate: string;
  thrufromdate: string;
  TrackCodeTypeList: any;
  showValue: boolean;
  getEstimateFrom: string;
  getEstimateTo: string;
  activeCategoriess=3;
  

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.CreateTrackingCodeForm = this._FormBuilder.group({
      TrackingCodeID: [''],
      Description: [''],
      Comments: [''],
      TrackingCodeTypeID: [''],
      MarketingCampaignID: [''],
      DefaultProductCatalogID: [''],
      FromDate: [''],
      ThroughDate: [''],
      RedirectURL: [''],
      OverrideLogoURL: [''],
      OverrideCSSURL: [''],
      TrackableLifetime: [''],
      BillableLifetime: [''],
      GroupID: [''],
      SubGroupID: [''],
      
    });
    this.CreateTrackingCodeTypeForm = this._FormBuilder.group({
      TrackingCodeTypeID: [''],
      Description: [''],
      
    });
   }

  ngOnInit(): void {
    this.getTrackingCodeType();
    this.getMarketingCampaignIdsList();
    this.getdefaultProductCatalogId();
    this.getTrackingCodeList();
    this.getTrackCodeTypeList();
  }
  createcontent(){
    this._Router.navigate(['/marketing/create-content']);
    
  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  updateEvent(product){
    this.show=true;
    const fromdate=product.fromDate
    this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
    const toEstdate=product.thruDate
    this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
      setTimeout(() => {
      
        const formValue = this.CreateTrackingCodeForm;
        formValue.patchValue({
          TrackingCodeID: product.trackingCodeId,
      Description:product.description,
      Comments:product.comments,
      TrackingCodeTypeID: product.trackingCodeTypeId,
      MarketingCampaignID:product.marketingCampaignId,
      DefaultProductCatalogID:product.prodCatalogId,
      FromDate:this.getEstimateFrom,
      ThroughDate:this.getEstimateTo,
      RedirectURL:product.redirectUrl,
      OverrideLogoURL:product.overrideLogo,
      OverrideCSSURL: product.overrideCss,
      TrackableLifetime: product.trackableLifetime,
      BillableLifetime: product.billableLifetime,
      GroupID:product.groupId,
      SubGroupID:product.subgroupId,
        })
      
      }, 2000);
  
  }
  updateEventType(product){
    this.showValue=true;

      setTimeout(() => {
      
        const formValue = this.CreateTrackingCodeTypeForm;
        formValue.patchValue({
         
          TrackingCodeTypeID:product.trackingCodeTypeId,
           Description:product.description,
        })
      
      }, 2000);
  
  }
  getTrackCodeTypeList() {
    this.spinner.show();
    this.accountsService.getTrackCodeTypeList().subscribe(res => {
      this.TrackCodeTypeList = res.data[0].data;
      this.spinner.hide();
   
    })
  }
  getTrackingCodeList() {
    this.spinner.show();
    this.accountsService.getTrackingCodeList().subscribe(res => {
      this.TrackingCodeList = res.data[0].data;
      this.spinner.hide();
   
    })
  }
  addEffortEventReminderCreate(){
    this.showValue=false;
    this.CreateTrackingCodeTypeForm.reset();
  }
  addEffortEventReminder(){
    this.show=false;
    this.CreateTrackingCodeForm.reset();
  }
  
  deleteTrackCodeTypeById(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "trackingCodeTypeId":product.trackingCodeTypeId,
         
        }
        this.accountsService.deleteTrackCodeTypeById(req).subscribe(res => {
          this.getTrackCodeTypeList();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteTrackingCode(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "trackingCodeId":product.trackingCodeId,
         
        }
        this.accountsService.deleteTrackingCode(req).subscribe(res => {
          this.getTrackingCodeList();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  updateTrackingCodeType(): void {

    const requestData ={
      "description": this.CreateTrackingCodeTypeForm.value.Description,
      "trackingCodeTypeId": this.CreateTrackingCodeTypeForm.value.TrackingCodeTypeID,
      
      }
     
    this.accountsService.updateTrackingCodeType(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonA.nativeElement.click();
        this.getTrackCodeTypeList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  createTrackingCodeType(): void {

    const requestData ={
      "description": this.CreateTrackingCodeTypeForm.value.Description,
      "trackingCodeTypeId": this.CreateTrackingCodeTypeForm.value.TrackingCodeTypeID,
      
      }
     
    this.accountsService.createTrackingCodeType(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonA.nativeElement.click();
        this.getTrackCodeTypeList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    updateTrackingCode(): void {

      this.spinner.show();
      const fromActualdate=this.CreateTrackingCodeForm.get('FromDate').value;
      this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.CreateTrackingCodeForm.get('ThroughDate').value;
      this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    
      const requestData ={

        "billableLifetime": this.CreateTrackingCodeForm.value.BillableLifetime,
        "comments": this.CreateTrackingCodeForm.value.Comments,
        "description":  this.CreateTrackingCodeForm.value.Description,
        "fromDate": this.actualfromdate,
        "groupId":  this.CreateTrackingCodeForm.value.GroupID,
        "marketingCampaignId":  this.CreateTrackingCodeForm.value.MarketingCampaignID,
        "overrideCss": this.CreateTrackingCodeForm.value.OverrideCSSURL,
        "overrideLogo": this.CreateTrackingCodeForm.value.OverrideLogoURL,
        "prodCatalogId":  this.CreateTrackingCodeForm.value.DefaultProductCatalogID,
        "redirectUrl":  this.CreateTrackingCodeForm.value.RedirectURL,
        "subgroupId":  this.CreateTrackingCodeForm.value.SubGroupID,
        "thruDate":  this.thrufromdate,
        "trackableLifetime":  this.CreateTrackingCodeForm.value.TrackableLifetime,
        "trackingCodeId": this.CreateTrackingCodeForm.value.TrackingCodeID,
        "trackingCodeTypeId": this.CreateTrackingCodeForm.value.TrackingCodeTypeID,
        
        }
       
      this.accountsService.updateTrackingCode(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getTrackingCodeList(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
  createTrackingCode(): void {

    this.spinner.show();
    const fromActualdate=this.CreateTrackingCodeForm.get('FromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.CreateTrackingCodeForm.get('ThroughDate').value;
    this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
  
    const requestData ={
      "billableLifetime": this.CreateTrackingCodeForm.value.BillableLifetime,
      "comments": this.CreateTrackingCodeForm.value.Comments,
      "description":  this.CreateTrackingCodeForm.value.Description,
      "fromDate": this.actualfromdate,
      "groupId":  this.CreateTrackingCodeForm.value.GroupID,
      "marketingCampaignId":  this.CreateTrackingCodeForm.value.MarketingCampaignID,
      "overrideCss": this.CreateTrackingCodeForm.value.OverrideCSSURL,
      "overrideLogo": this.CreateTrackingCodeForm.value.OverrideLogoURL,
      "prodCatalogId":  this.CreateTrackingCodeForm.value.DefaultProductCatalogID,
      "redirectUrl":  this.CreateTrackingCodeForm.value.RedirectURL,
      "subgroupId":  this.CreateTrackingCodeForm.value.SubGroupID,
      "thruDate":  this.thrufromdate,
      "trackableLifetime":  this.CreateTrackingCodeForm.value.TrackableLifetime,
      "trackingCodeId": this.CreateTrackingCodeForm.value.TrackingCodeID,
      "trackingCodeTypeId": this.CreateTrackingCodeForm.value.TrackingCodeTypeID,
      
      }
     
    this.accountsService.createTrackingCode(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getTrackingCodeList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  TrackingCode() {
    this._Router.navigate(["/marketing/tracking-marketing"])
  }
  TrackingCodeType() {
    this._Router.navigate(["/marketing/tracking-marketing"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  getTrackingCodeType() {
    this.spinner.show();
    this.myContactsService.getTrackingCodeType().subscribe(res => {
      this.TrackingCodeTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.TrackingCodeTypeId) {
        this.TrackingCodeTypeIdArray.push({
          label: value.description,
          value: value.trackingCodeTypeId
        })
      }
    })
  }
  getMarketingCampaignIdsList() {
    this.spinner.show();
    this.myContactsService.getMarketingCampaignIdsList().subscribe(res => {
      this.MarketingCampaignIdsList = res.data[1].data;
      this.spinner.hide();
      for (const value of this.MarketingCampaignIdsList) {
        this.MarketingCampaignIdsListArray.push({
          label: value.campaignName,
          value: value.marketingCampaignId
        })
      }
    })
  }
  getdefaultProductCatalogId() {
    this.spinner.show();
    this.myContactsService.defaultProductCatalogId().subscribe(res => {
      this.ProductCatalogId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ProductCatalogId) {
        this.ProductCatalogIdArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  detailedPage(trackID) {
    this._Router.navigate(["/marketing/tracking-marketing/view-tracking-code"], { queryParams:{ trackingCodeID: trackID} })
  }

  detailedPagetype(trackID) {
    this._Router.navigate(["/marketing/tracking-marketing/view-tracking-code-type"], { queryParams:{ trackingCodeTypeID: trackID} })
  }
  
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  MobileMessaging()
  {
    this._Router.navigate(['/#']);

  } 
  SocialMedia()
  {
    this._Router.navigate(['/#']);

  }
  toOpportunity()
  {
    this._Router.navigate(['/crm/opportunities']);

  }
  toCases()
  {
    this._Router.navigate(['/marketing/cases-marketing']);

  }
  toAccount()
  {
    this._Router.navigate(['/crm/new-accounts']);
  }
  toContact()
  {
    this._Router.navigate(['/crm/my-contacts']);

  }
  toEmail(){
    this._Router.navigate(['/communication-overview/Dashboard']);
  }
  toForecast(){
    
    this._Router.navigate(['/crm/sales-forecast']);

  }
  toCalendar(){
    
    this._Router.navigate(['crm/sales-calendar']);

  }
  toNotes(){
    this._Router.navigate(['/crm/sales-notes']);
  }
  toEmails(){
    
    this._Router.navigate(['/communication-overview/Dashboard']);

  }  
  toTasks(){
    this._Router.navigate(['/crm/Tasks']);
  }
  toDocuments(){
    
    this._Router.navigate(['crm/sales-documents']);

  }
  toLead()
  {
    this._Router.navigate(['/crm/leads']);

  }
  openDataResource()
  {
    this._Router.navigate(['/marketing/data-source-marketing']);
  }
  openMarketingCampaign()
  {
    this._Router.navigate(['/marketing/marketing-campaign']);
  }
  openTracking()
  {
    this._Router.navigate(['/marketing/tracking-marketing/tracking-marketing']);
  }

  openSegment()
  {
    this._Router.navigate(['/marketing/segment-marketing']);
  }

  openContactList()
  {
    this._Router.navigate(['/marketing/contact-list-marketing']);
  
  }
  openReports()
  {
    this._Router.navigate(['/marketing/reports-marketing']);
  }

  openCustomer()
  {
    this._Router.navigate(['/crm/accounts']);

  }
  openActivities()
  {
    this._Router.navigate(['/crm/events']);

  }

  changeActiveCategoryFunctions(tab)
  {
    this.activeCategoriess=tab;
  }
  
}
