<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/financial/payable/credit-memo">Credit Memo </span>
                <span class="color-gry cursor-pointer" (click)="navigateToInvoice()">>Credit Memo Summary </span>
                <span class="color-black pl-1"> > Send Email</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div class="panel-heading" role="tab" id="headingOne">
                                                    <h4 class="panel-title">
                                                        <a role="button" data-toggle="collapse" data-parent="#accordion"
                                                            href="#collapseOne" aria-expanded="true"
                                                            aria-controls="collapseOne">
                                                            Send Email
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel"
                                                    aria-labelledby="headingOne">
                                                    <div class="panel-body">
                                                        <form [formGroup]="sendEmailForm">
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="invoiceTermId">From Email
                                                                            Address</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="sendFrom">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="invoiceTermId">To Email
                                                                            Address</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="sendTo">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="invoiceTermId">Copy Email
                                                                            Address</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="sendCc">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="invoiceTermId">Subject</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="subject">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-check margin">
                                                                        <div class="d-flex flex-column">
                                                                            <label class="form-check-label text-black"
                                                                                for="defaultCheck1">
                                                                                Other Currency
                                                                            </label>
                                                                            <input class="form-check-input"
                                                                                type="checkbox" value=""
                                                                                id="defaultCheck1"
                                                                                formControlName="other">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="termValue">Email Body</label>
                                                                        <textarea id="" cols="10" rows="5"
                                                                            formControlName="bodyText"
                                                                            class="form-control"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="main-submit-button">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="submit()">Send Email</button>
                                                                <button class="btn btn-danger cursor-pointer ml-2"
                                                                    (click)="navigateToInvoice()">
                                                                    Cancel</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 