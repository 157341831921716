import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
@Component({
  selector: 'app-create-financial-account',
  templateUrl: './create-financial-account.component.html',
  styleUrls: ['./create-financial-account.component.css']
})
export class CreateFinancialAccountComponent implements OnInit {
  show: boolean;
  createFinancial: FormGroup;
  FinAccountTypeList: any;
  FinAccountTypeListArray: any[] = [];
  FinAccountStatusList: any;
  FinAccountStatusListArray: any[] = [];
  currencyArray: any[] = [];
  refundable: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[] = []
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search=""
  readonly finSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,

  }
  actualfromdate: string;
  actualthroughdate: string;
  finAccountId: any;
  activeCategory = 2;
  getEstimateFrom: string;
  getEstimateTo: string;
  giAccountArray: any[]=[];
  id: any;

  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,) {
    this.refundable = [{
      label: "Y",
      value: "Y"
    },
    {
      label: "N",
      value: "N"
    }]
    this.createFinancial = this._FormBuilder.group({

      actualBalance: [''],
      availableBalance: [''],
      currencyUomId: ['',[Validators.required]],
      finAccountCode: [''],
      finAccountName: [''],
      finAccountPin: [''],
      finAccountTypeId: ['',[Validators.required]],
      fromDate: ['',[Validators.required]],
      isRefundable: ['',[Validators.required]],
      organizationPartyId: ['',[Validators.required]],
      ownerPartyId: [''],
      postToGlAccountId: [''],
      replenishLevel: [''],
      replenishPaymentId: [''],
      statusId: ['',[Validators.required]],
      thruDate: ['']


    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getFinAccountTypeList();
    this.getFinAccountStatusList();
    this.getCurrency();
    this.getPartyId();
    this.getGiAccountId();
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.finAccountId = params["finAccountId"];
    });
    if(this.finAccountId) {
      this.show=true;
      this.getFinancailAccount();
    }
    else {
      this.show=false;
    }

  }
  getFinancailAccount(){
    this.spinner.show();
    let req={
      "finAccountId":this.finAccountId,
      "finAccountIdSearchType": "Contains",
      "finAccountName": "",
      "finAccountNameSearchType": "Contains",
      "finAccountTypeId": "",
     
    }
    this.accountsService.getFinancailAccount(this.finSize,req).subscribe((res:any)=> {
      setTimeout(() => {
        const fromdate=res.data[0].fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=res.data[0].thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const formValue = this.createFinancial;
        formValue.patchValue({
          actualBalance: res.data[0].actualBalance,availableBalance: res.data[0].availableBalance,currencyUomId: res.data[0].currencyUomId,
          finAccountCode: res.data[0].finAccountCode,finAccountName: res.data[0].finAccountName,finAccountPin: res.data[0].finAccountPin,
          finAccountTypeId: res.data[0].finAccountTypeId,fromDate: this.getEstimateFrom,isRefundable: res.data[0].isRefundable,
          organizationPartyId: res.data[0].organizationPartyId,ownerPartyId: res.data[0].ownerPartyId,
          postToGlAccountId: res.data[0].postToGlAccountId,replenishLevel: res.data[0].replenishLevel,
          replenishPaymentId: res.data[0].replenishPaymentId,statusId: res.data[0].statusId,thruDate:this.getEstimateTo
        })
      
      }, 3000);
      this.spinner.hide();
    })  
   }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
 
  getGiAccountId() {
    this.spinner.show();
    this.accountsService.getGioAccountId().subscribe(res => {
      const giAccount = res.data.glAccounts;
      this.spinner.hide();
       for (const value of giAccount) {
        this.giAccountArray.push({
          label: value.glAccountId,
          value: value.glAccountId
        })
      } 
    })
   
  }

  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
    this.spinner.hide();
  }
  getFinAccountStatusList() {
    this.spinner.show();
    this.accountsService.getFinAccountStatusList().subscribe(res => {
      this.FinAccountStatusList = res.data;
      for (const value of this.FinAccountStatusList) {
        this.FinAccountStatusListArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
    this.spinner.hide();
  }
  getFinAccountTypeList() {
    this.spinner.show();
    this.accountsService.getFinAccountTypeList().subscribe(res => {
      this.FinAccountTypeList = res.data;
      this.spinner.hide();
      for (const value of this.FinAccountTypeList) {
        this.FinAccountTypeListArray.push({
          label: value.description,
          value: value.finAccountTypeId
        })
      }
    })
  }
  onUpdate(): void {
    this.spinner.show();
    const fromActualdate=this.createFinancial.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createFinancial.get('thruDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "actualBalance":0,"availableBalance": 0,"currencyUomId": this.createFinancial.value.currencyUomId,
      "finAccountCode": this.createFinancial.value.finAccountCode,"finAccountName": this.createFinancial.value.finAccountName,"finAccountPin": this.createFinancial.value.finAccountPin,
      "finAccountTypeId": this.createFinancial.value.finAccountTypeId,
      "fromDate": this.actualfromdate,"isRefundable": this.createFinancial.value.isRefundable,"organizationPartyId": this.createFinancial.value.organizationPartyId,
      "ownerPartyId": this.createFinancial.value.ownerPartyId,"postToGlAccountId": this.createFinancial.value.postToGlAccountId,
      "replenishLevel": this.createFinancial.value.replenishLevel,"replenishPaymentId": this.createFinancial.value.replenishPaymentId,
      "statusId": this.createFinancial.value.statusId,"thruDate":this.actualthroughdate     
    }
    this.accountsService.updateFinancailAccount(requestData,this.finAccountId).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/financial-account"])}
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    }); 
  }
 
  cancelSubmit() {
    this.router.navigate(["financial/financial-account"])
  }
  submit(): void {
    this.spinner.show();
    const fromActualdate=this.createFinancial.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createFinancial.get('thruDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "actualBalance":0,"availableBalance": 0,"currencyUomId": this.createFinancial.value.currencyUomId,
      "finAccountCode": this.createFinancial.value.finAccountCode,"finAccountName": this.createFinancial.value.finAccountName,"finAccountPin": this.createFinancial.value.finAccountPin,
      "finAccountTypeId": this.createFinancial.value.finAccountTypeId, "fromDate": this.actualfromdate,
      "isRefundable": this.createFinancial.value.isRefundable,"organizationPartyId": this.createFinancial.value.organizationPartyId,
      "ownerPartyId": this.createFinancial.value.ownerPartyId,"postToGlAccountId": this.createFinancial.value.postToGlAccountId,
      "replenishLevel": this.createFinancial.value.replenishLevel,"replenishPaymentId": this.createFinancial.value.replenishPaymentId,
      "statusId": this.createFinancial.value.statusId,"thruDate":this.actualthroughdate     
    }
    this.accountsService.postFinancialAccount(requestData).subscribe((res: any) => {
      if (res.success) {
        this.id=res.data.finAccountId;
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/detail-financial-summary"], { queryParams: { finAccountId: this.id } })
      } }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    })}
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

