import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from '../../accounting-ap/accounting-ap.service';

@Component({
    selector: 'app-send-email',
    templateUrl: './send-email.component.html'
})
export class SendEmailComponent implements OnInit {
    invoiceId: string;
    activeCategory=2;
    sendEmailForm: FormGroup;

    isCommission: boolean;
    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly _AccountingApService: AccountingApService,
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute
    ) {
        this.isCommission = false;
        this.invoiceId = '';
        this.sendEmailForm = this._FormBuilder.group({
            bodyText: [''],
            other: [''],
            sendCc: [''],
            sendFrom: [''],
            sendTo: [''],
            subject: ['']
        });
    }
    ngOnInit(): void {
        this._ActivatedRoute.queryParams.subscribe(data => {
            if (data) {
                this.invoiceId = data.invoiceId;
                this.isCommission = data.isCommission === 'true' ? true : false;
            }
        });
    }
    submit(): void {
        this.spinner.show();
        this._AccountingApService.sendEmail(this.invoiceId, this.sendEmailForm.value)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Mail Sent');
                    this.spinner.hide();
                    this.sendEmailForm.reset();
                    this.isCommission ?
                        this._Router.navigate([`/financial/payable/commission-invoice/summary-commission-invoice`], { queryParams: { invoiceId: this.invoiceId } }) :
                        this._Router.navigate(['/financial/payable/invoices/detailed-summary'], { queryParams: { invoiceId: this.invoiceId } });
                }
            });
        
    }
    navigateToInvoice(): void {
        this.sendEmailForm.reset();
        this.isCommission ?
            this._Router.navigate([`/financial/payable/commission-invoice/summary-commission-invoice`], { queryParams: { invoiceId: this.invoiceId } }) :
            this._Router.navigate(['/financial/payable/invoices/detailed-summary'], { queryParams: { invoiceId: this.invoiceId } });
    }
}