import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { WarehouseService } from '../../warehouse/warehouse.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-create-inventory-transfer',
    templateUrl: './create-inventory-transfer.component.html',
    styleUrls: ['./create-inventory-transfer.component.css']
})
export class CreateInventoryTransferComponent implements OnInit {
    facilityId: string;
    facilities: any[];
    products: any[];
    transferStatus: any[];
    inventoryTransferForm: FormGroup;
    isFormReady: boolean;
    editMode: boolean;
    inventoryTransferValue: any;
    preTransfer: {
        inventoryItemId: string,
        productId: any,
        productName: string,
        comments: string
    };
    inventoryItemId: string;
    location: any[];
    inventoryTransferId: string;
    preTransferproductId: any;
    preTransferA: any;
    url: string;
    @Output() InventoryModalClosed: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('closebutton') closebutton;
    constructor(
        readonly _MainCatalogService: MainCatalogService,
        readonly _WarehouseService: WarehouseService,
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router
    ) {
        this.url='/facilities/warehouse/detailed-summary-facilities'
        this.inventoryTransferForm = this._FormBuilder.group({
            comments: [''],
            containerIdTo: [''],
            facilityIdTo: [''],
            locationSeqId: [''],
            locationSeqIdTo: [''],
            sendDate: [new Date()],
            statusId: [''],
            xferQty: ['', [Validators.required]]
        });
        this.transferStatus = [];
        this.isFormReady = false;
        this.preTransfer = {
            inventoryItemId: '',
            productId: '',
            productName: '',
            comments: ''
        };
        this.inventoryItemId = '';
        this.location = [];
        this.editMode = false;
        this.inventoryTransferId = '';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.facilityId = this._ActivatedRoute.snapshot.queryParams.facilityId;
        this.inventoryItemId = this._ActivatedRoute.snapshot.queryParams.inventoryItemId;
        this.inventoryTransferId = this._ActivatedRoute.snapshot.queryParams.inventoryTransferId;
        if (this.inventoryTransferId) {
            this.editMode = true;
            this.getInventoryTransferById();
        }
        if (this.inventoryItemId) {
            this._WarehouseService.getInventoryItemById(this.inventoryItemId)
                .then(data => {
                    if (data.success) {
                        this.preTransfer = {
                            inventoryItemId: data.data.inventoryItemId,
                            productId: data.data.productId,
                            productName: data.data.productName,
                            comments: ''
                        };
                        this.inventoryTransferForm.patchValue({
                            statusId: 'IXF_REQUESTED'
                        });
                    }
                });
        }
        this.getStatus();
        this.getProducts();
        this.getFacility();
        this.inventoryTransferForm.controls.facilityIdTo.valueChanges.subscribe(value => {
            if (value) {
                this.getLocation(value);
            }
        });
    }
    getInventoryTransferById(): void {
        this.spinner.show();
        this._WarehouseService.getInventoryTransferById(this.inventoryTransferId)
            .then(data => {
                if (data.success) {
                    this.inventoryTransferValue = data.data;
                    this.spinner.hide();
                    this.inventoryTransferForm.patchValue({
                        ...data.data, ...{
                            sendDate: new Date(data.data.sendDate)
                        }
                    });
                }
            });
         
    }
    getLocation(facilityId: string): void {
        this.spinner.show();
        this._WarehouseService.getFacilityLocations(facilityId, { pageNo: 1, pageSize: 200 }, {
            aisle: '',
            area: '',
            level: '',
            locationSeqId: '',
            position: '',
            section: ''
        })
            .then(data => {
                if (data.success) {
                    this.location = data.data;
                    this.spinner.hide();
                }
            });
           
    }

    getStatus(): void {
        this.spinner.show();
        this._WarehouseService.getTransferStatus()
            .then(data => {
                this.transferStatus = data.data;
                this.spinner.hide();
            });
            
    }
    getProducts(): void {
        this.spinner.show();
        this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
            .then(data => {
                this.products = data.data;
                this.spinner.hide();
            });
           
    }
    getFacility(): void {
        this.spinner.show();
        this._WarehouseService.getFacility()
            .then(data => {
                this.facilities = data.data;
                this.spinner.hide();
            });
           
    }
    readyWithInventoryId(): void {
        this.spinner.show();
        if (this.preTransfer.inventoryItemId) {
            this._WarehouseService.getInventoryItemById(this.preTransfer.inventoryItemId)
                .then(data => {
                    this.spinner.hide();
                    this.inventoryTransferValue = data.data;
                    this.preTransferproductId = data.data.productId;
                    this.preTransfer.comments = data.data.comments;
                });
        }
       
    }
    readyWithProductId(): void {
        if (this.preTransferproductId) {
            this.preTransferA= this.preTransferproductId;
          //  this.preTransfer.productName = this.preTransfer.productName;
        }
    }
    transfer(): void {
        this.spinner.show();
        if (this.inventoryTransferId) {
            this.updateTransfer();
        } else {
            if (this.inventoryTransferForm.valid) {
                this._WarehouseService.createInventoryTransfer(this.facilityId, this.preTransfer.inventoryItemId, {
                    ...this.inventoryTransferForm.value, ...{
                        sendDate: moment(this.inventoryTransferForm.value.sendDate).format('YYYY-MM-DD HH:mm:ss')
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Transfered');
                            this.spinner.hide();
                            this.inventoryTransferForm.reset();
                            this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                        }
                    });
            }
        }
      
    }
    updateTransfer(): void {
        this.spinner.show();
        this._WarehouseService.updateInventoryTransfer(this.inventoryTransferId, {
            ...this.inventoryTransferValue, ...this.inventoryTransferForm.value, ...{
                sendDate: this.inventoryTransferForm.value.sendDate ? moment(this.inventoryTransferForm.value.sendDate).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.cancelSubmit();
                }
            });
           
    }
    cancelSubmit(): void {
        this.inventoryTransferForm.reset();
        this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
    }
    modalClose(): void {
        this.closebutton.nativeElement.click();
        this.InventoryModalClosed.emit();
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }

    onBack()
    {
        this._Router.navigate(['/facilities/warehouse/detailed-summary-facilities']);
    }
}