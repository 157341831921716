<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span>
                    Return Information
                </span>
                <span>

            <button class="btn btn-outline-secondary ml-2" (click)="downloadPDF()" [disabled]="downloading">
                <ng-container *ngIf="downloading;else noDownload">
                    PDF <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon>
                </ng-container>
                <ng-template #noDownload>
                    PDF
                </ng-template>
            </button>
            <button type="submit" class="btn btn-secondary submit-btn ml-2 mr-2" (click)="toDashboard()">Home</button>
            <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
        </span>
            </div>
            <div class=" bg-white color-grey create-new-table">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==0?'active':''" (click)="changeactiveCategoryFunction(0)"><a>
              Return Information </a></li>
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
          Return Items </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
        Return History </a></li>
                        </ul>
                    </div>

                    <div>
                        <div class="container-fluid">
                            <div class="row">

                                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div *ngIf="activeCategory==0">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Return Information
                                                        </h4>
                                                        <span class="edit-buttons hCreate">
                 
                            <a  data-toggle="modal" (click)="getReturnOrderValueEdit();"
                            data-target="#exampleModalCenter" >Update</a>
                          </span>
                                                    </div>
                                                    <div class="panel-body a">
                                                        <div class="mt-2">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div *ngIf="resReturn" class="color-black container">
                                                                        <div class="row">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Return Id</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{resReturn.returnId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12"> Entry Date</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{resReturn.entryDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12"> Return Form Party</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{resReturn.fromParty}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12"> Currency</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{resReturn.currencyUomId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12"> Destination Facility</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{resReturn.destinationFacilityName}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12"> Return Header</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{resReturn.returnHeaderTypeId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12"> Status</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{resReturn.statusDescription}}</span>
                                                                                </div>




                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==1">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Return Items
                                                        </h4>

                                                    </div>
                                                    <div class="panel-body a">
                                                        <div class="mt-2">
                                                            <div class="">
                                                                <div class="row m-0">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12 col-12 d-flex details-offer">
                                                                            <p class="common-regular-properties" style="margin-right:10px !important;">
                                                                                Return :
                                                                            </p>
                                                                            <a  class="account-button"  style="color: #65787e !important;">{{this.returnId}}</a>
                                                                        </div>

                                                                    </div>
                                                                    <div class="card return-items-table own-account-tables" *ngIf="this.resReturn.statusDescription=='Accepted'">
                                                                        <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%" [paginator]="false" [rows]="rows" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true" [totalRecords]="total" [value]="dataTable" [scrollable]="true" styleClass="tags-table   offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                                                            <ng-template pTemplate="colgroup" let-columns>
                                                                                <colgroup>
                                                                                    <col *ngFor="let col of offerShowingLineColoumnsShow">
                                                                                </colgroup>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="header">
                                                                                <tr class="activity-rows">
                                                                                    <th id="tableHead" class="tabTh" *ngFor="let col of offerShowingLineColoumnsShow">
                                                                                        <ng-container style="color: white!important;">

                                                                                            {{col.header}}
                                                                                        </ng-container>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity let-rowIndex="rowIndex">
                                                                                <tr class="activity-data">
                                                                                    <td *ngFor="let col of offerShowingLineColoumnsShow">
                                                                                        <ng-container *ngIf="col.field === 'des'">

                                                                                            <span> {{activity.orderId}}</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'order_qty'">

                                                                                            <span> {{activity.productId}}</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'return_qty'">

                                                                                            <span> {{activity.itemDescription}}</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'unit_price'">

                                                                                            <span> {{activity.returnQuantity}}</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'return_price'">

                                                                                            <span> {{activity.returnPrice}}</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'total'">

                                                                                            <span> {{activity.returnPrice}}</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'type'">

                                                                                            <span> {{activity.returnReasonDescription}}</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'item_status'">

                                                                                            <span> {{activity.statusDescription}}</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'includes'">

                                                                                            <span> {{activity.returnTypeDescription}}</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'action'">

                                                                                            <span> N/A</span>
                                                                                        </ng-container>



                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                    <br>
                                                                    <div class="card return-items-table own-account-tables" *ngIf="this.resReturn.statusDescription=='Accepted'">
                                                                        <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%" [paginator]="false" [rows]="rows" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true" [totalRecords]="total" [value]="returnItemAdjustmentMap" [scrollable]="true"
                                                                            styleClass="tags-table   offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                                                            <ng-template pTemplate="colgroup" let-columns>
                                                                                <colgroup>
                                                                                    <col *ngFor="let col of offerShowingData">
                                                                                </colgroup>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="header">
                                                                                <tr class="activity-rows">
                                                                                    <th id="tableHead" class="tabTh" *ngFor="let col of offerShowingData">
                                                                                        <ng-container style="color: white!important;">

                                                                                            {{col.header}}
                                                                                        </ng-container>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-list let-rowIndex="rowIndex">
                                                                                <tr class="activity-data">
                                                                                    <td *ngFor="let col of offerShowingData">
                                                                                        <ng-container *ngIf="col.field === 'com'">

                                                                                            <span> {{list.returnAdjustmentDescription}}</span>
                                                                                        </ng-container>

                                                                                        <ng-container *ngIf="col.field === 'am'">
                                                                                            <span> {{list.returnAdjustmentAmount}}</span>

                                                                                        </ng-container>



                                                                                        <ng-container *ngIf="col.field === 'type'">
                                                                                            <span> {{list.returnTypeDescription}}</span>

                                                                                        </ng-container>

                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>

                                                                    <div class="card return-items-table own-account-tables" *ngIf="this.resReturn.statusDescription != 'Accepted'">
                                                                        <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%" [paginator]="false" [rows]="rows" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true" [totalRecords]="total" [value]="dataTable" [scrollable]="true" styleClass="tags-table   offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                                                            <ng-template pTemplate="colgroup" let-columns>
                                                                                <colgroup>
                                                                                    <col *ngFor="let col of offerShowingLineColoumns">
                                                                                </colgroup>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="header">
                                                                                <tr class="activity-rows">
                                                                                    <th id="tableHead" class="tabTh" *ngFor="let col of offerShowingLineColoumns">
                                                                                        <ng-container style="color: white!important;">

                                                                                            {{col.header}}
                                                                                        </ng-container>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity let-rowIndex="rowIndex">
                                                                                <tr class="activity-data">
                                                                                    <td *ngFor="let col of offerShowingLineColoumns">
                                                                                        <ng-container *ngIf="col.field === 'des'">

                                                                                            <span> {{activity.orderId}}</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'order_qty'">
                                                                                            <span class="d-flex red-border align-items-center">
                                            <div class="name_text">
                                              <a>
                                                {{activity.productId}}
                                              </a>
                                            </div>
                                          </span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'return_qty'">
                                                                                            <span class="d-block align-items-center">
                                             <div  class="name_text">
                                              <input type="text" style="width: 100%;"
                                                                                      id="itemDescription{{rowIndex+1}}"
                                                                                      [(ngModel)]="activity.itemDescription"
                                                                                      [ngModelOptions]="{standalone: true}" />
                                           </div>
                                         </span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'unit_price'">
                                                                                            <span class="d-block align-items-center">
                                             <div  class="name_text">
                                              <input type="text" style="width: 100%;"
                                                                                      id="returnQuantity{{rowIndex+1}}"
                                                                                      [(ngModel)]="activity.returnQuantity"
                                                                                      [ngModelOptions]="{standalone: true}" />
                                           </div>
                                         </span>
                                                                                        </ng-container>

                                                                                        <ng-container *ngIf="col.field === 'return_price'">
                                                                                            <span class="d-block align-items-center">
                                              <div  class="name_text">
                                                  <input type="text" style="width: 100%;"
                                                  id="returnPrice{{rowIndex+1}}"
                                                  [(ngModel)]="activity.returnPrice"
                                                  [ngModelOptions]="{standalone: true}" />
                                            </div>
                                          </span>

                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'reason'">
                                                                                            <span class="d-block align-items-center">
                                            <div  class="name_text">
                                               {{activity.returnPrice}}
                                          </div>
                                        </span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'type'">
                                                                                            <span>
                                            <select style="width: 69px;"  id="returnReasonID{{rowIndex+1}}"
                                            [(ngModel)]="activity.returnReasonID" 
                                            [ngModelOptions]="{standalone: true}">
                                   <option *ngFor="let type of reasonType" [value]="type.returnReasonId" >{{type.description}} </option>
                                      </select>
                                         
                                          </span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'item_status'">
                                                                                            <span>
                                           {{activity.statusDescription}}
                                          
                                          </span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'includes'">
                                                                                            <span> 
      
                                            <select style="width: 69px;"  id="returnTypeId{{rowIndex+1}}"
                                            [(ngModel)]="activity.returnTypeId"
                                            [ngModelOptions]="{standalone: true}">
                                   <option *ngFor="let mission of returnTypes" [value]="mission.returnTypeId" >{{mission.description}} </option>
                                      </select>
                      </span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'action'">
                                                                                            <span (click)="deleteRemoveItem(activity.returnItemSeqId)" style="color: #0d3769;cursor:pointer">
                                            <p>Remove</p>
                                          </span>
                                                                                        </ng-container>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                    <br><br><br>
                                                                    <div class="card return-items-table own-account-tables" *ngIf="this.resReturn.statusDescription != 'Accepted'">
                                                                        <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%" [paginator]="false" [rows]="rows" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true" [totalRecords]="total" [value]="returnItemAdjustmentMap" [scrollable]="true"
                                                                            styleClass="tags-table   offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                                                            <ng-template pTemplate="colgroup" let-columns>
                                                                                <colgroup>
                                                                                    <col *ngFor="let col of offerShowing">
                                                                                </colgroup>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="header">
                                                                                <tr class="activity-rows">
                                                                                    <th id="tableHead" class="tabTh" *ngFor="let col of offerShowing">
                                                                                        <ng-container style="color: white;">

                                                                                            {{col.header}}
                                                                                        </ng-container>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-list let-rowIndex="rowIndex">
                                                                                <tr class="activity-data">
                                                                                    <td *ngFor="let col of offerShowing">
                                                                                        <ng-container *ngIf="col.field === 'com'">

                                                                                            <span> {{list.returnAdjustmentDescription}}</span>
                                                                                        </ng-container>

                                                                                        <ng-container *ngIf="col.field === 'am'">
                                                                                            <span class="d-block align-items-center">
                                             <div  class="name_text">
                                              <input type="text" style="width: 100%;"
                                                                                      id="returnAdjustmentAmount{{rowIndex+1}}"
                                                                                      [(ngModel)]="list.returnAdjustmentAmount"
                                                                                      [ngModelOptions]="{standalone: true}" />
                                           </div>
                                         </span>
                                                                                        </ng-container>



                                                                                        <ng-container *ngIf="col.field === 'type'">
                                                                                            <span> 
                                      
                                            <select style="width: 100px;"  id="returnTypeId{{rowIndex+1}}"
                                            [(ngModel)]="list.returnTypeId"
                                            [ngModelOptions]="{standalone: true}">
                                   <option *ngFor="let mission of returnTypes" [value]="mission.returnTypeId" >{{mission.description}} </option>
                                      </select>
</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.field === 'action'">
                                                                                            <span style="color: #0d3769;cursor:pointer" (click)="onDelete(list.returnAdjustmentId)">
                                            <p>Remove</p>
                                          </span>
                                                                                        </ng-container>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12 col-12 d-flex details-offer">
                                                                            <p class="common-regular-properties" style="margin-right:10px !important;">Return Total
                                                                            </p>
                                                                            <a  class="account-button"  style="color: #65787e !important;">${{this.returnTotal}}</a>
                                                                        </div>
                                                                        <div class="col-lg-12 col-12 main-submit-button" *ngIf="this.statusDescription != 'Accepted'"><button type="submit" class="btn btn-secondary submit-btn">Update</button></div>
                                                                        <div class="col-lg-12 col-12 main-submit-button" *ngIf="this.statusDescription != 'Accepted'"><button (click)="returnAccepted()" type="submit" class="btn btn-secondary submit-btn">Accept Return</button></div>
                                                                        <br>
                                                                        <br>

                                                                        <div class="all-users-infomation return-items-infos font-13 font-medium own-user-select">

                                                                            <div class="col-lg-12 col-12 d-flex details-offer">
                                                                                <p class="common-regular-properties" style="margin-right:10px !important;">Return Items Order ID
                                                                                </p>
                                                                                <p-dropdown [options]="orderDropdown" [(ngModel)]="orderValue" placeholder="Select" optionLabel="description"></p-dropdown>
                                                                            </div>


                                                                        </div>
                                                                        <div class="col-lg-12 col-12 main-submit-button"><button style="width: 23%!important;" (click)="viewOrderPage(orderValue)" type="submit" class="btn btn-secondary submit-btn">Load order items for return</button></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Return History
                                                        </h4>

                                                    </div>
                                                    <div class="panel-body a">
                                                        <app-return-history class="w-100" [orderHistory]="this.orderHistory"></app-return-history>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Return </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">

                <div class=" bg-white color-grey create-new-table" style="    width: 100%;">

                    <div>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div class="w3-card-4 classCardPopUp">
                                                    <div class="container-fluid">
                                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                                            <li *ngIf="show" [ngClass]="activeCategory==1?'active':''"><a>
                                        Update return</a></li>
                                                            <li *ngIf="!show" [ngClass]="activeCategory==1?'active':''"><a>
                                        Create new return</a></li>


                                                        </ul>
                                                    </div>

                                                    <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                                                        <div class="panel-body">
                                                            <div class="mt-2">
                                                                <div class="row">
                                                                    <form class="w-100" [formGroup]="createReturn">
                                                                        <div class="all-users-infomation font-13 font-medium own-user-select allPad">

                                                                            <div class="col-lg-12">

                                                                                <div class="row">
                                                                                    <div class=" col-lg-3 form-group rightFormD">
                                                                                        <label class="common-labels labelClass">
                                                    Return HeaderType ID
                                                  </label>
                                                                                    </div>

                                                                                    <div class="col-lg-3">
                                                                                        <p-dropdown formControlName="returnHeaderTypeId" placeholder="Select Return HeaderType ID	" filter="true" [options]="headerArray" optionlabel="label"></p-dropdown>
                                                                                    </div>


                                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                                        <label class="common-labels labelClass">
                                                    Status ID
                                                  </label>
                                                                                    </div>

                                                                                    <div class="col-lg-3">
                                                                                        <p-dropdown formControlName="statusId" placeholder="Select Return HeaderType ID	" filter="true" [options]="statusIdArray" optionlabel="label"></p-dropdown>

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass">
        
                                                  From Party ID
                                                </label>
                                                                                </div>

                                                                                <div class="col-lg-3">

                                                                                    <p-dropdown formControlName="fromPartyId" placeholder="Select PartyId" filter="true" [options]="partyIdArray" optionlabel="label"></p-dropdown>
                                                                                </div>



                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label class="common-labels labelClass">To Party ID
                                                </label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown formControlName="toPartyId" placeholder="Select PartyId" filter="true" [options]="partyIdArray" optionlabel="label"></p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>




                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass">Payment Method ID </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control" formControlName="paymentMethodId" placeholder="Enter Payment Method ID" id="exampleInputEmail1">
                                                                                </div>


                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label class="common-labels labelClass ">Find Account ID</label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control" formControlName="finAccountId" placeholder="Enter Payment Method ID" id="exampleInputEmail1">
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass">Billing Account ID </label>

                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control" formControlName="billingAccountId" placeholder="Enter Billing Account ID" id="exampleInputEmail1">
                                                                                </div>


                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label class="labelClass" for="exampleInputEmail1">Entry Date </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="entryDate">

                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass"> Origin Contact Mech ID</label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control" formControlName="originContactMechId" placeholder="Enter  Origin Contact Mech ID" id="exampleInputEmail1">
                                                                                </div>



                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label class="common-labels labelClass">Destination Facility ID
                                                </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown formControlName="destinationFacilityId" placeholder="Select Destination Facility ID" filter="true" [options]="facilityArray" optionlabel="label"></p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass">Needs Inventory Receive
                                                </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown formControlName="needsInventoryReceive" placeholder="Select Needs Inventory Receive" filter="true" [options]="dateIdArray" optionlabel="label"></p-dropdown>
                                                                                </div>


                                                                                <div class="col-lg-3 form-group rightFormA">
                                                                                    <label class="common-labels labelClass">Currency
                                                </label>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <p-dropdown formControlName="currencyUomId" placeholder="Select Currency" filter="true" [options]="currencyListArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormD">
                                                                                    <label class="common-labels labelClass">Supplier Rma ID </label>
                                                                                </div>

                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control" formControlName="supplierRmaId" placeholder="Enter Supplier Rma ID" id="exampleInputEmail1">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="submit-btn-sales main-submit-button" style="padding-right:45px;margin-top:-8px">
                                                                            <button (click)="submit();" *ngIf="!show" type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                                                            <button *ngIf="show" type="submit" (click)="update()" class="btn btn-secondary submit-btn">Update</button>
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>