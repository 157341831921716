import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-create-product-store',
  templateUrl: './create-product-store.component.html',
  styleUrls: ['./create-product-store.component.css']
})
export class CreateProductStoreComponent implements OnInit {
  createContact:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[]=[];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;
  AllPartyContactListPartyStatus: any;
  AllPartyContactListPartyStatusArray: any[]=[];
  status: string;
  PartyInvitationStatusList: any;
  PartyInvitationStatusListArray: any[]=[];
  contactId: any;
  
  preferredContactMechId: any;
  statusId: any;
  PreferredContactMechId: any;
  PreferredContactMechIdArray: any[]=[];
  contactList: any;
  contactListArray: any[]=[];
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  roleTypeId: any;
  sequenceNumber: any;
  productStoresListArray: any[]=[];
  productStoreId: any;
  @ViewChild('closebutton') closebutton;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    public createCont: CreateContactsService,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef
    ) { 
      this.contentIds = [];
      this.createContact = this._FormBuilder.group({
        RoleTypeID: [''],
        SequenceNum: [''],
        FromDate:[''],
        ThroughDate:[''],
        StoreID:['']
      
      
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
this.getRoleAllType();
this.getOrderListItems();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.roleTypeId = params["roleTypeId"];
      this.sequenceNumber = params["sequenceNumber"];
    this.productStoreId=params["productStoreId"];
      this.efromDate = params["fromDate"];
      this.ethruDate = params["thruDate"];
      
    });
  
    if(this.roleTypeId) {
      this.show=true;
      const fromdate=this.efromDate
      this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
      const ethruDate=this.ethruDate
      this.getthruDate=this.datePipe.transform(ethruDate,"yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.createContact;
      formValue.patchValue({
        RoleTypeID: this.roleTypeId,
        SequenceNum: this.sequenceNumber,
        FromDate: this.getEstimateFrom,
        ThroughDate: this.getthruDate,
        StoreID: this.productStoreId
      })
      }, 3000); 
    }
    else {
      
      this.show = false;
    }
 
  }
  getOrderListItems(): void {
    this.spinner.show();
    this.accountsService.getOrderList().subscribe((res) => {
      let productStores = res.data.productStores;
      this.spinner.hide();
      for (const value of productStores) {
        this.productStoresListArray.push({
          label: value.storeName,
          value: value.productStoreId,
        });
      }
    });
   
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
     
  onSubmit() {
    this.spinner.show();
    const fromDate=this.createContact.get('FromDate').value;
    this.fromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const thruDate=this.createContact.get('ThroughDate').value;
    this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      
      "fromDate":  this.fromDate,
      "partyId":this.party,
   
      "thruDate": this.thruDate,
      "productStoreId": this.createContact.value.StoreID,
      "roleTypeId": this.createContact.value.RoleTypeID,
      "sequenceNum": this.createContact.value.SequenceNum
     
     
  
     
    }
    this.accountsService.createStoreRole(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
   onUpdate() {
    this.spinner.show();
   
   
    const thruDate=this.createContact.get('ThroughDate').value;
    this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
     
      "fromDate":   this.efromDate,
     
      "partyId":this.party,
   
      "thruDate": this.thruDate,
      "productStoreId": this.createContact.value.StoreID,
      "roleTypeId": this.createContact.value.RoleTypeID,
      "sequenceNum": this.createContact.value.SequenceNum
  
     
    }
    this.accountsService.updateStoreRole(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }   
  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.bsModalRef.hide();
  }
   
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }}

