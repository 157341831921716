import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoryModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        categoryImageUrl:(data && data.categoryImageUrl) ? data.categoryImageUrl : null,
        categoryName:(data && data.categoryName) ? data.categoryName : null,
        description:(data && data.description) ? data.description : null,
        detailScreen:(data && data.detailScreen) ? data.detailScreen : null,
        linkOneImageUrl:(data && data.linkOneImageUrl) ? data.linkOneImageUrl : null,
        linkTwoImageUrl:(data && data.linkTwoImageUrl) ? data.linkTwoImageUrl : null,
        primaryParentCategoryId:(data && data.primaryParentCategoryId) ? data.primaryParentCategoryId : null,
        productCategoryId:(data && data.productCategoryId) ? data.productCategoryId : null,
        productCategoryTypeId:(data && data.productCategoryTypeId) ? data.productCategoryTypeId : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    categoryImageUrl: new FormControl(data.categoryImageUrl,[Validators.required]),
    categoryName: new FormControl(data.categoryName,[Validators.required,Validators.maxLength(12),Validators.minLength(5)]),
    description: new FormControl(data.description,[Validators.required]),
    detailScreen: new FormControl(data.detailScreen,[Validators.required,Validators.maxLength(12),Validators.minLength(5)]),
    linkOneImageUrl: new FormControl(data.linkOneImageUrl,[Validators.required]),
    linkTwoImageUrl: new FormControl(data.linkTwoImageUrl,[Validators.required,Validators.maxLength(12),Validators.minLength(5)]),
    primaryParentCategoryId: new FormControl(data.primaryParentCategoryId,[Validators.required]),
    productCategoryId: new FormControl(data.productCategoryId,[Validators.required,Validators.maxLength(12),Validators.minLength(5)]),
    productCategoryTypeId: new FormControl(data.productCategoryTypeId,[Validators.required,Validators.maxLength(12),Validators.minLength(5)]),

   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
