import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class LeadNotesService {

  constructor(readonly http: HttpClient) { }

  createContactNote(formData, id): Observable<any> {
    const createNote = URLS.createContactNote.replace(":partyId", id);
    return this.http.post(createNote, formData);
  }

  deleteNote(noteId): Observable<any> {
    const deleteNote = URLS.deleteContactNote.replace(":noteId", noteId);
    return this.http.delete(deleteNote);
  }

  updateContactNote(formData, noteId): Observable<any> {
    const updateNote = URLS.updateContactNote.replace(":noteId", noteId);
    return this.http.put(updateNote, formData);
  }

}
