<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Customer Summary ></span>
                <span class="color-black pl-1"> {{editMode? 'Update':'Create'}} Postal Address
                </span>
                <button type="submit" style="margin-left: 63%;" (click)="cancelSubmit();" routerLinkActive="active"
                class="btn btn-danger text-white">Cancel</button>
            </div>
            <div class="w3-card-4" style="border:1px solid;
            border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
            <header class="w3-container w3-blue">
              <div class="header-tabs">
                <h4 class=" common-styling" style="margin-left: 7px;">
                    {{editMode? 'Update':'Create'}} Postal Address
                </h4>
             
              </div>
            </header>
            <div class=" bg-white color-grey">
             
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="addressForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 9px!important;
                                            margin-left: -3%;">
                                                <label for="exampleInputEmail1">Attn Name</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Attn Name"
                                                formControlName="attnName">
                                            </div>

                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 9px!important;
                                            margin-left: -2%;">
                                                <label for="exampleInputEmail1">To Name</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="To Name"
                                                formControlName="toName">
                                            </div>
                                          
                                            
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 9px!important;
                                            margin-left: -3%;">
                                                <label for="exampleInputEmail1">Allow Solicitation </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Allow Solicitation"
                                                    [options]="solicitationArray" filter="true"
                                                    formControlName="allowSolicitation"></p-dropdown>
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 9px!important;
                                            margin-left: -2%;">
                                                <label for="exampleInputEmail1">Address 1 </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Address 1"
                                                formControlName="address1">
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 9px!important;
                                            margin-left: -3%;">
                                                <label for="exampleInputEmail1">Address 2</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Address 2"
                                                    formControlName="address2">
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 9px!important;
                                            margin-left: -2%;">
                                                <label for="exampleInputEmail1">City </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="City"
                                                formControlName="city">
                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 9px!important;
                                            margin-left: -3%;">
                                                <label for="exampleInputEmail1">Country </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="countryArray" placeholder="Country"
                                                    formControlName="countryGeoId"></p-dropdown>
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 9px!important;
                                            margin-left: -2%;">
                                                <label for="exampleInputEmail1">State </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="stateArray" placeholder="State" filter="true"
                                                    formControlName="stateProvinceGeoId"></p-dropdown>
                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 9px!important;
                                            margin-left: -3%;">
                                                <label for="pinCode">Pin Code</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Pin Code"
                                                    formControlName="postalCode">
                                            </div>

                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 9px!important;
                                            margin-left: -2%;">
                                                <label for="mechId">Contact Mech Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Contact Mech Id"
                                                    formControlName="contactMechId">
                                            </div>
                                            
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 9px!important;
                                            margin-left: -3%;">
                                                <label for="mechType">Contact Mech Purpose Type Id <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Contact Mech Purpose Type Id" filter="true"
                                                    [options]="contactMechTypeId"
                                                    formControlName="contactMechPurposeTypeId">
                                                </p-dropdown>
                                            </div>
                                          
                                            
                                           
                                        </div>
                                    </div>
                                 
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: 68%;">
                                <button type="submit" *ngIf="editMode" (click)="update()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!editMode" (click)="submit()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div> -->

<div class="modal-header">
    <span class="color-black pl-1">{{editMode? 'Update':'Create'}} Postal Address</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
      <div class=" bg-white color-grey border-card" style="width: 100%;">
          <div class="w3-card-4 classCardPopUp" style="width: 100%;">
              <div class="container-fluid">
                  <ul class="tabbing-section tabbing-accordians tabClass">

                      <li class="active"><a>
                        {{editMode? 'Update':'Create'}} Postal Address</a></li>
                  </ul>
              </div>
              
              <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100" [formGroup]="addressForm">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm"
                                        style="    text-align: right!important;
                                        padding-right: 9px!important;
                                        margin-left: -2%;">
                                            <label for="exampleInputEmail1">Attn Name</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="Attn Name"
                                            formControlName="attnName">
                                        </div>

                                        <div class="col-lg-2 form-group rightForm"
                                        style="    text-align: right!important;
                                        padding-right: 9px!important;
                                        margin-left: -2%;">
                                            <label for="exampleInputEmail1">To Name</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="To Name"
                                            formControlName="toName">
                                        </div>
                                      
                                        
                                       
                                    </div>
                                </div>
                              
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm"
                                        style="    text-align: right!important;
                                        padding-right: 9px!important;
                                        margin-left: -2%;">
                                            <label for="exampleInputEmail1">Allow Solicitation </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown placeholder="Allow Solicitation"
                                                [options]="solicitationArray" filter="true"
                                                formControlName="allowSolicitation"></p-dropdown>
                                        </div>
                                      
                                        <div class="col-lg-2 form-group rightForm"
                                        style="    text-align: right!important;
                                        padding-right: 9px!important;
                                        margin-left: -2%;">
                                            <label for="exampleInputEmail1">Address 1 </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="Address 1"
                                            formControlName="address1">
                                        </div>
                                       
                                    </div>
                                </div>
                              
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm"
                                        style="    text-align: right!important;
                                        padding-right: 9px!important;
                                        margin-left: -2%;">
                                            <label for="exampleInputEmail1">Address 2</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="Address 2"
                                                formControlName="address2">
                                        </div>
                                      
                                        <div class="col-lg-2 form-group rightForm"
                                        style="    text-align: right!important;
                                        padding-right: 9px!important;
                                        margin-left: -2%;">
                                            <label for="exampleInputEmail1">City </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="City"
                                            formControlName="city">
                                        </div>
                                       
                                    </div>
                                </div>
                               
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm"
                                        style="    text-align: right!important;
                                        padding-right: 9px!important;
                                        margin-left: -2%;">
                                            <label for="exampleInputEmail1">Country </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown [options]="countryArray" placeholder="Country"
                                                formControlName="countryGeoId"></p-dropdown>
                                        </div>
                                      
                                        <div class="col-lg-2 form-group rightForm"
                                        style="    text-align: right!important;
                                        padding-right: 9px!important;
                                        margin-left: -2%;">
                                            <label for="exampleInputEmail1">State </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown [options]="stateArray" placeholder="State" filter="true"
                                                formControlName="stateProvinceGeoId"></p-dropdown>
                                        </div>
                                       
                                    </div>
                                </div>
                               
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm"
                                        style="    text-align: right!important;
                                        padding-right: 9px!important;
                                        margin-left: -2%;">
                                            <label for="pinCode">Pin Code</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="Pin Code"
                                                formControlName="postalCode">
                                        </div>

                                        <div class="col-lg-2 form-group rightForm"
                                        style="    text-align: right!important;
                                        padding-right: 9px!important;
                                        margin-left: -2%;">
                                            <label for="mechId">Contact Mech Id</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" placeholder="Contact Mech Id"
                                                formControlName="contactMechId">
                                        </div>
                                        
                                       
                                    </div>
                                </div>
                              
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm"
                                        style="    text-align: right!important;
                                        padding-right: 9px!important;
                                        margin-left: -2%;">
                                            <label for="mechType">Contact Mech Purpose Type Id <span
                                                class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown placeholder="Contact Mech Purpose Type Id" filter="true"
                                                [options]="contactMechTypeId"
                                                formControlName="contactMechPurposeTypeId">
                                            </p-dropdown>
                                        </div>
                                      
                                        
                                       
                                    </div>
                                </div>
                             
                            </div>
                        </form>
                        <div class="main-submit-button" style="margin-left: 70%;">
                            <button type="submit" *ngIf="editMode" (click)="update()"
                                class="btn btn-secondary submit-btn">Update</button>
                            <button type="submit" *ngIf="!editMode" (click)="submit()"
                                class="btn btn-secondary submit-btn">Submit</button>
                          
                        </div>
                    </div>
                </div>
            </div>
                

              
              
             </div>
      </div>
  </div>