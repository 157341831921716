<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" routerLink="/facilities/catalog/products">Product </span>
                <span class="color-black pl-1"> > {{editMode ? 'Update': 'Create'}} New</span>
             
                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians">
                        <li [ngClass]="activeCategory==1?'active':''"><a>
                                Content</a></li>
                    </ul>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form [formGroup]="imageForm">
                                <div *ngIf="activeCategory==1">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Content
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12" >
                                                <div class="row">
                                                  
                                                    <div *ngIf="editMode" class="col-lg-3 form-group classInput">
                                                        <label for="priceType">Content ID</label>
                                                    </div>
                                                  
                                                    <div *ngIf="editMode" class="col-lg-2">
                                                        <span>{{contentId}}</span>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="priceType">Product Content Type ID</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <span>{{productContentTypeId}}</span>
                                                    </div>
                                                </div>
                                            </div> 
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="fromDate">From Date <span
                                                            class="text-danger">*</span></label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" placeholder="Form Date"
                                                        formControlName="fromDate">
                                                    <small class="text-danger"
                                                        *ngIf="!!imageForm.controls.fromDate.invalid && !!imageForm.controls.fromDate.touched">From
                                                        Date is required.</small>
                                                    </div>
                                                     
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="fromDate">Through Date</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" placeholder="Through Date"
                                                        formControlName="thruDate">
                                                    </div>
                                                </div>
                                            </div> 
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="taxPercentage">Sequence Num</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Sequence Num"
                                                        formControlName="sequenceNum">
                                                    </div>

                                                    <div *ngIf="!isText" class="col-lg-3 form-group classInput">
                                                        <label for="image">File</label>
                                                    </div>
                                                  
                                                    <div *ngIf="!isText" class="col-lg-2">
                                                        <input type="file" class="custom-file-input"
                                                        (change)="setImage($event)" id="inputGroupFile01"
                                                        aria-describedby="inputGroupFileAddon01">
                                                    <label class="custom-file-label"
                                                        for="inputGroupFile01">Choose
                                                        file</label>
                                                    </div>
                                                </div>
                                            </div> 
                                         
                                          
                                        
                                            <ng-container *ngIf="isText">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                      
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="description">Product Description</label>
                                                        </div>
                                                      
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                            formControlName="description"
                                                            placeholder="Product Description">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="description">Locale String</label>
                                                        </div>
                                                      
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                            formControlName="localeString" placeholder="Locale String">
                                                        </div>
                                                    </div>
                                                </div> 
                                              
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                      
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="text">Text <span
                                                                class="text-danger">*</span></label>
                                                        </div>
                                                      
                                                        <div class="col-lg-2">
                                                            <textarea name="text" id="" cols="30" rows="10"
                                                            placeholder="Text" formControlName="text"
                                                            class="form-control"></textarea>
                                                        <small class="text-danger"
                                                            *ngIf="!!imageForm.controls.text.invalid && !!imageForm.controls.text.touched">Text
                                                            is required.</small>
                                                        </div>
                                                    </div>
                                                </div> 
                                              
                                            </ng-container>

                                        </div>
                                        <div class="main-submit-button" style="margin-right: 19%;">
                                            <button type="submit" (click)="submit()"
                                                class="btn btn-secondary submit-btn">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>