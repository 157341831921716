import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-create-party-invitation',
  templateUrl: './create-party-invitation.component.html',
  styleUrls: ['./create-party-invitation.component.css']
})
export class CreatePartyInvitationComponent implements OnInit {

  show=false;
  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  status: string;
  partyIdArray: any[]=[];
  PartyInvitationStatusList: any;
  PartyInvitationStatusListArray: any[]=[];
  personId: any;
  invitataionForm:FormGroup;
  fromDate: string;
  inviteId: any;
  getEstimateFrom: string;
  constructor(readonly spinner: NgxSpinnerService, 
    readonly accountsService: AccountsService, readonly toastr: ToastrService,
    readonly datePipe: DatePipe,  readonly addSkillService: AddSkillService,
     readonly _FormBuilder: FormBuilder,
    readonly router: Router,  readonly _ToastrService: ToastrService,
    readonly activate:ActivatedRoute) {
      this.invitataionForm = this._FormBuilder.group({
        partyFrom: [''],
        partyTo: [''],
        toName:[''],
        emailAddress:[''],
        statusId:[''],
        inviteDate:['']
      
      
        
      });
     }

  ngOnInit(): void {
    this.getPartyId();
    this.getPartyInvitationStatusList();
    this.activate.queryParams.subscribe(params => {
      this.inviteId = params["inviteId"];
   
      
    });
    if(this.inviteId){
     this.show=true;
     this.editPartyInvitations();
    }
    else{
      this.show=false;
    }


  }
  editPartyInvitations(){
    this.spinner.show();
   
    this.accountsService.editPartyInvitations(this.inviteId).subscribe((res:any)=> {
      setTimeout(() => {
        const fromdate=res.data.PartyInvitation.lastInviteDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        
        const formValue = this.invitataionForm;
        formValue.patchValue({
          partyFrom: res.data.PartyInvitation.partyIdFrom,
        partyTo:res.data.PartyInvitation.partyId,
        toName:res.data.PartyInvitation.toName,
        emailAddress:res.data.PartyInvitation.emailAddress,
        statusId:res.data.PartyInvitation.statusId,
        inviteDate: this.getEstimateFrom
        })
      
      }, 3000);
      this.spinner.hide();
    })  
   }
  
  getPartyInvitationStatusList(){
    this.spinner.show();
    this.status='PARTY_INV_STATUS'
      this.accountsService.getPartyInvitationStatusList(this.status).subscribe((res:any)=> {
        this.PartyInvitationStatusList = res.data;
        this.spinner.hide();
        for(const value of this.PartyInvitationStatusList){
          this.PartyInvitationStatusListArray.push({
            label:value.data.description,
            value:value.data.statusId
          })
        }
      
      })
     
     }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  
  onUpdate() {
    this.spinner.show();
    const fromDate=this.invitataionForm.get('inviteDate').value;
    this.fromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
   
    const requestData = {
      "emailAddress": this.invitataionForm.value.emailAddress,
      "lastInviteDate": this.fromDate,
      "lastInviteDate_i18n": "",
      "partyId":this.invitataionForm.value.partyTo,
      "partyIdFrom": this.invitataionForm.value.partyFrom,
      "partyInvitationId": this.inviteId,
      "statusId": this.invitataionForm.value.statusId,
      "toName":this.invitataionForm.value.toName,
    }
    this.accountsService.updatePartyInvitations(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/partyQuickLink/invitation']);
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onSubmit() {
    this.spinner.show();
    const fromDate=this.invitataionForm.get('inviteDate').value;
    this.fromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
   
    const requestData = {
      "emailAddress": this.invitataionForm.value.emailAddress,
      "lastInviteDate": this.fromDate,
      "lastInviteDate_i18n": "",
      "partyId":this.invitataionForm.value.partyTo,
      "partyIdFrom": this.invitataionForm.value.partyFrom,
      "partyInvitationId": '',
      "statusId": this.invitataionForm.value.statusId,
      "toName":this.invitataionForm.value.toName,
    }
    this.accountsService.createPartyInvitations(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/partyQuickLink/invitation']);
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  GoToQuickLink(){
    this.router.navigate(['/partyQuickLink/invitation']);
  }

}
