<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Find Order</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>
      <div class=" bg-white color-grey create-new-table">
        <div>
          <div class="container-fluid">
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">

                      <div class="w3-card-4">
                        <div class="accordion classCard" id="myAccordion">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <div
                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                    <span class="headingAccords"> Find Order Form
                                    </span>
                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 82%!important; padding-top: 11px !important;
                                    padding-bottom: 11px !important;"></button>									

                                </div>

                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                    <div class="card-body">
                                        
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Order Id</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <input type="text" [(ngModel)]="findOrder.orderId"
                                                    [ngModelOptions]="{standalone: true}" class="form-control"
                                                    placeholder="Enter Order Id">
                                                </div>
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">Order Name</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <input type="text" [(ngModel)]="findOrder.orderName"
                                                    [ngModelOptions]="{standalone: true}" class="form-control"
                                                    placeholder="Enter Order Name">
                                                </div>
      
                                              </div>
                                            </div>
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">External ID</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <input type="text" [(ngModel)]="findOrder.externalId"
                                                    [ngModelOptions]="{standalone: true}" class="form-control"
                                                    placeholder="Enter External ID">
                                                </div>
      
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">Customer PO# </label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <input type="text" [(ngModel)]="findOrder.correspondingPoId"
                                                    [ngModelOptions]="{standalone: true}" class="form-control"
                                                    placeholder="Enter Customer PO#	">
                                                </div>
                                              </div>
                                            </div>
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Internal Code</label>
                                                  </div>
                                                  
                                                  <div class="col-lg-2">
                                                  <input type="text" [(ngModel)]="findOrder.internalCode"
                                                    [ngModelOptions]="{standalone: true}" class="form-control"
                                                    placeholder="Enter Internal Code	">
                                                </div>
      
                                                <div class="col-lg-3 form-group rightFormA">
      
                                                  <label class="common-labels">Product ID</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="productListArray" appendTo="body"
                                                    [(ngModel)]="findOrder.productId" optionLabel="productId"
                                                    optionValue="productId" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Select Product">
                                                  </p-dropdown>
                                                </div>
                                              </div>
                                            </div>
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Good Identification Type</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="orderListArray" appendTo="body"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="findOrder.goodIdentificationTypeId" optionLabel="description"
                                                    optionValue="goodIdentificationTypeId"
                                                    placeholder="Select Good Identification Type">
                                                  </p-dropdown>
                                                </div>
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">Good Identification </label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <input type="text" [(ngModel)]="findOrder.goodIdentificationIdValue"
                                                    class="form-control" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Good Identification">
                                                </div>
                                              </div>
                                            </div>
      
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Inventory Item ID</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <input type="text" [(ngModel)]="findOrder.inventoryItemId"
                                                    class="form-control" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Inventory Item ID	">
                                                </div>
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">Serial Number</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <input type="text" [(ngModel)]="findOrder.serialNumber" class="form-control"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Enter Serial Number">
                                                </div>
                                              </div>
                                            </div>
      
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Soft Identifier</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <input type="text" [(ngModel)]="findOrder.softIdentifier"
                                                    class="form-control" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Soft Identifier">
                                                </div>
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">Role Type</label>
                                                </div>
      
      
                                                <div class="col-lg-2">
                                                  <input type="text" [(ngModel)]="findOrder.roleTypeId" class="form-control"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Enter Role Type">
                                                </div>
                                              </div>
                                            </div>
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Party ID
                                                  </label>
                                                </div>
      
                                                <div class="col-lg-2">
      
                                                  <p-dropdown filter="true" [options]="partyIdListArray" appendTo="body"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="findOrder.partyId"
                                                    optionLabel="partyId" optionValue="partyId" placeholder="Select Party ID">
                                                  </p-dropdown>
                                                </div>
      
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">User Login ID</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="userLoginArray" appendTo="body"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="findOrder.userLoginId"
                                                    optionLabel="userLoginId" optionValue="userLoginId"
                                                    placeholder="Select User Login ID">
                                                  </p-dropdown>
                                                </div>
      
                                              </div>
                                            </div>
      
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Order Type</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="orderTypeList" appendTo="body"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="findOrder.orderTypeId"
                                                    optionlabel="label" placeholder="Select Order Type">
                                                  </p-dropdown>
                                                </div>
      
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">Billing Account</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="billingAccountArray" appendTo="body"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="findOrder.billingAccountId" optionlabel="label"
                                                    placeholder="Select Billing Account">
                                                  </p-dropdown>
                                                </div>
                                              </div>
                                            </div>
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Created By </label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <input type="text" [(ngModel)]="findOrder.createdBy" class="form-control"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Enter Created By	">
                                                </div>
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">Sales Channel</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="salesOrderListArray" appendTo="body"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="findOrder.salesChannelEnumId" optionLabel="description"
                                                    optionValue="enumId" placeholder="Select Sales Channel">
                                                  </p-dropdown>
                                                </div>
                                              </div>
                                            </div>
      
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Product Store</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="productStoresListArray" appendTo="body"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="findOrder.productStoreId" optionLabel="storeName"
                                                    optionValue="productStoreId" placeholder="Select Product Store">
                                                  </p-dropdown>
                                                </div>
      
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">Web Site</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="webSitesListArray" appendTo="body"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="findOrder.orderWebSiteId" optionLabel="siteName"
                                                    optionValue="webSiteId" placeholder="Select Web Site">
                                                  </p-dropdown>
                                                </div>
                                              </div>
                                            </div>
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Status</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-multiSelect [(ngModel)]="findOrder.orderStatusId"
                                                    [options]="statusListArray" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Select Status" optionLabel="description"
                                                    optionValue="statusId" display="chip">
                                                  </p-multiSelect>
                                                </div>
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">Contains BackOrders </label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="containsBackOrder" appendTo="body"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="findOrder.hasBackOrders" optionlabel="label"
                                                    placeholder="Select BackOrders">
                                                  </p-dropdown>
                                                </div>
                                              </div>
                                            </div>
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Select Shipping
                                                    Methods</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="shippingMethodsList" appendTo="body"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="findOrder.shipmentMethod" optionlabel="label"
                                                    placeholder="Select Shipping Methods">
                                                  </p-dropdown>
                                                </div>
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">Viewed</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="viewed" appendTo="body"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="findOrder.isViewed"
                                                    optionlabel="label" placeholder="Select Viewed">
                                                  </p-dropdown>
                                                </div>
                                              </div>
                                            </div>
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">Address Verification (AVS)
                                                  </label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <input type="text" class="form-control"
                                                    [(ngModel)]="findOrder.gatewayAvsResult"
                                                    [ngModelOptions]="{standalone: true}"
                                                    placeholder="Enter Address Verification (AVS)	">
                                                </div>
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label class="common-labels">Score</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <input type="text" class="form-control"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="findOrder.gatewayScoreResult" placeholder="Enter Score">
                                                </div>
                                              </div>
                                            </div>
      
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label class="common-labels">More Filters</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-multiSelect [options]="moreFilters" placeholder="Select Filters"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="moreFiltersValue">
                                                  </p-multiSelect>
                                                </div>
      
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label for="exampleInputEmail1">Date From</label>
                                                </div>
      
                                                <div class="col-lg-2">
      
                                                  <p-calendar showSeconds="true" [(ngModel)]="findOrder.minDate"
                                                    placholder="Select Date From" showTime="true" hourFormat="24"
                                                    [ngModelOptions]="{standalone: true}" dateFormat="dd.mm.yy">
                                                  </p-calendar>
                                                </div>
                                              </div>
                                            </div>
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label for="exampleInputEmail1">Date To</label>
                                                </div>
      
                                                <div class="col-lg-2">
      
                                                  <p-calendar showSeconds="true" [(ngModel)]="findOrder.maxDate"
                                                    placholder="Select Date To" showTime="true" hourFormat="24"
                                                    [ngModelOptions]="{standalone: true}" dateFormat="dd.mm.yy">
                                                  </p-calendar>
                                                </div>
      
      
                                                <div class="col-lg-3 form-group rightFormA">
                                                  <label for="exampleInputEmail1">Payment Status</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="paymentStatusArray" appendTo="body"
                                                    [(ngModel)]="findOrder.paymentStatusId" optionLabel="description"
                                                    optionValue="statusId" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Select Payment Status">
                                                  </p-dropdown>
                                                </div>
                                              </div>
                                            </div>
      
                                            <div class="col-lg-12">
                                              <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                  <label for="exampleInputEmail1">Ship to Country</label>
                                                </div>
      
                                                <div class="col-lg-2">
                                                  <p-dropdown filter="true" [options]="countryList" appendTo="body"
                                                    [(ngModel)]="findOrder.countryGeoId" optionLabel="uomId"
                                                    optionValue="uomId" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Select Ship to Country">
                                                  </p-dropdown>
                                                </div>
      
                                              </div>
                                            </div>
      
    

                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button"
                                            style="margin-left: 62%;height: 21px;font-size: 11px; width: 158px;">
                                            <button _ngcontent-cta-c128=""
                                            type="submit" (click)="submit()" class="btn btn-secondary submit-btn mr-2">Find</button>
                                            <button type="button" class="btn btn-danger" (click)="resetForm()">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>

                      <!-- <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                          <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                Find Order Form </a></li>
                          </ul>
                        </div>
                        <div>
                          <div class="panel-body">
                            <div class="mt-2">
                              <div class="">
                                <div class="row">
                                  <form class="w-100">
                                    <div class="all-users-infomation font-13 font-medium own-user-select allPad">
                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Order Id</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <input type="text" [(ngModel)]="findOrder.orderId"
                                              [ngModelOptions]="{standalone: true}" class="form-control"
                                              placeholder="Enter Order Id">
                                          </div>

                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Order Name</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <input type="text" [(ngModel)]="findOrder.orderName"
                                              [ngModelOptions]="{standalone: true}" class="form-control"
                                              placeholder="Enter Order Name">
                                          </div>

                                        </div>
                                      </div>

                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">External ID</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <input type="text" [(ngModel)]="findOrder.externalId"
                                              [ngModelOptions]="{standalone: true}" class="form-control"
                                              placeholder="Enter External ID">
                                          </div>


                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Customer PO# </label>
                                          </div>

                                          <div class="col-lg-2">
                                            <input type="text" [(ngModel)]="findOrder.correspondingPoId"
                                              [ngModelOptions]="{standalone: true}" class="form-control"
                                              placeholder="Enter Customer PO#	">
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Internal Code</label>
                                            </div>
                                            
                                            <div class="col-lg-2">
                                            <input type="text" [(ngModel)]="findOrder.internalCode"
                                              [ngModelOptions]="{standalone: true}" class="form-control"
                                              placeholder="Enter Internal Code	">
                                          </div>

                                          <div class="col-lg-3 form-group rightFormA">

                                            <label class="common-labels">Product ID</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="productListArray" appendTo="body"
                                              [(ngModel)]="findOrder.productId" optionLabel="productId"
                                              optionValue="productId" [ngModelOptions]="{standalone: true}"
                                              placeholder="Select Product">
                                            </p-dropdown>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Good Identification Type</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="orderListArray" appendTo="body"
                                              [ngModelOptions]="{standalone: true}"
                                              [(ngModel)]="findOrder.goodIdentificationTypeId" optionLabel="description"
                                              optionValue="goodIdentificationTypeId"
                                              placeholder="Select Good Identification Type">
                                            </p-dropdown>
                                          </div>

                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Good Identification </label>
                                          </div>

                                          <div class="col-lg-2">
                                            <input type="text" [(ngModel)]="findOrder.goodIdentificationIdValue"
                                              class="form-control" [ngModelOptions]="{standalone: true}"
                                              placeholder="Enter Good Identification">
                                          </div>
                                        </div>
                                      </div>


                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Inventory Item ID</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <input type="text" [(ngModel)]="findOrder.inventoryItemId"
                                              class="form-control" [ngModelOptions]="{standalone: true}"
                                              placeholder="Enter Inventory Item ID	">
                                          </div>

                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Serial Number</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <input type="text" [(ngModel)]="findOrder.serialNumber" class="form-control"
                                              [ngModelOptions]="{standalone: true}" placeholder="Enter Serial Number">
                                          </div>
                                        </div>
                                      </div>


                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Soft Identifier</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <input type="text" [(ngModel)]="findOrder.softIdentifier"
                                              class="form-control" [ngModelOptions]="{standalone: true}"
                                              placeholder="Enter Soft Identifier">
                                          </div>

                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Role Type</label>
                                          </div>


                                          <div class="col-lg-2">
                                            <input type="text" [(ngModel)]="findOrder.roleTypeId" class="form-control"
                                              [ngModelOptions]="{standalone: true}" placeholder="Enter Role Type">
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Party ID
                                            </label>
                                          </div>

                                          <div class="col-lg-2">

                                            <p-dropdown filter="true" [options]="partyIdListArray" appendTo="body"
                                              [ngModelOptions]="{standalone: true}" [(ngModel)]="findOrder.partyId"
                                              optionLabel="partyId" optionValue="partyId" placeholder="Select Party ID">
                                            </p-dropdown>
                                          </div>


                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">User Login ID</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="userLoginArray" appendTo="body"
                                              [ngModelOptions]="{standalone: true}" [(ngModel)]="findOrder.userLoginId"
                                              optionLabel="userLoginId" optionValue="userLoginId"
                                              placeholder="Select User Login ID">
                                            </p-dropdown>
                                          </div>

                                        </div>
                                      </div>


                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Order Type</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="orderTypeList" appendTo="body"
                                              [ngModelOptions]="{standalone: true}" [(ngModel)]="findOrder.orderTypeId"
                                              optionlabel="label" placeholder="Select Order Type">
                                            </p-dropdown>
                                          </div>


                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Billing Account</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="billingAccountArray" appendTo="body"
                                              [ngModelOptions]="{standalone: true}"
                                              [(ngModel)]="findOrder.billingAccountId" optionlabel="label"
                                              placeholder="Select Billing Account">
                                            </p-dropdown>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Created By </label>
                                          </div>

                                          <div class="col-lg-2">
                                            <input type="text" [(ngModel)]="findOrder.createdBy" class="form-control"
                                              [ngModelOptions]="{standalone: true}" placeholder="Enter Created By	">
                                          </div>

                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Sales Channel</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="salesOrderListArray" appendTo="body"
                                              [ngModelOptions]="{standalone: true}"
                                              [(ngModel)]="findOrder.salesChannelEnumId" optionLabel="description"
                                              optionValue="enumId" placeholder="Select Sales Channel">
                                            </p-dropdown>
                                          </div>
                                        </div>
                                      </div>


                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Product Store</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="productStoresListArray" appendTo="body"
                                              [ngModelOptions]="{standalone: true}"
                                              [(ngModel)]="findOrder.productStoreId" optionLabel="storeName"
                                              optionValue="productStoreId" placeholder="Select Product Store">
                                            </p-dropdown>
                                          </div>


                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Web Site</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="webSitesListArray" appendTo="body"
                                              [ngModelOptions]="{standalone: true}"
                                              [(ngModel)]="findOrder.orderWebSiteId" optionLabel="siteName"
                                              optionValue="webSiteId" placeholder="Select Web Site">
                                            </p-dropdown>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Status</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-multiSelect [(ngModel)]="findOrder.orderStatusId"
                                              [options]="statusListArray" [ngModelOptions]="{standalone: true}"
                                              placeholder="Select Status" optionLabel="description"
                                              optionValue="statusId" display="chip">
                                            </p-multiSelect>
                                          </div>

                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Contains BackOrders </label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="containsBackOrder" appendTo="body"
                                              [ngModelOptions]="{standalone: true}"
                                              [(ngModel)]="findOrder.hasBackOrders" optionlabel="label"
                                              placeholder="Select BackOrders">
                                            </p-dropdown>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Select Shipping
                                              Methods</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="shippingMethodsList" appendTo="body"
                                              [ngModelOptions]="{standalone: true}"
                                              [(ngModel)]="findOrder.shipmentMethod" optionlabel="label"
                                              placeholder="Select Shipping Methods">
                                            </p-dropdown>
                                          </div>

                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Viewed</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="viewed" appendTo="body"
                                              [ngModelOptions]="{standalone: true}" [(ngModel)]="findOrder.isViewed"
                                              optionlabel="label" placeholder="Select Viewed">
                                            </p-dropdown>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">Address Verification (AVS)
                                            </label>
                                          </div>

                                          <div class="col-lg-2">
                                            <input type="text" class="form-control"
                                              [(ngModel)]="findOrder.gatewayAvsResult"
                                              [ngModelOptions]="{standalone: true}"
                                              placeholder="Enter Address Verification (AVS)	">
                                          </div>

                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Score</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <input type="text" class="form-control"
                                              [ngModelOptions]="{standalone: true}"
                                              [(ngModel)]="findOrder.gatewayScoreResult" placeholder="Enter Score">
                                          </div>
                                        </div>
                                      </div>


                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label class="common-labels">More Filters</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-multiSelect [options]="moreFilters" placeholder="Select Filters"
                                              [ngModelOptions]="{standalone: true}" [(ngModel)]="moreFiltersValue">
                                            </p-multiSelect>
                                          </div>


                                          <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Date From</label>
                                          </div>

                                          <div class="col-lg-2">

                                            <p-calendar showSeconds="true" [(ngModel)]="findOrder.minDate"
                                              placholder="Select Date From" showTime="true" hourFormat="24"
                                              [ngModelOptions]="{standalone: true}" dateFormat="dd.mm.yy">
                                            </p-calendar>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label for="exampleInputEmail1">Date To</label>
                                          </div>

                                          <div class="col-lg-2">

                                            <p-calendar showSeconds="true" [(ngModel)]="findOrder.maxDate"
                                              placholder="Select Date To" showTime="true" hourFormat="24"
                                              [ngModelOptions]="{standalone: true}" dateFormat="dd.mm.yy">
                                            </p-calendar>
                                          </div>


                                          <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Payment Status</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="paymentStatusArray" appendTo="body"
                                              [(ngModel)]="findOrder.paymentStatusId" optionLabel="description"
                                              optionValue="statusId" [ngModelOptions]="{standalone: true}"
                                              placeholder="Select Payment Status">
                                            </p-dropdown>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label for="exampleInputEmail1">Ship to Country</label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" [options]="countryList" appendTo="body"
                                              [(ngModel)]="findOrder.countryGeoId" optionLabel="uomId"
                                              optionValue="uomId" [ngModelOptions]="{standalone: true}"
                                              placeholder="Select Ship to Country">
                                            </p-dropdown>
                                          </div>

                                        </div>
                                      </div>

                                    </div>
                                  </form>
                                </div>
                              </div>
                              <div class="col-lg-12 col-12 main-submit-button " style="padding-right: 23em;">
                                <button _ngcontent-cta-c128=""
                                  type="submit" (click)="submit()" class="btn btn-secondary submit-btn">Find</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>

                  </div>
                </div>
              </div>
              <!-- 2 -->
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                          <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                Find Order Form </a></li>


                          </ul>
                        </div>
                        <div>
                          <br>
                          <div class="panel-body a AllPanel">
                            <div class="mt-2">
                              <div class="">
                                <div class="row m-0">
                                  <div class="card own-account-tables">
                                    <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%"
                                      [paginator]="true" [rows]="rows"
                                      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true"
                                      [totalRecords]="total" [value]="offerShowingLineData" [scrollable]="true"
                                      styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                      <ng-template pTemplate="colgroup" let-columns>
                                        <colgroup>
                                          <col *ngFor="let col of offerShowingLineColoumns">
                                        </colgroup>
                                      </ng-template>
                                      <ng-template pTemplate="header">
                                        <tr class="activity-rows">
                                          <th id="tableHead" class="tabTh" *ngFor="let col of offerShowingLineColoumns">
                                            <ng-container *ngIf="col.field === 'status'">
                                            </ng-container>
                                            <ng-container style="color: white;">
                                              {{col.header}}
                                            </ng-container>
                                            <p-sortIcon class="sort-icon" field="quantity">
                                            </p-sortIcon>

                                          </th>
                                        </tr>
                                      </ng-template>
                                      <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity>
                                        <tr class="activity-data">
                                          <td *ngFor="let col of offerShowingLineColoumns">
                                            <ng-container *ngIf="col.field === 'status'">
                                              <span>{{activity.status}}</span>
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'orderDate'">
                                              <span>{{activity.orderDate|date}}</span>
                                            </ng-container>
                                            <ng-container *ngIf="col.field==='orderId'">
                                              <span routerLink="/order/sales-order-view-page"
                                                 class="account-button" 
                                                [queryParams]="{orderId:activity.orderId}">{{activity.orderId}}</span>
                                            </ng-container>
                                            <ng-container
                                              *ngIf="col.field != 'status' && col.field != 'orderDate' && col.field!=='orderId'">
                                              <span class="d-block align-items-center">
                                                <div class="name_text">
                                                  {{activity[col.field]}}
                                                </div>
                                              </span>
                                            </ng-container>
                                          </td>
                                        </tr>
                                      </ng-template>
                                    </p-table>

                                    <p class="paginate_data fid-pagination">
                                      View per page </p>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <ngx-spinner></ngx-spinner>