<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">My Payments</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategories==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                    Find</a></li>
                            <li [ngClass]="activeCategories==2?'active':''" (click)="changeactiveCategory(2)"><a>
                                    Overview </a></li>
                            <li [ngClass]="activeCategories==3?'active':''" (click)="changeactiveCategory(3)"
                                 *ngIf="statusIdCheck=='PMNT_NOT_PAID'||statusIdCheck=='PMNT_RECEIVED'"  ><a>
                                    Header </a></li>
                            <li [ngClass]="activeCategories==4?'active':''"  (click)="changeactiveCategory(4)"
                            *ngIf="statusIdCheck=='PMNT_NOT_PAID'||statusIdCheck=='PMNT_RECEIVED'"><a>
                                    Applications </a></li>


                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">

                                    <div class="submenu d-flex ml-3 mt-3"
                                        style="width: 100%!important; margin-bottom: 10px; margin-left: -5px !important;">
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            class="btn btn-secondary submit-btn ml-2 fontMenu"
                                              (click)="createPayment()">Create</button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="statusIdCheck=='PMNT_NOT_PAID'||statusIdCheck=='PMNT_RECEIVED'"    class="btn btn-secondary submit-btn ml-2 fontMenu" data-toggle="modal"
                                                (click)="setPaymentStatusSent()">Status to 'Sent'
                                            </button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="statusIdCheck=='PMNT_NOT_PAID'||statusIdCheck=='PMNT_RECEIVED'"    class="btn btn-secondary submit-btn ml-2 fontMenu"
                                                (click)="setPaymentStatusCancel()"> Status to 'Cancelled'</button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="statusIdCheck=='PMNT_NOT_PAID'||statusIdCheck=='PMNT_RECEIVED'"   class="btn btn-secondary submit-btn ml-2 fontMenu"
                                                (click)="generateChecksPDFNew()"> Print As Check</button>
                                        </div>
                                        <div>
                                            <button _ngcontent-cta-c128="" type="submit"
                                            *ngIf="statusIdCheck=='PMNT_NOT_PAID'||statusIdCheck=='PMNT_CANCELLED'||statusIdCheck=='PMNT_RECEIVED'"  
                                              class="btn btn-secondary submit-btn ml-2 fontMenu"
                                                (click)="setPaymentStatusVoid()"> Status to 'Void'</button>
                                        </div>
                                   </div>
                                    <div *ngIf="activeCategories==1">
                                        <div class="w3-card-4 classCardView">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling" style="margin-left: 7px;">
                                                    Find Invoice
                                                </h4>
                                            </div>
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <form class="w-100" [formGroup]="findForm">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">
                                                                                    Payment ID

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Payment ID"
                                                                                    formControlName="paymentId">

                                                                            </div>
                                                                            <div
                                                                                class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">

                                                                                    Payment Type</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="PaymentTypeOutgoingList"
                                                                                    optionlabel="label"
                                                                                    formControlName="paymentType"
                                                                                    placeholder="Select Payment Type">
                                                                                </p-dropdown>

                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">
                                                                                    Comments

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Comments"
                                                                                    formControlName="comments">

                                                                            </div>

                                                                            <div
                                                                                class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">

                                                                                    Status ID </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    formControlName="status"
                                                                                    optionlabel="label"
                                                                                    [options]="allStatusList"
                                                                                    placeholder="Select Status ID">
                                                                                </p-dropdown>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">

                                                                                    From Party ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    formControlName="fromPartyId"
                                                                                    [options]="partyIdArray"
                                                                                    optionlabel="label"
                                                                                    placeholder="Select From Party ID">
                                                                                </p-dropdown>
                                                                            </div>

                                                                            <div
                                                                                class="col-lg-3 form-group classInput__new_changes">
                                                                                <label for="exampleInputEmail1">

                                                                                    To Party ID </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true"
                                                                                    formControlName="toPartyId"
                                                                                    [options]="partyIdArray"
                                                                                    optionlabel="label"
                                                                                    placeholder="Select To Party ID">
                                                                                </p-dropdown>

                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">

                                                                                    Amount </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Amount"
                                                                                    formControlName="amount">

                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                </div>


                                                            </form>
                                                            <div class="main-submit-button" style="margin-left: -26%;">
                                                                <button _ngcontent-cta-c128="" type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="findPaymentsList()">Find</button>&nbsp;
                                                                <button type="button" class="btn btn-danger ml-2"
                                                                    (click)="resetPayment()">Reset</button>


                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="w3-card-4 classCard w-100 mt-2">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Search Results
                                                </p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="PaymentsList"
                                                                [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [responsive]="true" [totalRecords]="total"
                                                                [scrollable]="true" styleClass="p-datatable-customers"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th pSortableColumn="code"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Payment ID <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;"> Payment Type

                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;"> Comments
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="quantity">
                                                                            <div style="color: white;"> Status
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div style="color: white;"> Payment Method Id
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div style="color: white;"> From Party Id
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div style="color: white;"> To Party Id
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div style="color: white;"> Amount</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="price"
                                                                            [ngStyle]="{'width':'190px'}">
                                                                            <div style="color: white;"> Outstanding
                                                                                Amount</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <!--  <span
                                                                                    routerLink="/financial/{{invoiceType}}/invoices/detailed-summary"
                                                                                    [queryParams]="{invoiceId:product.invoiceId}"
                                                                                     class="account-button">{{product.invoiceId}}</span> -->
                                                                                    <span>{{product.paymentId}}</span>
                                                                            </div>
                                                                        </td>


                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.paymentTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.comments}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.statusId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.paymentMethodId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.partyIdFrom}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.partyIdTo}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.amount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.outstandingAmount}}
                                                                        </td>



                                                                    </tr>

                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No Record Found</h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="activeCategories==2">
                                        <div>
                                        <div class="w3-card-4 classCardView w-100 mt-3">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                style="justify-content: space-between;">
                                                <p class="h-text">Summary</p>
                                                <span>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                     *ngIf="statusIdCheck=='PMNT_NOT_PAID'||statusIdCheck=='PMNT_RECEIVED'"   style="margin: 0;" data-toggle="modal" 
                                                        data-target="#updateHeaderdPopup">Update</button>
                                                </span>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                                <div class="color-black container">
                                                    <div class="row" *ngIf="PaymentDetailsById">
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Payment Type ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.paymentType}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Payment Method Type </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.paymentMethodType}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Status</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.status}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Payment Method ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.paymentMethodId}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">From Party </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.fromParty}} 
                                                                        {{"[" + this.PaymentDetailsById.partyIdFrom + "]"}} 

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">To Party </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.partyIdTo}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Reference No </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.paymentRefNum}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Payment Preference ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">


                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Amount </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.amount}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Actual Currency Amount </p>
                                                                  
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.actualCurrencyAmount}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Effective Date </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.effectiveDate |
                                                                        date:"yyyy-MM-dd HH:mm:ss"}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Comments </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.comments}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Override Gl Account ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.overrideGlAccountId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6"> Payment Gateway Response ID
                                                                    </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.paymentGatewayResponseId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                      
                                        <div class="w3-card-4 classCardView w-100 mt-3" *ngIf="PaymentDetailsById.paymentTypeId=='CUSTOMER_DEPOSIT'">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                style="justify-content: space-between;">
                                                <p class="h-text">Financial Account Transaction</p>
                                                <span>
                                                   
                                                </span>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">

                                                <div class="color-black container">
                                                    <div class="row" *ngIf="financialAccountTransaction">
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Fin Account Trans ID</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{financialAccountTransaction.finAccountTransId}}

                                                                        

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Fin Account Trans Type </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{financialAccountTransaction.finAccountTransType}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Amount</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{financialAccountTransaction.amount}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">GI Reconciliation ID
                                                                    </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Transaction Date</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{financialAccountTransaction.transactionDate
                                                                         | date:"yyyy-MM-hh HH:mm:ss"}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Status </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{financialAccountTransaction.status}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                      
                                    <div>
                                        <div class="row mt-2">
                                            <div class="col-lg-6" style="padding-right: 0;">
                                                <div class="w3-card-4 classCard w-100 ">
                                                    <div
                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text">Payments Applied </p>
        
                                                        <span>
                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            *ngIf="statusIdCheck=='PMNT_NOT_PAID'||statusIdCheck=='PMNT_RECEIVED'"   style="margin: 0;"  (click)="changeactiveCategory(8)">Update</button>
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="paymentApplicationList" [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
        
                                                                                        Invoice ID
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Item No
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Billing Account ID </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Override Gl Account ID</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        To payment ID
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
        
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Tax Auth Geo ID
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
        
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Amount Applied
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
        
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
        
        
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span
                                                                                             class="account-button"  
        
                                                                                            (click)="invoicePage(product.invoiceId)">
                                                                                            {{product.invoiceId}}</span>
        
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.invoiceItemSeqId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}" >
                                                                                <div data-toggle="modal" style="color:#0d3769;"
                                                                                data-target="#profileCreateBillingAcc" 
                                                                                (click)="getBillingAccountById(product)"> 
                                                                                  {{product.billingAccountId}}</div>
                                                                                 
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.overrideGlAccountId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}" 
                                                                               >
                                                                                <span (click)="paymentPage(product.toPaymentId)"
                                                                                 class="account-button"  >
                                                                                {{product.toPaymentId}}</span>
        
                                                                                  
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.taxAuthGeoId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.amountApplied}}
                                                                                </td>
        
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No
                                                                                            Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page
        
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 pl-2">

                                        <div class="w3-card-4 classCardView w-100 mb-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Transactions
                                                </p>
                                                  <span>
                                                    </span>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="transactionList" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Acctg Trans ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Acctg Trans Entry Seq ID
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Is Posted </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Fiscal Gl Type ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Acctg Trans Type ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Transaction Date
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Posted Date
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Gl Journal ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Trans Type Description
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Invoice ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Fixed Asset ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Description
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Gl Account ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Product ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Debit Credit Flag
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Amount
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Orig Amount
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Organization Party ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Gl Account Type
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Account Code
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Account Name
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                GL Account Class
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Party
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Reconcile Status ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Acctg Trans Entry Type ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.acctgTransId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.acctgTransEntrySeqId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.isPosted}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.glFiscalTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.transTypeDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.transactionDate | date:"yyyy-MM-dd HH:mm:ss"}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.postedDate| date:"yyyy-MM-dd HH:mm:ss"}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.glJournalId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.transTypeDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.invoiceId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fixedAssetId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.glAccountId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.productId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.debitCreditFlag}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.amount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.origAmount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.organizationPartyId}}
                                                                        </td>     
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.glAccountTypeDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.accountCode}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.accountName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.glAccountClassDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.partyName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.reconcileStatusDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.acctgTransEntryTypeId}}
                                                                        </td>
                                                                      

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            </div>

                                        </div>
                                      

                                    </div>
                           </div>

                                    <div *ngIf="activeCategories==3">

                                        <div class="w3-card-4 classCard ">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                style="justify-content: space-between;">
                                                <p class="h-text">Summary</p>
                                                <span class="edit-buttons" style="cursor: pointer;"
                                                    data-toggle="modal" data-target="#updateHeaderdPopup">Update</span>

                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="color-black container">
                                                    <div class="row" *ngIf="PaymentDetailsById">
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Payment Type ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.paymentType}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Status Id</p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.status}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Payment Method Type </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.paymentMethodType}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
          
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Payment Method ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.paymentMethodId}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">From Party </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.fromParty}} 
                                                                        {{"[" + this.PaymentDetailsById.partyIdFrom + "]"}} 

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">To Party </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.partyIdTo}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Amount </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.amount}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Currency </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.currencyUomId}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Actual Currency Amount </p>
                                                                  
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.actualCurrencyAmount}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Effective Date </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.effectiveDate |
                                                                        date:"yyyy-MM-dd HH:mm:ss"}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Reference No </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.paymentRefNum}}

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Comments </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.comments}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                     
                                                        

                                          
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6"> Fin Account Trans ID	
                                                                    </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.financialAccountTransaction}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-6">Override Gl Account ID </p>
                                                                    <span class="color-grey pl-20 font-weight-bold">
                                                                        {{this.PaymentDetailsById.overrideGlAccountId}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       




                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div *ngIf="activeCategories==4">
                                        <div style="width: 49.9%; float: left;">

                                        <div class="w3-card-4 classCard w-100 mt-2">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import  create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Payment Applications </p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="PaymentApplicationList" [paginator]="false" [rows]="3"
                                                            scrollHeight="150px"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                Invoice ID	                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Item No	
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Amount Applied	 </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                 


                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Remove
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  
                                                                                    (click)="invoicePage(product.invoiceId)">
                                                                                    {{product.invoiceId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.invoiceItemSeqId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.amountApplied}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <button _ngcontent-cta-c128="" type="submit" 
                                                                            class="btn btn-secondary submit-btn " 
                                                                            (click)="removePaymentApplication(product)">
                                                                            Remove</button>
                                                                        </td>
                                                                       

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                       
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <div class="w3-card-4 classCardView mt-2"
                                        style="width:202% !important;">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling" style="margin-left: 7px;">
                                                    Apply this Payment to
                                                </h4>
    
    
                                            </div>
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <form class="w-100" [formGroup]="applicationsForm">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">
                                                                                    Invoice ID
    
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown optionlabel="label"
                                                                                    placeholder="Select Invoice ID"
                                                                                    filter="true" [options]="invoiceIDArray"
                                                                                    formControlName="invoiceID">
    
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group rightFormA">
                                                                                <label for="exampleInputEmail1">
    
                                                                                    To payment ID </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown optionlabel="label"
                                                                                    placeholder="Select To payment ID"
                                                                                    filter="true" [options]="PaymentIDArray"
                                                                                    formControlName="topaymentID">
    
                                                                                </p-dropdown>
    
                                                                            </div>
    
                                                                        </div>
                                                                    </div>
    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">
    
                                                                                    Billing Account ID </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown
                                                                                    placeholder="Select Billing Account ID"
                                                                                    [options]="billingAccountDroparray" optionlabel="label" filter="true"
                                                                                    formControlName="billingAccountID">
    
                                                                                </p-dropdown>
    
                                                                            </div>
    
                                                                            <div class="col-lg-3 form-group rightFormA">
                                                                                <label for="exampleInputEmail1">
    
                                                                                    Tax Auth Geo ID </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown optionlabel="label"
                                                                                    placeholder="Select Tax Auth Geo ID"
                                                                                    filter="true" [options]="CatalogGeoIdArray"
                                                                                    formControlName="taxAuthGeoID">
    
                                                                                </p-dropdown>
    
                                                                            </div>
                                                                        </div>
                                                                    </div>
    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group rightForm">
                                                                                <label for="exampleInputEmail1">
    
                                                                                    Outstanding amount </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Outstanding amount"
                                                                                    formControlName="outstandingAmount">
    
                                                                            </div>
                                                                            <div class="col-lg-3 form-group rightFormA">
                       
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <button type="submit"
                                                                                class="btn btn-secondary submit-btn" 
                                                                                (click)="applyApplicationPayment()">Apply</button>
    
                                                                            </div>
    
    
                                                                        </div>
                                                                    </div>
    
    
                                                                </div>
    
    
                                                            </form>
                            
                                                        </div>
                                                    </div>
    
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                    <div style="width: 49.3%; float: right;">

                                        <div class="w3-card-4 classCard w-100 mt-2">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Amount Total US$ {{PaymentApplication.totalAmount}} Amount not yet applied US$  {{PaymentApplication.paymentNotApplied}} </p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="PaymentApplicationList" [paginator]="false" [rows]="rows"
                                                            scrollHeight="150px"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                To payment ID	                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Billing Account ID	
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Tax Auth Geo ID	 </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                    


                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Amount Applied
                                                                        </div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.toPaymentId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.billingAccountId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.taxAuthGeoId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.amountApplied}}
                                                                        </td>
                                                                      
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                             class="btn btn-secondary submit-btn " 
                                                                             (click)="removePaymentApplication(product)">Remove</button>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                   
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                       
                                    </div>

                                   
                                        <!-- <div class="w3-card-4 classCard w-100 mt-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Poosilbe Payments to Apply </p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>

                                                                                To Payment ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Effective Date
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Amount</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Amount Applied
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Outstanding Amount
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>



                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.data.custRequestId}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.data.custRequestTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.data.productStoreId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.data.custRequestName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.data.priority}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.data.priority}}
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->

                                    </div>

                                   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateBillingAcc" tabindex="-1" role="dialog" aria-labelledby="createBillingAcc" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closeButton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Edit Billing Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createBillingAccount">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Account Limit</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Account Limit" id="exampleInputEmail1" formControlName="accountLimit">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Account Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Account Currency Uom ID"
                                                         filter="true" id="exampleInputEmail1" 
                                                        [options]="currencyUomIds" formControlName="accountCurrencyUomId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Contact Mech ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Contact Mech ID" id="exampleInputEmail1" formControlName="contactMechId">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" placeholder="From Date" id="exampleInputEmail1" formControlName="fromDate">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Through Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" placeholder="Through Date" formControlName="thruDate">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Description" id="exampleInputEmail1" formControlName="description">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">External Account ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="External Account ID" id="exampleInputEmail1" formControlName="externalAccountId">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Party ID <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Party ID" filter="true"
                                                       [options]="partyIdArray"    id="exampleInputEmail1" formControlName="partyId">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Available Balance</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="External Account ID" id="exampleInputEmail1" formControlName="AvailableBalance">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 main-submit-button5">
                                            <button type="submit" class="btn btn-secondary submit-btn" 
                                            (click)="updateBillingAccount()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateHeaderdPopup" tabindex="-1" role="dialog" aria-labelledby="updateHeaderdPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Header Detailed Information
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Header Detailed Information
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateHeadeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Payment Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="paymentType"
                                                         [options]="PaymentTypeOutgoingList"   optionlabel="label" placeholder="Select Payment Type">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Status ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="statusID"
                                                         disabled   class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Status ID">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Payment Method ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="paymentMethodID"
                                                          [options]="paymentMethodIdrray"  optionlabel="label" placeholder="Select Payment Method ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">From Party ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="fromPartyID"
                                                           [options]="partyIdArray" optionlabel="label" placeholder="Select From Party ID">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            To Party ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="toPartyID"
                                                        [options]="partyIdArray"   optionlabel="label" 
                                                        placeholder="Select To Party ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Amount</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="amount"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Amount">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Currency </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="currency"
                                                         [options]="currencyUomIds"   optionlabel="label" placeholder="Select Currency">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Actual Currency Amount </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="actualCurrencyAmount"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Actual Currency Amount">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Actual Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="actualCurrencyUomID"
                                                            optionlabel="label"
                                                            placeholder="Select Actual Currency Uom ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Effective Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="effectiveDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Reference No</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="referenceNo"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Reference No">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="comments"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comments">


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Fin Account Trans ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="finAccountTransID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Fin Account Trans ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Override Gl Account ID </label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" formControlName="overrideGlAccountID"
                                                            optionlabel="label" [options]="GIAccounts"
                                                            placeholder="Select Override Gl Account ID">
                                                        </p-dropdown>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 32%;">
                                                <button type="submit"
                                                    class="btn btn-secondary submit-btn" (click)="updatePayment()">Update</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







<ngx-spinner></ngx-spinner>