import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployementApplicationService } from "../employee-application.service";
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { EmployeeApplicationService } from "../employee-application/employee-application.service"
import { ReallocationsService } from 'src/app/human-resource/reallocation/reallocations.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { InternalJobService } from 'src/app/human-resource/recuitment/internal-job-posting/internal-job-posting.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { CreateEventFormService } from '../create-employee-application/create-employee-application-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { DeparmentsService } from 'src/app/human-resource/departments/departments.service';
import { saveAs } from 'file-saver';
import { Location } from '@angular/common';
@Component({
  selector: 'app-employee-application',
  templateUrl: './employee-application.component.html',
  styleUrls: ['./employee-application.component.css']
})
export class EmployeeApplicationComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  activeCategory = 3;
  statusList: any;
  appSource: any;
  statusArray: any = [];
  appSourceArray: any = [];
  createEmploymentForm: FormGroup;
  updateId: any;
  show = false;
  pageNo = 1;
  total = 0;
  data: any;
  rows = 50;
  pageSize = 100;
  readonly employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  public departments = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  search = "";
  public jobPost = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search

  }
  positionArray: any = [];
  positionType: any;
  personId: any;
  partyIdArray: any = [];
  internalJobPostList: any;
  appIdArray: any = [];
  updateethrdateStatus: any;


  public applicationSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  employmentApplication: any;
  dateIdArray: { label: string; value: string; }[];
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  arrayStatus: any = [];
  yesNoArray: { label: string; value: string; }[];
  AvailabilityArray: any[] = [];
  available: any;
  transportationArray: { label: string; value: string; }[];
  DepartmentArray: any[] = [];
  Department: any;
  departmentList: any;
  empTypeId: any;
  availableArray: { label: string; value: string; }[];
  availArray: { label: string; value: string; }[];
  fileData: File;
  EmploymentFormSignature: FormGroup;
  signatureURL: any;
  advanceSearch: {
    employTypeID: string;
    Name: string;
    SocialSecurityNo: string;
    LicenseNumber: string;
    Position: string;
    Availability: string;
    Department: string;
  };
  employmentApplicationID: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly employementApplicationService: EmployementApplicationService,
    readonly employeeApplicationService: EmployeeApplicationService,
    readonly accountsService: AccountsService,
    readonly toastr: ToastrService, 


    private _location: Location,

    public datePipe: DatePipe,
    readonly reallocationsService: ReallocationsService,
    public addSkillService: AddSkillService,
    readonly deparmentsService: DeparmentsService,
    readonly internalJobService: InternalJobService,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly router: Router) {
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.dateIdArray = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },

      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equal To',
        value: 'GreaterThanEqualTo'
      },
      {
        label: 'Less Than Equal To',
        value: 'LessThanEqualTo'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      }

    ];
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Yes'
      },
      {
        label: 'No',
        value: 'No'
      },

    ];
    this.transportationArray = [
      {
        label: 'Own Vehicle',
        value: 'Own Vehicle'
      },
      {
        label: 'Public Transport',
        value: 'Public Transport'
      },


    ];
    this.availArray = [
      {
        label: 'Full Time',
        value: 'Full Time'
      },
      {
        label: 'Part Time',
        value: 'Part Time'
      },


    ];
    // this.advanceSearch = {
    //   applicationDateFrom: "",
    //   applicationDateFromSearchType: "Contains",
    //   applicationDateTo: "",
    //   applicationDateToSearchType: "Contains",
    //   applicationId: "",
    //   applyingPartyId: "",
    //   emplPositionId: "",
    //   employmentAppSourceTypeId: "",
    //   referredByPartyId: "",
    //   statusID: [
    //     ""
    //   ]
    // }
    this.createEmploymentForm = this._FormBuilder.group({
      crime: [''],
      name: [''],
      socailSecurityNumber: [''],
      address: [''],
      telephoneNo: [''],
      OtherPhonenumber: [''],
      reason: [''],
      empDate: ['', [Validators.required]],


      PositionAppliedFor: [''],
      DateAvailable: ['', [Validators.required]],
      referred: [''],
      payRate: [''],
      available: [''],


      SchoolAddress: [''],
      degree: [''],
      SchoolAddressTwo: [''],
      degreeTwo: [''],
      SchoolAddressThree: [''],
      degreeThree: [''],
      SchoolAddressFour: [''],
      degreeFour: [''],


      ProfessionalLicense: [''],
      Expiration: ['', [Validators.required]],
      applicationFilled: [''],
      explain: [''],
      transportation: [''],
      license: [''],
      LicenseNumber: [''],
      StateofIssue: [''],
      ExpirationDate: ['', [Validators.required]],


      nameOne: [''],
      nameTwo: [''],
      addressOne: [''],
      secondAddress: [''],
      TelephoneNumber: [''],
      TelephoneNumberTwo: [''],


      Employer: [''],
      TypeofBusiness: [''],
      EmpAddress: [''],
      Phone: [''],
      TitlesDuties: [''],
      RatePay: [''],
      PeriodEmployed: [''],
      ReasonforLeaving: [''],
      SupervisorName: [''],


      position: [''],
      Department: [''],
      dateHire: ['', [Validators.required]],
      Salary: [''],
      Comments: [''],

      Signature: [''],
      signDate: [''],







    });

    this.EmploymentFormSignature = this._FormBuilder.group({
      Signature: [''],
      signDate: [''],

    });

    this.advanceSearch = {

      employTypeID: "",
      Name: "",
      SocialSecurityNo: "",
      LicenseNumber: "",
      Position: "",
      Availability: "",
      Department: "",

    };
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.createEventFormService.employee_application_form = null;
    this.createEventFormService.employeeApplicationForm(this.createEventFormService.employee_application_form);
    this.statusIdNew();
    this.appSourceTypeListNew();
    this.getPositionTypeNew();
    this.getPartyIdNew();
    this.getInternalJobDropdwonNew();


    this.getEmployeeApplication();
    this.statusId();
    this.appSourceTypeList();
    this.getPositionType();
    this.getPartyId();
    this.getInternalJobDropdwon();
    this.getdepartmentsList();
    this.findApplicationEmploymentId();
    this.findApplicationEmployment();
    this.getAvailabilty();

  }

  generalInformation(){
      this.activeCategory=3;
  }

  reset() {
    this.spinner.show();
    this.advanceSearch.employTypeID = "";
    this.advanceSearch.Name = "";
    this.advanceSearch.SocialSecurityNo = "";
    this.advanceSearch.LicenseNumber = "";
    this.advanceSearch.Position = "";
    this.advanceSearch.Availability = "";
    this.advanceSearch.Department = "";



    this.accountsService.findApplicationEmployment().subscribe((res: any) => {
      this.employmentApplication = res.data;
      this.spinner.hide();


    })
  }


  onSubmitSign() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.signatureURL = res.data.URL;
        this.closebutton3.nativeElement.click();


      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })

  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];

  }

  findApplicationEmployment() {
    this.accountsService.findApplicationEmployment().subscribe((res: any) => {
      this.spinner.show();
      this.employmentApplication = res.data;
      this.spinner.hide();
    })
  }
  findApplicationEmploymentId() {
    this.accountsService.findApplicationEmploymentId(this.empTypeId).subscribe((res: any) => {
      this.spinner.show();
      this.employmentApplicationID = res.data;
      this.spinner.hide();
    })
  }


  findApplicationEmploymentIdSearch() {
    this.spinner.show();
    const req = {
      "empTypeId": this.advanceSearch.employTypeID ? this.advanceSearch.employTypeID : '',
      "name": this.advanceSearch.Name ? this.advanceSearch.Name : '',
      "socialSecurityNumber": this.advanceSearch.SocialSecurityNo ? this.advanceSearch.SocialSecurityNo : '',
      "driverLicenseNumber": this.advanceSearch.LicenseNumber ? this.advanceSearch.LicenseNumber : '',
      "position": this.advanceSearch.Position ? this.advanceSearch.Position : '',
      "availability": this.advanceSearch.Availability ? this.advanceSearch.Availability : '',
      "departments": this.advanceSearch.Department ? this.advanceSearch.Department : '',
    }
    this.accountsService.findApplicationEmploymentIdSearch(req.empTypeId, req.name, req.socialSecurityNumber,
      req.driverLicenseNumber, req.position, req.availability, req.departments).subscribe((res: any) => {
        this.employmentApplication = res.data;
        this.spinner.hide();
        this.findApplicationEmployment();


      })

  }


  onSubmitEmployment() {
    this.spinner.show();

     const fromActualdate=this.createEmploymentForm.get('empDate').value;
     let empDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");

     const Available =this.createEmploymentForm.get('DateAvailable').value;
     let DateAvailable =this.datePipe.transform(Available,"yyyy-MM-dd hh:mm:ss");

    const expiration =this.createEmploymentForm.get('Expiration').value;
    let expirationDate =this.datePipe.transform(expiration,"yyyy-MM-dd hh:mm:ss");

    const expirationOne =this.createEmploymentForm.get('ExpirationDate').value;
    let expirationDate1 =this.datePipe.transform(expirationOne,"yyyy-MM-dd hh:mm:ss");

    const dateHire =this.createEmploymentForm.get('dateHire').value;
    let hireDate =this.datePipe.transform(dateHire,"yyyy-MM-dd hh:mm:ss");


    const formData = {
      "name": this.createEmploymentForm.value.name,
      "empTypeId": this.empTypeId,
      "address": this.createEmploymentForm.value.address,
      "socialSecurityNumber": this.createEmploymentForm.value.socailSecurityNumber,
      "telephoneNumber": this.createEmploymentForm.value.telephoneNo,
      "phoneNumber": this.createEmploymentForm.value.OtherPhonenumber,
      // "date": this.createEmploymentForm.value.empDate,
      "date": empDate,
      "felonyCrime": this.createEmploymentForm.value.crime,
      "description": this.createEmploymentForm.value.reason,


      "positionAppliedFor": this.createEmploymentForm.value.PositionAppliedFor,
      // "availableDate": this.createEmploymentForm.value.DateAvailable,
      "availableDate": DateAvailable,
      "desirePayRate": this.createEmploymentForm.value.payRate,
      "availability": this.createEmploymentForm.value.available,
      "referredToUs": this.createEmploymentForm.value.referred,

      "schoolAddress1": this.createEmploymentForm.value.SchoolAddress,
      "schoolAddress2": this.createEmploymentForm.value.SchoolAddressTwo,
      "schoolAddress3": this.createEmploymentForm.value.SchoolAddressThree,
      "schoolAddress4": this.createEmploymentForm.value.SchoolAddressFour,
      "degree1": this.createEmploymentForm.value.degree,
      "degree2": this.createEmploymentForm.value.degreeTwo,
      "degree3": this.createEmploymentForm.value.degreeThree,
      "degree4": this.createEmploymentForm.value.degreeFour,

      "professionalLicense": this.createEmploymentForm.value.ProfessionalLicense,
      // "expirationDate": this.createEmploymentForm.value.Expiration,
      "expirationDate": expirationDate,
      "applicationHereBefore": this.createEmploymentForm.value.applicationFilled,
      "explain": this.createEmploymentForm.value.explain,
      "meansOfTransportation": this.createEmploymentForm.value.transportation,
      "driverLicense": this.createEmploymentForm.value.license,
      "driverLicenseNumber": this.createEmploymentForm.value.LicenseNumber,
      "stateOfIssue": this.createEmploymentForm.value.StateofIssue,
      // "expirationDate1": this.createEmploymentForm.value.ExpirationDate,
      "expirationDate1": expirationDate1,
      "referencesAddress1": this.createEmploymentForm.value.addressOne,
      "referencesAddress2": this.createEmploymentForm.value.secondAddress,
      "referencesName1": this.createEmploymentForm.value.nameOne,
      "referencesName2": this.createEmploymentForm.value.nameTwo,

      "referencesTelephoneNumber2": this.createEmploymentForm.value.TelephoneNumberTwo,
      "referencestTelephoneNumber1": this.createEmploymentForm.value.TelephoneNumber,

      "employer": this.createEmploymentForm.value.Employer,
      "typeOfBusiness": this.createEmploymentForm.value.TypeofBusiness,
      "employmentAddress": this.createEmploymentForm.value.EmpAddress,
      "employmentPhone": this.createEmploymentForm.value.Phone,
      "titlesAndDuties": this.createEmploymentForm.value.TitlesDuties,
      "ratePay": this.createEmploymentForm.value.RatePay,
      "periodEmployed": this.createEmploymentForm.value.PeriodEmployed,
      "reasonForLeaving": this.createEmploymentForm.value.ReasonforLeaving,
      "supervisorName": this.createEmploymentForm.value.SupervisorName,


      "comments": this.createEmploymentForm.value.Comments,
      "departments": this.createEmploymentForm.value.Department,
      "position": this.createEmploymentForm.value.position,
      // "hireDate": this.createEmploymentForm.value.dateHire,
      "hireDate": hireDate,
      "salary": this.createEmploymentForm.value.Salary,
      "signatureUrl": this.signatureURL,

    }
    this.accountsService.createApplicationEmployment(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.findApplicationEmployment();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }


  getInternalJobDropdwonNew() {
    this.spinner.show();
    const jobList = {
      applicationDateFrom: "",
      applicationDateTo: "",
      applicationId: "",
      applyingPartyId: "",
      approverPartyId: "",
      jobRequisitionId: "",
      statusId: "",
      applicationDateFromSearchType: "Equals",
      applicationDateToSearchType: "Equals"
    }
    this.internalJobService.getInternalJobList(this.jobPost, jobList).subscribe((res: any) => {
      this.internalJobPostList = res.data;
      this.spinner.hide();
      for (const value of this.internalJobPostList) {
        this.appIdArray.push({
          label: value.applicationId,
          value: value.applicationId
        })
      }
    })

  }
  getAvailabilty() {
    this.spinner.show();
    this.accountsService.getAvailabilty().subscribe(res => {
      this.available = res.data[0].data;
      this.spinner.hide();
      for (const value of this.available) {
        this.AvailabilityArray.push({
          label: value.availability,
          value: value.availability
        })
      }
    })
  }


  getPositionTypeNew() {
    this.spinner.show();
    const emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: "",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.reallocationsService.getEmployeePosition(this.employessSize, emplPosition).subscribe(res => {

      this.positionType = res.data;
      this.spinner.hide();
      for (const value of this.positionType) {
        this.positionArray.push({
          label: value.emplPositionId,
          value: value.emplPositionId
        })
      }
    })

  }
  getPartyIdNew() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  statusIdNew() {
    this.spinner.show();
    this.employeeApplicationService.getStatusList().subscribe(res => {
      this.statusList = res.data;
      this.spinner.hide();
      for (const value of this.statusList) {
        this.statusArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })

  }
  onSubmit() {
    this.spinner.show();
    const thrudate = this.createEventFormService.employee_application_form.get('applicationDate').value;
    this.updateethrdateStatus = this.datePipe.transform(thrudate, "yyyy-MM-dd hh:mm:ss");
    const formDate = {
      applicationDate: this.updateethrdateStatus,
      applicationId: this.createEventFormService.employee_application_form.value.applicationId,
      applyingPartyId: this.createEventFormService.employee_application_form.value.applyingPartyId,
      emplPositionId: this.createEventFormService.employee_application_form.value.emplPositionId,
      employmentAppSourceTypeId: this.createEventFormService.employee_application_form.value.employmentAppSourceTypeId,
      referredByPartyId: this.createEventFormService.employee_application_form.value.referredByPartyId,
      statusId: this.createEventFormService.employee_application_form.value.statusId
    }

    this.employeeApplicationService.createEmploymentApplication(formDate).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.createEventFormService.employee_application_form.reset();
        this.getEmployeeApplication();
        this.closebutton.nativeElement.click();
        this.router.navigate(['/hr/employee-application'])
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const data of err.error.errors) {
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
    })

  }
  appSourceTypeListNew() {
    this.spinner.show();
    this.employeeApplicationService.getAppsourceList().subscribe(res => {
      this.appSource = res.data;
      this.spinner.hide();
      for (const data of this.appSource) {
        this.appSourceArray.push({
          label: data.description,
          value: data.employmentAppSourceTypeId
        })
      }
    })

  }
  detailPage(empTypeId) {
    this.router.navigate(["/hr/employee-application-summary"], { queryParams: { empTypeId: empTypeId } })

  }
  appSourceTypeList() {
    this.spinner.show();
    this.employeeApplicationService.getAppsourceList().subscribe(res => {
      this.appSource = res.data;
      this.spinner.hide();
      for (const data of this.appSource) {
        this.appSourceArray.push({
          label: data.description,
          value: data.employmentAppSourceTypeId
        })
      }
    })

  }
  getInternalJobDropdwon() {
    this.spinner.show();
    const jobList = {
      applicationDateFrom: "",
      applicationDateTo: "",
      applicationId: "",
      applyingPartyId: "",
      approverPartyId: "",
      jobRequisitionId: "",
      statusId: "",
      applicationDateFromSearchType: "Equals",
      applicationDateToSearchType: "Equals"
    }
    this.internalJobService.getInternalJobList(this.jobPost, jobList).subscribe((res: any) => {
      this.internalJobPostList = res.data;
      this.spinner.hide();
      for (const value of this.internalJobPostList) {
        this.appIdArray.push({
          label: value.applicationId,
          value: value.applicationId
        })
      }
    })

  }
  getPositionType() {
    this.spinner.show();
    const emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: "",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.reallocationsService.getEmployeePosition(this.employessSize, emplPosition).subscribe(res => {

      this.positionType = res.data;
      this.spinner.hide();
      for (const value of this.positionType) {
        this.positionArray.push({
          label: value.emplPositionId,
          value: value.emplPositionId
        })
      }
    })

  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  statusId() {
    this.spinner.show();
    this.employeeApplicationService.getStatusList().subscribe(res => {
      this.statusList = res.data;
      this.spinner.hide();
      for (const value of this.statusList) {
        this.statusArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })

  }

  getdepartmentsList() {
    this.spinner.show();
    const dept = {
      companyId: "",
      companyIdSearchType: "Contains",
      departmentId: "",
      departmentIdSearchType: "Contains",
      departmentName: "",
      departmentNameSearchType: "Contains"
    }
    this.deparmentsService.getDepartment(this.departments, dept).subscribe((res: any) => {
      this.departmentList = res.data;
      this.spinner.hide();
      for (const value of this.departmentList) {
        this.DepartmentArray.push({
          label: value.departmentName,
          value: value.departmentName
        })
      }
    })

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  getEmployeeApplication() {
    this.spinner.show();
    const data = {
      "applicationDateFrom": "",
      "applicationDateFromSearchType": "Contains",
      "applicationDateTo": "",
      "applicationDateToSearchType": "Contains",
      "applicationId": "",
      "applyingPartyId": "",
      "emplPositionId": "",
      "employmentAppSourceTypeId": "",
      "referredByPartyId": "",
      "statusID": [
        ""
      ]
    }
    this.employementApplicationService.getEmpJobList(this.applicationSize, data).subscribe(res => {
      this.employmentApplication = res.data;
      this.spinner.hide();
    })
  }
  // advanceSearchContacts(isPopUp: boolean): void {
  //   this.spinner.show();
  //   this.employmentApplication = [];

  //   const req = {
  //     "applicationDateFrom": this.datePipe.transform(this.advanceSearch.applicationDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.applicationDateFrom, "yyyy-MM-dd hh:mm:ss") : '',
  //     "applicationDateFromSearchType": this.advanceSearch.applicationDateFromSearchType ? this.advanceSearch.applicationDateFromSearchType : '',
  //     "applicationDateTo": this.datePipe.transform(this.advanceSearch.applicationDateTo, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.applicationDateTo, "yyyy-MM-dd hh:mm:ss") : '',
  //     "applicationDateToSearchType": this.advanceSearch.applicationDateToSearchType ? this.advanceSearch.applicationDateToSearchType : '',
  //     "applicationId": this.advanceSearch.applicationId ? this.advanceSearch.applicationId : '',
  //     "applyingPartyId": this.advanceSearch.applyingPartyId ? this.advanceSearch.applyingPartyId : '',
  //     "emplPositionId": this.advanceSearch.emplPositionId ? this.advanceSearch.emplPositionId : '',
  //     "employmentAppSourceTypeId": this.advanceSearch.employmentAppSourceTypeId ? this.advanceSearch.employmentAppSourceTypeId : '',
  //     "referredByPartyId": this.advanceSearch.referredByPartyId ? this.advanceSearch.referredByPartyId : '',
  //     "statusID": this.arrayStatus


  //   }
  //   this.employementApplicationService.getEmpJobList(this.applicationSize, req).subscribe(res => {
  //     this.employmentApplication = res.data;
  //     this.spinner.hide();
  //   })

  // }
  // reset() {
  //   this.spinner.show();
  //   this.arrayStatus = []
  //   this.advanceSearch.applicationDateFrom = "";
  //   this.advanceSearch.applicationDateTo = "";
  //   this.advanceSearch.applicationId = "";
  //   this.advanceSearch.applyingPartyId = "";
  //   this.advanceSearch.emplPositionId = "";
  //   this.advanceSearch.employmentAppSourceTypeId = "";
  //   this.advanceSearch.referredByPartyId = "";
  //   this.advanceSearch.statusID = [""];
  //   const data = {
  //     "applicationDateFrom": "",
  //     "applicationDateFromSearchType": "Contains",
  //     "applicationDateTo": "",
  //     "applicationDateToSearchType": "Contains",
  //     "applicationId": "",
  //     "applyingPartyId": "",
  //     "emplPositionId": "",
  //     "employmentAppSourceTypeId": "",
  //     "referredByPartyId": "",
  //     "statusID": [
  //       ""
  //     ]
  //   }
  //   this.employementApplicationService.getEmpJobList(this.applicationSize, data).subscribe(res => {
  //     this.employmentApplication = res.data;
  //     this.spinner.hide();
  //   })

  // }
  onUpdate(id) {
    this.router.navigate(['./hr/create-employee-application'], { queryParams: { updateId: id } });
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  onDelete(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {

          "empTypeId": product.data.empTypeId,

        }
        this.accountsService.deleteApplicationEmployment(req).subscribe((res: any) => {
          this.findApplicationEmployment();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  // onDelete(id) {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'You will not be able to recover this data.!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, delete it!',
  //     cancelButtonText: 'No, keep it'
  //   }).then((result) => {
  //     if (result.value) {
  //       this.employeeApplicationService.deleteEmployeeApp(id).subscribe((res: any) => {
  //         if (res.success) {
  //           this.getEmployeeApplication();
  //         }
  //         else if (res.success == false) {
  //           this.toastr.error("Error");
  //         }
  //       })
  //       Swal.fire(
  //         'Deleted!',
  //         'Deleted Successfully.',
  //         'success'
  //       )
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire(
  //         'Cancelled',
  //         'Your data is safe :)',
  //         'error'
  //       )
  //     }
  //   })
  // }
  onChange(value) {
    this.arrayStatus = []
    this.arrayStatus.push(value);
  }
  requiredFieldCheck(tab:number,requiredFieldArr){
    let isValid : boolean;

    requiredFieldArr.forEach((field)=>{
        isValid = (this.createEmploymentForm.get(field).valid) ? true : false;
        if(!isValid){
          return;
        }
    })

    if(isValid){
      this.changeactiveCategory(tab);
    }
  }

  changeactiveCategory(tab: number) {
      this.activeCategory = tab;
  }


  create() {
    this.router.navigate(['./hr/create-employee-application']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
function dept(departments: { pageNo: number; pageSize: number; search: string; }, dept: any) {
  throw new Error('Function not implemented.');
}







