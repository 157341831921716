<div class="card own-account-table mt-2">
    <div class="card-header">
      
        <h5 class="text-primary" style="font-size: 100%;">Receipt(s) For Return Nbr {{this.returnNumber}}</h5>
        <h4 class="text-primary" style="font-size: 100%;">Receive Return Into {{ this.facilityId}}</h4>
    </div>
    <div class="card-body">
        <div class="card own-account-table mt-2">
            <p-table [value]="reasonid" [paginator]="true"
                [rows]="rows" scrollWidth="100%"
                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                [totalRecords]="total" [scrollable]="true"
                (sortFunction)="customSort($event)"
                [customSort]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="code">
                            <div class="checkbox-align">
                                <p-checkbox
                                    _ngcontent-egj-c329=""
                                    styleclass="custom-checkbox"
                                    binary="true"
                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                    <div
                                        class="custom-checkbox ui-chkbox ui-widget">
                                        <div
                                            class="ui-helper-hidden-accessible">
                                            <input
                                                type="checkbox"
                                                name="undefined"
                                                value="undefined">
                                        </div>
                                        <div role="checkbox"
                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                            <span
                                                class="ui-chkbox-icon ui-clickable"></span>
                                        </div>
                                    </div>
                                    <!---->
                                </p-checkbox>

                                Item Sequence ID <p-sortIcon
                                    field="code">
                                </p-sortIcon>
                            </div>
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="name">
                            <div> Product ID<p-sortIcon
                                    field="name">
                                </p-sortIcon>
                            </div>
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">Product Description
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                style="visibility:hidden;"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">Inventory Item
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                style="visibility:hidden;"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">
                            Initial Inventory Item Status
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                style="visibility:hidden;"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">
                            LOC
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                style="visibility:hidden;"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">
                            Quantity Received
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                style="visibility:hidden;"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">
                            Per Unit Price
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                style="visibility:hidden;"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                     
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">
                            Select all
                            <p-checkbox _ngcontent-egj-c329=""  
                                styleclass="custom-checkbox"
                                binary="true"
                                (click)="productValueSelectAll($event)"
                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                <div
                                    class="custom-checkbox ui-chkbox ui-widget">
                                    <div
                                        class="ui-helper-hidden-accessible">
                                        <input type="checkbox"
                                            name="undefined"
                                            value="undefined">
                                    </div>
                                    <div role="checkbox"
                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                        <span
                                            class="ui-chkbox-icon ui-clickable"></span>
                                    </div>
                                </div>
                                <!---->
                            </p-checkbox>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product
                    let-rowIndex="rowIndex">
                    <tr>
                        <td [ngStyle]="{'width':'190px'}">
                            <div class="checkbox-align">

                                <span
                                     class="account-button" >{{product.orderItemSeqId}}</span>
                            </div>
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            {{product.productId}}
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            {{product.description}}:
                           
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            <select  [ngModelOptions]="{standlone:true}"
                            id="col{{rowIndex+1}}">
        <option *ngFor="let mission of opportunityIdArray" value="mission.value">{{mission.label}} </option>
        </select>
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            	<select id="column{{rowIndex+1}}"
                                [(ngModel)]="product.expectedItemStatus"
                                [ngModelOptions]="{standalone: true}">
			<option *ngFor="let mission of status" [value]="mission.value" >{{mission.label}} </option>
			</select>
                          
                           
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            <input type="text"
                            id="facilityLocation{{rowIndex+1}}"
                            [(ngModel)]="product.facilityLocation"
                            [ngModelOptions]="{standalone: true}" />
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            <input type="text"
                            id="returnQuantity{{rowIndex+1}}"
                            [(ngModel)]="product.receivedQuantity"
                            [ngModelOptions]="{standalone: true}" />
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            <input type="text"
                            id="returnPrice{{rowIndex+1}}"
                            [(ngModel)]="product.returnPrice"
                            [ngModelOptions]="{standalone: true}" />
                        </td>
                       
                        <td [ngStyle]="{'width':'190px'}">
                            <p-checkbox _ngcontent-egj-c329=""
                                styleclass="custom-checkbox"
                                binary="true" (click)="submitData(product);"
                               
                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                <div
                                    class="custom-checkbox ui-chkbox ui-widget">
                                    <div
                                        class="ui-helper-hidden-accessible">
                                        <input type="checkbox"
                                            name="undefined"
                                            value="undefined">
                                    </div>
                                    <div role="checkbox"
                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                        <span
                                            class="ui-chkbox-icon ui-clickable"></span>
                                    </div>
                                </div>
                                <!---->
                            </p-checkbox>
                        </td>


                    </tr>
                </ng-template>
            </p-table>
            <!-- <p class="paginate_data">
                                                                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
            <p class="paginate_data">
                View per page </p>

        </div>

        <button class="btn btn-secondary cursor-pointer mt-4" (click)="completeSubmit()"
            >Submit</button>
    </div>
    <br>
    <div class="card-body" >
        <div class="card-header">
           
            <h5 class="text-primary" style="font-size: 100%;">Receipt(s) For Return Nbr {{this.returnNumber}}</h5>
        </div>
        <div class="card own-account-table mt-2">
            <p-table [value]="received" [paginator]="true"
                [rows]="rows" scrollWidth="100%"
                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                [totalRecords]="total" [scrollable]="true"
                (sortFunction)="customSort($event)"
                [customSort]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="code">
                            <div class="checkbox-align">
                                <p-checkbox
                                    _ngcontent-egj-c329=""
                                    styleclass="custom-checkbox"
                                    binary="true"
                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                    <div
                                        class="custom-checkbox ui-chkbox ui-widget">
                                        <div
                                            class="ui-helper-hidden-accessible">
                                            <input
                                                type="checkbox"
                                                name="undefined"
                                                value="undefined">
                                        </div>
                                        <div role="checkbox"
                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                            <span
                                                class="ui-chkbox-icon ui-clickable"></span>
                                        </div>
                                    </div>
                                    <!---->
                                </p-checkbox>

                               
Receipt <p-sortIcon
                                    field="code">
                                </p-sortIcon>
                            </div>
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="name">
                            <div> 	Date<p-sortIcon
                                    field="name">
                                </p-sortIcon>
                            </div>
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">	Return
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                style="visibility:hidden;"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">	Line
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                style="visibility:hidden;"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">
                            Product ID
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                style="visibility:hidden;"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">
                            Per Unit Price
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                style="visibility:hidden;"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                        <th [ngStyle]="{'width':'190px'}"
                            pSortableColumn="price">
                            Received
                            <input
                                id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                style="visibility:hidden;"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                role="textbox"
                                aria-disabled="false"
                                aria-readonly="false">
                        </th>
                     
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product
                    let-rowIndex="rowIndex">
                    <tr>
                        <td [ngStyle]="{'width':'190px'}">
                            <div class="checkbox-align">

                                <span
                                     class="account-button" >{{product.receiptId}}</span>
                            </div>
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            {{product.datetimeReceived | date:'yyyy-MM-dd hh:mm:ss'}}
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            {{product.returnId}}
                           
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            {{product.returnItemSeqId}}
                           
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            {{product.productId}}
                           
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            {{product.unitCost}}
                           
                        </td>
                        <td [ngStyle]="{'width':'190px'}">
                            {{product.quantityAccepted}}
                           
                        </td>
                       
                      


                    </tr>
                </ng-template>
            </p-table>
            <!-- <p class="paginate_data">
                                                                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
            <p class="paginate_data">
                View per page </p>

        </div>

    
    </div>
</div>
<ngx-spinner></ngx-spinner> 