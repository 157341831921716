<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Quote Summary ></span>
                <span class="color-black pl-1"> Send Email </span>
            </div>
            <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                <header class="w3-container w3-blue">
                  <div class="header-tabs">
                    <h4 class=" common-styling" style="margin-left: 7px;">
                        Send Email
                    </h4>
                 
                  </div>
                </header>
            <div class=" bg-white color-grey">
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="mailForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Send To <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control" placeholder="Send To"
                                                formControlName="sendTo">
                                            <small class="text-danger"
                                                *ngIf="!!mailForm.controls.sendTo.invalid && !!mailForm.controls.sendTo.touched">Send
                                                To is required</small>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Send CC</label>
                                            <input type="text" placeholder="Send CC" class="form-control"
                                                formControlName="sendCc">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Note</label>
                                            <textarea style="height: 100%!important;" class="form-control" placeholder="Note" rows="3" cols="30"
                                                formControlName="note" placeholder="Note"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: 80%;">
                                <button type="submit" (click)="createMail()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                                <button type="submit" (click)="cancelSubmit();"
                                    class="btn btn-degault cncl-btn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 