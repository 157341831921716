<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey titlePanels">Communications</span>
               <span class="" style="margin-left: 80%;">

                <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ml-2">Back</button>
            </span>
          
            </div>
            <div class=" bg-white color-grey">


                <div class="">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCardView">

                                    <div class="header-tabs">
                                        <h4 class=" common-styling" style="margin-left: 7px;">
                                            Find Communication By Order
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="create-new-leade mt-2">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <form class="w-100">
                                                        <div class="w3-card-4 classCardD">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div
                                                                            class="col-lg-3 form-group rightForm">
                                                                            <label
                                                                                for="exampleInputEmail1">Order
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2"
                                                                            style="display: none;">
                                                                            <p-dropdown filter="true"
                                                                                style="display: none;"
                                                                                [(ngModel)]="advanceSearchOrderBy.orderId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="orderId"
                                                                                [options]="opportunityIdArray"
                                                                                optionlabel="label">
                                                                            </p-dropdown>

                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                name="orderId"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                [(ngModel)]="advanceSearchOrderBy.orderId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="returnId"
                                                                                placeholder="Order ID">

                                                                        </div>
                                                                        <div
                                                                            class="col-lg-3 form-group rightFormA">
                                                                            <label
                                                                                for="exampleInputEmail1">Communication
                                                                                Event ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2"
                                                                            style="display: none;">
                                                                            <p-dropdown filter="true"
                                                                                [options]="opportunityIdArray"
                                                                                optionlabel="label"
                                                                                [(ngModel)]="advanceSearchOrderBy.communicationEventIdSearchType"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="communicationEventIdSearchType">

                                                                            </p-dropdown>

                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                class="form-control"
                                                                                name="eventId"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Communication Event ID"
                                                                                [(ngModel)]="advanceSearchOrderBy.communicationEventId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="communicationEventId">

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div
                                                                            class="col-lg-3 form-group rightForm">
                                                                            <label
                                                                                for="exampleInputEmail1">Party
                                                                                ID From</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown
                                                                                [options]="partyIdArray"
                                                                                optionlabel="label"
                                                                                filter="true"
                                                                                placeholder="Enter Party ID From"
                                                                                [(ngModel)]="advanceSearchOrderBy.partyIdFrom"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="partyIdFrom">

                                                                            </p-dropdown>

                                                                        </div>

                                                                        <div
                                                                            class="col-lg-3 form-group rightFormA">
                                                                            <label
                                                                                for="exampleInputEmail1">Party
                                                                                ID To</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown
                                                                                [options]="partyIdArray"
                                                                                optionlabel="label"
                                                                                filter="true"
                                                                                placeholder="Enter Party ID To"
                                                                                [(ngModel)]="advanceSearchOrderBy.partyIdTo"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="partyIdTo">

                                                                            </p-dropdown>

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div
                                                                            class="col-lg-3 form-group rightForm">
                                                                            <label
                                                                                for="exampleInputEmail1">Party
                                                                                ID (any role)</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown
                                                                                [options]="partyIdArray"
                                                                                optionlabel="label"
                                                                                filter="true"
                                                                                placeholder="Enter Party ID"
                                                                                [(ngModel)]="advanceSearchOrderBy.partyId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="partyId">

                                                                            </p-dropdown>

                                                                        </div>


                                                                        <div
                                                                            class="col-lg-3 form-group rightFormA">
                                                                            <label
                                                                                for="exampleInputEmail1">Comm.
                                                                                Type Id</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown
                                                                                [options]="comtypeIdArray"
                                                                                optionlabel="label"
                                                                                filter="true"
                                                                                placeholder="Enter Comm Type Id"
                                                                                [(ngModel)]="advanceSearchOrderBy.communicationEventTypeId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="communicationEventTypeId">

                                                                            </p-dropdown>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div
                                                                            class="col-lg-3 form-group rightForm">
                                                                            <label
                                                                                for="exampleInputEmail1">Status
                                                                                Id</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown
                                                                                [options]="stausIdArray"
                                                                                optionlabel="label"
                                                                                filter="true"
                                                                                placeholder="Enter Status Id"
                                                                                [(ngModel)]="advanceSearchOrderBy.statusId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="statusId">

                                                                            </p-dropdown>

                                                                        </div>



                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="col-lg-12 col-12 main-submit-button"
                                                                style="margin-left: -24%; margin-bottom: -3px;">
                                                                <button _ngcontent-cta-c128=""
                                                                    type="submit"
                                                                    (click)="advanceSearchOrder(false)"
                                                                    class="btn btn-secondary submit-btn ml-3"
                                                                    style="margin-bottom:20px ;">Find</button>&nbsp;
                                                                <button _ngcontent-cta-c128=""
                                                                    type="submit"
                                                                    class="btn btn-danger "
                                                                    (click)="resetAdvanceReset()">Reset</button>




                                                            </div>
                                                        </div>

                                                        <br>

                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w3-card-4  classCard mt-3">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs"
                                            style="background:#F5F7FA!important ; margin-top: 7px;">
                                            <h4 class=" common-styling h4Margin"
                                                style="margin-left: 7px;">
                                                Search Result
                                            </h4>

                                        </div>
                                    </header>

                                    <div
                                        class="all-users-infomation font-13 font-medium border-top-grey own-user-select allTableUser">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">


                                                <div class="card own-account-table">
                                                    <p-table [value]="commByOrder" [paginator]="true"
                                                        [rows]="rows" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)"
                                                        [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'180px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align"
                                                                        style="color: white;">
                                                                        <p-checkbox
                                                                            _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>


                                                                        Communication Event ID
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;"> Subject
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="quantity">
                                                                    <div style="color: white;">Type
                                                                        <p-sortIcon field="quantity">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;">Status Id
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;">Party Id
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;">Role Type
                                                                        Id
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;">Role
                                                                        Status Id
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;">Created
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;">Sent
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;">Order Id
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>

                                                                    </div>
                                                                </th>


                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'180px'}"
                                                                  >
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox
                                                                            _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        {{product.communicationEventId}}
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{product.subject}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{product.communicationEventTypeDesc}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{product.statusId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{product.partyIdDesc}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{product.roleTypeIdTo}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">

                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{product.entryDate|date:'yyyy-MM-dd hh:mm:ss'}}

                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{product.datetimeStarted | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">

                                                                    {{product.orderId}}
                                                                </td>



                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>