import { Component, OnInit } from '@angular/core';
import {AddSkillService} from "./add-skill.service";
import {AddSkillsService} from './add-skill-service';
import { NgxSpinnerService } from "ngx-spinner";
import {ActivatedRoute,Router} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { SkillService } from '../skills/skill.service';


@Component({
  selector: 'app-add-skills',
  templateUrl: './add-skills.component.html',
  styleUrls: ['./add-skills.component.css']
})
export class AddSkillsComponent implements OnInit {
  getSkillLists: any;
  activeCategory = 2;
  skillArray: any =[];
  personId: any;
  partyIdArray: any = [];
  updateId: any;
  show= false;
  total=0;
  rowNumber=0;
  pageNo:number = 1;
  pageSize= 100;
  rows = 50;
  skillList: any;
  Skillid: any;
  rate: any;
  skill: any;
  year: any;

  constructor(readonly addSkillService:AddSkillService,
    readonly spinner:NgxSpinnerService,
    readonly activatedRoute:ActivatedRoute,
    public addSkillsService:AddSkillsService,
    readonly toastr:ToastrService,
    readonly router:Router,
    readonly skillService:SkillService,
    ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.addSkillsService.add_skill_form = null;
    this.addSkillsService.addSkillForm(this.addSkillsService.add_skill_form);
    this.activatedRoute.queryParams.subscribe(params=> [
      this.updateId = params["updateId"],
      this.Skillid=params["skid"],
      this.rate=params["rate"],
      this.skill=params["skill"],
      this.year=params["year"]
    ])
    if(this.updateId) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.addSkillsService.add_skill_form;
      formValue.patchValue({
        partyId:this.updateId,
        skillTypeId: this.Skillid,
        yearsExperience: this.year,
        rating: this.rate,
        skillLevel: this.skill,  
      })
      }, 3000); 
    }
    else {
      this.show = false;
    }
    this.getPartyId();
    this.getSkillList(); 
  }
  
  getSkillList() {
    this.spinner.show();
    this.addSkillService.getSkillTypeList().subscribe(res=> {
      this.getSkillLists = res.data;
      this.spinner.hide();
      for(const value of this.getSkillLists) {
        this.skillArray.push({
          label:value.description,
          value:value.skillTypeId
        })
      }
     
    })
   
  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }

  onSubmit() {
    this.spinner.show();
    const formData = {...this.addSkillsService.add_skill_form.value};
    this.addSkillService.createSkills(formData).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success("Skill Subbmitted Successfully");
        this.router.navigate(['/hr/skills'])
      }

      else if(res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    },(err) => {
      for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }
        this.spinner.hide();
   })

  }
back(){
  this.router.navigate(['/hr/skills'])
}
  updateSkills() {
    this.spinner.show();
    const updateFormData ={...this.addSkillsService.add_skill_form.value};
    this.addSkillService.updateSkills(updateFormData,this.updateId).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success("Skill Updated Successfully");
        this.router.navigate(['/hr/skills'])
      }

      else if(res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    },(err) => {
      for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }
        this.spinner.hide();
   })

  
}
ngOnDestroy(): void {
  this.spinner.hide();
    }
}
