<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">BIRT</span>

                <button type="submit" class="btn btn-secondary"(click)="toBack()">Home</button>


            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                Manage Reports</a></li>
            
                            <li [ngClass]="activeCategory==2?'active':''"  (click)="changeactiveCategory(2)"><a>
                                Use a Report</a></li>
                            
                            
                           

                        </ul>
                        
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">

                                        <div *ngIf="activeCategory==1">
                                             <div class="w3-card-4 classCardB" style="width: 100%;">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                        <h4 class=" common-styling h4M">
                                                            Reports management
                                                            <button type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" 
                                                            data-target="#generateReportPopup">Generate Report</button>


                                                        </h4>
                                                        
                                                        </div>
                                                    </header>
                                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select" style="display: block!important;">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="FindListFlexibleReport" [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input
                                                                                                        type="checkbox" name="undefined"
                                                                                                        value="undefined"></div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                        </p-checkbox>
                                                                                        
                                                                                        Content Name <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name" [ngStyle]="{'width':'170px'}"><div style="color: white;">Master Content Name <p-sortIcon field="name">
                                                                                    </p-sortIcon></div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="category" [ngStyle]="{'width':'170px'}"><div style="color: white;"> Status ID <p-sortIcon
                                                                                        field="category">
                                                                                    </p-sortIcon></div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Description</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                
                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">	Manage Link</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                
                                                                                
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input
                                                                                                        type="checkbox" name="undefined"
                                                                                                        value="undefined"></div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button" 
                                                                                          >{{product.ListFlexibleReport.contentName}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.dataMasterDetails[0].contentName}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.ListFlexibleReport.statusId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.ListFlexibleReport.description}}</td>
                                                                                
                                                                                <td [ngStyle]="{'width':'214px'}">
                                                                                    <button *ngIf="product.ListFlexibleReport.statusId=='CTNT_PUBLISHED'" type="submit" class="btn btn-secondary submit-btn" >Open</button>
                                                                                    
                                                                                    <button *ngIf="product.ListFlexibleReport.statusId=='CTNT_IN_PROGRESS'" type="submit" class="btn btn-secondary submit-btn"  (click)="UpdateFlexibleReport(product)">Publish</button>
                                                                                    <button *ngIf="product.ListFlexibleReport.statusId=='CTNT_IN_PROGRESS'" type="submit" class="btn btn-secondary submit-btn"  (click)="toupdateReport(product)">Edit</button>

                                                                                    <button  *ngIf="product.ListFlexibleReport.statusId=='CTNT_IN_PROGRESS'" type="submit" class="btn btn-secondary submit-btn" (click)="DeleteFlexibleReport(product)">Delete</button>
        
            
                                                                                </td>
        
                                                                                
                                                                            </tr>
                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                        </div>

                                        <div *ngIf="activeCategory==2">
                                            <div class="w3-card-4 classCardView" >
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        Report Choice 
                                                    <!-- <button type="button" class="btn btn-outline-secondary active" style="margin-left:77% !important ;"
                                                        data-target="#createNewPathAlisPopup" data-toggle="modal">Create New Path Alias</button>
                                                    -->
                                                </div>

                                                <form [formGroup]="reportChoiceForm">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-3 form-group classInput" >
                                                                        <label for="exampleInputEmail1">
                                                                            Report choice</label>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-2" >
                                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                        formControlName="reportchoice" [options]="reportChoiceArray" 
                                                                        placeholder="Select Report choice" >

                                                                        </p-dropdown>
                                                                        
                                                                    </div>
                                                                    </div>
                                                            </div>
                                                        
                                                            
                                                    </div>
                                                </form>
                                                <div class="col-lg-10 main-submit-button" style="margin-left: -50% !important;">
                                                    <button type="submit"  class="btn btn-secondary submit-btn" 
                                                    data-target="#previewPopup" data-toggle="modal" (click)="referTurnOn()" >Send</button>&nbsp;
                                                
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                                        

                </div>
            </div>
        </div>
    </div> 
</div>
            
 <div class="modal fade" id="generateReportPopup" tabindex="-1" role="dialog" aria-labelledby="generateReportPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Generate Report</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Generate Report</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="generateReportForm">

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">
                                                            Choose report Topic		</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="reportTopic" [options]="reportTopicArray"
                                                        placeholder="Choose report Topic" >

                                                        </p-dropdown>
                                                    </div>
                                                  
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Report Name	<span style="color: red;">*</span>		
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Report Name"
                                                        formControlName="reportName">
                                                    
                                                    </div>
                            
                                                    
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Description<span style="color: red;">*</span></label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Description"
                                                        formControlName="description">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Data Resource Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="dataResourceId" [options]="DataResourceIdArray"
                                                        placeholder="Choose Data Resource Id" ></p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-10 main-submit-button" style="margin-left: 9% !important;">
                                                <button type="submit"  class="btn btn-secondary submit-btn" 
                                                     (click)="flexible()">Send</button>&nbsp;
                                                
                                            </div>
                                           
                                        </div>
                                    </form>                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 

 <!-- <div  class="modal fade" id="previewPopup" tabindex="-1" role="dialog" aria-labelledby="previewPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Preview</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Preview</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="displayFlexibleReportSearchForm">

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">
                                                            Example ID	</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Example ID"
                                                        formControlName="exampleID">
                                                    </div>
                                                  
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Example Type ID		
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Example Type ID	"
                                                        formControlName="exampleTypeID">
                                                    
                                                    </div>
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Status ID	</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Status ID"
                                                        formControlName="statusID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Example Name</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Example Name"
                                                            formControlName="exampleName">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Description</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Description"
                                                        formControlName="description">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Long Description	</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Long Description"
                                                            formControlName="longDescription">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Comments	</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Comments"
                                                        formControlName="comments">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Example Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <input type="date"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" 
                                                            formControlName="exampleDate">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Example Size</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Example Size"
                                                        formControlName="exampleSize">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit"></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Example Size"
                                                            formControlName="exampleSize">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Another Date</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="date"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Another Date"
                                                        formControlName="anotherDate">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Another Text	</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Another Text"
                                                            formControlName="anotherText">

                                                    </div>

                                                </div>
                                            </div>

                                            <div>
                                                <hr style="width:98% !important; margin-left: 1%;">
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                   <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Birt Export Format</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label" 
                                                            formControlName="birtExportFormat" [options]="birtexportformatArray"
                                                            placeholder="Select Birt Export Format" >

                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                            </div>

                                            <div class="col-lg-10 main-submit-button" style="margin-left: 9% !important;">
                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="generatePdf()"
                                                     >Send</button>&nbsp;
                                                
                                            </div>
                                        </div>
                                    </form>                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  -->

 <div  class="modal fade" id="previewPopup" tabindex="-1" role="dialog" aria-labelledby="previewPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Preview</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Preview</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="this.turnOn" class="w-100" [formGroup]="previewForm">

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">
                                                            From Date</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="date"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" 
                                                        formControlName="fromDate">
                                                    </div>
                                                  
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Through Date	
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="date"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" 
                                                        formControlName="throughDate">
                                                    
                                                    </div>
                            
                                                    
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Product Category ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Product Category ID"
                                                        formControlName="productCategoryID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Product Store ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Product Store ID"
                                                            formControlName="productStoreID">

                                

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                   <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Birt Export Format</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label" 
                                                            formControlName="birtexportformat" 
                                                            placeholder="Select Birt Export Format" [options]="birtexportformatArray">

                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                            </div>

                                            <div class="col-lg-10 main-submit-button" style="margin-left: 9% !important;">
                                                <button type="submit"  class="btn btn-secondary submit-btn" 
                                                  (click)="generatePdfShort()">Send</button>&nbsp;
                                                
                                            </div>
                                        </div>
                                    </form>  

                                    <form *ngIf="!this.turnOn" class="w-100" [formGroup]="displayFlexibleReportSearchForm">

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">
                                                            Example ID	</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Example ID"
                                                        formControlName="exampleID">
                                                    </div>
                                                  
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Example Type ID		
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Example Type ID	"
                                                        formControlName="exampleTypeID">
                                                    
                                                    </div>
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Status ID	</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Status ID"
                                                        formControlName="statusID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Example Name</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Example Name"
                                                            formControlName="exampleName">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Description</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Description"
                                                        formControlName="description">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Long Description	</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Long Description"
                                                            formControlName="longDescription">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Comments	</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Comments"
                                                        formControlName="comments">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Example Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <input type="date"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" 
                                                            formControlName="exampleDate">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Example Size</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Example Size"
                                                        formControlName="exampleSize">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Another Date</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="date"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Another Date"
                                                        formControlName="anotherDate">

                                                    </div>

                                                   

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                  <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Another Text	</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Another Text"
                                                            formControlName="anotherText">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA" >
                                                        <label for="accountLimit">Birt Export Format</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                            <p-dropdown filter="true" optionlabel="label" 
                                                            formControlName="birtExportFormat" [options]="birtexportformatArray"
                                                            placeholder="Select Birt Export Format" >

                                                            </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div>
                                                <hr style="width:98% !important; margin-left: 1%;">
                                            </div>

                                            <div class="col-lg-10 main-submit-button" style="margin-left: 9% !important;">
                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="generatePdf()"
                                                     >Send</button>&nbsp;
                                                
                                            </div>
                                        </div>
                                    </form>  
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 <ngx-spinner></ngx-spinner> 

