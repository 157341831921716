import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
    import { Location } from '@angular/common';
    import { ToastrService } from 'ngx-toastr';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-exception-week',
  templateUrl: './create-exception-week.component.html',
  styleUrls: ['./create-exception-week.component.css']
})
export class CreateExceptionWeekComponent implements OnInit {
  createDay:FormGroup;
  activeCategory=2;
  calendarId: any;
  weekList: any;
  weekListArray: any[]=[];
  exceptionday: string;
  exceptionDateStart: any;
  description: any;
  calendarWeekId: any;
  show: boolean;

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,  private _location: Location,
    readonly productionService:ProductionService,
    readonly spinner: NgxSpinnerService,
  
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createDay = this._FormBuilder.group({
        exceptionday: [''],
        CalendarWeekID: [''],
        Description:['']

       
      });
    
  }
 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.calendarId = params["calendarId"];
      this.exceptionDateStart = params["exceptionDateStart"];
      this.description = params["description"];
      this.calendarWeekId = params["calendarWeekId"];
    });
  
    this.getProdCalenderWeekId();
    if(this.exceptionDateStart) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.createDay;
      formValue.patchValue({
        exceptionday: this.datePipe.transform(this.exceptionDateStart,"yyyy-MM-dd"),
        CalendarWeekID: this.calendarWeekId,
        Description:this.description
      })
      }, 3000); }
    else {
      this.show = false;
    }
  }
  getProdCalenderWeekId() {
    this.spinner.show();
   
    this.productionService.getProdCalenderWeekId().subscribe(res => {
      this.weekList = res.data;
    
      this.spinner.hide();
      for (const value of this.weekList) {
        this.weekListArray.push({
          label: value.description,
          value: value.calendarWeekId
        })
      }
    
    })
    
  }
  homeButton(){
    this._Router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  onSubmit() {
    this.spinner.show();
    const completeDate = this.createDay.get('exceptionday').value;
    this.exceptionday = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "calendarWeekId":this.createDay.value.CalendarWeekID,
      "description": this.createDay.value.Description,
      "exceptionDateStart": this.exceptionday
    }

    this.productionService.postExceptionWeek(this.calendarId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-calender"],{ queryParams: { calendarId : this.calendarId}})
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.debugMessage);
        }
    });

  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "calendarWeekId":this.createDay.value.CalendarWeekID,
      "description": this.createDay.value.Description,
      "exceptionDateStart": this.exceptionDateStart
    }
    this.productionService.updateExceptionWeek(this.calendarId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(["production/detail-calender"],{ queryParams: { calendarId : this.calendarId}})
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  
  // cancelSubmit(){
  //   this._Router.navigate(["production/detail-calender"],{ queryParams: { calendarId : this.calendarId}})
  // }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}


