import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { PayablePaymentService } from '../payables-payment.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
@Component({
    selector: 'app-summary-payable-payment',
    templateUrl: './summary-payable-payment.component.html',
    styleUrls: ['./summary-payable-payment.component.css']
})
export class SummaryPayablePaymentComponent implements OnInit {
    paymentStatus: any[];
    statusId: string;
    paymentId: string;
    total=0;
    activeCategory=1;
    @ViewChild('closebutton') closebutton;
    array = [
        {
            label: 'Your Company Name',
            value: 'Company'
        },
        {
            label: 'Widget Business Unit',
            value: 'widget_div'
        },
    ];
    partyIds: any[];
    paymentTypeList: any[];
    payablePaymentForm: FormGroup;
    editMode: boolean;
 
    paymentMethods: any[];
    GIAccounts: any[];
    currencyUomID: any[];
    paymentValue: any;
    rowNumber=0;
    pageSize = 10;
    rows = 50;
    faEdit = faEdit;
    faTrash = faTrash;
    financialAccountTransaction: any;
    paymentApplicationList: any[];
    transactions: any[];
    constructor(
        private _location: Location,
        readonly _PayablePaymentService: PayablePaymentService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly  _Router: Router,
        readonly spinner: NgxSpinnerService,
        readonly _ToastrService: ToastrService,
        readonly _GlobalResourceService: GlobalResourceService,
     
        readonly _FormBuilder: FormBuilder,
      
    ) {
        this.paymentId = '';
        this.paymentStatus = [];
        this.statusId = '';
        this.paymentApplicationList = [];
        this.transactions = [];
        this.partyIds = [];
        this.paymentTypeList = [];
        this.payablePaymentForm = this._FormBuilder.group({
            amount: ['', [Validators.required]],
            comments: [''],
            overrideGlAccountId: [''],
            partyIdFrom: ['Company'],
            partyIdTo: ['', [Validators.required]],
            paymentMethodId: [''],
            paymentRefNum: [''],
            paymentTypeId: [''],
            actualCurrencyAmount: [''],
            actualCurrencyUomId: [''],
            currencyUomId: [''],
            effectiveDate: [''],
            finAccountTransId: [''],
            statusId: ['']
        });
        this.paymentMethods = [];
        this.GIAccounts = [];
        this.paymentId = '';
        this.editMode = false;
        this.currencyUomID = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.paymentId = this._ActivatedRoute.snapshot.queryParams.paymentId;
        this.getPaymentById();
        this.getStatus();
        this.getPartyIds();
        this.getPaymentType();
        this.getGIAccountsAndPaymentMethodIds();
        this.getCurrency();
    }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/financial/main']);
  }

    edit(){
        if (this.paymentId) {
            this.editMode = true;
            this._PayablePaymentService.getPaymentById(this.paymentId)
                .then(data => {
                    if (data.data.length) {
                        this.paymentValue = data.data[0];
                        this.payablePaymentForm.patchValue({
                            ...data.data[0], ...{
                                effectiveDate: new Date(data.data[0].effectiveDate),
                                finAccountTransId: data.data[0].financialAccountTransaction.finAccountTransId?data.data[0].financialAccountTransaction.finAccountTransId:''
                            }
                        });
                    }
                });
        }
    }
    getCurrency(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencyUomID = data.data.currencyList.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
           
    }
    getGIAccountsAndPaymentMethodIds(): void {
        this.spinner.show();
        this._PayablePaymentService.getGIAccountAndPaymentMethod()
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.paymentMethods = data.data.paymentMethodId.map(value => {
                        return {
                            label: value.description,
                            value: value.paymentMethodId
                        };
                    });
                    this.GIAccounts = data.data.glAccounts.map(value => {
                        return {
                            label: value.glAccountId,
                            value: value.glAccountId
                        };
                    });
                }
            });
           
    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
          
    }
    getPaymentType(): void {
        this.spinner.show();
        this._PayablePaymentService.getPaymentType()
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.paymentTypeList = data.data.map(value => {
                        return {
                            label: value.description,
                            value: value.paymentTypeId
                        };
                    });
                }
            });
           
    }
    reset(): void {
        this.payablePaymentForm.reset();
        this._Router.navigate(['/financial/payable/payment-payables']);
    }
    update(): void {
        this.spinner.show();
        if (this.payablePaymentForm.valid) {
            const {
                actualCurrencyAmount,actualCurrencyUomId,amount,comments,currencyUomId,effectiveDate,finAccountTransId,
                overrideGlAccountId,partyIdFrom,partyIdTo,paymentMethodId,paymentRefNum,paymentTypeId,
                statusId
            } = this.paymentValue;
            this._PayablePaymentService.updatePayment(this.paymentId, {
                ...{
                    actualCurrencyAmount,actualCurrencyUomId,amount,comments,currencyUomId,effectiveDate,
                    finAccountTransId,overrideGlAccountId,partyIdFrom,partyIdTo,paymentMethodId,paymentRefNum,
                    paymentTypeId,statusId
                }, ...this.payablePaymentForm.value, ...{
                    effectiveDate: moment(this.payablePaymentForm.value.effectiveDate).format('YYYY-MM-DD HH:mm:ss')
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.payablePaymentForm.reset();
                        this.closebutton.nativeElement.click();
                        this.getPaymentById();
                    }
                });
        }
      
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            if (this.payablePaymentForm.valid) {
                const {
                    amount, comments,
                    overrideGlAccountId, partyIdFrom,
                    partyIdTo, paymentMethodId,
                    paymentRefNum, paymentTypeId
                } = this.payablePaymentForm.value;
                this._PayablePaymentService.createOutgoingPayment({
                    amount, comments,
                    overrideGlAccountId, partyIdFrom,
                    partyIdTo, paymentMethodId,
                    paymentRefNum, paymentTypeId
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.spinner.hide();
                            this.payablePaymentForm.reset();
                            this._Router.navigate(['/financial/payable/payment-payables']);
                        }
                    });

            }
        }
       
    }
    cancelSubmit(){
        this._location.back();
      }
    getPaymentById(): void {
        this.spinner.show();
        this._PayablePaymentService.getPaymentById(this.paymentId)
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.paymentValue = data.data[0];
                    this.financialAccountTransaction = data.data[0].financialAccountTransaction;
                    this.paymentApplicationList = data.data[0].paymentApplicationList;
                    this.transactions = data.data[0].transactionList;
                }
            });
          
    }
    setStatus(event: any): void {
        this.spinner.show();
        this._PayablePaymentService.setPaymentStatus(this.paymentId, this.statusId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this._ToastrService.success('Status Updated');
                    this.getPaymentById();
                }
            });
          
    }
    setStatusToVoid(): void {
        this.spinner.show();
        this._PayablePaymentService.setPaymentStatusToVoid(this.paymentId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this._ToastrService.success('Status Updated');
                    this.getPaymentById();
                }
            });
           
    }
    getStatus(): void {
        this.spinner.show();
        this._PayablePaymentService.getStatus()
            .then(data => {
                this.spinner.hide();
                this.paymentStatus = data.data.status.map(value => {
                    return {
                        label: value.description,
                        value: value.statusId
                    };
                });
            });
           
    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    changeactiveCategoryFunction(tab: number){
        this.activeCategory =tab;
      }
      ngOnDestroy(): void {
        this.spinner.hide();
          }
}