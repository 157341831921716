import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-create-acc-review',
  templateUrl: './create-acc-review.component.html',
  styleUrls: ['./create-acc-review.component.css']
})
export class CreateAccReviewComponent implements OnInit {
  createbudget:FormGroup;
  activeCategory=2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  varianceReasonId: any;
  varianceReasonIdArray: any[]=[];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  budgetId: any;
  personId: any;
  partyIdArray: any[]=[];
  ReviewResultTypes: any;
  ReviewResultTypesArray: any[]=[];
  reviewDate: string;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,
  
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createbudget = this._FormBuilder.group({
        partyId: [''],
        reviewReview:[''],
        reviewDate:['']
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.budgetId = params["budgetId"];
     
    });
    this.getPartyId();
    this.budgetReviewResultTypes();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  budgetReviewResultTypes() {
    this.spinner.show();
    this.accountsService.budgetReviewResultTypes().subscribe(res => {
      this.ReviewResultTypes = res.data;
      this.spinner.hide();
      for (const value of this.ReviewResultTypes) {
        this.ReviewResultTypesArray.push({
          label: value.description,
          value: value.budgetReviewResultTypeId
        })
      }
    })
    
  }
  onSubmit() {
    this.spinner.show();
    const reviewDate=this.createbudget.get('reviewDate').value;
    this.reviewDate=this.datePipe.transform(reviewDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "budgetId": this.budgetId,
      "budgetReviewResultTypeId": this.createbudget.value.reviewReview,
      "partyId":this.createbudget.value.partyId,
      "reviewDate":this.reviewDate
    }
    this.accountsService.postbudgetReview(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-budget-summary'],{ queryParams: { budgetId:this.budgetId}});
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-budget-summary'],{ queryParams: { budgetId:this.budgetId}});
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
