<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Shipping of {{this.shipmentMethodTypeId}} </span>
                
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <header class="w3-container w3-blue">
                            <div class="header-tabs" style="margin-left: -15px;
                        margin-right: -14px;">
                                <h4 class=" common-styling h4Margin">
                                    Edit Shipment Time Estimates
                                </h4>
                                <span *ngIf="!this.Hidebtn" class="edit-buttons hCreate">

                                    <a data-toggle="modal" data-target="#exampleCenter">Update</a>
                                </span>
                            </div>
                        </header>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div class="panel-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                            <div class="row">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Shipment Method Type
                                                                            ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12"
                                                                            *ngIf="this.shipmentTimeList">{{this.shipmentTimeList.shipmentMethodTypeId}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Party ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12"
                                                                            *ngIf="this.shipmentTimeList">{{this.shipmentTimeList.partyId}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Role Type ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12"
                                                                            *ngIf="this.shipmentTimeList">{{this.shipmentTimeList.roleTypeId}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Geo Id To</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12"
                                                                            *ngIf="this.shipmentTimeList">{{this.shipmentTimeList.geoIdTo}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Geo Id From</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12"
                                                                            *ngIf="this.shipmentTimeList">{{this.shipmentTimeList.geoIdFrom}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">From Date</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12"
                                                                            *ngIf="this.shipmentTimeList">{{this.shipmentTimeList.fromDate}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Through Date</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12"
                                                                            *ngIf="this.shipmentTimeList">{{this.shipmentTimeList.thruDate
                                                                            | date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Lead Time</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12"
                                                                            *ngIf="this.shipmentTimeList">{{this.shipmentTimeList.leadTime}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Lead Time Uom ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12"
                                                                            *ngIf="this.shipmentTimeList">{{this.shipmentTimeList.leadTimeUomId}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleCenter" tabindex="-1" role="dialog" aria-labelledby="exampleCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Shipment Time Estimates</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategorytab==2?'active':''"><a>
                                    Edit Shipment Time Estimates</a></li>
                        </ul>
                    </div>
                    <div>
                        <div class="row">
                            <form class="w-100" [formGroup]="ShipmentEstimatesForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Shipment Method Type ID </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                   disabled aria-describedby="emailHelp" formControlName="ShipmentMethodTypeID"
                                                    placeholder="Enter Shipment Method Type ID">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Party ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                disabled   aria-describedby="emailHelp" formControlName="PartyID"
                                                    placeholder="Enter Party ID">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Role Type ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                disabled  aria-describedby="emailHelp" formControlName="RoleTypeID"
                                                    placeholder="Enter Role Type ID">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Geo Id To</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="" filter="true" placeholder="Choose Geo Id To"
                                                    [options]="GeoIdArray" formControlName="GeoIdTo"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Geo Id From</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="" filter="true" placeholder="Choose Geo Id From"
                                                    [options]="GeoIdArray" formControlName="GeoIdFrom"></p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    From Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" formControlName="FromDate"
                                                    placeholder="Enter From Date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Through Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" formControlName="ThroughDate"
                                                    placeholder="Enter Through Date">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Lead Time</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" formControlName="LeadTime"
                                                    placeholder="Enter Lead Time">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Lead Time Uom ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" [options]="LeadTimeUomIdArray"
                                                    placeholder="Choose Lead Time Uom ID"
                                                    formControlName="LeadTimeUomID"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left:-35%;"><button
                                        _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn"
                                        (click)="updateShipmentTimeEstimate()">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div><br><br>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>