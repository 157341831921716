<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Facilities </span>
                <span class="color-black pl-1"> > Facility Location</span>
                <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger" style="margin-left: 73%;"
                (click)="onCancel()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians" style=" margin-top: 0px!important;background: #cce0e95e!important;">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Facility Location</a></li>
                     
                    </ul>
                </div>
                <div class="port-header-contact create-lead font-medium mb-0">
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="locationForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput" style="
                                            text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -17%;">
                                                <label for="exampleInputEmail1">Type</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <ng-select [items]="locationTypes" bindLabel="description"
                                                bindValue="enumId" class="ng-select-width"
                                                formControlName="locationTypeEnumId" placeholder="Type">
                                            </ng-select>
                                            </div>
                                            <div class="col-lg-3 form-group classInput" style="
                                            text-align: right!important;
                                            padding-right: 17px!important;
                                           ">
                                                <label for="exampleInputEmail1">Area</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="areaId" aria-describedby="emailHelp"
                                                placeholder="Area">
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput" style="
                                            text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -17%;">
                                                <label for="exampleInputEmail1">Aisle </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="aisleId" aria-describedby="emailHelp"
                                                placeholder="Aisle">
                                            </div>
                                            <div class="col-lg-3 form-group classInput" style="
                                            text-align: right!important;
                                            padding-right: 17px!important;
                                           ">
                                                <label for="exampleInputEmail1">Section</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="sectionId" aria-describedby="emailHelp"
                                                placeholder="Section">
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput" style="
                                            text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -17%;">
                                                <label for="exampleInputEmail1">Level</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="levelId" aria-describedby="emailHelp"
                                                placeholder="Level">
                                            </div>
                                            <div class="col-lg-3 form-group classInput" style="
                                            text-align: right!important;
                                            padding-right: 17px!important;
                                          ">
                                                <label for="exampleInputEmail1">Position</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="positionId" aria-describedby="emailHelp"
                                                placeholder="Position">
                                            </div>
                                        </div>
                                    </div>
                                 
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="
                                margin-left: -34%;">
                                    <button _ngcontent-cta-c128="" type="submit"
                                        class="btn btn-secondary submit-btn update-button"
                                        (click)="submit()">{{editMode?'Update':'Create'}}</button>
                                  
                                </div>
                            </form>

                            <ng-container *ngIf="editMode">
                                <div class="mt-5 w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                        data-toggle="collapse" href="#collapsetwo">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div class="panel panel-default">
                                                    <div class="panel-heading" role="tab" id="headingOne">
                                                        <h4 class="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                                data-parent="#accordion" href="#collapseOne"
                                                                aria-expanded="true" aria-controls="collapseOne">
                                                                Location Products
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseOne" class="panel-collapse collapse in"
                                                        role="tabpanel" aria-labelledby="headingOne">
                                                        <div class="panel-body">
                                                            <form [formGroup]="productForm">
                                                                <div class="row">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInput" style="
                                                                            text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -8%;">>
                                                                                <label for="product">Product Id</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                [options]="productIds"
                                                                                formControlName="productId"
                                                                                placeholder="Product Id">
                                                                            </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput" style="
                                                                            text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                           ">
                                                                                <label for="minimumStock">Minimum
                                                                                    Stock</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                formControlName="minimumStock"
                                                                                placeholder="Minimum Stock">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInput" style="
                                                                            text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -8%;">>
                                                                                <label for="moveQuantity">Move
                                                                                    Quantity</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control"
                                                                                formControlName="moveQuantity"
                                                                                placeholder="Move Quantity">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                  
                                                                    <div class="col-lg-12 col-12 main-submit-button">
                                                                        <button (click)="submitProduct()"
                                                                            class="btn btn-primary cursor-pointer">{{editTableProduct ? 'Update' : 'Create'}}</button>
                                                                        <button (click)="productCancel()"
                                                                            class="btn btn-danger cursor-pointer ml-2">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <div class="card own-account-table mt-2">
                                                                <p-table [value]="productList" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Product Id <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="name">Minimum Stock
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category"> Move
                                                                                Quantity
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="quantity">
                                                                                Action <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    {{product.productId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.minimumStock}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.moveQuantity}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <fa-icon [icon]="faEdit"
                                                                                    class="text-primary"
                                                                                    (click)="makeEditable(product.productId, product.minimumStock, product.moveQuantity )">
                                                                                </fa-icon>
                                                                                <fa-icon [icon]="faTrash" class="ml-2"
                                                                                    class="text-danger"
                                                                                    (click)="deleteProduct(product.productId)">
                                                                                </fa-icon>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <!-- <p class="paginate_data">
                                                                                                                                                                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 