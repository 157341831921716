import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.css']
})
export class CustomFieldsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;

  customFieldTab:any;

  editCustomFieldForm:FormGroup
  rank:string;
  label:string;
  pickList:string;
  type:string;
  length:string;
  customFieldForm:FormGroup;
  customField:string;
  addCustomFieldForm:FormGroup;
  size:string;




  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 

  {
    this.editCustomFieldForm=this._FormBuilder.group({
      rank:"",
      label:"",
      pickList:"",
      type:"",
      length:""
    });

    this.addCustomFieldForm=this._FormBuilder.group({
      label:"",
      type:"",
      size:"",
    });
    
  }

  ngOnInit(): void {
   
    
  }

 
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  

  ngOnDestroy(): void {
    this.spinner.hide();
  }



}
