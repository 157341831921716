<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Account Summary ></span>
                <span class="color-black pl-1"> {{editMode? 'Update':'Create'}} Additional Account Detail
                </span>
                <button type="submit" style="margin-left: 56%;" (click)="cancelSubmit();" routerLinkActive="active"
                class="btn btn-danger text-white">Cancel</button>
            </div>
            <div class="w3-card-4" style="border:1px solid;
            border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
            <header class="w3-container w3-blue">
              <div class="header-tabs">
                <h4 class=" common-styling" style="margin-left: 7px;">
                    {{editMode? 'Update':'Create'}} Additional Account Detail
                </h4>
             
              </div>
            </header>
            <div class=" bg-white color-grey">
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="additionalDetailForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                             style="text-align: right!important;
                                            padding-right: 17px!important;margin-left:-7%;">
                                                <label for="exampleInputEmail1">Type</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Type"
                                                formControlName="infoType">
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;">
                                                <label for="exampleInputEmail1">Terms</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Terms"
                                                formControlName="terms">
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;margin-left: -7%;">
                                                <label for="exampleInputEmail1">Rep. </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Rep"
                                                formControlName="rep">
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;">
                                                <label for="exampleInputEmail1">Price Level </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Price Level"
                                                formControlName="priceLevel">
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;margin-left: -7%;">
                                                <label for="exampleInputEmail1">Preferred Send Method
                                                </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control"
                                                placeholder="Preferred Send Method"
                                                formControlName="prefeferredSendMethod">
                                            </div>
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;">
                                                <label for="exampleInputEmail1">Resale No. </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Resale No."
                                                formControlName="resaleNo">
                                            </div>
                                          
                                            
                                           
                                        </div>
                                    </div>
                                 
                                 
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: 83%;">
                                <button type="submit" *ngIf="editMode" (click)="update()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!editMode" (click)="submit()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 