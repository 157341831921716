import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-edit-product-store',
  templateUrl: './edit-product-store.component.html',
  styleUrls: ['./edit-product-store.component.css']
})
export class EditProductStoreComponent implements OnInit {
  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonB') closebuttonB;
  @ViewChild('closebuttonC')closebuttonC;
  @ViewChild('closebuttonD')closebuttonD;
  @ViewChild('closebuttonE')closebuttonE;
  @ViewChild('closebuttonG')closebuttonG;
  @ViewChild('closebuttonH')closebuttonH;
  @ViewChild('closebuttonRA')closebuttonRA;
  @ViewChild('closebuttonHH')closebuttonHH;
  @ViewChild('closebuttonMM')closebuttonMM;
  @ViewChild('closebuttonLL')closebuttonLL;
  @ViewChild('closebuttonLM')closebuttonLM;
  @ViewChild('closebuttonJJ')closebuttonJJ;
  @ViewChild('closebuttonAB')closebuttonAB;
  @ViewChild('closebutton15')closebutton15;
  activeCategory = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  editProductStore: any;
  addRole: any;
  addPromo: any;
  addCatalog: any;
  addWebsite: any;
  addStoreWeb: any;
  addStoreShip: any;
  addPaymentSetup: any;
  addFinancial: any;
  addFacility: any;
  addEmailSetup: any;
  addSurvey: any;
  addKeyword: any;
  addSegment: any;
  addVendorPayment: any;
  addVendorShipment: any;
  newShipment: any;
  roleSearch: { partyID: string[]; roleTypeID: string[]; fromDate: string[]; throughDate: string[]; seqNum: string[]; };
  personId: any;
  partyIdArray: any[]=[];
  productStoreId: any;
  ProductVendorStoreShipmentListById: any;
  ShipmentMethodTypeIdList: any;
  ShipmentMethodTypeIdListArray: any[]=[];
  ProductStoreVendorPaymentListById: any;
  CreditCardEnumIdList: any;
  CreditCardEnumIdListArray: any[]=[];
  PaymentMethodTypeList: any;
  PaymentMethodTypeListArray: any[]=[];
  SegmentGroupTypeID: any;
  ProductStoreID: any;
  SegmentGroupTypeIDArray: any[]=[];
  ProductStoreIDArray: any[]=[];
  ProductSurveyListById: any;
  ProductStoreKeywordOvrdById: any;
  TargetTypeEnumIdList: any;
  TargetTypeEnumIdListArray: any[]=[];
  show: boolean;
  actualfromdate: string;
  thrufromdate: string;
  getEstimateFrom: string;
  getEstimateTo: string;
  fromDateUpdate: any;
  SurveyListValue: any;
  SurveyListValueArray: any[]=[];
  SurveyTypeList: any;
  SurveyTypeListArray: any[]=[];
  productListArray: any[]=[];
  categoryList: any;
  categoryListArray: any[]=[];
 
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  actualfromdateSurvey: string;
  thrufromdateSuvey: string;
  ViewProductStoreSegmentsId: any;
  ProductStoreById: any;
  ProductStoreGroupList: any;
  ProductStoreGroupListArray: any[]=[];
  LookAndFeelWebBasedStore: any;
  LookAndFeelWebBasedStoreArray: any[]=[];
  InventoryFacilityId: any;
  InventoryFacilityIdArray: any[]=[];
  ReserveOrderEnumId: any;
  ReserveOrderEnumIdArray: any[]=[];
  RequirementMethodEnumId: any;
  RequirementMethodEnumIdArray: any[]=[];
  ScoreCreditAccountList: any;
  ScoreCreditAccountListArray: any[]=[];
  DefaultSalesChannelEnumList: any;
  DefaultSalesChannelEnumListArray: any[]=[];
  HeaderApprovedStatus: any;
  HeaderApprovedStatusArray: any[]=[];
  ItemApprovedStatus: any;
  ItemApprovedStatusArray: any[]=[];
  currencyArray: any[]=[];
  DigProdUploadCategoryList: any;
  DigProdUploadCategoryListArray: any[]=[];
  VatTaxAuthGeoId: any;
  VatTaxAuthGeoIdArray: any[]=[];
  yesNoArray: { label: string; value: string; }[];
  FacilityIdListValue: any;
  FacilityIdListValueArray: any[]=[];
  actualfromdateFac: string;
  thrufromdateFac: string;
  StoreFacilitySettingById: any;
  FinAccountTypeIdList: any;
  FinAccountTypeIdListArray: any[]=[];
  ReplenishEnumTypeIdList: any;
  ReplenishEnumTypeIdListArray: any[]=[];
  showFin: boolean;
  showEmail:boolean;
  ProductStoreFinAccountSettingsListById: any;
  ProductStoreEmailSetupById: any;
  EmailTypeList: any;
  EmailTypeListArray: any[]=[];
  ProductStorePaymentListById: any;
  showPay: boolean;
  PaymentMethodTypeListData: any;
  PaymentMethodTypeListDataArray: any[]=[];
  ServiceTypeList: any;
  ServiceTypeListArray: any[]=[];
  CustomMethodListPayment: any;
  CustomMethodListPaymentArray: any[]=[];
  PaymentGatewayConfigIdList: any;
  PaymentGatewayConfigIdListArray: any[]=[];
 
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  ProductStore: any;
  showRole: boolean;
  actualDepromdate: string;
  thruDepfromdate: string;
  assignFromDate: any;
  getAssignEstimateFrom: string;
  getAssignEstimateTo: string;
  ProductStoreCatalog: any;
  showCat: boolean;
  catList: any;
  catListArray: any[]=[];
  thruDepfromdateCat: string;
  actualDepromdateCat: string;
  assignFromDateCat: any;
  getAssignEstimateToCat: string;
  getAssignEstimateFromCat: string;
  ActiveOrInActive_All: any;
  StorePromoList: any;
  StorePromoListArray: any[]=[];
  actualfromdatePromo: string;
  thrufromdatePromo: string;
  showPromo: boolean;
  assignPromo: any;
  DatePromo: any;
  ProductStoreShipmentEstiById: any;
  GeoId: any;
  GeoIdArray: any[]=[];
  UnitOfMeasure: any;
  UnitOfMeasureArray: any[]=[];
  WeightBreakIdList: any;
  WeightBreakIdListArray: any[]=[];
  ProductStoreShipmentSetupById: any;
  ProductStoreShipMethId: any;
  ProductStoreShipMethIdArray: any[]=[];
  newShipmentMutliTabForm: any;
  customerCategoryPopup=1;
  advanceSearch: { partyId: string; productStoreId: string; roleTypeId: string; sequenceNum: string; ThroughDate: string; fromDate: string; };
  ProductStoreShipForm: any;
  carrierShipmentMethodList: any;
  carrierShipmentMethodListArray: any[]=[];
  shipmentGatewayConfig: any;
  shipmentGatewayConfigArray: any[]=[];
  shipmentCustomMethod: any;
  shipmentCustomMethodArray: any[]=[];
  showShip: boolean;
  storeWebsiteList: any;
  storeWebsiteListArray: any[]=[];
  productStoreWebsiteById: any;
  costEstimatedUom: any;
  costEstimatedUomArray: any[]=[];
  yes: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,    private _location: Location,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
   
    readonly addSkillService: AddSkillService
  ) { 
    this.advanceSearch ={
       
      partyId: '',
      productStoreId:'',
      roleTypeId: '',
      sequenceNum:'',
      ThroughDate:'',
      fromDate:''
  
    };
    this.yesNoArray = 
    [{
      label: "Y",
      value: "Y",
    },
    {
      label: "N",
      value: "N",
    },
     
    ]

    this.editProductStore = this._FormBuilder.group({
      productStoreID: [''],
      primaryStoreGroupID: [''],
      storeName: [''],
      title: [''],
      subTitle: [''],
      companyName: [''],
      isDemoStore: [''],
      controlling: [''],
      inventoryFacilityID: [''],
      oneInventoryFacility: [''],
      isFulfil: [''],
      checkInventory: [''],
      reqInventory: [''],
      reqEnumID: [''],
      reserveInventory: [''],
      reserveEnumID: [''],
      resOrderCreation: [''],
      showStockProducts: [''],
      lotsManaging: [''],
      cartOnAdd: [''],
      autoSaveCart: [''],
      replaceUpsell: [''],
      removeIncompat: [''],
      giftOptions: [''],
      excludeVariants: [''],
      decimalQuantity: [''],
      prorateShipping: [''],
      addrDigItems: [''],
      paymentType: [''],
      captureFails: [''],
      splitPay: [''],
      payPartyID: [''],
      storeEnumID: [''],
      manualAudioCapture: [''],
      retryAuths: [''],
      daystoCancelnonPay: [''],
      ccTryExp: [''],
      ccOtherCards: [''],
      ccLaterNSF: [''],
      ccLaterMax: [''],
      creditValidDays: [''],
      setIssuance: [''],
      orderNumberPrefix: [''],
      channelEnumID: [''],
      exploreItems: [''],
      gcBalance: [''],
      invoiceDigitalItems: [''],
      approveInvoice: [''],
      approveOrder: [''],
      returnInventoryRecieve: [''],
      localeStorage: [''],
      currencyUomID: [''],
      timeZone: [''],
      headerApprovedStatus: [''],
      itemStatus: [''],
      digitalItemStatus: [''],
      headerDeclinedStatus: [''],
      itemDeclinedStatus: [''],
      headerCancelStatus: [''],
      itemCancelStatus: [''],
      authDeclinedMessage: [''],
      authFraudMessage: [''],
      authErrorMessage: [''],
      prorateTax: [''],
      pricesVATIncluded: [''],
      taxExempt: [''],
      vatGeoID: [''],
      vatPartyID: [''],
      approveReviews: [''],
      allowPassword: [''],
      defaultPassword: [''],
      usePrimaryUsername: [''],
      reqCustomerRole: [''],
      enableSuggestion: [''],
      enableProductUpload: [''],
      productUploadCategoryID: [''],
      allowComment: [''],
      allocateInventory: [''],
    });
    this.addRole = this._FormBuilder.group({
      partyID: [''],
      roleTypeID: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
    });
    this.roleSearch = {
      partyID: [''],
      roleTypeID: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
    };
    this.addPromo = this._FormBuilder.group({
      promoID: [''],
      fromDate: [''],
      throughDate: [''],
      manualOnly: [''],
    });
    this.addCatalog = this._FormBuilder.group({
      catalog: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
    });
    this.addWebsite = this._FormBuilder.group({
      websiteID: [''],
      siteName: [''],
      httpHost: [''],
      httpPort: [''],
      enableHttps: [''],
      webappPath: [''],
      standardPrefix: [''],
      securePrefix: [''],
      domainCookie: [''],
      visualTheme: [''],
      productStoreID: [''],
      allowPSChange: [''],
      pathAlias: [''],
      isDefault: [''],
      maintenancePage: [''],
    });
    this.addStoreWeb = this._FormBuilder.group({
      storeName: [''],
    });
    this.ProductStoreShipForm = this._FormBuilder.group({
      CarrierShipmentMethod: [''],
    });
    this.addStoreShip = this._FormBuilder.group({
      ShipMethID: [''],
      ShipmentMethodTypeID: [''],
      RoleTypeID: [''],
      PartyID: [''],
      MinSize: [''],
      MaxSize: [''],
      MinWt: [''],
      MaxWt: [''],
      MinTot: [''],
      AllowUSPSAddr: [''],
      MaxTot: [''],
      ReqUSPSAddr: [''],
      AllowCompAddr: [''],
      ReqCompAddr: [''],
      IncludeFreeShipItems: [''],
      CompPartyID: [''],
      IncludeGeo: [''],
      ExcludeGeo: [''],
      IncludeFeatureGroup: [''],
      ExcludeFeatureGroup: [''],
      ShipmentServiceName: [''],
      ConfigProperties: [''],
      ShipmentCustomMethod: [''],
      ShipmentGatewayConfigID: [''],
      SequenceNum: [''],
      
    });
    this.newShipment = this._FormBuilder.group({
      shipmentMethod: [''],
      fromGeo: [''],
      toGeo: [''],
      partyID: [''],
      roleTypeID: [''],
      basePercent: [''],
      basePrice: [''],
      itemPrice: [''],
      shippingPercent: [''],
      featureGroup: [''],
      preFeaturePercent: [''],
      preFeaturePrice: [''],
      oversizeUnit: [''],
      oversizeSurchurge: [''],
      minWT: [''],
      maxWT: [''],
      wtBreakID: [''],
      pressureUnitWT: [''],
      unitPriceWT: [''],
      minQty: [''],
      maxQty: [''],
      qtyBreakID: [''],
      measureUnitQty: [''],
      unitPriceQty: [''],
      minPrice: [''],
      maxPrice: [''],
      priceBreakID: [''],
      measureUnitPrice: [''],
      unitPricePR: [''],
      
    });

    this.newShipmentMutliTabForm = this._FormBuilder.group({
      shipmentCostEstimateID:"",
      shipmentMethodTypeID:"",
      shipmentMethod: [''],
      fromGeo: [''],
      toGeo: [''],
      partyID: [''],
      roleTypeID: [''],
      basePercent: [''],
      basePrice: [''],
      itemPrice: [''],
      shippingPercent: [''],
      featureGroup: [''],
      preFeaturePercent: [''],
      preFeaturePrice: [''],
      oversizeUnit: [''],
      oversizeSurchurge: [''],
      minWT: [''],
      maxWT: [''],
      wtBreakID: [''],
      pressureUnitWT: [''],
      unitPriceWT: [''],
      minQty: [''],
      maxQty: [''],
      qtyBreakID: [''],
      measureUnitQty: [''],
      unitPriceQty: [''],
      minPrice: [''],
      maxPrice: [''],
      priceBreakID: [''],
      measureUnitPrice: [''],
      unitPricePR: [''],
      
    });

    this.addPaymentSetup = this._FormBuilder.group({
      methodType: [''],
      serviceType: [''],
      serviceName: [''],
      custMethod: [''],
      gatewayConfigID: [''],
      paymentProps: [''],
      applyProducts: [''],
    });
    this.addFinancial = this._FormBuilder.group({
      financialID: [''],
      requirePinCode: [''],
      validateGift: [''],
      codeLength: [''],
      pinCodeLength: [''],
      accValidDays: [''],
      authValidDays: [''],
      purchaseSurveyID: [''],
      purchSurveyTo: [''],
      purchSurveyCopyMe: [''],
      allowAuthNegative: [''],
      minBalance: [''],
      replenishThreshold: [''],
      replenishEnumID: [''],
    });
    this.addFacility = this._FormBuilder.group({
      facilityID: [''],
      seqNum: [''],
      fromDate: [''],
      throughDate: [''],
    });
    this.addEmailSetup = this._FormBuilder.group({
      emailType: [''],
      bodyScreenLocation: [''],
      attachScreenLocation: [''],
      fromAddress: [''],
      ccAddress: [''],
      bccAddress: [''],
      subject: [''],
      contentType: [''],
    });
    this.addSurvey = this._FormBuilder.group({
      type: [''],
      groupName: [''],
      surveys: [''],
      productID: [''],
      categoryID: [''],
      fromDate: [''],
      throughDate: [''],
      surveyPath: [''],
      resultPath: [''],
      seqNum: [''],
    });
    this.addKeyword = this._FormBuilder.group({
      keyword: [''],
      fromDate: [''],
      throughDate: [''],
      target: [''],
      targetEnum: [''],
    });
    this.addSegment = this._FormBuilder.group({
      segmentTypeID: [''],
      productStoreID: [''],
      desc: [''],
    });
    this.addVendorPayment = this._FormBuilder.group({
      partyID: [''],
      paymentType: [''],
      creditCardEnumID: [''],
    });
    this.addVendorShipment = this._FormBuilder.group({
      carrierPartyID: [''],
      vendorPartyID: [''],
      shipmentTypeID: [''],
    });
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.productStoreId = params["productStoreId"];
    });
    this.getPartyId();
    this.getActiveOrInActive_All();
    this.getProductStoreShipmentSetupById();
    this.getProductStoreShipmentEstiById();
    this.getProductVendorStoreShipmentListById();
    this.getShipmentMethodTypeIdList();
    this.getProductStoreVendorPaymentListById();
    this.getCreditCardEnumIdList();
    this.getPaymentMethodTypeList();
    this.getSegmentGroupTypeID();
    this.getProductStoreID();
    this.getProductSurveyListById();
    this.getProductStoreKeywordOvrdById();
    this.getTargetTypeEnumIdList();
    this.getSurveyListValue();
    this.getSurveyTypeList();
    this.getProducts();
    this.getCategorySearch();
    this.getViewProductStoreSegmentsId();
    this.getStoreFacilitySettingById();
    this.getProductStoreById();
    this.getStorePromoList();
    this.getProductStoreGroupList();
    this.getLookAndFeelWebBasedStore();
    this.getInventoryFacilityId();
    this.getReserveOrderEnumId();
    this.getRequirementMethodEnumId();
    this.getScoreCreditAccountList();
    this.getDefaultSalesChannelEnumList();
    this.getHeaderApprovedStatus();
    this.getItemApprovedStatus();
    this.getCurrencyRate();
    this.getDigProdUploadCategoryList();
    this.getVatTaxAuthGeoId();
    this.getPartyId();
    this.getFacilityIdListValue();
    this.getProductStoreFinAccountSettingsListById();
    this.getFinAccountTypeIdList();
    this.getReplenishEnumTypeIdList();
    this.getProductStoreEmailSetupById();
    this.getProductStorePaymentListById();
    this.getEmailTypeList();
    this.getPaymentMethodTypeListData();
    this.getServiceTypeList();
    this.getCustomMethodListPayment();
    this.getPaymentGatewayConfigIdList();
    this.getRoleAllType();
    this.reset();
    this.getProductStoreCatalog();
    this.getCatalogList();
    this.getGeoId();
    this.getUnitOfMeasure();
    this.getWeightBreakIdList();
    this.getProductStoreShipMethId();
    this.getCarrierShipmentMethodList();
    this.getShipmentCustomMethod();
    this.getShipmentGatewayConfig();
    this.getStoreWebsiteList();
    this.getProductStoreWebsiteById();
    this.shipmentCostEstimatedUom();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  shipmentCostEstimatedUom() {
    this.spinner.show();
    this.myContactsService.shipmentCostEstimatedUom().subscribe(res => {
      this.costEstimatedUom = res.data[0].shipmentCostEstimatedUomList;
      this.spinner.hide();
      for (const value of this.costEstimatedUom) {
        this.costEstimatedUomArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getUnitOfMeasure() {
    this.spinner.show();
    this.accountsService.getUnitOfMeasure().subscribe(res => {
      this.UnitOfMeasure = res.data[0].unitOfMeasureList;
      this.spinner.hide();
      for (const value of this.UnitOfMeasure) {
        this.UnitOfMeasureArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
   createWebsitecontent(){
    this._Router.navigate(["/content/webSite"], 
    { queryParams: { productStoreId: this.productStoreId, showPop: "yes" } })
   }
   updateWebsitecontent(product){
    this._Router.navigate(["/content/main/website-detail-page"], 
    { queryParams: { productStoreId: this.productStoreId, webSiteId: product.webSiteId } })
   }
  removeStoreUpdateWebSite(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "productStoreId":product.productStoreId,
  "viewProductStoreId": product.viewProductStoreId,
  "webSiteId": product.webSiteId
        }
        this.myContactsService.removeStoreUpdateWebSite(req).subscribe(res => {
          this.getProductStoreWebsiteById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getProductStoreWebsiteById() {
    this.spinner.show();
    this.myContactsService.getProductStoreWebsiteById(this.productStoreId).subscribe(res => {
      this.productStoreWebsiteById = res.data[0].webSiteList;
      this.spinner.hide();
     
    })
  }
  getStoreWebsiteList() {
    this.spinner.show();
    this.myContactsService.getStoreWebsiteList().subscribe(res => {
      this.storeWebsiteList = res.data;
      this.spinner.hide();
      for (const value of this.storeWebsiteList) {
        this.storeWebsiteListArray.push({
          label: value.description,
          value: value.value
        })
      }
    })
  }
  storeUpdateWebSite(): void {
    this.spinner.show();
    const requestData ={
      "productStoreId":  this.productStoreId,
      "viewProductStoreId": this.productStoreId,
      "webSiteId": this.addStoreWeb.value.storeName,
      }
     this.myContactsService.storeUpdateWebSite(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success(" Success");
        this.spinner.hide(); 
        this.closebutton15.nativeElement.click();
        this.getProductStoreWebsiteById();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  catalog_Page(id) {
    this._Router.navigate(["/product-management/catalogs/detail-catalog-page"], 
    { queryParams: { prodCatalogId: id } })
  }
  getShipmentCustomMethod() {
    this.spinner.show();
    this.myContactsService.getShipmentCustomMethod().subscribe(res => {
      this.shipmentCustomMethod = res.data[0].customMethodList;
      this.spinner.hide();
      for (const value of this.shipmentCustomMethod) {
        this.shipmentCustomMethodArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
    })
  }
  getShipmentGatewayConfig() {
    this.spinner.show();
    this.myContactsService.getShipmentGatewayConfig().subscribe(res => {
      this.shipmentGatewayConfig = res.data[0].shipmentGatewayConfig;
      this.spinner.hide();
      for (const value of this.shipmentGatewayConfig) {
        this.shipmentGatewayConfigArray.push({
          label: value.description,
          value: value.shipmentGatewayConfigId
        })
      }
    })
  }
  updateShipping(product){
    this.showShip =true;
      setTimeout(() => {
        const formValue = this.addStoreShip;
        formValue.patchValue({
      ShipMethID:product.shipmentMeth.productStoreShipMethId,
          ShipmentMethodTypeID: product.shipmentMeth.shipmentMethodTypeId,
          RoleTypeID: product.shipmentMeth.roleTypeId,
          PartyID: product.shipmentMeth.partyId,
          MinSize: product.shipmentMeth.minSize,
          MaxSize: product.shipmentMeth.maxSize,
          MinWt: product.shipmentMeth.minWeight,
          MaxWt: product.shipmentMeth.maxWeight,
          MinTot: product.shipmentMeth.minTotal,
          AllowUSPSAddr: product.shipmentMeth.allowUspsAddr,
          MaxTot: product.shipmentMeth.maxTotal,
          ReqUSPSAddr: product.shipmentMeth.requireUspsAddr,
          AllowCompAddr: product.shipmentMeth.allowCompanyAddr,
          ReqCompAddr: product.shipmentMeth.requireCompanyAddr,
          IncludeFreeShipItems: product.shipmentMeth.includeNoChargeItems,
          CompPartyID: product.shipmentMeth.companyPartyId,
          IncludeGeo: product.shipmentMeth.includeGeoId,
          ExcludeGeo: product.shipmentMeth.excludeGeoId,
          IncludeFeatureGroup: product.shipmentMeth.includeFeatureGroup,
          ExcludeFeatureGroup: product.shipmentMeth.excludeFeatureGroup,
          ShipmentServiceName: product.shipmentMeth.serviceName,
          ConfigProperties: product.shipmentMeth.configProps,
          ShipmentCustomMethod: product.shipmentMeth.shipmentCustomMethodId,
          ShipmentGatewayConfigID: product.shipmentMeth.shipmentGatewayConfigId,
          SequenceNum: product.shipmentMeth.sequenceNumber,
          
        })
      
      }, 2000);
  
  }
  storeUpdateShipMeth(): void {
    this.spinner.show();
    const requestData ={
      "allowCompanyAddr":  this.addStoreShip.value.AllowCompAddr,
      "allowUspsAddr": this.addStoreShip.value.AllowUSPSAddr,
      "companyPartyId": this.addStoreShip.value.CompPartyID,
      "configProps": this.addStoreShip.value.ConfigProperties,
      "excludeFeatureGroup": this.addStoreShip.value.ExcludeFeatureGroup,
      "excludeGeoId": this.addStoreShip.value.ExcludeGeo,
      "includeFeatureGroup": this.addStoreShip.value.IncludeFeatureGroup,
      "includeGeoId": this.addStoreShip.value.IncludeGeo,
      "includeNoChargeItems": this.addStoreShip.value.IncludeFreeShipItems,
      "maxSize": this.addStoreShip.value.MaxSize,
      "maxTotal": this.addStoreShip.value.MaxTot,
      "maxWeight": this.addStoreShip.value.MaxWt,
      "minSize": this.addStoreShip.value.MinSize,
      "minTotal": this.addStoreShip.value.MinTot,
      "minWeight": this.addStoreShip.value.MinWt,
      "partyId": this.addStoreShip.value.PartyID,
      "productStoreId": this.productStoreId,
      "requireCompanyAddr": this.addStoreShip.value.ReqCompAddr,
      "requireUspsAddr": this.addStoreShip.value.ReqUSPSAddr,
      "roleTypeId": this.addStoreShip.value.RoleTypeID,
      "sequenceNumber": this.addStoreShip.value.SequenceNum,
      "serviceName": this.addStoreShip.value.ShipmentServiceName,
      "shipmentCustomMethodId": this.addStoreShip.value.ShipmentCustomMethod,
      "shipmentGatewayConfigId": this.addStoreShip.value.ShipmentGatewayConfigID,
      "shipmentMethodTypeId":  this.addStoreShip.value.ShipmentMethodTypeID,
      }
     this.myContactsService.storeUpdateShipMeth(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonAB.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  storeCreateShipMeth(): void {
    this.spinner.show();
    const requestData ={
   
      "allowCompanyAddr":  this.addStoreShip.value.AllowCompAddr,
      "allowUspsAddr": this.addStoreShip.value.AllowUSPSAddr,
      "companyPartyId": this.addStoreShip.value.CompPartyID,
      "configProps": this.addStoreShip.value.ConfigProperties,
      "excludeFeatureGroup": this.addStoreShip.value.ExcludeFeatureGroup,
      "excludeGeoId": this.addStoreShip.value.ExcludeGeo,
      "includeFeatureGroup": this.addStoreShip.value.IncludeFeatureGroup,
      "includeGeoId": this.addStoreShip.value.IncludeGeo,
      "includeNoChargeItems": this.addStoreShip.value.IncludeFreeShipItems,
      "maxSize": this.addStoreShip.value.MaxSize,
      "maxTotal": this.addStoreShip.value.MaxTot,
      "maxWeight": this.addStoreShip.value.MaxWt,
      "minSize": this.addStoreShip.value.MinSize,
      "minTotal": this.addStoreShip.value.MinTot,
      "minWeight": this.addStoreShip.value.MinWt,
      "partyId": this.addStoreShip.value.PartyID,
      "productStoreId": this.productStoreId,
      "requireCompanyAddr": this.addStoreShip.value.ReqCompAddr,
      "requireUspsAddr": this.addStoreShip.value.ReqUSPSAddr,
      "roleTypeId": this.addStoreShip.value.RoleTypeID,
      "sequenceNumber": this.addStoreShip.value.SequenceNum,
      "serviceName": this.addStoreShip.value.ShipmentServiceName,
      "shipmentCustomMethodId": this.addStoreShip.value.ShipmentCustomMethod,
      "shipmentGatewayConfigId": this.addStoreShip.value.ShipmentGatewayConfigID,
      "shipmentMethodTypeId":  this.addStoreShip.value.ShipmentMethodTypeID,
      }
     
    this.myContactsService.storeCreateShipMeth(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonAB.nativeElement.click();

        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getCarrierShipmentMethodList() {
    this.spinner.show();
    this.myContactsService.getCarrierShipmentMethodList().subscribe(res => {
      this.carrierShipmentMethodList = res.data;
      this.spinner.hide();
      for (const value of this.carrierShipmentMethodList) {
        this.carrierShipmentMethodListArray.push({
          label: value.description,
          value: value.value
        })
      }
    })
  }
  prepareCreateShipMeth(): void {
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
      const requestData ={
        "carrierShipmentString": this.ProductStoreShipForm.value.CarrierShipmentMethod,
        "newShipMethod": "Y",
        "productStoreId":  this.productStoreId
        }
       
      this.myContactsService.prepareCreateShipMeth(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Success");
          this.spinner.hide();
          // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); 
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.toastr.error("Permission Denied");
    }
 
  }
  getWeightBreakIdList() {
    this.spinner.show();
    this.accountsService.getWeightBreakIdList().subscribe(res => {
      this.WeightBreakIdList = res.data[0].quantityBreakList;
      this.spinner.hide();
      for (const value of this.WeightBreakIdList) {
        this.WeightBreakIdListArray.push({
          label: value.quantityBreakId,
          value: value.quantityBreakId
        })
      }
    })
  }
 
  getGeoId() {
    this.spinner.show();
    this.accountsService.getGeoId().subscribe(res => {
      this.GeoId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.GeoId) {
        this.GeoIdArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.ProductStore=[];
    const req = {
      "partyId": this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
      "productStoreId":this.productStoreId,
      "roleTypeId":this.advanceSearch.roleTypeId ? this.advanceSearch.roleTypeId : '',
      "sequenceNum": this.advanceSearch.sequenceNum ? this.advanceSearch.sequenceNum : '',

   
    }
    this.accountsService.findProductStore(req)
      .subscribe(resp => {
  
          this.ProductStore = resp.data;
          this.spinner.hide();
        
      }); } 
      reset(): void {
        this.spinner.show();
        this.advanceSearch.sequenceNum='';
        this.advanceSearch.roleTypeId='';
        this.advanceSearch.partyId='';
        this.advanceSearch.sequenceNum='0';
        this.advanceSearch.fromDate='';
        this.advanceSearch.ThroughDate='';
        this.ProductStore=[];
        const req = {
          "partyId":'',
          "productStoreId":this.productStoreId,
          "roleTypeId":'',
          "sequenceNum":'0',
    
       
        }
        this.accountsService.findProductStore(req)
          .subscribe(resp => {
      
            this.ProductStore = resp.data;
              this.spinner.hide();
            
          }); } 
          updateCatalog(product){
            this.showCat=true;
            this.addCatalog.reset();
            this.assignFromDateCat=product.fromDate;
              setTimeout(() => {
                const fromdate=product.productStoreList.fromDate
                this.getAssignEstimateFromCat=this.datePipe.transform(fromdate,"yyyy-MM-dd");
                const toEstdate=product.productStoreList.thruDate
                this.getAssignEstimateToCat=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
               
                const formValue = this.addCatalog;
                formValue.patchValue({
                  catalog:product.productStoreList.prodCatalogId,
                  fromDate: this.getAssignEstimateFromCat,
                  throughDate: this.getAssignEstimateToCat,
                   seqNum:product.productStoreList.sequenceNum,
                  
                })
              
              }, 2000);
          
          }
          updateRole(product){
            this.showRole=true;
            this.addRole.reset();
            this.assignFromDate=product.fromDate;
              setTimeout(() => {
                const fromdate=product.data.fromDate
                this.getAssignEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
                const toEstdate=product.data.thruDate
                this.getAssignEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
               
                const formValue = this.addRole;
                formValue.patchValue({
                  partyID: product.data.partyId,
                  roleTypeID: product.data.roleTypeId,
                  fromDate:  this.getAssignEstimateFrom,
                  throughDate: this.getAssignEstimateTo,
                  seqNum:product.data.sequenceNum,
                  
                })
              
              }, 2000);
          
          }
          updatePromo(product){
            this.showPromo=true;
            this.addPromo.reset();
            this.DatePromo=product.fromDate;
              setTimeout(() => {
                const fromdate=product.fromDate
                this.getAssignEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
                const toEstdate=product.thruDate
                this.getAssignEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
               
                const formValue = this.addPromo;
                formValue.patchValue({
                  promoID: product.dataNode.productPromoId,
                  fromDate: this.getAssignEstimateFrom,
                  throughDate: this.getAssignEstimateTo,
                  manualOnly: product.node1.manualOnly,
                  
                })
              
              }, 2000);
          
          }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }

      })
     
     }
     addRoleData(){
      this.addRole.reset();
    this.showRole=false;  
     }
  addPayment(){
    this.addPaymentSetup.reset();
    this.showPay=false;
  }
  addEmailData(){
    this.addEmailSetup.reset();
    this.showEmail=false;
  }
  finOpen(){
     this.addFinancial.reset();
     this.showFin=false;
  }
  
  getPaymentGatewayConfigIdList() {
    this.spinner.show();
    this.accountsService.getPaymentGatewayConfigIdList().subscribe(res => {
      this.PaymentGatewayConfigIdList = res.data[0].paymentGatewayConfig;
      this.spinner.hide();
      for (const value of this.PaymentGatewayConfigIdList) {
        this.PaymentGatewayConfigIdListArray.push({
          label: value.description,
          value: value.paymentGatewayConfigId
        })
      }
    })
  }
  getCustomMethodListPayment() {
    this.spinner.show();
    this.accountsService.getCustomMethodListPayment().subscribe(res => {
      this.CustomMethodListPayment = res.data[0].customMethodList;
      this.spinner.hide();
      for (const value of this.CustomMethodListPayment) {
        this.CustomMethodListPaymentArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
    })
  }
  
  getCatalogList() {
    this.spinner.show();
    this.accountsService.getCatalogList().subscribe(res => {
      this.catList = res.data[0].prodCatalogList;
      this.spinner.hide();
      for (const value of this.catList) {
        this.catListArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })
  }
  getServiceTypeList() {
    this.spinner.show();
    this.accountsService.getServiceTypeList().subscribe(res => {
      this.ServiceTypeList = res.data[0].serviceTypeList;
      this.spinner.hide();
      for (const value of this.ServiceTypeList) {
        this.ServiceTypeListArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  getPaymentMethodTypeListData() {
    this.spinner.show();
    this.accountsService.getPaymentMethodTypeListData().subscribe(res => {
      this.PaymentMethodTypeListData = res.data[0].paymentMethodType;
      this.spinner.hide();
      for (const value of this.PaymentMethodTypeListData) {
        this.PaymentMethodTypeListDataArray.push({
          label: value.description,
          value: value.paymentMethodTypeId
        })
      }
    })
  }
  getEmailTypeList() {
    this.spinner.show();
    this.accountsService.getEmailTypeList().subscribe(res => {
      this.EmailTypeList = res.data[0];
      this.spinner.hide();
      for (const value of this.EmailTypeList) {
        this.EmailTypeListArray.push({
          label: value.description,
          value: value.value
        })
      }
    })
  }
  getProductStoreGroupList() {
    this.spinner.show();
    this.accountsService.getProductStoreGroupList().subscribe(res => {
      this.ProductStoreGroupList = res.data;
      this.spinner.hide();
      for (const value of this.ProductStoreGroupList) {
        this.ProductStoreGroupListArray.push({
          label: value.productStoreGroupName+"["+value.productStoreGroupId+"]",
          value: value.productStoreGroupId
        })
      }
    })
  }
  getLookAndFeelWebBasedStore() {
    this.spinner.show();
    this.accountsService.getLookAndFeelWebBasedStore().subscribe(res => {
      this.LookAndFeelWebBasedStore = res.data[0].visualList;
      this.spinner.hide();
      for (const value of this.LookAndFeelWebBasedStore) {
        this.LookAndFeelWebBasedStoreArray.push({
          label: value.visualThemeId+"-"+value.description,
          value: value.visualThemeId
        })
      }
    })
  }
  getInventoryFacilityId() {
    this.spinner.show();
    this.accountsService.getInventoryFacilityId().subscribe(res => {
      this.InventoryFacilityId = res.data;
      console.log("InventoryFacilityId", res.data);
      this.spinner.hide();
      for (const value of this.InventoryFacilityId) {
        this.InventoryFacilityIdArray.push({
          label: value.facilityName[0].facilityName+"["+value.facility.facilityId+"]",
          value: value.facility.facilityId
        })
      }
    })
  }
  getReserveOrderEnumId() {
    this.spinner.show();
    this.accountsService.getReserveOrderEnumId().subscribe(res => {
      this.ReserveOrderEnumId = res.data[0].requirementEnumList;
      console.log("ReserveOrderEnumId", res.data);
      this.spinner.hide();
      for (const value of this.ReserveOrderEnumId) {
        this.ReserveOrderEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  getRequirementMethodEnumId() {
    this.spinner.show();
    this.accountsService.getRequirementMethodEnumId().subscribe(res => {
      this.RequirementMethodEnumId = res.data[0].requirementEnumList;
      console.log("RequirementMethodEnumId", res.data);
      this.spinner.hide();
      for (const value of this.RequirementMethodEnumId) {
        this.RequirementMethodEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  getScoreCreditAccountList() {
    this.spinner.show();
    this.accountsService.getScoreCreditAccountList().subscribe(res => {
      this.ScoreCreditAccountList = res.data[0].creditList;
      console.log("ScoreCreditAccountList", res.data);
      this.spinner.hide();
      for (const value of this.ScoreCreditAccountList) {
        this.ScoreCreditAccountListArray.push({
          label: value.description,
          value: value.value
        })
      }
    })
  }
  getDefaultSalesChannelEnumList() {
    this.spinner.show();
    this.accountsService.getDefaultSalesChannelEnumList().subscribe(res => {
      this.DefaultSalesChannelEnumList = res.data[0].defaultSalesChannel;
      console.log("DefaultSalesChannelEnumList", res.data);
      this.spinner.hide();
      for (const value of this.DefaultSalesChannelEnumList) {
        this.DefaultSalesChannelEnumListArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  getHeaderApprovedStatus() {
    this.spinner.show();
    this.accountsService.getHeaderApprovedStatus().subscribe(res => {
      this.HeaderApprovedStatus = res.data[0].headerApproved;
      console.log("HeaderApprovedStatus", res.data);
      this.spinner.hide();
      for (const value of this.HeaderApprovedStatus) {
        this.HeaderApprovedStatusArray.push({
          label: value.statusId,
          value: value.statusId
        })
      }
    })
  }
  getItemApprovedStatus() {
    this.spinner.show();
    this.accountsService.getItemApprovedStatus().subscribe(res => {
      this.ItemApprovedStatus = res.data[0].itemApprovedList;
      console.log("ItemApprovedStatus", res.data);
      this.spinner.hide();
      for (const value of this.ItemApprovedStatus) {
        this.ItemApprovedStatusArray.push({
          label: value.description+"["+value.statusCode+"]",
          value: value.statusId
        })
      }
    })
  }
  getCurrencyRate() {
    this.spinner.show();
    this.myContactsService.getCurrencyDataList().subscribe(res => {
      const currencyList = res.data.currencyList;
      console.log("currencyList", res.data);

      this.spinner.hide();
      for (const value of currencyList) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }
  getDigProdUploadCategoryList() {
    this.spinner.show();
    this.accountsService.getDigProdUploadCategoryList().subscribe(res => {
      this.DigProdUploadCategoryList = res.data[0].DigProdUploadCategoryList;
      this.spinner.hide();
      for (const value of this.DigProdUploadCategoryList) {
        this.DigProdUploadCategoryListArray.push({
          label: value.productCategoryId,
          value: value.productCategoryId
        })
      }
    })
  }
  getVatTaxAuthGeoId() {
    this.spinner.show();
    this.accountsService.getVatTaxAuthGeoId().subscribe(res => {
      this.VatTaxAuthGeoId = res.data[0].vatTaxAuth;
      this.spinner.hide();
      for (const value of this.VatTaxAuthGeoId) {
        this.VatTaxAuthGeoIdArray.push({
          label: value.geoId,
          value: value.geoSecCode
        })
      }
    })
  }

  getProductStoreById() {
    this.spinner.show();
    this.accountsService.getProductStoreById(this.productStoreId).subscribe(res => {
      this.ProductStoreById = res.data[0].productStoreList[0];
    
      setTimeout(() => {
        const formValue = this.editProductStore;
        formValue.patchValue({
          productStoreID: this.ProductStoreById.productStoreId,
          primaryStoreGroupID: this.ProductStoreById.primaryStoreGroupId,
          storeName: this.ProductStoreById.storeName,
          title: this.ProductStoreById.title,
          subTitle: this.ProductStoreById.subtitle,
          companyName: this.ProductStoreById.companyName,
          isDemoStore: this.ProductStoreById.isDemoStore,
          controlling: this.ProductStoreById.ecomThemeId,
          inventoryFacilityID: this.ProductStoreById.inventoryFacilityId,
          oneInventoryFacility: this.ProductStoreById.oneInventoryFacility,
          isFulfil: this.ProductStoreById.isImmediatelyFulfilled,
          checkInventory: this.ProductStoreById.checkInventory,
          reqInventory: this.ProductStoreById.requireInventory,
          reqEnumID: this.ProductStoreById.requirementMethodEnumId,
          reserveInventory: this.ProductStoreById.reserveInventory,
          reserveEnumID: this.ProductStoreById.reserveOrderEnumId,
          resOrderCreation: this.ProductStoreById.balanceResOnOrderCreation,
          showStockProducts: this.ProductStoreById.showOutOfStockProducts,
          lotsManaging: this.ProductStoreById.managedByLot,
          cartOnAdd: this.ProductStoreById.viewCartOnAdd,
          autoSaveCart: this.ProductStoreById.autoSaveCart,
          replaceUpsell: this.ProductStoreById.addToCartReplaceUpsell,
          removeIncompat: this.ProductStoreById.addToCartRemoveIncompat,
          giftOptions: this.ProductStoreById.showCheckoutGiftOptions,
          excludeVariants: this.ProductStoreById.prodSearchExcludeVariants,
          decimalQuantity: this.ProductStoreById.orderDecimalQuantity,
          prorateShipping: this.ProductStoreById.prorateShipping,
          addrDigItems: this.ProductStoreById.reqShipAddrForDigItems,
          paymentType: this.ProductStoreById.selectPaymentTypePerItem,
          captureFails: this.ProductStoreById.shipIfCaptureFails,
          splitPay: this.ProductStoreById.splitPayPrefPerShpGrp,
          payPartyID: this.ProductStoreById.payToPartyId,
          storeEnumID: this.ProductStoreById.storeCreditAccountEnumId,
          manualAudioCapture: this.ProductStoreById.manualAuthIsCapture,
          retryAuths: this.ProductStoreById.retryFailedAuths,
          daystoCancelnonPay: this.ProductStoreById.daysToCancelNonPay,
          ccTryExp: this.ProductStoreById.autoOrderCcTryExp,
          ccOtherCards: this.ProductStoreById.autoOrderCcTryOtherCards,
          ccLaterNSF: this.ProductStoreById.autoOrderCcTryLaterNsf,
          ccLaterMax: this.ProductStoreById.autoOrderCcTryLaterMax,
          creditValidDays: this.ProductStoreById.storeCreditValidDays,
          setIssuance: this.ProductStoreById.setOwnerUponIssuance,
          orderNumberPrefix: this.ProductStoreById.orderNumberPrefix,
          channelEnumID: this.ProductStoreById.defaultSalesChannelEnumId,
          exploreItems: this.ProductStoreById.explodeOrderItems,
          gcBalance: this.ProductStoreById.checkGcBalance,
          invoiceDigitalItems: this.ProductStoreById.autoInvoiceDigitalItems,
          approveInvoice: this.ProductStoreById.autoApproveInvoice,
          approveOrder: this.ProductStoreById.autoApproveOrder,
          returnInventoryRecieve: this.ProductStoreById.reqReturnInventoryReceive,
          localeStorage: this.ProductStoreById.defaultLocaleString,
          currencyUomID: this.ProductStoreById.defaultCurrencyUomId,
          timeZone: this.ProductStoreById.defaultTimeZoneString,
          headerApprovedStatus: this.ProductStoreById.headerApprovedStatus,
          itemStatus: this.ProductStoreById.itemApprovedStatus,
          digitalItemStatus: this.ProductStoreById.digitalItemApprovedStatus,
          headerDeclinedStatus: this.ProductStoreById.headerDeclinedStatus,
          itemDeclinedStatus: this.ProductStoreById.itemDeclinedStatus,
          headerCancelStatus: this.ProductStoreById.headerCancelStatus,
          itemCancelStatus: this.ProductStoreById.itemCancelStatus,
          authDeclinedMessage: this.ProductStoreById.authDeclinedMessage,
          authFraudMessage: this.ProductStoreById.authFraudMessage,
          authErrorMessage: this.ProductStoreById.authErrorMessage,
          prorateTax: this.ProductStoreById.prorateTaxes,
          pricesVATIncluded: this.ProductStoreById.showPricesWithVatTax,
          taxExempt: this.ProductStoreById.showTaxIsExempt,
          vatGeoID: this.ProductStoreById.vatTaxAuthGeoId,
          vatPartyID: this.ProductStoreById.vatTaxAuthPartyId,
          approveReviews: this.ProductStoreById.autoApproveReviews,
          allowPassword: this.ProductStoreById.allowPassword,
          defaultPassword: this.ProductStoreById.defaultPassword,
          usePrimaryUsername: this.ProductStoreById.usePrimaryEmailUsername,
          reqCustomerRole: this.ProductStoreById.requireCustomerRole,
          enableSuggestion: this.ProductStoreById.enableAutoSuggestionList,
          enableProductUpload: this.ProductStoreById.enableDigProdUpload,
          productUploadCategoryID: this.ProductStoreById.digProdUploadCategoryId,
          allowComment: this.ProductStoreById.allowComment,
          allocateInventory: this.ProductStoreById.allocateInventory,
        })
  
      }, 2000);
      this.spinner.hide();
    })
  }

  updateProductStore(): void {
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
      const requestData ={
        "addToCartRemoveIncompat": this.editProductStore.value.removeIncompat,
        "addToCartReplaceUpsell": this.editProductStore.value.replaceUpsell,
        "allocateInventory": this.editProductStore.value.allocateInventory,
        "allowComment": this.editProductStore.value.allowComment,
        "allowPassword": this.editProductStore.value.allowPassword,
        "authDeclinedMessage": this.editProductStore.value.authDeclinedMessage,
        "authErrorMessage": this.editProductStore.value.authErrorMessage,
        "authFraudMessage": this.editProductStore.value.authFraudMessage,
        "autoApproveInvoice": this.editProductStore.value.approveInvoice,
        "autoApproveOrder": this.editProductStore.value.approveOrder,
        "autoApproveReviews": this.editProductStore.value.approveReviews,
        "autoInvoiceDigitalItems": this.editProductStore.value.invoiceDigitalItems,
        "autoOrderCcTryExp": this.editProductStore.value.ccTryExp,
        "autoOrderCcTryLaterMax": this.editProductStore.value.ccLaterMax,
        "autoOrderCcTryLaterNsf": this.editProductStore.value.ccLaterNSF,
        "autoOrderCcTryOtherCards": this.editProductStore.value.ccOtherCards,
        "autoSaveCart": this.editProductStore.value.autoSaveCart,
        "balanceResOnOrderCreation": this.editProductStore.value.resOrderCreation,
        "checkGcBalance": this.editProductStore.value.gcBalance,
        "checkInventory": this.editProductStore.value.checkInventory,
        "companyName": this.editProductStore.value.companyName,
        "daysToCancelNonPay": this.editProductStore.value.daystoCancelnonPay,
        "defaultCurrencyUomId": this.editProductStore.value.currencyUomID,
        "defaultLocaleString": this.editProductStore.value.localeStorage,
        "defaultPassword": this.editProductStore.value.defaultPassword,
        "defaultSalesChannelEnumId": this.editProductStore.value.channelEnumID,
        "defaultTimeZoneString": this.editProductStore.value.timeZone,
        "digProdUploadCategoryId": this.editProductStore.value.productUploadCategoryID,
        "digitalItemApprovedStatus": this.editProductStore.value.digitalItemStatus,
        "ecomThemeId": this.editProductStore.value.controlling,
        "enableAutoSuggestionList": this.editProductStore.value.enableSuggestion,
        "enableDigProdUpload": this.editProductStore.value.enableProductUpload,
        "explodeOrderItems": this.editProductStore.value.exploreItems,
        "headerApprovedStatus": this.editProductStore.value.headerApprovedStatus,
        "headerCancelStatus": this.editProductStore.value.headerCancelStatus,
        "headerDeclinedStatus": this.editProductStore.value.headerDeclinedStatus,
        "inventoryFacilityId": this.editProductStore.value.inventoryFacilityID,
        "isDemoStore": this.editProductStore.value.isDemoStore,
        "isImmediatelyFulfilled": this.editProductStore.value.isFulfil,
        "itemApprovedStatus": this.editProductStore.value.itemStatus,
        "itemCancelStatus": this.editProductStore.value.itemCancelStatus,
        "itemDeclinedStatus": this.editProductStore.value.itemDeclinedStatus,
        "managedByLot": this.editProductStore.value.lotsManaging,
        "manualAuthIsCapture": this.editProductStore.value.manualAudioCapture,
        "oldHeaderLogo": " ",
        "oldHeaderMiddleBackground": " ",
        "oldHeaderRightBackground": " ",
        "oldStyleSheet": " ",
        "oneInventoryFacility": this.editProductStore.value.oneInventoryFacility,
        "orderDecimalQuantity": this.editProductStore.value.decimalQuantity,
        "orderNumberPrefix": this.editProductStore.value.orderNumberPrefix,
        "payToPartyId": this.editProductStore.value.payPartyID,
        "primaryStoreGroupId": this.editProductStore.value.primaryStoreGroupID,
        "prodSearchExcludeVariants": this.editProductStore.value.excludeVariants,
        "productStoreId": this.editProductStore.value.productStoreID,
        "prorateShipping": this.editProductStore.value.prorateShipping,
        "prorateTaxes": this.editProductStore.value.prorateTax,
        "reqReturnInventoryReceive": this.editProductStore.value.returnInventoryRecieve,
        "reqShipAddrForDigItems": this.editProductStore.value.addrDigItems,
        "requireCustomerRole": this.editProductStore.value.reqCustomerRole,
        "requireInventory": this.editProductStore.value.reqInventory,
        "requirementMethodEnumId": this.editProductStore.value.reqEnumID,
        "reserveInventory": this.editProductStore.value.reserveInventory,
        "reserveOrderEnumId": this.editProductStore.value.reserveEnumID,
        "retryFailedAuths": this.editProductStore.value.retryAuths,
        "selectPaymentTypePerItem": this.editProductStore.value.paymentType,
        "setOwnerUponIssuance": this.editProductStore.value.setIssuance,
        "shipIfCaptureFails": this.editProductStore.value.captureFails,
        "showCheckoutGiftOptions": this.editProductStore.value.giftOptions,
        "showOutOfStockProducts": this.editProductStore.value.showStockProducts,
        "showPricesWithVatTax": this.editProductStore.value.pricesVATIncluded,
        "showTaxIsExempt": this.editProductStore.value.taxExempt,
        "splitPayPrefPerShpGrp": this.editProductStore.value.splitPay,
        "storeCreditAccountEnumId": this.editProductStore.value.storeEnumID,
        "storeCreditValidDays": this.editProductStore.value.creditValidDays,
        "storeName": this.editProductStore.value.storeName,
        "subtitle": this.editProductStore.value.subTitle,
        "title": this.editProductStore.value.title,
        "usePrimaryEmailUsername": this.editProductStore.value.usePrimaryUsername,
        "vatTaxAuthGeoId": this.editProductStore.value.vatGeoID,
        "vatTaxAuthPartyId": this.editProductStore.value.vatPartyID,
        "viewCartOnAdd": this.editProductStore.value.cartOnAdd
        }
       
      this.accountsService.updateProductStore(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); 
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.toastr.error("Permission Denied");
    }

  }



  getCategorySearch() {
    this.spinner.show();
    const req={
   "categoryId": "",
   "categoryIdSearchType": "Contains",
   "categoryName": "",
   "categoryNameSearchType": "Contains"
    }
    this.accountsService.getCategorySearch(this.finSize,req).subscribe((res: any) => {
      this.categoryList = res.data;
      this.spinner.hide();
      for (const value of this.categoryList) {
        this.categoryListArray.push({
          label: value.productCategoryId,
          value: value.productCategoryId
        })
      }
    })
  
  }
  
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
   
    });
  
  }
  getTargetTypeEnumIdList() {
    this.spinner.show();
    this.accountsService.getTargetTypeEnumIdList().subscribe(res => {
      this.TargetTypeEnumIdList = res.data[0].targetTypeEnum;
      this.spinner.hide();
      for (const value of this.TargetTypeEnumIdList) {
        this.TargetTypeEnumIdListArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  
  storeUpdateRole(): void {
    this.spinner.show();
    
      const thruActualdate=this.addRole.get('throughDate').value;
      this.thruDepfromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      
    const requestData ={
      "fromDate":this.assignFromDate,
      "partyId":this.addRole.value.partyID,
      "roleTypeId": this.addRole.value.roleTypeID,
      "sequenceNum": this.addRole.value.seqNum,
      "thruDate":this.thruDepfromdate,
      "productStoreId": this.productStoreId
      }
    
    this.accountsService.storeUpdateRole(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonMM.nativeElement.click();
        this.reset(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    updateProductStoreCatalog(): void {
      this.spinner.show();
      
        const thruActualdate=this.addCatalog.get('throughDate').value;
        this.thruDepfromdateCat=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
        
      const requestData ={
        "fromDate": this.assignFromDateCat,
        "prodCatalogId":this.addCatalog.value.catalog,
        "sequenceNum": this.addCatalog.value.seqNum,
        "thruDate": this.thruDepfromdateCat,
        "productStoreId": this.productStoreId
        }
      
      this.accountsService.updateProductStoreCatalog(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebuttonLL.nativeElement.click();
          this.getProductStoreCatalog(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
    createProductStoreCatalog(): void {
      this.spinner.show();
        const fromActualdate=this.addCatalog.get('fromDate').value;
        this.actualDepromdateCat=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
        const thruActualdate=this.addCatalog.get('throughDate').value;
        this.thruDepfromdateCat=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
        
      const requestData ={
        "fromDate":this.actualDepromdateCat,
        "prodCatalogId":this.addCatalog.value.catalog,
        "sequenceNum": this.addCatalog.value.seqNum,
        "thruDate": this.thruDepfromdateCat,
        "productStoreId": this.productStoreId
        }
      
      this.accountsService.createProductStoreCatalog(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.closebuttonLL.nativeElement.click();
          this.getProductStoreCatalog(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
   
    
      storeCreateShipRate(): void {
        this.spinner.show();
        const requestData ={
          "featurePercent":this.newShipment.value.preFeaturePercent,
          "featurePrice":this.newShipment.value.preFeaturePrice,
          "flatItemPrice": this.newShipment.value.itemPrice,
          "flatPercent":this.newShipment.value.basePercent,
          "flatPrice": this.newShipment.value.basePrice,
          "fromGeo": this.newShipment.value.fromGeo,
          "oversizePrice": this.newShipment.value.oversizeSurchurge,
          "oversizeUnit": this.newShipment.value.oversizeUnit,
          "partyId": this.newShipment.value.partyID,
          "pmax":this.newShipment.value.maxQty,
          "pmin": this.newShipment.value.minQty,
          "pprice": this.newShipment.value.unitPriceQty,
          "priceBreakId": this.newShipment.value.priceBreakID,
          "productFeatureGroupId": this.newShipment.value.featureGroup,
          "productStoreId":this.productStoreId,
          "productStoreShipMethId": this.newShipment.value.shipmentMethod,
          "puom": this.newShipment.value.wtBreakID,
          "qmax": this.newShipment.value.maxWT,
          "qmin": this.newShipment.value.minWT,
          "qprice": this.newShipment.value.unitPricePR,
          "quantityBreakId": this.newShipment.value.qtyBreakID,
          "quom": this.newShipment.value.unitPriceQty,
          "roleTypeId":this.newShipment.value.roleTypeID,
          "shippingPricePercent": this.newShipment.value.shippingPercent,
          "toGeo": this.newShipment.value.toGeo,
          "weightBreakId": this.newShipment.value.wtBreakID,
          "wmax": this.newShipment.value.maxWT,
          "wmin": this.newShipment.value.minWT,
          "wprice":this.newShipment.value.unitPriceWT,
          "wuom": this.newShipment.value.wtBreakID,
          }
        
        this.myContactsService.storeCreateShipRate(requestData).subscribe((res: any) => { 
          if (res.success) {
            this.toastr.success("Created Successfully");
            this.spinner.hide();
            this.closebuttonLM.nativeElement.click();
            this.getActiveOrInActive_All(); 
          }
        }, (err) => {
          this.spinner.hide();
          if(err.error.errors){
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
          }
          else{
            this.toastr.error(err.error.message);
          }
        }); }
  storeCreateRole(): void {
    this.spinner.show();
      const fromActualdate=this.addRole.get('fromDate').value;
      this.actualDepromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.addRole.get('throughDate').value;
      this.thruDepfromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      
    const requestData ={
      "fromDate":  this.actualDepromdate,
      "partyId":this.addRole.value.partyID,
      "roleTypeId": this.addRole.value.roleTypeID,
      "sequenceNum": this.addRole.value.seqNum,
      "thruDate":this.thruDepfromdate,
      "productStoreId": this.productStoreId
      }
    
    this.accountsService.storeCreateRole(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonMM.nativeElement.click();
        this.reset(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  updateProductStoreEmail(): void {
    this.spinner.show();
   
    const requestData ={
      "bccAddress":  this.addEmailSetup.value.bccAddress,
      "bodyScreenLocation": this.addEmailSetup.value.bodyScreenLocation,
      "ccAddress": this.addEmailSetup.value.bccAddress,
      "contentType": this.addEmailSetup.value.contentType,
      "emailType": this.addEmailSetup.value.emailType,
      "fromAddress":this.addEmailSetup.value.fromAddress,
      "productStoreId": this.productStoreId,
      "subject": this.addEmailSetup.value.subject,
      "xslfoAttachScreenLocation":this.addEmailSetup.value.attachScreenLocation,
      }
    
    this.accountsService.updateProductStoreEmail(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonRA.nativeElement.click();
        this.getProductStoreEmailSetupById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    storeUpdatePaySetting(): void {
      this.spinner.show();
      const requestData ={
        "applyToAllProducts":this.addPaymentSetup.value.applyProducts,
        "paymentCustomMethodId": this.addPaymentSetup.value.custMethod,
        "paymentGatewayConfigId":this.addPaymentSetup.value.gatewayConfigID,
        "paymentMethodTypeId":this.addPaymentSetup.value.methodType,
        "paymentPropertiesPath":this.addPaymentSetup.value.paymentProps,
        "paymentService": this.addPaymentSetup.value.serviceName,
        "paymentServiceTypeEnumId": this.addPaymentSetup.value.serviceType,
        "productStoreId": this.productStoreId,
        }
      
      this.accountsService.storeUpdatePaySetting(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebuttonHH.nativeElement.click();
          this.getProductStorePaymentListById(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
    storeCreatePaySetting(): void {
      this.spinner.show();
      const requestData ={
        "applyToAllProducts":this.addPaymentSetup.value.applyProducts,
        "paymentCustomMethodId": this.addPaymentSetup.value.custMethod,
        "paymentGatewayConfigId":this.addPaymentSetup.value.gatewayConfigID,
        "paymentMethodTypeId":this.addPaymentSetup.value.methodType,
        "paymentPropertiesPath":this.addPaymentSetup.value.paymentProps,
        "paymentService": this.addPaymentSetup.value.serviceName,
        "paymentServiceTypeEnumId": this.addPaymentSetup.value.serviceType,
        "productStoreId": this.productStoreId,
        }
      
      this.accountsService.storeCreatePaySetting(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.closebuttonHH.nativeElement.click();
          this.getProductStorePaymentListById(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
  createProductStoreEmail(): void {
    this.spinner.show();
   
    const requestData ={
      "bccAddress":  this.addEmailSetup.value.bccAddress,
      "bodyScreenLocation": this.addEmailSetup.value.bodyScreenLocation,
      "ccAddress": this.addEmailSetup.value.bccAddress,
      "contentType": this.addEmailSetup.value.contentType,
      "emailType": this.addEmailSetup.value.emailType,
      "fromAddress":this.addEmailSetup.value.fromAddress,
      "productStoreId": this.productStoreId,
      "subject": this.addEmailSetup.value.subject,
      "xslfoAttachScreenLocation":this.addEmailSetup.value.attachScreenLocation,
      }
    
    this.accountsService.createProductStoreEmail(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonRA.nativeElement.click();
        this.getProductStoreEmailSetupById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  createSegmentGroup(): void {
    this.spinner.show();

    const requestData ={
      "description":  this.addSegment.value.desc,
      "productStoreId":  this.addSegment.value.productStoreID,
      "segmentGroupTypeId":  this.addSegment.value.segmentTypeID

      }
    
    this.accountsService.createSegmentGroup(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getViewProductStoreSegmentsId();
        this.closebuttonC.nativeElement.click();
      //  this.getSegmentGroupFrontPage(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  getSegmentGroupTypeID() {
    
    this.spinner.show();
    this.myContactsService.getSegmentGroupTypeID().subscribe(res => {
      this.SegmentGroupTypeID = res.data[0].data;
      this.spinner.hide();
      for (const value of this.SegmentGroupTypeID) {
        this.SegmentGroupTypeIDArray.push({
          label: value.segmentGroupTypeId,
          value: value.segmentGroupTypeId
        })
      }
    })
  }
  getProductStoreID() {
    this.spinner.show();
    this.myContactsService.getProductStoreID().subscribe(res => {
      this.ProductStoreID = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ProductStoreID) {
        this.ProductStoreIDArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }
    })
  }
  addOveride(){
    this.show=false;
    this.addKeyword.reset();
  }
  updateEvent(product){
    this.show=true;
    this.addKeyword.reset();
    const fromdate=product.fromDate
    this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
    const toEstdate=product.thruDate
    this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
    this.fromDateUpdate=product.fromDate;
      setTimeout(() => {
      
        const formValue = this.addKeyword;
        formValue.patchValue({
      keyword:product.node.keyword,
      fromDate: this.getEstimateFrom,
      throughDate: this.getEstimateTo,
      target: product.node.target,
      targetEnum: product.node.targetTypeEnumId,
        })
      
      }, 2000);
  
  }
  updateFin(product){
    this.showFin=true;
    this.addFinancial.reset();
    
      setTimeout(() => {
      
        const formValue = this.addFinancial;
        formValue.patchValue({
          financialID:product.finAccountTypeId,
          requirePinCode: product.requirePinCode,
          validateGift:product.validateGCFinAcct,
          codeLength: product.accountCodeLength,
          pinCodeLength:product.pinCodeLength,
          accValidDays:product.accountValidDays,
          authValidDays:product.authValidDays,
          purchaseSurveyID: product.purchaseSurveyId,
          purchSurveyTo: product.purchSurveySendTo,
          purchSurveyCopyMe: product.purchSurveyCopyMe,
          allowAuthNegative: product.allowAuthToNegative,
          minBalance: product.minBalance,
          replenishThreshold:product.replenishThreshold,
          replenishEnumID:product.replenishMethodEnumId,
        })
      
      }, 2000);
  
  }
  updatePayment(product){
    this.showPay=true;
    this.addPaymentSetup.reset();
    
      setTimeout(() => {
       
        const formValue = this.addPaymentSetup;
        formValue.patchValue({
          methodType:product.productStorePaymentList.paymentMethodTypeId,
          serviceType:product.productStorePaymentList.paymentServiceTypeEnumId,
          serviceName:product.productStorePaymentList.paymentService,
          custMethod:product.productStorePaymentList.paymentCustomMethodId,
          gatewayConfigID: product.productStorePaymentList.paymentGatewayConfigId,
          paymentProps:product.productStorePaymentList.paymentPropertiesPath,
          applyProducts:product.productStorePaymentList.applyToAllProducts,
        })
      
      }, 2000);
  
  }
  
  updateAddress(product){
    this.showEmail=true;
    this.addEmailSetup.reset();
    
      setTimeout(() => {
      
        const formValue = this.addEmailSetup;
        formValue.patchValue({
          emailType:product.emailType,
          bodyScreenLocation: product.bodyScreenLocation,
          attachScreenLocation: product.xslfoAttachScreenLocation,
          fromAddress:product.fromAddress,
          ccAddress:product.ccAddress,
          bccAddress:product.bccAddress,
          subject:product.subject,
          contentType: product.contentType,
        })
      
      }, 2000);
  
  }
  
  updateProductStoreKeywordOvrd(): void {
    this.spinner.show();
  
      const thruActualdate=this.addKeyword.get('throughDate').value;
      this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "fromDate": this.fromDateUpdate,
      "keyword":this.addKeyword.value.keyword,
      "target":this.addKeyword.value.target,
      "targetTypeEnumId": this.addKeyword.value.targetEnum,
      "thruDate":this.thrufromdate,
      "productStoreId": this.productStoreId
   
      }
     
    this.accountsService.updateProductStoreKeywordOvrd(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonD.nativeElement.click();
        this.getProductStoreKeywordOvrdById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    createProductStoreSurveyAppl(): void {
      this.spinner.show();
     
        const fromActualdate=this.addSurvey.get('fromDate').value;
        this.actualfromdateSurvey=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
        const thruActualdate=this.addSurvey.get('throughDate').value;
        this.thrufromdateSuvey=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
        "fromDate": this.actualfromdateSurvey,
        "groupName":this.addSurvey.value.groupName,
        "productCategoryId":this.addSurvey.value.categoryID,
        "productId":this.addSurvey.value.productID,
        "resultTemplate": this.addSurvey.value.resultPath,
        "sequenceNum": this.addSurvey.value.seqNum,
        "surveyApplTypeId": this.addSurvey.value.type,
        "surveyId": this.addSurvey.value.surveys,
        "surveyTemplate": this.addSurvey.value.surveyPath,
        "thruDate":  this.thrufromdateSuvey,
        "productStoreId": this.productStoreId
     
        }
       
      this.accountsService.createProductStoreSurveyAppl(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.closebuttonE.nativeElement.click();
          this.getProductSurveyListById(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
      
      CreateProductStoreFinAccountSettings(): void {
        this.spinner.show();
        
        const requestData ={
          "accountCodeLength":this.addFinancial.value.codeLength,
          "accountValidDays":this.addFinancial.value.accValidDays,
          "allowAuthToNegative": this.addFinancial.value.allowAuthNegative,
          "authValidDays":this.addFinancial.value.authValidDays,
          "finAccountTypeId": this.addFinancial.value.financialID,
          "minBalance": this.addFinancial.value.minBalance,
          "pinCodeLength": this.addFinancial.value.pinCodeLength,
          "purchSurveyCopyMe":this.addFinancial.value.purchSurveyCopyMe,
          "purchSurveySendTo": this.addFinancial.value.purchSurveyTo,
          "purchaseSurveyId": this.addFinancial.value.purchaseSurveyID,
          "replenishMethodEnumId":this.addFinancial.value.replenishEnumID,
          "replenishThreshold": this.addFinancial.value.replenishThreshold,
          "requirePinCode": this.addFinancial.value.requirePinCode,
          "spacer":'',
          "validateGCFinAcct": this.addFinancial.value.validateGift,
          "productStoreId": this.productStoreId
       
          }
         
        this.accountsService.CreateProductStoreFinAccountSettings(requestData).subscribe((res: any) => { 
          if (res.success) {
            this.toastr.success("Created Successfully");
            this.spinner.hide();
            this.closebuttonH.nativeElement.click();
            this.getProductStoreFinAccountSettingsListById(); 
          }
        }, (err) => {
          this.spinner.hide();
          if(err.error.errors){
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
          }
          else{
            this.toastr.error(err.error.message);
          }
        }); }
        updateProductStoreFinAccountSettings(): void {
          this.spinner.show();
          
          const requestData ={
            "accountCodeLength":this.addFinancial.value.codeLength,
            "accountValidDays":this.addFinancial.value.accValidDays,
            "allowAuthToNegative": this.addFinancial.value.allowAuthNegative,
            "authValidDays":this.addFinancial.value.authValidDays,
            "finAccountTypeId": this.addFinancial.value.financialID,
            "minBalance": this.addFinancial.value.minBalance,
            "pinCodeLength": this.addFinancial.value.pinCodeLength,
            "purchSurveyCopyMe":this.addFinancial.value.purchSurveyCopyMe,
            "purchSurveySendTo": this.addFinancial.value.purchSurveyTo,
            "purchaseSurveyId": this.addFinancial.value.purchaseSurveyID,
            "replenishMethodEnumId":this.addFinancial.value.replenishEnumID,
            "replenishThreshold": this.addFinancial.value.replenishThreshold,
            "requirePinCode": this.addFinancial.value.requirePinCode,
            "spacer":'',
            "validateGCFinAcct": this.addFinancial.value.validateGift,
            "productStoreId": this.productStoreId
         
            }
           
          this.accountsService.updateProductStoreFinAccountSettings(requestData).subscribe((res: any) => { 
            if (res.success) {
              this.toastr.success("Updated Successfully");
              this.spinner.hide();
              this.closebuttonH.nativeElement.click();
              this.getProductStoreFinAccountSettingsListById(); 
            }
          }, (err) => {
            this.spinner.hide();
            if(err.error.errors){
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
            }
            else{
              this.toastr.error(err.error.message);
            }
          }); }
           
          updateProductStorePromoAppl(): void {
            this.spinner.show();
          
             
              const thruActualdate=this.addPromo.get('throughDate').value;
              this.thrufromdatePromo=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
            const requestData ={
            
              "fromDate": this.DatePromo,
              "manualOnly":this.addPromo.value.manualOnly,
              "productPromoId":this.addPromo.value.promoID,
              "productStoreId": this.productStoreId,
              "thruDate": this.thrufromdatePromo
       
           
              }
             
            this.accountsService.updateProductStorePromoAppl(requestData).subscribe((res: any) => { 
              if (res.success) {
                this.toastr.success("Updated Successfully");
                this.spinner.hide();
                this.closebuttonJJ.nativeElement.click();
                this.getActiveOrInActive_All(); 
              }
            }, (err) => {
              this.spinner.hide();
              if(err.error.errors){
              for (const value of err.error.errors) {
                this.toastr.error(value.fieldName + ' ' + value.fieldError);
              }
              }
              else{
                this.toastr.error(err.error.message);
              }
            }); }
          createProductStorePromoAppl(): void {
            this.spinner.show();
          
              const fromActualdate=this.addPromo.get('fromDate').value;
              this.actualfromdatePromo=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
              const thruActualdate=this.addPromo.get('throughDate').value;
              this.thrufromdatePromo=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
            const requestData ={
            
              "fromDate": this.actualfromdatePromo,
              "manualOnly":this.addPromo.value.manualOnly,
              "productPromoId":this.addPromo.value.promoID,
              "productStoreId": this.productStoreId,
              "thruDate": this.thrufromdatePromo
       
           
              }
             
            this.accountsService.createProductStorePromoAppl(requestData).subscribe((res: any) => { 
              if (res.success) {
                this.toastr.success("Created Successfully");
                this.spinner.hide();
                this.closebuttonJJ.nativeElement.click();
                this.getActiveOrInActive_All(); 
              }
            }, (err) => {
              this.spinner.hide();
              if(err.error.errors){
              for (const value of err.error.errors) {
                this.toastr.error(value.fieldName + ' ' + value.fieldError);
              }
              }
              else{
                this.toastr.error(err.error.message);
              }
            }); }
      addProductStoreFacility(): void {
        this.spinner.show();
      
          const fromActualdate=this.addFacility.get('fromDate').value;
          this.actualfromdateFac=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
          const thruActualdate=this.addFacility.get('throughDate').value;
          this.thrufromdateFac=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
        const requestData ={

          "facilityId":this.addFacility.value.facilityID,
          "fromDate":this.actualfromdateFac,
          "sequenceNum": this.addFacility.value.seqNum,
          "thruDate":  this.thrufromdateFac,
          "productStoreId": this.productStoreId
       
          }
         
        this.accountsService.addProductStoreFacility(requestData).subscribe((res: any) => { 
          if (res.success) {
            this.toastr.success("Created Successfully");
            this.spinner.hide();
            this.closebuttonG.nativeElement.click();
            this.getStoreFacilitySettingById(); 
          }
        }, (err) => {
          this.spinner.hide();
          if(err.error.errors){
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
          }
          else{
            this.toastr.error(err.error.message);
          }
        }); }
  createProductStoreKeywordOvrd(): void {
    this.spinner.show();
  
      const fromActualdate=this.addKeyword.get('fromDate').value;
      this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.addKeyword.get('throughDate').value;
      this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "fromDate": this.actualfromdate,
      "keyword":this.addKeyword.value.keyword,
      "target":this.addKeyword.value.target,
      "targetTypeEnumId": this.addKeyword.value.targetEnum,
      "thruDate":this.thrufromdate,
      "productStoreId": this.productStoreId
   
      }
     
    this.accountsService.createProductStoreKeywordOvrd(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonD.nativeElement.click();
        this.getProductStoreKeywordOvrdById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  createProductStoreVendorPayment(): void {
    this.spinner.show();
  
    const requestData ={
      "vendorPartyId":this.addVendorPayment.value.partyID,
      "productStoreId": this.productStoreId,
      "paymentMethodTypeId":this.addVendorPayment.value.paymentType,
      "creditCardEnumId": this.addVendorPayment.value.creditCardEnumID,
   
      }
     
    this.accountsService.createProductStoreVendorPayment(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonB.nativeElement.click();
        this.addVendorPayment.reset();
        this.getProductStoreVendorPaymentListById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  createProductStoreVendorShipment(): void {
    this.spinner.show();
  
    const requestData ={
      "carrierPartyId":this.addVendorShipment.value.carrierPartyID,
      "productStoreId": this.productStoreId,
      "shipmentMethodTypeId":this.addVendorShipment.value.shipmentTypeID,
      "vendorPartyId": this.addVendorShipment.value.vendorPartyID,
   
      }
     
    this.accountsService.createProductStoreVendorShipment(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonA.nativeElement.click();
        this.getProductVendorStoreShipmentListById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    getReplenishEnumTypeIdList() {
      this.spinner.show();
      this.accountsService.getReplenishEnumTypeIdList().subscribe(res => {
        this.ReplenishEnumTypeIdList = res.data[0].replenishEnumTypeIdList;
        this.spinner.hide();
        for (const value of this.ReplenishEnumTypeIdList) {
          this.ReplenishEnumTypeIdListArray.push({
            label: value.description,
            value: value.enumId
          })
        }
      })
      
    }
    getFinAccountTypeIdList() {
      this.spinner.show();
      this.accountsService.getFinAccountTypeIdList().subscribe(res => {
        this.FinAccountTypeIdList = res.data[0].findAccountTypeList;
        this.spinner.hide();
        for (const value of this.FinAccountTypeIdList) {
          this.FinAccountTypeIdListArray.push({
            label: value.finAccountTypeId,
            value: value.finAccountTypeId
          })
        }
      })
      
    }
    getFacilityIdListValue() {
      this.spinner.show();
      this.accountsService.getFacilityIdListValue().subscribe(res => {
        this.FacilityIdListValue = res.data[0].facilityData;
        this.spinner.hide();
        for (const value of this.FacilityIdListValue) {
          this.FacilityIdListValueArray.push({
            label: value.facilityName,
            value: value.facilityId
          })
        }
      })
      
    }
    getSurveyTypeList() {
      this.spinner.show();
      this.accountsService.getSurveyTypeList().subscribe(res => {
        this.SurveyTypeList = res.data[0].node;
        this.spinner.hide();
        for (const value of this.SurveyTypeList) {
          this.SurveyTypeListArray.push({
            label: value.description,
            value: value.surveyApplTypeId
          })
        }
      })
      
    }
    
    getStorePromoList() {
      this.spinner.show();
      this.accountsService.getStorePromoList().subscribe(res => {
        this.StorePromoList = res.data[0].productPromoList;
        this.spinner.hide();
        for (const value of this.StorePromoList) {
          this.StorePromoListArray.push({
            label: value.productPromoId,
            value: value.productPromoId
          })
        }
      })
      
    }
    getSurveyListValue() {
      this.spinner.show();
      this.accountsService.getSurveyListValue().subscribe(res => {
        this.SurveyListValue = res.data[0].node;
        this.spinner.hide();
        for (const value of this.SurveyListValue) {
          this.SurveyListValueArray.push({
            label: value.description,
            value: value.surveyId
          })
        }
      })
      
    }
    getPaymentMethodTypeList() {
      this.spinner.show();
      this.accountsService.getPaymentMethodTypeList().subscribe(res => {
        this.PaymentMethodTypeList = res.data[0].paymentMethodType;
        this.spinner.hide();
        for (const value of this.PaymentMethodTypeList) {
          this.PaymentMethodTypeListArray.push({
            label: value.description,
            value: value.paymentMethodTypeId
          })
        }
      })
      
    }
    getCreditCardEnumIdList() {
      this.spinner.show();
      this.accountsService.getCreditCardEnumIdList().subscribe(res => {
        this.CreditCardEnumIdList = res.data[0].creditCardTypeList;
        this.spinner.hide();
        for (const value of this.CreditCardEnumIdList) {
          this.CreditCardEnumIdListArray.push({
            label: value.enumCode,
            value: value.enumId
          })
        }
      })
      
    }
  getShipmentMethodTypeIdList() {
    this.spinner.show();
    this.accountsService.getShipmentMethodTypeIdList().subscribe(res => {
      this.ShipmentMethodTypeIdList = res.data[0].shipmentMethod;
      this.spinner.hide();
      for (const value of this.ShipmentMethodTypeIdList) {
        this.ShipmentMethodTypeIdListArray.push({
          label: value.shipmentMethodTypeId,
          value: value.shipmentMethodTypeId
        })
      }
    })
    
  }
  
  storeRemoveShipMeth(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "productStoreShipMethId": product.shipmentMeth.productStoreShipMethId,
       
          "view_INDEX_1": "0",
          "view_SIZE_1": "20",
          "productStoreId": this.productStoreId,
         
         
        }
        this.accountsService.storeRemoveShipMeth(req).subscribe(res => {
          this.getProductStoreShipmentSetupById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteProductStoreKeywordOvrd(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
        
          "fromDate":product.fromDate,
          "keyword": product.node.keyword,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "productStoreId": this.productStoreId,
         
         
        }
        this.accountsService.deleteProductStoreKeywordOvrd(req).subscribe(res => {
          this.getProductStoreKeywordOvrdById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  storeRemoveRole(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
        
          "fromDate":product.fromDate,
          "partyId":product.data.partyId,
          "roleTypeId":product.data.roleTypeId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "productStoreId": this.productStoreId,
         
         
        }
        this.accountsService.storeRemoveRole(req).subscribe(res => {
          this.reset();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteProductStoreSurveyAppl(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
        
           "productStoreSurveyId": product.node.productStoreSurveyId,
          "productStoreId": this.productStoreId,
         
         
        }
        this.accountsService.deleteProductStoreSurveyAppl(req).subscribe(res => {
          this.getProductSurveyListById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteProductStoreVendorPayment(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "creditCardEnumId": product.psVendorPayment.creditCardEnumId,
          "paymentMethodTypeId": product.psVendorPayment.paymentMethodTypeId,
          "vendorPartyId": product.psVendorPayment.vendorPartyId,
          "productStoreId": this.productStoreId,
         
         
        }
        this.accountsService.deleteProductStoreVendorPayment(req).subscribe(res => {
          this.getProductStoreVendorPaymentListById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  deleteDataSource(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "carrierPartyId": product.pvShipment.carrierPartyId,
          "productStoreId": this.productStoreId,
          "shipmentMethodTypeId": product.pvShipment.shipmentMethodTypeId,
          "vendorPartyId": product.pvShipment.vendorPartyId
         
         
        }
        this.accountsService.deleteProductStoreVendorShipment(req).subscribe(res => {
          this.getProductVendorStoreShipmentListById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteSegmentGroupById(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "segmentGroupId":product.segmentGroupId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
         
         
        }
        this.accountsService.deleteSegmentGroupById(req).subscribe(res => {
          this.getViewProductStoreSegmentsId();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteProductStoreFacility(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "facilityId": product.data.facilityId,
          "fromDate": product.fromDate,
          "productStoreId": this.productStoreId
         
         
        }
        this.accountsService.deleteProductStoreFacility(req).subscribe(res => {
          this.getStoreFacilitySettingById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  RemoveProductStoreFinAccountSettings(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "finAccountTypeId": product.finAccountTypeId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "productStoreId": this.productStoreId
         
         
        }
        this.accountsService.RemoveProductStoreFinAccountSettings(req).subscribe(res => {
          this.getProductStoreFinAccountSettingsListById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  removeProductStoreEmail(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "emailType": product.emailType,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "productStoreId": this.productStoreId
         
         
        }
        this.accountsService.removeProductStoreEmail(req).subscribe(res => {
          this.getProductStoreEmailSetupById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  storeRemovePaySetting(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "paymentMethodTypeId": product.productStorePaymentList.paymentMethodTypeId,
          "paymentServiceTypeEnumId":product.productStorePaymentList.paymentServiceTypeEnumId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "productStoreId": this.productStoreId
         
         
        }
        this.accountsService.storeRemovePaySetting(req).subscribe(res => {
          this.getProductStorePaymentListById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  deleteProductStoreCatalog(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fromDate":product.fromDate,
          "prodCatalogId": product.productStoreList.prodCatalogId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "productStoreId": this.productStoreId
         
         
        }
        this.accountsService.deleteProductStoreCatalog(req).subscribe(res => {
          this.getProductStoreCatalog();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  storeRemoveShipRate(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "shipmentCostEstimateId":product.shipmentList.shipmentCostEstimateId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "productStoreId": this.productStoreId
         
         
        }
        this.accountsService.storeRemoveShipRate(req).subscribe(res => {
          this.getProductStoreShipmentEstiById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  addPromoStore(){
    this.addPromo.reset();
    this.showPromo=false;
  }
  addCatalogData(){
    this.addCatalog.reset();
    this.showCat=false;

  }
  getProductStoreCatalog() {
    this.spinner.show();
    this.accountsService.getProductStoreCatalog(this.productStoreId).subscribe(res => {
      this.ProductStoreCatalog = res.data;
      this.spinner.hide();
    
    })
    
  }
  getProductStorePaymentListById() {
    this.spinner.show();
    this.accountsService.getProductStorePaymentListById(this.productStoreId).subscribe(res => {
      this.ProductStorePaymentListById = res.data;
      this.spinner.hide();
    
    })
    
  }
  getProductStoreEmailSetupById() {
    this.spinner.show();
    this.accountsService.getProductStoreEmailSetupById(this.productStoreId).subscribe(res => {
      this.ProductStoreEmailSetupById = res.data[0].node;
      this.spinner.hide();
    
    })
    
  }
  getProductStoreFinAccountSettingsListById() {
    this.spinner.show();
    this.accountsService.getProductStoreFinAccountSettingsListById(this.productStoreId).subscribe(res => {
      this.ProductStoreFinAccountSettingsListById = res.data[0].psfsData;
      this.spinner.hide();
    
    })
    
  }
  getStoreFacilitySettingById() {
    this.spinner.show();
    this.accountsService.getStoreFacilitySettingById(this.productStoreId).subscribe(res => {
      this.StoreFacilitySettingById = res.data;
      this.spinner.hide();
    
    })
    
  }
  getProductStoreKeywordOvrdById() {
    this.spinner.show();
    this.accountsService.getProductStoreKeywordOvrdById(this.productStoreId).subscribe(res => {
      this.ProductStoreKeywordOvrdById = res.data;
      this.spinner.hide();
    
    })
    
  }
  
  getViewProductStoreSegmentsId() {
    this.spinner.show();
    this.accountsService.getViewProductStoreSegmentsId(this.productStoreId).subscribe(res => {
      this.ViewProductStoreSegmentsId = res.data[0].getViewProductStoreSegmentsId;
      this.spinner.hide();
    
    })
    
  }
  
  getProductStoreShipMethId() {
    this.spinner.show();
    this.accountsService.getProductStoreShipMethId(this.productStoreId).subscribe(res => {
      this.ProductStoreShipMethId = res.data;
      for (const value of this.ProductStoreShipMethId) {
        this.ProductStoreShipMethIdArray.push({
          label: value.productStoreShipmentMeth.productStoreShipMethId,
          value: value.productStoreShipmentMeth.productStoreShipMethId
        })
      }
      this.spinner.hide();
    
    })
    
  }
  getProductStoreShipmentEstiById() {
    this.spinner.show();
    this.accountsService.getProductStoreShipmentEstiById(this.productStoreId).subscribe(res => {
      this.ProductStoreShipmentEstiById = res.data;
      this.spinner.hide();
    
    })
    
  }
  getActiveOrInActive_All() {
    this.spinner.show();
    this.accountsService.getActiveOrInActive_All(this.productStoreId).subscribe(res => {
      this.ActiveOrInActive_All = res.data;
      this.spinner.hide();
    
    })
    
  }
  
  getProductStoreShipmentSetupById() {
    this.spinner.show();
    this.accountsService.getProductStoreShipmentSetupById(this.productStoreId).subscribe(res => {
      this.ProductStoreShipmentSetupById = res.data;
      this.spinner.hide();
    
    })
    
  }
  getProductSurveyListById() {
    this.spinner.show();
    this.accountsService.getProductSurveyListById(this.productStoreId).subscribe(res => {
      this.ProductSurveyListById = res.data;
      this.spinner.hide();
    
    })
    
  }
  getProductVendorStoreShipmentListById() {
    this.spinner.show();
    this.accountsService.getProductVendorStoreShipmentListById(this.productStoreId).subscribe(res => {
      this.ProductVendorStoreShipmentListById = res.data;
      this.spinner.hide();
    
    })
    
  }
  promos_Page(id) {
    this._Router.navigate(["/facilities/catalog/promos/editPromo"], 
    { queryParams: { productPromoId: id } })
  }
  getProductStoreVendorPaymentListById() {
    this.spinner.show();
    this.accountsService.getProductStoreVendorPaymentListById(this.productStoreId).subscribe(res => {
      this.ProductStoreVendorPaymentListById = res.data;
      this.spinner.hide();
    
    })
    
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
 
  newProduct() {
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this._Router.navigate(["/facilities/catalog/stores/new-product-store"])
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.toastr.error("Permission Denied");
    }
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  customerActiveCategoryFunction(tab: number)
    {
      this.customerCategoryPopup = tab;
    
    }
    
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}