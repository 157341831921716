import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { ProductBacklogComponent } from 'src/app/scrum/product-backlog/product-backlog.component';
import Swal from 'sweetalert2';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';

@Component({
  selector: 'app-detail-note',
  templateUrl: './detail-note.component.html',
  styleUrls: ['./detail-note.component.css']
})
export class DetailNoteComponent implements OnInit {
  noteId: any;
  taskNoteByIdList: any;

  constructor(
    readonly activatedRoute: ActivatedRoute,
        private _location: Location,
        readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.noteId = params.noteId;

    })

    this.getTaskNoteById();
  } 


  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this.router.navigate(['/psa/Dashboard']);
}
getTaskNoteById() {
  this.spinner.show();
  this.myContactsService.getTaskNoteById(this.noteId).subscribe(res => {
    this.taskNoteByIdList = res.data[0];
    this.spinner.hide();
   
  })
}
}
