import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { array } from '@amcharts/amcharts4/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-report-builder',
  templateUrl: './report-builder.component.html',
  styleUrls: ['./report-builder.component.css']
})
export class ReportBuilderComponent implements OnInit {
  @ViewChild('closebutton4')closebutton4;
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  selectStarSchema: FormGroup;
  dropdownBI: { label: string; value: string; }[];
  ReportBuilderSelectData: any;
  starSchemaName: any;
  showTable: boolean;
  reportFormArray:Array<any> = [];
  payIdArrayWith: any[]=[];
  requestDataRecon: any[];

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    readonly _CategoryService: CategoryService,
    readonly router: Router,
    private _location: Location,
  ) 
  {
    this.selectStarSchema=this._FormBuilder.group({
      startschema:"",
    })
    this.dropdownBI =[
      {
        label:"Inventory Item Schema",
        value:"InventoryItemSchema",

      },
      {
        label:"Sales Invoice Item Star Schema",
        value:"SalesInvoiceItemStarSchema",

      },
      {
        label:"Sales Order Item Star Schema",
        value:"SalesOrderItemStarSchema",

      },
    ]
   }

  ngOnInit(): void {
    this.showTable = false;
  }
  // sendmarkChecksSent() {
  //   this.spinner.show();

  //   this.requestDataRecon = this.payIdArrayWith




  //   this.accountsService.sendmarkChecksSent(this.requestDataRecon).subscribe((res: any) => {

  //     if (res.success) {
       
  //       this.toastr.success("Send Successfully");
  //       this.spinner.hide();

  //     }
  //   }, (err) => {
  //     this.spinner.hide();
  //     if (err.error.errors) {
  //       for (const value of err.error.errors) {
  //         this.toastr.error(value.fieldName + ' ' + value.fieldError);
  //       }


  //     }
  //     else {
  //       this.toastr.error(err.error.message);
  //     }
  //   });

  // }
  backSubmit(){
    this._location.back();
  }
    toReports()
{
  this._Router.navigate(['/business-intelligence/reports-dashboard']);
}

markCheckPrint(e) {

  if (e.target.ariaChecked === "true") {
    for (const r of this.ReportBuilderSelectData) {

      this.payIdArrayWith.push(r.name);
    }
  }
  else {
    this.payIdArrayWith = []
  }
}
payment(product) {

  const index = this.payIdArrayWith.findIndex(x => x == product)
  if (index === -1) {

    this.payIdArrayWith.push(product);

  }
  else if (index === 0) {

    this.payIdArrayWith.splice(index, 1);
  }
  else {
    this.payIdArrayWith.splice(index, 1);

  }

  console.log(this.payIdArrayWith,"payIdArrayWith")
}
nextPage(){
  this._Router.navigate(["business-intelligence/detail-report-builder"], 
    { queryParams: { payIdArrayWith: this.payIdArrayWith } })
 
    //localStorage.setItem('payIdArrayWith', this.payIdArrayWith);
}

  submit(){
    this.ReportBuilderSelectStarSchemaFields();
    this.showTable = true;
    localStorage.setItem('starSchemaName', this.selectStarSchema.value.startschema);
  }
  // detailPage(id){
  //   this._Router.navigate(["business-intelligence/detail-report-builder"], 
  //   { queryParams: { starSchemaName: id } })
  // }
  detailPage(){
    this._Router.navigate(["business-intelligence/detail-report-builder"])
  }
  ReportBuilderSelectStarSchemaFields() {
    this.spinner.show();
    this.myContactsService.ReportBuilderSelectStarSchemaFields(this.selectStarSchema.value.startschema).subscribe(res => {
      this.ReportBuilderSelectData = res.data.starSchemaFields;
      this.ReportBuilderSelectData = res.data.starSchemaFields;
      this.spinner.hide();

    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
