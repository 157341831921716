import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { WarehouseService } from '../warehouse/warehouse.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { DatePipe } from '@angular/common';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';




@Component({
  selector: 'app-detail-inventory-items',
  templateUrl: './detail-inventory-items.component.html',
  styleUrls: ['./detail-inventory-items.component.css']
})
export class DetailInventoryItemsComponent implements OnInit {
  @ViewChild('closebutton2') closebutton2;

  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  inventoryItemLablesForm: FormGroup;
  inventoryItemDetail: any;
  facilityId: any;
  inventoryItemId: any;
  getinventoryItemLabel: any;
  show: boolean;
  updateLableAppllicablityForm: FormGroup;
  updateForumGroupPat: any;
  updatenventoryItemLabelPat: any;
  inventoryItemLabelIdArray: any[]=[];
  inventorySummaryPage: any;
  inventoryItemForm: FormGroup;
  currencyIds: any;
  products: any;
  partyIds: any;
  facilityIds: any;
  statusIds: any;
  inventoryItemTypeIds: any;
  facilityLocations: any;
  editMode: any;
  LocationSeqId: any;
  LocationSeqIdArray: any;
  inventoryItemDetailSecond: any;

  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly _WarehouseService: WarehouseService,
    readonly _ToastrService: ToastrService,
    readonly  _Router: Router,
    readonly accountsService: AccountsService,
    readonly datePipe: DatePipe,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
) 
  {
    this.inventoryItemLablesForm=this._FormBuilder.group({
      inventoryItemLabelID:"",
      sequenceNum:"",
    })

    this.updateLableAppllicablityForm=this._FormBuilder.group({
      inventoryItemLabelID:"",
      sequenceNum:"",
    })

    this.inventoryItemForm = this._FormBuilder.group({
      inventoryItemID:[''],
      accountingQuantityTotal: [''],
      activationNumber: [''],
      activationValidThru: [new Date()],
      binNumber: [''],
      comments: [''],
      containerId: [''],
      currencyUomId: [null],
      datetimeManufactured: [new Date()],
      datetimeReceived: [new Date()],
      expireDate: [new Date()],
      facilityId: [null],
      fixedAssetId: [''],
      inventoryItemTypeId: [null],
      locationSeqId: [null],
      lotId: [''],
      ownerPartyId: [null],
      partyId: [null],
      productId: [null, [Validators.required]],
      serialNumber: [''],
      softIdentifier: [''],
      statusId: [null],
      unitCost: [''],
      uomId: ['']
  });


    

  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.facilityId = params["facilityId"];
      this.inventoryItemId = params["inventoryItemId"];

    });
    this.ViewInventoryItemDetail();
    this.inventoryItemLabelId();
    this.EditInventoryItemLabels();
    this.getInventorySummaryPage();

    this.getInventoryTypeIds();
    this.getCurrency();
    this.getProducts();
    this.getPartyIds();
    this.getStatusIds();
    this.getFacilityIds();
    this.getFacilityLocation();
    this.ViewInventoryItemDetailSecond();
  }

  
  ViewInventoryItemDetail() {
    this.spinner.show();
    this.accountsService.ViewInventoryItemDetail(this.facilityId,this.inventoryItemId).subscribe(res => {
      this.inventoryItemDetail = res.data[0].dataNode;
      this.spinner.hide();
    })
  }

  ViewInventoryItemDetailSecond() {
    this.spinner.show();
    this.accountsService.ViewInventoryItemDetailSecond(this.facilityId,this.inventoryItemId).subscribe(res => {
      this.inventoryItemDetailSecond = res.data[0].dataNode;
      this.spinner.hide();
    })
  }

  

  EditInventoryItemLabels() {
    this.spinner.show();
    this.accountsService.EditInventoryItemLabels(this.inventoryItemId).subscribe(res => {
      this.getinventoryItemLabel = res.data[0].dataNode;
      this.spinner.hide();
    })
  }

  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
        .then(data => {
            if (data.success) {
                this.spinner.hide();
                this.currencyIds = data.data.currencyList;
            }
        });
       
}

getFacilityLocation(): void {
  this.spinner.show();
  this._WarehouseService.getFacilityLocations(this.facilityId, { pageNo: 1, pageSize: 200 }, {
      locationSeqId: '',
      aisle: '',
      area: '',
      level: '',
      position: '',
      section: ''
  })
      .then(data => {
          this.facilityLocations = data.data;
          this.spinner.hide();
      });
      
}

getInventoryTypeIds(): void {
    this.spinner.show();
    this._WarehouseService.defaultInventory()
        .then(data => {
            this.spinner.hide();
            this.inventoryItemTypeIds = data.data;
        });
      
}
getProducts(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
        .then(data => {
            this.spinner.hide();
            this.products = data.data;
        });
       
}
getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
        .then(data => {
            this.spinner.hide();
            this.partyIds = data.data.PersonsGroups;
        });
       
}
getStatusIds(): void {
    this.spinner.show();
    this._WarehouseService.inventoryStatusId()
        .then(data => {
            this.spinner.hide();
            this.statusIds = data.data;
        });
      
}


  inventoryItemLabelId() {
    this.spinner.show();
    this.accountsService.inventoryItemLabelId().subscribe(res => {
      const eventPurpose = res.data[0].dataNode;
      this.spinner.hide();
       for (const value of eventPurpose) {
        this.inventoryItemLabelIdArray.push({
          label: value.description,
          value: value.inventoryItemLabelId
        })
      } 
    })
   
  }


createInventoryItemLabelApplFromItem(): void {
    this.spinner.show();
    const requestData ={
      "facilityId": this.facilityId,
      "inventoryItemId": this.inventoryItemId,
      "inventoryItemLabelId":this.inventoryItemLablesForm.value.inventoryItemLabelID,
      "sequenceNum":this.inventoryItemLablesForm.value.sequenceNum

 }
     this.accountsService.createInventoryItemLabelApplFromItem(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Create Successfully");
        this.spinner.hide();
        this.EditInventoryItemLabels();
        this.inventoryItemLablesForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this._ToastrService.error(err.error.message);
      }
    }); 
  }

  updateInventoryItemLabelApplFromItem(): void {
    this.spinner.show();
    const requestData = {
      "facilityId": this.facilityId,
      "inventoryItemId":this.updatenventoryItemLabelPat.inventoryItemId,
      "inventoryItemLabelId":this.updateLableAppllicablityForm.value.inventoryItemLabelID,
      "inventoryItemLabelTypeId":this.updatenventoryItemLabelPat.inventoryItemLabelTypeId,
      "sequenceNum": this.updateLableAppllicablityForm.value.sequenceNum,

    }
    this.accountsService.updateInventoryItemLabelApplFromItem(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.EditInventoryItemLabels();
        this.closebutton2.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }

  updateInventoryItemLabelPatch(product) {
    this.show=false;
    this.updatenventoryItemLabelPat=product;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.updateLableAppllicablityForm;
        formValue.patchValue({
          inventoryItemLabelID: this.updatenventoryItemLabelPat.inventoryItemLabelId,
          sequenceNum:this.updatenventoryItemLabelPat.sequenceNum,
           
        })
      }, 2000);
      this.spinner.hide();
  }

  getInventorySummaryPage() {
    this.spinner.show();
    this.accountsService.getInventorySummaryPage(this.inventoryItemId).subscribe(res => {
      this.inventorySummaryPage = res.data[0].dataNode[0];
      this.spinner.hide();
    })
  }

  getLocationSeqId() {
    this.spinner.show();
    this.accountsService.getLocationSeqId().subscribe(res => {
      this.LocationSeqId = res.data.getFacilityId;
      this.spinner.hide();
      for (const value of this.LocationSeqId) {
        this.LocationSeqIdArray.push({
          label: value.locationSeqId,
          value: value.facilityId
        })
      }
    })
  }
  

  updateInventorySummaryPage() {
    this.spinner.show();
    this.accountsService.getInventorySummaryPage(this.inventoryItemId).subscribe(res => {
      this.inventorySummaryPage = res.data[0].dataNode[0];
      this.spinner.hide();   
      setTimeout(() => {
        const formValue = this.inventoryItemForm;
        formValue.patchValue({
      inventoryItemID:this.inventorySummaryPage.inventoryItemId,
      accountingQuantityTotal:this.inventorySummaryPage.accountingQuantityTotal,
      activationNumber: this.inventorySummaryPage.activationNumber,
      activationValidThru: this.inventorySummaryPage.inventoryItemId,
      binNumber: this.inventorySummaryPage.comments,
      comments: this.inventorySummaryPage.inventoryItemId,
      containerId: this.inventorySummaryPage.containerId,
      currencyUomId: this.inventorySummaryPage.currencyUomId,
      datetimeManufactured:this.inventorySummaryPage.datetimeManufactured,
      datetimeReceived: this.inventorySummaryPage.datetimeReceived,
      expireDate: this.inventorySummaryPage.expireDate,
      facilityId: this.inventorySummaryPage.facilityId,
      fixedAssetId: this.inventorySummaryPage.fixedAssetId,
      inventoryItemTypeId: this.inventorySummaryPage.inventoryItemTypeId,
      locationSeqId:this.inventorySummaryPage.locationSeqId,
      lotId: this.inventorySummaryPage.lotId,
      ownerPartyId: this.inventorySummaryPage.ownerPartyId,
      partyId: this.inventorySummaryPage.partyId,
      productId: this.inventorySummaryPage.productId,
      serialNumber: this.inventorySummaryPage.serialNumber,
      softIdentifier:this.inventorySummaryPage.softIdentifier,
      statusId: this.inventorySummaryPage.statusId,
      unitCost: this.inventorySummaryPage.unitCost,
      uomId: this.inventorySummaryPage.uomId,

        })
  
      }, 2000);
      this.spinner.hide();

    })
  }

  getFacilityIds(): void {
    this.spinner.show();
    this._WarehouseService.getFacility()
        .then(data => {
            this.spinner.hide();
            this.facilityIds = data.data;
        });
       
}

  // updateLayoutSubContent(): void {
  //   this.spinner.show();
  //   const requestData ={
  //     "contentId": this.createTemplateForm.value.contentID,
  //     "contentIdTo": this.createTemplateForm.value.contentIDTo,
  //     "contentName": this.createTemplateForm.value.contentName,
  //     "contentTypeId": this.createTemplateForm.value.contentType,
  //     "contentTypeIdDisplay": '',
  //     "createdByUserLogin": this.createTemplateForm.value.createdByUser,
  //     "createdDate": this.createTemplateForm.value.createdDate,
  //     "description": this.createTemplateForm.value.description,
  //     "drDataResourceId": this.createTemplateForm.value.dataResourceID,
  //     "drDataResourceTypeId": this.createTemplateForm.value.drDataResourceTypeID,
  //     "drDataTemplateTypeId": this.createTemplateForm.value.drDataTemplateTypeID,
  //     "drMimeTypeId": this.createTemplateForm.value.drMimeTypeID,
  //     "drObjectInfo": this.createTemplateForm.value.filePath,
  //     "lastModifiedByUserLogin": this.createTemplateForm.value.lastModifiedByUser,
  //     "lastModifiedDate": this.createTemplateForm.value.lastModifiedDate,
  //     "mapKey": this.createTemplateForm.value.mapKey,
  //     "textData": this.createTemplateForm.value.text
  //     }
     
  //   this.accountsService.updateLayoutSubContent(requestData).subscribe((res: any) => { 
  //     if (res.success) {
  //       this._ToastrService.success("Updated Successfully");
  //       this.spinner.hide();
  //       this.FindLayout();
  //       this.closebutton2.nativeElement.click();
  //       this.show=true;
  //       // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
  //     }
  //   }, (err) => {
  //     this.spinner.hide();
  //     if(err.error.errors){
  //     for (const value of err.error.errors) {
  //       this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
  //     }
  //     }
  //     else{
  //       this._ToastrService.error(err.error.message);
  //     }
  //   }); 
  // }
  // updateSurveyPagePatch() {
  //   this.show=false;
  //   this.spinner.show();
  //   const fromDateCate=this.detailPageAll.createdDate;
  //   this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
  //   const throughDateCate=this.detailPageAll.lastModifiedDate;
  //   this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
  //     setTimeout(() => {
  //       const formValue = this.createTemplateForm;
  //       formValue.patchValue({
  //         contentID:this.detailPageAll.contentId,
  //         contentType:this.detailPageAll.contentTypeId,
  //         dataResourceID:this.detailPageAll.dataResourceId,
  //         contentName:this.detailPageAll.contentName,
  //         description:this.detailPageAll.description,
  //         contentIDTo:"TEMPLATE_MASTER",
  //         drMimeTypeID:this.detailPageAll.mimeTypeId,
  //         createdByUser:this.detailPageAll.createdByUserLogin,
  //         createdDate:this.fromDateCate,
  //         lastModifiedByUser:this.detailPageAll.lastModifiedByUserLogin,
  //         lastModifiedDate:this.throughDateCate
  //       })
  //     }, 2000);
  //     this.spinner.hide();
  // }


  deleteInventoryItemLabelApplFromItem(product) {
    Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      let req = {
        "facilityId":product.facilityId,
        "inventoryItemId":product.inventoryItemId ,
        "inventoryItemLabelId":product.inventoryItemLabelId,
        "inventoryItemLabelTypeId":product.inventoryItemLabelTypeId ,
        "view_INDEX_1": '',
        "view_SIZE_1": '',

      }
      this.accountsService.deleteInventoryItemLabelApplFromItem(req).subscribe((res: any) => {
        if (res.success) {
          this.EditInventoryItemLabels();
        }
        else {
          this._ToastrService.error("Error");
        }
      })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })
}



  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
}

changeactiveCategoryFunction(tab:number)
{
  this.activeCategory=tab;
}

cancelSubmit()
{
  this._Router.navigate(['/facilities/warehouse/detailed-summary-facilities']);
}
}
