import { Injectable,Inject  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DeparmentsService {

  constructor(private http:HttpClient) { }

 getDepartment(data,dept) {
  const getDepartment = URLS.getDepartments + "?pageSize=" + data.pageSize + "&pageNo=" + data.pageNo + "&search=" + data.search 
 return this.http.post(getDepartment,dept);
  }

  deleteDept(id){
    const deleteDept = (URLS.deleteDepartment) + id;

    return this.http.delete(deleteDept);
  }






  
}
