import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-requests-order',
  templateUrl: './detail-requests-order.component.html',
  styleUrls: ['./detail-requests-order.component.css']
})
export class DetailRequestsOrderComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 3;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  newForm: FormGroup;
  newrequestForm: FormGroup;
  CustRequestTypeID: any;
  CustRequestTypeIDArray: any[] = [];
  ParentCustRequestId: any;
  ParentCustRequestIdArray: any[] = [];
  PriorityListArray: any[] = [];
  PriorityList: any;
  PartyId: any;
  PartyIdArray: any[] = [];
  ProjectAndPhaseNameArray: any[] = [];
  ProjectAndPhaseName: any;
  personId: any;
  partyIdArray: any[] = [];
  yesNOArray: { label: string; value: string; }[];
  requestRoleForm: FormGroup;
  RequestRolesById: any;
  custRequestId: any;
  ThroughDate: string;
  FromDate: string;
  showRequest: boolean;
  RequestDate: string;
  ResponseRequiredDate: string;
  requestRolesById: any;
  RequestItemData: any;
  RoleTypeId: any;
  RoleTypeIdArray: any[] = [];
  noteForm: FormGroup;
  custRequestName: any;
  requestItemForm: FormGroup;
  ProductIdArray: any[] = [];
  ProductId: any;
  StatusId: any;
  StatusIdArray: any[] = [];
  RequiredByDate: string;
  ReservStart: string;
  updateRequestItemForm: FormGroup;
  RequestItem: any;
  RequestItemNote: any;
  RequestItemNotes: any;
  CommunicationEvents: any;
  fileData: File;
  CustRequestContentById: any;
  RequestInformation: any;
  WorkEfforts: any;
  TaskNameListExistingTask: any;
  TaskNameListExistingTaskArray: any[]=[];  existingContentId: any;
  existingContentIdListArray:any[]=[];
  AddTaskContentForm: FormGroup;
  uploadId: any;
  fileURL: any;
  parentContentId: any;
  parentContentIdArray: any[]=[];
  MimeTypeId: any;
  mimeTypeIdArray: any[]=[];
  contentTypeId: any;
  contentTypeIdArray: any[]=[];
  custRequestItemSeqId: any;
  quoteId: any;
  statusID: any;
  updateRequestForm: FormGroup;
  currencyArray: any[]=[];
  salesChannel: any;
  salesChannelArray: any[]=[];
  ContactMechIdArray:any[]=[];
  ContactMechId: any;
  productStoreIdReportArray:any[]=[];
  productStoreIdReport: any;
  StatusArray: { label: string; value: string; }[];
  custRequestCategoryId: any;
  custRequestCategoryIdArray: any[]=[];
  productStoresListArray: any[]=[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly router: Router,
    private _location: Location,
  ) {
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.newrequestForm = this._FormBuilder.group({
      RequestingParty: [''],
      CustRequestName: [''],
      CustRequestTypeID: [''],
      Description: [''],
      Priority: [''],
      RequestDate: [''],
      ResponseRequiredDate: [''],
      CustEstimatedMilliSeconds: [''],
      CustSequenceNum: [''],
      ParentCustRequestID: [''],
      Billed: [''],
      Story: [''],
      WebSiteID: [''],
      CustRequestCategoryID: [''],
      MaximumAmountUomID: [''],
      ProductStore: [''],
      SalesChannel: [''],
      FulfillContactMechID: [''],
      Currency: [''],
      OpenDateTime: [''],
      ClosedDateTime: [''],
      InternalComment: [''],
      Reason: [''],
     

    });
   
    this.updateRequestForm = this._FormBuilder.group({
      CustRequestTypeID: [''],
      CustRequestCategoryID: [''],
      StatusID: [''],
      RequestingParty: [''],
      Priority: [''],
      RequestDate: [''],
      ResponseRequiredDate: [''],
      CustRequestName: [''],
      Description: [''],
      MaximumAmountUomID: [''],
      ProductStore: [''],
      SalesChannel: [''],
      FulfillContactMechID: [''],
      Currency: [''],
      OpenDateTime: [''],
      ClosedDateTime: [''],
      InternalComment: [''],
      Reason: [''],
      Billed: [''],
      Story: [''],
      WebSiteID: [''],
      CustEstimatedMilliSeconds: [''],
      CustSequenceNum: [''],
      ParentCustRequestID: [''],


    });
    this.requestRoleForm = this._FormBuilder.group({
      PartyID: [''],
      RoleType: [''],
      FromDate: [''],
      ThroughDate: [''],

    });
    this.noteForm = this._FormBuilder.group({
      Note: [''],

    });
    this.requestItemForm = this._FormBuilder.group({
      StatusID: [''],
      Priority: [''],
      SequenceNum: [''],
      RequiredByDate: [''],
      ProductID: [''],
      Quantity: [''],
      SelectedAmount: [''],
      MaximumAmount: [''],
      ReservStart: [''],
      ReservLength: [''],
      ReservPersons: [''],
      ConfigID: [''],
      Description: [''],
      Story: [''],

    });
    this.updateRequestItemForm = this._FormBuilder.group({
      StatusID: [''],
      Priority: [''],
      SequenceNum: [''],
      RequiredByDate: [''],
      ProductID: [''],
      Quantity: [''],
      SelectedAmount: [''],
      MaximumAmount: [''],
      ReservStart: [''],
      ReservLength: [''],
      ReservPersons: [''],
      ConfigID: [''],
      Description: [''],
      Story: [''],

    });
    this.AddTaskContentForm = this._FormBuilder.group({
      ExistingContent: "",
      WorkEffortContentTypeID: "",
      Upload: "",
      StatusId: "",
      ParentContent: "",

    });
    this.StatusArray = [

      {
        label: 'Accept Request (Reviewed)',
        value: 'CRQ_ACCEPTED'
      },
      {
        label: 'Pending Customer (Reviewed)',
        value: 'CRQ_PENDING'
      },
      {
        label: 'Complete Request (Reviewed)',
        value: 'CRQ_COMPLETED'
      },
      
      {
        label: 'Cancel Request (Reviewed)',
        value: 'CRQ_CANCELLED'
      },


    ];
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.custRequestId = params.custRequestId;
      this.custRequestName = params.custRequestName;
      this.custRequestItemSeqId = params.custRequestItemSeqId;

    })
    this.getCustRequestTypeID();
    this.getParentCustRequestId();
    this.getProjectAndPhaseName();
    this.getPriorityList();
    this.getPartyIdList();
    this.getPartyId();
    this.getRequestRolesById();
    this.getRoleTypeIdRequest();
    this.getRequestItemData();
    this.getRequestItemsStatusId();
    this.getProductProductId();
    this.getCustRequestContentById();
    this.getRequestInformation();
    this.getCommunicationEvents();
    this.getMimeTypeId();
    this.parentContentIdList();
    this.existingContentIdList();
    this.getWorkEfforts();
    this.getContentTypeId();
    this.getRequestInformations();
    this.getCurrency();
    this.getContactMechId();
    this.getSalesChannel();
    this.getCustRequestCategoryId();
    this.getProductStoreIdReport();
    this.getOrderListItems();

  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/order/main-order']);
  }
  getCustRequestCategoryId() {
    this.spinner.show();
    this.myContactsService.getCustRequestCategoryId().subscribe(res => {
      this.custRequestCategoryId = res.data;
      this.spinner.hide();
      for (const value of this.custRequestCategoryId) {
        this.custRequestCategoryIdArray.push({
          label: value.Data.description,
          value: value.Data.custRequestCategoryId
        })
      }
    })
  }

  getOrderListItems(): void {
    this.spinner.show();
    this.accountsService.getOrderList().subscribe((res) => {
      let productStores = res.data.productStores;
      this.spinner.hide();
      for (const value of productStores) {
        this.productStoresListArray.push({
          label: value.storeName,
          value: value.productStoreId,
        });
      }
    });
   
  }
  getSalesChannel() {
    this.spinner.show();
    this.myContactsService.getSalesChannel().subscribe(res => {
      this.salesChannel = res.data[0].data ;
      this.spinner.hide();
      for (const value of this.salesChannel) {
        this.salesChannelArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  getProductStoreIdReport() {
    this.spinner.show();
    this.myContactsService.getProductStoreIdReport().subscribe(res => {
      this.productStoreIdReport = res.data.productStoreList ;
      this.spinner.hide();
      for (const value of this.productStoreIdReport) {
        this.productStoreIdReportArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }
    })
  }
  getContactMechId() {
    this.spinner.show();
    this.accountsService.getContactMechId().subscribe(res => {
      this.ContactMechId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactMechId) {
        this.ContactMechIdArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  quotePage() {
    this.spinner.show();
    const requestData = {
      "custRequestId": this.custRequestId,
    }
    this.myContactsService.createQuoteFromCustRequest(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.quoteId = res.data.quoteId;
        this.router.navigate(["/psa/project/create-quote-from-request-list"],  
        { queryParams: { quoteId : this.quoteId ,custRequestId: this.custRequestId,} })
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
 
  deleteCustRequestContents(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {

          "custRequestId": this.custRequestId,
          "contentId": product.data.contentId

        }
        this.myContactsService.deleteCustRequestContents(req).subscribe((res: any) => {
          this.getCustRequestContentById();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  existingContentIdList() {
    this.spinner.show();
    this.myContactsService.existingcontentId().subscribe(res => {
      this.existingContentId = res.data;
      this.spinner.hide();
      for (const value of this.existingContentId) {
        this.existingContentIdListArray.push({
          label: value.description,
          value: value.contentId
        })
      }
    })
  }

  getContentTypeId() {
    this.spinner.show();
    this.myContactsService.getContentTypeIds().subscribe(res => {
      this.contentTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.contentTypeId) {
        this.contentTypeIdArray.push({
          label: value.description,
          value: value.workEffortContentTypeId
        })
      }
    })
  }
  getMimeTypeId() {
    this.spinner.show();
    this.accountsService.getMimeTypeId().subscribe(res => {
      this.MimeTypeId = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.MimeTypeId) {
        this.mimeTypeIdArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }
  parentContentIdList() {
    this.spinner.show();
    this.myContactsService.parentContentIdList().subscribe(res => {
      this.parentContentId = res.data;
      this.spinner.hide();
      for (const value of this.parentContentId) {
        this.parentContentIdArray.push({
          label: value.contentIdTo,
          value: value.contentId
        })
      }
    })
  }

  uploadFormContent(){
    this.onSubmitFile();
    setTimeout(() => {
      this.createCustRequestContent();
    }, 2000);
   
  }
  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  createCustRequestContent() {
    this.spinner.show();
    const formData = {
      "contentId":this.AddTaskContentForm.value.ExistingContent,
      "contentIdFrom": this.AddTaskContentForm.value.ParentContent,
      "contentTypeId": this.AddTaskContentForm.value.WorkEffortContentTypeID,
      "custRequestId": this.custRequestId,
      "dataResourceName":  this.fileURL,
      "statusId": this.AddTaskContentForm.value.StatusId,
    }
    this.myContactsService.createCustRequestContent(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.AddTaskContentForm.reset();
        this.getCustRequestContentById();
        this.uploadId = res.data.id;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  onSubmitFile() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Document Uploaded");
        this.fileURL = res.data.URL;
      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })

  }
  getWorkEfforts() {
    this.spinner.show();
    this.myContactsService.getWorkEfforts(this.custRequestId).subscribe(res => {
      this.WorkEfforts = res.data;
      this.spinner.hide();
    })
  }
  
  expireCustRequestContent() {
    this.spinner.show();
    const requestData = {

      "contentId":this.AddTaskContentForm.value.ExistingContent,
      "contentIdFrom": this.AddTaskContentForm.value.ParentContent,
      "contentTypeId": this.AddTaskContentForm.value.WorkEffortContentTypeID,
      "custRequestId": this.custRequestId,
      "dataResourceName":  this.fileURL,
      "statusId": this.AddTaskContentForm.value.StatusId,
    }
    this.myContactsService.expireCustRequestContent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.noteForm.reset();
        this.getCustRequestContentById();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  getCustRequestContentById() {
    this.spinner.show();
    this.myContactsService.getCustRequestContentById(this.custRequestId).subscribe(res => {
      this.CustRequestContentById = res.data;
      this.spinner.hide();
    })
  }
  getRequestInformation() {
    
    this.spinner.show();
    this.myContactsService.getRequestInformation(this.custRequestId).subscribe(res => {
      this.RequestInformation = res.data[0].node[0];
      this.statusID = res.data[0].node[0].statusId;

      this.spinner.hide();
    })
  }
  reset(){
    
    this.newrequestForm.reset();
  }
  getRequestInformations() {
    this.myContactsService.getRequestInformation(this.custRequestId).subscribe(res => {
      this.RequestInformation = res.data[0].node[0];
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.updateRequestForm;
      formValue.patchValue({
        RequestingParty: "",
      CustRequestName: this.RequestInformation.custRequestName,
      CustRequestTypeID:  this.RequestInformation.custRequestTypeId,
      Description:  this.RequestInformation.description,
      Priority: this.RequestInformation.priority,
      Party:  this.RequestInformation.fromPartyId,
      RequestDate:  "",
      ResponseRequiredDate:this.datePipe.transform(this.RequestInformation.responseRequiredDate,"yyyy-MM-dd")  ,
      CustEstimatedMilliSeconds:  this.RequestInformation.custEstimatedMilliSeconds,
      CustSequenceNum:  this.RequestInformation.custSequenceNum,
      ParentCustRequestID:  this.RequestInformation.parentCustRequestId,
      Billed: this.RequestInformation.billed,
      Story: "",
      WebSiteID: "",
      CustRequestCategoryID: this.RequestInformation.custRequestCategoryId,
      StatusID: this.RequestInformation.statusId,
      MaximumAmountUomID: this.RequestInformation.maximumAmountUomId,
      ProductStore: this.RequestInformation.productStoreId,
      SalesChannel: this.RequestInformation.salesChannelEnumId,
      FulfillContactMechID: this.RequestInformation.fulfillContactMechId,
      Currency: this.RequestInformation.currencyUomId,
      OpenDateTime:this.datePipe.transform(this.RequestInformation.openDateTime,"yyyy-MM-dd") ,
      ClosedDateTime:this.datePipe.transform(this.RequestInformation.closedDateTime,"yyyy-MM-dd")  ,
      InternalComment: this.RequestInformation.internalComment,
      Reason: this.RequestInformation.reason,
      
      })

    }, 2000);
    this.spinner.hide();
  })
  }
   getCommunicationEvents() {
    this.spinner.show();
    this.myContactsService.getCommunicationEvents(this.custRequestId).subscribe(res => {
      this.CommunicationEvents = res.data;
      this.spinner.hide();
    })
  }
  createRequestItemNote() {
    this.spinner.show();
    const requestData = {
      "custRequestId": this.custRequestId,
      "custRequestItemSeqId": this.custRequestItemSeqId,
      "note": this.noteForm.value.Note
    }
    this.myContactsService.createRequestItemNote(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.noteForm.reset();
        this.getRequestItemNote();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  notePage(custRequestItemSeqId,custRequestId) {
    this.router.navigate(["/order/project/detail-requests-order"], 
    { queryParams: {custRequestItemSeqId:custRequestItemSeqId ,custRequestId:custRequestId} })
    this.activeCategory=6;
    this.getRequestItemNote();
    this.spinner.hide();
  }
  
  updateRequestItem(product) {
    this.RequestItem = product;
    this.spinner.show();

    setTimeout(() => {
      const formValue = this.updateRequestItemForm;
      formValue.patchValue({
        StatusID: product.data.statusId,
      Priority: product.data.priority,
      SequenceNum: product.data.sequenceNum,
      RequiredByDate: product.data.requiredByDate,
      ProductID: product.data.productId,
      Quantity: product.data.quantity,
      SelectedAmount: product.data.selectedAmount,
      MaximumAmount: product.data.maximumAmount,
      ReservStart: product.data.reservStart,
      ReservLength: product.data.reservLength,
      ReservPersons: product.data.reservPersons,
      ConfigID: product.data.configId,
      Description: product.data.description,
      Story: product.data.story,
      })

    }, 2000);
    this.spinner.hide();
  }
  updaterequestitem() {
    this.spinner.show();

    const requiredByDate = this.updateRequestItemForm.get('RequiredByDate').value;
    this.RequiredByDate = this.datePipe.transform(requiredByDate, "yyyy-MM-dd hh:mm:ss");

    const reservStart = this.updateRequestItemForm.get('ReservStart').value;
    this.ReservStart = this.datePipe.transform(reservStart, "yyyy-MM-dd");

    const requestData = {
      "configId": this.updateRequestItemForm.value.ConfigID,
      "custRequestId": this.custRequestId,
      "custRequestItemSeqId": "",
      "custRequestResolutionId": "",
      "description": this.updateRequestItemForm.value.Description,
      "maximumAmount": this.updateRequestItemForm.value.MaximumAmount,
      "priority": this.updateRequestItemForm.value.Priority,
      "productId": this.updateRequestItemForm.value.ProductID,
      "quantity": this.updateRequestItemForm.value.Quantity,
      "requiredByDate": this.RequiredByDate,
      "reservLength": this.updateRequestItemForm.value.ReservLength,
      "reservPersons": this.updateRequestItemForm.value.ReservPersons,
      "reservStart": this.ReservStart,
      "selectedAmount": this.updateRequestItemForm.value.SelectedAmount,
      "sequenceNum": this.updateRequestItemForm.value.SequenceNum,
      "statusId": this.updateRequestItemForm.value.StatusID,
      "story": this.updateRequestItemForm.value.Story,

    }
    this.myContactsService.updaterequestitem(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.updateRequestItemForm.reset();
        this.getRequestItemData();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  } 
  getRequestItemNote() {
    this.spinner.show();
    this.myContactsService.getRequestItemNote(this.custRequestId,this.custRequestItemSeqId).subscribe(res => {
      this.RequestItemNote = res.data;
      this.spinner.hide();
    })
  } 
  copyCustRequestItem() {
    this.spinner.show();
    const requestData = {
      "custRequestId": this.custRequestId,
      "custRequestItemSeqId": this.RequestItem.data.custRequestItemSeqId
    }
    this.myContactsService.copyCustRequestItem(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.requestItemForm.reset();
        this.getRequestItemData();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  createrequestitem() {
    this.spinner.show();

    const requiredByDate = this.requestItemForm.get('RequiredByDate').value;
    this.RequiredByDate = this.datePipe.transform(requiredByDate, "yyyy-MM-dd hh:mm:ss");

    const reservStart = this.requestItemForm.get('ReservStart').value;
    this.ReservStart = this.datePipe.transform(reservStart, "yyyy-MM-dd");

    const requestData = {
      "configId": this.requestItemForm.value.ConfigID,
      "custRequestId": this.custRequestId,
      "custRequestItemSeqId": "",
      "custRequestResolutionId": "",
      "description": this.requestItemForm.value.Description,
      "maximumAmount": this.requestItemForm.value.MaximumAmount,
      "priority": this.requestItemForm.value.Priority,
      "productId": this.requestItemForm.value.ProductID,
      "quantity": this.requestItemForm.value.Quantity,
      "requiredByDate": this.RequiredByDate,
      "reservLength": this.requestItemForm.value.ReservLength,
      "reservPersons": this.requestItemForm.value.ReservPersons,
      "reservStart": this.ReservStart,
      "selectedAmount": this.requestItemForm.value.SelectedAmount,
      "sequenceNum": this.requestItemForm.value.SequenceNum,
      "statusId": this.requestItemForm.value.StatusID,
      "story": this.requestItemForm.value.Story,
    }
    this.myContactsService.createrequestitem(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.requestItemForm.reset();
        this.getRequestItemData();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  getRequestItemsStatusId() {
    this.spinner.show();
    this.myContactsService.getRequestItemsStatusId().subscribe(res => {
      this.StatusId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.StatusId) {
        this.StatusIdArray.push({
          label: value.description,
          value: value.requestItemsStatusIdForm
        })
      }
    })

  }
  getProductProductId() {
    this.spinner.show();
    this.myContactsService.getProductProductId().subscribe(res => {
      this.ProductId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
  }
  getRoleTypeIdRequest() {
    this.spinner.show();
    this.myContactsService.getRoleTypeIdRequest().subscribe(res => {
      this.RoleTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })

  }
  getRequestItemData() {
    this.spinner.show();
    this.myContactsService.getRequestItemData(this.custRequestId).subscribe(res => {
      this.RequestItemData = res.data;
      this.spinner.hide();
    })
  }
  deleteCustRequestParty(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.FromDate = this.datePipe.transform(product.fromDate, "yyyy-MM-dd");
        let req = {
          "custRequestId": this.custRequestId,
          "fromDate": this.FromDate,
          "partyId": product.partyId,
          "roleTypeId": product.roleTypeId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"

        }
        this.myContactsService.deleteCustRequestParty(req).subscribe(res => {
          this.getRequestRolesById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  expireCustRequestParty(product) {
    this.spinner.show();

    this.FromDate = this.datePipe.transform(product.fromDate, "yyyy-MM-dd");

    const requestData = {
      "custRequestId": this.custRequestId,
      "fromDate": this.FromDate,
      "partyId": product.partyId,
      "roleTypeId": product.roleTypeId,
      "view_INDEX_1": "0",
      "view_SIZE_1": "20"
    }

    this.myContactsService.expireCustRequestParty(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.newrequestForm.reset();
        this.getRequestRolesById();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getRequestRolesById() {
    
    this.spinner.show();
    this.myContactsService.getRequestRolesById(this.custRequestId).subscribe(res => {
      this.requestRolesById = res.data[0].entityQuery;
      this.spinner.hide();
    })
  }

  resetRequest() {
    this.requestRoleForm.reset();
    this.showRequest = false;
  }
  updateRequest(product) {
    this.showRequest = true;
    //   this.updatesurvey = pro;
    this.spinner.show();

    setTimeout(() => {
      const formValue = this.requestRoleForm;
      formValue.patchValue({
        PartyID: product.partyId,
        RoleType: product.roleTypeId,
        ThroughDate: this.datePipe.transform(product.thruDate,"yyyy-MM-dd") ,
        FromDate: this.datePipe.transform(product.fromDate,"yyyy-MM-dd") ,
      })

    }, 2000);
    this.spinner.hide();
  }
  
  createCustRequestParty() {
    this.spinner.show();

    const fromdate = this.requestRoleForm.get('FromDate').value;
    this.FromDate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const throughdate = this.requestRoleForm.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(throughdate, "yyyy-MM-dd");

    const requestData = {
      "custRequestId": this.custRequestId,
      "fromDate": this.FromDate,
      "partyId": this.requestRoleForm.value.PartyID,
      "roleTypeId": this.requestRoleForm.value.RoleType,
      "thruDate": this.ThroughDate
    }


    this.myContactsService.createCustRequestParty(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.requestRoleForm.reset();
        this.getRequestRolesById();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  updateCustRequestParty() {
    this.spinner.show();
    const fromdate = this.requestRoleForm.get('FromDate').value;
    this.FromDate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const throughdate = this.requestRoleForm.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(throughdate, "yyyy-MM-dd");

    const requestData = {
      "custRequestId": this.custRequestId,
      "fromDate": this.FromDate,
      "partyId": this.requestRoleForm.value.PartyID,
      "roleTypeId": this.requestRoleForm.value.RoleType,
      "thruDate": this.ThroughDate,
      "deleteLink": "",
      "expireLink": "",
      "useRowSubmit": "Y",
      "viewIndex_1": "0",
      "viewSize_1": "20"
    }

    this.myContactsService.updateCustRequestParty(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.newrequestForm.reset();
        this.getRequestRolesById();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  createRequest() {
    this.spinner.show();
    const requestDate = this.newrequestForm.get('RequestDate').value;
    this.RequestDate = this.datePipe.transform(requestDate, "yyyy-MM-dd hh:mm:ss");

    const responseRequiredDate = this.newrequestForm.get('ResponseRequiredDate').value;
    this.ResponseRequiredDate = this.datePipe.transform(responseRequiredDate, "yyyy-MM-dd");

    const requestData = {
      "billed": this.newrequestForm.value.Billed,
      "communicationEventId": "",
      "createdByUserLogin": "",
      "createdDate": "",
      "currentStatusId": "PTS_CREATED",
      "custEstimatedMilliSeconds": this.newrequestForm.value.CustEstimatedMilliSeconds,
      "custRequestDate": this.RequestDate,
      "custRequestId": "",
      "custRequestName": this.newrequestForm.value.CustRequestName,
      "custRequestTypeId": this.newrequestForm.value.CustRequestTypeID,
      "custSequenceNum": this.newrequestForm.value.CustSequenceNum,
      "description": this.newrequestForm.value.Description,
      "form": "list",
      "fromPartyId": this.newrequestForm.value.RequestingParty,
      "lastModifiedByUserLogin": "",
      "lastModifiedDate": "",
      "parentCustRequestId": this.newrequestForm.value.ParentCustRequestID,
      "partyId": this.newrequestForm.value.Party,
      "portalPageId": "",
      "priority": this.newrequestForm.value.Priority,
      "responseRequiredDate": this.ResponseRequiredDate,
      "roleTypeId": "PROJECT_TEAM",
      "statusId": "CRQ_SUBMITTED",
      "story": this.newrequestForm.value.Story,
      "webSiteId": this.newrequestForm.value.WebSiteID,
      "workEffortName": "",
      "workEffortParentId": this.newrequestForm.value.ProjectandPhaseName,
      "workEffortTypeId": "TASK"
    }

    this.myContactsService.createRequest(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.newrequestForm.reset();
        this.router.navigate(["/psa/project/request-list"])
        //this.getMainPageRequestListDetails();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getProjectAndPhaseName() {
    this.spinner.show();
    this.myContactsService.getProjectAndPhaseName().subscribe(res => {
      this.ProjectAndPhaseName = res.data;
      this.spinner.hide();
      for (const value of this.ProjectAndPhaseName) {
        this.ProjectAndPhaseNameArray.push({
          label: value.data.phaseName + "[" + value.data.projectName + "]",
          value: value.data.projectId
        })
      }
    })
  }
  getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  getPriorityList() {
    this.spinner.show();
    this.myContactsService.getPriorityList().subscribe(res => {
      this.PriorityList = res.data;
      this.spinner.hide();
      for (const value of this.PriorityList) {
        this.PriorityListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  getParentCustRequestId() {
    this.spinner.show();
    this.myContactsService.getParentCustRequestId().subscribe(res => {
      this.ParentCustRequestId = res.data;
      this.spinner.hide();
      for (const value of this.ParentCustRequestId) {
        this.ParentCustRequestIdArray.push({
          label: value.data.custRequestName,
          value: value.data.custRequestId
        })
      }
    })
  }
  getCustRequestTypeID() {
    this.spinner.show();
    this.myContactsService.getCustRequestTypeID().subscribe(res => {
      this.CustRequestTypeID = res.data;
      this.spinner.hide();
      for (const value of this.CustRequestTypeID) {
        this.CustRequestTypeIDArray.push({
          label: value.data.description,
          value: value.data.custRequestTypeId
        })
      }
    })
  }
  updaterequest() {
    this.spinner.show();
   // const requestDate = this.updateRequestForm.get('').value;
   // this.RequestDate = this.datePipe.transform(requestDate, "yyyy-MM-dd hh:mm:ss");
   // const responseRequiredDate = this.updateRequestForm.get('').value;
   // this.ResponseRequiredDate = this.datePipe.transform(responseRequiredDate, "yyyy-MM-dd");
    const requestData = {
      "billed": this.updateRequestForm.value.Billed,
      "communicationEventId": "",
      "createdByUserLogin": "",
      "createdDate": "",
      "currentStatusId": "PTS_CREATED",
      "custEstimatedMilliSeconds": this.updateRequestForm.value.CustEstimatedMilliSeconds,
      "custRequestDate":  this.datePipe.transform(this.updateRequestForm.value.RequestDate, "yyyy-MM-dd HH:mm:ss"),
      "custRequestId": this.custRequestId,
      "custRequestName": this.updateRequestForm.value.CustRequestName,
      "custRequestTypeId": this.updateRequestForm.value.CustRequestTypeID,
      "custSequenceNum": this.updateRequestForm.value.CustSequenceNum,
      "description": this.updateRequestForm.value.Description,
      "form": "list",
      "fromPartyId": this.updateRequestForm.value.RequestingParty,
      "lastModifiedByUserLogin": "",
      "lastModifiedDate": "",
      "parentCustRequestId": this.updateRequestForm.value.ParentCustRequestID,
      "partyId": this.updateRequestForm.value.Party,
      "portalPageId": "",
      "priority": this.updateRequestForm.value.Priority,
      "responseRequiredDate":this.datePipe.transform(this.updateRequestForm.value.ResponseRequiredDate, "yyyy-MM-dd HH:mm:ss"), 
      "roleTypeId": "PROJECT_TEAM",
      "statusId": this.updateRequestForm.value.StatusID,
      "story": "",
      "webSiteId": this.updateRequestForm.value.WebSiteID,
      "workEffortName": "",
      "workEffortParentId": "",
      "workEffortTypeId": "TASK"
    }
    this.myContactsService.updaterequest(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.activeCategory = 1;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  cancelSubmit(){
    this.router.navigate(['/order/requests-order'])
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }
}