import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-inactive-customer-summary',
  templateUrl: './inactive-customer-summary.component.html',
  styleUrls: ['./inactive-customer-summary.component.css']
})
export class InactiveCustomerSummaryComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  CreateDataSourceForm: any;
  DataSourceValue: any;
  DataSourceTypeId: any;
  DataSourceTypeIdArray: any[]=[];
  show: boolean;
  DataSourceTypeValue: any;
  dataSourceID: any;
  dataSourceTypeById: any;
  dataSourceTypeId: any;
  fsmTimeCardData: any;
  timesheetid: any;
  inactiveCusotmerId: any;


  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) { }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.inactiveCusotmerId = params.id;
    })
    this.getFsmTimeCardById();
  }
  backWindow(){
    this._location.back();
  }
    toDashboard()
  {
  this._Router.navigate(['/hub/Dashboard']);
  }
    getFsmTimeCardById() {
      this.spinner.show();
      this.myContactsService.getFsmTimeCardById(this.inactiveCusotmerId).subscribe(res => {
        this.fsmTimeCardData = res.data[0].getFsmTimeCardById[0];
        this.spinner.hide();
      })
    }
  
  cancelSubmit(){
        this._Router.navigate(['/fsm/timesheet'])
  }
    ngOnDestroy(): void {
      this.spinner.hide();
     
    }

}
