import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
@Component({
    selector: 'app-agreement-summary-item',
    templateUrl: './agreement-summary-item.component.html',
    styleUrls: ['./agreement-summary-item.component.css']
})
export class AgreementSummaryItemComponent implements OnInit {
    activeCategory=1;
    agreement: {
        agreementId: string,
        agreementItemSeqId: string
    };
    faEdit = faEdit;
    faTrash = faTrash;
    total=0;
    rowNumber=0;
    pageSize = 10;
    rows = 50;
    agreementType: string;
    agreementPromotions: any[];
    agreementItemTerms: any[];
    isPurchasedAgreement: boolean;
    agreementProducts: any[];
    agreementItemGioList: any[];
    agreementItemFacilities: any[];
    constructor(
      readonly _ActivatedRoute: ActivatedRoute,
      readonly _ToastrService: ToastrService,
        readonly spinner: NgxSpinnerService,
        readonly _AccountingApService: AccountingApService,
        readonly  _Router: Router
    ) {
        this.agreement = {
            agreementId: '',
            agreementItemSeqId: ''
        };
        this.agreementType = '';
        this.agreementPromotions = [];
        this.agreementItemTerms = [];
        this.isPurchasedAgreement = false;
        this.agreementProducts = [];
        this.agreementItemGioList = [];
        this.agreementItemFacilities = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this._ActivatedRoute.queryParams.subscribe(data => {
            this.agreement = {
                agreementId: data.agreementId,
                agreementItemSeqId: data.agreementItemSeqId
            };
        });
        this._ActivatedRoute.data.subscribe(data => {
            this.isPurchasedAgreement = data.agreement;
        });
        this.getAgreementPromotions();
        this.getAgreementItemTerms();
        this.getAgreementProducts();
        this.getAgreementItemGioList();
        this.getAgreementItemFacilities();
    }
    getAgreementPromotions(): void {
        this.spinner.show();
        this._AccountingApService.getAgreementItemPromotions(this.agreement.agreementId, this.agreement.agreementItemSeqId, { pageNo: 1, pageSize: 200 })
            .then(data => {
                this.agreementPromotions = data.data;
            });
            this.spinner.hide();
    }
    deleteItmPromotion(fromDate: string, productPromoId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteAgreementItemPromotions(this.agreement.agreementId, this.agreement.agreementItemSeqId, moment(fromDate).format('YYYY-MM-DD HH:mm:ss'), productPromoId)
                .then(data => {
                    if (data.success) {
                        this.getAgreementPromotions();
                    }
                });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })     
    }
    getAgreementItemTerms(): void {
        this.spinner.show();
        this._AccountingApService.getAgreementItemTerms(this.agreement.agreementId, this.agreement.agreementItemSeqId, { pageNo: 1, pageSize: 200 })
            .then(data => {
                this.agreementItemTerms = data.data;
                this.spinner.hide();
            });
           
    }
    deleteAgreementItemTerm(agreementTermId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteAgreementItemTerm(this.agreement.agreementId, this.agreement.agreementItemSeqId, agreementTermId)
                .then(data => {
                    if (data.success) {
                        this.getAgreementItemTerms();
                    }
                });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })     
    }
    getAgreementProducts(): void {
        this.spinner.show();
        if (this.isPurchasedAgreement) {
            this._AccountingApService.getAgreementItemProductPurchased(this.agreement.agreementId, this.agreement.agreementItemSeqId,
                { pageNo: 1, pageSize: 200 })
                .then(data => {
                    this.agreementProducts = data.data;
                    this.spinner.hide();
                });
        } else {
            this._AccountingApService.getAgreementItemProductSales(this.agreement.agreementId, this.agreement.agreementItemSeqId, { pageNo: 1, pageSize: 200 })
                .then(data => {
                    this.agreementProducts = data.data;
                    this.spinner.hide();
                });
        }
      
    }
    changeactiveCategoryFunction(tab: number){
        this.activeCategory =tab;
      }
    deletePurchasedAgreement(availableFromDate: string, currencyUomId: string, minimumOrderQuantity: number, partyId: string, productId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteAgreementItemPurchased({
                    agreementId: this.agreement.agreementId,agreementItemSeqId: this.agreement.agreementItemSeqId,
                    availableFromDate: availableFromDate ? moment(availableFromDate).format('YYYY-MM-DD HH:mm:ss') : '',
                    currencyUomId,minimumOrderQuantity,partyId, productId})
                    .then(data => {
                        if (data.success) {  
                            this.getAgreementProducts();
                        }  });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )} else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              ) }})      }
    deleteSaleAgreement(productId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteAgreementItemProductSale(this.agreement.agreementId, this.agreement.agreementItemSeqId, productId)
                .then(data => {
                    if (data.success) {
                        this.getAgreementProducts();
                    } });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })    
    }


    getAgreementItemGioList(): void {
        this.spinner.show();
        this._AccountingApService.getAgreementItemGioList(this.agreement.agreementId, this.agreement.agreementItemSeqId, { pageNo: 1, pageSize: 200 })
            .then(data => {
                this.agreementItemGioList = data.data;
                this.spinner.hide();
            });
        
    }

    deleteAgreementGioIds(geoId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteAgreementItemGio({ ...this.agreement, ...{ geoId } })
                .then(data => {
                    if (data.success) {
                        this.getAgreementItemGioList();
                    }
                });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          }) }
    getAgreementItemFacilities(): void {
        this.spinner.show();
        this._AccountingApService.getAgreementItemFacilityList(this.agreement.agreementId, this.agreement.agreementItemSeqId, { pageNo: 1, pageSize: 200 })
            .then(data => {
                this.agreementItemFacilities = data.data;
                this.spinner.hide();
            });
          
    }
    deleteAgreementItemFacility(facilityId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteAgreementItemFacility({ ...this.agreement, ...{ facilityId } })
                .then(data => {
                    if (data.success) { 
                        this.getAgreementItemFacilities();
                    }});
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })
    }
    navigateToEditPage(productId: string, availableFromDate: string, currencyUomId: string, minimumOrderQuantity: number, partyId: string): void {
        window.localStorage.setItem('itemValue', JSON.stringify({ availableFromDate, currencyUomId, minimumOrderQuantity, partyId }));
        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/create-item-product`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId, productId } });
    }
    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    ngOnDestroy(): void {
      this.spinner.hide();
        }
}