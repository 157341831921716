import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { FeaturesService } from '../features.service'; import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-feature-category',
  templateUrl: './feature-category.component.html',
  styleUrls: ['./feature-category.component.css']
})
export class FeatureCategoryComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  total=0;
  activeCategorytab=2;
  activeCat=5;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  activeCategoryMain=2;
  featureCategories: any[];
  opportunityIdArray: any[];
  searchFilter: FormGroup;
  activeCategory = 2;
  featureCategoryForm: FormGroup;
  featureCategoryId: string;
  editMode: boolean;
  parentCategory: any[];
  featureCategory: any;
  url: string;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly _FeaturesService: FeaturesService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,    private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly _ToastrService: ToastrService
  ) {
    this.featureCategoryForm = this._FormBuilder.group({
      description: ['', [Validators.required]],
      parentCategoryId: ['']
    });
    this.featureCategoryId = '';
    this.editMode = false;
    this.parentCategory = [];
    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Begins With',
        text: 'Begins With'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      }
    ];
    this.searchFilter = this._FormBuilder.group({
      description: [''],
      descriptionSearchType: ['Contains'],
      productFeatureCategoryId: [''],
      productFeatureCategoryIdSearchType: ['Contains']
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this.getFeatureCategories();
    this.getParentCategory();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  resetForm(){
    this.searchFilter.reset();
  }
  getFeatureCategoriesGroup(): void {
    this.spinner.show();
    const searchFilter = {

      "description": "",
      "descriptionSearchType": "Contains",
      "productFeatureCategoryId": this.featureCategoryId,
      "productFeatureCategoryIdSearchType": "Contains"

    }
    this._FeaturesService.getFeatureCategoriesList({ pageNo: 1, pageSize: 200 }, searchFilter)
      .then(data => {

        if (data.success) {
          setTimeout(() => {
            this.featureCategory = data.data[0];
            const formValue = this.featureCategoryForm;
            formValue.patchValue({
              description: this.featureCategory.description,
              parentCategoryId: this.featureCategory.productFeatureCategoryId,
            })
            this.featureCategoryForm.controls.parentCategoryId.disable();
            this.featureCategoryForm.controls.parentCategoryId.updateValueAndValidity();
          }, 3000);
          this.spinner.hide();
        }
      });
  }
  getParentCategory(): void {
    this.spinner.show();
    this._FeaturesService.getFeatureParentCategory()
      .then(data => {
        this.spinner.hide();
        this.parentCategory = data.data.map(value => {
          return {
            label: value.description,
            value: value.productFeatureCategoryId
          };
        });
      });
     
  }
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      if (this.featureCategoryForm.valid) {
        this._FeaturesService.updateFeatureCategory({
          ...this.featureCategory, ...this.featureCategoryForm.value
        }, this.featureCategoryId)
          .then(data => {
            if (data.success) {
              this.featureCategoryForm.reset();
              this.editMode = false;
              this._ToastrService.success('Updated');
              this.closebutton.nativeElement.click();
              this.getFeatureCategories();
              this.spinner.hide();
              this.featureCategoryForm.controls.parentCategoryId.disable();
              this.featureCategoryForm.controls.parentCategoryId.updateValueAndValidity();
             
            } });
      } } else {
      if (this.featureCategoryForm.valid) {
        this._FeaturesService.createFeatureCategory(this.featureCategoryForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.closebutton.nativeElement.click();
              this.getFeatureCategories();
              this.spinner.hide();
              this.featureCategoryForm.reset();
            
            }
          });
      }
    } }
  getFeatureCategories(): void {
    this.spinner.show();
    this._FeaturesService.getFeatureCategoriesList({ pageNo: 1, pageSize: 200 }, this.searchFilter.value)
      .then(data => {
        this.featureCategories = data.data;
        this.spinner.hide();
      });
    
  }
  featureType(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  featureCategoryGroup(){
    this.router.navigate(['/facilities/catalog/feature-category']);
  }
  featureGroup(){
    this.router.navigate(['/facilities/catalog/feature-group']);
  }

  featureInteraction(){
    this.router.navigate(['/facilities/catalog/feature-interaction']);
  }

  navigateToEdit(featureId: string): void {
    this.router.navigate(['/facilities/catalog/feature-category/new-feature-category'], { queryParams: { featureCategoryId: featureId } });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
