import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from "@angular/router";
import { CategoryService } from "./category.service";
import { CategoryFormService } from "./category-service";
import { ToastrService } from 'ngx-toastr';  import { Location } from '@angular/common';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainCatalogService } from '../main-catalog-mgmt/catalog.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-new-catagory',
  templateUrl: './new-catagory.component.html',
  styleUrls: ['./new-catagory.component.css']
})
export class NewCatagoryComponent implements OnInit {
  categoryId: any;
  data: any;
  activeCategory = 2;
  categoryArray: any = [];
  show = false;
  categoryForm: FormGroup;
  editMode: boolean;
  categories: any[];
  fileType: string;
  fileData: FormData;
  url: string;
  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly categoryService: CategoryService,
    readonly toastr: ToastrService,
    public categoryFormService: CategoryFormService,
    readonly router: Router,    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly _MainCatalogService: MainCatalogService
  ) {
    this.url='/facilities/catalog/catagories'
    this.categoryForm = this._FormBuilder.group({
      categoryImageUrl: [''],
      categoryName: [''],
      description: [''],
      detailScreen: [''],
      linkOneImageUrl: [''],
      linkTwoImageUrl: [''],
      primaryParentCategoryId: [''],
      productCategoryId: [''],
      productCategoryTypeId: ['', [Validators.required]]
    });
    this.editMode = false;
    this.categories = [];
    this.fileType = '';
    this.fileData = new FormData();
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.productCategoryList();
    this.activatedRoute.queryParams.subscribe(params => {
      this.categoryId = params["categoryId"];
      if (this.categoryId) {
        this.show = true;
      }
      else {
        this.show = false;
      }
    });
    if (this.categoryId) {
      this.editMode = true;


      this.categoryForm.controls.productCategoryId.disable();
      this.categoryForm.controls.productCategoryId.updateValueAndValidity();


    }
    this.getCategoryId();
    this.getCategoryList();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  productCategoryList(): void {
    this.spinner.show();
    this.categoryService.productCategoryTypeList().subscribe(res => {
      this.data = res.data;
      this.spinner.hide();
      this.categoryArray = res.data.map(value => {
        return {
          label: value.description,
          value: value.productCategoryTypeId
        };
      });
    
    });
   
  }
  onSubmit(): void {
    this.spinner.show();
    if (this.editMode) {
      if (this.categoryForm.valid) {
        this.onUpdate();
      } else {
        this.spinner.hide();
        this.toastr.error('Please select product category type');
      }  } else {
      if (this.categoryForm.valid) {
        this.categoryService.createCategory({
          ...this.categoryForm.value, ...{
            productCategoryId: this.categoryForm.value.productCategoryId ? this.categoryForm.value.productCategoryId : ''
          }
        }).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success(res.data.successMessage);
            this.spinner.hide();
            this.categoryForm.reset();
            this.router.navigate([this.url]);
          }
          else {
            this.spinner.hide();
            this.toastr.error(res.message);
          }
        }); } else {
        this.spinner.hide();
        this.toastr.error('Please select product category type');
      }} }
  onUpdate(): void {
    this.spinner.show();
    this.categoryService.updateCategory({
      ...this.categoryForm.value, ...{
        productCategoryId: this.categoryId
      }
    }, this.categoryId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.categoryForm.reset();
        this.router.navigate([this.url]);
      }
      else {
        this.spinner.hide();
        this.toastr.error(res.message);
      }
    });
   
  }

  getCategoryId() {
    this.spinner.show();
    this.categoryService.getCategoryById(this.categoryId).subscribe(res => {
     
      setTimeout(() => {

        this.categoryForm.patchValue({
          ...res.data, ...{
            categoryName: res.data.categoryName,
            productCategoryId: res.data.productCategoryId,
            productCategoryTypeId: res.data.productCategoryTypeId,
            description: res.data.description,
            detailScreen: res.data.detailScreen,
            linkOneImageUrl: res.data.linkOneImageUrl,
            linkTwoImageUrl: res.data.linkTwoImageUrl,
            primaryParentCategoryId: res.data.primaryParentCategoryId
          }
        });
      }, 3000);
      this.spinner.hide();
    });
  }
  getCategoryList(): void {
    this.spinner.show();
    this._MainCatalogService.getCategories({ pageNo: 1, pageSize: 200 }, { categoryId: '', categoryIdSearchType: 'Contains', categoryName: '', categoryNameSearchType: 'Contains' })
      .then(data => {
        this.spinner.hide();
        this.categories = data.data;
      });
     
  }
  setImage(event: any): void {
    this.fileData.append('file', event.target.files[0]);
  }
  setCategoryImageType(fileType: string): void {
    this.fileType = fileType;
  }
  uploadImage(): void {
    this.spinner.show();
    if (this.fileType) {
      this.categoryService.uploadCategoryImage(this.categoryId, this.fileType, this.fileData)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.toastr.success('Uploaded');
            this.fileType = '';
            this.fileData.delete('file');
            this.router.navigate([this.url]);
          }
        });
    }
  
  }
  activeTab(tab: number): void {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
