
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PostalEmployeeModel } from './update-postal-model';

@Injectable({
    providedIn: 'root'
  })


export class PostalEmployeeFormService {
  reset() {
    throw new Error('Method not implemented.');
  }
    postal_employee_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    public postalEmployeeModel: PostalEmployeeModel
    ) { } 

  addEmployeeForm(data:any): void {
    if (!this.postal_employee_form) {
        this.postal_employee_form = this._formBuilder.group(this.postalEmployeeModel.mapDataToModel(data));
       
    }

  }

}
