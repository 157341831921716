import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

import {DropdownModule} from 'primeng/dropdown';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-permision-manually',
  templateUrl: './add-permision-manually.component.html',
  styleUrls: ['./add-permision-manually.component.css']
})
export class AddPermisionManuallyComponent implements OnInit {
  total=0;
  activeCategory = 2;
  rowNumber=0;
  pageSize= 100;
  rows = 50;
  pageNo=1;
  search="";
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  createGroup: FormGroup;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  show: boolean;
  groupId: any;
  DateFrom: string;
  throughDate: string;
  @Output() manualModalClosed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;
  
  constructor(  readonly accountsService: AccountsService,
   
    public addSkillService:AddSkillService, private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly activatedRoute:ActivatedRoute,
    readonly router: Router  ) {
      this.createGroup = this._FormBuilder.group({
        fromDate: [''],
        permissionId: ['',[Validators.required]],
        thruDate: [''],
        
        
      });}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> [
      this.groupId = params["groupId"],
      
    ])
  }
  submit(): void {
    this.spinner.show();
    const fromDate=this.createGroup.get('fromDate').value;
    this.DateFrom=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const thruDate=this.createGroup.get('thruDate').value;
    this.throughDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
          "fromDate":  this.DateFrom,
          "permissionId": this.createGroup.value.permissionId,
          "thruDate": this.throughDate      
      }
      this.accountsService.postPermisionDate(requestData,this.groupId).subscribe((res: any) => {
     
        if (res.success) {
          this.modalClose();
          this.toastr.success("Successfully Created");
          this.spinner.hide();
          this._location.back();
        }
      }, (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
      this.spinner.hide();
    }
  cancel(){
    this._location.back();
  }

  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.manualModalClosed.emit();
  }
}
