import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-update-report',
  templateUrl: './update-report.component.html',
  styleUrls: ['./update-report.component.css']
})
export class UpdateReportComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  editReportForm: FormGroup;
  previewForm: FormGroup;
  contentName: any;
  description: any;
  statusId: any;
  UpdateFlexibleReportPat: any;
  show: boolean;
  updateContentRolesPat: any;
  contentId: any;
  statusArray: { label: string; value: string; }[];
  showNew: any;
  birtexportformatArray: { label: string; value: string; }[];
  displayFlexibleReportSearchForm: FormGroup;
  fileData: any;
  dataResourceIdRpt: any;
  uploadFile:FormData
  masterContentName: any;
  dataResourceId: any;
  flexibleReport: any;
  contentIdTo: any;
  downloading: boolean;
  dropdownvalue: any;
  type: string;


  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
  )
  {
    this.editReportForm=this._FormBuilder.group({
      reportname:"",
      description:"",
      statusID:"",
      uploadFileRptDesign:"",
      textareadata:"",
    })
    this.statusArray =[
      {
        label:"In Progress",
        value:"CTNT_IN_PROGRESS"
      },
      {
        label:"Published",
        value:"CTNT_PUBLISHED"
      }
    ]

    this.displayFlexibleReportSearchForm=this._FormBuilder.group({
      exampleID:"",
      exampleTypeID:"",
      statusID:"",
      exampleName:"",
      description:"",
      longDescription:"",
      comments:"",
      exampleDate:"",
      exampleSize:"",
      anotherDate:"",
      anotherText:"",
      birtExportFormat:"",
    })

    this.previewForm=this._FormBuilder.group({
      fromDate:"",
      throughDate:"",
      productCategoryID:"",
      productStoreID:"",
      birtexportformat:"",
    })

    this.birtexportformatArray =[
      {
        label:"Word(.doc)",
        value:"Word (.doc)"
      },
      {
        label:"Pdf(.pdf)",
        value:"Pdf (.pdf)"
      },
      {
        label:"Excel(.xlsx)",
        value:"Excel (.xlsx)"
      }
    ]

  }
  
ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
    this.contentId = params["contentId"];
    this.contentName = params["contentName"];
    this.description = params["description"];
    this.statusId = params["statusId"];
    this.masterContentName=params["masterContentName"];
    this.contentIdTo=params["contentIdTo"];
    })

    if(this.masterContentName=="Example")
    {
      this.showNew=true;
    }

    if(this.masterContentName=="Turnover")
    {
      this.showNew=false;
    }

  this.UpdateFlexibleReportPatch();
  this.EditFlexibleReport();
    
  }


  generatePdfShort() {
 
    this.dropdownvalue= this.previewForm.value.birtexportformat;
    
    if(this.dropdownvalue=="Word (.doc)")
     {
       this.DisplayFlexibleReportSearchWord();
     }
     else if(this.dropdownvalue=="Pdf (.pdf)")
     {
       this.DisplayFlexibleReportSearchPdf();
     }
     else if(this.dropdownvalue=="Excel (.xlsx)")
     {
       this.DisplayFlexibleReportSearchExcel();
     }
     else
     {
      console.log("No file Choosen");
     }
    
    }

  DisplayFlexibleReportSearchWord(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
    "birtContentType":this.previewForm.value.birtexportformat,
    "fromDate":this.datePipe.transform(this.previewForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
    "productCategoryId": this.previewForm.value.productCategoryID,
    "productStoreId":this.previewForm.value.productStoreID,
    "thruDate":this.datePipe.transform(this.previewForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),

    }
    this.accountsService.DisplayFlexibleReportSearch(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:"application/msword" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const requestData = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = requestData; this.spinner.hide();
        link.download = `DisplayFlexibleReportSearch.doc`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(requestData);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.previewForm.reset();
  }

  DisplayFlexibleReportSearchExcel(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
    "birtContentType":this.previewForm.value.birtexportformat,
    "fromDate":this.datePipe.transform(this.previewForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
    "productCategoryId": this.previewForm.value.productCategoryID,
    "productStoreId":this.previewForm.value.productStoreID,
    "thruDate":this.datePipe.transform(this.previewForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),

    }
    this.accountsService.DisplayFlexibleReportSearch(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const requestData = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = requestData; this.spinner.hide();
        link.download = `DisplayFlexibleReportSearch.xlsx`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(requestData);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.previewForm.reset();
  }

  DisplayFlexibleReportSearchPdf(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
    "birtContentType":this.previewForm.value.birtexportformat,
    "fromDate":this.datePipe.transform(this.previewForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
    "productCategoryId": this.previewForm.value.productCategoryID,
    "productStoreId":this.previewForm.value.productStoreID,
    "thruDate":this.datePipe.transform(this.previewForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),

    }
    this.accountsService.DisplayFlexibleReportSearch(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:'application/application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const requestData = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = requestData; this.spinner.hide();
        link.download = `DisplayFlexibleReportSearch.pdf`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(requestData);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.previewForm.reset();
  }
  
  generatePdf() {
 
    this.dropdownvalue= this.displayFlexibleReportSearchForm.value.birtExportFormat;
    
    if(this.dropdownvalue=="Word (.doc)")
     {
       this.DisplayFlexibleReportSearchSecondWord();
     }
     else if(this.dropdownvalue=="Pdf (.pdf)")
     {
       this.DisplayFlexibleReportSearchSecondPDF();
     }
     else if(this.dropdownvalue=="Excel (.xlsx)")
     {
       this.DisplayFlexibleReportSearchSecondExcel();
     }
     else
     {
      console.log("No file Choosen");
     }
    
    }
  
  DisplayFlexibleReportSearchSecondWord(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
      "anotherDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.anotherDate, "yyyy-MM-dd HH:mm:ss"),
      "anotherText":this.displayFlexibleReportSearchForm.value.anotherText,
      "birtExportFormat": this.displayFlexibleReportSearchForm.value.birtExportFormat,
      "description":this.displayFlexibleReportSearchForm.value.description,
      "exampleDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.exampleDate, "yyyy-MM-dd HH:mm:ss"),
      "exampleID":this.displayFlexibleReportSearchForm.value.exampleID,
      "exampleName":this.displayFlexibleReportSearchForm.value.exampleName,
      "exampleSize":this.displayFlexibleReportSearchForm.value.exampleSize,
      "exampleTypeID":this.displayFlexibleReportSearchForm.value.exampleTypeID,
      "longDescription":this.displayFlexibleReportSearchForm.value.longDescription,
      "statusID":this.displayFlexibleReportSearchForm.value.statusID,
      "comments":this.displayFlexibleReportSearchForm.value.comments,
    
    }
    this.accountsService.DisplayFlexibleReportSearchSecond(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:"application/msword" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data; this.spinner.hide();
        link.download = `DisplayFlexibleReport.doc`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.displayFlexibleReportSearchForm.reset();

  }

  DisplayFlexibleReportSearchSecondPDF(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
      "anotherDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.anotherDate, "yyyy-MM-dd HH:mm:ss"),
      "anotherText":this.displayFlexibleReportSearchForm.value.anotherText,
      "birtExportFormat": this.displayFlexibleReportSearchForm.value.birtExportFormat,
      "description":this.displayFlexibleReportSearchForm.value.description,
      "exampleDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.exampleDate, "yyyy-MM-dd HH:mm:ss"),
      "exampleID":this.displayFlexibleReportSearchForm.value.exampleID,
      "exampleName":this.displayFlexibleReportSearchForm.value.exampleName,
      "exampleSize":this.displayFlexibleReportSearchForm.value.exampleSize,
      "exampleTypeID":this.displayFlexibleReportSearchForm.value.exampleTypeID,
      "longDescription":this.displayFlexibleReportSearchForm.value.longDescription,
      "statusID":this.displayFlexibleReportSearchForm.value.statusID,
      "comments":this.displayFlexibleReportSearchForm.value.comments,
    
    }
    this.accountsService.DisplayFlexibleReportSearchSecond(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:'application/application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data; this.spinner.hide();
        link.download = `Display Flexible Report.pdf`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.displayFlexibleReportSearchForm.reset();

  }

  DisplayFlexibleReportSearchSecondExcel(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
      "anotherDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.anotherDate, "yyyy-MM-dd HH:mm:ss"),
      "anotherText":this.displayFlexibleReportSearchForm.value.anotherText,
      "birtExportFormat": this.displayFlexibleReportSearchForm.value.birtExportFormat,
      "description":this.displayFlexibleReportSearchForm.value.description,
      "exampleDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.exampleDate, "yyyy-MM-dd HH:mm:ss"),
      "exampleID":this.displayFlexibleReportSearchForm.value.exampleID,
      "exampleName":this.displayFlexibleReportSearchForm.value.exampleName,
      "exampleSize":this.displayFlexibleReportSearchForm.value.exampleSize,
      "exampleTypeID":this.displayFlexibleReportSearchForm.value.exampleTypeID,
      "longDescription":this.displayFlexibleReportSearchForm.value.longDescription,
      "statusID":this.displayFlexibleReportSearchForm.value.statusID,
      "comments":this.displayFlexibleReportSearchForm.value.comments,
    
    }
    this.accountsService.DisplayFlexibleReportSearchSecond(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data; this.spinner.hide();
        link.download = `Display Flexible Report.xlsx`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.displayFlexibleReportSearchForm.reset();

  }

 fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    console.log("file-data_recoed",this.fileData);
    console.log("file-data_recoed",this.fileData.name);
}

EditFlexibleReport() {
  this.spinner.show();
  this.accountsService.EditFlexibleReport(this.contentId).subscribe(res => {
    this.flexibleReport = res.data[0].nodeDataResourceId[0].dataResourceId;
    this.spinner.hide();
  })
}
  
  UploadRptDesign(): void {
    this.spinner.show();
      const uploadFile = new FormData();
      uploadFile.append("file", this.fileData);
      this.accountsService.UploadRptDesign(uploadFile,this.flexibleReport).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Uploaded Successfully");
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
      this.editReportForm.value.uploadFileRptDesign.reset();
    

  }

  toBack()
  {
    this.router.navigate(['/birt/flexible-reports']);
  }

UpdateFlexibleReportS(): void {
    this.spinner.show();
    const requestData = {
      "contentId":this.contentId,
      "contentName":this.editReportForm.value.reportname,
      "description": this.editReportForm.value.description,
      "statusId":this.editReportForm.value.statusID,
}
    this.accountsService.UpdateFlexibleReportS(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.ListFlexibleReport();
        this.closebutton2.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }


  ListFlexibleReport() {
    throw new Error('Method not implemented.');
  }

  UpdateFlexibleReportPatch() {
    this.show=false;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.editReportForm;
        formValue.patchValue({
          reportname:this.contentName,
          description:this.description,
          statusID:this.statusId,
          textareadata: "<form name="+this.contentId+this.contentIdTo+"type="+""+ "extends="+this.contentIdTo+ 
          "extends-resource=></form>"
        })
      }, 2000);
      this.spinner.hide();
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

}
