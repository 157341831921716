<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Employee Summary ></span>
                <span class="color-black pl-1"> {{editMode? 'Update':'Create'}} Emergency Contact</span>
            </div>
            <div class="w3-card-4" style="border:1px solid;
            border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
            <header class="w3-container w3-blue">
                <div class="header-tabs">
                  <h4 class=" common-styling" style="margin-left: 7px;">
                    {{editMode? 'Update':'Create'}} Emergency Contact
                  </h4>
               
                </div>
              </header>
           <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="emergencyContactForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -11%;">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Contact Type Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="array" placeholder="Select"
                                                formControlName="contactTypeId"></p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -3%;">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Person Name</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Person Name"
                                                formControlName="personName">
                                            </div>
                                           
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -11%;">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Relation</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Relation"
                                                formControlName="relation">
                                            </div>

                                            <div class="col-lg-3 form-group" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -3%;">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Contact Number</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Contact Number"
                                                formControlName="contactNumber">
                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                   
                                  
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: 49%;">
                                <button type="submit" *ngIf="editMode" (click)="update()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!editMode" (click)="submit()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                                <button type="submit" (click)="cancelSubmit();"
                                    class="btn btn-degault cncl-btn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 