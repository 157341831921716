import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-asset',
  templateUrl: './main-asset.component.html',
  styleUrls: ['./main-asset.component.css']
})
export class MainAssetComponent implements OnInit {

  constructor(
    private _location: Location,
    readonly router: Router
  ) { }

  ngOnInit(): void {
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this.router.navigate(['/hub/Dashboard']);
}

}
