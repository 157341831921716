<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Account Reconciliation</span>
               
                <span class="color-black pl-1" > >Update Account Reconciliation</span>
                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                    
                        <li [ngClass]="activeCategory==2?'active':''" ><a>
                            Update Account Reconciliation</a></li>
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createAccountForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Gl Reconciliation ID<span
                                                    style="color:red">*</span>
                                                   
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Gl Reconciliation ID"
                                                formControlName="glAccountReconId"  >

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Gl Reconciliation Name<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Gl Reconciliation Name"
                                                formControlName="glReconciliationName"  >
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Description"
                                            formControlName="Description"  >

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Created Date<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Created Date"
                                                formControlName="CreatedDate"  >
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Last Modified Date<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Last Modified Date"
                                                formControlName="LastModifiedDate"  >
                                               

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Gl Account ID </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Status" filter="true"
                                                formControlName="GlAccountID" 
                                              
                                                [options]="assignGLPartyIdArray" optionlabel="label">
                                                                
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Status	<span
                                                    style="color:red">*</span>
                                                   
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Status" filter="true"
                                            formControlName="Status" 
                                            [options]="status" optionlabel="label"  >
                                                            
                                            </p-dropdown>
                                               

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Organization Party ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="OrganizationPartyID"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Organization Party ID"
                                              >
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Reconciled Balance</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="ReconciledBalance"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Reconciled Balance"
                                              >
                                               

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Opening Balance</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="OpeningBalance"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Opening Balance"
                                              >
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Reconciled Date<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"   formControlName="ReconciledDate"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter External Id"
                                              >
                                               

                                            </div>
                                          
                                        </div>
                                    </div>
                                 
                                 
                                  
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 64%;">
                                <button type="submit" (click)="createAccount();" class="btn btn-secondary submit-btn">Update</button>
                               
                              
                            </div>
                            <br>
                            <br>
                            <div class="container-fluid">
                                <ul class="tabbing-section tabbing-accordians">
                      
                                
                                    <li [ngClass]="activeCategory==2?'active':''" ><a>
                                        Edit Account Reconciliation</a></li>
                               
                                </ul>
                              </div>
                            <div class="card own-account-table all">
                                <p-table [value]="detailRec" [paginator]="true"
                                    [rows]="rows" scrollWidth="100%"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                    [totalRecords]="total" [scrollable]="true"
                                    (sortFunction)="customSort($event)"
                                    [customSort]="true">
                                    <ng-template pTemplate="header">
                                        <tr style="background: #0d3769;">
                                            <th pSortableColumn="code"
                                                [ngStyle]="{'width':'170px'}">
                                                <div class="checkbox-align" style="color: white;">
                                                    <p-checkbox _ngcontent-egj-c329=""
                                                        styleclass="custom-checkbox"
                                                        binary="true"
                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div
                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                            <div
                                                                class="ui-helper-hidden-accessible">
                                                                <input type="checkbox"
                                                                    name="undefined"
                                                                    value="undefined">
                                                            </div>
                                                            <div role="checkbox"
                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span
                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>
                                                        <!---->
                                                    </p-checkbox>

                                         
                                                    Gl Reconciliation ID<p-sortIcon
                                                        field="code"></p-sortIcon>
                                                </div>
                                                <input
                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                    value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false"
                                                    aria-readonly="false">
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}"
                                                pSortableColumn="name"> 	<div style="color: white;"> Acctg Trans ID
                                                <p-sortIcon field="name"></p-sortIcon></div>
                                                <div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox"
                                                        aria-disabled="false"
                                                        aria-readonly="false">
                                                </div>
                                            </th>
                                           

                                          
                                            <th [ngStyle]="{'width':'170px'}"
                                                pSortableColumn="category"> <div style="color: white;"> 	Acctg Trans Entry Seq ID
                                                <p-sortIcon field="category">
                                                </p-sortIcon></div>
                                                <div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox"
                                                        aria-disabled="false"
                                                        aria-readonly="false">
                                                </div>
                                            </th>

                                            <th [ngStyle]="{'width':'170px'}"
                                                pSortableColumn="category"> <div style="color: white;"> 	Reconciled Amount
                                                <p-sortIcon field="category">
                                                </p-sortIcon></div>
                                                <div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox"
                                                        aria-disabled="false"
                                                        aria-readonly="false">
                                                </div>
                                            </th>

                                            <th [ngStyle]="{'width':'170px'}"
                                                pSortableColumn="category"> 	<div style="color: white;"> 	Last Updated Stamp
                                                <p-sortIcon field="category">
                                                </p-sortIcon></div>
                                                <div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox"
                                                        aria-disabled="false"
                                                        aria-readonly="false">
                                                </div>
                                            </th>
                                      
                              




                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                        <tr>
                                            <td [ngStyle]="{'width':'170px'}">
                                                <div class="checkbox-align">
                                                    <p-checkbox _ngcontent-egj-c329=""
                                                        styleclass="custom-checkbox"
                                                        binary="true"
                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div
                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                            <div
                                                                class="ui-helper-hidden-accessible">
                                                                <input type="checkbox"
                                                                    name="undefined"
                                                                    value="undefined">
                                                            </div>
                                                            <div role="checkbox"
                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span
                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>
                                                    </p-checkbox>
                                                    {{product.glReconciliationId}}

                                                </div>
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">  {{product.acctgTransId}}</td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.acctgTransEntrySeqId}}</td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.reconciledAmount}} </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.lastUpdatedTxStamp|date:"yyyy-MM-dd hh:mm:ss"}}</td>
                                            

                    
                                           
                                           


                                        </tr>
                                    </ng-template>
                                </p-table>
                                <p class="paginate_data">
                                    View per page
                                    
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
