<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">View Tree</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openViewTree()"><a>
                                View Tree</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="openEdit()" ><a>
                                Edit</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="openApprovals()" ><a>
                                Approvals</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="openRevisions()"><a>
                                Revisions</a></li>
            
                            <li [ngClass]="activeCategory==5?'active':''" (click)="openCreateNewRoot()"><a>
                                Create New Root CompDoc Template</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''"  (click)="openApprovals()"><a>
                                View Waiting Approvals</a></li>
                                        
                        </ul>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div *ngIf="activeCategory==1">

                                        <div class="create-new-leade">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="bg-white color-grey w-100 ">
                                                            <div class="">
                                                                <div class="row">
                                                                    <form class="w-100" style="padding: 20px 20px;">
                                                                        <div class="w3-card-4 classCardView mt-2 w-100">
                                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                            
                                                                                <p class="h-text">List View Tree</p>
                                                                                <span>
                                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                                    style="margin: 0;" (click)="referToCreateCompdoc()">View Instances</button>
                                                                                </span>
                                                                               
                                                                            </div>
                                                                         
                                                                            
                                                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">
                                            
                                                                                        <div class="card own-account-table">
                                                                                            <p-table [value]="ViewCompDocTemplateTreeAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                <ng-template pTemplate="header">
                                                                                                    <tr style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                            
                                                                                                                Content ID			

                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th> 
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Content Name		
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                            <div style="color: white;">
                                                                                                                Action		
                                                                                                            </div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </th>
                                                                                                        
                                            
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                    <tr>
                                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                                            <div class="checkbox-align">
                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                <span class=""
                                                                                                                >
                                                                                                                {{product.ListContentRevisions.contentId}}
                                                                                                                </span>
                                            
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                                            {{product.ListContentRevisions.contentName}}                                                                                                            
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                                            <span>
                                                                                                                <small style="margin-right:12px;color: #0d3769 !important;font-weight: 600;cursor:pointer;" (click)="referToEditFromTree()">Edit</small>

                                                                                                            </span>      

                                                                                                            
                                                                                                        </td>
                                            
                                                                                                    </tr>
                                                                                                </ng-template>
                                            
                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span class="text-center">
                                                                                                        <h4
                                                                                                            class="ml-2">
                                                                                                            No Record
                                                                                                            Found</h4>
                                                                                                    </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                            
                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>  
                                                                        </div>
                                                                        
                                                                    </form>
                                                                    <div class="mt-4 pd-5">
                                                                        <form class="w-100" style="padding: 20px 20px;">
                                                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                
                                                                                    <p class="h-text">Child View Tree</p>
                                                                                    <span>
                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                                        style="margin: 0;" data-target="#addChidPopup" data-toggle="modal" (click)="createContentApprovalPatch()">Add Child</button>
                                                                                    </span>
                                                                                   
                                                                                </div>
                                                                             
                                                                                
                                                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">
                                                
                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="EditChildCompDocAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                
                                                                                                                    Content ID			
    
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th> 
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Content Name		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    From Date		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Through Date		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Sequence Num		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Mime Type ID		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Action		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            
                                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                                                                                    <span class=""
                                                                                                                    >
                                                                                                                    {{product.dataContent.contentId}}
                                                                                                                    </span>
                                                
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.dataContent.contentName}}                                                                                                            
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.dataContent.mimeTypeId}}                                                                                                            
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                <span>
                                                                                                                    <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;">Update</small>
    
                                                                                                                </span>      
    
                                                                                                                
                                                                                                            </td>
                                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No Record
                                                                                                                Found</h4>
                                                                                                        </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
                                                
                                                                                                <p class="paginate_data">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>  
                                                                            </div>
                                                                            
                                                                        </form>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateEditPopup" tabindex="-1" role="dialog" aria-labelledby="updateEditPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update CompDoc Template
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update CompDoc Template </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateCompocTemlateForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Content ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content ID">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Name</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content Name">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 
<div class="modal fade" id="createNewInstancesTemplatePopup" tabindex="-1" role="dialog" aria-labelledby="createNewInstancesTemplatePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="this.show" class="color-black pl-1">Create New Instance Template
                </span>
                <span *ngIf="!this.show" class="color-black pl-1">Update Instance Template
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="this.show">
                                    <a>Create New Instance Template </a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>Update Instance Template </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createNewInstancesTemlateForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Content Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content Name">
                                                    
                                                        
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addChidPopup" tabindex="-1" role="dialog" aria-labelledby="createNewInstancesTemplatePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="this.show" class="color-black pl-1">Create Child
                </span>
                <span *ngIf="!this.show" class="color-black pl-1">Update Child
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="this.show">
                                    <a>Create Child</a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>Update Child</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addChidForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Content Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content Name">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Parent Content ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="parentContentId" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Parent Content ID"  [readonly]="surveyIdValue">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Content Assoc Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentAssocTypeID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content Assoc Type ID" [readonly]="surveyIdValue">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="fromDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="throughDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Through Date">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Sequence Num</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="sequenceNum" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Document Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="documentType" 
                                                        placeholder="Select Document Type" [options]="AddChildCompDocArray">
                                                        </p-dropdown>
                                                       
                                               
                                                        
                                                    </div>

                                                   
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createChildCompDocTemplate()">Create</button>
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
