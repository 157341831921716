import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-report-main',
  templateUrl: './report-main.component.html',
  styleUrls: ['./report-main.component.css']
})
export class ReportMainComponent implements OnInit {

  constructor(
    private _location: Location,
    readonly  _Router: Router,

  ) { }

  ngOnInit(): void {
  }
  backSubmit(){
    this._location.back();
  }
    toReports()
{
  this._Router.navigate(['/business-intelligence/reports-dashboard']);
}
}
