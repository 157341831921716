import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-create-doc-type',
  templateUrl: './create-doc-type.component.html',
  styleUrls: ['./create-doc-type.component.css']
})
export class CreateDocTypeComponent implements OnInit {
  createDoc:FormGroup;
  activeCategory=2;
  partyId: any;
  dropdownValue: { label: string; value: string; }[];
  show: boolean;
  doctype: any;
  customerScreen: {}[];
  labelValue: string;
  typeId: any;
  typeIdArray: any[]=[];
  quoteTypeId: any;
  quoteTypeIdArray: any[]=[];
  typeIdOrder: any;
  typeIdArrayOrder: any[]=[];
  InvoiceDropdown=false;
  QuoteDropdowm=false;
  OrderDropdown=false;
  actualfromdate: any;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly _ToastrService: ToastrService,) { 
      this.customerScreen=[{
        label: "Default Screen",
        value: ""
      }]
      this.dropdownValue = [{
        label: "Template for invoice",
        value: "InvoiceType"
      },
      {
        label: "Template for quote",
        value: "QuoteType"
      },
      {
        label: "Template for order",
        value: "OrderType"
      }]
      this.createDoc = this._FormBuilder.group({
        documentType: [''],
        customScreenId:[''],
        fromDate:[''],
        quoteTypeId:[''],
        orderTypeId:[''],
        invoiceTypeId:['']
       
        
      });
    
  }
  
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
    });
    this.show=true;
  }
  onChange(event){
    this.show=false;
    if(event.value==="InvoiceType"){
      this.labelValue='Invoice Type';
      this.InvoiceDropdown=true;
      this.getAllDocTypeTemplateInitial(event.value);
    }
    else if(event.value==="QuoteType"){
      this.labelValue='Quote Type';
      this.QuoteDropdowm=true;
      this.getAllDocTypeTemplateInitialQuto(event.value);
    }
    else if(event.value==="OrderType"){
      this.labelValue='Order Type';
      this.OrderDropdown=true;
      this.getAllDocTypeTemplateInitialOrder(event.value);
    }
    this.doctype=event.value;
   

  }
  
  getAllDocTypeTemplateInitial(id) {
    this.spinner.show();
    this.accountsService.getAllDocTypeTemplateInitialData(id).subscribe((res: any) => {

      this.typeId = res.data.docType;
      this.spinner.hide();
      for (const value of this.typeId) {
        this.typeIdArray.push({
          label: value.description,
          value: value.invoiceTypeId
        })
      }
    })
   
  }
  getAllDocTypeTemplateInitialQuto(id) {
    this.spinner.show();
    this.accountsService.getAllDocTypeTemplateInitialData(id).subscribe((res: any) => {

      this.quoteTypeId = res.data.docType;
      this.spinner.hide();
      for (const value of this.quoteTypeId) {
        this.quoteTypeIdArray.push({
          label: value.description,
          value: value.quoteTypeId
        })
      }

    })
   
  }
  getAllDocTypeTemplateInitialOrder(id) {
    this.spinner.show();
    this.accountsService.getAllDocTypeTemplateInitialData(id).subscribe((res: any) => {

      this.typeIdOrder = res.data.docType;
      this.spinner.hide();
      for (const value of this.typeIdOrder) {
        this.typeIdArrayOrder.push({
          label: value.description,
          value: value.orderTypeId
        })
      }
    })
   
  }
  onSubmit() {
    this.spinner.show();
    const fromActualdate=this.createDoc.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "customScreenId":  this.createDoc.value.customScreenId,
      "fromDate": this.actualfromdate,
      "invoiceTypeId": this.createDoc.value.invoiceTypeId,
      "orderTypeId":  this.createDoc.value.orderTypeId,
      "partyId":this.partyId,
      "quoteTypeId":  this.createDoc.value.quoteTypeId,
    }
    this.accountsService.postAllDocTypeTemplate(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }}
        else{
          this._ToastrService.error(err.error.message);
        }
    });
  }
  
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
  }

 }
