<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">The Profile of {{demoName}} {{demoId}} </span>
                <span class="d-flex">

                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <div class="divA">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Current Employment information
                                                        </h4>
                                                        <!-- Added Create Button -->
                                                        <!-- <span class="edit-buttons hCreate" data-toggle="modal" data-target="#" >Create</span> -->
                                                        <!-- <span class="edit-buttons">List All</span> -->
                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div *ngIf="employeeDetail" class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Company</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.company
                                                                                !=={} ?
                                                                                employeeDetail.company.groupName:""}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Position</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.position}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Salary</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.salary}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Department </p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.department
                                                                                !==null ?
                                                                                employeeDetail.department.departmentName:""}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-4 col-12">Assign To</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-6 col-12">
                                                                                <p-dropdown filter="true"
                                                                                    class="dropdown"
                                                                                    (ngModelChange)="onChange($event)"
                                                                                    [(ngModel)]="initialStage"
                                                                                    [options]="departmantArray"
                                                                                    [ngModelOptions]="{standalone: true}"
                                                                                    placeholder="select department"
                                                                                    optionlabel="label"></p-dropdown>
                                                                                <button style="margin-left: 108%;
                                          margin-top: -44%;" class="btn btn-outline-secondary" (click)="assign()"
                                                                                    type="text">Assign</button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Contact Information
                                                        </h4>
                                                        <div class="d-flex flex-row">
                                                            <span class="edit-buttons" data-toggle="modal"
                                                                data-target="#exampleModalTwo"
                                                                style="margin-left:-3%!important ;">Create New Contact
                                                                Information
                                                                Address</span>
                                                            <span class="edit-buttons ml-2 hCreate" data-toggle="modal"
                                                                data-target="#exampleModalThird">Create New Contact
                                                                Information
                                                                Telecom</span>
                                                        </div>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                            <div class="form-group" style="width: 100%!important;">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="contactInformation" [rows]="rows"
                                                                        scrollWidth="100%" scrollHeight="150px"
                                                                        [paginator]="false" [totalRecords]="total"
                                                                        [scrollable]="true"
                                                                        (sortFunction)="customSort($event)">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    style="width:150px;">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Contact Type
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    style="width:150px;">
                                                                                    <div style="color: white;">
                                                                                        Contact Information
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    style="width:150px;">
                                                                                    <div style="color: white;">
                                                                                        Soliciting OK?
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="price"
                                                                                    style="width:150px;">
                                                                                    <div style="color: white;"> Action
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td
                                                                                    style="width:150px;padding:10px!important;">
                                                                                    <ng-container
                                                                                        *ngIf="product.contactMech.contactMechTypeId==='POSTAL_ADDRESS'">
                                                                                        {{product.contactMechType.description}}
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="product.contactMech.contactMechTypeId==='EMAIL_ADDRESS'">
                                                                                        {{product.contactMechType.description}}
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="product.contactMech.contactMechTypeId==='TELECOM_NUMBER'">
                                                                                        {{product.contactMechType.description}}
                                                                                    </ng-container>
                                                                                </td>
                                                                                <td
                                                                                    style="width:150px;padding:10px!important;">
                                                                                    <div class="checkbox-align">
                                                                                        <ng-container
                                                                                            *ngIf="product.contactMech.contactMechTypeId==='POSTAL_ADDRESS'">
                                                                                            {{product.postalAddress.address1}}
                                                                                            ,
                                                                                            {{product.postalAddress.address2}},
                                                                                            <br>{{product.postalAddress.stateProvinceGeoId}}
                                                                                            ,
                                                                                            {{product.postalAddress.city}},
                                                                                            <br>{{product.postalAddress.postalCode}},{{product.postalAddress.countryGeoId}}
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="product.contactMech.contactMechTypeId==='EMAIL_ADDRESS'">
                                                                                            {{product.contactMech.infoString}}
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="product.contactMech.contactMechTypeId==='TELECOM_NUMBER'">
                                                                                            <!--   Area Code: {{product.telecomNumber.areaCode}}, Contact Number:
                                              {{product.telecomNumber.contactNumber}}, Country Code:
                                              {{product.telecomNumber.countryCode}} -->
                                                                                            Contact Number:
                                                                                            {{product.telecomNumber.contactNumber}}
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </td>
                                                                                <td
                                                                                    style="width:150px;padding:10px!important;">
                                                                                    {{product.partyContactMech.allowSolicitation
                                                                                    ?product.partyContactMech.allowSolicitation
                                                                                    :'N' }}
                                                                                </td>
                                                                                <td
                                                                                    style="width:150px;padding:10px!important;">
                                                                                    <button type="submit"
                                                                                        (click)="updateData(product.contactMech.contactMechId ,product.contactMech.contactMechTypeId)"
                                                                                        class="btn btn-secondary submit-btn">Update</button>


                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>

                                                                    </p-table>



                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            List Related Customers
                                                        </h4>
                                                        <span class="edit-buttons hCreate"
                                                            data-target="#exampleModalFour" data-toggle="modal">Create
                                                            New Customers</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                            <div class="form-group">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="EditListRelatedAccountList"
                                                                        scrollHeight="100px" [paginator]="false"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        To Party ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th pSortableColumn="quantity">
                                                                                    <div style="color: white;">
                                                                                        Type
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">
                                                                                        Comment
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align p-1">

                                                                                        {{product.dataNode.partyIdTo}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{{product.dataNode.partyRelationshipTypeId}}
                                                                                </td>
                                                                                <td>{{product.dataNode.comments}}
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Training
                                                        </h4>
                                                        <!-- Added Create Button -->
                                                        <!-- <span class="edit-buttons hCreate" data-toggle="modal" data-target="#" >Create</span> -->
                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                            <div class="form-group">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table scrollHeight="100px" [paginator]="false"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [value]="empTraining"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        From Date
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Through
                                                                                        Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;">Training
                                                                                        Class Type
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;">Approver
                                                                                        Status
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;">Approver
                                                                                        Party
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">

                                                                                        {{product.fromDate|
                                                                                        date:"yyyy-MM-dd hh:mm:ss"}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{{product.thruDate| date:"yyyy-MM-dd
                                                                                    hh:mm:ss"}}</td>
                                                                                <td>{{product.classType}}</td>
                                                                                <td>{{product.status}}</td>
                                                                                <td>{{product.firstName}}
                                                                                    {{product.middleName}}
                                                                                    {{product.lastName}}</td>



                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                              

                                                <div class="w3-card-4 classCardView mt-2">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Departments List
                                                            </h4>
                                                            <!-- <span class="edit-buttons hCreate" data-target="#addNewDepartmentPopup" data-toggle="modal">Create Departments List</span> -->

                                                        </div>
                                                    </header>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">

                                                                <div class="card own-account-table">
                                                                    <p-table [value]="EditDepartmentList"
                                                                        scrollHeight="100px" [paginator]="false"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'160px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Department Name
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'160px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Department Id
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'160px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;"> Manager
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'160px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">Parent
                                                                                        Department
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'160px'}"
                                                                                    pSortableColumn="quantity">
                                                                                    <div style="color: white;">Employee
                                                                                        <p-sortIcon
                                                                                            field="quantity"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th pSortableColumn="price"
                                                                                    [ngStyle]="{'width':'160px'}">
                                                                                    <div style="color: white;">Action
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'160px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        <span class="account-button"
                                                                                            (click)="detailDepartmentPage(product.departmentId)">
                                                                                            {{product.departmentName}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'160px'}">
                                                                                    {{product.departmentId}}</td>
                                                                                <td [ngStyle]="{'width':'160px'}">
                                                                                    {{product.active}}</td>
                                                                                <td [ngStyle]="{'width':'160px'}">
                                                                                    {{product.parentDepartmentId}}</td>

                                                                                <td [ngStyle]="{'width':'160px'}">
                                                                                    <a>Employee</a></td>

                                                                                <td [ngStyle]="{'width':'160px'}">
                                                                                    <button type="submit"
                                                                                        (click)="onDeleteDepartment(product.departmentId)"
                                                                                        class="btn btn-danger ">Delete</button>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="w3-card-4 classCardView mt-2">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Timesheets List
                                                            </h4>
                                                            <span class="edit-buttons hCreate"
                                                                data-target="#newTimeSheetPopup"
                                                                data-toggle="modal">Create New TimeSheet</span>

                                                        </div>
                                                    </header>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">

                                                                <div class="card own-account-table">
                                                                    <p-table [value]="editTimeSheetList"
                                                                        scrollHeight="100px" [paginator]="false"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'200px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        Timesheet ID
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'150px'}">
                                                                                    <div style="color: white;"> Employee
                                                                                        ID
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'150px'}">
                                                                                    <div style="color: white;"> Approver
                                                                                        Party ID
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="quantity"
                                                                                    [ngStyle]="{'width':'150px'}">
                                                                                    <div style="color: white;"> From
                                                                                        Date
                                                                                        <p-sortIcon
                                                                                            field="quantity"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="price"
                                                                                    [ngStyle]="{'width':'150px'}">
                                                                                    <div style="color: white;"> Through
                                                                                        Date
                                                                                        <p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="price"
                                                                                    [ngStyle]="{'width':'150px'}">
                                                                                    <div style="color: white;"> Status
                                                                                        ID
                                                                                        <p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Approved
                                                                                        By User Login ID</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Comments
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Created
                                                                                        Date</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <!-- <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                <div style="color: white;">   Action</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th> -->
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'200px'}"
                                                                                    routerLinkActive="active"
                                                                                    (click)="updateTimesheetDetail(product.timesheetId)">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span
                                                                                            style="color:#0d3769;">{{product.timesheetId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.partyId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.clientPartyId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.fromDate | date
                                                                                    :"yyyy-MM-dd HH:mm:ss"}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.thruDate | date
                                                                                    :"yyyy-MM-dd HH:mm:ss"}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.statusId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.approvedByUserLoginId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.comments}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.createdStamp |date
                                                                                    :'yyyy-MM-dd HH:mm:ss'}}
                                                                                </td>
                                                                                <!-- <td [ngStyle]="{'width':'150px'}">
                                                                                <div>
                                                                                    <svg class="hover"
                                                                                        (click)="onUpdate(product.timesheetId)"
                                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                        height="16" viewBox="0 0 21.223 21.222">
                                                                                        <g transform="translate(0 -0.004)">
                                                                                            <g transform="translate(0 1.52)">
                                                                                                <path
                                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                    transform="translate(0 -34.137)" />
                                                                                            </g>
                                                                                            <g transform="translate(4.548 0.004)">
                                                                                                <g transform="translate(0 0)">
                                                                                                    <path
                                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                
                                                                                </div>
                                                                            </td> -->
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="w3-card-4 classCardView mt-2">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Employees Position List
                                                            </h4>

                                                            <!-- <span class="edit-buttons hCreate" data-target="#newEmployeePositionPopup" data-toggle="modal">Create New Employees Position</span> -->

                                                        </div>
                                                    </header>
                                                    <div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="EditEmployeePositionList"
                                                                            scrollHeight="100px" [paginator]="false"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Position ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Status Id
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">Party
                                                                                            ID
                                                                                            <p-sortIcon
                                                                                                field="category"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Budget ID
                                                                                            <p-sortIcon
                                                                                                field="quantity"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Budget Item
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">Emp
                                                                                            Position Type ID
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Estimated From Date
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Estimated Through Date
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Salary Flag
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Exempt Flag
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Temporary Flag
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Fulltime Flag
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Actual From Date
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Actual Through Date
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <!-- <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price"><div style="color: white;">Action <p-sortIcon
                                                                                        field="price"></p-sortIcon></div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th> -->
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}"
                                                                                        routerLinkActive="active">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span class="text-primary"
                                                                                                (click)="detailEmployeePosition(product.emplPositionId)">
                                                                                                {{product.emplPositionId}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.partyId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.budgetId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.budgetItemSeqId}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.emplPositionTypeId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.estimatedFromDate |
                                                                                        date :'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.estimatedThruDate |
                                                                                        date :'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.salaryFlag}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.exemptFlag}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.temporaryFlag}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.fulltimeFlag}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.actualFromDate | date
                                                                                        :'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.actualThruDate | date
                                                                                        :'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </td>


                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="w3-card-4 classCardView mt-2">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Skill List
                                                            </h4>
                                                            <span class="edit-buttons hCreate"
                                                                data-target="#appSkillPopUp" data-toggle="modal">Create
                                                                New Skill</span>

                                                        </div>
                                                    </header>
                                                    <div>

                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="EditEmployeeSkillList"
                                                                            scrollHeight="100px" [paginator]="false"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Skill Type Id
                                                                                            <p-sortIcon
                                                                                                field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Years Experience
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Rating
                                                                                            <p-sortIcon
                                                                                                field="category"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Skill Level
                                                                                            <p-sortIcon
                                                                                                field="quantity"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th style="width: 16%;"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Created At
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action</div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                style="visibility:hidden;"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td class="account-button" (click)="detailSkillPages(product.partyId,product.skillTypeId
                                                ,product.rating,product.skillLevel,product.yearsExperience)">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            {{product.skillTypeId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.yearsExperience}}</td>
                                                                                    <td>{{product.rating}}</td>
                                                                                    <td>{{product.skillLevel}}</td>

                                                                                    <td>{{product.createdStamp | date
                                                                                        :'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                    <td>

                                                                                        <button type="submit"
                                                                                            (click)="onDeleteSkills(product.partyId,product.skillTypeId)"
                                                                                            class="btn btn-danger ">Delete</button>

                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="divB">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Personal Information
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal"
                                                            data-target="#exampleModalCenter"
                                                            (click)="getEmployeeDetailEdit()">Update</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select"
                                                            style="height: 166px;overflow-y: scroll;">
                                                            <div class="color-black container">
                                                                <div *ngIf="employeeDetail" class="row">
                                                                    <div class="article-container">
                                                                        <div class="article" style="    padding-left: 42%;
                                                                        margin-left: 29%;">

                                                                            <button type="submit"
                                                                                (click)="uploadImage();"
                                                                                class="btn btn-secondary submit-btn">Edit</button>
                                                                            <br>&nbsp;

                                                                            <span *ngIf="!imagePic"
                                                                                style="    width: 27%;"><img
                                                                                    style="width: 170%;"
                                                                                    src="../../assets/images/user.png">
                                                                            </span>
                                                                            <span *ngIf="imagePic"
                                                                                style="    width: 27%;"><img
                                                                                    style="width: 170%;"
                                                                                    *ngIf="imageView" alt="small image"
                                                                                    class="mr-2 float-right img-thumbnail"
                                                                                    [src]="imageView"></span>
                                                                            <!--   <img style="width: 25%;" src="../../assets/images/user.png"> -->

                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Employee ID</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.partyId
                                                                                ?
                                                                                employeeDetail.partyId:'N/A'}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Name</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.firstName}}
                                                                                {{employeeDetail.lastName}}</span>

                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Comments</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.comments
                                                                                ?
                                                                                employeeDetail.comments:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">External ID</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.externalId
                                                                                ?
                                                                                employeeDetail.externalId:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Status ID</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.status
                                                                                ?
                                                                                employeeDetail.status:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">SS No.</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.socialSecurityNumber
                                                                                ?
                                                                                employeeDetail.socialSecurityNumber:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Gender </p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.gender
                                                                                ?
                                                                                employeeDetail.gender:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Date Of Birth</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.birthDate
                                                                                ?
                                                                                (employeeDetail.birthDate |date
                                                                                :'yyyy-MM-dd HH:mm:ss'):'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Marital Status
                                                                            </p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.maritalStatusEnumId
                                                                                ?
                                                                                employeeDetail.maritalStatusEnumId:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">U.S Citizen</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.usCitizen
                                                                                ?
                                                                                employeeDetail.usCitizen:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Ethnicity</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{employeeDetail.ethnicity
                                                                                ?
                                                                                employeeDetail.ethnicity:'N/A'}}</span>

                                                                        </div>




                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Employee Content
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body allUserCardDetail">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="contentList" scrollHeight="100px"
                                                                [paginator]="false" [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                Content Id
                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;"> Name
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">Content Type
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="quantity">
                                                                            <div style="color: white;">Party Content
                                                                                Type
                                                                                <p-sortIcon
                                                                                    field="quantity"></p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Status
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">From Date
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Action</div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                {{product.contentId}}
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.contentName}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.contentType}}</td>

                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.partyContentTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.status}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fromDate | date :'yyyy-MM-dd
                                                                            HH:mm:ss'}}
                                                                        </td>

                                                                        <td>{{product.price | currency: 'USD'}}</td>
                                                                        <td>{{product.category}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <svg (click)="export(product.contentId,product.contentName)"
                                                                                width="2em" height="2em"
                                                                                viewBox="0 0 16 16"
                                                                                class="bi bi-arrow-down-circle-fill"
                                                                                fill="currentColor"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd"
                                                                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                class="ml-20" width="16" height="16"
                                                                                (click)="onDelete(product.contentId,product.fromDate,product.partyContentTypeId,product.partyId)"
                                                                                viewBox="0 0 26 26">
                                                                                <defs>
                                                                                    <style>
                                                                                        .a {
                                                                                            fill: #f44336;
                                                                                        }

                                                                                        .b {
                                                                                            fill: #fafafa;
                                                                                            margin-left: 20px;
                                                                                        }
                                                                                    </style>
                                                                                </defs>
                                                                                <path class="a fill-color"
                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                <path class="b"
                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                    transform="translate(-147.576 -147.576)" />
                                                                            </svg>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>


                                                        </div>
                                                        <form [formGroup]="Editform">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select pb-0">
                                                                <div class="col-lg-4 col-12">
                                                                    <div class="form-group">

                                                                        <label for="exampleInputEmail1">Attach
                                                                            Document</label>
                                                                        <div class="suppliers-wrapper">
                                                                            <input type="file"
                                                                                class="inputField classFile"
                                                                                id="exampleInputEmail1"
                                                                                formControlName="file"
                                                                                aria-describedby="emailHelp"
                                                                                (change)="fileProgress($event)"
                                                                                accept="">

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4 col-12">
                                                                    <br><br>
                                                                    <div class="form-group">
                                                                        <p-dropdown filter="true"
                                                                            [options]="partyContentArray"
                                                                            placeholder="Enter Party Content"
                                                                            formControlName="partyContentTypeId"
                                                                            [(ngModel)]="partyContentTypeId"
                                                                            name="partyContentTypeId"
                                                                            (ngModelChange)="onpartyContent(partyContentTypeId)"
                                                                            optionlabel="label"
                                                                            placeholder="Select Purpose"></p-dropdown>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4 col-12">
                                                                    <br><br>
                                                                    <div class="form-group">


                                                                        <button type="submit" (click)="onSubmit()"
                                                                            class="btn btn-outline-secondary">Submit</button>

                                                                    </div>
                                                                </div>

                                                            </div>


                                                        </form>

                                                    </div>


                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Notes
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal"
                                                            data-target="#exampleModalFive">Create New Notes</span>
                                                    </div>
                                                    <br>
                                                    <div class="panel-body">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="leadNoteId" [rows]="rows"
                                                                        scrollWidth="100%" [scrollable]="true"
                                                                        scrollHeight="100px" [paginator]="false"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Note Name
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>

                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Note
                                                                                        <p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="quantity">
                                                                                    <div style="color: white;"> By
                                                                                        <p-sortIcon
                                                                                            field="quantity"></p-sortIcon>
                                                                                    </div>

                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Created
                                                                                        At
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>

                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Action
                                                                                    </div>
                                                                                    <div>

                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>

                                                                                <td
                                                                                    [ngStyle]="{'width':'170px','height':'35px'}">
                                                                                    <div class="checkbox-align">

                                                                                        {{product.noteName}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.noteInfo}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.firstName}}
                                                                                    {{product.middleName}}
                                                                                    {{product.lastName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.createdStamp |
                                                                                    date:'yyyy-MM-dd'}}
                                                                                </td>


                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <button type="submit"
                                                                                        (click)="onUpdateNotes(product.noteId,product.noteName,product.noteInfo)"
                                                                                        class="btn btn-secondary submit-btn">Update</button>
                                                                                    <button type="submit"
                                                                                        (click)="onDeleteNotes(product.noteId)"
                                                                                        class="btn btn-danger ">Delete</button>




                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Emergency Contact
                                                        </h4>
                                                        <span data-toggle="modal" data-target="#exampleModalSix"
                                                            class="edit-buttons hCreate">Create New Emergency
                                                            Contact</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                            <div class="form-group">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="emergencyContact" [rows]="rows"
                                                                        scrollWidth="100%" [totalRecords]="total"
                                                                        [scrollable]="true"
                                                                        (sortFunction)="customSort($event)">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Contact Type
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;">Contact
                                                                                        Number
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;">Relation
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Action
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td style="width: 25%;">
                                                                                    {{product.contactType}}</td>
                                                                                <td>
                                                                                    {{product.contactNumber}}
                                                                                </td>
                                                                                <td>
                                                                                    {{product.relation}}
                                                                                </td>
                                                                                <td>
                                                                                    <fa-icon [icon]="faEdit"
                                                                                        class="account-button"
                                                                                        routerLink="/hr/employees/emergency-contact"
                                                                                        [queryParams]="{partyId:product.partyId,personName:product.personName,contactNumber:product.contactNumber,relation:product.relation,contactTypeId:product.contactTypeId}">
                                                                                    </fa-icon>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>

                                                                    </p-table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                                <!-- Added By RA -->
                                                <div class="w3-card-4 classCardView  mt-3">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Leave List
                                                            </h4>

                                                            <span class="edit-buttons hCreate" data-toggle="modal"
                                                                data-target="#addNewLeavesPopup">Create New</span>
                                                        </div>
                                                    </header>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">

                                                                <div class="card own-account-table">
                                                                    <p-table [value]="EditEmployeeLeavesList"
                                                                        scrollHeight="100px" [paginator]="false"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Employee ID
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Leave
                                                                                        Type ID
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'150px'}">
                                                                                    <div style="color: white;">Leave
                                                                                        Reason Type
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="quantity">
                                                                                    <div style="color: white;">From Date
                                                                                        <p-sortIcon
                                                                                            field="quantity"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Through
                                                                                        Date
                                                                                        <p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Approver
                                                                                        Party
                                                                                        <p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Leave
                                                                                        Status
                                                                                        <p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Action
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span class="account-button"
                                                                                            (click)="detailPageLeave(product.EditEmployeeLeaves.partyId,product.EditEmployeeLeaves.leaveTypeId,product.fromDate,product.thruDate,product.EditEmployeeLeaves.approverPartyId,product.EditEmployeeLeaves.description,product.EditEmployeeLeaves.emplLeaveReasonTypeId)">
                                                                                            {{product.EditEmployeeLeaves.partyId}}</span>

                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.EditEmployeeLeaves.leaveTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'160px'}">
                                                                                    {{product.EditEmployeeLeaves.emplLeaveReasonTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.fromDate }}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.thruDate }}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.EditEmployeeLeaves.approverPartyId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.EditEmployeeLeaves.leaveStatus}}
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <button type="submit"
                                                                                        (click)="onDeleteLeave(product.EditEmployeeLeaves.partyId,product.EditEmployeeLeaves.leaveTypeId,product.fromDate)"
                                                                                        class="btn btn-danger ">Delete</button>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="w3-card-4 classCardView mt-2">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Find Party Qualification
                                                            </h4>
                                                            <span class="edit-buttons hCreate" data-toggle="modal"
                                                                data-target="#newPartyQualificationPopup">Create New
                                                                Qualification</span>

                                                        </div>
                                                    </header>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">


                                                                <div class="card own-account-table">
                                                                    <p-table [value]="EditPartyQualList"
                                                                        scrollHeight="100px" [paginator]="false"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Party ID
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> Party
                                                                                        Qual Type ID
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> From
                                                                                        Date
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;">
                                                                                        Qualification Desc
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> Title
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> Status
                                                                                        ID
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> Verif
                                                                                        Status ID
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> Through
                                                                                        Date
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;">Action
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td routerLinkActive="active"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span class="account-button"
                                                                                            (click)="qualificationDetail(product.data.partyId,product.data.partyQualTypeId,product.fromDate,
                                                                                       product.data.qualificationDesc, product.data.title, product.data.statusId,
                                                                                        product.data.verifStatusId ,product.thruDate)">
                                                                                            {{product.partyId}}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.partyQualTypeId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.fromDate |
                                                                                    date:'yyyy-MM-dd'}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.qualificationDesc}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.title}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.statusId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.verifStatusId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.thruDate |
                                                                                    date:'yyyy-MM-dd'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">


                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        (click)="deletePartyQual(product)"
                                                                                        class="ml-20" width="16"
                                                                                        height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }

                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                </td>



                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="w3-card-4 classCardView mt-2">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Find Performance Review
                                                            </h4>
                                                            <span class="edit-buttons hCreate New" data-toggle="modal"
                                                                data-target="#performaceReviewPopup">Create New
                                                                Performance Review</span>

                                                        </div>
                                                    </header>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">


                                                                <div class="card own-account-table">
                                                                    <p-table [value]="EditPerfReviewList"
                                                                        scrollHeight="100px" [paginator]="false"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        Employee Party ID
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> Perf
                                                                                        Review ID
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> Manager
                                                                                        Party ID
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> Payment
                                                                                        ID
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;">Empl
                                                                                        Position ID
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> From
                                                                                        Date
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> Through
                                                                                        Date
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;">Comments
                                                                                        <p-sortIcon
                                                                                            field="category"></p-sortIcon>

                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td routerLinkActive="active"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span class="account-button"
                                                                                            (click)="employeeProfile(product.perfReviewId)">
                                                                                            {{product.employeePartyId}}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.perfReviewId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.managerPartyId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.paymentId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.emplPositionId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.fromDate}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.thruDate}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.comments}}</td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Personal Information</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <header class="w3-container w3-blue">
                        <div class="header-tabs">
                            <h4 class=" common-styling" style="margin-left: 7px;">
                                Edit Personal Information
                            </h4>

                        </div>
                    </header>
                    <div class=" bg-white color-grey">


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="empPersonalFormService.emp_personal_form"
                                        [formGroup]="empPersonalFormService.emp_personal_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party Id </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="partyId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" disabled>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">External Id </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="externalId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter External Id">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">First Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="firstName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter First name">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Last Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="lastName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Last name">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Comment</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="comments"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comment">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="statusId"
                                                            [options]="crmArray" optionlabel="label"
                                                            placeholder="Enter Status"></p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="description">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" placeholder="Description"
                                                            formControlName="description" id="exampleInputEmail1"
                                                            class="form-control">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <div class="form-group" style="color: black!important">Preferred
                                                            Currency</div>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="currencyList"
                                                            placeholder="Preferred Currency" filter="true"
                                                            formControlName="preferredCurrencyUomId"></p-dropdown>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>


                        <div class="main-submit-button" style="    margin-right: 32%;">
                            <button type="submit" (click)="onUpdate()"
                                class="btn btn-secondary submit-btn">Update</button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalTwo" tabindex="-1" role="dialog" aria-labelledby="exampleModalTwoTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Postal Address</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <header class="w3-container w3-blue">
                        <div class="header-tabs">
                            <h4 class=" common-styling" style="margin-left: 7px;">
                                {{show ? 'Update':'Create'}} Postal Address
                            </h4>

                        </div>
                    </header>
                    <div class=" bg-white color-grey">

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="addEmployeeFormService.postal_employee_form"
                                        [formGroup]="addEmployeeFormService.postal_employee_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                        </div>

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party Id </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="partyId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" disabled>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Address 1 <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="address1"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Address 2 </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="address2"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">City <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="city" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">State</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="stateArray"
                                                            formControlName="stateProvinceGeoId" filter="true"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Zip/Postal Code <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="postalCode"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Postal Code">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Country</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="array"
                                                            (ngModelChange)="onCountryChange($event)"
                                                            formControlName="countryGeoId" filter="true"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Solicitation</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="solicitation"
                                                            formControlName="allowSolicitation" filter="true"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">To Name </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="toName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Alternative Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="attnName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp">
                                                    </div>

                                                </div>
                                            </div>



                                            <div class="col-lg-4 col-12">
                                                <div class="form-group">


                                                    <input type="hidden" formControlName="contactMechId"
                                                        class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                        </div>


                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="main-submit-button" style="    margin-right: 33%;">
                        <button type="submit" *ngIf="show" (click)="onUpdatePostal()"
                            class="btn btn-secondary submit-btn">Update</button>
                        <button type="submit" *ngIf="!show" (click)="onSubmitPostal()"
                            class="btn btn-secondary submit-btn">Submit</button>


                    </div>
                    <br><br>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalThird" tabindex="-1" role="dialog" aria-labelledby="exampleModalThirdTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Contact</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <header class="w3-container w3-blue">
                        <div class="header-tabs">
                            <h4 class=" common-styling" style="margin-left: 7px;">
                                {{editMode? 'Update':'Create'}} Contact
                            </h4>

                        </div>
                    </header>
                    <div class=" bg-white color-grey">

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="contactForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group" style="    text-align: right!important;
                                        padding-right: 15px!important;
                                        margin-left: -4%;
                                    ">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                            font-size: 13px!important; font-weight:600!important;">Mech Purpose Type
                                                            Id<span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="contactPurpose"
                                                            placeholder="Mech Purpose Type Id"
                                                            formControlName="contactMechPurposeTypeId"></p-dropdown>
                                                        <small
                                                            *ngIf="!!contactForm.controls.contactMechPurposeTypeId.invalid && !!contactForm.controls.contactMechPurposeTypeId.touched"
                                                            class="text-danger">Mech Purpose Type Id is required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group"
                                                        style="text-align: right!important;padding-right: 15px!important;margin-left: 1%;">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                            font-size: 13px!important; font-weight:600!important;">Allow
                                                            Solicitation</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="arrayA" placeholder="Select"
                                                            formControlName="allowSolicitation">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group" style="    text-align: right!important;
                                        padding-right: 15px!important;
                                        margin-left: -4%;
                                    ">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                            font-size: 13px!important; font-weight:600!important;">Country Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            placeholder="Country Code" formControlName="countryCode">
                                                    </div>

                                                    <div class="col-lg-3 form-group" style="    text-align: right!important;
                                        padding-right: 15px!important;
                                        margin-left: 1%;
                                    ">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                            font-size: 13px!important; font-weight:600!important;">Area Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Area Code"
                                                            formControlName="areaCode">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group" style="    text-align: right!important;
                                        padding-right: 15px!important;
                                        margin-left: -4%;
                                    ">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                            font-size: 13px!important; font-weight:600!important;">Fax Number</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Fax Number"
                                                            formControlName="contactNumber">
                                                    </div>

                                                    <div class="col-lg-3 form-group" style="    text-align: right!important;
                                        padding-right: 15px!important;
                                        margin-left: 1%;
                                    ">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                            font-size: 13px!important; font-weight:600!important;">Extension</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Extension"
                                                            formControlName="extension">
                                                    </div>

                                                </div>
                                            </div>





                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: -21%;">
                                        <button type="submit" *ngIf="editMode" (click)="updateTelecom()"
                                            class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submitTelecom()"
                                            class="btn btn-secondary submit-btn">Submit</button>
                                        <!-- <button type="submit" (click)="cancelSubmit();" class="btn btn-degault cncl-btn">Cancel</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalFour" tabindex="-1" role="dialog" aria-labelledby="exampleModalFourTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Customer</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                    Add Related Customer </a></li>
                        </ul>
                    </div>
                    <div class="panel-group create-new-leade ">
                        <div *ngIf="activeCategory==1">

                            <div class="panel-body">
                                <form [formGroup]="RelatedCustomerForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput" style="margin-left: -11%;">
                                                    <label for="exampleInputEmail1">Customer ID</label>
                                                </div>
                                                <div class="col-lg-2">

                                                    <p-dropdown formControlName="accountPartyId"
                                                        [options]="contactArray" optionlabel="label"
                                                        id="exampleInputEmail1" filter="true"
                                                        placeholder="Select Select Customer ID "></p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes"
                                                    style="margin-left: -2%;">
                                                    <label for="exampleInputEmail1">Comments</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" formControlName="comments"
                                                        placeholder="Enter Comments ">



                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    <div class="proper mb-5" style="margin-left: 73%;">

                                        <button type="submit" (click)="onSubmitContact()"
                                            class="btn btn-secondary submit-btn mr-2">Save</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalFive" tabindex="-1" role="dialog" aria-labelledby="exampleModalFiveTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Note</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="panel panel-default">
                            <div class="container-fluid">
                                <ul class="tabbing-section tabbing-accordians tabClass">

                                    <li [ngClass]="activeCategory==1?'active':''"><a>
                                            New Party Note</a></li>


                                </ul>
                            </div>

                            <div>
                                <div class="panel-body create-new-leade ">
                                    <form [formGroup]="fileUploadForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput"
                                                        style="margin-left: -3%;">
                                                        <label for="exampleInputEmail1">Note Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" [(ngModel)]="noteNameForUpdate"
                                                            formControlName="noteName" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp">
                                                    </div>

                                                    <div class="col-lg-2 form-group classInput__new_changes"
                                                        style="margin-left: -3%;">
                                                        <label for="exampleInputEmail1">Note</label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <textarea
                                                            style="height: 100%!important;width:319%;padding-left: 17px;"
                                                            id="w3review" [(ngModel)]="noteForUpdate"
                                                            formControlName="note" rows="3" cols="30"
                                                            placeholder="Note">
                                      </textarea>


                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div style="margin-right: 10%;" class="mb-4">
                                            <div class="col-lg-12 col-12 main-submit-button">
                                                <button type="submit" (click)="onSubmitNote()"
                                                    class="btn btn-secondary submit-btn mr-2">Create</button>
                                                <button type="submit" (click)="navigateFromCreate()"
                                                    class="btn btn-danger ">Cancel</button>
                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateBtn">
                                                <button type="submit" (click)="onUpdateNote()"
                                                    class="btn btn-secondary submit-btn">Update</button>
                                                <button type="submit" (click)="navigate()"
                                                    class="btn btn-danger ">Cancel</button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalSix" tabindex="-1" role="dialog" aria-labelledby="exampleModalSixTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Emergency Contact</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <header class="w3-container w3-blue">
                        <div class="header-tabs">
                            <h4 class=" common-styling" style="margin-left: 7px;">
                                {{editModeA? 'Update':'Create'}} Emergency Contact
                            </h4>

                        </div>
                    </header>
                    <div class=" bg-white color-grey">
                        <div class="port-header-contact create-lead font-medium mb-0">

                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="emergencyContactForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group" style="    text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -9%;">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                            font-size: 13px!important; font-weight:600!important;">Contact Type
                                                            Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="arrayB" placeholder="Select"
                                                            formControlName="contactTypeId"></p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group" style="    text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -3%;">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                            font-size: 13px!important; font-weight:600!important;">Person Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            placeholder="Person Name" formControlName="personName">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group" style="    text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -9%;">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                            font-size: 13px!important; font-weight:600!important;">Relation</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Relation"
                                                            formControlName="relation">
                                                    </div>

                                                    <div class="col-lg-3 form-group" style="    text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -3%;">
                                                        <label for="exampleInputEmail1" style=" color:black !important;
                                            font-size: 13px!important; font-weight:600!important;">Contact
                                                            Number</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            placeholder="Contact Number"
                                                            formControlName="contactNumber">
                                                    </div>

                                                </div>
                                            </div>



                                        </div>
                                    </form>
                                    <div class="main-submit-button mb-4" style="margin-left: -30%;">
                                        <button type="submit" *ngIf="editModeA" (click)="update()"
                                            class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editModeA" (click)="submit()"
                                            class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Added By RA -->
<!-- Start Code -->
<div class="modal fade" id="addNewLeavesPopup" tabindex="-1" role="dialog" aria-labelledby="addNewLeavesPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add New Leaves</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton11>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                        Add New Employee Leaves</a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="addLeaveService.add_leave_form"
                                        [formGroup]="addLeaveService.add_leave_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Employee Party ID <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="partyId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Party ID ">

                                                        <!-- <p-dropdown filter="true" [options]="partyIdArray" formControlName="partyId" optionlabel="label" placeholder="Enter Party ID">
                                                        </p-dropdown> -->
                                                        <small class="text-danger"
                                                            *ngIf="!!addLeaveService.add_leave_form.controls.partyId.invalid && !!addLeaveService.add_leave_form.controls.partyId.touched">
                                                            Emp Party ID is required!</small>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Leave Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="leaveId"
                                                            formControlName="leaveTypeId" optionlabel="label"
                                                            placeholder="Enter Leave Type ID">

                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Leave Reason Type<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="leaveReasonArray"
                                                            formControlName="emplLeaveReasonTypeId" optionlabel="label"
                                                            placeholder="Enter Leave Reason Type">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">From Date <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="From Date">
                                                        <small class="text-danger"
                                                            *ngIf="!!addLeaveService.add_leave_form.controls.fromDate.invalid && !!addLeaveService.add_leave_form.controls.fromDate.touched">
                                                            From Date is required!</small>




                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Through Date">

                                                        <small class="text-danger"
                                                            *ngIf="!!addLeaveService.add_leave_form.controls.thruDate.invalid && !!addLeaveService.add_leave_form.controls.thruDate.touched">
                                                            Through Date is required!</small>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Approver Party <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="partyIdArray"
                                                            optionlabel="label" placeholder="Enter Approver Party"
                                                            formControlName="approverPartyId">

                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!addLeaveService.add_leave_form.controls.approverPartyId.invalid && !!addLeaveService.add_leave_form.controls.approverPartyId.touched">
                                                            Approver PartyId is required!</small>




                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="description"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter description">
                                                    </div>

                                                    <!-- <div class="col-lg-3 form-group classInput__new_changes" *ngIf="leave">
                                                        <label for="exampleInputEmail1">Leave</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="leave">
                                                        <p-dropdown filter="true" [options]="leaveStatusReoortArray" formControlName="leaveStatus" optionlabel="label" placeholder="Enter Leave">
                                                        </p-dropdown>

                                                    </div> -->

                                                </div>
                                            </div>

                                        </div>
                                        <div class="main-submit-button" style="
                                        margin-right: 12%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmitLeave()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                            <span *ngIf="show">

                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="newPartyQualificationPopup" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{show?'Update':'Create'}} New Party Qualification</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create New Party Qualification </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update New Party Qualification </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createQualification" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">
                                                            Qualification Desc</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="QualificationDesc"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Qualification Desc">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Title</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Title" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Title">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Status ID"
                                                            formControlName="StatusID" [options]="VerifStatusIDArray"
                                                            optionlabel="label" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Verif Status ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Verif Status ID"
                                                            formControlName="VerifStatusID"
                                                            [options]="VerifStatusIDArray" optionlabel="label"
                                                            filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="ThroughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Title">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        {{employeeDetails}}
                                                        <!-- <p-dropdown placeholder="Enter Party ID" formControlName="PartyID" [options]="partyIdArray" optionlabel="label" filter="true">

                                                        </p-dropdown> -->
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Party Qual Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Party Qual Type ID"
                                                            formControlName="PartyQualTypeID"
                                                            [options]="PartyQualTypeIdArray" optionlabel="label"
                                                            filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Budget ID">

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Comments"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>


                                                </div>
                                            </div>




                                        </div>

                                        <div class="main-submit-button" style="margin-right: 16%;">
                                            <button type="submit" *ngIf="show"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!show"
                                                class="btn btn-secondary submit-btn ml-2"
                                                (click)="onSubmits()">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="performaceReviewPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{show?'Update':'Create'}} New Performance Review</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton11>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create New Performance Review </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update New Performance Review </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createReview" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Employee Party ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Employee Party ID"
                                                            formControlName="EmployeePartyID" filter="true"
                                                            [options]="partyIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Perf Review ID </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="PerfReviewID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Perf Review ID">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Manager Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        {{employeeDetails}}
                                                        <!-- <p-dropdown placeholder="Enter Manager Party ID" formControlName="ManagerPartyID" filter="true" [options]="partyIdArray" optionlabel="label">

                                                        </p-dropdown> -->
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Manager Role Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Manager Role Type ID"
                                                            formControlName="ManagerRoleTypeID" filter="true"
                                                            [options]="allroleArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Payment ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="PaymentIDArray" optionlabel="label"
                                                            placeholder="Enter Payment ID" formControlName="PaymentID"
                                                            filter="true">


                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Empl Position ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Empl Position ID"
                                                            formControlName="EmplPositionID" filter="true"
                                                            [options]="positionArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Budget ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="ThroughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Budget ID">

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Comments"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>


                                                </div>
                                            </div>




                                        </div>

                                        <div class="main-submit-button" style="margin-right: 16%;">
                                            <button type="submit" *ngIf="show"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" (click)="onSubmitPerformanceReview();" *ngIf="!show"
                                                class="btn btn-secondary submit-btn ml-2">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="addNewDepartmentPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Add New Department</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Add New Department </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Department </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="createDepartmentFormService.job_requistion_form"
                                        [formGroup]="createDepartmentFormService.job_requistion_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Department Name <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="departmentName"
                                                            placeholder="Enter Department name">
                                                        <small class="text-danger"
                                                            *ngIf="!!createDepartmentFormService.job_requistion_form.controls.departmentName.invalid && !!createDepartmentFormService.job_requistion_form.controls.departmentName.touched">
                                                            Department Name is required!</small>
                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Manager</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="partyIdArray"
                                                            optionlabel="label" placeholder="Enter Manager"
                                                            formControlName="partyId">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Company</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Company"
                                                            formControlName="companyNameId" [options]="companyArray"
                                                            filter="true" optionlabel="label">
                                                        </p-dropdown>


                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                        <div class="main-submit-button" style="margin-right: 15%;">
                                            <button type="submit" (click)="onSubmitDepartment()"
                                                class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="newTimeSheetPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add New Timesheet</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width:100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Create New Employee Timesheet</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="createTimesheetFormService.create_timesheet_form"
                                        [formGroup]="createTimesheetFormService.create_timesheet_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Employee ID <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" placeholder="Enter Employee ID"
                                                            formControlName="partyId" [options]="partyIdArray"
                                                            optionlabel="label"></p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!createTimesheetFormService.create_timesheet_form.controls.partyId.invalid && !!createTimesheetFormService.create_timesheet_form.controls.partyId.touched">
                                                            Employee Id is required!</small>



                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Approver Party </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="clientPartyId"
                                                            [options]="partyIdArray" optionlabel="label"
                                                            placeholder="Enter Approver Party"></p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="From Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Through Date">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Status <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="statusId"
                                                            [options]="statusArray" optionlabel="label"
                                                            placeholder="Enter Status"></p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!createTimesheetFormService.create_timesheet_form.controls.statusId.invalid && !!createTimesheetFormService.create_timesheet_form.controls.statusId.touched">Status
                                                            is required!</small>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Comments </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="comments"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comments">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Approved By User Login ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                            formControlName="approvedByUserLoginId"
                                                            [options]="userLoginArray" optionlabel="label"
                                                            placeholder="Approved by user login Id"></p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 7%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmitTimeSheet()"
                                                class="btn btn-secondary submit-btn">Save</button>
                                            <!-- <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button> -->

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="newEmploymentApplicationPopup" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create New Employment Application</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width:100%">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <!--
                                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)"><a>
                                        New Employment Application</a></li> -->
                                <li [ngClass]="activeCategory==3?'active':''"><a>
                                        Personal </a></li>
                                <li [ngClass]="activeCategory==4?'active':''"><a>
                                        Desired Position </a></li>
                                <li [ngClass]="activeCategory==5?'active':''"><a>
                                        Education </a></li>
                                <li [ngClass]="activeCategory==6?'active':''"><a>
                                        General </a></li>
                                <li [ngClass]="activeCategory==7?'active':''"><a>
                                        References</a></li>
                                <li [ngClass]="activeCategory==8?'active':''"><a>
                                        Employment History </a></li>
                                <li [ngClass]="activeCategory==9?'active':''"><a>
                                        To Be Completed By The Company</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <!-- <form class="w-100" *ngIf="createEventFormService.employee_application_form"
                                        [formGroup]="createEventFormService.employee_application_form"> -->
                                    <form class="w-100" [formGroup]="createEmploymentForm">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <!-- <div *ngIf="activeCategory==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            GENERAL LEAD INFORMATION
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">

                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Application
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="applicationId"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Application Id">


                                                                    </div>

                                                                    <div
                                                                        class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Empl
                                                                            PositionId</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true"
                                                                            placeholder="Empl Position name"
                                                                            formControlName="emplPositionId"
                                                                            [options]="positionArray"
                                                                            optionlabel="label">
                                                                        </p-dropdown>


                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Status Id<span
                                                                                style="color:red">*</span> </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true"
                                                                            placeholder="Status Id"
                                                                            formControlName="statusId"
                                                                            [options]="statusArray" optionlabel="label">
                                                                        </p-dropdown>

                                                                    </div>

                                                                    <div
                                                                        class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Employment App
                                                                            Source
                                                                            Type
                                                                            Id<span style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown class="lists"
                                                                            placeholder="Employment App Source Type Id"
                                                                            filter="true"
                                                                            formControlName="employmentAppSourceTypeId"
                                                                            [options]="appSourceArray"
                                                                            optionlabel="label">
                                                                        </p-dropdown>


                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Applying Party
                                                                            Id<span style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown placeholder="Applying Party Id"
                                                                            class="lists" filter="true"
                                                                            formControlName="applyingPartyId"
                                                                            [options]="partyIdArray"
                                                                            optionlabel="label">
                                                                        </p-dropdown>


                                                                    </div>


                                                                    <div
                                                                        class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Application
                                                                            Date<span style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="date"
                                                                            formControlName="applicationDate"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" >



                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Referred By
                                                                            Party
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown placeholder="Refered By Party Id"
                                                                            class="lists" filter="true"
                                                                            formControlName="referredByPartyId"
                                                                            [options]="partyIdArray"
                                                                            optionlabel="label">
                                                                        </p-dropdown>

                                                                    </div>
                                                                    <div class="col-lg-2"></div>

                                                                </div>
                                                            </div>



                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit" *ngIf="!show" (click)="onSubmit()"
                                                                class="btn btn-secondary submit-btn">Submit</button>
                                                            <button type="submit" *ngIf="show" (click)="onUpdate()"
                                                                class="btn btn-secondary submit-btn">Update</button>

                                                        </div>
                                                    </div>
                                                </div> -->


                                                <div *ngIf="activeCategory==3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            PERSONAL INFORMATION

                                                        </h4>


                                                    </div>


                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">


                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Name
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="name"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Name">


                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Social
                                                                        Security Number
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"
                                                                        formControlName="socailSecurityNumber"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Social Security Number">

                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Address

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="address"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Address">

                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Telephone
                                                                        Number

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="telephoneNo"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Telephone Number">


                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Other Phone
                                                                        number
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"
                                                                        formControlName="OtherPhonenumber"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Other Phone Number">


                                                                </div>


                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Have you
                                                                        ever been convicted of a felony /crime
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true"
                                                                        placeholder="Choose Your Answer"
                                                                        [options]="yesNoArray" optionlabel="label"
                                                                        formControlName="crime">
                                                                    </p-dropdown>




                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">If Yes,
                                                                        Please Explain
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="reason"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Reason">

                                                                </div>
                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Date
                                                                        <span style="color:red">*</span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="empDate"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Date ">

                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div class="main-submit-button" style="margin-right: 23%;">

                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="changeactiveCategory(4)">Continue</button>


                                                    </div>

                                                </div>

                                                <div *ngIf="activeCategory==4">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            DESIRED POSITION
                                                        </h4>


                                                    </div>


                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Position
                                                                        Applied For
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">

                                                                    <input type="text"
                                                                        formControlName="PositionAppliedFor"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Position Applied For">


                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Date
                                                                        available for work
                                                                        <span style="color:red">*</span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="DateAvailable"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Date available for work">

                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">How Were
                                                                        You
                                                                        Referred To Us

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" formControlName="referred"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Your Answer">

                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Desire
                                                                        Pay
                                                                        Rate


                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="payRate"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Pay Rate">


                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Availability
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">

                                                                    <p-dropdown filter="true"
                                                                        placeholder="Choose Your Answer"
                                                                        [options]="AvailabilityArray"
                                                                        optionlabel="label" formControlName="available">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="main-submit-button" style="margin-right:18%;">
                                                        <button type="submit" (click)="changeactiveCategory(3)"
                                                            class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="changeactiveCategory(5)">Continue</button>


                                                    </div>

                                                </div>

                                                <div *ngIf="activeCategory==5">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            EDUCATION
                                                        </h4>


                                                    </div>


                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class='' style="color: black;">List educational
                                                                qualifications you believe will help you perform the job
                                                                for which you are applying.</div>
                                                            <div class="row mt-4">
                                                                <div class="col-lg-3 form-group classInput ">
                                                                    <label for="exampleInputEmail1">School
                                                                        Address-1

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">

                                                                    <input type="text" formControlName="SchoolAddress"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter  School Address">

                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Degree/Diploma-1

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" formControlName="degree"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Degree/Dimloma">

                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">School
                                                                        Address-2

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">

                                                                    <input type="text"
                                                                        formControlName="SchoolAddressTwo"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter School Address">

                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Degree/Diploma-2

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" formControlName="degreeTwo"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Degree/Dimloma">

                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">School
                                                                        Address-3

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">

                                                                    <input type="text"
                                                                        formControlName="SchoolAddressThree"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter School Address">

                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Degree/Diploma-3

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" formControlName="degreeThree"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Degree/Dimloma">

                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">School
                                                                        Address-4

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">

                                                                    <input type="text"
                                                                        formControlName="SchoolAddressFour"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter School Address">

                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Degree/Diploma-4

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" formControlName="degreeFour"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Degree/Dimloma">

                                                                </div>


                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="main-submit-button" style="margin-right: 24%;">
                                                        <button type="submit" (click)="changeactiveCategory(4)"
                                                            class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="changeactiveCategory(6)">Continue</button>


                                                    </div>


                                                </div>

                                                <div *ngIf="activeCategory==6">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            GENERAL INFORMATION
                                                        </h4>


                                                    </div>


                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputB">
                                                                    <label for="exampleInputEmail1">Professional
                                                                        License Held

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-2 ">

                                                                    <input type="text"
                                                                        formControlName="ProfessionalLicense"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Professional License Held">


                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">

                                                                    <label for="exampleInputEmail1">Expiration
                                                                        Date
                                                                        <span style="color:red">*</span>
                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="Expiration"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Date available for work">

                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputB">
                                                                    <label for="exampleInputEmail1">Have you
                                                                        ever worked or filled and
                                                                        application
                                                                        here before?

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <p-dropdown placeholder="Choose Your Answer"
                                                                        [options]="yesNoArray" optionlabel="label"
                                                                        filter="true"
                                                                        formControlName="applicationFilled">
                                                                    </p-dropdown>

                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">If Yes,
                                                                        Please list dates and explain

                                                                    </label>



                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="explain"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Pay Rate">


                                                                </div>

                                                            </div>
                                                        </div>


                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputB">
                                                                    <label for="exampleInputEmail1">What is
                                                                        your
                                                                        means of transportation to work?

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown placeholder="Choose Your Answer"
                                                                        filter="true" formControlName="transportation"
                                                                        [options]="transportationArray"
                                                                        optionlabel="label">
                                                                    </p-dropdown>

                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Do you
                                                                        have
                                                                        a driver’s license?


                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown placeholder="Choose Your Answer"
                                                                        [options]="yesNoArray" optionlabel="label"
                                                                        filter="true" formControlName="license">
                                                                    </p-dropdown>

                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputB">
                                                                    <label for="exampleInputEmail1">Driver’s
                                                                        License Number

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="LicenseNumber"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Driver’s License Number ">

                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">State of
                                                                        Issue


                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="StateofIssue"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter State of Issue">


                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputB">
                                                                    <label for="exampleInputEmail1">Expiration
                                                                        Date
                                                                        <span style="color:red">*</span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="ExpirationDate"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Expiration Date ">

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="main-submit-button" style="margin-right: 11%;">
                                                        <button type="submit" (click)="changeactiveCategory(5)"
                                                            class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="changeactiveCategory(7)">Continue</button>


                                                    </div>


                                                </div>

                                                <div *ngIf="activeCategory==7">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            REFERENCES
                                                        </h4>


                                                    </div>

                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">

                                                        <div class="col-lg-12" style="margin-right: 2%;">

                                                            <div style="color: black;">Please list two references other
                                                                than relatives or previous employers
                                                            </div>

                                                            <div class="row mt-4">
                                                                <div class="col-lg-3 form-group classInputE">
                                                                    <label for="exampleInputEmail1">Name-1

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" formControlName="nameOne"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Name ">

                                                                </div>


                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Name-2

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">

                                                                    <input type="text" formControlName="nameTwo"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Name ">

                                                                </div>




                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInputE">
                                                                    <label for="exampleInputEmail1">Address-1

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" formControlName="addressOne"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Address ">

                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Address-2

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" formControlName="secondAddress"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Address ">

                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInputE">

                                                                    <label for="exampleInputEmail1">Telephone
                                                                        Number-1

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"
                                                                        formControlName="TelephoneNumber"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Telephone Number">


                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">

                                                                    <label for="exampleInputEmail1">Telephone
                                                                        Number-2

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email"
                                                                        formControlName="TelephoneNumberTwo"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Telephone Number">


                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="main-submit-button" style="margin-right:22%;">
                                                        <button type="submit" (click)="changeactiveCategory(6)"
                                                            class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="changeactiveCategory(8)">Continue</button>


                                                    </div>


                                                </div>

                                                <div *ngIf="activeCategory==8">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            EMPLOYMENT HISTORY
                                                        </h4>
                                                    </div>

                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputD">
                                                                    <label for="exampleInputEmail1">Employer

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">

                                                                    <input type="text" formControlName="Employer"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Employer ">


                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Type of
                                                                        Business


                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" formControlName="TypeofBusiness"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Type of Business ">

                                                                </div>
                                                            </div>


                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputD">
                                                                    <label for="exampleInputEmail1">Address

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="EmpAddress"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Address ">


                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Phone

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="Phone"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Phone ">


                                                                </div>


                                                            </div>

                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputD">
                                                                    <label for="exampleInputEmail1">Titles
                                                                        and
                                                                        Duties

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="TitlesDuties"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Titles and Duties ">


                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Rate Pay

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="RatePay"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Rate Pay ">


                                                                </div>


                                                            </div>

                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputD">
                                                                    <label for="exampleInputEmail1">Period
                                                                        Employed

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="PeriodEmployed"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Period Employed  ">


                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Reason
                                                                        for
                                                                        Leaving

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <input type="email"
                                                                        formControlName="ReasonforLeaving"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Reason for Leaving  ">


                                                                </div>


                                                            </div>

                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputD">
                                                                    <label for="exampleInputEmail1">Supervisor’s
                                                                        Name and Title

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="SupervisorName"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Supervisor’s Name and Title  ">


                                                                </div>
                                                                <div class="col-lg-3 form-group classInput"
                                                                    style="margin-left: -3%;">
                                                                    <p style="color: #000!important;">
                                                                        Please Read Carefully</p>

                                                                </div>
                                                                <div class="col-lg-3 form-group classInput__new_changes"
                                                                    style="text-align: left!important;">

                                                                    <button class="btn btn-secondary submit-btn ml-2"
                                                                        data-toggle="modal"
                                                                        data-target="#exampleModal">Click
                                                                        Here To
                                                                        Read

                                                                    </button>
                                                                </div>



                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div class="main-submit-button" style="margin-right: 17%;">
                                                        <button type="submit" (click)="changeactiveCategory(7)"
                                                            class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="changeactiveCategory(9)">Continue</button>


                                                    </div>

                                                </div>

                                                <div *ngIf="activeCategory==9">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            TO BE COMPLETED BY THE COMPANY
                                                        </h4>
                                                    </div>


                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Position

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">

                                                                    <input type="email" formControlName="position"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Employment App Source Type Id ">


                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Department


                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown placeholder="Enter Department"
                                                                        filter="true" formControlName="Department"
                                                                        [options]="DepartmentArray" optionlabel="label">
                                                                    </p-dropdown>


                                                                </div>
                                                            </div>


                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Date of
                                                                        Hire<span style="color:red">*</span>

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="dateHire"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Date of Hire ">


                                                                </div>

                                                                <div
                                                                    class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Salary

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="Salary"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Salary ">
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Comments


                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="Comments"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Comments ">

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div class="main-submit-button" style="margin-right: 26%;">
                                                        <button type="submit" (click)="changeactiveCategory(8)"
                                                            class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            [disabled]="createEmploymentForm.invalid"
                                                            (click)="onSubmitEmployment()">Submit</button>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Document</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Document </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''">
                                    <a>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h4 class="document mt-2">Please Read this Document Carefully</h4>
                                        <p>
                                            I hereby certify that the facts set forth in this employment application
                                            (and accompanying resume, if any) are true and complete to the best of my
                                            knowledge, and I agree and understand that any misrepresentation of
                                            information or failure to disclose information
                                            on this employment application may disqualify me from further consideration
                                            for employment and, if employed, will subject me to dismissal. I am aware
                                            that a more detailed investigation concerning background
                                            and credit may also be conducted, if applicable to the job for which I am
                                            applying, and I hereby authorize such an investigation. I understand that
                                            employment is contingent upon satisfactory completion of reference
                                            checks and that, upon my written request, information on the nature and
                                            scope of an inquiry, if one is made, will be provided to me. Should a job
                                            offer be made, I consent to taking a pre-placement physical examination
                                            and such future examination as may be required by Phyrst. I understand that
                                            any job offer or my continuing employment, if hired is contingent upon being
                                            physically, mentally, and medical able, with or without
                                            reasonable accommodation, to successfully perform the essential functions of
                                            my job. I agree that the results of my medical/health screen may be released
                                            to appropriate agencies in the event of a worker’s compensation
                                            injury and/or dispute on payment of a medical claim. I understand that as
                                            part of my pre-placement physical examination, upon which any offer of
                                            employment is contingent, I may be required to successfully pass
                                            a drug screening test. The test will be administered at Phyrst expense, and
                                            will require me to provide a urine specimen for analysis. The urine specimen
                                            will be analyzed for the presence of marijuana, cocaine,
                                            phencyclidine (PCP), opiates and amphetamines. Results of the drug test are
                                            confidential, and will not be disclosed to others without specific written
                                            consent. My signature below specifically signifies my consent
                                            to this pre-placement drug screening test. I agree to wear or use all
                                            protective clothing or devices required by the facility and to comply with
                                            all safety policies and procedures. I understand that nothing
                                            contained in this employment application is intended to lead to or to create
                                            an employment contract between Phyrst or any subsidiary or affiliate and
                                            myself which would in any way restrict the right of the company
                                            to terminate my employment at will. I further understand and agree that the
                                            employment relationship that may result from my application will be
                                            employment-at-will, and either I or Phyrst or any subsidiary or
                                            affiliate and I may terminate the relationship at any time. I understand
                                            that any omission, misrepresentation of falsification can be grounds for
                                            refusal of employment. I further understand that, if employed,
                                            any false statements or misrepresentations herein or in conjunction with the
                                            application may be a cause for dismissal. I understand that any and all
                                            disputes regarding my employment with Phyrst, including and
                                            dispute relating to the termination of my employment are subject to Phyrst
                                            fair treatment process by utilizing the grievance procedure, and I also
                                            understand and agree as a condition of employment and continued
                                            employment, to submit any such disputes for resolution under that process,
                                            and I further agree to abide by and accept the decision of the grievance
                                            panel as the final and binding decision and resolution of any
                                            such disputes I may have.
                                        </p>
                                    </div>
                                    <form class="w-100 mt-3" [formGroup]="EmploymentFormSignature">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Applicant’s Signature

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="file" formControlName="Signature"
                                                            class="form-control" id="exampleInputEmail1"
                                                            (change)="fileProgress($event)" aria-describedby="emailHelp"
                                                            placeholder="Enter Signature">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Date
                                                            <span style="color:red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="signDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Date">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="main-submit-button" style="margin-right: 10%;">
                                            <button type="submit" *ngIf="show"
                                                class="btn btn-secondary submit-btn">Cancel</button>
                                            <button type="submit" *ngIf="!show" class="btn btn-secondary submit-btn"
                                                [disabled]="EmploymentFormSignature.invalid"
                                                (click)="onSubmitSign()">Submit</button>

                                        </div>
                                    </form>
                                    <div class="col-lg-12 mb-5 mt-3">
                                        <p>Phyrst, offers equal employment opportunity to all applicants and to all
                                            employees regardless of sex, age, race, color, religion, creed, national
                                            origin, ancestry, marital status, sexual orientation, or disability.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="newEmployeePositionPopup" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1">Create New Employee Position</span>
                <span *ngIf="show" class="color-black pl-1">Update New Employee Position</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create New Employee Position </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update New Employee Position </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="createEmployeePositionService.addEmployee_position_form"
                                        [formGroup]="createEmployeePositionService.addEmployee_position_form"
                                        class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Employee Position ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="empPositionId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Employee Position ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Status <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Status"
                                                            formControlName="statusId" filter="true"
                                                            [options]="statusArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Internal Organization
                                                            <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Internal Organization"
                                                            formControlName="internalOrganization" filter="true"
                                                            [options]="internalArray" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.internalOrganization.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.internalOrganization.touched">
                                                            Organization is required!</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Budget ID </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="budgetId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Budget ID">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Budget Item Seq ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="budgetItemSeqId"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Budget Item Seq ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Employee Position Type
                                                            ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter EmployeePosition Type ID"
                                                            formControlName="empPositionTypeId"
                                                            [options]="positionArray" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated From Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Estimated Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Through Date">

                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Salary Flag <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation"
                                                            placeholder="Enter Salary Flag" formControlName="salaryFlag"
                                                            filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.salaryFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.salaryFlag.touched">
                                                            Salary Flag is required!</small>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Exempt Flag <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation"
                                                            placeholder="Enter Exempt Flag" formControlName="exemptFlag"
                                                            filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.exemptFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.exemptFlag.touched">
                                                            Exempt Flag is required!</small>


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Fulltime Flag <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation"
                                                            placeholder="Enter Fulltime Flag"
                                                            formControlName="fulltimeFlag" filter="true"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.fulltimeFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.fulltimeFlag.touched">
                                                            Fulltime Flag is required!</small>



                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Temporary Flag <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation"
                                                            placeholder="Enter Temporary Flag"
                                                            formControlName="temporaryFlag" filter="true"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.temporaryFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.temporaryFlag.touched">
                                                            Temporary Flag is required!</small>


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="actualFromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Actual From Date">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Actual Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="actualThruDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Actual From Date">


                                                    </div>

                                                </div>
                                            </div>



                                        </div>

                                        <div class="main-submit-button" style="margin-right: 10%;">
                                            <!-- <button type="submit" *ngIf="show" (click)="onUpdateEmployeePosition()" class="btn btn-secondary submit-btn">Update</button> -->
                                            <button type="submit" *ngIf="!show" (click)="onSubmitEmployeePosition()"
                                                class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="appSkillPopUp" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Add Party Skill</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton9>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Add Party Skill </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Party Skill </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="addSkillsService.add_skill_form"
                                        [formGroup]="addSkillsService.add_skill_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Employee ID <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="partyId"
                                                            placeholder="Select Employee ID" filter="true"
                                                            [options]="partyIdArray" optionlabel="label">
                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!addSkillsService.add_skill_form.controls.partyId.invalid && !!addSkillsService.add_skill_form.controls.partyId.touched">
                                                            Employee ID is required!</small>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Skill Type ID <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="skillTypeId" filter="true"
                                                            [options]="skillArray" optionlabel="label"
                                                            placeholder="Select Skill Type Id">

                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!addSkillsService.add_skill_form.controls.skillTypeId.invalid && !!addSkillsService.add_skill_form.controls.skillTypeId.touched">
                                                            Skill Type ID is required!</small>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Years Experience</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="yearsExperience"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter year of experience">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Rating </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="rating"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Rating">


                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Skill Level</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="skillLevel"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter skill Level">

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="main-submit-button" style="margin-right: 13%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmitSkill()"
                                                class="btn btn-secondary submit-btn">Create</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="jobRequisitionPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Job Requisition</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryTabs==2?'active':''"><a>
                                        Add New Job Requisition </a></li>


                            </ul>
                        </div>
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians">

                                <li [ngClass]="activeCategoryTabs==2?'active':''"
                                    (click)="changeactiveCategoryFunctions(2)"><a>
                                        Add Job Information </a></li>
                                <li [ngClass]="activeCategoryTabs==3?'active':''">
                                    <a>Skills Required </a>
                                </li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="jobRequisitionForm" class="w-100">
                                        <div *ngIf="activeCategoryTabs==2">
                                            <!-- <div class="header-tabs">
                                              <h4 class=" common-styling marginLeft">
                                                Add Job Information
                                              </h4>
                                            
                    
                                            </div> -->
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Job Location <span
                                                                        style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="jobLocation"
                                                                    class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Job Location">
                                                                <!-- <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.jobLocation.invalid && !!newJobRequistionService.job_requistion_form.controls.jobLocation.touched">Location is required!</small> -->



                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Age</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="age"
                                                                    class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter age">
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">No Of Resources <span
                                                                        style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="noOfResources"
                                                                    class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter no Of Resources">

                                                                <!-- <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.noOfResources.invalid && !!newJobRequistionService.job_requistion_form.controls.noOfResources.touched">No Of Resources is required!</small> -->


                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Gender <span
                                                                        style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="GenderNew"
                                                                    formControlName="gender" optionlabel="label"
                                                                    placeholder="Enter Gender">

                                                                </p-dropdown>

                                                                <!-- <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.gender.invalid && !!newJobRequistionService.job_requistion_form.controls.gender.touched">Gender is required! </small> -->

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Duration Months</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input style="width: 100%;"
                                                                    formControlName="durationMonths"
                                                                    id="exampleInputEmail1" type="text"
                                                                    placeholder="Duration (In Months)">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Qualification<span
                                                                        style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="qualArray"
                                                                    formControlName="qualification" optionlabel="label"
                                                                    placeholder="Enter Qualification">
                                                                </p-dropdown>
                                                                <!-- <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.qualification.invalid && !!newJobRequistionService.job_requistion_form.controls.qualification.touched">Qualification is required!</small> -->


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Exam Type Enum
                                                                    ID</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="enumArray"
                                                                    formControlName="examTypeEnumId" optionlabel="label"
                                                                    placeholder="Enter Exam Type Enum ID">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-2"></div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="main-submit-button" style="
                                              margin-right: 11%;">
                                                    <button type="submit"
                                                        class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                                <br>
                                                <br>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategoryTabs==3">
                                            <!-- <div class="header-tabs">
                                              <h4 class=" common-styling marginLeft">
                                                Skills Required
                                              </h4>
                                            
                    
                                            </div> -->
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Skill Type ID <span
                                                                        style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="skillTypeArray"
                                                                    formControlName="skillTypeId" optionlabel="label"
                                                                    placeholder="Enter Skill Type ID">

                                                                </p-dropdown>
                                                                <!-- <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.skillTypeId.invalid && !!newJobRequistionService.job_requistion_form.controls.skillTypeId.touched">Skill TypeId is required!</small> -->



                                                            </div>
                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Experience in months
                                                                    <span style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="experienceInMonths"
                                                                    class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Experience in months">

                                                                <!-- <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.experienceInMonths.invalid && !!newJobRequistionService.job_requistion_form.controls.experienceInMonths.touched">Experience In Months is required!</small> -->


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Experience in years
                                                                    <span style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="experienceInYears"
                                                                    class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Experience in years">
                                                                <!-- <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.experienceInYears.invalid && !!newJobRequistionService.job_requistion_form.controls.experienceInYears.touched">Experience In Year is required!</small> -->


                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="
                                                margin-right: 11%;">
                                                    <button type="submit"
                                                        class="btn btn-secondary submit-btn">Create</button>
                                                    <button type="submit"
                                                        class="btn btn-secondary submit-btn">Update</button>


                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>