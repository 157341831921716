<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Current Project: </span>

               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard w-100">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                        <p class="h-text">Product Store Group</p>
                        <span>
                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#newPopup">New Group</button>
                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#newPopup1">Add Product Store Group</button>               
                    </span>
                    </div>
                    <form>
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <p>Name :</p>
                                    </div>
                                    <div class="col-lg-4" *ngIf="this.ProductStoreGroupById">
                                        <span>{{this.ProductStoreGroupById.productStoreGroupName}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <p>Description :</p>
                                    </div>
                                    <div class="col-lg-4" *ngIf="this.ProductStoreGroupById">
                                        <span>{{this.ProductStoreGroupById.description}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 mt-3">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <p>Type :</p>
                                    </div>
                                    <div class="col-lg-4" *ngIf="this.ProductStoreGroupById">
                                        <span>{{this.ProductStoreGroupById.productStoreGroupTypeId}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 mt-3">
                                <div class="row">
                                    <div class="col-lg-3"  *ngIf="this.ProductStoreGroupById">
                                        <p>Group Name :</p>
                                    </div>
                                    <div class="col-lg-4">
                                        <span>{{this.ProductStoreGroupById.productStoreGroupName}} {{"[" +this.ProductStoreGroupById.primaryParentGroupId+"]"}}</span>                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </form>
                </div>
         
                
            </div>
            <div class="w3-card-4 classCard mt-1">
                <header class="w3-container w3-blue">
                    <div class="header-tabs">
                        <h4 class=" common-styling h4Margin">
                            Product Store Group Rollup
                        </h4>
                    </div>
                </header>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12 col-12">
                        <div class="form-group">

                            <div class="card own-account-table">
                                <p-table [value]="ProductStoreGroupRollUpById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                        <tr style="background: #0d3769;">
                                            <th [ngStyle]="{'width':'250px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                            <div class="ui-helper-hidden-accessible">
                                                                <input type="checkbox" name="undefined" value="undefined">
                                                            </div>
                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>

                                                    </p-checkbox>

                                                    Product Store Group ID
                                                    <p-sortIcon field="code">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>

                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    From Date
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    Through Date
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>
                                            <!-- <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    Action
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th> -->

                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                        <tr>
                                            <td [ngStyle]="{'width':'250px'}">
                                                <div class="checkbox-align">
                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                            <div class="ui-helper-hidden-accessible">
                                                                <input type="checkbox" name="undefined" value="undefined">
                                                            </div>
                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>

                                                    </p-checkbox>
                                                    <span   class="account-button"  >
                                                        {{product.productStoreGroupId}}
                                                    </span>
                                                </div>
                                            </td>

                                            <td [ngStyle]="{'width':'170px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                <span  class="account-button"  >
                                                {{product.fromDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                </span>
                                            </td>
                                            <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                <span>
                                                    {{product.thruDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                </span>
                                            </td>

                                            <!-- <td [ngStyle]="{'width':'170px'}">
                                                <span></span>

                                            </td> -->

                                         

                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                        <tr>
                                            <td>
                                                <span class="text-center">
                                                    <h4 class="ml-2">No Record Found
                                                    </h4>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>

                                <p class="paginate_data">
                                    View per page </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w3-card-4 classCard mt-3">
                <header class="w3-container w3-blue">
                    <div class="header-tabs">
                        <h4 class=" common-styling h4Margin">
                            Product Store Member
                        </h4>
                    </div>
                </header>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="col-lg-12 col-12">
                        <div class="form-group">

                            <div class="card own-account-table">
                                <p-table [value]="ProductStoreGroupMemberById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                        <tr style="background: #0d3769;">
                                            <th [ngStyle]="{'width':'250px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                            <div class="ui-helper-hidden-accessible">
                                                                <input type="checkbox" name="undefined" value="undefined">
                                                            </div>
                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>

                                                    </p-checkbox>

                                                    Product Store ID
                                                    <p-sortIcon field="code">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    Store Name
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>

                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    From Date
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;">
                                                    Through Date
                                                    <p-sortIcon field="name">
                                                    </p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                            </th>
                                       
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                        <tr>
                                            <td [ngStyle]="{'width':'250px'}">
                                                <div class="checkbox-align">
                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                            <div class="ui-helper-hidden-accessible">
                                                                <input type="checkbox" name="undefined" value="undefined">
                                                            </div>
                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                            </div>
                                                        </div>

                                                    </p-checkbox>
                                                    <span   class="account-button"  >
                                                        {{product.productStoreId}}
                                                    </span>
                                                </div>
                                            </td>

                                            <td [ngStyle]="{'width':'170px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                <span  class="account-button"  >
                                                   {{product.productStoreId}}
                                                </span>
                                            </td>
                                            <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                <span>
                                                    {{product.fromDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                </span>
                                            </td>

                                            <td [ngStyle]="{'width':'170px'}">
                                                <span>
                                                    {{product.thruDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                </span>

                                            </td>

                                         

                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                        <tr>
                                            <td>
                                                <span class="text-center">
                                                    <h4 class="ml-2">No Record Found
                                                    </h4>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>

                                <p class="paginate_data">
                                    View per page </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="modal fade" id="newPopup" tabindex="-1" role="dialog" aria-labelledby="newPopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add to Product Store Group</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add to Product Store Group</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addStoreGroup">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="storeType"  [options]="ProductStoreTypeArray" optionlabel="label" placeholder="Enter Product Store ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Primary Parent Group ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="storeID"  [options]="PrimaryParentGroupIDArray" optionlabel="label" placeholder="Enter Product Store ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" formControlName="name" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductStoreGroup()" class="btn btn-secondary submit-btn">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="newPopup1" tabindex="-1" role="dialog" aria-labelledby="newPopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add to Product Store Group</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add to Product Store Group</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addStoreGroup1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Store ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="storeID" [options]="ProductStoreIdArray" optionlabel="label" placeholder="Enter Product Store ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductStoreGroupMember()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>