import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-requests-order',
  templateUrl: './requests-order.component.html',
  styleUrls: ['./requests-order.component.css']
})
export class RequestsOrderComponent implements OnInit {
  @ViewChild('closebutton')closebutton;
  @ViewChild('closebutton2')closebutton2;
  @ViewChild('closebutton3')closebutton3;
  @ViewChild('closebutton4')closebutton4;
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  advanceRequest: { CustRequestID: string; CustRequestTypeID: string; StatusID: string; FromPartyID: string; CustRequestDate: string; ResponseRequiredDate: string; CustRequestName: string; SalesChannel: string; ProductStore: string; OpenDateTime: string; ClosedDateTime: string; Comment: string; Reason: string; MilliSeconds: string; CustSequenceNum: string; ParentCustRequestID: string; Billed: string; };
  MainPageRequestListDetails: any;
  yesNOArray: { label: string; value: string; }[];
  newrequestForm: any;
  CustRequestTypeIDArray: any[]=[];
  CustRequestTypeID: any;
  ParentCustRequestId: any;
  ParentCustRequestIdArray: any[]=[];
  ProjectAndPhaseNameArray: any[]=[];
  ProjectAndPhaseName: any;
  PriorityListArray: any[]=[];
  PriorityList: any;
  PartyId: any;
  PartyIdArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  ResponseRequiredDate: string;
  RequestDate: string;
  findOrderList: any;
  StatusiD: { label: string; value: string; }[];
  salesOrderListArray: any[]=[];
  productStoresListArray: any = [];
  currencyArray: any[]=[];
  ContactMechId: any[]=[];
  ContactMechIdArray: any[]=[];
  productStoreIdReport: any;
  productStoreIdReportArray: any[]=[];
  salesChannel: any;
  salesChannelArray: any[]=[];
  custRequestCategoryId: any;
  custRequestCategoryIdArray: any[]=[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) { 
    this.advanceRequest = {
      CustRequestID: "",
      CustRequestTypeID: "",
      StatusID: "",
      FromPartyID: "",
      CustRequestDate: "",
      ResponseRequiredDate: "",
      CustRequestName: "",
      SalesChannel: "",
      ProductStore: "",
      OpenDateTime: "",
      ClosedDateTime: "",
      Comment: "",
      Reason: "",
      MilliSeconds: "",
      CustSequenceNum: "",
      ParentCustRequestID: "",
      Billed: ""
    };

    this.newrequestForm = this._FormBuilder.group({
      RequestingParty: [''],
      CustRequestName: [''],
      CustRequestTypeID: [''],
      Description: [''],
      Priority: [''],
      RequestDate: [''],
      ResponseRequiredDate: [''],
      CustEstimatedMilliSeconds: [''],
      CustSequenceNum: [''],
      ParentCustRequestID: [''],
      Billed: [''],
      Story: [''],
      WebSiteID: [''],
      CustRequestCategoryID: [''],
      MaximumAmountUomID: [''],
      ProductStore: [''],
      SalesChannel: [''],
      FulfillContactMechID: [''],
      Currency: [''],
      OpenDateTime: [''],
      ClosedDateTime: [''],
      InternalComment: [''],
      Reason: [''],
     

    });
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.StatusiD = [
      {
        label: 'Draft',
        value: 'CRQ_DRAFT'
      },
      {
        label: 'Submitted',
        value: 'CRQ_SUBMITTED'
      },

      {
        label: 'Accepted',
        value: 'CRQ_ACCEPTED'
      },
      {
        label: 'Reviewed',
        value: 'CRQ_REVIEWED'
      },
      {
        label: 'Completed',
        value: 'CRQ_COMPLETED'
      },
      {
        label: 'Reopened',
        value: 'CRQ_REOPENED'
      },
      {
        label: 'Pending Cust.',
        value: 'CRQ_PENDING'
      },
      {
        label: 'Rejected',
        value: 'CRQ_REJECTED'
      },
      {
        label: 'Cancelled',
        value: 'CRQ_CANCELLED'
      }

    ];
    
  }

  ngOnInit(): void {
    this.getMainPageRequestListDetails();
    this.getMainPageRequestListDetails();
    this.getCustRequestTypeID();
    this.getParentCustRequestId();
    this.getProjectAndPhaseName();
    this.getPriorityList();
    this.getPartyId();
    this.getFindOrderRequest();
   this.getSalesChannelList();
    this.getOrderListItems();
    this.getCurrency();
    this.getContactMechId();
  this.getSalesChannel();
  this.getProductStoreIdReport();
  this.getCustRequestCategoryId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/order/main-order']);
  }

  getCustRequestCategoryId() {
    this.spinner.show();
    this.myContactsService.getCustRequestCategoryId().subscribe(res => {
      this.custRequestCategoryId = res.data;
      this.spinner.hide();
      for (const value of this.custRequestCategoryId) {
        this.custRequestCategoryIdArray.push({
          label: value.Data.description,
          value: value.Data.custRequestCategoryId
        })
      }
    })
  }

  getProductStoreIdReport() {
    this.spinner.show();
    this.myContactsService.getProductStoreIdReport().subscribe(res => {
      this.productStoreIdReport = res.data.productStoreList ;
      this.spinner.hide();
      for (const value of this.productStoreIdReport) {
        this.productStoreIdReportArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }
    })
  }

  getSalesChannel() {
    this.spinner.show();
    this.myContactsService.getSalesChannel().subscribe(res => {
      this.salesChannel = res.data[0].data ;
      this.spinner.hide();
      for (const value of this.salesChannel) {
        this.salesChannelArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }

  getContactMechId() {
    this.spinner.show();
    this.accountsService.getContactMechId().subscribe(res => {
      this.ContactMechId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactMechId) {
        this.ContactMechIdArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
  }

  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }

  getOrderListItems(): void {
    this.spinner.show();
    this.accountsService.getOrderList().subscribe((res) => {
      let productStores = res.data.productStores;
      this.spinner.hide();
      for (const value of productStores) {
        this.productStoresListArray.push({
          label: value.storeName,
          value: value.productStoreId,
        });
      }
    });
  }

  getSalesChannelList(): void {
    this.spinner.show();
    this.accountsService.getSalesOrderList().subscribe((res) => {
      let salesOrderList = res.data;
      this.spinner.hide();
      for (const value of salesOrderList) {
        this.salesOrderListArray.push({
          label: value.description,
          value: value.enumId,
        });
      }
    });
    
  }
  setCustRequestStatus(product) {
    this.spinner.show();
    const fromData = {

      "custRequestId": product.custRequest.custRequestId,
      "statusId": "CRQ_REJECTED",
      "view_INDEX_1": "0",
      "view_SIZE_1": "20"
    }
    this._Router.navigate(["/order/detail-requests-order"], 
    { queryParams: { custRequestId:product.custRequest.custRequestId, custRequestName:product.custRequest.custRequestName} })
    this.myContactsService.setCustRequestOrderStatus(fromData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  reset() {
    this.spinner.show();
    this.advanceRequest.CustRequestID = "";
    this.advanceRequest.CustRequestTypeID = "";
    this.advanceRequest.StatusID = "";
    this.advanceRequest.FromPartyID = "";
    this.advanceRequest.CustRequestDate = "";
    this.advanceRequest.ResponseRequiredDate = "";
    this.advanceRequest.CustRequestName = "";
    this.advanceRequest.SalesChannel = "";
    this.advanceRequest.ProductStore = "";
    this.advanceRequest.OpenDateTime = "";
    this.advanceRequest.ClosedDateTime = "";
    this.advanceRequest.Comment = "";
    this.advanceRequest.Reason = "";
    this.advanceRequest.MilliSeconds = "";
    this.advanceRequest.CustSequenceNum = "";
    this.advanceRequest.ParentCustRequestID = "";
    this.advanceRequest.Billed = "";
    this.findOrderList = []

    const req = {
      "billed": "",
      "custEstimatedMilliSeconds": "",
      "custRequestDate": "",
      "custRequestId": "",
      "custRequestName": "",
      "custRequestTypeId": "",
      "custSequenceNum": "",
      "fromPartyId": "",
      "internalComment": "",
      "parentCustRequestId": "",
      "productStore": "",
      "reason": "",
      "responseRequiredDate": "",
      "salesChannelEnumId": "",
      "statusId": ""
    }
    this.myContactsService.getFindOrderRequest(req).subscribe((res: any) => {
      this.findOrderList = res.data;
    })
    this.spinner.hide();
  }

  FindOrderRequest() {
    this.spinner.show();
    this.findOrderList = [];
    const req = {
      
      "billed": this.advanceRequest.Billed ? this.advanceRequest.Billed : '',
      "custEstimatedMilliSeconds": this.advanceRequest.MilliSeconds ? this.advanceRequest.MilliSeconds : '',
      "custRequestDate": this.advanceRequest.CustRequestDate ? this.advanceRequest.CustRequestDate : '',
      "custRequestId": this.advanceRequest.CustRequestID ? this.advanceRequest.CustRequestID : '',
      "custRequestName": this.advanceRequest.CustRequestName ? this.advanceRequest.CustRequestName : '',
      "custRequestTypeId": this.advanceRequest.CustRequestTypeID ? this.advanceRequest.CustRequestTypeID : '',
      "custSequenceNum": this.advanceRequest.CustSequenceNum ? this.advanceRequest.CustSequenceNum : '',
      "fromPartyId": this.advanceRequest.FromPartyID ? this.advanceRequest.FromPartyID : '',
      "internalComment": this.advanceRequest.Comment ? this.advanceRequest.Comment : '',
      "parentCustRequestId": this.advanceRequest.ParentCustRequestID ? this.advanceRequest.ParentCustRequestID : '',
      "productStore": this.advanceRequest.ProductStore ? this.advanceRequest.ProductStore : '',
      "reason": this.advanceRequest.Reason ? this.advanceRequest.Reason : '',
      "responseRequiredDate": this.advanceRequest.ResponseRequiredDate ? this.advanceRequest.ResponseRequiredDate : '',
      "salesChannelEnumId": this.advanceRequest.SalesChannel ? this.advanceRequest.SalesChannel : '',
      "statusId": this.advanceRequest.StatusID ? this.advanceRequest.StatusID : '',

      
    }
    this.myContactsService.findOrderRequest(req).subscribe((res: any) => {
      this.findOrderList = res.data;
    })
    this.spinner.hide();
  }

  getFindOrderRequest() {
    this.spinner.show();
    const req = {
      "billed": "",
      "custEstimatedMilliSeconds": "",
      "custRequestDate": "",
      "custRequestId": "",
      "custRequestName": "",
      "custRequestTypeId": "",
      "custSequenceNum": "",
      "fromPartyId": "",
      "internalComment": "",
      "parentCustRequestId": "",
      "productStore": "",
      "reason": "",
      "responseRequiredDate": "",
      "salesChannelEnumId": "",
      "statusId": ""
    }
    this.myContactsService.getFindOrderRequest(req).subscribe((res: any) => {
      this.findOrderList = res.data;
      this.spinner.hide();

    })
  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getProjectAndPhaseName() {
    this.spinner.show();
    this.myContactsService.getProjectAndPhaseName().subscribe(res => {
      this.ProjectAndPhaseName = res.data;
      this.spinner.hide();
      for (const value of this.ProjectAndPhaseName) {
        this.ProjectAndPhaseNameArray.push({
          label: value.data.phaseName + "[" + value.data.projectName + "]",
          value: value.data.projectId
        })
      }
    })
  }
 
  getPriorityList() {
    this.spinner.show();
    this.myContactsService.getPriorityList().subscribe(res => {
      this.PriorityList = res.data;
      this.spinner.hide();
      for (const value of this.PriorityList) {
        this.PriorityListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  getParentCustRequestId() {
    this.spinner.show();
    this.myContactsService.getParentCustRequestId().subscribe(res => {
      this.ParentCustRequestId = res.data;
      this.spinner.hide();
      for (const value of this.ParentCustRequestId) {
        this.ParentCustRequestIdArray.push({
          label: value.data.custRequestName,
          value: value.data.custRequestId
        })
      }
    })
  }
  getCustRequestTypeID() {
    this.spinner.show();
    this.myContactsService.getCustRequestTypeID().subscribe(res => {
      this.CustRequestTypeID = res.data;
      this.spinner.hide();
      for (const value of this.CustRequestTypeID) {
        this.CustRequestTypeIDArray.push({
          label: value.data.description,
          value: value.data.custRequestTypeId
        })
      }
    })
  }
  myProfilePage(fromPartyId) {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { partyId:fromPartyId} })
  }
  createRequest() {
    this.spinner.show();
    const requestDate = this.newrequestForm.get('RequestDate').value;
    this.RequestDate = this.datePipe.transform(requestDate, "yyyy-MM-dd hh:mm:ss");
    
    const responseRequiredDate = this.newrequestForm.get('ResponseRequiredDate').value;
    this.ResponseRequiredDate = this.datePipe.transform(responseRequiredDate, "yyyy-MM-dd");
    const requestData = {
      "billed": this.newrequestForm.value.Billed ,
      "communicationEventId": "",
      "createdByUserLogin": "",
      "createdDate": "",
      "currentStatusId": "PTS_CREATED",
      "custEstimatedMilliSeconds": this.newrequestForm.value.CustEstimatedMilliSeconds ,
      "custRequestDate":this.RequestDate ,
      "custRequestId": "",
      "custRequestName": this.newrequestForm.value.CustRequestName ,
      "custRequestTypeId":this.newrequestForm.value.CustRequestTypeID ,
      "custSequenceNum": this.newrequestForm.value.CustSequenceNum ,
      "description":this.newrequestForm.value.Description ,
      "form": "list",
      "fromPartyId": this.newrequestForm.value.RequestingParty ,
      "lastModifiedByUserLogin": "",
      "lastModifiedDate": "",
      "parentCustRequestId": this.newrequestForm.value.ParentCustRequestID ,
      "partyId": this.newrequestForm.value.Party,
      "portalPageId": "",
      "priority":this.newrequestForm.value.Priority ,
      "responseRequiredDate": this.ResponseRequiredDate ,
      "roleTypeId": "PROJECT_TEAM" ,
      "statusId": "CRQ_SUBMITTED",
      "story":this.newrequestForm.value.Story ,
      "webSiteId": this.newrequestForm.value.WebSiteID ,
      "workEffortName": "",
      "workEffortParentId": this.newrequestForm.value.ProjectandPhaseName ,
      "workEffortTypeId": "TASK"
    } 
    this.myContactsService.createrequest(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.newrequestForm.reset();
        this.getMainPageRequestListDetails();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
 
  getMainPageRequestListDetails() {
    this.spinner.show();
    this.myContactsService.getMainPageRequestListDetails().subscribe(res => {
      this.MainPageRequestListDetails = res.data;
      this.spinner.hide();
    })
  }
  summaryPage(id,custRequestName) {
    this._Router.navigate(["/order/detail-requests-order"], { queryParams: { custRequestId:id, custRequestName:custRequestName} })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
