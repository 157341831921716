import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-find-detail',
  templateUrl: './find-detail.component.html',
  styleUrls: ['./find-detail.component.css']
})
export class FindDetailComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  searchContentForm: FormGroup;
  contentSearchResults: any;
  contentAssocTypeId: any;
  roleTypeID: string;
  lastUpdatedDateFilter: string;
  partyID: string;
  contentID: any;
  throughDate: string;
  sortedBy: string;
  keywords: string;
  advanceSearchResult: any;
  advanceSearchResultData: any;
  FsmTechnicianFind: string;
  FsmTechnicianFindData: any;
  contentSearchResult: string;
  contentSearchResultData: any;
  response: string;
  responseData: any;
  resultAll: string;
  responseAll: string;
  searchResultData: any;
  contentAssocTypeID: string;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.searchContentForm = this._FormBuilder.group({
      sortedBy: "",
    })
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.contentAssocTypeID = params.contentAssocTypeID;
      this.partyID =  params.partyID;
      this.contentID = params.contentID;
      this.keywords =  params.keywords;
      this.roleTypeID =   params.roleTypeID;
      this.sortedBy =  params.sortedBy;
    });

   this.findAdvanceSearch();
  //  setTimeout(() => {
  //   this.contentAssocTypeID = localStorage.getItem('contentAssocTypeID');
  //   this.partyID = localStorage.getItem('partyID');
  //   this.contentID = localStorage.getItem('contentID');
  //   this.keywords = localStorage.getItem('keywords');
  //   this.roleTypeID = localStorage.getItem('roleTypeID');
  //   this.sortedBy = localStorage.getItem('sortedBy');
  //  }, 2000);
 

    // this.searchResultData = JSON.parse(`${this.resultAll}`);
    // this.responseData = JSON.parse(`${this.responseAll}`);
      
   

  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  abc(){
    this.contentAssocTypeID = localStorage.getItem('contentAssocTypeID');
    this.partyID = localStorage.getItem('partyID');
    this.contentID = localStorage.getItem('contentID');
    this.keywords = localStorage.getItem('keywords');
    this.roleTypeID = localStorage.getItem('roleTypeID');
    this.sortedBy = localStorage.getItem('sortedBy');

    // this.searchResultData = JSON.parse(`${this.resultAll}`);
    // this.responseData = JSON.parse(`${this.responseAll}`);
      
  
  }
  onDelete(){
    this.contentSearchResults = [];
  }
  findAdvanceSearch(): void {
    this.spinner.show();
    const requestData = {
      "contentAssocTypeId": this.contentAssocTypeId ? this.contentAssocTypeId : '',
      "partyId": this.partyID ? this.partyID : '',
      "contentId": this.contentID ? this.contentID : '',
      "keyword": this.keywords ? this.keywords : '',
      "roleTypeId":this.roleTypeID ? this.roleTypeID : '',
    }
    this.myContactsService.findAdvanceSearch(requestData).subscribe((res: any) => {
      if (res.success) {
        this.contentSearchResults = res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
