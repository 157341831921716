import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2'
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-logged-in-users',
  templateUrl: './logged-in-users.component.html',
  styleUrls: ['./logged-in-users.component.css']
})
export class LoggedInUsersComponent implements OnInit {
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  rows = 50;
  pageNo = 1;
  activeCategory = 1;
  search = "";
  public user = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search
  }

  userList: any;
  loginIdArray: any[] = [];
  partIdArray: any[] = [];
  status: string;
  adminSecurity: string;
  userEmail: string;
  personId: any;
  partyIdArray: any[] = [];
  catalogId: string;
  party: any;
  AddUserLoginToSecurityGroup: any;
  loginid: any;
  LoggedInUsers: any;
  constructor(private accountsService: AccountsService,
    private headerService: HeaderService,
    readonly _ActivatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    private addSkillService: AddSkillService,
    readonly router: Router,
    private _location: Location,
    ) {

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.listLoggedInUsers();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/security/security-dashboard']);
  }



  detailAddress(userLoginId,partyId,clientIpAddress){
    this.router.navigate(['/security/detail-logged-user'],{ queryParams: { userLoginId:userLoginId,partyId:partyId,clientIpAddress:clientIpAddress}});
  }
  referToParty(id){
    this.router.navigate(['/partyQuickLink/profile-party-quick-link'],{ queryParams:{party:id} });
  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  listLoggedInUsers() {
    this.spinner.show();

    this.accountsService.listLoggedInUsers().subscribe((res: any) => {
      this.LoggedInUsers = res.data;
      this.spinner.hide();

    })

  }

  





  ngOnDestroy(): void {
    this.spinner.hide();
  }}
