import { Component, OnInit } from '@angular/core';
import {JobInterviewService} from "../job-interview/job-interview.service";
import {JobInterviewFormService} from "../job-interview/job-interview-service";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import {ActivatedRoute} from "@angular/router"
import { CreateInternalService } from '../recuitment/create-internal-job-posting/create-internal-job.service';
import { AddSkillService } from '../add-skills/add-skill.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-add-job-interview',
  templateUrl: './add-job-interview.component.html',
  styleUrls: ['./add-job-interview.component.css']
})
export class AddJobInterviewComponent implements OnInit {
  enumId: any;
  activeCategory = 2;
  enumArray: any=[];
  jobInterviewId: any;
  jobArray: any=[];
  resultStatus:any=[];
  interviewId: any;
  show=false;
  jobRequestion: any;
  getJobRequistionArray: any=[];
  personId: any;
  partyIdArray: any = [];
  updateestifromdate: any;
  dateJob: string;

  constructor(public jobInterviewService:JobInterviewService,
    public jobInterviewFormService:JobInterviewFormService,
    readonly toastr:ToastrService,
    readonly activatedRoute:ActivatedRoute,
    readonly createInternalService:CreateInternalService,
    public addSkillService:AddSkillService,
    readonly datePipe: DatePipe,readonly spinner:NgxSpinnerService,
    readonly router:Router) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.interviewId = params["interviewId"];
    });
    
    if(this.interviewId){
      this.show=true;
      this.getInterviewId();
    }
    else {
      this.show=false;
    }
    this.resultStatus = [{
      "label":"Pass",
      "value":"Pass"
    },
  {
    "label":"Fail",
    "value":"Fail"
  }]
    this.jobInterviewParametre();
    this.jobInterviewFormService.job_interview_form=null;
    this.jobInterviewFormService.jobInterviewForm(this.jobInterviewFormService.job_interview_form);
    
    this.getPartyId();
    this.getJobRequistionList();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
  }
  getInterviewId(){
   
    this.jobInterviewService.getInterviewById(this.interviewId).subscribe(res=> {
      this.spinner.show();
      setTimeout(() => {
        let fromdate=res.data.jobInterviewDate 
        this.dateJob=this.datePipe.transform(fromdate,"yyyy-MM-dd");
      
        const formPatch = this.jobInterviewFormService.job_interview_form;
        formPatch.patchValue({
          jobInterviewId:res.data.jobInterviewId,
          jobInterviewTypeId:res.data.jobInterviewTypeId,
          jobIntervieweePartyId:res.data.jobIntervieweePartyId,
          interviewerPartyId:res.data.jobInterviewerPartyId,
          jobRequisitionId:res.data.jobRequisitionId,
          interviewDate: this.dateJob,
          intervieweePartyId:res.data.jobIntervieweePartyId,
          interviewTypeId:res.data.jobInterviewTypeId,
          gradeSecuredEnum:res.data.gradeSecuredEnumId,
          interviewResult:res.data.jobInterviewResult
        })
      
      }, 3000);
     
    })
    this.spinner.hide();
  }
  jobInterviewParametre(){
    this.spinner.show();
    this.jobInterviewService.getJobInterviewParameter().subscribe(res=> {
      this.enumId = res.data.gradeSecuredEnumID;
      this.jobInterviewId = res.data.jobInterviewTypeId;
      this.spinner.hide();
      for(const value of this.enumId){
        this.enumArray.push({
          label:value.description,
          value:value.enumId
        })
      }
      for(const value of this.jobInterviewId){
        this.jobArray.push({
          label:value.jobInterviewTypeId,
          value:value.jobInterviewTypeId
        })
      }
    })
  }
  getJobRequistionList() {
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Contains",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Contains",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Contains",
      experienceYears: "",
      experienceYearsSearchType: "Contains",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
    this.createInternalService.getJobRequistionList(jobRequistion).subscribe(res=> {
      this.jobRequestion=res.data;
      this.spinner.hide();
     for(const value of this.jobRequestion){
       this.getJobRequistionArray.push({
         label:value.jobRequisitionId,
         value:value.jobRequisitionId
       })
     }
    
    })
  }
  onSubmit(){
    this.spinner.show();
   let fromdate=moment(this.jobInterviewFormService.job_interview_form.value.interviewDate).format('YYYY-MM-DD HH:mm:ss')
  
    const formData={
      "gradeSecuredEnum":this.jobInterviewFormService.job_interview_form.value.gradeSecuredEnum,
      "interviewDate": fromdate,
      "interviewResult": this.jobInterviewFormService.job_interview_form.value.interviewResult,
      "interviewTypeId": this.jobInterviewFormService.job_interview_form.value.interviewTypeId,
      "intervieweePartyId": this.jobInterviewFormService.job_interview_form.value.intervieweePartyId,
      "interviewerPartyId":this.jobInterviewFormService.job_interview_form.value.interviewerPartyId,
      "jobRequisitionId": this.jobInterviewFormService.job_interview_form.value.jobRequisitionId
    }

   this.jobInterviewService.createJobInterview(formData).subscribe((res:any)=> {
    if(res.success) {
      this.spinner.hide();
      this.toastr.success("Job Interview Saved Successfully");
      this.router.navigate(['/hr/job-requisition/job-interview'])
    }
    else if(res.success == false) {
      this.toastr.error(res.data.message);
      this.spinner.hide();
    }
   },(err) => {
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
    this.spinner.hide();
  
 }
 
 )
 this.spinner.hide();
  }
  
  onUpdate(){
    this.spinner.show();
    let fromdate=this.jobInterviewFormService.job_interview_form.get('interviewDate').value;
    this.updateestifromdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");
    const formData={
      "gradeSecuredEnum":this.jobInterviewFormService.job_interview_form.value.gradeSecuredEnum,
      "interviewDate":  this.updateestifromdate,
      "interviewResult": this.jobInterviewFormService.job_interview_form.value.interviewResult,
      "interviewTypeId": this.jobInterviewFormService.job_interview_form.value.interviewTypeId,
      "intervieweePartyId": this.jobInterviewFormService.job_interview_form.value.intervieweePartyId,
      "interviewerPartyId":this.jobInterviewFormService.job_interview_form.value.interviewerPartyId,
      "jobRequisitionId": this.jobInterviewFormService.job_interview_form.value.jobRequisitionId
    }
 
     this.jobInterviewService.updateJobInterview(formData,this.interviewId).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success("Job Interview Updated Successfully.");
        this.router.navigate(["/hr/job-requisition/job-interview"])
      }
      else if(res.success == false) {
        this.toastr.error("Error");
      }
     },(err) => {
      this.toastr.error(err.error.message);
      this.spinner.hide();
   }
   
    
     )}
  cancel(){
    this.router.navigate(["/hr/job-requisition/job-interview"]);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
