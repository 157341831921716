import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr'; import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { CreateSimpleTextComponent } from 'src/app/catalog-mgmt/configurations/create-simple-text/create-simple-text.component'
import Swal from 'sweetalert2';
import { TemplateRef } from '@angular/core';
//import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-detail-configurations',
  templateUrl: './detail-configurations.component.html',
  styleUrls: ['./detail-configurations.component.css']
})
export class DetailConfigurationsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('testPOPUP') testPOPUP;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton13') closebutton13;
  activeCategory = 1;
  activeCategoryInv = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  editProductConfigItem: any;
  configId: any;
  updateconfigForm: any;
  productConfigTypeID: any;

  productTableData: any;

  productConfigTypeIDArray: any[]=[];
  dropdownValue: { label: string; value: string; }[];
  productConfigArticle: any;
  productSimple: any;
  commEventId: any;
  configForm: FormGroup;
  contentForm: FormGroup;
  addContentForm: FormGroup;
  uploadImageForm: FormGroup;
  overrideForm: FormGroup;
  configItemId: any;
  productConfigOption: any;
  configOptionForm: FormGroup;
  ProductComponentForm: FormGroup;
  configOptionId: any;
  productComponent: any;
  productConfigProductById: any;
  showProductOptions: boolean;
  ProductId: any;
  ProductIdArray: any[] = [];
  showComponent: boolean;
  showComponentTable: boolean;
  TextContentForm: FormGroup;
  bsModalRef: any;
  ContentIdID: any;
  ContentIdIDArray: any[] = [];
  simpleTextContent: any;
  ProductConfigItem: any;
  configRedirect = "configTab";
  @ViewChild('updateConfigItemPopup') updateConfigItemPopup: TemplateRef<any>;
  demo: any;
  pro: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService, 
    private _location: Location,

    readonly createEventServices: CreateEventServices,
    public modalService: BsModalService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
   ) {
    this.updateconfigForm = this._FormBuilder.group({
      ConfigItemType: [''],
      ConfigItemName: [''],
      Description: [''],
    })
    this.configForm = this._FormBuilder.group({
      ConfigItemType: [''],
      ConfigItemName: [''],
      Description: [''],
    })
    this.contentForm = this._FormBuilder.group({
      ProductConfigItemContentTypeID: [''],
    })
    this.uploadImageForm = this._FormBuilder.group({
      image: [''],
    })
    this.addContentForm = this._FormBuilder.group({
      ContentID: [''],
      ProductConfigItemContentTypeID: [''],
      fromdate: [''],
      throughdate: [''],
    })
    this.overrideForm = this._FormBuilder.group({
      Description: [''],
      ProductConfigItemContentTypeID: [''],
      smallImage: [''],
      longDescription: [''],
    })
    this.configOptionForm = this._FormBuilder.group({
      FromDate: [''],
      ThroughDate: [''],
      ConfigOptionName: [''],
      Description: [''],
      SequenceNum: [''],
    })
    this.ProductComponentForm = this._FormBuilder.group({
      productID: [''],
      Quantity: [''],
      SequenceNum: [''],
    })

    this.dropdownValue = [{
      label: 'Single Choice',
      value: 'SINGLE'
    },
    {
      label: 'Multi Choice',
      value: 'MULTIPLE'
    },
    ]
    this.TextContentForm = this._FormBuilder.group({
      FromDate: [''],
      ThroughDate: [''],
      ProductDescription: [''],
      LocaleString: [''],
      Text: [''],
      ContentID: [''],
    })
  }

  ngOnInit(): void {  

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }                                                                       
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.configItemId = params.configItemId;
      this.configOptionId = params.configOptionId;
    })
    this.showComponentTable = false;
    this.getProductConfigItem();
    this.getProductConfigOption();
    this.getProductConfigItemArticle();
    this.getProductIds();
    this.getProductTableData();
    this.getProductConfigItemContentTypeID();
    this.ContentId();
    this.getSimpleTextContentForProductConfigItemById();
    this.getEditProductConfigItemContent();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  

  detailPage(id){
    this._Router.navigate(['/facilities/catalog/products/detail-product-summary'],
    {queryParams:{configItemId:id}});
  }
  navToProdictConfiguration(product){
  
    this.pro = product.productData.productId;
    console.log("pro id", this.pro);
    this._Router.navigate(['/facilities/catalog/products/detail-product-summary/{{product.productData.productId}}'],);
    
  }
 
  addContentToProductConfigItem() {
    this.spinner.show();
    const formData = {
      "confItemContentTypeId": this.addContentForm.value.ProductConfigItemContentTypeID,
      "configItemId": this.configItemId,
      "contentId": this.addContentForm.value.ContentID,
      "fromDate": this.datePipe.transform(this.addContentForm.value.fromdate, "yyyy-dd-mm hh:mm:ss"),
      "thruDate": this.datePipe.transform(this.addContentForm.value.throughdate, "yyyy-dd-mm hh:mm:ss"),
    }

    this.myContactsService.addContentToProductConfigItem(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.addContentForm.reset();
        this.closebutton3.nativeElement.click();
        this.getSimpleTextContentForProductConfigItemById();
        this.testPOPUP.nativeElement.click();

        // setTimeout(() => {
        //   document.querySelector<HTMLElement>('#testPOPUP')?.click(); 
        // }, 2000);

        console.log("test pop up")
       

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
editProductContent(product) {
  this.spinner.show();
  this.productSimple = product;
  this.myContactsService.EditProductConfigItemContentContent(this.productSimple.confItemContentTypeId, this.productSimple.configItemId, this.productSimple.contentId, "").subscribe(res => {
    this.editProductConfigItem = res.data;
    setTimeout(() => {
      const formValue = this.TextContentForm;
      formValue.patchValue({
        ContentID:  product.contentId ,
        ProductDescription: this.editProductConfigItem.content.description,
        LocaleString: this.editProductConfigItem.content.localeString,
        Text: this.editProductConfigItem.textData.text
      })
    }, 2000);
    this.spinner.hide();
  })
}
updateSimpleTextContentForProductConfigItem() {
  this.spinner.show();
  const formData = {
    "confItemContentTypeId":  this.productSimple.confItemContentTypeId,
    "configItemId": this.configItemId,
    "contentId": this.productSimple.contentId,
    "description":  this.TextContentForm.value.ProductDescription,
    "fromDate":   this.TextContentForm.value.FromDate,
    "localeString":  this.TextContentForm.value.LocaleString,
    "text":  this.TextContentForm.value.Text,
    "textDataResourceId": "",
    "thruDate": this.datePipe.transform(this.TextContentForm.value.ThroughDate,"yyyy-dd-mm hh:mm:ss") 
    }
  this.myContactsService.updateSimpleTextContentForProductConfigItem(formData).subscribe((res: any) => {
    if (res.success) {
      this.toastr.success("Created Successfully");
      this.spinner.hide();
      this.TextContentForm.reset();
      this.closebutton13.nativeElement.click();
    }
  }, (err) => {
    this.spinner.hide();
    if (err.error.errors) {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    }
    else {
      this.toastr.error(err.error.message);
    }
  });

}
  contentPage(product) {
   // let dialogRef = this.dialog.open(this.updateConfigItemPopup);
    this._Router.navigate([], { queryParams: { configItemId: this.configItemId, contentId: product.contentId, confItemContentTypeId: product.confItemContentTypeId } });
    this.bsModalRef = this.modalService.show(CreateSimpleTextComponent, { class: 'modal-lg' });
  }
  getEditProductConfigItemContent() {
    this.spinner.show();
    // this.showSubscription = true;
    this.myContactsService.getEditProductConfigItemContent(this.configItemId).subscribe(res => {
      this.ProductConfigItem = res.data[0].getEditProductConfigItemContent[0];
      setTimeout(() => {
        const formValue = this.overrideForm;
        formValue.patchValue({
          ProductConfigItemContentTypeID: this.ProductConfigItem.configItemTypeId,
          smallImage: this.ProductConfigItem.imageUrl,
          Description: this.ProductConfigItem.description
        })
      }, 2000);
      this.spinner.hide();
    })
  }

  getProductTableData() {
    this.spinner.show();
    this.myContactsService.getProductCatalogData(this.configItemId).subscribe((res: any) => {
      this.productTableData = res.data;
      this.spinner.hide();
    })
  }

  updateProductConfigItemContent() {
    this.spinner.show();
    const formData = {
      "configItemId": this.configItemId,
      "description": this.overrideForm.value.Description,
      "imageUrl": this.overrideForm.value.smallImage,
      "longDescription": this.overrideForm.value.longDescription,
      "update": this.overrideForm.value.Description,
    }
    this.myContactsService.updateProductConfigItemContent(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Success");
        this.spinner.hide();
        this.overrideForm.reset();
        this.closebutton5.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  

  UploadProductConfigItemImage() {
    this.spinner.show();
    const formData = {
      "configItemId": this.configItemId,
      "upload_file_type": this.uploadImageForm.value.image
    }
    this.myContactsService.UploadProductConfigItemImage(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.uploadImageForm.reset();
        this.closebutton6.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  removeContentFromProductConfigItem(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "confItemContentTypeId": product.confItemContentTypeId,
          "configItemId": product.configItemId,
          "contentId": product.contentId,
          "fromDate": this.datePipe.transform(product.fromDate, "yyyy-dd-mm hh:mm:ss")
        }
        this.myContactsService.removeContentFromProductConfigItem(req).subscribe((res: any) => {
          if (res.success) {
            this.getSimpleTextContentForProductConfigItemById()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getSimpleTextContentForProductConfigItemById() {
    this.spinner.show();
    this.myContactsService.getSimpleTextContentForProductConfigItemById(this.configItemId).subscribe(res => {
      this.simpleTextContent = res.data[0].getSimpleTextContentForProductConfigItemById;
      this.spinner.hide();
    })
  }
  prepareAddContentToProductConfigItem() {
    
    this.closebutton4.nativeElement.click();
    this._Router.navigate([], { queryParams: { configItemId: this.configItemId, 
      confItemContentTypeId: this.contentForm.value.ProductConfigItemContentTypeID } });
    this.bsModalRef = this.modalService.show(CreateSimpleTextComponent, { class: 'modal-lg' });
  

  }
  ContentId() {
    this.spinner.show();
    this.myContactsService.ContentId().subscribe(res => {
      this.ContentIdID = res.data;
      this.spinner.hide();
      for (const value of this.ContentIdID) {
        this.ContentIdIDArray.push({
          label: value.contentName,
          value: value.contentId
        })
      }
    })
  }
  deleteProductConfigProduct(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "configItemId": product.configItemId,
          "configOptionId": product.configOptionId,
          "productId": product.productId,
          "view_INDEX_2": "0",
          "view_SIZE_2": "20"
        }
        this.myContactsService.deleteProductConfigProduct(req).subscribe((res: any) => {
          if (res.success) {
            this.getProductConfigOption()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId
        })
      }
    })
  }
  getProductConfigProductById() {
    this.spinner.show();
    this.myContactsService.getProductConfigProductById(this.configItemId, this.configOptionId).subscribe(res => {
      this.productConfigProductById = res.data[0].getProductConfigProductById;
      this.spinner.hide();
    })
  }
  updateProductConfigProduct() {
    this.spinner.show();
    const formData = {
      "configItemId": this.configItemId,
      "configOptionId": this.configOptionId,
      "productId": this.ProductComponentForm.value.productID,
      "quantity": this.ProductComponentForm.value.Quantity,
      "sequenceNum": this.ProductComponentForm.value.SequenceNum,

    }
    this.myContactsService.updateProductConfigProduct(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.ProductComponentForm.reset();
        this.closebutton8.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  createProductConfigProduct() {
    this.spinner.show();
    const formData = {
      "configItemId": this.configItemId,
      "configOptionId": this.configOptionId,
      "productId": this.ProductComponentForm.value.productID,
      "quantity": this.ProductComponentForm.value.Quantity,
      "sequenceNum": this.ProductComponentForm.value.SequenceNum,
    }
    this.myContactsService.createProductConfigProduct(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.ProductComponentForm.reset();
        this.closebutton8.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  getProductConfigItemArticle() {
    this.spinner.show();
    this.myContactsService.getProductConfigItemArticle(this.configItemId).subscribe(res => {
      this.productConfigArticle = res.data;
      this.spinner.hide();
    })
  }


  deleteProductConfigOption(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "configItemId": product.configItemId,
          "configOptionId": product.configOptionId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.deleteProductConfigOption(req).subscribe((res: any) => {
          if (res.success) {
            this.getProductConfigOption()
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  resetConfigComponent() {
    this.showComponent = false;
    this.ProductComponentForm.reset();
  }
  updateComponent(product) {
    this.showComponent = true;
    this.productComponent = product;
    setTimeout(() => {
      const formValue = this.ProductComponentForm;
      formValue.patchValue({

        productID: product.productId,
        Quantity: product.quantity,
        SequenceNum: product.sequenceNum
      })
    }, 2000);
  }
  resetConfigOption() {
    this.showProductOptions = false;
    this.configOptionForm.reset();
  }
  updateConfigOption(product) {
    this.showProductOptions = true;
    this.productComponent = product;
    this.showComponentTable = true;
    setTimeout(() => {
      const formValue = this.configOptionForm;
      formValue.patchValue({
        FromDate: this.datePipe.transform(product.fromDate, "yyyy-MM-dd hh:mm:ss"),
        ThroughDate: this.datePipe.transform(product.fromDate, "yyyy-MM-dd hh:mm:ss"),
        ConfigOptionName: product.configOptionName,
        Description: product.description,
        SequenceNum: product.sequenceNum
      })
    }, 2000);

    this._Router.navigate(['/facilities/catalog/configurations/detail-configurations'],
      { queryParams: { configItemId: product.configItemId, configOptionId: product.configOptionId } });
    this.getProductConfigProductById();

  }
  updateProductConfigOption() {
    this.spinner.show();
    const formData = {
      "configOptionId": this.productComponent.configOptionId,
      "configItemId": this.configItemId,
      "configOptionName": this.configOptionForm.value.ConfigOptionName,
      "description": this.configOptionForm.value.Description,
      "fromDate": this.datePipe.transform(this.configOptionForm.value.FromDate, "yyyy-MM-dd HH:mm:ss"),
      "sequenceNum": this.configOptionForm.value.SequenceNum,
      "thruDate": this.datePipe.transform(this.configOptionForm.value.ThroughDate, "yyyy-MM-dd hh:mm:ss")
    }
    this.myContactsService.updateProductConfigOption(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.configOptionForm.reset();
        this.closebutton7.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  createProductConfigOption() {
    this.spinner.show();
    const formData = {
      "configItemId": this.configItemId,
      "configOptionName": this.configOptionForm.value.ConfigOptionName,
      "description": this.configOptionForm.value.Description,
      "fromDate": this.datePipe.transform(this.configOptionForm.value.FromDate, "yyyy-MM-dd HH:mm:ss"),
      "sequenceNum": this.configOptionForm.value.SequenceNum,
      "thruDate": this.datePipe.transform(this.configOptionForm.value.ThroughDate, "yyyy-MM-dd hh:mm:ss")
    }
    this.myContactsService.createProductConfigOption(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.configOptionForm.reset();
        this.closebutton7.nativeElement.click();
        this.getProductConfigOption();
        this.showComponentTable = true;
        this._Router.navigate(['/facilities/catalog/configurations/detail-configurations'],
          { queryParams: { configItemId: this.configItemId, configOptionId: res.data.configOptionId } });
        this.getProductConfigProductById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getProductConfigOption() {
    this.spinner.show();
    this.myContactsService.getProductConfigOption(this.configItemId).subscribe(res => {
      this.productConfigOption = res.data[0].getProductConfigOption;
      this.spinner.hide();
    })
  }
  createProductConfigItem() {
    this.spinner.show();
    const formData = {
      "configItemName": this.configForm.value.ConfigItemName,
      "configItemTypeId": this.configForm.value.ConfigItemType,
      "description": this.configForm.value.Description,
    }
    this.myContactsService.createProductConfigItem(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.configForm.reset();
        this.closebutton1.nativeElement.click();
        this._Router.navigate(['/facilities/catalog/configurations/detail-configurations'],
          { queryParams: { configItemId: res.data.configItemId } });
        this.getProductConfigItem();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  getProductConfigItemContentTypeID() {
    this.spinner.show();
    this.myContactsService.getProductConfigItemContentTypeID().subscribe(res => {
      this.productConfigTypeID = res.data[0].getProductConfigItemContentTypeID;
      this.spinner.hide();
      for (const value of this.productConfigTypeID) {
        this.productConfigTypeIDArray.push({
          label: value.description,
          value: value.confItemContentTypeId
        })
      }
    })
  }
  updateProductConfigItem() {
    this.spinner.show();
    const formData = {
      "configItemName": this.updateconfigForm.value.ConfigItemName,
      "configItemTypeId": this.updateconfigForm.value.ConfigItemType,
      "description": this.updateconfigForm.value.Description,
      "configItemId": this.configItemId,
    }
    this.myContactsService.updateProductConfigItem(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.updateconfigForm.reset();
        this.closebutton.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getProductConfigItem() {
    this.spinner.show();
    // this.showSubscription = true;
    this.myContactsService.getProductConfigItem(this.configItemId).subscribe(res => {
      this.editProductConfigItem = res.data[0].getProductConfigItem[0];
      setTimeout(() => {
        const formValue = this.updateconfigForm;
        formValue.patchValue({
          ConfigItemType: this.editProductConfigItem.configItemTypeId,
          ConfigItemName: this.editProductConfigItem.configItemName,
          Description: this.editProductConfigItem.description
        })
      }, 2000);
      this.spinner.hide();
    })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
