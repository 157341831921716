<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">


            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <span class="d-flex">

                                        <button type="submit" (click)="homeButton();"
                                            class=" btn btn-outline-secondary mr-2">Home</button>
                                
                                        <button type="submit" (click)="cancelSubmit();"
                                            class="btn btn-danger buttonclass ">Back</button>
                                    </span>
                                
                                    <div class="w-100">
                                        <div class="w3-card-4 classCardA ">


                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div class="create-new-leade ">
                                                    <div class="container-fluid">


                                                        <div class="panel-group">

                                                            <div class="w3-card-4 classCardView mt-2">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Visit Detail
                                                                    </h4>

                                                                </div>


                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="color-black container">
                                                                            <div class="row">
                                                                                <div class="article-container">
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            VisitID/SessionID</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold"></span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            Visitor ID</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">
                                                                                                </span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            PartyID/UserLoginID</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>

                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            User Created </p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            WebApp</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            Server</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            Client</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            Client User </p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            Initial Locale</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            Initial Request</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            Initial Referer</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            Initial User Agent </p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            Cookie</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-8 col-12">
                                                                                            From Date / Through Date</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="w3-card-4 classCardView mt-2">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Hit Tracker
                                                                    </h4>
                                                                </div>
                                                                <div class="panel-body allStyleUser">
                                                                    <div class="card own-account-table borderTable">
                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>


                                                                                            Content ID
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'125px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Type
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Size</div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Start Time</div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Time
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            URI</div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>


                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                    </td>


                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>

                                                                </div>

                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ngx-spinner></ngx-spinner>