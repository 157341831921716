import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';

import * as moment from 'moment'; 
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DatePipe } from '@angular/common';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-content-detail-page',
  templateUrl: './content-detail-page.component.html',
  styleUrls: ['./content-detail-page.component.css']
})
export class ContentDetailPageComponent implements OnInit {
  activeCategory=1;

  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton9') closebutton9;

  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  gateway:any;
  paymentSearch: FormGroup;
  createContentForm: FormGroup;
  rolesForm: FormGroup;
  associationForm: FormGroup;
  addPurposeForm: FormGroup;
  addAttributeForm: FormGroup;
  addMetaDataForm: FormGroup;
  addContentKeywordForm: FormGroup;
  addWorkEffortForm: FormGroup;
  contentId: any;
  contentIdparams: any;
  contentIdcheck: any;
  EditContentById: any;
  ContentAssocById: any;
  ContentAssocTypeId: any;
  ContentAssocTypeIdArray: any[]=[];
  MetaDataPredicateID: any;
  MetaDataPredicateIDArray: any[]=[];
  fromDateCatePatch: string;
  throughDateCatePatch: string;
  personId: any;
  partyIdArray: any[]=[];
  CalatalogContentTypeId: any;
  CalatalogContentTypeIdArray: any[]=[];
  CalatalogMimeTypeId: any;
  CalatalogMimeTypeIdArray: any[]=[];
  DataResourceId: any;
  DataResourceIdArray: any[]=[];
  CatalogContentId: any;
  CatalogContentIdArray: any[]=[];
  CharacterSetId: any;
  CharacterSetIdArray: any[]=[];
  show: boolean;
  updateProductPricePat: any;
  readOnlyCodeNew=true;
  readOnlyCode=false;
  updateContentAssocPat: any;
  ContentRoleTypeId: any;
  ContentRoleTypeIdArray: any[]=[];
  ContentRoleById: any;
  updateContentRolePat: any;
  ContentPurposeType: any;
  ContentPurposeTypeArray: any[]=[];
  ContentPurposeById: any;
  updateContentPurposePat: any;
  ContentAttributeById: any;
  updateContentAttributePat: any;
  ContentMetaDataById: any;
  MetaDataPredicateIDNew: any;
  MetaDataPredicateIDNewArray: any[]=[];
  updateContentMetaDataPat: any;
  WorkEffortContentById: any;
  updateWorkEffortContentNewPat: any;
  contentTypeId: any;
  contentTypeIdArray: any[]=[];
  WorkEffortId: any;
  WorkEffortIdArray: any[]=[];
  keyword: any;
  ContentKeywordById: any;
  ListWebSiteById: any;
  EditContentByIdAll: any;
  matchid: any;
  contentAssocSecondById: any;
  addOnFeild: boolean;
  updateContentAssocBottomPat: any;
  lastCatePatch: string;
  createCatePatch: string;
  dataResourceIdAll: any;
  dataResourceIdAllArray: any[]=[];
  localString: any;
  localStringArray: any[]=[];

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addSkillService: AddSkillService,
    private _location: Location,

  )
   {
     this.rolesForm = this._FormBuilder.group({
      contentID:"",
      partyID:"",
      roleTypeID:"",
      fromDate:"",
      throughDate:"",
     });

     this.addAttributeForm = this._FormBuilder.group({
      contentID:"",
      attrName:"",
      attrValue:"",
      attrDescription:"",
      
     });

     this.addPurposeForm = this._FormBuilder.group({
      contentID:"",
      contentPurposeTypeID:"",
      sequenceNum:"",
      
     });

     this.associationForm = this._FormBuilder.group({
      contentID:"",
      contentIDTo:"",
      contentAssocTypeID:"",
      fromDate:"",
      thruDate:"",
      contentAssocPredicateID:"",
      dataSourceID:"",
      sequenceNum:"",
      mapKey:"",
      upperCoordinate:"",
      leftCoordinate:"",
      metaDataPredicateID:"",
      createdDate:"",
      createdByUser:"",
      lastModifiedDate:"",
      lastModifiedByUser:"",

    });

    this.addMetaDataForm = this._FormBuilder.group({
      contentID:"",
      metaDataPredicateID:"",
      metaDataValue:"",
      dataSourceID:"",
    });

    this.addWorkEffortForm = this._FormBuilder.group({
      workEffortID:"",
      workEffortContentTypeID:"",
      fromDate:"",
      throughDate:"",
    });

    this.addContentKeywordForm=this._FormBuilder.group({
      keyword:"",
      weight:"",
    })

    

    this.createContentForm = this._FormBuilder.group({
      contentID:"",
      contentTypeId:"",
      ownerContentID:"",
      decoratorContentID:"",
      instanceContentID:"",
      dataResourceId:"",
      templateDataResourceID:"",
      dataSourceID:"",
      privilegeEnumID:"",
      serviceName:"",
      customMethodID:"",
      contentName:"",
      description:"",
      localeString:"",
      mimeTypeID:"",
      characterSetID:"",
      childLeafCount:"",
      childBranchCount:"",
      createdDate:"",
      createdByUser:"",
      lastModifiedDate:"",
      lastModifiedByUser:"",
    });
     

   }

  ngOnInit(): void {
    this.show=true;
    this.addOnFeild=true;
      this._ActivatedRoute.queryParams.subscribe(params => {
        this.contentId = params["contentId"];
        this.matchid = params["matchid"];
        console.log("matchid",this.matchid);
        
      });
      if(this.matchid==="blog"){
        this.activeCategory=3;
      }

    this.getPartyId();
    this.getCatalogContentId();
    this.getDataResourceId();
    this.getCalatalogMimeTypeId();
    this.getCalatalogContentTypeId();
    this.getCharacterSet();
    this.getEditContentById();
    this.getContentAssocById();
    this.getMetaDataPredicateID();
    this.getContentAssocTypeId();
    this.getContentRoleTypeId();
    this.getContentRoleById();
    this.getContentPurposeType();
    this.getContentPurposeById();
    this.getContentAttributeById();
    this.getContentMetaDataById();
    this.getMetaDataPredicateIDNew();
    this.getWorkEffortContentById();
    this.getContentTypeId();
    this.getWorkEffortId();
    this.getContentKeywordById();
    this.getListWebSiteById();
    this.getContentAssocSecondById();
    this.dataResourceId();
    this.getLocalString();

  }
  generatePDFContent() {
    this.spinner.show();
    
    this.myContactsService.generatePDFContent(this.contentId).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "CONTENT.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  getLocalString() {
    this.spinner.show();
    this.myContactsService.getLocalString().subscribe(res => {
      this.localString = res.data[0].getLocalString;
      this.spinner.hide();
      for (const value of this.localString) {
        this.localStringArray.push({
          label: value.localeString,
          value: value.localeString
        })
      }
    })
  }
  dataResourceId() {
    this.spinner.show();
    this.accountsService.dataResourceId().subscribe(res => {
      this.dataResourceIdAll = res.data[0].dataSourceId;
      this.spinner.hide();
      for (const value of this.dataResourceIdAll) {
        this.dataResourceIdAllArray.push({
          label: value.description,
          value: value.dataSourceId
        })
      }
    })
  }
  deleteContentAssocNew(product) {
    this.updateContentAssocBottomPat=product.getContentAssocById
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const fromDateCatePatch=product.getContentAssocById.fromDate;
        this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
        let req = {
          "contentAssocTypeId": this.updateContentAssocBottomPat.contentAssocTypeId,
          "contentId": this.updateContentAssocBottomPat.contentId,
          "contentIdTo": this.updateContentAssocBottomPat.contentIdTo,
          "fromDate":  this.fromDateCatePatch
        }
        this.accountsService.deleteContentAssocNew(req).subscribe((res: any) => {
          if (res.success) {
            this.getContentAssocSecondById();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateContentAssocBottom(): void {
    this.spinner.show();
    const requestData = {
      "contentAssocTypeId":  this.associationForm.value.contentAssocTypeID,
      "contentId": this.associationForm.value.contentID,
      "contentIdTo": this.associationForm.value.contentIDTo,
      "createdByUserLogin": this.associationForm.value.createdByUser,
      "createdDate": this.associationForm.value.createdDate,
      "dataSourceId": this.associationForm.value.dataSourceID,
      "fromDate": this.datePipe.transform(this.associationForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "lastModifiedDate": this.associationForm.value.lastModifiedDate,
      "leftCoordinate": this.associationForm.value.leftCoordinate,
      "mapKey": this.associationForm.value.mapKey,
      "sequenceNum": this.associationForm.value.sequenceNum,
      "thruDate": this.datePipe.transform(this.associationForm.value.thruDate, "yyyy-MM-dd HH:mm:ss"),
      "upperCoordinate": this.associationForm.value.upperCoordinate,
    }
    this.accountsService.updateContentAssocBottom(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getContentAssocById();
        this.router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: this.associationForm.value.contentID } });
        setTimeout(() => {
          this.getContentMetaDataById();
          this.getContentAssocSecondById();
        }, 1000);
        this.associationForm.reset();
        this.closebutton2.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateContentAssocBottomPatch(product) {
    this.show=false;
    this.readOnlyCode=true;
    this.addOnFeild=false;
    this.updateContentAssocBottomPat = product.getContentAssocById;
    this.spinner.show();
    const fromDateCatePatch=product.getContentAssocById.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.getContentAssocById.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");

    const createCatePatch=product.getContentAssocById.createdDate;
    this.createCatePatch=this.datePipe.transform(createCatePatch,"yyyy-MM-dd");
    const lastCatePatch=product.getContentAssocById.lastModifiedDate;
    this.lastCatePatch=this.datePipe.transform(lastCatePatch,"yyyy-MM-dd");
    setTimeout(() => {
      const formValue = this.associationForm;
      formValue.patchValue({
          contentID: this.updateContentAssocBottomPat.contentId,
          contentIDTo: this.updateContentAssocBottomPat.contentIdTo,
          contentAssocTypeID: this.updateContentAssocBottomPat.contentAssocTypeId,
          fromDate: this.fromDateCatePatch,
          thruDate: this.throughDateCatePatch,
          contentAssocPredicateID: this.updateContentAssocBottomPat.contentAssocPredicateId,
          dataSourceID: this.updateContentAssocBottomPat.dataSourceId,
          sequenceNum: this.updateContentAssocBottomPat.sequenceNum,
          mapKey: this.updateContentAssocBottomPat.mapKey,
          upperCoordinate: this.updateContentAssocBottomPat.upperCoordinate,
          leftCoordinate: this.updateContentAssocBottomPat.leftCoordinate,
          metaDataPredicateID: '',
          createdDate:  this.createCatePatch,
          createdByUser: this.updateContentAssocBottomPat.createdByUserLogin,
          lastModifiedDate: this.lastCatePatch,
          lastModifiedByUser: this.updateContentAssocBottomPat.lastModifiedByUserLogin,
      })

    }, 1000);
    this.spinner.hide();
  }
  getContentAssocSecondById() {
    this.spinner.show();
    this.accountsService.getContentAssocSecondById(this.contentId).subscribe(res => {
      this.contentAssocSecondById = res.data;
      this.spinner.hide();
    });
  }
  referToNewTable(id){
    this.router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: id } });
    setTimeout(() => {
      this.getContentMetaDataById();
      this.getContentAssocSecondById();
    }, 1000);
  }
  referToWebsite(id){
    this.router.navigate(['/content/main/website-detail-page'], { queryParams: { webSiteId: id } });
  }
  referToworkEffort(id){
    this.router.navigate(['/psa/work-effort/task-list-view/editWorkEffort'], { queryParams: { workEffortId: id } });

  }
  getAddBtn(){
    this.show=true;
    this.readOnlyCode=false;
    this.addOnFeild=true;
    this.addWorkEffortForm.reset();
    this.addMetaDataForm.reset();
    this.addAttributeForm.reset();
    this.addPurposeForm.reset();
    this.rolesForm.reset();
    this.associationForm.reset();
  }
  deleteContentKeyword(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "contentId": this.contentId,
          "keyword": product.keyword,
          "relevancyWeight": product.relevancyWeight
        }
        this.accountsService.deleteContentKeyword(req).subscribe((res: any) => {
          if (res.success) {
            this.getContentKeywordById();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createContentKeyword(): void {
    this.spinner.show();
    const requestData = {
      "contentId": this.contentId,
      "keyword": this.addContentKeywordForm.value.keyword,
      "relevancyWeight": this.addContentKeywordForm.value.weight
    }

    this.accountsService.createContentKeyword(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getContentKeywordById();
        this.addContentKeywordForm.reset();
        this.closebutton9.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteWorkEffortContentNew(product) {
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "contentId": this.contentId,
          "fromDate": this.fromDateCatePatch,
          "view_INDEX_1": '',
          "view_SIZE_1": '',
          "workEffortContentTypeId": product.workEffortContentTypeId,
          "workEffortId": product.workEffortId
        }
        this.accountsService.deleteWorkEffortContentNew(req).subscribe((res: any) => {
          if (res.success) {
            this.getWorkEffortContentById();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateWorkEffortContentNew(): void {
    this.spinner.show();
    const requestData = {
      "contentId": this.contentId,
      "fromDate":  this.datePipe.transform(this.addWorkEffortForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "thruDate":  this.datePipe.transform(this.addWorkEffortForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
      "workEffortContentTypeId": this.addWorkEffortForm.value.workEffortContentTypeID,
      "workEffortId": this.addWorkEffortForm.value.workEffortID
    }
    this.accountsService.updateWorkEffortContentNew(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getWorkEffortContentById();
        this.closebutton7.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateWorkEffortContentNewPatch(product) {
    this.show=false;
    this.readOnlyCode=true;
    this.updateWorkEffortContentNewPat = product ;
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.addWorkEffortForm;
      formValue.patchValue({
          workEffortID: this.updateWorkEffortContentNewPat.workEffortId,
          workEffortContentTypeID: this.updateWorkEffortContentNewPat.workEffortContentTypeId,
          fromDate: this.datePipe.transform(this.updateWorkEffortContentNewPat.fromDate, "yyyy-MM-dd"),
          throughDate: this.datePipe.transform(this.updateWorkEffortContentNewPat.fromDate, "yyyy-MM-dd"),
      })

    }, 1000);
    this.spinner.hide();
  }
  
  createWorkEffortContentNew(): void {
    this.spinner.show();
    const requestData = {
      "contentId": this.contentId,
      "fromDate":  this.datePipe.transform(this.addWorkEffortForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "thruDate":  this.datePipe.transform(this.addWorkEffortForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
      "workEffortContentTypeId": this.addWorkEffortForm.value.workEffortContentTypeID,
      "workEffortId": this.addWorkEffortForm.value.workEffortID
    }

    this.accountsService.createWorkEffortContentNew(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getWorkEffortContentById();
        this.addWorkEffortForm.reset();
        this.closebutton7.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getWorkEffortContentById() {
    this.spinner.show();
    this.accountsService.getWorkEffortContentById(this.contentId).subscribe(res => {
      this.WorkEffortContentById = res.data.getWorkEffortContentById;
      this.spinner.hide();
    })
  }

  getContentKeywordById() {
    this.spinner.show();
    this.accountsService.getContentKeywordById(this.contentId).subscribe(res => {
      this.ContentKeywordById = res.data.getContentKeywordById;
      this.spinner.hide();
    })
  }

  getListWebSiteById() {
    this.spinner.show();
    this.accountsService.getListWebSiteById(this.contentId).subscribe(res => {
      this.ListWebSiteById = res.data;
      this.spinner.hide();
    })
  }

  

  getContentTypeId() {
    this.spinner.show();
    this.myContactsService.getContentTypeIds().subscribe(res => {
      this.contentTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.contentTypeId) {
        this.contentTypeIdArray.push({
          label: value.description,
          value: value.workEffortContentTypeId
        })
      }
    })
  }
  getWorkEffortId() {
    this.spinner.show();
    this.myContactsService.getWorkEffortId().subscribe(res => {
      this.WorkEffortId = res.data[0].getWorkEffortId;
      this.spinner.hide();
      for (const value of this.WorkEffortId) {
        this.WorkEffortIdArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
  }
  removeContentMetaData(product) {
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "contentId": product.contentId,
          "metaDataPredicateId": product.metaDataPredicateId,
          "view_INDEX_1": '',
          "view_SIZE_1": ''
        }
        this.accountsService.removeContentMetaData(req).subscribe((res: any) => {
          if (res.success) {
            this.getContentMetaDataById();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateContentMetaData(): void {
    this.spinner.show();
    const requestData = {
      "contentId": this.addMetaDataForm.value.contentID,
      "dataSourceId": this.addMetaDataForm.value.dataSourceID,
      "metaDataPredicateId": this.addMetaDataForm.value.metaDataPredicateID,
      "metaDataValue": this.addMetaDataForm.value.metaDataValue,
    }
    this.accountsService.updateContentMetaData(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: this.addMetaDataForm.value.contentID } });
        setTimeout(() => {
          this.getContentMetaDataById();
        }, 1000);
        this.closebutton6.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateContentMetaDatapatch(product) {
    this.show=false;
    this.updateContentMetaDataPat = product ;
    this.spinner.show();
    this.readOnlyCode=true;
    setTimeout(() => {
      const formValue = this.addMetaDataForm;
      formValue.patchValue({
          contentID: this.updateContentMetaDataPat.contentId,
          metaDataPredicateID: this.updateContentMetaDataPat.metaDataPredicateId,
          metaDataValue: this.updateContentMetaDataPat.metaDataValue,
          dataSourceID: this.updateContentMetaDataPat.dataSourceId,
      })

    }, 1000);
    this.spinner.hide();
  }
  
  addContentMetaData(): void {
    this.spinner.show();
    const requestData = {
      "contentId": this.addMetaDataForm.value.contentID,
      "dataSourceId": this.addMetaDataForm.value.dataSourceID,
      "metaDataPredicateId": this.addMetaDataForm.value.metaDataPredicateID,
      "metaDataValue": this.addMetaDataForm.value.metaDataValue,
    }

    this.accountsService.addContentMetaData(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: this.addMetaDataForm.value.contentID } });
        setTimeout(() => {
          this.getContentMetaDataById();
        }, 1000);
        this.addMetaDataForm.reset();
        this.closebutton6.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getContentMetaDataById() {
    this.spinner.show();
    this.accountsService.getContentMetaDataById(this.contentId).subscribe(res => {
      this.ContentMetaDataById = res.data[0].getContentMetaDataById;
      this.spinner.hide();
    })
  }
  getMetaDataPredicateIDNew() {
    this.spinner.show();
    this.accountsService.getMetaDataPredicateIDNew().subscribe(res => {
      this.MetaDataPredicateIDNew = res.data.getMetaDataPredicateID;
      this.spinner.hide();
      for (const value of this.MetaDataPredicateIDNew) {
        this.MetaDataPredicateIDNewArray.push({
          label: value.description,
          value: value.metaDataPredicateId
        })
      }
    })
  }

  removeContentAttribute(product) {
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "attrName": product.attrName,
          "contentId": product.contentId,
          "view_INDEX_1": '',
          "view_SIZE_1": ''
        }
        this.accountsService.removeContentAttribute(req).subscribe((res: any) => {
          if (res.success) {
            this.getContentAttributeById();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateContentAttribute(): void {
    this.spinner.show();
    const requestData = {
      "attrDescription": this.addAttributeForm.value.attrDescription,
      "attrName": this.addAttributeForm.value.attrName,
      "attrValue": this.addAttributeForm.value.attrValue,
      "contentId": this.addAttributeForm.value.contentID,
    }
    this.accountsService.updateContentAttribute(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: this.addAttributeForm.value.contentID } });
        setTimeout(() => {
          this.getContentAttributeById();
        }, 1000);
        this.addAttributeForm.reset();
        this.closebutton5.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateContentAttributePatch(product) {
    this.show=false;
    this.readOnlyCode=true;
    this.updateContentAttributePat = product ;
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.addAttributeForm;
      formValue.patchValue({
          contentID: this.updateContentAttributePat.contentId,
          attrName: this.updateContentAttributePat.attrName,
          attrValue: this.updateContentAttributePat.attrValue,
          attrDescription: this.updateContentAttributePat.attrDescription,
       
      })

    }, 1000);
    this.spinner.hide();
  }
  
  addContentAttribute(): void {
    this.spinner.show();
    const requestData = {
       "attrDescription": this.addAttributeForm.value.attrDescription,
       "attrName": this.addAttributeForm.value.attrName,
       "attrValue": this.addAttributeForm.value.attrValue,
       "contentId": this.addAttributeForm.value.contentID,
    }

    this.accountsService.addContentAttribute(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: this.addAttributeForm.value.contentID } });
        setTimeout(() => {
          this.getContentAttributeById();
        }, 1000);
        this.addAttributeForm.reset();
        this.closebutton5.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  getContentAttributeById() {
    this.spinner.show();
    this.accountsService.getContentAttributeById(this.contentId).subscribe(res => {
      this.ContentAttributeById = res.data.getContentAttributeById;
      this.spinner.hide();
    })
  }

  removeContentPurpose(product) {
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "contentId": product.contentId,
          "contentPurposeTypeId": product.contentPurposeTypeId,
          "sequenceNum": product.sequenceNum
        }
        this.accountsService.removeContentPurpose(req).subscribe((res: any) => {
          if (res.success) {
            this.getContentPurposeById();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateContentPurpose(): void {
    this.spinner.show();
    const requestData = {
      "contentId": this.addPurposeForm.value.contentID,
      "contentPurposeTypeId": this.addPurposeForm.value.contentPurposeTypeID,
      "sequenceNum": this.addPurposeForm.value.sequenceNum
    }
    this.accountsService.updateContentPurpose(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: this.addPurposeForm.value.contentID } });
        setTimeout(() => {
          this.getContentPurposeById();
        }, 1000);
        this.addPurposeForm.reset();
        this.closebutton4.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateContentPurposepatch(product) {
    this.show=false;
    this.readOnlyCode=true;
    this.updateContentPurposePat = product ;
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.addPurposeForm;
      formValue.patchValue({
          contentID:  this.updateContentPurposePat.contentId,
          contentPurposeTypeID:  this.updateContentPurposePat.contentPurposeTypeId,
          sequenceNum:  this.updateContentPurposePat.sequenceNum,
      })

    }, 1000);
    this.spinner.hide();
  }
  
  addContentPurpose(): void {
    this.spinner.show();
    const requestData = {
      "contentId": this.addPurposeForm.value.contentID,
      "contentPurposeTypeId": this.addPurposeForm.value.contentPurposeTypeID,
      "sequenceNum": this.addPurposeForm.value.sequenceNum
    }

    this.accountsService.addContentPurpose(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: this.addPurposeForm.value.contentID } });
        setTimeout(() => {
          this.getContentPurposeById();
        }, 1000);
        this.addPurposeForm.reset();
        this.closebutton4.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  getContentPurposeById() {
    this.spinner.show();
    this.accountsService.getContentPurposeById(this.contentId).subscribe(res => {
      this.ContentPurposeById = res.data[0].getContentPurposeById;
      this.spinner.hide();
    })
  }
  getContentPurposeType() {
    this.spinner.show();
    this.accountsService.getContentPurposeType().subscribe(res => {
      this.ContentPurposeType = res.data.getContentPurposeById;
      this.spinner.hide();
      for (const value of this.ContentPurposeType) {
        this.ContentPurposeTypeArray.push({
          label: value.description,
          value: value.contentPurposeTypeId
        })
      }
    })
  }

  removeContentRole(product) {
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "contentId": this.contentId,
          "fromDate": this.fromDateCatePatch,
          "partyId": product.partyId,
          "roleTypeId": product.roleTypeId,
          "view_INDEX_1": '',
          "view_SIZE_1": ''
        }
        this.accountsService.removeContentRole(req).subscribe((res: any) => {
          if (res.success) {
            this.getContentRoleById();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateContentRole(): void {
    this.spinner.show();
    const requestData = {
      "contentId": this.rolesForm.value.contentID,
      "fromDate": this.datePipe.transform(this.rolesForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "partyId": this.rolesForm.value.partyID,
      "roleTypeId": this.rolesForm.value.roleTypeID,
      "thruDate": this.datePipe.transform(this.rolesForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.accountsService.updateContentRole(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: this.rolesForm.value.contentID } });
        setTimeout(() => {
          this.getContentRoleById();
        }, 1000);
        this.rolesForm.reset();
        this.closebutton3.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateContentRolePatch(product) {
    this.show=false;
    this.readOnlyCode=true;
    this.updateContentRolePat = product ;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
    setTimeout(() => {
      const formValue = this.rolesForm;
      formValue.patchValue({
          contentID: this.updateContentRolePat.contentId,
          partyID: this.updateContentRolePat.partyId,
          roleTypeID: this.updateContentRolePat.roleTypeId,
          fromDate: this.fromDateCatePatch,
          throughDate: this.throughDateCatePatch
      })

    }, 1000);
    this.spinner.hide();
  }
  
  addContentRole(): void {
    this.spinner.show();
    const requestData = {
       "contentId": this.rolesForm.value.contentID,
       "fromDate": this.datePipe.transform(this.rolesForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
       "partyId": this.rolesForm.value.partyID,
       "roleTypeId": this.rolesForm.value.roleTypeID,
       "thruDate": this.datePipe.transform(this.rolesForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),
    }

    this.accountsService.addContentRole(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: this.rolesForm.value.contentID } });
        setTimeout(() => {
          this.getContentRoleById();
        }, 1000);
        this.rolesForm.reset();
        this.closebutton3.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getContentRoleById() {
    this.spinner.show();
    this.accountsService.getContentRoleById(this.contentId).subscribe(res => {
      this.ContentRoleById = res.data.getContentRoleById;
      this.spinner.hide();
    })
  }
  getContentRoleTypeId() {
    this.spinner.show();
    this.accountsService.getContentRoleTypeId().subscribe(res => {
      this.ContentRoleTypeId = res.data[0].node
      this.spinner.hide();
      for (const value of this.ContentRoleTypeId) {
        this.ContentRoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  removeContentAssoc(product) {
    const fromDateCatePatch=product.getContentAssocById.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "contentAssocTypeId": product.getContentAssocById.contentAssocTypeId,
          "contentId": product.getContentAssocById.contentId,
          "contentIdTo": product.getContentAssocById.contentIdTo,
          "fromDate": this.fromDateCatePatch,
          "view_INDEX_1": '',
          "view_SIZE_1": ''
        }
        this.accountsService.removeContentAssoc(req).subscribe((res: any) => {
          if (res.success) {
            this.getContentAssocById();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateContentAssoc(): void {
    this.spinner.show();
    const requestData = {
      "contentAssocTypeId": this.associationForm.value.contentAssocTypeID,
      "contentId": this.associationForm.value.contentID,
      "contentIdTo": this.associationForm.value.contentIDTo,
      "fromDate": this.datePipe.transform(this.associationForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "mapKey": this.associationForm.value.mapKey,
      "thruDate": this.datePipe.transform(this.associationForm.value.thruDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.accountsService.updateContentAssoc(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getContentAssocById();
        this.associationForm.reset();
        this.closebutton2.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateContentAssocPatch(product) {
    this.show=false;
    this.readOnlyCode=true;
    this.addOnFeild=true;
    this.updateContentAssocPat = product.getContentAssocById ;
    this.spinner.show();
    const fromDateCatePatch=product.getContentAssocById.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.getContentAssocById.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
    setTimeout(() => {
      const formValue = this.associationForm;
      formValue.patchValue({
          contentID: this.updateContentAssocPat.contentId,
          contentIDTo: this.updateContentAssocPat.contentIdTo,
          contentAssocTypeID: this.updateContentAssocPat.contentAssocTypeId,
          fromDate: this.fromDateCatePatch,
          thruDate: this.throughDateCatePatch,
          contentAssocPredicateID: this.updateContentAssocPat.contentAssocPredicateId,
          dataSourceID: this.updateContentAssocPat.dataSourceId,
          sequenceNum: this.updateContentAssocPat.sequenceNum,
          mapKey: this.updateContentAssocPat.mapKey,
          upperCoordinate: this.updateContentAssocPat.upperCoordinate,
          leftCoordinate: this.updateContentAssocPat.leftCoordinate,
          metaDataPredicateID: '',
      })

    }, 1000);
    this.spinner.hide();
  }
  clickContentAssPatch(){
    this.show=true;
    this.readOnlyCode=false;
    this.addOnFeild=true;
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.associationForm;
      formValue.patchValue({
          contentID: this.contentId,
          contentIDTo: '',
          contentAssocTypeID: '',
          fromDate: '',
          thruDate: '',
          contentAssocPredicateID: '',
          dataSourceID: '',
          sequenceNum: '',
          mapKey: '',
          upperCoordinate: '',
          leftCoordinate: '',
          metaDataPredicateID: '',
      })

    }, 1000);
    this.spinner.hide();

  }
  createContentAssoc(): void {
    this.spinner.show();
    const requestData = {
      "contentAssocPredicateId": this.associationForm.value.contentAssocPredicateID,
      "contentAssocTypeId": this.associationForm.value.contentAssocTypeID,
      "contentId": this.associationForm.value.contentID,
      "contentIdTo": this.associationForm.value.contentIDTo,
      "createdByUserLogin": this.associationForm.value.new,
      "createdDate": '',
      "dataSourceId": this.associationForm.value.dataSourceID,
      "fromDate": this.datePipe.transform(this.associationForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "lastModifiedByUserLogin": this.associationForm.value.new,
      "lastModifiedDate": '',
      "leftCoordinate": this.associationForm.value.leftCoordinate,
      "mapKey": this.associationForm.value.mapKey,
      "metaDataPredicateId": this.associationForm.value.metaDataPredicateID,
      "sequenceNum": this.associationForm.value.sequenceNum,
      "thruDate": this.datePipe.transform(this.associationForm.value.thruDate, "yyyy-MM-dd HH:mm:ss"),
      "upperCoordinate": this.associationForm.value.upperCoordinate
    }

    this.accountsService.createContentAssoc(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getContentAssocById();
        this.associationForm.reset();
        this.closebutton2.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getMetaDataPredicateID() {
    this.spinner.show();
    this.accountsService.getMetaDataPredicateID().subscribe(res => {
      this.MetaDataPredicateID = res.data.getMetaDataPredicateID;
      this.spinner.hide();
      for (const value of this.MetaDataPredicateID) {
        this.MetaDataPredicateIDArray.push({
          label: value.description,
          value: value.metaDataPredicateId
        })
      }
    })
  }
  getContentAssocTypeId() {
    this.spinner.show();
    this.accountsService.getContentAssocTypeId().subscribe(res => {
      this.ContentAssocTypeId = res.data[0].getContentAssocTypeId;
      this.spinner.hide();
      for (const value of this.ContentAssocTypeId) {
        this.ContentAssocTypeIdArray.push({
          label: value.description,
          value: value.contentAssocTypeId
        })
      }
    })
  }
  getContentAssocById() {
    this.spinner.show();
    this.accountsService.getContentAssocById(this.contentId).subscribe(res => {
      this.ContentAssocById = res.data;
      this.spinner.hide();
    })
  }
  updateContent(): void {
    this.spinner.show();
    const requestData = {
      "characterSetId": this.createContentForm.value.characterSetID,
      "childBranchCount": this.createContentForm.value.childBranchCount,
      "childLeafCount": this.createContentForm.value.childLeafCount,
      "contentId": this.createContentForm.value.contentID,
      "contentName": this.createContentForm.value.contentName,
      "contentTypeId": this.createContentForm.value.contentTypeId,
      "createdByUserLogin": this.createContentForm.value.createdByUser,
      "createdDate": this.datePipe.transform(this.createContentForm.value.createdDate, "yyyy-MM-dd HH:mm:ss"),
      "customMethodId": this.createContentForm.value.customMethodID,
      "dataResourceId": this.createContentForm.value.dataResourceId,
      "dataSourceId": this.createContentForm.value.dataSourceID,
      "decoratorContentId": this.createContentForm.value.decoratorContentID,
      "description": this.createContentForm.value.description,
      "instanceOfContentId": this.createContentForm.value.instanceContentID,
      "lastModifiedByUserLogin": this.createContentForm.value.lastModifiedByUser,
      "lastModifiedDate": this.datePipe.transform(this.createContentForm.value.lastModifiedDate, "yyyy-MM-dd HH:mm:ss"),
      "localeString": this.createContentForm.value.localeString,
      "mimeTypeId": this.createContentForm.value.mimeTypeID,
      "ownerContentId": this.createContentForm.value.ownerContentID,
      "privilegeEnumId": this.createContentForm.value.privilegeEnumID,
      "serviceName": this.createContentForm.value.serviceName,
      "statusId": "",
      "templateDataResourceId": this.createContentForm.value.templateDataResourceID,
    }
    this.accountsService.updateContent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getEditContentById();
        this.closebutton1.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateContentPatch() {
    this.spinner.show();
    const fromDateCatePatch=this.EditContentById[0].createdDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=this.EditContentById[0].lastModifiedDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
    setTimeout(() => {
      const formValue = this.createContentForm;
      formValue.patchValue({
          contentID: this.contentId,
          contentTypeId: this.EditContentById[0].contentTypeId,
          ownerContentID: this.EditContentById[0].ownerContentId,
          decoratorContentID: this.EditContentById[0].decoratorContentId,
          instanceContentID: this.EditContentById[0].instanceOfContentId,
          dataResourceId: this.EditContentById[0].dataResourceId,
          templateDataResourceID: this.EditContentById[0].templateDataResourceId,
          dataSourceID: this.EditContentById[0].dataSourceId,
          privilegeEnumID: this.EditContentById[0].privilegeEnumId,
          serviceName: this.EditContentById[0].serviceName,
          customMethodID: this.EditContentById[0].customMethodId,
          contentName: this.EditContentById[0].contentName,
          description: this.EditContentById[0].description,
          localeString: this.EditContentById[0].localeString,
          mimeTypeID: this.EditContentById[0].mimeTypeId,
          characterSetID: this.EditContentById[0].characterSetId,
          childLeafCount: this.EditContentById[0].childLeafCount,
          childBranchCount: this.EditContentById[0].childBranchCount,
          createdDate: this.fromDateCatePatch,
          createdByUser: this.EditContentById[0].createdByUserLogin,
          lastModifiedDate: this.throughDateCatePatch,
          lastModifiedByUser: this.EditContentById[0].lastModifiedByUserLogin,   
      })

    }, 1000);
    this.spinner.hide();
  }
  getCharacterSet() {
    this.spinner.show();
    this.accountsService.getCharacterSet().subscribe(res => {
      this.CharacterSetId = res.data.getCharacterSet;
      this.spinner.hide();
      for (const value of this.CharacterSetId) {
        this.CharacterSetIdArray.push({
          label: value.description,
          value: value.characterSetId
        })
      }
    })
  }
  getCatalogContentId() {
    this.spinner.show();
    this.accountsService.getCatalogContentId().subscribe(res => {
      this.CatalogContentId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CatalogContentId) {
        this.CatalogContentIdArray.push({
          label: value.contentId,
          value: value.contentId
        })
      }
    })
  }
  getDataResourceId() {
    this.spinner.show();
    this.accountsService.getDataResourceId().subscribe(res => {
      this.DataResourceId = res.data.getDataResourceId;
      this.spinner.hide();
      for (const value of this.DataResourceId) {
        this.DataResourceIdArray.push({
          label: value.dataResourceId,
          value: value.dataResourceId
        })
      }
    })
  }
  getCalatalogMimeTypeId() {
    this.spinner.show();
    this.accountsService.getCalatalogMimeTypeId().subscribe(res => {
      this.CalatalogMimeTypeId = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.CalatalogMimeTypeId) {
        this.CalatalogMimeTypeIdArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }
  getCalatalogContentTypeId() {
    this.spinner.show();
    this.accountsService.getCalatalogContentTypeId().subscribe(res => {
      this.CalatalogContentTypeId = res.data.contentTypeId;
      this.spinner.hide();
      for (const value of this.CalatalogContentTypeId) {
        this.CalatalogContentTypeIdArray.push({
          label: value.description,
          value: value.contentTypeId
        })
      }
    })
  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getEditContentById() {
    this.spinner.show();
    this.accountsService.getEditContentById(this.contentId).subscribe(res => {
      this.EditContentById = res.data.getEditContent;
      this.EditContentByIdAll = res.data.getEditContent[0];
      this.spinner.hide();
    })
  }
  

 

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

    changeactiveCategory(tab: number)
    {
      this.activeCategory = tab;
    
    }

}
