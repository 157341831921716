<div class="container-fluid main-container-wrapper">
  <br>
  <div class="row">
    <div class="col-12">
      <div class="" style="background: #E8E8E8;">
        <div>
          <div class="container-fluid">
            <div class="row">
              <ng-container *ngIf="isPurchase">
                <div class="w3-card-4">
                  <header class="w3-container w3-blue" style="margin-left: -12.7px;margin-right: -12px;">
                    <div class="header-tabs">
                      <h4 class=" common-styling amargin">
                        Purchase Order Form
                      </h4>
                      <span>
                        <button type="submit" (click)="toDashboard()" class="btn btn-secondary submit-btn mr-2">
                          Home</button>
                        <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">
                          Back</button>
                      </span>
                    </div>
                  </header>

                  <div class="row">
                    <div class="col-md-12" style="margin-top: 15px;">
                      <div class="w3-card-4 classCardA pb-3">
                        <div class="row">
                          <div class="col-md-6" style="padding: 45px;
                          align-content: center;
                          display: grid;">

                            <h1 style="color:#fff;font-size:33px;">PURCHASE ORDER</h1>

                          </div>
                          <div class="col-md-6" style="padding-right: 0;margin-top: 15px;">
                            <div class="w3-card-4 classCardC">
                              <!-- <div class="container-fluid">
                              
      
                                  <h4 class="m-0" style="margin-left: 6% !important;
                                  font-weight: bold;
                                  color: white;">
                                    Product Store</h4>
                                
                              </div> -->
                              <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select pb-0">

                                  <div class="col-lg-12">
                                    <div class="row">
                                      <!-- <div class="col-lg-3 form-group rightFormAD">
                                        <label class="common-labels" style="color:#fff !important;">Product Store
                                        </label>
                                      </div> -->

                                      <div class="col-lg-5 form-group rightFormAD">
                                        <p-dropdown filter="true" [options]="organisationsArray" appendTo="body"
                                          [ngModelOptions]="{standalone: true}" [class]="'dropdown-style'"
                                          [(ngModel)]="createOrderPurchase.billToCustomerPartyId" optionlabel="label"
                                          placeholder="Select Internal Organization">
                                        </p-dropdown>
                                      </div>
                                      <!-- <div class="col-lg-3 form-group rightFormBB">
                                        <label class="common-labels" style="color:#fff !important;">Sales Channel
                                        </label>
                                      </div> -->
                                      <div class="col-lg-5 form-group rightFormBB">
                                        <p-dropdown filter="true" [options]="userLoginArray" appendTo="body"
                                          [ngModelOptions]="{standalone: true}" [class]="'dropdown-style'"
                                          [(ngModel)]="createOrderPurchase.userLoginId" optionlabel="label"
                                          placeholder="Select User Login ID">
                                        </p-dropdown>
                                      </div>
                                    </div>
                                  </div>



                                  <div class="col-lg-12">
                                    <div class="row">

                                      <!-- <div class="col-lg-3 form-group rightFormAD">
                                        <label class="common-labels" style="color:#fff !important;">User Login ID</label>
                                      </div> -->

                                      <div class="col-lg-5 form-group rightFormAD">
                                        <p-dropdown filter="true" [options]="supplierArray" appendTo="body"
                                          [ngModelOptions]="{standalone: true}" [class]="'dropdown-style'"
                                          [(ngModel)]="createOrderPurchase.supplierPartyId" optionlabel="label"
                                          placeholder="Select Supplier">
                                        </p-dropdown>
                                      </div>

                                      <div class="col-lg-2 form-group rightFormAD">
                                        <button type="submit" (click)="continuePurchaseOrder()"
                                          class="btn btn-secondary submit-btn" style="    background: #015fa5 !important;
                                        border: 0px solid white !important;
                                        font-size: 13px !important;
                                        margin-left: 67%;
                                        color: #fff !important;">
                                          Continue</button>
                                      </div>
                                      <!-- <div class="col-lg-3 form-group rightFormBB">
                                        <label class="common-labels" style="color:#fff !important;">Customer</label>
                                      </div> -->
                                      <!--       
                                      <div class="col-lg-5 form-group rightFormBB">
                                        <p-dropdown filter="true" [options]="customerArray" appendTo="body"
                                        [class]="'dropdown-style'"  [ngModelOptions]="{standalone: true}"
                                          [(ngModel)]="createOrderSales.partyId" optionlabel="label"
                                          placeholder="Select Customer">
                                        </p-dropdown>
                                      </div> -->

                                    </div>
                                  </div>
                                </div>
                              </form>

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>


                  <div class="row">

                    <div class="col-md-9" style="padding-left: 15px;padding-right: 0;">
                      <div class="w3-card-4 classCardD">
                        <!-- <div class="container-fluid">
                          <ul class="tabbing-section tabbing-accordians" style="background: #F5F7FA!important;
                          margin-top: 0%;">
  
                            <li class="aSize" [ngClass]="activeCategoryValue==2?'active':''">
                              Enter Details</li>
  
  
                          </ul>
                        </div> -->

                        <div *ngIf="activeCategoryValue==2">
                          <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div class="panel-body">
                              <div class="">
                                <div class="">
                                  <div class="row">
                                    <form class="w-100">
                                      <div
                                        class="mt-4 mb-0 all-users-infomation font-13 font-medium own-user-select userListValue"
                                        style="white-space: nowrap;padding-bottom:0px !important;">
                                        <div class="col-lg-12">
                                          <div class="row">
                                            <div class="col-lg-2 form-group mb-4 rightFormAB">
                                              <label class="common-labels">Order Id </label>
                                            </div>
                                            <div class="col-lg-4">
                                              <input type="text" class="form-control" [(ngModel)]="poContinue.orderId"
                                                [ngModelOptions]="{standalone: true}" placeholder="Enter Order Id">
                                            </div>

                                            <div class="col-lg-2 form-group  mb-4 rightFormAC">
                                              <label class="common-labels">Order Name </label>
                                            </div>

                                            <div class="col-lg-4">
                                              <input type="text" class="form-control" [(ngModel)]="poContinue.orderName"
                                                [ngModelOptions]="{standalone: true}" placeholder="Enter Order Name	">
                                            </div>


                                          </div>
                                        </div>


                                        <div class="col-lg-12">
                                          <div class="row">
                                            <div class="col-lg-2 form-group  mb-4 rightFormAB">
                                              <label class="common-labels">Currency
                                              </label>
                                            </div>
                                            <div class="col-lg-4">
                                              <p-dropdown filter="true" [options]="currencyArray" appendTo="body"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="poContinue.currencyUomId" optionlabel="label"
                                                placeholder="Select Currency"></p-dropdown>
                                            </div>

                                            <div class="col-lg-2 form-group mb-4  rightFormAC">
                                              <label class="common-labels">Choose Catalog
                                              </label>
                                            </div>
                                            <div class="col-lg-4">
                                              <p-dropdown filter="true" [options]="catalogueArray" appendTo="body"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="poContinue.CURRENT_CATALOG_ID" optionlabel="label"
                                                placeholder="Select Catalogue"></p-dropdown>
                                            </div>



                                          </div>
                                        </div>


                                        <div class="col-lg-12">
                                          <div class="row">
                                            <div class="col-lg-2 form-group  mb-4 rightFormAB">
                                              <label class="common-labels">WorkEffort ID
                                              </label>
                                            </div>
                                            <div class="col-lg-4">
                                              <p-dropdown filter="true" [options]="workEffortArray" appendTo="body"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="poContinue.workEffortId" optionlabel="label"
                                                placeholder="Select WorkEffort ID"></p-dropdown>
                                            </div>

                                            <div class="col-lg-2 form-group  mb-4 rightFormAC">
                                              <label for="exampleInputEmail1">Ship After Date </label>
                                            </div>

                                            <div class="col-lg-4">
                                              <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                                [(ngModel)]="poContinue.shipAfterDate"
                                                [ngModelOptions]="{standalone: true}" hourFormat="24"
                                                dateFormat="dd.mm.yy"></p-calendar>
                                            </div>



                                          </div>
                                        </div>

                                        <div class="col-lg-12">
                                          <div class="row">
                                            <div class="col-lg-2 form-group  mb-4 rightFormAB">
                                              <label for="exampleInputEmail1">After Before Date</label>
                                            </div>

                                            <div class="col-lg-4">
                                              <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                                hourFormat="24" dateFormat="dd.mm.yy"
                                                [(ngModel)]="poContinue.reserveAfterDate"
                                                [ngModelOptions]="{standalone: true}"></p-calendar>
                                            </div>

                                            <div class="col-lg-2 form-group mb-4  rightFormAC">

                                              <label for="exampleInputEmail1">Ship Before Date </label>
                                            </div>

                                            <div class="col-lg-4">
                                              <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                                [(ngModel)]="poContinue.shipBeforeDate"
                                                [ngModelOptions]="{standalone: true}" hourFormat="24"
                                                dateFormat="dd.mm.yy">
                                              </p-calendar>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-lg-12">
                                          <div class="row">
                                            <div class="col-lg-10"></div>
                                            <div class="col-lg-2">
                                              <div *ngIf="showbutton" class="main-submit-button m-0 buttonB">
                                                <button type="submit" (click)="continuePurchaseOrderCreation()"
                                                  class="btn btn-secondary submit-btn">Continue
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                    <!-- <form class="w-100">
                                      <div style="padding: 15px 8px!important;"
                                        class="all-users-infomation font-13 font-medium own-user-select" style="white-space: nowrap;">
                                        <div class="col-lg-12">
                                          <div class="row">
  
                                            <div class="col-lg-3 form-group rightFormB" style="margin-left: -15px;">
                                              <label class="common-labels">Order Id </label>
                                            </div>
  
                                            <div class="col-lg-3">
                                              <input type="text" class="form-control" [(ngModel)]="poContinue.orderId"
                                                [ngModelOptions]="{standalone: true}" placeholder="Enter Order Id">
                                            </div>
  
                                            <div class="col-lg-3 form-group rightFormA">
                                              <label class="common-labels">Order Name </label>
                                            </div>
  
                                            <div class="col-lg-3">
                                              <input type="text" class="form-control" [(ngModel)]="poContinue.orderName"
                                                [ngModelOptions]="{standalone: true}" placeholder="Enter Order Name	">
                                            </div>
  
                                           
                                          </div>
                                        </div>
  
                                        <div class="col-lg-12">
                                          <div class="row">
  
                                            <div class="col-lg-3 form-group rightFormB" style="margin-left: -15px;">
                                              <label class="common-labels">Currency
                                              </label>
                                            </div>
  
                                            <div class="col-lg-3">
                                              <p-dropdown filter="true" [options]="currencyArray" appendTo="body"
                                              [ngModelOptions]="{standalone: true}"
                                              [(ngModel)]="poContinue.currencyUomId" optionlabel="label"
                                              placeholder="Select Currency"></p-dropdown>
                                            </div>
  
                                            <div class="col-lg-3 form-group rightFormA">
                                              <label class="common-labels">Choose catalogue
                                              </label>
                                            </div>
  
                                            <div class="col-lg-3">
                                              <p-dropdown filter="true" [options]="catalogueArray" appendTo="body"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="poContinue.CURRENT_CATALOG_ID" optionlabel="label"
                                                placeholder="Select Catalogue"></p-dropdown>
                                            </div>
  
                                         
                                          </div>
                                        </div>
                                        <div class="col-lg-12">
                                          <div class="row">
  
                                            <div class="col-lg-3 form-group rightFormB" style="margin-left: -15px;">
                                              <label class="common-labels">WorkEffort ID
                                              </label>
                                            </div>
  
                                            <div class="col-lg-3">
                                              <p-dropdown filter="true" [options]="workEffortArray" appendTo="body"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="poContinue.workEffortId" optionlabel="label"
                                                placeholder="Select WorkEffort ID"></p-dropdown>
                                            </div>
  
                                            <div class="col-lg-3 form-group rightFormA">
                                              <label for="exampleInputEmail1">Ship After Date </label>
                                            </div>
                                            <div class="col-lg-3">
                                              <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                                [(ngModel)]="poContinue.shipAfterDate"
                                                [ngModelOptions]="{standalone: true}" hourFormat="24"
                                                dateFormat="dd.mm.yy"></p-calendar>
                                            </div>
  
                                          
                                          </div>
                                        </div>
                                        <div class="col-lg-12">
                                          <div class="row">
  
                                            <div class="col-lg-3 form-group rightFormB" style="margin-left: -15px;">
                                              <label for="exampleInputEmail1">Ship Before Date </label>
                                            </div>
  
                                            <div class="col-lg-3">
                                              <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                                [(ngModel)]="poContinue.shipBeforeDate"
                                                [ngModelOptions]="{standalone: true}" hourFormat="24"
                                                dateFormat="dd.mm.yy">
                                              </p-calendar>
                                            </div>
  
  
                                            <div class="col-lg-3 form-group rightFormA">
                                              <label for="exampleInputEmail1">Cancel Order Date</label>
                                            </div>
                                            <div class="col-lg-3">
                                              <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                                [(ngModel)]="poContinue.reserveAfterDate"
                                                [ngModelOptions]="{standalone: true}" hourFormat="24"
                                                dateFormat="dd.mm.yy">
                                              </p-calendar>
                                            </div>
  
                                          
                                          </div>
  
                
                                        </div>
  
                                        <div *ngIf="showbutton" class="main-submit-button aButtonClass"
                                          style="padding-left: 34em;">
                                          <button type="submit" (click)="continuePurchaseOrderCreation()"
                                            class="btn btn-secondary submit-btn">Continue</button>
                                        </div>
                                      </div>
  
                                    </form> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="activeCategoryValue==8">
                          <div>
                            <div class="container-fluid">
                              <div class="container-fluid">
                                <ul class="tabbing-section  tabbing-accordians paddingSales">
                                  <li class="aSalesOrderTag" [ngClass]="activeCategoryParty==1?'active':''"><a>
                                      Purchase Order : Additional Party Entry </a></li>
                                </ul>
                              </div>
                              <div class="row">
                                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                  <div class="w-100">
                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                      <div class="panel panel-default">
                                        <div *ngIf="activeCategoryParty==1">
                                          <div class="header-tabs">
                                            <h4 class=" common-styling spanACard">
                                              Purchase Order : Additional Party Entry
                                            </h4>
                                            <span class="arrow-toggle toggle-arrow detail-created"
                                              data-toggle="collapse" href="#seo-collapse">
                                            </span>
                                            <span class="edit-buttons aParty" routerLinkActive="active"
                                              (click)="orderTabRefresh()"> Order Items</span>
                                            <span class="edit-buttons spanACard"
                                              routerLinkActive="active">Shipping</span>&nbsp;
                                            <span class="edit-buttons spanACard">Options</span>&nbsp;
                                            <span class="edit-buttons spanACard">Order Terms</span>
                                            <span class="edit-buttons spanACard">Payment</span>&nbsp;
                                            <span class="edit-buttons spanACard"> Parties</span>&nbsp;
                                            <span class="edit-buttons spanACard"> Reviews Order</span>
                                            <span class="edit-buttons spanACard" routerLinkActive="active"
                                              (click)="finalizeOrderParty()"> Continue</span>



                                          </div>
                                          <div class="panel-body">
                                            <div class="mt-2">
                                              <div class="">
                                                <h4 class="detail-heading">1) Select type of party to associate to order
                                                  :
                                                </h4>
                                                <div class="radios-sections">
                                                  <div class="col-lg-9 pl-0 col-12">
                                                    <div class="form-group  d-flex radio-boxes-wrapper">
                                                      <input type="radio" id="Guaranteed" name="radio"
                                                        value="Guaranteed" (click)="setPartyId(true)">
                                                      <label class="common-labels" for="Guaranteed">Person</label>
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-9 pl-0 col-12">
                                                    <div class="form-group d-flex radio-boxes-wrapper">
                                                      <input type="radio" id="Air" name="radio" value="Air"
                                                        (click)="setPartyId(false)">
                                                      <label class="common-labels" for="Air">Group</label>
                                                    </div>
                                                  </div>
                                                  <div class="col-lg-9 pl-0 col-12">
                                                    <div class="form-group d-flex radio-boxes-wrapper">
                                                      <input type="radio" id="Ground" name="radio" value="Ground"
                                                        (click)="setPartyId(false)">
                                                      <label class="common-labels" for="Ground">I do not wish to add
                                                        additional parties.
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <ng-container *ngIf="isParty">
                                                    <h4 class="detail-heading">2) Find Order :</h4>
                                                    <div class="col-lg-4 col-12">
                                                      <div class="form-group">
                                                        <label class="common-labels identifier-label">Identifier
                                                        </label>
                                                        <p-dropdown [options]="partyIds" filter="true"
                                                          [(ngModel)]="additionalPartyForm.additionalPartyId"
                                                          [ngModelOptions]="{standAlone:true}"></p-dropdown>
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-12 col-12 main-submit-button">
                                                      <button type="submit" class="btn btn-secondary submit-btn"
                                                        (click)="isRole=true">Apply</button>
                                                    </div>
                                                  </ng-container>
                                                  <ng-container *ngIf="isRole">
                                                    <h4 class="detail-heading">3) Select Role :</h4>
                                                    <div class="col-lg-4 col-12">
                                                      <div class="form-group">
                                                        <label class="common-labels identifier-label">Role
                                                        </label>
                                                        <p-dropdown [options]="roleValue" filter="true"
                                                          [(ngModel)]="additionalPartyForm.additionalRoleTypeId"
                                                          [ngModelOptions]="{standALone:true}"></p-dropdown>
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-12 col-12 main-submit-button">
                                                      <button type="submit" class="btn btn-secondary submit-btn"
                                                        (click)="addAdditionalParty()">Add</button>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategoryValue==9">
                          <div>
                            <div class="container-fluid">
                              <div class="container-fluid">
                                <ul class="tabbing-section  tabbing-accordians paddingSales">
                                  <li class="aSalesOrderTag" [ngClass]="activeCategoryComfirm==1?'active':''"
                                    (click)="changeactiveCategoryFunctionConfirm(1)"><a>
                                      Purchase Order : Order Confirmation</a></li>

                                  <li class="aSalesOrderTag" [ngClass]="activeCategoryComfirm==3?'active':''"
                                    (click)="changeactiveCategoryFunctionConfirm(3)"><a>
                                      Delivery Information </a></li>
                                  <li class="aSalesOrderTag" [ngClass]="activeCategoryComfirm==4?'active':''"
                                    (click)="changeactiveCategoryFunctionConfirm(4)"><a>
                                      Order Items </a></li>

                                </ul>
                              </div>
                              <div class="row">
                                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                  <div class="w-100">
                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                      <div class="panel panel-default">
                                        <div *ngIf="activeCategoryComfirm==1">
                                          <div class="header-tabs">
                                            <h4 class=" common-styling spanACard">
                                              Purchase Order : Order Confirmation
                                            </h4>
                                            <a class="arrow-toggle toggle-arrow detail-created port-header tabs-header collapsed"
                                              data-toggle="collapse" href="#Order">
                                            </a>
                                            <span class="edit-buttons confirmOrder"
                                              routerLinkActive="active" (click)="orderTabRefresh()">Order Items</span>
                                            <span class="edit-buttons spanACard">Shipping</span>&nbsp;
                                            <span class="edit-buttons spanACard">Options</span>&nbsp;
                                            <span class="edit-buttons spanACard">Order Terms</span>
                                            <span class="edit-buttons spanACard">Payment</span>&nbsp;
                                            <span class="edit-buttons spanACard"> Parties</span>&nbsp;
                                            <span class="edit-buttons spanACard"> Review Order</span>
                                            <span class="edit-buttons spanACard" routerLinkActive="active"
                                              (click)="processOrder()">Create Order</span>

                                          </div>
                                          <div class="panel-body">
                                            <div class="mt-2">
                                              <div class="">
                                                <div class="row">
                                                  <div
                                                    class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                                    style="padding-left:15px;">
                                                    <div class="d-flex flex-column">
                                                      <strong class="common-bold-properties" style="margin-right:10px;">
                                                        Order Name
                                                      </strong>
                                                      <p class="common-regular-properties">
                                                        {{reviewOrderValue?.orderHeaderInfo.orderName}}
                                                      </p>
                                                    </div>
                                                    <div class="d-flex flex-column">
                                                      <strong class="common-bold-properties" style="margin-right:10px;">
                                                        Order Terms
                                                      </strong>
                                                      <div class="common-regular-properties">
                                                        <table class="table">
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">Term Type</th>
                                                              <th scope="col">Term Value</th>
                                                              <th scope="col">Term Days</th>
                                                              <th scope="col">Text Value</th>
                                                              <th scope="col">Description</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <ng-template ngFor let-term
                                                              [ngForOf]="reviewOrderValue?.orderTerms">
                                                              <tr>
                                                                <th scope="row">{{term.termTypeID}}</th>
                                                                <td>{{term.termValue}}</td>
                                                                <td>{{term.termDays}}</td>
                                                                <td>{{term.textValue}}</td>
                                                                <td>{{term.description}}</td>
                                                              </tr>
                                                            </ng-template>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                    <strong class="common-bold-properties" style="margin-right:10px;">
                                                      Splitting
                                                      Preference </strong>
                                                    <p class="common-regular-properties"> N/A
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div *ngIf="activeCategoryComfirm==3">
                                          <div class="header-tabs">
                                            <h4 class=" common-styling spanACard">
                                              Delivery Information
                                            </h4>

                                          </div>
                                          <div class="panel-body a">
                                            <div class="mt-2">
                                              <div class="">
                                                <div class="row m-0">
                                                  <div class="card confimation-table own-account-tables">
                                                    <p-table [resizableColumns]="true" columnResizeMode="expand"
                                                      scrollWidth="100%" [paginator]="false" [rows]="rows"
                                                      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true"
                                                      [totalRecords]="total"
                                                      [value]="reviewOrderValue?.orderShippingInformation"
                                                      [scrollable]="true"
                                                      styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                                      <ng-template pTemplate="colgroup" let-columns>
                                                        <colgroup>
                                                          <col *ngFor="let col of offerShowingLineColoumns">
                                                        </colgroup>
                                                      </ng-template>
                                                      <ng-template pTemplate="header">
                                                        <tr class="activity-rows">
                                                          <th id="tableHead"
                                                            *ngFor="let col of offerShowingLineColoumns">
                                                            <ng-container>
                                                              {{col.header}}
                                                            </ng-container>
                                                          </th>
                                                        </tr>
                                                      </ng-template>
                                                      <ng-template pTemplate="body" let-i="rowIndex" let-activity>
                                                        <tr class="activity-data">
                                                          <td *ngFor="let col of offerShowingLineColoumns">
                                                            <ng-container *ngIf="col.field === 'Destination'">
                                                              <span>{{activity.address.toName}},{{activity.address.address1}},{{activity.address.countryGeoId}},
                                                                {{activity.address.postalCode}}</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="col.field === 'Supplier'">
                                                              <span class="d-flex red-border align-items-center">
                                                                <div class="name_text">
                                                                  <a>
                                                                    {{activity.supplier}}
                                                                  </a>
                                                                </div>
                                                              </span>
                                                            </ng-container>
                                                            <ng-container *ngIf="col.field === 'ShipmentMethod'">
                                                              <span class="d-block align-items-center">
                                                                {{activity.method.description}}
                                                                {{activity.method.shipmentMethodTypeId}}
                                                              </span>
                                                            </ng-container>
                                                            <ng-container *ngIf="col.field === 'Items'"><span>
                                                                {{activity.shipItems[0].item}}</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="col.field === 'Quantity'">
                                                              <span>{{activity.shipItems[0].qty}}</span>
                                                            </ng-container>

                                                          </td>
                                                        </tr>
                                                      </ng-template>
                                                    </p-table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div *ngIf="activeCategoryComfirm==4">
                                          <div class="header-tabs">
                                            <h4 class=" common-styling spanACard">
                                              Order Items
                                            </h4>

                                          </div>
                                          <div class="panel-body a">
                                            <div class="">
                                              <div class="">
                                                <div class="row m-0">
                                                  <div class="card confimation-table own-account-tables">
                                                    <p-table [resizableColumns]="true" columnResizeMode="expand"
                                                      scrollWidth="100%" [paginator]="false" [rows]="rows"
                                                      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true"
                                                      [totalRecords]="total" [value]="reviewOrderValue?.orderItemsMap"
                                                      [scrollable]="true"
                                                      styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                                      <ng-template pTemplate="colgroup" let-columns>
                                                        <colgroup>
                                                          <col *ngFor="let col of orderItemsColumns">
                                                        </colgroup>
                                                      </ng-template>
                                                      <ng-template pTemplate="header">
                                                        <tr class="activity-rows">
                                                          <th id="tableHead" *ngFor="let col of orderItemsColumns">
                                                            <ng-container>
                                                              {{col.header}}
                                                            </ng-container>
                                                          </th>
                                                        </tr>
                                                      </ng-template>
                                                      <ng-template pTemplate="body" let-rowData let-i="rowIndex"
                                                        let-activity>
                                                        <tr class="activity-data">
                                                          <td *ngFor="let col of orderItemsColumns">
                                                            <ng-container *ngIf="col.field === 'status'">
                                                              <span>{{activity.itemDescription}}</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="col.field === 'name'">
                                                              <span class="d-flex red-border align-items-center">
                                                                <div class="name_text">
                                                                  <a>
                                                                    {{activity.quantity}}
                                                                  </a>
                                                                </div>
                                                              </span>
                                                            </ng-container>
                                                            <ng-container *ngIf="col.field === 'email'">
                                                              <span class="d-block align-items-center">
                                                                <div class="name_text">
                                                                  {{activity.unitPrice}}
                                                                </div>
                                                              </span>
                                                            </ng-container>
                                                            <ng-container *ngIf="col.field === 'phone'"><span>
                                                                {{activity.orderItemAdjustment}}</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="col.field === 'type'">
                                                              <span>{{activity.orderItemSubTotal}}</span>
                                                            </ng-container>
                                                          </td>
                                                        </tr>
                                                      </ng-template>
                                                    </p-table>
                                                  </div>

                                                  <div class="row w-100" style="padding-top:10px;">
                                                    <div class="col-md-10 text-right">
                                                      <strong
                                                        class="common-bold-properties confirm-price-text d-block">Sub
                                                        Total</strong>
                                                      <strong
                                                        class="common-bold-properties  confirm-price-text d-block">Promotion</strong>
                                                      <strong
                                                        class="common-bold-properties confirm-price-text  d-block">Shipping
                                                        and
                                                        Handling</strong>
                                                      <strong
                                                        class="common-bold-properties confirm-price-text  d-block">VAT</strong>
                                                      <br>
                                                      <strong
                                                        class="common-bold-properties confirm-price-text d-block">Grand
                                                        Total</strong>
                                                    </div>
                                                    <div class="col-md-2 text-right">
                                                      <p class="common-regular-properties confirm-price-text  d-block">
                                                        US${{reviewOrderValue?.orderHeaderInfo.commonTotal}}</p>
                                                      <p class="common-regular-properties  confirm-price-text  d-block">
                                                        -US$0</p>
                                                      <p class="common-regular-properties confirm-price-text d-block">
                                                        US${{reviewOrderValue?.shippingAndHandling}}</p>
                                                      <p class="common-regular-properties confirm-price-text d-block">
                                                        US${{reviewOrderValue?.orderSalesTax}}</p>
                                                      <br>
                                                      <p class="common-regular-properties confirm-price-text d-block">
                                                        US${{reviewOrderValue?.orderGrandTotal}}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3" style="padding-left: 0;">
                      <div class="w3-card-4 classCardBA" style="height:100%;">

                        <form class="w-100">
                          <div class="all-users-infomation font-13 font-medium own-user-select"
                            style="white-space: nowrap; padding: 40px 0px;">

                            <div class="col-lg-12">
                              <div class="row">
                                <div class="col-lg-10 form-group rightFormA">
                                  <label class="common-labels">Total </label>
                                </div>

                              </div>
                            </div>

                            <div class="col-lg-12">
                              <div class="row">

                                <div class="col-lg-8 rightFormnb" style="margin-left: 20% !important;">
                                  <input disabled type="text" class="form-control" placeholder="Enter Total">
                                </div>
                              </div>
                            </div>

                          </div>
                        </form>

                      </div>
                    </div>




                  </div>

                </div>
                <div class="row pr-0" style="margin-top:0.1%;">
                  <div class="col-lg-12" style="padding-right:0px;padding-left:15px;">

                    <div class="w3-card-4 classCardB " style="
                  width: 100%;">
                      <div>
                        <div class="container-fluid">
                          <ul class="tabbing-section tabbing-accordians"
                            style="margin-left: -12px;margin-right: -12px;background: #fff!important;margin-top: -0.1%;">


                            <li class="aSize" [ngClass]="activeCategoryValueMain==3?'active':''">
                              Create Order
                            </li>

                            <li class="aSize" [ngClass]="activeCategoryValueMain==4?'active':''">
                              Shipping</li>
                            <li class="aSize" [ngClass]="activeCategoryValueMain==5?'active':''">
                              Option</li>
                            <li class="aSize" [ngClass]="activeCategoryValueMain==6?'active':''">
                              Term</li>





                          </ul>
                        </div>

                        <div *ngIf="activeCategoryValueMain==3">
                          <div>
                            <div class="container-fluid">
                              <div class="container-fluid">
                                <ul class="tabbing-section  tabbing-accordians paddingSales" style="background: #fff!important;    margin-left: 15px;
                              margin-right: 35px;">
                                  <li class="aSalesOrderTag f12" [ngClass]="activeCategoryOrderTab==1?'active':''"
                                    (click)="changeactiveCategoryFunctionOrderTab(1)"><a>
                                      Create Purchase Order </a></li>
                                  <li class="aSalesOrderTag f12" [ngClass]="activeCategoryOrderTab==2?'active':''"
                                    (click)="changeactiveCategoryFunctionOrderTab(2)"><a>
                                      Order Items </a></li>


                                </ul>
                              </div>
                              <div class="row">
                                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                  <div class="w-100">
                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                      <div class="panel panel-default">
                                        <div *ngIf="activeCategoryOrderTab==1">
                                          <div class="header-tabs pr-3"
                                            style="margin-left:35px !important;margin-left:35px !important;border:0px !important;background:#fff;">
                                            <h4 class=" common-styling buttonClaerOder f12">
                                              Create Purchase Order
                                            </h4>
                                            <span class="arrow-toggle toggle-arrow detail-created"
                                              data-toggle="collapse" href="#seo-collapse">
                                            </span>
                                            <span class="edit-buttons f12 cleaerOrder"
                                              routerLinkActive="active" (click)="clearOrder()">Clear Order</span>
                                            <span class="edit-buttons f12 buttonClaerOder">Remove
                                              Selected</span>
                                            <span class="edit-buttons  f12 updates-btn buttonClaerOder"
                                              (click)="recalculateOrder()">Recalculate
                                              Order</span>
                                            <span class="edit-buttons f12 updates-btn buttonClaerOder"
                                              (click)="finalizeOrder()">Finalize Order</span>
                                          </div>
                                          <div class="panel-body">
                                            <div class="row">
                                              <form class="w-100" [formGroup]="purchaseForm">
                                                <div
                                                  class="all-users-infomation font-13 font-medium border-top-grey own-user-select"
                                                  style="border:0px !important;">
                                                  <div class="col-lg-12 col-12">
                                                    <div class="form-group table-responsive mb-0">
                                                      <table class="table align-middle">
                                                        <thead>
                                                          <tr style="color: grey;">
                                                            <th
                                                              style="font-size: 12px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              S.No

                                                            </th>
                                                            <th
                                                              style="font-size: 12px;min-width: 155px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              Product Id

                                                            </th>
                                                            <th
                                                              style="font-size: 12px;min-width: 155px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              Quantity

                                                            </th>
                                                            <th
                                                              style="font-size: 12px;min-width: 155px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              Unit Price


                                                            </th>
                                                            <th
                                                              style="font-size: 12px;min-width: 155px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              Comments


                                                            </th>

                                                            <th
                                                              style="font-size: 12px;min-width: 155px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              Desired Delivery Date

                                                            </th>
                                                            <th
                                                              style="font-size: 12px;min-width: 155px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              Ship After Date

                                                            </th>
                                                            <th
                                                              style="font-size: 12px;min-width: 155px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              Ship Before Date


                                                            </th>
                                                            <th
                                                              style="font-size: 12px;min-width: 155px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              Reserve After Date


                                                            </th>
                                                            <th
                                                              style="font-size: 12px;min-width: 155px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              Item Type


                                                            </th>


                                                            <th
                                                              style="font-size: 12px;min-width: 155px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              Item Total


                                                            </th>
                                                            <th
                                                              style="font-size: 12px;min-width: 155px;padding: 14px 20px !important;color:#015fa5 !important;">
                                                              Action


                                                            </th>



                                                          </tr>
                                                        </thead>
                                                        <tbody>

                                                          <tr class="align-bottom"
                                                            *ngFor="let product of orderItems;let i = index;">
                                                            <td style=" color: black;
                                                      font-weight: 100;min-width: 155px;
                                                      font-size: 12px;">

                                                              {{i+1}}

                                                            </td>
                                                            <td style=" color: black;
                                                      font-weight: 100;min-width: 155px;
                                                      font-size: 12px;">
                                                              {{product.productId}}

                                                            </td>
                                                            <td style=" color: black;
                                                      font-weight: 100;min-width: 155px;
                                                      font-size: 12px;">
                                                              {{product.quantity}}
                                                            </td>
                                                            <td style=" color: black;
                                                      font-weight: 100;min-width: 155px;
                                                      font-size: 12px;">
                                                              {{product.unitPrice}}
                                                            </td>
                                                            <td style=" color: black;
                                                      font-weight: 100;min-width: 155px;
                                                      font-size: 12px;">
                                                              {{product.commonComment}}
                                                            </td>
                                                            <td style=" color: black;
                                                        font-weight: 100;min-width: 155px;
                                                        font-size: 12px;">
                                                              <!--   {{product.quantity}} -->
                                                            </td>
                                                            <td style=" color: black;
                                                      font-weight: 100;min-width: 155px;
                                                      font-size: 12px;">
                                                              {{product.shipAfterDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                            </td>
                                                            <td style=" color: black;
                                                      font-weight: 100;min-width: 155px;
                                                      font-size: 12px;">
                                                              {{product.shipBeforeDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                            </td>
                                                            <td style=" color: black;
                                                      font-weight: 100;min-width: 155px;
                                                      font-size: 12px;">
                                                              <!--   US${{product.itemTotal}} -->
                                                            </td>
                                                            <td style=" color: black;
                                                      font-weight: 100;min-width: 155px;
                                                      font-size: 12px;">
                                                              <!--   US${{product.itemTotal}} -->
                                                            </td>


                                                            <td style=" color: black;
                                                      font-weight: 100;min-width: 155px;
                                                      font-size: 12px;">
                                                              {{product.itemTotal}}
                                                            </td>
                                                            <td style=" color: black;
                                                        font-weight: 100;min-width: 155px;
                                                        font-size: 12px;">

                                                            </td>

                                                          </tr>

                                                          <tr class="align-bottom">
                                                            <td>

                                                            </td>
                                                            <td style="min-width: 155px;">

                                                              <p-dropdown filter="true" [options]="products"
                                                                placeholder="Select Product Id" name="product"
                                                                formControlName="productId">
                                                              </p-dropdown>

                                                            </td>
                                                            <td style="min-width: 155px;">
                                                              <input type="text" class="form-control"
                                                                placeholder="Enter Quantity" formControlName="quantity">
                                                            </td>
                                                            <td style="min-width: 155px;">

                                                            </td>
                                                            <td style="min-width: 155px;">
                                                              <textarea maxlength="1500" [rows]="2" [cols]="30"
                                                                style="width: 105%;" formControlName="itemComment"
                                                                autoResize="autoResize" width="30px"></textarea>
                                                            </td>
                                                            <td style="min-width: 155px;">
                                                              <p-calendar appendTo="body" showSeconds="true"
                                                                placholder="Select Date" showTime="true"
                                                                formControlName="itemDesiredDeliveryDate"
                                                                hourFormat="24" dateFormat="dd.mm.yy"></p-calendar>
                                                            </td>
                                                            <td style="min-width: 155px;">
                                                              <p-calendar appendTo="body" showSeconds="true"
                                                                placholder="Select Date" showTime="true"
                                                                formControlName="shipAfterDate" hourFormat="24"
                                                                dateFormat="dd.mm.yy">
                                                              </p-calendar>
                                                            </td>
                                                            <td style="min-width: 155px;">
                                                              <p-calendar appendTo="body" showSeconds="true"
                                                                placholder="Select Date" showTime="true"
                                                                formControlName="shipBeforeDate" hourFormat="24"
                                                                dateFormat="dd.mm.yy">
                                                              </p-calendar>
                                                            </td>
                                                            <td style="min-width: 155px;">
                                                              <p-calendar appendTo="body" showSeconds="true"
                                                                placholder="Select Date" showTime="true"
                                                                formControlName="reserveAfterDate" hourFormat="24"
                                                                dateFormat="dd.mm.yy">
                                                              </p-calendar>
                                                            </td>
                                                            <td style="min-width: 155px;">
                                                              <p-dropdown filter="true" [options]="[]"></p-dropdown>
                                                            </td>


                                                            <td style="min-width: 155px;">

                                                            </td>
                                                            <td style="min-width: 155px;">
                                                              <button type="submit" (click)="placeOrder()"
                                                                class="btn btn-secondary submit-btn">Add To
                                                                Order</button>
                                                            </td>
                                                          </tr>


                                                        </tbody>
                                                      </table>

                                                    </div>
                                                  </div>
                                                </div>


                                              </form>
                                            </div>
                                          </div>
                                        </div>

                                        <div *ngIf="activeCategoryOrderTab==2">
                                          <div class="header-tabs">
                                            <h4 class=" common-styling" style="font-size:82%!important;">
                                              Order Items
                                            </h4>

                                          </div>
                                          <div class="panel-body">
                                            <div class="row">
                                              <form class="w-100">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                  <p class="col-lg-12 mb-0 col-12"
                                                    style="color:#65787e;font-size:14px;">
                                                    No order
                                                    items to display : <span class="text-parimary">{{orderItems.length ?
                                                      orderItems.length :0}}</span></p>
                                                </div>
                                                <ng-template ngFor let-order [ngForOf]="orderItems" let-index="index">
                                                  <div class="row">
                                                    <div class="col-md-9 d-flex align-items-center">
                                                      <div
                                                        class=" justify-content-start submit-btn-sales main-submit-button"
                                                        style="padding-left:15px;margin-top:-8px">
                                                        <button type="submit"
                                                          class="btn btn-secondary submit-btn">{{order.productId}}</button>
                                                      </div>
                                                      <div class="col-lg-3 col-12">
                                                        <div class="form-group">
                                                          <input type="text" class="form-control"
                                                            placeholder="Enter Name" [value]="order.description">
                                                        </div>
                                                      </div>
                                                      <div class="col-lg-4 col-12">
                                                        <div class="form-group">
                                                          <input type="text" class="form-control" placeholder="Quantity"
                                                            [value]="order.quantity">
                                                        </div>
                                                      </div>
                                                      <div class="col-lg-3 col-12">
                                                        <div class="form-group">
                                                          <input type="text" class="form-control"
                                                            placeholder="Enter Price" [value]="order.unitPrice">
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-3 d-flex justify-content-end">
                                                      <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <p class="col-lg-12 mb-0 col-12"
                                                          style="color:#65787e;font-size:12px;">
                                                          US${{order.orderAmount}} US${{order.itemTotal}}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div class="row">
                                                    <div class="col-md-9 d-flex align-items-center">
                                                      <div
                                                        class=" justify-content-start submit-btn-sales main-submit-button"
                                                        style="padding-left:15px;margin-top:-8px">
                                                        <button type="submit"
                                                          class="btn btn-secondary  submit-btn">Quick
                                                          Loop</button>
                                                        <div
                                                          class="all-users-infomation font-13 font-medium own-user-select">
                                                          <p class="col-lg-12 mb-0 col-12"
                                                            style="color:#65787e;font-size:14px;">
                                                            Associated Products</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-3 d-flex">

                                                    </div>
                                                  </div>
                                                  <ng-template ngFor let-productAssociate
                                                    [ngForOf]="order.orderAssociatedProducts" let-j="index">
                                                    <div class="row">
                                                      <div class="col-md-9 d-flex align-items-center">
                                                        <div
                                                          class=" justify-content-start submit-btn-sales main-submit-button"
                                                          style="padding-left:15px;margin-top:-8px">
                                                          <button type="submit"
                                                            class="btn btn-secondary submit-btn">{{productAssociate.productId}}</button>
                                                          <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <p class="col-lg-12 mb-0 col-12"
                                                              style="color:#65787e;font-size:14px;">
                                                              {{productAssociate.description}}(Also Bought)</p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="col-md-3 d-flex">

                                                      </div>
                                                    </div>
                                                  </ng-template>



                                                </ng-template>


                                                <div class="row">
                                                  <div class="col-md-9 d-flex align-items-center">
                                                  </div>
                                                  <div class="col-md-3 d-flex justify-content-end">
                                                    <div
                                                      class="all-users-infomation font-13 font-medium own-user-select">
                                                      <p class="col-lg-12  col-12"
                                                        style="text-align:right;color:#65787e;margin-bottom:5px;font-size:12px;">
                                                        Sub
                                                        Total:
                                                        US${{totalAmount?.orderSubTotal}}</p>
                                                      <p class="col-lg-12 col-12"
                                                        style="text-align:right;color:#65787e;margin-bottom:5px;font-size:12px;">
                                                        Adjustment - Promotion:
                                                        -US${{totalAmount?.orderAdjustment.adjustmentAmount}}</p>
                                                      <p class="col-lg-12 col-12"
                                                        style="text-align:right;color:#65787e;margin-bottom:5px;font-size:12px;">
                                                        Cart
                                                        Total:
                                                        US${{totalAmount?.cartTotal}}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategoryValueMain==6">
                          <div class="container-fluid">
                            <div class="container-fluid">
                              <ul class="tabbing-section  tabbing-accordians paddingSales">
                                <li class="purchaseLi" [ngClass]="activeCategoryTerm==1?'active':''"><a>
                                    Purchase Order : Order Entry Order Terms</a></li>



                              </ul>
                            </div>
                            <div class="row">
                              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                <div class="w-100">
                                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                    <div class="panel panel-default">
                                      <div *ngIf="activeCategoryTerm==1">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="font-size: 78%!important;">
                                            Purchase Order : Order Entry Order Terms
                                          </h4>
                                          <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                                            href="#seo-collapse">
                                          </span>
                                          <span class="header-labels continue-btn cursor-pointer liAPur"
                                            routerLinkActive="active" *ngIf="isSearchPage" (click)="isSearchPage=false">
                                            Create</span>
                                          <span class="edit-buttons spanACard" routerLinkActive="active"
                                            (click)="orderTabRefresh()"> Order Items</span>
                                          <span class="edit-buttons spanACard">Shipping</span>&nbsp;
                                          <span class="edit-buttons spanACard">Options</span>&nbsp;
                                          <span class="edit-buttons spanACard">Order Terms</span>
                                          <span class="edit-buttons spanACard">Payment </span>&nbsp;
                                          <span class="edit-buttons spanACard">Parties</span>&nbsp;




                                          <span class="edit-buttons spanACard">Review Order</span>&nbsp;
                                          <span class="edit-buttons spanACard" routerLinkActive="active"
                                            (click)="navigateToPayment()"> Continue</span>


                                        </div>
                                        <div class="panel-body">
                                          <div class="mt-2">
                                            <div class="">
                                              <div class="row">
                                                <ng-container *ngIf="!isSearchPage;else searchPage">
                                                  <form class="w-100" [formGroup]="orderTermForm">
                                                    <div
                                                      class="all-users-infomation font-13 font-medium own-user-select">
                                                      <div class="col-lg-4 col-12">
                                                        <div class="form-group">
                                                          <label class="common-labels">Term Type
                                                          </label>
                                                          <p-dropdown [options]="orderTermType"
                                                            placeholder="Select Term Type" name="term"
                                                            formControlName="termTypeId"></p-dropdown>
                                                        </div>
                                                      </div>
                                                      <div class="col-lg-4 col-12">
                                                        <div class="form-group">
                                                          <label class="common-labels">Term Value
                                                          </label>
                                                          <input type="text" class="form-control"
                                                            formControlName="termValue" placeholder="Enter Name">
                                                        </div>
                                                      </div>
                                                      <div class="col-lg-4 col-12">
                                                        <div class="form-group">
                                                          <label class="common-labels">Term Days
                                                          </label>
                                                          <input type="text" class="form-control"
                                                            formControlName="termDays" placeholder="Enter Term Days">
                                                        </div>
                                                      </div>
                                                      <div class="col-lg-4 col-12">
                                                        <div class="form-group">
                                                          <label class="common-labels">Text Value
                                                          </label>
                                                          <input type="text" class="form-control"
                                                            formControlName="textValue" placeholder="Enter Text Value">
                                                        </div>
                                                      </div>
                                                      <div class="col-lg-4 col-12">
                                                        <div class="form-group">
                                                          <label style="color: #65787E !important;"
                                                            for="exampleInputEmail1">Description</label>
                                                          <div>
                                                            <textarea name="" id="exampleInputEmail1"
                                                              class="form-control" cols="30" rows="10"
                                                              formControlName="description"></textarea>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="col-lg-12 col-12 main-submit-button">
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                          (click)="submit()">{{editMode ? 'Update' :'Add'}}</button>
                                                      </div>
                                                    </div>
                                                  </form>
                                                </ng-container>
                                                <ng-template #searchPage>
                                                  <div class="card own-account-table">
                                                    <p-table [value]="orderTerms" [paginator]="true" [rows]="rows"
                                                      scrollWidth="100%"
                                                      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                      [totalRecords]="total" [scrollable]="true"
                                                      (sortFunction)="customSort($event)" [customSort]="true">
                                                      <ng-template pTemplate="header">
                                                        <tr>
                                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                            <div class="checkbox-align">
                                                              <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                  <div class="ui-helper-hidden-accessible"><input
                                                                      type="checkbox" name="undefined"
                                                                      value="undefined">
                                                                  </div>
                                                                  <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                  </div>
                                                                </div>
                                                                <!---->
                                                              </p-checkbox>
                                                              Term Type <p-sortIcon field="code"></p-sortIcon>
                                                            </div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                              value="" autocomplete="off"
                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                              role="textbox" aria-disabled="false"
                                                              aria-readonly="false">
                                                          </th>
                                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">Term
                                                            Value
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                              value="" autocomplete="off"
                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                              role="textbox" aria-disabled="false"
                                                              aria-readonly="false">
                                                          </th>
                                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                            Term Days
                                                            <p-sortIcon field="category">
                                                            </p-sortIcon>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                              value="" autocomplete="off"
                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                              role="textbox" aria-disabled="false"
                                                              aria-readonly="false">
                                                          </th>
                                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                            Text Value <p-sortIcon field="quantity">
                                                            </p-sortIcon>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                              value="" autocomplete="off"
                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                              role="textbox" aria-disabled="false"
                                                              aria-readonly="false">
                                                          </th>
                                                          <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                            Action
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                              value="" autocomplete="off"
                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                              role="textbox" aria-disabled="false"
                                                              aria-readonly="false">
                                                          </th>
                                                        </tr>
                                                      </ng-template>
                                                      <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                          <td [ngStyle]="{'width':'190px'}">
                                                            <div class="checkbox-align">
                                                              <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                  <div class="ui-helper-hidden-accessible"><input
                                                                      type="checkbox" name="undefined"
                                                                      value="undefined">
                                                                  </div>
                                                                  <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                  </div>
                                                                </div>
                                                              </p-checkbox>
                                                              <span class="account-button">{{product.termTypeId}}
                                                              </span>
                                                            </div>
                                                          </td>
                                                          <td [ngStyle]="{'width':'190px'}">{{product.termValue}}</td>
                                                          <td [ngStyle]="{'width':'190px'}">{{product.termDays}}</td>
                                                          <td [ngStyle]="{'width':'190px'}">{{product.textValue}}</td>
                                                          <td [ngStyle]="{'width':'190px'}">{{product.description}}</td>
                                                          <td [ngStyle]="{'width':'190px'}">
                                                            <fa-icon [icon]="faEdit" class="account-button"
                                                              (click)="makeOrderTermEditable(product.termIndex)">
                                                            </fa-icon>
                                                            <fa-icon [icon]="faTrash" class="text-danger cursor-pointer"
                                                              (click)="deleteOrderTerm(product.termIndex)"></fa-icon>
                                                          </td>
                                                        </tr>
                                                      </ng-template>
                                                      <ng-template pTemplate="emptymessage" let-columns>
                                                        <tr>
                                                          <td>
                                                            <span class="text-center">
                                                              <h4 class="ml-2">No Record Found</h4>
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                      View per page </p>
                                                  </div>
                                                </ng-template>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategoryValueMain==4">
                          <div>
                            <div class="container-fluid">
                              <div class="container-fluid">
                                <ul class="tabbing-section  tabbing-accordians paddingSales">
                                  <li class="aSalesOrderTag" [ngClass]="activeCategoryShip==1?'active':''"
                                    (click)="changeactiveCategoryShiping(1)"><a>
                                      Purchase Order : Order Entry Ship-To Settings </a></li>
                                  <li class="aSalesOrderTag" [ngClass]="activeCategoryShip==2?'active':''"
                                    (click)="changeactiveCategoryShiping(2)"><a>
                                      Ship to Another Party </a></li>


                                </ul>
                              </div>
                              <div class="row">
                                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                  <div class="w-100">
                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                      <div class="panel panel-default">
                                        <div *ngIf="activeCategoryShip==1">
                                          <div class="header-tabs">
                                            <h4 class=" common-styling spanACard">
                                              Purchase Order : Order Entry Ship-To Settings
                                            </h4>
                                            <span class="arrow-toggle toggle-arrow detail-created"
                                              data-toggle="collapse" href="#seo-collapse">
                                            </span>
                                            <span class="edit-buttons orderItemTab"
                                              routerLinkActive="active" (click)="orderTabRefresh()">Order Items</span>
                                            <span class="edit-buttons spanACard"
                                              routerLinkActive="active">Shipping</span>&nbsp;
                                            <span class="edit-buttons spanACard"
                                              routerLinkActive="active">Options</span>&nbsp;
                                            <span class="edit-buttons spanACard">Order Terms</span>
                                            <span class="edit-buttons spanACard"> Payment</span>&nbsp;
                                            <span class="edit-buttons spanACard">Parties</span>&nbsp;
                                            <span class="edit-buttons spanACard">Review Order</span>&nbsp;
                                            <span class="edit-buttons spanACard" routerLinkActive="active"
                                              (click)="finalizeOrderShip()">Continue</span>





                                          </div>
                                          <div class="panel-body">
                                            <div class="d-flex justify-content-end">
                                              <div class="col-lg-4 col-12 main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                  routerLinkActive="active" routerLink="/order/purchase-order-options">
                                                  Create New Ship Group
                                                </button>
                                              </div>
                                              <div class="main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                  routerLinkActive="active"
                                                  routerLink="/order/purchase-shipping-address">
                                                  Create New Shipping Address
                                                </button>
                                              </div>
                                            </div>
                                            <div class="mt-2">
                                              <div class="">
                                                <div class="row">
                                                  <ng-template ngFor let-address [ngForOf]="addressList"
                                                    let-index="index">
                                                    <form class="w-100 form-border">
                                                      <h2 class="col-lg-12 col-12 address-heading">Ship Group Nbr
                                                        {{address.shipGroupNumber}}</h2>
                                                      <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">

                                                        <ng-template ngFor let-po [ngForOf]="address.shippingAddresses"
                                                          let-i="index">
                                                          <h4 class="col-lg-12 col-12 address-heading">{{po.facility}}
                                                          </h4>
                                                          <div
                                                            class="col-lg-12 col-12 d-flex align-items-center flex-wrap"
                                                            style="margin-top:10px;">
                                                            <div class="w-100">
                                                              <p-checkbox name="selectContacts" [id]="i+'checkbox'"
                                                                styleClass="custom-checkbox"
                                                                [value]="po.address.contactMechId"
                                                                (onChange)="setShipping($event,po.address.contactMechId,po.facility)">
                                                              </p-checkbox>
                                                              <p class="mb-0 d-inline-block"
                                                                style="color: #65787e;font-size: 13px;font-weight:400">
                                                                <span style="font-weight:700;">To:
                                                                </span>{{po.address.toName}}
                                                              </p>
                                                            </div>
                                                            <div style="color: #65787e;
                                              font-size: 13px;
                                              font-weight: 400;">
                                                              <span class="address-values"><span
                                                                  style="font-weight:700">Attn
                                                                  :</span>
                                                                {{po.address.attnName}}
                                                              </span>
                                                              <span class="address-values">
                                                                {{po.address.address1}}
                                                              </span>
                                                              <span
                                                                class="address-values">{{po.address.address2}}</span>
                                                              <span class="address-values">{{po.address.city}}</span>
                                                              <span
                                                                class="address-values">{{po.address.postalCode}}</span>
                                                              <span
                                                                class="address-values">{{po.address.countryGeoId}}</span>
                                                            </div>
                                                          </div>
                                                        </ng-template>
                                                      </div>


                                                    </form>
                                                  </ng-template>

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div *ngIf="activeCategoryShip==2">
                                          <div class="header-tabs">
                                            <h4 class=" common-styling spanACard">
                                              Ship to Another Party
                                            </h4>

                                          </div>
                                          <div class="panel-body">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="col-lg-4 col-12">
                                                <div class="form-group">
                                                  <label class="common-labels">Party Id
                                                  </label>
                                                  <p-dropdown [options]="partyIds" placeholder="Select Party Id"
                                                    [(ngModel)]="partyIdValue" [ngModelOptions]="{standAlone:true}">
                                                  </p-dropdown>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button"><button type="submit"
                                                class="btn btn-secondary submit-btn">Continue</button></div>


                                          </div>
                                        </div>

                                      </div>

                                    </div>
                                    <!--Promotion/Coupon Codes-->

                                    <!--  Promotion/Coupon Codes -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategoryValueMain==5">
                          <div class="container-fluid">
                            <div class="container-fluid">
                              <ul class="tabbing-section  tabbing-accordians paddingSales">
                                <li class="aSalesOrderTag" [ngClass]="activeCategoryOptionSetting==1?'active':''"><a>
                                    Purchase Order : Order Option Settings </a></li>



                              </ul>
                            </div>
                            <div class="row">
                              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                <div class="w-100">
                                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                    <div class="panel panel-default">
                                      <div *ngIf="activeCategoryOptionSetting==1">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling spanACard">
                                            Puchase Order : Order Option Settings
                                          </h4>
                                          <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                                            href="#seo-collapse">
                                          </span>
                                          <span class="edit-buttons pOrdrSetting"
                                            routerLinkActive="active" (click)="orderTabRefresh()"> Order Items</span>
                                          <span class="edit-buttons spanACard"
                                            routerLinkActive="active">Shipping</span>&nbsp;
                                          <span class="edit-buttons spanACard">Options</span>&nbsp;
                                          <span class="edit-buttons spanACard">Order Terms</span>
                                          <span class="edit-buttons spanACard">Payment </span>&nbsp;
                                          <span class="edit-buttons spanACard">Parties</span>&nbsp;
                                          <span class="edit-buttons spanACard">Review Order</span>



                                          <span class="edit-buttons spanACard" routerLinkActive="active"
                                            (click)="finalizeOrderOption()"> Continue</span>



                                        </div>
                                        <div class="panel-body">
                                          <div class="mt-2">
                                            <div class="">
                                              <div class="row">
                                                <form class="w-100">
                                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-4 col-12">
                                                      <div class="form-group">
                                                        <label class="common-labels">Internal Note
                                                        </label>
                                                        <input type="text" class="form-control"
                                                          [(ngModel)]="notes.internalNote"
                                                          [ngModelOptions]="{standalone:true}" placeholder="Enter Note">
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-4 col-12">
                                                      <div class="form-group">
                                                        <label class="common-labels">Shipping Notes
                                                        </label>
                                                        <input type="text" class="form-control"
                                                          [(ngModel)]="notes.shippingNote"
                                                          [ngModelOptions]="{standalone:true}"
                                                          placeholder="Enter Shipping Notes">
                                                      </div>
                                                    </div>
                                                  </div>
                                                </form>
                                              </div>
                                              <h3 class="top-heading">Ship Group Nbr 1</h3>
                                              <h4 class="detail-heading">Shipment Method
                                              </h4>
                                              <div class="radios-sections">
                                                <ng-template ngFor let-option [ngForOf]="carrierShipments"
                                                  let-index="index">
                                                  <div class="col-lg-9 pl-0 col-12">
                                                    <div class="form-group  d-flex radio-boxes-wrapper">
                                                      <input type="radio" [id]="index+'Guaranteed'" name="radio"
                                                        (change)="setOption(option.shipmentMethodTypeId,option.partyId,option.shippingEst)">
                                                      <label class="common-labels"
                                                        [for]="index+'Guaranteed'">{{option.partyId}}
                                                        {{option.description}} - US${{option.shippingEst}}</label>
                                                    </div>
                                                  </div>
                                                </ng-template>

                                                <h4 class="detail-heading">Ship all at once, or 'as available'?</h4>
                                                <div class="col-lg-9 pl-0 col-12">
                                                  <div class="form-group d-flex radio-boxes-wrapper">
                                                    <input type="radio" id="until" name="radio-btns" [value]="false"
                                                      [(ngModel)]="shipmentCustom.shipAtOnce"
                                                      [ngModelOptions]="{standalone:true}">
                                                    <label class="common-labels" for="until">Please wait until the
                                                      entire
                                                      order is ready
                                                      before shipping.</label>
                                                  </div>
                                                </div>
                                                <div class="col-lg-9 pl-0 col-12">
                                                  <div class="form-group d-flex radio-boxes-wrapper">
                                                    <input type="radio" id="ship items" name="radio-btns" [value]="true"
                                                      [(ngModel)]="shipmentCustom.shipAtOnce"
                                                      [ngModelOptions]="{standalone:true}">
                                                    <label class="common-labels" for="ship items">Please ship items I
                                                      ordered as they
                                                      become available (you may incur additional shipping charges). -
                                                      US$0.00</label>
                                                  </div>
                                                </div>
                                                <div class="col-lg-4 pl-0 col-12">
                                                  <div class="form-group">
                                                    <label style="color: #65787E !important;"
                                                      for="exampleInputEmail1">Ship
                                                      Before Date
                                                    </label>
                                                    <div class="">
                                                      <p-calendar showSeconds="true" placeholder="Select Date"
                                                        showTime="true" hourFormat="24" dateFormat="dd.mm.yy"
                                                        [(ngModel)]="shipmentCustom.shipDateBefore"
                                                        [ngModelOptions]="{standalone:true}"></p-calendar>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-lg-4 pl-0 col-12">
                                                  <div class="form-group">
                                                    <label style="color: #65787E !important;"
                                                      for="exampleInputEmail1">Ship
                                                      After Date
                                                    </label>
                                                    <div class="">
                                                      <p-calendar [(ngModel)]="shipmentCustom.shipDateAfter"
                                                        [ngModelOptions]="{standalone:true}" showSeconds="true"
                                                        placeholder="Select Date" showTime="true" hourFormat="24"
                                                        dateFormat="dd.mm.yy">
                                                      </p-calendar>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-lg-4 pl-0 col-12">
                                                  <div class="form-group">
                                                    <label style="color: #65787E !important;"
                                                      for="exampleInputEmail1">Special
                                                      Instructions</label>
                                                    <div>
                                                      <textarea name="" id="exampleInputEmail1" class="form-control"
                                                        cols="30" [ngModelOptions]="{standalone:true}"
                                                        [(ngModel)]="shipmentCustom.specialInstruction"
                                                        rows="10"></textarea>
                                                    </div>
                                                  </div>
                                                </div>
                                                <h4 class="detail-heading">Is This a Gift? </h4>
                                                <div class="col-lg-9 pl-0 col-12">
                                                  <div class="form-group d-flex radio-boxes-wrapper">
                                                    <input type="radio" id="Yes" name="Yes-btns" value="Yes"
                                                      [(ngModel)]="shipmentCustom.isGift"
                                                      [ngModelOptions]="{standalone:true}">
                                                    <label class="common-labels" for="Yes">Yes</label>
                                                  </div>
                                                </div>
                                                <div class="col-lg-9 pl-0 col-12">
                                                  <div class="form-group d-flex radio-boxes-wrapper">
                                                    <input type="radio" id="No" name="Yes-btns" value="No"
                                                      [(ngModel)]="shipmentCustom.isGift"
                                                      [ngModelOptions]="{standalone:true}">
                                                    <label class="common-labels" for="No">No</label>
                                                  </div>
                                                </div>

                                                <div class="col-lg-4 pl-0 col-12">
                                                  <div class="form-group">
                                                    <label style="color: #65787E !important;"
                                                      for="exampleInputEmail1">Gift
                                                      Message</label>
                                                    <div>
                                                      <textarea name="" id="exampleInputEmail1" class="form-control"
                                                        cols="30" [(ngModel)]="shipmentCustom.giftMessage"
                                                        [ngModelOptions]="{standalone:true}" rows="10"></textarea>
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

                <div class="row pr-0">

                  <div class="col-md-6 pr-0" style="padding-left:15px !important;">
                    <div class="col-md-12 p-0">
                      <div class="w3-card-4 classCardB px-3" style="padding-top:1px !important;">
                        <div class="container-fluid">
                          <ul class="tabbing-section tabbing-accordians" style="background: #fff!important;
                      margin-top: 0%;">

                            <li class="aSize" [ngClass]="activeCategoryValue==2?'active':''">
                              Parties</li>
                          </ul>
                        </div>
                        <div class="container-fluid">
                          <div class="container-fluid">
                            <ul class="tabbing-section  tabbing-accordians paddingSales"
                              style="background: #fff!important;">
                              <li class="aSalesOrderTag" [ngClass]="activeCategoryParty==1?'active':''"><a>
                                  Purchase Order : Additional Party Entry </a></li>



                            </ul>
                          </div>
                          <div class="row">
                            <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                              <div class="w-100">
                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                  <div class="panel panel-default">
                                    <div *ngIf="activeCategoryParty==1">
                                      <div class="header-tabs">
                                        <h4 class=" common-styling spanACard" style="margin-left: 1%;">
                                          Purchase Order : Additional Party Entry
                                        </h4>
                                        <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                                          href="#seo-collapse">
                                        </span>
                                        <span class="edit-buttons aParty" routerLinkActive="active"
                                          (click)="orderTabRefresh()"> Order Items</span>
                                        <span class="edit-buttons spanACard"
                                          routerLinkActive="active">Shipping</span>&nbsp;
                                        <span class="edit-buttons spanACard">Options</span>&nbsp;
                                        <span class="edit-buttons spanACard">Order Terms</span>
                                        <span class="edit-buttons spanACard">Payment</span>&nbsp;
                                        <span class="edit-buttons spanACard"> Parties</span>&nbsp;
                                        <span class="edit-buttons spanACard"> Reviews Order</span>
                                        <span class="edit-buttons spanACard" routerLinkActive="active"
                                          (click)="finalizeOrderParty()"> Continue</span>



                                      </div>
                                      <div class="panel-body pb-0" style="padding: 12px;">
                                        <div class="mt-2">
                                          <div class="">
                                            <h4 class="detail-heading" style="color: initial;">1) Select type of party
                                              to associate to order :
                                            </h4>
                                            <div class="radios-sections">
                                              <div class="col-lg-9 pl-0 col-12">
                                                <div class="form-group  d-flex radio-boxes-wrapper">
                                                  <input type="radio" id="Guaranteed" name="radio" value="Guaranteed"
                                                    (click)="setPartyId(true)">
                                                  <label class="common-labels" for="Guaranteed">Person</label>
                                                </div>
                                              </div>
                                              <div class="col-lg-9 pl-0 col-12">
                                                <div class="form-group d-flex radio-boxes-wrapper">
                                                  <input type="radio" id="Air" name="radio" value="Air"
                                                    (click)="setPartyId(false)">
                                                  <label class="common-labels" for="Air">Group</label>
                                                </div>
                                              </div>
                                              <div class="col-lg-9 pl-0 col-12">
                                                <div class="form-group d-flex radio-boxes-wrapper">
                                                  <input type="radio" id="Ground" name="radio" value="Ground"
                                                    (click)="setPartyId(false)">
                                                  <label class="common-labels" for="Ground">I do not wish to add
                                                    additional parties.
                                                  </label>
                                                </div>
                                              </div>
                                              <ng-container *ngIf="isParty">
                                                <h4 class="detail-heading">2) Find Order :</h4>
                                                <div class="col-lg-4 col-12">
                                                  <div class="form-group">
                                                    <label class="common-labels identifier-label">Identifier
                                                    </label>
                                                    <p-dropdown [options]="partyIds" filter="true"
                                                      [(ngModel)]="additionalPartyForm.additionalPartyId"
                                                      [ngModelOptions]="{standAlone:true}"></p-dropdown>
                                                  </div>
                                                </div>
                                                <div class="col-lg-12 col-12 main-submit-button">
                                                  <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="isRole=true">Apply</button>
                                                </div>
                                              </ng-container>
                                              <ng-container *ngIf="isRole">
                                                <h4 class="detail-heading">3) Select Role :</h4>
                                                <div class="col-lg-4 col-12">
                                                  <div class="form-group">
                                                    <label class="common-labels identifier-label">Role
                                                    </label>
                                                    <p-dropdown [options]="roleValue" filter="true"
                                                      [(ngModel)]="additionalPartyForm.additionalRoleTypeId"
                                                      [ngModelOptions]="{standALone:true}"></p-dropdown>
                                                  </div>
                                                </div>
                                                <div class="col-lg-12 col-12 main-submit-button">
                                                  <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="addAdditionalParty()">Add</button>
                                                </div>
                                              </ng-container>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 pr-0 pl-0" style="background:#fff !important;">
                    <div class="col-md-12 p-0">


                      <div class="w3-card-4 classCardB pb-3 " style="margin-right:0px;">
                        <div class="container-fluid pl-0">
                          <ul class="tabbing-section tabbing-accordians" style="background: #fff!important;
                       margin-top: 0%;">

                            <li class="aSize" [ngClass]="activeCategoryValue==2?'active':''">
                              Review Order</li>
                          </ul>
                        </div>
                        <div class="container-fluid">
                          <div class="container-fluid">
                            <ul class="tabbing-section  tabbing-accordians paddingSales">
                              <li class="aSalesOrderTag" [ngClass]="activeCategoryComfirm==1?'active':''"
                                (click)="changeactiveCategoryFunctionConfirm(1)"><a>
                                  Purchase Order : Order Confirmation</a></li>

                              <li class="aSalesOrderTag" [ngClass]="activeCategoryComfirm==3?'active':''"
                                (click)="changeactiveCategoryFunctionConfirm(3)"><a>
                                  Delivery Information </a></li>
                              <li class="aSalesOrderTag" [ngClass]="activeCategoryComfirm==4?'active':''"
                                (click)="changeactiveCategoryFunctionConfirm(4)"><a>
                                  Order Items </a></li>

                            </ul>
                          </div>
                          <div class="row">
                            <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                              <div class="w-100">
                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                  <div class="panel panel-default">
                                    <div *ngIf="activeCategoryComfirm==1">
                                      <div class="header-tabs">
                                        <h4 class=" common-styling spanACard">
                                          Purchase Order : Order Confirmation
                                        </h4>
                                        <a class="arrow-toggle toggle-arrow detail-created port-header tabs-header collapsed"
                                          data-toggle="collapse" href="#Order">
                                        </a>
                                        <span class="edit-buttons confirmOrder" routerLinkActive="active"
                                          (click)="orderTabRefresh()">Order Items</span>
                                        <span class="edit-buttons spanACard">Shipping</span>&nbsp;
                                        <span class="edit-buttons spanACard">Options</span>&nbsp;
                                        <span class="edit-buttons spanACard">Order Terms</span>
                                        <span class="edit-buttons spanACard">Payment</span>&nbsp;
                                        <span class="edit-buttons spanACard"> Parties</span>&nbsp;
                                        <span class="edit-buttons spanACard"> Review Order</span>
                                        <span class="edit-buttons spanACard" routerLinkActive="active"
                                          (click)="processOrder()">Create Order</span>






                                      </div>
                                      <div class="panel-body">
                                        <div class="mt-2">
                                          <div class="">
                                            <div class="">
                                              <div
                                                class="all-users-infomation col-lg-12  col-12 font-13 font-medium own-user-select"
                                                style="padding-left:15px;">
                                                <div class="d-flex flex-column">
                                                  <strong class="common-bold-properties" style="margin-right:10px;">
                                                    Order Name
                                                  </strong>
                                                  <p class="common-regular-properties">
                                                    {{reviewOrderValue?.orderHeaderInfo.orderName}}
                                                  </p>
                                                </div>
                                                <div class="d-flex flex-column">
                                                  <strong class="common-bold-properties" style="margin-right:10px;">
                                                    Order Terms
                                                  </strong>
                                                  <div class="common-regular-properties">
                                                    <table class="table">
                                                      <thead>
                                                        <tr>
                                                          <th scope="col">Term Type</th>
                                                          <th scope="col">Term Value</th>
                                                          <th scope="col">Term Days</th>
                                                          <th scope="col">Text Value</th>
                                                          <th scope="col">Description</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <ng-template ngFor let-term
                                                          [ngForOf]="reviewOrderValue?.orderTerms">
                                                          <tr>
                                                            <th scope="row">{{term.termTypeID}}</th>
                                                            <td>{{term.termValue}}</td>
                                                            <td>{{term.termDays}}</td>
                                                            <td>{{term.textValue}}</td>
                                                            <td>{{term.description}}</td>
                                                          </tr>
                                                        </ng-template>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                                <strong class="common-bold-properties" style="margin-right:10px;">
                                                  Splitting
                                                  Preference </strong>
                                                <p class="common-regular-properties"> N/A
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div *ngIf="activeCategoryComfirm==3">
                                      <div class="header-tabs">
                                        <h4 class=" common-styling spanACard">
                                          Delivery Information
                                        </h4>

                                      </div>
                                      <div class="panel-body a">
                                        <div class="mt-2">
                                          <div class="">
                                            <div class="row m-0">
                                              <div class="card confimation-table own-account-tables">
                                                <p-table [resizableColumns]="true" columnResizeMode="expand"
                                                  scrollWidth="100%" [paginator]="false" [rows]="rows"
                                                  [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true"
                                                  [totalRecords]="total"
                                                  [value]="reviewOrderValue?.orderShippingInformation"
                                                  [scrollable]="true"
                                                  styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                                  <ng-template pTemplate="colgroup" let-columns>
                                                    <colgroup>
                                                      <col *ngFor="let col of offerShowingLineColoumns">
                                                    </colgroup>
                                                  </ng-template>
                                                  <ng-template pTemplate="header">
                                                    <tr class="activity-rows">
                                                      <th id="tableHead" *ngFor="let col of offerShowingLineColoumns">
                                                        <ng-container>
                                                          {{col.header}}
                                                        </ng-container>
                                                      </th>
                                                    </tr>
                                                  </ng-template>
                                                  <ng-template pTemplate="body" let-i="rowIndex" let-activity>
                                                    <tr class="activity-data">
                                                      <td *ngFor="let col of offerShowingLineColoumns">
                                                        <ng-container *ngIf="col.field === 'Destination'">
                                                          <span>{{activity.address.toName}},{{activity.address.address1}},{{activity.address.countryGeoId}},
                                                            {{activity.address.postalCode}}</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="col.field === 'Supplier'">
                                                          <span class="d-flex red-border align-items-center">
                                                            <div class="name_text">
                                                              <a>
                                                                {{activity.supplier}}
                                                              </a>
                                                            </div>
                                                          </span>
                                                        </ng-container>
                                                        <ng-container *ngIf="col.field === 'ShipmentMethod'">
                                                          <span class="d-block align-items-center">
                                                            {{activity.method.description}}
                                                            {{activity.method.shipmentMethodTypeId}}
                                                          </span>
                                                        </ng-container>
                                                        <ng-container *ngIf="col.field === 'Items'"><span>
                                                            {{activity.shipItems[0].item}}</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="col.field === 'Quantity'">
                                                          <span>{{activity.shipItems[0].qty}}</span>
                                                        </ng-container>

                                                      </td>
                                                    </tr>
                                                  </ng-template>
                                                </p-table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div *ngIf="activeCategoryComfirm==4">
                                      <div class="header-tabs">
                                        <h4 class=" common-styling spanACard">
                                          Order Items
                                        </h4>

                                      </div>
                                      <div class="panel-body a">
                                        <div class="mt-2">
                                          <div class="">
                                            <div class="row m-0">
                                              <div class="card confimation-table own-account-tables">
                                                <p-table [resizableColumns]="true" columnResizeMode="expand"
                                                  scrollWidth="100%" [paginator]="false" [rows]="rows"
                                                  [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true"
                                                  [totalRecords]="total" [value]="reviewOrderValue?.orderItemsMap"
                                                  [scrollable]="true"
                                                  styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                                  <ng-template pTemplate="colgroup" let-columns>
                                                    <colgroup>
                                                      <col *ngFor="let col of orderItemsColumns">
                                                    </colgroup>
                                                  </ng-template>
                                                  <ng-template pTemplate="header">
                                                    <tr class="activity-rows">
                                                      <th id="tableHead" *ngFor="let col of orderItemsColumns">
                                                        <ng-container>
                                                          {{col.header}}
                                                        </ng-container>
                                                      </th>
                                                    </tr>
                                                  </ng-template>
                                                  <ng-template pTemplate="body" let-rowData let-i="rowIndex"
                                                    let-activity>
                                                    <tr class="activity-data">
                                                      <td *ngFor="let col of orderItemsColumns">
                                                        <ng-container *ngIf="col.field === 'status'">
                                                          <span>{{activity.itemDescription}}</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="col.field === 'name'">
                                                          <span class="d-flex red-border align-items-center">
                                                            <div class="name_text">
                                                              <a>
                                                                {{activity.quantity}}
                                                              </a>
                                                            </div>
                                                          </span>
                                                        </ng-container>
                                                        <ng-container *ngIf="col.field === 'email'">
                                                          <span class="d-block align-items-center">
                                                            <div class="name_text">
                                                              {{activity.unitPrice}}
                                                            </div>
                                                          </span>
                                                        </ng-container>
                                                        <ng-container *ngIf="col.field === 'phone'"><span>
                                                            {{activity.orderItemAdjustment}}</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="col.field === 'type'">
                                                          <span>{{activity.orderItemSubTotal}}</span>
                                                        </ng-container>
                                                      </td>
                                                    </tr>
                                                  </ng-template>
                                                </p-table>
                                              </div>

                                              <div class="row w-100" style="padding-top:10px;">
                                                <div class="col-md-10 text-right">
                                                  <strong class="common-bold-properties confirm-price-text d-block">Sub
                                                    Total</strong>
                                                  <strong
                                                    class="common-bold-properties  confirm-price-text d-block">Promotion</strong>
                                                  <strong
                                                    class="common-bold-properties confirm-price-text  d-block">Shipping
                                                    and
                                                    Handling</strong>
                                                  <strong
                                                    class="common-bold-properties confirm-price-text  d-block">VAT</strong>
                                                  <br>
                                                  <strong
                                                    class="common-bold-properties confirm-price-text d-block">Grand
                                                    Total</strong>
                                                </div>
                                                <div class="col-md-2 text-right">
                                                  <p class="common-regular-properties confirm-price-text  d-block">
                                                    US${{reviewOrderValue?.orderHeaderInfo.commonTotal}}</p>
                                                  <p class="common-regular-properties  confirm-price-text  d-block">
                                                    -US$0</p>
                                                  <p class="common-regular-properties confirm-price-text d-block">
                                                    US${{reviewOrderValue?.shippingAndHandling}}</p>
                                                  <p class="common-regular-properties confirm-price-text d-block">
                                                    US${{reviewOrderValue?.orderSalesTax}}</p>
                                                  <br>
                                                  <p class="common-regular-properties confirm-price-text d-block">
                                                    US${{reviewOrderValue?.orderGrandTotal}}</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                </div>

                <div class="row mb-5 pb-5 pr-1" style="margin-top:-2%;">

                  <div class="col-md-6 pr-0" style="padding-left:13px !important;">
                    <div class="w3-card-4 classCardB" style="height:100%;margin-right:0px !important;margin-left:2px !important;">
                    </div>
                  </div>
                  <div class="col-md-6  pl-0 pr-0" style="padding-left:0px !important;">
  
                    <div class="w3-card-4 classCardB" style="height:100%;margin-right:-2px !important;">
  
                      <form class="w-100">
                        <div class="all-users-infomation font-13 font-medium own-user-select pt-0 mb-3" style="
                          bottom: 0;
                          left: 25%;">
  
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-4 form-group rightFormBK">
                                <label class="common-labels" style="color:#015fa5 !important;">Subtotal </label>
                              </div>
  
                              <div class="col-lg-4 rightFormnb">
                                <input disabled type="text" class="form-control" placeholder=" ">
                              </div>
                            </div>
                          </div>
  
  
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-4 form-group rightFormBK">
                                <label class="common-labels" style="color:#015fa5 !important;"> Tax </label>
                              </div>
  
                              <div class="col-lg-4 rightFormnb">
                                <input disabled type="text" class="form-control" placeholder=" ">
                              </div>
  
  
                            </div>
                          </div>
  
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-4 form-group rightFormBK ">
                                <label class="common-labels" style="color:#015fa5 !important;">Total </label>
                              </div>
  
                              <div class="col-lg-4 rightFormnb">
                                <input disabled type="text" class="form-control" placeholder=" ">
                              </div>
  
  
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-4 form-group rightFormBK ">
                                <label class="common-labels" style="color:#015fa5 !important;">Amount Due (USD)</label>
                              </div>
  
                              <div class="col-lg-4 rightFormnb">
                                <input disabled type="text" class="form-control" placeholder="  ">
                              </div>
  
  
                            </div>
                          </div>
  
  
                        </div>
                      </form>
  
                    </div>
  
                  </div>
                </div>


              </ng-container>
           
            </div>

          </div>
        </div>
      </div>
    </div>
    <ngx-spinner></ngx-spinner>