import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';

@Component({
    selector: 'app-create-new-item-geo',
    templateUrl: './create-new-item-geo.component.html'
})
export class CreateNewItemGeoComponent implements OnInit {
    agreementType: string;
    activeCategory=1;
    createGeoForm: FormGroup;

    agreement: {
        agreementId: string,
        agreementItemSeqId: string
    };
    geoIds: any[];
    constructor(
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService,
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly  _Router: Router,
        readonly _AccountingApService: AccountingApService
    ) {
        this.geoIds = [];
        this.agreementType = '';
        this.agreement = {
            agreementId: '',
            agreementItemSeqId: ''
        };
        this.createGeoForm = this._FormBuilder.group({
            agreementId: [''],
            agreementItemSeqId: [''],
            geoId: ['', [Validators.required]]
        });
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this._ActivatedRoute.queryParams.subscribe(data => {
            this.agreement = {
                agreementId: data.agreementId,
                agreementItemSeqId: data.agreementItemSeqId
            };
        });
        this.getGeoIds();
    }
    getGeoIds(): void {
        this.spinner.show();
        this._AccountingApService.getAgreementItemGeoIds()
            .then(data => {
                this.spinner.hide();
                this.geoIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.geoId
                    };
                });
            });
           
    }
    submit(): void {
        this.spinner.show();
        this._AccountingApService.createAgreementItemGio({
            ...this.createGeoForm.value, ...this.agreement
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Created');
                    this.spinner.hide();
                    this.createGeoForm.reset();
                    this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
                }
            });
         
    }
    reset(): void {
        this.createGeoForm.reset();
        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}