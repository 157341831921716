import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
@Component({
  selector: 'app-create-foreign-exchange-rate',
  templateUrl: './create-foreign-exchange-rate.component.html',
  styleUrls: ['./create-foreign-exchange-rate.component.css']
})
export class CreateForeignExchangeRateComponent implements OnInit {
  show: boolean;
  uom: any;
  uomArray: any[] = [];
  createRate: FormGroup;
  exchangePurpose: any;
  exchangePurposeArray: any[]=[];
  actualfromdate: string;
  actualTodate: string;
  activeCategory = 2;
  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    
    readonly _FormBuilder: FormBuilder,
  ) {
    this.createRate = this._FormBuilder.group({
     
      conversionFactor: ['',[Validators.required]],
      fromDate:[''],
      organizationPartyId: [''],
      purposeEnumId:[''],
      thruDate: [''],
      uomId: ['',[Validators.required]],
      uomIdTo: ['',[Validators.required]],


    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show = false;
    this.getInitialDataCost();
    this.getRatesInitialData();
  }
  getRatesInitialData() {
    this.spinner.show();
    this.accountsService.getRatesInitialData().subscribe(res => {
       this.exchangePurpose = res.data.exchangePurpose;
       this.spinner.hide();
        for (const value of this.exchangePurpose) {
          this.exchangePurposeArray.push({
            label: value.description,
            value: value.enumId
          })
        } 



    })
   
  }
  getInitialDataCost() {
    this.spinner.show();
    this.accountsService.getInitialDataCost().subscribe(res => {

      this.uom = res.data.uom;
      this.spinner.hide();
      for (const value of this.uom) {
        this.uomArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
    
  }
  cancelSubmit() {
    this.router.navigate(["financial/gl-foreign-exchange-rate"])
  }
  
  onSubmit() {
    this.spinner.show();
    const fromActualdate = this.createRate.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const toActualdate = this.createRate.get('thruDate').value;
    this.actualTodate = this.datePipe.transform(toActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "conversionFactor": this.createRate.value.conversionFactor,
      "fromDate": this.actualfromdate,
      "organizationPartyId": "Company",
      "purposeEnumId": this.createRate.value.purposeEnumId,
      "thruDate": this.actualTodate ,
      "uomId": this.createRate.value.uomId,
      "uomIdTo":this.createRate.value.uomIdTo,
    }
    this.accountsService.updateForeignExchangeRates(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/gl-foreign-exchange-rate"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
