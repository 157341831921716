<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Documents</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategories==8?'active':''" (click)="toLead()"><a>
                                Leads</a></li>
                        <li [ngClass]="activeCategories==2?'active':''" (click)="toContact()"><a>
                                Contact</a></li>
                        <li [ngClass]="activeCategories==1?'active':''" (click)="toAccount()"><a>
                                Account</a></li>
                        <li [ngClass]="activeCategories==3?'active':''" (click)="toOpportunity()"><a>
                                Opportunity</a></li>
                        <li [ngClass]="activeCategories==9?'active':''" (click)="toTasks()"><a>
                                Tasks</a></li>
                        <li [ngClass]="activeCategories==7?'active':''" (click)="toCases()"><a>
                                Cases</a></li>



                        <li [ngClass]="activeCategories==4?'active':''" (click)="toActivities()"><a>
                                Activities Campaign</a></li>

                        <li [ngClass]="activeCategories==10?'active':''" (click)="toForecast()"><a>
                                Forecast </a></li>
                        <li [ngClass]="activeCategories==11?'active':''" (click)="toDocuments()"><a>
                                Documents </a></li>
                        <li [ngClass]="activeCategories==12?'active':''" (click)="toNotes()"><a>
                                Notes </a></li>
                        <li [ngClass]="activeCategories==13?'active':''" (click)="toCalendar()"><a>
                                Calendar </a></li>
                                <li [ngClass]="activeCategories==14?'active':''" (click)="toEmails()"><a>
                                    Emails </a></li>

                        <li [ngClass]="activeCategories==5?'active':''" (click)="toQuotes()"><a>
                                Quotes </a></li>

                        <li [ngClass]="activeCategories==6?'active':''" (click)="toSalesOrder()"><a>
                                Sales order</a></li> 



                        </ul>
                    </div>

                    <div class="">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="w3-card-4 classCardView  mt-2">
                                        <div class="header-tabs pl-2" >

                                            <h4 class=" common-styling hCard">
                                                Documents
                                            </h4>
                                        </div>
                                        <div class="panel-body allUserInfoDetail">
                                            <form [formGroup]="Editform">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-4 col-12">
                                                        <div class="form-group">
                                                            <label for="exampleInputEmail1">Attach Document</label>
                                                            <div class="suppliers-wrapper">

                                                                <input type="file" class="inputField classFile"
                                                                    id="exampleInputEmail1" formControlName="file"
                                                                    aria-describedby="emailHelp" 
                                                                    (change)="fileProgress($event)" accept=""
                                                                    style="padding: 1px 4px;">


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-12">
                                                        <br><br>
                                                        <div class="form-group dropdowns">
                                                            <p-dropdown filter="true" [options]="partyContentArray"
                                                                placeholder="Enter Party Content"
                                                                formControlName="partyContentTypeId"
                                                                [(ngModel)]="partyContentTypeId"
                                                                name="partyContentTypeId"
                                                                (ngModelChange)="onpartyContent(partyContentTypeId)"
                                                                optionlabel="label"
                                                                placeholder="Select Purpose"></p-dropdown>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-12">
                                                        <br><br>
                                                        <div class="form-group">
                                                            <button type="submit" (click)="onSubmit()"
                                                                class="btn btn-outline-secondary">Submit</button>
                                                        </div>
                                                    </div>

                                                </div>


                                            </form>

                                        </div>

                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>