<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of <span
            [ngStyle]="{'color':'#0d3769'}">{{this.taxAuthPartyId}}</span></span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
              flex-wrap: wrap !important;
              display: flex !important;">
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                  Profile</a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                  Preferences</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                  Role(s)</a></li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                  Identification Numbers</a></li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                  Link Party</a></li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategoryFunction(7)"><a>
                  Relationships</a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategoryFunction(8)"><a>
                  Vendor</a></li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategoryFunction(9)"><a>
                  Rates</a></li>
                            <li [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategoryFunction(10)"><a>
                  Party Skills</a></li>
                            <li [ngClass]="activeCategory==11?'active':''" (click)="changeactiveCategoryFunction(11)"><a>
                  Party Content</a></li>
                            <li [ngClass]="activeCategory==12?'active':''" (click)="changeactiveCategoryFunction(12)"><a>
                  Segments</a></li>
                            <li [ngClass]="activeCategory==14?'active':''" (click)="changeactiveCategoryFunction(14)"><a>
                  Classifications</a></li>
                            <li [ngClass]="activeCategory==13?'active':''" (click)="changeactiveCategoryFunction(13)"><a>
                  Resumes</a></li>
                            <li [ngClass]="activeCategory==15?'active':''" (click)="changeactiveCategoryFunction(15)"><a>
                  Contact List</a></li>
                            <li [ngClass]="activeCategory==16?'active':''" (click)="changeactiveCategoryFunction(16)"><a>
                  Employment Applications
                </a></li>
                            <li [ngClass]="activeCategory==17?'active':''" (click)="changeactiveCategoryFunction(17)"><a>
                  Product Store Roles
                </a></li>
                            <li [ngClass]="activeCategory==19?'active':''" (click)="changeactiveCategoryFunction(19)"><a>
                  Fin. History
                </a></li>
                            <li [ngClass]="activeCategory==20?'active':''" (click)="changeactiveCategoryFunction(20)"><a>
                  Shopping List
                </a></li>
                            <li [ngClass]="activeCategory==18?'active':''" (click)="changeactiveCategoryFunction(18)"><a>
                  Tax Infos
                </a></li>



                        </ul>
                    </div>
                    <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium classHead">
                        <div class="submenu">
                            <div>
                                <button _ngcontent-cta-c128="" type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important" routerLinkActive="active" routerLink="/financial/payable/billing-account">Billing Account</button>&nbsp;
                                <button _ngcontent-cta-c128="" type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important" routerLinkActive="active" routerLink="/financial/financial-account">Financial Account</button>&nbsp;
                                <button _ngcontent-cta-c128="" type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important" routerLinkActive="active" routerLink="/partyQuickLink/communications">Communications</button>&nbsp;

                                <button _ngcontent-cta-c128="" type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important" routerLinkActive="active" routerLink="/crm/quotations">Quotes</button>&nbsp;
                                <button _ngcontent-cta-c128="" type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important" routerLinkActive="active" routerLink="/order/order-management">Orders</button>&nbsp;
                                <button _ngcontent-cta-c128="" type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important" routerLinkActive="active" routerLink="/crm/quotations/create-quotes">New Quotes</button>&nbsp;
                                <button _ngcontent-cta-c128="" type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important" routerLinkActive="active" routerLink="/order/create-sales-order">New Order</button>&nbsp;

                            </div>

                        </div>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==18">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Tax Authority Info
                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a (click)="createTaxInfo()">Create  Tax Authority Info</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                                            <div class="form-group" style="width: 100%!important">

                                                                <div class="card own-account-table">
                                                                    <p-table id="table" [value]="TaxInfo" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Tax Auth Geo ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Tax Authority Party
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> From Date Time
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Through Date
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Party Tax ID
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Is Exempt </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Is Nexus </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Action</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                              {{product.getTaxInfo.taxAuthGeoId}}</span>

                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">{{product.getTaxInfo.taxAuthPartyId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">{{product.getTaxInfo.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.getTaxInfo.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.getTaxInfo.partyTaxId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.getTaxInfo.isExempt}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.getTaxInfo.isNexus}}</td>

                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div>

                                                                                        <svg class="hover" (click)="updateTaxInfo(product)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                              <g transform="translate(0 -0.004)">
                                                <g transform="translate(0 1.52)">
                                                  <path
                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                    transform="translate(0 -34.137)" />
                                                </g>
                                                <g transform="translate(4.548 0.004)">
                                                  <g transform="translate(0 0)">
                                                    <path
                                                      d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                      transform="translate(-102.409 -0.004)" />
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>
                                                                                        <svg (click)="deleteTaxInfo(product)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                              <defs>
                                                <style>
                                                  .a {
                                                    fill: #f44336;
                                                  }

                                                  .b {
                                                    fill: #fafafa;
                                                    margin-left: 20px;
                                                  }
                                                </style>
                                              </defs>
                                              <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                              <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                            </svg>
                                                                                    </div>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==20">

                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Shopping Lists


                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a (click)="createPartyEmptyShopping()">Create New Shopping Lists</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <form [formGroup]="shoppingList">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-8 col-12">
                                                                        <div class="form-group">
                                                                            <p-dropdown [options]="ShoppingListArray" filter="true" placeholder="Select Shopping List" formControlName="shoppingList">
                                                                            </p-dropdown>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-lg-4 col-12">
                                                                        <div class="form-group">
                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="editAddShoping()">Edit</button>&nbsp;&nbsp;
                                                                        </div>

                                                                    </div>


                                                                </div>

                                                            </form>
                                                            <br>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==19">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Invoices not yet (fully) applied
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <span class="text-center" style="color: #65787e;">
                                      <h4 class="ml-2">No Record Found
                                      </h4>
                                    </span>
                                                                    </td>
                                                                </tr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Payments not yet (fully) applied
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <span class="text-center" style="color: #65787e;">
                                      <h4 class="ml-2">No Record Found
                                      </h4>
                                    </span>
                                                                    </td>
                                                                </tr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Totals for Company: 0
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12" style="color: #65787e;">Total Sales Invoice</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">$0.00</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12" style="color: #65787e;">Total Payments In</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12"> $0.00</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12" style="color: #65787e;">Total Invoice Not Applied</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">$0.00</span>

                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12" style="color: #65787e;">To be received from: {{this.taxAuthPartyId}}
                                                                        </p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">$0.00</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12" style="color: #65787e;">Total Purchase Invoice</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">$0.00</span>

                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12" style="color: #65787e;">Total Payments Out</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">$0.00</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12" style="color: #65787e;">Total Payment Not Applied</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">$0.00</span>
                                                                    </div>









                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Billing Account
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <span class="text-center" style="color: #65787e;">
                                      <h4 class="ml-2">No Record Found
                                      </h4>
                                    </span>
                                                                    </td>
                                                                </tr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Returns
                                                        </h4>


                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="AllFinanceHistory" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>




                                                                                    Return ID
                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;"> Status ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;"> From Party ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;"> To Party ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-activity>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    {{activity.data.returnId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{activity.data.statusId}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}"> {{activity.data.fromPartyId}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}"> {{activity.data.toPartyId}} </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span class="text-center">
                                          <h4 class="ml-2">No Record Found
                                          </h4>
                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==4">
                                                    <div class="w3-card-4 classCard materialCard" *ngFor="let cost of Preferences">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 1%;">
                                                                Preferences For userLogin: {{cost.userLogins.userLoginId}}
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div style="padding: 15px 20px!important;" class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">

                                                                    <div id="screenlet_1_col" class="screenlet-body">
                                                                        <table cellspacing="0" class="basic-table hover-bar" style="width: 142%!important;">
                                                                            <thead>
                                                                                <tr class="header-row" style="background: #0d3769;">
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <label style="color: white!important;margin-top: 6%;" for="ListPreference_childDescription_o_0" id="ListPreference_childDescription_o_0_title">Preference Group</label>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <label style="color: white!important;margin-top: 6%;" for="ListPreference_description_o_0" id="ListPreference_description_o_0_title">Preference Name</label>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <label style="color: white!important;margin-top: 6%;" for="ListPreference_userPrefValue_o_0" id="ListPreference_userPrefValue_o_0_title">Value</label>
                                                                                    </td>
                                                                                    <td>
                                                                                        <label style="color: white!important;margin-top: 6%;" for="ListPreference_submit_o_0" id="ListPreference_submit_o_0_title">Remove</label>
                                                                                    </td>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr style="padding: 4px 6px !important;
                                    background: #fafafa;
                                    font-size: 13px !important;
                                    color: #383139 !important;">
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                        {{cost.dataList.childDescription}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                        {{cost.dataList.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                        &nbsp;
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                    </td>

                                                                                    <!-- End  Grid Widget - Grid Element  component://party/widget/partymgr/PartyForms.xml#ListPreference -->
                                                                                </tr>
                                                                                <tr class="alternate-row" style="padding: 3px 0px !important;
                                 background: rgba(0,0,0,.05)!important;
                                  font-size: 13px !important;
                                  color: #383139 !important;">
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                        {{cost.userPreferences.userPrefGroupTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                        {{cost.userPreferences.userPrefTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                        &nbsp;
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                    </td>

                                                                                    <!-- End  Grid Widget - Grid Element  component://party/widget/partymgr/PartyForms.xml#ListPreference -->
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <!-- End  Grid Widget - Grid Element  component://party/widget/partymgr/PartyForms.xml#ListPreference -->
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Roles
                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a (click)="createRoleType()">New Role Type|</a> <a (click)="createAddRole()">Add To
                                Role</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="roleList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        RoleType ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Role
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Parent Type ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{activity.roleTypeId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.description}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.parentTypeId}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}" style="cursor: pointer;color: #0d3769;" (click)="deleteRoleTypeId(activity.roleTypeId)">
                                                                                    <a  class="account-button"  routerLinkActive="active"> Remove</a>
                                                                                </td>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==5">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            List Identification Number
                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a data-toggle="modal" data-target="#exampleModalIdentify">Create New Identification Number</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group" style="width: 100%!important;">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="IdentificationList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Identification Type
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>

                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">ID Value
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">Action
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.description}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.identificationData.idValue}}
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <fa-icon [icon]="faEdit" class="text-primary" (click)="updatePartyIdentification(product.identificationData.partyIdentificationTypeId,product.identificationData.idValue)">
                                                                                    </fa-icon>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deletePartyIdentification(product.identificationData.partyIdentificationTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>

                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==6">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Link Party
                                                        </h4>
                                                    </div>
                                                    <div class="create-new-leade mt-2">
                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                <form class="w-100" [formGroup]="createLinkParty">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                            Party ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" placeholder="Enter Party ID" name="Party ID" id="exampleInputEmail1" formControlName="partyIdTo" [options]="partyIdArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail2">
                                            To Party ID</label>
                                                                                </div>


                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" placeholder="Enter To Party ID" name="Party ID" id="exampleInputEmail2" formControlName="partyIdFrom" [options]="partyIdArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </form>
                                                                <div class="main-submit-button" style="margin-left: 56%;">

                                                                    <button type="submit" (click)="setPartyLink();" class="btn btn-secondary submit-btn">Submit</button>




                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==7">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Relationships
                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a (click)="createNewRelationshipType()">New Relationship Type|</a> <a
                                (click)="createAddOtherpartyRelationship()">Add other party relationship</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="RelationshipPartyIdTo" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Party ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> In The Role OF
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Is A
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Of Party
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> In The Role Of
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Through Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Comments
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{activity.data.partyIdTo}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.data.roleTypeIdTo}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.isA}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.data.partyIdFrom}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.data.roleTypeIdFrom}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.data.fromDate|date:'yyyy-MM-dd'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.data.thruDate|date:'yyyy-MM-dd'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{activity.data.comments}} </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg class="hover" (click)="updateAddOtherpartyRelationship(activity)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                            <g transform="translate(0 -0.004)">
                                              <g transform="translate(0 1.52)">
                                                <path
                                                  d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                  transform="translate(0 -34.137)" />
                                              </g>
                                              <g transform="translate(4.548 0.004)">
                                                <g transform="translate(0 0)">
                                                  <path
                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                    transform="translate(-102.409 -0.004)" />
                                                </g>
                                              </g>
                                            </g>
                                          </svg>


                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteOtherRelationship(activity)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==8">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Vendor Information
                                                        </h4>
                                                    </div>
                                                    <div class="create-new-leade mt-2">
                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                <form class="w-100" [formGroup]="createVendor">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group classInputB">
                                                                                    <label for="exampleInputEmail1">
                                            Manifest Company Name</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="email" formControlName="ManifestCompany" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manifest Company name">
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail2">
                                            Manifest Company Title</label>
                                                                                </div>


                                                                                <div class="col-lg-2">
                                                                                    <input type="email" formControlName="CompanyTitle" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manifest Company Title">
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group classInputB">
                                                                                    <label for="exampleInputEmail1">
                                            Manifest Logo Url</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="email" formControlName="logo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manifest Logo Url">
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail2">
                                            Manifest Policies</label>
                                                                                </div>


                                                                                <div class="col-lg-2">
                                                                                    <input type="email" formControlName="policies" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manifest Policies">
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </form>
                                                                <div class="main-submit-button" style="margin-left: 66%;">

                                                                    <button type="submit" (click)="onUpdateVendor();" class="btn btn-secondary submit-btn">Update</button>




                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==9">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Rates
                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a data-toggle="modal" data-target="#exampleModalRate">Create New Rates</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="PartyRate" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Rate Type
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Period Type ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Rate Default Amount
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Default Rate
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Percentage Used
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{activity.partyRateData.rateTypeId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> Rate amount per Hour</td>
                                                                                <td [ngStyle]="{'width':'170px'}"> </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.partyRateData.defaultRate}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.partyRateData.percentageUsed}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">

                                                                                    <span *ngIf="activity.thruDate!='null'">
                                            <svg xmlns="http://www.w3.org/2000/svg" (click)="deletePartRate(activity)"
                                              class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                              <defs>
                                                <style>
                                                  .a {
                                                    fill: #f44336;
                                                  }

                                                  .b {
                                                    fill: #fafafa;
                                                    margin-left: 20px;
                                                  }
                                                </style>
                                              </defs>
                                              <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                              <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                            </svg></span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==10">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Party SKills
                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a data-toggle="modal" data-target="#exampleModalSkill">Create New Party SKills</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="skillList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Skill Type ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Years Experience
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Rating
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Skill Level
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.description}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.yearsExperience}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.rating}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.skillLevel}} </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg class="hover" (click)="onUpdateSkill(product.skillTypeId
                                      ,product.rating,product.skillLevel,product.yearsExperience)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                            <g transform="translate(0 -0.004)">
                                              <g transform="translate(0 1.52)">
                                                <path
                                                  d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                  transform="translate(0 -34.137)" />
                                              </g>
                                              <g transform="translate(4.548 0.004)">
                                                <g transform="translate(0 0)">
                                                  <path
                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                    transform="translate(-102.409 -0.004)" />
                                                </g>
                                              </g>
                                            </g>
                                          </svg>
                                                                                    <svg (click)="onDeleteSkill(product.partyId,product.skillTypeId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==11">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Content List
                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a data-toggle="modal" data-target="#exampleModalAttach">Create New Content List</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="PartyContentData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Content Id
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Content Name
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Content Type
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Party Content Type
                                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Status
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}" (click)="contactsDetail()">
                                                                                    <div class="checkbox-align">

                                                                                        {{product.data.contentId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.data.contentName}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.data.contentTypeId}}</td>

                                                                                <td [ngStyle]="{'width':'170px'}">{{product.data.partyContentTypeId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.data.statusId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span (click)="editContent(product.data.contentId,product.data.partyContentTypeId,product.data.contentTypeId,product.fromDate,product.thruDate,product.data.statusId,product.data.dataResourceId)">Edit</span>

                                                                                    <svg (click)="onDelete(product.data.contentId,product.fromDate,product.data.partyContentTypeId,product.data.partyId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>

                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==12">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Segments
                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a data-toggle="modal" data-target="#exampleModalSegments">Create New Segments</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="SegmentRole" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Segment Group ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Roles
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{activity.segmentGroupIdDescription}}- {{activity.segmentGroupId.segmentGroupId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.segmentGroupId.roleTypeId}}</td>

                                                                                <td [ngStyle]="{'width':'170px'}">


                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deletePartySegment(activity)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==13">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Resumes
                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a data-toggle="modal" data-target="#exampleModalResume">Create New Resumes</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="PartyResume" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Resume ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Party ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Content ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Resume Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Resume Text
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{activity.data.resumeId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.data.partyId}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.data.contentId}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.data.resumeDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.data.resumeText}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg class="hover" (click)="updateResume(activity.data.resumeId,activity.data.contentId,activity.data.resumeDate,activity.data.resumeText)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                            <g transform="translate(0 -0.004)">
                                              <g transform="translate(0 1.52)">
                                                <path
                                                  d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                  transform="translate(0 -34.137)" />
                                              </g>
                                              <g transform="translate(4.548 0.004)">
                                                <g transform="translate(0 0)">
                                                  <path
                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                    transform="translate(-102.409 -0.004)" />
                                                </g>
                                              </g>
                                            </g>
                                          </svg>


                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deletePartyResume(activity)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==3">
                                                    <div style="width: 49%; float:left">
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs">

                                                                <h4 class=" common-styling hCard" style="margin-left: 7px;">
                                                                    Personal Information
                                                                </h4>
                                                                <span style="margin-right: 2%;" class="edit-buttons  hCreate" data-toggle="modal" data-target="#exampleModal">Update</span>
                                                            </div>
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div *ngIf="Person" class="row">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Personal Image</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Party ID</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{Person.partyId}}
                                          </span>

                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Name</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{Person.firstName}}
                                            {{Person.middleName}} {{Person.lastName}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">External ID</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Status ID</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Comments</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{Person.comments
                                            ? Person.comments:'N/A'}}</span>
                                                                                </div>







                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        List Related Contacts
                                                                    </h4>
                                                                    <span style="margin-right: 2%;" class="edit-buttons " data-toggle="modal" data-target="#exampleModalAccount">Create New Contacts</span>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="form-group" style="width: 100%!important;">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="relatedContact" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    From Party ID
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                <div style="color: white;"> Type
                                                                                                    <p-sortIcon field="quantity">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Comments
                                                                                                    <p-sortIcon field="price">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    {{product.partyIdTo}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.partyRelationshipName}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.comments}}</td>


                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Contact Information
                                                                    </h4>
                                                                    <div class="d-flex flex-row" style="margin-right: 2%;">
                                                                        <span class="edit-buttons " data-toggle="modal" data-target="#exampleModalContact" [queryParams]="{partyId:partyId}">Create New Contact Information
                                      Telecom</span>
                                                                        <span class="edit-buttons  ml-2" data-toggle="modal" data-target="#exampleModalAddress" [queryParams]="{partyId:partyId}">Create New Contact Information
                                      Address</span>
                                                                    </div>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="contactInformation" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Contact Type
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'139px'}" style="text-align: center!important;" pSortableColumn="name">
                                                                                                <div style="color: white;">Contact Information
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'120px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">Soliciting OK
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'115px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">Action
                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                </div>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'100px'}">{{product.contactMechType.description}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'139px'}">
                                                                                                <div class="checkbox-align">

                                                                                                    <ng-container *ngIf="product.contactMech.contactMechTypeId==='POSTAL_ADDRESS'">
                                                                                                        {{product.postalAddress.address1}} , {{product.postalAddress.address2}},
                                                                                                        <br>{{product.postalAddress.stateProvinceGeoId}} , {{product.postalAddress.city}},
                                                                                                        <br>{{product.postalAddress.postalCode}},{{product.postalAddress.countryGeoId}}
                                                                                                    </ng-container>
                                                                                                    <ng-container *ngIf="product.contactMech.contactMechTypeId==='EMAIL_ADDRESS'">
                                                                                                        {{product.contactMech.infoString}}
                                                                                                    </ng-container>
                                                                                                    <ng-container *ngIf="product.contactMech.contactMechTypeId==='TELECOM_NUMBER'">
                                                                                                        Area Code: {{product.telecomNumber.areaCode}}, Contact Number: {{product.telecomNumber.contactNumber}}, Country Code: {{product.telecomNumber.countryCode}}
                                                                                                    </ng-container>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'120px'}" style="text-align: center!important;">
                                                                                                {{product.partyContactMech.allowSolicitation ?product.partyContactMech.allowSolicitation :'N' }}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'115px'}">
                                                                                                <fa-icon [icon]="faEdit" class="text-primary" (click)="updateData(product.contactMech.contactMechId ,product.contactMech.contactMechTypeId)">
                                                                                                </fa-icon>

                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Loyalty Points
                                                                    </h4>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table" style="width: -3px!important;    margin-left: 5%!important;">
                                                                                <span *ngIf="this.PartyLoyaltyPoints" style="color: #383139 !important;">
                                          You have {{this.PartyLoyaltyPoints.totalSubRemainingAmount}} points from
                                          {{this.PartyLoyaltyPoints.totalOrders}} order(s) in the last
                                          {{this.PartyLoyaltyPoints.monthsToInclude}} Months.
                                        </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Identification Numbers
                                                                    </h4>
                                                                    <div class="d-flex flex-row" style="margin-right: 2%;">
                                                                        <span class="edit-buttons " data-toggle="modal" data-target="#exampleModalID">Create New Identification Numbers</span>
                                                                    </div>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select classUser">
                                                                        <div class="form-group" style="width: 100%!important;">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="IdentificationList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Identification Type
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">ID Value
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">Action
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.description}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.identificationData.idValue}}
                                                                                            </td>

                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <fa-icon [icon]="faEdit" class="text-primary" (click)="updatePartyIdentification(product.identificationData.partyIdentificationTypeId,product.identificationData.idValue)">
                                                                                                </fa-icon>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" (click)="deletePartyIdentification(product.identificationData.partyIdentificationTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                  <defs>
                                                    <style>
                                                      .a {
                                                        fill: #f44336;
                                                      }

                                                      .b {
                                                        fill: #fafafa;
                                                        margin-left: 20px;
                                                      }
                                                    </style>
                                                  </defs>
                                                  <path class="a fill-color"
                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                  <path class="b"
                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                    transform="translate(-147.576 -147.576)" />
                                                </svg>

                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br>
                                                    </div>
                                                    <div style="width: 49.8%; float:right">
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                                 border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        User name(s)
                                                                    </h4>
                                                                    <div class="d-flex flex-row" style="margin-right: 2%;">
                                                                        <span class="edit-buttons " data-toggle="modal" data-target="#exampleModalLogin">Create New User name</span>

                                                                    </div>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select classUser">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="UserLoginData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'100px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    User Login
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'139px'}" style="text-align: center!important;" pSortableColumn="name">
                                                                                                <div style="color: white;">Status
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'115px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">Action
                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                </div>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>

                                                                                            <td [ngStyle]="{'width':'139px'}">
                                                                                                <div class="checkbox-align">

                                                                                                    {{product.data.userLoginId}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'120px'}" style="text-align: center!important;">
                                                                                                {{product.partyStatusId.statusId}}

                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'115px'}">
                                                                                                <fa-icon [icon]="faEdit" class="text-primary" (click)="updatePassword(product.data.userLoginId)">
                                                                                                </fa-icon>
                                                                                                <span style="cursor: pointer;" (click)="securityList(product.data.userLoginId)">Security Group</span>


                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Party Attributes
                                                                    </h4>
                                                                    <span style="margin-right: 2%;" class="edit-buttons " data-toggle="modal" data-target="#exampleModalParty">Create New Party Attributes</span>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select">



                                                                        <div class="form-group" style="width: 100%!important;">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="PartyAttribute" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'100px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Name
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'100px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Value
                                                                                                    <p-sortIcon field="price"></p-sortIcon>

                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'120px'}" pSortableColumn="quantity">
                                                                                                <div style="color: white;"> Action
                                                                                                    <p-sortIcon field="quantity">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'100px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    {{product.attrName}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'100px'}"> {{product.attrValue}}</td>

                                                                                            <td [ngStyle]="{'width':'100px'}">
                                                                                                <svg class="hover" (click)="updateAttribute(product)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                  <g transform="translate(0 -0.004)">
                                                    <g transform="translate(0 1.52)">
                                                      <path
                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                        transform="translate(0 -34.137)" />
                                                    </g>
                                                    <g transform="translate(4.548 0.004)">
                                                      <g transform="translate(0 0)">
                                                        <path
                                                          d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                          transform="translate(-102.409 -0.004)" />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </svg>
                                                                                                <!--      <svg xmlns="http://www.w3.org/2000/svg"
                                                  (click)="deletePartyAttribute(product)" class="ml-20" width="16"
                                                  height="16" viewBox="0 0 26 26">
                                                  <defs>
                                                    <style>
                                                      .a {
                                                        fill: #f44336;
                                                      }

                                                      .b {
                                                        fill: #fafafa;
                                                        margin-left: 20px;
                                                      }
                                                    </style>
                                                  </defs>
                                                  <path class="a fill-color"
                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                  <path class="b"
                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                    transform="translate(-147.576 -147.576)" />
                                                </svg> -->
                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        AVS Override (CC Fraud Screening)
                                                                    </h4>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table" *ngIf="PartyIcsAvsOverRide.length" style="width: -3px!important;    margin-left: 5%!important;">
                                                                                <div *ngFor="let title of PartyIcsAvsOverRide" style="width: 153%;" class="all-users-infomation font-13 font-medium own-user-select">

                                                                                    <div class="col-lg-4 col-12">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleInputEmail1">
                                                AVS String</label>


                                                                                        </div>


                                                                                    </div>
                                                                                    <div class="col-lg-4 col-12">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleInputEmail1">
                                                {{title.avsDeclineStringList.avsDeclineString}}</label>


                                                                                        </div>

                                                                                    </div>


                                                                                    <div class="col-lg-4 col-12">
                                                                                        <div class="form-group">
                                                                                            <button type="submit" (click)="updateAVS();" class="btn btn-secondary submit-btn">Edit</button>

                                                                                            <button type="submit" (click)="resetAvsOverride();" class="btn btn-secondary submit-btn">Reset</button>

                                                                                        </div>

                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                            <div class="card own-account-table" *ngIf="!PartyIcsAvsOverRide.length" style="width: -3px!important;    margin-left: 5%!important;">
                                                                                <div style="width: 153%;" class="all-users-infomation font-13 font-medium own-user-select">

                                                                                    <div class="col-lg-4 col-12">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleInputEmail1">
                                                AVS String</label>


                                                                                        </div>


                                                                                    </div>
                                                                                    <div class="col-lg-4 col-12">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleInputEmail1">
                                                Global</label>


                                                                                        </div>

                                                                                    </div>


                                                                                    <div class="col-lg-4 col-12">
                                                                                        <div class="form-group">
                                                                                            <button type="submit" (click)="updateAVS();" class="btn btn-secondary submit-btn">Edit</button>


                                                                                        </div>

                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                                  border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Notes
                                                                    </h4>
                                                                    <span style="margin-right: 2%;" class="edit-buttons " data-toggle="modal" data-target="#exampleModalNote">Create New Notes</span>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select">



                                                                        <div class="form-group" style="width: 100%!important;">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="accountDataNote" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Note Name
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Note
                                                                                                    <p-sortIcon field="price"></p-sortIcon>

                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'120px'}" pSortableColumn="quantity">
                                                                                                <div style="color: white;"> By
                                                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Created At
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Action


                                                                                                </div>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'100px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    {{product.noteName}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'100px'}">{{product.noteInfo}}</td>
                                                                                            <td [ngStyle]="{'width':'120px'}">{{product.firstName}} {{product.middleName}} {{product.lastName}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'100px'}">{{product.createdStamp | date:'yyyy-MM-dd'}}
                                                                                            </td>


                                                                                            <td [ngStyle]="{'width':'100px'}">
                                                                                                <svg class="hover" (click)="onUpdateNotes(product.noteId,product.noteName,product.noteInfo)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                  <g transform="translate(0 -0.004)">
                                                    <g transform="translate(0 1.52)">
                                                      <path
                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                        transform="translate(0 -34.137)" />
                                                    </g>
                                                    <g transform="translate(4.548 0.004)">
                                                      <g transform="translate(0 0)">
                                                        <path
                                                          d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                          transform="translate(-102.409 -0.004)" />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </svg>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" (click)="onDeleteNotes(product.noteId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                  <defs>
                                                    <style>
                                                      .a {
                                                        fill: #f44336;
                                                      }

                                                      .b {
                                                        fill: #fafafa;
                                                        margin-left: 20px;
                                                      }
                                                    </style>
                                                  </defs>
                                                  <path class="a fill-color"
                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                  <path class="b"
                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                    transform="translate(-147.576 -147.576)" />
                                                </svg>
                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Party Content
                                                                    </h4>
                                                                    <span style="margin-right: 2%;" class="edit-buttons " data-toggle="modal" data-target="#exampleModalContent">Create New Party Content</span>
                                                                </div>
                                                            </header>
                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="card own-account-table">
                                                                        <p-table [value]="contentList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Content Id
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;"> Content Name
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                        <div style="color: white;"> Content Type
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Party Content Type
                                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Status
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> From Date
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Action
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}" (click)="contactsDetail()">
                                                                                        <div class="checkbox-align">

                                                                                            {{product.contentId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.contentName}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.contentType}}</td>

                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.partyContentTypeId}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.status}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.fromDate | date:'medium'}}</td>

                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                        <svg (click)="export(product.contentId,product.contentName)" width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-arrow-down-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                              <path fill-rule="evenodd"
                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                            </svg>
                                                                                        <svg (click)="onDelete(product.contentId,product.fromDate,product.partyContentTypeId,product.partyId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                              <defs>
                                                <style>
                                                  .a {
                                                    fill: #f44336;
                                                  }

                                                  .b {
                                                    fill: #fafafa;
                                                    margin-left: 20px;
                                                  }
                                                </style>
                                              </defs>
                                              <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                              <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                            </svg>

                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Stores
                                                                    </h4>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="ProductStore" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>

                                                                                                    Store Name [ID]
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Role Type
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>


                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    <span class="account-button">{{product.productStore.storeName}}
                                                    {{product.productStore.productStoreId}}</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                {{product.roleType.roleTypeId}}
                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                  <h4 class="ml-2">No
                                                    Record Found
                                                  </h4>
                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Visits
                                                                    </h4>
                                                                    <span style="margin-right: 2%;" class="edit-buttons " (click)="account()">List All</span>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;" class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="form-group" style="width: 100%!important;">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="Visitors" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Visit ID
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> User Login
                                                                                                    <p-sortIcon field="price"></p-sortIcon>

                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                                <div style="color: white;"> user Impersonated
                                                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> New User
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Web App


                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Client IP


                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> From Date


                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Through Date


                                                                                                </div>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    {{product.visitorData.visitId}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.userLoginId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.userImpersonated}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.userCreated}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.webappName}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.clientIpAddress}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.visitorData.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.visitorData.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>



                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important; width: 200% !important;
      margin-left: -100% !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                                                        Payment Method
                                                                    </h4>
                                                                    <div class="d-flex flex-row">
                                                                        <span class="edit-buttons  ml-2" data-toggle="modal" data-target="#exampleModalCredit" [queryParams]="{partyId:taxAuthPartyId}">Create New Payment Method
                                      Credit Card</span>
                                                                        <span class="edit-buttons  ml-2" data-toggle="modal" data-target="#exampleModalGift" [queryParams]="{partyId:taxAuthPartyId}">Create Gift Card</span>
                                                                        <span class="edit-buttons  ml-2" data-toggle="modal" data-target="#exampleModalEFT" [queryParams]="{partyId:taxAuthPartyId,isEftAccount:true}">Create EFT
                                      Account</span>
                                                                        <span class="edit-buttons  ml-2" data-toggle="modal" data-target="#exampleModalCheck" [queryParams]="{partyId:taxAuthPartyId}">Add
                                      Check Account</span>
                                                                        <span class="edit-buttons  ml-2" data-toggle="modal" data-target="#exampleModalBilling" [queryParams]="{partyId:taxAuthPartyId}">Create
                                      Billing Account</span>
                                                                    </div>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div class="card own-account-table" style="max-width: 1056px!important; margin-left: 4%;">
                                                                        <p-table [value]="paymentMethods" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td>{{product.type}}</td>
                                                                                    <td>
                                                                                        <ng-container *ngIf="product.paymentMethodTypeId.includes('ACCOUNT') || product.paymentMethodTypeId.includes('CHECK')">
                                                                                            <span>{{product.nameOnAccount}},{{product.bankName}}({{product.branchCode}}),A/C
                                                {{product.accountNumber}}, (Type:
                                                {{product.accountType ? product.accountType :''}}), (Updated At:
                                                {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="product.paymentMethodTypeId==='BILLING'">
                                                                                            <span>({{product.billingAccountId}}),(Account
                                                Limit:${{product.accountLimit}}),(Available Balance:
                                                ${{product.accountBalance}}), (Updated At:
                                                {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="product.paymentMethodTypeId.includes('CARD')">
                                                                                            <span>{{product.cardNumber ? product.cardNumber
                                                :''}}</span>, Expire :
                                                                                            <span>{{product.expireDate? product.expireDate:''}}</span>,
                                                                                            <span> {{product.companyNameOnCard? product.companyNameOnCard:''}}</span>,
                                                                                            <span> ,( {{product.firstNameOnCard ? product.firstNameOnCard:''}}
                                                {{product.middleNameOnCard ? product.middleNameOnCard:''}}
                                                {{product.lastNameOnCard ? product.lastNameOnCard : ''}}),(
                                                {{product.cardType}}), (Updated At:
                                                {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                                        </ng-container>
                                                                                    </td>
                                                                                    <td>
                                                                                        <fa-icon  class="account-button"  (click)="paymentMethodNavigation(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)" [icon]="faEdit"></fa-icon>
                                                                                        <fa-icon class="text-danger cursor-pointer" (click)="removePaymentMethod(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)" [icon]="faTrash"></fa-icon>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <br> <br> <br> <br> <br>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==14">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Classification
                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a data-toggle="modal" data-target="#exampleModalClass">Create New Classification</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="PartyClassification" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        Party ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Classification Group ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">Through Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{activity.partyRateData.partyId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.partyRateData.partyClassificationGroupId}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.fromDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.thruDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg class="hover" (click)="updatePartyClassification(activity.partyRateData.partyClassificationGroupId,activity.fromDate,activity.thruDate)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                            <g transform="translate(0 -0.004)">
                                              <g transform="translate(0 1.52)">
                                                <path
                                                  d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                  transform="translate(0 -34.137)" />
                                              </g>
                                              <g transform="translate(4.548 0.004)">
                                                <g transform="translate(0 0)">
                                                  <path
                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                    transform="translate(-102.409 -0.004)" />
                                                </g>
                                              </g>
                                            </g>
                                          </svg>


                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deletePartyClassification(activity)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==15">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Contact List

                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a data-toggle="modal" data-target="#exampleModalContactList">Create New Contact</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group" style="width: 100%!important">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="PartyContactList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        Contact Lists
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">Status History
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">Through Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Status ID
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Opt-in Code
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Preferred Contact Mech ID
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{activity.contactListName.contactListId}}
                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.data.fromDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.data.thruDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.data.statusId}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{activity.data.preferredContactMechId}}
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg class="hover" (click)="updateContactList(activity)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                            <g transform="translate(0 -0.004)">
                                              <g transform="translate(0 1.52)">
                                                <path
                                                  d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                  transform="translate(0 -34.137)" />
                                              </g>
                                              <g transform="translate(4.548 0.004)">
                                                <g transform="translate(0 0)">
                                                  <path
                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                    transform="translate(-102.409 -0.004)" />
                                                </g>
                                              </g>
                                            </g>
                                          </svg>


                                                                                    <!--    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteContactList(activity)"
                                            class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg> -->
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==16">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Employment Applications


                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a data-toggle="modal" data-target="#exampleModalEmploy">Create New Employment Applications</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group" style="width: 100%!important">

                                                                <div class="card own-account-table">
                                                                    <p-table id="table" [value]="employmentApplication" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Application ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Empl Position ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Status ID
                                                                                        <p-sortIcon field="category"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;">Employment App Source Type ID
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;">Applying Party ID
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Referred By Party ID
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Application Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Action</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}" (click)="contactsDetail()">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"   (click)="detailPage(product.applicationId)">
                                              {{product.applicationId}}</span>

                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">{{product.emplPositionId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">{{product.statusId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.employmentAppSourceTypeId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.applyingPartyId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.referredByPartyId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.applicationDate | date :'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div>

                                                                                        <svg class="hover" (click)="onUpdate(product.applicationId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                              <g transform="translate(0 -0.004)">
                                                <g transform="translate(0 1.52)">
                                                  <path
                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                    transform="translate(0 -34.137)" />
                                                </g>
                                                <g transform="translate(4.548 0.004)">
                                                  <g transform="translate(0 0)">
                                                    <path
                                                      d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                      transform="translate(-102.409 -0.004)" />
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>
                                                                                        <svg (click)="onDeleteEmp(product.applicationId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                              <defs>
                                                <style>
                                                  .a {
                                                    fill: #f44336;
                                                  }

                                                  .b {
                                                    fill: #fafafa;
                                                    margin-left: 20px;
                                                  }
                                                </style>
                                              </defs>
                                              <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                              <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                            </svg>
                                                                                    </div>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==17">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Product Store Roles


                                                        </h4>
                                                        <span class="edit-buttons ">
                              <a data-toggle="modal" data-target="#exampleModalProduct">Create New Product Store Roles</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <!--  <form [formGroup]="findPartyForm">
                                <div
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Store ID</label>
                                                    <p-dropdown [options]="productStoresListArray"
                                                    filter="true" placeholder="Select Store ID"
                                                    formControlName="StoreID">
                                                </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-lg-2"></div>
                                            <div class="col-lg-2">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">RoleType ID</label>
                                                    <p-dropdown [options]="allroleArray"
                                                        filter="true"  placeholder="Select Role Type ID"
                                                        formControlName="RoleTypeID">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">From Date</label>
                                                    <input type="date" name="externalId"
                                                        class="form-control"
                                                        formControlName="FromDate"
                                                        id="externalId"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter From Date">
                                                </div>
                                            </div>
                                            <div class="col-lg-2"></div>
                                            <div class="col-lg-2">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">	Through Date</label>
                                                    <input type="date" name="lastName"
                                                        class="form-control"
                                                        formControlName="ThroughDate"
                                                        id="lastName"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Last Name">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Sequence Num</label>
                                                    <input type="text" name="firstName"
                                                        class="form-control"
                                                        formControlName="SequenceNum"
                                                        id="firstName"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Sequence Num">
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                   
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button ">
                                    <button _ngcontent-cta-c128="" type="submit"
                                        class="btn btn-secondary submit-btn"
                                        (click)="onSubmit()">Find</button>&nbsp;&nbsp;
                                    <button type="button" (click)="reset();"
                                        class="btn btn-danger ml-2">Reset</button>&nbsp;
                                </div>
                            </form> -->
                                                            <div class="form-group" style="width: 100%!important">

                                                                <div class="card own-account-table">
                                                                    <p-table id="table" [value]="AllProductStoreRole" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Store ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">RoleType ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Through Date
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Sequence Num
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Action</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}" (click)="contactsDetail()">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                              {{product.data.productStoreId}}</span>

                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">{{product.data.roleTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">{{product.data.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.data.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.data.sequenceNum}}</td>

                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div>

                                                                                        <svg class="hover" (click)="onUpdateProductStore(product)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                              <g transform="translate(0 -0.004)">
                                                <g transform="translate(0 1.52)">
                                                  <path
                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                    transform="translate(0 -34.137)" />
                                                </g>
                                                <g transform="translate(4.548 0.004)">
                                                  <g transform="translate(0 0)">
                                                    <path
                                                      d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                      transform="translate(-102.409 -0.004)" />
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>
                                                                                        <svg (click)="deleteStoreRole(product)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                              <defs>
                                                <style>
                                                  .a {
                                                    fill: #f44336;
                                                  }

                                                  .b {
                                                    fill: #fafafa;
                                                    margin-left: 20px;
                                                  }
                                                </style>
                                              </defs>
                                              <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                              <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                            </svg>
                                                                                    </div>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="main-submit-button">
                      <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
                  </div> -->
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Personal Information </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">


                                <li [ngClass]="activeCategoryA==2?'active':''"><a>
                    Edit Personal Information 
                  </a>
                                </li>
                            </ul>
                        </div>


                        <div class="port-header-contact create-lead font-medium mb-0">

                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="empPersonalFormService.emp_personal_form" [formGroup]="empPersonalFormService.emp_personal_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Party Id </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="partyId" [ngModelOptions]="{standalone: true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  disabled>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">External Id </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="externalId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Id">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">First Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="firstName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First name">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Last Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="lastName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last name">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Comment</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comment">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="statusId" [options]="crmArray" optionlabel="label" placeholder="Enter Status"></p-dropdown>


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="description">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" placeholder="Description" formControlName="description" class="form-control">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <div class="form-group" style="color: black!important">Preferred Currency
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="currencyList" placeholder="Preferred Currency" filter="true" formControlName="preferredCurrencyUomId"></p-dropdown>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>


                        <div class="main-submit-button mb-4" style="    margin-right: 29%;">
                            <button type="submit" (click)="onUpdateInfo()" class="btn btn-secondary submit-btn">Update</button>
                            <button type="button" (click)="onCancel()" routerLink="/hr/employees" routerLinkActive="active" class="btn btn-degault cncl-btn">Cancel</button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalLogin" tabindex="-1" role="dialog" aria-labelledby="exampleModalLoginTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create New User Login</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton2>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryA==2?'active':''"><a>
                    Create User Login
                  </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">
                              User Login ID<span style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="loginId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User Login ID">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                              Current Password<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="currentpass" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">
                              Current Password Verify<span style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="verifypass" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Current Password Verify">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                              Password Hint</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="passHint" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">
                              Require Password Change</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="passrequire" placeholder="Require Password Change" [options]="user" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                              External Auth ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="extAuthId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                                    </div>



                                                </div>
                                            </div>



                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 71%;">

                                        <button type="submit" (click)="onSubmitLogin();" class="btn btn-secondary submit-btn">Save</button>



                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalAccount" tabindex="-1" role="dialog" aria-labelledby="exampleModalAccountTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create Related Account</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton3>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryA==2?'active':''"><a>
                    Add Related Account
                  </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="fileUploadForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput" style="margin-left: -8%;">
                                                        <label for="exampleInputEmail1">
                              Account Party ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="accountPartyId" optionlabel="label" filter="true" placeholder="Enter Account Party ID" name="Parent Type ID" [options]="accountArray">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                              Comments</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>



                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 65%;">

                                        <button type="submit" (click)="onSubmitAccount();" class="btn btn-secondary submit-btn">Add</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalParty" tabindex="-1" role="dialog" aria-labelledby="exampleModalPartyTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Party Attributes</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton4>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryA==2?'active':''" *ngIf="!show"><a>
                    Create Party Attributes
                  </a></li>
                                <li [ngClass]="activeCategoryA==2?'active':''" *ngIf="show"><a>
                    Update Party Attributes
                  </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createAttribue">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">
                              Attribute name<span style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" *ngIf="!show" formControlName="attrName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr name">
                                                        <input type="text" *ngIf="show" formControlName="attrName" class="form-control" id="exampleInputEmail1" readonly aria-describedby="emailHelp" placeholder="Enter Attr name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                              Attribute Value<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="attrValue" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Value">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">
                              Attribute Description<span style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="attrDescription" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Description">
                                                    </div>

                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 69%;">

                                        <button type="submit" *ngIf="!show" (click)="onSubmitParty();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="show" (click)="onUpdateParty();" class="btn btn-secondary submit-btn">Update</button>



                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalContact" tabindex="-1" role="dialog" aria-labelledby="exampleModalContactTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Contact </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton5>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" >
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editMode? 'Update':'Create'}} Contact
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="contactForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 14px!important;
                                            margin-left: -10%;">
                                                            <label for="exampleInputEmail1">Allow Solicitation</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [options]="array" placeholder="Select" formControlName="allowSolicitation">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 14px!important;
                                            margin-left: 1%;">
                                                            <label for="exampleInputEmail1">Country Code</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Country Code" formControlName="countryCode">
                                                        </div>



                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 14px!important;
                                            margin-left: -10%;">
                                                            <label for="exampleInputEmail1">Area Code</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Area Code" formControlName="areaCode">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 14px!important;
                                            margin-left: 1%;">
                                                            <label for="exampleInputEmail1">Contact Number</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Contact Number" formControlName="contactNumber">
                                                        </div>




                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 14px!important;
                                            margin-left: -10%;">
                                                            <label for="exampleInputEmail1">Extension</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Extension" formControlName="extension">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 14px!important;
                                            margin-left: 1%;">
                                                            <label for="exampleInputEmail1">Mech Purpose Type Id<span
                                  class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [options]="contactPurpose" placeholder="Mech Purpose Type Id" formControlName="contactMechPurposeTypeId"></p-dropdown>
                                                            <small *ngIf="!!contactForm.controls.contactMechPurposeTypeId.invalid && !!contactForm.controls.contactMechPurposeTypeId.touched" class="text-danger">Mech Purpose Type Id is required</small>
                                                        </div>

                                                    </div>
                                                </div>



                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: 63%;">
                                            <button type="submit" *ngIf="editMode" (click)="updateContact()" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editMode" (click)="submitContact()" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalAddress" tabindex="-1" role="dialog" aria-labelledby="exampleModalAddressTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Postal Address </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton6>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editMode? 'Update':'Create'}} Postal Address
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="addressForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 9px!important;
                                          margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Attn Name</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Attn Name" formControlName="attnName">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 9px!important;
                                          margin-left: -2%;">
                                                            <label for="exampleInputEmail1">To Name</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="To Name" formControlName="toName">
                                                        </div>



                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 9px!important;
                                          margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Allow Solicitation </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Allow Solicitation" [options]="solicitationArray" filter="true" formControlName="allowSolicitation"></p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 9px!important;
                                          margin-left: -2%;">
                                                            <label for="exampleInputEmail1">Address 1 </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Address 1" formControlName="address1">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 9px!important;
                                          margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Address 2</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Address 2" formControlName="address2">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 9px!important;
                                          margin-left: -2%;">
                                                            <label for="exampleInputEmail1">City </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="City" formControlName="city">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 9px!important;
                                          margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Country </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [options]="countryArray" placeholder="Country" formControlName="countryGeoId">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 9px!important;
                                          margin-left: -2%;">
                                                            <label for="exampleInputEmail1">State </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [options]="stateArray" placeholder="State" filter="true" formControlName="stateProvinceGeoId"></p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 9px!important;
                                          margin-left: 0%;">
                                                            <label for="pinCode">Pin Code</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Pin Code" formControlName="postalCode">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 9px!important;
                                          margin-left: -2%;">
                                                            <label for="mechId">Contact Mech Id</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Contact Mech Id" formControlName="contactMechId">
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 9px!important;
                                          margin-left: 0%;">
                                                            <label for="mechType">Contact Mech Purpose Type Id <span
                                  class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Contact Mech Purpose Type Id" filter="true" [options]="contactMechTypeIdAdd" formControlName="contactMechPurposeTypeId">
                                                            </p-dropdown>
                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: 70%;">
                                            <button type="submit" *ngIf="editMode" (click)="updateAddress()" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editMode" (click)="submitAddress()" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalNote" tabindex="-1" role="dialog" aria-labelledby="exampleModalNoteTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Note </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton7>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li [ngClass]="activeCategoryNote==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                    New Party Note</a></li>
                            </ul>
                        </div>
                        <div class="panel-group">

                            <div *ngIf="activeCategoryNote==1">

                                <div class="panel-body">
                                    <form [formGroup]="noteForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput" style="margin-left: -13%;">
                                                        <label for="exampleInputEmail1">Note Name</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" [(ngModel)]="noteName" formControlName="noteName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Note</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <textarea style="height:100%!important;" id="w3review" [(ngModel)]="note" formControlName="note" rows="3" cols="30">
                              </textarea>
                                                    </div>



                                                </div>
                                            </div>

                                        </div>

                                        <div class="proper mb-4" style="margin-right: 25%;">
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn">
                                                <button type="submit" (click)="onSubmitNote()" class="btn btn-secondary submit-btn mr-2">Create</button>
                                                <button type="submit" (click)="navigateFromCreate()" class="btn btn-danger ">Cancel</button>
                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateBtn">
                                                <button type="submit" (click)="onUpdateNote()" class="btn btn-secondary submit-btn">Update</button>
                                                <button type="submit" (click)="navigate()" class="btn btn-danger ">Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalContent" tabindex="-1" role="dialog" aria-labelledby="exampleModalContentTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Party Content </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton8>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryB==2?'active':''"><a>
                    Attach Content
                  </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="Editform">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput" style="margin-left: -9%;">
                                                        <label for="exampleInputEmail1">Attach Content</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input class="form-control" type="file" style="width: 100%!important;" id="exampleInputEmail1" formControlName="file" aria-describedby="emailHelp"  accept="" (change)="fileProgress($event)" style="line-height: 0.9!important;">
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="partyContentTypeId" [(ngModel)]="partyContentTypeId" name="partyContentTypeId" (ngModelChange)="onpartyContent(partyContentTypeId)" [options]="partyContentArray" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput" style="display: none;">
                                                        <label [ngStyle]="{'visibility':'hidden'}" for="exampleInputEmail1">
                              Purpose</label>
                                                    </div>

                                                    <div class="col-lg-2" style="display: none;">
                                                        <p-dropdown filter="true" formControlName="partyContentTypeId" [(ngModel)]="partyContentTypeId" name="partyContentTypeId" (ngModelChange)="onpartyContent(partyContentTypeId)" [options]="partyContentArray" optionlabel="label">
                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 38%;">

                                        <button type="submit" (click)="onSubmitContent();" class="btn btn-secondary submit-btn">Upload</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalID" tabindex="-1" role="dialog" aria-labelledby="exampleModalIDTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Identification Number </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton9>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryB==2?'active':''" *ngIf="!this.show"><a>
                    Create Identification Number
                  </a></li>
                                <li [ngClass]="activeCategoryB==2?'active':''" *ngIf="this.show"><a>
                    Update Identification Number
                  </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createIdentification">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput" style="margin-left: -7%;">
                                                        <label for="exampleInputEmail1">
                              Identification Type</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" *ngIf="!this.show" placeholder="Enter Identification Type" formControlName="type" [options]="identificationArray" optionlabel="label" id="exampleInputEmail1">
                                                        </p-dropdown>
                                                        <input type="email" *ngIf="this.show" formControlName="type" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter ID Value" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                              ID Value</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="idvalue" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter ID Value">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 66%;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmitID();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.show" (click)="onUpdateID();" class="btn btn-secondary submit-btn">Update</button>





                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCredit" tabindex="-1" role="dialog" aria-labelledby="exampleModalCreditTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Credit Card </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton10>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" >
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editMode? 'Update':'Create'}} Credit Card
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="creditCardForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0% !important;">
                                                            <label for="exampleInputEmail1">Company Name Card</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Company Name Card" formControlName="companyNameOnCard">
                                                        </div>

                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Prefix Card</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Prefix Card" filter="true" [options]="prefixArray" formControlName="titleOnCard">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0% !important;">
                                                            <label for="exampleInputEmail1">First Name</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="First Name" formControlName="firstNameOnCard">
                                                            <small class="text-danger" *ngIf="!!creditCardForm.controls.firstNameOnCard.invalid && !!creditCardForm.controls.firstNameOnCard.touched">First
                                Name is Required</small>
                                                        </div>
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Middle Name Card</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Middle Name Card" formControlName="middleNameOnCard">
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0% !important;">
                                                            <label for="exampleInputEmail1">Last Name Card <span class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Last Name Card" formControlName="lastNameOnCard">
                                                            <small class="text-danger" *ngIf="!!creditCardForm.controls.lastNameOnCard.invalid && !!creditCardForm.controls.lastNameOnCard.touched">Last
                                Name is Required</small>
                                                        </div>

                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Suffix On Card
                              </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Suffix On Card" filter="true" [options]="suffixArray" formControlName="suffixOnCard">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0% !important;">
                                                            <label for="exampleInputEmail1">Card Type
                              </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Card Type" filter="true" [options]="cardTypes" formControlName="cardType">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Card Number</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="cardNumber" aria-describedby="emailHelp" placeholder="Card Number">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0% !important;">
                                                            <label for="exampleInputEmail1">Expiration Date <span class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Month" filter="true" [options]="expirationMonth" formControlName="expMonth">
                                                            </p-dropdown>
                                                            <small class="text-danger" *ngIf="!!creditCardForm.controls.expMonth.invalid && !!creditCardForm.controls.expMonth.touched">Expiration
                                Month is Required</small>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown class="ml-2" placeholder="Year" filter="true" [options]="expirationYear" formControlName="expYear">
                                                            </p-dropdown>


                                                            <small class="text-danger" *ngIf="!!creditCardForm.controls.expYear.invalid && !!creditCardForm.controls.expYear.touched">Expiration
                                Year is Required</small>
                                                        </div>



                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0% !important;">
                                                            <label for="exampleInputEmail1">Description</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">
                                                        </div>
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                            <label for="">Billing Address</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <ng-container *ngIf="accountDataCredit?.address;else noData">
                                                                <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="getAddress($event,accountDataCredit?.address.contactMechId)">
                                                                <label class="form-check-label" for="exampleCheck1">
                                  To:<span>{{accountDataCredit?.address.address1}}</span>,
                                  <span>{{accountDataCredit?.address.address2}}</span>,
                                  <span>{{accountDataCredit?.address.city}}</span>,
                                  <span>{{accountDataCredit?.address.stateProvinceGeoId}}</span>,
                                  <span>{{accountDataCredit?.address.countryId}}</span>,
                                  <span>{{accountDataCredit?.address.postalCode}}</span>
                                </label>
                                                            </ng-container>
                                                            <ng-template #noData>
                                                                No contact information on file.
                                                            </ng-template>
                                                        </div>


                                                    </div>
                                                </div>



                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: 55%;">
                                            <button type="submit" *ngIf="editMode" (click)="updateCredit()" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editMode" (click)="submitCredit()" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalGift" tabindex="-1" role="dialog" aria-labelledby="exampleModalGiftTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Gift Card</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton11>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" >
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editMode? 'Update':'Create'}} Gift Card
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="giftForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Card Number <span class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="cardNumber" aria-describedby="emailHelp" placeholder="Card Number">
                                                            <small class="text-danger" *ngIf="!!giftForm.controls.cardNumber.invalid && !!giftForm.controls.cardNumber.touched">Card
                                Number is Required</small>
                                                        </div>
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            ">
                                                            <label for="exampleInputEmail1">PIN number <span class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Pin Number" formControlName="pinNumber">
                                                            <small class="text-danger" *ngIf="!!giftForm.controls.pinNumber.invalid && !!giftForm.controls.pinNumber.touched">PIN
                                Number is Required</small>
                                                        </div>



                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Expiration Date <span class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Month" filter="true" [options]="expirationMonth" formControlName="expMonth">
                                                            </p-dropdown>
                                                            <small class="text-danger" *ngIf="!!giftForm.controls.expMonth.invalid && !!giftForm.controls.expMonth.touched">Expiration
                                Month is Required</small>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown class="ml-2" placeholder="Year" filter="true" [options]="expirationYear" formControlName="expYear">
                                                            </p-dropdown>


                                                            <small class="text-danger" *ngIf="!!giftForm.controls.expYear.invalid && !!giftForm.controls.expYear.touched">Expiration
                                Year is Required</small>

                                                        </div>



                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 14px!important;
                                            margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Description</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">
                                                        </div>



                                                    </div>
                                                </div>


                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: 55%;">
                                            <button type="submit" *ngIf="editMode" (click)="updateGift()" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editMode" (click)="submitGift()" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalEFT" tabindex="-1" role="dialog" aria-labelledby="exampleModalEFTTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create EFT Account </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton12>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" >
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editMode? 'Update':'Create'}} {{isEftAccount?'EFT':'Check'}} Account
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="eftAccountForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -11%;">
                                                            <label for="exampleInputEmail1">Name Account <span
                                                  class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Name Account" formControlName="nameOnAccount">
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.nameOnAccount.invalid && !!eftAccountForm.controls.nameOnAccount.touched">Name
                                              Account is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                         ">
                                                            <label for="exampleInputEmail1">Company Name On Account</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Company Name On Account" formControlName="companyNameOnAccount">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -11%;">
                                                            <label for="exampleInputEmail1">Bank Name <span
                                                  class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Bank Name" formControlName="bankName">
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.bankName.invalid && !!eftAccountForm.controls.bankName.touched">Bank
                                              Name is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          ">
                                                            <label for="exampleInputEmail1">Routing Number <span
                                                  class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Routing Number" formControlName="routingNumber">
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.routingNumber.invalid && !!eftAccountForm.controls.routingNumber.touched">Routing
                                              Number is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -11%;">
                                                            <label for="exampleInputEmail1">Account Type <span
                                                  class="text-danger">*</span>
                                          </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Account Type" filter="true" [options]="accountTypes" formControlName="accountType">
                                                            </p-dropdown>
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.accountType.invalid && !!eftAccountForm.controls.accountType.touched">Account
                                              Type is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          ">
                                                            <label for="exampleInputEmail1">Account Number <span
                                                  class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Account Number" formControlName="accountNumber">
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.accountNumber.invalid && !!eftAccountForm.controls.accountNumber.touched">Account
                                              Number is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -11%;">
                                                            <label for="exampleInputEmail1">Description <span
                                                  class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.description.invalid && !!eftAccountForm.controls.description.touched">Description
                                              is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                       ">
                                                            <label for="">Billing Address</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <ng-container *ngIf="accountData?.address;else noData">
                                                                <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="getAddress($event,accountData?.address.contactMechId)">
                                                                <label class="form-check-label" for="exampleCheck1">
                                                      To:<span>{{accountData?.address.address1}}</span>,
                                                      <span>{{accountData?.address.address2}}</span>,
                                                      <span>{{accountData?.address.city}}</span>,
                                                      <span>{{accountData?.address.stateProvinceGeoId}}</span>,
                                                      <span>{{accountData?.address.countryId}}</span>,
                                                      <span>{{accountData?.address.postalCode}}</span>
                                                  </label>
                                                            </ng-container>
                                                            <ng-template #noData>
                                                                No contact information on file.
                                                            </ng-template>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: 63%;">
                                            <button type="submit" *ngIf="editMode" (click)="updateEFT()" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editMode" (click)="submitEFT()" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCheck" tabindex="-1" role="dialog" aria-labelledby="exampleModalCheckTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Check Account</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton13>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" >
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editMode? 'Update':'Create'}} Check Account
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="checkForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -4%;">
                                                            <label for="exampleInputEmail1">Name Account <span
                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Name Account" formControlName="nameOnAccount">
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.nameOnAccount.invalid && !!eftAccountForm.controls.nameOnAccount.touched">Name
                                                Account is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                        ">
                                                            <label for="exampleInputEmail1">Company Name On Account</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Company Name On Account" formControlName="companyNameOnAccount">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -4%;">
                                                            <label for="exampleInputEmail1">Bank Name <span
                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Bank Name" formControlName="bankName">
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.bankName.invalid && !!eftAccountForm.controls.bankName.touched">Bank
                                                Name is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                        ">
                                                            <label for="exampleInputEmail1">Routing Number <span
                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Routing Number" formControlName="routingNumber">
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.routingNumber.invalid && !!eftAccountForm.controls.routingNumber.touched">Routing
                                                Number is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -4%;">
                                                            <label for="exampleInputEmail1">Payment Method Type <span
                                                    class="text-danger">*</span>
                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Account Type" filter="true" [options]="paymentMethodTypes" formControlName="paymentMethodTypeId">
                                                            </p-dropdown>

                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                           ">
                                                            <label for="exampleInputEmail1">Account Type <span
                                                    class="text-danger">*</span>
                                            </label>

                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Account Type" filter="true" [options]="accountTypes" formControlName="accountType">
                                                            </p-dropdown>
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.accountType.invalid && !!eftAccountForm.controls.accountType.touched">Account
                                                Type is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -4%;">
                                                            <label for="exampleInputEmail1">Account Number <span
                                                    class="text-danger">*</span></label>

                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Account Number" formControlName="accountNumber">
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.accountNumber.invalid && !!eftAccountForm.controls.accountNumber.touched">Account
                                                Number is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                        ">
                                                            <label for="exampleInputEmail1">Description <span
                                                    class="text-danger">*</span></label>

                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">
                                                            <small class="text-danger" *ngIf="!!eftAccountForm.controls.description.invalid && !!eftAccountForm.controls.description.touched">Description
                                                is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -4%;">
                                                            <label for="">Billing Address</label>

                                                        </div>
                                                        <div class="col-lg-2">
                                                            <ng-container *ngIf="accountDataCredit?.address;else noData">
                                                                <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="getAddress($event,accountDataCredit?.address.contactMechId)">
                                                                <label class="form-check-label" for="exampleCheck1">
                                                        To:<span>{{accountDataCredit?.address.address1}}</span>,
                                                        <span>{{accountDataCredit?.address.address2}}</span>,
                                                        <span>{{accountDataCredit?.address.city}}</span>,
                                                        <span>{{accountDataCredit?.address.stateProvinceGeoId}}</span>,
                                                        <span>{{accountDataCredit?.address.countryId}}</span>,
                                                        <span>{{accountDataCredit?.address.postalCode}}</span>
                                                    </label>
                                                            </ng-container>
                                                            <ng-template #noData>
                                                                No contact information on file.
                                                            </ng-template>
                                                        </div>



                                                    </div>
                                                </div>


                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: 68%;">
                                            <button type="submit" *ngIf="editMode" (click)="updateCheck()" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editMode" (click)="submitCheck()" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalBilling" tabindex="-1" role="dialog" aria-labelledby="exampleModalBillingTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Billing Account</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton14>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" >
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editMode? 'Update':'Create'}} Billing Account
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="billingAccountForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -8%;">
                                                            <label for="exampleInputEmail1">Account Limit</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Account Limit" formControlName="accountLimit">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                         ">
                                                            <label for="exampleInputEmail1">Account Currency Uom ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Account Currency Uom ID" filter="true" [options]="currencyUomIds" formControlName="accountCurrencyUomId">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -8%;">
                                                            <label for="exampleInputEmail1">Contact Mech ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Contact Mech ID" formControlName="contactMechId">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          ">
                                                            <label for="exampleInputEmail1">From Date</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="date" class="form-control" placeholder="From Date" formControlName="fromDate">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -8%;">
                                                            <label for="exampleInputEmail1">Through Date </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="date" class="form-control" placeholder="Through Date" formControlName="thruDate">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                              ">
                                                            <label for="exampleInputEmail1">Description
                                              </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="Description" formControlName="description">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -8%;">
                                                            <label for="exampleInputEmail1">External Account ID
                                              </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="External Account ID" formControlName="externalAccountId">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          ">
                                                            <label for="exampleInputEmail1">Party ID <span
                                                  class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Party ID" filter="true" [options]="partyIds" formControlName="partyId">
                                                            </p-dropdown>
                                                            <small class="text-danger" *ngIf="!!billingAccountForm.controls.partyId.invalid && !!billingAccountForm.controls.partyId.touched">Party
                                                  ID is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -8%;">
                                                            <label for="exampleInputEmail1">Available Balance</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p> ${{availableBalance}} <span> This is the amount which can be used
                                                  for new orders.</span></p>
                                                        </div>



                                                    </div>
                                                </div>


                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: 64%;">
                                            <button type="submit" *ngIf="editMode" (click)="updateBilling()" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editMode" (click)="submitBilling()" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalRate" tabindex="-1" role="dialog" aria-labelledby="exampleModalRateTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Party Rates</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton15>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryB==2?'active':''" *ngIf="!this.show"><a>
                    Edit Party Rates
                </a></li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createParty">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                Rate Type<span style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="rateType" placeholder="Enter Rate Type" name="Parent Type ID" [options]="rateTypesArray" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Period Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <label>Rate amount per Hour</label>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                Rate Amount</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="rateAmount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rate Amount">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="currency" placeholder="Enter Currency Uom ID" [options]="currencyArray" optionlabel="label" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                Percentage Used</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="percentageUsed" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Percentage Used">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Default Rate</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="defaultRate" placeholder="Enter Default Rate" [options]="default" optionlabel="label" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>




                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 64%;">

                                        <button type="submit" (click)="onSubmitRate();" class="btn btn-secondary submit-btn">Create</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalSkill" tabindex="-1" role="dialog" aria-labelledby="exampleModalSkillTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Party Skill</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton16>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategoryB==2?'active':''"><a>
                    Add Party Skill </a></li>
                                <li *ngIf="show" [ngClass]="activeCategoryB==2?'active':''"><a>
                        Update Party Skill </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="addSkillsService.add_skill_form" [formGroup]="addSkillsService.add_skill_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="partyId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter year of experience">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Skill Type ID <span
                                                style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="skillTypeId" filter="true" [options]="skillArray" optionlabel="label" placeholder="Select Skill Type Id">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Years Experience</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="yearsExperience" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter year of experience">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Rating </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="rating" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rating">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Skill Level</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="skillLevel" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter skill Level">
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="main-submit-button" style="margin-right: 27%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmitSkill()" class="btn btn-secondary submit-btn">Create</button>
                                            <button type="submit" *ngIf="show" (click)="updateSkills()" class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalIdentify" tabindex="-1" role="dialog" aria-labelledby="exampleModalIdentifyTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Identification Number</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton17>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryB==2?'active':''" *ngIf="!this.show"><a>
                   Create Identification Number
                </a></li>
                                <li [ngClass]="activeCategoryB==2?'active':''" *ngIf="this.show"><a>
                    Update Identification Number
                 </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createIdentificationNo">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput" style="margin-left:-8% !important;">
                                                        <label for="exampleInputEmail1">
                                                Identification Type</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" *ngIf="!this.show" placeholder="Enter Identification Type" formControlName="type" [options]="identificationArray" optionlabel="label" id="exampleInputEmail1">
                                                        </p-dropdown>
                                                        <input type="email" *ngIf="this.show" formControlName="type" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter ID Value" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                ID Value</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="idvalue" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter ID Value">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 65%;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmitIdentify();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.show" (click)="onUpdateIdentify();" class="btn btn-secondary submit-btn">Update</button>





                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalSegments" tabindex="-1" role="dialog" aria-labelledby="exampleModalSegmentsTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Party Segment Role</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton18>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryB==2?'active':''" *ngIf="!this.show"><a>
                    Add Party Segment Role
                </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createSegment">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput" style="margin-left: -7%;">
                                                        <label for="exampleInputEmail1">
                                                Segment Group ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="segmentId" placeholder="Enter Segment Group ID" [options]="SegmentGroupArray" optionlabel="label" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Role Type ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="roleId" placeholder="Enter Role Type ID" [options]="allroleArray" optionlabel="label" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left:66% ;">

                                        <button type="submit" (click)="onSubmitSegments();" class="btn btn-secondary submit-btn">Save</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalClass" tabindex="-1" role="dialog" aria-labelledby="exampleModalClassTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Party Classification</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton19>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryB==2?'active':''" *ngIf="!this.show"><a>
                    Add Party Classification
                </a></li>
                                <li [ngClass]="activeCategoryB==2?'active':''" *ngIf="this.show"><a>
                    Update Party Classification
                </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createClassification">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                Party ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="partyId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party Id" readonly>
                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormA" *ngIf="!this.show">
                                                        <label for="exampleInputEmail1">
                                                Classification Group ID</label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.show">
                                                        <p-dropdown [options]="ClassificationGroupIdArray" filter="true" formControlName="ClassificationGroupID" placeholder="Enter Classification Group ID" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" *ngIf="!this.show" formControlName="FromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Date">
                                                        <input type="date" *ngIf="this.show" formControlName="FromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Date" readonly>
                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="ThroughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                                    </div>


                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 57%;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmitClass();" class="btn btn-secondary submit-btn">Create</button>

                                        <button type="submit" *ngIf="this.show" (click)="onUpdateClass();" class="btn btn-secondary submit-btn">Update</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalAttach" tabindex="-1" role="dialog" aria-labelledby="exampleModalAttachTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Party Content</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton20>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Attach Content
                </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="Attachform">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                              Content Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <label>{{this.contentId}}</label>
                                                    </div>




                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                               Parent Content Type ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown *ngIf="!show" filter="true" formControlName="partyContentTypeId" [options]="partyContentArray" placeholder="Parent Content Type ID" optionlabel="label">
                                                        </p-dropdown>
                                                        <input type="text" *ngIf="this.show" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Parent ContentTypeId" formControlName="partyContentTypeId" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Content Type ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Enter Content Type ID" name="Parent Type ID" formControlName="ContentTypeID" [options]="PartyContentTypeIdListArray" optionlabel="label">
                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                From Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" *ngIf="!this.show" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date" formControlName="fromDate">
                                                        <input type="date" *ngIf="this.show" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Parent ContentTypeId" formControlName="fromDate" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Through</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date" formControlName="throughDate">

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                Status ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="StatusID" [options]="PartyStatusIdListArray" placeholder="Status ID" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Data Category ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Enter Data Category ID" name="Parent Type ID" formControlName="DataCategoryID" [options]="DataCategoryIdListArray" optionlabel="label">
                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                Is Public</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="IsPublic" [options]="isPublic" placeholder="Is Public" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Attach Content</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input class="form-control" type="file" style="width: 100%!important;" id="exampleInputEmail1" formControlName="file" aria-describedby="emailHelp"  accept="" (change)="fileProgress($event)" style="line-height: 0.9!important;">
                                                    </div>



                                                </div>
                                            </div>




                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 66%;">

                                        <button type="submit" *ngIf="!show" (click)="onSubmitAttach();" class="btn btn-secondary submit-btn">Save</button>

                                        <button type="submit" *ngIf="show" (click)="onUpdateAttach();" class="btn btn-secondary submit-btn">Update</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalResume" tabindex="-1" role="dialog" aria-labelledby="exampleModalResumeTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Party Resumes</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton21>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                    Add Party Resumes
                </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                    Update Party Resumes
                </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createResume">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput" style="margin-left: -10%;">
                                                        <label for="exampleInputEmail1">
                                                Resume ID<span
                                                style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" *ngIf="!this.show" formControlName="resumeId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Id">
                                                        <input type="text" *ngIf="this.show" formControlName="resumeId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Id" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Content ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="contentIds" filter="true" formControlName="contentId" placeholder="Enter Content ID" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput" style="margin-left: -10%;">
                                                        <label for="exampleInputEmail1">
                                                Resume Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="resumeDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Resume Text</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="resumetxt" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 62%;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmitResume();" class="btn btn-secondary submit-btn">Create</button>

                                        <button type="submit" *ngIf="this.show" (click)="onUpdateResume();" class="btn btn-secondary submit-btn">Update</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalContactList" tabindex="-1" role="dialog" aria-labelledby="exampleModalContactListTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Party Contact</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton22>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                    Add Party Contact
                </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                    Update Party Contact
                </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createContact">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC" style="margin-left: -1%;">
                                                        <label for="exampleInputEmail1">
                                                Contact List ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown *ngIf="!this.show" [options]="contactListArray" filter="true" formControlName="ContactListID" placeholder="Enter Contact List ID" name="Parent Type ID">
                                                        </p-dropdown>
                                                        <input type="text" *ngIf="this.show" formControlName="ContactListID" class="form-control" id="exampleInputEmail1" readonly aria-describedby="emailHelp" placeholder="Enter Party Id">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm" style="margin-left: 3%;">
                                                        <label for="exampleInputEmail1">
                                                From Date<span
                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" *ngIf="!this.show" formControlName="FromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party Id">
                                                        <input type="date" *ngIf="this.show" formControlName="FromDate" class="form-control" id="exampleInputEmail1" readonly aria-describedby="emailHelp" placeholder="Enter Party Id">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC" style="margin-left: -1%;">
                                                        <label for="exampleInputEmail1">
                                                Through Date</label>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="ThroughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm" style="margin-left: 3%;">
                                                        <label for="exampleInputEmail1">
                                                Status ID</label>

                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown [options]="PartyInvitationStatusListArray" filter="true" formControlName="StatusID" placeholder="Enter Status ID" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC" style="margin-left: -1%;">
                                                        <label for="exampleInputEmail1">
                                                Preferred Contact Mech ID</label>


                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="PreferredContactMechIdArray" filter="true" formControlName="PreferredContactMechID" placeholder="Enter Preferred Contact Mech ID" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm" style="margin-left: 3%;">
                                                        <label for="exampleInputEmail1">
                                                Preferred Contact Mech ID</label>

                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown [options]="PreferredContactMechIdArray" filter="true" formControlName="PreferredContactMechID" placeholder="Enter Preferred Contact Mech ID" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="this.show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">
                                                Opt-in Code </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="OptinCode" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Opt-in Code ">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                Status History</label>


                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown [options]="AllPartyContactListPartyStatusArray" filter="true" formControlName="StatusHistory" placeholder="Enter 	Status History" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 74%;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmitContact();" class="btn btn-secondary submit-btn">Create</button>

                                        <button type="submit" *ngIf="this.show" (click)="onUpdateContact();" class="btn btn-secondary submit-btn">Update</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalEmploy" tabindex="-1" role="dialog" aria-labelledby="exampleModalEmployTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Employment Application</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton23>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    New Employment Application</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" *ngIf="createEventFormService.employee_application_form" [formGroup]="createEventFormService.employee_application_form">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="margin-left: -6%;">
                                                        <label for="exampleInputEmail1">Application ID</label>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="applicationId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Application Id">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Empl PositionId</label>

                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" placeholder="Empl Position name" formControlName="emplPositionId" [options]="positionArray" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="margin-left: -6%;">
                                                        <label for="exampleInputEmail1">Status Id<span style="color:red">*</span> </label>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Status Id" formControlName="statusId" [options]="statusArray" optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Employment App Source<span style="color:red">*</span></label>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown class="lists" placeholder="Employment App Source Type Id" filter="true" formControlName="employmentAppSourceTypeId" [options]="appSourceArray" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="margin-left: -6%;">
                                                        <label for="exampleInputEmail1">Applying Party Id<span style="color:red">*</span></label>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <label>{{this.party}}</label>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Application Date<span style="color:red">*</span></label>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="applicationDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Referred By Party ID</label>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Refered By Party Id" class="lists" filter="true" formControlName="referredByPartyId" [options]="partyIdArray" optionlabel="label">
                                                        </p-dropdown>

                                                    </div>


                                                </div>
                                            </div>



                                        </div>
                                        <div class="main-submit-button" style="margin-right: 25%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmitEmployee()" class="btn btn-secondary submit-btn">Submit</button>
                                            <button type="submit" *ngIf="show" (click)="onUpdateEmployee()" class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalProduct" tabindex="-1" role="dialog" aria-labelledby="exampleModalProductTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Product Store Roles</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton24>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                    Add Product Store Roles
                </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                    Update Product Store Roles
                </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createContactProduct">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="margin-left: -8%;">
                                                        <label for="exampleInputEmail1">
                                                Store ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown *ngIf="!this.show" [options]="productStoresListArray" filter="true" formControlName="StoreID" placeholder="Enter Store ID" name="Parent Type ID">
                                                        </p-dropdown>
                                                        <input type="text" *ngIf="this.show" formControlName="StoreID" class="form-control" id="exampleInputEmail1" readonly aria-describedby="emailHelp" placeholder="Enter Party Id">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                RoleType ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown *ngIf="!this.show" [options]="allroleArray" filter="true" formControlName="RoleTypeID" placeholder="Enter RoleType ID" name="Parent Type ID">
                                                        </p-dropdown>
                                                        <input type="text" *ngIf="this.show" formControlName="RoleTypeID" class="form-control" id="exampleInputEmail1" readonly aria-describedby="emailHelp" placeholder="Enter Party Id">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="margin-left: -8%;">
                                                        <label for="exampleInputEmail1">
                                                From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" *ngIf="!this.show" formControlName="FromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party Id">
                                                        <input type="date" *ngIf="this.show" formControlName="FromDate" class="form-control" id="exampleInputEmail1" readonly aria-describedby="emailHelp" placeholder="Enter Party Id">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="ThroughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="margin-left: -8%;">
                                                        <label for="exampleInputEmail1">
                                                Sequence Num </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="SequenceNum" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>


                                                </div>
                                            </div>



                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 64%;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmitProduct();" class="btn btn-secondary submit-btn">Create</button>

                                        <button type="submit" *ngIf="this.show" (click)="onUpdateProduct();" class="btn btn-secondary submit-btn">Update</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>