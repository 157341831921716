<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Events > New</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Create Events</a></li>
                     
                     
          
          
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form *ngIf="createEventFormService.create_event_form"
                                [formGroup]="createEventFormService.create_event_form" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Event Name<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control small-input" formControlName="workEffortName"
                                                estimatedStartDate id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Event name">
                                            <ng-container
                                                *ngIf="this.createEventFormService.create_event_form.controls['workEffortName'].invalid && (this.createEventFormService.create_event_form.controls['workEffortName'].dirty || this.createEventFormService.create_event_form.controls['workEffortName'].touched)">
                                                <p class="validate-field"
                                                    *ngIf="this.createEventFormService.create_event_form.controls['workEffortName'].errors.required">
                                                    Event Name required!</p>
                                            </ng-container>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control small-input" formControlName="description"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Description">
                                            </div>
                                           
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Priority</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown styleClass="small-dropdown" [(ngModel)]="preSelectedPriority" [options]="priorityArray" optionlabel="label"
                                                formControlName="priority">
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">To Party ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown styleClass="small-dropdown" filter="true" formControlName="partyId"
                                                    [options]="partygroupArray" optionlabel="label"
                                                    placeholder="Select To Party ID">
                                                </p-dropdown>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Purpose</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown styleClass="small-dropdown" filter="true" [options]="purposeArray"
                                                    formControlName="workEffortPurposeTypeId" optionlabel="label"
                                                    placeholder="Select Purpose">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Estimated Start Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-calendar formControlName="estimatedStartDate"
                                                placeholder="Select Estimated Start Date"></p-calendar>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Estimated Completion Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-calendar formControlName="estimatedCompletionDate"
                                                    placeholder="Select Completion Date"></p-calendar>
                                            </div>
                                            
                                           
                                        </div>
                                    </div>
                                   
                                 




                                </div>

                                <div class="main-submit-button" style="margin-right: 28%;">
                                    <button type="submit" *ngIf="show" (click)="onUpdate()"
                                        class="btn btn-secondary submit-btn">Update </button>
                                    <button style="width:130px" type="submit" (click)="onSubmit()" *ngIf="!show"
                                        class="btn btn-secondary submit-btn">Create </button>
                                   
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner></ngx-spinner> 