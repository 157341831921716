import { DatePipe } from '@angular/common';
import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';  import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-product-store-groups-catalog',
  templateUrl: './product-store-groups-catalog.component.html',
  styleUrls: ['./product-store-groups-catalog.component.css']
})
export class ProductStoreGroupsCatalogComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;

  activeCategory = 1;
  activeCat=9;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  addGroup: any;
  ProductStoreGroupAll: any;
  typeCatalog: any;
  typeCatalogArray: any[]=[];
  addStoreGroup: any;
  ProductStoreType: any;
  ProductStoreTypeArray: any[]=[];
  PrimaryParentGroupID: any;
  PrimaryParentGroupIDArray: any[]=[];
  ProductStoreGroup: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,    private _location: Location,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) { 
    this.addGroup = this._FormBuilder.group({
      type: [''],
      name: [''],
      description: [''],
    });
    this.addStoreGroup = this._FormBuilder.group({
      storeType: [''],
      storeID: [''],
      name: [''],
      Description: [''],
    });
  }

  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this.getProductStoreGroupAll();
    this.getTypeCatalog();
    this.getPrimaryParentGroupID();
    this.getProductStoreType();

  }
  getPrimaryParentGroupID() {
    this.spinner.show();
    this.accountsService.getPrimaryParentGroupID().subscribe(res => {
      this.PrimaryParentGroupID = res.data[0].getPrimaryParentGroupID;
      this.spinner.hide();
      for (const value of this.PrimaryParentGroupID) {
        this.PrimaryParentGroupIDArray.push({
          label: value.productStoreGroupName+"["+value.productStoreGroupId+"]",
          value: value.productStoreGroupId
        })
      }
    })
  }
  getProductStoreType() {
    this.spinner.show();
    this.accountsService.getProductStoreType().subscribe(res => {
      this.ProductStoreType = res.data[0].getType;
      this.spinner.hide();
      for (const value of this.ProductStoreType) {
        this.ProductStoreTypeArray.push({
          label: value.description,
          value: value.productStoreGroupTypeId
        })
      }
    })
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  updateProduct(product) {
    this.spinner.show();
    this.ProductStoreGroup = product ;
   
      setTimeout(() => {
        const formValue = this.addStoreGroup;
        formValue.patchValue({
          storeType:  this.ProductStoreGroup.productStoreGroupTypeId,
          storeID: this.ProductStoreGroup.productStoreGroupId,
          name: this.ProductStoreGroup.productStoreGroupName,
          Description: this.ProductStoreGroup.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  updateProductStoreGroup(): void {
    this.spinner.show();
    const requestData ={
      "description": this.addStoreGroup.value.Description,
      "primaryParentGroupId": this.addStoreGroup.value.storeID,
      "productStoreGroupId": this.ProductStoreGroup.productStoreGroupId,
      "productStoreGroupName": this.addStoreGroup.value.name,
      "productStoreGroupTypeId": this.addStoreGroup.value.storeType
      }
     
    this.accountsService.updateProductStoreGroup(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getTypeCatalog() {
    this.spinner.show();
    this.accountsService.getTypeCatalog().subscribe(res => {
      this.typeCatalog = res.data[0].getType;
      this.spinner.hide();
      for (const value of this.typeCatalog) {
        this.typeCatalogArray.push({
          label: value.description,
          value: value.productStoreGroupTypeId
        })
      }
    })
  }
  createProductStoreGroup() {
    this.spinner.show();
    const formData = {
      "description": this.addGroup.value.description ,
      "productStoreGroupId": "",
      "productStoreGroupName": this.addGroup.value.name ,
      "productStoreGroupTypeId":  this.addGroup.value.type ,

    }
    this.accountsService.createProductStoreGroup(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Success");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.addGroup.reset();
      //  this.getParticipants();
       

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getProductStoreGroupAll() {
    this.spinner.show();
    this.accountsService.getProductStoreGroupAll().subscribe(res => {
      this.ProductStoreGroupAll = res.data[0].getProductStoreGroupAll;
      console.clear();
      console.log( "check", this.ProductStoreGroupAll);
      this.spinner.hide();
    })

  }
  dataSourceTypeDetails(id) {
    this._Router.navigate(["facilities/catalog/product-store-groups/editStoreGroups"], { queryParams: { productStoreGroupId: id } })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
