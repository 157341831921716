import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {
  activeCategory= 1;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  findLayoutForm: FormGroup;
  createTemplateForm: FormGroup;
  addTemplateForm: FormGroup;
  FindLayoutAll: any;
  ListLayoutAll: any;
  fromDateCate: string;
  throughDateCate: string;
  drDataResourceTypeIdArray: { label: string; value: string; }[];
  drDataTemplateTypeIdArray: { label: string; value: string; }[];
  drMimeTypeIdArray: { label: string; value: string; }[];
  surveyIdValue=true;
  surveyIdValueNew=true;
  show: boolean;
  subContent: boolean;
  editContent: boolean;
  contentId: any;
  route: any;
  edit: any;
  detailPageAll: any;
  contentIdCre: any;
  detailPageAllCre: any;
  StartDate: string;


  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    private _location: Location,
  ) 

  {
    this.drDataResourceTypeIdArray=[
      {
        label: 'DataBase Text',
        value: 'ELECTRONIC_TEXT'
      },
    ];
    this.drDataTemplateTypeIdArray=[
      {
        label: 'No Template',
        value: 'NONE'
      },
      {
        label: 'FreeMarker',
        value: 'FTL'
      },
      {
        label: 'WebMacro',
        value: 'WM'
      },
      {
        label: 'XSLT',
        value: 'XSLT'
      },
      {
        label: 'Screen widget',
        value: 'SCREEN_COMBINED'
      },
      {
        label: 'Form widget',
        value: 'FORM_COMBINED'
      },
    ];
    this.drMimeTypeIdArray=[
      {
        label: 'Html Text',
        value: 'text/html'
      },
      {
        label: 'Plain Text',
        value: 'text/plain'
      },
     
    ];

  this.addTemplateForm=this._FormBuilder.group({
    contentID:"",
    contentType:"",
    dataResourceID:"",
    contentName:"",
    description:"",
    drDataResourceTypeID:"",
    drMimeTypeID:"",
    filePath:"",
    mapKey:"",
    parentContent:"",
  })

    this.findLayoutForm=this._FormBuilder.group({
      contentID:"",
      contentName:"",
      description:"",
      createdByUser:"",
      createdDate:"",
      lastModifiedByUser:"",
      lastModifiedDate:"",
    })

    this.createTemplateForm=this._FormBuilder.group({
      contentID:"",
      contentType:"",
      dataResourceID:"",
      contentName:"",
      description:"",
      contentIDTo:"",
      mapKey:"",
      drDataResourceTypeID:"",
      drDataTemplateTypeID:"",
      drMimeTypeID:"",
      filePath:"",
      text:"",
      createdByUser:"",
      createdDate:"",
      lastModifiedByUser:"",
      lastModifiedDate:"",
    })
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.contentId = params["contentId"];
      this.route = params["route"];

    });
    this.show=true;
    this.subContent=true;
    this.editContent=true;
 
    this.FindLayout();
    this.ListLayout();

  }
  generatePDFTemplate() {
    this.spinner.show();
  
    this.myContactsService.generatePDFTemplate(this.contentId).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "TEMPLATE.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  
  deleteContent(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const StartDate=product.caFromDate
        this.StartDate=this.datePipe.transform(StartDate,"yyyy-MM-dd hh:mm:ss");
        
        this.myContactsService.deleteContent(product.contentId).subscribe(res => {
          this.ListLayout();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  MultiRespResetForm(){
    this.show=true;
    this.createTemplateForm.reset();

  }
  cloneLayout(): void {
    this.spinner.show();
    const requestData ={
      "contentId": this.contentId
      }
     
    this.accountsService.cloneLayout(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Clone Successfully Added");
        this.spinner.hide();
        this.ListLayout();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  redirctToEdit(id){
    this._Router.navigate(['/content/template'], { queryParams: { contentId: id} });
    this.editContent=false;
    this.activeCategory=3;
    setTimeout(() => {
      this.detailPage();
    }, 2000);
   
  }
  detailPage() {
    this.spinner.show();
    this.accountsService.detailPage(this.contentId).subscribe(res => {
      this.detailPageAll = res.data[0].detailPage[0];
      this.spinner.hide();
    })
  }
  updateLayoutSubContent(): void {
    this.spinner.show();
    const requestData ={
      "contentId": this.createTemplateForm.value.contentID,
      "contentIdTo": this.createTemplateForm.value.contentIDTo,
      "contentName": this.createTemplateForm.value.contentName,
      "contentTypeId": this.createTemplateForm.value.contentType,
      "contentTypeIdDisplay": '',
      "createdByUserLogin": this.createTemplateForm.value.createdByUser,
      "createdDate": this.createTemplateForm.value.createdDate,
      "description": this.createTemplateForm.value.description,
      "drDataResourceId": this.createTemplateForm.value.dataResourceID,
      "drDataResourceTypeId": this.createTemplateForm.value.drDataResourceTypeID,
      "drDataTemplateTypeId": this.createTemplateForm.value.drDataTemplateTypeID,
      "drMimeTypeId": this.createTemplateForm.value.drMimeTypeID,
      "drObjectInfo": this.createTemplateForm.value.filePath,
      "lastModifiedByUserLogin": this.createTemplateForm.value.lastModifiedByUser,
      "lastModifiedDate": this.createTemplateForm.value.lastModifiedDate,
      "mapKey": this.createTemplateForm.value.mapKey,
      "textData": this.createTemplateForm.value.text
      }
     
    this.accountsService.updateLayoutSubContent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.FindLayout();
        this.closebutton2.nativeElement.click();
        this.show=true;
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateSurveyPagePatch() {
    this.show=false;
    this.spinner.show();
    const fromDateCate=this.detailPageAll.createdDate;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.detailPageAll.lastModifiedDate;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
      setTimeout(() => {
        const formValue = this.createTemplateForm;
        formValue.patchValue({
          contentID:this.detailPageAll.contentId,
          contentType:this.detailPageAll.contentTypeId,
          dataResourceID:this.detailPageAll.dataResourceId,
          contentName:this.detailPageAll.contentName,
          description:this.detailPageAll.description,
          contentIDTo:"TEMPLATE_MASTER",
          drMimeTypeID:this.detailPageAll.mimeTypeId,
          createdByUser:this.detailPageAll.createdByUserLogin,
          createdDate:this.fromDateCate,
          lastModifiedByUser:this.detailPageAll.lastModifiedByUserLogin,
          lastModifiedDate:this.throughDateCate
        })
      }, 2000);
      this.spinner.hide();
  }
  updateSurveyPagePatchCre() {
    this.show=false;
    this.spinner.show();
    const fromDateCate=this.detailPageAllCre.createdDate;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.detailPageAllCre.lastModifiedDate;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
      setTimeout(() => {
        const formValue = this.createTemplateForm;
        formValue.patchValue({
          contentID:this.detailPageAllCre.contentId,
          contentType:this.detailPageAllCre.contentTypeId,
          dataResourceID:this.detailPageAllCre.dataResourceId,
          contentName:this.detailPageAllCre.contentName,
          description:this.detailPageAllCre.description,
          contentIDTo:"TEMPLATE_MASTER",
          drMimeTypeID:this.detailPageAllCre.mimeTypeId,
          createdByUser:this.detailPageAllCre.createdByUserLogin,
          createdDate:this.fromDateCate,
          lastModifiedByUser:this.detailPageAllCre.lastModifiedByUserLogin,
          lastModifiedDate:this.throughDateCate
        })
      }, 2000);
      this.spinner.hide();
  }




  createLayoutSubContentPatch() {
    this.spinner.show();
    this.MultiRespResetForm();
      setTimeout(() => {
        const formValue = this.createTemplateForm;
        formValue.patchValue({
            contentType:"DOCUMENT",
            contentIDTo:"TEMPLATE_MASTER",
            drDataResourceTypeID:"ELECTRONIC_TEXT",

        })
      }, 2000);
      this.spinner.hide();
  }
  createLayoutSubContent(): void {
    this.spinner.show();
    const fromDateCate=this.findLayoutForm.get('createdDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.findLayoutForm.get('lastModifiedDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "contentId": '',
      "contentIdTo": this.createTemplateForm.value.contentIDTo,
      "contentName": this.createTemplateForm.value.contentName,
      "contentTypeId": this.createTemplateForm.value.contentType,
      "contentTypeIdDisplay": '',
      "createdByUserLogin": this.createTemplateForm.value.createdByUser,
      "createdDate": this.fromDateCate,
      "description": this.createTemplateForm.value.description,
      "drDataResourceId": '',
      "drDataResourceTypeId": this.createTemplateForm.value.drDataResourceTypeID,
      "drDataTemplateTypeId": this.createTemplateForm.value.drDataTemplateTypeID,
      "drMimeTypeId": this.createTemplateForm.value.drMimeTypeID,
      "drObjectInfo": this.createTemplateForm.value.filePath,
      "lastModifiedByUserLogin": this.createTemplateForm.value.lastModifiedByUser,
      "lastModifiedDate": this.throughDateCate,
      "mapKey": this.createTemplateForm.value.mapKey,
      "textData": this.createTemplateForm.value.text
      }
     
    this.accountsService.createLayoutSubContent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.contentIdCre=res.data.contentId;
        this.detailPagecontentIdCre();
        this.FindLayout();
        this.closebutton2.nativeElement.click();
        this.subContent=false;
        this.activeCategory=4;
        this.createTemplateForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  detailPagecontentIdCre() {
    this.spinner.show();
    this.accountsService.detailPage(this.contentIdCre).subscribe(res => {
      this.detailPageAllCre = res.data[0].detailPage[0];
      this.spinner.hide();
    })
  }
  restLayout(){
    this.findLayoutForm.reset();
  }
  ListLayout() {
    this.spinner.show();
    this.accountsService.ListLayout().subscribe(res => {
      this.ListLayoutAll = res.data[0].ListLayout;
      this.spinner.hide();
    })
  }
  FindLayout(): void {
    this.spinner.show();
    const fromDateCate=this.findLayoutForm.get('createdDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");
    const throughDateCate=this.findLayoutForm.get('lastModifiedDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "contentId": this.findLayoutForm.value.contentID?this.findLayoutForm.value.contentID:'',
      "contentName": this.findLayoutForm.value.contentName?this.findLayoutForm.value.contentName:'',
      "createdByUserLogin": this.findLayoutForm.value.createdByUser?this.findLayoutForm.value.createdByUser:'',
      "createdDate": this.fromDateCate?this.fromDateCate:'',
      "description": this.findLayoutForm.value.description?this.findLayoutForm.value.description:'',
      "lastModifiedByUserLogin": this.findLayoutForm.value.lastModifiedByUser?this.findLayoutForm.value.lastModifiedByUser:'',
      "lastModifiedDate": this.throughDateCate?this.throughDateCate:'',
      }
    this.accountsService.FindLayout(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.FindLayoutAll = res.data;
        this.findLayoutForm.reset();
        
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

changeactiveCategory(tab: number)
{
  this.activeCategory = tab;

}

}
