import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
@Component({
  selector: 'app-create-party-login',
  templateUrl: './create-party-login.component.html',
  styleUrls: ['./create-party-login.component.css']
})
export class CreatePartyLoginComponent implements OnInit {
  show=false;
  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  status: string;
  partyIdArray: any[]=[];
  PartyInvitationStatusList: any;
  PartyInvitationStatusListArray: any[]=[];
  personId: any;
  invitataionForm:FormGroup;
  fromDate: string;
  inviteId: any;
  getEstimateFrom: string;
  groupId: any;
  userLogin: any;
  throughDate: string;
  efromDate: any;
  ethruDate: any;
  euserLogin: any;
  constructor(readonly spinner: NgxSpinnerService, 
    readonly accountsService: AccountsService, readonly toastr: ToastrService,
    readonly datePipe: DatePipe,  readonly addSkillService: AddSkillService, readonly _FormBuilder: FormBuilder,
    readonly router: Router,  readonly _ToastrService: ToastrService,
    readonly activate:ActivatedRoute, private globalResourceService:GlobalResourceService,private _location: Location,) {
      this.invitataionForm = this._FormBuilder.group({
        loginId: [''],
        fromDate: [''],
        throughDate:[''],
     
        
      });
     }

  ngOnInit(): void {
  this. getUserLoginIds();
    this.activate.queryParams.subscribe(params => {
      this.groupId = params["groupId"];
      this.efromDate = params["fromDate"];
      this.ethruDate = params["thruDate"];
      this.euserLogin = params["userLogin"];
   
      
    });
   
    if(this.euserLogin){
     this.show=true;
     setTimeout(() => {
      const efromDate=this.efromDate
      this.efromDate=this.datePipe.transform(efromDate,"yyyy-MM-dd");
      const ethruDate=this.ethruDate
      this.ethruDate=this.datePipe.transform(ethruDate,"yyyy-MM-dd");
      
      const formValue = this.invitataionForm;
      formValue.patchValue({
        loginId:this.euserLogin,
        fromDate:  this.efromDate,
        throughDate:this.ethruDate,
      })
    
    }, 3000);
    }
    else{
      this.show=false;
    }


  }
  getUserLoginIds(){
    this.globalResourceService.getUserLoginIds()
    .then(data=>{
      this.userLogin=data.data;
      this.userLogin.forEach((logindata:any)=>{
        var userLoginIdValue=logindata.userLoginId;
        var userLoginIdLabel=logindata.userLoginId;
        this.userLoginIdArray.push({
          label:userLoginIdValue,
          value:userLoginIdLabel
        })
      })
    });
  }
  
  onUpdate() {
    this.spinner.show();
    const fromDate=this.invitataionForm.get('fromDate').value;
    this.fromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const throughDate=this.invitataionForm.get('throughDate').value;
    this.throughDate=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
  
    const requestData = {
      "fromDate": this.fromDate,
      "groupId": this.groupId,
      "thruDate": this.throughDate,
      "userLoginId": this.invitataionForm.value.loginId,
    
    }
    this.accountsService.updateUserLoginToSecurityGroup(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onSubmit() {
    this.spinner.show();
    const fromDate=this.invitataionForm.get('fromDate').value;
    this.fromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const throughDate=this.invitataionForm.get('throughDate').value;
    this.throughDate=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
  
    const requestData = {
      "fromDate": this.fromDate,
      "groupId": this.groupId,
      "thruDate": this.throughDate,
      "userLoginId": this.invitataionForm.value.loginId,
    
    }
    this.accountsService.addUserLoginToSecurityGroup(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  GoToQuickLink(){
    this._location.back();
  }

}
