<div class="container-fluid main-container-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
          <span class="color-grey breadcrumbb"  (click)="cancel()" >Security Group</span>
          <span *ngIf="!show"  class="color-black pl-1"> > Create Security Group</span>
          <span *ngIf="show"  class="color-black pl-1"> > Update Security Group</span>
          <button type="submit"  (click)="cancel()"  class="btn btn-danger buttonclass">Cancel</button>
      </div>
        <div class=" bg-white color-grey create-new-table">
          <div class="w3-card-4 classCard" >
          <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass">
  
              <li  *ngIf="!show" [ngClass]="activeCategory==2?'active':''" ><a>
                Create Security Group</a></li>
                <li  *ngIf="show" [ngClass]="activeCategory==2?'active':''" ><a>
                  Update Security Group</a></li>
          
            </ul>
          
          </div>
          <div>
            <div class="container-fluid">
              <div class="row">
                  <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">  
                      <div class="panel-group"  role="tablist" aria-multiselectable="true">
                        <div class="panel panel-default">
                         
                          <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections"> <div class="panel-body">
                              <div class="mt-2">
                                <div class="">
                                  <div class="row">
                                    <form class="w-100" [formGroup]="createGroup">
                                      <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12" >
                                          <div class="row">
                                              <div class="col-lg-3 form-group rightForm">
                                                <label class="common-labels">
                                                  Security Group ID	</label>
                                              </div>
                                              <div class="col-lg-2">
                                                <input type="text"  class="form-control" formControlName="groupId"
                                                placeholder="Enter Security Group ID">
                                              </div>
                                              <div class="col-lg-3 form-group rightFormA">
                                                <label class="common-labels">Name</label>
                                              </div>
                                              <div class="col-lg-2">
                                                <input type="text"  class="form-control" formControlName="groupName"
                                                placeholder="Enter Name">
                                              </div>
                                             
                                          </div>
                                        </div>
                                        <div class="col-lg-12" >
                                          <div class="row">
                                              <div class="col-lg-3 form-group rightForm">
                                                <label class="common-labels">Description</label>
                                              </div>
                                              <div class="col-lg-2">
                                                <input type="text"  class="form-control" formControlName="description"
                                                placeholder="Enter Description">
                                              </div>
                                            
                                             
                                          </div>
                                        </div>

                                      
                                      
                                      </div>
                                      <div class="submit-btn-sales main-submit-button" style="padding-right: 310px;margin-top:-8px">
                                        <button  *ngIf="!show" (click)="submit()" type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                        <button  *ngIf="show" (click)="update()" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                      
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                      </div>
                    </div>
                  </div>
  
            </div>
  
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
     