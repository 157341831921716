import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-tasks',
  templateUrl: './detail-tasks.component.html',
  styleUrls: ['./detail-tasks.component.css']
})
export class DetailTasksComponent implements OnInit {
  activeCategory = 3;
  total = 0;
  rowNumber = 0;
  pageSize = 10;
  rows = 50;
  addNewTaskForm: any;
  editTaskForm: any;
  TaskMemberForm: any;
  partyId: any;
  ProjectMembersList: any;
  AssignTo: any;
  AssignToArray: any[] = [];
  ProjectID: any;
  ProjectIDArray: any[] = [];
  SprintBacklogList: any;
  SprintBacklogListArray: any[] = [];
  RoleTypeList: any;
  RoleTypeArray: any[] = [];
  fromDate: string;
  FromDate: string;
  ThroughDate: string;
  BacklogItemNoteList: any;
  custRequestId: any;
  productId: any;
  NoteOverviewList: any;
  workEffortId: any;
  ProjectMembersRoleList: any;
  UpdatePageList: any;
  workEffortName: any;
  TaskNoteForm: any;
  showMember = false;
  TaskAttachments: any;
  TaskOverview: any;
  ProjectMemberList: any;
  showStatus  = true;
  currentStatusId: any;
  StatusDescription: any;
  GroupId: any;
  GroupIdArray: any[]=[];
  activeCategoryValue: any;
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,  private _location: Location,
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly _Router: Router,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
  ) {
    this.editTaskForm = this._FormBuilder.group({
      ProjectID: [''],
      TaskId: [''],
      TaskType: [''],
      TaskName: [''],

      SprintBacklog: [''],
      PlanHours: [''],
      ActualHours: [''],
      Description: [''],
      AssignTo: [''],
    });

    this.TaskNoteForm = this._FormBuilder.group({
      NoteName: [''],
      NoteInfo: [''],
    });

    this.addNewTaskForm = this._FormBuilder.group({
      ProjectID: [''],
      TaskType: [''],
      TaskName: [''],
      SprintID: [''],
      SprintBacklog: [''],
      PlanHours: [''],
      Uploaded: [''],
      Description: [''],
      AssignTo: [''],
    });

    this.TaskMemberForm = this._FormBuilder.group({
      AssignTo: [''],
      AssignToo: [''],
      FromDate: [''],
      ThroughDate: [''],
      RoleTypeID: ['']
    });

  }

  ngOnInit(): void {
    this.spinner.show();
    this.activatedRoute.queryParams.subscribe(params => {
      this.workEffortName = params.workEffortName;
      this.partyId = params.partyId;
      this.workEffortId = params.workEffortId;
      this.custRequestId = params.custRequestId;
      this.productId = params.productId;
      this.activeCategoryValue = params.activeCategory;
    })
    this.activeCategory = this.activeCategoryValue;

    this.getNoteOverview();
    this.getTaskAttachments();
    this.getTaskOverview();
    this.getProjectMember();
    this.ProjectMembersRole();
    this.getUpdatePage();
    this.getProjectMembers();
    this.getRoleTypeList();
    this.getProjectID();
    this.getSprintBacklogList();
    this.getAssignToList();
    this.getGroupIdList();
  }

  changeAssignTo(){
    this.showMember = false;
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  addTask() {
    this.spinner.show();

    const requestData = {

      "actualHours": "",
      "contentTypeId": "DOCUMENT",
      "currentStatusId": "STS_CREATED",
      "custRequestId": "",
      "description": this.addNewTaskForm.value.Description,
      "mimeTypeId": "application/octet-stream",
      "partyId": "",
      "planHours": this.addNewTaskForm.value.PlanHours,
      "resourceStatusId": "CTNT_PUBLISHED",
      "uploadedFile": this.addNewTaskForm.value.Uploaded,
      "workEffortContentTypeId": "CREATED_MEDIA",
      "workEffortId": "",
      "workEffortName": "",
      "workEffortTypeId": ""
    }

    this.accountsService.createTaskForBacklog(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
 


 


  getTaskAttachments() {

    this.spinner.show();
    this.accountsService.getTaskAttachments(this.workEffortId).subscribe(res => {
      this.TaskAttachments = res.data;
      this.spinner.hide();
    })
  }

  getTaskOverview() {

    this.spinner.show();
    this.accountsService.getTaskOverview(this.workEffortId).subscribe(res => {
      this.TaskOverview = res.data[0];
      this.StatusDescription = this.TaskOverview.tasks.currentStatusId;
      this.spinner.hide();

    })
  }

  setTaskStatusRelease() {
    this.spinner.show();

    const requestData = {
      "currentStatusId": "STS_CREATED",
      "taskId": this.workEffortId,
      "workEffortId": this.workEffortId
    }

    this.accountsService.setTaskStatus(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  setTaskStatusHold() {
    this.spinner.show();

    const requestData = {
      "currentStatusId": "STS_ON_HOLD",
      "taskId": this.workEffortId,
      "workEffortId": this.workEffortId
    }

    this.accountsService.setTaskStatus(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  setTaskStatusComplete() {
    this.spinner.show();

    const requestData = {
      "currentStatusId": "STS_COMPLETED",
      "taskId": this.workEffortId,
      "workEffortId": this.workEffortId
    }

    this.accountsService.setTaskStatus(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  setTaskStatusCancel() {
    this.spinner.show();

    const requestData = {
      "currentStatusId": "STS_CANCELLED",
      "taskId": this.workEffortId,
      "workEffortId": this.workEffortId
    }

    this.accountsService.setTaskStatus(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  ////////////-----NOTE-------//////////////////


  deleteTaskNote(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "noteId": product.note[0].noteId,
          "workEffortId": this.workEffortId

        }
        this.accountsService.deleteTaskNote(formData).subscribe((res: any) => {
          this.spinner.hide();
          this.getNoteOverview();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getNoteOverview() {

    this.spinner.show();
    this.accountsService.getNoteOverview(this.workEffortId).subscribe(res => {
      this.NoteOverviewList = res.data;
      this.spinner.hide();
    })
  }

  createProdBacklogNote() {
    this.spinner.show();

    const requestData = {
      "noteInfo": this.TaskNoteForm.value.NoteInfo,
      "noteName": this.TaskNoteForm.value.NoteName,

      "workEffortId": this.workEffortId
    }

    this.accountsService.createTaskNote(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getNoteOverview();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  ////////////-----UPDATE-------//////////////////

  updateTask() {
    this.spinner.show();

    const requestData = {
      "custRequestId": this.custRequestId,
      "description": this.editTaskForm.value.Description,
      "partyId": this.partyId,
      "planHours": this.editTaskForm.value.PlanHours,
      "taskId": this.editTaskForm.value.TaskId,
      "workEffortId": this.workEffortId,
      "workEffortName": this.editTaskForm.value.SprintBacklog,
      "workEffortTypeId": this.editTaskForm.value.TaskType,

    }

    this.accountsService.updateTask(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.activeCategory = 2;

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }


  getUpdatePage() {

    this.spinner.show();
    this.accountsService.getUpdatePage(this.workEffortId).subscribe(res => {
      this.UpdatePageList = res.data[0];
      this.spinner.hide();
    })
  }


  ////////////////---------TASK MEMBER---------------/////////////////////

  getGroupIdList() {
    this.spinner.show();
    this.accountsService.getGroupIdList().subscribe(res => {
      this.GroupId = res.data;
      this.spinner.hide();
      for (const value of this.GroupId) {
        this.GroupIdArray.push({
          label: value.singleObject.description,
          value: value.singleObject.groupId
        })
      }
    })
  }

  removeMemberTask() {
    const fromdate = this.TaskMemberForm.get('FromDate').value;
    this.FromDate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const thruDate = this.TaskMemberForm.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");


    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {

          "fromDate": this.FromDate,
          "memberName": this.TaskMemberForm.value.AssignTo,
          "noConditionFind": "",
          "partyId": this.custRequestId,
          "roleTypeId": this.TaskMemberForm.value.RoleTypeID,
          "sprintId": this.workEffortId,
          "taskId": this.workEffortId,
          "thruDate": this.ThroughDate,
          "viewIndex_1": "",
          "viewSize_1": "",
          "workEffortId": this.workEffortId


        }
        this.accountsService.removeMemberTask(formData).subscribe((res: any) => {
          this.spinner.hide();
         

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }


  addMemberTask() {
    this.spinner.show();
    const fromdate = this.TaskMemberForm.get('FromDate').value;
    this.FromDate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const thruDate = this.TaskMemberForm.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {
      "fromDate": this.FromDate,
      "partyId": this.TaskMemberForm.value.AssignTo,
      "roleTypeId": this.TaskMemberForm.value.RoleTypeID,
      "sprintId": "",
      "statusId": "SCAS_ASSIGNED",
      "taskId": this.workEffortId,
      "thruDate": this.ThroughDate,
      "workEffortId": this.workEffortId,

    }

    this.accountsService.addMemberTask(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getProjectMembers();
        this.showMember = true;

        setTimeout(() => {
          const formValue = this.TaskMemberForm;
          formValue.patchValue({
            AssignToo : this.TaskMemberForm.value.AssignTo,
          })
        }, 2000);

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  ProjectMembersRole() {

    this.spinner.show();
    this.accountsService.ProjectMembersRole(this.partyId).subscribe(res => {
      this.ProjectMembersRoleList = res.data;
      this.spinner.hide();
    })
  }
  getProjectMember() {

    this.spinner.show();
    this.accountsService.getProjectMembers(this.partyId).subscribe(res => {
      this.ProjectMemberList = res.data;
      this.spinner.hide();
    })
  }
  getProjectMembers() {

    this.spinner.show();
    this.accountsService.getProjectMembers(this.partyId).subscribe(res => {
      this.ProjectMembersList = res.data;
      this.spinner.hide();
    })
  }

  getProjectID() {
    this.spinner.show();
    this.accountsService.getProjectID().subscribe(res => {
      this.ProjectID = res.data;
      this.spinner.hide();
      for (const value of this.ProjectID) {
        this.ProjectIDArray.push({
          label: value.ProjectID.workEffortName,
          value: value.ProjectID.workEffortName
        })
      }
    })
  }

  getRoleTypeList() {
    this.spinner.show();
    this.accountsService.getRoleTypeList().subscribe(res => {
      this.RoleTypeList = res.data;
      this.spinner.hide();
      {
        this.RoleTypeArray.push({
          label: this.RoleTypeList.SCRUM_TASK_TEST,
          value: this.RoleTypeList.SCRUM_TASK_TEST
        })

        this.RoleTypeArray.push({
          label: this.RoleTypeList.SCRUM_TASK_INST,
          value: this.RoleTypeList.SCRUM_TASK_INST
        })

        this.RoleTypeArray.push({
          label: this.RoleTypeList.SCRUM_TASK_IMPL,
          value: this.RoleTypeList.SCRUM_TASK_IMPL
        })

        this.RoleTypeArray.push({
          label: this.RoleTypeList.SCRUM_TASK_ERROR,
          value: this.RoleTypeList.SCRUM_TASK_ERROR
        })
      }
    })
  }

  getSprintBacklogList() {
    this.spinner.show();
    this.accountsService.getSprintBacklogList().subscribe(res => {
      this.SprintBacklogList = res.data;
      this.spinner.hide();
      for (const value of this.SprintBacklogList) {
        this.SprintBacklogListArray.push({
          label: value.description,
          value: value.custRequestId
        })
      }
    })
  }

  getAssignToList() {
    this.spinner.show();
    this.accountsService.getAssignToList().subscribe(res => {
      this.AssignTo = res.data.data;
      this.spinner.hide();
      for (const value of this.AssignTo) {
        this.AssignToArray.push({
          label: value.scrumTeamValue,
          value: value.scrumTeamName
        })
      }
    })
  }



  findPage() {
    this._Router.navigate(["/psa/tasks"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
