<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Resume Id [10000]</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==0?'active':''" (click)="changeactiveCategoryFunction(0)"><a>
                            Resume Information</a></li>




                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==0">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Resume

                                                        </h4>
                                                        <span class="edit-buttons">
                                                        <a  data-toggle="modal"  (click)="edit()"
                                                        data-target="#exampleModalCenter">Update</a></span>

                                                    </div>
                                                    <br>
                                                    <div>
                                                        <!--  <button type="button" style="width: 132px;"
                                                        class="btn btn-outline-secondary bg-brown ">Export as CSV</button>
                                                    &nbsp; <button type="button" style="width: 132px;"
                                                        class="btn btn-danger" routerLinkActive="active">Export as PDF</button> -->
                                                    </div>
                                                    <div class="w3-container">
                                                        <div class="panel-body">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">
                                                                                    Resume Id</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.resumeId}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Content ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.contentId}}</span>

                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Party ID
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.partyId}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Resume Date</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.resumeDate}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Resume Text</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.resumeText}}</span>
                                                                            </div>



                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>




                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{show?'Update':'Create'}} New Party Resume</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                            Create New Party Resume </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                Update New Party Resume </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createResume" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">
                                                        Resume ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ResumeID" disabled
                                                         class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Resume ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Content ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Content ID" [options]="ContentIDArray" optionlabel="label" formControlName="ContentID" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Party ID
                                                       </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Party ID" formControlName="PartyID" [options]="partyIdArray" optionlabel="label" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Resume Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="ResumeDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Date">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Resume Text</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ResumeText" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                                    </div>



                                                </div>
                                            </div>



                                        </div>

                                        <div class="main-submit-button" style="margin-right: 10%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn" (click)="updateResume()">Update</button>
                                            <button type="submit" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>