<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"  routerLink="/catalog/products/detail-product-summary/{{this.product}}" style="cursor: pointer;">Product Supplier
                </span>
                <span class="color-black pl-1" *ngIf="!show"> >Add Product Supplier
                </span>
                <span class="color-black pl-1" *ngIf="show"> >Update Product Supplier
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">

                        <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                            Add Product Supplier</a></li>
                        
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                Update Product Supplier</a></li>
                      

                    </ul>
                </div>
             
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100"  [formGroup]="createSupplierForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Supplier
                                                    <span
                                                    style="color:red">*</span>
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <p-dropdown *ngIf="!show"  placeholder="Enter Supplier" filter="true"
                                            [options]="supplierArray" optionlabel="label"
                                            formControlName="partyId">
                                                            
                                            </p-dropdown>
                                            <p-dropdown *ngIf="show"  placeholder="Enter Supplier" filter="true"
                                            [options]="supplierArray" optionlabel="label"
                                            formControlName="partyId" [disabled]="true">
                                                            
                                            </p-dropdown>
                                            <small class="text-danger"
                                            *ngIf="!!createSupplierForm.controls.partyId.invalid && !!createSupplierForm.controls.partyId.touched">   Supplier Id is required!</small>
    
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Available From Date<span
                                                    style="color:red">*</span>
                                                    
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="date" *ngIf="!show"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Available From Date"
                                                formControlName="availableFromDate" >
                                                <input type="date" *ngIf="show"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Available From Date"
                                                formControlName="availableFromDate" readonly>
                                                <small class="text-danger"
                                                *ngIf="!!createSupplierForm.controls.availableFromDate.invalid && !!createSupplierForm.controls.availableFromDate.touched">  Available From Date is required!</small>
        
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Min Order Qty<span
                                                    style="color:red">*</span>
                                                    
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="email"  *ngIf="!show"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Min Order Qty"
                                                formControlName="minimumOrderQuantity" >
                                                <input type="email"  *ngIf="show"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Min Order Qty"
                                                formControlName="minimumOrderQuantity" readonly>
                                                <small class="text-danger"
                                                *ngIf="!!createSupplierForm.controls.minimumOrderQuantity.invalid && !!createSupplierForm.controls.minimumOrderQuantity.touched">  Min Order Qty is required!</small>
        
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Currency Uom ID
                                                    <span
                                                    style="color:red">*</span>
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <p-dropdown *ngIf="!show"   placeholder="Enter Currency Uom ID" filter="true"
                                            [options]="currencyArray" optionlabel="label"
                                            formControlName="currencyUomId" >
                                                            
                                            </p-dropdown>
                                            <p-dropdown *ngIf="show"   placeholder="Enter Currency Uom ID" filter="true"
                                            [options]="currencyArray" optionlabel="label"
                                            formControlName="currencyUomId" [disabled]="true">
                                                            
                                            </p-dropdown>
                                            <small class="text-danger"
                                            *ngIf="!!createSupplierForm.controls.currencyUomId.invalid && !!createSupplierForm.controls.currencyUomId.touched"> Currency Uom ID is required!</small>
    
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Available Through Date
                                                
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Available Through Date"
                                                formControlName="availableThruDate" >
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Supplier Pref Order ID</label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Supplier Pref Order ID" filter="true"
                                            [options]="supplierPrefOrderArray" optionlabel="label"
                                            formControlName="supplierPrefOrderId">
                                                            
                                            </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Supplier Rating Type ID</label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Supplier Rating Type ID" filter="true"
                                                formControlName="supplierRatingTypeId" >
                                                                
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Standard Lead Time Days
                                                
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Standard Lead Time Days"
                                                formControlName="standardLeadTimeDays" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Order Qty Increments
                                                
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Order Qty Increments"
                                                formControlName="orderQtyIncrements" >
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Units Included
                                                
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Units Included"
                                                formControlName="unitsIncluded" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Quantity UomId</label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Quantity UomId" filter="true"
                                                [options]="quantityUomIDArray" optionlabel="label"
                                                formControlName="quantityUomId">
                                                                
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Agreement ID
                                                
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Agreement ID"
                                                formControlName="agreementId">
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Agreement Item Seq ID
                                                
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Agreement Item Seq ID"
                                                formControlName="agreementItemSeqId">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Last Price<span
                                                    style="color:red">*</span>
                                                    
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Last Price"
                                                formControlName="lastPrice">
                                                <small class="text-danger"
                                                *ngIf="!!createSupplierForm.controls.lastPrice.invalid && !!createSupplierForm.controls.lastPrice.touched"> Last Price is required!</small>
        
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Shipping Price
                                                
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Shipping Price"
                                                formControlName="shippingPrice">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Supplier Product Name
                                                
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Supplier Product Name"
                                                formControlName="supplierProductName" >
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Supplier Product ID<span
                                                    style="color:red">*</span>
                                                    
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Supplier Product ID" filter="true"
                                                formControlName="supplierProductId" 
                                                [options]="ProductsArray" optionlabel="label">
                                                                
                                                </p-dropdown>
                                                <small class="text-danger"
                                                *ngIf="!!createSupplierForm.controls.supplierProductId.invalid && !!createSupplierForm.controls.supplierProductId.touched"> Supplier ProductId is required!</small>
        
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Supplier can drop ship?</label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Supplier can drop ship" filter="true"
                                            [options]="drop" optionlabel="label" 
                                            formControlName="canDropShip">
                                                            
                                            </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Comments
                                                
                                                </label>
                                            </div>
                                          
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Comments"
                                                formControlName="comments">
                                            </div>
                                        </div>
                                    </div>

                                 
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 57%;">
                                <button type="submit" *ngIf="show" (click)="onUpdate();"  class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" (click)="onSubmit()" *ngIf="!show"   class="btn btn-secondary submit-btn">Submit</button>
                                
                                <button type="submit"  routerLink="/catalog/products/detail-product-summary/{{this.product}}" class="btn btn-degault cncl-btn">Cancel</button>
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->


<div class="modal fade" id="supplierModalCenter" tabindex="-1" role="dialog" aria-labelledby="supplierModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1" *ngIf="!show">Add Product Supplier
                </span>
                <span class="color-black pl-1" *ngIf="show">Update Product Supplier
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                                        Add Product Supplier</a></li>

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                        Update Product Supplier</a></li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createSupplierForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Supplier
                                                            <span style="color:red">*</span>
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown *ngIf="!show" placeholder="Enter Supplier"
                                                            filter="true" [options]="supplierArray" optionlabel="label"
                                                            formControlName="partyId">

                                                        </p-dropdown>
                                                        <p-dropdown *ngIf="show" placeholder="Enter Supplier"
                                                            filter="true" [options]="supplierArray" optionlabel="label"
                                                            formControlName="partyId" [disabled]="true">

                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!createSupplierForm.controls.partyId.invalid && !!createSupplierForm.controls.partyId.touched">
                                                            Supplier Id is required!</small>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Available From Date<span
                                                                style="color:red">*</span>

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" *ngIf="!show" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Available From Date"
                                                            formControlName="availableFromDate">
                                                        <input type="date" *ngIf="show" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Available From Date"
                                                            formControlName="availableFromDate" readonly>
                                                        <small class="text-danger"
                                                            *ngIf="!!createSupplierForm.controls.availableFromDate.invalid && !!createSupplierForm.controls.availableFromDate.touched">
                                                            Available From Date is required!</small>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Min Order Qty<span
                                                                style="color:red">*</span>

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" *ngIf="!show" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Min Order Qty"
                                                            formControlName="minimumOrderQuantity">
                                                        <input type="email" *ngIf="show" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Min Order Qty"
                                                            formControlName="minimumOrderQuantity" readonly>
                                                        <small class="text-danger"
                                                            *ngIf="!!createSupplierForm.controls.minimumOrderQuantity.invalid && !!createSupplierForm.controls.minimumOrderQuantity.touched">
                                                            Min Order Qty is required!</small>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Currency Uom ID
                                                            <span style="color:red">*</span>
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown *ngIf="!show" placeholder="Enter Currency Uom ID"
                                                            filter="true" [options]="currencyArray" optionlabel="label"
                                                            formControlName="currencyUomId">

                                                        </p-dropdown>
                                                        <p-dropdown *ngIf="show" placeholder="Enter Currency Uom ID"
                                                            filter="true" [options]="currencyArray" optionlabel="label"
                                                            formControlName="currencyUomId" [disabled]="true">

                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!createSupplierForm.controls.currencyUomId.invalid && !!createSupplierForm.controls.currencyUomId.touched">
                                                            Currency Uom ID is required!</small>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Available Through Date

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Available Through Date"
                                                            formControlName="availableThruDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Supplier Pref Order ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Supplier Pref Order ID"
                                                            filter="true" [options]="supplierPrefOrderArray"
                                                            optionlabel="label" formControlName="supplierPrefOrderId">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Supplier Rating Type ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Supplier Rating Type ID"
                                                            filter="true" formControlName="supplierRatingTypeId">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Standard Lead Time Days

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Standard Lead Time Days"
                                                            formControlName="standardLeadTimeDays">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order Qty Increments

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Order Qty Increments"
                                                            formControlName="orderQtyIncrements">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Units Included

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Units Included"
                                                            formControlName="unitsIncluded">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quantity UomId</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Quantity UomId" filter="true"
                                                            [options]="quantityUomIDArray" optionlabel="label"
                                                            formControlName="quantityUomId">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Agreement ID

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Agreement ID"
                                                            formControlName="agreementId">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Agreement Item Seq ID

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Agreement Item Seq ID"
                                                            formControlName="agreementItemSeqId">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Last Price<span
                                                                style="color:red">*</span>

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Last Price"
                                                            formControlName="lastPrice">
                                                        <small class="text-danger"
                                                            *ngIf="!!createSupplierForm.controls.lastPrice.invalid && !!createSupplierForm.controls.lastPrice.touched">
                                                            Last Price is required!</small>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipping Price

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Shipping Price"
                                                            formControlName="shippingPrice">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Supplier Product Name

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Supplier Product Name"
                                                            formControlName="supplierProductName">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Supplier Product ID<span
                                                                style="color:red">*</span>

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Supplier Product ID"
                                                            filter="true" formControlName="supplierProductId"
                                                            [options]="ProductsArray" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!createSupplierForm.controls.supplierProductId.invalid && !!createSupplierForm.controls.supplierProductId.touched">
                                                            Supplier ProductId is required!</small>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Supplier can drop ship?</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Supplier can drop ship"
                                                            filter="true" [options]="drop" optionlabel="label"
                                                            formControlName="canDropShip">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Comments

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comments"
                                                            formControlName="comments">
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 57%;">
                                        <button type="submit" *ngIf="show" (click)="onUpdate();"
                                            class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" (click)="onSubmit()" *ngIf="!show"
                                            class="btn btn-secondary submit-btn">Submit</button>

                                        <button type="submit"
                                            routerLink="/catalog/products/detail-product-summary/{{this.product}}"
                                            class="btn btn-degault cncl-btn">Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>