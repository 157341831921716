import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { WarehouseService } from '../../warehouse/warehouse.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
@Component({
    selector: 'app-create-edit-facility-location',
    templateUrl: './create-new-facility-location.component.html'
})
export class CreateNewFacilityLocationComponent implements OnInit {
    editMode: boolean;
    facilityId: string;
    activeCategory = 2;
    locationForm: FormGroup;
    locationTypes: any[];
    locationData: any;
    locationSeqId: string;
    total=0;
    rowNumber=0;
    pageSize = 10;
    rows = 50;
    faEdit = faEdit;
    faTrash = faTrash;
    productList: any[];
    productForm: FormGroup;
    productIds: any[];
    editTableProduct: boolean;
    productValue: any;
    url: string;
    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _WarehouseService: WarehouseService,
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router,
        readonly _MainCatalogService: MainCatalogService
    ) {
        this.url='/facilities/warehouse/detailed-summary-facilities'
        this.locationForm = this._FormBuilder.group({
            aisleId: [''],
            areaId: [''],
            levelId: [''],
            locationSeqId: [''],
            locationTypeEnumId: null,
            sectionId: [''],
            positionId: ['']
        });
        this.locationTypes = [];
        this.editMode = false;
        this.facilityId = '';
        this.locationSeqId = '';
        this.constructorProduct();
    }
    constructorProduct(): void {
        this.productForm = this._FormBuilder.group({
            minimumStock: [''],
            moveQuantity: [''],
            productId: ['']
        });
        this.productIds = [];
        this.editTableProduct = false;
        this.productList = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.facilityId = this._ActivatedRoute.snapshot.queryParams.facilityId;
        this.locationSeqId = this._ActivatedRoute.snapshot.queryParams.locationSeqId;
        if (this.locationSeqId) {
            this.editMode = true;
            this._WarehouseService.getLocationById(this.facilityId, this.locationSeqId)
                .then(data => {
                    if (data.success) {
                        this.locationData = data.data;
                        this.locationForm.patchValue({
                            aisleId: data.data.aisle,
                            areaId: data.data.area,
                            levelId: data.data.level,
                            locationSeqId: data.data.locationSeqId,
                            locationTypeEnumId: data.data.locationTypeEnumId,
                            sectionId: data.data.section,
                            positionId: data.data.position
                        });
                    }
                });
            this.getProducts();
            this.getProductList();
        }
        this.getLocationType();
    }
    getProductList(): void {
        this.spinner.show();
        this._WarehouseService.getFacilityProducts(this.facilityId, this.locationSeqId)
            .then(data => {
                if (data.success) {
                    this.productList = data.data;
                    this.spinner.hide();
                }
            });
           
    }
    getProducts(): void {
        this.spinner.show();
        this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
            .then(data => {
                this.spinner.hide();
                this.productIds = data.data.map(value => {
                    return {
                        label: value.productId,
                        value: value.productId
                    };
                });
            });
          
    }
    getLocationType(): void {
        this.spinner.show();
        this._WarehouseService.getLocationType()
            .then(data => {
                this.spinner.hide();
                this.locationTypes = data.data;
            });
            
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this._WarehouseService.updateFacilityLocation(this.facilityId, {
                ...this.locationData, ...this.locationForm.value
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.locationForm.reset();
                        this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                    }
                });
               
        } else {
            this._WarehouseService.createFacilityLocation(this.facilityId, this.locationForm.value)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.locationForm.reset();
                        this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
                    }
                });
        }
      
    }
    submitProduct(): void {
        this.spinner.show();
        if (this.editTableProduct) {
            this.updateProduct();
            this.spinner.hide();
        } else {
            this._WarehouseService.createFacilityProduct(this.facilityId, this.locationSeqId, this.productForm.value)
                .then(data => {

                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.productForm.reset();
                        this.spinner.hide();
                        this.getProductList();
                    }
                });
        }
      
    }
    updateProduct(): void {
        this.spinner.show();
        this._WarehouseService.updateFacilityProduct(this.facilityId, this.locationSeqId, { ...this.productValue, ...this.productForm.value })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.productForm.reset();
                    this.spinner.hide();
                    this.editTableProduct = false;
                    this.getProductList();
                }
            });
           
    }
    makeEditable(productId: string, minimumStock: string, moveQuantity: string): void {
        this.productForm.patchValue({
            productId, minimumStock, moveQuantity
        });
        this.productValue = { productId, minimumStock, moveQuantity };
        this.editTableProduct = true;
    }
    deleteProduct(productId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._WarehouseService.deleteFacilityProduct(this.facilityId, this.locationSeqId, productId)
                .then(data => {
                    if (data.success) {
                      
                        this.getProductList();
                    }
                });
              
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
           
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })
      
    }
    productCancel(): void {
        this.productForm.reset();
        this.editTableProduct = false;
    }
    onCancel(): void {
        this._Router.navigate([this.url], { queryParams: { facilityId: this.facilityId } });
    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}