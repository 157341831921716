
<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100">
                   
                    <div class="mt-3 d-flex justify-content-between" style="margin-bottom: 10px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 16px!important;">Terminology</span>
                        <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin-right:1%!important";>Save</button>

                    </div>                    

                    
                    

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Work Zones</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyworkZonesForm">
                                                <div class=" ">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para">
                                                            <p>What do you call the work zones that your customers
                                                                are in? This is only important if you have more than
                                                                one work zone.
                                                            </p>

                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">
                                                            <input type="radio" formControlName="communities">
                                                            <label class="ml-1">
                                                                Communities
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="property">
                                                            <label class="ml-1">
                                                                Property
                                                            </label>
                                                            
                                                        </div>
                                                      

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="serviceAreas">
                                                            <label class="ml-1">
                                                                Service Areas
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="workZone">
                                                            <label class="ml-1">
                                                                Work Zone
                                                            </label>
                                                            
                                                        </div>

                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Site</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyforSiteForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you the customers loactions that you go to in order 
                                                                to provide service ?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio">
                                                            <label class="ml-1">Jobs</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="jobs">
                                                            <label class="ml-1">
                                                                Service Location
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="sales">
                                                            <label class="ml-1">
                                                                Sales
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="stores">
                                                            <label class="ml-1">
                                                                Stores
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="section">
                                                            <label class="ml-1">
                                                                Section
                                                            </label>
                                                            
                                                        </div>

                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Customers</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyforCustomersForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call the people or entities that you service ?
                                                                These are also the people that you would send an invoice
                                                                to 
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" formControlName="clients">
                                                            <label class="ml-1">Clients</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="customers">
                                                            <label class="ml-1">
                                                                Cusotmers
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="guests">
                                                            <label class="ml-1">
                                                                Guests
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="residents" >
                                                            <label class="ml-1">
                                                                Residents
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="tenants">
                                                            <label class="ml-1">
                                                                Tenants
                                                            </label>
                                                            
                                                        </div>

                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Work Orders</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyWorkOrdersForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call work orders ?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio">
                                                            <label class="ml-1">Service Orders</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="serviceOrders">
                                                            <label class="ml-1">
                                                                Service Requests
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="serviceTickets">
                                                            <label class="ml-1">
                                                                Service Tickets
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="workOrders">
                                                            <label class="ml-1">
                                                                Work Orders
                                                            </label>
                                                            
                                                        </div>

                                                        

                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Repair Categories</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyRepairCategoriesForm"> 
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call the categories that describes the root cause or service performed
                                                                on a work order?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" formControlName="operationalCategories">
                                                            <label class="ml-1">Operational Categories</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="repairCategories">
                                                            <label class="ml-1">
                                                                Repair Categories
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="rootCauseCategories">
                                                            <label class="ml-1">
                                                                Root Cause Categories
                                                            </label>
                                                            
                                                        </div>

                                                        

                                                        

                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Repair Codes</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyRepairCodesForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call codes that describe the root cause or service performed 
                                                                on a work order?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" formControlName="operationCodes">
                                                            <label class="ml-1">Operational Codes</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="repairCodes">
                                                            <label class="ml-1">
                                                                Repair Codes
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="rootCauseCodes">
                                                            <label class="ml-1">
                                                                Root Cause Codes
                                                            </label>
                                                            
                                                        </div>

                                                        

                                                        

                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Service</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyServicesForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call the services that you perform for your 
                                                                customers? You select a symptom when you are describing 
                                                                the problem on a work order?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" formControlName="complaint">
                                                            <label class="ml-1">Complaint</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="customerInstructions">
                                                            <label class="ml-1">
                                                                Cusotmer Instructions
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="problem">
                                                            <label class="ml-1">
                                                                Problem
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="service">
                                                            <label class="ml-1">
                                                                Service 
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="symptom">
                                                            <label class="ml-1">
                                                                Symptom
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="task">
                                                            <label class="ml-1">
                                                                Task
                                                            </label>
                                                            
                                                        </div>

                                                        

                                                        

                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>


                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Skills</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12"  [formGroup]="terminologySkillsForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call the Skills required by your Technician to complete
                                                                a work order?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" formControlName="exprertise">
                                                            <label class="ml-1">Expertise</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="skills">
                                                            <label class="ml-1">
                                                                Skills
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="specialities">
                                                            <label class="ml-1">
                                                                Specialities
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="trades">
                                                            <label class="ml-1">
                                                                Trades
                                                            </label>
                                                            
                                                        </div>

                                                    
                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Dispatchers</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyDispatchersForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call the people that take calls from your customers
                                                                and assign work orders?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" formControlName="csr">
                                                            <label class="ml-1">CSR</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="customerService">
                                                            <label class="ml-1">
                                                                Customer Service Representatives
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="dispatchers">
                                                            <label class="ml-1">
                                                                Dispatchers
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="serviceRepresentatives">
                                                            <label class="ml-1">
                                                                Service Representatives
                                                            </label>
                                                            
                                                        </div>

                                                    
                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Service Address </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyServiceAddressForm" >
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call the address of this location where the 
                                                                work order is to be performed?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>
                                                   
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" formControlName="">
                                                            <label class="ml-1">Address</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="driveToAddress">
                                                            <label class="ml-1">
                                                                Drive to Address
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="jobLocation">
                                                            <label class="ml-1">
                                                                Job Location
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="serviceAddress">
                                                            <label class="ml-1">
                                                                Service Address
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="siteAddress">
                                                            <label class="ml-1">
                                                                Site Address
                                                            </label>
                                                            
                                                        </div>

                                                    
                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Category/Equipment </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyCategoryForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call the category for the services you provide
                                                                for your customers?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>
                                                    
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" formControlName="assetEquipment">
                                                            <label class="ml-1">Asset/Equipment</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="category">
                                                            <label class="ml-1">
                                                                Category
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="categoryEquipment">
                                                            <label class="ml-1">
                                                                Category/Equipment
                                                            </label>
                                                            
                                                        </div>

                                                        

                                                    
                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Techs </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyForTechsForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call the people that do work orders in your company?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>
                                                   
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" formControlName="engineers">
                                                            <label class="ml-1">Engineers</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="serviceTechs" >
                                                            <label class="ml-1">
                                                                Service Techs
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="techs">
                                                            <label class="ml-1">
                                                                Techs
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="workMen">
                                                            <label class="ml-1">
                                                                Workmen
                                                            </label>
                                                            
                                                        </div>

                                                        

                                                    
                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px;  margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Service Teams </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyServiceTeamsForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call the teams of Techs in your company?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>
                                                    
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" formControlName="maintenanceTeams">
                                                            <label class="ml-1">Maintenance Teams</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="serviceTechs">
                                                            <label class="ml-1">
                                                                Service Techs
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="workTeams">
                                                            <label class="ml-1">
                                                                Work Teams
                                                            </label>
                                                            
                                                        </div>

                                                        

                                                        

                                                    
                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Dispatch Centers</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyDispatchCentersForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call the place, or group of people, that your 
                                                                customers call to request service?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" formControlName="branches">
                                                            <label class="ml-1">Branches</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="dispatchCenters">
                                                            <label class="ml-1">
                                                                Dispatch Centers
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="managementOffices">
                                                            <label class="ml-1">
                                                                Management Offices
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="serviceCenters">
                                                            <label class="ml-1">
                                                                Service Centers
                                                            </label>
                                                            
                                                        </div>

                                                        

                                                        

                                                    
                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px!important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black;">
                                        Terminology for Service Agreements</p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12" [formGroup]="terminologyServiceAgreementsForm">
                                                    <div class="col-lg-12">
                                                        <div class="row mb-3  para" >
                                                            <p>What do you call service agreements?
                                                                
                                                            </p>

                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mt-1">

                                                            
                                                            <input type="radio" fomrControlName="energyServiceAgrrements">
                                                            <label class="ml-1">Energy Savings Agreements</label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="maintenanceAgreements">
                                                            <label class="ml-1">
                                                                Maintenance Agreements
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="plannedMaintenanceAgreements" >
                                                            <label class="ml-1">
                                                                Planned Maintenance Agreements
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="preventiveMaintenanceAgreements">
                                                            <label class="ml-1">
                                                                Preventive Maintenance Agreements
                                                            </label>
                                                            
                                                        </div>

                                                        <div class="row d-flex">
                                                            <input type="radio" formControlName="serviceAgreements">
                                                            <label class="ml-1">
                                                                Service Agreements
                                                            </label>
                                                            
                                                        </div>

                                                        

                                                        

                                                    
                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    

            
                    

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


       

<!-- <ngx-spinner></ngx-spinner> -->





