import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {Router} from "@angular/router";
import {AccountsService} from "../../crm/accounts/accounts.service";
import {MyContactsService} from "../../crm/contacts/my-contacts/my-contacts.service";
import {ActivatedRoute} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { TimeSheetService } from '../timesheet/timesheet.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.css']
})
export class CreateRoleComponent implements OnInit {
  fileUploadForm: FormGroup;
  accountData: any;
  activeCategory = 2;
  accountArray: any=[];
  partyId: any;
  groupNameSearchType='Contains'
  contactMechTypeId:any='';
  groupName='';
  partyIds='';
  partyIdSearchType='Contains';
  timesheetid: any;
  partyIdArray: any=[];
  roleArray: any=[];
  constructor(readonly formBuilder:FormBuilder,
    readonly router:Router,readonly timeSheetService:TimeSheetService,
    readonly activatedRoute:ActivatedRoute,
    readonly MyContactsService:MyContactsService,
    readonly accountsService:AccountsService,
    readonly spinner:NgxSpinnerService,
    readonly toastr:ToastrService
    ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.timesheetid = params["timesheetid"];
    });
  
    this.fileUploadForm = this.formBuilder.group({
      roleTypeId: [''],
      partyId:[''],
      
    });
    this.getPartyId();
    this.getRoleList();
}
getPartyId() {
  this.spinner.show();
  this.timeSheetService.getPartyId().subscribe(res=> {
    this.partyId=res.data.PersonsGroups;
    this.spinner.hide();
    for(const value of this.partyId) {
      this.partyIdArray.push({
        label:value.partyId,
        value:value.partyId
      })
    }
  })
 
}
navigate(){
  this.router.navigate(['/hr/timesheets/detail-timesheet-summary'],{queryParams:{timesheetid:this.timesheetid}})
  }
getRoleList() {
  this.spinner.show();
  this.timeSheetService.getRoleList().subscribe(res=> {
    this.partyId=res.data;
    for(const value of this.partyId) {
      this.roleArray.push({
        label:value.description,
        value:value.roleTypeId
      })
    }
  })
  this.spinner.hide();
}
onSubmit(){
  this.spinner.show();
const formdata={
  "partyId": this.fileUploadForm.value.partyId,
  "roleTypeId": this.fileUploadForm.value.roleTypeId,
  "timesheetId": this.timesheetid
}

  this.timeSheetService.postRole(formdata).subscribe((res:any)=> {
    if(res.success) {
      this.toastr.success(res.data.responseMessage);
      this.spinner.hide();
      this.router.navigate(['/hr/timesheets/detail-timesheet-summary'],{queryParams:{timesheetid:this.timesheetid}})
    }      }
    ,(err) => {
      for(const value of err.error.errors){
      this.toastr.error(value.fieldName + " " + value.fieldError);
      }}
    )
    this.spinner.hide();
}
ngOnDestroy(): void {
  this.spinner.hide();
    }
}