import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fsm-dashboard',
  templateUrl: './fsm-dashboard.component.html',
  styleUrls: ['./fsm-dashboard.component.css']
})
export class FSMDashboardComponent implements OnInit {
  activeCategory=2;
  activeCategoryValue=2;
  constructor(
    private _location: Location,
    readonly router: Router
  ) { }

  ngOnInit(
  ): void {
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/hub/Dashboard']);
  }
  

}
