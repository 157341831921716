<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"></span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        
            </div>

            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                Overview </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)"><a>
                                Update
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)"><a>

                                Notes
                            </a></li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)"><a>
                                Content</a></li>

                        <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)"><a>
                                Communications</a></li>

                                <li hidden [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)"><a>
                                    New Task</a></li>

                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 pt-0 mt-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">

                                                <div *ngIf="activeCategory==1">
                                                    <div class="container-fluid" style="width: 103% !important;
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;
                                                    padding: 10px !important;">
                                                       
                                                                <button type="submit" (click)="newProductBacklogItem()"
                                                                    class="btn btn-secondary submit-btn ">
                                                                    New Backlog Item
                                                                </button>

                                                                <button type="submit" (click)="billed()"
                                                                    class="btn btn-secondary submit-btn ml-2"> Not
                                                                    Billed
                                                                </button>
                                                                <button type="submit" (click)="deleteBacklogItem()"
                                                                    class="btn btn-danger ml-2"> Delete



                                                                </button>
                                                                <button type="submit" (click)="changeactiveCategory(8)"
                                                                    class="btn btn-secondary submit-btn ml-2"> New Task
                                                                </button>
                                                          
                                                    </div>
                                                    <div class="divA">
                                                        <div><p style="color: #0d3769;font-weight: bold;font-size: 15px;">
                                                            Product Backlog Item:<span>{{this.description + this.custRequestId}}</span></p></div>
                                                        
                                                        <br>
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling hleft">

                                                                    Product Backlog Item Info
                                                                </h4>
                                                            </div>

                                                            <div class="panel-body">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="row">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Product Name </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12 font-weight-bold"
                                                                                        >{{this.SprintOverview.productName}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Company</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Company}}
                                                                                    </span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Product Owner</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOwner}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Request name </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].parentCustRequestId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Product Backlog Item </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.description}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Plan Time </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.hours}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Actual Hours </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.ActualHours}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Cust Sequence Num </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].custSequenceNum}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Billed </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].billed}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Unplanned </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Unplanned}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Status </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].statusId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Story</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Story}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        How to test </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Howtotest}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <br>


                                                        <br>
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Revisions
                                                                </h4>
                                                            </div>
                                                            <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div
                                                                        class="card own-account-table">
                                                                        <p-table
                                                                            [value]=""
                                                                            [paginator]="true"
                                                                            [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template
                                                                                pTemplate="header">
                                                                                <tr
                                                                                    style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            

                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div
                                                                                            style="color: white;">
                                                                                            
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div
                                                                                            style="color: white;">
                                                                                           
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div
                                                                                            style="color: white;">
                                                                                            
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div
                                                                                        style="color: white;">
                                                                                       
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template
                                                                                pTemplate="body"
                                                                                let-product>
                                                                                <tr>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'150px'}">
                                                                                        <div
                                                                                            class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                                 class="account-button"  >
                                                                                                {{product.contentTypeId}}</span>

                                                                                        </div>
                                                                                    </td>

                                                                                    <td
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        {{product.imageURL}}
                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        {{product.createdStamp
                                                                                        |date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}

                                                                                    </td>
                                                                                    <td
                                                                                    [ngStyle]="{'width':'180px'}">
                                                                                    {{product.imageURL}}
                                                                                </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                        <button
                                                                                        
                                                                                            type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2">
                                                                                            Delete
                                                                                        </button>


                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template
                                                                                pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span
                                                                                            class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No
                                                                                                Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per
                                                                            page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        </div>
                                                    </div>

                                                    <div class="divB">
                                                        <div class=" bg-white color-grey">
                                                            <div class="create-new-leade mt-2">
                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        <form class="w-100">
                                                                            <div class="w3-card-4 classCard ">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs"
                                                                                        style="background:#F5F7FA!important;">
                                                                                        <h4
                                                                                            class=" common-styling h4Margin">
                                                                                            Product Backlog Item Status
                                                                                            List
                                                                                        </h4>

                                                                                    </div>
                                                                                </header>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <div
                                                                                                class="card own-account-table">
                                                                                                <p-table [value]="ProductBacklogItemStatusList"
                                                                                                    [paginator]="true"
                                                                                                    [rows]="rows"
                                                                                                    scrollWidth="100%"
                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                    [totalRecords]="total"
                                                                                                    [scrollable]="true"
                                                                                                    (sortFunction)="customSort($event)"
                                                                                                    [customSort]="true">
                                                                                                    <ng-template
                                                                                                        pTemplate="header">
                                                                                                        <tr
                                                                                                            style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                                pSortableColumn="code">
                                                                                                                <div class="checkbox-align"
                                                                                                                    style="color: white;">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    Status
                                                                                                                    ID

                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Status
                                                                                                                    Date
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Change
                                                                                                                    By
                                                                                                                    User
                                                                                                                    Login
                                                                                                                    ID
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>




                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="body"
                                                                                                        let-product>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                                <div
                                                                                                                    class="checkbox-align">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    <span
                                                                                                                         class="account-button"  >
                                                                                                                        {{product.list.statusId}}</span>

                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.list.statusDate | date:'yyyy-MM-dd
                                                                                                                hh:mm:ss'}}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.list.changeByUserLoginId}}
                                                                                                                

                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="emptymessage"
                                                                                                        let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span
                                                                                                                    class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <p
                                                                                                    class="paginate_data">
                                                                                                    View per
                                                                                                    page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="w3-card-4 classCard mt-2">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs"
                                                                                        style="background:#F5F7FA!important;">
                                                                                        <h4
                                                                                            class=" common-styling h4Margin">
                                                                                            Tasks
                                                                                        </h4>

                                                                                    </div>
                                                                                </header>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <div
                                                                                                class="card own-account-table">
                                                                                                <p-table [value]=""
                                                                                                    [paginator]="true"
                                                                                                    [rows]="rows"
                                                                                                    scrollWidth="100%"
                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                    [totalRecords]="total"
                                                                                                    [scrollable]="true"
                                                                                                    (sortFunction)="customSort($event)"
                                                                                                    [customSort]="true">
                                                                                                    <ng-template
                                                                                                        pTemplate="header">
                                                                                                        <tr
                                                                                                            style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                                pSortableColumn="code">
                                                                                                                <div class="checkbox-align"
                                                                                                                    style="color: white;">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    Status
                                                                                                                    ID

                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Status
                                                                                                                    Date
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Change
                                                                                                                    By
                                                                                                                    User
                                                                                                                    Login
                                                                                                                    ID
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>




                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="body"
                                                                                                        let-product>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                                <div
                                                                                                                    class="checkbox-align">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    <span
                                                                                                                         class="account-button"  >
                                                                                                                        {{product.contentTypeId}}</span>

                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.imageURL}}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.createdStamp
                                                                                                                |date:'yyyy-MM-dd
                                                                                                                hh:mm:ss'}}

                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="emptymessage"
                                                                                                        let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span
                                                                                                                    class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <p
                                                                                                    class="paginate_data">
                                                                                                    View per
                                                                                                    page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="w3-card-4 classCardView mt-2">
                                                                                <div class="header-tabs"
                                                                                    style="background:#F5F7FA!important;">
                                                                                    <h4 class=" common-styling hleft">
                                                                                        Emails
                                                                                    </h4>
        
                                                                                </div>
                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
        
                                                                                        <div
                                                                                            class="card own-account-table borderTable">
                                                                                            <p-table
                                                                                                [value]="EmailsProductBacklogs"
                                                                                                [rows]="rows" scrollWidth="100%"
                                                                                                [scrollable]="true"
                                                                                                [customSort]="true">
                                                                                                <ng-template pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="code">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
        
                                                                                                            </div>
                                                                                                            Subject
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="price">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Status ID
                                                                                                            </div>
        
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="price">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                From
                                                                                                            </div>
        
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="quantity">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                To
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Created At
        
                                                                                                            </div>
                                                                                                        </th>
        
        
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                {{product.subject}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.statusId}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.partyIdFrom}}
        
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.partyIdTo}}
        
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.createdStamp
                                                                                                            | date:'yyyy-MM-d
                                                                                                            hh:mm:ss'}}</td>
        
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No Record
                                                                                                                    Found</h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_dataa">
                                                                                                <!--  <ng-container *ngIf="openNoRecordFoundNote">
                                              <span style="margin-left: 450px;">No Record Found!....</span>
                                            </ng-container> -->
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
        
                                                                            <div class="w3-card-4 classCardView mt-2">
                                                                                <div class="header-tabs"
                                                                                    style="background:#F5F7FA!important;">
                                                                                    <h4 class=" common-styling hleft">
                                                                                        Backlog Item Content List
                                                                                    </h4>
        
                                                                                </div>
                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
        
                                                                                        <div
                                                                                            class="card own-account-table borderTable">
                                                                                            <p-table
                                                                                                [value]="ProductContentList"
                                                                                                [rows]="rows" scrollWidth="100%"
                                                                                                [scrollable]="true"
                                                                                                [customSort]="true">
                                                                                                <ng-template pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="code">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                Content Type ID
                                                                                                            </div>
        
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="price">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Content Name
                                                                                                            </div>
                                                                                                            <div>
        
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="quantity">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                From Date
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                            </div>
                                                                                                        </th>
        
        
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div *ngFor="let r of product.productContent"
                                                                                                                class="checkbox-align">
                                                                                                                <span>{{r.contentId}}</span>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.ContentName}}
                                                                                                        </td>
                                                                                                        <td *ngFor="let r of product.productContent ;"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <span>{{r.fromDate
                                                                                                                |
                                                                                                                date:'yyyy-MM-d
                                                                                                                hh:mm:ss' }}
                                                                                                            </span>
        
        
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <button
                                                                                                                type="submit"
                                                                                                                class="btn btn-secondary submit-btn ml-2">Download</button>
                                                                                                        </td>
        
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No Record
                                                                                                                    Found</h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_dataa">
                                                                                                <!--  <ng-container *ngIf="openNoRecordFoundNote">
                                              <span style="margin-left: 450px;">No Record Found!....</span>
                                            </ng-container> -->
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="w3-card-4 classCard mt-2">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs"
                                                                                        style="background:#F5F7FA!important;">
                                                                                        <h4
                                                                                            class=" common-styling h4Margin">
                                                                                            Backlog Item Note List
                                                                                        </h4>

                                                                                    </div>
                                                                                </header>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <div
                                                                                                class="card own-account-table">
                                                                                                <p-table [value]=""
                                                                                                    [paginator]="true"
                                                                                                    [rows]="rows"
                                                                                                    scrollWidth="100%"
                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                    [totalRecords]="total"
                                                                                                    [scrollable]="true"
                                                                                                    (sortFunction)="customSort($event)"
                                                                                                    [customSort]="true">
                                                                                                    <ng-template
                                                                                                        pTemplate="header">
                                                                                                        <tr
                                                                                                            style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                                pSortableColumn="code">
                                                                                                                <div class="checkbox-align"
                                                                                                                    style="color: white;">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    Status
                                                                                                                    ID

                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Status
                                                                                                                    Date
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Change
                                                                                                                    By
                                                                                                                    User
                                                                                                                    Login
                                                                                                                    ID
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>




                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="body"
                                                                                                        let-product>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                                <div
                                                                                                                    class="checkbox-align">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    <span
                                                                                                                         class="account-button"  >
                                                                                                                        {{product.contentTypeId}}</span>

                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.imageURL}}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.createdStamp
                                                                                                                |date:'yyyy-MM-dd
                                                                                                                hh:mm:ss'}}

                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="emptymessage"
                                                                                                        let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span
                                                                                                                    class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <p
                                                                                                    class="paginate_data">
                                                                                                    View per
                                                                                                    page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <br>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==2">

                                                    <div class=" bg-white color-grey">
                                                        <div class="create-new-leade ">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                      
                                                                            <div class="container-fluid">
                                                                                <ul class=" tabbing-accordians " style="width: 103% !important;
                                                                                          flex-wrap: wrap !important;
                                                                                                  display: flex !important;
                                                                                                         padding: 10px !important;">
                                                                                    
                                                                                        <button type="submit" (click)="newProductBacklogItem()"
                                                                                            class="btn btn-secondary submit-btn ">
                                                                                            New Backlog Item
                                                                                        </button>
                        
                                                                                        <button type="submit" (click)="billed()"
                                                                                            class="btn btn-secondary submit-btn ml-2"> Not
                                                                                            Billed
                                                                                        </button>
                                                                                        <button type="submit" (click)="deleteBacklogItem()"
                                                                                            class="btn btn-danger ml-2"> Delete
                        
                        
                        
                                                                                        </button>
                                                                                        <button type="submit" (click)="changeactiveCategory(8)"
                                                                                            class="btn btn-secondary submit-btn ml-2"> New Task
                                                                                        </button>
                                                                                   
                                                                                </ul>
                                                                            </div>
                                                                        

                                                                        <div>
                                                                            <p style="color: #0d3769;font-weight: bold;font-size: 15px;">
                                                                                Product Backlog Item:<span>{{this.description + this.custRequestId}}</span></p>
                                                                        </div>
                                                                        <br>

                                                                        <div class="w3-card-4 classCard">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                                                    style="width: 103% !important; flex-wrap: wrap !important;display: flex !important;">
                                                                                    <li><a>
                                                                                        Update Product Backlog
                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>
                                                                            <form [formGroup]="UpdateProductBacklogForm">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">

                                                                                                    Product Backlog Item

                                                                                                               <span style="color: red;">*</span> 
                                                                                                </label>
                                                                                            </div>

                                                                                            <div class="col-lg-3">

                                                                                                <input type="email"
                                                                                                [(ngModel)]="this.desc"
                                                                                           
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                formControlName="ProductBacklogItem"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Product Backlog Item">
                                                                                            </div>
                                                                                            <!-- <div class="col-lg-2" style="color: #0d3769;">255 Chars Maximum</div> -->

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Company
                                                                                                    
                                                                                                </label>
                                                                                            </div>

                                                                                            <div class="col-lg-3">
                                                                                                <input type="email" disabled
                                                                                                [(ngModel)]="this.SprintOverview.Company"
                                                                                               
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="Company"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Company">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Product Owner
                                                                                                    

                                                                                                </label>

                                                                                            </div>

                                                                                            <div class="col-lg-3">
                                                                                                <input type="email" disabled
                                                                                                    class="form-control"
                                                                                                    [(ngModel)]="this.SprintOverview.productOwner"
                                                                                                 
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="ProductOwner"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Product Owner">
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Backlog Item Category
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-3">
                                                                                                <input type="email" 
                                                                                                class="form-control"
                                                                                              
                                                                                                id="exampleInputEmail1"
                                                                                                formControlName="BacklogItemCategory"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Backlog Item Category">

                                                                                              
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                   

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Plan Time
                                                                                                    

                                                                                                </label>

                                                                                            </div>

                                                                                            <div class="col-lg-3">
                                                                                                <input type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    [(ngModel)]="this.SprintOverview.hours"
                                                                                                  
                                                                                                    formControlName="PlanTime"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Plan Time">
                                                                                            </div>

                                                                                            <!-- <div class="col-lg-2" style="color: #0d3769;">hr(s).</div> -->

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Sequence Num
                                                                                                    

                                                                                                </label>

                                                                                            </div>

                                                                                            <div class="col-lg-3">
                                                                                                <input type="email"
                                                                                                    class="form-control"
                                                                                                    [(ngModel)]="this.SprintOverview.productOverviewDetails[0].custSequenceNum"
                                                                                                   
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="SequenceNum"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Sequence Num">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Billed
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-3">

                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNOArray"
                                                                                                    [(ngModel)]="this.SprintOverview.productOverviewDetails[0].billed"
                                                                                                    formControlName="Billed"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Billed">
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Unplanned
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-3">

                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNOArray"
                                                                                                    [(ngModel)]="this.SprintOverview.Unplanned"
                                                                                                  
                                                                                                    formControlName="Unplanned"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Unplanned">
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Status 
                                                                                                    

                                                                                                </label>

                                                                                            </div>

                                                                                            <div class="col-lg-3">
                                                                                                <input type="email" disabled
                                                                                                    class="form-control" 
                                                                                                     [(ngModel)]="this.SprintOverview.productOverviewDetails[0].statusId"
                                                                                                  
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="Status"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Status">
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Request name
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-3">
                                                                                                <p-dropdown
                                                                                                filter="true"
                                                                                                [options]="RequesterNameArray"
                                                                                                [(ngModel)]="this.SprintOverview.productOverviewDetails[0].parentCustRequestId"
                                                                                               
                                                                                                formControlName="Requestname"
                                                                                                optionlabel="label"
                                                                                                placeholder="Enter Request Name">
                                                                                            </p-dropdown>
                                                                                                
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Story 
                                                                                                    

                                                                                                </label>

                                                                                            </div>

                                                                                            <div class="col-lg-3">
                                                                                                <input type="email"
                                                                                                    class="form-control"
                                                                                                    [(ngModel)]="this.SprintOverview.Story"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="Story"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Story">
                                                                                            </div>

                                                                                            <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">How to test
                                                                                                

                                                                                            </label>

                                                                                        </div>

                                                                                        <div class="col-lg-3">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                [(ngModel)]="this.SprintOverview.Howtotest"
                                                                                              

                                                                                                id="exampleInputEmail1"
                                                                                                formControlName="Howtotest"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter How to test">
                                                                                        </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    


                                                                                </div>
                                                                            </form>
                                                                            <div class="col-lg-12  main-submit-button"
                                                                                style="margin-left: -58%;">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                  (click)="updateProductBacklogs()"  >Submit</button>&nbsp;
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==3">
                                                    <ul class=" tabbing-accordians " style="width: 103% !important; flex-wrap: wrap !important;display: flex !important; padding: 10px !important;">
                                                         <li>
                                                            <button type="submit" (click)="newProductBacklogItem()"
                                                                class="btn btn-secondary submit-btn ">
                                                                New Backlog Item
                                                            </button>

                                                            <button type="submit" (click)="billed()"
                                                                class="btn btn-secondary submit-btn ml-2"> Not
                                                                Billed
                                                            </button>
                                                            <button type="submit" (click)="deleteBacklogItem()"
                                                                class="btn btn-danger ml-2"> Delete



                                                            </button>
                                                            <button type="submit" (click)="changeactiveCategory(8)"
                                                                class="btn btn-secondary submit-btn ml-2"> New Task
                                                            </button>
                                                        </li>
                                                    </ul>
                                                    <div>
                                                        <p style="color: #0d3769;font-weight: bold;font-size: 15px;">
                                                            Product Backlog Item:<span>{{this.description + this.custRequestId}}</span></p>
                                                    </div>
                                                    <br>
                                                    <div class=" bg-white color-grey">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">

                                                                        <div class="w3-card-4 classCard mt-2">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                                                    style="width: 103% !important;   flex-wrap: wrap !important;display: flex !important;">
                                                                                    <li><a>
                                                                                        New Backlog Item Note
 
                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>

                                                                            <form [formGroup]="NewBacklogItemNoteForm">


                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                               

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputE">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Note Name
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input
                                                                                                   
                                                                                                    type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="NoteName"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Note Name">
                                                                                            </div>

                                                                                            <div
                                                                                            class="col-lg-3 form-group classInputE">
                                                                                            <label
                                                                                                for="exampleInputEmail1">
                                                                                                Note Info
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input
                                                                                               
                                                                                                type="email"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                formControlName="NoteInfo"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Note Info">
                                                                                        </div>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="col-lg-12  main-submit-button"
                                                                                    style="margin-left: -67%;">
                                                                                    <button _ngcontent-cta-c128=""
                                                                                        type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                  (click)="createProdBacklogNote()"   >Create</button>&nbsp;


                                                                                </div>
                                                                            </form>

                                                                        </div>
                                                                        <div class="w3-card-4 classCard mt-2">
                                                                            <header class="w3-container w3-blue">
                                                                                <div class="header-tabs"
                                                                                    style="background:#F5F7FA!important;">
                                                                                    <h4
                                                                                        class=" common-styling h4Margin">
                                                                                        Backlog Item Note List
 
 
                                                                                    </h4>

                                                                                </div>
                                                                            </header>
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">

                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="BacklogItemNoteList"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                                            pSortableColumn="code">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                Note Name

                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Note Info
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Note Date Time
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Note Party
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                           
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>




                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                <span
                                                                                                                     class="account-button"  >
                                                                                                                    {{product.noteName}}</span>

                                                                                                            </div>
                                                                                                        </td>

                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                                            {{product.noteInfo}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                                            {{product.noteDateTime
                                                                                                            |date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                                        </td>
                                                                                                        <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.noteParty}}
                                                                                                    </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                                            <button
                                                                                                            (click)="deleteProdBacklogNote(product)"
                                                                                                                type="submit"
                                                                                                                class="btn btn-secondary submit-btn ml-2">
                                                                                                                Delete
                                                                                                            </button>


                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>

                                                                                            <p class="paginate_data">
                                                                                                View per
                                                                                                page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==4">
                                                    <ul class=" tabbing-accordians " 
                                                    style="width: 103% !important;flex-wrap: wrap !important;display: flex !important; padding: 10px !important;" >
                                                          <li>
                                                            <button type="submit" (click)="newProductBacklogItem()"
                                                                class="btn btn-secondary submit-btn ">
                                                                New Backlog Item
                                                            </button>

                                                            <button type="submit" (click)="billed()"
                                                                class="btn btn-secondary submit-btn ml-2"> Not
                                                                Billed
                                                            </button>
                                                            <button type="submit"  (click)="deleteBacklogItem()"
                                                                class="btn btn-danger ml-2"> Delete



                                                            </button>
                                                            <button type="submit" (click)="changeactiveCategory(8)"
                                                                class="btn btn-secondary submit-btn ml-2"> New Task
                                                            </button>
                                                        </li>
                                                    </ul>
                                                    <div><p style="color: #0d3769;font-weight: bold;font-size: 15px;">Current Project :<span>{{this.productId}}</span></p></div>
                                                   <br>
                                                    <div class=" bg-white color-grey">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div
                                                                            class="w3-card-4 classCard mt-2">
                                                                            <header
                                                                                class="w3-container w3-blue">
                                                                                <div class="header-tabs"
                                                                                    style="background:#F5F7FA!important;">
                                                                                    <h4
                                                                                        class=" common-styling h4Margin">
                                                                                        Product Content List
                                                                                    </h4>

                                                                                </div>
                                                                            </header>
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div
                                                                                    class="col-lg-12 col-12">
                                                                                    <div class="form-group">

                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="ContentList"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                                            pSortableColumn="code">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                Content
                                                                                                                Type
                                                                                                                ID

                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Content
                                                                                                                Name
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                From
                                                                                                                Date
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">

                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>




                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                <span
                                                                                                                     class="account-button"  >
                                                                                                                    {{product.contentTypeId}}</span>

                                                                                                            </div>
                                                                                                        </td>

                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                                            {{product.imageURL}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                                            {{product.createdStamp
                                                                                                            |date:'yyyy-MM-dd
                                                                                                            hh:mm:ss'}}

                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                                            <button
                                                                                                                (click)="deleteScrumProductContent(product.contentId)"
                                                                                                                type="submit"
                                                                                                                class="btn btn-secondary submit-btn ml-2">
                                                                                                                Delete
                                                                                                            </button>


                                                                                                            <button
                                                                                                                (click)="DownloadImageProductContent(product.imageURL)"
                                                                                                                type="submit"
                                                                                                                class="btn btn-secondary submit-btn ml-2">
                                                                                                                Download
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>

                                                                                            <p
                                                                                                class="paginate_data">
                                                                                                View per
                                                                                                page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            class="w3-card-4 classCard mt-2">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                                                    style="width: 103% !important;
                                                                flex-wrap: wrap !important;
                                                                display: flex !important;">
                                                                                    <li><a>
                                                                                            Add Product
                                                                                            Content
                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>

                                                                            <form [formGroup]="contentForm">


                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputE">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Content
                                                                                                    Type
                                                                                                    ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2">

                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="contentTypeIdArray"
                                                                                                    formControlName="ContentTypeID"
                                                                                                    optionlabel="label"
                                                                                                    >
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputE">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Mime
                                                                                                    Type ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2">

                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="mimeTypeIdArray"
                                                                                                    formControlName="MimeTypeID"
                                                                                                    optionlabel="label"
                                                                                                    >
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputE">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Upload
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2">
                                                                                                <input
                                                                                                    (change)="fileProgress($event)"
                                                                                                    type="file"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="Upload"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    >
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>




                                                                                </div>
                                                                                <div class="col-lg-12  main-submit-button"
                                                                                    style="
                                                                                                           margin-left: -67%;">
                                                                                    <button
                                                                                        _ngcontent-cta-c128=""
                                                                                        type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                    (click)="oncreateScrumProductContent()"  >Create</button>&nbsp;


                                                                                </div>
                                                                            </form>

                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==5">
                                                    <div class=" bg-white color-grey">

                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div>
                                                                           
                                                                            <button type="submit"
                                                                                (click)="newEmailPage()"
                                                                                class="btn btn-secondary submit-btn ml-2">New
                                                                                Email</button>
                                                                        </div>

                                                                        <br>
                                                                        <div class="create-new-leade mt-2">
                                                                            <div class="container-fluid">
                                                                                <div class="row">

                                                                                    <form class="w-100">

                                                                                        <div>
                                                                                            <div
                                                                                                class="w3-card-4 classCard">
                                                                                                <header
                                                                                                    class="w3-container w3-blue">
                                                                                                    <div class="header-tabs"
                                                                                                        style="background:#F5F7FA!important;">
                                                                                                        <h4
                                                                                                            class=" common-styling h4Margin">
                                                                                                            Emails
                                                                                                        </h4>

                                                                                                    </div>
                                                                                                </header>
                                                                                                <div
                                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                    <div
                                                                                                        class="col-lg-12 col-12">
                                                                                                        <div
                                                                                                            class="form-group">

                                                                                                            <div
                                                                                                                class="card own-account-table">
                                                                                                                <p-table
                                                                                                                    [value]="CommunicationList"
                                                                                                                    [paginator]="true"
                                                                                                                    [rows]="rows"
                                                                                                                    scrollWidth="100%"
                                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                                    [totalRecords]="total"
                                                                                                                    [scrollable]="true"
                                                                                                                    (sortFunction)="customSort($event)"
                                                                                                                    [customSort]="true">
                                                                                                                    <ng-template
                                                                                                                        pTemplate="header">
                                                                                                                        <tr
                                                                                                                            style="background: #0d3769;">
                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                pSortableColumn="code">
                                                                                                                                <div class="checkbox-align"
                                                                                                                                    style="color: white;">
                                                                                                                                    <p-checkbox
                                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                                        styleclass="custom-checkbox"
                                                                                                                                        binary="true"
                                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                        <div
                                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                            <div
                                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                                <input
                                                                                                                                                    type="checkbox"
                                                                                                                                                    name="undefined"
                                                                                                                                                    value="undefined">
                                                                                                                                            </div>
                                                                                                                                            <div role="checkbox"
                                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                <span
                                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <!---->
                                                                                                                                    </p-checkbox>
                                                                                                                                    Subject
                                                                                                                                    Task
                                                                                                                                    Name
                                                                                                                                </div>
                                                                                                                                <input
                                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                    value=""
                                                                                                                                    autocomplete="off"
                                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox"
                                                                                                                                    aria-disabled="false"
                                                                                                                                    aria-readonly="false">
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                pSortableColumn="name">
                                                                                                                                <div
                                                                                                                                    style="color: white;">
                                                                                                                                    Status
                                                                                                                                    ID

                                                                                                                                </div>
                                                                                                                                <input
                                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                    value=""
                                                                                                                                    autocomplete="off"
                                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox"
                                                                                                                                    aria-disabled="false"
                                                                                                                                    aria-readonly="false">
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                pSortableColumn="name">
                                                                                                                                <div
                                                                                                                                    style="color: white;">
                                                                                                                                    From

                                                                                                                                </div>
                                                                                                                                <input
                                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                    value=""
                                                                                                                                    autocomplete="off"
                                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox"
                                                                                                                                    aria-disabled="false"
                                                                                                                                    aria-readonly="false">
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                pSortableColumn="name">
                                                                                                                                <div
                                                                                                                                    style="color: white;">
                                                                                                                                    To
                                                                                                                                </div>
                                                                                                                                <input
                                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                    value=""
                                                                                                                                    autocomplete="off"
                                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox"
                                                                                                                                    aria-disabled="false"
                                                                                                                                    aria-readonly="false">
                                                                                                                            </th>

                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                pSortableColumn="name">
                                                                                                                                <div
                                                                                                                                    style="color: white;">
                                                                                                                                    Created
                                                                                                                                </div>
                                                                                                                                <input
                                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                    value=""
                                                                                                                                    autocomplete="off"
                                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox"
                                                                                                                                    aria-disabled="false"
                                                                                                                                    aria-readonly="false">
                                                                                                                            </th>

                                                                                                                        </tr>


                                                                                                                    </ng-template>
                                                                                                                    <ng-template
                                                                                                                        pTemplate="body"
                                                                                                                        let-product>
                                                                                                                        <tr>
                                                                                                                            <td
                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                <div
                                                                                                                                    class="checkbox-align">
                                                                                                                                    <p-checkbox
                                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                                        styleclass="custom-checkbox"
                                                                                                                                        binary="true"
                                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                        <div
                                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                            <div
                                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                                <input
                                                                                                                                                    type="checkbox"
                                                                                                                                                    name="undefined"
                                                                                                                                                    value="undefined">
                                                                                                                                            </div>
                                                                                                                                            <div role="checkbox"
                                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                <span
                                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <!---->
                                                                                                                                    </p-checkbox>
                                                                                                                                    <span
                                                                                                                                        (click)="detailedCommunicationPage(this.productId,product.communicationEventId)"
                                                                                                                                        style="color: #0d3769;">{{product.subject}}</span>

                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td
                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                {{product.statusId}}
                                                                                                                            </td>
                                                                                                                            <td
                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                {{product.partyIdFrom}}
                                                                                                                            </td>
                                                                                                                            <td
                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                {{product.partyIdTo}}
                                                                                                                            </td>
                                                                                                                            <td
                                                                                                                                [ngStyle]="{'width':'170px'}">

                                                                                                                                {{product.entryDate
                                                                                                                                |
                                                                                                                                date:
                                                                                                                                'yyyy-MM-dd
                                                                                                                                hh:mm:ss'}}
                                                                                                                            </td>
                                                                                                                            <!-- <td [ngStyle]="{'width':'180px'}">
                                    
                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                                class="ml-20" width="16" height="16"
                                                                                                                                viewBox="0 0 26 26">
                                                                                                                                <defs>
                                                                                                                                    <style>
                                                                                                                                        .a {
                                                                                                                                            fill: #f44336;
                                                                                                                                        }
                                                        
                                                                                                                                        .b {
                                                                                                                                            fill: #fafafa;
                                                                                                                                            margin-left: 20px;
                                                                                                                                        }
                                                                                                                                    </style>
                                                                                                                                </defs>
                                                                                                                                <path class="a fill-color"
                                                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                                <path class="b"
                                                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                                                            </svg>
                                                                                                                        </td> -->

                                                                                                                            <!-- <td [ngStyle]="{'width':'170px'}">
                                                                                                                            <svg class="hover"
                                                                                                                                (click)="updateEvent(product.workEffortId)"
                                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                                                width="16" height="16"
                                                                                                                                viewBox="0 0 21.223 21.222">
                                                                                                                                <g
                                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                                    <g
                                                                                                                                        transform="translate(0 1.52)">
                                                                                                                                        <path
                                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                                    </g>
                                                                                                                                    <g
                                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                                        <g
                                                                                                                                            transform="translate(0 0)">
                                                                                                                                            <path
                                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                                        </g>
                                                                                                                                    </g>
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </td> -->
                                                                                                                            <!-- <svg (click)="onDelete(product.workEffortId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg> -->


                                                                                                                        </tr>
                                                                                                                    </ng-template>
                                                                                                                    <ng-template
                                                                                                                        pTemplate="emptymessage"
                                                                                                                        let-columns>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <span
                                                                                                                                    class="text-center">
                                                                                                                                    <h4
                                                                                                                                        class="ml-2">
                                                                                                                                        No
                                                                                                                                        Record
                                                                                                                                        Found
                                                                                                                                    </h4>
                                                                                                                                </span>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </ng-template>
                                                                                                                </p-table>

                                                                                                                <p
                                                                                                                    class="paginate_data">
                                                                                                                    View
                                                                                                                    per
                                                                                                                    page
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </form>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==6">
                                                    <div class="container-fluid">
                                                        <ul class=" tabbing-accordians " style="width: 103% !important;
                                        flex-wrap: wrap !important;
                                        display: flex !important;
                                        padding: 10px !important;">
                                                            <li>
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                    (click)="newEmailPage()"
                                                                   >
                                                                    New
                                                                    Email
                                                                </button>

                                                               
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div style="color: #0d3769;font-size: 15px;">  
                                                        Product Backlog Item:<span>{{this.description + this.custRequestId}}</span></div>

                                                    <div class="divA">
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling hleft">
                                                                    New Product Backlog Email
                                                                </h4>
                                                            </div>

                                                            <div
                                                                class="port-header-contact create-lead font-medium mb-0 pt-0 mt-0 text-white">
                                                                <div class="w-100">
                                                                    <div class="panel-group" id="accordion"
                                                                        role="tablist"
                                                                        aria-multiselectable="true">

                                                                        <div>


                                                                            <form
                                                                                [formGroup]="NewEmailForm">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputB">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">From

                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-9">
                                                                                                <input
                                                                                                    type="email"
                                                                                                    [(ngModel)]="this.ProductUpdateEmail.ItemList[0].fromString"
                                                                                                 
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="From"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter From">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>



                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputB">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">To
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-9">

                                                                                                <input
                                                                                                    type="email" disabled
                                                                                                    class="form-control"
                                                                                                    [(ngModel)]="this.ProductUpdateEmail.ItemList[0].toString"
                                                                                                 
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="To"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    >
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputB">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Subject

                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-9">

                                                                                                <input
                                                                                                    type="email"
                                                                                                    class="form-control"
                                                                                                    [(ngModel)]="this.ProductUpdateEmail.ItemList[0].subject"
                                                                                                   
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="Subject"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Subject">

                                                                                    <div style="color: #0d3769;">Do not remove the <span>{{this.ProductUpdateEmail.ItemList[0].subject}}</span></div> 
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInputB">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Content
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-9">

                                                                                                <input
                                                                                                    type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="Content"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Content">

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </form>
                                                                            <div class="col-lg-12  main-submit-button"
                                                                                style="
                                                                                   margin-left: -1%;">
                                                                                <button
                                                                                    _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="updateEmailForProduct() ">Send</button>&nbsp;
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <br>

                                                    </div>

                                                    <div class="divB">
                                                      
                                                        <div class="w3-card-4 classCardView ">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling hleft ml-2">
                                                                    Email Contents
                                                                </h4>

                                                            </div>
                                                            <div class="panel-body">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                    <div
                                                                        class="card own-account-table borderTable">
                                                                        <p-table [value]="ContentDataList"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [scrollable]="true"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr
                                                                                    style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'280px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Content Name
                                                                                        </div>

                                                                                    </th>
                                                                                    <!-- <th [ngStyle]="{'width':'100px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div
                                                                                            style="color: white;">
                                                                                            Delete
                                                                                        </div>

                                                                                    </th> -->
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body"
                                                                                let-product>
                                                                                <tr >
                                                                                    <td style="color: #0d3769;" (click)="EmailContentDownload(product.imageURL)"
                                                                                        [ngStyle]="{'width':'280px'}">
                                                                                        <div style="color: #0d3769;cursor: pointer;"
                                                                                            class="checkbox-align">
                                                                                            {{product.imageURL}}
                                                                                        </div>
                                                                                    </td>

<!-- 
                                                                                    <td
                                                                                        [ngStyle]="{'width':'100px'}">


                                                                                    </td> -->

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template
                                                                                pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span
                                                                                            class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_dataa">

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <form [formGroup]="UploadedFileForm">
                                                                    <div
                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group rightFormA"
                                                                                    style="margin-left: 1%;">
                                                                                    <label
                                                                                        for="exampleInputEmail1">
                                                                                        Uploaded File

                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-4">

                                                                                    <input type="file"
                                                                                        class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        (change)="fileProgressCom($event)"
                                                                                        formControlName="UploadedFile"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Enter Category">
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </form>

                                                                <div class="col-lg-12  main-submit-button"
                                                                    style="
                                                                               margin-left: -41%;">
                                                                    <button _ngcontent-cta-c128=""
                                                                        type="submit"
                                                                        class="btn btn-secondary submit-btn"
                                                                        (click)="uploadEmailContent()">Upload
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==7">

                                                    <div class=" bg-white color-grey">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="container-fluid">
                                                                            <ul class=" tabbing-accordians " style="width: 103% !important;
                                                            flex-wrap: wrap !important;
                                                            display: flex !important;
                                                            padding: 10px !important;">
                                                                                <li>
                                                                                    <button type="submit" (click)="newProductBacklogItem()"
                                                                                    class="btn btn-secondary submit-btn ">
                                                                                    New Backlog Item
                                                                                </button>
                    
                                                                                <button type="submit" (click)="billed()"
                                                                                    class="btn btn-secondary submit-btn ml-2"> Not
                                                                                    Billed
                                                                                </button>
                                                                                <button type="submit"  (click)="deleteBacklogItem()"
                                                                                    class="btn btn-danger ml-2"> Delete
                    
                    
                    
                                                                                </button>
                                                                                <button type="submit" (click)="changeactiveCategory(8)"
                                                                                    class="btn btn-secondary submit-btn ml-2"> New Task
                                                                                </button>
                    
                                                                                   
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                    
                                                                        <div style="color: #0d3769;font-size: 15px;">  
                                                                            Product Backlog Item:<span>{{this.description + this.custRequestId}}</span></div>

                                                                        <div
                                                                            class="w3-card-4 classCard mt-2">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                                                    style="width: 103% !important;
                                                            flex-wrap: wrap !important;
                                                            display: flex !important;">
                                                                                    <li><a>
                                                                                            Edit Delete
                                                                                            Backlog Item
                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>
                                                                            <form
                                                                                [formGroup]="editDetailForm">


                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group classInput"
                                                                                                style="margin-left: -15%;">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Reason

                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2">

                                                                                                <input
                                                                                                    type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="Reason"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Reason">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>



                                                                                </div>
                                                                                <div class="col-lg-12  main-submit-button"
                                                                                    style="
                                                                                                       margin-left: -74%;">
                                                                                    <button
                                                                                        _ngcontent-cta-c128=""
                                                                                        type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                        (click)="deleteBacklogItems()">Save
                                                                                    </button>


                                                                                </div>
                                                                            </form>

                                                                        </div>


                                                                        <div
                                                                            class="w3-card-4 classCard mt-2">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                                                    style="width: 103% !important;
                                                                flex-wrap: wrap !important;
                                                                display: flex !important;">
                                                                                    <li><a>
                                                                                            Product Backlog
                                                                                            Item Info
                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>


                                                                            <div class="panel-body">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="color-black container">
                                                                                        <div class="row">
                                                                                            <div class="article-container">
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Product Name </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12 font-weight-bold"
                                                                                                        >{{this.SprintOverview.productName}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Company</p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Company}}
                                                                                                    </span>
                                                                                                </div>
                
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Product Owner</p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOwner}}</span>
                                                                                                </div>
                
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Request name </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].parentCustRequestId}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Product Backlog Item </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.description}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Plan Time </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.hours}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Actual Hours </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.ActualHours}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Cust Sequence Num </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].custSequenceNum}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Billed </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].billed}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Unplanned </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Unplanned}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Status </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].statusId}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Story</p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Story}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        How to test </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Howtotest}}</span>
                                                                                                </div>
                
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>




                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div *ngIf="activeCategory==8">

                                                    <div class=" bg-white color-grey">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="container-fluid">
                                                                            <ul class=" tabbing-accordians " style="width: 103% !important;
                                                            flex-wrap: wrap !important;
                                                            display: flex !important;
                                                            padding: 10px !important;">
                                                                                <li>
                                                                                    <button type="submit" (click)="newProductBacklogItem()"
                                                                                    class="btn btn-secondary submit-btn ">
                                                                                    New Backlog Item
                                                                                </button>
                    
                                                                                <button type="submit" (click)="billed()"
                                                                                    class="btn btn-secondary submit-btn ml-2"> Not
                                                                                    Billed
                                                                                </button>
                                                                                <button type="submit"  (click)="deleteBacklogItem()"
                                                                                    class="btn btn-danger ml-2"> Delete
                    
                    
                    
                                                                                </button>
                                                                                <button type="submit" (click)="activeCategory(8)"
                                                                                    class="btn btn-secondary submit-btn ml-2"> New Task
                                                                                </button>
                    
                                                                                   
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                    
                                                                        <div style="color: #0d3769;font-size: 15px;">  
                                                                            Product Backlog Item:<span>{{this.description + this.custRequestId}}</span></div>

                                                                        <div
                                                                            class="w3-card-4 classCard mt-2">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                                                    style="width: 103% !important;
                                                            flex-wrap: wrap !important;
                                                            display: flex !important;">
                                                                                    <li><a>
                                                                                        Create Task for Product Backlog
                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>
                                                                            <form
                                                                                [formGroup]="CreateTaskforProductBacklogForm">


                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-3 form-group classInput"
                                                                                                style="margin-left: -13%;">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Task Name <span style="color: red;"></span>

                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2">

                                                                                                <input
                                                                                                    type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="Reason"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Reason">
                                                                                            </div>

                                                                                            <div class="col-lg-3 form-group classInput"
                                                                                                style="margin-left: 20%;">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Task Type <span style="color: red;"></span>

                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2">

                                                                                                <input
                                                                                                    type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="TaskType"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Task Type">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    


                                                                                </div>
                                                                                <div class="col-lg-12  main-submit-button"
                                                                                    style="
                                                                                                       margin-left: -10%;">
                                                                                    <button
                                                                                        _ngcontent-cta-c128=""
                                                                                        type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                       >Save
                                                                                    </button>


                                                                                </div>
                                                                            </form>

                                                                        </div>


                                                                        <div
                                                                            class="w3-card-4 classCard mt-2">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                                                    style="width: 103% !important;
                                                                flex-wrap: wrap !important;
                                                                display: flex !important;">
                                                                                    <li><a>
                                                                                            Product Backlog
                                                                                            Item Info
                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>


                                                                            <div class="panel-body">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="color-black container">
                                                                                        <div class="row">
                                                                                            <div class="article-container">
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Product Name </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12 font-weight-bold"
                                                                                                        >{{this.SprintOverview.productName}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Company</p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Company}}
                                                                                                    </span>
                                                                                                </div>
                
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Product Owner</p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOwner}}</span>
                                                                                                </div>
                
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Request name </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].parentCustRequestId}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Product Backlog Item </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.description}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Plan Time </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.hours}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Actual Hours </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.ActualHours}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Cust Sequence Num </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].custSequenceNum}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Billed </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].billed}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Unplanned </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Unplanned}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Status </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.productOverviewDetails[0].statusId}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        Story</p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Story}}</span>
                                                                                                </div>
                                                                                                <div class="article">
                                                                                                    <p class="col-lg-6 col-12">
                                                                                                        How to test </p>
                                                                                                    <span
                                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.SprintOverview.Howtotest}}</span>
                                                                                                </div>
                
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>




                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>