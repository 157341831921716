import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DetailedEmployeeSummary } from 'src/app/human-resource/detailed-employee-summary/detailed-employee-summary.service';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-update-contact-profile',
  templateUrl: './update-contact-profile.component.html',
  styleUrls: ['./update-contact-profile.component.css']

  
})
export class UpdateContactProfileComponent  {

  contactForm: FormGroup;
  editMode: boolean;
  partyId: string;
  contact: any;
  array = [
      {
          label: 'Y',
          value: 'Y'
      },
      {
          label: 'N',
          value: 'N'
      }
  ];
  contactPurpose: any[];
  contactMechId: string;
  @Output() closeTelecomModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;
  constructor(
      readonly _DetailedEmployeeSummary: DetailedEmployeeSummary,
      readonly _CRMAccountService: CRMAccountService,
      readonly _FormBuilder: FormBuilder,
      readonly  _Router: Router,
      private _location: Location,
      readonly spinner:NgxSpinnerService,
    readonly myContactsService: MyContactsService,
    readonly _ActivatedRoute: ActivatedRoute,
      readonly _ToastrService: ToastrService,
      public bsModalRef: BsModalRef
  ) {
      this.partyId = '';
      this.editMode = false;
      this.contactForm = this._FormBuilder.group({
          allowSolicitation: [''],
          areaCode: [''],
          askForName: [''],
          contactMechId: [''],
          contactMechPurposeTypeId: ['', [Validators.required]],
          contactMechTypeId: [''],
          contactNumber: [''],
          countryCode: [''],
          extension: [''],
          partyId: [''],
          preContactMechTypeId: ['TELECOM_NUMBER']
      });
      this.contactPurpose = [];
      this.contactMechId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
      this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
      this.contactMechId = this._ActivatedRoute.snapshot.queryParams.contactMechId;
      if (this.contactMechId) {
          this.editMode = true;
          this.getContactDetails();
        
      }
      this.getContactPurpose();
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  updatePostalAddressProfile() {
    this.spinner.show();
    const formData = {
      "allowSolicitation": this.contactForm.value.allowSolicitation,
      "areaCode":  this.contactForm.value.areaCode,"contactMechId": this.contactMechId,
      "contactMechPurposeTypeId":  this.contactForm.value.contactMechPurposeTypeId,
      "contactMechTypeId":  this.contactForm.value.contactMechTypeId,"contactNumber":  this.contactForm.value.contactNumber,
      "countryCode":  this.contactForm.value.countryCode, "done_PAGE": "",
      "extension":  this.contactForm.value.extension, "partyId":  this.partyId,  }
    this.myContactsService.updatePostalAddressProfile(formData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Update Successfully");
     this.modalClose(); this.spinner.hide();
        this.cancelSubmit(); }}, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }else {
        this._ToastrService.error(err.error.message); } this.spinner.hide();});}


  getContactDetails(): void {
      this.spinner.show();
      this._DetailedEmployeeSummary.getTelecomById(this.contactMechId, this.partyId)
          .then(data => {
              this.contact = {
                  areaCode: data.areaCode,
                  askForName: data.askForName,
                  countryCode: data.countryCode,
                  contactNumber: data.contactNumber,
                  contactMechId: data.contactMechId,
                  allowSolicitation: data.allowSolicitation,
                  contactMechPurposeTypeId: data.contactMechPurposeTypeId,
                  contactMechTypeId: data.contactMechTypeId,
                  extension: data.extension,
                  partyId: data.partyId,
                  preContactMechTypeId: 'TELECOM_NUMBER'
              };
              this.contactForm.patchValue(this.contact);
              this.spinner.hide();
              this.contactForm.controls.contactMechPurposeTypeId.enable();
              this.contactForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
          });
  }
  getContactPurpose(): void {
      this.spinner.show();
      this._CRMAccountService.getContactPurposes(this.partyId, 'TELECOM_NUMBER')
          .then(data => {
              if (data.success) {
                  this.spinner.hide();
                  this.contactPurpose = data.data.purposeTypes.map(value => {
                      return {
                          label: value.description,
                          value: value.contactMechPurposeTypeId
                      };
                  });
              }
          });
         
  }
  update(): void {
      this.spinner.show();
      if (this.contactForm.valid) {
          this._DetailedEmployeeSummary.updateEmployeeTelecomNumber({
              ...this.contact, ...this.contactForm.value
          })
              .then(data => {
                  if (data.success) {
                      this._ToastrService.success('Updated');
                      this.modalClose();
                      this.spinner.hide();
                      this.cancelSubmit();
                  }
              });
      }
     
  }
  submit(): void {
      this.spinner.show();
      if (this.contactForm.valid) {
          this._DetailedEmployeeSummary.createEmployeeTelecomNumber({
              ...this.contactForm.value, ...{
                  partyId: this.partyId
              }
          })
              .then(data => {
                  if (data.success) {
                      this._ToastrService.success('Created');
                      this.modalClose();
                      this.spinner.hide();
                      this.cancelSubmit();
                  }
              });
      }
     
  }
 

  modalClose(): void {
      this.closebutton.nativeElement.click();
      this.closeTelecomModal.emit();
    }

  ngOnDestroy(): void {
      this.spinner.hide();
        }
}