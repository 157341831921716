import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReceivalesPaymentServices } from './recieivales-payment.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-receivales-payment',
  templateUrl: './receivales-payment.component.html',
  styleUrls: ['./receivales-payment.component.css']
})
export class ReceivalesPaymentComponent implements OnInit {
  
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;

  pageNo = 1
  leadsLists: any;

  amount: any = '123';
  paymentIdSearchType: any = 'Contains';
  paymentId = '';
  commentsSearchType: any = 'Contains';
  comments = '';
  referenceNoSearchType: any = 'Contains';
  referenceNo = '';

  public contacts = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
  };


  opportunityIdArray: any = [];
  allPaymentTypeList: any = [];
  allStatusList: any = []
  allPaymentsList: any = [];


  constructor(

    readonly reveivalesService: ReceivalesPaymentServices,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getPaymentType();
    this.getAllPaymentList();

    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Begins With',
        text: 'Begins With'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
    ];

  }

  onPaymentId(event): void {
    this.paymentIdSearchType = event;
  }
  onComments(event): void {
    this.commentsSearchType = event;
  }
  onReferenceNo(event): void {
    this.referenceNoSearchType = event;
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  paymentDetail(id): void {
    this.router.navigate(['/accounting/payments/detailed-payment'], { queryParams: { paymentId: id } });
  }

  getPaymentType() {
    this.spinner.show();
    this.reveivalesService.getPaymentType().subscribe(res => {
      if (res.success) {
        const allPaymentType = res.data.paymentType;
        const allStatusList = res.data.status

        for (const value of allPaymentType) {
          this.allPaymentTypeList.push({
            label: value.description,
            value: value.statusId
          })
        }

        for (const value of allStatusList) {
          this.allStatusList.push({
            label: value.description,
            value: value.statusId
          })
        }
      }

    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }

  getAllPaymentList() {
    this.spinner.show();
    const paymentAdvancedSearch = {
      amount: this.amount,
      paymentIdSearchType: this.paymentIdSearchType,
      paymentId: this.paymentId,
      commentsSearchType: this.commentsSearchType,
      comments: this.comments,
      referenceNoType: this.referenceNoSearchType,
      referenceNo: this.referenceNo,
    };
    this.reveivalesService.getPaymentList(this.contacts, paymentAdvancedSearch).subscribe(res => {
      if (res.success) {
        this.allPaymentsList = res.data;
        this.spinner.hide();
     
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    
  }

  onUpdatePayment(updatePaymentId) {
    this.router.navigate(['/accounting/payments/create-payments'], { queryParams: { paymentId: updatePaymentId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
