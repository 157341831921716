<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Quotes </span>
               <span>
                <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span> 
              
                                            
            </div>
            <div class=" bg-white color-grey">
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                             
                                <div class="w3-card-4">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords"> Find Quotes

                                                    </span>
                                                    
                                                    <button type="button" class="btn btn-outline-secondary ml-2"   
                                                    (click)="createQuotation()">Create New Quotes</button>
                                                
                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 70%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>									

                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Quote Name</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown styleClass="small-dropdown" filter="true"
                                                                            [(ngModel)]="searchFilter.quoteNameSearchType"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            [options]="opportunityIdArray" optionlabel="label">
                                                                        </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control w-100 small-input"
                                                                            [(ngModel)]="searchFilter.quoteName"
                                                                            [ngModelOptions]="{standalone:true}" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter Quote Name">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Quote ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown styleClass="small-dropdown" filter="true"
                                                                    [(ngModel)]="searchFilter.quoteIdSearchType"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    [options]="opportunityIdArray" optionlabel="label">
                                                                </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control w-100 small-input"
                                                                            [(ngModel)]="searchFilter.quoteId"
                                                                            [ngModelOptions]="{standalone:true}" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter Quote ID">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Quote Type ID </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <ng-select [items]="storeData.quoteTypes" bindLabel="description"
                                                                    bindValue="quoteTypeId" [(ngModel)]="searchFilter.quoteTypeId"
                                                                    [ngModelOptions]="{standalone:true}"  placeholder="Enter Quote Type ID"></ng-select>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Party ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1"
                                                                    [(ngModel)]="searchFilter.partyId" [ngModelOptions]="{standalone:true}"
                                                                    aria-describedby="emailHelp" placeholder="Enter Party ID">
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                       
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Currency</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <ng-select [items]="currencyData" bindLabel="description" bindValue="uomId"
                                                                            [(ngModel)]="searchFilter.currencyId"
                                                                            [ngModelOptions]="{standalone:true}" placeholder="Select Currency"></ng-select>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Product Store</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <ng-select [items]="storeData.productStores" bindLabel="storeName"
                                                                            bindValue="productStoreId" [(ngModel)]="searchFilter.productStoreId"
                                                                            [ngModelOptions]="{standalone:true}" placeholder="Select Product Store"></ng-select>
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Sales Channel</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <ng-select [items]="storeData.salesChannels" bindLabel="description"
                                                                    bindValue="enumId" [(ngModel)]="searchFilter.salesChannelId"
                                                                    [ngModelOptions]="{standalone:true}" placeholder="Select Sales Channel"></ng-select>
                                                                </div>
                                                              
                                                               
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                    
                                                    <div style="margin-left: 28%!important;" class="col-lg-4 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                                        type="submit" class="btn btn-secondary submit-btn"
                                                        (click)="getQuetoList()">Find</button>
                                                        <button type="submit" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button></div>
                                                    </div><br>
                                                </div>
                                            </div>
                                        </div>
                                    
                                </div>

                                <!-- <div class="w3-card-4 classCard" >
                                    <div  class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <p class="h-text">Find Quotes</p>
                                            <button type="button" class="btn btn-outline-secondary"   
                                            (click)="createQuotation()">Create New Quotes</button>
                                            
                                      
                                    </div>
                                    <br>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Quote Name</label>
                                            </div>
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown styleClass="small-dropdown" filter="true"
                                                        [(ngModel)]="searchFilter.quoteNameSearchType"
                                                        [ngModelOptions]="{standalone:true}"
                                                        [options]="opportunityIdArray" optionlabel="label">
                                                    </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control w-100 small-input"
                                                        [(ngModel)]="searchFilter.quoteName"
                                                        [ngModelOptions]="{standalone:true}" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Quote Name">
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Quote ID</label>
                                            </div>
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown styleClass="small-dropdown" filter="true"
                                                [(ngModel)]="searchFilter.quoteIdSearchType"
                                                [ngModelOptions]="{standalone:true}"
                                                [options]="opportunityIdArray" optionlabel="label">
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control w-100 small-input"
                                                        [(ngModel)]="searchFilter.quoteId"
                                                        [ngModelOptions]="{standalone:true}" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Quote ID">
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Quote Type ID </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <ng-select [items]="storeData.quoteTypes" bindLabel="description"
                                                bindValue="quoteTypeId" [(ngModel)]="searchFilter.quoteTypeId"
                                                [ngModelOptions]="{standalone:true}"  placeholder="Enter Quote Type ID"></ng-select>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Party ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control small-input" id="exampleInputEmail1"
                                                [(ngModel)]="searchFilter.partyId" [ngModelOptions]="{standalone:true}"
                                                aria-describedby="emailHelp" placeholder="Enter Party ID">
                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Currency</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <ng-select [items]="currencyData" bindLabel="description" bindValue="uomId"
                                                        [(ngModel)]="searchFilter.currencyId"
                                                        [ngModelOptions]="{standalone:true}" placeholder="Select Currency"></ng-select>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Product Store</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <ng-select [items]="storeData.productStores" bindLabel="storeName"
                                                        bindValue="productStoreId" [(ngModel)]="searchFilter.productStoreId"
                                                        [ngModelOptions]="{standalone:true}" placeholder="Select Product Store"></ng-select>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Sales Channel</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <ng-select [items]="storeData.salesChannels" bindLabel="description"
                                                bindValue="enumId" [(ngModel)]="searchFilter.salesChannelId"
                                                [ngModelOptions]="{standalone:true}" placeholder="Select Sales Channel"></ng-select>
                                            </div>
                                          
                                           
                                        </div>
                                    </div>
                                
                                   
                                 
                                  
                                </div>
                                <div style="margin-left: 28%!important;" class="col-lg-4 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                        type="submit" class="btn btn-secondary submit-btn"
                                        (click)="getQuetoList()">Find</button>
                                        <button type="submit" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button></div>
                                </div><br> -->

                                <div class="w3-card-4 classCard mt-3">
                                    <header class="w3-container w3-blue">
                                      <div class="header-tabs">
                                        <h4 class=" common-styling h4Margin">
                                            Quotes List
                                        </h4>
                                     
                                      </div>
                                    </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                           
                                            <div class="card own-account-table">
                                                <p-table [value]="quetoList" [paginator]="true" [rows]="rows"
                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    Quote ID <p-sortIcon field="code"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                            pSortableColumn="quantity"><div style="color: white;"> Quotation Name <p-sortIcon
                                                                field="quantity"></p-sortIcon></div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">Quote Type ID<p-sortIcon field="name"></p-sortIcon></div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category"><div style="color: white;">Party ID<p-sortIcon
                                                                    field="category"></p-sortIcon></div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                           
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="quantity"><div style="color: white;">Status <p-sortIcon
                                                                    field="quantity"></p-sortIcon></div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="quantity"> <div style="color: white;">Sales Channel <p-sortIcon
                                                                    field="quantity"></p-sortIcon></div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="quantity"><div style="color: white;"> Product Store ID <p-sortIcon
                                                                    field="quantity"></p-sortIcon></div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="quantity"><div style="color: white;">Currency <p-sortIcon
                                                                    field="quantity"></p-sortIcon></div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="quantity"><div style="color: white;">Created At <p-sortIcon
                                                                    field="quantity"></p-sortIcon></div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                       
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'170px'}"
                                                                (click)="quoteDetails(product.quoteId)">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                 <span class="account-button"> {{product.quoteId}}</span>  
                                                                </div>
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.quoteName}}</td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.quoteType}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.partyId}}</td>
                                                           
                                                            <td [ngStyle]="{'width':'170px'}">{{product.status}}</td>
                                                          
                                                            <td [ngStyle]="{'width':'170px'}">{{product.salesChannel}}</td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.productStore}}</td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.uomDescription}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.createdStamp | date:'yyyy-MM-dd  HH:mm:ss'}}
                                                            </td>
                                                          

                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                               
                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
          

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 