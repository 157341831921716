<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
                <span class="color-grey titlePanels">Current Project: </span>
            </div>
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Current Project : {{workEffortId?workEffortId:''}}</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>

            <div class="bg-white color-grey" style="padding-bottom: 70px;" >
                <div class="w-100">
                    <div class="w3-card-4 classCard w-100">
                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                            <p class="h-text">Work Effort Related Summary</p>
                            <span>
                            <!-- <button type="submit" style="margin: 0 !important;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#updatePopup">Update</button> -->
                    </span>
                        </div>
                        <div class="all-users-infomation font-13 font-medium own-user-select" *ngIf="this.FixedAssetByWorkEffortId">
                            <div class="color-black container">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="article-container">
                                            <div class="article">
                                                <p class="col-lg-3">Work Effort ID</p>
                                                <span class="color-grey pl-20 font-weight-bold">
                                                    {{this.FixedAssetByWorkEffortId.result.workEffortId}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="article-container">
                                            <div class="article">
                                                <p class="col-lg-3">Name</p>
                                                <span class="color-grey pl-20 font-weight-bold">
                                                    {{this.FixedAssetByWorkEffortId.result.workEffortName}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="article-container">
                                            <div class="article">
                                                <p class="col-lg-3">Type</p>
                                                <span class="color-grey pl-20 font-weight-bold">
                                                    {{this.FixedAssetByWorkEffortId.result.workEffortTypeId}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="article-container">
                                            <div class="article">
                                                <p class="col-lg-3">Purpose</p>
                                                <span class="color-grey pl-20 font-weight-bold">
                                                    {{this.FixedAssetByWorkEffortId.result.workEffortPurposeTypeId}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="article-container">
                                            <div class="article">
                                                <p class="col-lg-3">Status</p>
                                                <span class="color-grey pl-20 font-weight-bold">
                                                    {{this.FixedAssetByWorkEffortId.result.currentStatusId}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="article-container">
                                            <div class="article">
                                                <p class="col-lg-3">Percent Complete</p>
                                                <span class="color-grey pl-20 font-weight-bold">
                                                    {{this.FixedAssetByWorkEffortId.result.percentComplete}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="article-container">
                                            <div class="article">
                                                <p class="col-lg-3">Estimated Start Date</p>
                                                <span class="color-grey pl-20 font-weight-bold">
                                                    {{this.FixedAssetByWorkEffortId.estimatedStartDate}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="article-container">
                                            <div class="article">
                                                <p class="col-lg-3">Estimated Completion date</p>
                                                <span class="color-grey pl-20 font-weight-bold">
                                                    {{this.FixedAssetByWorkEffortId.estimatedCompletionDate}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="article-container">
                                            <div class="article">
                                                <p class="col-lg-3">Actual Start Date</p>
                                                <span class="color-grey pl-20 font-weight-bold">
                                                    {{this.FixedAssetByWorkEffortId.actualStartDate}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="article-container">
                                            <div class="article">
                                                <p class="col-lg-3">Actual Completion Date</p>
                                                <span class="color-grey pl-20 font-weight-bold">
                                                    {{this.FixedAssetByWorkEffortId.actualCompletionDate}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="updatePopup" tabindex="-1" role="dialog" aria-labelledby="updatePopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Update Task List</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Task List</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createTaskList">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Start Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="EstimatedStartDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Estimated Completion Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="EstimatedCompletionDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Status" filter="true" optionlabel="label" formControlName="Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Maintenance Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Maintenance Type" optionlabel="label" filter="true" formControlName="MaintenanceType">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Maintenance</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product Maintenance" filter="true" formControlName="ProductMaintenance">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Interval Meter Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Interval Meter Type" filter="true" optionlabel="label" formControlName="IntervalMeterType">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Maintenance Template</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Maintenance Template" filter="true" optionlabel="label" formControlName="MaintenanceTemplate">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Purchase Order ID </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Purchase Order ID" filter="true" optionlabel="label" formControlName="PurchaseOrderID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Interval Quantity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Interval Quantity" formControlName="IntervalQuantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Party ID" filter="true" optionlabel="label" formControlName="PartyID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Interval Uom</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Interval Uom" filter="true" optionlabel="label" formControlName="IntervalUom">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1"> Completion Date</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="ActualCompletionDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Start Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="ActualStartDate">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>