import { Component, OnInit ,Input,Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isPayment = false;
  isInvoice = false;
  isAgreement = false;
  isAccountFin =false; 
  isAccountAP=true;
  isAccountAPNew=false
  isAccountAR=false;
  public token: boolean;
  jobInfo=false;
  isShown = false; // hidden by default
  isShow = false;
  isShowFin=false; // hidden by default
  isProductCatalog=false;
  adminSecurity: string;
  isfacility: boolean;
  isEmployee: boolean = false;
  isPaycheck: boolean = false
  isPayroll: boolean = false;
  isWorkOrder:boolean=false;
  isWorkZone:boolean=false;
  isModelYourBusiness:boolean=false;
  isCompany:boolean=false;
  isMobileSetting:boolean=false;
  isFinancial:boolean=false;
  isCustomers:boolean=false;
  isTimeSheet:boolean=false;
  isServiceAgreements:boolean=false;
  isWorkOrderNetwork:boolean=false;
  isSettings:boolean=false;

  userLoginId: string;
  isCatalog: boolean;
  iswebPOS: boolean;
  iswareHouse: boolean;
  isshipping: boolean;
  checkPOSToken: string;
  productStoreIdNew: string;
  posTerminalIdNew: string;
  userPermissions: any;
  isedi: boolean;
  isSecurity: any;
  isTechdiv:boolean=false;
  toggleView: boolean = true;
  @Input() navState: boolean;

  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);

  constructor(readonly router: Router,) { }

  ngOnInit(): void {
    
  this.userLoginId = localStorage.getItem("userLoginId");
  this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    const security: string = localStorage.getItem('adminSecurity');
      this.adminSecurity=security;

  } 
  openhidediv(){
    this.isTechdiv= !this.isTechdiv;
  }
  employeeToggle(){
    this.isEmployee = !this.isEmployee
  }
  paycheckToggle(){
    this.isPaycheck = !this.isPaycheck
  }
  payrollToggle(){
    this.isPayroll = !this.isPayroll;
  }

  workOrderToggle(){
    this.isWorkOrder = !this.isWorkOrder;
  }

  workZoneToggle()
  {
    this.isWorkZone = !this.isWorkZone;

  }

  securityToggle()
  {
    this.isSecurity = !this.isSecurity;

  }

  modelYourBusinessToggle()
  {
    this.isModelYourBusiness = !this.isModelYourBusiness;

  }

  companyToggle()
  {
    this.isCompany = !this.isCompany;

  }
  mobileSettingToggle()
  {
    this.isMobileSetting = !this.isMobileSetting;

  }

  financialToggle()
  {
    this.isFinancial = !this.isFinancial;

  }

  customersToggle()
  {
    this.isCustomers = !this.isCustomers;

  }

  timeSheetToggle()
  {
    this.isTimeSheet = !this.isTimeSheet;

  }

  serviceAgreementsToggle()
  {
    this.isServiceAgreements = !this.isServiceAgreements;
    
  }


  workOrdersNetworkToggle()
  {
    this.isWorkOrderNetwork = !this.isWorkOrderNetwork;

  }

  settingsToggle()
  {
    this.isSettings= !this.isSettings;

  }

  ediToggle()
  {
    this.isedi= !this.isedi;

  }


  toggleShow() {

    this.isShown = !this.isShown;

  }

  ShowRecrSubMenu() {
    this.isShow = !this.isShow;
  }
  ShowRecrSubMenuFin(){
    this.isShowFin=!this.isShowFin;
  }
  toggleJobInfo(){
    this.jobInfo=!this.jobInfo;
  }
  ShowRecrSubMenuFinAccountDetail(){
    this.isAccountFin=!this.isAccountFin;
    this.isAccountAR=false;
    this.isAccountAP=false;
  }
  ShowRecrSubMenuAccountingAP(){
    this.isAccountAP=!this.isAccountAP;
    this.isAccountFin=false;
    this.isAccountAR=false;
    this.isAccountAPNew=!this.isAccountAPNew;

  }
  ShowRecrSubMenuAccountingAR(){
    this.isAccountAR=!this.isAccountAR;
    this.isAccountAP=false;
    this.isAccountFin=false;
  }
  ShowRecrSubProduct(){
    this.isProductCatalog=!this.isProductCatalog;
    this.isfacility=false;
  }
  ShowRecFacility(){
    this.isfacility=!this.isfacility;
    this.isProductCatalog=false;
  }

  showCatalog(){
    this.isCatalog=!this.isCatalog;
    this.iswebPOS=false;
    this.iswareHouse=false;
    this.isshipping=false;
  }
  showwebPOS(){
    this.iswebPOS=!this.iswebPOS;
    this.isCatalog=false;
    this.iswareHouse=false;
    this.isshipping=false;
  }
  showwareHouse(){
    this.iswareHouse=!this.iswareHouse;
        this.isCatalog=false;
            this.iswebPOS=false;
                this.isshipping=false;
  }
  showshipping(){
    this.isshipping=!this.isshipping;
        this.isCatalog=false;
            this.iswebPOS=false;
                this.iswareHouse=false;
  }
  logoutPOS() {
    this.checkPOSToken = localStorage.getItem('posToken');
    if(this.checkPOSToken){
      this.productStoreIdNew = localStorage.getItem('productStoreId');
      this.posTerminalIdNew = localStorage.getItem('posTerminalId');
      this.router.navigate(["/webpos/control/main"],{queryParams:{ posTerminalId :  this.posTerminalIdNew, productStoreId:this.productStoreIdNew}});
    }
    else{
      localStorage.removeItem("token");
      localStorage.removeItem('posToken');
      localStorage.removeItem('productStoreId');
      localStorage.removeItem('posTerminalId');
      localStorage.removeItem('userLoginId');
      this.router.navigate(["/login"],{queryParams:{ webPos :  "webPos"}});
    }
   

  }
  logoutPosManager(){
    this.checkPOSToken = localStorage.getItem('posToken');
    if(this.checkPOSToken){
      this.productStoreIdNew = localStorage.getItem('productStoreId');
      this.posTerminalIdNew = localStorage.getItem('posTerminalId');
      this.router.navigate(["/webpos/control/manager"],{queryParams:{ posTerminalId :  this.posTerminalIdNew, productStoreId:this.productStoreIdNew}});
    }
    else{
      localStorage.removeItem("token");
      localStorage.removeItem('posToken');
      localStorage.removeItem('productStoreId');
      localStorage.removeItem('posTerminalId');
      localStorage.removeItem('userLoginId');
      this.router.navigate(["/login"],{queryParams:{ webPos :  "webPos"}});
    }
  }

  updateView() {
    this.toggleView = !this.toggleView;
    //this.appService.updateView(this.toggleView);
  }
}