import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { Router } from '@angular/router';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import { DashboardService } from 'src/app/services/dashboard.service';
import { ViewChild } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';

import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexChart,
  ApexPlotOptions,
  ApexLegend,
  ChartComponent
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
};


@Component({
  selector: 'app-hrm-dashboard',
  templateUrl: './hrm-dashboard.component.html',
  styleUrls: ['./hrm-dashboard.component.css']
})

export class HrmDashboardComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  r:any;
  activeCategory=2;
  chartValue: any[];
  onSickLeave: number;
  totalEmployee: number;
  activeEmployee: number;
  chartArray:any[]=[];
  hrmChart:any;
  c: any[] = [];




  constructor(private accountsService: AccountsService,
    readonly router: Router,
    private _DashboardService: DashboardService
  ) {

    // this.chartValue = [];
    // this.onSickLeave = 0;
    // this.totalEmployee = 0;
    // this.activeEmployee = 0;

    this.chartOptions = {
      series: [
        {
          data: [
            {
              x: "Skills",
              y: 1.2
            },
            {
              x: "Training",
              y: 0.4
            },
            {
              x: "Leaves",
              y: -1.4
            },
            {
              x: "Recruitment",
              y: 2.7
            },
            {
              x: "Employee",
              y: -0.3
            },
            {
              x: "Department",
              y: 5.1
            },
            {
              x: "Employment",
              y: -2.3
            },
            {
              x: "Timesheet",
              y: 2.1
            },
            {
              x: "Employee Application",
              y: 0.3
            },
           
            {
              x: "Performance Review",
              y: -2.31
            },
            {
              x: "Employee Position",
              y: 3.98
            },
           
          ]
        }
      ],
      legend: {
        show: false
      },
      chart: {
        height: 350,
        type: "treemap"
      },
      title: {
        text: ""
      },
      dataLabels: {
        enabled: true,

        offsetY: -3
      },
      plotOptions: {
        treemap: {
          enableShades: true,
          shadeIntensity: 0.5,
          reverseNegativeShade: true,
          colorScale: {
            ranges: [
              {
                from: -6,
                to: 0,
                color: "#CD363A"
              },
              {
                from: 0.001,
                to: 6,
                color: "#52B12C"
              }
            ]
          }
        }
      }
    };
  }
  public generateData(count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = "w" + (i + 1).toString();
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({
        x: x,
        y: y
      });
      i++;
    }
    return series;
  }
  ngOnInit(): void { 
    this.gethrmsDashboardChart();
  }

  gethrmsDashboardChart() {
    this._DashboardService.gethrmsDashboardChart().subscribe((res: any) => {
    this.hrmChart = res.data;
    for(let b of this.hrmChart){
      for(this.r of b){
        this.c.push({
          x:  this.r.x,
          y:  this.r.y,
         
         }),
        this.chartOptions = {
          series: [
            {
              data: this.c  
            }
          ],
          legend: {
            show: false
          },
          chart: {
            height: 350,
            type: "treemap"
          },
          title: {
            text: ""
          },
          dataLabels: {
            enabled: true,
    
            offsetY: -3
          },
          plotOptions: {
            treemap: {
              enableShades: true,
              shadeIntensity: 0.5,
              reverseNegativeShade: true,
              colorScale: {
                ranges: [
                 
                  {
                    from: 0,
                    to: 2,
                    color: "#e83e8c"
                  },
                  {
                    from: 2,
                    to: 20,
                    color: "#52B12C"
                  },
                  {
                    from: 20,
                    to: 30,
                    color: "#6f42c1"
                  },
                  {
                    from: 30,
                    to: 50,
                    color: "#CD363A"
                  }
                ]
              }
            }
          }
        };
        console.log(this.r,"chartArray inside")
      }
    }

    console.log(this.r,"chartArray")

    
    })
  }


  // ngAfterViewInit() {
  //   am4core.useTheme(am4themes_animated);
  //   am4core.useTheme(am4themes_material);
  //   //Bar Graph
  //   let chart: any = am4core.create("chartdiv", am4charts.XYChart);
  //   // some extra padding for range labels
  //   chart.paddingBottom = 50;

  //   chart.cursor = new am4charts.XYCursor();
  //   chart.scrollbarX = new am4core.Scrollbar();
  //   // will use this to store colors of the same items
  //   let colors = {};
  //   let categoryAxis: any = chart.xAxes.push(new am4charts.CategoryAxis());
  //   categoryAxis.dataFields.category = "category";
  //   categoryAxis.renderer.minGridDistance = 60;
  //   categoryAxis.renderer.grid.template.location = 0;
  //   categoryAxis.dataItems.template.text = "{realName}";
  //   categoryAxis.adapter.add("tooltipText", function (tooltipText, target) {
  //     return categoryAxis.tooltipDataItem.dataContext.realName;
  //   })
  //   let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //   valueAxis.tooltip.disabled = true;
  //   valueAxis.min = 0;
  //   // single column series for all data
  //   let columnSeries = chart.series.push(new am4charts.ColumnSeries());
  //   columnSeries.columns.template.width = am4core.percent(80);
  //   columnSeries.tooltipText = "{provider}: {realName}, {valueY}";
  //   columnSeries.dataFields.categoryX = "category";
  //   columnSeries.dataFields.valueY = "value";
  //   // second value axis for quantity
  //   let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
  //   valueAxis2.renderer.opposite = true;
  //   valueAxis2.syncWithAxis = valueAxis;
  //   valueAxis2.tooltip.disabled = true;
  //   // quantity line series
  //   let lineSeries = chart.series.push(new am4charts.LineSeries());
  //   lineSeries.tooltipText = "{valueY}";
  //   lineSeries.dataFields.categoryX = "category";
  //   lineSeries.dataFields.valueY = "quantity";
  //   lineSeries.yAxis = valueAxis2;
  //   lineSeries.bullets.push(new am4charts.CircleBullet());
  //   lineSeries.stroke = chart.colors.getIndex(13);
  //   lineSeries.fill = lineSeries.stroke;
  //   lineSeries.strokeWidth = 2;
  //   lineSeries.snapTooltip = true;
  //   // when data validated, adjust location of data item based on count
  //   lineSeries.events.on("datavalidated", function () {
  //     lineSeries.dataItems.each(function (dataItem) {
  //       // if count divides by two, location is 0 (on the grid)
  //       if (dataItem.dataContext.count / 2 == Math.round(dataItem.dataContext.count / 2)) {
  //         dataItem.setLocation("categoryX", 0);
  //       }
  //       // otherwise location is 0.5 (middle)
  //       else {
  //         dataItem.setLocation("categoryX", 0.5);
  //       }
  //     })
  //   })
  //   // fill adapter, here we save color value to colors object so that each time the item has the same name, the same color is used
  //   columnSeries.columns.template.adapter.add("fill", function (fill, target) {
  //     let name = target.dataItem.dataContext.realName;
  //     if (!colors[name]) {
  //       colors[name] = chart.colors.next();
  //     }
  //     target.stroke = colors[name];
  //     return colors[name];
  //   })
  //   let rangeTemplate = categoryAxis.axisRanges.template;
  //   rangeTemplate.tick.disabled = false;
  //   rangeTemplate.tick.location = 0;
  //   rangeTemplate.tick.strokeOpacity = 0.6;
  //   rangeTemplate.tick.length = 60;
  //   rangeTemplate.grid.strokeOpacity = 0.5;
  //   rangeTemplate.label.tooltip = new am4core.Tooltip();
  //   rangeTemplate.label.tooltip.dy = -10;
  //   rangeTemplate.label.cloneTooltip = false;
  //   ///// DATA
  //   let chartData = [];
  //   let lineSeriesData = [];
  //   let data =
  //   {
  //     "Provider 1": {
  //       "item 1": 10,
  //       "item 2": 35,
  //       "item 3": 5,
  //       "item 4": 20,
  //       "quantity": 430
  //     },
  //     "Provider 2": {
  //       "item 1": 15,
  //       "item 3": 21,
  //       "quantity": 210
  //     },
  //     "Provider 3": {
  //       "item 2": 25,
  //       "item 3": 11,
  //       "item 4": 17,
  //       "quantity": 265
  //     },
  //     "Provider 4": {
  //       "item 3": 12,
  //       "item 4": 15,
  //       "quantity": 98
  //     }
  //   }
  //   // process data ant prepare it for the chart
  //   for (var providerName in data) {
  //     let providerData = data[providerName];
  //     // add data of one provider to temp array
  //     let tempArray = [];
  //     let count = 0;
  //     // add items
  //     for (var itemName in providerData) {
  //       if (itemName != "quantity") {
  //         count++;
  //         // we generate unique category for each column (providerName + "_" + itemName) and store realName
  //         tempArray.push({ category: providerName + "_" + itemName, realName: itemName, value: providerData[itemName], provider: providerName })
  //       }
  //     }
  //     // sort temp array
  //     tempArray.sort(function (a, b) {
  //       if (a.value > b.value) {
  //         return 1;
  //       }
  //       else if (a.value < b.value) {
  //         return -1
  //       }
  //       else {
  //         return 0;
  //       }
  //     })

  //     // add quantity and count to middle data item (line series uses it)
  //     let lineSeriesDataIndex = Math.floor(count / 2);
  //     tempArray[lineSeriesDataIndex].quantity = providerData.quantity;
  //     tempArray[lineSeriesDataIndex].count = count;
  //     // push to the final data
  //     am4core.array.each(tempArray, function (item) {
  //       chartData.push(item);
  //     })

  //     // create range (the additional label at the bottom)
  //     let range = categoryAxis.axisRanges.create();
  //     range.category = tempArray[0].category;
  //     range.endCategory = tempArray[tempArray.length - 1].category;
  //     range.label.text = tempArray[0].provider;
  //     range.label.dy = 30;
  //     range.label.truncate = true;
  //     range.label.fontWeight = "bold";
  //     range.label.tooltipText = tempArray[0].provider;

  //     range.label.adapter.add("maxWidth", function (maxWidth, target) {
  //       //let range = target.dataItem;
  //       let startPosition = categoryAxis.categoryToPosition(range.category, 0);
  //       let endPosition = categoryAxis.categoryToPosition(range.endCategory, 1);
  //       let startX = categoryAxis.positionToCoordinate(startPosition);
  //       let endX = categoryAxis.positionToCoordinate(endPosition);
  //       return endX - startX;
  //     })
  //   }

  //   chart.data = chartData;
  //   // last tick
  //   let range = categoryAxis.axisRanges.create();
  //   range.category = chart.data[chart.data.length - 1].category;
  //   range.label.disabled = true;
  //   range.tick.location = 1;
  //   range.grid.location = 1;










  //   //Simple Pie Chart
  //   let cha = am4core.create("chartdiv1", am4charts.PieChart);

  //   // Add data
  //   cha.data = [{
  //     "country": "Lithuania",
  //     "litres": 501.9
  //   }, {
  //     "country": "Czechia",
  //     "litres": 301.9
  //   }, {
  //     "country": "Ireland",
  //     "litres": 201.1
  //   }, {
  //     "country": "Germany",
  //     "litres": 165.8
  //   }, {
  //     "country": "Australia",
  //     "litres": 139.9
  //   }, {
  //     "country": "Austria",
  //     "litres": 128.3
  //   }, {
  //     "country": "UK",
  //     "litres": 99
  //   }
  //   ];

  //   // Add and configure Series
  //   let pieSeries = cha.series.push(new am4charts.PieSeries());
  //   pieSeries.dataFields.value = "litres";
  //   pieSeries.dataFields.category = "country";
  //   pieSeries.slices.template.stroke = am4core.color("#fff");
  //   pieSeries.slices.template.strokeOpacity = 1;

  //   // This creates initial animation
  //   pieSeries.hiddenState.properties.opacity = 1;
  //   pieSeries.hiddenState.properties.endAngle = -90;
  //   pieSeries.hiddenState.properties.startAngle = -90;
  //   cha.hiddenState.properties.radius = am4core.percent(0);










  //   //Collapsible Force-directed tree
  //   var chart1 = am4core.create("chartdiv2", am4plugins_forceDirected.ForceDirectedTree);
  //   chart1.legend = new am4charts.Legend();
  //   var networkSeries = chart1.series.push(new am4plugins_forceDirected.ForceDirectedSeries())

  //   networkSeries.data = [{
  //     name: 'Flora',
  //     children: [{
  //       name: 'Black Tea', value: 1
  //     }, {
  //       name: 'Floral',
  //       children: [{
  //         name: 'Chamomile', value: 1
  //       }, {
  //         name: 'Rose', value: 1
  //       }, {
  //         name: 'Jasmine', value: 1
  //       }]
  //     }]
  //   }, {
  //     name: 'Fruity',
  //     children: [{
  //       name: 'Berry',
  //       children: [{
  //         name: 'Blackberry', value: 1
  //       }, {
  //         name: 'Raspberry', value: 1
  //       }, {
  //         name: 'Blueberry', value: 1
  //       }, {
  //         name: 'Strawberry', value: 1
  //       }]
  //     }, {
  //       name: 'Dried Fruit',
  //       children: [{
  //         name: 'Raisin', value: 1
  //       }, {
  //         name: 'Prune', value: 1
  //       }]
  //     }, {
  //       name: 'Other Fruit',
  //       children: [{
  //         name: 'Coconut', value: 1
  //       }, {
  //         name: 'Cherry', value: 1
  //       }, {
  //         name: 'Pomegranate', value: 1
  //       }, {
  //         name: 'Pineapple', value: 1
  //       }, {
  //         name: 'Grape', value: 1
  //       }, {
  //         name: 'Apple', value: 1
  //       }, {
  //         name: 'Peach', value: 1
  //       }, {
  //         name: 'Pear', value: 1
  //       }]
  //     }, {
  //       name: 'Citrus Fruit',
  //       children: [{
  //         name: 'Grapefruit', value: 1
  //       }, {
  //         name: 'Orange', value: 1
  //       }, {
  //         name: 'Lemon', value: 1
  //       }, {
  //         name: 'Lime', value: 1
  //       }]
  //     }]
  //   }, {
  //     name: 'Sour/Fermented',
  //     children: [{
  //       name: 'Sour',
  //       children: [{
  //         name: 'Sour Aromatics', value: 1
  //       }, {
  //         name: 'Acetic Acid', value: 1
  //       }, {
  //         name: 'Butyric Acid', value: 1
  //       }, {
  //         name: 'Isovaleric Acid', value: 1
  //       }, {
  //         name: 'Citric Acid', value: 1
  //       }, {
  //         name: 'Malic Acid', value: 1
  //       }]
  //     }, {
  //       name: 'Alcohol/Fremented',
  //       children: [{
  //         name: 'Winey', value: 1
  //       }, {
  //         name: 'Whiskey', value: 1
  //       }, {
  //         name: 'Fremented', value: 1
  //       }, {
  //         name: 'Overripe', value: 1
  //       }]
  //     }]
  //   }, {
  //     name: 'Green/Vegetative',
  //     children: [{
  //       name: 'Olive Oil', value: 1
  //     }, {
  //       name: 'Raw', value: 1
  //     }, {
  //       name: 'Green/Vegetative',
  //       children: [{
  //         name: 'Under-ripe', value: 1
  //       }, {
  //         name: 'Peapod', value: 1
  //       }, {
  //         name: 'Fresh', value: 1
  //       }, {
  //         name: 'Dark Green', value: 1
  //       }, {
  //         name: 'Vegetative', value: 1
  //       }, {
  //         name: 'Hay-like', value: 1
  //       }, {
  //         name: 'Herb-like', value: 1
  //       }]
  //     }, {
  //       name: 'Beany', value: 1
  //     }]
  //   }, {
  //     name: 'Other',
  //     children: [{
  //       name: 'Papery/Musty',
  //       children: [{
  //         name: 'Stale', value: 1
  //       }, {
  //         name: 'Cardboard', value: 1
  //       }, {
  //         name: 'Papery', value: 1
  //       }, {
  //         name: 'Woody', value: 1
  //       }, {
  //         name: 'Moldy/Damp', value: 1
  //       }, {
  //         name: 'Musty/Dusty', value: 1
  //       }, {
  //         name: 'Musty/Earthy', value: 1
  //       }, {
  //         name: 'Animalic', value: 1
  //       }, {
  //         name: 'Meaty Brothy', value: 1
  //       }, {
  //         name: 'Phenolic', value: 1
  //       }]
  //     }, {
  //       name: 'Chemical',
  //       children: [{
  //         name: 'Bitter', value: 1
  //       }, {
  //         name: 'Salty', value: 1
  //       }, {
  //         name: 'Medicinal', value: 1
  //       }, {
  //         name: 'Petroleum', value: 1
  //       }, {
  //         name: 'Skunky', value: 1
  //       }, {
  //         name: 'Rubber', value: 1
  //       }]
  //     }]
  //   }, {
  //     name: 'Roasted',
  //     children: [{
  //       name: 'Pipe Tobacco', value: 1
  //     }, {
  //       name: 'Tobacco', value: 1
  //     }, {
  //       name: 'Burnt',
  //       children: [{
  //         name: 'Acrid', value: 1
  //       }, {
  //         name: 'Ashy', value: 1
  //       }, {
  //         name: 'Smoky', value: 1
  //       }, {
  //         name: 'Brown, Roast', value: 1
  //       }]
  //     }, {
  //       name: 'Cereal',
  //       children: [{
  //         name: 'Grain', value: 1
  //       }, {
  //         name: 'Malt', value: 1
  //       }]
  //     }]
  //   }, {
  //     name: 'Spices',
  //     children: [{
  //       name: 'Pungent', value: 1
  //     }, {
  //       name: 'Pepper', value: 1
  //     }, {
  //       name: 'Brown Spice',
  //       children: [{
  //         name: 'Anise', value: 1
  //       }, {
  //         name: 'Nutmeg', value: 1
  //       }, {
  //         name: 'Cinnamon', value: 1
  //       }, {
  //         name: 'Clove', value: 1
  //       }]
  //     }]
  //   }, {
  //     name: 'Nutty/Cocoa',
  //     children: [{
  //       name: 'Nutty',
  //       children: [{
  //         name: 'Peanuts', value: 1
  //       }, {
  //         name: 'Hazelnut', value: 1
  //       }, {
  //         name: 'Almond', value: 1
  //       }]
  //     }, {
  //       name: 'Cocoa',
  //       children: [{
  //         name: 'Chocolate', value: 1
  //       }, {
  //         name: 'Dark Chocolate', value: 1
  //       }]
  //     }]
  //   }, {
  //     name: 'Sweet',
  //     children: [{
  //       name: 'Brown Sugar',
  //       children: [{
  //         name: 'Molasses', value: 1
  //       }, {
  //         name: 'Maple Syrup', value: 1
  //       }, {
  //         name: 'Caramelized', value: 1
  //       }, {
  //         name: 'Honey', value: 1
  //       }]
  //     }, {
  //       name: 'Vanilla', value: 1
  //     }, {
  //       name: 'Vanillin', value: 1
  //     }, {
  //       name: 'Overall Sweet', value: 1
  //     }, {
  //       name: 'Sweet Aromatics', value: 1
  //     }]
  //   }];

  //   networkSeries.dataFields.linkWith = "linkWith";
  //   networkSeries.dataFields.name = "name";
  //   networkSeries.dataFields.id = "name";
  //   networkSeries.dataFields.value = "value";
  //   networkSeries.dataFields.children = "children";

  //   networkSeries.nodes.template.tooltipText = "{name}";
  //   networkSeries.nodes.template.fillOpacity = 1;

  //   networkSeries.nodes.template.label.text = "{name}"
  //   networkSeries.fontSize = 8;
  //   networkSeries.maxLevels = 2;
  //   networkSeries.maxRadius = am4core.percent(6);
  //   networkSeries.manyBodyStrength = -16;
  //   networkSeries.nodes.template.label.hideOversized = true;
  //   networkSeries.nodes.template.label.truncate = true;







  //   //Duration on Value axis
  //   let chart3 = am4core.create("chartdiv3", am4charts.XYChart);
  //   chart3.colors.step = 2;
  //   chart3.maskBullets = false;

  //   // Add data
  //   chart3.data = [{
  //     "date": "2012-01-01",
  //     "distance": 227,
  //     "townName": "New York",
  //     "townName2": "New York",
  //     "townSize": 12,
  //     "latitude": 40.71,
  //     "duration": 408
  //   }, {
  //     "date": "2012-01-02",
  //     "distance": 371,
  //     "townName": "Washington",
  //     "townSize": 7,
  //     "latitude": 38.89,
  //     "duration": 482
  //   }, {
  //     "date": "2012-01-03",
  //     "distance": 433,
  //     "townName": "Wilmington",
  //     "townSize": 3,
  //     "latitude": 34.22,
  //     "duration": 562
  //   }, {
  //     "date": "2012-01-04",
  //     "distance": 345,
  //     "townName": "Jacksonville",
  //     "townSize": 3.5,
  //     "latitude": 30.35,
  //     "duration": 379
  //   }, {
  //     "date": "2012-01-05",
  //     "distance": 480,
  //     "townName": "Miami",
  //     "townName2": "Miami",
  //     "townSize": 5,
  //     "latitude": 25.83,
  //     "duration": 501
  //   }, {
  //     "date": "2012-01-06",
  //     "distance": 386,
  //     "townName": "Tallahassee",
  //     "townSize": 3.5,
  //     "latitude": 30.46,
  //     "duration": 443
  //   }, {
  //     "date": "2012-01-07",
  //     "distance": 348,
  //     "townName": "New Orleans",
  //     "townSize": 5,
  //     "latitude": 29.94,
  //     "duration": 405
  //   }, {
  //     "date": "2012-01-08",
  //     "distance": 238,
  //     "townName": "Houston",
  //     "townName2": "Houston",
  //     "townSize": 8,
  //     "latitude": 29.76,
  //     "duration": 309
  //   }, {
  //     "date": "2012-01-09",
  //     "distance": 218,
  //     "townName": "Dalas",
  //     "townSize": 8,
  //     "latitude": 32.8,
  //     "duration": 287
  //   }, {
  //     "date": "2012-01-10",
  //     "distance": 349,
  //     "townName": "Oklahoma City",
  //     "townSize": 5,
  //     "latitude": 35.49,
  //     "duration": 485
  //   }, {
  //     "date": "2012-01-11",
  //     "distance": 603,
  //     "townName": "Kansas City",
  //     "townSize": 5,
  //     "latitude": 39.1,
  //     "duration": 890
  //   }, {
  //     "date": "2012-01-12",
  //     "distance": 534,
  //     "townName": "Denver",
  //     "townName2": "Denver",
  //     "townSize": 9,
  //     "latitude": 39.74,
  //     "duration": 810
  //   }, {
  //     "date": "2012-01-13",
  //     "townName": "Salt Lake City",
  //     "townSize": 6,
  //     "distance": 425,
  //     "duration": 670,
  //     "latitude": 40.75,
  //     "dashLength": 8,
  //     "alpha": 0.4
  //   }, {
  //     "date": "2012-01-14",
  //     "latitude": 36.1,
  //     "duration": 470,
  //     "townName": "Las Vegas",
  //     "townName2": "Las Vegas"
  //   }, {
  //     "date": "2012-01-15"
  //   }, {
  //     "date": "2012-01-16"
  //   }, {
  //     "date": "2012-01-17"
  //   }];

  //   // Create axes
  //   let dateAxis = chart3.xAxes.push(new am4charts.DateAxis());
  //   dateAxis.renderer.grid.template.location = 0;
  //   dateAxis.renderer.minGridDistance = 50;
  //   dateAxis.renderer.grid.template.disabled = true;
  //   dateAxis.renderer.fullWidthTooltip = true;

  //   let distanceAxis = chart3.yAxes.push(new am4charts.ValueAxis());
  //   distanceAxis.title.text = "Distance";
  //   //distanceAxis.renderer.grid.template.disabled = true;

  //   let durationAxis = chart3.yAxes.push(new am4charts.DurationAxis());
  //   durationAxis.title.text = "Duration";
  //   durationAxis.baseUnit = "minute";
  //   //durationAxis.renderer.grid.template.disabled = true;
  //   durationAxis.renderer.opposite = true;
  //   durationAxis.syncWithAxis = distanceAxis;

  //   durationAxis.durationFormatter.durationFormat = "hh'h' mm'min'";

  //   let latitudeAxis = chart3.yAxes.push(new am4charts.ValueAxis());
  //   latitudeAxis.renderer.grid.template.disabled = true;
  //   latitudeAxis.renderer.labels.template.disabled = true;
  //   latitudeAxis.syncWithAxis = distanceAxis;

  //   // Create series
  //   let distanceSeries = chart3.series.push(new am4charts.ColumnSeries());
  //   distanceSeries.dataFields.valueY = "distance";
  //   distanceSeries.dataFields.dateX = "date";
  //   distanceSeries.yAxis = distanceAxis;
  //   distanceSeries.tooltipText = "{valueY} miles";
  //   distanceSeries.name = "Distance";
  //   distanceSeries.columns.template.fillOpacity = 0.7;
  //   distanceSeries.columns.template.propertyFields.strokeDasharray = "dashLength";
  //   distanceSeries.columns.template.propertyFields.fillOpacity = "alpha";
  //   distanceSeries.showOnInit = true;

  //   let distanceState = distanceSeries.columns.template.states.create("hover");
  //   distanceState.properties.fillOpacity = 0.9;

  //   let durationSeries = chart3.series.push(new am4charts.LineSeries());
  //   durationSeries.dataFields.valueY = "duration";
  //   durationSeries.dataFields.dateX = "date";
  //   durationSeries.yAxis = durationAxis;
  //   durationSeries.name = "Duration";
  //   durationSeries.strokeWidth = 2;
  //   durationSeries.propertyFields.strokeDasharray = "dashLength";
  //   durationSeries.tooltipText = "{valueY.formatDuration()}";
  //   durationSeries.showOnInit = true;

  //   let durationBullet = durationSeries.bullets.push(new am4charts.Bullet());
  //   let durationRectangle = durationBullet.createChild(am4core.Rectangle);
  //   durationBullet.horizontalCenter = "middle";
  //   durationBullet.verticalCenter = "middle";
  //   durationBullet.width = 7;
  //   durationBullet.height = 7;
  //   durationRectangle.width = 7;
  //   durationRectangle.height = 7;

  //   let durationState = durationBullet.states.create("hover");
  //   durationState.properties.scale = 1.2;

  //   let latitudeSeries = chart3.series.push(new am4charts.LineSeries());
  //   latitudeSeries.dataFields.valueY = "latitude";
  //   latitudeSeries.dataFields.dateX = "date";
  //   latitudeSeries.yAxis = latitudeAxis;
  //   latitudeSeries.name = "Duration";
  //   latitudeSeries.strokeWidth = 2;
  //   latitudeSeries.propertyFields.strokeDasharray = "dashLength";
  //   latitudeSeries.tooltipText = "Latitude: {valueY} ({townName})";
  //   latitudeSeries.showOnInit = true;

  //   let latitudeBullet = latitudeSeries.bullets.push(new am4charts.CircleBullet());
  //   latitudeBullet.circle.fill = am4core.color("#fff");
  //   latitudeBullet.circle.strokeWidth = 2;
  //   latitudeBullet.circle.propertyFields.radius = "townSize";

  //   let latitudeState = latitudeBullet.states.create("hover");
  //   latitudeState.properties.scale = 1.2;

  //   let latitudeLabel = latitudeSeries.bullets.push(new am4charts.LabelBullet());
  //   latitudeLabel.label.text = "{townName2}";
  //   latitudeLabel.label.horizontalCenter = "left";
  //   latitudeLabel.label.dx = 14;

  //   // Add legend
  //   chart3.legend = new am4charts.Legend();

  //   // Add cursor
  //   chart3.cursor = new am4charts.XYCursor();
  //   chart3.cursor.fullWidthLineX = true;
  //   chart3.cursor.xAxis = dateAxis;
  //   chart3.cursor.lineX.strokeOpacity = 0;
  //   chart3.cursor.lineX.fill = am4core.color("#000");
  //   chart3.cursor.lineX.fillOpacity = 0.1;











  //   this._DashboardService.getHrmsData()
  //     .then(data => {
       
  //       this.chartValue = Object.keys(data.data.employeeInDepartments).map(_ => {
  //         return {
  //           name: _,
  //           value: data.data.employeeInDepartments[_]
  //         };
  //       });
  //       this.onSickLeave = data.data.onSickLeave;
  //       this.activeEmployee = data.data.activeEmployees;
  //       this.totalEmployee = data.data.totalEmployees;

  //       //Pictorial Stacked Chart
  //       var iconPath = "M53.5,476c0,14,6.833,21,20.5,21s20.5-7,20.5-21V287h21v189c0,14,6.834,21,20.5,21 c13.667,0,20.5-7,20.5-21V154h10v116c0,7.334,2.5,12.667,7.5,16s10.167,3.333,15.5,0s8-8.667,8-16V145c0-13.334-4.5-23.667-13.5-31 s-21.5-11-37.5-11h-82c-15.333,0-27.833,3.333-37.5,10s-14.5,17-14.5,31v133c0,6,2.667,10.333,8,13s10.5,2.667,15.5,0s7.5-7,7.5-13 V154h10V476 M61.5,42.5c0,11.667,4.167,21.667,12.5,30S92.333,85,104,85s21.667-4.167,30-12.5S146.5,54,146.5,42 c0-11.335-4.167-21.168-12.5-29.5C125.667,4.167,115.667,0,104,0S82.333,4.167,74,12.5S61.5,30.833,61.5,42.5z"

  //       var chart4 = am4core.create("chartdiv4", am4charts.SlicedChart);
  //       chart4.hiddenState.properties.opacity = 0; // this makes initial fade in effect

  //       chart4.data = [...this.chartValue, ...[{
  //         name: 'Sick Leave',
  //         value: this.onSickLeave
  //       },
  //       {
  //         name: 'Total Employee',
  //         value: this.totalEmployee
  //       },
  //       {
  //         name: 'Active Employee',
  //         value: this.activeEmployee
  //       }]];
       

  //       var series = chart4.series.push(new am4charts.PictorialStackedSeries());
  //       series.dataFields.value = "value";
  //       series.dataFields.category = "name";
  //       series.alignLabels = true;

  //       series.maskSprite.path = iconPath;
  //       series.ticks.template.locationX = 1;
  //       series.ticks.template.locationY = 0.5;

  //       series.labelsContainer.width = 200;

  //       chart4.legend = new am4charts.Legend();
  //       chart4.legend.position = "left";
  //       chart4.legend.valign = "bottom";




















  //       //Gauge with bands
  //       var chartMin = -50;
  //       var chartMax = 100;



  //       var data1 = {
  //         score: 52.7,
  //         gradingData: [
  //           {
  //             title: "Unsustainable",
  //             color: "#ee1f25",
  //             lowScore: -100,
  //             highScore: -20
  //           },
  //           {
  //             title: "Volatile",
  //             color: "#f04922",
  //             lowScore: -20,
  //             highScore: 0
  //           },
  //           {
  //             title: "Foundational",
  //             color: "#fdae19",
  //             lowScore: 0,
  //             highScore: 20
  //           },
  //           {
  //             title: "Developing",
  //             color: "#f3eb0c",
  //             lowScore: 20,
  //             highScore: 40
  //           },
  //           {
  //             title: "Maturing",
  //             color: "#b0d136",
  //             lowScore: 40,
  //             highScore: 60
  //           },
  //           {
  //             title: "Sustainable",
  //             color: "#54b947",
  //             lowScore: 60,
  //             highScore: 80
  //           },
  //           {
  //             title: "High Performing",
  //             color: "#0f9747",
  //             lowScore: 80,
  //             highScore: 100
  //           }
  //         ]
  //       };

  //       /**
  //       Grading Lookup
  //        */
  //       function lookUpGrade(lookupScore, grades) {
  //         // Only change code below this line
  //         for (var i = 0; i < grades.length; i++) {
  //           if (
  //             grades[i].lowScore < lookupScore &&
  //             grades[i].highScore >= lookupScore
  //           ) {
  //             return grades[i];
  //           }
  //         }
  //         return null;
  //       }

  //       // create chart
  //       var chart6: any = am4core.create("chartdiv6", am4charts.GaugeChart);
  //       chart6.hiddenState.properties.opacity = 0;
  //       chart6.fontSize = 11;
  //       chart6.innerRadius = am4core.percent(80);
  //       chart6.resizable = true;

  //       /**
  //        * Normal axis
  //        */

  //       var axis: any = chart6.xAxes.push(new am4charts.ValueAxis());
  //       axis.min = chartMin;
  //       axis.max = chartMax;
  //       axis.strictMinMax = true;
  //       axis.renderer.radius = am4core.percent(80);
  //       axis.renderer.inside = true;
  //       axis.renderer.line.strokeOpacity = 0.1;
  //       axis.renderer.ticks.template.disabled = false;
  //       axis.renderer.ticks.template.strokeOpacity = 1;
  //       axis.renderer.ticks.template.strokeWidth = 0.5;
  //       axis.renderer.ticks.template.length = 5;
  //       axis.renderer.grid.template.disabled = true;
  //       axis.renderer.labels.template.radius = am4core.percent(15);
  //       axis.renderer.labels.template.fontSize = "0.9em";

  //       /**
  //        * Axis for ranges
  //        */

  //       var axis2: any = chart6.xAxes.push(new am4charts.ValueAxis());
  //       axis2.min = chartMin;
  //       axis2.max = chartMax;
  //       axis2.strictMinMax = true;
  //       axis2.renderer.labels.template.disabled = true;
  //       axis2.renderer.ticks.template.disabled = true;
  //       axis2.renderer.grid.template.disabled = false;
  //       axis2.renderer.grid.template.opacity = 0.5;
  //       axis2.renderer.labels.template.bent = true;
  //       axis2.renderer.labels.template.fill = am4core.color("#000");
  //       axis2.renderer.labels.template.fontWeight = "bold";
  //       axis2.renderer.labels.template.fillOpacity = 0.3;



  //       /**
  //       Ranges
  //       */

  //       for (let grading of data1.gradingData) {
  //         var range1 = axis2.axisRanges.create();
  //         range1.axisFill.fill = am4core.color(grading.color);
  //         range1.axisFill.fillOpacity = 0.8;
  //         range1.axisFill.zIndex = -1;
  //         range1.value = grading.lowScore > chartMin ? grading.lowScore : chartMin;
  //         range1.endValue = grading.highScore < chartMax ? grading.highScore : chartMax;
  //         range1.grid.strokeOpacity = 0;
  //         range1.stroke = am4core.color(grading.color).lighten(-0.1);
  //         range1.label.inside = true;
  //         range1.label.text = grading.title.toUpperCase();
  //         range1.label.inside = true;
  //         range1.label.location = 0.5;
  //         range1.label.inside = true;
  //         range1.label.radius = am4core.percent(10);
  //         range1.label.paddingBottom = -5; // ~half font size
  //         range1.label.fontSize = "0.9em";
  //       }

  //       var matchingGrade = lookUpGrade(data1.score, data1.gradingData);

  //       /**
  //        * Label 1
  //        */

  //       var label = chart6.radarContainer.createChild(am4core.Label);
  //       label.isMeasured = false;
  //       label.fontSize = "6em";
  //       label.x = am4core.percent(50);
  //       label.paddingBottom = 15;
  //       label.horizontalCenter = "middle";
  //       label.verticalCenter = "bottom";
  //       //label.dataItem = data;
  //       label.text = data1.score.toFixed(1);
  //       //label.text = "{score}";
  //       label.fill = am4core.color(matchingGrade.color);

  //       /**
  //        * Label 2
  //        */

  //       var label2 = chart6.radarContainer.createChild(am4core.Label);
  //       label2.isMeasured = false;
  //       label2.fontSize = "2em";
  //       label2.horizontalCenter = "middle";
  //       label2.verticalCenter = "bottom";
  //       label2.text = matchingGrade.title.toUpperCase();
  //       label2.fill = am4core.color(matchingGrade.color);


  //       /**
  //        * Hand
  //        */

  //       var hand = chart6.hands.push(new am4charts.ClockHand());
  //       hand.axis = axis2;
  //       hand.innerRadius = am4core.percent(55);
  //       hand.startWidth = 8;
  //       hand.pin.disabled = true;
  //       hand.value = data1.score;
  //       hand.fill = am4core.color("#444");
  //       hand.stroke = am4core.color("#000");

  //       hand.events.on("positionchanged", function () {
  //         label.text = axis2.positionToValue(hand.currentPosition).toFixed(1);
  //         var value2 = axis.positionToValue(hand.currentPosition);
  //         var matchingGrade = lookUpGrade(axis.positionToValue(hand.currentPosition), data1.gradingData);
  //         label2.text = matchingGrade.title.toUpperCase();
  //         label2.fill = am4core.color(matchingGrade.color);
  //         label2.stroke = am4core.color(matchingGrade.color);
  //         label.fill = am4core.color(matchingGrade.color);
  //       })

  //       setInterval(function () {
  //         var value = chartMin + Math.random() * (chartMax - chartMin);
  //         hand.showValue(value, 1000, am4core.ease.cubicOut);
  //       }, 2000);













  //       //Area With Time Based Data
  //       var chart7: any = am4core.create("chartdiv7", am4charts.XYChart);
  //       chart7.paddingRight = 20;

  //       chart7.data = generateChartData();

  //       var dateAxis2 = chart7.xAxes.push(new am4charts.DateAxis());
  //       dateAxis2.baseInterval = {
  //         "timeUnit": "minute",
  //         "count": 1
  //       };
  //       dateAxis2.tooltipDateFormat = "HH:mm, d MMMM";

  //       var valueAxis3 = chart7.yAxes.push(new am4charts.ValueAxis());
  //       valueAxis3.tooltip.disabled = true;
  //       valueAxis3.title.text = "Unique visitors";

  //       var series1 = chart7.series.push(new am4charts.LineSeries());
  //       series1.dataFields.dateX = "date";
  //       series1.dataFields.valueY = "visits";
  //       series1.tooltipText = "Visits: [bold]{valueY}[/]";
  //       series1.fillOpacity = 0.3;


  //       chart7.cursor = new am4charts.XYCursor();
  //       chart7.cursor.lineY.opacity = 0;
  //       chart7.scrollbarX = new am4charts.XYChartScrollbar();
  //       chart7.scrollbarX.series1.push(series);


  //       dateAxis2.start = 0.8;
  //       dateAxis2.keepSelection = true;



  //       function generateChartData() {
  //         var chartData = [];
  //         // current date
  //         var firstDate = new Date();
  //         // now set 500 minutes back
  //         firstDate.setMinutes(firstDate.getDate() - 500);

  //         // and generate 500 data items
  //         var visits = 500;
  //         for (var i = 0; i < 500; i++) {
  //           var newDate = new Date(firstDate);
  //           // each time we add one minute
  //           newDate.setMinutes(newDate.getMinutes() + i);
  //           // some random number
  //           visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
  //           // add data item to the array
  //           chartData.push({
  //             date: newDate,
  //             visits: visits
  //           });
  //         }
  //         return chartData;
  //       }







  //       //3D Pie Chart
  //       let chart8 = am4core.create("chartdiv8", am4charts.PieChart);
  //       chart8.hiddenState.properties.opacity = 0; // this creates initial fade-in
  //       chart8.legend = new am4charts.Legend();

  //       chart8.data = [
  //         {
  //           country: "Lithuania",
  //           litres: 501.9
  //         },
  //         {
  //           country: "Czech Republic",
  //           litres: 301.9
  //         },
  //         {
  //           country: "Ireland",
  //           litres: 201.1
  //         },
  //         {
  //           country: "Germany",
  //           litres: 165.8
  //         },
  //         {
  //           country: "Australia",
  //           litres: 139.9
  //         },
  //         {
  //           country: "Austria",
  //           litres: 128.3
  //         },
  //         {
  //           country: "UK",
  //           litres: 99
  //         },
  //         {
  //           country: "Belgium",
  //           litres: 60
  //         },
  //         {
  //           country: "The Netherlands",
  //           litres: 50
  //         }
  //       ];

  //       let series4 = chart8.series.push(new am4charts.PieSeries());
  //       series4.dataFields.value = "litres";
  //       series4.dataFields.category = "country";

  //     });

  // }

}