import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DetailPositionService } from '../detailed-employee-position/detailed-employee-position.service';

import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { EmployeePositionService } from '../employee-position/employee-position.service';

@Component({
  selector: 'app-emp-responsibility',
  templateUrl: './emp-responsibility.component.html',
  styleUrls: ['./emp-responsibility.component.css']
})
export class EmpResponsibilityComponent implements OnInit {
  updatePartyID:any;
  activeCategory = 2;
  partyIDArray: any[]=[];
  updateFromDate:any;
  throughFromDate:any;
  updateComment:any;
  partyIdForCreate: any;
  showUpdateBtn=false;
  showCreateBtn=false;
  updatePosition:any;
  partyId: any;
  positionID: any;
  contactPartyId: any;
  updateToDate: string;
  updateDateDate: string;
  updateRespId:any;
  updateDateDateForm: string;
  throughFromDateForm: string;
  employeePositionResponsibility: any;
  pDes: any;
  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly router:Router,
    readonly spinner:NgxSpinnerService,
    readonly toastr:ToastrService,
  
    readonly detailPositionService:DetailPositionService,
    readonly formBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly employeePositionService: EmployeePositionService,
  ) { }
 responsibility_employee_form: FormGroup;
 ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    let contactPartyIdLocal;
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate= params["positionId"];
      if(this.partyIdForCreate) 
      {
        contactPartyIdLocal=this.partyIdForCreate;
      }
     
      if(this.partyIdForCreate)
      {
        this.showCreateBtn=true;   
      }
      })
      this.activatedRoute.queryParams.subscribe(params => {
        this.updatePartyID = params["pid"]; 
        this.positionID = params["positionID"];
        if(this.positionID) 
        {
          contactPartyIdLocal=this.positionID;
        }
        let fromdate=params["fromdate"];
        this.updateDateDateForm =fromdate
        this.updateDateDate = this.datePipe.transform(fromdate,"yyyy-MM-dd");
        
        let todate=params["todate"];
       
        this.throughFromDateForm = this.datePipe.transform(todate,"yyyy-MM-dd hh:mm:ss");
        this.throughFromDate = this.datePipe.transform(todate,"yyyy-MM-dd");
        
        this.updateComment=params["comment"];
        this.pDes=params["pDes"];
        
        if(this.updatePartyID && this.positionID){
          this.showUpdateBtn=true;
        }
      });
      this.contactPartyId=contactPartyIdLocal;
  
   
    this.responsibility_employee_form = this.formBuilder.group({
      comments: [''],
      thruDate: [''],
      fromDate: [''],
      responsibilityTypeId: [''],
     
    });
    this.getEmpPositionResponsibility();
  }
  getEmpPositionResponsibility() {
    this.spinner.show();
    this.detailPositionService.getEmpPositionResponsibility().subscribe((res:any)=> {
      this.employeePositionResponsibility = res.data;
      this.spinner.hide();
      for(const value of this.employeePositionResponsibility){
        this.partyIDArray.push({
          label:value.description,
          value:value.responsibilityTypeId
        })
      }
     
    })
   
  }
  navigateFromCreate(){
    this.router.navigate(["/hr/employee-position/employee-position-details"],{queryParams:{positionId:  this.partyIdForCreate}});
  } 
  navigate(){
    this.router.navigate(["/hr/employee-position/employee-position-details"],{queryParams:{positionId:  this.positionID}});
  } 
  onSubmit() {
   
    this.spinner.show();
    let formData = {... this.responsibility_employee_form.value}
  
    this.detailPositionService.postEmpPositionResponsibility( this.partyIdForCreate,formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(["/hr/employee-position/employee-position-details"],{queryParams:{positionId:  this.partyIdForCreate}});
      }
      else if(res.success == false) {
        this.toastr.error(res.message);
      }
    },(err) => {
      for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }
   })
   this.spinner.hide();
  }
  onUpdate() {
    this.spinner.show();
     let formData = {
       comments : this.responsibility_employee_form.value.comments,
      
       fromDate :  this.updateDateDateForm,
       responsibilityTypeId :   this.updatePartyID,
       thruDate : this.responsibility_employee_form.value.thruDate
     }
    
     this.detailPositionService.updateEmpPositionResponsibility(this.positionID,this.updatePartyID,this.updateDateDateForm,formData).subscribe((res:any)=> {
       if(res.success) {
         this.toastr.success(res.data.successMessage);
         this.spinner.hide();
         this.router.navigate(["/hr/employee-position/employee-position-details"],{queryParams:{positionId: this.positionID}});
       }
       else if(res.success == false) {
         this.toastr.error(res.message);
       }
     },(err) => {
       for(const value of err.error.errors){
         this.toastr.error(value.fieldName + " " + value.fieldError);
         }
    })
    this.spinner.hide();
   }
   ngOnDestroy(): void {
    this.spinner.hide();
      }
}
