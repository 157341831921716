import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DetailPositionService } from '../detailed-employee-position/detailed-employee-position.service';
import { FullfilmentEmployeeFormService } from './fullfilment-service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-emp-fulfillment-position',
  templateUrl: './emp-fulfillment-position.component.html',
  styleUrls: ['./emp-fulfillment-position.component.css']
})
export class EmpFulfillmentPositionComponent implements OnInit {
  updateId: any;
  activeCategory = 2;
  show= false;
  employeePosition: any;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search=""
  readonly employessSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,

  }
  updatePartyID:any;
  partyIDArray: any[]=[];
  updateFromDate:any;
  throughFromDate:any;
  updateComment:any;
  partyIdForCreate: any;
  showUpdateBtn=false;
  showCreateBtn=false;
  updatePosition:any;
  partyId: any;
  positionID: any;
  contactPartyId: any;
  updateToDate: string;
  updateDateDate: string;
  updateDateDateForm: string;
  throughFromDateForm: string;
  pDes: any;
  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly router:Router,
    readonly spinner:NgxSpinnerService,
    readonly toastr:ToastrService,
    public addFullfilmentservice:FullfilmentEmployeeFormService,
    readonly detailPositionService:DetailPositionService,
    readonly formBuilder: FormBuilder,
    readonly datePipe: DatePipe
  ) { }
  fullfilment_employee_form: FormGroup;
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    
    let contactPartyIdLocal;
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate= params["positionId"];
      if(this.partyIdForCreate) 
      {
        contactPartyIdLocal=this.partyIdForCreate;
      }
     
      if(this.partyIdForCreate)
      {
        this.showCreateBtn=true;   
      }
      })
      this.activatedRoute.queryParams.subscribe(params => {
        this.updatePartyID = params["pid"]; 
        this.positionID = params["positionID"];
        if(this.positionID) 
        {
          contactPartyIdLocal=this.positionID;
        }
        let fromdate=params["fromdate"];
        this.updateDateDateForm = fromdate
        this.updateDateDate = this.datePipe.transform(fromdate,"yyyy-MM-dd");
        
        let todate=params["todate"];
       
        this.throughFromDateForm = this.datePipe.transform(todate,"yyyy-MM-dd hh:mm:ss");
        this.throughFromDate = this.datePipe.transform(todate,"yyyy-MM-dd");
        
        this.updateComment=params["comment"];
        this.pDes=params["pDes"];
        
        if(this.updatePartyID && this.positionID){
          this.showUpdateBtn=true;
        }
      });
      this.contactPartyId=contactPartyIdLocal;
  
   
    this.fullfilment_employee_form = this.formBuilder.group({
      comments: [''],
      thruDate: [''],
      fromDate: [''],
      partyId: [''],
      emplPositionId:['']
    });
  
    this.getEmployeePosition();
  
  }
  getEmployeePosition() {
    this.spinner.show();
    this.detailPositionService.getPartId().subscribe((res:any)=> {
      this.employeePosition = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.employeePosition){
        this.partyIDArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    
    })
   
  }
  onSubmit() {
    this.spinner.show();
    const partyid=  this.partyIdForCreate;
    this.fullfilment_employee_form.get('emplPositionId').setValue(partyid);
    const formData = {... this.fullfilment_employee_form.value}
   
    this.detailPositionService.postEmpPositionFullfillment( this.partyIdForCreate,formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(["/hr/employee-position/employee-position-details"],{queryParams:{positionId:  this.partyIdForCreate}});
      }
      else if(res.success == false) {
        this.toastr.error(res.message);
      }
    },(err) => {
      for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }
   })
   this.spinner.hide();
  }
  onUpdate() {
    this.spinner.show();
    const formData = {
      comments : this.fullfilment_employee_form.value.comments,
      emplPositionId : this.fullfilment_employee_form.value.emplPositionId,
      fromDate :  this.updateDateDateForm,
      partyId :   this.updatePartyID,
      thruDate : this.fullfilment_employee_form.value.thruDate
    }
    
    this.detailPositionService.updateEmpPositionFullfillment(this.positionID,this.updatePartyID,this.updateDateDateForm,formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(["/hr/employee-position/employee-position-details"],{queryParams:{positionId: this.positionID}});
      }
      else if(res.success == false) {
        this.toastr.error(res.message);
      }
    },(err) => {
      for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }
   })
   this.spinner.hide();
  }
  navigateFromCreate(){
    this.router.navigate(["/hr/employee-position/employee-position-details"],{queryParams:{positionId:  this.partyIdForCreate}});
  } 
  navigate(){
    this.router.navigate(["/hr/employee-position/employee-position-details"],{queryParams:{positionId:  this.positionID}});
  } 
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
