import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { MyLeadService } from './my-lead.service'
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LeadService } from 'src/app/crm/leads/lead-service';
import { LeadServices } from 'src/app/crm/leads/leads.service';
declare var $: any;
import { Location } from '@angular/common';

@Component({
  selector: 'app-my-lead',
  templateUrl: './my-lead.component.html',
  styleUrls: ['./my-lead.component.css']
})
export class MyLeadComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  constructor(
    readonly myLeadService: MyLeadService,
    readonly spinner: NgxSpinnerService,
    public leadService: LeadService,
    readonly activatedRoute: ActivatedRoute,
    readonly leadServices: LeadServices,
    readonly toastr: ToastrService, 
    readonly router: Router,
    private _location: Location,
  ) { }
  opportunityIdArray: any = [];
  myLeadList: any;
  total=0;
  rows = 50;
  pageSize= 100;
  pageNo: number = 1

  contactMechId = '';
  firstNameSearchType = 'Contains';
  firstName = '';
  lastName = '';
  lastNameSearchType = 'Contains';
  partyId = '';
  partyIdSearchType = 'Contains';
  array: any = [];
  countryGeoId: any;
  stateData: any;
  stateArray: any = [];
  leadSource: any;
  leadArray: any = [];
  contactList: any;
  contactListArray: any = [];
  leadId: any;
  show = false;
  gender: any = [];
  crmStatus: any;
  crmArray: any = [];
  datailedData: any;
  activeCategory = 2;
  rowNumber=0;
  leadsLists: any;
  
  public contacts = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    isMyLead: 'true',
  };

  
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.leadId = params["leadId"];
    });
    if (this.leadId) {
      this.show = true;
    }
    else {
      this.show = false;
    }
   
    this.getLeadSource()
    this.getCountryList();
    this.getContactList();
    this.getStatusCrm();
    this.getLeadById();
    this.leadService.lead_form = null;
    this.leadService.leadInfoForm(this.leadService.lead_form);
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    this.gender.push({
      "label": "Male",
      "value": "M"
    },
      {
        "label": "Female",
        "value": "F"
      })
    this.getLeadList();
    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }
  resetFilter(){
    this.contactMechId = '';
    this.firstNameSearchType= 'Contains';
    this.firstName= '';
    this.lastName= '';
    this.lastNameSearchType= 'Contains';
    this.partyId= '';
    this.partyIdSearchType = 'Contains';
    this.getLeadList();
  }
  onLastName(event): void {
    this.lastNameSearchType = event;
  }
  onFirstName(event): void {
    this.firstNameSearchType = event;
  }
  onPartyId(event): void {
    this.partyIdSearchType = event;
  }
  customSort(event: SortEvent) {
   
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
   
  }

  getLeadList(): void {
    this.spinner.show();
    const leadAdvancedSearch = {
      contactMechId: this.contactMechId,
      firstName: this.firstName,
      firstNameSearchType: this.firstNameSearchType,
      lastName: this.lastName,
      lastNameSearchType: this.lastNameSearchType,
      partyId: this.partyId,
      partyIdSearchType: this.partyIdSearchType
    };
    this.myLeadService.getLeadsList(this.contacts, leadAdvancedSearch).subscribe(res => {
      this.myLeadList = res.data;
      this.spinner.hide();
    });
   
  }

  getCountryList() {
    this.spinner.show();
    this.leadServices.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })

      })

    })
  
  }
  getLeadById() {
    this.spinner.show();
    this.leadServices.getLeadDetail(this.leadId).subscribe(res => {
      this.datailedData = res.data;
      this.spinner.hide();
      const formLead = this.leadService.lead_form;
      formLead.patchValue({
        address1: this.datailedData.contactInformation.address !== null ? this.datailedData.contactInformation.address.address1 : '',
        address2: this.datailedData.contactInformation.address !== null ? res.data.contactInformation.address.address2 : '',
        emailId: res.data.contactInformation.email.infoString,
        firstName: res.data.personalInformation.firstName,
        lastName: res.data.personalInformation.lastName,
        suffix: res.data.personalInformation.suffix,
        comments: res.data.personalInformation.comments,
       
        areaCode: res.data.personalInformation.areaCode,
        
        phoneNumber: res.data.personalInformation.contactNumber

      })
     
    })
   
  }
  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    if (event) {
      this.getStateList();
    }
  }
  getStatusCrm() {
    this.spinner.show();
    this.leadServices.getStatusCrm().subscribe(res => {
      this.crmStatus = res.data;
      this.spinner.hide();
      for (const value of this.crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  
  }
  getLeadSource() {
    this.spinner.show();
    this.leadServices.getLeadSource().subscribe(res => {
      this.leadSource = res.data.leadSourceList;
      this.spinner.hide();
      for (const value of this.leadSource) {
        this.leadArray.push({
          label: value.description,
          value: value.dataSourceId
        })
      }
    })
   
  }
  getContactList() {
    this.spinner.show();
    this.leadServices.getContactList().subscribe(res => {
      this.contactList = res.data;
      this.spinner.hide();
      for (const value of this.contactList) {
        this.contactListArray.push({
          label: value.description,
          value: value.descriptioncontactListId
        })
      }
    })
    
  }
  navigateBack() {
    this.router.navigate(["/crm/leads"])
  }
  updateLead() {
    this.spinner.show();
    this.leadService.lead_form.markAllAsTouched();
    const formValues = { ...this.leadService.lead_form.value };
    this.leadServices.updateLead(formValues).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.spinner.hide();
      
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.debugMessage);
    })
    
  }
  getStateList() {
    this.spinner.show();
    this.leadServices.getState(this.countryGeoId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
  
  }

  onSubmit() {
    this.spinner.show();
    this.leadService.lead_form.markAllAsTouched();
    const formValues = { ...this.leadService.lead_form.value };
    this.leadServices.createLead(formValues).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.closebutton.nativeElement.click();
        this.spinner.hide();
        this.router.navigate(['/crm/leads'])
      }

    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.debugMessage);
    })
  
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  generalInformation(first,last,customer,noOfEmp){
    if(first && last&&customer && noOfEmp){
      this.activeCategory=3
    }
    else{
      this.toastr.error("First Name,Last Name,No of Employee and Customer Name is required");
    }
  }
  addressInformation(address,city,country,state,postal){
    if(address && country  && city  && state && postal){
      this.activeCategory=4
    }
    else{
      this.toastr.error("Address1,Country,City,State and Postal Code is required");
    }
  }
  phoneInformation(phone){
    if(phone){
      this.activeCategory=5
    }
    else{
      this.toastr.error("Phone Number is required");
    }
  
  }
  emailInformation(email){
    if(email){
      this.activeCategory=6
    }
    else{
      this.toastr.error("Email is required");
    }
  }
  leadInformation(lead){
    if(lead.constructor === Array){
      this.toastr.error("Lead Source is required");
     
    }
    else{
      this.activeCategory=8
    }
  
  }
  addressInfo(first,last,customer,noOfEmp){
    if(first && last&&customer && noOfEmp){
      this.activeCategory=3
    }
    else{
      this.toastr.error("Fill General Information.");
    }
  }
  phoneInfo(address,city,country,state,postal){
    if(address && country  && city  && state && postal){
      this.activeCategory=4
    }
    else{
      this.toastr.error("Fill Address Information.");
    }
  }
  emailInfo(phone){
    if(phone){
      this.activeCategory=5
    }
    else{
      this.toastr.error("Fill Phone Information.");
    }
  
  }
  leadInfo(email){
    if(email){
      this.activeCategory=6
    }
    else{
      this.toastr.error("Fill Email Information.");
    }
  }
  contactInfo(lead){
    if(lead.constructor === Array){
      this.toastr.error("Fill Lead Information.");
     
    }
    else{
      this.activeCategory=8
    }
  
  }
}