<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12 pad_0 ">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Suppliers</span>
               <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                    <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class="mt-2" style="padding-bottom: 70px;">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCard w-100 border_wdth_1 ">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text color-grey">Find Suppliers</p>
                                    <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#createSupplierPopup">Create Supplier</button>
                                    <!-- <button type="button" class="btn btn-secondary submit-btn ml-2" 
                                        data-toggle="modal" data-target="#mergeSupplierPopup">
                                        Merge Suppliers</button> -->
                                    <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#outsourceSupplierPopup">Outsource a Task</button>
                                </div>
                                <div class="bg-white color-grey">
                                    <div class="create-new-leade">
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                                                        <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                                            <a>Name and ID</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                                            <a>Phone</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                                            <a>E-mail</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                                            <a>Advanced</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                    <div *ngIf="activeCategory==1" class="w-100">

                                                        <div class="w3-card-4">
                                                            <div class="accordion classCard" id="myAccordion">
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id="headingOne">
                                                                        <div
                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                                        <span style="margin-left: 12px!important;"> Name and ID</span>
                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 82%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;"></button>									
        
                                                                    </div>
        
                                                                    </h2>
                                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                        <div class="card-body">
                                                                            
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Supplier ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" [(ngModel)]="findByAdvanced.supplierID" 
                                                                                            [ngModelOptions]="{standalone:true}" id="exampleInputEmail1"
                                                                                             name="supplierID" aria-describedby="emailHelp" placeholder="Enter Supplier ID">
                                                                                        </div>
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Supplier Name</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" [(ngModel)]="findByAdvanced.supplierName" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="supplierName" aria-describedby="emailHelp" placeholder="Enter Supplier Name">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                       
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-8 main-submit-button">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="findSupplier()">Find</button>
                                                                                    <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>

                                                        <!-- <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs" >
                                                                <h4 class=" common-styling hleft">
                                                                    Name and ID
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">Supplier ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control" [(ngModel)]="findByAdvanced.supplierID" 
                                                                                [ngModelOptions]="{standalone:true}" id="exampleInputEmail1"
                                                                                 name="supplierID" aria-describedby="emailHelp" placeholder="Enter Supplier ID">
                                                                            </div>
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">Supplier Name</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control" [(ngModel)]="findByAdvanced.supplierName" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="supplierName" aria-describedby="emailHelp" placeholder="Enter Supplier Name">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                           
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 main-submit-button">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="findSupplier()">Find</button>
                                                                        <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div> -->
                                                    </div>
                                                    <div *ngIf="activeCategory==2" class="w-100">

                                                        <div class="w3-card-4">
                                                            <div class="accordion classCard" id="myAccordion">
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id="headingOne">
                                                                        <div
                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                                        <span style="margin-left: 12px!important;"> Phone</span>
                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 88%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;"></button>									
        
                                                                    </div>
        
                                                                    </h2>
                                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                        <div class="card-body">
                                                                            
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Phone Number</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" [(ngModel)]="findByAdvanced.phone1"
                                                                                             [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" 
                                                                                             name="phone1" aria-describedby="emailHelp" placeholder="Enter Phone Number">
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 main-submit-button">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="findSupplier()">Find</button>
                                                                                    <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>

                                                        <!-- <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs" >
                                                                <h4 class=" common-styling hleft">
                                                                    Phone
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">Phone Number</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control" [(ngModel)]="findByAdvanced.phone1"
                                                                                 [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" 
                                                                                 name="phone1" aria-describedby="emailHelp" placeholder="Enter Phone Number">
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 main-submit-button">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="findSupplier()">Find</button>
                                                                        <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div> -->
                                                    </div>
                                                    <div *ngIf="activeCategory==3" class="w-100">

                                                        <div class="w3-card-4">
                                                            <div class="accordion classCard" id="myAccordion">
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id="headingOne">
                                                                        <div
                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                                        <span style="margin-left: 12px!important;"> E-mail
                                                                        </span>
                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 86%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;"></button>									
        
                                                                    </div>
        
                                                                    </h2>
                                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                        <div class="card-body">
                                                                            
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">E-mail</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" [(ngModel)]="findByAdvanced.email" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="email" aria-describedby="emailHelp" placeholder="Enter Partner ID">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 main-submit-button">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="findSupplier()">Find</button>
                                                                                    <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>

                                                        <!-- <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs" >
                                                                <h4 class=" common-styling hleft">
                                                                    E-mail
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">E-mail</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control" [(ngModel)]="findByAdvanced.email" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="email" aria-describedby="emailHelp" placeholder="Enter Partner ID">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 main-submit-button">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="findSupplier()">Find</button>
                                                                        <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div> -->
                                                    </div>
                                                    <div *ngIf="activeCategory==4" class="w-100">

                                                        <div class="w3-card-4">
                                                            <div class="accordion classCard" id="myAccordion">
                                                                <div class="accordion-item">
                                                                    <h2 class="accordion-header" id="headingOne">
                                                                        <div
                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                                        <span style="margin-left: 12px!important;"> Advanced</span>
                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 86%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;"></button>									
        
                                                                    </div>
        
                                                                    </h2>
                                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                        <div class="card-body">
                                                                            
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Address</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" [(ngModel)]="findByAdvanced.address"
                                                                                             [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="address" 
                                                                                             aria-describedby="emailHelp" placeholder="Enter Address">
                                                                                           
                                                                                        </div>
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">City</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" [(ngModel)]="findByAdvanced.city" 
                                                                                            [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="city"
                                                                                             aria-describedby="emailHelp" placeholder="Enter City">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
            
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Country</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true" optionlabel="label" 
                                                                                            [(ngModel)]="findByAdvanced.country" (ngModelChange)="onCountryChange($event)"
                                                                                            [options]="array" [ngModelOptions]="{standalone:true}" name="country"
                                                                                             placeholder="Enter Country">
                                                                                             </p-dropdown>  
                                                                       
                                                                                        </div>
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">State/Province</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true" optionlabel="label" [(ngModel)]="findByAdvanced.state" 
                                                                                            (ngModelChange)="onStateChange($event)" [options]="stateArray" [ngModelOptions]="{standalone:true}" name="state"
                                                                                            placeholder="Enter State/Province">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                             
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Postal Code</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" [(ngModel)]="findByAdvanced.postalCode" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="postalCode" aria-describedby="emailHelp" placeholder="Enter Postal Code">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-8 main-submit-button">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="findSupplier()">Find</button>
                                                                                    <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </div>

                                                        <!-- <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs" >
                                                                <h4 class=" common-styling hleft">
                                                                    Advanced
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">Address</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control" [(ngModel)]="findByAdvanced.address"
                                                                                 [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="address" 
                                                                                 aria-describedby="emailHelp" placeholder="Enter Address">
                                                                               
                                                                            </div>
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">City</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control" [(ngModel)]="findByAdvanced.city" 
                                                                                [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="city"
                                                                                 aria-describedby="emailHelp" placeholder="Enter City">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">Country</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                [(ngModel)]="findByAdvanced.country" (ngModelChange)="onCountryChange($event)"
                                                                                [options]="array" [ngModelOptions]="{standalone:true}" name="country"
                                                                                 placeholder="Enter Country">
                                                                                 </p-dropdown>  
                                                           
                                                                            </div>
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">State/Province</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" [(ngModel)]="findByAdvanced.state" 
                                                                                (ngModelChange)="onStateChange($event)" [options]="stateArray" [ngModelOptions]="{standalone:true}" name="state"
                                                                                placeholder="Enter State/Province">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">Postal Code</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" class="form-control" [(ngModel)]="findByAdvanced.postalCode" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="postalCode" aria-describedby="emailHelp" placeholder="Enter Postal Code">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 main-submit-button">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="findSupplier()">Find</button>
                                                                        <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="header-tabs" >
                                                    <h4 class=" common-styling h4Margin">
                                                        Supplier List
                                                    </h4>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="findSupplierList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Supplier ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Supplier Name
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    City
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    State/Province
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Phone Number
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    E-Mail Address
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>

                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                       
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button"  
                                                                                    *ngIf="product.supplierIdName" (click)="detailPage(product.supplierIdName[0].partyId)"
                                                                                    >
                                                                                        {{product.supplierIdName[0].partyId}}
                                                                                </span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}" >  
                                                                               <div *ngIf="product.supplierIdName"> {{product.supplierIdName[0].groupName}} </div> 
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}"> 
                                                                                <div *ngIf="product.city"> {{product.city[0].generalCity}} </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}" > <div *ngIf="product.city">{{product.city[0].generalStateProvinceGeoId}} </div> 
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}"> <div *ngIf="product.phoneNumber"> {{product.phoneNumber[0].primaryPhoneNumber}}</div> 
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}"><div *ngIf="product.email"> {{product.email[0].primaryEmail}} </div> 
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                            <h4
                                                                                class="ml-2">
                                                                                No
                                                                                Record
                                                                                Found
                                                                            </h4>
                                                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="createSupplierPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create Supplier</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                <div class=" bg-white color-grey" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategoryA==2?'active':''"  ><a>
                                                    General</a></li>
                                            <li [ngClass]="activeCategoryA==3?'active':''"  
                                               ><a>
                                                    Contact</a></li>
                                            <li [ngClass]="activeCategoryA==4?'active':''"   ><a>
                                                    Address</a></li>
                                            <li [ngClass]="activeCategoryA==5?'active':''" 
                                               ><a>
                                                    Financial</a></li>



                                        </ul>
                                    </div>

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="createVendor">
                                                    <div *ngIf="activeCategoryA==2">
                                                        <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Supplier
                                                                        ID<span style="color:red">*</span></label>
                                                                </div>
                             
                                                                <div class="col-lg-3">
                                                                    <p-dropdown formControlName="partyId"
                                                                        placeholder="Choose Supplier ID" filter="true"
                                                                        [options]="partyIdArray"
                                                                        optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Name

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="name"
                                                                        formControlName="name" class="form-control"
                                                                        placeholder="Enter Name">
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Type of Product
                                                                        or Service Offered

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="type"
                                                                        id="exampleInputEmail1"
                                                                        placeholder="Enter Type of Product or Service Offered"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Supplier Name

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="vendorName"
                                                                        placeholder="Enter Supplier Name"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Supplier
                                                                        Email

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="vendorEmail"
                                                                        placeholder="Enter Supplier Email"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Company Website

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="companyWebsite"
                                                                        id="exampleInputEmail1" placeholder="Enter Company Website "
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Supplier is
                                                                        Active

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="isActive"
                                                                        id="exampleInputEmail1" placeholder="Enter Supplier is Active"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Individual

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="text" formControlName="individual"
                                                                        id="exampleInputEmail1" placeholder="Enter Individual"
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Supplier
                                                                        Forms

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="vendorForms"
                                                                        placeholder="Enter Supplier Forms" id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Map

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="map"
                                                                        id="exampleInputEmail1"
                                                                        placeholder="Enter Map"
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Manifest Company
                                                                        Title

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="manifestCompanyTitle"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Manifest Company Title">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Manifest
                                                                        Policies

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="manifestPolicies"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Manifest Policies">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Manifest Company
                                                                        Name

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="manifestCompanyName"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Manifest Company Name">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Manifest Logo
                                                                        Url

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="manifestLogoUrl"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Manifest Logo Url">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Billing Rate Level
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="billingRateLevel"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter billing Rate Level">
                                                                </div>


                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Phone
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                    formControlName="phone"
                                                                    placeholder="Enter Phone"
                                                                    id="exampleInputEmail1">
                                                                </div>



                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Web Url Id
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="webUrlId"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter web Url Id">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Type Supplier
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="typeVendor"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter type Supplier">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Primary Web Url
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="PrimaryWebUrl"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter primary Web Url">
                                                                </div>



                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="main-submit-button" style="margin-right:11%;">
                                                        <button type="submit"
                                                            (click)="changeactiveCategoryFunctionA(3)"
                                                            class="btn btn-secondary submit-btn">Continue</button>

                                                    </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryA==3">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Supplier
                                                                            Phone

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="vendorPhone"
                                                                            placeholder="Enter Supplier Phone"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Supplier Fax

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="vendorFax"
                                                                            placeholder="Enter Supplier Fax"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Email
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryEmail"
                                                                            placeholder="Enter primary Email"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Primary Fax Area Code

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryFaxAreaCode"
                                                                            placeholder="Enter Primary Fax Area Code"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Fax Country Code
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryFaxCountryCode"
                                                                            placeholder="Enter Primary Fax Country Code"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Primary Fax Extension

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryFaxExtension"
                                                                            placeholder="Enter primary Fax Extension"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Fax Number
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryFaxNumber"
                                                                            placeholder="Enter Primary Fax Number"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Primary Phone Area Code

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryPhoneAreaCode"
                                                                            placeholder="Enter Primary Phone Area Code"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Phone Country Code
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryPhoneCountryCode"
                                                                            placeholder="Enter Primary Phone Country Code"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Primary Phone Extension

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryPhoneExtension"
                                                                            placeholder="Enter Primary Phone Extension"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Phone Number
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryPhoneNumber"
                                                                            placeholder="Enter Primary Phone Number"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General Attn Name
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalAttnName"
                                                                            placeholder="Enter General Attn Name"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 13%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunctionA(2)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunctionA(4)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryA==4">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">General Address1
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalAddress1"
                                                                            placeholder="Enter General Address1"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General Address2

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalAddress2"
                                                                            placeholder="Enter General Address2"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">General State Province Geo Id
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                        formControlName="generalStateProvinceGeoId"
                                                                        placeholder="Enter General State Province Geo Id"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General City

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalCity"
                                                                            placeholder="Enter General City"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">General Country Geo Id
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalCountryGeoId"
                                                                            placeholder="Enter General Country Geo Id"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General Postal Code

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalPostalCode"
                                                                            placeholder="Enter general Postal Code"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                           


                                                        </div>
                 
                                                        <div class="main-submit-button" style="margin-right: 13%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunctionA(3)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunctionA(5)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryA==5">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Credit Limit

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="creditLimit"
                                                                            placeholder="Enter Credit Limit"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Terms
                                                                            


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Terms"
                                                                            formControlName="terms"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Info String

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="infoString"
                                                                            placeholder="Enter Info String"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">is Incorporated
                                                                            


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="isIncorporated"
                                                                            id="exampleInputEmail1" class="form-control"
                                                                            placeholder="Enter is Incorporated">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Group Name

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="groupName"
                                                                            placeholder="Enter group Name"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General To Name
                                                                            


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalToName"
                                                                            id="exampleInputEmail1" class="form-control"
                                                                            placeholder="Enter general To Name">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Company


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">

                                                                        <input type="email" formControlName="company"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Company"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Federal Tax ID

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="federalTaxId"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Company Tax ID"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Supplier
                                                                            Eligible
                                                                           

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Supplier Eligible"
                                                                            formControlName="eligibleFor"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Contact Mech Purpose Type Id
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter contact Mech Purpose Type Id"
                                                                            formControlName="contactMechPurposeTypeId"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Requires 1099
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="requires1099"
                                                                            placeholder="Enter Requires 1099"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Tax Id


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" placeholder="Enter Tax Id"
                                                                            formControlName="taxId"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
                                      


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">


                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Default Payables
                                                                            Account


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Default Payables Account"
                                                                            formControlName="accountNo"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Print Check As


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" placeholder="Enter Print Check As"
                                                                            formControlName="printCheckAs"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunctionA(4)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit" (click)="onSubmit()"
                                                                class="btn btn-secondary submit-btn">Save</button>

                                                        </div>
                                                    </div>



                                                </form>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createSupplierPopup1" tabindex="-1" role="dialog" aria-labelledby="createSupplierPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Create Supplier</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Supplier</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createSupplier">
                                        <div *ngIf="activeCategory==2">
                                            <div
                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Supplier
                                                                ID<span style="color:red">*</span></label>
                                                        </div>
                                                        <!-- <div class="col-lg-3" *ngIf="!show">
                                                            <p-dropdown formControlName="partyId" (onChange)="getVendorPartyIdListById($event.value)" placeholder="Enter Party ID" filter="true" [options]="listVendorsIntialArray" optionlabel="label">

                                                            </p-dropdown>
                                                        </div> -->
                                                        <div class="col-lg-3">
                                                            <p-dropdown formControlName="partyId"
                                                                placeholder="Choose Supplier ID" filter="true"
                                                                [options]="partyIdArray"
                                                                optionlabel="label">

                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Name

                                                            </label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="name"
                                                                formControlName="name" class="form-control"
                                                                placeholder="Enter Name">
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Type of Product
                                                                or Service Offered

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="type"
                                                                id="exampleInputEmail1"
                                                                placeholder="Enter Type of Product or Service Offered"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Supplier Name

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="vendorName"
                                                                placeholder="Enter Supplier Name"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Supplier
                                                                Email

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="vendorEmail"
                                                                placeholder="Enter Supplier Email"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Company Website

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="companyWebsite"
                                                                id="exampleInputEmail1" placeholder="Enter Company Website "
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Supplier is
                                                                Active

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="isActive"
                                                                id="exampleInputEmail1" placeholder="Enter Supplier is Active"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Individual

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="text" formControlName="individual"
                                                                id="exampleInputEmail1" placeholder="Enter Individual"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Supplier
                                                                Forms

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="vendorForms"
                                                                placeholder="Enter Supplier Forms" id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Map

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="map"
                                                                id="exampleInputEmail1"
                                                                placeholder="Enter Map"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Manifest Company
                                                                Title

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="manifestCompanyTitle"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Manifest Company Title">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Manifest
                                                                Policies

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="manifestPolicies"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Manifest Policies">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Manifest Company
                                                                Name

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="manifestCompanyName"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Manifest Company Name">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Manifest Logo
                                                                Url

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="manifestLogoUrl"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Manifest Logo Url">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">billingRateLevel
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="billingRateLevel"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter billingRateLevel">
                                                        </div>


                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Phone
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" class="form-control"
                                                            formControlName="phone"
                                                            placeholder="Enter Phone"
                                                            id="exampleInputEmail1">
                                                        </div>



                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">webUrlId
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="webUrlId"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter web Url Id">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">typeVendor
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="typeVendor"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter typeVendor">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">primary Web Url
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="primaryWebUrl"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter primaryWeb Url">
                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                            <div class="main-submit-button" style="margin-right:11%;">
                                                <button type="submit"
                                                    (click)="changeactiveCategoryFunction(3)"
                                                    class="btn btn-secondary submit-btn">Continue</button>

                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==3">
                                            <div
                                                class="all-users-infomation font-13 font-medium own-user-select">

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Supplier
                                                                Phone

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="vendorPhone"
                                                                placeholder="Enter Supplier Phone"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Supplier Fax

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="vendorFax"
                                                                placeholder="Enter Supplier Fax"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">primaryEmail
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="primaryEmail"
                                                                placeholder="Enter primaryEmail"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">primaryFaxAreaCode

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="primaryFaxAreaCode"
                                                                placeholder="Enter primaryFaxAreaCode"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">primaryFaxCountryCode
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="primaryFaxCountryCode"
                                                                placeholder="Enter primaryFaxCountryCode"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">primaryFaxExtension

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="primaryFaxExtension"
                                                                placeholder="Enter primaryFaxExtension"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">primaryFaxNumber
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="primaryFaxNumber"
                                                                placeholder="Enter primaryFaxNumber"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">primaryPhoneAreaCode

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="primaryPhoneAreaCode"
                                                                placeholder="Enter primaryPhoneAreaCode"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">primaryPhoneCountryCode
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="primaryPhoneCountryCode"
                                                                placeholder="Enter primaryPhoneCountryCode"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">primaryPhoneExtension

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="primaryPhoneExtension"
                                                                placeholder="Enter primaryPhoneExtension"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">primaryPhoneNumber
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="primaryPhoneNumber"
                                                                placeholder="Enter primaryPhoneNumber"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">generalStateProvinceGeoId
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="generalStateProvinceGeoId"
                                                                placeholder="Enter generalStateProvinceGeoId"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                


                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">generalAddress1
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="generalAddress1"
                                                                placeholder="Enter generalAddress1"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">generalAddress2

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="generalAddress2"
                                                                placeholder="Enter generalAddress2"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">generalAttnName
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="generalAttnName"
                                                                placeholder="Enter generalAttnName"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">generalCity

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="generalCity"
                                                                placeholder="Enter generalCity"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">generalCountryGeoId
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="generalCountryGeoId"
                                                                placeholder="Enter generalCountryGeoId"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">generalPostalCode

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="generalPostalCode"
                                                                placeholder="Enter generalPostalCode"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                     

                                                     


                                                    </div>
                                                </div>
                                              

                                            </div>
                                            <div class="main-submit-button" style="margin-right: 13%;">
                                                <button type="submit"
                                                    (click)="changeactiveCategoryFunction(2)"
                                                    class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                <button type="submit"
                                                    (click)="changeactiveCategoryFunction(5)"
                                                    class="btn btn-secondary submit-btn">Continue</button>

                                            </div>
                                        </div>
                                        <!-- <div *ngIf="activeCategory==4">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputD">
                                                            <label for="exampleInputEmail1">Address

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" disabled [(ngModel)]="this.addressVendor" [ngModelOptions]="{standalone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                                                        </div>




                                                    </div>
                                                </div>






                                            </div>
                                            <div class="main-submit-button" style="margin-right:64%;">
                                                <button type="submit" (click)="changeactiveCategoryFunction(5)" class="btn btn-secondary submit-btn">Continue</button>

                                            </div>
                                        </div> -->
                                        <div *ngIf="activeCategory==5">
                                            <div
                                                class="all-users-infomation font-13 font-medium own-user-select">

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputE">
                                                            <label for="exampleInputEmail1">Credit Limit

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="creditLimit"
                                                                placeholder="Enter Credit Limit"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputE">
                                                            <label for="exampleInputEmail1">infoString

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="infoString"
                                                                placeholder="Enter infoString"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">is Incorporated
                                                                


                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="isIncorporated"
                                                                id="exampleInputEmail1" class="form-control"
                                                                placeholder="Enter is Incorporated">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputE">
                                                            <label for="exampleInputEmail1">group Name

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="groupName"
                                                                placeholder="Enter group Name"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">general To Name
                                                                


                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="generalToName"
                                                                id="exampleInputEmail1" class="form-control"
                                                                placeholder="Enter general To Name">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputE">
                                                            <label for="exampleInputEmail1">Company


                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">

                                                            <input type="email" formControlName="company"
                                                                id="exampleInputEmail1"
                                                                placeholder="Enter Company"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">federal Tax ID

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="federalTaxId"
                                                                id="exampleInputEmail1"
                                                                placeholder="Enter Company Tax ID"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputE">
                                                            <label for="exampleInputEmail1">Supplier
                                                                Eligible
                                                               

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                placeholder="Enter Supplier Eligible"
                                                                formControlName="eligibleFor"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">contact Mech Purpose Type Id
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                placeholder="Enter contact Mech Purpose Type Id"
                                                                formControlName="contactMechPurposeTypeId"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">requires 1099
                                                                

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="requires1099"
                                                                placeholder="Enter requires 1099"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">isIncorporated

                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                formControlName="isIncorporated"
                                                                placeholder="Enter isIncorporated"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInputE">
                                                            <label for="exampleInputEmail1">Default Payables
                                                                Account


                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                placeholder="Enter Default Payables Account"
                                                                formControlName="accountNo"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Print Check As


                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" placeholder="Print Check As"
                                                                formControlName="printCheckAs"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInputE">
                                                            <label for="exampleInputEmail1">terms
                                                                


                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email"
                                                                placeholder="Enter terms"
                                                                formControlName="terms"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>

                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">taxId


                                                            </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" placeholder="taxId"
                                                                formControlName="taxId"
                                                                id="exampleInputEmail1"
                                                                class="form-control">
                                                        </div>


                                                    </div>
                                                </div>



                                            </div>
                                            <div class="main-submit-button" style="margin-right: 12%;">
                                                <button type="submit"
                                                    (click)="changeactiveCategoryFunction(3)"
                                                    class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                <button type="submit" (click)="createSuppliers()"
                                                    class="btn btn-secondary submit-btn">Save</button>

                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="outsourceSupplierPopup" tabindex="-1" role="dialog" aria-labelledby="outsourceSupplierPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Outsource a Task</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Outsource a Task</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="outsourceTask">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Supplier</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="supplierAllArray" formControlName="supplier" placeholder="Enter Supplier">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Routing Task ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        [options]="WorkEffortIdArray"  formControlName="routingTaskID" 
                                                        placeholder="Enter Routing Task ID" >
                                                        </p-dropdown>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                       
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="productID" 
                                                       [options]="ProductIdArray" placeholder="Enter Product ID">
                                                        </p-dropdown>
                                                       
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="productName" placeholder="Enter Product Name">
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Available From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" name="startDate" formControlName="startDate" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hourFrom" optionlabel="label" formControlName="hourFrom"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minuteFrom" optionlabel="label" formControlName="minuteFrom">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="amPmFrom" [options]="amPM" name="amPmFrom" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">End Date &amp; Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" name="EndDate" formControlName="EndDate" aria-describedby="emailHelp" placeholder="Enter End Date">
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="hours" name="hourEnd" optionlabel="label" formControlName="hourEnd"> :
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" [options]="minute" name="minuteEnd" optionlabel="label" formControlName="minuteEnd">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 time">
                                                        <p-dropdown filter="true" formControlName="amPmEnd" [options]="amPM" name="amPmEnd" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Minimum Order Quantity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="minimumOrder" placeholder="Enter Minimum Order Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Supplier Product ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="supplierProductID" placeholder="Enter Supplier Product ID">
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Supplier Product Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="supplierProductName" placeholder="Enter Supplier Product Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Cost GI Type Account Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="accTypeID"
                                                        [options]="glAccountTypeIdArray"  placeholder="Enter Cost GI Type Account Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                       
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Price</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="price" placeholder="Enter Price">
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="glAccountTypeIdArray"  [options]="currencyList"  formControlName="currency" placeholder="Enter Currency">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8  main-submit-button" style="margin-left: 16%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createOutsourceRoutingTask()">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="mergeSupplierPopup" tabindex="-1" role="dialog" aria-labelledby="mergeSupplierPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Merge Suppliers</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Merge Suppliers</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="mergeSupplier">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput1">
                                                        <label for="exampleInputEmail1">From Supplier</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="fromSupplier" placeholder="Enter From Supplier">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput1">
                                                        <label for="exampleInputEmail1">To Supplier</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="toSupplier" placeholder="Enter To Supplier">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-4 main-submit-button" style="margin-left: 47%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>