<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-3">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100">
                   
                    <div class="mt-3" style="margin-bottom: 10px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 16px!important;">List View Configuration Settings</span>

                    </div>                    

                    
                    <div class="col-lg-12 ">
                        <div class="row d-flex modals" style="margin-left: 0px !important;">
                            <p>This selection allows you to configure what numbers you see 
                                on the Time Sheet List View.

                            </p>
                        </div>
                    </div>

                   

            
                    

                    <div class="row " style="width: 100%; margin-top: 1%; margin-bottom: 1%; padding-left: 8px;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%; height: 270px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color: black; font-weight: bold !important;">
                                        Select types of times to display in the Time Sheet List View </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12">
                                                <div class="col-lg-12">
                                                    <div class="row d-flex modals">
                                                        <div class="col-lg-2 classInput">
                                                            <label>First Value</label>

                                                        </div>

                                                        <div class=" col-lg-1" >
                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                    </div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                            
                                                            </p-checkbox>
                                                               
                                                        </div>

                                                        <div class="col-lg-2 ">
                                                            <label>Regular Time</label>

                                                        </div>

                                                        <div class="col-lg-5 blueFont">
                                                            <label>Time purchased in for the Payroll items checked here will be 
                                                                added and displayed as the first value in List View
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row d-flex">
                                                        

                                                        <div class=" col-lg-1" >
                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                    </div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                            
                                                            </p-checkbox>
                                                               
                                                        </div>

                                                        <div class="col-lg-2 ">
                                                            <label>Regular Time</label>

                                                        </div>


                                                        

                                                        <div class="col-lg-9">
                                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
        
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
        
                                                                                                Category [ID]
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
        
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                From Date Time
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Through Date Time
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Comments
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Sequence Num
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Quantity
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Action
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span  class="account-button"  >
                                                                                                    
                                                                                                    </span>
        
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
        
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
        
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
        
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
        
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            <span>
                                                                                                    <small style="font-weight: 600;cursor:pointer">Update</small>
                                                                                                    <small  style="margin-left: 9px;font-weight: 600;cursor: pointer; ">Delete</small>
                                                                                                    
                                                                                                </span>
                                                                                            <!-- <span  class="account-button"  >
                                                                                                    <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                            </span> -->
                                                                                        </td>
        
                                                                                    </tr>
                                                                                </ng-template>
        
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
        
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
        

                                                        </div>

                                                    </div>

                                                </div>

                                                   

                                                    
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    

                    <div class="d-flex justify-content-end  mb-1" style="margin-right: 1%;">
                        <button class="btn btn-secondary ml-2 submit-btn">Cancel</button>
                        <button class="btn btn-secondary ml-2 submit-btn">Ok</button>
                    </div>

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


        

<!-- <ngx-spinner></ngx-spinner> -->











