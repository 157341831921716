<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Foreign Exchange Rates</span>
                <span class="color-black pl-1" *ngIf="!show"> >Add Foreign Exchange Rates</span>
                <span class="color-black pl-1" *ngIf="show"> >Update Foreign Exchange Rates</span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Add Foreign Exchange Rates</a></li>
                        <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                            Update Foreign Exchange Rates</a></li>
                   
          
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createRate">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    From Currency<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter From Currency" filter="true"
                                            formControlName="uomId"  [options]="uomArray" optionlabel="label">
                                                            
                                            </p-dropdown>
                                           
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">To Currency<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter To Currency" filter="true"
                                            formControlName="uomIdTo"  [options]="uomArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                           
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Purpose</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Purpose" filter="true"
                                            formControlName="purposeEnumId"  [options]="exchangePurposeArray" optionlabel="label">
                                                            
                                            </p-dropdown>
                                           
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Rate <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Rate"
                                                formControlName="conversionFactor" >
                                           
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">From Date 
                                               
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Rate"
                                                formControlName="fromDate" >
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Through Date 
                                               
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Rate"
                                                formControlName="thruDate">
                                           
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>

                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 61%;">
                                <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit"  *ngIf="!show"  (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
