<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Header </span>
                <!-- <span class="color-black pl-1"> > Create New</span> -->
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                   Header Detail Information
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Payment Type</label>
                                            <div>
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item1 pTemplate="selectedItem1">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item1.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car1 pTemplate="item1">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car1.label}}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Payment Method ID</label>
                                            <div>
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item1 pTemplate="selectedItem1">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item1.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car1 pTemplate="item1">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car1.label}}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">From Party ID</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Amount</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Actual Currency Amount</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Effective Date</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Reference Date</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Fin Account Tran Id</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">To Party Id</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Currency</label>
                                            <div>
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item1 pTemplate="selectedItem1">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item1.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car1 pTemplate="item1">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car1.label}}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Actual Currency Uom Id</label>
                                            <div>
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item1 pTemplate="selectedItem1">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item1.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car1 pTemplate="item1">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car1.label}}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Comments</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Override GI Actual Id</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>   
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Update</button></div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
