import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-flexible-main',
  templateUrl: './flexible-main.component.html',
  styleUrls: ['./flexible-main.component.css']
})
export class FlexibleMainComponent implements OnInit {
  activeCategory=1;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  generateReportForm: FormGroup;
  displayFlexibleReportSearchForm: FormGroup;
  reportChoiceForm: FormGroup;
  FindListFlexibleReport: any;
  reportChoice: any;
  reportChoiceArray: any[]=[];
  UpdateFlexibleReportPat: any;
  forumGroupId: any;
  updateContentRolesPat: any;
  updaterolesForm: any;
  show: boolean;
  product:any;
  throughDateCatePatch: string;
  fromDateCatePatch: string;
  editReportForm: any;
  EditListFlexibleReport: any;
  userLoginId: string;
  statusArray: { label: string; value: string; }[];
  statusId: any;
  contentId: any;
  contentIdTo: any;
  birtexportformatArray: { label: string; value: string; }[];
  previewForm: FormGroup;
  downloading: boolean;
  displayFlexibleReportSearchForms: FormGroup;
  displayFlexibleReportSearchForm1: FormGroup;
  reportTopicArray: { label: string; value: string; }[];
  getDataResource: any;
  getDataResourceArray: any;
  DataResourceId: any;
  DataResourceIdArray: any[]=[];
  dropdownvalue: any;
  reportChoiceValue: any;
  turnOn: boolean;

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
  )
  {
    this.generateReportForm=this._FormBuilder.group({
      dataResourceId:"",
      reportName:"",
      description:"",
      reportTopic:"",
    })

    this.previewForm=this._FormBuilder.group({
      fromDate:"",
      throughDate:"",
      productCategoryID:"",
      productStoreID:"",
      birtexportformat:"",
    })

    this.reportChoiceForm=this._FormBuilder.group({
      reportchoice:"",
     
    })

    this.birtexportformatArray =[
      {
        label:"Word(.doc)",
        value:"Word (.doc)"
      },
      {
        label:"Pdf(.pdf)",
        value:"Pdf (.pdf)"
      },
      {
        label:"Excel(.xlsx)",
        value:"Excel (.xlsx)"
      }
    ],

    this.displayFlexibleReportSearchForm=this._FormBuilder.group({
      exampleID:"",
      exampleTypeID:"",
      statusID:"",
      exampleName:"",
      description:"",
      longDescription:"",
      comments:"",
      exampleDate:"",
      exampleSize:"",
      anotherDate:"",
      anotherText:"",
      birtExportFormat:"",

    }),

    this.displayFlexibleReportSearchForm1=this._FormBuilder.group({
      fromDate:"",
      throughDate:"",
      productCategoryID:"",
      productStoreID:"",
      birtexportformat:"",
    })

    this.statusArray =[
      {
        label:"In Progress",
        value:"CTNT_IN_PROGRESS"
      },
      {
        label:"Published",
        value:"CTNT_PUBLISHED"
      }
    ],

    this.reportTopicArray =[
      {
        label:"Turnover (product)",
        value:"CTNT_MASTER_TURNOVER"
      },
      {
        label:"Example",
        value:"CTNT_MASTER_EXAMPLE"
      }
    ]

    
}

  ngOnInit(): void {
    this.turnOn=true;

    this.ListFlexibleReport();
    this.ReportChoice();

    this.userLoginId = localStorage.getItem('userLoginId');
    this.getDataResourceId();

 }

  getDataResourceId() {
    this.spinner.show();
    this.accountsService.getDataResourceId().subscribe(res => {
      this.DataResourceId = res.data.getDataResourceId;
      this.spinner.hide();
      for (const value of this.DataResourceId) {
        this.DataResourceIdArray.push({
          label: value.dataResourceId,
          value: value.dataResourceId
        })
      }
    })
  }

 ListFlexibleReport() {
    this.spinner.show();
    this.accountsService.ListFlexibleReport().subscribe(res => {
      this.FindListFlexibleReport = res.data;
      this.spinner.hide();
    })
  }

  toBack()
  {
    this._Router.navigate(['/Reports/main']);
  }

  flexible(): void {
    this.spinner.show();
    const requestData ={
      "contentAssocTypeId":"SUB_CONTENT",
      "contentId 	":'',
      "contentIdTo":'',
      "contentName":this.generateReportForm.value.reportName,
      "contentTypeId": "FLEXIBLE_REPORT",
      "dataResourceId":this.generateReportForm.value.dataResourceId,
      "description": this.generateReportForm.value.description,
      "masterContentId": this.generateReportForm.value.reportTopic,
      "statusId":'CTNT_IN_PROGRESS',
      "userLogin": this.userLoginId,
    }
     this.accountsService.flexible(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.contentId=res.data.contentId;
        this.contentIdTo=res.data.contentIdTo;
        this._Router.navigate(['/birt/flexible-reports/update-report'],{ queryParams:{contentId:this.contentId,contentName:this.generateReportForm.value.reportName,description:this.generateReportForm.value.description,dataResourceId:this.generateReportForm.value.dataResourceId} });
        this.ListFlexibleReport();
        this.closebutton.nativeElement.click();
        this.generateReportForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  
  UpdateFlexibleReport(product): void {
    this.spinner.show();
    const requestData = {
      "contentId": product.ListFlexibleReport.contentId,
      "statusId":'CTNT_PUBLISHED',
    }
    this.accountsService.UpdateFlexibleReport(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.ListFlexibleReport();
        this.closebutton2.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  DeleteFlexibleReport(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "contentId": product.ListFlexibleReport.contentId,
        }
        this.accountsService.DeleteFlexibleReport(req).subscribe((res: any) => {
          if (res.success) {
            this.ListFlexibleReport();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  ReportChoice() {
    this.spinner.show();
    this.accountsService.ReportChoice().subscribe(res => {
      this.reportChoice = res.data;
      this.spinner.hide();
      for (const value of this.reportChoice) {
        this.reportChoiceArray.push({
          label: value.ReportChoice.contentName + "["+ value.ReportChoice.description+"]",
          value: value.dataMasterDetails[0].contentId,
        })
      }

    })
  }

  referTurnOn()
  {
    this.turnOn=true;
    this.reportChoiceValue=this.reportChoiceForm.value.reportchoice;
    console.log("value",this.reportChoiceValue )
    if(this.reportChoiceValue=="CTNT_MASTER_EXAMPLE")
    {
      this.turnOn=false;
    }

  }

  generatePdfShort() {
 
    this.dropdownvalue= this.previewForm.value.birtexportformat;
    
    if(this.dropdownvalue=="Word (.doc)")
     {
       this.DisplayFlexibleReportSearchWord();
     }
     else if(this.dropdownvalue=="Pdf (.pdf)")
     {
       this.DisplayFlexibleReportSearchPdf();
     }
     else if(this.dropdownvalue=="Excel (.xlsx)")
     {
       this.DisplayFlexibleReportSearchExcel();
     }
     else
     {
      console.log("No file Choosen");
     }
    
    }

  DisplayFlexibleReportSearchWord(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
    "birtContentType":this.previewForm.value.birtexportformat,
    "fromDate":this.datePipe.transform(this.previewForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
    "productCategoryId": this.previewForm.value.productCategoryID,
    "productStoreId":this.previewForm.value.productStoreID,
    "thruDate":this.datePipe.transform(this.previewForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),

    }
    this.accountsService.DisplayFlexibleReportSearch(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:"application/msword" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const requestData = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = requestData; this.spinner.hide();
        link.download = `DisplayFlexibleReportSearch.doc`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(requestData);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.previewForm.reset();
      this.closebutton2.nativeElement.click();

  }

  DisplayFlexibleReportSearchExcel(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
    "birtContentType":this.previewForm.value.birtexportformat,
    "fromDate":this.datePipe.transform(this.previewForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
    "productCategoryId": this.previewForm.value.productCategoryID,
    "productStoreId":this.previewForm.value.productStoreID,
    "thruDate":this.datePipe.transform(this.previewForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),

    }
    this.accountsService.DisplayFlexibleReportSearch(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const requestData = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = requestData; this.spinner.hide();
        link.download = `DisplayFlexibleReportSearch.xlsx`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(requestData);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.previewForm.reset();
      this.closebutton2.nativeElement.click();

  }

  DisplayFlexibleReportSearchPdf(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
    "birtContentType":this.previewForm.value.birtexportformat,
    "fromDate":this.datePipe.transform(this.previewForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
    "productCategoryId": this.previewForm.value.productCategoryID,
    "productStoreId":this.previewForm.value.productStoreID,
    "thruDate":this.datePipe.transform(this.previewForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),

    }
    this.accountsService.DisplayFlexibleReportSearch(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:'application/application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const requestData = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = requestData; this.spinner.hide();
        link.download = `DisplayFlexibleReportSearch.pdf`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(requestData);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.previewForm.reset();
      this.closebutton2.nativeElement.click();

  }

  generatePdf() {
 
    this.dropdownvalue= this.displayFlexibleReportSearchForm.value.birtExportFormat;
    
    if(this.dropdownvalue=="Word (.doc)")
     {
       this.DisplayFlexibleReportSearchSecondWord();
     }
     else if(this.dropdownvalue=="Pdf (.pdf)")
     {
       this.DisplayFlexibleReportSearchSecondPDF();
     }
     else if(this.dropdownvalue=="Excel (.xlsx)")
     {
       this.DisplayFlexibleReportSearchSecondExcel();
     }
     else
     {
      console.log("No file Choosen");
     }
    
    }
  
  DisplayFlexibleReportSearchSecondWord(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
      "anotherDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.anotherDate, "yyyy-MM-dd HH:mm:ss"),
      "anotherText":this.displayFlexibleReportSearchForm.value.anotherText,
      "birtExportFormat": this.displayFlexibleReportSearchForm.value.birtExportFormat,
      "description":this.displayFlexibleReportSearchForm.value.description,
      "exampleDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.exampleDate, "yyyy-MM-dd HH:mm:ss"),
      "exampleID":this.displayFlexibleReportSearchForm.value.exampleID,
      "exampleName":this.displayFlexibleReportSearchForm.value.exampleName,
      "exampleSize":this.displayFlexibleReportSearchForm.value.exampleSize,
      "exampleTypeID":this.displayFlexibleReportSearchForm.value.exampleTypeID,
      "longDescription":this.displayFlexibleReportSearchForm.value.longDescription,
      "statusID":this.displayFlexibleReportSearchForm.value.statusID,
      "comments":this.displayFlexibleReportSearchForm.value.comments,
    
    }
    this.accountsService.DisplayFlexibleReportSearchSecond(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:"application/msword" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data; this.spinner.hide();
        link.download = `DisplayFlexibleReport.doc`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.closebutton2.nativeElement.click();
      this.displayFlexibleReportSearchForm.reset();

  }

  DisplayFlexibleReportSearchSecondPDF(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
      "anotherDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.anotherDate, "yyyy-MM-dd HH:mm:ss"),
      "anotherText":this.displayFlexibleReportSearchForm.value.anotherText,
      "birtExportFormat": this.displayFlexibleReportSearchForm.value.birtExportFormat,
      "description":this.displayFlexibleReportSearchForm.value.description,
      "exampleDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.exampleDate, "yyyy-MM-dd HH:mm:ss"),
      "exampleID":this.displayFlexibleReportSearchForm.value.exampleID,
      "exampleName":this.displayFlexibleReportSearchForm.value.exampleName,
      "exampleSize":this.displayFlexibleReportSearchForm.value.exampleSize,
      "exampleTypeID":this.displayFlexibleReportSearchForm.value.exampleTypeID,
      "longDescription":this.displayFlexibleReportSearchForm.value.longDescription,
      "statusID":this.displayFlexibleReportSearchForm.value.statusID,
      "comments":this.displayFlexibleReportSearchForm.value.comments,
    
    }
    this.accountsService.DisplayFlexibleReportSearchSecond(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:'application/application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data; this.spinner.hide();
        link.download = `Display Flexible Report.pdf`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.displayFlexibleReportSearchForm.reset();
      this.closebutton2.nativeElement.click();


  }

  DisplayFlexibleReportSearchSecondExcel(): void {
    this.spinner.show();
    this.downloading = true;
    const requestData = {
      "anotherDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.anotherDate, "yyyy-MM-dd HH:mm:ss"),
      "anotherText":this.displayFlexibleReportSearchForm.value.anotherText,
      "birtExportFormat": this.displayFlexibleReportSearchForm.value.birtExportFormat,
      "description":this.displayFlexibleReportSearchForm.value.description,
      "exampleDate":this.datePipe.transform(this.displayFlexibleReportSearchForm.value.exampleDate, "yyyy-MM-dd HH:mm:ss"),
      "exampleID":this.displayFlexibleReportSearchForm.value.exampleID,
      "exampleName":this.displayFlexibleReportSearchForm.value.exampleName,
      "exampleSize":this.displayFlexibleReportSearchForm.value.exampleSize,
      "exampleTypeID":this.displayFlexibleReportSearchForm.value.exampleTypeID,
      "longDescription":this.displayFlexibleReportSearchForm.value.longDescription,
      "statusID":this.displayFlexibleReportSearchForm.value.statusID,
      "comments":this.displayFlexibleReportSearchForm.value.comments,
    
    }
    this.accountsService.DisplayFlexibleReportSearchSecond(requestData)
      .subscribe(value => {
        const blob = new Blob([value], { type:'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data; this.spinner.hide();
        link.download = `Display Flexible Report.xlsx`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100); this.downloading = false;
      });
      this.displayFlexibleReportSearchForm.reset();
      this.closebutton2.nativeElement.click();


  }

  // DisplayFlexibleReportSearch(): void {
  //   this.spinner.show();
  //   this.downloading = true;
  //   const requestData = {
  //   "birtContentType":this.previewForm.value.birtexportformat,
  //   "fromDate":this.datePipe.transform(this.previewForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
  //   "productCategoryId": this.previewForm.value.productCategoryID,
  //   "productStoreId":this.previewForm.value.productStoreID,
  //   "thruDate":this.datePipe.transform(this.previewForm.value.throughDate, "yyyy-MM-dd HH:mm:ss"),

  //   }
  //   this.accountsService.DisplayFlexibleReportSearch(requestData)
  //     .subscribe(value => {
  //       const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' });
  //       if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //         window.navigator.msSaveOrOpenBlob(blob);
  //         return;
  //       }
  //       const data = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = data; this.spinner.hide();
  //       link.download = `Preview`;
  //       link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  //       setTimeout(() => {
  //         window.URL.revokeObjectURL(data);
  //         link.remove();
  //       }, 100); this.downloading = false;
  //     });
  // }

  // DisplayFlexibleReportSearchs(): void {
  //   this.spinner.show();
  //   this.downloading = true;
  //   const requestData = {
  //   "birtContentType":this.displayFlexibleReportSearchForm1.value.birtexportformat,
  //   "fromDate":this.datePipe.transform(this.displayFlexibleReportSearchForm1.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
  //   "productCategoryId": this.displayFlexibleReportSearchForm1.value.productCategoryID,
  //   "productStoreId":this.displayFlexibleReportSearchForm1.value.productStoreID,
  //   "thruDate":this.datePipe.transform(this.displayFlexibleReportSearchForm1.value.throughDate, "yyyy-MM-dd HH:mm:ss"),

  //   }
  //   this.accountsService.DisplayFlexibleReportSearchs(requestData)
  //     .subscribe(value => {
  //       const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' });
  //       if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //         window.navigator.msSaveOrOpenBlob(blob);
  //         return;
  //       }
  //       const data = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = data; this.spinner.hide();
  //       link.download = `Preview`;
  //       link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  //       setTimeout(() => {
  //         window.URL.revokeObjectURL(data);
  //         link.remove();
  //       }, 100); this.downloading = false;
  //     });
  // }

  toupdateReport(product)
  {
    this.product=product;
    this._Router.navigate(['/birt/flexible-reports/update-report'],{ queryParams:{contentId:this.product.dataMasterName.contentIdTo,contentName:this.product.ListFlexibleReport.contentName,description:this.product.ListFlexibleReport.description,statusId:this.product.ListFlexibleReport.statusId,masterContentName:product.dataMasterDetails[0].contentName,contentIdTo:product.dataMasterName.contentId } });
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  
  }


}
