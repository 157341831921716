<div *ngIf='!token && !public'>
    <app-login></app-login>
</div>

<div *ngIf="this.public==true">
    <router-outlet></router-outlet>
</div>

<div class="app-base-layout" *ngIf="token">

    <div class="w-100">
        <app-header></app-header>
        <div class="row tabResponsive" style="margin-top: 55px;width: 100%;">

            <!-- <div *ngIf="!_Router.url.includes('/webpos')" class="col-md-2">
                <app-sidebar></app-sidebar>
            </div> -->
            <div class="">
                <app-sidebar [isExpanded]="sidebarExpanded"
                    (toggleSidebar)="sidebarExpanded = !sidebarExpanded"></app-sidebar>
            </div>
            <div class="pr-0" style="margin-top: -53px;">
                <div class="content" [ngClass]="{ sidebarExpanded: sidebarExpanded }">

                    <app-main-container></app-main-container>
                    <app-footer></app-footer>
                   
                </div>
            </div>
            <!-- <div *ngIf="_Router.url.includes('/webpos')" class="col-md-10" style="margin-top: -53px;">
                <app-main-container></app-main-container>

            </div> -->
        </div>
        <!-- <div class="content-footer" [ngClass]="{ sidebarExpanded: sidebarExpanded }">
            
        </div> -->
       
    </div>
</div>
<!-- <ngx-spinner></ngx-spinner> -->