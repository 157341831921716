
<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of [{{contactPartyId}}] </span>
              </div>
              <div class="w3-card-4 classCard" >
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
              
                          <li  [ngClass]="activeCategory==2?'active':''" ><a>
                            New Party Note</a></li>
                          
                         
                        </ul>
                      </div>
                  
                    <div >
                      <div class="panel-body">
                          <form [formGroup]="fileUploadForm">
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-2 form-group classInput">
                                        <label for="exampleInputEmail1">Note Name</label>
                                    </div>
                                    <div class="col-lg-2">
                                        <input type="email" [(ngModel)]="noteNameForUpdate" formControlName="noteName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                    </div>

                                    <div class="col-lg-2 form-group classInput__new_changes">
                                        <label for="exampleInputEmail1">Note</label>
                                    </div>
                                    <div class="col-lg-2">

                                        <textarea style="height: 100%!important;width:319%;padding-left: 17px;" id="w3review" [(ngModel)]="noteForUpdate" formControlName="note" rows="3" cols="30" placeholder="Note">
                                        </textarea> 
                                         

                                    </div>
                                   
                                </div>
                            </div>
 
                        </div>
                        <div style="margin-right: 8%;">
                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn">
                                <button type="submit" (click)="onSubmit()"  class="btn btn-secondary submit-btn">Create</button>
                                <button  type="submit"(click)="navigateFromCreate()" class="btn btn-danger ">Cancel</button>
                            </div>
                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateBtn">
                                <button type="submit" (click)="onUpdate()"  class="btn btn-secondary submit-btn">Update</button>
                                <button  type="submit" (click)="navigate()"class="btn btn-danger ">Cancel</button>
                            </div>
                       
                        </div>
                    </form>
                    </div>
                    </div>
                  </div>
                  </div>
                  </div>

            </div>
        </div>
</div>
<ngx-spinner></ngx-spinner> 



