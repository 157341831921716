import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';

import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-jobshop-assocs',
  templateUrl: './create-jobshop-assocs.component.html',
  styleUrls: ['./create-jobshop-assocs.component.css']
})
export class CreateJobshopAssocsComponent implements OnInit {
  createJob: FormGroup;
  activeCategory = 2;
  workEffortId: any;
  ProductionproductionRunIdArray: any[]=[];
  ProductionproductionRunId: any;
  enumId: string;
  enumDataArrayList: any[]=[];

  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
   
    readonly accountsService: AccountsService,
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) {
    this.createJob = this._FormBuilder.group({
      ProductionRunID: [''],
      Type: [''],

    });

  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
   
    });
    this.getProductionproductionRunId();
    this.getEenumByTypeData();
  }
  getEenumByTypeData(): void {
    this.spinner.show();
    this.enumId="WORKFLOW"
    this.accountsService.getEenumByType(this.enumId).subscribe((res) => {
      let enumDa = res.data;
      this.spinner.hide();
      for (const value of enumDa) {
        this.enumDataArrayList.push({
          label: value.description,
          value: value.enumId,
        });
      }
    });
   
  }
  
  onSubmit() {
    this.spinner.show();
    this.productionService.postProductionJobassoc(this.workEffortId,this.createJob.value.ProductionRunID, this.createJob.value.Type).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId:this.workEffortId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getProductionproductionRunId(): void {
    this.spinner.show();
    this.productionService.getProductionproductionRunId().subscribe((res) => {
      this.ProductionproductionRunId = res.data; 
      this.spinner.hide();
      for (const value of this.ProductionproductionRunId) {
        this.ProductionproductionRunIdArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        })
      }
      
    
    })
  
  }
  


  cancelSubmit(){
    this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}

