import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WorkEffortService {
    constructor(private _HttpClient: HttpClient) { }
    getWorkEfforts(pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/crm/event?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`).toPromise();
    }
}