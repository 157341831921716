<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Vendors</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">
                        <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active"
                            routerLink="/payable/vendors/create-vendors">Create Invoice</button>
                    </div>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="searchInvoiceForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Invoice ID</label>
                                            <div class="more-filters-option d-flex">
                                                <div class="col-lg-5 w-100">
                                                    <p-dropdown filter="true" name="party"
                                                        [options]="opportunityIdArray" optionlabel="label"
                                                        formControlName="invoiceIdSearchType">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-5 w-100">
                                                    <input type="email" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" 
                                                        formControlName="invoiceId">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Description</label>
                                            <div class="more-filters-option d-flex">
                                                <div class="col-lg-5 w-100">
                                                    <p-dropdown filter="true" name="party"
                                                        formControlName="descriptionSearchType"
                                                        [options]="opportunityIdArray" optionlabel="label">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-5 w-100">
                                                    <input type="email" id="exampleInputEmail1"
                                                        formControlName="description" aria-describedby="emailHelp"
                                                        >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <div class="d-flex flex-column">
                                                <label for="exampleInputEmail1">Invoice Type</label>
                                                <p-dropdown filter="true" name="party" [options]="invoiceType"
                                                    formControlName="invoiceType" optionlabel="label">
                                                </p-dropdown>
                                                <p class="validate-field"
                                                    *ngIf="!!searchInvoiceForm.controls.invoiceType.invalid && !!searchInvoiceForm.controls.invoiceType.touched">
                                                    Invoice type is required!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <div class="d-flex flex-column">
                                                <label for="exampleInputEmail1">Status ID</label>
                                                <p-dropdown filter="true" name="party" [options]="statusIds"
                                                    formControlName="statusId" optionlabel="label">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <div class="d-flex flex-column">
                                                <label for="exampleInputEmail1">From Party ID</label>
                                                <p-dropdown filter="true" name="party" [options]="partyIds"
                                                    formControlName="fromPartyId" optionlabel="label"
                                                    placeholder="Select PartyID">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <div class="d-flex flex-column">
                                                <label for="exampleInputEmail1">To Party ID</label>
                                                <p-dropdown filter="true" name="party" [options]="partyIds"
                                                    formControlName="toPartyId" optionlabel="label"
                                                    placeholder="Select PartyID">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Invoice Date</label>
                                            <div class="d-flex flex-row">
                                                <p-dropdown filter="true" name="party" [options]="greaterArray"
                                                    formControlName="invoiceDateFromSearchType" optionlabel="label">
                                                </p-dropdown>
                                                <p-calendar class="ml-2" formControlName="invoiceDateFrom"></p-calendar>
                                                <p-dropdown filter="true" class="ml-2" name="party"
                                                    formControlName="invoiceDateToSearchType" [options]="smallerArray"
                                                    optionlabel="label">
                                                </p-dropdown>
                                                <p-calendar class="ml-2" formControlName="invoiceDateTo"></p-calendar>
                                            </div>
                                        </div>
                                    </div>
                                 

                                </div>
                                <div class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                        type="submit" class="btn btn-secondary submit-btn"
                                        (click)="searchInvoice()">Find</button></div>
                                <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1"> Invoice List</label>

                                            <div class="card own-account-table">
                                                <p-table [value]="invoices" [paginator]="true" [rows]="rows"
                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th pSortableColumn="code">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    Invoice ID <p-sortIcon field="code"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                Invoice Type<p-sortIcon field="name">
                                                                </p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}"
                                                                pSortableColumn="category"> Invoice Date
                                                                <p-sortIcon field="category"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="quantity"> Status
                                                                <p-sortIcon field="quantity"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price">Description <p-sortIcon
                                                                    field="price"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price">From Party<p-sortIcon
                                                                    field="price"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price">To Party
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    style="visibility:hidden;" value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price">Total
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    style="visibility:hidden;" value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price">Outstanding amount
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    style="visibility:hidden;" value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price">Created Date
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    style="visibility:hidden;" value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price">Action
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    style="visibility:hidden;" value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td>
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    {{product.invoiceId}}
                                                                </div>
                                                            </td>
                                                            <td [ngStyle]="{'width':'190px'}">{{product.invoiceTypeId}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'190px'}">{{product.category}}</td>
                                                            <td>{{product.invoiceDate |date :'medium'}}</td>
                                                            <td>{{product.status }}</td>
                                                            <td>{{product.description}}</td>
                                                            <td>{{product.partyFrom}}</td>
                                                            <td>{{product.partyTo}}</td>
                                                            <td>{{product.description}}</td>
                                                            <td>{{product.total}}</td>
                                                            <td>{{product.outstanding}}</td>
                                                            <td>{{product.createdStamp |date:'medium'}}</td>
                                                            <td>
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        class="ml-20" width="16" height="16"
                                                                        viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                
                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
           

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 