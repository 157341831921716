<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels">The Profile of <span
          [ngStyle]="{'color':'#0d3769'}">{{this.taxAuthPartyId}}</span></span>
      
        <button type="submit" class="btn btn-danger buttonclass"(click)="GoToQuickLink()">Cancel</button>&nbsp;
    </div>
    


      <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
          <div class="suppliers-wrapper">

          </div>
        </div>
        <div class="w3-card-4 classCard">
          <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
              flex-wrap: wrap !important;
              display: flex !important;">
              <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                  Profile</a></li>
              <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                  Preferences</a></li>
              <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                  Role(s)</a></li>
              <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                  Identification Numbers</a></li>
              <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                  Link Party</a></li>
              <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategoryFunction(7)"><a>
                  Relationships</a></li>
              <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategoryFunction(8)"><a>
                  Vendor</a></li>
              <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategoryFunction(9)"><a>
                  Rates</a></li>
              <li [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategoryFunction(10)"><a>
                  Party Skills</a></li>
              <li [ngClass]="activeCategory==11?'active':''" (click)="changeactiveCategoryFunction(11)"><a>
                  Party Content</a></li>
              <li [ngClass]="activeCategory==12?'active':''" (click)="changeactiveCategoryFunction(12)"><a>
                  Segments</a></li>
              <li [ngClass]="activeCategory==14?'active':''" (click)="changeactiveCategoryFunction(14)"><a>
                  Classifications</a></li>
              <li [ngClass]="activeCategory==13?'active':''" (click)="changeactiveCategoryFunction(13)"><a>
                  Resumes</a></li>
              <li [ngClass]="activeCategory==15?'active':''" (click)="changeactiveCategoryFunction(15)"><a>
                  Contact List</a></li>
              <li [ngClass]="activeCategory==16?'active':''" (click)="changeactiveCategoryFunction(16)"><a>
                  Employment Applications
                </a></li>
                <li [ngClass]="activeCategory==17?'active':''" (click)="changeactiveCategoryFunction(17)"><a>
                  Product Store Roles
                </a></li>
                <li [ngClass]="activeCategory==19?'active':''" (click)="changeactiveCategoryFunction(19)"><a>
                  Fin. History</a></li>
                 <li [ngClass]="activeCategory==20?'active':''" (click)="changeactiveCategoryFunction(20)"><a>
                    Shopping Lists</a></li>
                   
              <li [ngClass]="activeCategory==18?'active':''" (click)="changeactiveCategoryFunction(18)"><a>
                 Tax Infos
                </a></li> 



            </ul>
          </div>
          <div *ngIf="!this.Hidebtn"  class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium classHead">
            <div class="submenu" >
             <div>
                 <button _ngcontent-cta-c128="" 
                 type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important"
                 routerLinkActive="active" routerLink="/financial/payable/billing-account">Billing Account</button>&nbsp;
                 <button _ngcontent-cta-c128="" 
                 type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important"
                 routerLinkActive="active" routerLink="/financial/financial-account">Financial Account</button>&nbsp;
                 <button _ngcontent-cta-c128="" 
                 type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important"
                 routerLinkActive="active" routerLink="/partyQuickLink/communications">Communications</button>&nbsp;
                
                 <button _ngcontent-cta-c128="" 
                 type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important"
                 routerLinkActive="active" routerLink="/crm/quotations">Quotes</button>&nbsp;
                 <button _ngcontent-cta-c128="" 
                 type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important"
                 routerLinkActive="active" routerLink="/order/order-management">Orders</button>&nbsp;
                 <button _ngcontent-cta-c128="" 
                 type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important"
                 routerLinkActive="active" routerLink="/crm/quotations/create-quotes">New Quotes</button>&nbsp;
                 <button _ngcontent-cta-c128="" 
                 type="submit" style="width:auto!important" class="btn btn-secondary submit-btn fontMenu" style="width: auto!important"
                 routerLinkActive="active" routerLink="/order/create-sales-order">New Order</button>&nbsp;
           
                 </div>
                      
            </div>
          </div>
          <div class="create-new-leade mt-2">
            <div class="container-fluid">
              <div class="row">
                <form class="w-100">
                  <div class="port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div *ngIf="activeCategory==4">
                          <div class="w3-card-4 classCard materialCard" *ngFor="let cost of Preferences">
                            <div class="header-tabs">
                              <h4 class=" common-styling" style="margin-left: 1%;">
                                Preferences For userLogin: {{cost.userLogins.userLoginId}}
                              </h4>
                             
  
                            </div>
                          <div class="panel-body">
                            <div style="padding: 15px 20px!important;" class="all-users-infomation font-13 font-medium own-user-select">
                            
                              <div class="form-group">
                               
                                <div id="screenlet_1_col" class="screenlet-body">
                                <table cellspacing="0" class="basic-table hover-bar" style="width: 142%!important;">
                                  <thead>
                                  <tr class="header-row" style="background: #0d3769;">
                                  <td [ngStyle]="{'width':'170px'}">
                                <label style="color: white!important;margin-top: 6%;" for="ListPreference_childDescription_o_0" id="ListPreference_childDescription_o_0_title">Preference Group</label>  </td>
                                  <td [ngStyle]="{'width':'170px'}">
                                <label style="color: white!important;margin-top: 6%;" for="ListPreference_description_o_0" id="ListPreference_description_o_0_title">Preference Name</label>  </td>
                                  <td [ngStyle]="{'width':'170px'}" >
                                <label style="color: white!important;margin-top: 6%;" for="ListPreference_userPrefValue_o_0" id="ListPreference_userPrefValue_o_0_title">Value</label>  </td>
                                 <td>
                                <label style="color: white!important;margin-top: 6%;" for="ListPreference_submit_o_0" id="ListPreference_submit_o_0_title">Remove</label>  </td>
                                  
                                  </tr>
                                  </thead>
                                  <tbody><tr style="padding: 4px 6px !important;
                                    background: #fafafa;
                                    font-size: 13px !important;
                                    color: #383139 !important;">
                                  <td [ngStyle]="{'width':'170px'}">
                                
                                    {{cost.dataList.childDescription}}
                                  </td>
                                  <td [ngStyle]="{'width':'170px'}">
                                
                                    {{cost.dataList.description}}
                                  </td>
                                  <td [ngStyle]="{'width':'170px'}">
                                
                                &nbsp;  
                                  </td>
                                  <td [ngStyle]="{'width':'170px'}">
                               </td>
                                
                                <!-- End  Grid Widget - Grid Element  component://party/widget/partymgr/PartyForms.xml#ListPreference -->  
                                  </tr>
                                  <tr class="alternate-row" style="padding: 3px 0px !important;
                                 background: rgba(0,0,0,.05)!important;
                                  font-size: 13px !important;
                                  color: #383139 !important;">
                                  <td [ngStyle]="{'width':'170px'}" >
                                
                                    {{cost.userPreferences.userPrefGroupTypeId}}
                                  </td>
                                  <td [ngStyle]="{'width':'170px'}">
                                
                                    {{cost.userPreferences.userPrefTypeId}}
                                  </td>
                                  <td [ngStyle]="{'width':'170px'}">
                                
                                &nbsp;  
                                  </td>
                                  <td [ngStyle]="{'width':'170px'}">
                               </td>
                                
                                <!-- End  Grid Widget - Grid Element  component://party/widget/partymgr/PartyForms.xml#ListPreference -->  
                                  </tr>
                                </tbody></table>
                                <!-- End  Grid Widget - Grid Element  component://party/widget/partymgr/PartyForms.xml#ListPreference --></div></div>
                               
                              </div>
                            </div>
                          </div>
                          </div>
                       
                        <div *ngIf="activeCategory==2">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Roles
                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createRoleType()">New Role Type|</a> <a (click)="createAddRole()">Add To
                                Role</a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <div class="card own-account-table">
                                  <p-table [value]="roleList" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                    (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                          
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>

                                            RoleType ID<p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Role <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Parent Type ID <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Action <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>


                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-activity>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            {{activity.roleTypeId}}
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}">{{activity.description}} </td>
                                        <td [ngStyle]="{'width':'170px'}">{{activity.parentTypeId}} </td>
                                        <td [ngStyle]="{'width':'170px'}" style="cursor: pointer;color: #0d3769;"
                                          (click)="deleteRoleTypeId(activity.roleTypeId)">
                                          <a  class="account-button"  routerLinkActive="active"> Remove</a>
                                        </td>



                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <p class="paginate_data">
                                    View per page

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div *ngIf="activeCategory==5">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              List Identification Number
                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createIdentificationNumber()">Create New Identification Number</a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group" style="width: 100%!important;">
                                <div class="card own-account-table">
                                  <p-table [value]="IdentificationList" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}"
                                          pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            Identification Type <p-sortIcon field="code"></p-sortIcon>
                                          </div>

                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}"
                                          pSortableColumn="name">
                                          <div style="color: white;">ID Value<p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>

                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}"
                                          pSortableColumn="name">
                                          <div style="color: white;">Action<p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <p-checkbox _ngcontent-egj-c329=""
                                          styleclass="custom-checkbox" binary="true"
                                          class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                          <div
                                              class="custom-checkbox ui-chkbox ui-widget">
                                              <div class="ui-helper-hidden-accessible">
                                                  <input type="checkbox" name="undefined"
                                                      value="undefined"></div>
                                              <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                  <span
                                                      class="ui-chkbox-icon ui-clickable"></span>
                                              </div>
                                          </div>
                                          <!---->
                                      </p-checkbox>
                                          {{product.description}}
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}">
                                          {{product.identificationData.idValue}}
                                        </td>

                                        <td [ngStyle]="{'width':'170px'}">
                                          <fa-icon [icon]="faEdit" class="text-primary"
                                            (click)="updatePartyIdentification(product.identificationData.partyIdentificationTypeId,product.identificationData.idValue)">
                                          </fa-icon>
                                          <svg xmlns="http://www.w3.org/2000/svg"
                                            (click)="deletePartyIdentification(product.identificationData.partyIdentificationTypeId)"
                                            class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>

                                        </td>

                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <p class="paginate_data">
                                    View per page

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==6">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Link Party
                            </h4>
                          </div>
                          <div >
                            <div class="container-fluid">
                              <div class="row">
                                <form class="w-100" [formGroup]="createLinkParty">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                      <div class="row">
                  
                                          <div class="col-lg-3 form-group classInput" >
                                            <label for="exampleInputEmail1">
                                              Party ID</label>
                                          </div>
                                       
                                          <div class="col-lg-2">
                                            <p-dropdown filter="true" placeholder="Enter Party ID" name="Party ID"
                                          id="exampleInputEmail1" formControlName="partyIdTo" [options]="partyIdArray"
                                          optionlabel="label">
                                        </p-dropdown>
                                          </div>
                                          <div class="col-lg-3 form-group classInputA" >
                                            <label for="exampleInputEmail2">
                                              To Party ID</label>
                                          </div>
                                         
                                        
                                          <div class="col-lg-2" >
                                            <p-dropdown filter="true" placeholder="Enter To Party ID" name="Party ID"
                                            id="exampleInputEmail2" formControlName="partyIdFrom" [options]="partyIdArray"
                                            optionlabel="label">
                                          </p-dropdown>
                                          </div>
                  
                                      </div>
                                  </div>
                                 
                                  </div>
                                </form>
                                <div class="main-submit-button" style="margin-left: 56%;">

                                  <button type="submit" (click)="setPartyLink();"
                                    class="btn btn-secondary submit-btn">Submit</button>




                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div *ngIf="activeCategory==7">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Relationships
                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createNewRelationshipType()">New Relationship Type|</a> <a
                                (click)="createAddOtherpartyRelationship()">Add other party relationship</a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group" style="    width: 100%;">
                                <div class="card own-account-table">
                                  <p-table [value]="RelationshipPartyIdTo" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>


                                            Party ID<p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> In The Role OF <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Is A <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Of Party <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> In The Role Of <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> From Date <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Through Date <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Comments <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Action <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>


                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-activity>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                           
{{activity.data.partyIdTo}}
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}">{{activity.data.roleTypeIdTo}} </td>
                                        <td [ngStyle]="{'width':'170px'}">{{activity.isA}} </td>
                                        <td [ngStyle]="{'width':'170px'}">{{activity.data.partyIdFrom}} </td>
                                        <td [ngStyle]="{'width':'170px'}">{{activity.data.roleTypeIdFrom}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.data.fromDate|date:'yyyy-MM-dd'}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{activity.data.thruDate|date:'yyyy-MM-dd'}} </td>
                                        <td [ngStyle]="{'width':'170px'}">{{activity.data.comments}} </td>
                                      
                                        <td [ngStyle]="{'width':'170px'}">
                                          <svg class="hover" (click)="updateAddOtherpartyRelationship(activity)"
                                            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            viewBox="0 0 21.223 21.222">
                                            <g transform="translate(0 -0.004)">
                                              <g transform="translate(0 1.52)">
                                                <path
                                                  d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                  transform="translate(0 -34.137)" />
                                              </g>
                                              <g transform="translate(4.548 0.004)">
                                                <g transform="translate(0 0)">
                                                  <path
                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                    transform="translate(-102.409 -0.004)" />
                                                </g>
                                              </g>
                                            </g>
                                          </svg>


                                          <svg xmlns="http://www.w3.org/2000/svg"
                                            (click)="deleteOtherRelationship(activity)" class="ml-20"
                                            width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>
                                        </td>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <p class="paginate_data">
                                    View per page

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div *ngIf="activeCategory==8">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Vendor Information
                            </h4>
                          </div>
                          <div >
                            <div class="container-fluid">
                              <div class="row">
                                <form class="w-100" [formGroup]="createVendor">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label for="exampleInputEmail1">
                                          Manifest Company Name</label>
                                        <input type="email" formControlName="ManifestCompany" class="form-control"
                                          id="exampleInputEmail1" aria-describedby="emailHelp"
                                          placeholder="Enter Manifest Company name">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label for="exampleInputEmail2">
                                          Manifest Company Title</label>
                                        <input type="email" formControlName="CompanyTitle" class="form-control"
                                          id="exampleInputEmail1" aria-describedby="emailHelp"
                                          placeholder="Enter Manifest Company Title">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label for="exampleInputEmail1">
                                          Manifest Logo Url</label>
                                        <input type="email" formControlName="logo" class="form-control"
                                          id="exampleInputEmail1" aria-describedby="emailHelp"
                                          placeholder="Enter Manifest Logo Url">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label for="exampleInputEmail2">
                                          Manifest Policies</label>
                                        <input type="email" formControlName="policies" class="form-control"
                                          id="exampleInputEmail1" aria-describedby="emailHelp"
                                          placeholder="Enter Manifest Policies">
                                      </div>
                                    </div>
                                  </div>
                                </form>
                                <div class="main-submit-button" style="margin-left: 80%;">

                                  <button type="submit" (click)="onUpdateVendor();"
                                    class="btn btn-secondary submit-btn">Update</button>




                                </div>
                                <br>   <br>   <br>   <br>   <br>   <br>   <br>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div *ngIf="activeCategory==9">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Rates
                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createEditPartyRates()">Create New Rates</a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <div class="card own-account-table">
                                  <p-table [value]="PartyRate" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                    (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>


                                            Rate Type<p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Period Type ID <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Rate Default Amount <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Default Rate <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Percentage Used <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Action <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>



                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-activity>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            {{activity.partyRateData.rateTypeId}}
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}"> Rate amount per Hour</td>
                                        <td [ngStyle]="{'width':'170px'}"> </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.partyRateData.defaultRate}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.partyRateData.percentageUsed}}
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}">

                                         <span *ngIf="activity.thruDate==null">
                                          <svg xmlns="http://www.w3.org/2000/svg" (click)="deletePartRate(activity)"
                                            class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg></span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <p class="paginate_data">
                                    View per page

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==10">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Party SKills
                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createAddPartySkill()">Create</a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <div class="card own-account-table">
                                  <p-table [value]="skillList" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                    (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                           
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>

                                            Skill Type ID<p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Years Experience <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Rating <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Skill Level <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Action <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>



                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            {{product.description}}
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{product.yearsExperience}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{product.rating}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{product.skillLevel}} </td>

                                        <td [ngStyle]="{'width':'170px'}">
                                          <svg class="hover" (click)="onUpdateSkill(product.skillTypeId
                                      ,product.rating,product.skillLevel,product.yearsExperience)"
                                            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            viewBox="0 0 21.223 21.222">
                                            <g transform="translate(0 -0.004)">
                                              <g transform="translate(0 1.52)">
                                                <path
                                                  d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                  transform="translate(0 -34.137)" />
                                              </g>
                                              <g transform="translate(4.548 0.004)">
                                                <g transform="translate(0 0)">
                                                  <path
                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                    transform="translate(-102.409 -0.004)" />
                                                </g>
                                              </g>
                                            </g>
                                          </svg>
                                          <svg (click)="onDeleteSkill(product.partyId,product.skillTypeId)"
                                            xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16"
                                            viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>
                                        </td>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <p class="paginate_data">
                                    View per page

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==11">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Content List
                            </h4>
                            <span class="edit-buttons"> 
                              <!-- <a (click)="createAddPartyContent()">Create New Content</a></span> -->
                              <a  data-toggle="modal" data-target="#contentPopup" 
                              (click)="reset()">Create New Content</a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <div class="card own-account-table">
                                  <p-table [value]="PartyContentData" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                    (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            Content Id <p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Content Name <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                          <div style="color: white;"> Content Type <p-sortIcon field="category">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>

                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                          <div style="color: white;"> Party Content Type
                                            <p-sortIcon field="quantity"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>

                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                          <div style="color: white;"> Status <p-sortIcon field="price">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                          <div style="color: white;"> From Date <p-sortIcon field="price">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                          <div style="color: white;"> Action <p-sortIcon field="quantity"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>

                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}" >
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            {{product.data.contentId}}
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.data.contentName}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.data.contentTypeId}}</td>

                                        <td [ngStyle]="{'width':'170px'}">{{product.data.partyContentTypeId}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.data.statusId}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>

                                        <td [ngStyle]="{'width':'170px'}">
                                           <span  data-toggle="modal" data-target="#contentPopup" 
                                           (click)="updateContent(product)" >Edit</span>

                                           <!-- <span  (click)="editContent(product.data.contentId,product.data.partyContentTypeId,product.data.contentTypeId,product.fromDate,product.thruDate,product.data.statusId,product.data.dataResourceId)">Edit</span> -->
                                         
                                          <svg
                                            (click)="onDelete(product.data.contentId,product.fromDate,product.data.partyContentTypeId,product.data.partyId)"
                                            xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16"
                                            viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>

                                        </td>

                                      </tr>
                                    </ng-template>
                                  </p-table>

                                  <p class="paginate_data">
                                    View per page </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==12">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Segments
                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createAddPartySegmentRole()">Create New Segments</a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <div class="card own-account-table">
                                  <p-table [value]="SegmentRole" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                    (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>


                                            Segment Group ID<p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Roles <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Action <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-activity>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            {{activity.segmentGroupIdDescription}}-
                                            {{activity.segmentGroupId.segmentGroupId}}
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.segmentGroupId.roleTypeId}}</td>

                                        <td [ngStyle]="{'width':'170px'}">


                                          <svg xmlns="http://www.w3.org/2000/svg" (click)="deletePartySegment(activity)"
                                            class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>
                                        </td>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <p class="paginate_data">
                                    View per page

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==13">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Resumes
                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createAddPartyResumes()">Create New Resumes</a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <div class="card own-account-table">
                                  <p-table [value]="PartyResume" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                    (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>


                                            Resume ID<p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Party ID <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Content ID <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Resume Date <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Resume Text <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Action <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-activity>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            {{activity.data.resumeId}}
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.data.partyId}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.data.contentId}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.data.resumeDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.data.resumeText}} </td>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <svg class="hover"
                                            (click)="updateResume(activity.data.resumeId,activity.data.contentId,activity.data.resumeDate,activity.data.resumeText)"
                                            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            viewBox="0 0 21.223 21.222">
                                            <g transform="translate(0 -0.004)">
                                              <g transform="translate(0 1.52)">
                                                <path
                                                  d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                  transform="translate(0 -34.137)" />
                                              </g>
                                              <g transform="translate(4.548 0.004)">
                                                <g transform="translate(0 0)">
                                                  <path
                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                    transform="translate(-102.409 -0.004)" />
                                                </g>
                                              </g>
                                            </g>
                                          </svg>


                                          <svg xmlns="http://www.w3.org/2000/svg" (click)="deletePartyResume(activity)"
                                            class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>
                                        </td>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <p class="paginate_data">
                                    View per page

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==3">
                          <div style="width: 49%; float:left">
                            <div class="w3-card-4 classCardView" *ngIf="this.partyGroup;">
                              <div class="header-tabs">

                                <h4 class=" common-styling hCard" style="margin-left: 7px;">
                                  Party Group Information
                                </h4>
                                <!-- <span style="margin-right: 2%;" class="edit-buttons hCreate"
                                  (click)="updatePartyInfo()"  data-toggle="modal" 
                                  data-target="#updateModalCenter">Update</span> -->
                                  <span style="margin-right: 2%;" class="edit-buttons hCreate"
                                  (click)="updatePartyInfo()">Update</span>
                              </div>
                              <div class="panel-body">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                  <div class="color-black container">
                                    <div *ngIf="PartyGroup" class="row">
                                      <div class="article-container">
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Personal Image</p>
                                          <span class="color-grey  col-lg-10 col-12"></span>
                                        </div>
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Party ID</p>
                                          <span class="color-grey  col-lg-10 col-12" *ngIf="PartyGroup.partyGroupInformation.partyId">{{PartyGroup.partyGroupInformation.partyId}}</span>
                                        </div>
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Group Name	</p>
                                          <span class="color-grey  col-lg-10 col-12">{{PartyGroup.partyGroupInformation.groupName}}
                                           </span>

                                        </div>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">Group Name Local</p>
                                          <span class="color-grey  col-lg-10 col-12">{{PartyGroup.partyGroupInformation.groupNameLocal}}</span>
                                        </div>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">Office Site Name</p>
                                          <span class="color-grey  col-lg-10 col-12">{{PartyGroup.partyGroupInformation.officeSiteName}}</span>
                                        </div>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">Annual revenue</p>
                                          <span class="color-grey  col-lg-10 col-12">{{PartyGroup.partyGroupInformation.annualRevenue}}</span>
                                        </div>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">Number of employees</p>
                                          <span
                                            class="color-grey  col-lg-10 col-12">{{PartyGroup.partyGroupInformation.numEmployees}}</span>
                                        </div>
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Ticker Symbol</p>
                                          <span
                                            class="color-grey  col-lg-10 col-12">{{PartyGroup.partyGroupInformation.tickerSymbol}}</span>
                                        </div>
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Description</p>
                                          <span
                                            class="color-grey  col-lg-10 col-12">{{PartyGroup.status.description}}</span>
                                        </div>
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Currency</p>
                                          <span
                                            class="color-grey  col-lg-10 col-12">{{PartyGroup.currency}}</span>
                                        </div>
                                        <div class="article">
                                          <p class="col-lg-6 col-12">External Id</p>
                                          <span
                                            class="color-grey  col-lg-10 col-12">{{PartyGroup.partyInformation.externalId}}</span>
                                        </div>
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Status Id</p>
                                          <span
                                            class="color-grey  col-lg-10 col-12">{{PartyGroup.status.description}}</span>
                                        </div>
                                        <div class="article">
                                          <p class="col-lg-6 col-12">First Name</p>
                                          <span
                                            class="color-grey  col-lg-10 col-12">{{PartyGroup.partyGroupInformation.firstName}}</span>
                                        </div>
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Last Name</p>
                                          <span
                                            class="color-grey  col-lg-10 col-12">{{PartyGroup.partyGroupInformation.lastName}}</span>
                                        </div>
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Title</p>
                                          <span
                                            class="color-grey  col-lg-10 col-12">{{PartyGroup.partyGroupInformation.title}}</span>
                                        </div>




                                      </div>


                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div class="w3-card-4 classCardView" *ngIf="!this.partyGroup;">
                              <div class="header-tabs">

                                <h4 class=" common-styling hCard" style="margin-left: 7px;">
                                  Personal Information  
                                </h4>
                                <span style="margin-right: 2%;" class="edit-buttons hCreate"
                                  (click)="updatePerson()">Update</span>
                              </div>
                              <div class="panel-body">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                  <div class="color-black container">
                                    <div *ngIf="Person" class="row">
                                      <div class="article-container">
                                      
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Party ID</p>
                                          <span class="color-grey  col-lg-10 col-12" *ngIf="Person.personInformation.partyId">{{Person.personInformation.partyId}}
                                           </span>

                                        </div>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">Name</p>
                                          <span class="color-grey  col-lg-10 col-12">{{Person.partyGroupInformation.firstName}} {{Person.partyGroupInformation.middleName}} {{Person.partyGroupInformation.lastName}}</span>
                                        </div>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">External ID</p>
                                          <span class="color-grey  col-lg-10 col-12">{{Person.personInformation.externalId}}</span>
                                        </div>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">Status ID</p>
                                          <span class="color-grey  col-lg-10 col-12">{{Person.statusId.description}}</span>
                                        </div>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">Comments</p>
                                          <span
                                            class="color-grey  col-lg-10 col-12">{{Person.partyGroupInformation.comments}}</span>
                                        </div>

                                      





                                      </div>


                                    </div>
                                 
                                  </div>
                                </div>
                              </div>

                            </div>
                            <br>
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;" *ngIf="!this.partyGroup;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    List Related Account
                                  </h4>
                                  <span style="margin-right: 2%;" class="edit-buttons"
                                    data-toggle="modal" data-target="#accModalCenter"
                                    (click)="createAddRelatedAccount();">Create New Account</span>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group" style="width: 100%!important;">
                                      <div class="card own-account-table">
                                        <p-table [value]="relatedContactInfo" [paginator]="true" [rows]="rows"
                                          scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                          [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                          [customSort]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                  From Party ID <p-sortIcon field="code"></p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                  name="j_idt727:j_idt728:j_idt731:filter"
                                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                  value="" autocomplete="off"
                                                  aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                  role="textbox" aria-disabled="false" aria-readonly="false">
                                              </th>

                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                <div style="color: white;"> Type <p-sortIcon field="quantity">
                                                  </p-sortIcon>
                                                </div>
                                                <div>
                                                  <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                    value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                </div>
                                              </th>

                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                <div style="color: white;"> Comments <p-sortIcon field="price">
                                                  </p-sortIcon>
                                                </div>
                                                <div>
                                                  <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                    value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                </div>
                                              </th>

                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'170px'}">
                                                <div class="checkbox-align">
                                                  <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                  {{product.partyIdFrom}}
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'170px'}">{{product.partyRelationshipName}}</td>
                                              <td [ngStyle]="{'width':'170px'}">{{product.comments}}</td>


                                            </tr>
                                          </ng-template>
                                        </p-table>

                                        <p class="paginate_data">
                                          View per page </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;" *ngIf="this.partyGroup;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    List Related Contacts
                                  </h4>
                                  <span style="margin-right: 2%;" class="edit-buttons"
                                    (click)="createAddRelatedAccount();">Create New Contacts</span>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group" style="width: 100%!important;">
                                      <div class="card own-account-table">
                                        <p-table [value]="relatedContact" [paginator]="true" [rows]="rows"
                                          scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                          [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                          [customSort]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                  From Party ID <p-sortIcon field="code"></p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                  name="j_idt727:j_idt728:j_idt731:filter"
                                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                  value="" autocomplete="off"
                                                  aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                  role="textbox" aria-disabled="false" aria-readonly="false">
                                              </th>

                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                <div style="color: white;"> Type <p-sortIcon field="quantity">
                                                  </p-sortIcon>
                                                </div>
                                                <div>
                                                  <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                    value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                </div>
                                              </th>

                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                <div style="color: white;"> Comments <p-sortIcon field="price">
                                                  </p-sortIcon>
                                                </div>
                                                <div>
                                                  <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                    value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                </div>
                                              </th>

                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'170px'}">
                                                <div class="checkbox-align">
                                                  <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                  {{product.partyIdTo}}
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'170px'}">{{product.partyRelationshipName}}</td>
                                              <td [ngStyle]="{'width':'170px'}">{{product.comments}}</td>


                                            </tr>
                                          </ng-template>
                                        </p-table>

                                        <p class="paginate_data">
                                          View per page </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                            <br>
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Contact Information
                                  </h4>
                                  <div class="d-flex flex-row" style="margin-right: 2%;">
                                    <!-- <span class="edit-buttons" routerLink="/crm/accounts/create-contact"
                                      [queryParams]="{partyId:partyId}">Create Telecom</span>
                                    <span class="edit-buttons ml-2"
                                      routerLink="/crm/accounts/create-postal-address"
                                      [queryParams]="{partyId:partyId}">Create
                                      Address</span> -->
                                      <span class="edit-buttons" (click)="openTelecomModal(partyId)">Create Telecom</span>
                                      <span class="edit-buttons ml-2" (click)="openAddrModal(partyId)" >Create Address</span>
                                  </div>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group">
                                      <div class="card own-account-table">
                                        <p-table [value]="contactInformation" [paginator]="false"  scrollHeight="150px"  [rows]="rows"
                                          scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                          [totalRecords]="total" [scrollable]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'100px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                  Contact Type <p-sortIcon field="code"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'139px'}" style="text-align: center!important;"
                                                pSortableColumn="name">
                                                <div style="color: white;">Contact Information<p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'120px'}" pSortableColumn="name">
                                                <div style="color: white;">Soliciting OK<p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'115px'}" pSortableColumn="name">
                                                <div style="color: white;">Action<p-sortIcon field="name"></p-sortIcon>
                                                </div>
                                              </th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'100px'}" *ngIf="product.contactMechType.description">{{product.contactMechType.description}}
                                              </td>
                                              <td [ngStyle]="{'width':'139px'}">
                                                <div class="checkbox-align">

                                                  <ng-container
                                                    *ngIf="product.contactMech.contactMechTypeId==='POSTAL_ADDRESS'">
                                                    {{product.postalAddress.address1}} ,
                                                    {{product.postalAddress.address2}},<br>{{product.postalAddress.stateProvinceGeoId}}
                                                    ,
                                                    {{product.postalAddress.city}},<br>{{product.postalAddress.postalCode}},{{product.postalAddress.countryGeoId}}
                                                  </ng-container>
                                                  <ng-container
                                                    *ngIf="product.contactMech.contactMechTypeId==='EMAIL_ADDRESS'">
                                                    {{product.contactMech.infoString}}
                                                  </ng-container>
                                                  <ng-container
                                                    *ngIf="product.contactMech.contactMechTypeId==='TELECOM_NUMBER'">
                                                    Area Code: {{product.telecomNumber.areaCode}}, Contact Number:
                                                    {{product.telecomNumber.contactNumber}}, Country Code:
                                                    {{product.telecomNumber.countryCode}}
                                                  </ng-container>
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'120px'}" style="text-align: center!important;">
                                                {{product.partyContactMech.allowSolicitation
                                                ?product.partyContactMech.allowSolicitation :'N' }}
                                              </td>
                                              <td [ngStyle]="{'width':'115px'}">
                                                <button type="submit"  class="btn btn-secondary submit-btn ml-2"
                                                (click)="updateData(product.contactMech.contactMechId ,product.contactMech.contactMechTypeId)">Update</button>
   
                                                


                                              </td>

                                            </tr>
                                          </ng-template>
                                        </p-table>
                                        <!-- <p class="paginate_data">
                                          View per page </p> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br>
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Loyalty Points
                                  </h4>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group">
                                      <div class="card own-account-table"
                                        style="width: -3px!important;    margin-left: 5%!important;">
                                    <span *ngIf="this.PartyLoyaltyPoints" style="color: #383139 !important;">
                                      You have {{this.PartyLoyaltyPoints.totalSubRemainingAmount}} points from {{this.PartyLoyaltyPoints.totalOrders}} order(s) in the last {{this.PartyLoyaltyPoints.monthsToInclude}} Months.
                                    </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                           
                            <br>
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Identification Numbers
                                  </h4>
                                  <div class="d-flex flex-row" style="margin-right: 2%;">
                                    <span class="edit-buttons"
                                      data-toggle="modal" data-target="#identificationModalCenter"
                                      (click)="createIdentificationNumber();">Create New Identification Numbers</span>
                                  </div>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select classUser">
                                    <div class="form-group" style="width: 100%!important;">
                                      <div class="card own-account-table">
                                        <p-table [value]="IdentificationList" [paginator]="false"  scrollHeight="150px"  [rows]="rows"
                                          scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                          [totalRecords]="total" [scrollable]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}"
                                                pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                  Identification Type <p-sortIcon field="code"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}"
                                                pSortableColumn="name">
                                                <div style="color: white;">ID Value<p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'170px'}"
                                                pSortableColumn="name">
                                                <div style="color: white;">Action<p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>
                                              </th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'170px'}">
                                                {{product.description}}
                                              </td>
                                              <td [ngStyle]="{'width':'170px'}">
                                                {{product.identificationData.idValue}}
                                              </td>

                                              <td [ngStyle]="{'width':'170px'}">

                                                <button type="submit"  class="btn btn-secondary submit-btn ml-2"
                                                (click)="updatePartyIdentification(product.identificationData.partyIdentificationTypeId,product.identificationData.idValue)">>Update</button>
                                                
                                                
                                                
                                                  <button type="submit" class="btn btn-danger  ml-2"
                                                  (click)="deletePartyIdentification(product.identificationData.partyIdentificationTypeId)">Delete</button>

                                               
                                             

                                              </td>

                                            </tr>
                                          </ng-template>
                                        </p-table>
                                        <!-- <p class="paginate_data">
                                          View per page </p> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br>
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Stores
                                  </h4>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group">
                                      <div class="card own-account-table">
                                        <p-table [value]="ProductStore" [paginator]="false"  scrollHeight="150px"  [rows]="rows" scrollWidth="100%"
      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                            <ng-template pTemplate="header">
                                                <tr style="background: #0d3769;">
                                                    <th [ngStyle]="{'width':'150px'}"
                                                        pSortableColumn="code">
                                                        <div class="checkbox-align"
                                                            style="color: white;">
                                                           
                                                            <p-checkbox _ngcontent-egj-c329=""
                                                            styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div
                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined"></div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span
                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                            Store Name [ID]<p-sortIcon
                                                                field="code">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input
                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox"
                                                            aria-disabled="false"
                                                            aria-readonly="false">
                                                    </th>
                                                    <th [ngStyle]="{'width':'150px'}"
                                                        pSortableColumn="name">
                                                        <div style="color: white;">
                                                          Role Type
                                                            <p-sortIcon
                                                                field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input
                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox"
                                                            aria-disabled="false"
                                                            aria-readonly="false">
                                                    </th>
                                                  
                                                  
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body"
                                                let-product>
                                                <tr>
                                                    <td
                                                        [ngStyle]="{'width':'150px'}">
                                                        <div class="checkbox-align">
                                                          <p-checkbox _ngcontent-egj-c329=""
                                                          styleclass="custom-checkbox" binary="true"
                                                          class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                          <div
                                                              class="custom-checkbox ui-chkbox ui-widget">
                                                              <div class="ui-helper-hidden-accessible">
                                                                  <input type="checkbox" name="undefined"
                                                                      value="undefined"></div>
                                                              <div role="checkbox"
                                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                  <span
                                                                      class="ui-chkbox-icon ui-clickable"></span>
                                                              </div>
                                                          </div>
                                                          <!---->
                                                      </p-checkbox>
                                                            <span
                                                                class="account-button">{{product.productStore.storeName}} {{product.productStore.productStoreId}}</span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        [ngStyle]="{'width':'150px'}">
                                                        {{product.roleType.roleTypeId}}
                                                    </td>
                                                  
                                                </tr>
                                            </ng-template>
                                            <!-- <ng-template pTemplate="emptymessage"
                                                let-columns>
                                                <tr>
                                                    <td>
                                                        <span class="text-center">
                                                            <h4 class="ml-2">No
                                                                Record Found
                                                            </h4>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template> -->
                                        </p-table>
<!-- 
                                        <p class="paginate_data">
                                            View per page </p> -->
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                            <br>
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Visits
                                  </h4>
                                  <span style="margin-right: 2%;" class="edit-buttons"
                                    (click)="listAll()">List All</span>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group" style="width: 100%!important;">
                                      <div class="card own-account-table">
                                        <p-table [value]="Visitors" [paginator]="false" [rows]="rows"
                                          scrollWidth="100%"  scrollHeight="150px" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                          [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                          [customSort]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox> 
                                                  Visit ID <p-sortIcon field="code"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                <div style="color: white;"> User Login
                                                  <p-sortIcon field="price"></p-sortIcon>

                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                <div style="color: white;"> user Impersonated<p-sortIcon
                                                    field="quantity"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;"> New User <p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>

                                              </th>

                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                <div style="color: white;"> Web App


                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                <div style="color: white;"> Client IP


                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                <div style="color: white;"> From Date


                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                <div style="color: white;"> Through Date


                                                </div>
                                              </th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'170px'}">
                                                <div class="checkbox-align">
                                                  {{product.visitorData.visitId}}
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.userLoginId}}</td>
                                              <td [ngStyle]="{'width':'170px'}"> {{product.userImpersonated}}</td>
                                              <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.userCreated}}
                                              </td>
                                              <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.webappName}}</td>
                                              <td [ngStyle]="{'width':'170px'}"> {{product.clientIpAddress}}</td>
                                              <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                              </td>
                                              <td [ngStyle]="{'width':'170px'}"> {{product.visitorData.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                              </td>



                                            </tr>
                                          </ng-template>
                                        </p-table>
                                        <!-- <p class="paginate_data">
                                          View per page </p> -->
                                      </div>
                                    </div>
                                  </div>



                                </div>
                              </div>
                            </div>
                            <br>
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important; width: 200% !important;
      margin-left: 0% !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Payment Method
                                  </h4>
                                  <div class="d-flex flex-row">
                                    <!-- <span class="edit-buttons ml-2"
                                      routerLink="/crm/accounts/create-credit-card"
                                      [queryParams]="{partyId:taxAuthPartyId}">Create Credit Card</span> 
                                      data-toggle="modal" data-target="#creditModalCenter"
                                    -->
                                      <span class="edit-buttons ml-2"
                                      (click)="openCreditModal(taxAuthPartyId)">Create Credit Card</span>
                                    <!-- <span class="edit-buttons ml-2"
                                      routerLink="/crm/accounts/create-gift-card"
                                      [queryParams]="{partyId:taxAuthPartyId}">Create Gift Card</span> -->
                                      <span class="edit-buttons ml-2"
                                      (click)="openGiftModal(taxAuthPartyId)">Create Gift Card</span>
                                    <!-- <span class="edit-buttons ml-2"
                                      routerLink="/crm/accounts/create-eft-account"
                                      [queryParams]="{partyId:taxAuthPartyId,isEftAccount:true}">Create EFT Account</span> -->
                                      <span class="edit-buttons ml-2"
                                      (click)="openEftModal(taxAuthPartyId)">Create EFT Card</span>
                                    <!-- <span class="edit-buttons ml-2"
                                      routerLink="/crm/accounts/create-check-account"
                                      [queryParams]="{partyId:taxAuthPartyId}">Add
                                      Check Account</span> -->
                                      <span class="edit-buttons ml-2"
                                      (click)="openCheckModal(taxAuthPartyId)">Add Check Account</span>
                                    <!-- <span class="edit-buttons ml-2"
                                      routerLink="/crm/accounts/create-billing-account"
                                      [queryParams]="{partyId:taxAuthPartyId}">Create
                                      Billing Account</span> -->
                                      <span class="edit-buttons ml-2"
                                      (click)="openBillModal()">Create Billing Card</span>
                                  </div>
                                </div>
                              </header>
  
                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;" >
                                  <div class="card own-account-table" *ngIf="(paymentMethods | json) != '{}'"
                                    style="max-width: 1056px!important; margin-left: 4%;">
                                    <p-table [value]="paymentMethods" [paginator]="false"  scrollHeight="150px"  [rows]="rows"
                                      scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                      [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                      <ng-template pTemplate="body" let-product>
                                        <tr>
                                          <td>{{product.type}}</td>
                                          <td>
                                            <ng-container
                                              *ngIf="product.paymentMethodTypeId.includes('ACCOUNT') || product.paymentMethodTypeId.includes('CHECK')">
                                              <span>{{product.nameOnAccount}},{{product.bankName}}({{product.branchCode}}),A/C
                                                {{product.accountNumber}}, (Type:
                                                {{product.accountType ? product.accountType :''}}), (Updated At:
                                                {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                            </ng-container>
                                            <ng-container *ngIf="product.paymentMethodTypeId==='BILLING'">
                                              <span>({{product.billingAccountId}}),(Account
                                                Limit:${{product.accountLimit}}),(Available Balance:
                                                ${{product.accountBalance}}), (Updated At:
                                                {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                            </ng-container>
                                            <ng-container *ngIf="product.paymentMethodTypeId.includes('CARD')">
                                              <span>{{product.cardNumber ? product.cardNumber
                                                :''}}</span>, Expire :
                                              <span>{{product.expireDate? product.expireDate:''}}</span>,
                                              <span> {{product.companyNameOnCard? product.companyNameOnCard:''}}</span>,
                                              <span> ,( {{product.firstNameOnCard ? product.firstNameOnCard:''}}
                                                {{product.middleNameOnCard ? product.middleNameOnCard:''}}
                                                {{product.lastNameOnCard ? product.lastNameOnCard : ''}}),(
                                                {{product.cardType}}), (Updated At:
                                                {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                            </ng-container>
                                          </td>
                                          <td>
                                            <button type="submit"  class="btn btn-secondary submit-btn ml-2"
                                            (click)="paymentMethodNavigation(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)">Update</button>   
                                            
                                              <button type="submit" class="btn btn-danger  ml-2"
                                              (click)="removePaymentMethod(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)">Delete</button>
                                            
                                            
                                          </td>
                                        </tr>
                                      </ng-template>
                                    </p-table>
                                    <!-- <p class="paginate_data">
                                      View per page </p> -->
                                  </div>
                                </div>
                              </div>
  
  
                            </div>
                            <br> <br>  <br>  <br>  <br>
                          </div>
                          <div style="width: 49.8%; float:right">
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                                 border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    User name(s)
                                  </h4>
                                  <div class="d-flex flex-row" style="margin-right: 2%;">
                                    <!-- <span class="edit-buttons" (click)="createUserLogin();"
                                    data-toggle="modal" data-target="#userNameModalCenter">Create</span> -->
                                    <span class="edit-buttons" (click)="createUserLogin();"
                                    >Create New User name</span>

                                  </div>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select classUser">
                                    <div class="form-group">
                                      <div class="card own-account-table">
                                        <p-table [value]="UserLoginData" [paginator]="false"  scrollHeight="150px"  [rows]="rows"
                                          scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                          [totalRecords]="total" [scrollable]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'100px'}"
                                                pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                  User Login <p-sortIcon field="code"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'139px'}"
                                                style="text-align: center!important;" pSortableColumn="name">
                                                <div style="color: white;">Status<p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>

                                              </th>

                                              <th [ngStyle]="{'width':'170px'}" [ngStyle]="{'width':'115px'}"
                                                pSortableColumn="name">
                                                <div style="color: white;">Action<p-sortIcon field="name"></p-sortIcon>
                                                </div>
                                              </th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>

                                              <td [ngStyle]="{'width':'139px'}">
                                                <div class="checkbox-align">
                                                  <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                  {{product.data.userLoginId}}
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'120px'}" style="text-align: center!important;">
                                                {{product.partyStatusId.statusId}}

                                              </td>
                                              <td [ngStyle]="{'width':'115px'}">
                                                <button type="submit"  class="btn btn-secondary submit-btn ml-2"
                                                (click)="updatePassword(product.data.userLoginId)">Update</button>
                                                
                                                
                                                
                                                  <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                  (click)="securityList(product.data.userLoginId)">Security Group</button>


                                               

                                              </td>

                                            </tr>
                                          </ng-template>
                                        </p-table>
                                        <!-- <p class="paginate_data">
                                          View per page </p> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br>
                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                            border-color: #0d3769 !important;">
                             <header class="w3-container w3-blue">
                               <div class="header-tabs">
                                 <h4 class=" common-styling" style="margin-left: 7px;">
                                   Party Attributes
                                 </h4>
                                 <span style="margin-right: 2%;" class="edit-buttons"
                                 data-toggle="modal" data-target="#profilePartyAttribute" >Create</span>
                               </div>
                             </header>

                             <div class="w3-container">
                               <div class="panel-body" style="    background: white;">
                                 <div style="padding: 15px 5px!important;"
                                   class="all-users-infomation font-13 font-medium own-user-select">



                                   <div class="form-group" style="width: 100%!important;">
                                     <div class="card own-account-table">
                                       <p-table [value]="PartyAttribute"  [paginator]="false" [rows]="rows"
                                        scrollHeight="100px" scrollWidth="100%" 
                                         [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                         [customSort]="true">
                                         <ng-template pTemplate="header">
                                           <tr style="background: #0d3769;">
                                             <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'100px'}"
                                               pSortableColumn="code">
                                               <div class="checkbox-align" style="color: white;">
                                                 <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                   binary="true"
                                                   class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                   <div class="custom-checkbox ui-chkbox ui-widget">
                                                     <div class="ui-helper-hidden-accessible">
                                                       <input type="checkbox" name="undefined" value="undefined">
                                                     </div>
                                                     <div role="checkbox"
                                                       class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                       <span class="ui-chkbox-icon ui-clickable"></span>
                                                     </div>
                                                   </div>
                                                   <!---->
                                                 </p-checkbox>
                                                 Name <p-sortIcon field="code"></p-sortIcon>
                                               </div>

                                             </th>
                                             <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'100px'}"
                                               pSortableColumn="price">
                                               <div style="color: white;"> Value
                                                 <p-sortIcon field="price"></p-sortIcon>

                                               </div>
                                             </th>
                                             <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'120px'}"
                                               pSortableColumn="quantity">
                                               <div style="color: white;"> Action<p-sortIcon field="quantity">
                                                 </p-sortIcon>
                                               </div>
                                           </tr>
                                         </ng-template>
                                         <ng-template pTemplate="body" let-product>
                                           <tr>
                                             <td [ngStyle]="{'width':'100px'}">
                                               <div class="checkbox-align">
                                                 {{product.attrName}}
                                               </div>
                                             </td>
                                             <td [ngStyle]="{'width':'100px'}"> {{product.attrValue}}</td>

                                             <td [ngStyle]="{'width':'100px'}">
                                               <svg class="hover"  data-toggle="modal" data-target="#profilePartyAttribute" 
                                               (click)="updateAttributes(product)" 
                                                 xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 viewBox="0 0 21.223 21.222">
                                                 <g transform="translate(0 -0.004)">
                                                   <g transform="translate(0 1.52)">
                                                     <path
                                                       d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                       transform="translate(0 -34.137)" />
                                                   </g>
                                                   <g transform="translate(4.548 0.004)">
                                                     <g transform="translate(0 0)">
                                                       <path
                                                         d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                         transform="translate(-102.409 -0.004)" />
                                                     </g>
                                                   </g>
                                                 </g>
                                               </svg>
                                          <!--      <svg xmlns="http://www.w3.org/2000/svg"
                                                 (click)="deletePartyAttribute(product)" class="ml-20" width="16"
                                                 height="16" viewBox="0 0 26 26">
                                                 <defs>
                                                   <style>
                                                     .a {
                                                       fill: #f44336;
                                                     }

                                                     .b {
                                                       fill: #fafafa;
                                                       margin-left: 20px;
                                                     }
                                                   </style>
                                                 </defs>
                                                 <path class="a fill-color"
                                                   d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                 <path class="b"
                                                   d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                   transform="translate(-147.576 -147.576)" />
                                               </svg> -->
                                             </td>

                                           </tr>
                                         </ng-template>
                                       </p-table>
                                      
                                     </div>
                                   </div>
                                 </div>



                               </div>
                             </div>
                           </div>
                          
                            <br>
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    AVS Override (CC Fraud Screening)
                                  </h4>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group">
                                      <div class="card own-account-table" *ngIf="PartyIcsAvsOverRide.length"
                                        style="width: -3px!important;    margin-left: 5%!important;">
                                        <div  *ngFor = "let title of PartyIcsAvsOverRide" style="width: 153%;"
                                        class="all-users-infomation font-13 font-medium own-user-select">
                                        
                                          <div class="col-lg-4 col-12">
                                              <div class="form-group">
                                                  <label for="exampleInputEmail1">
                                                    AVS String</label>
                                                      
                                                   
                                              </div>
                                            
                                           
                                          </div>
                                          <div class="col-lg-4 col-12" >
                                            <div class="form-group" >
                                              <label for="exampleInputEmail1">
                                                  {{title.avsDeclineStringList.avsDeclineString}}</label>
                                                  
                                                
                                          </div>
                                          
                                        </div>
                                      
                                        
                                          <div class="col-lg-4 col-12" >
                                              <div class="form-group" >
                                                <button type="submit"  (click)="updateAVS();"  class="btn btn-secondary submit-btn">Edit</button>
                                 
                                                <button type="submit"  (click)="resetAvsOverride();"  class="btn btn-secondary submit-btn">Reset</button>
                                                
                                              </div>
                                            
                                          </div>
                                       
                                        
                                      </div>
                                      </div>
                                      <div class="card own-account-table" *ngIf="!PartyIcsAvsOverRide.length"
                                        style="width: -3px!important;    margin-left: 5%!important;">
                                        <div   style="width: 153%;"
                                        class="all-users-infomation font-13 font-medium own-user-select">
                                        
                                          <div class="col-lg-4 col-12">
                                              <div class="form-group">
                                                  <label for="exampleInputEmail1">
                                                    AVS String</label>
                                                      
                                                   
                                              </div>
                                            
                                           
                                          </div>
                                          <div class="col-lg-4 col-12" >
                                            <div class="form-group" >
                                              <label for="exampleInputEmail1">
                                                 Global</label>
                                                  
                                                
                                          </div>
                                          
                                        </div>
                                      
                                        
                                          <div class="col-lg-4 col-12" >
                                              <div class="form-group" >
                                                <button type="submit"  (click)="updateAVS();"  class="btn btn-secondary submit-btn">Edit</button>
                                 
                                               
                                              </div>
                                            
                                          </div>
                                       
                                        
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                            <br>
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                                  border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Notes
                                  </h4>
                                  <span style="margin-right: 2%;"
                                  class="edit-buttons"
                               (click)="resetnote()"   data-toggle="modal"
                                  data-target="#exampleModalFive">Create New Notes</span>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 8px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">



                                    <div class="form-group" style="width: 100%!important;">
                                      <div class="card own-account-table">
                                        <p-table [value]="accountData" [paginator]="false" [rows]="rows"
                                        scrollHeight="100px"
                                          scrollWidth="100%" 
                                          [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                          [customSort]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                    binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                        <input type="checkbox" name="undefined" value="undefined">
                                                      </div>
                                                      <div role="checkbox"
                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                    </div>
                                                    <!---->
                                                  </p-checkbox>
                                                  Note Name <p-sortIcon field="code"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                <div style="color: white;"> Note
                                                  <p-sortIcon field="price"></p-sortIcon>

                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'250px'}" pSortableColumn="quantity">
                                                <div style="color: white;"> By<p-sortIcon field="quantity"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                <div style="color: white;"> Created At <p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>

                                              </th>

                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                <div style="color: white;"> Action


                                                </div>
                                              </th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'200px'}">
                                                <div class="checkbox-align pl-5">
                                                  {{product.noteName}}
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'150px'}">{{product.noteInfo}}</td>
                                              <td [ngStyle]="{'width':'250px'}">{{product.firstName}}
                                                {{product.middleName}}
                                                {{product.lastName}}</td>
                                              <td [ngStyle]="{'width':'200px'}">{{product.createdStamp |
                                                date:'yyyy-MM-dd'}}
                                              </td>


                                              <td [ngStyle]="{'width':'200px'}">
                                               
                                                <td
                                                [ngStyle]="{'width':'200px'}">
                                                <button type="submit"   data-toggle="modal"
                                                data-target="#exampleModalFive" (click)="updateNote(product)" 
                                                class="btn btn-secondary submit-btn"> Update</button>

                                                <button type="submit"  (click)="onDeleteNotes(product.noteId)" class="btn btn-danger"> Delete</button>

                                             
                                            </td>

                                            </tr>
                                          </ng-template>
                                        </p-table>
                                       
                                      </div>
                                    </div>



                                  </div>
                                </div>
                              </div>



                            </div>
                          
                            <br>
                            <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Party Content
                                  </h4>
                                  <span style="margin-right: 2%;" class="edit-buttons"
                                    (click)="createAttachContent();">Create New Party Content</span>
                                </div>
                              </header>
                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;" class="card own-account-table">
                                    <p-table [value]="contentList" [paginator]="false"  scrollHeight="150px" [rows]="rows" scrollWidth="100%"
                                      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                      (sortFunction)="customSort($event)" [customSort]="true">
                                      <ng-template pTemplate="header">
                                        <tr style="background: #0d3769;">
                                          <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                            <div class="checkbox-align" style="color: white;">
                                              <p-checkbox _ngcontent-egj-c329=""
                                              styleclass="custom-checkbox" binary="true"
                                              class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div
                                                  class="custom-checkbox ui-chkbox ui-widget">
                                                  <div class="ui-helper-hidden-accessible">
                                                      <input type="checkbox" name="undefined"
                                                          value="undefined"></div>
                                                  <div role="checkbox"
                                                      class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                      <span
                                                          class="ui-chkbox-icon ui-clickable"></span>
                                                  </div>
                                              </div>
                                              <!---->
                                          </p-checkbox>
                                              Content Id <p-sortIcon field="code"></p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>
                                          <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                            <div style="color: white;"> Content Name <p-sortIcon field="name">
                                              </p-sortIcon>
                                            </div>
                                            <div>
                                              <input id="j_idt727:j_idt728:j_idt731:filter"
                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                value="" autocomplete="off"
                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                aria-disabled="false" aria-readonly="false">
                                            </div>
                                          </th>
                                          <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                            <div style="color: white;"> Content Type <p-sortIcon field="category">
                                              </p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>

                                          <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                            <div style="color: white;"> Party Content Type
                                              <p-sortIcon field="quantity"></p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>

                                          <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                            <div style="color: white;"> Status <p-sortIcon field="price">
                                              </p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>
                                          <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                            <div style="color: white;"> From Date <p-sortIcon field="price">
                                              </p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>
                                          <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                            <div style="color: white;"> Action <p-sortIcon field="quantity">
                                              </p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>

                                        </tr>
                                      </ng-template>
                                      <ng-template pTemplate="body" let-product>
                                        <tr>
                                          <td [ngStyle]="{'width':'170px'}" >
                                            <div class="checkbox-align">
                                              <p-checkbox _ngcontent-egj-c329=""
                                              styleclass="custom-checkbox" binary="true"
                                              class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div
                                                  class="custom-checkbox ui-chkbox ui-widget">
                                                  <div class="ui-helper-hidden-accessible">
                                                      <input type="checkbox" name="undefined"
                                                          value="undefined"></div>
                                                  <div role="checkbox"
                                                      class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                      <span
                                                          class="ui-chkbox-icon ui-clickable"></span>
                                                  </div>
                                              </div>
                                              <!---->
                                          </p-checkbox>
                                              {{product.contentId}}
                                            </div>
                                          </td>
                                          <td [ngStyle]="{'width':'170px'}">{{product.contentName}}</td>
                                          <td [ngStyle]="{'width':'170px'}">{{product.contentType}}</td>

                                          <td [ngStyle]="{'width':'170px'}">{{product.partyContentTypeId}}</td>
                                          <td [ngStyle]="{'width':'170px'}">{{product.status}}</td>
                                          <td [ngStyle]="{'width':'170px'}">{{product.fromDate | date:'medium'}}</td>

                                          <td [ngStyle]="{'width':'170px'}">
                                            <button type="submit"  class="btn btn-secondary submit-btn ml-2"
                                            (click)="export(product.contentId,product.contentName)">Update</button>
                                            
                                            
                                            
                                              <button type="submit" class="btn btn-danger  ml-2"
                                              (click)="onDelete(product.contentId,product.fromDate,product.partyContentTypeId,product.partyId)">Delete</button>


                                          </td>

                                        </tr>
                                      </ng-template>
                                    </p-table>

                                    <!-- <p class="paginate_data">
                                      View per page </p> -->
                                  </div>


                                </div>
                              </div>


                            </div>
                            <br>
                       
                  
                          </div>
                         
                        </div>
                        <div *ngIf="activeCategory==14">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Classification
                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createAddPartyClassification()">Create New Classification</a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <div class="card own-account-table">
                                  <p-table [value]="PartyClassification" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                          
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>


                                            Party ID<p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Classification Group ID <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> From Date <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;">Through Date <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>

                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Action <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-activity>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            {{activity.partyRateData.partyId}}
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}">
                                          {{activity.partyRateData.partyClassificationGroupId}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.fromDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.thruDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>

                                        <td [ngStyle]="{'width':'170px'}">
                                          <button type="submit"  class="btn btn-secondary submit-btn ml-2"
                                          (click)="updatePartyClassification(activity.partyRateData.partyClassificationGroupId,activity.fromDate,activity.thruDate)">Update</button>   
                                          
                                            <button type="submit" class="btn btn-danger  ml-2"
                                            (click)="deletePartyClassification(activity)">Delete</button>
                                          
                                        
                                        </td>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <p class="paginate_data">
                                    View per page

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==15">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Contact List

                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createPartyContact()">Create New Contact</a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group" style="width: 100%!important">
                                <div class="card own-account-table">
                                  <p-table [value]="PartyContactList" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                          
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>


                                            Contact Lists<p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> From Date <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;">Status History <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;">Through Date <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>

                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Status ID <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Opt-in Code <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Preferred Contact Mech ID <p-sortIcon
                                              field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                          <div style="color: white;"> Action <p-sortIcon field="name"></p-sortIcon>
                                          </div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-activity>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            {{activity.contactListName.contactListId}}
                                          </div>
                                        </td>

                                        <td [ngStyle]="{'width':'170px'}"> {{activity.data.fromDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.data.thruDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.data.statusId}} </td>
                                        <td [ngStyle]="{'width':'170px'}"> </td>
                                        <td [ngStyle]="{'width':'170px'}"> {{activity.data.preferredContactMechId}}
                                        </td>

                                        <td [ngStyle]="{'width':'170px'}">
                                          <button type="submit"  class="btn btn-secondary submit-btn ml-2"
                                          (click)="updateContactList(activity)">Update</button>   
   
                                       <!--    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteContactList(activity)"
                                            class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg> -->
                                        </td>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <span class="text-center">
                                            <h4 class="ml-2">No Record Found
                                            </h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <p class="paginate_data">
                                    View per page

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==16">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Employment Applications


                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createEmployeeAppilcation()">Create New Employment Applications </a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group" style="width: 100%!important">

                                <div class="card own-account-table">
                                  <p-table id="table" [value]="employmentApplication" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>

                                            Application ID <p-sortIcon field="code">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                          <div style="color: white;"> Empl Position ID <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                          <div style="color: white;">Status ID<p-sortIcon field="category"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'250px'}" pSortableColumn="quantity">
                                          <div style="color: white;">Employment App Source Type ID
                                            <p-sortIcon field="quantity">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                          <div style="color: white;">Applying Party ID <p-sortIcon field="quantity">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                          <div style="color: white;"> Referred By Party ID<p-sortIcon field="price">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                          <div style="color: white;"> Application Date<p-sortIcon field="price">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                          <div style="color: white;"> Action</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td [ngStyle]="{'width':'150px'}" >
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            <span  >
                                              {{product.applicationId}}</span>

                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">{{product.emplPositionId}}
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">{{product.statusId}}</td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.employmentAppSourceTypeId}}</td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.applyingPartyId}}</td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.referredByPartyId}}</td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.applicationDate | date :'yyyy-MM-dd hh:mm:ss'}}
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          <div>
                                            <button type="submit"  class="btn btn-secondary submit-btn ml-2"
                                            (click)="onUpdate(product.applicationId)">Update</button>   
                                            
                                              <button type="submit" class="btn btn-danger  ml-2"
                                              (click)="onDeleteEmp(product.applicationId)">Delete</button>
                                            
                                      
                                          </div>
                                        </td>

                                      </tr>
                                    </ng-template>
                                  </p-table>

                                  <p class="paginate_data">
                                    View per page </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==17">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Product Store Roles


                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createProductStore()">Create New Product Store Roles </a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                             <!--  <form [formGroup]="findPartyForm">
                                <div
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Store ID</label>
                                                    <p-dropdown [options]="productStoresListArray"
                                                    filter="true" placeholder="Select Store ID"
                                                    formControlName="StoreID">
                                                </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-lg-2"></div>
                                            <div class="col-lg-2">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">RoleType ID</label>
                                                    <p-dropdown [options]="allroleArray"
                                                        filter="true"  placeholder="Select Role Type ID"
                                                        formControlName="RoleTypeID">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">From Date</label>
                                                    <input type="date" name="externalId"
                                                        class="form-control"
                                                        formControlName="FromDate"
                                                        id="externalId"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter From Date">
                                                </div>
                                            </div>
                                            <div class="col-lg-2"></div>
                                            <div class="col-lg-2">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">	Through Date</label>
                                                    <input type="date" name="lastName"
                                                        class="form-control"
                                                        formControlName="ThroughDate"
                                                        id="lastName"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Last Name">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Sequence Num</label>
                                                    <input type="text" name="firstName"
                                                        class="form-control"
                                                        formControlName="SequenceNum"
                                                        id="firstName"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Sequence Num">
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                   
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button ">
                                    <button _ngcontent-cta-c128="" type="submit"
                                        class="btn btn-secondary submit-btn"
                                        (click)="onSubmit()">Find</button>&nbsp;&nbsp;
                                    <button type="button" (click)="reset();"
                                        class="btn btn-danger ml-2">Reset</button>&nbsp;
                                </div>
                            </form> -->
                              <div class="form-group" style="width: 100%!important">

                                <div class="card own-account-table">
                                  <p-table id="table" [value]="AllProductStoreRole" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>

                                            
Store ID<p-sortIcon field="code">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                          <div style="color: white;">RoleType ID <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                          <div style="color: white;">	From Date<p-sortIcon field="category"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                          <div style="color: white;">	Through Date
                                            <p-sortIcon field="quantity">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                          <div style="color: white;">	Sequence Num <p-sortIcon field="quantity">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                     
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                          <div style="color: white;"> Action</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td [ngStyle]="{'width':'150px'}" >
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            <span  class="account-button"  
                                             >
                                              {{product.data.productStoreId}}</span>

                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">{{product.data.roleTypeId}}
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">{{product.data.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.data.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.data.sequenceNum}}</td>
                                        
                                        <td [ngStyle]="{'width':'150px'}">
                                          <div>
                                            <button type="submit"  class="btn btn-secondary submit-btn ml-2"
                                            (click)="onUpdateProductStore(product)">Update</button>   
                                            
                                              <button type="submit" class="btn btn-danger  ml-2"
                                              (click)="deleteStoreRole(product)">Delete</button>
                                            
                                            
                                       
                                          </div>
                                        </td>

                                      </tr>
                                    </ng-template>
                                  </p-table>

                                  <p class="paginate_data">
                                    View per page </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==19">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Invoices not yet (fully) applied
                            </h4>
                           

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <tr>
                                  <td [ngStyle]="{'width':'170px'}">
                                    <span class="text-center"  style="color: #65787e;">
                                      <h4 class="ml-2">No Record Found
                                      </h4>
                                    </span>
                                  </td>
                                </tr>
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Payments not yet (fully) applied
                            </h4>
                           

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <tr>
                                  <td [ngStyle]="{'width':'170px'}">
                                    <span class="text-center"  style="color: #65787e;">
                                      <h4 class="ml-2">No Record Found
                                      </h4>
                                    </span>
                                  </td>
                                </tr>
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Totals for Company: 0
                            </h4>
                           

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <div class="article-container">
                                  <div class="article">
                                    <p class="col-lg-6 col-12" style="color: #65787e;">Total Sales Invoice</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12">$0.00</span>
                                  </div>
                                  <div class="article">
                                    <p class="col-lg-6 col-12" style="color: #65787e;">Total Payments In</p>
                                    <span class="color-grey pl-20 col-lg-10 col-12"> $0.00</span>
                                  </div>
                                  <div class="article">
                                    <p class="col-lg-6 col-12" style="color: #65787e;">Total Invoice Not Applied</p>
                                    <span
                                      class="color-grey pl-20 col-lg-10 col-12">$0.00</span>
  
                                  </div>
  
                                  <div class="article">
                                    <p class="col-lg-6 col-12" style="color: #65787e;">To be received from: {{this.taxAuthPartyId}}</p>
                                    <span
                                      class="color-grey pl-20 col-lg-10 col-12">$0.00</span>
                                  </div>
                                  <div class="article">
                                    <p class="col-lg-6 col-12" style="color: #65787e;">Total Purchase Invoice</p>
                                    <span
                                      class="color-grey pl-20 col-lg-10 col-12">$0.00</span>
  
                                  </div>
  
                                  <div class="article">
                                    <p class="col-lg-6 col-12" style="color: #65787e;">Total Payments Out</p>
                                    <span
                                      class="color-grey pl-20 col-lg-10 col-12">$0.00</span>
                                  </div>
                                  <div class="article">
                                    <p class="col-lg-6 col-12" style="color: #65787e;">Total Payment Not Applied</p>
                                    <span
                                      class="color-grey pl-20 col-lg-10 col-12">$0.00</span>
                                  </div>
  
                               
  
                               
  
                                 
  
  
  
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Billing Account
                            </h4>
                           

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <tr>
                                  <td [ngStyle]="{'width':'170px'}">
                                    <span class="text-center"  style="color: #65787e;">
                                      <h4 class="ml-2">No Record Found
                                      </h4>
                                    </span>
                                  </td>
                                </tr>
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Returns
                            </h4>
                           

                          </div>
                          <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="form-group">
                              <div class="card own-account-table">
                                <p-table [value]="AllFinanceHistory" [paginator]="true" [rows]="rows"
                                  scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                  [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                  <ng-template pTemplate="header">
                                    <tr style="background: #0d3769;">
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                        <div class="checkbox-align" style="color: white;">
                                        
                                          <p-checkbox _ngcontent-egj-c329=""
                                          styleclass="custom-checkbox" binary="true"
                                          class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                          <div
                                              class="custom-checkbox ui-chkbox ui-widget">
                                              <div class="ui-helper-hidden-accessible">
                                                  <input type="checkbox" name="undefined"
                                                      value="undefined"></div>
                                              <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                  <span
                                                      class="ui-chkbox-icon ui-clickable"></span>
                                              </div>
                                          </div>
                                          <!---->
                                      </p-checkbox>


                                          
Return ID<p-sortIcon field="code"></p-sortIcon>
                                        </div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                        <div style="color: white;"> Status ID <p-sortIcon field="name">
                                          </p-sortIcon>
                                        </div>
                                        <div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </div>
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                        <div style="color: white;"> 	From Party ID <p-sortIcon field="name">
                                          </p-sortIcon>
                                        </div>
                                        <div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </div>
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                        <div style="color: white;">	To Party ID <p-sortIcon field="name">
                                          </p-sortIcon>
                                        </div>
                                        <div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </div>
                                      </th>

                                     
                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-activity>
                                    <tr>
                                      <td [ngStyle]="{'width':'170px'}">
                                        <div class="checkbox-align">
                                          <p-checkbox _ngcontent-egj-c329=""
                                          styleclass="custom-checkbox" binary="true"
                                          class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                          <div
                                              class="custom-checkbox ui-chkbox ui-widget">
                                              <div class="ui-helper-hidden-accessible">
                                                  <input type="checkbox" name="undefined"
                                                      value="undefined"></div>
                                              <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                  <span
                                                      class="ui-chkbox-icon ui-clickable"></span>
                                              </div>
                                          </div>
                                          <!---->
                                      </p-checkbox>
                                          {{activity.data.returnId}}
                                        </div>
                                      </td>
                                      <td [ngStyle]="{'width':'170px'}">
                                        {{activity.data.statusId}} </td>
                                      <td [ngStyle]="{'width':'170px'}"> {{activity.data.fromPartyId}} </td>
                                      <td [ngStyle]="{'width':'170px'}"> {{activity.data.toPartyId}} </td>

                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage" let-columns>
                                    <tr>
                                      <td [ngStyle]="{'width':'170px'}">
                                        <span class="text-center">
                                          <h4 class="ml-2">No Record Found
                                          </h4>
                                        </span>
                                      </td>
                                    </tr>
                                  </ng-template>
                                </p-table>
                                <p class="paginate_data">
                                  View per page

                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==20">

                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Shopping Lists


                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createPartyEmptyShopping()">Create New Shopping</a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                            <form [formGroup]="shoppingList">
                                <div
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-8 col-12" >
                                      <div class="form-group" >
                                        <p-dropdown [options]="ShoppingListArray"
                                        filter="true" placeholder="Select Shopping List"
                                        formControlName="shoppingList">
                                    </p-dropdown>
                                      </div>
                                    
                                  </div>
                                  <div class="col-lg-4 col-12" >
                                      <div class="form-group" >
                                        <button _ngcontent-cta-c128="" type="submit"
                                        class="btn btn-secondary submit-btn"
                                        (click)="editAddShoping()">Edit</button>&nbsp;&nbsp;
                                      </div>
                                    
                                  </div>
                                  
                             
                                </div>
                              
                            </form> 
                            <br>
                          
                             
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==18">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Tax Authority Info
                            </h4>
                            <span class="edit-buttons">
                              <a (click)="createTaxInfo()">Create New Tax Authority Info </a></span>

                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                          
                              <div class="form-group" style="width: 100%!important">

                                <div class="card own-account-table">
                                  <p-table id="table" [value]="TaxInfo" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                           
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            
                                            Tax Auth Geo ID<p-sortIcon field="code">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                          <div style="color: white;">	Tax Authority Party <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                          <div style="color: white;">		From Date Time<p-sortIcon field="category"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                          <div style="color: white;">	Through Date
                                            <p-sortIcon field="quantity">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                          <div style="color: white;">	Party Tax ID <p-sortIcon field="quantity">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                          <div style="color: white;">  Is Exempt </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                          <div style="color: white;"> Is Nexus </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                          <div style="color: white;"> Action</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td [ngStyle]="{'width':'150px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            <span  class="account-button"  
                                             >
                                              {{product.getTaxInfo.taxAuthGeoId}}</span>

                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">{{product.getTaxInfo.taxAuthPartyId}}
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">{{product.getTaxInfo.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.getTaxInfo.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          {{product.getTaxInfo.partyTaxId}}</td>
                                          <td [ngStyle]="{'width':'150px'}">
                                            {{product.getTaxInfo.isExempt}}</td>
                                            <td [ngStyle]="{'width':'150px'}">
                                              {{product.getTaxInfo.isNexus}}</td>
                                        
                                        <td [ngStyle]="{'width':'150px'}">
                                          <div>
                                            <button type="submit"  class="btn btn-secondary submit-btn ml-2"
                                            (click)="updateTaxInfo(product)">Update</button>   
                                            
                                              <button type="submit" class="btn btn-danger  ml-2"
                                              (click)="deleteTaxInfo(product)">Delete</button>
                                          </div>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>

                                  <p class="paginate_data">
                                    View per page </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </form>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="main-submit-button">
                      <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
                  </div> -->
    </div>
  </div>
</div>


<div class="modal fade" id="contentPopup" tabindex="-1" role="dialog" aria-labelledby="contentPopup" aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content cssModelLength">
          <div class="modal-header">
              <span *ngIf="!this.show" class="color-black pl-1">Add Content

              </span>
              <span *ngIf="this.show" class="color-black pl-1">Update Content

              </span>
              <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" #closebutton1>&times;</span>
              </button>
          </div>
          <div class="modal-body d-flex">
              <div class=" bg blackcolor-grey w-100">
                  <div class="w3-card-4 classCardPopUp">
                      <div class="container-fluid">
                          <ul class="tabbing-section tabbing-accordians tabClass">
                              <li *ngIf="!this.show">
                                  <a>Add Content

                                  </a>
                              </li>
                              <li *ngIf="this.show">
                                  <a>Update Content

                                  </a>
                              </li>
                          </ul>
                      </div>
                    
                      <div class="create-new-leade mt-2">
                          <div class="container-fluid">
                              <div class="row">
                                <form  class="w-100" [formGroup]="Editform">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-12" *ngIf="show">
                                          <div class="row">
          
                                              <div class="col-lg-4 form-group classInput">
                                                  <label for="exampleInputEmail1">
                                                    Content Id</label>
                                              </div>
                                           
                                              <div class="col-lg-3">
                                                <label>{{this.contentId}}</label>
                                              </div>
                                            
          
                                             
          
                                          </div>
                                      </div>
                                      <div class="col-lg-12">
                                          <div class="row">
          
                                              <div class="col-lg-4 form-group classInput">
                                                  <label for="exampleInputEmail1">
                                                     Parent Content Type ID</label>
                                              </div>
                                           
                                              <div class="col-lg-3">
                                                  <p-dropdown *ngIf="!show"  filter="true" formControlName="partyContentTypeId"
                                                 
                                                  [options]="partyContentArray" placeholder="Parent Content Type ID"
                                                  optionlabel="label">
                                                  </p-dropdown>
                                                  <input type="text" *ngIf="this.show"   class="form-control" id="exampleInputEmail1"
                                                  aria-describedby="emailHelp"   placeholder="Enter Parent ContentTypeId"
                                                  formControlName="partyContentTypeId" readonly>
                                              </div>
                                              <div class="col-lg-2 form-group classInputA">
                                                  <label for="exampleInputEmail1">
                                                      Content Type ID</label>
                                              </div>
                                           
                                              <div class="col-lg-3">
                                                  <p-dropdown filter="true"  
                                                  placeholder="Enter Content Type ID"
                                                  name="Parent Type ID"
                                                  formControlName="ContentTypeID"
                                                  [options]="PartyContentTypeIdListArray" optionlabel="label">
                                              </p-dropdown>
                                           
                                              </div>
          
                                             
          
                                          </div>
                                      </div>
                                      <div class="col-lg-12">
                                          <div class="row">
          
                                              <div class="col-lg-4 form-group classInput">
                                                  <label for="exampleInputEmail1">
                                                      From Date</label>
                                              </div>
                                           
                                              <div class="col-lg-3">
                                                  <input type="date"  *ngIf="!this.show"    class="form-control" id="exampleInputEmail1"
                                                  aria-describedby="emailHelp" placeholder="Enter From Date"
                                                  formControlName="fromDate"> 
                                                  <input type="date" *ngIf="this.show"   class="form-control" id="exampleInputEmail1"
                                                  aria-describedby="emailHelp"   placeholder="Enter Parent ContentTypeId"
                                                  formControlName="fromDate" readonly>
                                              </div>
                                              <div class="col-lg-2 form-group classInputA">
                                                  <label for="exampleInputEmail1">
                                                      Through</label>
                                              </div>
                                           
                                              <div class="col-lg-3">
                                                  <input type="date"    class="form-control" id="exampleInputEmail1"
                                                  aria-describedby="emailHelp" placeholder="Enter Through Date"
                                                  formControlName="throughDate">
                                           
                                              </div>
          
                                             
          
                                          </div>
                                      </div>
                                      <div class="col-lg-12">
                                          <div class="row">
          
                                              <div class="col-lg-4 form-group classInput">
                                                  <label for="exampleInputEmail1">
                                                      Status ID</label>
                                              </div>
                                           
                                              <div class="col-lg-3">
                                                  <p-dropdown  filter="true" formControlName="StatusID"
                                                
                                                  [options]="PartyStatusIdListArray" placeholder="Status ID"
                                                  optionlabel="label">
                                                  </p-dropdown>
                                              </div>
                                              <div class="col-lg-2 form-group classInputA">
                                                  <label for="exampleInputEmail1">
                                                      Data Category ID</label>
                                              </div>
                                           
                                              <div class="col-lg-3">
                                                  <p-dropdown filter="true"  
                                                  placeholder="Enter Data Category ID"
                                                  name="Parent Type ID"
                                                  formControlName="DataCategoryID"
                                                  [options]="DataCategoryIdListArray" optionlabel="label">
                                              </p-dropdown>
                                           
                                              </div>
          
                                             
          
                                          </div>
                                      </div>
                                      <div class="col-lg-12">
                                          <div class="row">
          
                                              <div class="col-lg-4 form-group classInput">
                                                  <label for="exampleInputEmail1">
                                                      Is Public</label>
                                              </div>
                                           
                                              <div class="col-lg-3">
                                                  <p-dropdown  filter="true" formControlName="IsPublic"
                                                 
                                                  [options]="isPublic" placeholder="Is Public"
                                                  optionlabel="label">
                                                  </p-dropdown>
                                              </div>
                                              <div class="col-lg-2 form-group classInputA">
                                                  <label for="exampleInputEmail1">Attach Content</label>
                                              </div>
                                           
                                              <div class="col-lg-3">
                                                  <input class="form-control" type="file" style="width: 100%!important;" id="exampleInputEmail1" formControlName="file"
                                                  aria-describedby="emailHelp"  accept="" (change)="fileProgress($event)" style="line-height: 0.9!important;">
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </form>
                              <div class="main-submit-button" style="margin-left: -5%;">
                                 
                                  <button type="submit" *ngIf="!show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                  
                                  <button type="submit" *ngIf="show" (click)="onUpdates();" class="btn btn-secondary submit-btn">Update</button>
                                  
                                  
                                  
                                
                              </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="modal fade" id="profilePartyAttribute" tabindex="-1" role="dialog" aria-labelledby="profilePartyAttributeTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content cssModelLength">
          <div class="modal-header">

              <span class="color-black pl-1">  Party Attribute</span>

              <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" #closebutton2>&times;</span>
              </button>
          </div>
          <div class="modal-body d-flex">
              <div class=" bg-white color-grey w-100">
                  <div class="w3-card-4 classCardPopUp">
                      <div class="container-fluid">
                          <ul class="tabbing-section tabbing-accordians tabClass">

                              <li [ngClass]="activeCategory==2?'active':''"><a>
                                       Party Attribute</a></li>
                          </ul>
                      </div>
                      <div class="create-new-leade ">
                          <div class="container-fluid">
                              <div class="row">
                                  <form class="w-100" [formGroup]="partyAttributes">
                                      <div class="all-users-infomation font-13 font-medium own-user-select">
                                          <div class="col-lg-12">
                                              <div class="row">

                                                  <div class="col-lg-3 form-group classInputB">
                                                      <label for="exampleInputEmail1">
                                                          Attribute name<span style="color:red">*</span></label>
                                                  </div>

                                                  <div class="col-lg-3">
                                                      <input type="text" formControlName="attrName" *ngIf="!showAttribute" 
                                                      class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr name">

                                                      <input type="text" *ngIf="showAttribute" disabled
                                                       formControlName="attrName" class="form-control" 
                                                       id="exampleInputEmail1" readonly aria-describedby="emailHelp"
                                                        placeholder="Enter Attr name">
                                                  </div>

                                                  <div class="col-lg-3 form-group classInput__new_changes">
                                                      <label for="exampleInputEmail1">
                                                          Attribute Value<span style="color:red">*</span></label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <input type="text" formControlName="attrValue" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Value">
                                                  </div>

                                              </div>
                                          </div>

                                          
                                          <div class="col-lg-12">
                                              <div class="row">

                                                  <div class="col-lg-3 form-group classInputB">
                                                      <label for="exampleInputEmail1">
                                                          Attribute Description<span
                                                              style="color:red">*</span></label>
                                                  </div>

                                                  <div class="col-lg-3">
                                                      <input type="text" formControlName="attrDescription" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Description">
                                                  </div>

                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 36%;">
                                          <button type="submit"  *ngIf="!showAttribute"(click)="onSubmitPartyAttribute();" class="btn btn-secondary submit-btn">Save</button>
                                          <button type="submit" *ngIf="showAttribute" (click)="onUpdatePartyAttribute();" class="btn btn-secondary submit-btn">Update</button>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


      </div>
  </div>
</div>

<div class="modal fade" id="exampleModalFive" tabindex="-1" role="dialog" aria-labelledby="exampleModalFiveTitle"
aria-hidden="true">
<div class="modal-dialog" role="document">
    <div class="modal-content cssModelLength">
        <div class="modal-header">
            <span *ngIf="!showUpdateNoteBtn" class="color-black pl-1"> Create Note</span>
            <span *ngIf="showUpdateNoteBtn" class="color-black pl-1"> Update Note</span>

            <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton7>&times;</span>
            </button>

        </div>
        <div class="modal-body d-flex">
            <div class=" bg-white color-grey" style="width: 100%;">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategoryB==1?'active':''"
                                (click)="changeactiveCategoryFunctionValue(1)"><a>
                                    New Party Note</a></li>
                        </ul>
                    </div>
                    <div class="panel-group create-new-leade ">

                        <div *ngIf="activeCategoryB==1">

                            <div class="panel-body">
                                <form [formGroup]="fileUploadFormNote">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm"
                                                    >
                                                    <label for="exampleInputEmail1">Note Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"
                                                        formControlName="noteName" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter Note Name">
                                                </div>

                                                <div class="col-lg-3 form-group classInputA"
                                                   >
                                                    <label for="exampleInputEmail1">Note</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"
                                                    formControlName="note" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Note">
                                                   
                                                </div>


                                            </div>
                                        </div>


                                    </div>

                                    <div class="proper" style="margin-right: 18%;">
                                        <div class="col-lg-12 col-12 main-submit-button">
                                            <button type="submit" (click)="onSubmitNote()" *ngIf="!showUpdateNoteBtn"
                                                class="btn btn-secondary submit-btn">Create</button>
                                          
                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateNoteBtn">
                                            <button type="submit" (click)="updateContactNote()"
                                                class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>



<app-create-party-attribute *ngIf="showAttrModal" (closeAttrModal)="closeAttrModal()"></app-create-party-attribute>
<app-new-party-note *ngIf="showNoteModal" (closeNoteModal)="closeNoteModal()"></app-new-party-note>
<!-- <app-attach-content *ngIf="showContentModal" (closeContentModal)="closeContentModal()"></app-attach-content> -->
<!-- <app-create-billing-account *ngIf="showBillModal" (closeBillModal)="closeBillModal()"></app-create-billing-account> -->