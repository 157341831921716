<div class="container-fluid main-container-wrapper">
    <br>

    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Job Requisition</span>

               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>

            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategoryMain==1?'active':''" (click)="JobRequisition()"><a>
                            Job Requisition</a></li>
                        <li [ngClass]="activeCategoryMain==2?'active':''" (click)="InternalJobPostingApplication()"><a>
                            Internal Job Posting Application</a></li>



                    </ul>
                </div>
                <div class=" bg-white color-grey" style="padding: 15px 15px;">

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4  w-100">
                                        <div class="accordion classCard" id="myAccordion">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                        <span class="headingAccords">Find Job Requisition</span>
                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2"  data-toggle="modal" data-target="#exampleModalCenter">Create New Job Requisition</button>

                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 58%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                        </button>	
                                                    </div>
                
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                    <div class="card-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Job Requisition ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="rIdArray" [(ngModel)]="advanceSearch.jobRequisitionId" name="jobRequisitionId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Requisition ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2"></div>
                
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Experience in months</label>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown [(ngModel)]="advanceSearch.experienceMonthSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="experienceMonthSearchType" [options]="dateIdArray" optionlabel="label" placeholder="Months">
                
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input style="width: 100%;" type="text" [(ngModel)]="advanceSearch.experienceMonths" name="experienceMonths" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Months" class="pl-2">
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input style="width: 100%;" type="text" [(ngModel)]="advanceSearch.experienceMonthSecond" name="experienceMonthSecond" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Months" class="pl-2">
                                                                    </div>
                
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12" style="display: none;">
                                                                <div class="row">
                                                                    <div class="col-lg-2 form-group classInput">
                                                                        <label for="exampleInputEmail1"></label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [(ngModel)]="advanceSearch.experienceMonthSecondSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="experienceMonthSecondSearchType" [options]="dateIdArray" optionlabel="label">
                
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2"></div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" [(ngModel)]="advanceSearch.experienceMonthSecond" name="experienceMonthSecond" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Months" class="pl-2">
                                                                    </div>
                
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Experience in years</label>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown [(ngModel)]="advanceSearch.experienceYearsSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="experienceYearsSearchType" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" style="width: 100%;" [(ngModel)]="advanceSearch.experienceYears" name="experienceYears" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Years" class="pl-2">
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" style="width: 100%;" [(ngModel)]="advanceSearch.experienceYearSecond" name="experienceYearSecond" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Years" class="pl-2">
                                                                    </div>
                
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12" style="display: none;">
                                                                <div class="row">
                                                                    <div class="col-lg-2 form-group classInput">
                                                                        <label for="exampleInputEmail1"></label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [(ngModel)]="advanceSearch.experienceYearSecondSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="experienceYearSecondSearchType" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2"></div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" [(ngModel)]="advanceSearch.experienceYearSecond" name="experienceYearSecond" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Years">
                                                                    </div>
                
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Job Location</label>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown [(ngModel)]="advanceSearch.jobLocationSearchType" name="jobLocationSearchType" [ngModelOptions]="{standlone:true}" filter="true" [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.jobLocation" name="jobLocation" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Job Location">
                                                                    </div>
                
                
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Skill Type ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="skillTypeArray" [(ngModel)]="advanceSearch.skillTypeId" name="skillTypeId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Skill Type ID">
                
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Job Posting Type</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="Gender" [(ngModel)]="advanceSearch.jobPostingTypeEnumId" name="jobPostingTypeEnumId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Job Posting Type">
                
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Exam Type Enum ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="enumArray" [(ngModel)]="advanceSearch.examTypeEnumId" name="examTypeEnumId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Exam Type Enum ID">
                
                                                                        </p-dropdown>
                                                                    </div>
                
                
                
                                                                </div>
                                                            </div>
                
                
                                                        </div>
    
                                                        <div class="col-lg-12 col-12 main-submit-button" style="
                                                            margin-left: -26%;">
                                                            <button _ngcontent-cta-c128="" (click)="advanceSearchContacts(false)" type="submit" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                        </div>
    
    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>


                                    <!-- <div class="w3-card-4 classCard">
                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead 
                                        font-medium mb-0 suppliers-wrapper">
                                            <div class="suppliers-wrapper">
                                                <p class="h-text">Find Job Requisition</p>
                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" style="margin-left:175px" data-toggle="modal" data-target="#exampleModalCenter">Create New Job Requisition</button>
                                                
                                            </div>
                                        </div>
                                        <br>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Job Requisition ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="rIdArray" [(ngModel)]="advanceSearch.jobRequisitionId" name="jobRequisitionId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Requisition ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Experience in months</label>
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">
                                                        <p-dropdown [(ngModel)]="advanceSearch.experienceMonthSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="experienceMonthSearchType" [options]="dateIdArray" optionlabel="label" placeholder="Months">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input style="width: 100%;" type="text" [(ngModel)]="advanceSearch.experienceMonths" name="experienceMonths" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Months" class="pl-2">
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input style="width: 100%;" type="text" [(ngModel)]="advanceSearch.experienceMonthSecond" name="experienceMonthSecond" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Months" class="pl-2">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12" style="display: none;">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1"></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [(ngModel)]="advanceSearch.experienceMonthSecondSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="experienceMonthSecondSearchType" [options]="dateIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" [(ngModel)]="advanceSearch.experienceMonthSecond" name="experienceMonthSecond" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Months" class="pl-2">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Experience in years</label>
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">
                                                        <p-dropdown [(ngModel)]="advanceSearch.experienceYearsSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="experienceYearsSearchType" [options]="dateIdArray" optionlabel="label" checked>

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" style="width: 100%;" [(ngModel)]="advanceSearch.experienceYears" name="experienceYears" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Years" class="pl-2">
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" style="width: 100%;" [(ngModel)]="advanceSearch.experienceYearSecond" name="experienceYearSecond" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Years" class="pl-2">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12" style="display: none;">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1"></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [(ngModel)]="advanceSearch.experienceYearSecondSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="experienceYearSecondSearchType" [options]="dateIdArray" optionlabel="label" checked>

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" [(ngModel)]="advanceSearch.experienceYearSecond" name="experienceYearSecond" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Years">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Job Location</label>
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">
                                                        <p-dropdown [(ngModel)]="advanceSearch.jobLocationSearchType" name="jobLocationSearchType" [ngModelOptions]="{standlone:true}" filter="true" [options]="opportunityIdArray" optionlabel="label" checked>

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.jobLocation" name="jobLocation" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Job Location">
                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Skill Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="skillTypeArray" [(ngModel)]="advanceSearch.skillTypeId" name="skillTypeId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Skill Type ID">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Job Posting Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="Gender" [(ngModel)]="advanceSearch.jobPostingTypeEnumId" name="jobPostingTypeEnumId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Job Posting Type">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Exam Type Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="enumArray" [(ngModel)]="advanceSearch.examTypeEnumId" name="examTypeEnumId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Exam Type Enum ID">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button" style="
                                            margin-left: -26%;">
                                            <button _ngcontent-cta-c128="" (click)="advanceSearchContacts(false)" type="submit" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                        </div>
                                    </div> -->


                                    <br>
                                    <div class="w3-card-4 classCard">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Job Requisition List
                                                </h4>

                                            </div>
                                        </header>
                                        <div>

                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="jobRequestion" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Job Requisition ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Skill Type ID
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Job Posting Type
                                                                                <p-sortIcon field="category"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                            <div style="color: white;"> Exam Type Enum ID
                                                                                <p-sortIcon field="quantity"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Qualification
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Job Location
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Experience in years
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Experience in months
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Created At
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Apply
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Action</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span class="account-button"
                                                                                (click)="detailPage(product.jobRequisitionId)">
                                                                        {{product.jobRequisitionId}}
                                                                        </span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            {{product.skillTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            {{product.jobPostingTypeEnumId}}</td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            {{product.examTypeEnumId}}</td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            {{product.qualification}}</td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            {{product.jobLocation}}</td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            {{product.experienceYears}}</td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            {{product.experienceMonths}}</td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            {{product.createdStamp| date :'yyyy-MM-dd HH:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                            <button _ngcontent-cta-c128="" (click)="navigate(product.jobRequisitionId)"
                                            type="submit" class="btn btn-secondary submit-btn">Apply</button>&nbsp;
                                                                            
                     
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <button _ngcontent-cta-c128=""  (click)="onDelete(product.jobRequisitionId)"
                                                                            type="submit" class="btn btn-danger ">Delete</button>&nbsp;
                                                                          
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Job Requisition</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                Add New Job Requisition </a></li>


                            </ul>
                        </div>
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians">

                                <li [ngClass]="activeCategoryTab==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                Add Job Information </a></li>
                                <li [ngClass]="activeCategoryTab==3?'active':''" (click)="jobInfo(newJobRequistionService.job_requistion_form.value.jobLocation,
                                newJobRequistionService.job_requistion_form.value.noOfResources,newJobRequistionService.job_requistion_form.value.gender,
                                newJobRequistionService.job_requistion_form.value.qualification)"><a>
                                   Skills Required </a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="newJobRequistionService.job_requistion_form" [formGroup]="newJobRequistionService.job_requistion_form" class="w-100">
                                        <div *ngIf="activeCategoryTab==2">
                                            <!-- <div class="header-tabs">
                                              <h4 class=" common-styling marginLeft">
                                                Add Job Information
                                              </h4>
                                            
                    
                                            </div> -->
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Job Location <span
                                                                    style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="jobLocation" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Job Location">
                                                                <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.jobLocation.invalid && !!newJobRequistionService.job_requistion_form.controls.jobLocation.touched">Location is required!</small>



                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Age</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" (keypress)="numberOnly($event)" formControlName="age" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter age">
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">No Of Resources <span
                                                                    style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="noOfResources" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter no Of Resources">

                                                                <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.noOfResources.invalid && !!newJobRequistionService.job_requistion_form.controls.noOfResources.touched">No Of Resources is required!</small>


                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Gender <span
                                                                    style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="GenderNew" formControlName="gender" optionlabel="label" placeholder="Enter Gender">

                                                                </p-dropdown>

                                                                <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.gender.invalid && !!newJobRequistionService.job_requistion_form.controls.gender.touched">Gender is required!
                                                            </small>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Duration Months</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input style="width: 100%;" (keypress)="numberOnly($event)" formControlName="durationMonths" id="exampleInputEmail1" type="text" placeholder="Duration (In Months)">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Qualification<span
                                                                    style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="qualArray" formControlName="qualification" optionlabel="label" placeholder="Enter Qualification">
                                                                </p-dropdown>
                                                                <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.qualification.invalid && !!newJobRequistionService.job_requistion_form.controls.qualification.touched">Qualification is required!</small>


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Exam Type Enum ID</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="enumArray" formControlName="examTypeEnumId" optionlabel="label" placeholder="Enter Exam Type Enum ID">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-2"></div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="main-submit-button" style="
                                              margin-right: 11%;">
                                                    <button type="submit" (click)="jobInfo(newJobRequistionService.job_requistion_form.value.jobLocation,
                                                  newJobRequistionService.job_requistion_form.value.noOfResources,newJobRequistionService.job_requistion_form.value.gender,
                                                  newJobRequistionService.job_requistion_form.value.qualification)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                                <br>
                                                <br>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategoryTab==3">
                                            <!-- <div class="header-tabs">
                                              <h4 class=" common-styling marginLeft">
                                                Skills Required
                                              </h4>
                                            
                    
                                            </div> -->
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Skill Type ID <span
                                                                    style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="skillTypeArray" formControlName="skillTypeId" optionlabel="label" placeholder="Enter Skill Type ID">

                                                                </p-dropdown>
                                                                <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.skillTypeId.invalid && !!newJobRequistionService.job_requistion_form.controls.skillTypeId.touched">Skill TypeId is required!</small>



                                                            </div>
                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Experience in months <span
                                                                    style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" (keypress)="numberOnly($event)" formControlName="experienceInMonths" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Experience in months">

                                                                <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.experienceInMonths.invalid && !!newJobRequistionService.job_requistion_form.controls.experienceInMonths.touched">Experience In Months is required!</small>


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Experience in years <span
                                                                    style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" (keypress)="numberOnly($event)" formControlName="experienceInYears" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Experience in years">
                                                                <small class="text-danger" *ngIf="!!newJobRequistionService.job_requistion_form.controls.experienceInYears.invalid && !!newJobRequistionService.job_requistion_form.controls.experienceInYears.touched">Experience In Year is required!</small>


                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="
                                                margin-right: 11%;">
                                                    <button type="submit" (click)="onSubmit()" *ngIf="!show" class="btn btn-secondary submit-btn">Create</button>


                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>