<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100">
                   
                    <div class="mt-3" style="margin-bottom: 10px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 9px!important;">Integration with QuickBooks Merchant Services</span>

                    </div> 
                    
                    <div class="col-lg-12 ">
                        <div class="row d-flex modals" style="margin-left: -7px !important;">
                            <p>You will have to create a Connection Ticket if you wish to process the payments 
                                you  receive out in the field with QBMS. Connection ticket will authorize ..........
                                to access your QBMS account. <a style="text-decoration: underline !important;">Click here</a> to read more about QBMS.
                            </p>
                        </div>
                    </div>

                    <div class="row " style="width: 100%; margin-top: 2%; margin-bottom: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color: black; font-weight: bold !important;">
                                        QBMS Connection Ticket </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12">
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex  modals" style="margin-left: 0px; margin-right: 0px;">
                                                            <div class="" style="margin-left: -14px !important;">
                                                                <p>Click on the button below to create a Connection Ticket. Clicking on the button will 
                                                                    navigate you to QBMS website. Please follow the prompts and complete the process.

                                                                </p>

                                                            </div>  

                                                            
                                                                                                                 

                                                            

                                                        </div>

                                                    </div>

                                                    <div>
                                                        <div class="col-lg-12">
                                                            <div class="row d-flex modals mt-2" >
                                                                <p>Once you have completed process with QBMS website, you can come back to this page and hit 
                                                                    refresh button to your browser to verify that you have sucessfully created the 
                                                                    Connection Ticket. You will notice that Connect to QBMS has changed to 'Cancel QBMS' as you
                                                                    have Connection Ticket in place.
                                                                    
                                                                </p>
                                                            </div>

                                                        </div>  
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row  mt-5 mb-3" style="margin-left: -17px !important;">
                                                            <button class="btn btn-secondary submit-btn">Connect to QBMS</button>
                                                        </div>
                                                    </div>

                                                   
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>
                    


                    <div class="row " style="width: 100%; margin-bottom: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color: black; font-weight: bold !important;">
                                        AVS Settings </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12">
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mb-2 modals">
                                                            <div class="classInputA">
                                                                <label>Have you enabled Address verification (AVS) on your QBMS account?</label>

                                                            </div>  

                                                            
                                                            <div class="" style="margin-left: 30%;">
                                                                <label class="">No</label>
                                                            
                                                            
                                                            </div>  
                                                            
                                                            <div>
                                                                <a class="ml-3" href="#">Edit</a>  

                                                            </div>

                                                            

                                                        </div>

                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    

                    

            
                    

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


        

<!-- <ngx-spinner></ngx-spinner> -->







