import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { MainCatalogService } from '../main-catalog-mgmt/catalog.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';import { Location } from '@angular/common';
import { CatalogService } from '../new-product-catalog/new-product-catalog-service';
@Component({
  selector: 'app-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.css']
})
export class CatalogsComponent implements OnInit, OnDestroy {
  products3: any;
  total=0;
  @ViewChild('closebutton') closebutton;
  rowNumber=0;
  pageSize= 100;
  activeCategory=2;
  activeCat=2;
  pageNo = 1;
  rows = 50;
  search: any = '';
  getCatalogLists: any = [];
  pagination: {
    pageNo: number,
    pageSize: number
  };
  opportunityIdArray: any[];
  searchCatalogForm: FormGroup;
  isShown=false;
  catalogId: any;
 
  show= false;
  catalogArray: any = [];
  productCatalogId: any;
  catalogForm: FormGroup;
  editMode: boolean;
  catalog: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly router: Router,
    readonly mainCatalogService: MainCatalogService,
    readonly spinner: NgxSpinnerService,
    private _location: Location,
    readonly _FormBuilder: FormBuilder,
 
    readonly activatedRoute: ActivatedRoute,
    public catalogService: CatalogService,
    readonly toastr: ToastrService,
 
  ) {
    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Begins With',
        text: 'Begins With'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      }
    ];
    this.searchCatalogForm = this._FormBuilder.group({
      prodCatalogId: [''],
      prodCatalogIdSearchType: ['Contains'],
      catalogName: [''],
      catalogNameSearchType: ['Contains']
    });
    this.pagination = {
      pageNo: 1,
      pageSize: 10
    };
    this.catalogForm = this._FormBuilder.group({
      catalogName: ['', [Validators.required]],
      contentPathPrefix: [''],
      headerLogo: [''],
      prodCatalogId: [''],
      purchaseAllowPermReqd: ['N'],
      styleSheet: [''],
      templatePathPrefix: [''],
      useQuickAdd: ['Y'],
      viewAllowPermReqd: ['N']
    });
    this.catalogArray = [{
      label: 'Yes',
      value: 'Y'
    },
    {
      label: 'No',
      value: 'N'
    }];
    this.editMode = false;
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.Hidebtn=true;
    }
    this.catalogService.catalog_form = null;
    this.catalogService.catalogForm(this.catalogService.catalog_form);
    this.getCatalogList();
    this.getCatalogListForm();
    this.show = false;
    this.products3 = this.accountsService.customerDetails();
   
  }
  resetForm(){
    this.searchCatalogForm.reset();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getCatalogListForm(): void {
   
    if (this.editMode) {
      this.mainCatalogService.getCatalogById(this.productCatalogId).subscribe(res => {
        this.catalog = res.data;
       
        this.catalogForm.patchValue({
          catalogName: res.data.catalogName,
          prodCatalogId: res.data.prodCatalogId,
          styleSheet: res.data.styleSheet,
          templatePathPrefix: res.data.templatePathPrefix,
          contentPathPrefix: res.data.contentPathPrefix,
          useQuickAdd: res.data.useQuickAdd,
          viewAllowPermReqd: res.data.viewAllowPermReqd,
          purchaseAllowPermReqd: res.data.purchaseAllowPermReqd,
          headerLogo: res.data.headerLogo
        });
      });
    }
  
  }
  onSubmit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.mainCatalogService.updateCatalog({ ...this.catalog, ...this.catalogForm.value }).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getCatalogList();
        }

      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     } else {
      this.mainCatalogService.createCatalog(this.catalogForm.value).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getCatalogList();
       
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });} }
  getCatalogList(): void {
    this.spinner.show();
    this.mainCatalogService.getCatalogs(this.pagination, this.searchCatalogForm.value)
      .then(data => {
        if (data.data.length && data.success) {
          this.getCatalogLists = data.data;
          this.spinner.hide();
        }
      });
     
  }
  feautureValue(){
    this.isShown = !this.isShown;
  }
  detailPage(id: string): void {
    this.router.navigate(['/product-management/catalogs/detail-catalog-page'], { queryParams: { prodCatalogId: id } });

  }
  onUpdate(id: string): void {
    this.router.navigate(['/product-management/catalogs/detail-catalog-page'], { queryParams: { prodCatalogId: id } });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
