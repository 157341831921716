import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FeaturesService } from '../features/features.service';

@Component({
  selector: 'app-detail-feature-type',
  templateUrl: './detail-feature-type.component.html',
  styleUrls: ['./detail-feature-type.component.css']
})
export class DetailFeatureTypeComponent implements OnInit {
  id: any;
  featureType: any;
  activeCategory = 1;
  featureTypeForm: FormGroup;
  @ViewChild('closebutton') closebutton;

  featureTypeId: string;
  editMode: boolean;
  parentType: any[];
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly _NgbModal: NgbModal,
    readonly _FeaturesService: FeaturesService,
    readonly router: Router,  
    private _location: Location,

    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly activatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService

  ) { 
    this.featureTypeForm = this._FormBuilder.group({
      description: [null, [Validators.required]],
      parentTypeId: [''],
      productFeatureTypeId: ['']
    });
    this.featureTypeId = '';
    this.editMode = false;
    this.parentType = [];
  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params["id"];
    })
    if (this.id) {
      this.editMode = true;
      this._FeaturesService.getFeatureTypeById(this.id)
        .then(data => {
          this.featureType = data.data;
          this.featureTypeForm.patchValue(this.featureType);
          this.featureTypeForm.controls.productFeatureTypeId.disable();
          this.featureTypeForm.controls.productFeatureTypeId.updateValueAndValidity();
        });
    }
    this.searchFeatureType();
    this.geParentType();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  

  geParentType(): void {
    this.spinner.show();
    this._FeaturesService.getProductParentType()
      .then(data => {
        this.spinner.hide();
        this.parentType = data.data.parentTypes.map(value => {
          return {
            label: value.description,
            value: value.productFeatureTypeId
          };
        });
      });
     
  }
 
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this._FeaturesService.updateFeatureType({
        ...this.featureType, ...this.featureTypeForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.searchFeatureType();
            this.router.navigate(['/facilities/catalog/feature-type']);
            this.closebutton.nativeElement.click();
            this.featureTypeForm.reset();
            this.featureTypeForm.controls.productFeatureTypeId.enable();
            this.featureTypeForm.controls.productFeatureTypeId.updateValueAndValidity();
          
          }
        });
    } else {
      this._FeaturesService.createFeatureType(this.featureTypeForm.value)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.router.navigate(['/facilities/catalog/feature-type']);
            this.closebutton.nativeElement.click();
            this.spinner.hide();
            this.searchFeatureType();
            this.featureTypeForm.reset();
          
          }
        });
    }
    
  }
  searchFeatureType(): void {
    this.spinner.show();
    const req = {
      description: "",
      descriptionSearchType: "Contains",
      productFeatureTypeId: this.id,
      productFeatureTypeIdSearchType: "Contains"
    }
    this._FeaturesService.getFeatureType({ pageNo: 1, pageSize: 200 }, req)
      .then(data => {
        this.featureType = data.data[0];
        this.spinner.hide();
      });

  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}


