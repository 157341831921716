<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium classHead">
                <div class="submenu">
                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn stylesubmenu"
                    routerLinkActive="active" routerLink="/production/create-bom-simulation">Bom
                    Simulation</button>&nbsp;
                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn stylesubmenu"
                    routerLinkActive="active" routerLink="/production/create-product-bom">Edit Bom</button>&nbsp;
                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn stylesubmenu"
                    routerLinkActive="active" style="width: 14%!important;" routerLink="/production/manufacturing-rules">Manufacturing
                    Rules</button>&nbsp;


                </div>
            </div>
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
                <span class="color-black pl-1" > >Add New Product Bom 



 
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"  *ngIf="!this.show"  ><a>
                        Add Product Bom 

                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''"   *ngIf="this.show" ><a>
                        Update Product Bom 

                    </a></li>
                 
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createBom">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    Bom Type	 </label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown  placeholder="Enter Bom Type" filter="true"
                                                formControlName="BomTypeA"
                                                [options]="bomTypesArray" optionlabel="label" >
                                                                
                                                </p-dropdown>
                                              

                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Product ID	 </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Product ID" filter="true"
                                                formControlName="ProductIDA"
                                                [options]="productListArray" optionlabel="label" >
                                                                
                                                </p-dropdown>
                                               
                                            </div>
                                            <div class="col-lg-2">
                                                <button type="submit" (click)="showProductBOMData()"   class="btn btn-secondary submit-btn butMargin" >Show BOM</button>
                                                </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    To Product ID		 </label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown  placeholder="Enter To Product ID" filter="true"
                                                formControlName="ToProductIDA"
                                                [options]="productListArray" optionlabel="label" >
                                                                
                                                </p-dropdown>
                                              

                                            </div>
                                            <div class="col-lg-2"></div>
                                          
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    Bom Type<span style="color:red">*</span>	 </label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown  placeholder="Enter Bom Type" filter="true"
                                                formControlName="BomType"
                                                [options]="bomTypesArray" optionlabel="label" >
                                                                
                                                </p-dropdown>
                                              

                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Product ID	 </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Product ID" filter="true"
                                            formControlName="ProductID"
                                            [options]="productListArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    To Product ID	<span style="color:red">*</span>	 </label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown  placeholder="Enter To Product ID	" filter="true"
                                            formControlName="ToProductID"
                                            [options]="productListArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                              

                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    From Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Sequence Num"
                                            formControlName="FromDate" >
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    Through Date</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Routing Task Name"
                                            formControlName="ThroughDate" >

                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Sequence Num</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Sequence Num"
                                            formControlName="SequenceNum" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    Reason</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Reason"
                                            formControlName="Reason" >

                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Instruction</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Instruction"
                                                formControlName="Instruction" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    Quantity</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                formControlName="Quantity" >

                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Scrap Factor %	</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Scrap Factor %"
                                            formControlName="ScrapFactor" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"  >
                                                <label for="exampleInputEmail1">
                                                    Formula </label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown  placeholder="Enter Formula" filter="true"
                                            formControlName="Formula"
                                            [options]="formulasArray" optionlabel="label"  >
                                                            
                                            </p-dropdown>

                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Routing Task	 	
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Routing Task" filter="true"
                                            formControlName="RoutingTask"
                                            [options]="taskListArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                 
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 62%;">
                                <button type="submit" (click)="onSubmit()"   *ngIf="!this.show"  class="btn btn-secondary submit-btn">Save</button>
                                <button type="submit" (click)="onUpdate()"  *ngIf="this.show"   class="btn btn-secondary submit-btn">Update</button>
                            </div>
                           
                          
                           
                        </div>
                        <br>
                        <br>
                        <div class="w3-card-4 classCard widthSty"  >
                            <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                    <h4 class=" common-styling h4Class">
                                        Components of this Product 
                                    </h4>

                                </div>
                            </header>
                            <div
                                class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                <div class="col-lg-12 col-12">
                                    <div class="form-group">

                                        <div class="card own-account-table">
                                            <p-table [value]="assocFromProducts" [paginator]="true" [rows]="rows"
                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                [totalRecords]="total" [scrollable]="true"
                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                    <tr style="background: #0d3769;">
                                                        <th [ngStyle]="{'width':'180px'}"
                                                            pSortableColumn="code">
                                                            <div class="checkbox-align" style="color: white;">
                                                                <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>

                                                                Product ID <p-sortIcon field="code">
                                                                </p-sortIcon>
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}"
                                                            pSortableColumn="name">
                                                            <div style="color: white;"> Product Name <p-sortIcon
                                                                    field="name"></p-sortIcon>
                                                            </div>
                                                        </th>

                                                        <th [ngStyle]="{'width':'170px'}"
                                                            pSortableColumn="quantity">
                                                            <div style="color: white;"> From Date <p-sortIcon
                                                                    field="quantity"></p-sortIcon>
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}"
                                                        pSortableColumn="quantity">
                                                        <div style="color: white;">Through Date <p-sortIcon
                                                                field="quantity"></p-sortIcon>
                                                        </div>
                                                    </th>
                                                    <th [ngStyle]="{'width':'170px'}"
                                                    pSortableColumn="quantity">
                                                    <div style="color: white;"> Sequence Num <p-sortIcon
                                                            field="quantity"></p-sortIcon>
                                                    </div>
                                                </th>
                                                <th [ngStyle]="{'width':'170px'}"
                                                pSortableColumn="quantity">
                                                <div style="color: white;"> Quantity <p-sortIcon
                                                        field="quantity"></p-sortIcon>
                                                </div>
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}"
                                            pSortableColumn="quantity">
                                            <div style="color: white;"> 	Quantity Uom<p-sortIcon
                                                    field="quantity"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}"
                                        pSortableColumn="quantity">
                                        <div style="color: white;"> 	Scrap Factor % <p-sortIcon
                                                field="quantity"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th [ngStyle]="{'width':'170px'}"
                                    pSortableColumn="quantity">
                                    <div style="color: white;">Formula <p-sortIcon
                                            field="quantity"></p-sortIcon>
                                    </div>
                                </th>
                                <th [ngStyle]="{'width':'170px'}"
                                pSortableColumn="quantity">
                                <div style="color: white;"> Routng Task <p-sortIcon
                                        field="quantity"></p-sortIcon>
                                </div>
                            </th>
                            <th [ngStyle]="{'width':'170px'}"
                            pSortableColumn="quantity">
                            <div style="color: white;"> Action <p-sortIcon
                                    field="quantity"></p-sortIcon>
                            </div>
                        </th>
                  
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-product>
                                                    <tr>
                                                        <td [ngStyle]="{'width':'180px'}">
                                                            <div class="checkbox-align">
                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div
                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined"></div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                                <span
                                                                    >{{product.productIdTo}}</span>
                                                            </div>
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.productName}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.sequenceNum}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.quantity}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.scrapFactor}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.formulaDesc}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.routingWorkEffortId}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            <svg class="hover" (click)="getProductBOMDataDetailById(product.productId,product.productAssocTypeId,product.fromDate,product.productIdTo);scroll(target)"
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                               transform="translate(-102.409 -0.004)"/></g></g></g></svg>
                                                               <svg xmlns="http://www.w3.org/2000/svg"
                                                               (click)="deleteBOMData(product.fromDate,product.productId,product.productAssocTypeId,product.productIdTo)"
                                                               class="ml-20" width="16" height="16"
                                                               viewBox="0 0 26 26">
                                                               <defs>
                                                                   <style>
                                                                       .a {
                                                                           fill: #f44336;
                                                                       }
                     
                                                                       .b {
                                                                           fill: #fafafa;
                                                                           margin-left: 20px;
                                                                       }
                                                                   </style>
                                                               </defs>
                                                               <path class="a fill-color"
                                                                   d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                               <path class="b"
                                                                   d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                   transform="translate(-147.576 -147.576)" />
                                                           </svg>
                                                            </td>





                                                </ng-template>
                                            </p-table>
                                            <p class="paginate_data">
                                                View per page </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="w3-card-4 classCard"  >
                            <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                    <h4 class=" common-styling h4Class">
                                        This Product is a Component of
                                    </h4>

                                </div>
                            </header>
                            <div
                                class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                <div class="col-lg-12 col-12">
                                    <div class="form-group">

                                        <div class="card own-account-table">
                                            <p-table [value]="assocToProducts" [paginator]="true" [rows]="rows"
                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                [totalRecords]="total" [scrollable]="true"
                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                    <tr style="background: #0d3769;">
                                                        <th [ngStyle]="{'width':'180px'}"
                                                            pSortableColumn="code">
                                                            <div class="checkbox-align" style="color: white;">
                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox" binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div
                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined"
                                                                            value="undefined"></div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>

                                                                Product ID <p-sortIcon field="code">
                                                                </p-sortIcon>
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}"
                                                            pSortableColumn="name">
                                                            <div style="color: white;"> Product Name <p-sortIcon
                                                                    field="name"></p-sortIcon>
                                                            </div>
                                                        </th>

                                                        <th [ngStyle]="{'width':'170px'}"
                                                            pSortableColumn="quantity">
                                                            <div style="color: white;"> From Date <p-sortIcon
                                                                    field="quantity"></p-sortIcon>
                                                            </div>
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}"
                                                        pSortableColumn="quantity">
                                                        <div style="color: white;">Through Date <p-sortIcon
                                                                field="quantity"></p-sortIcon>
                                                        </div>
                                                    </th>
                                                  
                                                <th [ngStyle]="{'width':'170px'}"
                                                pSortableColumn="quantity">
                                                <div style="color: white;"> Quantity <p-sortIcon
                                                        field="quantity"></p-sortIcon>
                                                </div>
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}"
                                            pSortableColumn="quantity">
                                            <div style="color: white;"> Action <p-sortIcon
                                                    field="quantity"></p-sortIcon>
                                            </div>
                                        </th>
                                          
                                   
                                
                  
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-product>
                                                    <tr>
                                                        <td [ngStyle]="{'width':'180px'}">
                                                            <div class="checkbox-align">
                                                                <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                              {{product.productId}}
                                                            </div>
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.productName}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                            {{product.quantity}}
                                                        </td>
                                                        <td [ngStyle]="{'width':'170px'}">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                        (click)="deleteBOMData(product.fromDate,product.productId,product.productAssocTypeId,product.productIdTo)"
                                                        class="ml-20" width="16" height="16"
                                                        viewBox="0 0 26 26">
                                                        <defs>
                                                            <style>
                                                                .a {
                                                                    fill: #f44336;
                                                                }
              
                                                                .b {
                                                                    fill: #fafafa;
                                                                    margin-left: 20px;
                                                                }
                                                            </style>
                                                        </defs>
                                                        <path class="a fill-color"
                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                        <path class="b"
                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                            transform="translate(-147.576 -147.576)" />
                                                    </svg></td>
                                                      




                                                </ng-template>
                                            </p-table>
                                            <p class="paginate_data">
                                                View per page </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
