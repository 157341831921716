import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 2;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  TaskListData: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor( public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    private _location: Location,
    
    readonly datePipe: DatePipe,
    
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) { }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this.getTaskListData();
  }
  
  getTaskListData(){
    this.spinner.show();
   
    this.accountsService.getTaskListData().subscribe((res:any)=> {
      this.TaskListData = res.data[0].ParentProjectNameList;
      this.spinner.hide();
    
    })   
   }
   viewSummaryPage(id){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"], { queryParams: { workEffortId: id} })
  }
  TaskList(){
    this._Router.navigate(["/psa/work-effort/task-list"])
  }
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  Calendar(){
    this._Router.navigate(["/psa/work-effort/calendar"])
  }
  MyTime(){
    this._Router.navigate(["/psa/work-effort/my-time-effort"])
  }
  RequestList(){
    this._Router.navigate(["/psa/work-effort/request-list-effort"])
  }
  WorkEffort(){
    this._Router.navigate(["/psa/work-effort/work-effort-effort"])
  }
  Timesheet(){
    this._Router.navigate(["/psa/work-effort/timesheet-effort"])
  }
  SubmittedJobs(){
    this._Router.navigate(["/psa/work-effort/submitted-jobs"])
  }
  iCalendar(){
    this._Router.navigate(["/psa/work-effort/icalendar"])
  }
 
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
