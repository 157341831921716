import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentGroupService } from '../../accounting-ap/payable-payment-group/payment-group.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-payment-group',
    templateUrl: './ar-payment-group.component.html',
    styleUrls: ['./ar-payment-group.component.css']
})
export class ArPaymentGroupComponent implements OnInit {
    @ViewChild('closebutton') closebutton;
    total = 0;
    rowNumber = 0;
    isShown = false;
    activeCategory = 3;
    pageSize = 10;
    rows = 50;
    pageNo = 1;
    opportunityIdArray: any = [
        {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Contains',
            value: 'Contains'
        },
        {
            label: 'Is Empty',
            value: 'Is Empty'
        },
        {
            label: 'Begins With',
            value: 'Begins With'
        },
    ];
    searchPaymentGroupForm: FormGroup;
    paymentGroups: any[];
    faEdit = faEdit;
    faTrash = faTrash;
    agreementType: string;
    paymentGroupTypes: any[];
    activeCategorymain = 2;

    paymentType: string;
    paymentGroupForm: FormGroup;
    paymentGroupId: string;
    editMode: boolean;
    paymentGroup: any;
    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly _Router: Router,
        readonly _PaymentGroupService: PaymentGroupService,
        readonly spinner: NgxSpinnerService,
        readonly _ToastrService: ToastrService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly accountsService:AccountsService,
        private _location: Location,

    ) {
        this.searchPaymentGroupForm = this._FormBuilder.group({
            paymentGroupId: [''],
            paymentGroupIdSearchType: ['Contains'],
            paymentGroupName: [''],
            paymentGroupNameSearchType: ['Contains']
        });

        this.paymentType = '';
        this.paymentGroupId = '';
        this.paymentGroupTypes = [];
        this.paymentGroupForm = this._FormBuilder.group({
            paymentGroupName: ['', [Validators.required]],
            paymentGroupTypeId: ['CHECK_RUN']
        });
        this.editMode = false;


    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.getPaymentGroups();

        this.paymentGroupId = this._ActivatedRoute.snapshot.queryParams.paymentGroupId;
        if (this.paymentGroupId) {
            this.editMode = true;
            this._PaymentGroupService.getPayablePaymentById(this.paymentGroupId)
                .then(data => {
                    if (data) {
                        this.paymentGroup = {
                            paymentGroupName: data.data.paymentGroupName,
                            paymentGroupTypeId: data.data.paymentGroupTypeId
                        };
                        this.paymentGroupForm.patchValue({
                            paymentGroupName: data.data.paymentGroupName,
                            paymentGroupTypeId: data.data.paymentGroupTypeId
                        });
                    }
                });
            this.paymentGroupForm.controls.paymentGroupTypeId.disable();
            this.paymentGroupForm.controls.paymentGroupTypeId.updateValueAndValidity();

        }
        this.paymentType = window.location.pathname.includes('receiveable') ? '/financial/receiveable/payment-group' : '/financial/payable/payment-group';
        this.getPaymentTypes();
    }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/financial/main']);
  }
    mainReceive() {
        this._Router.navigate(["/financial/main-receivable"])
    }
    enterBills() {
        this._Router.navigate(["/financial/payable/invoices"])
    }
    payableMakePayment() {
        this._Router.navigate(["/financial/payable/payment-payables"])
    }
    payableMakeGroupPayment() {
        this._Router.navigate(["/financial/payable/payment-group"])
    }
    salesInvoice() {
        this._Router.navigate(["/financial/receiveable/invoices"])
    }
    receivePayment() {
        this._Router.navigate(["/financial/receiveable/payments"])
    }
    receiveGroupPayments() {
        this._Router.navigate(["/financial/receiveable/payment-group"])
    }
    invoiceType() {
        this.isShown = !this.isShown;
    }
    getPaymentGroups(): void {
        this.spinner.show();
        this._PaymentGroupService.getPaymentGroup('BATCH_PAYMENT', this.searchPaymentGroupForm.value)
            .then(data => {
                if (data.success) {
                    this.paymentGroups = data.data;
                    this.spinner.hide();
                }
            });

    }
    deleteProdCatalogToPartyNew(paymentGroupId: string) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this data.!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.accountsService.deleteProdCatalogToPartyNew(paymentGroupId).subscribe((res: any) => {
              this.getPaymentGroups();
            }, (err) => {
              this._ToastrService.error(err.error.message);
            })
            Swal.fire(
              'Deleted!',
              'Deleted Successfully.',
              'success'
            )
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your data is safe :)',
              'error'
            )
          }
        })
      }
    cancelBatchPayment(paymentGroupId: string): void {
        this.spinner.show();
        this._PaymentGroupService.cancelBatchPayment(paymentGroupId)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Canceled');
                    this.spinner.hide();
                    this.getPaymentGroups();
                }
            });

    }
    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    getPaymentTypes(): void {
        this.spinner.show();
        this._PaymentGroupService.getPaymentGroupTypes()
            .then(data => {
                this.spinner.hide();
                this.paymentGroupTypes = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.paymentGroupTypeId
                    };
                });
            });

    }
    update(): void {
        this.spinner.show();
        if (this.paymentGroupForm.valid) {
            this._PaymentGroupService.updatePaymentGroup(this.paymentGroupId, {
                ...this.paymentGroup, ...this.paymentGroupForm.value
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.closebutton.nativeElement.click();
                        this.getPaymentGroups();
                        this.paymentGroupForm.reset();
                        this.paymentGroupForm.controls.paymentGroupTypeId.enable();
                        this.paymentGroupForm.controls.paymentGroupTypeId.updateValueAndValidity();
                        this._Router.navigate([this.paymentType]);
                    }
                });
        }
        this.spinner.hide();
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            if (this.paymentGroupForm.valid) {
                this._PaymentGroupService.createPaymentGroup(this.paymentGroupForm.value)
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.paymentGroupForm.reset();
                            this.spinner.hide();
                            this.closebutton.nativeElement.click();
                            this.getPaymentGroups();
                            //this._Router.navigate([this.paymentType]);
                        }
                    });
            }
        }
        this.spinner.hide();
    }
    reset(): void {
        this.paymentGroupForm.reset();
        this._Router.navigate([this.paymentType]);

    }
    ngOnDestroy(): void {
        this.spinner.hide();
    }
}