<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard"> 
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Costing Method, P&L, TB</a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    <div class="panel-body">
                                                        <form>
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Costing
                                                                                    Method</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <p-dropdown [options]="identificationArray"
                                                                                    formControlName1="partyIdentificationTypeId"
                                                                                    filter="true"
                                                                                    placeholder="Enter Costing Method">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Cost of
                                                                                    goods
                                                                                    sold</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <p-dropdown [options]="identificationArray"
                                                                                    formControlName1="partyIdentificationTypeId"
                                                                                    filter="true"
                                                                                    placeholder="Enter Cost of goods sold">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">min length autocomplete codes</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <input type="text" name="idValue"
                                                                                    class="form-control"
                                                                                    formControlName1="idValue"
                                                                                    id="idValue"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter min length autocomplete codes">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">min length autocomplete description</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <input type="text" name="idValue"
                                                                                    class="form-control"
                                                                                    formControlName1="idValue"
                                                                                    id="idValue"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter min length autocomplete description">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Use Warranty Parts</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <p-dropdown [options]="identificationArray"
                                                                                    formControlName1="partyIdentificationTypeId"
                                                                                    filter="true"
                                                                                    placeholder="Enter Use Warranty Parts">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Use Sample parts</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <p-dropdown [options]="identificationArray"
                                                                                    formControlName1="partyIdentificationTypeId"
                                                                                    filter="true"
                                                                                    placeholder="Enter Sample Parts">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Use Free Goods Parts</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <p-dropdown [options]="identificationArray"
                                                                                    formControlName1="partyIdentificationTypeId"
                                                                                    filter="true"
                                                                                    placeholder="Enter Use Free Goods Parts">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Customers Trial Balance</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <p-dropdown [options]="identificationArray"
                                                                                    formControlName1="partyIdentificationTypeId"
                                                                                    filter="true"
                                                                                    placeholder="Enter Customers Trial Balance">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Customers Profit & Lost</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <p-dropdown [options]="identificationArray"
                                                                                    formControlName1="partyIdentificationTypeId"
                                                                                    filter="true"
                                                                                    placeholder="Enter Customers Profit & Lost">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Vendors Trial Balance</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <p-dropdown [options]="identificationArray"
                                                                                    formControlName1="partyIdentificationTypeId"
                                                                                    filter="true"
                                                                                    placeholder="Enter Vendors Trial Balance">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Vendors Profit & Lost</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <p-dropdown [options]="identificationArray"
                                                                                    formControlName1="partyIdentificationTypeId"
                                                                                    filter="true"
                                                                                    placeholder="Enter Vendors Profit & Lost">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-12 main-submit-button ">
                                                                <button type="submit" (click)="prevPage()"
                                                                    class="btn btn-secondary submit-btn">Previous</button>&nbsp;&nbsp;
                                                                <button type="submit" (click)="nextPage()"
                                                                    class="btn btn-secondary submit-btn">Save &
                                                                    Next</button>&nbsp;&nbsp;
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>