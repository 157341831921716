<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
          
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Find Fixed Assets</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords"> Search</span>
                                                    <button type="button" class="btn btn-outline-secondary ml-2" data-toggle="modal" data-target="#exampleModalCenter">Create New Fixed Asset</button>

                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 71%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>									
        
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                                </div>
                
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" [(ngModel)]="advanceSearch.fixedAssetIdSearchType" [ngModelOptions]="{standalone:true}" name="fixedAssetIdSearchType" optionlabel="label"></p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" name="groupName" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.fixedAssetId" [ngModelOptions]="{standalone:true}" name="fixedAssetId" aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Fixed Asset Name</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.fixedAssetNameSearchType" [ngModelOptions]="{standalone:true}" name="fixedAssetNameSearchType" [options]="opportunityIdArray" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" name="partyId" [(ngModel)]="advanceSearch.fixedAssetName" [ngModelOptions]="{standalone:true}" name="fixedAssetName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Asset Name">
                                                                </div>
                
                
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Asset Type</label>
                                                                </div>
                
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.assetType" [ngModelOptions]="{standalone:true}" name="assetType" [options]="fixedAssetsInitialDataArray" optionlabel="label" placeholder="Enter Asset Type">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: -29%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="findFixedAssetsNew()">Find</button>&nbsp;
                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2" (click)="reset();">Reset</button>
                                                    </div>
                                                

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <!-- <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter">Create New Fixed Asset</button>

                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                </div>

                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [options]="opportunityIdArray" [(ngModel)]="advanceSearch.fixedAssetIdSearchType" [ngModelOptions]="{standalone:true}" name="fixedAssetIdSearchType" optionlabel="label"></p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" name="groupName" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.fixedAssetId" [ngModelOptions]="{standalone:true}" name="fixedAssetId" aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Fixed Asset Name</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.fixedAssetNameSearchType" [ngModelOptions]="{standalone:true}" name="fixedAssetNameSearchType" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" name="partyId" [(ngModel)]="advanceSearch.fixedAssetName" [ngModelOptions]="{standalone:true}" name="fixedAssetName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Asset Name">
                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Asset Type</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.assetType" [ngModelOptions]="{standalone:true}" name="assetType" [options]="fixedAssetsInitialDataArray" optionlabel="label" placeholder="Enter Asset Type">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: -29%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2" (click)="reset();">Reset</button></div>
                                
                                    </div> -->
                                <br>

                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Fixed Assets List
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="getFixedAssets" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>

                                                                        Fixed Asset ID
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;"> Fixed Asset Name
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>

                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                    <div style="color: white;"> Fixed Asset Type ID
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Fixed Asset Parent ID
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Date Acquired
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Expected End Of Life
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Purchase Cost
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Salvage Value
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Depreciation
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <!-- <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Planned Past Depreciation Total
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th> -->
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Created At
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>


                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'200px'}" (click)="accountDetails(product.fixedAssetId)">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span class="account-button">
                                                                            {{product.fixedAssetId}}</span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.fixedAssetName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.fixedAssetTypeId}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.parentFixedAssetId}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.dateAcquired|date:'yyyy-MM-dd'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.expectedEndOfLife|date:'yyyy-MM-dd'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}"> {{product.purchaseCost}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}"> {{product.salvageValue}}

                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}"> {{product.depreciation}}

                                                                </td>
                                                                <!-- <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.plannedPastDepreciationTotal}}

                                                                </td> -->
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.createdStamp|date:'yyyy-MM-dd'}}
                                                                </td>


                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Fixed Asset</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                            {{editMode ? 'Update':'Create'}} Fixed Asset</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createFixAssetForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Fixed Asset ID
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="fixedAssetId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Fixed Asset Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="fixedAssetTypeId" [options]="fixedAssetTypes" placeholder="Enter Fixed Asset Type ID" filter="true">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Fixed Asset Parent ID </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="parentFixedAssetId" [options]="fixAssetIds" placeholder="Enter Fixed Asset Parent ID" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Instance Of Product ID </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="instanceOfProductId" [options]="productIds" placeholder="Enter Instance Of Product ID" filter="true">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Class Enum ID
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="classEnumId" [options]="classEnumId" placeholder="Enter Class Enum ID" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Party ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="partyId" placeholder="Enter Customer ID" filter="true" [options]="partyIds">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="roleTypeIds" formControlName="roleTypeId" placeholder="Enter RoleType ID" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Fixed Asset Name </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="fixedAssetName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Asset Name">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Acquire Order ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="acquireOrderId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Acquire Order ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Acquire Order Item Seq ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="acquireOrderItemSeqId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Acquire Order Item Seq ID">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Date Acquired</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="dateAcquired" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date Acquired">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Date Last Serviced
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="dateLastServiced" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date Last Serviced">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Date Next Service</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="dateNextService" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date Next Service">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Expected End Of Life </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="expectedEndOfLife" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Expected End Of Life">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Actual End Of Life</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="actualEndOfLife" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual End Of Life">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Production Capacity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="productionCapacity" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Production Capacity">

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">UOM</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="uomId" [options]="uomIds" placeholder="Enter UOM" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Calendar</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="calendarId" [options]="calendar" placeholder="Enter Calendar" filter="true">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Located At Facility ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="locatedAtFacilityId" [options]="facilityId" placeholder="Enter Located At Facility ID" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Located At Location Seq ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="locatedAtLocationSeqId" [options]="facilityLocation" placeholder="Enter Located At Location Seq ID" filter="true">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Salvage Value</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="salvageValue" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Salvage Value">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Depreciation</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="depreciation" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Depreciation">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Purchase Cost</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="purchaseCost" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase Cost">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Purchase Cost Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="partyId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase Cost Uom ID">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 82%;">

                                        <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>