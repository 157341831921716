import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-gl-invoice-type-list',
  templateUrl: './gl-invoice-type-list.component.html',
  styleUrls: ['./gl-invoice-type-list.component.css']
})
export class GlInvoiceTypeListComponent implements OnInit {
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  invoiceItemType: any;
  activeCategory=5;
  constructor(  readonly spinner: NgxSpinnerService,
    
    readonly router: Router,
  
    readonly accountsService: AccountsService,
  
    readonly _FormBuilder: FormBuilder,
   ) { }
    getChild=[{
      value:'6011'
    }]
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activeCategory=5;
    this.getInvoiceItemType();
  }
  chartAccount(){
    this.router.navigate(["/financial/global-gl-setting-list"])
  }
  timePeriod(){
    this.router.navigate(["/financial/gl-custom-time-period-list"])
  }
  cost(){
    this.router.navigate(["/financial/global-gl-cost"])
  }
  paymentMethod(){
    this.router.navigate(["/financial/gl-payment-method-type"])
  }
  invoiceMethod(){
    this.router.navigate(["/financial/gl-invoice-type-list"])
  }
  rate(){
    this.router.navigate(["/financial/global-gl-rate-list"])
  }
  foreignExc(){
    this.router.navigate(["/financial/gl-foreign-exchange-rate"])
  }
  accountCat(){
    this.router.navigate(["/financial/gl-account-category-list"])
  }
  costCenter(){
    this.router.navigate(["/financial/gl-cost-center"])
  } 
  getInvoiceItemType() {
    this.spinner.show();
    this.accountsService.getInvoiceItemType(this.finSize).subscribe((res: any) => {
      this.invoiceItemType = res.data;
      
      this.spinner.hide();

    })
   
  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  
  updateInvoice(einvoiceItemTypeId,edescription,edefaultGlAccountId){
    this.router.navigate(["financial/update-invoice-method-type"],{ queryParams: { invoiceItemTypeId : einvoiceItemTypeId,description : edescription,defaultGlAccountId : edefaultGlAccountId}})
   
  }
  updateSummaryInvoice(einvoiceItemTypeId,edescription,edefaultGlAccountId){
    this.router.navigate(["financial/gl-invoice-list-summary"],{ queryParams: { invoiceItemTypeId : einvoiceItemTypeId,description : edescription,defaultGlAccountId : edefaultGlAccountId}})
   
  }
 
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
