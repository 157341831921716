<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
  
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">The Profile of {{accountName}} [{{partyId}}: <span *ngIf="accountDetails.status=='Enabled'" style="color: #219c51!important;">Active</span><span class="btn-danger" *ngIf="accountDetails.status=='Disabled'">Inactive</span>] </span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>

            <div class=" bg-white color-grey">
                 <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
<!-- Removed class divCardA -->
                                            <div *ngIf="activeCategory==1">

                                                <div class="divCardA">
                                                <div class="w3-card-4 classCard">

                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Party Group Information
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#exampleModalCenter">Update</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div *ngIf="accountDetails" class="row">
                                                                    <div class="article-container">
                                                                        <div class="article" style="margin-left: 71%;">

                                                                            <button type="submit" (click)="uploadImage();" class="btn btn-secondary submit-btn">Edit</button><br>&nbsp;
                                                                            <span *ngIf="!imagePic" style="    width: 35%;"><img style="width: 78%;"
                                                src="../../assets/images/user.png"> </span>
                                                                            <span *ngIf="imagePic" style="    width: 35%;"><img style="width: 78%;"
                                                *ngIf="imageData" alt="small image" class="mr-2 float-right img-thumbnail"
                                                [src]="imageData"></span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Customer ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{partyId}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Customer Name</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.groupName}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Office Site Name</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.officeSiteName}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Annual revenue</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.annualRevenue}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Number of employees </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.numEmployees}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Description</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.description}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Currency</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.preferredCurrencyUomId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">External ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.externalId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Status ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.status}}</span>
                                                                        </div>




                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCard">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Additional Info
                                                        </h4>
                                                        <div class="d-flex flex-row hCreate">
                                                            <span class="edit-buttons ml-2 hCreate" data-toggle="modal" data-target="#exampleModal">{{isAdditionalDetail?
                                                'Update':'Create'}}
                                                </span>

                                                        </div>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div *ngIf="accountDetails" class="row">
                                                                    <div class="article-container" *ngIf="accountDetails.additionalInformation">

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Type</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.additionalInformation.infoType
                                                ? accountDetails.additionalInformation.infoType:'N/A'}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Terms</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.additionalInformation.terms
                                                ? accountDetails.additionalInformation.terms:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Rep</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.additionalInformation.rep
                                                ? accountDetails.additionalInformation.rep:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Price Level</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.additionalInformation.priceLevel
                                                ? accountDetails.additionalInformation.priceLevel:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Preferred Send Method</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.additionalInformation.prefeferredSendMethod
                                                ? accountDetails.additionalInformation.prefeferredSendMethod:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Resale No.</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.additionalInformation.resaleNo
                                                ? accountDetails.additionalInformation.resaleNo:'N/A'}}</span>
                                                                        </div>


                                                                    </div>







                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCard">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Job Info
                                                        </h4>
                                                        <div class="d-flex flex-row hCreate">
                                                            <span class="edit-buttons ml-2 hCreate" data-toggle="modal" data-target="#exampleModalLong" [queryParams]="{partyId:partyId,isEditable:isJobDetail}">{{isJobDetail ?
                                                'Update':'Create'}}</span>

                                                        </div>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div *ngIf="accountDetails.jobInformation" class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Job Status</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.jobInformation.jobStatus
                                                ? accountDetails.jobInformation.jobStatus:'N/A'}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Start Date</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.jobInformation.startDate
                                                ? (accountDetails.jobInformation.startDate |date :'yyyy-MM-dd
                                                HH:mm:ss'):'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Projected End Date</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.jobInformation.projectedEndDate
                                                ? (accountDetails.jobInformation.projectedEndDate |date :'yyyy-MM-dd
                                                HH:mm:ss'):'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">End Date</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.jobInformation.end ?
                                                (accountDetails.jobInformation.end |date :'yyyy-MM-dd HH:mm:ss'):'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Description</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.jobInformation.description
                                                ? accountDetails.jobInformation.description:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Job Type</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.jobInformation.jobType
                                                ? accountDetails.jobInformation.jobType:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Product Type</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.jobInformation.productType
                                                ? accountDetails.jobInformation.productType:'N/A'}}</span>
                                                                        </div>

                                                                        <div class="article">

                                                                            <p class="col-lg-6 col-12">Service Type</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{accountDetails.jobInformation.serviceType
                                                ? accountDetails.jobInformation.serviceType:'N/A'}}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCard">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            List Related Contacts
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#exampleModalFifth">Create New List Related Contacts</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select allUserInfoDetail">
                                                            <div class="form-group">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="relatedContact" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        To Contact ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th pSortableColumn="quantity">
                                                                                    <div style="color: white;">Type
                                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Comment
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        {{product.partyIdTo}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{{product.partyRelationshipName}}</td>
                                                                                <td>{{product.comments}}</td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <br>

                                                <!-- New table added -->

                                                <div class="w3-card-4 classCard">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Cases
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createCasesNewPop" (click)="resetFrom()" style="margin-left:65%!important;">Create New Cases</span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                        <p-table [value]="allCasessData" [paginator]="false"   scrollHeight="150px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                           Priority
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                           Subject
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                           Type
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Reason
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    

                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">Action
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.priority}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.subject}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.status}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.type}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.reason}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn"data-toggle="modal" data-target="#createCasesNewPop" (click)="updateCasessPatch(product)">Update</button>
                                                                                         <button type="submit" class="btn btn-secondary submit-btn" (click)="deleteCasess(product)">Delete</button>
                                                                                    </td>
                                                                               
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Activities History
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createActivitiesHistoryPopup" (click)="resetFrom()" style="margin-left:54%!important;">Log Call</span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                    
                                                                        <p-table [value]="allActivityHistoryData" [paginator]="false"   scrollHeight="150px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                           Types
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Purpose
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Activity
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Started Date 
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Completion Date
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    

                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">Action
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.types}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.purpose}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.activity}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.status}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.startedDate | date}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.completionDate | date}}</td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn"data-toggle="modal" data-target="#createActivitiesHistoryPopup" (click)="updateActivityHistoryLogCallPatch(product)">Update</button>
                                                                                         <button type="submit" class="btn btn-secondary submit-btn" (click)="deleteActivityHistoryLogCall(product)">Delete</button>
                                                                                    </td>
                                                                               
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Catalog Requests
                                                        </h4>

                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createCatalogRequestPop" (click)="resetFrom()" style="margin-left:54%!important;">Create New Catalog Request</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                        <p-table [value]="allCatalogRequestData" [paginator]="false"   scrollHeight="150px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                           Date
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Address
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Activity
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Taken By
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Full Filled
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    

                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">Action
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.date | date}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.address}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.activity}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.takenBy}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.fullFilled}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn"data-toggle="modal" data-target="#createCatalogRequestPop" (click)="UpdateCatalogRequestPatch(product)">Update</button>
                                                                                         <button type="submit" class="btn btn-secondary submit-btn" (click)="deleteCatalogRequest(product)">Delete</button>
                                                                                    </td>
                                                                               
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Document and Files
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createDocuandFilesPopup" (click)="resetFrom()" style="margin-left:42%!important;">Bookmark URL</span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                       
                                                                        <p-table [value]="allBookMarkUrlByData" [paginator]="false"   scrollHeight="150px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                           Name
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Classification
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                   
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Created Date 
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                   
    

                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">Action
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.name}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.classification}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.description}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.createdDate| date}}</td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn"data-toggle="modal" data-target="#createDocuandFilesPopup" (click)="updateBookMarkUrlPatch(product)">Update</button>
                                                                                         <button type="submit" class="btn btn-secondary submit-btn" (click)="deleteBookMarkUrl(product)">Delete</button>
                                                                                    </td>
                                                                               
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                             

                                               


                                                </div>
        <!-- Removed class  divCardB-->
                                                <div class="divCardB">
                                                <div class="w3-card-4 classCard">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling hCard">
                                                        Party Documents
                                                    </h4>
                                                </div>

                                                <div class="panel-body allUserInfoDetail">
                                                    <div class="card own-account-table borderTable">
                                                        <p-table [value]="contentList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Content Id
                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">Content Name
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                        <div style="color: white;">Content Type
                                                                            <p-sortIcon field="category">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                        <div style="color: white;">Party Content Type
                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Status
                                                                            <p-sortIcon field="price">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">From Date
                                                                            <p-sortIcon field="price">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                        <div style="color: white;">Action
                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            {{product.contentId}}
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">{{product.contentName}}</td>
                                                                    <td [ngStyle]="{'width':'170px'}">{{product.contentType}}</td>

                                                                    <td [ngStyle]="{'width':'170px'}">{{product.partyContentTypeId}}</td>
                                                                    <td [ngStyle]="{'width':'170px'}">{{product.status}}</td>
                                                                    <td [ngStyle]="{'width':'170px'}">{{product.fromDate | date:'medium'}}</td>

                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                        <svg (click)="export(product.contentId,product.contentName)" width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-arrow-down-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                </svg>
                                                                        <svg (click)="onDelete(product.contentId,product.fromDate,product.partyContentTypeId,product.partyId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                <defs>
                                                <style>
                                                .a {
                                                fill: #f44336;
                                                }

                                                .b {
                                                fill: #fafafa;
                                                margin-left: 20px;
                                                }
                                                </style>
                                                </defs>
                                                <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                                </svg>

                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                    <form [formGroup]="Editform">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group">

                                                                    <label for="exampleInputEmail1">Attach Document</label>
                                                                    <div class="suppliers-wrapper">
                                                                        <input type="file" class="inputField classFile" id="exampleInputEmail1" formControlName="file" aria-describedby="emailHelp"  (change)="fileProgress($event)" accept=""  style="    padding: 2px;
                                                                        padding-left: 6px;">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <br><br>
                                                                <div class="form-group dropdowns">
                                                                    <p-dropdown filter="true" formControlName="partyContentTypeId" [(ngModel)]="partyContentTypeId" name="partyContentTypeId" placeholder="Enter Party Content" (ngModelChange)="onpartyContent(partyContentTypeId)" [options]="partyContentArray" optionlabel="label"></p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <br><br>
                                                                <div class="form-group">


                                                                    <button type="submit" (click)="onSubmit()" class="btn btn-outline-secondary">Submit</button>

                                                                </div>
                                                            </div>

                                                        </div>


                                                    </form>

                                                </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCard">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling hCard">
                                                        Notes
                                                    </h4>
                                                    <span class="edit-buttons hCreate" data-toggle="modal" data-target="#templateModal">Create New Notes</span>
                                                </div>
                                                <div class="panel-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select notes">


                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="accountDataNote" [rows]="rows" scrollWidth="100%" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Note Name
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>

                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Note
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;">
                                                                                        By
                                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                                    </div>

                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">Created At
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>

                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;">Action


                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        {{product.noteName}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.noteInfo}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.firstName}} {{product.middleName}} {{product.lastName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.createdStamp | date:'yyyy-MM-dd'}}
                                                                                </td>


                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg class="hover" (click)="onUpdateNotes(product.noteId,product.noteName,product.noteInfo)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                <g transform="translate(0 -0.004)">
                                                <g transform="translate(0 1.52)">
                                                <path
                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                transform="translate(0 -34.137)" />
                                                </g>
                                                <g transform="translate(4.548 0.004)">
                                                <g transform="translate(0 0)">
                                                <path
                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                    transform="translate(-102.409 -0.004)" />
                                                </g>
                                                </g>
                                                </g>
                                                </svg>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="onDeleteNotes(product.noteId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                <defs>
                                                <style>
                                                .a {
                                                fill: #f44336;
                                                }

                                                .b {
                                                fill: #fafafa;
                                                margin-left: 20px;
                                                }
                                                </style>
                                                </defs>
                                                <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                                </svg>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCard">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling hCard">
                                                        Lead Source List
                                                    </h4>
                                                </div>
                                                <div class="panel-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select leadSorce">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="leadSourceList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Lead Source
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;">From Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td>{{product.dataSourceId}}</td>
                                                                                <td>{{product.fromDate | date:"yyyy-MM-dd"}}</td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCard">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling hCard">
                                                        Lead Source
                                                    </h4>
                                                </div>
                                                <div class="panel-body">
                                                    <form *ngIf="accountLeadSourceService.lead_source_form" [formGroup]="accountLeadSourceService.lead_source_form">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormBA">
                                                                        <label for="exampleInputEmail1">Lead Source</label>
                                                                    </div>
                                                                    <div class="col-lg-3 dropdowns">
                                                                        <p-dropdown filter="true" formControlName="dataSourceId" [options]="leadSourcearray" optionlabel="label" placeholder="Enter Lead Source">

                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormBB">
                                                                        <label for="exampleInputEmail1">From Date</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="date" style="width: 105%;" formControlName="fromDate">
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormBA">
                                                                        <label for="exampleInputEmail1">Visit ID</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" class="form-control" formControlName="visitId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Visit Id">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group rightFormBB">
                                                                        <label for="exampleInputEmail1">Comments</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" class="form-control" formControlName="comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comment">
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormBA">
                                                                        <label for="exampleInputEmail1">Is Create</label>
                                                                    </div>
                                                                    <div class="col-lg-3 dropdowns">
                                                                        <p-dropdown filter="true" formControlName="isCreate" [options]="isCreate" placeholder="Enter Is Create" optionlabel="label" [(ngModel)]="selectedIsCreate">

                                                                        </p-dropdown>
                                                                    </div>



                                                                </div>
                                                            </div>


                                                            <div style="margin-left:-14%;" class="col-lg-12 col-12 main-submit-button"><button _ngcontent-cta-c128="" (click)="onCreate()" type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                                </div>
                                                
                                                <br>
                                                <div class="w3-card-4 classCard">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling hCard">
                                                        Contact Information
                                                    </h4>
                                                    <div class="d-flex flex-row hCreate">
                                                        <span class="edit-buttons" data-toggle="modal" data-target="#exampleModalSixth" [queryParams]="{partyId:partyId}">Create Telecom</span>
                                                        <span class="edit-buttons ml-2" data-toggle="modal" data-target="#exampleModalAddress" [queryParams]="{partyId:partyId}">Create
                                                Address</span>
                                                    </div>
                                                </div>
                                                <div class="panel-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select allUserInfoDetail">
                                                        <div class="form-group">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="contactInformation" [rows]="rows">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Contact Type
                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                </div>

                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">Contact Information
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>

                                                                            </th>
                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                <div style="color: white;">Soliciting OK
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                <div style="color: white;">Action
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td style="width: 25%;">{{product.contactMechType.description}}
                                                                            </td>
                                                                            <td>
                                                                                <div class="checkbox-align">

                                                                                    <ng-container *ngIf="product.contactMech.contactMechTypeId==='POSTAL_ADDRESS'">
                                                                                        {{product.postalAddress.address1}} , {{product.postalAddress.address2}},
                                                                                        <br>{{product.postalAddress.stateProvinceGeoId}} , {{product.postalAddress.city}},
                                                                                        <br>{{product.postalAddress.postalCode}},{{product.postalAddress.countryGeoId}}
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="product.contactMech.contactMechTypeId==='EMAIL_ADDRESS'">
                                                                                        {{product.contactMech.infoString}}
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="product.contactMech.contactMechTypeId==='TELECOM_NUMBER'">
                                                                                        Area Code: {{product.telecomNumber.areaCode}}, Contact Number: {{product.telecomNumber.contactNumber}}, Country Code: {{product.telecomNumber.countryCode}}
                                                                                    </ng-container>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {{product.partyContactMech.allowSolicitation ?product.partyContactMech.allowSolicitation :'N' }}
                                                                            </td>
                                                                            <td>
                                                                                <fa-icon [icon]="faEdit" class="text-primary" (click)="updateData(product.contactMech.contactMechId ,product.contactMech.contactMechTypeId)">
                                                                                </fa-icon>

                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                </div>
                                                <br>


                                                
                                                <!-- new table added by RA -->

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Pending Activities
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createNewTaskPopup" (click)="resetFrom()" style="margin-left:51%!important;">New Task</span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
    
    
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table borderTable">
                                                                        <div class="card own-account-table ">
                                                                           

                                                                            <p-table [value]="allNewTaskData" [paginator]="false"   scrollHeight="150px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code"  [ngStyle]="{'width':'180px'}">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                               Types
                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                                            <div style="color: white;">
                                                                                                Purpose
                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                            <div style="color: white;">
                                                                                                Activity
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                            <div style="color: white;">
                                                                                                Status
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                            <div style="color: white;">
                                                                                                Scheduled Date 
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                            <div style="color: white;">
                                                                                                Due Date
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
    
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                            <div style="color: white;">Action
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
                                                                                        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td  [ngStyle]="{'width':'180px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                {{product.type}}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">{{product.purpose}}</td>
                                                                                        <td [ngStyle]="{'width':'180px'}">{{product.activity}}</td>
                                                                                        <td [ngStyle]="{'width':'180px'}">{{product.status}}</td>
                                                                                        <td [ngStyle]="{'width':'180px'}">{{product.scheduledDate| date}}</td>
                                                                                        <td [ngStyle]="{'width':'180px'}">{{product.dueDate | date}}</td>

                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            <button type="submit" class="btn btn-secondary submit-btn"data-toggle="modal" data-target="#createNewTaskPopup" (click)="updatePendingActivityNewTaskPatch(product)">Update</button>
                                                                                             <button type="submit" class="btn btn-secondary submit-btn" (click)="deletePendingActivityTaskPartnerNew(product)">Delete</button>
                                                                                        </td>
                                                                                   
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
                                           
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
    
    
                                                        </div>
                                                    </div>
    
                                                </div>
                                                
                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Accounts
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createAccountsPopup" style="margin-left:51%!important;" (click)="resetFrom()">Create New Accounts</span>

                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table borderTable">
                                                                        <div class="card own-account-table ">
                                                                            <p-table [value]="allAccountData" [paginator]="false"   scrollHeight="150px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code"  [ngStyle]="{'width':'180px'}">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                Account Name
                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                                            <div style="color: white;">
                                                                                                City
                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                            <div style="color: white;">
                                                                                                Primary Email
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                            <div style="color: white;">
                                                                                                Primary Phone
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
    
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                            <div style="color: white;">Action
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
                                                                                        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td  [ngStyle]="{'width':'180px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                {{product.accountName}}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">{{product.city}}</td>
                                                                                        <td [ngStyle]="{'width':'180px'}">{{product.primaryEmail}}</td>
                                                                                        <td [ngStyle]="{'width':'180px'}">{{product.primaryPhone}}</td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            <button type="submit" class="btn btn-secondary submit-btn"data-toggle="modal" data-target="#createAccountsPopup" (click)="updateAcc(product)">Update</button>
                                                                                             <button type="submit" class="btn btn-secondary submit-btn" (click)="deleteAccount(product)">Delete</button>
                                                                                        </td>
                                                                                   
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                           
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Open Orders
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createOrderPopup" style="margin-left:65%!important;">Orders </span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                        <p-table [value]="allOrderByData" [paginator]="false"   scrollHeight="150px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Order Date 
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Order Name and ID 
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            PO#
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Customer
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Ship Before Date 
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Amount
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    

                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">Action
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.orderDate|date}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.orderName}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.po}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.customer}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.status}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.shipBeforeDate|date}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.amount}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn"data-toggle="modal" data-target="#createOrderPopup" (click)="updateOrderPatch(product)">Update</button>
                                                                                         <button type="submit" class="btn btn-secondary submit-btn" (click)="deleteOrder(product)">Delete</button>
                                                                                    </td>
                                                                               
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>
                                                
                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Opportunities
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createOpportunityPopup" (click)="resetFrom()" style="margin-left:65%!important;">Create New Opportunity</span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                        <p-table [value]="allOpportunityData" [paginator]="false"   scrollHeight="150px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Opportunity Name 
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                          Stage
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Opportunity Amount
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Estimated Close Date 
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    

                                                                                    <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td  [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.opportunityName}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.stage}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.opportunityAmount}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.closeDate| date}}</td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn"data-toggle="modal" data-target="#createOpportunityPopup" (click)="updateOpportunityPatch(product)">Update</button>
                                                                                         <button type="submit" class="btn btn-secondary submit-btn" (click)="deleteOpportunity(product)">Delete</button>
                                                                                    </td>
                                                                               
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                               

                                                

                                                 </div>
                                         
                                            </div>
                                         
                                        </div>
                                    </div>
                                </div>
                                        <div class="col-md-12">
                                                    <div class="w3-card-4 classCard" style="width:100%;    margin-left: 0%;">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hCard">
                                                                Payment Method
                                                            </h4>
                                                            <div class="d-flex flex-row" style="margin-right: 12px;">
                                                                <span class="edit-buttons ml-2" data-toggle="modal" data-target="#exampleModalCredit">Create Credit Card</span>
                                                                <span class="edit-buttons ml-2" data-toggle="modal" data-target="#exampleModalGift">Create Gift Card</span>
                                                                <span class="edit-buttons ml-2" data-toggle="modal" data-target="#exampleModalEFT">Create EFT Account</span>
                                                                <span class="edit-buttons ml-2" data-toggle="modal" data-target="#exampleModalCheck">Add Check Account</span>
                                                                <span class="edit-buttons ml-2" data-toggle="modal" data-target="#exampleModalBilling">Create Billing Account</span>
                                                            </div>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="card own-account-table ml-4 borderTable">
                                                                <p-table [value]="paymentMethods" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td>{{product.type}}</td>
                                                                            <td>
                                                                                <ng-container *ngIf="product.paymentMethodTypeId.includes('ACCOUNT') || product.paymentMethodTypeId.includes('CHECK')">
                                                                                    <span>{{product.nameOnAccount}},{{product.bankName}}({{product.branchCode}}),A/C
                                                        {{product.accountNumber}}, (Type:
                                                        {{product.accountType ? product.accountType :''}}), (Updated At:
                                                        {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                                </ng-container>
                                                                                <ng-container *ngIf="product.paymentMethodTypeId==='BILLING'">
                                                                                    <span>({{product.billingAccountId}}),(Account
                                                        Limit:${{product.accountLimit}}),(Available Balance:
                                                        ${{product.accountBalance}}), (Updated At:
                                                        {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                                </ng-container>
                                                                                <ng-container *ngIf="product.paymentMethodTypeId.includes('CARD')">
                                                                                    <span>{{product.cardNumber ? product.cardNumber
                                                        :''}}</span>, Expire :
                                                                                    <span>{{product.expireDate? product.expireDate:''}}</span>,
                                                                                    <span> {{product.companyNameOnCard? product.companyNameOnCard:''}}</span>,
                                                                                    <span> ,( {{product.firstNameOnCard ? product.firstNameOnCard:''}}
                                                        {{product.middleNameOnCard ? product.middleNameOnCard:''}}
                                                        {{product.lastNameOnCard ? product.lastNameOnCard : ''}}),(
                                                        {{product.cardType}}), (Updated At:
                                                        {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                                </ng-container>
                                                                            </td>
                                                                            <td>
                                                                                <fa-icon  class="account-button"  (click)="paymentMethodNavigation(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)" [icon]="faEdit"></fa-icon>
                                                                                <fa-icon class="text-danger cursor-pointer" (click)="removePaymentMethod(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)" [icon]="faTrash"></fa-icon>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
        
                                                    </div>
                                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update-Customer</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategorymain==2?'active':''"><a>
                    Address</a></li>
                                <li [ngClass]="activeCategorymain==3?'active':''"><a>
                    Additional Info</a></li>
                                <li [ngClass]="activeCategorymain==4?'active':''"><a>
                    Job Info</a></li>
                            </ul>
                        </div>

                        <div *ngIf="activeCategorymain==2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="accountForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="partyId">Party Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" [value]="partyId" readonly>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="city">City
                                                            <span style="color:red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" formControlName="city" placeholder="Enter City">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Company Name<span style="color:red">*</span>
                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input formControlName="groupName" type="email" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Company Name">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="salutation">Salutation</label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" [options]="salutationArray" placeholder="Salutation" filter="true" formControlName="title">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="salutation">First Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="First Name" formControlName="firstName">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="salutation">Last Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Last Name" formControlName="lastName">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="countryCode">Country Code</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Country Code" formControlName="countryCode">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="salutation">Area Code</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Area Code" formControlName="areaCode">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="!show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="salutation">Contact</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Contact" formControlName="contactNumber">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="salutation">Extension</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Extension" formControlName="extension">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label style="font-size: 10px;" for="exampleInputEmail1">Number
                              of
                              Employees</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="numEmployees" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter No of Employess">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Office Site Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="officeSiteName" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Office Site Name">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="emailAddress">Email Address</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Email Address" formControlName="emailAddress">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="address1">Address 1<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Address 1" formControlName="address1">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="!show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="address1">Address 2<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Address 2" formControlName="address2">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="city">City<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="city" formControlName="city">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="!show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="city">Country<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" [options]="array" placeholder="Select Country" formControlName="countryGeoId" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="city">State<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" [options]="stateArray" placeholder="State" filter="true" formControlName="stateProvinceGeoId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="pinCode">Pin Code<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Pin Code" formControlName="postalCode">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="city">Customer Type<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" [options]="customerType" placeholder="Customer Type" filter="true" formControlName="customerType">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="revenue">Annual Revenue</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Annual Revenue" formControlName="annualRevenue">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA ">
                                                        <label for="desc">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Description" formControlName="description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="externalID">External Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="External Id" formControlName="externalId">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="logoImage">Logo Image Url</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input" placeholder="Logo Image Url" formControlName="logoImageUrl">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="currency">Preferred Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" [options]="currencyList" placeholder="Preferred Currency Uom ID" formControlName="preferredCurrencyUomId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="statusId">Status ID</label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" [options]="statusList" placeholder="Status ID" formControlName="statusId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="currency">Country</label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" [options]="array" (ngModelChange)="onCountryChange($event)" formControlName="countryGeoId" placeholder="Select Country" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="statusId">State</label>
                                                    </div>
                                                    <div class="col-lg-3 dropdowns">
                                                        <p-dropdown styleClass="small-dropdown" formControlName="stateProvinceGeoId" placeholder="Select State" filter="true" [options]="stateArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                        <div class="main-submit-button" style="margin-right: 15%;">
                                            <button type="submit" (click)="createAccount()" *ngIf="!show" class="btn btn-secondary submit-btn">Save</button>
                                            <button type="submit" (click)="updateAccount()" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>



                                        </div>

                                    </form>
                                </div>

                            </div>

                        </div>
                        <div *ngIf="activeCategorymain==3">
                            <div class="container-fluid">
                                <div class="row">

                                    <form class="w-100" [formGroup]="additionalDetailForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormD">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Type" formControlName="infoType">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Terms</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Terms" formControlName="terms">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormD">
                                                        <label for="exampleInputEmail1">Rep. </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Rep" formControlName="rep">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Price Level </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Price Level" formControlName="priceLevel">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormD">
                                                        <label for="exampleInputEmail1">Preferred Send Method
                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Preferred Send Method" formControlName="prefeferredSendMethod">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Resale No. </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Resale No." formControlName="resaleNo">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left:-11% !important;">
                                        <button type="submit" *ngIf="!editModeA" (click)="updateAdd()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="editModeA" (click)="submitAdd()" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="activeCategorymain==4">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="jobInfoForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Job Status</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Job Status" formControlName="jobStatus">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Start Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" placeholder="Start Date" formControlName="startDate">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Projected End </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" placeholder="Projected End" formControlName="projectedEndDate">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">End Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" placeholder="End Date" formControlName="endDate">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Description
                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Description" formControlName="description">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Job Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Job Type" formControlName="jobType">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Product Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Product Type" formControlName="productType">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Service Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Service Type" formControlName="serviceType">
                                                    </div>


                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: -11%;">
                                        <button type="submit" *ngIf="!editModeA" (click)="updateJob()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="editModeA" (click)="submit()" class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <br> <br> <br> <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create-Additional-Info</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton2>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Create Additional Info</a></li>
                            </ul>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="additionalDetailForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;margin-left:-4%;">
                                                    <label for="exampleInputEmail1">Type</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control" placeholder="Type" formControlName="infoType">
                                                </div>

                                                <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;">
                                                    <label for="exampleInputEmail1">Terms</label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control" placeholder="Terms" formControlName="terms">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;margin-left: -4%;">
                                                    <label for="exampleInputEmail1">Rep. </label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control" placeholder="Rep" formControlName="rep">
                                                </div>

                                                <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;">
                                                    <label for="exampleInputEmail1">Price Level </label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control" placeholder="Price Level" formControlName="priceLevel">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;margin-left: -4%;">
                                                    <label for="exampleInputEmail1">Preferred Send Method
                          </label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control" placeholder="Preferred Send Method" formControlName="prefeferredSendMethod">
                                                </div>
                                                <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;">
                                                    <label for="exampleInputEmail1">Resale No. </label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control" placeholder="Resale No." formControlName="resaleNo">
                                                </div>



                                            </div>
                                        </div>


                                    </div>
                                </form>
                                <div class="main-submit-button" style="margin-left: -29%;">
                                    <button type="submit" *ngIf="editModeA" (click)="update()" class="btn btn-secondary submit-btn">Update</button>
                                    <button type="submit" *ngIf="!editModeA" (click)="submitInfo()" class="btn btn-secondary submit-btn">Submit</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="templateModal" tabindex="-1" role="dialog" aria-labelledby="templateModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create-Note</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton3>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                    New Party Note</a></li>
                            </ul>
                        </div>
                        <div class="panel-group">

                            <div *ngIf="activeCategory==1">

                                <div class="panel-body">
                                    <form [formGroup]="fileUploadForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Note Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" [(ngModel)]="noteName" formControlName="noteName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormANote">
                                                        <label for="exampleInputEmail1">Note</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <textarea id="w3review" style="height: 100%!important;padding-left:-10% !important;" placeholder="Note" [(ngModel)]="note" formControlName="note" rows="3" cols="60">
                                </textarea>
                                                    </div>


                                                </div>
                                            </div>


                                        </div>

                                        <div class="proper">
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn" style="margin-left: -19% !important;">
                                                <button type="submit" (click)="onSubmitbtn()" class="btn btn-secondary submit-btn mr-2">Create</button>
                                                <button type="submit" (click)="navigateFromCreate()" class="btn btn-danger ">Cancel</button>
                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateBtn">
                                                <button type="submit" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                                <button type="submit" (click)="navigate()" class="btn btn-danger ">Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update-Job-Info</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton4>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Update Job Info</a></li>
                            </ul>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="jobInfoForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightFormE" style="text-align: right!important;
                                  padding-right: 17px!important;
                                  margin-left: -11%;">
                                                    <label for="exampleInputEmail1">Job Status</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" class="form-control" placeholder="Job Status" formControlName="jobStatus">
                                                </div>

                                                <div class="col-lg-3 form-group rightFormRR" style="text-align: right!important;
                                  padding-right: 17px!important;
                                 ">
                                                    <label for="exampleInputEmail1">Start Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" placeholder="Start Date" formControlName="startDate">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightFormE" style="text-align: right!important;
                                  padding-right: 17px!important;
                                  margin-left: -11%;">
                                                    <label for="exampleInputEmail1">Projected End </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control"  formControlName="projectedEndDate">
                                                </div>

                                                <div class="col-lg-3 form-group rightFormRR" style="text-align: right!important;
                                                    padding-right: 17px!important;">
                                                    <label for="exampleInputEmail1">End Date </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control"  formControlName="endDate">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightFormE" style="text-align: right!important;
                                  padding-right: 17px!important;
                                  margin-left: -11%;">
                                                    <label for="exampleInputEmail1">Description
                          </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" class="form-control" placeholder="Description" formControlName="description">
                                                </div>

                                                <div class="col-lg-3 form-group rightFormRR" style="text-align: right!important;
                                  padding-right: 17px!important;
                              ">
                                                    <label for="exampleInputEmail1">Job Type </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" class="form-control" placeholder="Job Type" formControlName="jobType">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightFormE" style="text-align: right!important;
                                  padding-right: 17px!important;
                                  margin-left: -11%;">
                                                    <label for="exampleInputEmail1">Product Type </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" class="form-control" placeholder="Product Type" formControlName="productType">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormRR" style="text-align: right!important;
                                  padding-right: 17px!important;
                                 ">
                                                    <label for="exampleInputEmail1">Service Type </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" class="form-control" placeholder="Service Type" formControlName="serviceType">
                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                </form>
                                <div class="main-submit-button" style="margin-left: 67%;">
                                    <button type="submit" *ngIf="editMode" (click)="updateForm()" class="btn btn-secondary submit-btn">Update</button>
                                    <button type="submit" *ngIf="!editMode" (click)="submitJob()" class="btn btn-secondary submit-btn">Submit</button>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalFifth" tabindex="-1" role="dialog" aria-labelledby="exampleModalFifthTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add-Related-Contacts</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton5>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Add Related Contacts</a></li>
                            </ul>
                        </div>
                        <div class="panel-body">
                            <form [formGroup]="fileUploadFormContact">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group rightForm" style="margin-left: -7%;">
                                                <label for="exampleInputEmail1">Contact Party Id</label>
                                            </div>
                                            <div class="col-lg-2 dropdowns">
                                                <p-dropdown formControlName="contactPartyId" [options]="contactArray" optionlabel="label" id="exampleInputEmail1" filter="true" placeholder="Select Contact Id"></p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInput" style="text-align: right !important;margin-left: -2% !important;">
                                                <label for="exampleInputEmail1">Comments</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <textarea id="w3review" formControlName="comments" rows="8" cols="50">
                          </textarea>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="proper" style="margin-left: 74% !important;">
                                    <button type="submit" (click)="onSubmitcontact()" class="btn btn-secondary submit-btn mr-3">Save</button>
                                    <button type="submit" (click)="navigate()" class="btn btn-danger">Cancel</button>
                                </div>
                                <br>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalSixth" tabindex="-1" role="dialog" aria-labelledby="exampleModalSixthTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create-Contact</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton7>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Create Contact</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="contactForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: -8%;">
                                                        <label for="exampleInputEmail1">Allow Solicitation</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown [options]="arrayfax" placeholder="Select" formControlName="allowSolicitation">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: 1%;">
                                                        <label for="exampleInputEmail1">Country Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Country Code" formControlName="countryCode">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: -8%;">
                                                        <label for="exampleInputEmail1">Area Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Area Code" formControlName="areaCode">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: 1%;">
                                                        <label for="exampleInputEmail1">Contact Number</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Contact Number" formControlName="contactNumber">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: -8%;">
                                                        <label for="exampleInputEmail1">Extension</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Extension" formControlName="extension">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: 1%;">
                                                        <label for="exampleInputEmail1">Mech Purpose Type Id<span
                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown [options]="contactPurpose" placeholder="Mech Purpose Type Id" formControlName="contactMechPurposeTypeId"></p-dropdown>
                                                        <small *ngIf="!!contactForm.controls.contactMechPurposeTypeId.invalid && !!contactForm.controls.contactMechPurposeTypeId.touched" class="text-danger">Mech Purpose Type Id is required</small>
                                                    </div>

                                                </div>
                                            </div>



                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: -24%;">
                                        <button type="submit" *ngIf="editMode" (click)="updateContact()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submitContact()" class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalAddress" tabindex="-1" role="dialog" aria-labelledby="exampleModalAddressTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create-Postal-Address</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton6>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Create Postal Address</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addressForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -4%;">
                                                        <label for="exampleInputEmail1">Attn Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Attn Name" formControlName="attnName">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -2%;">
                                                        <label for="exampleInputEmail1">To Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="To Name" formControlName="toName">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -4%;">
                                                        <label for="exampleInputEmail1">Allow Solicitation </label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown placeholder="Allow Solicitation" [options]="solicitationArray" filter="true" formControlName="allowSolicitation"></p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -2%;">
                                                        <label for="exampleInputEmail1">Address 1 </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Address 1" formControlName="address1">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -4%;">
                                                        <label for="exampleInputEmail1">Address 2</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Address 2" formControlName="address2">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -2%;">
                                                        <label for="exampleInputEmail1">City </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="City" formControlName="city">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -4%;">
                                                        <label for="exampleInputEmail1">Country </label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown [options]="countryArray" placeholder="Country" formControlName="countryGeoId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -2%;">
                                                        <label for="exampleInputEmail1">State </label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown [options]="stateArray" placeholder="State" filter="true" formControlName="stateProvinceGeoId"></p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -4%;">
                                                        <label for="pinCode">Pin Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Pin Code" formControlName="postalCode">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -2%;">
                                                        <label for="mechId">Contact Mech Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Contact Mech Id" formControlName="contactMechId">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 9px!important;
                                      margin-left: -4%;">
                                                        <label for="mechType">Contact Mech Purpose Type Id <span
                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown placeholder="Contact Mech Purpose Type Id" filter="true" [options]="contactMechTypeId" formControlName="contactMechPurposeTypeId">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: -15%;">
                                        <button type="submit" *ngIf="editMode" (click)="updatePostal()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submitPostal()" class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="exampleModalCredit" tabindex="-1" role="dialog" aria-labelledby="exampleModalCreditTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create-Credit-Card</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton8>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Create Credit Card</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="creditCardForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left:3%;">
                                                        <label for="exampleInputEmail1">Company Name Card</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Company Name Card" formControlName="companyNameOnCard">
                                                    </div>

                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 0%;">
                                                        <label for="exampleInputEmail1">Prefix Card</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown placeholder="Prefix Card" filter="true" [options]="prefixArray" formControlName="titleOnCard">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 3%;">
                                                        <label for="exampleInputEmail1">First Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="First Name" formControlName="firstNameOnCard">
                                                        <small class="text-danger" *ngIf="!!creditCardForm.controls.firstNameOnCard.invalid && !!creditCardForm.controls.firstNameOnCard.touched">First
                              Name is Required</small>
                                                    </div>
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 0%;">
                                                        <label for="exampleInputEmail1">Middle Name Card</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Middle Name Card" formControlName="middleNameOnCard">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 3%;">
                                                        <label for="exampleInputEmail1">Last Name Card <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Last Name Card" formControlName="lastNameOnCard">
                                                        <small class="text-danger" *ngIf="!!creditCardForm.controls.lastNameOnCard.invalid && !!creditCardForm.controls.lastNameOnCard.touched">Last
                              Name is Required</small>
                                                    </div>

                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 0%;">
                                                        <label for="exampleInputEmail1">Suffix On Card
                            </label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown placeholder="Suffix On Card" filter="true" [options]="suffixArray" formControlName="suffixOnCard">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 3%;">
                                                        <label for="exampleInputEmail1">Card Type
                            </label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown placeholder="Card Type" filter="true" [options]="cardTypes" formControlName="cardType">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 0%;">
                                                        <label for="exampleInputEmail1">Card Number</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="cardNumber" aria-describedby="emailHelp" placeholder="Card Number">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 3%;">
                                                        <label for="exampleInputEmail1">Expiration Date <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown placeholder="Month" filter="true" [options]="expirationMonth" formControlName="expMonth">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!creditCardForm.controls.expMonth.invalid && !!creditCardForm.controls.expMonth.touched">Expiration
                              Month is Required</small>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown class="ml-2" placeholder="Year" filter="true" [options]="expirationYear" formControlName="expYear">
                                                        </p-dropdown>


                                                        <small class="text-danger" *ngIf="!!creditCardForm.controls.expYear.invalid && !!creditCardForm.controls.expYear.touched">Expiration
                              Year is Required</small>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 3%;">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">
                                                    </div>
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 0%;">
                                                        <label for="">Billing Address</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <ng-container *ngIf="accountData?.address;else noData">
                                                            <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="getAddress($event,accountData?.address.contactMechId)">
                                                            <label class="form-check-label" for="exampleCheck1">
                                To:<span>{{accountData?.address.address1}}</span>,
                                <span>{{accountData?.address.address2}}</span>,
                                <span>{{accountData?.address.city}}</span>,
                                <span>{{accountData?.address.stateProvinceGeoId}}</span>,
                                <span>{{accountData?.address.countryId}}</span>,
                                <span>{{accountData?.address.postalCode}}</span>
                              </label>
                                                        </ng-container>
                                                        <ng-template #noData>
                                                            No contact information on file.
                                                        </ng-template>
                                                    </div>


                                                </div>
                                            </div>



                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 61%;">
                                        <button type="submit" *ngIf="editMode" (click)="updateCredit()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submitCredit()" class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalGift" tabindex="-1" role="dialog" aria-labelledby="exampleModalGiftTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create-Gift-Card</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton9>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Create Gift Card</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="giftForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 2%;">
                                                        <label for="exampleInputEmail1">Card Number <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="cardNumber" aria-describedby="emailHelp" placeholder="Card Number">
                                                        <small class="text-danger" *ngIf="!!giftForm.controls.cardNumber.invalid && !!giftForm.controls.cardNumber.touched">Card
                              Number is Required</small>
                                                    </div>
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      ">
                                                        <label for="exampleInputEmail1">PIN number <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Pin Number" formControlName="pinNumber">
                                                        <small class="text-danger" *ngIf="!!giftForm.controls.pinNumber.invalid && !!giftForm.controls.pinNumber.touched">PIN
                              Number is Required</small>
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: 2%;">
                                                        <label for="exampleInputEmail1">Expiration Date <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns" >
                                                        <p-dropdown placeholder="Month" filter="true" [options]="expirationMonth" formControlName="expMonth">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!giftForm.controls.expMonth.invalid && !!giftForm.controls.expMonth.touched">Expiration
                              Month is Required</small>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown class="ml-2" placeholder="Year" filter="true" [options]="expirationYear" formControlName="expYear">
                                                        </p-dropdown>


                                                        <small class="text-danger" *ngIf="!!giftForm.controls.expYear.invalid && !!giftForm.controls.expYear.touched">Expiration
                              Year is Required</small>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 14px!important;
                                      margin-left: 2%;">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 53%;">
                                        <button type="submit" *ngIf="editMode" (click)="updateGift()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submitGift()" class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalEFT" tabindex="-1" role="dialog" aria-labelledby="exampleModalEFTTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create-EFT-Account
        </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton10>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Create EFT Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="eftAccountForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -7%;">
                                                        <label for="exampleInputEmail1">Name Account <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Name Account" formControlName="nameOnAccount">
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.nameOnAccount.invalid && !!eftAccountForm.controls.nameOnAccount.touched">Name
                              Account is Required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                     ">
                                                        <label for="exampleInputEmail1">Company Name On Account</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Company Name On Account" formControlName="companyNameOnAccount">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -7%;">
                                                        <label for="exampleInputEmail1">Bank Name <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Bank Name" formControlName="bankName">
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.bankName.invalid && !!eftAccountForm.controls.bankName.touched">Bank
                              Name is Required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      ">
                                                        <label for="exampleInputEmail1">Routing Number <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Routing Number" formControlName="routingNumber">
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.routingNumber.invalid && !!eftAccountForm.controls.routingNumber.touched">Routing
                              Number is Required</small>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -7%;">
                                                        <label for="exampleInputEmail1">Account Type <span class="text-danger">*</span>
                            </label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown placeholder="Account Type" filter="true" [options]="accountTypes" formControlName="accountType">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.accountType.invalid && !!eftAccountForm.controls.accountType.touched">Account
                              Type is Required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      ">
                                                        <label for="exampleInputEmail1">Account Number <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Account Number" formControlName="accountNumber">
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.accountNumber.invalid && !!eftAccountForm.controls.accountNumber.touched">Account
                              Number is Required</small>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -7%;">
                                                        <label for="exampleInputEmail1">Description <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.description.invalid && !!eftAccountForm.controls.description.touched">Description
                              is Required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                   ">
                                                        <label for="">Billing Address</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <ng-container *ngIf="accountData?.address;else noData">
                                                            <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="getAddress($event,accountData?.address.contactMechId)">
                                                            <label class="form-check-label" for="exampleCheck1">
                                To:<span>{{accountData?.address.address1}}</span>,
                                <span>{{accountData?.address.address2}}</span>,
                                <span>{{accountData?.address.city}}</span>,
                                <span>{{accountData?.address.stateProvinceGeoId}}</span>,
                                <span>{{accountData?.address.countryId}}</span>,
                                <span>{{accountData?.address.postalCode}}</span>
                              </label>
                                                        </ng-container>
                                                        <ng-template #noData>
                                                            No contact information on file.
                                                        </ng-template>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 60%;">
                                        <button type="submit" *ngIf="editMode" (click)="updateEFT()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submitEFT()" class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCheck" tabindex="-1" role="dialog" aria-labelledby="exampleModalCheckTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create-Check-Account</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton11>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Create Check Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="eftAccountForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -1%;">
                                                        <label for="exampleInputEmail1">Name Account <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Name Account" formControlName="nameOnAccount">
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.nameOnAccount.invalid && !!eftAccountForm.controls.nameOnAccount.touched">Name
                              Account is Required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                  ">
                                                        <label for="exampleInputEmail1">Company Name On Account</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Company Name On Account" formControlName="companyNameOnAccount">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -1%;">
                                                        <label for="exampleInputEmail1">Bank Name <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Bank Name" formControlName="bankName">
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.bankName.invalid && !!eftAccountForm.controls.bankName.touched">Bank
                              Name is Required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                  ">
                                                        <label for="exampleInputEmail1">Routing Number <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Routing Number" formControlName="routingNumber">
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.routingNumber.invalid && !!eftAccountForm.controls.routingNumber.touched">Routing
                              Number is Required</small>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -1%;">
                                                        <label for="exampleInputEmail1">Payment Method Type <span class="text-danger">*</span>
                            </label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown placeholder="Account Type" filter="true" [options]="paymentMethodTypes" formControlName="paymentMethodTypeId">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.paymentMethodTypeId.invalid && !!eftAccountForm.controls.paymentMethodTypeId.touched">Payment
                              Method
                              Type is Required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                     ">
                                                        <label for="exampleInputEmail1">Account Type <span class="text-danger">*</span>
                            </label>

                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown placeholder="Account Type" filter="true" [options]="accountTypes" formControlName="accountType">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.accountType.invalid && !!eftAccountForm.controls.accountType.touched">Account
                              Type is Required</small>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -1%;">
                                                        <label for="exampleInputEmail1">Account Number <span class="text-danger">*</span></label>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Account Number" formControlName="accountNumber">
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.accountNumber.invalid && !!eftAccountForm.controls.accountNumber.touched">Account
                              Number is Required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                  ">
                                                        <label for="exampleInputEmail1">Description <span class="text-danger">*</span></label>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">
                                                        <small class="text-danger" *ngIf="!!eftAccountForm.controls.description.invalid && !!eftAccountForm.controls.description.touched">Description
                              is Required</small>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -1%;">
                                                        <label for="">Billing Address</label>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <ng-container *ngIf="accountData?.address;else noData">
                                                            <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="getAddress($event,accountData?.address.contactMechId)">
                                                            <label class="form-check-label" for="exampleCheck1">
                                To:<span>{{accountData?.address.address1}}</span>,
                                <span>{{accountData?.address.address2}}</span>,
                                <span>{{accountData?.address.city}}</span>,
                                <span>{{accountData?.address.stateProvinceGeoId}}</span>,
                                <span>{{accountData?.address.countryId}}</span>,
                                <span>{{accountData?.address.postalCode}}</span>
                              </label>
                                                        </ng-container>
                                                        <ng-template #noData>
                                                            No contact information on file.
                                                        </ng-template>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 67%;">
                                        <button type="submit" *ngIf="editMode" (click)="updateCheck()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submitCheck()" class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalBilling" tabindex="-1" role="dialog" aria-labelledby="exampleModalBillingTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create-Billing-Account</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton12>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Create Billing Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="billingAccountForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -4%;">
                                                        <label for="exampleInputEmail1">Account Limit</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Account Limit" formControlName="accountLimit">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                     ">
                                                        <label for="exampleInputEmail1">Account Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown placeholder="Account Currency Uom ID" filter="true" [options]="currencyUomIds" formControlName="accountCurrencyUomId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -4%;">
                                                        <label for="exampleInputEmail1">Contact Mech ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Contact Mech ID" formControlName="contactMechId">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      ">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" placeholder="From Date" formControlName="fromDate">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -4%;">
                                                        <label for="exampleInputEmail1">Through Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" placeholder="Through Date" formControlName="thruDate">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                          ">
                                                        <label for="exampleInputEmail1">Description
                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Description" formControlName="description">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -4%;">
                                                        <label for="exampleInputEmail1">External Account ID
                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="External Account ID" formControlName="externalAccountId">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      ">
                                                        <label for="exampleInputEmail1">Party ID <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown placeholder="Party ID" filter="true" [options]="partyIds" formControlName="partyId">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!billingAccountForm.controls.partyId.invalid && !!billingAccountForm.controls.partyId.touched">Party
                              ID is Required</small>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                      padding-right: 17px!important;
                                      margin-left: -4%;">
                                                        <label for="exampleInputEmail1">Available Balance</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p> ${{availableBalance}} <span> This is the amount which can be used
                                for new orders.</span></p>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 64%;">
                                        <button type="submit" *ngIf="editMode" (click)="updateBilling()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submitBilling()" class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- New Model added by RA -->

<div class="modal fade" id="createAccountsPopup" tabindex="-1" role="dialog" aria-labelledby="createAccountsPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create Accounts
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update Accounts
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton13>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create Accounts</a> 
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Accounts</a> 
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createAccountsForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Account Name	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="accountName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Account Name">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">City</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="city" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter City">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Primary Eamil</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="email" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Primary Eamil">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Primary Phone</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="primaryPhone" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Primary Phone">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createAccounts()">Create</button>
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn ml-2" (click)="updateAccounts()">Update</button>
                                            </div>
                                        </div>
                                    </form> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createOpportunityPopup" tabindex="-1" role="dialog" aria-labelledby="createOpportunityPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create Opportunity
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update Opportunity
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton14>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create Opportunity</a> 
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Opportunity</a> 
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCasesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Opportunity Name	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="opportunityName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Opportunity Name">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Stage</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="stage" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Stage">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Opportunity Amount
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="opportunityAmount" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Opportunity Amount">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Estimated Close Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="estimatedCloseDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createOpportunities()">Create</button>
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn ml-2" (click)="updateOpportunity()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="createOrderPopup" tabindex="-1" role="dialog" aria-labelledby="createOrderPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create Order
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update Order
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton15>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create Order</a> 
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Order</a> 
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createOrderForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Customer	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="customer" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Customer Name">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Amount</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="amount" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Amount">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Order Name
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="orderName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Order Name">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Order Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="orderDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Order Date">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">PO
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="po" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter PO">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Ship Before Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="shipBeforeDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Ship Before Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Status
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="status" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Status">
                                                    
                                                        
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createOrder()">Create</button>
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn ml-2" (click)="updateOrder()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="createCatalogRequestPop" tabindex="-1" role="dialog" aria-labelledby="createCatalogRequestPop" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create Catalog Request
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update Catalog Request
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton16>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create Catalog Request</a> 
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Catalog Request</a> 
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCatalogForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Activity	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="activity" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Activity">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Full Filled</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="fullFilled" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter FullFilled">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Taken By
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="takenBy" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Taken By">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="date" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Date">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Address
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="address" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Address">
                                                    
                                                        
                                                    </div>

                                                </div>
                                            </div>
                                           

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createCatalogRequest()">Create</button>
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn ml-2" (click)="UpdateCatalogRequest()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="createCasesNewPop" tabindex="-1" role="dialog" aria-labelledby="createCasesNewPop" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create Cases
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update Cases
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton17>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create Catalog Request</a> 
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Catalog Request</a> 
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCasesNewForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Priority	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="priority" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Priority">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Reason</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="reason" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Reason">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Status
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="status" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Status">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Subject</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text"  formControlName="subject" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Subject">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Type
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="type" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Type">
                                                    
                                                        
                                                    </div>

                                                </div>
                                            </div>
                                           

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createCasess()">Create</button>
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn ml-2" (click)="updateCasess()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="createNewTaskPopup" tabindex="-1" role="dialog" aria-labelledby="createOrderPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create New Task
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update New Task
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton18>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create New Task</a> 
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Task</a> 
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createNewTaskForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Activity	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="activity" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Activity">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Purpose</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="purpose" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter purpose">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Status
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="status" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Status">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text"  formControlName="type" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Type">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Scheduled Date
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="scheduledDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Scheduled Date">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Due Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="dueDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Due Date">
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createPendingActivityNewTask()">Create</button>
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn ml-2" (click)="updatePendingActivityNewTask()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="createActivitiesHistoryPopup" tabindex="-1" role="dialog" aria-labelledby="createActivitiesHistoryPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create Activities History
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update Activities History
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton19>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create Activities History</a> 
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Activities History</a> 
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createActivitiesHistoryForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Activity	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="activity" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Activity">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Purpose</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="purpose" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter purpose">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Status
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="status" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Status">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text"  formControlName="types" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Type">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Started Date
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="startedDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Started Date">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Completion Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="completionDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Completion Date">
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createActivityHistoryLogCall()">Create</button>
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn ml-2" (click)="updateActivityHistoryLogCall()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="createDocuandFilesPopup" tabindex="-1" role="dialog" aria-labelledby="createDocuandFilesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!this.show" class="color-black pl-1">Create Document Files
                </span>
                <span *ngIf="this.show" class="color-black pl-1">Update Document Files
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton20>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="!this.show">
                                    <a>Create Document Files</a> 
                                </li>
                                <li *ngIf="this.show">
                                    <a>Update Document Files</a> 
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createDocumentFilesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Name	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="name" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Name">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Classification</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="classification" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Classification">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Description">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="createdDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Created Date">
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createBookMarkUrl()">Create</button>
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn ml-2" (click)="updateBookMarkUrl()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createNotesPopup" tabindex="-1" role="dialog" aria-labelledby="createNotesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Notes 
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Notes</a>
                                </li>
                            </ul> 
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createNotesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Note Info	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="noteInfo" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Note Info">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Created By</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="createdBy" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Created By">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Note Date Time
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="noteDateTime" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                                    
                                                        
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" >Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>