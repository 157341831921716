<div class="container-fluid main-container-wrapper">
    <br>
   
    <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
           <span class="color-black pl-1 titlePanels">Reports
            </span>
        </div>
        <div class="w3-card-4 classCard" > 
        <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategoryMain==1?'active':''" (click)="PayablesRepots()"><a>
                    Payables Report</a></li>
                <li [ngClass]="activeCategoryMain==2?'active':''" (click)="ReceivablesReports()"><a>
                    Receivables Report</a></li>
                    <li [ngClass]="activeCategoryMain==3?'active':''" (click)="ogclReportList()"><a>
                       Organisation Report</a></li>
               
            </ul>
        </div>

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
              
                   
                                <div class="panel-group">
                                    <div *ngIf="activeCategoryMain==1">
                                       
                                       
                                        <div class="panel-collapse">
                                            <br>
                                            <div class="w3-card-4 classCard">
                                            <div class="container-fluid">
                                                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                                  <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                                    Past Due Invoices:
                                                    {{pastDueInvoiceTotal ? pastDueInvoiceTotal:0}}</a></li>
                                                      <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                                        Invoices Due Soon:
                                                        {{invoiceDueSoonTotal ? invoiceDueSoonTotal :0}}</a></li>
                                                </ul>
                                              </div>
                                              <br>
                                            <div class=" bg-white color-grey">
                                                <div class="create-new-leade mt-2">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100">
                                                                <div>
                                                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                                                    <div class="w-100">
                                                                        <div class="panel-group">
                                                                            <div *ngIf="activeCategory==1">
                                                                                <div class="header-tabs">
                                                                                 <h4 class=" common-styling">
                                                                                    Past Due Invoices:
                                                                                    {{pastDueInvoiceTotal ? pastDueInvoiceTotal:0}}
                                                                                 </h4>
                                                                               </div>
                                                                                    <div class="panel-body">
                                                                                        <div
                                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="pastDueInvoices" [paginator]="true"
                                                                                                    [rows]="rows" scrollWidth="100%"
                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                    [totalRecords]="total" [scrollable]="true"
                                                                                                    (sortFunction)="customSort($event)"
                                                                                                    [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th pSortableColumn="code"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    Invoice ID <p-sortIcon field="code">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                                pSortableColumn="name">
                                                                                                            <div style="color: white;"> Invoice Type<p-sortIcon field="name">
                                                                                                                </p-sortIcon></div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                                pSortableColumn="category"><div style="color: white;">  Invoice Date
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon></div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                                pSortableColumn="quantity"><div style="color: white;">  Status
                                                                                                                <p-sortIcon field="quantity">
                                                                                                                </p-sortIcon></div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div style="color: white;">  Description <p-sortIcon field="price">
                                                                                                                </p-sortIcon></div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div style="color: white;">  From Party<p-sortIcon field="price">
                                                                                                                </p-sortIcon></div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}"><div style="color: white;"> To
                                                                                                                Party</div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    style="visibility:hidden;" value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div style="color: white;">  Total</div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    style="visibility:hidden;" value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div style="color: white;">   Outstanding amount</div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    style="visibility:hidden;" value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div style="color: white;">  Created Date</div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    style="visibility:hidden;" value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'190px'}"
                                                                                                                [ngStyle]="{'width':'200px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    <span
                                                                                                                        routerLink="/financial/payable/invoices/detailed-summary"
                                                                                                                        [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                                         class="account-button">{{product.invoiceId}}</span>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.invoiceTypeId}}
                                                                                                            </td>
                                
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.invoiceDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.status }}</td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.description}}</td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.partyIdFrom}}</td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.toParty}}</td>
                                
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.total |currency:'USD'}}</td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.outstandingAmount |currency:'USD'}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                                    <h4 class="ml-2">No Record Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
                                                                                              
                                                                                                <p class="paginate_data">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            </div>
                                                                            <div *ngIf="activeCategory==2">
                                                                                <div class="header-tabs">
                                                                                 <h4 class=" common-styling">
                                                                                    Invoices Due Soon:
                                                                                            {{invoiceDueSoonTotal ? invoiceDueSoonTotal :0}}
                                                                                 </h4>
                                                                               </div>
                                                                                    <div class="panel-body">
                                                                                        <div
                                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="invoiceDueSoon" [paginator]="true"
                                                                                                    [rows]="rows" scrollWidth="100%"
                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                    [totalRecords]="total" [scrollable]="true"
                                                                                                    (sortFunction)="customSort($event)"
                                                                                                    [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th pSortableColumn="code"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    Invoice ID <p-sortIcon field="code">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div style="color: white;">  Invoice Type<p-sortIcon field="name">
                                                                                                                </p-sortIcon></div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                                pSortableColumn="category"> <div style="color: white;"> Invoice Date
                                                                                                                <p-sortIcon field="category">
                                                                                                                </p-sortIcon></div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                                pSortableColumn="quantity"><div style="color: white;">  Status
                                                                                                                <p-sortIcon field="quantity">
                                                                                                                </p-sortIcon></div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div style="color: white;">   Description <p-sortIcon field="price">
                                                                                                                </p-sortIcon></div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div style="color: white;">   From Party<p-sortIcon field="price">
                                                                                                                </p-sortIcon></div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value="" autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}"><div style="color: white;"> To
                                                                                                                Party</div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    style="visibility:hidden;" value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div style="color: white;">   Total</div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    style="visibility:hidden;" value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div style="color: white;">   Outstanding amount</div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    style="visibility:hidden;" value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th pSortableColumn="price"
                                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                                <div style="color: white;">   Created Date</div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    style="visibility:hidden;" value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'190px'}"
                                                                                                                [ngStyle]="{'width':'200px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    <span
                                                                                                                        routerLink="/financial/payable/invoices/detailed-summary"
                                                                                                                        [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                                         class="account-button">{{product.invoiceId}}</span>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.invoiceTypeId}}
                                                                                                            </td>
                                
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.invoiceDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.status }}</td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.description}}</td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.partyIdFrom}}</td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.toParty}}</td>
                                
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.total |currency:'USD'}}</td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.outstandingAmount |currency:'USD'}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                                {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                                    <h4 class="ml-2">No Record Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
                                                                                               
                                                                                                <p class="paginate_data">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                

                                </div>
                          
            
            </div>
        </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 