<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Product Id: {{this.productId }}</span>
               <span class="d-flex">
     
                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>

            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="w3-card-4 classCardA ">

                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div class="create-new-leade ">
                                                    <div class="container-fluid">


                                                        <div class="panel-group">
                                                            <div>
                                                                <div class="divA">
                                                                    <div class="w3-card-4 classCardView mt-3">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Choose Replacement Image
                                                                            </h4>
                                                                        </div>
                                                                        <div class="panel-body allStyleUser">
                                                                            <div class="card own-account-table borderTable">
                                                                                <p-table [value]="manageList" [paginator]="false" [rows]="rows"   scrollHeight="150px"   scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width': '250px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                   Image
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
    
                                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Image Name
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    From Date
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
    
                                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                              Status 
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
    
                                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    View
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{ 'width': '250px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    {{product.objectInfo}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">  {{product.dataResourceName}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">  {{product.createdDate}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">  {{product.statusId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                                                                (click)="viewImage(product)">
                                                                                                View</button>
                                                                                            </td>
                                                                                            
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">
                                                                                                        No
                                                                                                        Record
                                                                                                        Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                           
                                                                                <p class="paginate_data">
                                                                                    View per page

                                                                                </p>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="divB">
                                                                    <div class="w3-card-4 classCardView mt-3">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hleft">
                                                                                Choose Image to Replace
                                                                            </h4>
                                                                        </div>

                                                                        <div class="panel-body allStyleUser">
                                                                            <div class="card own-account-table borderTable">
                                                                                <p-table [value]="manageList" [paginator]="false" [rows]="rows"   scrollHeight="150px"   scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width': '250px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                   Image
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
    
                                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Image Name
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    From Date
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
    
                                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                              Status 
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
    
                                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    View
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                           
                                                                                           
    
    
    
    
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{ 'width': '250px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    {{product.objectInfo}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">  {{product.dataResourceName}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">  {{product.createdDate}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">  {{product.statusId}}
                                                                                            </td>
                                                                                            <td  [ngStyle]="{'width':'180px'}">
                                                                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                                                                (click)="viewImage(product)">
                                                                                                View</button>
                                                                                            </td>
                                                                                            
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">
                                                                                                        No
                                                                                                        Record
                                                                                                        Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                           
                                                                                <p class="paginate_data">
                                                                                    View per page

                                                                                </p>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                   
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>