<div class="container-fluid main-container-wrapper">
    <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels">Category Summary </span>
       
       <span class="d-flex">

        <button type="submit" (click)="homeButton();"
            class=" btn btn-outline-secondary mr-2">Home</button>

        <button type="submit" (click)="cancelSubmit();"
            class="btn btn-danger buttonclass ">Back</button>
    </span>

    </div>
    <div class="" style="margin-top: -2%">
        <div class="">
            <div class="w-100">
                <br>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid scrolling-container">
                        <ul class="tabbing-section scolling-tabs tabbing-accordians tabClass" style="padding-left: 9px;width: 105% !important;
                    flex-wrap: wrap !important;
                    display: flex !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                    Category Information</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                    Content</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                                    Create Category Attributes </a></li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                                    Category Attributes</a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                                    Create Category Link </a></li>

                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                                    Category Links</a></li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategoryFunction(7)"><a>
                                    Add Product Category Member </a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategoryFunction(8)"><a>
                                    Expire All Product Members </a></li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategoryFunction(9)"><a>
                                Remove Expired Product Members </a></li>
                            <li [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategoryFunction(10)"><a>
                                Apply Features From Category </a></li>
                            <li [ngClass]="activeCategory==11?'active':''" (click)="changeactiveCategoryFunction(11)"><a>
                                Category Feature Categories </a></li>
                            <li [ngClass]="activeCategory==12?'active':''" (click)="changeactiveCategoryFunction(12)"><a>
                                Apply Features To Category </a></li>
                            <li [ngClass]="activeCategory==13?'active':''" (click)="changeactiveCategoryFunction(13)"><a>
                                Apply Features From Category List </a></li>
                            <li [ngClass]="activeCategory==14?'active':''" (click)="changeactiveCategoryFunction(14)"><a>
                                Catalogs </a></li>
                            <li [ngClass]="activeCategory==15?'active':''" (click)="changeactiveCategoryFunction(15)"><a>
                                Parties </a></li>
                            <li [ngClass]="activeCategory==16?'active':''" (click)="changeactiveCategoryFunction(16)"><a>
                                Rollup</a></li>
    

                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                        <div class="w-100">
                            <div class="panel-group">
                                <div *ngIf="activeCategory==1">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Category Information
                                        </h4>
                                        <span class="edit-buttons hCreate">

                                            <!-- <a   data-toggle="modal" (click)="edit();" -->
                                            <a *ngIf="!this.Hidebtn" data-toggle="modal" data-target="#exampleModalCenter">Update</a>
                                        </span>
                                        <img *ngIf="category?.categoryImageUrl" alt="small image"
                                            class="mr-2 float-right img-thumbnail" [src]="category?.categoryImageUrl">
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                                <div class="row">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Category [ID]</p>
                                                            <span
                                                                class="color-grey pl-20 col-lg-10 col-12">{{category.productCategoryId
                                                                ? category.productCategoryId:'N/A'}}</span>
                                                        </div>
                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Category Name</p>
                                                            <span
                                                                class="color-grey pl-20 col-lg-10 col-12">{{category.categoryName
                                                                ? category.categoryName:'N/A'}}</span>

                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Detail Screen</p>
                                                            <span
                                                                class="color-grey pl-20 col-lg-10 col-12">{{category.detailScreen
                                                                ? category.detailScreen:'N/A'}}</span>
                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Link 1 image Url</p>
                                                            <span
                                                                class="color-grey pl-20 col-lg-10 col-12">{{category.linkOneImageUrl
                                                                ? category.linkOneImageUrl:'N/A'}}</span>
                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Link 2 image Url</p>
                                                            <span
                                                                class="color-grey pl-20 col-lg-10 col-12">{{category.linkTwoImageUrl
                                                                ? category.linkTwoImageUrl:'N/A'}}</span>
                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Primary Parent Category Id</p>
                                                            <span
                                                                class="color-grey pl-20 col-lg-10 col-12">{{category.primaryParentCategoryId
                                                                ? category.primaryParentCategoryId:'N/A'}}</span>
                                                        </div>


                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Product Category Type Id</p>
                                                            <span
                                                                class="color-grey pl-20 col-lg-10 col-12">{{category.productCategoryTypeId
                                                                ? category.productCategoryTypeId:'N/A'}}</span>

                                                        </div>

                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Description</p>
                                                            <span
                                                                class="color-grey pl-20 col-lg-10 col-12">{{category.description
                                                                ? category.description:'N/A'}}</span>
                                                        </div>



                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==2">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Content
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">

                                                <div class="card mb-5">
                                                    <div class="card-header">
                                                        <h4>{{editModeContent?'Update':'Create'}} Category Content</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <form [formGroup]="categoryContentForm">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">

                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="contentId">Content Id <span
                                                                                    style="color:red">*</span></label>
                                                                        </div>

                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="contentIds"
                                                                                formControlName="contentId"
                                                                                placeholder="Content Id">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-4 form-group classInputA">
                                                                            <label for="prodCatContentType">Product
                                                                                Category
                                                                                Content
                                                                                Type
                                                                                Id</label>
                                                                        </div>

                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="prodCatContentType"
                                                                                formControlName="prodCatContentTypeId"
                                                                                placeholder="Product Category Content Type Id">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">

                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="fromDate">From Date</label>
                                                                        </div>

                                                                        <div class="col-lg-2">
                                                                            <!-- <p-calendar formControlName="fromDate"
                                                                                placeholder="From Date">
                                                                            </p-calendar> -->
                                                                            <input type="text" class="form-control"
                                                                                formControlName="fromDate"
                                                                                placeholder="Enter From Date">
                                                                        </div>
                                                                        <div class="col-lg-4 form-group classInputA">
                                                                            <label for="throughDate">Through
                                                                                Date</label>
                                                                        </div>

                                                                        <div class="col-lg-2">
                                                                            <!-- <p-calendar formControlName="thruDate"
                                                                                placeholder="Through Date">
                                                                            </p-calendar> -->
                                                                            <input type="text" class="form-control"
                                                                            formControlName="thruDate"
                                                                            placeholder="Enter Thru Date">
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">

                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="countLimit">Use Count
                                                                                Limit</label>
                                                                        </div>

                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="useCountLimit"
                                                                                placeholder="Enter Use Count Limit">
                                                                        </div>
                                                                        <div class="col-lg-4 form-group classInputA">
                                                                            <label for="daysLimit">Use Days
                                                                                Limit</label>
                                                                        </div>

                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="useDaysLimit"
                                                                                placeholder="Enter Use Days Limit">
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12">
                                                                    <div class="main-submit-button" style="
                                                                margin-right: 21%;">
                                                                        <button type="submit" (click)="submitContent()"
                                                                            class="btn btn-secondary submit-btn">{{editModeContent
                                                                            ?'Update':'Submit'}}
                                                                            Content</button>

                                                                        <button *ngIf="!this.Hidebtn" type="button"
                                                                            (click)="resetPreparedContent()"
                                                                            class="btn btn-danger ml-2">Cancel</button>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div class="card mb-5">
                                                    <div class="card-header">
                                                        <h4>Content List</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="categoryContents" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Content ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'220px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Product
                                                                                    Category Content Type Id
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> From Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Through Date
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Use Count
                                                                                    Limit
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Use Days
                                                                                    Limit
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;"> Action</div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.contentId}}</td>
                                                                            <td [ngStyle]="{'width':'220px'}">
                                                                                {{product.prodCatContentTypeDescription}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.fromDate | date:'yyyy-MM-dd   HH:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.thruDate | date:'yyyy-MM-dd   HH:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.useCountLimit}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.useDaysLimit}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div *ngIf="!this.Hidebtn">
                                                                                    <svg (click)="makeContentEditable(product.contentId,product.prodCatContentTypeId,product.fromDate,product.thruDate,product.useCountLimit,product.useDaysLimit,product.purchaseFromDate,product.purchaseThruDate)"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="16" height="16"
                                                                                        viewBox="0 0 21.223 21.222">
                                                                                        <g
                                                                                            transform="translate(0 -0.004)">
                                                                                            <g
                                                                                                transform="translate(0 1.52)">
                                                                                                <path
                                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                    transform="translate(0 -34.137)" />
                                                                                            </g>
                                                                                            <g
                                                                                                transform="translate(4.548 0.004)">
                                                                                                <g
                                                                                                    transform="translate(0 0)">
                                                                                                    <path
                                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                    <svg (click)="deleteContent(product.prodCatContentTypeId,product.contentId,product.fromDate)"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        class="ml-20" width="16"
                                                                                        height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }

                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==3">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            {{editModeAttribute?'Update':'Create'}} Category Attributes
                                        </h4>
                                    </div>

                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                                <form [formGroup]="categoryAttributeForm" (submit)="submitAttribute()">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInputB">
                                                                    <label for="categoryId">Product Category ID</label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <input type="text" id="categoryId"
                                                                        class="form-control"
                                                                        formControlName="productCategoryId"
                                                                        placeholder="Product Category ID">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="attrName">Attr Name</label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <input type="text" id="attrName"
                                                                        class="form-control" formControlName="attrName"
                                                                        placeholder="Attr Name">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInputB">
                                                                    <label for="attrValue">Att Value</label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <input type="text" id="attrValue"
                                                                        class="form-control" formControlName="attrValue"
                                                                        placeholder="Att Value">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="attrDescription">Att Description</label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <input type="text" id="attrDescription"
                                                                        class="form-control"
                                                                        formControlName="attrDescription"
                                                                        placeholder="Att Description">
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div class="col-md-12 float-right">
                                                        <div class="main-submit-button" style="    margin-right: 25%;">
                                                            <button type="submit"
                                                                class="btn btn-secondary submit-btn">{{editModeAttribute?'Update':'Create'}}Attribute</button>

                                                            <button *ngIf="!this.Hidebtn" type="button" (click)="resetCategoryLinks()"
                                                                class="btn btn-danger ml-2">Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==6">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Category Attributes
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="card own-account-table">
                                                <p-table [value]="categoryAttributes" [paginator]="true" [rows]="rows"
                                                    scrollWidth="100%"
                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                    Attribute Name
                                                                    <p-sortIcon field="code">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                <div style="color: white;"> Attribute Value
                                                                    <p-sortIcon field="name">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;"> Attribute Description
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                <div style="color: white;"> Action
                                                                </div>
                                                                <div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                {{product.attrName}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                {{product.attrValue}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                {{product.attrDescription}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                <div *ngIf="!this.Hidebtn">
                                                                    <svg (click)="makeAttributeEditable(product.attrDescription,product.attrName,product.attrValue)"
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    <svg (click)="deleteAttribute(product.attrName)"
                                                                        xmlns="http://www.w3.org/2000/svg" class="ml-20"
                                                                        width="16" height="16" viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==4">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Create Category Link
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <form [formGroup]="categoryLinkForm" (submit)="submitLink()">
                                                <ng-template [ngIf]="editModeLink">
                                                    <div class="text-center">{{linkSeqId}}</div>
                                                </ng-template>
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="fromDate">From Date</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <p-calendar formControlName="fromDate"></p-calendar>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="throughDate">Through Date</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <p-calendar formControlName="thruDate"></p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="comments">Comments</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    formControlName="comments" placeholder="Comments">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="sequence">Sequence</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    formControlName="sequenceNum"
                                                                    placeholder="Sequence">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="titleText">Title Text</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    formControlName="titleText"
                                                                    placeholder="Title Text">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="detailText">Detail Text</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    formControlName="detailText"
                                                                    placeholder="Detail Text">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="imageUrl">Image Url</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    formControlName="imageUrl" placeholder="Image Url">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="imageUrlTwo">Image Url Two</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    formControlName="imageTwoUrl"
                                                                    placeholder="Image Url Two">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="linkType">Link Type ENUM</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <ng-select [items]="linkTypeEnum"
                                                                    bindLabel="description" bindValue="enumId"
                                                                    formControlName="linkTypeEnumId">
                                                                </ng-select>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="linkInfo">Link Info</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    formControlName="linkInfo" placeholder="Link Info">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="detailSubScreen">Detail Sub Screen</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                    formControlName="detailSubScreen"
                                                                    placeholder="Detail Sub Screen">
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div class="col-md-12 float-right">
                                                        <div class="main-submit-button" style="margin-right: 28%;">
                                                            <button type="submit"
                                                                class="btn btn-secondary submit-btn">Create
                                                                Link</button>

                                                            <button *ngIf="!this.Hidebtn" type="button" (click)="resetLinks()"
                                                                class="btn btn-danger ml-2">Cancel</button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 float-right">

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==5">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Category Links
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="card own-account-table">
                                                <p-table [value]="categoryLinks" [paginator]="true" [rows]="rows"
                                                    scrollWidth="100%"
                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                    Link Seq Id
                                                                    <p-sortIcon field="code">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;"> From Date
                                                                    <p-sortIcon field="name">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'150px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;"> Through date
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'150px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;"> Comments
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'150px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;"> Sequence
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'150px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;"> Title Text
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                <div style="color: white;"> Action
                                                                </div>
                                                                <div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
                                                        <tr>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                <span>Link Seq Id: {{product.linkSeqId}}</span></td>
                                                            <td [ngStyle]="{'width':'150px'}">
                                                                {{product.fromDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                                                            <td [ngStyle]="{'width':'150px'}">
                                                                {{product.thruDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'150px'}">{{product.comments}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'150px'}">{{product.sequenceNum}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'150px'}">{{product.titleText}}
                                                            </td>
                                                            <td>
                                                                <div *ngIf="!this.Hidebtn">
                                                                    <svg (click)="makeLinkEditable(product.linkSeqId)"
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    <svg (click)="deleteLink(product.fromDate,product.linkSeqId)"
                                                                        xmlns="http://www.w3.org/2000/svg" class="ml-20"
                                                                        width="16" height="16" viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==7">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Add Product Category Member
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                                <form [formGroup]="categoryProductForm">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="productId">Product Id <span
                                                                            style="color:red">*</span></label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="productArray"
                                                                        optionlabel="label" formControlName="productId"
                                                                        placeholder="Product Id">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="fromDate">From Date<span
                                                                            style="color:red">*</span></label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <p-calendar formControlName="fromDate"></p-calendar>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="comments">Comments</label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="comments"
                                                                        placeholder="Comments">
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <ng-template [ngIf]="editModeProduct">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="quantity">Quantity</label>
                                                                    </div>

                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control"
                                                                            formControlName="quantity"
                                                                            placeholder="Quantity">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="sequence">Sequence</label>
                                                                    </div>

                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control"
                                                                            formControlName="sequenceNum"
                                                                            placeholder="Sequence">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="throughDate">Through Date</label>
                                                                    </div>

                                                                    <div class="col-lg-2">
                                                                        <p-calendar formControlName="thruDate">
                                                                        </p-calendar>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </ng-template>
                                                        <div class="col-md-12 float-right">
                                                            <div class="main-submit-button" style="margin-right: 30%;">
                                                                <button class="btn btn-secondary submit-btn"
                                                                    (click)="submitProduct()">{{editModeProduct
                                                                    ?'Update':'Add'}}
                                                                </button>



                                                                <button *ngIf="!this.Hidebtn" type="button" (click)="resetProduct()"
                                                                    class="btn btn-danger ml-2">Cancel</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </form>
                                                <div class="card-mt-5">
                                                    <div class="card-header">
                                                        <h4>Product List</h4>
                                                    </div>
                                                    <div class="card-body">

                                                        <div>

                                                        </div>
                                                        <div class="card own-account-table">
                                                            <p-table [value]="categoryProducts" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                Product Id
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;"> Product Name
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;"> From Date Time
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;"> Through Date
                                                                                Time
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;"> Sequence
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;"> Quantity
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;"> Comments
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Action</div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product
                                                                    let-rowIndex="rowIndex">
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.productId}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.productName }}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fromDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.thruDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.sequenceNum }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.quantity }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.comments}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <div *ngIf="!this.Hidebtn">
                                                                                <svg (click)="makeProductEditable(product.productId,product.fromDate,product.comments,product.quantity,product.sequenceNum)"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="16" height="16"
                                                                                    viewBox="0 0 21.223 21.222">
                                                                                    <g transform="translate(0 -0.004)">
                                                                                        <g
                                                                                            transform="translate(0 1.52)">
                                                                                            <path
                                                                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                transform="translate(0 -34.137)" />
                                                                                        </g>
                                                                                        <g
                                                                                            transform="translate(4.548 0.004)">
                                                                                            <g
                                                                                                transform="translate(0 0)">
                                                                                                <path
                                                                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                    transform="translate(-102.409 -0.004)" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                                <svg (click)="deleteProduct(product.fromDate,product.productId)"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    class="ml-20" width="16" height="16"
                                                                                    viewBox="0 0 26 26">
                                                                                    <defs>
                                                                                        <style>
                                                                                            .a {
                                                                                                fill: #f44336;
                                                                                            }

                                                                                            .b {
                                                                                                fill: #fafafa;
                                                                                                margin-left: 20px;
                                                                                            }
                                                                                        </style>
                                                                                    </defs>
                                                                                    <path class="a fill-color"
                                                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                    <path class="b"
                                                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                        transform="translate(-147.576 -147.576)" />
                                                                                </svg>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="activeCategory==8">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Expire All Product Members
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputE">
                                                                <label for="expirationDate">Optional Expiration
                                                                    Date</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <p-calendar (ngModel)="expireProductMember"
                                                                    [ngModelOptions]="{standalone:true}">
                                                                </p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button "
                                                        style="margin-left: -64%;">
                                                        <button style="margin-left: 80%;"
                                                            class="btn btn-secondary submit-btn"
                                                            (click)="submitToExpireProductMember()">Expire All</button>

                                                        <button *ngIf="!this.Hidebtn" type="button"
                                                            class="btn btn-danger buttonclass">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==9">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Remove Expired Product Members
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputF">
                                                                <label for="expirationDate">Optional Expired Before
                                                                    Date</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <p-calendar [(ngModel)]="removeExpiredProductMember"
                                                                    [ngModelOptions]="{standalone:true}"></p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-md-12 float-right">
                                                        <div class="main-submit-button" style="margin-left: 12%;">
                                                            <button class="btn btn-secondary submit-btn"
                                                                (click)="submitToRemoveExpiredProductMember()">Remove
                                                                Expired</button>
                                                            <button *ngIf="!this.Hidebtn"  type="button" style="margin-right: 72%;"
                                                                class="btn btn-danger buttonclass">Cancel</button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==10">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Apply Features From Category
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                                <form [formGroup]="createCategoryFeatureGroupForm">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <p-dropdown [options]="categoryFeatureGroups"
                                                                    formControlName="productFeatureGroupId"
                                                                    placeholder="Select Product Feature Group Id">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <p-calendar formControlName="fromDate"></p-calendar>
                                                            </div>
                                                        </div>
                                                        <ng-template [ngIf]="editModeCategoryFeatureGroup">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <p-calendar formControlName="thruDate"></p-calendar>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <div class="col-md-12">
                                                            <div class="float-right">
                                                                <button class="btn btn-secondary submit-btn"
                                                                    (click)="submitFeatureCategoryGroup()">{{editModeCategoryFeatureGroup
                                                                    ?'Update':'Add'}}</button>
                                                                <button *ngIf="!this.Hidebtn" class="btn btn-danger cursor-pointer ml-2"
                                                                    (click)="resetFeatureCategoryGroup()">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==11">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Category Feature Categories
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <button *ngIf="!this.Hidebtn" class="btn btn-primary mb-2"
                                                (click)="attachedProductFeatureCategory()">Attach Product feature
                                                to category</button>
                                            <div class="card own-account-table">
                                                <p-table [value]="categoryFeatureGroupApply" [paginator]="true"
                                                    [rows]="rows" scrollWidth="100%"
                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                    Group Id
                                                                    <p-sortIcon field="code">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                <div style="color: white;"> Group Name
                                                                    <p-sortIcon field="name">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;"> From Date Time
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;"> Through Date Time
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                <div style="color: white;"> Action</div>
                                                                <div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
                                                        <tr>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                {{product.productFeatureGroupId}}</td>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                {{product.description }}</td>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                {{product.fromDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                {{product.thruDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                <div *ngIf="!this.Hidebtn">
                                                                    <svg (click)="makeFeatureCategoryGroupEditable(product.fromDate,product.productFeatureGroupId,product.thruDate)"
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    <svg (click)="deleteFeatureCategoryGroup(product.productFeatureGroupId,product.fromDate)"
                                                                        xmlns="http://www.w3.org/2000/svg" class="ml-20"
                                                                        width="16" height="16" viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==12">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Apply Features To Category
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                                <form [formGroup]="categoryToFeatureForm">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <ng-select [items]="categoryFeature"
                                                                    bindLabel="description"
                                                                    bindValue="productFeatureCategoryId"
                                                                    placeholder="Select Product Feature Group Id"
                                                                    formControlName="productFeatureCategoryId">
                                                                </ng-select>
                                                                <small class="text-danger"
                                                                    *ngIf="!!categoryToFeatureForm.controls.productFeatureCategoryId.invalid && !!categoryToFeatureForm.controls.productFeatureCategoryId.touched">Required</small>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <p-calendar formControlName="fromDate"
                                                                    placeholder="Select Date"></p-calendar>
                                                            </div>
                                                        </div>
                                                        <ng-template [ngIf]="editModeCategoryToFeature">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <p-calendar formControlName="thruDate"></p-calendar>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <div class="col-md-12">
                                                            <div class="float-right">
                                                                <button class="btn btn-secondary submit-btn"
                                                                    (click)="submitCategoryToFeature()">{{editModeCategoryToFeature?'Update':'Add'}}</button>
                                                                <button *ngIf="!this.Hidebtn" class="btn btn-danger cursor-pointer ml-2"
                                                                    (click)="resetCategoryToFeature()">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==13">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling">
                                            Apply Features From Category List
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="card own-account-table">
                                                <p-table [value]="categoryToFeatures" [paginator]="true" [rows]="rows"
                                                    scrollWidth="100%"
                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                    Feature Category Id
                                                                    <p-sortIcon field="code">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;"> Feature Category Name
                                                                    <p-sortIcon field="name">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'150px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;"> From Date Time
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'150px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;"> Through Date Time
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price">
                                                                <div style="color: white;"> Action</div>
                                                                <div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
                                                        <tr>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.productFeatureCategoryId}}</td>
                                                            <td [ngStyle]="{'width':'150px'}">
                                                                {{product.description }}</td>
                                                            <td [ngStyle]="{'width':'150px'}">
                                                                {{product.fromDate | date :'medium'}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'150px'}">
                                                                {{product.thruDate | date :'medium'}}
                                                            </td>
                                                            <td>
                                                                <div *ngIf="!this.Hidebtn">
                                                                    <svg (click)="makeCategoryToFeatureEditable(product.productFeatureCategoryId,product.fromDate,product.thruDate)"
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    <svg (click)="deleteCategoryToFeature(product.productFeatureCategoryId,product.fromDate)"
                                                                        xmlns="http://www.w3.org/2000/svg" class="ml-20"
                                                                        width="16" height="16" viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==14" class="w-100">
                                    <div class="w3-card-4 classCard w-100">
                                        <div
                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                            <p class="h-text" style="color:grey;">Edit Category Product Catalogs </p>
                                            <span>
                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                    style="margin: 0;" data-toggle="modal"
                                                    data-target="#addCatalogspopup" (click)="resetCatalog()">Add a
                                                    Catalog </button>

                                            </span>
                                        </div>
                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="EditCategoryProdCatalogsData"
                                                            [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="code">
                                                                        <div class="checkbox-align"
                                                                            style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>

                                                                            Catalog Name [Id]
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Type
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            From Date Time
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Through Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Time Sequence
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Action
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span
                                                                                 class="account-button"  
                                                                                (click)="catalog_Page(product.productCategoryId)">
                                                                                {{product.productCategoryId}}
                                                                            </span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.prodCatalogCategoryTypeId}}
                                                                    </td>


                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.sequenceNum}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <span
                                                                             class="account-button"   *ngIf="!this.Hidebtn">
                                                                            <a  class="account-button"  data-toggle="modal"
                                                                                (click)="updateCatalog(product)"
                                                                                data-target="#addCatalogspopup">Update</a>&nbsp;&nbsp;&nbsp;<a
                                                                                (click)="category_removeProductCategoryFromProdCatalog(product)"
                                                                                 class="account-button" >Delete</a>
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                            <h4 class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==15" class="w-100">
                                    <div class="w3-card-4 classCard w-100">
                                        <div
                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                            <p class="h-text" style="color:grey;">Edit Category Parties </p>
                                            <span>
                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                    style="margin: 0;" data-toggle="modal"
                                                    data-target="#addPartypopup">Add Parties </button>

                                            </span>
                                        </div>
                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="productCategoryIdData" [paginator]="true"
                                                            [rows]="rows" scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="code">
                                                                        <div class="checkbox-align"
                                                                            style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>


                                                                            Party ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Role
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            From Date Time
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Through Date Time
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Action
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span
                                                                                 class="account-button"  
                                                                                (click)="catalog_Page(product.partyId)">
                                                                                {{product.partyId}}
                                                                            </span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.roleTypeId}}
                                                                    </td>


                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <span
                                                                             class="account-button"   *ngIf="!this.Hidebtn">
                                                                            <a  class="account-button"  data-toggle="modal"
                                                                                (click)="updateParty(product)"
                                                                                data-target="#addPartypopup">Update</a>&nbsp;&nbsp;&nbsp;<a
                                                                                (click)="deletePartyFromCategory(product)"
                                                                                 class="account-button" >Delete</a>
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                            <h4 class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="activeCategory==16" class="w-100">
                                    <div class="w3-card-4 classCard w-100">
                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                            <p class="h-text" style="color:grey;">Category Rollup : Parent Categories</p>
                                            <span>
                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                                                data-toggle="modal"   data-target="#addRollUpPopup"
                                                (click)="resetForm()">Add Category Parent Category</button>
                                               
                                        </span>
                                        </div>
                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                         <p-table [value]="ProductCategoryToCategory" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>

                                                                           
                                                                              
                                                                            Parent Category [ID]
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            From Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Through Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sequence
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                  
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Action
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span  class="account-button"  
                                                                             >
                                                                                {{product.dataNode.parentProductCategoryId}}
                                                                            </span>

                                                                        </div>
                                                                    </td>
                                                                  
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.dataNode.fromDate|date}}
                                                                    </td>
                                                                  

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.dataNode.thruDate|date}}
                                                                    </td>
                                                                   
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.dataNode.sequenceNum}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <span  class="account-button"   *ngIf="!this.Hidebtn">
                                                                            <a  class="account-button"  data-toggle="modal" (click)="getProductCategoryToCategoryPatch(product)"
                                                                             data-target="#addRollUpPopup">Update</a>&nbsp;&nbsp;&nbsp;
                                                                             <a (click)="removeProductCategoryFromCategory(product)"  class="account-button" >Delete</a>
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                    <h4
                                                                        class="ml-2">
                                                                        No Record
                                                                        Found</h4>
                                                                </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="w3-card-4 classCard w-100 mt-4">
                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                            <p class="h-text" style="color:grey;">Category Rollup : Child Categories</p>
                                            <span>
                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                                                data-toggle="modal"   data-target="#addRollUpChildPopup"
                                                (click)="resetForm()">Add Category Child Category</button>
                                               
                                        </span>
                                        </div>
                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                         <p-table [value]="productCategoryToCategorySecond" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                                 Child Category ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            From Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Through Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                   
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Sequence
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                  
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Action
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span  class="account-button"  
                                                                            >
                                                                                {{product.dataNode.productCategoryId}}
                                                                            </span>

                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.dataNode.fromDate|date}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.dataNode.thruDate|date}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.dataNode.sequenceNum}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <span  class="account-button"   *ngIf="!this.Hidebtn">
                                                                            <a  class="account-button"  data-toggle="modal" (click)="updateProductCategoryToCategorySPatch(product)"
                                                                             data-target="#addRollUpChildPopup">Update</a>&nbsp;&nbsp;&nbsp;
                                                                             <a (click)="removeProductCategoryFromCategory(product)"  class="account-button" >Delete</a>
                                                                    </span>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                    <h4
                                                                        class="ml-2">
                                                                        No Record
                                                                        Found</h4>
                                                                </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Product Category</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''" (click)="activeTab(1)"><a>
                                        Create Product Category</a></li>
                                <ng-container *ngIf="editMode">
                                    <li [ngClass]="activeCategory==2?'active':''" (click)="activeTab(2)"><a>
                                            Upload Category Image</a></li>
                                </ng-container>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <ng-container *ngIf="activeCategory==1">
                                        <form class="w-100" [formGroup]="categoryForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputHH">
                                                            <label for="exampleInputEmail1">Product Category ID</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="productCategoryId"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Product Category ID">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Product Category
                                                                Name</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="categoryName"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Product Category Name">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputHH">
                                                            <label for="exampleInputEmail1">Product Category Type<span
                                                                    style="color:red">*</span></label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <p-dropdown filter="true"
                                                                formControlName="productCategoryTypeId"
                                                                [options]="categoryArray" optionlabel="label"
                                                                placeholder="Product Category Type">
                                                            </p-dropdown>
                                                            <small class="text-danger"
                                                                *ngIf="!!categoryForm.controls.productCategoryTypeId.invalid && !!categoryForm.controls.productCategoryTypeId.touched"></small>
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Product Category
                                                                Description</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <textarea class="form-control" formControlName="description"
                                                                id="exampleFormControlTextarea1" rows="1"></textarea>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputHH">
                                                            <label for="exampleInputEmail1">Category Image URL </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="categoryImageUrl"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Category Image URL">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Link One Image URL </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="linkOneImageUrl"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Link One Image URL">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputHH">
                                                            <label for="exampleInputEmail1">Link Two Image URL</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="linkTwoImageUrl"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Link Two Image URL">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Detail Screen</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="detailScreen"
                                                                class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Detail Screen">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputHH">
                                                            <label for="exampleInputEmail1">Primary Parent
                                                                Category</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <ng-select [items]="categories"
                                                                plcaeholder="Primary Parent Category"
                                                                formControlName="primaryParentCategoryId"
                                                                bindLabel="categoryName" bindValue="productCategoryId"
                                                                class="ng-select-width"></ng-select>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                            <div class="main-submit-button" style="    margin-right: 9%;">
                                                <button type="submit" (click)="onSubmit()"
                                                    class="btn btn-secondary submit-btn">{{editMode
                                                    ?'Update':'Submit'}}</button>


                                            </div>
                                        </form>
                                    </ng-container>
                                    <ng-container *ngIf="activeCategory==2 && editMode">
                                        <form class="w-100">
                                            <div class="container row">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputHH">
                                                            <label for="">Category Image File</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="file" class="custom-file-input"
                                                                (change)="setImage($event)" id="inputGroupFile01">
                                                            <label class="custom-file-label"
                                                                for="inputGroupFile01">Choose
                                                                file</label>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio"
                                                            name="exampleRadios"
                                                            (click)="setCategoryImageType('category')"
                                                            id="exampleRadio1">
                                                        <label class="form-check-label" for="exampleRadio1">
                                                            Category Image URL
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio"
                                                            name="exampleRadios"
                                                            (click)="setCategoryImageType('linkOne')"
                                                            id="exampleRadio2">
                                                        <label class="form-check-label" for="exampleRadio2">
                                                            Link One Image URL
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio"
                                                            name="exampleRadios"
                                                            (click)="setCategoryImageType('linkTwo')"
                                                            id="exampleRadios1" value="option1">
                                                        <label class="form-check-label" for="exampleRadios1">
                                                            Link Two Image URL
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="d-flex justify-content-end">
                                                        <button style="margin-right: 31%;"
                                                            class="btn btn-primary cursor-pointer ml-2"
                                                            (click)="uploadImage()">Upload
                                                            Image</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="addCatalogspopup" tabindex="-1" role="dialog" aria-labelledby="addCatalogspopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Product Category</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Add a Catalog to this Category (select Catalog and Type,
                                        then enter optional From Date)</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">

                                    <form class="w-100" [formGroup]="catalogForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputHH">
                                                        <label for="exampleInputEmail1">
                                                            Catalog Name</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="prodCatalogId"
                                                            [options]="ProdCatalogIdArray" optionlabel="label"
                                                            *ngIf="!showCatalog" placeholder="Product Catalog Name">
                                                        </p-dropdown>
                                                        <input type="text" formControlName="prodCatalogId"
                                                            *ngIf="showCatalog" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder=" ">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Catalog Type</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true"
                                                            formControlName="prodCatalogCategoryTypeId"
                                                            [options]="catalogCategoryTypeIdArray" optionlabel="label"
                                                            *ngIf="!showCatalog" placeholder="Product Catalog Type">
                                                        </p-dropdown>
                                                        <input type="text" formControlName="prodCatalogCategoryTypeId"
                                                            *ngIf="showCatalog" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            >
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputHH">
                                                        <label for="exampleInputEmail1">From Date </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate"
                                                            *ngIf="!showCatalog" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter From Date ">
                                                        <input type="date" formControlName="fromDate" disabled
                                                            *ngIf="showCatalog" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter From Date ">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA" *ngIf="showCatalog">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate"
                                                            *ngIf="showCatalog" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="showCatalog">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputHH">
                                                        <label for="exampleInputEmail1">Sequence Number</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="sequenceNum"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num">
                                                    </div>

                                                </div>
                                            </div>



                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 9%;">
                                            <button type="submit" (click)="category_addProductCategoryToProdCatalog()"
                                                *ngIf="!showCatalog"
                                                class="btn btn-secondary submit-btn">Submit</button>
                                            <button type="submit"
                                                (click)="category_updateProductCategoryToProdCatalog()"
                                                *ngIf="showCatalog" class="btn btn-secondary submit-btn">Update</button>


                                        </div>
                                    </form>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="addPartypopup" tabindex="-1" role="dialog" aria-labelledby="addPartypopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Parties Category</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Associate Party to Category (enter Party ID, select Type, then enter optional
                                        From Date)</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">

                                    <form class="w-100" [formGroup]="partyForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputHH">
                                                        <label for="exampleInputEmail1">

                                                            Party ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="partyId"
                                                            [options]="partyIdArray" optionlabel="label"
                                                            *ngIf="!showParty" placeholder="Product Party ID ">
                                                        </p-dropdown>
                                                        <input type="text" formControlName="partyId" *ngIf="showParty"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder=" ">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Role </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="roleTypeId"
                                                            [options]="roleTypeIdArray" optionlabel="label"
                                                            *ngIf="!showParty" placeholder="Product Role">
                                                        </p-dropdown>
                                                        <input type="text" formControlName="roleTypeId"
                                                            *ngIf="showParty" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            >
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputHH">
                                                        <label for="exampleInputEmail1">From Date </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" *ngIf="!showParty"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date ">
                                                        <input type="date" formControlName="fromDate" disabled
                                                            *ngIf="showParty" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter From Date ">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA" *ngIf="showParty">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate" *ngIf="showParty"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 9%;">
                                            <button type="submit" (click)="createPartyToCategory()" *ngIf="!showParty"
                                                class="btn btn-secondary submit-btn">Submit</button>
                                            <button type="submit" (click)="updateProdCatalogToParty()" *ngIf="showParty"
                                                class="btn btn-secondary submit-btn">Update</button>


                                        </div>
                                    </form>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="addRollUpPopup" tabindex="-1" role="dialog" aria-labelledby="addRollUpPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="this.showNew" class="color-black pl-1">Add Category Parent Category</span>
                <span *ngIf="!this.showNew" class="color-black pl-1">Update Category Parent Category</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton4>&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.showNew"><a>
                                    Add Category Parent Category</a></li>
                                <li *ngIf="!this.showNew"><a>
                                    Update Category Parent Category</a></li>
                             

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                   
                                        <form class="w-100" [formGroup]="rollUpform">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputHH">
                                                            <label for="exampleInputEmail1">
                                                                Parent Category ID</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <p-dropdown filter="true" formControlName="parentCategoryID"
                                                             optionlabel="label"
                                                             placeholder="Enter Parent Category ID" [readonly]="readOnlyCode" [options]="allCategoryIdArray">
                                                            </p-dropdown>
                                                           
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">From Date</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                        
                                                            <input type="date" formControlName="fromDate" 
                                                            class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Date" [readonly]="readOnlyCode">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="this.hideInput" class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputHH">
                                                            <label for="exampleInputEmail1">
                                                                Through Date</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="date" formControlName="thruDate" 
                                                            class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter thruDate">
                                                        
                                                           
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Sequence Num</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                        
                                                            <input type="text" formControlName="sequenceNum" 
                                                            class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                



                                            </div>
                                            <div class="main-submit-button" style="    margin-right: 9%;">
                                                <button type="submit" (click)="addProductCategoryToCategory()"
                                                 *ngIf="this.showNew" class="btn btn-secondary submit-btn">Add</button>
                                                <button type="submit" (click)="updateProductCategoryToCategory()"
                                                 *ngIf="!this.showNew" class="btn btn-secondary submit-btn">Update</button>


                                            </div>
                                        </form>
                                 
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="addRollUpChildPopup" tabindex="-1" role="dialog" aria-labelledby="addRollUpPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="this.showNew" class="color-black pl-1">Add Category Child Category</span>
                <span *ngIf="!this.showNew" class="color-black pl-1">Update Category Child Category</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton5>&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.showNew"><a>
                                    Add Category Child Category</a></li>
                                <li *ngIf="!this.showNew"><a>
                                    Update Category Child Category</a></li>
                             

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                   
                                        <form class="w-100" [formGroup]="rollUpChildform">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputHH">
                                                            <label for="exampleInputEmail1">
                                                                Parent Category ID</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <p-dropdown filter="true" formControlName="parentCategoryID"
                                                             optionlabel="label"
                                                             placeholder="Enter Parent Category ID" [readonly]="readOnlyCode" [options]="allCategoryIdArray">
                                                            </p-dropdown>
                                                           
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">From Date</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                        
                                                            <input type="date" formControlName="fromDate" 
                                                            class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Date" [readonly]="readOnlyCode">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="this.hideInput" class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputHH">
                                                            <label for="exampleInputEmail1">
                                                                Through Date</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="date" formControlName="thruDate" 
                                                            class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter thruDate">
                                                        
                                                           
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Sequence Num</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                        
                                                            <input type="text" formControlName="sequenceNum" 
                                                            class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                



                                            </div>
                                            <div class="main-submit-button" style="    margin-right: 9%;">
                                                <button type="submit" (click)="addProductCategoryToCategoryS()"
                                                 *ngIf="this.showNew" class="btn btn-secondary submit-btn">Add</button>
                                                <button type="submit" (click)="updateProductCategoryToCategoryS()"
                                                 *ngIf="!this.showNew" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </form>
                                 
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>