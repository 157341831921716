import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GlobalResourceService } from 'src/app/services/global-resource.service'
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { SortEvent } from 'primeng/api';
import {
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
@Component({
  selector: 'app-fsm-tech-invoive',
  templateUrl: './fsm-tech-invoive.component.html',
  styleUrls: ['./fsm-tech-invoive.component.css']
})
export class FsmTechInvoiveComponent implements OnInit {

  show = false;
  createPage: FormGroup
  activeCategory = 3;
  @ViewChild('closebutton2') closebutton2;
  total = 0;
  rowNumber = 0;
  pageSize = 10;
  rows = 50;
  invoiceForm: FormGroup;
  invoiceList: any;
  technicianId: string;
  FSMCountryGeoId: any;
  FSMCountryGeoIdArray: any[] = [];
  FSMStateProvIdArray: any[] = [];
  FSMStateProvId: any;
  customerId: any;
  pro: any;
  invoiceById: any;
  invoiceID: any;
  showInvoice: boolean;
  invoiceByIdData: any;
  constructor(readonly _CategoryService: CategoryService, readonly _Router: Router,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService) {

    this.invoiceForm = this._FormBuilder.group({
      invoiceId: "",
      contactEmail: "",
      workOrderId: "",
      customerId: "",
      customerName: "",
      technicianId: "",
      technicianName: "",
      technicianStatus: "",
      customerProblemDescription: "",
      email: "",
      technicianNumber: "",
      address1: "",
      address2: "",
      city: "",
      countryGeoId: "",
      stateProvinceGeoId: "",
      postalCode: "",
      equipmentRequired: "",
      equipmentRequiredPrice: "",
      directLabourPerBuilt: "",
      equipmentPartsPrice: "",
      subTotal: "",
      discounts: "",
      tax: "",
      total: "",
      primaryContactPrimaryPhone: ""
    });
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.customerId = params.custId;
      this.technicianId = params.techId;
    })
    this.getAllDetailsByInvoiceId();
    this.getInvoice();
    this.getStateProvId();
    this.getCountryGeoId();

    setTimeout(() => {
      this.getAllDetailsByInvoiceId();
    }, 1000);

    if (this.invoiceByIdData) {
      this.getInvoiceById();
    }
  }
  reset() {
    this.show = false;
    this.invoiceForm.reset();
  }
  techPage() {
    this._Router.navigate(["fsm/fsm-technician"],
      { queryParams: { partyId: this.technicianId, customerId: this.customerId } });

  }
  getStateProvId() {
    this.spinner.show();
    this.accountsService.getStateProvId().subscribe(res => {
      this.FSMStateProvId = res.data[0].getStateProvId;
      this.spinner.hide();
      for (const value of this.FSMStateProvId) {
        this.FSMStateProvIdArray.push({
          label: value.geoName,
          value: value.geoId
        })
      }
    })
  }
  getCountryGeoId() {
    this.spinner.show();
    this.accountsService.getCountryGeoId().subscribe(res => {
      this.FSMCountryGeoId = res.data[0].getCountryGeoId;
      this.spinner.hide();
      for (const value of this.FSMCountryGeoId) {
        this.FSMCountryGeoIdArray.push({
          label: value.geoName,
          value: value.geoId
        })
      }
    })
  }
  getInvoiceById() {
    this.spinner.show();
    this.myContactsService.getInvoiceById(this.invoiceID).subscribe(res => {
      this.invoiceById = res.data[0].getInvoiceById;
      this.invoiceByIdData = res.data[0].getInvoiceById[0];
      this.spinner.hide();
    })
  }
  getAllDetailsByInvoiceId() {
    this.spinner.show();
    this.myContactsService.getAllDetailsByInvoiceId(this.customerId, this.technicianId).subscribe(res => {
      this.invoiceList = res.data[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.invoiceForm;
        formValue.patchValue({
          workOrderId: this.invoiceList.dataAssignment.workOrderId,
          customerId: this.invoiceList.dataCustomer.customerId,
          customerName: this.invoiceList.dataCustomer.customerName,
          technicianId: this.invoiceList.dataTechnician.technicianId,
          technicianName: this.invoiceList.dataTechnician.technicianName,
          technicianStatus: this.invoiceList.dataTechnician.technicianStatus,
          customerProblemDescription: this.invoiceList.dataProblem.description,
          email: this.invoiceList.dataTechnician.email,
          technicianNumber: this.invoiceList.dataTechnician.technicianNumber,
          address1: this.invoiceList.dataCustomer.address1,
          address2: this.invoiceList.dataCustomer.address2,
          city: this.invoiceList.dataCustomer.city,
          countryGeoId: this.invoiceList.dataCustomer.countryGeoId,
          stateProvinceGeoId: this.invoiceList.dataCustomer.stateProvinceGeoId,
          postalCode: this.invoiceList.dataCustomer.postalCode,
          equipmentRequired: "",
          equipmentRequiredPrice: "",
          directLabourPerBuilt: "",
          equipmentPartsPrice: "",
          subTotal: "",
          discounts: "",
          tax: "",
          total: "",
          contactEmail: this.invoiceList.dataCustomer.primaryContactEmail,
          primaryContactPrimaryPhone: this.invoiceList.dataCustomer.primaryContactPrimaryPhone,

        })

      }, 1000);
    })
  }
  update(product) {
    this.showInvoice = true;
    this.pro = product;

    setTimeout(() => {
      const formValue = this.invoiceForm;
      formValue.patchValue({
        invoiceId: product.invoiceId,
        contactEmail: product.primaryContactEmail,
        workOrderId: product.workOrderId,
        customerId: product.customerId,
        customerName: product.customerName,
        technicianId: product.technicianId,
        technicianName: product.technicianName,
        technicianStatus: product.technicianStatus,
        customerProblemDescription: product.customerProblemDescription,
        email: product.invoiceId,
        technicianNumber: product.technicianNumber,
        address1: product.address1,
        address2: product.address2,
        city: product.city,
        countryGeoId: product.countryGeoId,
        stateProvinceGeoId: product.stateProvinceGeoId,
        postalCode: product.postalCode,
        equipmentRequired: product.equipmentRequired,
        equipmentRequiredPrice: product.equipmentRequiredPrice,
        directLabourPerBuilt: product.directLabourPerBuilt,
        equipmentPartsPrice: product.equipmentPartsPrice,
        subTotal: product.subTotal,
        discounts: product.discounts,
        tax: product.tax,
        total: product.total,
        primaryContactPrimaryPhone: product.primaryContactPrimaryPhone
      })

    }, 2000);
  }
  updateFsmInvoice() {
    this.spinner.show();
    const requestData =
    {
      "address1": this.invoiceForm.value.address1,
      "address2": this.invoiceForm.value.address2,
      "city": this.invoiceForm.value.city,
      "countryGeoId": this.invoiceForm.value.countryGeoId,
      "customerId": this.pro.customerId,
      "customerName": this.invoiceForm.value.customerName,
      "customerProblemDescription": this.invoiceForm.value.customerProblemDescription,
      "directLabourPerBuilt": this.invoiceForm.value.directLabourPerBuilt,
      "discounts": this.invoiceForm.value.discounts,
      "email": this.invoiceForm.value.email,
      "equipmentPartsPrice": this.invoiceForm.value.equipmentPartsPrice,
      "equipmentRequired": this.invoiceForm.value.equipmentRequired,
      "equipmentRequiredPrice": this.invoiceForm.value.equipmentRequiredPrice,
      "invoiceId": this.pro.invoiceId,
      "postalCode": this.invoiceForm.value.postalCode,
      "primaryContactEmail": this.invoiceForm.value.contactEmail,
      "primaryContactPrimaryPhone": this.invoiceForm.value.primaryContactPrimaryPhone,
      "stateProvinceGeoId": this.invoiceForm.value.stateProvinceGeoId,
      "subTotal": this.invoiceForm.value.subTotal,
      "tax": this.invoiceForm.value.tax,
      "technicianId": this.technicianId,
      "technicianName": this.invoiceForm.value.technicianName,
      "technicianNumber": this.invoiceForm.value.technicianNumber,
      "technicianStatus": this.invoiceForm.value.technicianStatus,
      "total": this.invoiceForm.value.total,
      "workOrderId": this.invoiceForm.value.workOrderId,
    }
    this.myContactsService.updateFsmInvoice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.invoiceForm.reset();
        this.getInvoiceById();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createFsmInvoice() {
    this.spinner.show();
    const requestData =
    {
      "address1": this.invoiceForm.value.address1,
      "address2": this.invoiceForm.value.address2,
      "city": this.invoiceForm.value.city,
      "countryGeoId": this.invoiceForm.value.countryGeoId,
      "customerId": this.invoiceForm.value.customerId,
      "customerName": this.invoiceForm.value.customerName,
      "customerProblemDescription": this.invoiceForm.value.customerProblemDescription,
      "directLabourPerBuilt": this.invoiceForm.value.directLabourPerBuilt,
      "discounts": this.invoiceForm.value.discounts,
      "email": this.invoiceForm.value.email,
      "equipmentPartsPrice": this.invoiceForm.value.equipmentPartsPrice,
      "equipmentRequired": this.invoiceForm.value.equipmentRequired,
      "equipmentRequiredPrice": this.invoiceForm.value.equipmentRequiredPrice,
      "invoiceId": "",
      "postalCode": this.invoiceForm.value.postalCode,
      "primaryContactEmail": this.invoiceForm.value.contactEmail,
      "primaryContactPrimaryPhone": this.invoiceForm.value.primaryContactPrimaryPhone,
      "stateProvinceGeoId": this.invoiceForm.value.stateProvinceGeoId,
      "subTotal": "",
      "tax": this.invoiceForm.value.tax,
      "technicianId": this.technicianId,
      "technicianName": this.invoiceForm.value.technicianName,
      "technicianNumber": this.invoiceForm.value.technicianNumber,
      "technicianStatus": this.invoiceForm.value.technicianStatus,
      "total": this.invoiceForm.value.total,
      "workOrderId": this.invoiceForm.value.workOrderId,
    }
    this.myContactsService.createFsmInvoice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.invoiceForm.reset();
        this.invoiceID = res.data.invoiceId;
        this.getInvoiceById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getInvoice() {
    this.spinner.show();
    this.myContactsService.getInvoice().subscribe(res => {
      this.invoiceList = res.data[0].getInvoice;
      this.spinner.hide();
    })
  }
  fsmInvoiceGenerate(product) {
    this.spinner.show();
    this.myContactsService.fsmInvoiceGenerate(product.invoiceId).subscribe(res => {
      const blob = new Blob([res], { type: 'invoice/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "Invoice";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }
  deleteFsmInvoice(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        const req = {
          invoiceId: product.invoiceId
        }
        this.myContactsService.deleteFsmInvoice(product).subscribe(res => {
          this.getInvoice();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
