import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DetaledLeadService } from '../detaled-lead/detaled-lead.service';
import { Location } from '@angular/common';



@Component({
  selector: 'app-sales-documents',
  templateUrl: './sales-documents.component.html',
  styleUrls: ['./sales-documents.component.css']
})
export class SalesDocumentsComponent implements OnInit {
  Editform: FormGroup;
  partyContentTypeId: any;
  partyContentData: any;
  partyContentArray: any[]=[];
  fileData: File;
  partyId: any;

  activeCategories=11;

  constructor(
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly detaledLeadService: DetaledLeadService,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    private _location: Location,

  ) { 
  
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.partyId = params['leadId'];
    });
    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
      partyContentTypeId: new FormControl('')
    });
    this.getPartyContent();
  
  }
  changeactiveCategoryAction(tab: number) {
    this.activeCategories = tab;
  }
  toForecast(){
    
    this.router.navigate(['/crm/sales-forecast']);

  }
  toCalendar(){
    
    this.router.navigate(['crm/sales-calendar']);

  }
  toNotes(){
    this.router.navigate(['/crm/sales-notes']);
  }
  toEmails(){
    
    this.router.navigate(['/communication-overview/Dashboard']);

  }  
  toTasks(){
    this.router.navigate(['/crm/Tasks']);
  }
  toDocuments(){
    
    this.router.navigate(['crm/sales-documents']);

  }
  toLead()
  {
    this.router.navigate(['/crm/leads']);

  }
  toAccount()
  {
    this.router.navigate(['/crm/accounts']);
  }
  toContact()
  {
    this.router.navigate(['/crm/my-contacts']);

  }
  toOpportunity()
  {
    this.router.navigate(['/crm/opportunities']);

  }
  
  toCases(){
    this.router.navigate(['/crm/Cases']);
  }
  toActivities()
  {
    this.router.navigate(['/crm/events']);

  }
  toQuotes()
  {
    this.router.navigate(['/order/quotations']);

  }
  toSalesOrder()
  {
    this.router.navigate(['/order/create-sales-order']);

  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }
  onpartyContent(event) {
    this.partyContentTypeId = event;
  }
  getPartyContent() {
    this.spinner.show();
    this.detaledLeadService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        })
      }
    })
    
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  onSubmit() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.fileData);
    this.partyContentTypeId = this.Editform.get('partyContentTypeId').value;
    this.detaledLeadService.attachPartyContent(formData, this.partyContentTypeId, this.partyId)
      .subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          // window.location.reload();
          // this.getLeadById()

        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      });
     
  }

 

 
}
