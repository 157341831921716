import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

    import { Location } from '@angular/common';

@Component({
  selector: 'app-project-my-profile',
  templateUrl: './project-my-profile.component.html',
  styleUrls: ['./project-my-profile.component.css']
})
export class ProjectMyProfileComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 8;
  activeCategoryA = 4;
  resData: any;
  total=0;
  partyId:any
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  sprintTable:any;
  roleForm:FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  RoleTypeList_profile: any;
  RoleTypeList_profileArray: any[]=[];
  fromdate: string;
  thrudate: string;
  taxAuthPartyId: any;
  listResourceData: any;
  Person: any;
  listResourcesProjectList: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  HidebtnRoleView: boolean=false;
  HidebtnTaskCrud: boolean=false;
  HidebtnTimeCurd: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
      private _location: Location,
      readonly _FormBuilder: FormBuilder,
        readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.roleForm = this._FormBuilder.group({
      RoleTypeID: [''],
    });
    this.AddTaskForm = this._FormBuilder.group({
      TaskName: [''],
    });
    this.projectForm = this._FormBuilder.group({
      DefaultRate: [''],
      RateAmount: [''],
      ProductCurrencyUomId: [''],
      PercentageUsed: [''],
      PeriodTypeID: [''],
      RateType: [''],
    });
    this.ratesForm = this._FormBuilder.group({
      RoleType: [''],
      FromDate: [''],
      ThroughDate: [''],
    });
    this.updateProfile = this._FormBuilder.group({
      partyID: [''],
      salutation: [''],
      firstName: [''],
      middleName: [''],
      lastName: [''],
      personalTitle: [''],
      suffix: [''],
      nickname: [''],
      firstNameLocal: [''],
      middleNameLocal: [''],
      lastNameLocal: [''],
      otherLocal: [''],
      memberID: [''],
      gender: [''],
      birthDate: [''],
      decreasedDate: [''],
      height: [''],
      weight: [''],
      mothersMaidenName: [''],
      oldMaritialStatus: [''],
      maritialStatusEnumID: [''],
      socialSecurityNumber: [''],
      passportNumber: [''],
      passportExpiryDate: [''],
      totalExperience: [''],
      comments: [''],
      employeeStatusEnumID: [''],
      residenceStatusEnumID: [''],
      occupation: [''],
      yearswithEmployer: [''],
      monthsWithEmployer: [''],
      existingCustomer: [''],
      cardID: [''],
      usCitizen: [''],
      ethnicity: [''],
      accountNo: [''],
      billingRateLevel: [''],
      preferredCurrencyUomID: [''],
      description: [''],
      externalID: [''],
      statusID: [''],
    });
    this.createUserLogIn = this._FormBuilder.group({
      userLoginID: [''],
      currentPassword: [''],
      currentPasswordVerify: [''],
      passwordHint: [''],
      requirePasswordChange: [''],
      externalAuthID: [''],
    });
    this.addRelatedAccount = this._FormBuilder.group({
      accountPartyID: [''],
      comments: [''],
    });
    this.createNewContactInformation = this._FormBuilder.group({
      selectContactType: [''],
    });
    this.partyAttributes = this._FormBuilder.group({
      attrName: [''],
      attrValue: [''],
      attrDescription: [''],
    });
    this.addCheckAccount = this._FormBuilder.group({
      nameOnAccount: [''],
      companyNameOnAccount: [''],
      bankName: [''],
      routingNumber: [''],
      paymentMethodTypeId: [''],
      accountType: [''],
    });
    this.createBillingAccount = this._FormBuilder.group({
      accountLimit: [''],
      accountCurrencyUomId: [''],
      contactMechId: [''],
      fromDate: [''],
      thruDate: [''],
      description: [''],
      externalAccountId: [''],
      partyId: [''],
    });
    this.addNewCreditCard = this._FormBuilder.group({
      companyNameOnCard: [''],
      titleOnCard: [''],
      firstNameOnCard: [''],
      middleNameOnCard: [''],
      lastNameOnCard: [''],
      suffixOnCard: [''],
      cardType: [''],
      cardNumber: [''],
      expMonth: [''],
      expYear: [''],
      description: [''],
    });
    this.createGiftCard = this._FormBuilder.group({
      cardNumber: [''],
      pinNumber: [''],
      expMonth: [''],
      expYear: [''],
      description: [''],
    });
    this.addCheckAccount = this._FormBuilder.group({
      nameOnAccount: [''],
      companyNameOnAccount: [''],
      bankName: [''],
      routingNumber: [''],
      paymentMethodTypeId: [''],
      accountType: [''],
      accountNumber: [''],
      description: [''],
    });
   }

  ngOnInit(): void {
 
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.Hidebtn=true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.taxAuthPartyId = params["taxAuthPartyId"];
    });
    this.getPerson();
    this.getRoleTypeList_profile();
    this.listResourcesProject();


  }
  
  projectPage(productId,internalName) {
    this._Router.navigate(["/psa/detail-sprint-project"], { queryParams: { workEffortId: productId, workEffortName: internalName,} })
  }
  listResourcesProject() {
    this.spinner.show();
    this.myContactsService.listResourcesProject(this.taxAuthPartyId).subscribe(res => {
      this.listResourcesProjectList = res.data;
      this.spinner.hide();
    })

  }
  getPerson() {
    this.spinner.show();
    this.accountsService.getPerson(this.taxAuthPartyId).subscribe(res => {
      this.Person = res.data;
      this.spinner.hide();
    })

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  getRoleTypeList_profile() {
    this.spinner.show();
    this.myContactsService.getRoleTypeList_profile().subscribe(res => {
      this.RoleTypeList_profile = res.data.node;
      this.spinner.hide();
      for (const value of this.RoleTypeList_profile) {
        this.RoleTypeList_profileArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
      
  addWorkEffortPartyAssign() {
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
      const sdate = this.ratesForm.get('FromDate').value;
          this.fromdate = this.datePipe.transform(sdate, "yyyy-MM-dd hh:mm:ss");
      const edate = this.ratesForm.get('ThroughDate').value;
          this.thrudate = this.datePipe.transform(edate, "yyyy-MM-dd hh:mm:ss");
      const formData = {
        "currentStatusId": "_NA_",
        "fromDate": this.fromdate,
        "partyId": this.taxAuthPartyId,
        "projectId": "",
        "roleTypeId": this.ratesForm.value.RoleType,
        "statusId": "PRTYASGN_ASSIGNED",
        "thruDate": this.thrudate,
        "workEffortId": ""
      }
      this.myContactsService.addWorkEffortPartyAssign(formData).subscribe((res: any) => {
        
        if (res.success) {
          this._ToastrService.success("Created Successfully");
          this.spinner.hide();
          // this.closebutton7.nativeElement.click();
           this.listResourcesProject();
           this.ratesForm.reset();
    
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
          }
          }
          else{
            this._ToastrService.error(err.error.message);
          }
      });
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.toastr.error("Permission Denied"); 
    }
  
  }

  deleteProjectPartyAssign(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      const sdate = this.ratesForm.get('FromDate').value;
      this.fromdate = this.datePipe.transform(sdate, "yyyy-MM-dd hh:mm:ss");
  const edate = this.ratesForm.get('ThroughDate').value;
      this.thrudate = this.datePipe.transform(edate, "yyyy-MM-dd hh:mm:ss");
      if (result.value) {
        let req = {
          "_useRowSubmit": "Y",
          "actualCompletionDate": product.workeffort[0].actualCompletionDate,
          "actualHours": "",
          "actualStartDate": product.workeffort[0].actualStartDate,
          "createdStamp": product.workeffort[0].createdStamp,
          "currentStatusId": "SPJ_ACTIVE",
          "estimatedCompletionDate": product.workeffort[0].estimatedCompletionDate,
          "estimatedStartDate":product.workeffort[0].estimatedStartDate,
          "fromDate":"",
          "partyId":this.taxAuthPartyId,
          "plannedHours": "",
          "priority": "string",
          "projectId": product.workeffort[0].workEffortId,
          "roleTypeId":product.workeffortas.roleTypeId,
          "viewIndex_1": "0",
          "viewSize_1": "20",
          "workEffortName": product.workeffort[0].workEffortName
        }
        this.accountsService.deleteProjectPartyAssign(req).subscribe(res => {
          this.listResourcesProject();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
 
  MyProfile() {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Preferences() {
    this._Router.navigate(["/psa/preferences-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Roles() {
    this._Router.navigate(["/psa/roles"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Project() {
    this._Router.navigate(["/psa/project-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  TasksProfile() {
    this._Router.navigate(["/psa/tasks-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Rates() {
    this._Router.navigate(["/psa/rates-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Revisions() {
    this._Router.navigate(["/psa/revisions-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Backlog(){
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint(){
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork(){
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks(){
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest(){
    this._Router.navigate(["/psa/open-test"])
  }
  Revision(){
    this._Router.navigate(["/psa/find-task-revision"])
  }
}
