<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey breadcrumbb" (click)="cancel()">Physical Inventory </span>
                <span class="color-black pl-1"> > Update</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    Update Physical Inventory
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="updateWarehouseShipment">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Inventory Item ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"  formControlName="itemId"
                                                    readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Product ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"  formControlName="productId"
                                                readonly>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Internal Name</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" 
                                                formControlName="internalName" readonly>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Item ATP</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"  formControlName="itemAtp"
                                                readonly>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1"> Item QOH</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"  formControlName="itemQAH"
                                                readonly>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1"> Product ATP</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"  formControlName="ProductATP"
                                                readonly>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1"> Product QOH</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"  formControlName="ProductQOH"
                                                readonly>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1"> Variance Reason ID <span
                                                    class="text-danger">*</span> </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" [options]="reasinIdArray" optionlabel="label"
                                                formControlName="vReasonId" placeholder="Reason Id">
                                            </p-dropdown>
                                            <small class="text-danger"
                                                *ngIf="!!updateWarehouseShipment.controls.vReasonId.invalid && !!updateWarehouseShipment.controls.vReasonId.touched">Variance
                                                Reason ID is required.</small>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">ATP Var</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"  formControlName="ATPVar">
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">QOH Var</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"  formControlName="QOHVar">
                                            </div>
                                        </div>
                                    </div>
                                 
                                

                                    <div class="col-lg-12 col-12" style="margin-left: -26%;">
                                        <div class="main-submit-button">
                                            <button type="button" (click)="submit()"
                                                class="btn btn-secondary submit-btn update-button">Update</button>
                                            <button type="button" (click)="cancel()"
                                                class="btn btn-danger ml5">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 