import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { SortEvent } from 'primeng/api';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { BillingAccountService } from 'src/app/accounting/accounting-ap/billing-account/billing-account.service';
import { CustomerAgreementsService } from 'src/app/accounting/accounting-ar/customer-agreements/customer-agreements.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-task-list-view',
  templateUrl: './task-list-view.component.html',
  styleUrls: ['./task-list-view.component.css']
})
export class TaskListViewComponent implements OnInit {

  CustomerAgreements: FormGroup;
 
  total=0;
  @ViewChild('closebutton') closebutton;
  isShown = false; 
  rowNumber=0;
  agreementList: any;
  pageSize = 10;
  activeCategoryMainTs=1;
  PersonsAndPartyGroupsListArray: any[]=[];
  productListArray: any[]=[];
  AgreementTypeListArray: any[]=[];
  fromDateType: { label: string; value: string; }[];
  toDateType: { label: string; value: string; }[];
  rows = 50;
  activeCategoriess = 7;
  pageNo = 1;
  billingAccountForm: FormGroup; agreementType: string;
  productList: any = [];
  AgreementTypeList: any = [];
  RoleTypesList: any = [];
  activeCategory = 2;
  currencyUomIds: any[];
  partyIds: any[];
  editMode: boolean;
  createCustomerAgreements: FormGroup;
  billingAccountId: string;
  billingAccount: any;
  contactMechIds: any[];
  opportunityIdArray: any = [
      {
          label: 'Equals',
          value: 'Equals'
      },
      {
          label: 'Contains',
          value: 'Contains'
      },
      {
          label: 'Is Empty',
          value: 'Is Empty'
      },
      {
          label: 'Begins With',
          value: 'Begins With'
      },
  ];
  PersonsAndPartyGroupsList: any = [];
  agreementDate: any;
  thruDate: any;
  fromDate: any;
  searchBillingAccountForm: FormGroup;
  billingAccounts: any[];
  faEdit = faEdit;
  constructor(
      readonly _FormBuilder: FormBuilder,
      readonly spinner: NgxSpinnerService,
      readonly _BillingAccountService: BillingAccountService,
    readonly datePipe: DatePipe,
    readonly _GlobalResourceService: GlobalResourceService,
      readonly CustomerAgreementsService: CustomerAgreementsService,
    
      readonly _ToastrService: ToastrService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly  _Router: Router,
   
      readonly _ActivatedRoute: ActivatedRoute,
      private _location: Location,
  
  ) {
      this.currencyUomIds = [];


      this.CustomerAgreements = this._FormBuilder.group({
        agreementId: [''],
        agreementIdSearchType: ['Contains'],
        agreementTypeId: [''],
        fromDateFrom: [''],
        fromDateFromSearchType: ['Greater Than'],
        fromDateTo: [''],
        fromDateToSearchType: ['Less Than'],
        partyIdFrom: [''],
        partyIdTo: [''],
        productId: [''],
      });


      this.partyIds = [];
      this.editMode = false;
      this.contactMechIds = [];
      this.billingAccountForm = this._FormBuilder.group({
        accountCurrencyUomId: [''],
        accountLimit: [''],
        availableBalance: [0],
        contactMechId: [''],
        description: ['', [Validators.required]],
        externalAccountId: [''],
        fromDate: [''],
        partyId: ['', [Validators.required]],
        thruDate: [''],
        accountType:[''],
        name:['']
      });
      this.createCustomerAgreements = this._FormBuilder.group({
        statusId: [''],
        agreementTypeId: ['', [Validators.required]],
        agreementDate: [''],
        description: [''],
        fromDate: [''],
        partyIdFrom: [''],
        partyIdTo: [''],
        productId: [''],
        roleTypeIdFrom: [''],
        roleTypeIdTo: [''],
        textData: [''],
        thruDate: ['']
      });
      this.billingAccountId = '';
      this.searchBillingAccountForm = this._FormBuilder.group({
          accountLimit: [''],
          billingAccountId: [''],
          billingAccountIdSearchType: ['Contains'],
          description: [''],
          descriptionSearchType: ['Contains'],
          fromDate: [''],
          thruDate: ['']
      });
      this.billingAccounts = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    
      this.getBillingAccounts();
      this.getContactMechId();
      this.getCurrency();
      this.getAgreementTypeListData();
      this.getPartyIds();
      this.getRoleTypes();
      this.getRoleTypes();
      this.getRoleTypes();
      this.getProductsData();
      this.getPersonsAndPartyGroupsData();
  }

  getPersonsAndPartyGroupsData() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.spinner.hide();
      this.PersonsAndPartyGroupsList = responce.data.PersonsGroups;
    });
  
  }

  getProductsData() {
    this.spinner.show();
    const requestData = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains"
    };
    this.CustomerAgreementsService.getProducts(requestData).subscribe((responce) => {
      this.spinner.hide();
      this.productList = responce.data;
    });
   
  }

  getRoleTypes() {
    this.spinner.show();
    this.CustomerAgreementsService.getRoleTypes().subscribe((responce) => {
      this.spinner.hide();
      this.RoleTypesList = responce.data;
    });
   
  }

  getAgreementTypeListData() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {
      this.spinner.hide();
      this.AgreementTypeList = responce.data;
    });

  }
  cancelAgreement(agreementId: string): void {
    this.spinner.show();
    this.CustomerAgreementsService.cancelAgreement(agreementId)
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Cancelled');
          this.spinner.hide();
          this.submit();
        }
      });
    
  }
  getAgreementTypeList() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {

      this.AgreementTypeListArray = responce.data.map(value => {
        return {
          label: value.description,
          value: value.agreementTypeId
        };
      });
    });
    this.spinner.hide();
  }
  summary(agreementID, toPartyId: string) {
    window.localStorage.setItem('toPartyId', toPartyId);
    this._Router.navigate([`/financial/payable/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID } });
  }
  restForm(){
    this.CustomerAgreements.reset();
    this.CustomerAgreements.reset();
  }
  getProducts() {
    this.spinner.show();
    const requestData = {
      internalName: '',
      internalNameSearchType: 'Contains',
      productId: '',
      productIdSearchType: 'Contains'
    };
    this.CustomerAgreementsService.getProducts(requestData).subscribe((responce) => {

      this.productListArray = responce.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
    });
    this.spinner.hide();
  }

  submitData(): void {
    this.spinner.show();
    if (this.createCustomerAgreements.valid) {
      const agreementDate = this.createCustomerAgreements.get('agreementDate').value;
      this.agreementDate = this.datePipe.transform(agreementDate, "yyyy-MM-dd hh:mm:ss");
      const thruDate = this.createCustomerAgreements.get('thruDate').value;
      this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
      const fromDate = this.createCustomerAgreements.get('fromDate').value;
      this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
      const requestData = {
        agreementDate: this.agreementDate,
        agreementTypeId: this.createCustomerAgreements.value.agreementTypeId.agreementTypeId,
        description: this.createCustomerAgreements.value.description,
        fromDate: this.fromDate,
        partyIdFrom: this.createCustomerAgreements.value.partyIdFrom.partyId,
        partyIdTo: this.createCustomerAgreements.value.partyIdTo.partyId,
        productId: this.createCustomerAgreements.value.productId.productId,
        roleTypeIdFrom: this.createCustomerAgreements.value.roleTypeIdFrom.roleTypeId,
        roleTypeIdTo: this.createCustomerAgreements.value.roleTypeIdTo.roleTypeId,
        textData: this.createCustomerAgreements.value.textData,
        thruDate: this.thruDate
      };
      this.CustomerAgreementsService.createAgreementAccnAR(requestData).subscribe((res: any) => {
        if (res.success) {
          this._ToastrService.success(res.data.successMessage);
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getAgreementTypeList();
         // this.router.navigate([`/financial/${this.agreementType}/customer-agreeement`]);
        } }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
          } } });
    } else {
      this.spinner.hide();
      this._ToastrService.error('Please fill required field');
    }}
  submit(): void {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementList({
      agreementDate: this.CustomerAgreements.value.agreementDate ? moment(this.CustomerAgreements.value.agreementDate).format('YYYY-MM-DD HH:mm:ss') : '',
      agreementId: this.CustomerAgreements.value.agreementId?this.CustomerAgreements.value.agreementId:'',
      agreementIdSearchType: this.CustomerAgreements.value.agreementIdSearchType?this.CustomerAgreements.value.agreementIdSearchType:'',
      agreementTypeId: this.CustomerAgreements.value.agreementTypeId?this.CustomerAgreements.value.agreementTypeId:'',
      fromDateFrom: this.CustomerAgreements.value.fromDateFrom ? moment(this.CustomerAgreements.value.fromDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
      fromDateFromSearchType: this.CustomerAgreements.value.fromDateFromSearchType?this.CustomerAgreements.value.fromDateFromSearchType:'',
      fromDateTo: this.CustomerAgreements.value.fromDateTo ? moment(this.CustomerAgreements.value.fromDateTo).format('YYYY-MM-DD HH:mm:ss') : '',
      fromDateToSearchType: this.CustomerAgreements.value.fromDateToSearchType?this.CustomerAgreements.value.fromDateToSearchType:'',
      partyIdFrom: this.CustomerAgreements.value.partyIdFrom?this.CustomerAgreements.value.partyIdFrom:'',
      partyIdTo: this.CustomerAgreements.value.partyIdTo?this.CustomerAgreements.value.partyIdTo:'',
      productId: this.CustomerAgreements.value.productId? this.CustomerAgreements.value.productId:''
    }).subscribe((res: any) => {
     
      if (res.success) {
       
        this.agreementList = res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
    });
   
  }

  mainPage()
  {
    this._Router.navigate(['/financial/main-accounting']);
  }
  accountingPage()
  {
    this._Router.navigate(['/financial/Accounting']);
  }
  invoicePage()
  {
    this._Router.navigate(['/financial/Invoices']);
  }
  paymentPage()
  {
    this._Router.navigate(['/financial/Payments']);
  }
  paymentGrpPage()
  {
    this._Router.navigate(['/financial/Payment-group']);
  }
  billingAccountPage()
  {
    this._Router.navigate(['/financial/Billing-Account']);
  }
  agreementPage()
  {
    this._Router.navigate(['/financial/Agreements']);
  }
  commisionReportPage()
  {
    this._Router.navigate(['/financial/Commission-Report']);
  }
  mainAgreementPage()
  {
    this._Router.navigate(['/financial/main-agreements']);
  } 
  mainBillingAccountPage()
  {
    this._Router.navigate(['/financial/main-Billing-Accounts']);
  }
  mainFixedAssetPage()
  {
    this._Router.navigate(['/financial/main-Fixed-Assets']);
  } 
  
  mainInvoicePage()
  {
    this._Router.navigate(['/financial/main-Invoices']);
  }
   mainPaymentPage()
  {
    this._Router.navigate(['/financial/main-Payment']);
  }


backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/financial/main']);
}
  resetForm(){
    this.searchBillingAccountForm.reset();
  }
  getContactMechId(): void {
      this.spinner.show();
      this._BillingAccountService.getBillingAccountContactMechIdList(this.billingAccountId)
        .then(data => {
          this.spinner.hide();
          this.contactMechIds = data.data.map(value => {
            return {
              label: value.description,
              value: value.contactMechId
            };
          });
        });
       
    }
    getPartyIds(): void {
      this.spinner.show();
      this._GlobalResourceService.getPartyGroups()
        .then(data => {
          this.spinner.hide();
          this.partyIds = data.data.PersonsGroups.map(value => {
            return {
              label: value.partyId,
              value: value.partyId
            };
          });
        });
       
    }
    getCurrency(): void {
      this.spinner.show();
      this._GlobalResourceService.getCurrency()
        .then(data => {
          this.spinner.hide();
          this.currencyUomIds = data.data.currencyList.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        });
      
    }
 
 
  invoiceType(){
      this.isShown = !this.isShown;
    }
  getBillingAccounts(): void {
      this.spinner.show();
      this._BillingAccountService.getBillingAccounts({ pageNo: 1, pageSize: 200 }, {
          ...this.searchBillingAccountForm.value, ...{
              fromDate: this.searchBillingAccountForm.value.fromDate ? moment(this.searchBillingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
              thruDate: this.searchBillingAccountForm.value.thruDate ? moment(this.searchBillingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }
      })
          .then(data => {
              if (data) {
                  this.billingAccounts = data.data;
                  this.spinner.hide();
              }
          });
         
  }
  customSort(event: SortEvent): void {
      event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
              result = -1;
          }
          else if (value1 != null && value2 == null) {
              result = 1;
          }
          else if (value1 == null && value2 == null) {
              result = 0;
          }
          else if (typeof value1 === 'string' && typeof value2 === 'string') {
              result = value1.localeCompare(value2);
          }
          else {
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
      });
  }
  ngOnDestroy(): void {
      this.spinner.hide();
        }
        
        cancelSubmit(){
          this._Router.navigate(['/financial/main'])
        }
  
}
