<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Customer
                </span>
            </div>
            <div class="bg-white color-grey">
               
                <div class="mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="w3-card-4 classCard" style="width: 100%;">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text">Customer Problem </p>

                                </div>
                                <br>
                                <form class="w-100" [formGroup]="addCustoProblem">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <!-- <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightFormC">
                                                    <label for="exampleInputEmail1">Problem Category</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [options]="FsmEquipmentIdArray"
                                                        optionlabel="label" placeholder="Select Problem Category"
                                                        formControlName="category"
                                                        (ngModelChange)="getfilterService($event)">
                                                    </p-dropdown>
                                                </div>


                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Service</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [options]="FsmEquipmentServiceByIdArray"
                                                        optionlabel="label" placeholder="Select Service"
                                                        formControlName="service">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Problem Category</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [options]="expertiseAreaArray"
                                                        optionlabel="label" placeholder="Select Category"
                                                        formControlName="problemCategory">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormC">
                                                    <label for="exampleInputEmail1">Problem Description</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"formControlName="description" 
                                                    class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Problem Description">
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                               
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Priority</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [options]="priorityArray"
                                                        optionlabel="label" placeholder="Select Priority"
                                                        formControlName="priority">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group rightFormC">
                                                    <label for="exampleInputEmail1">Payment Mode</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [options]="paymentProcessSettingArray"
                                                        optionlabel="label" placeholder="Select payment Mode"
                                                        formControlName="paymentMode">
                                                    </p-dropdown>
                                                </div>
                                                
                                            </div>
                                        </div>
                                       
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -25%;">
                                            <button _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn"
                                                (click)="createFsmProblem()">Create</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <br>
                            <div class="w3-card-4 classCard  w-100" style="margin-top: 2%;" *ngIf="this.showTable">
                                <header class="w3-container w3-blue">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling h4Margin">
                                            Problem List
                                        </h4>

                                    </div>
                                </header>
                                <div class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">


                                            <div class="card own-account-table">
                                                <p-table [value]="FsmProblemByCustomerIdData" [paginator]="true"
                                                    [rows]="rows" scrollWidth="100%"
                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                    Problem ID
                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                </div>

                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">Customer ID
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="quantity">
                                                                <div style="color: white;">Service
                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;">Problem Description
                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">Priority
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">Payment Mode
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">Problem Status
                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                </div>
                                                            </th>

                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">Problem Created Date
                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                </div>
                                                            </th>


                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr  ng-repeat="product in product | orderBy:reverse:true">
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                    <span (click)="summaryPage(product.problemId)" style="color:#0d3769">
                                                                        {{product.problemId }}
                                                                    </span>
                                                                   
                                                                </div>
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.customerId}}
                                                            
                                                            </td>

                                                            <td [ngStyle]="{'width':'170px'}">{{product.service}}</td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.description}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.priority}}</td>
                                                            <td [ngStyle]="{'width':'170px'}">{{product.paymentMode}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.problemStatus
                                                                !==null?product.problemStatus:'Not Assigned'}}</td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.createdStamp | date:'yyyy-MM-dd HH:mm:ss'}}
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>