<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of Mr. THE PRIVILEGED ADMINISTRATOR
                    [admin]</span>
                    <span class="">

                        <button type="submit" (click)="homeButton();"
                         class=" btn btn-outline-secondary mr-2">Home</button>
             
                       <button type="submit" (click)="cancelSubmit();"
                         class="btn btn-danger buttonclass ">Back</button> 
                    </span>
            </div>

            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Task Summary</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running'">
                                <a>Edit Task</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running'">
                                <a>Dependencies
                                </a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running'">
                                <a>Resources</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running'">
                                <a>Skill Standards</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running'">
                                <a>Time Entries</a>
                            </li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running'||this.statusId=='Cancelled'||this.statusId=='Closed'">
                                <a>Content</a>
                            </li>

                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)">
                                <a>Notes</a>
                            </li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)">
                                <a>Surveys</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCard w-100">
                                            <div class="" style="margin: 1%;">
                                                <div *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running'">
                                                    <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" (click)="detailNewTask()">New</button>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running' || !this.Hidebtn" (click)="setTaskStatus()">On-Hold </button>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running' || !this.Hidebtn" (click)="setCompleteTask()">Complete</button>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running' || !this.Hidebtn" (click)="setTaskStatusCancel()">Cancel</button>
                                                    <button type="submit" *ngIf="this.statusId=='On Hold' || !this.Hidebtn" class="btn btn-secondary submit-btn ml-2" (click)="setTaskStatusRelease()">Release</button>

                                                </div>

                                                <div *ngIf="this.statusId=='Completed'">
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="detailNewTask()">New</button>
                                                </div>

                                                <div *ngIf="this.statusId=='Cancelled'||this.statusId=='Closed'">
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="detailNewTask()">New</button>
                                                </div>


                                                <div *ngIf="this.statusId=='On Hold'">
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="detailNewTask()">New</button>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running'" (click)="setTaskStatus()">On-Hold </button>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running'" (click)="setCompleteTask()">Complete</button>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" *ngIf="this.statusId=='Created'||this.statusId=='Confirmed'||this.statusId=='Running'" (click)="setTaskStatusCancel()">Cancel</button>
                                                    <button type="submit" *ngIf="this.statusId=='On Hold'" class="btn btn-secondary submit-btn ml-2" (click)="setTaskStatusRelease()">Release</button>
                                                </div>

                                            </div>
                                            <div class="" style="margin: 1% 2%;color: #0d3769;">
                                                Current Task : <span *ngIf="this.TaskInformationById">{{TaskInformationById.workEffortName}}{{
                                                    "[" + TaskInformationById.workEffortId + "]"}}</span>
                                            </div>
                                            <div class="" style="margin: 1% 2%;color: #0d3769;">
                                                Phase Name :<span *ngIf="this.TaskInformationById">{{TaskInformationById.phaseName}}</span>
                                            </div>
                                            <div class="" style="margin: 1% 2%;color: #0d3769;">
                                                Project Name :<span *ngIf="this.TaskInformationById">{{TaskInformationById.projectName}}</span>
                                            </div>

                                            <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                <div *ngIf="activeCategory==1" class="w-100">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                    <div style="width: 49.4%; float: left;">
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Task information
                                                                </h4>
                                                            </div>
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="row">
                                                                            <div class="article-container" *ngIf="this.TaskInformationById">
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Task Name </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                        {{TaskInformationById.workEffortName}}
                                                                                        {{ "[" +
                                                                                        TaskInformationById.workEffortId
                                                                                        + "]"}}
                                                                                    </span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Phase Name</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="TaskInformationById.phaseName">
                                                                                        {{TaskInformationById.phaseName}}
                                                                                    </span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Project Name</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="TaskInformationById.projectName">{{TaskInformationById.projectName}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Description </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Current Status ID </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="TaskInformationById.projectStatusId">{{TaskInformationById.projectStatusId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Priority </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="TaskInformationById.priority">{{TaskInformationById.priority}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Estimated Start Date </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="TaskInformationById.estimatedStartDate">{{TaskInformationById.estimatedStartDate}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Estimated Completion Date </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="TaskInformationById.estimatedCompletionDate">{{TaskInformationById.estimatedCompletionDate}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Actual Start Date </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="TaskInformationById.actualStartDate">{{TaskInformationById.actualStartDate}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Actual Completion Date </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="TaskInformationById.actualCompletionDate">{{TaskInformationById.actualCompletionDate}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Planned Hours </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Original Actual Hours </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Actual Hours </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Tasks to be executed before the current task
                                                                </h4>
                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="DependencyFromList" [paginator]="false" [rows]="rows" scrollWidth="100%" scrollHeight="150px"  [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Name


                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Parent Phase

                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Status
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Estimated Start Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Estimated Completion Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Actual Start Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Actual Completion Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.workeffortListFrom[0].workEffortName}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].workEffortName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].currentStatusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].estimatedStartDate| date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].estimatedCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].actualStartDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].actualCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <!-- <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No
                                                                                            Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template> -->
                                                                    </p-table>
                                                                    <!-- <p class="paginate_data">
                                                                        View per page

                                                                    </p> -->
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Tasks to be executed after the current task
                                                                </h4>
                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="DependencyToList" [paginator]="false" scrollHeight="150px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Name


                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Parent Phase

                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Status
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Estimated Start Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Estimated Completion Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Actual Start Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Actual Completion Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.workeffortListTo[0].workEffortName}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].workEffortName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].currentStatusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].estimatedStartDate| date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].estimatedCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].actualStartDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].actualCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <!-- <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No
                                                                                            Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template> -->
                                                                    </p-table>
                                                                    <!-- <p class="paginate_data">
                                                                        View per page

                                                                    </p> -->
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div style="width: 49.8%; float: right;">
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Task Team

                                                                </h4>

                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="TaskTeam" [paginator]="false" [rows]="rows" scrollHeight="150px" scrollWidth="100%"  [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Name
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Role Type ID
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Planned Hours</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Original Actual Hours</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Actual Hours</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>




                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>

                                                                                        <span class="account-button" (click)="myProfile(product.data.partyId)">
                                                                                            {{product.data.partyId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.data.roleTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.PlannedHours }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.OriginalActualHours }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.ActualHours }}
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                 
                                                                </div>

                                                            </div>

                                                        </div>
                                                      
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Request List
                                                                </h4>
                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="RequestList" [paginator]="false"   scrollHeight="150px" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Cust Request ID


                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Description

                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Cust Request Type Description
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Status
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.dataRequest.custRequestId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.dataRequest.description}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.dataRequest.custRequestTypeId }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.dataRequest.statusId}}
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No
                                                                                            Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                   
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    List Surveys

                                                                </h4>


                                                            </div>
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                    <div class="card own-account-table borderTable">
                                                                        <p-table [value]="SurveyData" [rows]="rows" scrollWidth="100%" [scrollable]="true" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Survey ID
                                                                                        </div>

                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            From Date Time
                                                                                        </div>

                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Through Date Time
                                                                                        </div>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>

                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            {{product.surveyName.surveyName}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.survey.fromDate}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.survey.thruDate}}
                                                                                    </td>
                                                                                    <!-- <td [ngStyle]="{'width':'170px'}">
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2">Take
                                                                                            Survey

                                                                                        </button>
                                                                                    </td> -->
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_dataa">

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                          
                                                      
                                                     
                                                    </div>
                                                    </div>
                                                </div>

                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="w3-card-4 classCardView mt-2">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        List Task Content Items
                                                                    </h4>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
    
                                                                        <div class="card own-account-table borderTable">
                                                                            <p-table [value]="EmailsProductBacklogs" [rows]="rows"
                                                                            scrollHeight="150px"  scrollWidth="100%" [scrollable]="true" 
                                                                            [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'250px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox> Content Name
    
                                                                                            </div>
    
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Content Type ID
                                                                                            </div>
    
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Work Effort
                                                                                            </div>
    
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Content Type ID
                                                                                            </div>
    
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                From Date
                                                                                            </div>
    
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Dr Mime Type ID
                                                                                            </div>
    
                                                                                        </th>
    
    
    
    
    
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'250px'}">
                                                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2">Accounting[HELP
                                                                                                ACCOUNTING]
    
                                                                                            </button>
    
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                {{product.subject}}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.statusId}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.partyIdFrom}}
                                                                                        </td>
    
    
    
    
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_dataa">
                                                                                <!--  <ng-container *ngIf="openNoRecordFoundNote">
                              <span style="margin-left: 450px;">No Record Found!....</span>
                            </ng-container> -->
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="w3-card-4 classCardView mt-2">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Notes
                                                                    </h4>
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12 col-12">
                                                                            <div class="form-group">
    
                                                                                <div class="card own-account-table">
                                                                                    <p-table [value]="TaskNoteList" [paginator]="false" [rows]="rows" scrollWidth="100%"
                                                                                    scrollHeight="150px" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        Note Name
    
                                                                                                    </div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </th>
    
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                        Note Info
                                                                                                    </div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                        Note Date Time
                                                                                                    </div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </th>
    
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                        Note Party
                                                                                                    </div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </th>
    
                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                    <div style="color: white;">
                                                                                                        More Info Url
    
                                                                                                    </div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </th>
    
    
    
    
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                </div>
                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        <span class="account-button1" (click)="detailNote(product.list.noteId)">
                                                                                                            {{product.list.noteName}}</span>
    
                                                                                                    </div>
                                                                                                </td>
    
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.list.noteInfo}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.list.noteDateTime |date:'yyyy-MM-dd hh:mm:ss'}}
    
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.list.noteParty}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    {{product.list.moreInfoUrl}}
    
    
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                    <button type="submit" class="btn btn-secondary 
                                                                                            submit-btn ml-2" *ngIf="internalNote=='N' || !this.Hidebtn" (click)="makePrivate(product)">Make
                                                                                                        Private
                                                                                                    </button>
                                                                                                    <button type="submit" class="btn btn-secondary 
                                                                                        submit-btn ml-2" *ngIf="internalNote=='Y' || !this.Hidebtn" (click)="makePrivateN(product)">Make
                                                                                                        Public
                                                                                                    </button>
    
    
                                                                                                </td>
    
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                        <h4 class="ml-2">
                                                                                                            No
                                                                                                            Record
                                                                                                            Found
                                                                                                        </h4>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==2" class="w-100">

                                                    <div class="divA">
                                                        <div class="w3-card-4 classCard">
                                                            <div class="container-fluid">
                                                                <ul class="tabbing-section tabbing-accordians tabClass">

                                                                    <li [ngClass]="activeCategory==1?'active':''"><a>
                                                                            Edit Task # Information
                                                                        </a></li>

                                                                </ul>
                                                            </div>

                                                            <div class="create-new-leade mt-2">
                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        <form class="w-100" [formGroup]="newTaskProject">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Task
                                                                                                Name<span
                                                                                                    style="color: red;">*</span></label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <input type="text" [(ngModel)]="this.TaskInformationById.workEffortName" [ngModelOptions]="{standalone:true}" *ngIf="this.TaskInformationById" class="form-control" formControlName="taskName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Task Name">

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Parent
                                                                                                Phase</label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <p-dropdown filter="true" formControlName="parentPhase" [options]="ParentPhaseArray" optionlabel="label" placeholder="Enter Parent Phase">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Sequence
                                                                                                Num</label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <input type="text" class="form-control" formControlName="sequenceNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">My
                                                                                                Role for This
                                                                                                Week</label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <p-dropdown filter="true" formControlName="roleWeek" [options]="RoleResourceListArray" optionlabel="label" placeholder="Enter Role for This Week">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Estimated
                                                                                                Hours</label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <input type="text" class="form-control" formControlName="estimatedHours" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Hours">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Skill
                                                                                                Type ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <p-dropdown filter="true" formControlName="skillTypeID" [options]="SkillTypeIdArray" optionlabel="label" placeholder="Enter Skill Type ID">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Priority</label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <p-dropdown filter="true" formControlName="priority" [options]="PriorityListArray" optionlabel="label" placeholder="Enter Priority">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <p-dropdown filter="true" formControlName="type" [options]="TypeListArray" optionlabel="label" placeholder="Enter Type">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Description</label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Scope</label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <p-dropdown filter="true" formControlName="scope" [options]="ScopeArray" optionlabel="label" placeholder="Enter Scope">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Estimated
                                                                                                Start Date</label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <input type="date" [(ngModel)]="this.TaskInformationById.estimatedStartDate" [ngModelOptions]="{standalone:true}" *ngIf="this.TaskInformationById" class="form-control" formControlName="startDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Start Date">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-5 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Estimated
                                                                                                Completion
                                                                                                Date</label>
                                                                                        </div>
                                                                                        <div class="col-lg-5 form-group">
                                                                                            <input type="date" [(ngModel)]="this.TaskInformationById.estimatedCompletionDate" [ngModelOptions]="{standalone:true}" *ngIf="this.TaskInformationById" class="form-control" formControlName="endDate" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                                placeholder="Enter Completion Date">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 32%;">
                                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createNewTask()">Add</button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="divB">
                                                        <div class="w3-card-4 classCard">
                                                            <div class="container-fluid">
                                                                <ul class="tabbing-section tabbing-accordians tabClass">

                                                                    <li [ngClass]="activeCategory==1?'active':''"><a>
                                                                            Edit Task # Information
                                                                        </a></li>

                                                                </ul>
                                                            </div>

                                                            <div class="create-new-leade mt-2">
                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        <form class="w-100" [formGroup]="EditTaskForm">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                                <div class="col-lg-12">
                                                                                    <div class="row">


                                                                                        <div class="col-lg-5 form-group classInputC" style="margin-left: -7%;">
                                                                                            <label for="exampleInputEmail1">Parent
                                                                                                Phase
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-5">

                                                                                            <p-dropdown filter="true" optionlabel="label" [options]="ParentPhaseArray" formControlName="ParentPhase">

                                                                                            </p-dropdown>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>



                                                                            </div>
                                                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -25%;">
                                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="updateTaskSave()">Save</button>

                                                                            </div>


                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==3" class="w-100">

                                                    <div class="divA">
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Tasks to be executed before the current task
                                                                </h4>
                                                                <button *ngIf="!this.Hidebtn" type="submit" style="margin-right: 1%;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addDependencyBefore">Create New Tasks</button>
                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="DependencyFromList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Name


                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Parent Phase

                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Status
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Estimated Start Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Estimated Completion Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Actual Start Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Actual Completion Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.workeffortListFrom[0].workEffortName}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].workEffortName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].currentStatusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].estimatedStartDate| date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].estimatedCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].actualStartDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListFrom[0].actualCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td> 
                                                                                    <button *ngIf="!this.Hidebtn" type="submit" style="margin-right: 1%;" class="btn btn-secondary submit-btn ml-2" (click)="removeDependencyBefore(product)">Delete</button>
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No
                                                                                            Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div class="divB">
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Tasks to be executed After the current task
                                                                </h4>
                                                                <button *ngIf="!this.Hidebtn" type="submit" style="margin-right: 1%;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addDependencyAfter">Create New Tasks</button>
                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="DependencyToList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Name


                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Parent Phase

                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Status
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Estimated Start Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Estimated Completion Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Actual Start Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Actual Completion Date

                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.workeffortListTo[0].workEffortName}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].workEffortName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].currentStatusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].estimatedStartDate| date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].estimatedCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].actualStartDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workeffortListTo[0].actualCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td>
                                                                                     <button *ngIf="!this.Hidebtn" type="submit" style="margin-right: 1%;" class="btn btn-secondary submit-btn " (click)="removeDependencyAfter(product)">Delete</button>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No
                                                                                            Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==4" class="w-100">


                                                    <div class="w3-card-4 classCardView mt-2">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Resource List
                                                            </h4>
                                                            <button *ngIf="!this.Hidebtn" type="submit" style="margin-right: 1%;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addResource">Create New Resource</button>
                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="ResourceList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Party ID

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Role Type

                                                                                </div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Status ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Through Date

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">Delete

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">Complete


                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button" (click)="myProfile(product.data.partyId)">
                                                                                        {{product.data.partyId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.roleTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.statusId}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.fromDate| date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.thruDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn" (click)=" removeTaskPartyAssign(product)">
                                                                                    Delete</button>

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn" (click)="updateTaskPartyAssign(product) ">
                                                                                    To complete</button>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No
                                                                                        Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==5" class="w-100">


                                                    <div class="w3-card-4 classCardView mt-2">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Skill Standards
                                                            </h4>
                                                            <button *ngIf="!this.Hidebtn" type="submit" style="margin-right: 1%;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" (click)="resetSkill()" data-target="#skillStandard">Create New Skills</button>
                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="SkillList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    ID

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">

                                                                                    Skill Type

                                                                                </div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Estimated People
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Estimated Duration
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Estimated Cost

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">Update

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button" (click)="myProfile(product.data.partyId)">
                                                                                        {{product.data.workEffortId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.skillTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.estimatedNumPeople}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.estimatedDuration }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.estimatedCost }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="submit" data-toggle="modal" data-target="#skillStandard" class="btn btn-secondary submit-btn" (click)="updateSkill(product)">
                                                                                    Update</button>

                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No
                                                                                        Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                                <div *ngIf="activeCategory==6" class="w-100">


                                                    <div class="w3-card-4 classCardView mt-2">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Time Entries
                                                            </h4>
                                                            <button *ngIf="!this.Hidebtn" type="submit" style="margin-right: 1%;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" (click)="resetTimeEntry()" data-target="#AddTimeEntry">Create New TimeEntry</button>
                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="TimeSheetById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Party Id

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">

                                                                                    From Date
                                                                                </div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Rate Type
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Hours
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Comments

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">Plan Hours

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">Update

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button" (click)="myProfile(product.data.partyId)">
                                                                                        {{product.partyId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.fromDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.rateTypeId}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.hours }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.comments }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.planHours }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="submit" data-toggle="modal" data-target="#AddTimeEntry" class="btn btn-secondary submit-btn" (click)="updateTime(product)">
                                                                                    Update</button>

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.timesheetId }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn" (click)="deleteTimeEntry(product)">
                                                                                    Delete</button>

                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No
                                                                                        Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==7" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Task Content List
                                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-3" data-toggle="modal" data-target="#contentPopup">Create New Content</button>
                                                            </h4>
                                                        </div>
                                                        <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="FindTaskContentById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Content ID

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Work Effort Content Type ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                <div style="color: white;">Delete

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                <div style="color: white;">Download

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>




                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span >
                                                                                        {{product.id}}</span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.workEffortContentTypeId}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">


                                                                            </td>
                                                                            <td [ngStyle]="{'width':'100px'}">
                                                                                <button *ngIf="!this.Hidebtn" (click)="deletedTaskContentById(product.id)" type="submit" class="btn btn-secondary submit-btn ml-2">
                                                                                    Delete
                                                                                </button>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'100px'}">
                                                                                <button *ngIf="!this.Hidebtn" (click)="downloadFileTaskContent(product.fileUrl) " type="submit" class="btn btn-secondary submit-btn ml-2">
                                                                                    Download
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No
                                                                                        Record
                                                                                        Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_dataa">
                                                                    <!--  <ng-container *ngIf="openNoRecordFoundNote">
                      <span style="margin-left: 450px;">No Record Found!....</span>
                    </ng-container> -->
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==8" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Notes
                                                            </h4>
                                                            <button *ngIf="!this.Hidebtn" type="submit" style="margin-right: 1%;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#notePopUp"> Create New Notes</button>
                                                        </div>
                                                        <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="NoteList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Note

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    By
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Private/Public
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    <span >
                                                                                        {{product.list.noteName}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.list.noteParty}} at {{product.list.noteDateTime | date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button type="submit" class="btn btn-secondary 
                                                                    submit-btn ml-2" *ngIf="internalNote=='N' || !this.Hidebtn" (click)="updateTaskNote(product)">Make
                                                                                    Private
                                                                                </button>
                                                                                <button type="submit" class="btn btn-secondary 
                                                                submit-btn ml-2" *ngIf="internalNote=='Y' || !this.Hidebtn" (click)="makePrivateN(product)">Make
                                                                                    Public
                                                                                </button>


                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No
                                                                                        Record
                                                                                        Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_dataa">
                                                                    <!--  <ng-container *ngIf="openNoRecordFoundNote">
                      <span style="margin-left: 450px;">No Record Found!....</span>
                    </ng-container> -->
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==9" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                List Surveys
                                                            </h4>
                                                            <button *ngIf="!this.Hidebtn" type="submit" style="margin-right: 1%;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#surveyPopUp">Create</button>
                                                        </div>
                                                        <div class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="SurveyData" [rows]="rows" scrollWidth="100%" [scrollable]="true" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Survey ID
                                                                                </div>

                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                <div style="color: white;">
                                                                                    From Date Time
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">

                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                <div style="color: white;">
                                                                                    Through Date Time
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="price">
                                                                                <div style="color: white;">

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="price">
                                                                                <div style="color: white;">

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'100px'}" pSortableColumn="price">
                                                                                <div style="color: white;">

                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-pro>
                                                                        <tr>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    {{pro.survey.surveyId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{pro.survey.fromDate |date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{pro.survey.thruDate |date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'100px'}">
                                                                                <!-- <button type="submit"
                                                                                    class="btn btn-secondary submit-btn ml-2">
                                                                                    Take
                                                                                    Survey

                                                                                </button> -->
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'100px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="submit" data-toggle="modal" data-target="#surveyPopUp" (click)="updateSurvey(pro)" class="btn btn-secondary submit-btn ml-2">
                                                                                    Update

                                                                                </button>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'100px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="submit" (click)="deleteWorkEffortSurveyAppl(pro)" class="btn btn-secondary submit-btn ml-2">
                                                                                    Delete

                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_dataa">
                                                                    <!--  <ng-container *ngIf="openNoRecordFoundNote">
                      <span style="margin-left: 450px;">No Record Found!....</span>
                    </ng-container> -->
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==10" class="w-100">

                                                    <div class="w3-card-4 classCardView mt-2">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Add Task Content
                                                            </h4>
                                                        </div>
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Existing Content
                                                                            ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">

                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=" Enter Existing Content ID">
                                                                    </div>
                                                                </div>
                                                            </div>





                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Work Effort
                                                                            Content Type ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="statusArray" name="WorkEffortContentTypeID" optionlabel="label" placeholder="Choose Work Effort Content Type ID">
                                                                        </p-dropdown>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Mime Type ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="statusArray" name="MimeTypeID" optionlabel="label" placeholder="Choose Mime Type ID">
                                                                        </p-dropdown>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Upload
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">

                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=" Enter Upload">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Parent Content
                                                                            ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">

                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=" Enter Parent Content ID">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="col-lg-12  main-submit-button" style="
                                                                               margin-left: -59%;">
                                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="getFindProduct()">Create</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleNew" tabindex="-1" role="dialog" aria-labelledby="exampleNewTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Task # Information
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="divA">
                        <div class="w3-card-4 classCardPopUp">
                            <div class="container-fluid">
                                <ul class="tabbing-section tabbing-accordians tabClass">

                                    <li [ngClass]="activeCategory==1?'active':''"><a>
                                            Edit Task # Information
                                        </a></li>

                                </ul>
                            </div>

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="newForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">


                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="productId">Task Name
                                                                <span style="color: red;">*</span>

                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="TaskName" >

                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Parent Phase
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <p-dropdown filter="true" optionlabel="label" formControlName="ParentPhase">

                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="productId">Sequence Num

                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="SequenceNum" >

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">My Role For This Task
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <p-dropdown filter="true" optionlabel="label" formControlName="MyRoleForThisTask">

                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="productId">Estimated Hours

                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="EstimatedHours" >

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Skill Type ID
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <p-dropdown filter="true" optionlabel="label" formControlName="SkillTypeID">

                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Priority
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <p-dropdown filter="true" optionlabel="label" formControlName="Priority">

                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Type
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <p-dropdown filter="true" optionlabel="label" formControlName="Type">

                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="productId">Description

                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="Description" >

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Scope
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <p-dropdown filter="true" optionlabel="label" formControlName="Scope">

                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="productId">Estimated Start Date


                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="EstimatedStartDate" placeholder="Enter Estimated Start Date">

                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="productId">Estimated Completion Date


                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="EstimatedCompletionDate" placeholder="Enter Estimated Completion Date">

                                                        </div>

                                                    </div>
                                                </div>


                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -82%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Add</button>

                                            </div>


                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="divB">
                        <div class="w3-card-4 classCard">
                            <div class="container-fluid">
                                <ul class="tabbing-section tabbing-accordians tabClass">

                                    <li [ngClass]="activeCategory==1?'active':''"><a>
                                            Edit Task # Information
                                        </a></li>

                                </ul>
                            </div>

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="newForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">


                                                <div class="col-lg-12">
                                                    <div class="row">


                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Parent Phase
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <p-dropdown filter="true" optionlabel="label" formControlName="ParentPhase">

                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -82%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Save</button>

                                            </div>


                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addDependencyBefore" tabindex="-1" role="dialog" aria-labelledby="addDependencyBeforeTitle" aria-hidden="true">
    <div class="modal-dialog ht48" role="document">
        <div class="modal-content cssModelLength modal-cont">
            <div class="modal-header">
                <span> Add Dependency before the current Task</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp " style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active">
                                    <a>
                                        Add Dependency before the current Task</a>
                                </li>


                            </ul>
                        </div>
                        <div class="panel-group">
                            <div>
                                <div class="panel-body">
                                    <form class="w-100" [formGroup]="DependencyBeforeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row mt-3">
                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Related Tasks
                                                            <span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="RelatedTasks" [options]="RelatedTaskListArray" optionlabel="label" placeholder="Enter 
                                                        Related Tasks">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left: -13%;">
                                            <button type="submit" class="btn btn-secondary submit-btn mb-5" (click)="addDependencyFrom()">Add</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addDependencyAfter" tabindex="-1" role="dialog" aria-labelledby="addDependencyAfterTitle" aria-hidden="true">
    <div class="modal-dialog ht48" role="document">
        <div class="modal-content cssModelLength modal-cont">
            <div class="modal-header">
                <span> Add Dependency After the current Task</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp " style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active">
                                    <a>
                                        Add Dependency After the current Task</a>
                                </li>


                            </ul>
                        </div>
                        <div class="panel-group">
                            <div>
                                <div class="panel-body">
                                    <form class="w-100" [formGroup]="DependencyAfterForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row mt-3">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">
                                                            Related Tasks
                                                            <span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="RelatedTasks" [options]="RelatedTaskListArray" optionlabel="label" placeholder="Enter 
                                                        Related Tasks">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left:-13%;">
                                            <button type="submit" class="btn btn-secondary submit-btn mb-5" (click)="addDependencyTo()">Add</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addResource" tabindex="-1" role="dialog" aria-labelledby="addResource" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Resource Assignment </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Resource Assignment </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addResourceForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Party Id</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="PartyId" [options]="PartyIdArray" optionlabel="label" placeholder="Enter Party Id">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Role Type</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="roleTypeID" [options]="RoleResourceListArray" optionlabel="label" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="FromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="ThroughDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">

                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 43%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createTaskPartyAssign()">Add</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="skillStandard" tabindex="-1" role="dialog" aria-labelledby="skillStandard" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Edit Requirement</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Edit Requirement</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="skillStandardForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormI">
                                                        <label for="exampleInputEmail1">Skill Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="SkillTypeID" [options]="SkillTypeIdArray" optionlabel="label" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormJ">
                                                        <label for="exampleInputEmail1">Estimated People</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="EstimatedPeople" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated People">

                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormI">
                                                        <label for="exampleInputEmail1">Estimated Duration</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="EstimatedDuration" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Duration">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormJ">
                                                        <label for="exampleInputEmail1">Estimated Cost</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="EstimatedCost" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Cost">

                                                    </div>

                                                </div>
                                            </div>

                                            

                                            <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 46%;">
                                                <button type="submit" *ngIf="!this.showSkill" class="btn btn-secondary submit-btn" (click)="createTaskSkillStandard()">Add</button>
                                                <button type="submit" *ngIf="this.showSkill" class="btn btn-secondary submit-btn" (click)="updateTaskSkillStandard()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="AddTimeEntry" tabindex="-1" role="dialog" aria-labelledby="AddTimeEntry" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Time Entry</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Add Time Entry</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="AddTimeEntryForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormI">
                                                        <label for="exampleInputEmail1">Date </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="Date" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date ">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormJ">
                                                        <label for="exampleInputEmail1">Hours </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="Hours" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Hours ">

                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormI">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="PartyID" [options]="PartyIdArray" optionlabel="label" placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormJ">
                                                        <label for="exampleInputEmail1">Role Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="RoleTypeID" [options]="RoleResourceListArray" optionlabel="label" placeholder="Enter Role Type ID ">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormI">
                                                        <label for="exampleInputEmail1">Rate Type</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="RateType" [options]="rateTypesArray" optionlabel="label" placeholder="Enter Rate Type ">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormJ">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="Comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments ">

                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 46%;">
                                                <button type="submit" *ngIf="!this.showTimeEntry" class="btn btn-secondary submit-btn" (click)="createTimeEntry()">Add</button>
                                                <button type="submit" *ngIf="this.showTimeEntry" class="btn btn-secondary submit-btn" (click)="updateTimeEntry()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="notePopUp" tabindex="-1" role="dialog" aria-labelledby="notePopUp" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Task Notes</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Task Notes</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="AddTaskNotesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormII">
                                                        <label for="exampleInputEmail1">Note
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Note" aria-describedby="emailHelp" placeholder=" Enter Note">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormJJ">
                                                        <label for="exampleInputEmail1">Internal Note
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="InternalNote" [options]="yesNOArray" optionlabel="label" placeholder="Choose Internal Note">
                                                        </p-dropdown>


                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12  main-submit-button" style="
                                            margin-left: -16%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="addTaskNote()">Add</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="surveyPopUp" tabindex="-1" role="dialog" aria-labelledby="surveyPopUp" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Surveys</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Surveys</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="surveyForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormII">
                                                        <label for="exampleInputEmail1">Survey ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showSurvey">
                                                        <p-dropdown filter="true" formControlName="SurveyID" [options]="SurveyIDArray" optionlabel="label" placeholder="Choose Survey ID">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showSurvey">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" disabled aria-describedby="emailHelp" formControlName="SurveyID" placeholder="Choose Survey ID">

                                                        <!-- <p-dropdown filter="true" formControlName="SurveyID"
                                                           disable [options]="SurveyIDArray" optionlabel="label"
                                                            >
                                                        </p-dropdown> -->

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormJJ">
                                                        <label for="productId">From Date


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showSurvey">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="FromDate" placeholder="Enter From Date">

                                                    </div>
                                                    <div class="col-lg-2 " *ngIf="this.showSurvey">
                                                        <input type="date" class="form-control" disabled id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="FromDate" placeholder="Enter From Date">

                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormII">
                                                        <label for="productId">Through Date


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ThroughDate" placeholder="Enter Through Date">

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12  main-submit-button" style="
                                        margin-left: -16%;">


                                                <button type="submit" *ngIf="!this.showSurvey" class="btn btn-secondary submit-btn" (click)="createWorkEffortSurveyAppl()">Add</button>
                                                <button type="submit" *ngIf="this.showSurvey" class="btn btn-secondary submit-btn" (click)="updateWorkEffortSurveyAppl()">Update</button>
                                            </div>

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="contentPopup" tabindex="-1" role="dialog" aria-labelledby="contentPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Task Content</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton11>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Task Content</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="AddTaskContentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormII">
                                                        <label for="exampleInputEmail1">Existing Content
                                                            ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="existingContentIdListArray" formControlName="ExistingContent" optionlabel="label" placeholder=" Enter Existing Content ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormJJ">
                                                        <label for="exampleInputEmail1">Work Effort
                                                            Content Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="workEffortContentTypeArray" formControlName="WorkEffortContentTypeID" optionlabel="label" placeholder="Choose Work Effort Content Type ID">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>





                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormII">
                                                        <label for="exampleInputEmail1">Mime Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="mimeTypeIdArray" formControlName="MimeTypeID" optionlabel="label" placeholder="Choose Mime Type ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormJJ">
                                                        <label for="exampleInputEmail1">Upload
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="file" class="form-control" id="exampleInputEmail1" (change)="fileProgressCom($event)" formControlName="Upload" aria-describedby="emailHelp" placeholder=" Enter Upload">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormII">
                                                        <label for="exampleInputEmail1">Parent Content ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="parentContentIdArray" formControlName="ParentContent" optionlabel="label" placeholder="Choose Parent Content ID  ">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div class="col-lg-12  main-submit-button" style="
                                                                               margin-left: -16%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="uploadFormContent()">Create</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<ngx-spinner></ngx-spinner>