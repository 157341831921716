<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Config</span>
                <span class="color-black pl-1"> > New</span>

                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    New Config Item
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Config Item Type
                                                </label>
                                                <div class="">
                                                    <p-dropdown placeholder="Enter Interval Uom" filter="true" optionlabel="label" formControlName="IntervalUom">

                                                        </p-dropdown>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Config Item Name</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Description</label>
                                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="1"></textarea>
                                        </div>
                                    </div> 
                                </div>

                                <div class="main-submit-button">
                                    <button type="submit" class="btn btn-secondary bg-brown ml-3">Update</button>
                                </div>
                            </form>

                            
                        </div>
                    </div>

                </div>

            </div>
            
        </div>
    </div>
</div>