import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { PayablePaymentService } from '../../payables-payment/payables-payment.service';
import { PaymentGroupService } from '../payment-group.service';
import { faEdit, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
    selector: 'app-summary-payable-payment-group',
    templateUrl: './summary-payable-payment-group.component.html',
    styleUrls: ['./summary-payable-payment-group.component.css']
})
export class SummaryPayablePaymentGroupComponent implements OnInit {
    total=0;
    @ViewChild('closebutton') closebutton;
    rowNumber=0;
    pageSize = 10;
    activeCategory=1;
    rows = 50;
    pageNo = 1;
    downloading: boolean;
    faSpinner = faSpinner;
    paymentGroupId: string;
    paymentGroup: any;
    paymentGroupMember: any[];
    paymentIds: any[];
    editMode: boolean;
    paymentGroupMemberForm: FormGroup;
    groupMembers: any[];
    faEdit = faEdit;
    faTrash = faTrash;
    groupMemberValue: any;
    paymentGroupTypes: any[];
  
  
    paymentType: string;
    paymentGroupForm: FormGroup;
  
    constructor(
        readonly _PaymentGroupService: PaymentGroupService,
        readonly _ActivatedRoute: ActivatedRoute,
        private _location: Location,
        readonly spinner: NgxSpinnerService,
        readonly _PayablePaymentService: PayablePaymentService,
        readonly _FormBuilder: FormBuilder,
        readonly _ToastrService: ToastrService,
        readonly router: Router
    ) {
        this.paymentIds = [];
        this.paymentGroupId = '';
        this.paymentGroupMember = [];
        this.paymentGroupMemberForm = this._FormBuilder.group({
            fromDate: [''],
            paymentId: [''],
            sequenceNum: [''],
            thruDate: ['']
        });
        this.downloading = false;
        this.groupMembers = [];
        this.paymentType = '';
        this.paymentGroupId = '';
        this.paymentGroupTypes = [];
        this.paymentGroupForm = this._FormBuilder.group({
          paymentGroupName: ['', [Validators.required]],
          paymentGroupTypeId: ['CHECK_RUN']
        });
        this.editMode = false;
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.paymentGroupId = this._ActivatedRoute.snapshot.queryParams.paymentGroupId;
        this.getPaymentGroupById();
        this.getPaymentIds();
        this.getGroupMembers();
        this.getPaymentTypes();
    }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/financial/main']);
  }
    edit(){
        if (this.paymentGroupId) {
            this.editMode = true;
            this._PaymentGroupService.getPayablePaymentById(this.paymentGroupId)
              .then(data => {
                if (data) {
                  this.paymentGroup = {
                    paymentGroupName: data.data.paymentGroupName,
                    paymentGroupTypeId: data.data.paymentGroupTypeId
                  };
                  this.paymentGroupForm.patchValue({
                    paymentGroupName: data.data.paymentGroupName,
                    paymentGroupTypeId: data.data.paymentGroupTypeId
                  });
                }
              });
            this.paymentGroupForm.controls.paymentGroupTypeId.disable();
            this.paymentGroupForm.controls.paymentGroupTypeId.updateValueAndValidity();
      
          }
    }
    getPaymentTypes(): void {
        this.spinner.show();
        this._PaymentGroupService.getPaymentGroupTypes()
          .then(data => {
            this.spinner.hide();
            this.paymentGroupTypes = data.data.map(value => {
              return {
                label: value.description,
                value: value.paymentGroupTypeId
              };
            });
          });
         
      }
      updateData(): void {
        this.spinner.show();
        if (this.paymentGroupForm.valid) {
          this._PaymentGroupService.updatePaymentGroup(this.paymentGroupId, {
            ...this.paymentGroup, ...this.paymentGroupForm.value
          })
            .then(data => {
              if (data.success) {
                this._ToastrService.success('Updated');
                this.spinner.hide();
                this.paymentGroupForm.reset();
                this.paymentGroupForm.controls.paymentGroupTypeId.enable();
                this.paymentGroupForm.controls.paymentGroupTypeId.updateValueAndValidity();
                this.closebutton.nativeElement.click();
                this.getPaymentGroupById();
              }
            });
        }
        this.spinner.hide();
      }
      submitData(): void {
        this.spinner.show();
        if (this.editMode) {
          this.updateData();
        } else {
          if (this.paymentGroupForm.valid) {
            this._PaymentGroupService.createPaymentGroup(this.paymentGroupForm.value)
              .then(data => {
                if (data.success) {
                  this._ToastrService.success('Created');
                  this.paymentGroupForm.reset();
                  this.spinner.hide();
                
                }
              });
          }
        }
        this.spinner.hide();
      }
    cancelSubmit(){
        this._location.back();
      }
    getPaymentGroupById(): void {
        this.spinner.show();
        this._PaymentGroupService.getPayablePaymentById(this.paymentGroupId)
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.paymentGroup = data.data;
                    this.paymentGroupMember = data.data.paymentGroupMember;
                }
            });
            
    }
    getPaymentIds(): void {
        this.spinner.show();
        this._PayablePaymentService.getPaymentList({ pageNo: 1, pageSize: 200 }, {
            amount: '',
            comments: '',
            commentsSearchType: 'Contains',
            fromPartyId: '',
            paymentId: '',
            paymentIdSearchType: 'Contains',
            paymentType: '',
            status: '',
            toPartyId: ''
        })
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.paymentIds = data.data.map(value => {
                        return {
                            label: value.paymentId,
                            value: value.paymentId
                        };
                    });
                }
            });
            
    }
    getGroupMembers(): void {
        this.spinner.show();
        this._PaymentGroupService.getGroupMembers(this.paymentGroupId)
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.groupMembers = data.data;
                }
            });
           
    }
    makeGroupMemberEditable(paymentId: string, fromDate: string, thruDate: string, sequenceNum: string): void {
        this.editMode = true;
        this.groupMemberValue = { paymentId, fromDate, thruDate, sequenceNum };
        this.paymentGroupMemberForm.patchValue({
            paymentId, fromDate: new Date(fromDate), thruDate: thruDate ? new Date(thruDate) : '', sequenceNum
        });
        this.paymentGroupMemberForm.controls.paymentId.disable();
        this.paymentGroupMemberForm.controls.paymentId.updateValueAndValidity();
        this.paymentGroupMemberForm.controls.fromDate.disable();
        this.paymentGroupMemberForm.controls.fromDate.updateValueAndValidity();
        this.paymentGroupMemberForm.controls.sequenceNum.disable();
        this.paymentGroupMemberForm.controls.sequenceNum.updateValueAndValidity();
    }
    update(): void {
        this.spinner.show();
        this._PaymentGroupService.updateGroupMember(this.paymentGroupId, {
            ...this.groupMemberValue, ...this.paymentGroupMemberForm.value, ...{
                fromDate: this.groupMemberValue.fromDate ? moment(this.groupMemberValue.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
                thruDate: this.paymentGroupMemberForm.value.thruDate ? moment(this.paymentGroupMemberForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Created');
                    this.spinner.hide();
                    this.paymentGroupMemberForm.reset();
                    this.paymentGroupMemberForm.controls.paymentId.enable();
                    this.paymentGroupMemberForm.controls.paymentId.updateValueAndValidity();
                    this.paymentGroupMemberForm.controls.fromDate.enable();
                    this.paymentGroupMemberForm.controls.fromDate.updateValueAndValidity();
                    this.paymentGroupMemberForm.controls.sequenceNum.enable();
                    this.paymentGroupMemberForm.controls.sequenceNum.updateValueAndValidity();
                    this.getGroupMembers();
                    this.editMode = false;
                }
            });
            this.spinner.hide();
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            this._PaymentGroupService.createGroupMember(this.paymentGroupId, {
                ...this.paymentGroupMemberForm.value, ...{
                    fromDate: this.paymentGroupMemberForm.value.fromDate ? moment(this.paymentGroupMemberForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
                    thruDate: this.paymentGroupMemberForm.value.thruDate ? moment(this.paymentGroupMemberForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.paymentGroupMemberForm.reset();
                        this.getGroupMembers();
                    }
                });
        }
        this.spinner.hide();
    }
    reset(): void {
        this.editMode = false;
        this.paymentGroupMemberForm.reset();
        this.paymentGroupMemberForm.controls.sequenceNum.enable();
        this.paymentGroupMemberForm.controls.sequenceNum.updateValueAndValidity();
    }
    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    downloadPDF(): void {
        this.spinner.show();
        this.downloading = true;
        this._PaymentGroupService.checksToPrint(this.paymentGroupId)
            .subscribe(value => {
                if (value) {
                    const blob = new Blob([value], { type: 'application/application/pdf' });
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                        return; } 
                    const data = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = data;
                    link.download = `${this.paymentGroupId}.pdf`;
                    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                    setTimeout(() => {
                        window.URL.revokeObjectURL(data);
                        link.remove();
                    }, 100);
                    this.downloading = false;
                }
            }, (err) => {
                if (err.error.errors) {
                    err.error.errors.forEach(value => {
                        this.downloading = false;
                        this._ToastrService.error(`${value.fieldName} ${value.fieldError}`);
                    }); }
            });
            this.spinner.hide();}
    cancelGroupMember(paymentId: string, fromDate: string): void {
        this.spinner.show();
        this._PaymentGroupService.cancelGroupMember(this.paymentGroupId, paymentId, moment(fromDate).format('YYYY-MM-DD HH:mm:ss'))
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this._ToastrService.success('Canceled');
                    this.getGroupMembers();
                }
            });
            
    }
    changeactiveCategoryFunction(tab: number){
        this.activeCategory =tab;
      }
      ngOnDestroy(): void {
        this.spinner.hide();
          }
}