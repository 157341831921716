import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-reports-marketing',
  templateUrl: './reports-marketing.component.html',
  styleUrls: ['./reports-marketing.component.css']
})
export class ReportsMarketingComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total = 0;
  show=true;
  emailReport=false
  trackCode=false;
  marketCampaignId=false;
  partyStatusReport=false;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  trackingcodereport: FormGroup;
  EmailStatusReport: FormGroup;
  MarketingCampaignReport: FormGroup;
  PartyStatusReport: FormGroup;
  TrackingCode: any;
  TrackingCodeArray: any[]=[];
  MarketCampaignIds: any;
  MarketCampaignIdsArray: any[]=[];
  EmailStatusReportArray: any;
  EmailStatusReports: any;
  EmailStatusReportsArray: any[]=[];
  PartyStatusReports: any;
  PartyStatusReportsArray: any[]=[];
  RoleStatusArray: { label: string; value: string; }[];
  ContactListName: any;
  ContactListNameArray: any[]=[];
  TrackingCodeReport: any;
  EmailReport: any;
  PartyReport: any;
  MarketCampaignReport: any;
  PartyIDFrom: any;
  PartyIDFromArray: any[]=[];
  activeCategoriess=20;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {

    this.RoleStatusArray = [

      {
        label: 'Created',
        value: 'COM_ROLE_CREATED'
      },
      {
        label: 'Read',
        value: 'COM_ROLE_READ'
      },
      {
        label: 'Closed',
        value: 'COM_ROLE_COMPLETED'
      },
    ];
    this.trackingcodereport = this._FormBuilder.group({
      FromDate: [''],
      ThroughDate: [''],
      TrackingCode: [''],
      });

      this.EmailStatusReport = this._FormBuilder.group({
        FromDate: [''],
        ThroughDate: [''],
        ToPartyID: [''],
        FromPartyID: [''],
        Status: [''],
        RoleStatus: [''],
        });

        this.MarketingCampaignReport = this._FormBuilder.group({
          FromDate: [''],
          ThroughDate: [''],
          MarketingCampaign: [''],
          });

          this.PartyStatusReport = this._FormBuilder.group({
            FromDate: [''],
            StatusDate: [''],
            ThroughDate: [''],
            ContactLists: [''],
            Status: [''],
            });
   }

  ngOnInit(): void {
    this.getTrackingCode();
    this.getMarketCampaignIds();
    this.getEmailStatusReport();
    this.getPartyStatusReport();
    this.getContactListName();
    this.getPartyID();
  }
  createcontent(){
    this._Router.navigate(['/marketing/create-content']);
    
  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getPartyID() {
    this.spinner.show();
    this.accountsService.getPartyID().subscribe(res => {
      this.PartyIDFrom = res.data;
      this.spinner.hide();
      for (const value of this.PartyIDFrom) {
        this.PartyIDFromArray.push({
          label: value.PartyIDFrom.partyTypeId,
          value: value.PartyIDFrom.partyTypeId
        })
      }
    })
  }
  getTrackingCode() {
    this.spinner.show();
    this.myContactsService.getTrackingCode().subscribe(res => {
      this.TrackingCode = res.data[0].data;
      this.spinner.hide();
      for (const value of this.TrackingCode) {
        this.TrackingCodeArray.push({
          label: value.trackingCodeId,
          value: value.trackingCodeId
        })
      }
    })
  }
  getMarketCampaignIds() {
    this.spinner.show();
    this.myContactsService.getMarketCampaignIds().subscribe(res => {
      this.MarketCampaignIds = res.data[1].data;
      this.spinner.hide();
      for (const value of this.MarketCampaignIds) {
        this.MarketCampaignIdsArray.push({
          label: value.campaignName,
          value: value.MarketCampaignId
        })
      }
    })
  }
  getEmailStatusReport() {
    this.spinner.show();
    this.myContactsService.getEmailStatusReport().subscribe(res => {
      this.EmailStatusReports = res.data[0].data;
      this.spinner.hide();
      for (const value of this.EmailStatusReports) {
        this.EmailStatusReportsArray.push({
          label: value.description,
          value: value.statusValue
        })
      }
    })
  }
  getPartyStatusReport() {
    this.spinner.show();
    this.myContactsService.getPartyStatusReport().subscribe(res => {
      this.PartyStatusReports = res.data[0].data;
      this.spinner.hide();
      for (const value of this.PartyStatusReports) {
        this.PartyStatusReportsArray.push({
          label: value.description,
          value: value.statusValue
        })
      }
    })
  }
  getContactListName() {
    this.spinner.show();
    this.myContactsService.getContactListName().subscribe(res => {
      this.ContactListName = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactListName) {
        this.ContactListNameArray.push({
          label: value.contactListName,
          value: value.contactListName
        })
      }
    })
  }

  getTrackingCodeReport() {

   
    if(  this.trackingcodereport.value.FromDate||this.trackingcodereport.value.ThroughDate||this.trackingcodereport.value.TrackingCode){
      this.spinner.show();
    this.show=false;
    this.trackCode=true;
    this.emailReport=false;
    this.partyStatusReport=false;
    this.marketCampaignId=false;
    this.accountsService.getTrackingCodeReport(this.trackingcodereport.value.FromDate,this.trackingcodereport.value.ThroughDate,this.trackingcodereport.value.TrackingCode).subscribe(res => {
      this.TrackingCodeReport = res.data;
      this.trackingcodereport.reset();
      this.spinner.hide();
    
    })
  }
  else{
    this.spinner.show();
    this.show=false;
    this.trackCode=true;
    this.emailReport=false;
    this.partyStatusReport=false;
    this.marketCampaignId=false;
    this.accountsService.getTrackingCodeReportWith().subscribe(res => {
      this.TrackingCodeReport = res.data;
      this.trackingcodereport.reset();
      this.spinner.hide();
    
    })
  }
  }

  getPartyReport() {
    if(this.PartyStatusReport.value.FromDate||this.PartyStatusReport.value.ThroughDate||this.PartyStatusReport.value.ContactLists||this.PartyStatusReport.value.StatusDate||this.PartyStatusReport.value.Status){
    this.spinner.show();
    this.show=false;
    this.trackCode=false;
    this.emailReport=false;
    this.partyStatusReport=true;
    this.marketCampaignId=false;
    this.accountsService.getPartyReport(this.PartyStatusReport.value.FromDate,this.PartyStatusReport.value.ThroughDate,this.PartyStatusReport.value.ContactLists,this.PartyStatusReport.value.StatusDate,this.PartyStatusReport.value.Status).subscribe(res => {
      this.PartyReport = res.data;
      this.PartyStatusReport.reset();
      this.spinner.hide();
    
    })
  }
  else{
    this.spinner.show();
    this.show=false;
    this.trackCode=false;
    this.emailReport=false;
    this.partyStatusReport=true;
    this.marketCampaignId=false;
    this.accountsService.getPartyReportWith().subscribe(res => {
      this.PartyReport = res.data;
      this.PartyStatusReport.reset();
      this.spinner.hide();
    
    })
  }
  }

  getMarketCampaignReport() {
    if(this.MarketingCampaignReport.value.FromDate||this.MarketingCampaignReport.value.ThroughDate||this.MarketingCampaignReport.value.MarketingCampaign){
    this.spinner.show();
    this.show=false;
    this.trackCode=false;
    this.emailReport=false;
    this.partyStatusReport=false;
    this.marketCampaignId=true;
    this.accountsService.getMarketCampaignReport(this.MarketingCampaignReport.value.FromDate,this.MarketingCampaignReport.value.ThroughDate,this.MarketingCampaignReport.value.MarketingCampaign).subscribe(res => {
      this.MarketCampaignReport = res.data;
      this.MarketingCampaignReport.reset();
      this.spinner.hide();
    
    })
  }
  else{
    this.spinner.show();
    this.show=false;
    this.trackCode=false;
    this.emailReport=false;
    this.partyStatusReport=false;
    this.marketCampaignId=true;
    this.accountsService.getMarketCampaignReportWith().subscribe(res => {
      this.MarketCampaignReport = res.data;
      this.MarketingCampaignReport.reset();
      this.spinner.hide();
    
    })
  }
  }
  getEmailReport() {
    if(this.EmailStatusReport.value.FromDate||this.EmailStatusReport.value.ThroughDate||this.EmailStatusReport.value.FromPartyID||this.EmailStatusReport.value.ToPartyID||this.EmailStatusReport.value.RoleStatus||this.EmailStatusReport.value.Status){
    this.spinner.show();
    this.show=false;
    this.trackCode=false;
    this.emailReport=true;
    this.partyStatusReport=false;
    this.marketCampaignId=false;
    this.accountsService.getEmailReport(this.EmailStatusReport.value.FromDate,this.EmailStatusReport.value.ThroughDate,this.EmailStatusReport.value.FromPartyID,this.EmailStatusReport.value.ToPartyID,this.EmailStatusReport.value.RoleStatus,this.EmailStatusReport.value.Status).subscribe(res => {
      this.EmailReport = res.data;
      this.EmailStatusReport.reset();
      this.spinner.hide();
    
    })
  }
  else{
    this.spinner.show();
    this.show=false;
    this.trackCode=false;
    this.emailReport=true;
    this.partyStatusReport=false;
    this.marketCampaignId=false;
    this.accountsService.getEmailReportWith().subscribe(res => {
      this.EmailReport = res.data;
      this.EmailStatusReport.reset();
      this.spinner.hide();
    
    })
  }
  }
  MobileMessaging()
  {
    this._Router.navigate(['/#']);

  } 
  SocialMedia()
  {
    this._Router.navigate(['/#']);

  }
  toAccount()
  {
    this._Router.navigate(['/crm/new-accounts']);
  }
  toContact()
  {
    this._Router.navigate(['/crm/my-contacts']);

  }
  toOpportunity()
  {
    this._Router.navigate(['/crm/opportunities']);

  }
  toEmail(){
    this._Router.navigate(['/communication-overview/Dashboard']);
  }
  toForecast(){
    
    this._Router.navigate(['/crm/sales-forecast']);

  }
  toCalendar(){
    
    this._Router.navigate(['crm/sales-calendar']);

  }
  toNotes(){
    this._Router.navigate(['/crm/sales-notes']);
  }
  toEmails(){
    
    this._Router.navigate(['/communication-overview/Dashboard']);

  }  
  toTasks(){
    this._Router.navigate(['/crm/Tasks']);
  }
  toDocuments(){
    
    this._Router.navigate(['crm/sales-documents']);

  }
  toLead()
  {
    this._Router.navigate(['/crm/leads']);

  }
  toCases()
  {
    this._Router.navigate(['/marketing/cases-marketing']);

  }
  openDataResource()
  {
    this._Router.navigate(['/marketing/data-source-marketing']);
  }
  openMarketingCampaign()
  {
    this._Router.navigate(['/marketing/marketing-campaign']);
  }
  openTracking()
  {
    this._Router.navigate(['/marketing/tracking-marketing/tracking-marketing']);
  }

  openSegment()
  {
    this._Router.navigate(['/marketing/segment-marketing']);
  }

  openContactList()
  {
    this._Router.navigate(['/marketing/contact-list-marketing']);
  
  }
  openReports()
  {
    this._Router.navigate(['/marketing/reports-marketing']);
  }

  openCustomer()
  {
    this._Router.navigate(['/crm/accounts']);

  }
  openActivities()
  {
    this._Router.navigate(['/crm/events']);

  }

  changeActiveCategoryFunctions(tab)
  {
    this.activeCategoriess=tab;
  }
  
}
