<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div>
          <div class="container-fluid">
            <div class="row">
                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                  <div class="w-100">  
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                          <h4 class="panel-title port-header d-flex justify-content-between" style="background:#0d3769">
                            <a class=" w-100 position-relative  tabs-header" style="display: flex !important; justify-content: space-between;">
                              Send a confirmation email
                              <div class="icon-wrapper sales-properties custom-properties">
                                <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse" href="#seo-collapse">
                                </span>
                            </div>
                            </a>
                          </h4>
                        </div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections"> <div class="panel-body">
                            <div class="mt-2">
                              <div class="">
                                <div class="row">
                                  <form class="w-100">
                                    <div class="col-lg-12 col-12 main-submit-button">
                                      <button  type="submit" class="btn btn-secondary submit-btn" routerLinkActive="active"  routerLink="/order/sales-order-view-page">Go Back</button>
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Subject</label>
                                          <input type="text"  class="form-control"
                                            placeholder="Enter Subject">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">Send To</label>
                                          <input type="text"  class="form-control"
                                            placeholder="Send to">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">CC To</label>
                                          <input type="text"  class="form-control"
                                            placeholder="Enter CC To">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">BCC To</label>
                                          <input type="text"  class="form-control"
                                            placeholder="Enter BCC To">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                          <label class="common-labels">BCC To</label>
                                            <p class="common-regular-properties">ofbiztest@example.com
                                            </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-8 col-12">
                                  <div class="form-group">
                                      <label for="exampleInputEmail1">Content</label>
                                      <div class="">
                                        <textarea maxlength="1500" [rows]="2" [cols]="30"
                                        autoResize="autoResize"></textarea>
                                        </div>
                                      </div>
                                   </div>  
                                  </div>
                                    <div class="col-lg-12 col-12 main-submit-button">
                                      <button  type="submit" class="btn btn-secondary submit-btn">Send</button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
          </div>

            </div>
          </div>
        </div>
      </div>
    </div>
