import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { WarehouseService } from '../warehouse/warehouse.service';
import * as moment from 'moment';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { ProductService } from 'src/app/catalog-mgmt/new-products/product.service';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import {  FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.css']
})
export class PlanningComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  findPlanning: { facility: string; product: string; internalName: string; productType: string; category: string; supplier: string; qohStock: string; atpStock: string; soldThru: string; productsPerPage: string; };
  ProductId: any;
  ProductIdArray: any[]=[];
  facility: any;
  facilityArray: any[]=[];
  facilityInventoryForm: FormGroup;
  statusIds: any[];
  categories: any[];
  products: any[];
  productsData: any[];
  productType: any[];
  inventoryTab: string;
  productIdName: string;
  inventories: any[];
  inventoryItems: any[];
  inventoryGrands: any[];
  inventoryAverageCosts: any[];
  partyIds: any[];
    facilityIds: any[];
    facilityId: string;
  suppliers: any[];
  receiveProductsMasterValue: any[];

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
        readonly _MainCatalogService: MainCatalogService,
        readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
        readonly _ProductService: ProductService,
        readonly _FormBuilder: FormBuilder,    private _location: Location,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
        readonly _CategoryService: CategoryService,
        readonly _Router: Router,
    readonly _WarehouseService: WarehouseService,

  ) { 
    this.findPlanning = {
      facility: "",
      product: "",
      internalName: "",
      productType: "",
      category: "",
      supplier: "",
      qohStock: "",
      atpStock: "",
      soldThru: "",
      productsPerPage: "",
    };
  }


  ngOnInit(): void {
    setTimeout(() => {
      const form = this.facilityInventoryForm;
      form.patchValue({
        facilityId :"WebStoreWarehouse"
      })
    }, 1000);

    this.getProductIds();
    this.getFacilityId();
    this.facilityInventoryDefaultValue();
    this.getSuppliers();
    this.getProducts();
    this.getProductType();
    this.getCategories();
    this.getStatusIds();
    this.getCategories();
  }
  navToProdictConfiguration(product){
    this._Router.navigate(['/facilities/catalog/products/detail-product-summary/{{product.productId}}'],);
  }
  getStatusIds(): void {
    this.spinner.show();
    this._WarehouseService.inventoryStatusId()
        .then(data => {
            this.spinner.hide();
            this.statusIds = data.data.map(value => {
                return {
                    label: value.description,
                    value: value.statusId
                };
            });
        });
    
}
  getCategories(): void {
    this.spinner.show();
    this._CategoryService.getCategories(
        { pageNo: 1, pageSize: 200 }, { categoryId: '', categoryIdSearchType: 'Contains', categoryName: '', categoryNameSearchType: 'Contains' })
        .then(data => {
            this.spinner.hide();
            this.categories = data.data.map(value => {
                return {
                    label: value.categoryName,
                    value: value.productCategoryId
                };
            });
        });
        
}
  getProductType(): void {
    this.spinner.show();
    this._ProductService.getProductMenu()
        .then(data => {
            this.spinner.hide();
            this.productType = data.data.productType.map(value => {
                return {
                    label: value.description,
                    value: value.productTypeId
                };
            });
        });
       
}
  getSuppliers(): void {

    this.spinner.show();
    this._WarehouseService.getSuppliers()
        .then(data => {
            this.spinner.hide();
            this.suppliers = data.data.map(value => {
                return {
                    label: value.partyId,
                    value: value.partyId
                };
            });
        });
        
}
  facilityInventoryDefaultValue(): void {
    this.facilityInventoryForm = this._FormBuilder.group({
        facilityId: [''],
        fromDateSellThrough: [''],
        internalName: [''],
        monthsInPastLimit: [''],
        offsetATPQty: [''],
        offsetQOHQty: [''],
        productId: [''],
        productSupplierId: [''],
        productTypeId: [''],
        productsSoldThruTimestamp: [''],
        searchInProductCategoryId: [''],
        statusId: [''],
        thruDateSellThrough: [''],
        viewIndex: ['0'],
        viewSize: ['20']
    });
    this.categories = [];
    this.products = [];
    this.productType = [];
    this.inventoryTab = 'inventory';
    this.inventories = [];
    this.inventoryItems = [];
    this.inventoryGrands = [];
    this.inventoryAverageCosts = [];
    this.partyIds = [];
    this.facilityIds = [];
}
editProduct(id) {
  this._Router.navigate(["/facilities/catalog/products/detail-product-summary/"],
   { queryParams: { productId: id } })
}
homeButton(){
  this._Router.navigate(['/facilities/Dashboard'])

}
cancelSubmit(){
  this._location.back();
}
getProducts(): void {
  this.spinner.show();
  this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
      .then(data => {
          this.spinner.hide();
          this.receiveProductsMasterValue = data.data;
          this.products = data.data.map(value => {
              return {
                  label: value.productId,
                  name: value.productName,
                  value: value.productId
              };
          });
      });
     
}
searchInventoryReset(): void {
 this.facilityInventoryForm.reset();
 
   setTimeout(() => {
     const form = this.facilityInventoryForm;
     form.patchValue({
      viewIndex: '0',
        viewSize: '20'
     })
   }, 500);
//   this._WarehouseService.getFacilityInventories('Get', {
//     ...this.facilityInventoryForm.value, ...{
//         facilityId: this.facilityInventoryForm.value.facilityId ?this.facilityInventoryForm.value.facilityId: '',
//         fromDateSellThrough: this.facilityInventoryForm.value.fromDateSellThrough ? moment(this.facilityInventoryForm.value.fromDateSellThrough).format('YYYY-MM-DD HH:mm:ss') : '',
//         productsSoldThruTimestamp: this.facilityInventoryForm.value.productsSoldThruTimestamp ? moment(this.facilityInventoryForm.value.productsSoldThruTimestamp).format('YYYY-MM-DD HH:mm:ss') : '',
//         thruDateSellThrough: this.facilityInventoryForm.value.thruDateSellThrough ? moment(this.facilityInventoryForm.value.thruDateSellThrough).format('YYYY-MM-DD HH:mm:ss') : '',
//     }
// })
//     .then(data => {
//         if (data.data.results) {
         
//             this.inventories = data.data.results;
//         }
//     });
}

  searchInventory(): void {
  //  this.spinner.show();
    this._WarehouseService.getFacilityInventories('Get', {
        ...this.facilityInventoryForm.value, ...{
            facilityId: this.facilityInventoryForm.value.facilityId ?this.facilityInventoryForm.value.facilityId: '',
            fromDateSellThrough: this.facilityInventoryForm.value.fromDateSellThrough ? moment(this.facilityInventoryForm.value.fromDateSellThrough).format('YYYY-MM-DD HH:mm:ss') : '',
            productsSoldThruTimestamp: this.facilityInventoryForm.value.productsSoldThruTimestamp ? moment(this.facilityInventoryForm.value.productsSoldThruTimestamp).format('YYYY-MM-DD HH:mm:ss') : '',
            thruDateSellThrough: this.facilityInventoryForm.value.thruDateSellThrough ? moment(this.facilityInventoryForm.value.thruDateSellThrough).format('YYYY-MM-DD HH:mm:ss') : '',
        }
    })
        .then(data => {
            if (data.data.results) {
              //  this.spinner.hide();
                this.inventories = data.data.results;
            }
        });
      
}
  getFacilityId() {
    this.spinner.show();
    this.myContactsService.getFacilityId().subscribe(res => {
      this.facility = res.data[0].getFacilityId;
      this.spinner.hide();
      for (const value of this.facility) {
        this.facilityArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
   
  }
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName + "[" + value.productId + "]",
          value: value.productId
        })
      }
    })
   
  }


  openInventory()
  {
    this._Router.navigate(['/facilities/Planning']);

  }
  openFindRequirements()
  {
    this._Router.navigate(['/facilities/Planning/find-requirements']);

  }
  openRequirements()
  {
    this._Router.navigate(['/facilities/Planning/open-requirements']);

  }
  openProductRequirements()
  {
    this._Router.navigate(['/facilities/Planning/product-requirements']);
    
  }
  openInternalRequirements()
  {
    this._Router.navigate(['/facilities/Planning/internal-requirements']);

  }
  openTransferRequirements()
  {
    this._Router.navigate(['/facilities/Planning/transfer-requirements']);

  }
  openSalesOrderHistory()
  {
    this._Router.navigate(['/facilities/Planning/upload-sales-order-history']);

  }
  openRunMRP()
  {
    this._Router.navigate(['/facilities/Planning/run-mrp']);

  }
  openViewMRP()
  {
    this._Router.navigate(['/facilities/Planning/view-mrp']);

  }
  openConfigureWarehouses()
  {
    this._Router.navigate(['/facilities/Planning/configure-warehouse']);

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
