<div class="container-fluid main-container-wrapper-new">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Dashboard</span>
               <span class="d-flex">
               <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

               <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
        </div>
    </div>
   
    <div class="row">
        <div class="col-md-6 pr-2">
           
            <div class=" mt-2">
                <div class="w3-card-4 cardEffect w-100" style="height: 130px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <h4 class="common-styling h4Margin m-0" style="color: #242222b5;
                        font-size: 13px;
                        font-weight: 600;">My Communication</h4>
                    </div>
                    <div class=" font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/communication-overview/mycommunications">
                                            New Communication
                                        </span>
                                      
                                    </div>
                                   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
          
            <div class=" mt-2">
                <div class="w3-card-4 cardEffect w-100" style="height: 105px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <h4 class="common-styling h4Margin m-0" style="color: #242222b5;
                        font-size: 13px;
                        font-weight: 600;">Email From Unknown Party</h4>
                    </div>
                    <div class=" font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/communication-overview/communications-email-form">
                                            Communications from Party origin
                                        </span>
                                       
                                    </div>
                                 


                                  
                                    
                                    
                                    
                              
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
          
        </div>
        <div class="col-md-6" style="padding-left: 0;">
            <div class="mt-2">
                <div class="w3-card-4 cardEffect w-100" style="height: 130px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <h4 class="common-styling h4Margin m-0" style="color: #242222b5;
                        font-size: 13px;
                        font-weight: 600;">Communication</h4>
                    </div>
                    <div class=" font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/communication-overview/allcommunications">
                                            Find Communications
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/communication-overview/allcommunications">
                                            Email From unknown Parties
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/communication-overview/allcommunications">
                                            
                                            Find Communications by Order
                                         </span>
                                    </div>
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/communication-overview/allcommunications">
                                            Find Communications by Return
                                        </span>
                                       
                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="mt-2">
                <div class="w3-card-4 cardEffect w-100" style="height: 105px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <h4 class="common-styling h4Margin m-0" style="color: #242222b5;
                        font-size: 13px;
                        font-weight: 600;">Find Communication By Order</h4>
                    </div>
                    <div class=" font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <div class="card own-account-table" style="background-color: #fafafa !important;">
                                        <div class="article">
                                            <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/communication-overview/find-communications">
                                                Find Communication By Order
                                            </span>
                                         
                                        </div>
                                    
    
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          

        
        </div>
       
       
    </div>
</div>    


<ngx-spinner></ngx-spinner>