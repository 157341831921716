import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder ,FormGroup,Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';  import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configure-warehouse-existing-detail',
  templateUrl: './configure-warehouse-existing-detail.component.html',
  styleUrls: ['./configure-warehouse-existing-detail.component.css']
})
export class ConfigureWarehouseExistingDetailComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;

  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  addNewForm: FormGroup;
  planningWareHouseId: any;
  planningWareHousesById: any;
  currencyUomIds: any[];
  personId: any;
  partyIdArray: any[]=[];  InitialManager: any;
  InitialManagerArray: any[]=[];
  inventoryItemArray: { label: string; value: string; }[];
  primaryWarehouse: any;
  primaryWarehouseArray: any[]=[];
  primaryWarehouseId: any;
  FacilityIdToArray: any[]=[];
  FacilityIdTo: any;
  planningWareHousesExistingById: any;
  addExistingForm:FormGroup;
  planningWareHouseExistingId: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly mergeContactsService: MergeContactsService,
    readonly _ToastrService: ToastrService,
    readonly addSkillService: AddSkillService,
    readonly _FormBuilder: FormBuilder,    private _location: Location,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  ) 
  {

    this.addExistingForm=this._FormBuilder.group({
      existingBackupWarehouse:"",
      backupWarehouse:"",
      Priority:"",
      From:"",
      Thru:"",
    })
    this.inventoryItemArray =[
      {
       label:"Serialized",
       value:"SERIALIZED_INV_ITEM"
      },
       {
         label:"Non-Serialized",
         value:"NON_SERIAL_INV_ITEM"
       }
     ]
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      this.planningWareHouseExistingId = params.planningWareHouseExistingId;
      this.primaryWarehouseId = params.primaryWarehouseId;
    })
    this.getPlanningWareHousesExistingById();
    this.getFacilityIdTo();
  }
  getFacilityIdTo() {
    this.spinner.show();
    this.mergeContactsService.getFacilityIdTo(this.primaryWarehouseId).subscribe(res => {
      this.FacilityIdTo = res.data[0].getFacilityIdTo;
      this.spinner.hide();
      for (const value of this.FacilityIdTo) {
        this.FacilityIdToArray.push({
          label: value.facilityId,
          value: value.facilityId
        })
      }
    })
  }
  getPlanningWareHousesExistingById() {
    this.spinner.show();
    this.mergeContactsService.getPlanningWareHousesExistingById(this.planningWareHouseExistingId).subscribe(res => {
      this.planningWareHousesExistingById = res.data[0].getPlanningWareHousesExistingById[0];
      this.spinner.hide();
      setTimeout(() => {
        const form = this.addExistingForm;
      form.patchValue({
        existingBackupWarehouse:this.planningWareHousesExistingById.planningWareHouseExistingId ,
      backupWarehouse:this.planningWareHousesExistingById.primaryWarehouse ,
      Priority:this.planningWareHousesExistingById.priority ,
      From:this.datePipe.transform( this.planningWareHousesExistingById.fromDate,"yyyy-MM-dd"),
      Thru:this.datePipe.transform( this.planningWareHousesExistingById.thruDate,"yyyy-MM-dd"),
      })
      }, 2000);
    })
   
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  UpdatePlanningWareHousesExisting(): void {
    this.spinner.show();
    const requestData = {
      "facilityIdTo": this.addExistingForm.value.backupWarehouse,
  "fromDate":this.datePipe.transform(this.addExistingForm.value.From,"yyyy-MM-dd hh:mm:ss") ,
  "planningWareHouseExistingId": this.planningWareHouseExistingId,
  "primaryWarehouse":this.primaryWarehouseId,
  "priority": this.addExistingForm.value.Priority,
  "thruDate":this.datePipe.transform(this.addExistingForm.value.Thru,"yyyy-MM-dd hh:mm:ss") 

    };
    this.mergeContactsService.UpdatePlanningWareHousesExisting(requestData).subscribe((res: any) => {
  
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.addExistingForm.reset();
        this.getPlanningWareHousesExistingById();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  toBack()
  {
    this._Router.navigate(['/facilities/Planning/configure-warehouse']);
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}

