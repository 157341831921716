import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeService } from '../employees/employess.service';
import { EmpPhoneFormService } from '../update-phone-number/update-phone-service';
import { EmpEmailFormService } from './emp-emai-service';
import { EmpEmailPhoneModel } from './emp.upadate-email.model';
import {Location} from '@angular/common';

@Component({
  selector: 'app-emp-update-email',
  templateUrl: './emp-update-email.component.html',
  styleUrls: ['./emp-update-email.component.css']
})
export class EmpUpdateEmailComponent implements OnInit {
  partyId: any;
  partyIds: any;
  employeeDetail: any;
  crmStatus: any;
  crmArray: any[] = [];
  solicitation: any[] = [];
  cid: any;
  editMode: boolean;
  constructor(
    readonly employeeService: EmployeeService,
    public empPersonalFormService: EmpEmailFormService,
    readonly toastr: ToastrService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    private _location: Location,
    readonly spinner:NgxSpinnerService,
  ) {
    this.solicitation = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      }
    ];
    this.editMode = false;
  }
  ngAfterViewInit(): void {  }
  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params['personId'];
      this.cid = params['contactMechId'];

    })
    this.partyIds = this.partyId;
    this.empPersonalFormService.emp_email_form = null;
    if (this.cid) {
      this.getEmployeeDetail();
    }
    this.empPersonalFormService.createEventForm(this.empPersonalFormService.emp_email_form);
  }
  getEmployeeDetail() {
    this.spinner.show();
    this.employeeService.getEmailEmployeeId(this.partyId).subscribe(res => {
      setTimeout(() => {
        const formValue = this.empPersonalFormService.emp_email_form;
        formValue.patchValue({
          contactMechId: this.cid,
          emailAddress: res.data.email.infoString,
          partyId: this.partyId
  
        });
      }, 1000);
     
      this.spinner.hide();

    })
   
  }
  cancelSubmit() {
    this.router.navigate(["/hr/employees/employee-details"], { queryParams: { employeeDetailId: this.partyId } });
  }

  onSubmit(): void {

  }
  onUpdate() {
    this.spinner.show();
    const formData = { ... this.empPersonalFormService.emp_email_form.value };
    this.employeeService.updateEmail(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this._location.back();
    //    this.router.navigate(["/hr/employees/employee-details"], { queryParams: { employeeDetailId: this.partyId } });
      } else if (res.success == false) {
        this.toastr.error(res.message);
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
    this.spinner.hide();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
