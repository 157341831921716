import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
    import { Location } from '@angular/common';
    import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import Swal from 'sweetalert2';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { Color, Label } from 'ng2-charts';
import { ChartType } from 'chart.js';
import { ChartDataSets, ChartOptions } from 'chart.js';
@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css']
})
export class AdministrationComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton10') closebutton10;
  activeCategory = 7;
  activeCategoryA = 1;
  resData: any;
  total = 0;
  fileData: File;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  billimgForm: FormGroup;
  billingForm: FormGroup;
  scrumForm: FormGroup;
  QuickAddTaskForm: FormGroup;
  quickAddBacklogForm: FormGroup;
  ProductStatisticsForm: FormGroup;
  ratesForm: FormGroup;
  editDefaultTask: FormGroup;
  createNewParty: FormGroup;
  createPartyRelationship: FormGroup;
  advanceSearch: { ProductBacklogID: string; ProductID: string; RequesterName: string; ProductBacklogsName: string; BacklogItemCategory: string; RequestDate: string; Billed: string; Planned: string; Status: string; };
  TotalBacklogList: any;
  RequesterName: any;
  RequesterNameArray: any[] = [];
  yesNOArray: { label: string; value: string; }[];
  backlogStatus: { label: string; value: string; }[];
  FindTimeSheetList: any;
  TimeSheetList: any;  RoleTypeList: any;
  RoleTypeArray: any[] = [];
  RoleTypeListArray: any[] = [];
  
  TimesheetStatus: { label: string; value: string; }[];
  TimesheetStatusArray: { label: string; value: string; }[];
  advanceSearchTimeSheet: { TimesheetID: string; Comments: string; Party: string; FromDate: string; throughDate: string; Status: string; };
  ProjectSprintProductName: any;
  ProjectSprintProductNameArray: any[] = [];
  advanceSearchA: { ProductName: string; ThroughDate: string; FromDate: string; IncludeMeeting: string; };
  billingList: any;
  billingInvoiceList: any;
  productId: any;
  fromdate: string;
  thruDate: string;
  defaultTaskCreateForm: FormGroup;
  DefaultTaskArray: any[] = [];
  DefaultTask: any;
  AssignToArray: any[] = [];
  AssignTo: any;
  TaskworkEffortId: any;
  TaskList: any;
  ScrumMembersList: any;
  Person: any;
  PersonArray: any[] = [];
  PartyId: any;
  partyId: any;
  partyIdArray: any[] = [];
  roleTypeId: any;
  roleTypeIdArray: any[] = [];
  PartyRolesArray: any[] = [];
  GroupId: any;
  ProductIdArray:any[]=[];
  ProductId:any;
  PartyRoles: any;  SprintBacklogList: any;
  SprintBacklogListArray: any[] = [];
  GroupIdArray: any[] = [];
  DefaultTaskList: any;
  PartyTimesheetId: any;
  PartyTimesheetIdArray: any[] = [];
  defaultTaskUpdateForm: FormGroup;
  showUnplanned = false;
  product = "";
  FindTotalBacklogAdministration: any;
  FindBillingAdministration: any;
  unplannedArray: { label: string; value: string; }[];
  quickAddBacklogUpdateForm: FormGroup;
  scrumMemberUpdateForm: FormGroup;
  RateTypeList: any;
  RateTypeListArray: any[] = [];
  showProductSprint = false;
  advanceSearchD: { LastName: string; FirstName: string; MiddleName: string; GroupId: string; };
  Persons: any;
  productStatForm: FormGroup;
  proNameArray: { label: string; value: string; }[];
  showGraphs: boolean;
  productStatId: string;
  showInvoice: boolean;
  addNewTaskForm: FormGroup;
  chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems, data) {
          return data.datasets[0].data[tooltipItems.index] + ' %';
        }
      }
    },
  };


  pieChartLabels: Label[] = [''];
  pieChartLabelBacklogsType : Label[] = [''];

  pieChartData: number[] = [];
  pieChartDataBacklogsType: number[] = [];

  pieChartType: ChartType = 'pie';

  pieChartLegend = true;

  pieChartPlugins = [];

  pieChartColors = [
    {
      backgroundColor: ['#007bff', '#007bff', '#007bff'],
    },
  ];
  uploadRes:any;
  ProjectID: any;
  ProjectIDArray: any[] = [];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly myContactsService: MyContactsService,
    readonly datePipe: DatePipe,   private _location: Location,
      
    readonly accountsService: AccountsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.addNewTaskForm = this._FormBuilder.group({
      ProjectID: [''],
      TaskType: [''],
      TaskName: [''],
      SprintID: [''],
      SprintBacklog: [''],
      PlanHours: [''],
      Uploaded: [''],
      Description: [''],
      AssignTo: [''],
    });
    this.advanceSearch = {

      ProductBacklogID: "",
      ProductID: "",
      RequesterName: "",
      ProductBacklogsName: "",
      BacklogItemCategory: "",
      RequestDate: "",
      Billed: "",
      Planned: "",
      Status: "",

    };
    this.advanceSearchD = {

      LastName: "",
      FirstName: "",
      MiddleName: "",
      GroupId: "",

    };
    this.advanceSearchTimeSheet = {
      TimesheetID: "",
      Comments: "",
      Party: "",
      FromDate: "",
      throughDate: "",
      Status: "",
    };
    this.advanceSearchA = {

      ProductName: "",
      ThroughDate: "",
      FromDate: "",
      IncludeMeeting: "",


    };
    this.billingForm = this._FormBuilder.group({
      BillFromParty: [''],
      BillToParty: [''],
      Fromdate: [''],
      Throughdate: [''],
      IncludeMeeting: [''],
      invoice: [''],

    });
    this.scrumForm = this._FormBuilder.group({
      Person: [''],
      GroupID: [''],
      roleTypeId:['']

    });
    this.productStatForm = this._FormBuilder.group({
      ProductName: [''],

    });
    this.QuickAddTaskForm = this._FormBuilder.group({
      ProductBacklog: [''],
      TaskName: [''],
      PlannedHours: [''],
      TaskType: [''],
      Description: [''],
      UploadedFile: [''],
      AssignedTo: [''],

    });
    this.quickAddBacklogForm = this._FormBuilder.group({
      ProductBacklogItem: [''],
      Billed: [''],
      Unplanned: [''],
      BacklogItemCategory: [''],
      Story: [''],
      Howtotest: [''],
      PlanTime: [''],
      ProductProjectSprint: [''],
      ProductName: ['']

    });
    this.ProductStatisticsForm = this._FormBuilder.group({
      ProductName: [''],
    });
    this.defaultTaskCreateForm = this._FormBuilder.group({
      TaskName: [''],
      Product: [''],
      Type: [''],
      InitialPlannedHours: [''],
      Description: [''],
      AssignedTo: [''],
      productId:['']
    });
    this.defaultTaskUpdateForm = this._FormBuilder.group({
      TaskName: [''],
      Product: [''],
      Type: [''],
      InitialPlannedHours: [''],
      Description: [''],
      AssignedTo: [''],
    });
    this.editDefaultTask = this._FormBuilder.group({
      taskName: [''],
      type: [''],
      initialPlannedHours: [''],
      description: [''],
      assignTo: [''],
    });
    this.createNewParty = this._FormBuilder.group({
      firstName: [''],
      middleName: [''],
      lastName: [''],
      emailAddress: [''],
      userName: [''],
      password: [''],
      verifyPassword: [''],
      roleTypeID: [''],
      companyName: [''],
    });
    this.createPartyRelationship = this._FormBuilder.group({
      partyID: [''],
      companyPartyID: ['']
    })
    this.scrumMemberUpdateForm = this._FormBuilder.group({
      PartyID: [''],
      RateType: [''],
      DefaultRate: [''],
      RateAmount: [''],
      PercentageUsed: [''],
    })
    this.TimesheetStatusArray = [

      {
        label: 'Approved',
        value: 'TIMESHEET_APPROVED'
      },
      {
        label: 'Completed',
        value: 'TIMESHEET_COMPLETED'
      },
      {
        label: 'In-Process',
        value: 'TIMESHEET_IN_PROCESS'
      },
    ];
    this.unplannedArray = [

      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },
    ];
    this.proNameArray = [

      {
        label: 'Demo Scrum Company -- Demo Product 1[DEMO-PRODUCT-1]',
        value: 'DEMO-PRODUCT-1'
      },
      {
        label: 'Demo Scrum Company -- Demo Product 1[DEMO-PRODUCT-1]',
        value: 'DEMO-PRODUCT-1'
      },
    ];
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.backlogStatus = [

      {
        label: 'Accepted',
        value: 'CRQ_ACCEPTED'
      },
      {
        label: 'Reviewed',
        value: 'CRQ_REVIEWED'
      },
      {
        label: 'Completed',
        value: 'CRQ_COMPLETED'
      },
      {
        label: 'Reopened',
        value: 'CRQ_REOPENED'
      },
      {
        label: 'Cancelled',
        value: 'CRQ_CANCELLED'
      },


    ];
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      this.productId = params.productId;
    })
    this.productStatId = localStorage.getItem('productStatId');

    this.spinner.show;
    this.getFromPartyIdRequesterName();
    this.FindTimeSheet();
    this.getProjectSprintProductName();
    this.getForBillingPage();
    this.getDefaultTaskTypeList();
    this.getDefaultTask();
    this.getFindScrumMembersList();
    this.getFindScrumMembersList();
    this.getScrumPerson();
    this.getProductMemberParty();
    this.getProductMemberRole();
    this.getDefaultTaskList();
    this.getGroupIdList();
    this.getAssignToList();
    this.getPartyTimesheet();
    this.getFindTotalBacklogAdministration();
    this.getFindBillingAdministration();
    this.getRateTypeList();
    this.getBacklogTypeChartPie();
    this.getProductId();
    this.getProjectID();
    this.getSprintBacklogList();
    this.getSprintBacklogList();
    this.getRoleTypeList();
    this.spinner.hide();
  }

  onSubmit() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);
    this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.uploadRes = res.data.URL;
      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })
  }
  submit(){
    this.onSubmit();
    if(this.uploadRes){
      setTimeout(() => {
        this.addTask();
       }, 2000);
    }
  
  
  }


 fileProgress(fileInput: any) {
  this.fileData = <File>fileInput.target.files[0];
  console.log("file-data_recoed",this.fileData);
}
  addTask() {
    this.spinner.show();

    const requestData = {

      "actualHours": "",
      "contentTypeId": "DOCUMENT",
      "currentStatusId": "STS_CREATED",
      "custRequestId": "",
      "description": this.addNewTaskForm.value.Description,
      "mimeTypeId": "application/octet-stream",
      "partyId": "",
      "planHours": this.addNewTaskForm.value.PlanHours,
      "resourceStatusId": "CTNT_PUBLISHED",
      "uploadedFile":  this.uploadRes,
      "workEffortContentTypeId":"CREATED_MEDIA",
      "workEffortId": "",
      "workEffortName": "",
      "workEffortTypeId": this.addNewTaskForm.value.Task10Type,
    }

    this.accountsService.createTaskForBacklog(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
 
  getRoleTypeList() {
    this.spinner.show();
    this.accountsService.getRoleTypeList().subscribe(res => {
      this.RoleTypeList = res.data.data;
      this.spinner.hide();
      for (const value of this.RoleTypeList) {
        this.RoleTypeListArray.push({
          label: value.roleTypeDesc,
          value: value.roleTypeValue
        })
      }
      {
        this.RoleTypeArray.push({
          label: this.RoleTypeList.SCRUM_TASK_TEST,
          value: this.RoleTypeList.SCRUM_TASK_TEST
        })

        this.RoleTypeArray.push({
          label: this.RoleTypeList.SCRUM_TASK_INST,
          value: this.RoleTypeList.SCRUM_TASK_INST
        })

        this.RoleTypeArray.push({
          label: this.RoleTypeList.SCRUM_TASK_IMPL,
          value: this.RoleTypeList.SCRUM_TASK_IMPL
        })

        this.RoleTypeArray.push({
          label: this.RoleTypeList.SCRUM_TASK_ERROR,
          value: this.RoleTypeList.SCRUM_TASK_ERROR
        })
      }
    })
  }

  getProjectID() {
    this.spinner.show();
    this.accountsService.getProjectID().subscribe(res => {
      this.ProjectID = res.data;
      this.spinner.hide();
      for (const value of this.ProjectID) {
        this.ProjectIDArray.push({
          label: value.ProjectID.workEffortName,
          value: value.ProjectID.workEffortName
        })
      }
    })
  }
  getSprintBacklogList() {
    this.spinner.show();
    this.accountsService.getSprintBacklogList().subscribe(res => {
      this.SprintBacklogList = res.data;
      this.spinner.hide();
      for (const value of this.SprintBacklogList) {
        this.SprintBacklogListArray.push({
          label: value.description,
          value: value.custRequestId
        })
      }
    })
  }

  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  viewcrumProject(productId) {
    this._Router.navigate(["/psa/detail-sprint-project"], { queryParams: { productId: productId } })
  }

  TimesheetPage(productId) {
    this._Router.navigate(["/psa/my-timesheet"], { queryParams: { productId: productId } })
  }
  detailedPage(productId, internalName, billFromParty,partyId, Status, longDescription) {
    this._Router.navigate(["/psa/product-overview"], { queryParams: { productId: productId, internalName: internalName, billFromParty: billFromParty, partyId:partyId,Status: Status, longDescription: longDescription } })
  }
  detailNewSprint(productId,custRequestId,description){
    this._Router.navigate(["/psa/detail-overview-page-admin"], 
    { queryParams: { productId: productId ,custRequestId:custRequestId,
      description:description} })

  }
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
  }
  proStats(){
    this.showGraphs=true;
    localStorage.setItem('productStatId', this.productStatForm.value.ProductName);

  }
  getBacklogTypeChart() {
    this.spinner.show();
    this.myContactsService.getBacklogTypeChart(this.productStatId).subscribe(res => {
      this.pieChartLabelBacklogsType = res.data[1];
      this.pieChartDataBacklogsType =   res.data[0];
      this.spinner.hide();
    })
  }
  getBacklogTypeChartPie() {
    this.spinner.show();
    this.myContactsService.getBacklogTypePieChart(this.productStatId).subscribe(res => {
      this.pieChartLabels = res.data[1];
      this.pieChartData =   res.data[0];
      this.spinner.hide();
    })
  }
  getFindScrumMembers() {
    this.spinner.show();
    const req = {
        "firstName": this.advanceSearchD.FirstName ? this.advanceSearchD.FirstName : '',
        "groupId": this.advanceSearchD.GroupId ? this.advanceSearchD.GroupId : '',
        "lastName": this.advanceSearchD.LastName ? this.advanceSearchD.LastName : '',
        "middleName": this.advanceSearchD.MiddleName ? this.advanceSearchD.MiddleName : '',
      }
      this.myContactsService.getFindScrumMembers(req.firstName, req.groupId, req.lastName, req.middleName).subscribe((res: any) => {
        this.ScrumMembersList = res.data;
        this.spinner.hide();
      })
    this.spinner.hide();
  }
  myProfilePage(partyId) {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { taxAuthPartyId:partyId} })
  }

                                                                                                                  
  onChange(event) {
    this.showUnplanned = false;
    this.showProductSprint = false;

    if (event.value === "Y") {
      this.showUnplanned = true;
      this.showProductSprint = false;

    }

    else if (event.value === "N") {
      this.showUnplanned = false;
      this.showProductSprint = true;

    }
    else {
      this.showUnplanned = false;
      this.showProductSprint = false;
    }



  }
  QuickAddBacklog() {
    this.spinner.show();
    const formData = {
      "billed": this.quickAddBacklogForm.value.Billed,
      "custEstimatedMilliSeconds": this.quickAddBacklogForm.value.PlanTime,
      "custSequenceNum": "1",
      "description": this.quickAddBacklogForm.value.ProductBacklogItem,
      "noteInfo": this.quickAddBacklogForm.value.Howtotest,
      "parentCustRequestId": "",
      "productId": this.quickAddBacklogForm.value.ProductName,
      "story": this.quickAddBacklogForm.value.Story,
      "unplanned": this.quickAddBacklogForm.value.Unplanned,

    }
    this.accountsService.QuickAddBacklog(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(["/psa/product-overview"], { queryParams: { productId: this.quickAddBacklogForm.value.ProductName, activeFlag: "16" } })
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }

  /////////------SCRUM MEMBER-----/////////////////

  editScrum(product) {
    setTimeout(() => {
      const formValue = this.scrumMemberUpdateForm;
      formValue.patchValue({
        PartyID: product.Persons.partyId,
        RateType: product.RateTypeID[0].rateTypeId !? "":product.RateTypeID[0].rateTypeId,
        DefaultRate: "",
        RateAmount: product.rateAmount[0].rateAmount !? "" : product.rateAmount[0].rateAmount,
        PercentageUsed: ""

      })
    }, 2000);

  }

  updateQuickAddPartyRate() {
    this.spinner.show();
    const formData = {
      "defaultRate": this.scrumMemberUpdateForm.value.DefaultRate,
      "partyId": this.scrumMemberUpdateForm.value.PartyID,
      "percentageUsed": this.scrumMemberUpdateForm.value.PercentageUsed,
      "periodTypeId": "RATE_HOUR",
      "rateAmount": this.scrumMemberUpdateForm.value.RateAmount,
      "rateCurrencyUomId": "USD",
      "rateTypeId": this.scrumMemberUpdateForm.value.RateType,


    }
    this.accountsService.updateQuickAddPartyRate(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }

  getRateTypeList() {
    this.spinner.show();
    this.accountsService.getRateTypeList().subscribe(res => {
      this.RateTypeList = res.data;
      this.spinner.hide();
      for (const value of this.RateTypeList) {
        this.RateTypeListArray.push({
          label: value.description,
          value: value.rateTypeId
        })
      }
    })
  }

  getProductMemberRole() {
    this.spinner.show();
    this.accountsService.getProductMemberRole().subscribe(res => {
      this.roleTypeId = res.data[0].roleTypeID;
      this.spinner.hide();
      for (const value of this.roleTypeId) {
        this.roleTypeIdArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
    })
  }
  getPartyRoles() {
    this.spinner.show();
    this.myContactsService.getPartyRoles(this.partyId).subscribe(res => {
      this.PartyRoles = res.data[0].entityQuery;
      this.spinner.hide();
      for (const value of this.PartyRoles) {
        this.PartyRolesArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
    })
  }
  getDefaultTaskList() {
    this.spinner.show();
    this.accountsService.getDefaultTaskList().subscribe(res => {
      this.DefaultTaskList = res.data[0].node;
      this.spinner.hide();
    })
  }
  getGroupIdList() {
    this.spinner.show();
    this.accountsService.getGroupIdList().subscribe(res => {
      this.GroupId = res.data;
      this.spinner.hide();
      for (const value of this.GroupId) {
        this.GroupIdArray.push({
          label: value.singleObject.description,
          value: value.singleObject.groupId
        })
      }
    })
  }
  getPartyTimesheet() {
    this.spinner.show();
    this.accountsService.getPartyTimesheet().subscribe(res => {
      this.PartyTimesheetId = res.data[0].Party;
      this.spinner.hide();
      for (const value of this.PartyTimesheetId) {
        this.PartyTimesheetIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }

  addScrumMember() {
    this.spinner.show();
    const formData = {
      "groupId": this.scrumForm.value.GroupID,
      "partyId": this.scrumForm.value.Person,
      "userLoginIdDesc": this.scrumForm.value.Person,
      "roleTypeId": this.scrumForm.value.roleTypeId,
    }
    this.accountsService.addScrumMember(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton10.nativeElement.click();
        this.getFindScrumMembersList();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }

  createPartyRelation() {
    this.spinner.show();
    const formData = {
      "accountPartyId": this.createPartyRelationship.value.companyPartyID,
      "comments": "",
      "contactPartyId": this.createPartyRelationship.value.partyID

    }
    this.accountsService.createPartyRelationship(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.getFindScrumMembersList();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }
  getProductMemberParty() {
    this.spinner.show();
    this.accountsService.getProductMemberParty().subscribe(res => {
      this.PartyId = res.data[0].partyId;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }

  createParty() {
    this.spinner.show();
    const formData = {

      "company": this.createNewParty.value.companyName,
      "confirmPassword": this.createNewParty.value.verifyPassword,
      "emailAddress": this.createNewParty.value.emailAddress,
      "firstName": this.createNewParty.value.firstName,
      "lastName": this.createNewParty.value.lastName,
      "middleName": this.createNewParty.value.middleName,
      "password": this.createNewParty.value.password,
      "roleTypeId": this.createNewParty.value.roleTypeID,
      "userLoginId": this.createNewParty.value.userName,
    }
    this.accountsService.createNewParty(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getFindScrumMembersList();
        this.closebutton2.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();
    });
  }

  removeScrumMember(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "groupId": product.GroupId[0].groupId,
          "partyId": product.Persons.partyId,
          "userLoginId": product.GroupId[0].userLoginId,
          "view_INDEX_1": "20",
          "view_SIZE_1": "0"
        }
        this.accountsService.removeScrumMember(formData).subscribe((res: any) => {
          this.getFindScrumMembersList();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getScrumPerson() {
    this.spinner.show();
    this.accountsService.getScrumPerson().subscribe(res => {
      this.Persons = res.data;
      this.spinner.hide();
      for (const value of this.Persons) {
        if (value.Person) {
          this.PersonArray.push({
            label: value.Person,
            value: value.partyIdTo[0].partyId
          })
        }
      }
    })
  }

  getFindScrumMembersList() {
    this.spinner.show();
    this.myContactsService.getFindScrumMembersList().subscribe(res => {
      this.ScrumMembersList = res.data;
      this.spinner.hide();
    })
  }

  // getScrumMembersList() {
  //   this.spinner.show();
  //   this.accountsService.getScrumMembersList().subscribe(res => {
  //     this.ScrumMembersList = res.data;
  //     this.spinner.hide();
  //   })
  // }

  /////////------DEFAULT TASK------/////////////////

  updateDefaultTask() {
    this.spinner.show();
    const requestData = {

      "currentStatusId": "STS_CREATED",
      "description": this.defaultTaskUpdateForm.value.Description,
      "partyId": this.defaultTaskUpdateForm.value.AssignedTo,
      "plannedHours": this.defaultTaskUpdateForm.value.InitialPlannedHours,
      "workEffortId": "",
      "workEffortName": this.defaultTaskUpdateForm.value.TaskName,
      "workEffortPurposeTypeId": "SCRUM_DEFAULT_TASK",
      "workEffortTypeId": this.defaultTaskUpdateForm.value.Type,

    }
    this.accountsService.updateDefaultTasks(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  onDelete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.createEventServices.deleteEvent(id).subscribe(res => {
      
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})}
  deleteDefaultTask(pro) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "workEffortId": pro.defaultTaskParty.workEffortId
        }
        this.accountsService.deleteDefaultTasks(formData).subscribe((res: any) => {
          this.getDefaultTask();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  edit(pro) {

    setTimeout(() => {
      const formValue = this.defaultTaskUpdateForm;
      formValue.patchValue({
        TaskName: pro.defaultTaskParty.workEffortName,
        Type: pro.defaultTaskParty.workEffortTypeId,
        Description: pro.defaultTaskParty.description,

      })
    }, 2000);

  }

  getDefaultTask() {
    this.spinner.show();
    this.accountsService.getDefaultTask().subscribe(res => {
      this.TaskList = res.data;
      this.spinner.hide();
    })
  }

  createDefaultTasks() {
    this.spinner.show();
    const formData = {
      "currentStatusId": "STS_CREATED",
      "description": this.defaultTaskCreateForm.value.Description,
      "partyId": this.defaultTaskCreateForm.value.AssignedTo,
      "plannedHours": this.defaultTaskCreateForm.value.InitialPlannedHours,
      "productId": this.defaultTaskCreateForm.value.productId,
      "workEffortId": "",
      "workEffortName": this.defaultTaskCreateForm.value.TaskName,
      "workEffortPurposeTypeId": "SCRUM_DEFAULT_TASK",
      "workEffortTypeId": this.defaultTaskCreateForm.value.Type,
    }
    this.accountsService.createDefaultTasks(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getDefaultTask();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();
    });
  }
  getAssignToList() {
    this.spinner.show();
    this.accountsService.getAssignToList().subscribe(res => {
      this.AssignTo = res.data.data;
      this.spinner.hide();
      for (const value of this.AssignTo) {
        this.AssignToArray.push({
          label: value.scrumTeamValue,
          value: value.scrumTeamName
        })
      }
    })
  }
  getDefaultTaskTypeList() {
    this.spinner.show();
    this.accountsService.getDefaultTaskTypeList().subscribe(res => {
      this.DefaultTask = res.data.list;
      this.spinner.hide();
      for (const value of this.DefaultTask) {
        this.DefaultTaskArray.push({
          label: value.description,
          value: value.workEffortTypeId
        })
      }
    })
  }
  /////////------BILLING-----/////////////////


  getFindBilling() {

    this.spinner.show();

    if(this.advanceSearchA.IncludeMeeting||this.advanceSearchA.ProductName){
      const req = {
       // "fromDate":"",
        "includeMeeting": this.advanceSearchA.IncludeMeeting ? this.advanceSearchA.IncludeMeeting : '',
       // "thruDate": "",
        "productId": this.advanceSearchA.ProductName ? this.advanceSearchA.ProductName : '',
  
      }
      this.accountsService.getBillingAdministration(req.includeMeeting, req.productId).subscribe((res: any) => {
        this.billingList = res.data;
        this.spinner.hide();
      })
    }
    else
    {
      this.getFindBillingAdministration();
    }
    this.spinner.hide();
    this.showInvoice = true;
    this.getForBillingPage();
  }

  addProductTimeToNewInvoice() {
    this.spinner.show();
    let fromActualdate = this.billingForm.get('Fromdate').value;
    this.fromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    let throughDate = this.billingForm.get('Throughdate').value;
    this.thruDate = this.datePipe.transform(throughDate, "yyyy-MM-dd hh:mm:ss");

    const formdata = {

      "fromDate":this.datePipe.transform(this.billingInvoiceList.productRoles.fromDate, "yyyy-MM-dd hh:mm:ss") ,
      "includeMeeting": this.billingForm.value.IncludeMeeting,
      "invoiceId": "",
      "partyId": this.billingInvoiceList.billToParty,
      "partyIdFrom": this.billingInvoiceList.billFromParty,
      "productId": this.billingInvoiceList.productRoles.productId,
      "reCreate": "",
      "thruDate":this.datePipe.transform(this.billingInvoiceList.productRoles.thruDate, "yyyy-MM-dd hh:mm:ss") ,

    }

    this.accountsService.addProductTimeToNewInvoice(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getForBillingPage();

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  getForBillingPage() {
    this.spinner.show();
    this.accountsService.getForBillingPage(this.advanceSearchA.ProductName).subscribe(res => {
      this.billingInvoiceList = res.data[0];
      this.spinner.hide();
    })
  }

  getFindBillingAdministration() {
    this.spinner.show();
    this.accountsService.getFindBillingAdministration().subscribe(res => {
      this.billingList = res.data;
      this.spinner.hide();
    })
  }
  resetScrum() {

    this.spinner.show();
    this.advanceSearchD.LastName = "";
    this.advanceSearchD.FirstName = "";
    this.advanceSearchD.MiddleName = "";
    this.advanceSearchD.GroupId = "";

    this.myContactsService.getFindScrumMembersList().subscribe((res: any) => {
      this.ScrumMembersList = res.data;
      this.spinner.hide();
    })
  }
  resetBilling() {

    this.spinner.show();
    this.advanceSearchA.ProductName = "";
    this.advanceSearchA.ThroughDate = "";
    this.advanceSearchA.FromDate = "";
    this.advanceSearchA.IncludeMeeting = "";

    this.accountsService.getFindBillingAdministration().subscribe((res: any) => {
      this.billingList = res.data;
      this.spinner.hide();
    })
  }

  getProjectSprintProductName() {
    this.spinner.show();
    this.accountsService.getProjectSprintProductName().subscribe(res => {
      this.ProjectSprintProductName = res.data;
      this.spinner.hide();
      for (const value of this.ProjectSprintProductName) {
        this.ProjectSprintProductNameArray.push({
          label: value.productName[0].internalName + "[" + value.productName[0].productId + "]",
          value: value.productName[0].productId
        })
      }
    })
  }

  //////////////// ---- TIMESHEET ---- /////////////////

  getFindTimeSheet() {

    this.spinner.show();
    const req = {
      "comments": this.advanceSearchTimeSheet.Comments ? this.advanceSearchTimeSheet.Comments : '',
      "fromDate": this.advanceSearchTimeSheet.FromDate ? this.advanceSearchTimeSheet.FromDate : '',
      "partyId": this.advanceSearchTimeSheet.Party ? this.advanceSearchTimeSheet.Party : '',
      "statusId": this.advanceSearchTimeSheet.Status ? this.advanceSearchTimeSheet.Status : '',
      "timesheetId": this.advanceSearchTimeSheet.TimesheetID ? this.advanceSearchTimeSheet.TimesheetID : '',

    }
    this.accountsService.getFindTimeSheet(req.comments, req.fromDate, req.partyId,
      req.statusId, req.timesheetId).subscribe((res: any) => {
        this.TimeSheetList = res.data;
        this.spinner.hide();

      })
    this.spinner.hide();
  }

  FindTimeSheet() {

    this.spinner.show();
    this.accountsService.FindTimeSheet().subscribe(res => {
      this.TimeSheetList = res.data;
      this.spinner.hide();
    })
  }

  resetTimeSheet() {
    this.spinner.show();
    this.advanceSearchTimeSheet.TimesheetID = "";
    this.advanceSearchTimeSheet.Comments = "";
    this.advanceSearchTimeSheet.Party = "";
    this.advanceSearchTimeSheet.FromDate = "";
    this.advanceSearchTimeSheet.throughDate = "";
    this.advanceSearchTimeSheet.Status = "";

    this.accountsService.FindTimeSheet().subscribe((res: any) => {
      this.TimeSheetList = res.data;
      this.spinner.hide();


    })
    this.spinner.hide();
  }



  ////////////////---ALL BACKLOG----/////////////////

  getFindTotalBacklogAdministration() {
    this.spinner.show();
    this.accountsService.getFindTotalBacklogAdministration().subscribe(res => {
      this.TotalBacklogList = res.data;
      this.spinner.hide();
    })
  }

  getFromPartyIdRequesterName() {
    this.spinner.show();
    this.accountsService.getFromPartyIdRequesterName().subscribe(res => {
      this.RequesterName = res.data[0].requesterName;
      this.spinner.hide();
      for (const value of this.RequesterName) {
        this.RequesterNameArray.push({
          label: value.fromPartyId,
          value: value.fromPartyId
        })
      }
    })
  }


  reset() {
    this.spinner.show();
    this.advanceSearch.ProductBacklogID = "";
    this.advanceSearch.ProductID = "";
    this.advanceSearch.RequesterName = "";
    this.advanceSearch.ProductBacklogsName = "";
    this.advanceSearch.BacklogItemCategory = "";
    this.advanceSearch.RequestDate = "";
    this.advanceSearch.Billed = "";
    this.advanceSearch.Planned = "";
    this.advanceSearch.Status = "";


    this.accountsService.getFindTotalBacklogAdministration().subscribe((res: any) => {
      this.TotalBacklogList = res.data;
      this.spinner.hide();


    })
  }

  getFindTotalBacklog() {
    this.spinner.show();

    if( this.advanceSearch.Billed|| this.advanceSearch.ProductBacklogsName|| this.advanceSearch.RequesterName
      || this.advanceSearch.BacklogItemCategory||this.advanceSearch.Status||this.advanceSearch.ProductID||
      this.advanceSearch.Planned|| this.advanceSearch.ProductBacklogID){
        const req = {
          "billed": this.advanceSearch.Billed ? this.advanceSearch.Billed : '',
          "custRequestDate": this.advanceSearch.RequestDate ? this.advanceSearch.RequestDate : '',
          "custRequestId": this.advanceSearch.ProductBacklogID ? this.advanceSearch.ProductBacklogID : '',
          "description": this.advanceSearch.ProductBacklogsName ? this.advanceSearch.ProductBacklogsName : '',
          "fromPartyId": this.advanceSearch.RequesterName ? this.advanceSearch.RequesterName : '',
          "parentCustRequestId": this.advanceSearch.BacklogItemCategory ? this.advanceSearch.BacklogItemCategory : '',
          "statusId": this.advanceSearch.Status ? this.advanceSearch.Status : '',
          "productId": this.advanceSearch.ProductID ? this.advanceSearch.ProductID : '',
    
        }
        this.accountsService.getFindAllBacklogAdministration(req.billed, req.custRequestDate, req.custRequestId,
          req.description, req.fromPartyId, req.parentCustRequestId, req.productId, req.statusId).subscribe((res: any) => {
            this.TotalBacklogList = res.data;
            this.spinner.hide();
    
          })

    }
  else{
    this.getFindTotalBacklogAdministration();
  }
   
    this.spinner.hide();
  }

  detailOverviewPage(productId) {
    this._Router.navigate(["/psa/product-overview"], { queryParams: { productId: productId } })
  }
  changeactiveCategoryA(tab: number) {
    this.activeCategoryA = tab;
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  Backlog() {
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint() {
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork() {
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks() {
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest() {
    this._Router.navigate(["/psa/open-test"])
  }
  Revision() {
    this._Router.navigate(["/psa/find-task-revision"])
  }
  Administration() {
    this._Router.navigate(["/psa/admin-scrum"])
  }
  myProfile(partyId) {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { taxAuthPartyId: partyId = "admin" } })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
