import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { PayablePaymentService } from 'src/app/accounting/accounting-ap/payables-payment/payables-payment.service';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css']
})
export class CustomerDetailComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  customerCategoryPopup = 1;
  customerCommercialDetailsForm: FormGroup;
  customerForm: FormGroup;
  addCustoProblem: FormGroup;
  problemStatusArray: { label: string; value: string; }[];
  EquipmentId: any;
  addCustoProblemval: any;
  FsmEquipmentServiceById: any;
  FsmEquipmentServiceByIdArray: any[] = [];
  FsmEquipmentIdArray: any[] = [];
  FsmEquipmentId: any;
  FMS_CUSTYPEArray: { label: string; value: string; }[];
  customerId: string;
  problemId: any;
  FsmProblemByCustomerIdData: any;
  FsmProblemBio: any;
  showTable: boolean;
  customerIdData: any;
  priorityArray: { label: string; value: string; }[];
  paymentTypes: any[];
  paymentProcessSettingArray: any[]=[];
  paymentProcessSetting: any;
  expertiseArea: any;
  expertiseAreaArray: any[]=[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly _PayablePaymentService: PayablePaymentService,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) {
    this.addCustoProblem = this._FormBuilder.group({
      category: "",
      description: "",
      service: "",
      priority: "",
      paymentMode: "",
      problemStatus: "",
      problemCategory:""
    });
    this.priorityArray = [
      {
        label: 'High',
        value: 'High'
      },
      {
        label: 'Medium',
        value: 'Medium'
      },
      {
        label: 'Low',
        value: 'Low'
      }
    ]
    this.FMS_CUSTYPEArray = [
      {
        label: 'FMS_CUSTOMER_COM',
        value: 'FMS_CUSTOMER_COM'
      },
      {
        label: 'FMS_CUSTOMER_RES',
        value: 'FMS_CUSTOMER_RES'
      },
    ];
  }

  ngOnInit(): void {
    this.customerId = localStorage.getItem('partyId');
   
    console.log(this.customerId)
    console.log(this.customerIdData,"customerIdData")
   
    
    this.getFsmEquipment();
    this.getFsmProblemByCustomerId();
    this.getPaymentProcessSetting();
    this.getExpertiseArea();
  }
  getExpertiseArea() {
    this.spinner.show();
    this.myContactsService.getExpertiseArea().subscribe(res => {
      this.expertiseArea = res.data[0].getExpertiseArea;
      this.spinner.hide();
      for (const value of this.expertiseArea) {
        this.expertiseAreaArray.push({
          label: value.expertiseAreaName ,
          value: value.expertiseAreaId
        })
      }
    });

  }
  summaryPage(id){
    this._Router.navigate(["/fsm/fsm-customer/customer-summary-page"],
    { queryParams: { problemId : id}})
  }
 
  createFsmProblem(): void {
    this.spinner.show();
    const requestData = {
      "category": this.addCustoProblem.value.problemCategory,
      "customerId": this.customerId,
      "description": this.addCustoProblem.value.description,
      "problemId": '',
      "problemStatus": "UnassignedProblem",
      "service": this.addCustoProblem.value.service,
      "paymentMode": this.addCustoProblem.value.paymentMode,
      "priority": this.addCustoProblem.value.priority,

    }
    this.accountsService.createFsmProblem(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getFsmProblemByCustomerId();
        if (this.customerIdData) {
          this.showTable = true;
        }
        this.problemId = res.data.problemId;
        this.addCustoProblem.reset();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFsmProblemByCustomerId() {
    this.spinner.show();
    this.accountsService.getFsmProblemByCustomerId(this.customerId).subscribe(res => {
      this.FsmProblemByCustomerIdData = res.data[0].getFsmProblem.reverse();
      this.customerIdData = res.data[0].getFsmProblem[0];
     console.log(this.customerIdData.length,"length")
     console.log(this.FsmProblemByCustomerIdData.length,"length2")
      
      if (this.FsmProblemByCustomerIdData.length > 0) {
        this.showTable = true;
      } else if (this.FsmProblemByCustomerIdData.length == 0) {
        this.showTable = false;
      } else {
        this.showTable = true;
      }
      this.spinner.hide();

    })
  }
  getfilterService(e: any) {
    console.log("service filter code", e);
    this.EquipmentId = e;
    console.log("EquipmentId", this.addCustoProblemval);
    this.getFsmEquipmentServiceById();
  }
  getFsmEquipmentServiceById() {
    this.FsmEquipmentServiceByIdArray = [];
    this.spinner.show();
    this.myContactsService.getFsmEquipmentServiceById(this.EquipmentId).subscribe(res => {
      this.FsmEquipmentServiceById = res.data[0].getFsmEquipmentServiceById;
      this.spinner.hide();
      for (const value of this.FsmEquipmentServiceById) {
        this.FsmEquipmentServiceByIdArray.push({
          label: value.serviceName,
          value: value.serviceName
        })
      }
    });
  }
  getFsmEquipment() {
    this.spinner.show();
    this.myContactsService.getFsmEquipment().subscribe(res => {
      this.FsmEquipmentId = res.data[0].getFsmEquipment;
      this.spinner.hide();
      for (const value of this.FsmEquipmentId) {
        this.FsmEquipmentIdArray.push({
          label: value.typeName,
          value: value.equipmentId
        })
      }
    });

  }
  getPaymentProcessSetting() {
    this.spinner.show();
    this.myContactsService.getPaymentProcessSetting().subscribe(res => {
      this.paymentProcessSetting = res.data[0].getPaymentProcessSetting;
      this.spinner.hide();
      for (const value of this.paymentProcessSetting) {
        this.paymentProcessSettingArray.push({
          label: value.paymentType,
          value: value.paymentProcessId
        })
      }
    });

  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

  customerActiveCategoryFunction(tab: number) {
    this.customerCategoryPopup = tab;

  }

}
