import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-content-cms',
  templateUrl: './content-cms.component.html',
  styleUrls: ['./content-cms.component.css']
})
export class ContentCmsComponent implements OnInit {
  activeCategory = 1;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  CMSContentFindForm: FormGroup;
  cmsContentForm: FormGroup;
  customerCategoryPopup = 1;
  cmsContentFindData: any;
  ContentAssocTypeData: any;
  ContentAssocTypeDataArray: any[] = [];
  DataPredicateArray: any[] = [];
  DataPredicate: any;
  resourceTypeDROP: any;
  resourceTypeDROPArray: any[] = [];
  contentTypeIdData: any;
  contentTypeIdDataArray: any[] = [];
  mimeTypeIdData: any;
  mimeTypeIdDataArray: any[] = [];
  CharacterSetIdArray: any[] = [];
  CharacterSetId: any;
  fileData: File;
  uploadURL: any;
  CatalogContentId: any;
  CatalogContentIdArray: any[] = [];
  dataTemplateType: any;
  dataTemplateTypeArray: any[] = [];
  DataResourceId: any;
  DataResourceIdArray: any[] = [];
  dataResourceTemplate: any;
  dataResourceTemplateArray: any[] = [];

  constructor(
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,

    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  ) {
    this.CMSContentFindForm = this._FormBuilder.group({
      contentIDTo: "",
      mapKey: "",
      contentAssocTypeID: "",
      fromDate: "",
      contentID: "",
      dataResourceID: "",
      contentName: "",
    })

    this.cmsContentForm = this._FormBuilder.group({
      contentIDTo: "",
      mapKey: "",
      contentAssocTypeID: "",
      contentAssocPredicateID: "",
      fromDate: "",
      throughDate: "",
      contentID: "",
      templateDataResourceID: "",
      contentTypeID: "",
      ownerContentID: "",
      contentName: "",
      description: "",
      mimeTypeID: "",
      characterSetID: "",
      localeString: "",
      statusID: "",
      privilegeEnumID: "",
      dataResourceID: "",
      drDataResourceTypeID: "",
      filePath: "",
      drDataTemplateTypeID: "",
      drMimeTypeID: "",
      characterSetID1: "",
      localeString1: "",
      dataSource: "",
      dataCategory: "",
      textData: "",
      imageData: "",
      createdByUser: "",
      createdDate: "",
      lastModifiedByUser: "",
      lastModifiedDate: "",
    })

  }

  ngOnInit(): void {
    this.getCMSContentFind();
    this.ContentAssocType();
    this.metaDataPredicate();
    this.getCharacterSet();
    this.getMimeTypeIdData();
    this.DataResourceTypeDROP();
    this.contentTypeIds();
    this.getDataTemplateType();
    this.getCatalogContentId();
    this.getDataResourceId();
    this.AllContent();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  detailPage(caContentIdTo, contentId, caContentAssocTypeId) {
    this._Router.navigate(["content/cms/cms-summary"],
      { queryParams: { caContentIdTo: caContentIdTo, contentId: contentId, caContentAssocTypeId: caContentAssocTypeId } })
  }
  AllContent() {
    this.spinner.show();
    this.myContactsService.AllContent().subscribe(res => {
      this.dataResourceTemplate = res.data.FindForumMessages;
      this.spinner.hide();
      for (const value of this.dataResourceTemplate) {
        this.dataResourceTemplateArray.push({
          label: value.templateDataResourceId,
          value: value.templateDataResourceId
        })
      }
    })
  }
  getDataResourceId() {
    this.spinner.show();
    this.accountsService.getDataResourceId().subscribe(res => {
      this.DataResourceId = res.data.getDataResourceId;
      this.spinner.hide();
      for (const value of this.DataResourceId) {
        this.DataResourceIdArray.push({
          label: value.dataResourceId,
          value: value.dataResourceId
        })
      }
    })
  }
  getDataTemplateType() {
    this.spinner.show();
    this.myContactsService.getDataTemplateType().subscribe(res => {
      this.dataTemplateType = res.data.getDataTemplateType;
      this.spinner.hide();
      for (const value of this.dataTemplateType) {
        this.dataTemplateTypeArray.push({
          label: value.description,
          value: value.dataTemplateTypeId
        })
      }
    })
  }

  getCatalogContentId() {
    this.spinner.show();
    this.accountsService.getCatalogContentId().subscribe(res => {
      this.CatalogContentId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CatalogContentId) {
        this.CatalogContentIdArray.push({
          label: value.contentName,
          value: value.contentId
        })
      }
    })
  }
  // onUpload() {
  //   this.spinner.show();
  //   const formData = new FormData();
  //   formData.append("file", this.fileData);

  //   this.accountsService.attachFile(formData).subscribe((res: any) => {
  //     if (res.success) {
  //       this.spinner.hide();
  //       this.uploadURL = res.data.URL;
  //       this.persistContentStuff();
  //       this.closebutton2.nativeElement.click();
  //       this.toastr.success("Success");
  //       this.cmsContentForm.reset();
  //       this.getCMSContentFind();

  //     }
  //   }, (err) => {
  //     this.toastr.error(err.message);
  //     this.spinner.hide();
  //   })

  // }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];

  }
  onUpload(): void {
    this.spinner.show();
    const requestData = {

      "_rowCount": "1",
      "caContentAssocPredicateId": this.cmsContentForm.value.contentAssocPredicateID,
      "caContentAssocTypeId": this.cmsContentForm.value.contentAssocTypeID,
      "caContentIdTo": this.cmsContentForm.value.contentIDTo,
      "caFromDate": this.datePipe.transform(this.cmsContentForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "caMapKey": this.cmsContentForm.value.mapKey,
      "caThruDate": this.datePipe.transform(this.cmsContentForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),

      "characterSetId": this.cmsContentForm.value.characterSetID,
      "contentAssocTitle": "",
      "contentId": this.cmsContentForm.value.contentID,
      "contentName": this.cmsContentForm.value.contentName,
      "contentPurposeString": "ARTICLE",
      "contentTitle": "",
      "contentTypeId": this.cmsContentForm.value.contentTypeID,
      "createdByUserLogin": "admin",
      "createdDate": this.datePipe.transform(new Date(), "yyyy-MM-dd hh:mm:ss"),
      "dataResourceId": this.cmsContentForm.value.dataResourceID,
      "dataResourceTitle": "",
      "deactivateExisting": true,
      "description": this.cmsContentForm.value.description,

      "drCharacterSetId": this.cmsContentForm.value.characterSetID1,
      "drDataCategoryId": this.cmsContentForm.value.dataCategory,
      "drDataResourceTypeId": "",
      "drDataSourceId": this.cmsContentForm.value.dataSource,
      "drDataTemplateTypeId": this.cmsContentForm.value.drDataTemplateTypeID,
      "drLocaleString": this.cmsContentForm.value.localeString1,
      "drMimeTypeId": this.cmsContentForm.value.drMimeTypeID,
      "drObjectInfo": this.cmsContentForm.value.filePath,

      "imageData": "",
      "imageDataTitle": "",
      "lastModifiedByUserLogin": "admin",
      "lastModifiedDate": this.datePipe.transform(this.cmsContentForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),
      "localeString": this.cmsContentForm.value.localeString,
      "masterContentId": "",
      "master_caContentAssocTypeId": this.cmsContentForm.value.contentAssocTypeID,
      "master_caContentId": "",
      "master_caContentIdTo": this.cmsContentForm.value.contentIDTo,
      "master_caFromDate": this.datePipe.transform(this.cmsContentForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "master_contentId": this.cmsContentForm.value.contentIDTo,
      "master_drDataResource": "",
      "mimeTypeId": this.cmsContentForm.value.mimeTypeID,
      "nodeTrailCsv": "",
      "ownerContentId": this.cmsContentForm.value.ownerContentID,
      "statusId": this.cmsContentForm.value.statusID,
      "targetOperationString": "CONTENT_UPDATE|CONTENT_CREATE|CONTENT_CREATE_SUB",
      "templateDataResourceId": this.cmsContentForm.value.templateDataResourceID,
      "textData": this.cmsContentForm.value.textData,
      "textDataTitle": "",
    }
    this.myContactsService.persistContentStuff(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.closebutton2.nativeElement.click();
        this.cmsContentForm.reset();
        this.getCMSContentFind();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCharacterSet() {
    this.spinner.show();
    this.accountsService.getCharacterSet().subscribe(res => {
      this.CharacterSetId = res.data.getCharacterSet;
      this.spinner.hide();
      for (const value of this.CharacterSetId) {
        this.CharacterSetIdArray.push({
          label: value.description,
          value: value.characterSetId
        })
      }
    })
  }
  getMimeTypeIdData() {
    this.spinner.show();
    this.myContactsService.getMimeTypeIdData().subscribe(res => {
      this.mimeTypeIdData = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.mimeTypeIdData) {
        this.mimeTypeIdDataArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }

  ContentAssocType() {
    this.spinner.show();
    this.myContactsService.ContentAssocType().subscribe(res => {
      this.ContentAssocTypeData = res.data.ContentAssocType;
      this.spinner.hide();
      for (const value of this.ContentAssocTypeData) {
        this.ContentAssocTypeDataArray.push({
          label: value.description,
          value: value.contentAssocTypeId
        })
      }
    })
  }
  DataResourceTypeDROP() {
    this.spinner.show();
    this.myContactsService.DataResourceTypeDROP().subscribe(res => {
      this.resourceTypeDROP = res.data.DataResourceType;
      this.spinner.hide();
      for (const value of this.resourceTypeDROP) {
        this.resourceTypeDROPArray.push({
          label: value.description,
          value: value.dataResourceTypeId
        })
      }
    })
  }
  contentTypeIds() {
    this.spinner.show();
    this.myContactsService.contentTypeIds().subscribe(res => {
      this.contentTypeIdData = res.data;
      this.spinner.hide();
      for (const value of this.contentTypeIdData) {
        this.contentTypeIdDataArray.push({
          label: value.description,
          value: value.contentTypeId
        })
      }
    })
  }
  metaDataPredicate() {
    this.spinner.show();
    this.myContactsService.metaDataPredicate().subscribe(res => {
      this.DataPredicate = res.data[0].metaDataPredicate;
      this.spinner.hide();
      for (const value of this.DataPredicate) {
        this.DataPredicateArray.push({
          label: value.description,
          value: value.metaDataPredicateId
        })
      }
    })
  }

  reset() {
    this.CMSContentFindForm.reset();
    this.getCMSContentFind();

  }
  getCMSContentFind() {
    this.spinner.show();
    const formData = {
      "caContentAssocTypeId": '',
      "caContentId": '',
      "caContentIdTo": "",
      "caFromDate": "",
      "caMapKey": "",
      "contentName": "",
      "dataResourceId": ""
    }

    this.myContactsService.CMSContentFind(formData).subscribe(res => {
      this.cmsContentFindData = res.data[0].CMSContentFind;
      this.spinner.hide();

    })
  }
  CMSContentFind() {
    this.spinner.show();
    const formData = {

      "caContentAssocTypeId": this.CMSContentFindForm.value.contentAssocTypeID ? this.CMSContentFindForm.value.contentAssocTypeID : '',
      "caContentId": this.CMSContentFindForm.value.contentID ? this.CMSContentFindForm.value.contentID : '',
      "caContentIdTo": this.CMSContentFindForm.value.contentIDTo ? this.CMSContentFindForm.value.contentIDTo : '',
      "caFromDate": this.CMSContentFindForm.value.fromDate ? this.CMSContentFindForm.value.fromDate : '',
      "caMapKey": this.CMSContentFindForm.value.mapKey ? this.CMSContentFindForm.value.mapKey : '',
      "contentName": this.CMSContentFindForm.value.contentName ? this.CMSContentFindForm.value.contentName : '',
      "dataResourceId": this.CMSContentFindForm.value.dataResourceID ? this.CMSContentFindForm.value.dataResourceID : '',

    }

    this.myContactsService.CMSContentFind(formData).subscribe(res => {
      this.cmsContentFindData = res.data[0].CMSContentFind;
      this.spinner.hide();

    })
  }

  openContent() {
    this.router.navigate(['/content/cms/']);
  }
  openSubSites() {
    this.router.navigate(['/content/cms/subsites']);

  }
  openIndex() {
    this.router.navigate(['/content/cms/index']);

  }
  openFind() {
    this.router.navigate(['/content/cms/find']);

  }
  openSearchProducts() {
    this.router.navigate(['/content/cms/search-products']);

  }



  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });


  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;

  }

  customerActiveCategoryFunction(tab: number) {
    this.customerCategoryPopup = tab;

  }


}
