<div class="container-fluid main-container-wrapper">
    <br>
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Transactions</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>  
    <div class=" bg-white color-grey">
       
        <div class="w3-card-4 classCard classCardType" >
        <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategory==1?'active':''" (click)="Authorize()"><a>
                    Authorize</a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="Capture()"><a>
                    Capture</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="GatewayResponses()"><a>
                        Gateway Responses</a></li>
                    <li [ngClass]="activeCategory==4?'active':''" (click)="ManualElectronicTransaction()"><a>
                        Manual Electronic Transaction</a></li>
               
            </ul>
        </div>

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
              
                   
                                <div class="panel-group">
                                    <div *ngIf="activeCategory==4">
                                       
                                       
                                        <div class="panel-collapse">
                                            <br>
                                             
        <div class="create-new-leade mt-2">
            <div class="container-fluid">
                <div class="row">
                    <form  class="w-100" [formGroup]="createAuth">
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12"  *ngIf="this.showFirst">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">Payment Method Type <span
                                            style="color:red">*</span> </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Payment Method Type" filter="true"
                                        formControlName="paymentMethodType"
                                        [options]="creditCard" optionlabel="label">
                                                        
                                        </p-dropdown>
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                        
                                            Product Store <span
                                            style="color:red">*</span></label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Product Store" filter="true"
                                        formControlName="productStore"
                                        [options]="productStoresListArray" optionlabel="label" >
                                                        
                                        </p-dropdown>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12"  *ngIf="this.showFirst">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                        
                                            Transaction Type <span
                                            style="color:red">*</span></label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter  Transaction Type" filter="true"
                                        formControlName="transactionType"
                                        [options]="enumDataArray" optionlabel="label"  
                                        (onChange)="onChange($event)">
                                                        
                                        </p-dropdown>
                                    </div>
                                   
            
                                </div>
                            </div>

                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                       
                                            Forename</label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Forename"
                                    formControlName="Forename">
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                       
                                            Surname</label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Surname"
                                        formControlName="Surname">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                        
                                            E-Mail Address <span
                                            style="color:red">*</span></label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="EMailAddress"
                                    formControlName="EMailAddress">
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                      
                                            Company Name Card</label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Company Name Card"
                                        formControlName="CompanyNameCard">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                     
                                            Prefix Card </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter  Prefix Card " filter="true"
                                    formControlName="prefixCard"
                                    [options]="prefixCard" optionlabel="label"   >
                                                    
                                    </p-dropdown>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                        
                                            First Name Card  <span
                                            style="color:red">*</span></label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="First Name Card "
                                    formControlName="FirstNameCard">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                       
                                            Middle Name Card</label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Middle Name Card"
                                        formControlName="MiddleNameCard">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                        
                                            Last Name Card   <span
                                            style="color:red">*</span></label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Last Name Card  "
                                    formControlName="LastNameCard">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                        
                                            Suffix on Card</label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Suffix on Card" filter="true"
                                    formControlName="SuffixonCard"
                                    [options]="sufixCard" optionlabel="label"   >
                                                    
                                    </p-dropdown>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                        
                                            Card Type </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Card Type " filter="true"
                                        formControlName="CardType"
                                        [options]="CardTypeAccountsArray" optionlabel="label"  >
                                                        
                                        </p-dropdown>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                        
                                            Card Number    <span
                                            style="color:red">*</span></label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Card Number"
                                    formControlName="CardNumber">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                        
                                            Expiration Date  <span
                                            style="color:red">*</span></label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Month " filter="true"
                                    formControlName="CardDay"
                                    [options]="months" optionlabel="label"   >
                                                    
                                    </p-dropdown>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                        
                                            Expiration Year    <span
                                            style="color:red">*</span></label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Year " filter="true"
                                    formControlName="Month"
                                    [options]="years" optionlabel="label"   >
                                                    
                                    </p-dropdown>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                       
                                            Description  </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Description"
                                    formControlName="Description">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                        
                                            Forename  </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Forename"
                                        formControlName="ForenameA">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                        
                                            Surname   </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Surname"
                                        formControlName="SurnameA">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                        
                                            Bill-To Address1  <span
                                            style="color:red">*</span> </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Bill-To Address1"
                                    formControlName="BilToAddress1">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                        
                                            Bill-To Address2    </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Bill-To Address2  "
                                    formControlName="BillToAddress2">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                        
                                            City  <span
                                            style="color:red">*</span> </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="City"
                                        formControlName="City">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                        
                                            State <span
                                            style="color:red">*</span></label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter State" filter="true"
                                        formControlName="state"
                                        [options]="stateArray" optionlabel="label"   >
                                                        
                                        </p-dropdown>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                      
                                            Zip/Postal Code <span
                                            style="color:red">*</span> </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Zip/Postal Code"
                                        formControlName="zip">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                      
                                            Country   <span
                                            style="color:red">*</span></label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Country" filter="true"
                                        formControlName="Country" (onChange)="onCountryChange($event)"
                                        [options]="array" optionlabel="label"   >
                                                        
                                        </p-dropdown>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                        
                                            Payment Method Type	  </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <label>{{ this.paymentethodType}}</label>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                        
                                            Product Store	 </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <label>{{this.productStoreType}}</label>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showSecond">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                        
                                            Transaction Type		  </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <label>{{ this.transactionType}}</label>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                      
                                            Amount   <span
                                            style="color:red">*</span> </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Amount"
                                        formControlName="Amount">
                                    </div>
            
                                   
            
                                </div>
                            </div>

                            <div class="col-lg-12" *ngIf="this.showThird">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                            
                                            Payment Method Type	  </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <label>{{ this.paymentethodType}}</label>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                            
                                            Product Store	 </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <label>{{this.productStoreType}}</label>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showThird">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                           
                                            Transaction Type		  </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <label>{{ this.transactionType}}</label>
                                  
                                    </div>
                                   
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showThird">
                                <div class="row">
            
                                    <div class="col-lg-4 form-group" >
                                        <label for="exampleInputEmail1">
                                            
    
                                            
                                            This transaction type is not yet supported	  </label>
                                    </div>
                                 
            
                                </div>
                            </div>

                            <div class="col-lg-12" *ngIf="this.showFour">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                            
                                            Reference Number  <span
                                            style="color:red">*</span> </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Reference Number"
                                        formControlName="ReferenceNumber">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                            
                                            Order Payment Preference ID   <span
                                            style="color:red">*</span></label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Order Payment Preference ID"
                                        formControlName="OrderPaymentPreferenceID">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFour">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                           
                                            Payment Method Type	  </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <label>{{ this.paymentethodType}}</label>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                            
                                            Product Store	 </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <label>{{this.productStoreType}}</label>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFour">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                          
                                            Transaction Type		  </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <label>{{ this.transactionType}}</label>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                            
                                            Amount   <span
                                            style="color:red">*</span></label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Amount"
                                        formControlName="Amount">
                                    </div>
            
                                   
            
                                </div>
                            </div>

                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                            
                                            Reference Number  <span
                                            style="color:red">*</span> </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Reference Number"
                                        formControlName="ReferenceNumber">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                            
                                            Order Payment Preference ID  <span
                                            style="color:red">*</span> </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Order Payment Preference ID"
                                        formControlName="OrderPaymentPreferenceID">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                            
                                            Forename</label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Forename"
                                        formControlName="Forename">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                            
                                            Surname</label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Surname"
                                        formControlName="Surname">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                            
                                            E-Mail Address <span
                                            style="color:red">*</span></label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="EMailAddress"
                                        formControlName="EMailAddress">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                          
                                            Company Name Card</label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Company Name Card"
                                        formControlName="CompanyNameCard">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                            
                                            Prefix Card </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter  Prefix Card " filter="true"
                                        formControlName="prefixCard"
                                        [options]="prefixCard" optionlabel="label"   >
                                                        
                                        </p-dropdown>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                            
                                            First Name Card  <span
                                            style="color:red">*</span></label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="First Name Card "
                                        formControlName="FirstNameCard">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                          
                                            Middle Name Card</label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Middle Name Card"
                                        formControlName="MiddleNameCard">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                           
                                            Last Name Card  <span
                                            style="color:red">*</span> </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Last Name Card  "
                                        formControlName="LastNameCard">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                          
                                            Suffix on Card</label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Suffix on Card" filter="true"
                                        formControlName="SuffixonCard"
                                        [options]="sufixCard" optionlabel="label"   >
                                                        
                                        </p-dropdown>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                         
                                            Card Type </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Card Type " filter="true"
                                        formControlName="CardType"
                                        [options]="CardTypeAccountsArray" optionlabel="label"  >
                                                        
                                        </p-dropdown>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                           
                                            Card Number   <span
                                            style="color:red">*</span> </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Card Number"
                                        formControlName="CardNumber">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                          
                                            Expiration Date  <span
                                            style="color:red">*</span></label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Card Type " filter="true"
                                        formControlName="CardDay"
                                        [options]="months" optionlabel="label"   >
                                                        
                                        </p-dropdown>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                           
                                            Expiration Year  <span
                                            style="color:red">*</span> </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Card Type " filter="true"
                                        formControlName="Month"
                                        [options]="years" optionlabel="label"   >
                                                        
                                        </p-dropdown>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                         
                                            Description  </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Description"
                                        formControlName="Description">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                           
                                            Forename  </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Forename"
                                        formControlName="ForenameA">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                            
                                            Surname   </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Surname"
                                        formControlName="SurnameA">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                           
                                            Bill-To Address1   <span
                                            style="color:red">*</span></label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Bill-To Address1"
                                        formControlName="BilToAddress1">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                            
                                            Bill-To Address2    </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Bill-To Address2  "
                                        formControlName="BillToAddress2">
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                            
                                            City  <span
                                            style="color:red">*</span> </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="City"
                                        formControlName="City">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                            
                                            State <span
                                            style="color:red">*</span></label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter State" filter="true"
                                        formControlName="state"
                                        [options]="stateArray" optionlabel="label"   >
                                                        
                                        </p-dropdown>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                            
                                            Zip/Postal Code <span
                                            style="color:red">*</span> </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Zip/Postal Code"
                                        formControlName="zip">
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                           
                                            Country  <span
                                            style="color:red">*</span> </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Country" filter="true"
                                formControlName="Country" (onChange)="onCountryChange($event)"
                                [options]="array" optionlabel="label"   >
                                                
                                </p-dropdown>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                           
                                            Payment Method Type	  </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <label>{{ this.paymentethodType}}</label>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                           
                                            Product Store	 </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <label>{{this.productStoreType}}</label>
                                    </div>
            
                                   
            
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="this.showFive">
                                <div class="row">
            
                                    <div class="col-lg-3 form-group classInput" >
                                        <label for="exampleInputEmail1">
                                            
                                            Transaction Type		  </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <label>{{ this.transactionType}}</label>
                                  
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                            
                                            Amount  <span
                                            style="color:red">*</span>  </label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="text" class="form-control" placeholder="Amount"
                                        formControlName="Amount">
                                    </div>
            
                                   
            
                                </div>
                            </div>

                      
                        </div>
                        
                        <div class="main-submit-button" style=" margin-right: 25%; ">
                       
                            <button type="submit" *ngIf="this.showSecond"  (click)="onSubmit();"   class="btn btn-secondary submit-btn">Submit</button>
                            <button type="submit" *ngIf="this.release"  (click)="processReleaseTransaction();"   class="btn btn-secondary submit-btn">Submit</button>
                            <button type="submit" *ngIf="this.refund"  (click)="processRefundTransaction();"   class="btn btn-secondary submit-btn">Submit</button>
                            <button type="submit" *ngIf="this.showFive"  (click)="onSubmitCredit();"   class="btn btn-secondary submit-btn">Submit</button>
                            
                        </div>
                        <br><br>
                    </form>
                   
                </div>
            </div>

        </div>
                                        </div>
                                    </div>
                                 


                                </div>
                          
            
            </div>
        </div>
        </div>
    </div>
 </div>
 <ngx-spinner></ngx-spinner> 