<div class="container-fluid main-container-wrapper">

    <div class="row ">
        <div class="col-12 pad_0">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Feature Types</span>
               <span class="d-flex">
     
                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class="mb-5 border_wdth_1">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="display: none !important;padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                        <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                            <a>Main</a>
                        </li>
                        <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                            <a>Catalogs</a>
                        </li>
                        <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                            <a>Categories</a>
                        </li>
                        <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                            <a>Products</a>
                        </li>
                        <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                            <a>Features</a>
                        </li>
                        <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                            <a>Promos</a>
                        </li>
                        <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                            <a>Price Rules</a>
                        </li>
                        <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                            <a>Stores</a>
                        </li>
                        <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                            <a>Product Store Groups</a>
                        </li>
                        <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                            <a>Thesaurus</a>
                        </li>
                        <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                            <a>Reviews</a>
                        </li>
                        <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                            <a>Configurations</a>
                        </li>
                        <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                            <a>Subscriptions</a>
                        </li>
                        <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                            <a>Shipping</a>
                        </li>
                        <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                            <a>Image Management</a>
                        </li>
                    </ul>
                </div>
                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                    <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li [ngClass]="activeCategoryMain==1?'active':''" (click)="featureTypeUrl()"><a>
                                    Feature Type</a></li>
                                <li [ngClass]="activeCategoryMain==2?'active':''" (click)="featureCategory()"><a>
                                    Feature Category</a></li>
                                <li [ngClass]="activeCategoryMain==3?'active':''" (click)="featureGroup()"><a>
                                    Feature Group</a></li>

                                <li [ngClass]="activeCategoryMain==4?'active':''" (click)="featureInteraction()"><a>
                                    Feature Interaction</a></li>


                            </ul>
                        </div>
                        <div class=" bg-white color-grey" style="    padding: 15px 15px;">

                            <div class="create-new-leade mt-2">
                                <div class="">
                                    <div class="">
                                        <form class="w-100" [formGroup]="searchFilterForm">

                                            <div class="w3-card-4  w-100">
                                                <div class="accordion classCard" id="myAccordion">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingOne">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                <!-- <span class="headingAccords"> Search Option</span> -->
                                                                <button *ngIf="!this.Hidebtn" type="button" class="btn btn-outline-secondary active marginHeader" data-toggle="modal" data-target="#exampleModalCenter">Create New Feature Type </button>

                                                                <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 75%!important; padding-top: 11px !important;
                                                                    padding-bottom: 11px !important;">
                                                                </button>									
                    
                                                            </div>
                        
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                            <div class="card-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select allUser">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Product Feature Type ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="productFeatureTypeIdSearchType">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control w-100" formControlName="productFeatureTypeId" placeholder="Enter Product Feature Type ID">
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Description</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="descriptionSearchType">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control w-100" formControlName="description" placeholder="Enter Description">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                
                
                                                                    </div>
                                                                </div>
        
                                                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -41%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="searchFeatureType()">Find</button>&nbsp;
                                                                    <button type="button" class="btn btn-danger ml-2" (click)="resetForm()">Reset</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <!-- <div class="w3-card-4 classCard">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" data-target="#exampleModalCenter">Create New Feature Type </button>

                                                </div><br>
                                                <div class="all-users-infomation font-13 font-medium own-user-select allUser">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Product Feature Type ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="productFeatureTypeIdSearchType">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="text" class="form-control w-100" formControlName="productFeatureTypeId" placeholder="Enter Product Feature Type ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Description</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="descriptionSearchType">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="text" class="form-control w-100" formControlName="description" placeholder="Enter Description">
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -41%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="searchFeatureType()">Find</button>&nbsp;
                                                    <button type="button" class="btn btn-danger ml-2" (click)="resetForm()">Reset</button>
                                                </div>
                                            </div> -->
                                            
                                            <br>

                                            <div class="w3-card-4 classCard">
                                                <header class="w3-container w3-blue">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling h4Margin">
                                                            Feature Types List
                                                        </h4>
                                                        <!-- <span class="edit-buttons hCreate">
            
                                                    <a   data-toggle="modal" 
                                                    data-target="#exampleModalCenter">Create</a>
                                                  </span> -->
                                                    </div>
                                                </header>
                                                <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="featureType" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th width="25%" [ngStyle]="{'width':'226px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Product Feature Type ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th width="30%" pSortableColumn="name">
                                                                                <div style="color: white;"> Description
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th width="25%" pSortableColumn="category">
                                                                                <div style="color: white;"> Parent Type Description
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th width="25%" pSortableColumn="category">
                                                                                <div style="color: white;"> Parent Type ID
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th width="25%" pSortableColumn="category">
                                                                                <div style="color: white;"> Created Date
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th width="10%" pSortableColumn="price">
                                                                                <div style="color: white;"> Action</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td width="25%" [ngStyle]="{'width':'236px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button" (click)="detailPage(product.productFeatureTypeId)">
                                                                            {{product.productFeatureTypeId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td width="30%">{{product.description}}</td>
                                                                            <td width="25%">{{product.parentType}}</td>
                                                                            <td width="25%">{{product.parentTypeId}}</td>
                                                                            <td width="25%">
                                                                                {{product.createdStamp | date :'yyyy-MM-dd HH:mm:ss'}}
                                                                            </td>
                                                                            <td width="10%">
                                                                                <div *ngIf="!this.Hidebtn">
                                                                                    <!--    <fa-icon  class="account-button" 
                                                                                [icon]="faEdit"
                                                                                (click)="edit(product.productFeatureTypeId)">
                                                                            </fa-icon> -->
                                                                                    <fa-icon class="text-danger cursor-pointer ml-2" (click)="delete(product.productFeatureTypeId)" [icon]="faTrash"></fa-icon>
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Create Feature Type</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="    width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.editMode==false" [ngClass]="activeCategory==2?'active':''"><a>
                                Create Feature Type</a></li>
                                <li *ngIf="this.editMode==true" [ngClass]="activeCategory==2?'active':''"><a>
                                Update Feature Type</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="featureTypeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Product Feature Type ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="productFeatureTypeId" aria-describedby="emailHelp" placeholder="Enter Product Feature Type ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Product Parent Type
                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="parentType" formControlName="parentTypeId" filter="true" placeholder="Product Parent Type"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Description<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="description" rows="1" placeholder="Enter Description"></textarea>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="main-submit-button" style="margin-right: 14%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="submit()">{{editMode ?'Update':'Create'}}</button>&nbsp;

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>