<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
          
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Find Products</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openContent()"><a>
                                Content</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="openSubSites()" ><a>
                                Sub Sites</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="openIndex()" ><a>
                                Index</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="openFind()"><a>
                                Find</a></li>
            
                            <li [ngClass]="activeCategory==5?'active':''" (click)="openSearchProducts()"><a>
                                Search Products</a></li>
            
                            
                            <button type="submit" class="btn btn-secondary submit-btn ml-2 mt-2" style="margin: 0;" data-toggle="modal" data-target="#creatContentCMSPopup">Create New Content CMS</button>

                        </ul>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==5">
                        
                                            <div class="create-new-leade mt-2">
                                                <div class="">
                                                    <div class="">
                                                        <form class="w-100">

                                                            <div class="w3-card-4  w-100">
                                                                <div class="accordion classCard" id="myAccordion">
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header" id="headingOne">
                                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                                <span class="headingAccords"> Search</span>
                                                                                <!-- <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                                                data-target="#creatContentPopup" data-toggle="modal">Create Content</button>
                                                                     -->
                                                                                <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 88%!important; padding-top: 11px !important;
                                                                                    padding-bottom: 11px !important;">
                                                                                </button>									
                                    
                                                                            </div>
                                        
                                                                        </h2>
                                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                            <div class="card-body">
                                                                                <form class="w-100" [formGroup]="searchProductsForm" >
                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInput" >
                                                                                                    <label for="exampleInputEmail1">
                                                                                                        Product Id</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2" >
                                                                                                    <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp" placeholder="Enter Product Id"
                                                                                                    formControlName="ProductId">
                                                                                                </div>
                                                                                                <!-- <div class="col-lg-3 form-group classInput" >
                                                                                                    <label for="exampleInputEmail1" >
                                                                                                       Product Name</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2" >
                                                                                                    <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp" placeholder="Enter Product Name"
                                                                                                    formControlName="productName">
                                                                                                </div>  -->
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                            
                                                                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -68%;">
                                                                                    <button _ngcontent-cta-c128="" type="submit" 
                                                                                    class="btn btn-secondary submit-btn" (click)="ProductSearch()" >Find</button>&nbsp;
                                                                                    <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                                </div>
                            
                            
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>

                                                            <!-- <div class="w3-card-4 classCard">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                    Search
                                                                </div><br>
                                                            <form class="w-100" [formGroup]="searchProductsForm" >
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">
                                                                                    Product Id</label>
                                                                            </div>
                                                                            <div class="col-lg-2" >
                                                                                <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Enter Product Id"
                                                                                formControlName="ProductId">
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1" >
                                                                                   Product Name</label>
                                                                            </div>
                                                                            <div class="col-lg-2" >
                                                                                <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Enter Product Name"
                                                                                formControlName="productName">
                                                                            </div> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -68%;">
                                                                    <button _ngcontent-cta-c128="" type="submit" 
                                                                    class="btn btn-secondary submit-btn" (click)="ProductSearch()" >Find</button>&nbsp;
                                                                    <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                </div>
                                                            </div> -->
                                                            
                                                            <br>
                    
                                                            <div class="w3-card-4 classCardB" style="width: 100%; margin-top: 0% !important;">
                                                                <header class="w3-container w3-blue">
                                                                    <div class="header-tabs">
                                                                    <h4 class=" common-styling h4M">
                                                                        Search Results

                                                                    </h4>
                                                                    
                                                                    </div>
                                                                </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select" style="display: block!important;">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                        
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="productSearchData" [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total" [scrollable]="true"
                                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input
                                                                                                                type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                </p-checkbox>
                                                                                                
                                                                                                Product ID	<p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="name" [ngStyle]="{'width':'170px'}"><div style="color: white;">Product Name <p-sortIcon field="name">
                                                                                            </p-sortIcon></div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="name" [ngStyle]="{'width':'170px'}"><div style="color: white;">Description <p-sortIcon field="name">
                                                                                        </p-sortIcon></div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'170px'}"><div style="color: white;"> Long Description
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon></div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        
                                                                                        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input
                                                                                                                type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                </p-checkbox>
                                                                                                <span  class=""
                                                                                                    >{{product.productId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.productName}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.description}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.longDescription}}</td>
                                                                                        
                                                                                    </tr>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </form>
                    
                                                    </div>
                                                </div>
                                            </div>
                                            

                                                
                                                
                                               
                                        </div>
                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="creatContentCMSPopup" tabindex="-1" role="dialog" aria-labelledby="creatContentCMSPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">CMS Content

                </span>
                <!-- <span *ngIf="this.show" class="color-black pl-1"> Update New Technician</span> -->
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="customerCategoryPopup==1?'active':''">
                                    <a>
                                        Content Assoc</a>
                                </li>
                                <li [ngClass]="customerCategoryPopup==2?'active':''">
                                    <a>
                                        Content </a>
                                </li>

                                <li [ngClass]="customerCategoryPopup==3?'active':''"><a>
                                        Data Resource</a></li>

                                <li [ngClass]="customerCategoryPopup==4?'active':''"><a>
                                        Text</a></li>

                                <li [ngClass]="customerCategoryPopup==5?'active':''"><a>
                                        Image </a></li>




                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="cmsContentForm" class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist"
                                                    aria-multiselectable="true">

                                                    <div *ngIf="customerCategoryPopup==1">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Content Assoc
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Content ID
                                                                                To</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                formControlName="contentIDTo"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Content ID To">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Map
                                                                                Key</label>
                                                                        </div>

                                                                        <div class="col-lg-2">

                                                                            <input type="email" formControlName="mapKey"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Map Key">



                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Content
                                                                                Assoc Type ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">

                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label"
                                                                                formControlName="contentAssocTypeID"
                                                                                [options]="ContentAssocTypeDataArray"
                                                                                placeholder="Select Content Assoc Type ID">

                                                                            </p-dropdown>


                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Content
                                                                                Assoc Predicate ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label"
                                                                                [options]="DataPredicateArray"
                                                                                formControlName="contentAssocPredicateID"
                                                                                placeholder="Select Content Assoc Predicate ID">

                                                                            </p-dropdown>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">From
                                                                                Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date"
                                                                                formControlName="fromDate"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                >

                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Through Date
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date"
                                                                                formControlName="throughDate"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                >


                                                                        </div>


                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 10%;">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="customerActiveCategoryFunction(2)">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="customerCategoryPopup==2">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Content
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Content ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                formControlName="contentID"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Content ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Template
                                                                                Data Resource ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label"
                                                                                [options]="dataResourceTemplateArray"
                                                                                formControlName="templateDataResourceID"
                                                                                placeholder="Select Template Data Resource ID">

                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Content Type
                                                                                ID </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label"
                                                                                [options]="contentTypeIdDataArray"
                                                                                formControlName="contentTypeID"
                                                                                placeholder="Select Content Type ID">

                                                                            </p-dropdown>
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Owner
                                                                                Content ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label" 
                                                                                [options]="CatalogContentIdArray"
                                                                                formControlName="ownerContentID"
                                                                                placeholder="Select Owner Content ID">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Content
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                formControlName="contentName"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Content Name">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Description
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                        <input type="email"
                                                                            formControlName="description"
                                                                            class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter description">
                                                                           

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Mime Type
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label"
                                                                                [options]="mimeTypeIdDataArray"
                                                                                formControlName="mimeTypeID"
                                                                                placeholder="Select Mime Type ID">
                                                                            </p-dropdown>
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Character
                                                                                Set ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label"
                                                                                [options]="CharacterSetIdArray"
                                                                                formControlName="characterSetID"
                                                                                placeholder="Select Character Set ID">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Locale
                                                                                String
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label"
                                                                                formControlName="localeString"
                                                                                placeholder="Select Locale String">
                                                                            </p-dropdown>
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Status ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label"
                                                                                formControlName="statusID" placeholder="Select Status ID	
                                                        ">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Privilege
                                                                                Enum ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label"
                                                                                formControlName="privilegeEnumID"
                                                                                placeholder="Select Privilege Enum ID ">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="main-submit-button" style="margin-right:9%;">
                                                                <button type="submit"
                                                                    (click)="customerActiveCategoryFunction(1)"
                                                                    class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="customerActiveCategoryFunction(3)">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div *ngIf="customerCategoryPopup==3">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Data Resource
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Data
                                                                                Resource ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label" [options]="DataResourceIdArray"
                                                                                formControlName="dataResourceID"
                                                                                placeholder="Select Data Resource ID	
                                                        ">

                                                                            </p-dropdown>
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Dr Data
                                                                                Resource Type ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label" [options]="resourceTypeDROPArray"
                                                                                formControlName="drDataResourceTypeID"
                                                                                placeholder="Select Dr Data Resource Type ID	
                                                        ">

                                                                            </p-dropdown>
                                                                        </div>




                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">File
                                                                                Path</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text"
                                                                                formControlName="filePath"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter File Path">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Dr Data
                                                                                Template Type ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label" [options]="dataTemplateTypeArray"
                                                                                formControlName="drDataTemplateTypeID"
                                                                                placeholder="Select Dr Data Template Type ID	
                                                        ">

                                                                            </p-dropdown>

                                                                        </div>




                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Dr Mime Type
                                                                                ID </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label" [options]="mimeTypeIdDataArray"
                                                                                formControlName="drMimeTypeID"
                                                                                placeholder="Select Dr Mime Type ID">

                                                                            </p-dropdown>
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Character
                                                                                Set ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label" [options]="CharacterSetIdArray"
                                                                                formControlName="characterSetID1"
                                                                                placeholder="Select Character Set ID">

                                                                            </p-dropdown>

                                                                        </div>




                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Locale
                                                                                String</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                formControlName="localeString1"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Locale String">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Data
                                                                                Source</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                formControlName="dataSource"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Data Source">
                                                                        </div>




                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Data
                                                                                Category</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                formControlName="dataCategory"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Data Category">

                                                                        </div>





                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 9%;">
                                                                <button type="submit"
                                                                    (click)="customerActiveCategoryFunction(2)"
                                                                    class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="customerActiveCategoryFunction(4)">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div *ngIf="customerCategoryPopup==4">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Text
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">


                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Text
                                                                                Data</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                formControlName="textData"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Text Data">

                                                                        </div>





                                                                    </div>
                                                                </div>





                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 9%;">
                                                                <button type="submit"
                                                                    (click)="customerActiveCategoryFunction(3)"
                                                                    class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="customerActiveCategoryFunction(5)">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div *ngIf="customerCategoryPopup==5">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Image
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">




                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Image
                                                                                Data</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="file" class="form-control"
                                                                                id="exampleInputEmail1" 
                                                                                formControlName="imageData"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Image Data">
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Created By
                                                                                User</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                formControlName="createdByUser"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Created By User">

                                                                        </div>





                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Created
                                                                                Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date"
                                                                                formControlName="createdDate"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                >

                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Last
                                                                                Modified By User</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"
                                                                                formControlName="lastModifiedByUser"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Last Modified By User">

                                                                        </div>





                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Last
                                                                                Modified Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date"
                                                                                formControlName="lastModifiedDate"
                                                                                class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                >

                                                                        </div>

                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 9%;">
                                                                <button type="submit"
                                                                    (click)="customerActiveCategoryFunction(4)"
                                                                    class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn" (click)="onUpload()">Create</button>

                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
 
<ngx-spinner></ngx-spinner> 

