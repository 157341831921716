<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12 pad_0">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">View Quote {{quoteName}} [{{quoteId}}] </span>
               <span>
                <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
            </span> 
            </div>

            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard border_wdth_1">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCategory==13?'active':''" 
                            (click)="changeactiveCategory(13)">
                                <a>Summary
                                </a>
                            </li>
                           <li [ngClass]="activeCategory==1?'active':''" 
                            *ngIf="this.statusId=='QUO_CREATED'||'QUO_APPROVED'||'QUO_REJECTED'||'QUO_ORDERED'"
                            (click)="changeactiveCategory(1)">
                                <a>Quote
                                </a>
                            </li> 
                            <li [ngClass]="activeCategory==2?'active':''"
                            *ngIf="this.statusId=='QUO_CREATED'||'QUO_ORDERED'" 
                            (click)="changeactiveCategory(2)">
                                <a>Items</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" 
                            (click)="changeactiveCategory(3)">
                                <a>Attributes</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''"
                            *ngIf="this.statusId=='QUO_CREATED'||'QUO_ORDERED'" 
                            (click)="changeactiveCategory(4)">
                                <a>Coefficients</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''"
                            *ngIf="this.statusId=='QUO_CREATED'||'QUO_ORDERED'" 
                            (click)="changeactiveCategory(5)">
                                <a>Prices</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" 
                            *ngIf="this.statusId=='QUO_CREATED'||'QUO_ORDERED'"
                            (click)="changeactiveCategory(6)">
                                <a>Adjustments</a>
                            </li>
                             <li [ngClass]="activeCategory==7?'active':''" 
                            *ngIf="this.statusId=='QUO_CREATED'||'QUO_APPROVED'||'QUO_REJECTED'||'QUO_ORDERED'" 
                            (click)="changeactiveCategory(7)">
                                <a> Profit</a>
                            </li> 
                            <li [ngClass]="activeCategory==8?'active':''" 
                            *ngIf="this.statusId=='QUO_CREATED'||'QUO_ORDERED'"
                            (click)="changeactiveCategory(8)">
                                <a> Work Efforts</a>
                            </li>
                            <li [ngClass]="activeCategory==9?'active':''"
                            *ngIf="this.statusId=='QUO_CREATED'||'QUO_ORDERED'" 
                            (click)="changeactiveCategory(9)">
                                <a>Notes</a>
                            </li>

                            <li [ngClass]="activeCategory==10?'active':''"
                            *ngIf="this.statusId=='QUO_CREATED'||'QUO_ORDERED'" 
                            (click)="changeactiveCategory(10)">
                                <a>Roles</a>
                            </li>
                            <li [ngClass]="activeCategory==11?'active':''" 
                            *ngIf="this.statusId=='QUO_CREATED'||'QUO_ORDERED'" 
                            (click)="changeactiveCategory(11)">
                                <a>Terms</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCard w-100">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">View Quote ID : [ {{this.quoteId}} ]
                                                </p>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                    data-toggle="modal" data-target="#createQuote">Create New Quote
                                                </button>

                                                <button type="submit" class="btn btn-secondary submit-btn mr-2"
                                                    (click)="update()" data-toggle="modal"
                                                    data-target="#updateQuote">Edit
                                                    request
                                                </button>

                                                <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                    (click)="generatePdfQuote()">PDF
                                                </button>

                                                <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                    data-toggle="modal" data-target="#QuoteReportMail"> Send Report By
                                                    Mail
                                                </button>
                                            </div>
                                            <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                <div *ngIf="activeCategory==13">
                                                    <div class="w-100">
                                                        <div class="panel-group">

                                                            <div class="divA">
                                                                <div class="w3-card-4 classCardView">
                                                                    <div class="header-tabs">

                                                                        <h4 class=" common-styling hleft">
                                                                            Quote Information
                                                                        </h4>
                                                                        <span
                                                                            class="edit-buttons cursor-pointer"
                                                                            (click)="generatePDF()"> PDF</span>
                                                                        <span
                                                                            class="edit-buttons cursor-pointer ml-4 hCreate"
                                                                            routerLink="/crm/quotations/quote-email"
                                                                            [queryParams]="{quoteId:quoteId}"> Send
                                                                            Email</span>
                                                                        <span
                                                                            class="edit-buttons hCreate pr-2">

                                                                            <a data-toggle="modal"
                                                                                (click)="getQuetoDetailEdit();"
                                                                                data-target="#exampleModalCenter">Update</a>
                                                                        </span>
                                                                    </div>
                                                                    <div class="panel-body">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div *ngIf="resultData"
                                                                                class="color-black container">
                                                                                <div class="row">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Quote Type</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">{{resultData.quoteType}}</span>
                                                                                        </div>
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Sales Channel</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">{{resultData.salesChannel}}</span>
                                                                                        </div>

                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Status</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">{{resultData.status}}</span>
                                                                                        </div>

                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Party</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">{{resultData.partyId}}</span>
                                                                                        </div>

                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Quote Name</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">{{resultData.quoteName}}</span>
                                                                                        </div>

                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Description</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">{{resultData.description}}</span>
                                                                                        </div>

                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Currency</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">{{resultData.currencyUomId}}</span>
                                                                                        </div>

                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Product Store</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">{{resultData.productStore}}</span>
                                                                                        </div>



                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <br>
                                                                <div class="w3-card-4 classCardView">
                                                                    <div class="header-tabs">

                                                                        <h4 class=" common-styling hleft">
                                                                            Date
                                                                        </h4>
                                                                    </div>

                                                                    <div class="panel-body">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div *ngIf="resultData"
                                                                                class="color-black container">
                                                                                <div class="row">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Quote Issue Date</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">{{resultData.issueDate
                                                                                                | date
                                                                                                :"yyyy-MM-dd"}}</span>
                                                                                        </div>


                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Valid From Date</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">{{resultData.validFromDate
                                                                                                | date
                                                                                                :"yyyy-MM-dd"}}</span>
                                                                                        </div>


                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Valid Through Date</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">{{resultData.validThruDate
                                                                                                |
                                                                                                date:"yyyy-MM-dd"}}</span>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <br>

                                                            </div>

                                                            <div class="divB">
                                                                <div class="w3-card-4 classCardView">
                                                                    <div class="header-tabs">

                                                                        <h4 class=" common-styling hleft">
                                                                            Quote Roles
                                                                        </h4>
                                                                    </div>
                                                                    <div class="panel-body">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="color-black container">
                                                                                <div class="row">
                                                                                    <div class="article-container">
                                                                                        <div class="article">
                                                                                            <p class="col-lg-6 col-12">
                                                                                                Request Taker</p>
                                                                                            <span
                                                                                                class="color-grey pl-20 col-lg-10 col-12">THE
                                                                                                PRIVILEGED
                                                                                                ADMINISTRATOR</span>
                                                                                        </div>

                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <br>
                                                                <div class="w3-card-4 classCardView">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling hleft">
                                                                            Notes
                                                                        </h4>
                                                                        <span
                                                                            class="edit-buttons mr-2 hCreate"
                                                                            data-toggle="modal"
                                                                            data-target="#noteQuote">Create  New Notes</span>
                                                                    </div>
                                                                    <div class="panel-body">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select padClass">

                                                                            <div
                                                                                class="card own-account-table borderTable">
                                                                                <p-table [value]="quoteNotes"  scrollHeight="100px"
                                                                                    [paginator]="true" [rows]="rows"
                                                                                    scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total"
                                                                                    [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr
                                                                                            style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Note Name
                                                                                                    <p-sortIcon
                                                                                                        field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="quantity">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Note Info
                                                                                                    <p-sortIcon
                                                                                                        field="quantity">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Note Date Time
                                                                                                    <p-sortIcon
                                                                                                        field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="category">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Note Party
                                                                                                    <p-sortIcon
                                                                                                        field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="quantity">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Created Date
                                                                                                    <p-sortIcon
                                                                                                        field="quantity">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-product>
                                                                                        <tr>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                <div
                                                                                                    class="checkbox-align">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    {{product.noteName}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                {{product.noteInfo}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                {{product.noteDateTime
                                                                                                |date :'yyyy-MM-DD  HH:mm:ss'}}
                                                                                            </td>


                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                {{product.noteParty}}
                                                                                            </td>

                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                {{product.createdStamp |
                                                                                                date:'yyyy-MM-dd HH:mm:ss'}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                <fa-icon [icon]="faEdit"
                                                                                                    routerLink="/crm/quotations/quoteNotes"
                                                                                                    [queryParams]="{quoteId:quoteId,noteId:product.noteId}"
                                                                                                    class="text-primary cursor-pointer mr-2">
                                                                                                </fa-icon>
                                                                                                <fa-icon
                                                                                                    (click)="deleteNote(product.noteId)"
                                                                                                    [icon]="faTrash"
                                                                                                    class="btn btn-danger buttonclass">
                                                                                                </fa-icon>
                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                               
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                                <br>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==1" class="w-100">
                                                    <div style="width: 49%; float: left;">
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Quote ID {{this.quoteId}} Information
                                                                </h4>
                                                            </div>
                                                            <div class="panel-body">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="row">
                                                                            <div class="article-container"
                                                                                *ngIf="this.MainQuoteList">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Type </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                        {{this.MainQuoteList.data.quoteTypeId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Sales Channel</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                    </span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Status</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.MainQuoteList.data.statusId}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Party Id </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        <!-- {{this.MainQuoteList.entityQueryParty.firstName
                                                                                        +"
                                                                                        "+this.MainQuoteList.entityQueryParty.lastName}} -->
                                                                                        <span (click)="partyPage()"
                                                                                            style="color: #0d3769;">{{this.MainQuoteList.data.partyId}}</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Quote Name </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{this.MainQuoteList.data.quoteName}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Description
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{this.MainQuoteList.data.description}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Currency
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{this.MainQuoteList.data.currencyUomId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        ProductProductStore
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{this.MainQuoteList.data.productStoreId}}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="width: 49.8%; float: right;">
                                                        <div class="w3-card-4 classCardView ">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Date
                                                                </h4>
                                                            </div>
                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">

                                                                    <p-table [value]="MainQuoteListNew" [paginator]="true"
                                                                        [rows]="rows" scrollWidth="100%"  scrollHeight="100px"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'200px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>



                                                                                        Quote Issue Date
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Valid From Date</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Valid Through Date</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.data.issueDate |
                                                                                        date:'yyyy-MM-d hh:mm:ss' }}
                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.data.validFromDate |
                                                                                    date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.data.validThruDate |
                                                                                    date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                    
                                                                </div>

                                                            </div>


                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Quote Roles

                                                                </h4>

                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">

                                                                    <p-table [value]="QuoteRoles" [paginator]="true"
                                                                        [rows]="rows" scrollWidth="100%"  scrollHeight="100px"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'200px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>


                                                                                        Parent Type Id
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Party</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.entityQueryRoleType[0].description}}
                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.dataRole.firstName +
                                                                                    product.dataRole.middleName +
                                                                                    product.dataRole.lastName }}
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                   
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div style="width: 100%; float: left;">
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Quote Items

                                                                </h4>

                                                            </div>



                                                        </div>

                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Duplicate Quote
                                                                </h4>
                                                            </div>
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <form class="w-100" [formGroup]="DuplicateQuote">
                                                                    <div
                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div
                                                                                    class="col-lg-2 form-group classInputCD">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Duplicate
                                                                                        Quote

                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-10">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-2 form-group classInputCD ">
                                                                                            Quote Items
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="checkbox"
                                                                                                formControlName="QuoteItems">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-2 form-group classInputCD">
                                                                                            Adjustments
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="checkbox"
                                                                                                formControlName="Adjustments">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-2 form-group classInputCD">
                                                                                            Quote Roles
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="checkbox"
                                                                                                formControlName="QuoteRoles">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div
                                                                                    class="col-lg-2 form-group classInputCD">
                                                                                </div>
                                                                                <div class="col-lg-10">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-2 form-group classInputCD">
                                                                                            Quote Attributes
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="checkbox"
                                                                                                formControlName="QuoteAttributes">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-2 form-group classInputCD">
                                                                                            Coefficients
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="checkbox"
                                                                                                formControlName="Coefficients">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-2 form-group classInputCD">
                                                                                            Quote Term
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="checkbox"
                                                                                                formControlName="QuoteTerm">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12 col-12 main-submit-button mt-3"
                                                                            style="margin-left: -10%;">
                                                                            <button type="submit"
                                                                                (click)="copyQuoteOrders()"
                                                                                class="btn btn-secondary submit-btn">Copy</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==2" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                List Quote Items
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                    data-toggle="modal" data-target="#quoteItem">Create New
                                                                    New Quote Item
                                                                </button>
                                                            </h4>
                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="QuoteItemLists" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"  scrollHeight="100px"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'200px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>

                                                                                    Quote Item Seq ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    ProductProductId
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quote Work Effort ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    View Request </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quantity </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Selected Amount </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quote Unit Price</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Adjustments</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Reserv Start</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Reserv Length</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Reserv Persons</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Config ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Estimated Delivery Date</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Comments</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Is Promo</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Lead Time Days</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Remove</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>





                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span type="submit"
                                                                                        style="color: #0d3769;"
                                                                                        (click)="updateQuote(product)">

                                                                                        {{product.node1.quoteItemSeqId}}</span>
                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.productId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.workEffortId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.quantity}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.selectedAmount}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.quoteUnitPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.reservStart}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.reservLength}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.reservPersons}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.configId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.estimatedDeliveryDate}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.comments}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.isPromo}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.leadTimeDays}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button type="submit"
                                                                                    class="btn btn-danger buttonclass"
                                                                                    (click)="removeQuoteItem(product)">Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==3" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                List Quote Attributes
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn mr-2"
                                                                    data-toggle="modal" (click)="resetAtt()"
                                                                    data-target="#quoteAttribute">Create New Quote Attributes</button>
                                                            </h4>


                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">

                                                                <p-table [value]="QuoteAttribute" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"  scrollHeight="100px"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'200px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>




                                                                                    Attribute Name
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Attribute Value</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Attr Description</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span style="color: #0d3769;"
                                                                                        data-toggle="modal"
                                                                                        data-target="#quoteAttribute"
                                                                                        (click)="updateAttribute(product)">
                                                                                        {{product.data.attrName}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.attrValue}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.attrDescription }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button type="submit"
                                                                                    (click)="removeQuoteAttribute(product)"
                                                                                    class="btn btn-danger buttonclass mr-2">Remove</button>


                                                                            </td>



                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==4" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                List Quote Coefficients
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn mr-2"
                                                                    (click)="resetCoefficient()" data-toggle="modal"
                                                                    data-target="#QuoteCoefficient">
                                                                    Create New Quote Coefficient</button>

                                                            </h4>


                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">

                                                                <p-table [value]="quoteCoefficient" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"  scrollHeight="100px"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'200px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>


                                                                                    Coeff. Name
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'125px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Coeff. Value
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>


                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Action</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>





                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>

                                                                                    <span type="submit"
                                                                                        style="color: #0d3769;"
                                                                                        (click)="updateCoefficient(product)"
                                                                                        data-toggle="modal"
                                                                                        data-target="#QuoteCoefficient">
                                                                                        {{product.data.coeffName}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.coeffValue}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button type="submit" class="btn btn-danger buttonclass"
                                                                                    (click)="removeQuoteCoefficient(product)">Remove
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==5" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Quote Prices
                                                                <!-- <button type="submit"
                                                                    class="btn btn-secondary submit-btn mr-2"
                                                                    data-toggle="modal"
                                                                    data-target="#contentPopup">Create New Quote
                                                                </button>  -->
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container"
                                                                            *ngIf="this.AutoUpdateQuoteList">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 classInput">
                                                                                    Coefficients </p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.AutoUpdateQuoteList.Coefficients?this.AutoUpdateQuoteList.Coefficients:'Null'}}
                                                                                </span>

                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 classInput">
                                                                                    Total Cost Mult</p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.AutoUpdateQuoteList.TotalCostMult?this.AutoUpdateQuoteList.TotalCostMult:'Null'}}
                                                                                </span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 classInput">
                                                                                    Cost To Price Mult</p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.AutoUpdateQuoteList.CostToPriceMult?this.AutoUpdateQuoteList.CostToPriceMult:'Null'}}</span>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCardView mt-3">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Quote Prices

                                                            </h4>
                                                        </div>
                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="autoUpdateQuotePricesList"  scrollHeight="100px"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>

                                                                                    View Request
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Quote Item Seq ID
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    ProductProductId
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quantity
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Average Cost
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Cost to Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Default Unit Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quote Unit Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Manual Unit Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Selected
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    {{product.data.contentId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.quoteItemSeqId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.productId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.quantity}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.averageCost.price}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.CostToPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.DefaultUnitPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.quoteUnitPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}"> <button
                                                                                    type="submit"
                                                                                    data-target="#quotePrices"
                                                                                    data-toggle="modal"
                                                                                    (click)="updatePrice(product)"
                                                                                    class="btn btn-secondary submit-btn">Submit
                                                                                </button>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container"
                                                                            *ngIf="this.AutoUpdateQuoteList">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 classInput">
                                                                                    Total Cost </p>

                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.AutoUpdateQuoteList.TotalCost}}
                                                                                </span>

                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 classInput">
                                                                                    Total Price </p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.AutoUpdateQuoteList.totalPrice}}
                                                                                </span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 classInput">
                                                                                    Total Cost Mult</p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.AutoUpdateQuoteList.TotalCostMult}}</span>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div *ngIf="activeCategory==6" class="w-100">

                                                    <div class="w3-card-4 classCardView mt-2">

                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn mr-2"
                                                                    (click)="autoCreateQuoteAdjustments()">Run Store
                                                                    Promotions</button>

                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn mr-2"
                                                                    data-toggle="modal" (click)="resetAdjustment()"
                                                                    data-target="#quoteAdjustment">Create
                                                                    Manual Adjustment</button>

                                                            </h4>
                                                        </div>
                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="quoteAdjustmentByIdAndAllList"  scrollHeight="100px"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>


                                                                                    Quote Adjustment ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Quote Adjustment Type
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quote Item Seq ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Description Amount </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Product Promo ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Product Promo Rule ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Product Promo Action Seq ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Corresponding Product ID </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Source Percentage </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Exempt Amount </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Include In Tax </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Include In Shipping </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Last Modified Date </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Last Modified By User </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Action </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>






                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                        (click)="updateAdjustment(product)"
                                                                                        data-toggle="modal"
                                                                                        data-target="#quoteAdjustment">
                                                                                        {{product.data.quoteAdjustmentId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.quoteAdjustmentTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.quoteItemSeqId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.description}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.amount}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.productPromoId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.productPromoRuleId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.productPromoActionSeqId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.correspondingProductId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.sourcePercentage}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.exemptAmount}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.includeInTax}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.includeInShipping}}
                                                                            </td>


                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.lastModifiedDate |
                                                                                date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.nodeData.lastModifiedByUserLogin}}
                                                                            </td>
                                                                            <td>
                                                                                <button type="submit"
                                                                                    class="btn btn-danger buttonclass"
                                                                                    (click)="removeQuoteOrderAdjustment(product)">Remove</button>
                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==7" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                View Quote Profit

                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container"
                                                                            *ngIf="this.viewQuoteProfitSummary">
                                                                            <div class="article">
                                                                                <p class="col-lg-3 classInput">
                                                                                    Coefficients</p>

                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">

                                                                                </span>

                                                                            </div>
                                                                            <div class="article mt-2">
                                                                                <p class="col-lg-3 classInput">
                                                                                    Total Cost Mult </p>

                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.viewQuoteProfitSummary.TotalCostMult}}
                                                                                </span>

                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-3 classInput">
                                                                                    Cost To Price Mult
                                                                                </p>

                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.viewQuoteProfitSummary.CostToPriceMult}}
                                                                                </span>

                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-3 classInput">
                                                                                    Total Cost
                                                                                </p>

                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.viewQuoteProfitSummary.TotalCost}}
                                                                                </span>

                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-3 classInput">
                                                                                    Total Amount
                                                                                </p>

                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.viewQuoteProfitSummary.TotalAmount}}
                                                                                </span>

                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-3 classInput">
                                                                                    Total Profit
                                                                                </p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.viewQuoteProfitSummary.TotalProfit}}
                                                                                </span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-3 classInput">
                                                                                    Total Perc Profit
                                                                                </p>
                                                                                <span
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                    {{this.viewQuoteProfitSummary.TotalPercProfit}}</span>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="viewQuoteProfit" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"  scrollHeight="100px"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>

                                                                                    View Request
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Quote Item Seq ID
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    ProductProductId
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quantity
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Average Cost
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quote Unit Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quote Profit
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quote Percentage Profit
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>




                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.quoteItemSeqId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.productId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.quantity}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.averageCost.price}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.quoteUnitPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.QuoteProfit}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.QuotePercentageProfit}}
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==8" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Quote Work Efforts
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn mr-2"
                                                                    (click)="reset()" data-toggle="modal"
                                                                    data-target="#quoteWorkEffort">
                                                                    Create new Quote Workeffort</button>

                                                            </h4>


                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="quoteWorkEffort" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"  scrollHeight="100px"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Work Effort ID


                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Current Status ID

                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;"> Start Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">Completion
                                                                                    Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>




                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    {{product.nodeData.workEffortId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.nodeData.currentStatusId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.nodeData.estimatedStartDate |
                                                                                date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.nodeData.estimatedCompletionDate
                                                                                | date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>
                                                                            <td>
                                                                                <button type="submit"
                                                                                    class="btn btn-secondary submit-btn mr-2"
                                                                                    (click)="updateWorkEffort(product)"
                                                                                    data-toggle="modal"
                                                                                    data-target="#quoteWorkEffort">
                                                                                    Update</button>

                                                                                <button type="submit"
                                                                                    class="btn btn-danger buttonclass mr-2"
                                                                                    (click)="deleteQuoteWorkEffort(product)">
                                                                                    Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No
                                                                                        Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==9" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                List Quote Note
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn mr-2"
                                                                    data-toggle="modal" data-target="#noteQuote">Create
                                                                    New Quote Note
                                                                </button>

                                                            </h4>


                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="quoteNotes" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"  scrollHeight="100px"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Note Name
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="quantity">
                                                                                <div style="color: white;">Note Info
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">Note Date
                                                                                    Time
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">Note Party
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="quantity">
                                                                                <div style="color: white;">Update
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    {{product.noteName}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.noteInfo}}</td>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.noteDateTime |date
                                                                                :'yyyy-MM-DD HH:mm:ss'}}
                                                                            </td>


                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.noteParty}}</td>


                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button type="submit"
                                                                                    data-toggle="modal"
                                                                                    data-target="#noteQuote"
                                                                                    (click)="updateNote(product)"
                                                                                    class="btn btn-secondary submit-btn mr-2">Edit</button>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==10" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                List Quote Roles
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn mr-2"
                                                                    (click)="resetRole()" data-toggle="modal"
                                                                    data-target="#quoteRoles">Create
                                                                    New Quote Role
                                                                </button>

                                                            </h4>


                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">

                                                                <p-table [value]="quoteRoleList" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"  scrollHeight="100px"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>

                                                                                    Party ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Role Type</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    From Date</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'100px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Update
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'100px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Remove
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span style="color: #0d3769;">
                                                                                        {{product.data.partyId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.roleTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.fromDate
                                                                                |date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.thruDate
                                                                                |date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>


                                                                            <td [ngStyle]="{'width':'100px'}">
                                                                                <button type="submit"
                                                                                    data-toggle="modal"
                                                                                    data-target="#quoteRoles"
                                                                                    (click)="updateRole(product)"
                                                                                    class="btn btn-secondary submit-btn mr-2">Update</button>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'100px'}">
                                                                                <button type="submit"
                                                                                    (click)="removeQuoteOrderRole(product)"
                                                                                    class="btn btn-danger buttonclass mr-2">Remove</button>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==11" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Quote Term List
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn mr-2"
                                                                    (click)="resetTerm()" data-toggle="modal"
                                                                    data-target="#quoteTerm">Create
                                                                    New Quote Term
                                                                </button>

                                                            </h4>


                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">

                                                                <p-table [value]="quoteTermList" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"  scrollHeight="100px"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'200px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>


                                                                                    Term Type ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quote ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quote Item Seq ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Term Value
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quote Uom ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Term Days
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Text Value
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Description
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'100px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">

                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'100px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">

                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span>
                                                                                        {{product.data.termTypeId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.quoteId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.quoteItemSeqId }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.termValue}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.uomId }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.termDays}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.termValue }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.description}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button type="submit"
                                                                                    (click)="updateTerm(product)"
                                                                                    data-toggle="modal"
                                                                                    data-target="#quoteTerm"
                                                                                    class="btn btn-secondary submit-btn mr-2">Edit</button>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button type="submit"
                                                                                    (click)="deleteQuoteTerm(product)"
                                                                                    class="btn btn-danger buttonclass mr-2">Remove</button>


                                                                            </td>



                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==12" class="w-100">
                                                    <div class="divA">
                                                        <div class="w3-card-4 classCard mt-2">
                                                            <div class="container-fluid">
                                                                <ul class="tabbing-section tabbing-accordians tabClass">

                                                                    <li [ngClass]="activeCategoryA==1?'active':''">
                                                                        <a>
                                                                            Edit Quote Items
                                                                        </a>
                                                                    </li>
                                                                    <button type="button" class="btn btn-secondary
                                                                     submit-btn ml-2"
                                                                        (click)="activeCategory=11">Create New Quote
                                                                        Term </button>
                                                                </ul>
                                                            </div>

                                                            <div class="create-new-leade mt-2">
                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        <form class="w-100" [formGroup]="QuoteItemForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">
                                                                                                Quote Item Seq ID


                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                disabled
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="quoteItemSeqId"
                                                                                                placeholder="Enter Quote Item Seq ID
                                                                                            	">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">ProductProductId

                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <p-dropdown
                                                                                                formControlName="productId"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter ProductProductId"
                                                                                                [options]="ProductIdArray"
                                                                                                filter="true"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>



                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">ProductFeatures


                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">

                                                                                            <p-dropdown
                                                                                                formControlName="productFeatureId"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                [options]="ProductFeaturesArray"
                                                                                                placeholder="Enter ProductFeatures"
                                                                                                filter="true"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Deliverable
                                                                                                Type ID


                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">

                                                                                            <p-dropdown
                                                                                                formControlName="deliverableTypeId"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Deliverable Type ID "
                                                                                                filter="true"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Quote
                                                                                                Skill Type ID


                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">

                                                                                            <p-dropdown
                                                                                                formControlName="skillTypeId"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                [options]="QuoteSkillTypeIdArray"
                                                                                                placeholder="Enter Quote Skill Type ID"
                                                                                                filter="true"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Quote
                                                                                                Uom ID


                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">

                                                                                            <p-dropdown
                                                                                                formControlName="uomId"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                [options]="QuoteUomIdArray"
                                                                                                placeholder="Enter Quote Uom ID"
                                                                                                filter="true"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Quote
                                                                                                Work Effort ID
                                                                                            </label>
                                                                                        </div>

                                                                                        <div class="col-lg-4">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="workEffortId"
                                                                                                placeholder="Enter Quote Work Effort ID">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label for="productId">Cust
                                                                                                Request ID


                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="custRequestId"
                                                                                                placeholder="Enter Cust Request ID">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Cust
                                                                                                Request Item Seq ID
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="custRequestItemSeqId"
                                                                                                placeholder="Enter Cust Request Item Seq ID ">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">
                                                                                                Quantity
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="quantity"
                                                                                                placeholder="Enter Quantity">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Selected
                                                                                                Amount
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="selectedAmount"
                                                                                                placeholder="Enter Selected Amount">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Quote
                                                                                                Unit Price
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="email" disabled
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="quoteUnitPrice"
                                                                                                placeholder="Enter Quote Unit Price">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>


                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reserv
                                                                                                Start

                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="date"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="reservStart"
                                                                                                placeholder="Enter Reserv Start ">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reserv
                                                                                                Length

                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="reservLength"
                                                                                                placeholder="Enter Reserv Length">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reserv
                                                                                                Persons

                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="reservPersons"
                                                                                                placeholder="Enter Reserv Persons">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Config
                                                                                                ID

                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="configId"
                                                                                                placeholder="Enter Config ID">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Estimated
                                                                                                Delivery Date

                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="date"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="estimatedDeliveryDate"
                                                                                                placeholder="Enter Estimated Delivery Date">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Comments

                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="comments"
                                                                                                placeholder="Enter Comments">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-4 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Lead
                                                                                                Time Days

                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="col-lg-4">
                                                                                            <input type="email"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                formControlName="leadTimeDays"
                                                                                                placeholder="Enter Lead Time Days">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12  main-submit-button"
                                                                                    style="margin-left: -17%;">
                                                                                    <button type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                        (click)="updateQuoteOrderItem()">Submit</button>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="divB">
                                                        <div class="w3-card-4 classCardView ">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Quote Term List
                                                                </h4>
                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="" [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"  scrollHeight="100px"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'200px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        Party ID
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Role Type ID</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        From Date</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.data.partyId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.data.roleTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.data.fromDate |
                                                                                    date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.data.thruDate |
                                                                                    date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>

                                                            </div>

                                                        </div>






                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="createQuote" tabindex="-1" role="dialog" aria-labelledby="createQuoteTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Quote
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Quote
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="quoteForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Quote Type ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="QuoteTypeID"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Quote Type ID"
                                                            [options]="QuoteTypeIdListArray" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputANew">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="PartyID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" [options]="partyIdArray"
                                                            placeholder="Enter Party ID" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Quote Issue Date


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="QuoteIssueDate"
                                                            placeholder="Enter Quote Issue Date">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputANew">
                                                        <label for="exampleInputEmail1">Status


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="Status" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" [options]="statusArray"
                                                            placeholder="Enter Status " filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Currency
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="Currency" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" [options]="QuoteUomIdArray"
                                                            placeholder="Enter Currency " filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputANew">
                                                        <label for="exampleInputEmail1">ProductProductStore
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="ProductProductStore"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            [options]="ProductProductStoreArray"
                                                            placeholder="Enter ProductProductStore " filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Sales Channel


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="SalesChannel"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            [options]="ChannelIdArray"
                                                            placeholder="Enter Sales Channel " filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputANew">
                                                        <label for="productId">Valid From Date


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="ValidFromDate"
                                                            placeholder="Enter Valid From Date">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Valid Through Date


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="ValidThroughDate"
                                                            placeholder="Enter Valid Through Date">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputANew">
                                                        <label for="exampleInputEmail1">Quote Name


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="QuoteName"
                                                            placeholder="Enter Quote Name">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Description

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Description"
                                                            placeholder="Enter Description ">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12  main-submit-button" style="margin-left: -27%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createQuotes()">Create</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="updateQuote" tabindex="-1" role="dialog" aria-labelledby="updateQuoteTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Quote
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Quote
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateQuoteForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Quote ID


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" disabled
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            formControlName="quoteId" placeholder="Enter Quote Id">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Quote Type ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="QuoteTypeID"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Quote Type ID"
                                                            [options]="QuoteTypeIdListArray" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Party ID


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="PartyID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" [options]="partyIdArray"
                                                            placeholder="Enter Party ID" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Quote Issue Date


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="QuoteIssueDate"
                                                            placeholder="Enter Quote Issue Date">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Status


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="Status" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" [options]="statusArray"
                                                            placeholder="Enter Status " filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Currency


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="Currency" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" [options]="QuoteUomIdArray"
                                                            placeholder="Enter Currency " filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">ProductProductStore


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="ProductProductStore"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            [options]="ProductProductStoreArray"
                                                            placeholder="Enter ProductProductStore " filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Sales Channel


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="SalesChannel"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            [options]="ChannelIdArray"
                                                            placeholder="Enter Sales Channel " filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="productId">Valid From Date


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="ValidFromDate"
                                                            placeholder="Enter Valid From Date">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Valid Through Date


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="ValidThroughDate"
                                                            placeholder="Enter Valid Through Date">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Quote Name


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="QuoteName"
                                                            placeholder="Enter Quote Name">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Description

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Description"
                                                            placeholder="Enter Description ">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12  main-submit-button" style="margin-left: -35%;">

                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="updateQuotes()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="QuoteReportMail" tabindex="-1" role="dialog" aria-labelledby="QuoteReportMail"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Edit Quote Report Mail</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Edit Quote Report Mail</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="quoteReportMailForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">
                                                            Send To
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="SendTo" aria-describedby="emailHelp"
                                                            placeholder=" Enter Send To">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Send Cc

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="SendCc" aria-describedby="emailHelp"
                                                            placeholder=" Enter Send Cc">
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">
                                                            Note

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="Note" aria-describedby="emailHelp"
                                                            placeholder=" Enter Note">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-lg-12  main-submit-button" style="
                                                                               margin-left: -31%;">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="sendQuoteOrderReportMail()">Submit</button>

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="noteQuote" tabindex="-1" role="dialog" aria-labelledby="noteQuote" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Note </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Note</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="noteForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Note Name
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="NoteName"
                                                            placeholder="Enter Note Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Note Info
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="NoteInfo"
                                                            placeholder="Enter Note Info">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12  main-submit-button" style="margin-left: -30%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showNote"
                                                    (click)="createQuoteOrderNote()">Submit</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showNote" (click)="updateQuoteNoteNew()">Update</button>



                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="quoteItem" tabindex="-1" role="dialog" aria-labelledby="quoteItem" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Quote Items
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5New>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Quote Items
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="QuoteItemForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Quote Item Seq ID


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="quoteItemSeqId" placeholder="Enter 
                                                            Quote Item Seq ID	">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">ProductProductId

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="productId" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter ProductProductId"
                                                            [options]="ProductIdArray" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>



                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">ProductFeatures


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="productFeatureId"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            [options]="ProductFeaturesArray"
                                                            placeholder="Enter Product Features" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Deliverable Type ID


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="deliverableTypeId"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Deliverable Type ID " filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quote Skill Type ID


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="skillTypeId"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            [options]="QuoteSkillTypeIdArray"
                                                            placeholder="Enter Quote Skill Type ID" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quote Uom ID


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="uomId" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" [options]="QuoteUomIdArray"
                                                            placeholder="Enter Quote Uom ID" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quote Work Effort ID
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="workEffortId"
                                                            placeholder="Enter Quote Work Effort ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Cust Request ID


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="custRequestId"
                                                            placeholder="Enter Cust Request ID">

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Cust Request Item Seq ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="custRequestItemSeqId"
                                                            placeholder="Enter Cust Request Item Seq ID ">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="quantity"
                                                            placeholder="Enter Quantity">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Selected Amount
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="selectedAmount"
                                                            placeholder="Enter Selected Amount">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quote Unit Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="quoteUnitPrice"
                                                            placeholder="Enter Quote Unit Price">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reserv Start

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="reservStart"
                                                            placeholder="Enter Reserv Start ">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reserv Length

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="reservLength"
                                                            placeholder="Enter Reserv Length">

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reserv Persons

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="reservPersons"
                                                            placeholder="Enter Reserv Persons">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Config ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="configId"
                                                            placeholder="Enter Config ID">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Delivery Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="estimatedDeliveryDate"
                                                            placeholder="Enter Estimated Delivery Date">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Comments

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="comments"
                                                            placeholder="Enter Comments">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Lead Time Days

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="leadTimeDays"
                                                            placeholder="Enter Lead Time Days">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12  main-submit-button" style="margin-left: -17%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createQuoteItem()">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Quote </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonA>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">

                <div class="w3-card-4 classCardPopUp">
                    <div class=" bg-white color-grey">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Update Quote</a></li>




                            </ul>
                        </div>

                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="createQuetoFormService.create_quetos_form"
                                        [formGroup]="createQuetoFormService.create_quetos_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Quote Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" id="exampleInputEmail1"
                                                            class="form-control small-input" formControlName="quoteName"
                                                            placeholder="Enter Quote Name" rows="1">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Quote Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            [options]="quoteTypesArray" formControlName="quoteTypeId"
                                                            optionlabel="label" placeholder="Select Quote Type ID">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1" *ngIf="show">Quote Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" id="exampleInputEmail1"
                                                            formControlName="quoteId" class="form-control small-input"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Quote Id" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            formControlName="partyId" [options]="partyarray"
                                                            placeholder="Select PartyId" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Quote Issue Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input id="exampleInputEmail1" class="small-input" type="date"
                                                            formControlName="quoteIssueDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Status<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            [options]="statusArrays" placeholder="Select Status"
                                                            formControlName="statusId" optionlabel="label">
                                                        </p-dropdown>
                                                        <ng-container
                                                            *ngIf="createQuetoFormService.create_quetos_form.controls['statusId'].invalid && (createQuetoFormService.create_quetos_form.controls['statusId'].dirty || createQuetoFormService.create_quetos_form.controls['statusId'].touched)">
                                                            <p class="validate-field"
                                                                *ngIf="createQuetoFormService.create_quetos_form.controls['statusId'].errors.required">
                                                                Status is required!</p>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Currency<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            [options]="currencyArray" optionlabel="label"
                                                            formControlName="currencyId" placeholder="Select Currency">
                                                        </p-dropdown>

                                                        <ng-container
                                                            *ngIf="createQuetoFormService.create_quetos_form.controls['currencyId'].invalid && (createQuetoFormService.create_quetos_form.controls['currencyId'].dirty || createQuetoFormService.create_quetos_form.controls['currencyId'].touched)">
                                                            <p class="validate-field"
                                                                *ngIf="createQuetoFormService.create_quetos_form.controls['currencyId'].errors.required">
                                                                Currency is required!</p>
                                                        </ng-container>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Product Store<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            [options]="productArray" formControlName="productStoreId"
                                                            placeholder="Enter product store" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Sales Channel</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown styleClass="small-dropdown" filter="true"
                                                            [options]="salesChannelArray" formControlName="salesId"
                                                            placeholder="Enter sales channel" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Valid From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input id="exampleInputEmail1" class="small-input" type="date"
                                                            formControlName="validFrom">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Valid Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input id="exampleInputEmail1" class="small-input" type="date"
                                                            formControlName="validThrough">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <textarea style="font-size: 11px;"
                                                            class="form-control small-input"
                                                            placeholder="Enter Description"
                                                            formControlName="description"
                                                            id="exampleFormControlTextarea1" rows="1"></textarea>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="main-submit-button" style="margin-right: 15%!important;">
                                            <!-- <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button> -->
                                            <button type="submit" *ngIf="show" (click)="onUpdate()"
                                                class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="quoteAttribute" tabindex="-1" role="dialog" aria-labelledby="quoteAttribute"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Quote Attribute</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Quote Attribute</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="attributeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Attribute Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control"
                                                            *ngIf="!this.showAttribute" id="exampleInputEmail1"
                                                            formControlName="attrName" aria-describedby="emailHelp"
                                                            placeholder=" Enter Attribute Name">

                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="attrName" disabled
                                                            *ngIf="this.showAttribute" aria-describedby="emailHelp"
                                                            placeholder=" Enter Attribute Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">


                                                            Attribute Value

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="attrValue" aria-describedby="emailHelp"
                                                            placeholder=" Enter Attribute Value">
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Attribute Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="attrDescription"
                                                            aria-describedby="emailHelp"
                                                            placeholder=" Enter Attribute Description">
                                                    </div>
                                                </div>
                                            </div>



                                        </div>

                                        <div class="col-lg-12  main-submit-button" style="
                                                                               margin-left: -35%;">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                *ngIf="!this.showAttribute"
                                                (click)="createQuoteAttribute()">Create</button>
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                *ngIf="this.showAttribute"
                                                (click)="updateQuoteAttribute()">Update</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="QuoteCoefficient" tabindex="-1" role="dialog" aria-labelledby="QuoteCoefficient"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Edit Quote Coefficients</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Edit Quote Coefficients</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="QuoteCoefficientForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputCA">
                                                        <label for="exampleInputEmail1">
                                                            Coeff. Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="coeffName" aria-describedby="emailHelp"
                                                            placeholder=" Enter Coeff. Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">
                                                            Coeff Value

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="coeffValue" aria-describedby="emailHelp"
                                                            placeholder=" Enter Coeff Value">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-lg-12  main-submit-button" style="
                                                                               margin-left: -29%;">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                *ngIf="!this.showCoefficient"
                                                (click)="createQuoteCoefficient()">Create</button>
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                *ngIf="this.showCoefficient"
                                                (click)="updateQuoteCoefficient()">Update</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="quotePrices" tabindex="-1" role="dialog" aria-labelledby="quotePrices" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Quote Prices
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Quote Prices
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="quoteProfitForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1"> Quote Item Seq ID
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="QuoteItemSeqID"
                                                            placeholder="Enter Quote Item Seq ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">ProductProductId
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="ProductProductId"
                                                            placeholder="Enter ProductProductId">
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Quantity
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Quantity"
                                                            placeholder="Enter Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Average Cost
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="AverageCost"
                                                            placeholder="Enter Average Cost">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1"> Cost to Price
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="CosttoPrice"
                                                            placeholder="Enter Cost to Price">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Default Unit Price
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="DefaultUnitPrice"
                                                            placeholder="Enter	Default Unit Price">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Quote Unit Price
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="QuoteUnitPrice"
                                                            placeholder="Enter Quote Unit Price">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Manual Unit Price
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="ManualUnitPrice"
                                                            placeholder="Enter Manual Unit Price">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Selected
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Selected"
                                                            placeholder="Enter Selected ">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12  main-submit-button" style="margin-left: -27%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="autoUpdateQuotePrices()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="quoteAdjustment" tabindex="-1" role="dialog" aria-labelledby="quoteAdjustment"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Quote Adjustments
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Quote Adjustments
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="quoteAdjustmentsForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="exampleInputEmail1">Quote Adjustment ID
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="QuoteAdjustmentID"
                                                            placeholder="Enter Quote Adjustment ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Quote Adjustment Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="QuoteAdjustmentType"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Quote Adjustment Type"
                                                            [options]="quoteAdjustmentTypeArray" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="exampleInputEmail1">Quote Item Seq ID
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="QuoteItemSeqID"
                                                            placeholder="Enter Quote Item Seq ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Description"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="exampleInputEmail1">Amount
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="Amount"
                                                            placeholder="Enter Amount">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Corresponding Product ID
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="CorrespondingProductID"
                                                            placeholder="Enter Corresponding Product ID">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="exampleInputEmail1">Source Percentage
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="SourcePercentage"
                                                            placeholder="Enter Source Percentage">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Exempt Amount
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="ExemptAmount"
                                                            placeholder="Enter Exempt Amount">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="exampleInputEmail1">Include In Tax
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="IncludeInTax"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Include In Tax" [options]="yesNOArray"
                                                            filter="true" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Include In Shipping
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="IncludeInShipping"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Include In Shipping"
                                                            [options]="yesNOArray" filter="true" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA2">
                                                        <label for="exampleInputEmail1">Last Modified Date
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="LastModifiedDate"
                                                            placeholder="Enter Last Modified Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Last Modified By User
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="LastModifiedByUser"
                                                            placeholder="Enter Last Modified By User">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12  main-submit-button" style="margin-left: -29%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showAdjustment"
                                                    (click)="createQuoteAdjustment()">Submit</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showAdjustment"
                                                    (click)="updateQuoteAdjustment()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="quoteWorkEffort" tabindex="-1" role="dialog" aria-labelledby="quoteWorkEffort"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create Quote Work Efforts
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton11>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Create Quote Work Efforts
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">


                                    <form class="w-100" [formGroup]="addWorkEffort">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Quote ID </label>
                                                </div>

                                                <div class="col-lg-3 form-group ">{{this.quoteId}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Quick Assign Party ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="partyIdArray"
                                                            formControlName="quickAssignPartyID" optionlabel="label"
                                                            placeholder="Enter Quick Assign Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Name<span
                                                                style="color: red;">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="name" aria-describedby="emailHelp"
                                                            placeholder="Enter Name">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Description </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="description" aria-describedby="emailHelp"
                                                            placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Type<span
                                                                style="color: red;">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="type"
                                                            [options]="TypeArray" optionlabel="label"
                                                            placeholder="Enter Type">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Purpose </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="purpose"
                                                            [options]="PurposeArray" optionlabel="label"
                                                            placeholder="Enter Purpose">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Status<span
                                                                style="color: red;">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="status"
                                                            [options]="StatusNewWorkEffortArray" optionlabel="label"
                                                            placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Percent Complete </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="percentComplete"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Percent Complete">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Priority </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="priority"
                                                            [options]="PriorityArray" optionlabel="label"
                                                            placeholder="Enter Priority">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Scope </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="scope"
                                                            [options]="scopeArray" optionlabel="label"
                                                            placeholder="Enter Scope">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Estimated Start Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control"
                                                            formControlName="estimatedStartDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Start Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Estimated Completion Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control"
                                                            formControlName="estimatedCompletionDate"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Completion Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Actual Start Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control"
                                                            formControlName="actualStartDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Actual Start Date">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Actual Completion Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control"
                                                            formControlName="actualCompletionDate"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Actual Completion Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Communication Event ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="communicationEventID"
                                                            [options]="CommunicationEventIdArray" optionlabel="label"
                                                            placeholder="Enter Communication Event ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-8 form-group classInput">
                                                        <label for="exampleInputEmail1"> Enter existing WorkEffort Id
                                                            below
                                                            OR add new WorkEffort data below to create new entity
                                                            (leave WorkEffort Id empty) </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Reason </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="reason" aria-describedby="emailHelp"
                                                            placeholder="Enter Reason">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Web Site ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="webSiteID" aria-describedby="emailHelp"
                                                            placeholder="Enter Web Site ID">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Work Effort Parent Id </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="workEffortParentID"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Work Effort Parent ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Show As Enum ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="showAsEnumID" aria-describedby="emailHelp"
                                                            placeholder="Enter Show As Enum ID">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Send Notification Email
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            formControlName="sendNotificationEmail"
                                                            [options]="yesNOArray" optionlabel="label"
                                                            placeholder="Enter Send Notification Email">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Location Desc </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="locationDesc" aria-describedby="emailHelp"
                                                            placeholder="Enter Location Desc">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Estimated Milli Seconds
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            formControlName="estimatedMilliSeconds" optionlabel="label"
                                                            placeholder="Enter Estimated Milli Seconds">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Estimated Setup Milli Seconds
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            formControlName="estimatedSetupMilliSeconds"
                                                            optionlabel="label"
                                                            placeholder="Enter Estimated Setup Milli Seconds">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Estimated Calc Method </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="estimatedCalcMethod"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Calc Method">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Actual Milli Seconds </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="actualMilliSeconds"
                                                            optionlabel="label"
                                                            placeholder="Enter Actual Milli Seconds">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Actual Setup Milli Seconds
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            formControlName="actualSetupMilliSeconds"
                                                            optionlabel="label"
                                                            placeholder="Enter Actual Setup Milli Seconds">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Total Milli Seconds Allowed
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            formControlName="totalMilliSecondsAllowed"
                                                            optionlabel="label"
                                                            placeholder="Enter Total Milli Seconds Allowed">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Total Money Allowed </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="totalMoneyAllowed"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Total Money Allowed">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Currency </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="currency"
                                                            [options]="CurrencyListArray" optionlabel="label"
                                                            placeholder="Enter Currency">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Special Terms </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="specialTerms" aria-describedby="emailHelp"
                                                            placeholder="Enter Special Terms">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Time Transparency </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="timeTransparency"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Time Transparency">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Universal ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="universalID" aria-describedby="emailHelp"
                                                            placeholder="Enter Universal ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Source Reference ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="sourceReferenceID"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Source Reference ID">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Fixed Asset ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="fixedAssetID" aria-describedby="emailHelp"
                                                            placeholder="Enter Fixed Asset ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Facility Id </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="facilityID"
                                                            [options]="FacilityIdListArray" optionlabel="label"
                                                            placeholder="Enter Facility ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Info URL </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="infoURL" aria-describedby="emailHelp"
                                                            placeholder="Enter Info URL">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Temporal Expression </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="temporalExpression"
                                                            [options]="TemporalExpressionArray" optionlabel="label"
                                                            placeholder="Enter Temporal Expression">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Service Loader Name </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="serviceLoaderName"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Service Loader Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Quantity To Produce </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="quantityToProduce"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Quantity To Produce">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Quantity Produced </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="quantityProduced"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Quantity Produced">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Quantity Rejected </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="quantityRejected"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Quantity Rejected">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Reserv Persons </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="reservPersons" aria-describedby="emailHelp"
                                                            placeholder="Enter Reserv Persons">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Reserv 2nd P P Perc </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="reserv2ndPerc" aria-describedby="emailHelp"
                                                            placeholder="Enter Reserv 2nd P P Perc">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Reserv Nth P P Perc </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="reservNthPerc" aria-describedby="emailHelp"
                                                            placeholder="Enter Reserv Nth P P Perc">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Accommodation Map ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="accommodationMapID"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Accommodation Map ID">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Accommodation Spot ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="accommodationSpotID"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Accommodation Spot ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Sequence Num </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            formControlName="sequenceNum" aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Requirement ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="requirementID"
                                                            [options]="RequirementListArray" optionlabel="label"
                                                            placeholder="Enter Requirement ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="margin-left: -21%;">
                                            <button type="submit" (click)="createQuoteWorkEffort()"
                                                class="btn btn-secondary submit-btn"
                                                *ngIf="!this.showWorkEffort">Save</button>
                                            <button type="submit" (click)="updateQuoteWorkEffort()"
                                                class="btn btn-secondary submit-btn"
                                                *ngIf="this.showWorkEffort">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="quoteRoles" tabindex="-1" role="dialog" aria-labelledby="quoteRoles" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Quote Roles
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton12>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Quote Roles
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="QuoteRoleForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="Party" id="exampleInputEmail1"
                                                            *ngIf="!this.showRole" aria-describedby="emailHelp"
                                                            placeholder="Enter Party" [options]="partyIdArray"
                                                            filter="true" optionlabel="label">
                                                        </p-dropdown>
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="this.showRole"
                                                            formControlName="Party" placeholder="Enter Party">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Role Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="RoleType" id="exampleInputEmail1"
                                                            *ngIf="!this.showRole" aria-describedby="emailHelp"
                                                            placeholder="Enter Role Type" [options]="RoleTypeIdArray"
                                                            filter="true" optionlabel="label">
                                                        </p-dropdown>

                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" *ngIf="this.showRole"
                                                            formControlName="RoleType" placeholder="Enter Role Type">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="FromDate"
                                                            placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Through Date


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="ThroughDate"
                                                            placeholder="Enter Through Date">

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12  main-submit-button" style="margin-left: -30%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showRole"
                                                    (click)="createQuoteOrderRole()">Submit</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showRole"
                                                    (click)="updateQuoteOrderRole()">Update</button>



                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="quoteTerm" tabindex="-1" role="dialog" aria-labelledby="quoteTerm" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Quote Term
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Quote Term
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="QuoteTermForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">
                                                            Term Type ID


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="termTypeId" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter 
                                                        Term Type ID" [options]="TermTypeIDArray" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Quote Item Seq ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="quoteItemSeqId"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Quote Item Seq ID"
                                                            [options]="QuoteItemSeqIDArray" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>



                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Term Value
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="termValue"
                                                            placeholder="Enter Term Value">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">UOM

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="uomId" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter UOM"
                                                            [options]="QuoteUomIdArray" filter="true"
                                                            optionlabel="label">
                                                        </p-dropdown>



                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Term Days


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="termDays"
                                                            placeholder="Enter Term Days">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Text Value
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="textValue"
                                                            placeholder="Enter Text Value">

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="description"
                                                            placeholder="Enter Description">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12  main-submit-button" style="margin-left: -37%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.showTerm"
                                                    (click)="createQuoteOrderTerm()">Submit</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.showTerm"
                                                    (click)="updateQuoteOrderTerm()">Update</button>



                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>