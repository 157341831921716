<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancel()">Feature Type </span>
               <span class="d-flex">
     
                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
              
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">

                        <li *ngIf="this.editMode==false" [ngClass]="activeCategory==2?'active':''"><a>
                                Create Feature Type</a></li>
                        <li *ngIf="this.editMode==true" [ngClass]="activeCategory==2?'active':''"><a>
                                Update Feature Type</a></li>

                    </ul>
                </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="featureTypeForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Product Feature Type ID</label>
                                            </div>
                                         
                                        <div class="col-lg-2">
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                            formControlName="productFeatureTypeId" aria-describedby="emailHelp"
                                            placeholder="Enter Product Feature Type ID">
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">Product Parent Type
                                        </label>
                                    </div>
                                 
                                <div class="col-lg-2">
                                    <p-dropdown [options]="parentType" formControlName="parentTypeId" filter="true"
                                    placeholder="Product Parent Type"></p-dropdown>
                            </div>
                                    </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                          
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Description<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                        <div class="col-lg-2">
                                            <textarea class="form-control" id="exampleFormControlTextarea1"
                                            formControlName="description" rows="1"
                                            placeholder="Enter Description"></textarea>
                                    </div>
                                    </div>
                                    </div>
                                 
                              
                            </div>
                                <div class="main-submit-button" style="margin-right: 31%;">
                                    <button type="submit" class="btn btn-secondary submit-btn"
                                        (click)="submit()">{{editMode ?'Update':'Create'}}</button>&nbsp;
                        
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>

            </div>

        </div>
       
    </div>
</div>
<ngx-spinner></ngx-spinner>