import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoices-service-items',
  templateUrl: './invoices-service-items.component.html',
  styleUrls: ['./invoices-service-items.component.css']
})
export class InvoicesServiceItemsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
