<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of Mr. THE PRIVILEGED ADMINISTRATOR<br>
                    [admin]<br>
                    Update User Login Security Settings - ttest</span>

                    <span class="d-flex">

                        <button type="submit" (click)="homeButton();"
                            class=" btn btn-outline-secondary mr-2">Home</button>
                
                        <button type="submit" (click)="cancelSubmit();"
                            class="btn btn-danger buttonclass ">Back</button>
                    </span>
                
            </div>

            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="w3-card-4 classCardA ">

                                            <div class="container-fluid">
                                                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                                                    <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                                            Profile

                                                        </a></li>
                                                    <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                                        <a>
                                                            Preferences
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                                        <a>
                                                            Role(s)</a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                                        <a>
                                                            Projects
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)">
                                                        <a>
                                                            Tasks
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)">
                                                        <a>
                                                            Rates
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)">
                                                        <a>
                                                            Revisions
                                                        </a>
                                                    </li>


                                                </ul>
                                            </div>
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div class="create-new-leade ">
                                                    <div class="container-fluid">


                                                        <div class="panel-group">
                                                            <div *ngIf="activeCategory==1">
                                                                <div class="w3-card-4 classCardView mt-2">





                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">




                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Enabled
                                                                                        

                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">

                                                                                    <p-dropdown name="Enabled" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Enabled" filter="true" optionlabel="label">
                                                                                    </p-dropdown>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                    <label for="productId">Disabled Date Time
                                                                                        


                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="DisabledDateTime" placeholder="Enter Disabled Date Time">

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Successive Failed Logins
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="SuccessiveFailedLogins" aria-describedby="emailHelp" placeholder="Enter Successive Failed Logins">

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">External Auth ID
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="ExternalAuthID" aria-describedby="emailHelp" placeholder="Enter External Auth ID">

                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Require Password Change
                                                                                        

                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">

                                                                                    <p-dropdown name="RequirePasswordChange" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Require Password Change" filter="true" optionlabel="label">
                                                                                    </p-dropdown>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Disabled By
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="DisabledBy" aria-describedby="emailHelp" placeholder="Enter Disabled By">

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12  main-submit-button" style="margin-left: -63%;">
                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Save</button>&nbsp;
                                                                        </div>
                                                                        <br>
                                                                        <div class="col-lg-12  main-submit-button" style="margin-left: -87%;">
                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Cancel/Done</button>&nbsp;
                                                                        </div>






                                                                    </div>






                                                                </div>
                                                                <div class="w3-card-4 classCardView mt-2">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling hleft">
                                                                            Change User Login Password
                                                                        </h4>
                                                                    </div>




                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Current Password
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="CurrentPassword" aria-describedby="emailHelp" placeholder="Enter Current Password">

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">New Password
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="NewPassword" aria-describedby="emailHelp" placeholder="Enter New Password">

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Verify New Password
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="VerifyNewPassword" aria-describedby="emailHelp" placeholder="Enter Verify New Password">

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Password Hint
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="PasswordHint" aria-describedby="emailHelp" placeholder="Enter Password Hint">

                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Require Password Change
                                                                                        

                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">

                                                                                    <p-dropdown name="RequirePasswordChange" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Require Password Change" filter="true" optionlabel="label">
                                                                                    </p-dropdown>

                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-12  main-submit-button" style="margin-left: -63%;">
                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Save</button>&nbsp;
                                                                        </div>
                                                                        <br>
                                                                        <div class="col-lg-12  main-submit-button" style="margin-left: -87%;">
                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Cancel/Done</button>&nbsp;
                                                                        </div>






                                                                    </div>






                                                                </div>

                                                            </div>






                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>