import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PublicHolidayModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
      descriptionHoliday:(data && data.descriptionHoliday) ? data.descriptionHoliday : null,
        fromDate:(data && data.fromDate) ? data.fromDate : null,
        holidayName:(data && data.holidayName) ? data.holidayName : null,
    });

    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    descriptionHoliday: new FormControl(data.descriptionHoliday,[Validators.required]),
    fromDate: new FormControl(data.fromDate),
    holidayName: new FormControl(data.holidayName,[Validators.required]),

   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
