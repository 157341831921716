import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreatePaymentServices } from 'src/app/accounting/payments/create-payments/create-payment.service';
import { SortEvent } from 'primeng/api';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { BillingAccountService } from 'src/app/accounting/accounting-ap/billing-account/billing-account.service';
import * as moment from 'moment';
import { AddLeaveEmpService } from 'src/app/human-resource/leave/add-new-leaves/add-new-leaves.service';
import { ToastrService } from 'ngx-toastr';
import { PayablePaymentService } from 'src/app/accounting/accounting-ap/payables-payment/payables-payment.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
@Component({
  selector: 'app-detail-payment-portal-summary',
  templateUrl: './detail-payment-portal-summary.component.html',
  styleUrls: ['./detail-payment-portal-summary.component.css']
})
export class DetailPaymentPortalSummaryComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  amount: any;
  effectiveDate: any;
  partyIdTo: any;
  partyIdFrom: any;
  comments: any;
  statusIdWithDescription: any;
  description: any;
  paymentId: any;
  activeCategory = 1;
  activeTab: number = 2;
  activepayTab: number = 1;
  activeCategories = 2;
  outgoingPaymentForm: FormGroup;
  incomingPaymentForm: FormGroup;
  paymentDetail: any;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search = ""
  updateHeadeForm: FormGroup;
  applicationsForm: FormGroup;
  updateBillingAccountForm: FormGroup;
  updateItemsForm: FormGroup;
  addTermsForm: FormGroup;
  addRolesForm: FormGroup;
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  finAccountIdrray: any[] = [];
  sendPerEmailForm: FormGroup;
  allStatusList: any = []
  findForm: FormGroup;
  newSalesInvoiceForm: FormGroup;
  updateHeadeOverViewForm: FormGroup;
  updateFinancialAccountTransForm: FormGroup;
  partyId: any;
  finAccountId: any;
  partyIdArray: any[] = [];
  GIAccounts: any[] = [];
  paymentMethodId: any;
  paymentMethodIdrray: any[] = [];
  statusIds: any[];
  invoiceTypes: any[];

  getPurchaseInvoicesData: any;
  paymentTypeList: any[] = [];
  invoices: any;
  PaymentDetailsById: any;
  statusIdCheck: any;
  PaymentsList: any;
  getPaymentsListArray: any;
  allPaymentTypeList: any = [];
  allFinAccountIdList: any = [];
  PaymentIDArray: any = [];
  PaymentApplication: any;
  PaymentID: any;
  invoiceID: any;
  actualfromdate: string;
  throughtDATE: string;
  allpaymentMethodId: any = [];
  paymentTypeArray: any[] = [];
  PaymentTypeOutgoingList: any[] = [];
  invoiceIDArray: any[] = [];
  CatalogGeoIdArray: any[] = [];
  currencyUomIds: any[];
  PaymentApplicationList: any;
  createBillingAccount: FormGroup;
  CatalogGeoId: any;
  invoiceApplicationList: any;
  billingAccount: any;
  paymentApplicationList: any;
  financialAccountTransaction: any;
  transactionList: any;
  billingAccountDrop: any;
  billingAccountDroparray: any[] = [];
  constructor(
    readonly addLeaveEmpService: AddLeaveEmpService,
    readonly activatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly mergeContactsService: MergeContactsService,
    readonly _ToastrService: ToastrService,
    readonly datePipe: DatePipe,
    private _location: Location,
    readonly router: Router,
    readonly _AccountingApService: AccountingApService,
    readonly _PayablePaymentService: PayablePaymentService,
    readonly _BillingAccountService: BillingAccountService,
    readonly createPaymentService: CreatePaymentServices,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _FormBuilder: FormBuilder,
    readonly accountsService: AccountsService) {
    this.invoiceTypes = [];
    this.statusIds = [];

    this.incomingPaymentForm = this._FormBuilder.group({
      orgPartyId: [''],
      fromPartyID: [''],
      paymentType: [''],
      paymentMethodId: [''],
      refNo: [''],
      overrideAccountId: [''],
      Amount: [''],
      Comments: [''],
      FinAccountId: [''],
    })

    this.outgoingPaymentForm = this._FormBuilder.group({
      OutorgPartyId: [''],
      OutfromPartyID: [''],
      OutpaymentType: [''],
      OutpaymentMethodId: [''],
      OutrefNo: [''],
      OutoverrideAccountId: [''],
      OutAmount: [''],
      OutComments: [''],
      OutFinAccountId: [''],
    })

    this.updateHeadeForm = this._FormBuilder.group({
      paymentType: [''],
      statusID: [''],
      paymentMethodID: [''],
      fromPartyID: [''],
      toPartyID: [''],
      amount: [''],
      currency: [''],
      actualCurrencyAmount: [''],
      actualCurrencyUomID: [''],
      effectiveDate: [''],
      referenceNo: [''],
      comments: [''],
      finAccountTransID: [''],
      overrideGlAccountID: [''],

    })

    this.applicationsForm = this._FormBuilder.group({
      invoiceID: [''],
      topaymentID: [''],
      billingAccountID: [''],
      taxAuthGeoID: [''],
      outstandingAmount: [''],
    })

    this.updateBillingAccountForm = this._FormBuilder.group({
      billingAccountID: [''],
      accountLimit: [''],
      accountCurrencyUomID: [''],
      contactMechID: [''],
      fromDate: [''],
      throughDate: [''],
      description: [''],
      externalAccountID: [''],
      partyID: [''],
      availableBalance: [''],
    });

    this.updateItemsForm = this._FormBuilder.group({
      invoiceItemType: [''],
      description: [''],
      overrideGlAccountID: [''],
      inventoryItemID: [''],
      productID: [''],
      productFeatureID: [''],
      quantity: [''],
      UOM: [''],
      unitPrice: [''],
      taxableFlag: [''],
    });

    this.addRolesForm = this._FormBuilder.group({
      partyID: [''],
      roleTypeID: [''],
      dateTimePerformed: [''],
      percentage: [''],


    });

    this.addTermsForm = this._FormBuilder.group({
      invoiceTermID: [''],
      termTypeID: [''],
      itemNo: [''],
      termValue: [''],
      termDays: [''],
      textValue: [''],
      description: [''],
      Uom: [''],

    });

    this.sendPerEmailForm = this._FormBuilder.group({
      fromEmailAddress: [''],
      toEmailAddress: [''],
      copyEmailAddress: [''],
      subject: [''],
      otherCurrency: [''],
      emailBody: [''],

    });

    this.findForm = this._FormBuilder.group({
      fromPartyId: [''],
      toPartyId: [''],
      amount: [''],
      status: [''],
      comments: [''],
      paymentId: [''],
      paymentType: [''],

    });

    this.newSalesInvoiceForm = this._FormBuilder.group({
      invoiceType: [''],
      organizationPartyID: [''],
      toPartyID: [''],
    });

    this.updateHeadeOverViewForm = this._FormBuilder.group({
      invoiceType: [''],
      status: [''],
      description: [''],
      invoiceMessage: [''],
      fromPartyID: [''],
      toPartyID: [''],
      roleTypeID: [''],
      billingAccountID: [''],
      invoiceDate: [''],
      dueDate: [''],
      total: [''],
      datePaid: [''],
      referenceNum: [''],

    })

    this.updateFinancialAccountTransForm = this._FormBuilder.group({
      finAccountTransID: [''],
      finAccountTransType: [''],
      amount: [''],
      GIReconciliationID: [''],
      transactionDate: [''],
      status: [''],
    })
    this.createBillingAccount = this._FormBuilder.group({
      accountLimit: [''],
      accountCurrencyUomId: [''],
      contactMechId: [''],
      fromDate: [''],
      thruDate: [''],
      description: [''],
      externalAccountId: [''],
      partyId: [''],
      AvailableBalance: [''],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => [
      this.paymentId = params["paymentId"],
      this.description = params["description"],
      this.statusIdWithDescription = params["statusIdWithDescription"],
      this.comments = params["comments"],
      this.partyIdFrom = params["partyIdFrom"],
      this.partyIdTo = params["partyIdTo"],
      this.effectiveDate = params["effectiveDate"],
      this.amount = params["amount"],
    ])
    this.getPartyId();
    this.getPaymentsById();
    this.getPaymentType();
    this.getPaymentsList();
    this.getPaymentTypes();
    this.getPaymentTypeOutgoing();
    this.getpaymentApplicationList();
    this.getFinAccountId();
    this.getGIAccounts();
    this.getPaymentMethodId();
    this.getCurrency();
    this.getPaymentApplicationList();
    this.getInvoiceApplicationList();
    this.getInvoiceId();
    this.getCatalogGeoId();
    this.getPaymentID();
    this.EditBillingAccountDrop();
  }
  homeButton() {
    this.router.navigate(['/myPortal/my-portal-my-profile'])

  }
  cancelSubmit() {
    this._location.back();
  }


  EditBillingAccountDrop() {
    this.spinner.show();
    this.createPaymentService.EditBillingAccountDrop().subscribe(res => {
      this.billingAccountDrop = res.data[0].temporalExpression;
      this.spinner.hide();
      for (const value of this.billingAccountDrop) {
        this.billingAccountDroparray.push({
          label: value.billingAccountId,
          value: value.billingAccountId
        })
      }
    })
  }
  invoicePage(invoiceId) {
    this.router.navigate(['/myPortal/my-portal-create-invoice'], { queryParams: { invoiceId: invoiceId } });
  }

  applyApplicationPayment() {
    this.spinner.show();
    const requestData = {
      "amountApplied": this.applicationsForm.value.outstandingAmount,
      "billingAccountId": this.applicationsForm.value.billingAccountID,
      "invoiceId": this.applicationsForm.value.invoiceID,
      "paymentId": this.paymentId,
      "taxAuthGeoId": this.applicationsForm.value.taxAuthGeoID,
      "toPaymentId": this.applicationsForm.value.topaymentID,
    }

    this.createPaymentService.applyApplicationPayment(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.applicationsForm.reset();
        this.spinner.hide();
        this.getPaymentApplicationList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  getBillingAccountById(product) {
    this.spinner.show();
    this._BillingAccountService.getBillingAccountById(product.billingAccountId)
      .then(data => {
        this.billingAccount = data.data;
        this.createBillingAccount.patchValue({
          ...data.data, ...{
            fromDate: this.datePipe.transform(data.data.fromDate, "yyyy-MM-dd"),
            thruDate: this.datePipe.transform(data.data.thruDate, "yyyy-MM-dd"),
            accountLimit: data.data.accountLimit,
            accountCurrencyUomId: data.data.accountCurrencyUomId,
            contactMechId: data.data.contactMechId,
            description: data.data.description,
            externalAccountId: "",
            partyId: data.data.partyId,
            AvailableBalance: data.data.availableBalance,
          }
        });
      });
    this.spinner.hide();

  }

  updateBillingAccount() {
    this.spinner.show();
    const fromActualdate = this.createBillingAccount.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    const actualthruDate = this.createBillingAccount.get('thruDate').value;
    this.throughtDATE = this.datePipe.transform(actualthruDate, "yyyy-MM-dd hh:mm:ss");
    const fromData = {

      "accountCurrencyUomId": this.createBillingAccount.value.accountCurrencyUomId,
      "accountLimit": this.createBillingAccount.value.accountLimit,
      "availableBalance": this.createBillingAccount.value.AvailableBalance,
      "contactMechId": this.createBillingAccount.value.contactMechId,
      "description": this.createBillingAccount.value.description,
      "externalAccountId": this.createBillingAccount.value.externalAccountId,
      "fromDate": this.actualfromdate,
      "partyId": this.paymentId,
      "thruDate": this.throughtDATE
    }
    this.createPaymentService.updateBillingAccount(fromData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  getCatalogGeoId() {
    this.spinner.show();
    this.accountsService.getCatalogGeoId().subscribe(res => {
      this.CatalogGeoId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CatalogGeoId) {
        this.CatalogGeoIdArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
  }
  getPaymentID() {
    this.spinner.show();
    this.accountsService.getPaymentID().subscribe(res => {
      this.PaymentID = res.data;
      this.spinner.hide();

      for (const value of this.PaymentID) {
        this.PaymentIDArray.push({
          label: value.data.paymentId,
          value: value.data.paymentId
        })
      }
    })
  }
  getInvoiceId() {
    this.spinner.show();
    this.createPaymentService.getInvoiceId().subscribe(res => {
      this.invoiceID = res.data[0].Invoice;
      this.spinner.hide();
      for (const value of this.invoiceID) {
        this.invoiceIDArray.push({
          label: value.invoiceId,
          value: value.invoiceId
        })
      }
    })
  }
  removePaymentApplication(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "amountApplied": product.amountApplied,
          "invoiceId": product.invoiceId,
          "paymentApplicationId": product.paymentApplicationId,
          "paymentId": this.paymentId
        }
        this.createPaymentService.removePaymentApplication(req).subscribe(res => {
          if (res.success) {

            this.getPaymentApplicationList();
          }
        }, (err) => {
          this._ToastrService.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  paymentPage(paymentId) {
    this.router.navigate(['/myPortal/detail-payment-portal-summary'],
      { queryParams: { paymentId: paymentId } });
  }
  getpaymentApplicationList() {
    this.spinner.show();
    this.mergeContactsService.getPaymentDetailsById(this.paymentId).subscribe(res => {
      this.paymentApplicationList = res.data[0].paymentApplicationList;
      this.financialAccountTransaction = res.data[0].financialAccountTransaction;
      this.transactionList = res.data[0].transactionList;
      this.spinner.hide();
    })
  }

  generateChecksPDFNew() {
    this.spinner.show();
    this.createPaymentService.generateChecksPDFNew(this.paymentId).subscribe(res => {
      const blob = new Blob([res], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'printChecks';
      this.spinner.hide();
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

    this.spinner.show();


  }
  getPaymentApplicationList() {
    this.spinner.show();
    this.createPaymentService.getPaymentApplicationList(this.paymentId).subscribe(res => {
      this.PaymentApplicationList = res.data.applications;
      this.PaymentApplication = res.data;
      this.spinner.hide();

    })
  }
  getInvoiceApplicationList() {
    this.spinner.show();
    this.createPaymentService.getInvoiceApplicationList(this.paymentId).subscribe(res => {
      this.invoiceApplicationList = res.data.invoiceApplications;
      this.spinner.hide();

    })
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        this.spinner.hide();
        this.currencyUomIds = data.data.currencyList.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });

  }
  getPaymentMethodId() {
    this.spinner.show();
    this.createPaymentService.getPaymentMethodId().subscribe(res => {
      this.paymentMethodId = res.data;
      this.spinner.hide();
      for (const value of this.paymentMethodId) {
        this.paymentMethodIdrray.push({
          label: value.paymentMethodId,
          value: value.paymentMethodId
        })
      }
    })
  }
  getGIAccounts(): void {
    this.spinner.show();
    this._PayablePaymentService.getGIAccountAndPaymentMethod()
      .then(data => {
        if (data) {
          this.GIAccounts = data.data.glAccounts.map(value => {
            return {
              label: value.glAccountId,
              value: value.glAccountId
            };
          });
        }
      });
    this.spinner.hide();
  }
  getFinAccountId() {
    this.spinner.show();
    this.createPaymentService.getFinAccountId().subscribe(res => {
      this.finAccountId = res.data;
      this.spinner.hide();
      for (const value of this.finAccountId) {
        this.finAccountIdrray.push({
          label: value.finAccountName,
          value: value.finAccountId
        })
      }
    })
  }

  updatePayment() {
    this.spinner.show();
    const req = {
      "actualCurrencyAmount": this.updateHeadeForm.value.actualCurrencyAmount,
      "actualCurrencyUomId": this.updateHeadeForm.value.actualCurrencyUomID,
      "amount": this.updateHeadeForm.value.amount,
      "comments": this.updateHeadeForm.value.comments,
      "currencyUomId": this.updateHeadeForm.value.currency,
      "effectiveDate": this.datePipe.transform(this.updateHeadeForm.value.effectiveDate, "yyyy-MM-dd HH:mm:ss"),
      "finAccountTransId": this.updateHeadeForm.value.finAccountTransID,
      "overrideGlAccountId": this.updateHeadeForm.value.overrideGlAccountID,
      "partyIdFrom": this.updateHeadeForm.value.fromPartyID,
      "partyIdTo": this.updateHeadeForm.value.toPartyID,
      "paymentId": this.paymentId,
      "paymentMethodId": this.updateHeadeForm.value.paymentMethodID,
      "paymentRefNum": this.updateHeadeForm.value.referenceNo,
      "paymentTypeId": this.updateHeadeForm.value.paymentType,
      "statusId": this.updateHeadeForm.value.statusID,
    }
    this.createPaymentService.updatePayment(req).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.getPaymentsById();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }

  getPaymentTypeOutgoing() {
    this.spinner.show();
    this.createPaymentService.getPaymentTypeOutgoing().subscribe(res => {
      if (res.success) {
        const PaymentTypeOutgoing = res.data;
        for (const value of PaymentTypeOutgoing) {
          this.PaymentTypeOutgoingList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }
      }

    }, (err) => {
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  getPaymentTypes() {
    this.spinner.show();
    this.createPaymentService.getPaymentType().subscribe(res => {
      if (res.success) {
        const allPaymentType = res.data.paymentType;
        const allStatusList = res.data.status;
        const finAccountIdList = res.data.finAccountId;
        for (const value of allPaymentType) {
          this.allPaymentTypeList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }

        for (const value of finAccountIdList) {
          this.allFinAccountIdList.push({
            label: value.finAccountName,
            value: value.finAccountId
          })
        }
        for (const value of allStatusList) {
          this.allStatusList.push({
            label: value.description,
            value: value.statusId
          })
        }

      }

    }, (err) => {
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  createPayment() {
    this.router.navigate(["/myPortal/my-portal-create-payments"])
  }
  getPaymentType(): void {
    this.spinner.show();
    this._PayablePaymentService.getPaymentType()
      .then(data => {
        if (data) {
          this.spinner.hide();
          this.paymentTypeList = data.data.map(value => {
            return {
              label: value.description,
              value: value.paymentTypeId
            };
          });
        }
      });

  }
  resetPayment() {
    this.findForm.reset();
    this.getPaymentsList();
  }
  findPaymentsList() {
    this.spinner.show();

    const req = {
      "amount": this.findForm.value.amount ? this.findForm.value.amount : "",
      "comments": this.findForm.value.comments ? this.findForm.value.comments : "",
      "commentsSearchType": "Contains",
      "fromPartyId": this.findForm.value.fromPartyId ? this.findForm.value.fromPartyId : "",
      "paymentId": this.findForm.value.paymentId ? this.findForm.value.paymentId : "",
      "paymentIdSearchType": "Contains",
      "paymentType": this.findForm.value.paymentType ? this.findForm.value.paymentType : "",
      "status": this.findForm.value.status ? this.findForm.value.status : "",
      "toPartyId": this.findForm.value.toPartyId ? this.findForm.value.toPartyId : ""
    }
    this.accountsService.getPaymentsList(this.finSize, req).subscribe((res: any) => {
      this.PaymentsList = res.data;
      this.spinner.hide();

    })
  }
  getPaymentsList() {
    this.spinner.show();
    const req = {
      "amount": "",
      "comments": "",
      "commentsSearchType": "Contains",
      "fromPartyId": "",
      "paymentId": "",
      "paymentIdSearchType": "Contains",
      "paymentType": "",
      "status": "",
      "toPartyId": ""
    }
    this.accountsService.getPaymentsList(this.finSize, req).subscribe((res: any) => {
      this.PaymentsList = res.data;
      this.spinner.hide();

    })
  }
  getPaymentsById() {
    this.spinner.show();
    this.mergeContactsService.getPaymentDetailsById(this.paymentId).subscribe(res => {
      this.PaymentDetailsById = res.data[0];
      this.statusIdCheck = res.data[0].statusId;

      setTimeout(() => {
        const formValue = this.updateHeadeForm;
        formValue.patchValue({
          paymentType: this.PaymentDetailsById.paymentTypeId,
          statusID: this.PaymentDetailsById.statusId,
          paymentMethodID: this.PaymentDetailsById.paymentMethodId,
          fromPartyID: this.PaymentDetailsById.partyIdFrom,
          toPartyID: this.PaymentDetailsById.partyIdTo,
          amount: this.PaymentDetailsById.amount,
          currency: this.PaymentDetailsById.currencyUomId,
          actualCurrencyAmount: this.PaymentDetailsById.actualCurrencyAmount,
          actualCurrencyUomID: "",
          effectiveDate: this.datePipe.transform(this.PaymentDetailsById.effectiveDate, "yyyy-MM-dd"),
          referenceNo: this.PaymentDetailsById.paymentRefNum,
          comments: this.PaymentDetailsById.comments,
          finAccountTransID: "",
          overrideGlAccountID: this.PaymentDetailsById.overrideGlAccountId,
        })

      }, 2000);

      this.spinner.hide();
    })
  }

  resetPerInvoice() {
    this.findForm.reset();
    //  this.findPurchaseInvoices();
  }
  setPaymentStatusWriteoff(): void {
    this.spinner.show();

    this.mergeContactsService.setPaymentStatus(this.paymentId, "INVOICE_WRITEOFF").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
      }
    });

  }
  setPaymentStatusVoid(): void {
    this.spinner.show();

    this.mergeContactsService.setPaymentStatus(this.paymentId, "PMNT_VOID").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
      }
    });

  }
  setPaymentStatusCancel(): void {
    this.spinner.show();

    this.mergeContactsService.setPaymentStatus(this.paymentId, "PMNT_CANCELLED").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
      }
    });

  }
  setPaymentStatusSent(): void {
    this.spinner.show();

    this.mergeContactsService.setPaymentStatus(this.paymentId, "PMNT_SENT").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
      }
    });

  }


  getPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }


  changeactiveCategoryFunction(tab: number) {
    this.activeTab = tab;
  }
  changeactivepaymentTab(tab: number) {
    this.activepayTab = tab
  }

  changeactiveCategory(tab: number) {
    this.activeCategories = tab

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}