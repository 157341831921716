import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {Router,ActivatedRoute} from "@angular/router";
import {AccountsService} from "../../crm/accounts/accounts.service";
import {MyContactsService} from "../../crm/contacts/my-contacts/my-contacts.service";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-related-account',
  templateUrl: './add-related-account.component.html',
  styleUrls: ['./add-related-account.component.css']
})
export class AddRelatedAccountComponent implements OnInit {

  fileUploadForm: FormGroup;
  accountData: any;
  accountArray: any=[];
  partyId: any;
  activeCategory=1;
  groupNameSearchType:any='Contains'
  contactMechTypeId:any='';
  groupName:any='';
  partyIds: any='';
  partyIdSearchType: any='Contains';
  isAdditionalDetail: boolean;
  isJobDetail: boolean;
  contactInformation: any[] = [];
  relatedContact: any = [];
  addr: string;
  emailId: any;
  phoneNumber: string;
  accountDetails: any;
  accountName: any;
  @Output() closeAccModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;
  constructor(readonly formBuilder:FormBuilder,
    readonly router:Router,
    private _location: Location,
    readonly activatedRoute:ActivatedRoute,
    readonly MyContactsService:MyContactsService,
    readonly accountsService:AccountsService,
    readonly spinner: NgxSpinnerService,
    readonly toastr:ToastrService,
    public bsModalRef: BsModalRef
    ) { }
 
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["party"];
    });
    this.fileUploadForm = this.formBuilder.group({
      accountPartyId: [''],
      comments:[''],
      contactPartyId:[''],
      partyId:['']
    });
    this.getAccountList();
  }
  
  getAccountList(){
    this.spinner.show();
    const searchAccountForm ={
      contactMechTypeId: this.contactMechTypeId,
      groupName: this.groupName,
      groupNameSearchType: this.groupNameSearchType,
      partyId: this.partyIds,
      partyIdSearchType: this.partyIdSearchType
    }
    const all = "true"
    this.accountsService.getAccountList(searchAccountForm,all).subscribe(res=> {
      this.accountData = res.data;
      this.spinner.hide();
      for(const value of this.accountData){
        this.accountArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
      
    })
    
  }

  onSubmit() { 
    this.spinner.show();
    this.fileUploadForm.get('contactPartyId').patchValue(this.partyId);
    this.fileUploadForm.get('partyId').patchValue(this.partyId);
    const formData = new FormData();
    const formdata = {...this.fileUploadForm.value};
    this.MyContactsService.RelatedAccount(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
    
    }

  cancelSubmit(){
    this.modalClose();
    this._location.back();
  }

  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.closeAccModal.emit();
  }

}
