<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Party Content
                </span>
                <span class="color-black pl-1" > >Attach Content
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"><a>
                        Attach Content
                    </a></li>
                    
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="Editform">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Attach Content</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input class="form-control" type="file" style="width: 100%!important;" id="exampleInputEmail1" formControlName="file"
                                            aria-describedby="emailHelp"  accept="" (change)="fileProgress($event)" style="line-height: 0.9!important;"> 
                                            </div>
                                            <div class="col-lg-2">
                                            <p-dropdown  filter="true" formControlName="partyContentTypeId"
                                            [(ngModel)]="partyContentTypeId" name="partyContentTypeId"
                                            (ngModelChange)="onpartyContent(partyContentTypeId)" [options]="partyContentArray"
                                            optionlabel="label">
                                            </p-dropdown>
                                        </div>
                                            <div class="col-lg-3 form-group classInput" style="display: none;">
                                                <label [ngStyle]="{'visibility':'hidden'}" for="exampleInputEmail1">
                                                    Purpose</label>
                                            </div>
                                         
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown  filter="true" formControlName="partyContentTypeId"
                                                [(ngModel)]="partyContentTypeId" name="partyContentTypeId"
                                                (ngModelChange)="onpartyContent(partyContentTypeId)" [options]="partyContentArray"
                                                optionlabel="label">
                                                </p-dropdown>
                                           
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                 
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 40%;">
                               
                                <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Upload</button>
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->

<div class="modal-header">
    <span class="color-black pl-1">Attach Content</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
 
 <div class="row p-15">
    <div class="col-12">
        <div class=" bg-white color-grey">
            <div class="w3-card-4 classCard">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">
        
                    <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Attach Content
                </a></li>
                    
                
                </ul>
                </div>
            
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="Editform">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-4 form-group classInput">
                                                <label for="exampleInputEmail1">Attach Content</label>
                                            </div>

                                            <div class="col-lg-3">
                                                <input class="form-control" type="file"
                                                    style="width: 100%!important;" id="exampleInputEmail1"
                                                    formControlName="file" aria-describedby="emailHelp"
                                                     accept="" (change)="fileProgress($event)"
                                                    style="line-height: 0.9!important;">
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown filter="true" formControlName="partyContentTypeId"
                                                    [(ngModel)]="partyContentTypeId" name="partyContentTypeId"
                                                    (ngModelChange)="onpartyContent(partyContentTypeId)"
                                                    [options]="partyContentArray" optionlabel="label">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInput" style="display: none;">
                                                <label [ngStyle]="{'visibility':'hidden'}"
                                                    for="exampleInputEmail1">
                                                    Purpose</label>
                                            </div>

                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown filter="true" formControlName="partyContentTypeId"
                                                    [(ngModel)]="partyContentTypeId" name="partyContentTypeId"
                                                    (ngModelChange)="onpartyContent(partyContentTypeId)"
                                                    [options]="partyContentArray" optionlabel="label">
                                                </p-dropdown>

                                            </div>



                                        </div>
                                    </div>

                                </div>


                            </form>
                            <div class="main-submit-button" style="margin-left: -29%;">

                                <button type="submit" (click)="onSubmit();"
                                    class="btn btn-secondary submit-btn">Upload</button>




                            </div>
                        </div>
                    </div>

                </div>
                

           
            </div>

        </div>
        
    </div>
</div>