import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        username:(data && data.username) ? data.username : null,
        password:(data && data.password) ? data.password : null,
        userTenantId:(data && data.userTenantId) ? data.userTenantId : null,
       
    });

    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    username: new FormControl(data.username,[Validators.required]),
    userTenantId: new FormControl(data.userTenantId),
    password: new FormControl(data.password,[Validators.required,Validators.maxLength(12),Validators.minLength(5)]),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
