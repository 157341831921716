import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FeaturesService } from '../features.service';
import { DatePipe } from '@angular/common'; import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-update-application',
  templateUrl: './update-application.component.html',
  styleUrls: ['./update-application.component.css']
})
export class UpdateApplicationComponent implements OnInit {
  featureGroupId: any;
  featureId: any;
  date: any;
  featureTypeForm: FormGroup;
  sequenceNum: any;
  url: string;
  constructor(
    readonly _FormBuilder: FormBuilder,
    readonly _FeaturesService: FeaturesService,
      private _location: Location,
      readonly _ActivatedRoute: ActivatedRoute,
    readonly  _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _ToastrService: ToastrService,
    readonly datePipe: DatePipe,
  ) {
    this.url='/facilities/catalog/feature-group/detailed-summary-feature-group'
    this.featureTypeForm = this._FormBuilder.group({
      fromDate: [''],
      productFeatureId: [''],
      sequenceNum: ['']
    });
   }

  ngOnInit(): void {
    
    this.featureGroupId = this._ActivatedRoute.snapshot.queryParams.featureId;
    this.featureId = this._ActivatedRoute.snapshot.queryParams.id;
    this.date = this._ActivatedRoute.snapshot.queryParams.date;
    this.sequenceNum = this._ActivatedRoute.snapshot.queryParams.sequenceNum;
    const dateFrom=this.datePipe.transform(this.date,"yyyy-MM-dd");
    setTimeout(() => {
      const formValue = this.featureTypeForm;
    formValue.patchValue({
      productFeatureId: this.featureId ,
      fromDate: dateFrom,
      sequenceNum:this.sequenceNum
     
     
    })
    }, 1000);
  
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  submit(): void {
    this.spinner.show();
      this._FeaturesService.updateProductEmp({
        ...this.featureTypeForm.value, ...{
            fromDate:this.date,
        
        }
    },this.featureGroupId)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.featureTypeForm.reset();
            this._Router.navigate([this.url],{ queryParams: {featureGroupId: this.featureGroupId}});
           
          }
        });
        this.spinner.hide();
    } 
    reset(){
      this._Router.navigate([this.url],{ queryParams: {featureGroupId: this.featureGroupId}});
           
    }
}
