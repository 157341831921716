<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Actual Materials


                </span> 
                <span class="color-black pl-1"> >Add New Actual Materials



                </span>

                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>

            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Add Actual Materials



                                </a></li>



                        </ul>
                    </div>


                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="createJob">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Task ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Task ID" filter="true"
                                                    formControlName="TaskID"
                                                    [options]="routingTaskListArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Product ID<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Product ID" filter="true"
                                                    formControlName="productId"
                                                    [options]="productListArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                     
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        From Date<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter From Date"
                                                    formControlName="FromDate">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Quantity<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                    formControlName="Quantity">
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Fail If Items Are Not Available</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Fail If Items Are Not Available"
                                                    formControlName="FailIfItemsAreNotAvailable">

                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Fail If Items Are Not On Hand</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Fail If Items Are Not On Hand"
                                                    formControlName="FailIfItemsAreNotOnHand">
                                                </div>
                                            </div>
                                        </div>
                                 
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Lot ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Lot ID"
                                                    formControlName="LotID">

                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Location Seq ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Location Seq ID"
                                                    formControlName="LocationSeqID">
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Secondary Location Seq ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Secondary Location Seq ID"
                                                    formControlName="SecondaryLocation">

                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Reason Enum ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Reason Enum ID" filter="true"
                                                    formControlName="ReasonEnumID"
                                                    [options]="enumDataArrayList" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Description</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Description"
                                                    formControlName="Description">


                                                </div>
                                                <div class="col-lg-2"></div>
                                               
                                            </div>
                                        </div>

                                       
                                       

                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 70%;">

                                    <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>