<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey titlePanels">Communications</span>
                <span class="" style="margin-left:80%;">
           
                <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
            </span> </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCardView ">
                                    <div class="header-tabs" 
                                        >
                                        <h4 class=" common-styling" style="margin-left: 7px;">
                                            Communications from Party origin
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-iCommunication Event Purposes
                                            nfomation font-13 font-medium own-user-select">
                                            <div class="form-group">
                                                <div class="card own-account-table">
                                                    <p-table [value]="UnknownPartyComms"
                                                        [paginator]="true" [rows]="rows"
                                                        scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)"
                                                        [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align"
                                                                        style="color: white;">
                                                                        <p-checkbox
                                                                            _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>


                                                                        Subject
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox"
                                                                        aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;"> Entry
                                                                        Date
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox"
                                                                            aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;"> Status
                                                                        ID
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox"
                                                                            aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;"> Party ID
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox"
                                                                            aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;"> Role
                                                                        Type ID
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox"
                                                                            aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;"> Role
                                                                        Status ID
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox"
                                                                            aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;"> Created
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox"
                                                                            aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </div>
                                                                </th>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-activity>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox
                                                                            _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        {{activity.communicationEventAndRole.subject}}
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{activity.communicationEventAndRole.entryDate|date:'yyyy-MM-dd
                                                                    hh:mm:ss'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{activity.communicationEventAndRole.statusId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{activity.communicationEventAndRole.partyId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{activity.communicationEventAndRole.roleTypeId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{activity.communicationEventAndRole.roleStatusId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{activity.communicationEventAndRole.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage"
                                                            let-columns>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">No Record Found
                                                                        </h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>