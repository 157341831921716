import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-detail-summary-reconciliation',
  templateUrl: './detail-summary-reconciliation.component.html',
  styleUrls: ['./detail-summary-reconciliation.component.css']
})
export class DetailSummaryReconciliationComponent implements OnInit {
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo= 1;
  rows = 50;
  search=""
  readonly employessSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,

  }
  activeCategory=2;
  detailSummary: any;
  finAccountId: any;
  gid: any;
  currentGlReconciliation: any;
  previousGlReconciliation: any;
  finAccountTransactions: any;
  grandTotal: any;
  reconArray: any[]=[];
  constructor(readonly spinner: NgxSpinnerService,
  
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
   ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.finAccountId = params["finAccountId"];
      this.gid=params["gid"];
      
    });
    this.getFinancialAccountReconciliations();
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
    
  getFinancialAccountReconciliations(){
    this.spinner.show();
    this.accountsService.getReconciliationSummary(this.finAccountId,this.gid).subscribe((res:any)=> {
      this.currentGlReconciliation = res.data.currentGlReconciliation;
      this.previousGlReconciliation = res.data.previousGlReconciliation;
      this.finAccountTransactions = res.data.finAccountTransactions;
      this.grandTotal=res.data.grandTotal
    
      this.spinner.hide();
    })
   
   
   }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  removeTransFromReconciliation(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.removeTransFromReconciliation(this.finAccountId,id,this.gid).subscribe((res:any)=> {  
          this.getFinancialAccountReconciliations();
      },(err) => {
        this.toastr.error(err.error.message);
     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })  
  }
  reconcileAll(){
    this.spinner.show();
    for(const v of this.finAccountTransactions){
      const req={
        "finAccountTransId":v.finAccountTransId,
        "organizationPartyId":v.party
      }
      this.reconArray.push(req)
    }
    const requestData = {
      "finAccountId":  this.finAccountId,
      "glReconciliationId":  this.gid,
      "reconcileInnerForms":this.reconArray
           
    }

    this.accountsService.reconcileAll(requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.toastr.success("Reconcile Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/detail-financial-summary"],{queryParams:{finAccountId:this.finAccountId}})
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
