import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2'
import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
@Component({
  selector: 'app-edit-shopping-list',
  templateUrl: './edit-shopping-list.component.html',
  styleUrls: ['./edit-shopping-list.component.css']
})
export class EditShoppingListComponent implements OnInit {
  createContact:FormGroup;
  activeCategory=2;
  partyId: any;
  public=[{
    "label":"Y",
    "value":"Y"
  },
  {
    "label":"N",
    "value":"N"
  }]
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search = ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }

 
  productStoreId: any;
  shoppingListId: any;
  show: boolean;
  party: any;
  AllProductStoreRole:any[]=[]
  ShoppingListArray: any[]=[];
  shoppingListTypeIdDataArray: any[]=[];
  ItemToShoppingList: any;
  promotion: string;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    public createCont: CreateContactsService,
    readonly accountsService: AccountsService,) { 
     
      this.createContact = this._FormBuilder.group({
        ParentList: [''],
        Public: [''],
        ListType:[''],
        Description:[''],
        ListName:['']
      
      
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {


    this._ActivatedRoute.queryParams.subscribe(params => {
      this.shoppingListId = params["shoppingListId"];
      this.party=params["party"];
    
      
    });
    this.getShoppingList();
    this.getShoppingListTypeIdList();
    this.getItemToShoppingList();
    if(this.shoppingListId) {
      this.show=true;
      this.getShoppingListById();
    
   
    }
    else {
      
      this.show = false;
    }
 
  }
  
  getShoppingListById(): void {
    this.spinner.show();
    this.accountsService.getShoppingListById(this.shoppingListId).subscribe((res) => {
      setTimeout(() => {
        const formValue = this.createContact;
      formValue.patchValue({
        ParentList: res.data[0].shoppingListByIdData.parentShoppingListId,
        Public:res.data[0].shoppingListByIdData.isPublic,
        ListType:res.data[0].shoppingListByIdData.shoppingListTypeId,
        Description:res.data[0].shoppingListByIdData.description,
        ListName:res.data[0].shoppingListByIdData.listName
      })
      }, 3000); 
    });
   
  }
   
  getItemToShoppingList(): void {
    this.spinner.show();
    this.accountsService.getItemToShoppingList(this.finSize,this.shoppingListId,this.party).subscribe((res) => {
      this.spinner.hide();
      this.ItemToShoppingList = res.data.shoppingListItemDatas;
      
      
    });
   
  }
  
  removeShoppingListItem(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.removeShoppingListItem(this.shoppingListId,id).subscribe((res: any) => {
          if (res.success) {  
            this.getItemToShoppingList();
          }
          else if (res.success == false) {
            this._ToastrService.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  getShoppingListTypeIdList(): void {
    this.spinner.show();
    this.accountsService.getShoppingListTypeIdList().subscribe((res) => {
      let shoppingListTypeIdData = res.data;
      this.spinner.hide();
      for (const value of shoppingListTypeIdData) {
        this.shoppingListTypeIdDataArray.push({
          label: value.shoppingListTypeIdData.description,
          value: value.shoppingListTypeIdData.shoppingListTypeId,
        });
      }
    });
   
  }
  getShoppingList(): void {
    this.spinner.show();
    this.accountsService.getShoppingList(this.finSize,this.party).subscribe((res) => {
      let ShoppingList = res.data;
      this.spinner.hide();
      for (const value of ShoppingList) {
        this.ShoppingListArray.push({
          label: value.shoppingListWithListName.listName,
          value: value.shoppingListWithListName.shoppingListId,
        });
      }
    });
   
  }
 
  
  createQuoteFromShoppingList() {
    this.spinner.show();
   this.promotion="N"
    this.accountsService.createQuoteFromShoppingList(this.promotion,this.shoppingListId).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
       
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.debugMessage);
        }
    });


  } 
  createPartyCustomRequest() {
    this.spinner.show();
   
 
    this.accountsService.createPartyCustomRequest(this.shoppingListId).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
       
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.debugMessage);
        }
    });


  } 
   onUpdate() {
    this.spinner.show();
   
    const requestData = {
      "description": this.createContact.value.Description,
      "isPublic": this.createContact.value.Public,
      "listName":this.createContact.value.ListName,
      "parentShoppingListId": this.createContact.value.ParentList,
      "partyId":this.party,
      "shoppingListId": this.shoppingListId,
      "shoppingListTypeId": this.createContact.value.ListType

    }
    this.accountsService.updateShoppingList(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }   
  quickAddShoping() {
    this._Router.navigate(['partyQuickLink/quick-add-shopping'],{ queryParams: {party: this.party, shoppingListId:this.shoppingListId } })
  }
  updatequickAddShoping(eproductId,equantity,eseqId,equantityPurchase) {
    this._Router.navigate(['partyQuickLink/quick-add-shopping'],{ queryParams: {party: this.party, shoppingListId:this.shoppingListId,productId:eproductId,quantity:equantity,seqId:eseqId,quantityPurchase:equantityPurchase } })
  }
  order(){
    this._Router.navigate(['order/create-sales-order'])
  }
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }}


