<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Feature Types  of {{ this.id}} </span>
                
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <header class="w3-container w3-blue">
                            <div class="header-tabs" style="margin-left: -15px;
                        margin-right: -14px;">
                                <h4 class=" common-styling h4Margin">
                                    Feature Types Information
                                </h4>
                                <span *ngIf="!this.Hidebtn" class="edit-buttons  hCreate">

                              <a   data-toggle="modal" 
                              data-target="#exampleModalCenter">Update</a>
                            </span>
                            </div>
                        </header>

                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div *ngIf="activeCategory==1">

                                                    <div class="panel-body" *ngIf="this.featureType">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Product Feature Type ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.id}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12"> Parent Type Id</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.featureType.parentTypeId}}</span>

                                                                        </div>



                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Description</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.featureType.description}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12"> Parent Type</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.featureType.parentType}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Created Date</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12"> {{this.featureType.createdStamp | date :'yyyy-MM-dd HH:mm:ss'}}</span>
                                                                        </div>





                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Update Feature Type</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="    width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.editMode==false" [ngClass]="activeCategory==1?'active':''"><a>
                                Create Feature Type</a></li>
                                <li *ngIf="this.editMode==true" [ngClass]="activeCategory==1?'active':''"><a>
                                Update Feature Type</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="featureTypeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Product Feature Type ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" style="margin-left: -5%;" class="form-control" id="exampleInputEmail1" formControlName="productFeatureTypeId" aria-describedby="emailHelp" placeholder="Enter Product Feature Type ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Product Parent Type
                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="parentType" formControlName="parentTypeId" filter="true" placeholder="Product Parent Type"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Description<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <textarea class="form-control" style="margin-left: -5%;" id="exampleFormControlTextarea1" formControlName="description" rows="1" placeholder="Enter Description"></textarea>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="main-submit-button" style="margin-right: 11%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="submit()">{{editMode ?'Update':'Create'}}</button>&nbsp;

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>