<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of Mr. THE PRIVILEGED ADMINISTRATOR<br>
                    [admin]
                    </span>

                    <span class="d-flex">

                        <button type="submit" (click)="homeButton();"
                            class=" btn btn-outline-secondary mr-2">Home</button>
                
                        <button type="submit" (click)="cancelSubmit();"
                            class="btn btn-danger buttonclass ">Back</button>
                    </span>
                
            </div>

            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="w3-card-4 classCardA ">

                                            <div class="container-fluid">
                                                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                                                    <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                                            Profile

                                                        </a></li>
                                                    <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                                        <a>
                                                            Preferences
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                                        <a>
                                                            Role(s)</a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                                        <a>
                                                            Projects
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)">
                                                        <a>
                                                            Tasks
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)">
                                                        <a>
                                                            Rates
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)">
                                                        <a>
                                                            Revisions
                                                        </a>
                                                    </li>


                                                </ul>
                                            </div>
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div class="create-new-leade ">
                                                    <div class="container-fluid">


                                                        <div class="panel-group">

                                                            <div class="w3-card-4 classCardView mt-2">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Edit Contact Information
                                                                    </h4>
                                                                </div>

                                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <span style="display: block;">
                                                                                        &nbsp;</span>
                                                                                <label for="exampleInputEmail1">Contact Purposes
                                                                                        

                                                                                    </label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <span style="color: black;
                                                                                white-space: nowrap;
                                                                                font-weight: 500;">
                                                                                        Main Mobile Phone Number (Since:2021-06-04 11:27:00.809)</span>

                                                                                <p-dropdown name="ContactPurposes" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact Purposes" filter="true" optionlabel="label">
                                                                                </p-dropdown>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Phone Number
                                                                                    </label>
                                                                            </div>
                                                                            <div class="col-lg-1">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1" name="PhoneNumber" aria-describedby="emailHelp" placeholder="Enter Phone Number">

                                                                            </div>
                                                                            <div class="col-lg-1">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1" name="PhoneNumber" aria-describedby="emailHelp" placeholder="Enter Phone Number">

                                                                            </div>
                                                                            <div class="col-lg-1">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1" name="PhoneNumber" aria-describedby="emailHelp" placeholder="Enter Phone Number">

                                                                            </div>
                                                                            <div class="col-lg-1 form-group classInput" style="display: contents;padding-right: 0% !important;">
                                                                                <label for="exampleInputEmail1">Ext
                                                                                    </label>
                                                                            </div>
                                                                            <div class="col-lg-1">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1" name="Ext" aria-describedby="emailHelp" placeholder="Enter Ext">

                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-lg-3">

                                                                            </div>
                                                                            <div class="col-lg-6">
                                                                                <span style="color: black;
                                                                                white-space: nowrap;
                                                                                font-weight: 500;">	[Country Code][Area Code][Contact Number][ext]</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Allow Solicitation?
                                                                                        

                                                                                    </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown name="AllowSolicitation?" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Allow Solicitation?" filter="true" optionlabel="label">
                                                                                </p-dropdown>

                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div class="col-lg-12  main-submit-button" style="margin-left: -63%;">
                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Go Back</button>&nbsp;
                                                                    </div>

                                                                    <div class="col-lg-12  main-submit-button" style="margin-left: -91%;">
                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Save</button>&nbsp;
                                                                    </div>
                                                                    <div class="col-lg-12  main-submit-button" style="margin-left: -90%;">
                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ">Delete</button>&nbsp;
                                                                    </div>
                                                                    <div class="col-lg-12  main-submit-button" style="margin-left: -87%;">
                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Add Purpose</button>&nbsp;
                                                                    </div>






                                                                </div>






                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>