import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { URLS } from '../app.constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CRMAccountService {
    constructor(
        private _ApiService: ApiService,private http: HttpClient) { }

    getAllPayment(partyId: string, showOld: boolean): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/${partyId}?showOld=${showOld}`);
    }
    // Credit Card
    createCreditCard(partyId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/creditCard/${partyId}`, formData);
    }
    getCreditCardTypes(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/creditCard/types`);
    }
    getAccountById(partyId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/accounts/${partyId}`);
    }
    updateCreditCard(partyId: string, paymentMethodId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/creditCard/${partyId}/${paymentMethodId}`, formData);
    }
    // Gift card
    removeGiftCard(paymentMethodId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/${paymentMethodId}`);
    }
    createGiftCard(partyId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/${partyId}`, formData);
    }
    updateGiftCard(partyId: string, paymentMethodId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/${partyId}/${paymentMethodId}`, formData);
    }
    // EFT Account

    createEftAccount(partyId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/eftAccount/${partyId}`, formData);
    }
    updateEftAccount(partyId: string, paymentMethodId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/eftAccount/${partyId}/${paymentMethodId}`, formData);
    }
    // Check Account
    createCheckAccount(partyId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/checkAccount/${partyId}`, formData);
    }
    updateCheckAccount(partyId: string, paymentMethodId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/checkAccount/${partyId}/${paymentMethodId}`, formData);
    }
    // Billing Account
    createBillingAccount(partyId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/billingAccount/${partyId}`, formData);
    }
    updateBillingAccount(billingAccountId: string, partyId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/billingAccount/${partyId}/${billingAccountId}`, formData);
    }
    getBillingAccounts(partyId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/billingAccount/${partyId}`);
    }
    getBillingAccountById(billingAccountId: string, partyId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/billingAccount/${partyId}/${billingAccountId}`);
    }
    removeBillingAccount(partyId: string, billingAccountId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/billingAccount/${partyId}/${billingAccountId}`);
    }
    getPaymentMethodById(partyId: string, paymentMethodId: string, type: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods?partyId=${partyId}&paymentMethodId=${paymentMethodId}&type=${type}`);
    }
    getPaymentMethodTypes(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/paymentMethods/checkAccount/paymentMethodTypes`);
    }

    // Additional Detail

    createAdditionalDetail(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/additionalInfo`, formData);
    }
    updateAdditionalDetail(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/additionalInfo`, formData);
    }

    // Job Information

    createJobInformation(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/jobInfo`, formData);
    }
    updateJobInformation(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/jobInfo`, formData);
    }
    // Postal Address
    getContactPurposes(partyId: string, preContactMechTypeId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/hr/employee/contactPurposes?partyId=${partyId}&preContactMechTypeId=${preContactMechTypeId}`);
    }
    createPostalAddress(formData): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/partyPostalAddress`, formData);
    }
    updatePostalAddress(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/partyPostalAddress`, formData);
    }
    // Account
    createAccount(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/createAccount`, formData);
    }
    updateAccount(partyId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/updateAccount?partyId=${partyId}`, formData);
    }
    postFindParty(formData:any):Observable<any>{
        return this.http.post(URLS.postFindParty,formData)
    }
}