import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {URLS} from '../app.constant';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }
  // loginDetails(loginUser): Observable<any>{
  //   const loginApiURL=URLS.loginApi 
  //   return this.http.post(loginApiURL,loginUser);
  // }
  loginDetails(loginUser): Observable<any>{
    
    const loginApiURL=URLS.loginApi + "?userTenantId=" + loginUser.userTenantId
    return this.http.post(loginApiURL,loginUser);
  }

  // New login code added
  // loginDetails(loginUser): Observable<any> {
  //   if (loginUser.userTenantId !== "") {
  //     const loginApiURL = URLS.loginApi + "?userTenantId=" + loginUser.userTenantId
  //     return this.http.post(loginApiURL, loginUser);
  //   }
  //   else {
  //     const loginApiURL = URLS.loginApi
  //     return this.http.post(loginApiURL, loginUser);
  //   }
  // }
  // New login code added END

  resetPassword(userLoginId,userTenantId): Observable<any>{
    
    const resetPassword=URLS.resetPassword + "?userLoginId=" + userLoginId + "&userTenantId=" + userTenantId;
    return this.http.post(resetPassword,'');
  }
  changePassword(token,userTenantId,form): Observable<any>{
    
    const changePassword=URLS.changePassword + "?token=" + token + "&userTenantId=" + userTenantId;
    return this.http.post(changePassword,form);
  }

}
