<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">HRM Overview page </span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="width: 102% !important;
              flex-wrap: wrap !important;
              display: flex !important;">
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)"><a>
                                    Profile </a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)"><a>
                                    Employements</a></li>

                            <li [ngClass]="activeCategory==20?'active':''" (click)="changeactiveCategory(20)"><a>
                                    Employee Position </a></li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)"><a>
                                    Skills </a></li>
                            <!--   <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)"><a>
                Qualification </a></li> -->
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)"><a>
                                    Training </a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)"><a>
                                    Leave </a></li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)"><a>
                                    Qualification </a></li>



                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">


                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">

                                                <div *ngIf="activeCategory==3">
                                                    <div class="w3-card-4 classCard">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Employments
                                                        </h4>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group" style="width: 100%!important;">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="getEmployement" [paginator]="true"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        Internal Organization<p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Employee
                                                                                        Party ID <p-sortIcon
                                                                                            field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> First
                                                                                        Name <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Last
                                                                                        Name <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> From
                                                                                        Date <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Through
                                                                                        Date<p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">
                                                                                        Termination Reason ID
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">
                                                                                        Termination Type ID <p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.internalOrg}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.partyId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.firstName}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.lastName}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.fromDate }}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.thruDate}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.terminationReasonId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.terminationTypeId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    <button type="submit"
                                                                                        (click)="employementDetails(product.partyId,product.partyIdFrom,product.fromDate,product.roleTypeIdFrom,product.roleTypeIdTo)"
                                                                                        class="btn btn-secondary submit-btn">Update</button>


                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>


                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>

                                                <div *ngIf="activeCategory==5">
                                                    <div class="w3-card-4 classCard">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Skill
                                                        </h4>
                                                        <span class="edit-buttons">
                                                            <a data-toggle="modal" (click)=" resetSkill()"
                                                                data-target="#exampleModalSkill">Create New Skill</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="EditEmployeeSkillList"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Skill Type Id
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Years
                                                                                        Experience
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Rating
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Skill
                                                                                        Level
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th style="width: 16%;"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Created
                                                                                        At
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;"> Action
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td  class="account-button"  
                                                                                    (click)="detailSkillPages(product.partyId,product.skillTypeId
                                      ,product.rating,product.skillLevel,product.yearsExperience)">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.skillTypeId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{{product.yearsExperience}}</td>
                                                                                <td>{{product.rating}}</td>
                                                                                <td>{{product.skillLevel}}</td>

                                                                                <td>{{product.createdStamp | date
                                                                                    :'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                <td>

                                                                                    <button type="submit"
                                                                                        (click)="onDeleteSkills(product.partyId,product.skillTypeId)"
                                                                                        class="btn btn-danger ">Delete</button>

                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="activeCategory==7">
                                                    <div class="w3-card-4 classCard">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Training Status
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group"  style="width:100% !important;">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="trainingApprovalList"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'155px'}"
                                                                                    pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Employee Party ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Training
                                                                                        Request ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'150px'}">
                                                                                    <div style="color: white;">Training
                                                                                        Class Type
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="quantity">
                                                                                    <div style="color: white;">Work
                                                                                        Effort ID
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">From Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Through
                                                                                        Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Approver
                                                                                        Party
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Approver
                                                                                        Status
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>

                                                                                <td [ngStyle]="{'width':'155px'}"
                                                                                     class="account-button"  
                                                                                    (click)="detailPageTraining(product.trainingRequestId)">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    {{product.partyId}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.trainingRequestId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.trainingClassTypeId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.workEffortId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.fromDate | date:
                                                                                    "yyyy-MM-dd HH:mm:ss"}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.thruDate | date:
                                                                                    "yyyy-MM-dd HH:mm:ss"}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.approverPartyName}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.approvalStatus}}</td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==8">
                                                    <div class="w3-card-4 classCard">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Leave
                                                        </h4>
                                                        <span class="edit-buttons">
                                                            <a data-toggle="modal" data-target="#exampleModalLeave"
                                                                (click)="resetLeave()">Create</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group" style="width:100% !important;">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="EditEmployeeLeavesList"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Employee ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Leave
                                                                                        Type ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'150px'}">
                                                                                    <div style="color: white;">Leave
                                                                                        Reason Type
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="quantity">
                                                                                    <div style="color: white;">From Date
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Through
                                                                                        Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Approver
                                                                                        Party
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Leave
                                                                                        Status
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Action
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span
                                                                                             class="account-button"  
                                                                                            (click)="detailPageLeave(product.EditEmployeeLeaves.partyId,product.EditEmployeeLeaves.leaveTypeId,product.fromDate,product.thruDate,product.EditEmployeeLeaves.approverPartyId,product.EditEmployeeLeaves.description,product.EditEmployeeLeaves.emplLeaveReasonTypeId)">
                                                                                            {{product.EditEmployeeLeaves.partyId}}</span>

                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.EditEmployeeLeaves.leaveTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'160px'}">
                                                                                    {{product.EditEmployeeLeaves.emplLeaveReasonTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.fromDate }}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.thruDate }}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.EditEmployeeLeaves.approverPartyId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.EditEmployeeLeaves.leaveStatus}}
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <button type="submit"
                                                                                        (click)="onDeleteLeave(product.EditEmployeeLeaves.partyId,product.EditEmployeeLeaves.leaveTypeId,product.fromDate)"
                                                                                        class="btn btn-danger ">Delete</button>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                <div *ngIf="activeCategory==20">
                                                    <div class="w3-card-4 classCard">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Employee Position
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group" style="width:100% !important;">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="EditEmployeePositionList"
                                                                        [paginator]="true" [rows]="3" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        Position ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">Status Id
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">Party ID
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="quantity">
                                                                                    <div style="color: white;">Budget ID
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Budget
                                                                                        Item
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Emp
                                                                                        Position Type ID
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Estimated
                                                                                        From Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Estimated
                                                                                        Through Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Salary
                                                                                        Flag
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Exempt
                                                                                        Flag
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Temporary
                                                                                        Flag
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Fulltime
                                                                                        Flag
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Actual
                                                                                        From Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Actual
                                                                                        Through Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <!-- <th [ngStyle]="{'width':'170px'}"
                                                                          pSortableColumn="price"><div style="color: white;">Action <p-sortIcon
                                                                              field="price"></p-sortIcon></div>
                                                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                              name="j_idt727:j_idt728:j_idt731:filter"
                                                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                              value="" autocomplete="off"
                                                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                              role="textbox" aria-disabled="false"
                                                                              aria-readonly="false">
                                                                      </th> -->
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}"
                                                                                    routerLinkActive="active">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span class="text-primary"
                                                                                            (click)="detailEmployeePosition(product.emplPositionId)">
                                                                                            {{product.emplPositionId}}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.statusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.partyId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.budgetId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.budgetItemSeqId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.emplPositionTypeId}}</td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.estimatedFromDate | date
                                                                                    :'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.estimatedThruDate | date
                                                                                    :'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.salaryFlag}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.exemptFlag}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.temporaryFlag}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.fulltimeFlag}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.actualFromDate | date
                                                                                    :'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.actualThruDate | date
                                                                                    :'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                                <div *ngIf="activeCategory==2">
                                                    <!--  -->
                                                    <div style="width: 49%; float:left">
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">

                                                                <h4 class=" common-styling hCard"
                                                                    style="margin-left: 7px;">
                                                                    Personal Information
                                                                </h4>
                                                                <span style="margin-right: 2%;"
                                                                    class="edit-buttons hCreate"
                                                                    data-toggle="modal"
                                                                    data-target="#exampleModal">Update</span>
                                                            </div>
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select"
                                                                    style="height: 190px;overflow-y: scroll;">
                                                                    <div class="color-black container">
                                                                        <div *ngIf="editPartyGroupList" class="row">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Organization Logo</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Party ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{partyId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Group
                                                                                        Name</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.dataNode.groupName}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Group
                                                                                        Name Local </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.dataNode.groupNameLocal}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Office
                                                                                        Site Name</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.dataNode.officeSiteName}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Annual
                                                                                        revenue</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.dataNode.annualRevenue}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Number of
                                                                                        employees </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.dataNode.numEmployees}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Ticker
                                                                                        symbol </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.dataNode.tickerSymbol}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        Description</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.editPartyGroupDetails[0].description}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Currency
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.editPartyGroupDetails[0].preferredCurrencyUomId}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">External
                                                                                        ID</p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.editPartyGroupDetails[0].externalId}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Status ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.editPartyGroupDetails[0].statusId}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">
                                                                                        First Name </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.dataNode.firstName}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Last Name
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.dataNode.lastName}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 col-12">Title
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">{{editPartyGroupList.dataNode.title}}</span>
                                                                                </div>




                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs"
                                                                    style="background:#F5F7FA!important;">
                                                                    <h4 class=" common-styling"
                                                                        style="margin-left: 7px;">
                                                                        List Related Contacts
                                                                    </h4>
                                                                    <span style="margin-right: 2%;"
                                                                        class="edit-buttons"
                                                                        data-toggle="modal"
                                                                        data-target="#exampleModalFour">Create New Related Contacts</span>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;"
                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="form-group"
                                                                            style="width: 100%!important;">
                                                                            <div class="card own-account-table">
                                                                                <p-table
                                                                                    [value]="EditListRelatedAccountList"
                                                                                    [paginator]="false"
                                                                                    scrollHeight="100px" [rows]="3"
                                                                                    scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total"
                                                                                    [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr
                                                                                            style="background: #0d3769;">
                                                                                            <th pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    To Party ID
                                                                                                    <p-sortIcon
                                                                                                        field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                            <th
                                                                                                pSortableColumn="quantity">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Type
                                                                                                    <p-sortIcon
                                                                                                        field="quantity">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                            <th pSortableColumn="price">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Comment
                                                                                                    <p-sortIcon
                                                                                                        field="price">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </div>
                                                                                            </th>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-product>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <div
                                                                                                    class="checkbox-align pl-5">

                                                                                                    {{product.dataNode.partyIdTo}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>{{product.dataNode.partyRelationshipTypeId}}
                                                                                            </td>
                                                                                            <td>{{product.dataNode.comments}}
                                                                                            </td>


                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs"
                                                                    style="background:#F5F7FA!important;">
                                                                    <h4 class=" common-styling"
                                                                        style="margin-left: 7px;">
                                                                        Party Content
                                                                    </h4>

                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="background: white;">
                                                                    <div style="padding: 15px 5px!important;"
                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="form-group" style="width: 100%;">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="contentList"
                                                                                    [paginator]="false" [rows]="3"
                                                                                    scrollWidth="100%"
                                                                                    scrollHeight="100px"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total"
                                                                                    [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr
                                                                                            style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Content Id
                                                                                                    <p-sortIcon
                                                                                                        field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Content Name
                                                                                                    <p-sortIcon
                                                                                                        field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input
                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox"
                                                                                                        aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="category">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Content Type
                                                                                                    <p-sortIcon
                                                                                                        field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="quantity">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Party Content Type
                                                                                                    <p-sortIcon
                                                                                                        field="quantity">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Status
                                                                                                    <p-sortIcon
                                                                                                        field="price">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    From Date
                                                                                                    <p-sortIcon
                                                                                                        field="price">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                pSortableColumn="quantity">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Action
                                                                                                    <p-sortIcon
                                                                                                        field="quantity">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-product>
                                                                                        <tr>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                <div
                                                                                                    class="checkbox-align pl-5">

                                                                                                    {{product.contentId}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                {{product.contentName}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                {{product.contentType}}
                                                                                            </td>

                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                {{product.partyContentTypeId}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                {{product.status}}</td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                {{product.fromDate |
                                                                                                date:'medium'}}
                                                                                            </td>

                                                                                            <td
                                                                                                [ngStyle]="{'width':'170px'}">

                                                                                                <svg (click)="export(product.contentId,product.contentName)"
                                                                                                    width="2em"
                                                                                                    height="2em"
                                                                                                    viewBox="0 0 16 16"
                                                                                                    class="bi bi-arrow-down-circle-fill"
                                                                                                    fill="currentColor"
                                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path
                                                                                                        fill-rule="evenodd"
                                                                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                                                                </svg>
                                                                                                <svg (click)="onDelete(product.contentId,product.fromDate,product.partyContentTypeId,product.partyId)"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    class="ml-20"
                                                                                                    width="16"
                                                                                                    height="16"
                                                                                                    viewBox="0 0 26 26">
                                                                                                    <defs>
                                                                                                        <style>
                                                                                                            .a {
                                                                                                                fill: #f44336;
                                                                                                            }

                                                                                                            .b {
                                                                                                                fill: #fafafa;
                                                                                                                margin-left: 20px;
                                                                                                            }
                                                                                                        </style>
                                                                                                    </defs>
                                                                                                    <path
                                                                                                        class="a fill-color"
                                                                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                    <path class="b"
                                                                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                        transform="translate(-147.576 -147.576)" />
                                                                                                </svg>

                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>


                                                                            </div>
                                                                            <form [formGroup]="Editform">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-4 col-12">
                                                                                        <div class="form-group">

                                                                                            <label
                                                                                                for="exampleInputEmail1">Attach
                                                                                                Document</label>
                                                                                            <div
                                                                                                class="suppliers-wrapper">
                                                                                                <input type="file"
                                                                                                    style="width: 117%!important;"
                                                                                                    class="classFile"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="file"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    
                                                                                                    (change)="fileProgress($event)"
                                                                                                    accept="">

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-4 col-12">
                                                                                        <br><br>
                                                                                        <div class="form-group">
                                                                                            <p-dropdown filter="true"
                                                                                                formControlName="partyContentTypeId"
                                                                                                [(ngModel)]="partyContentTypeId"
                                                                                                name="partyContentTypeId"
                                                                                                placeholder="Enter Party Content"
                                                                                                (ngModelChange)="onpartyContent(partyContentTypeId)"
                                                                                                [options]="partyContentArray"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-4 col-12">
                                                                                        <br><br>
                                                                                        <div class="form-group">


                                                                                            <button type="submit"
                                                                                                (click)="onSubmit()"
                                                                                                class="btn btn-outline-secondary">Submit</button>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>


                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br>
                                                     
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                                                        border-color: #0d3769 !important;width:200% !important;">
                                                                <header class="w3-container w3-blue">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling"
                                                                            style="margin-left: 7px;">
                                                                            <h4 class=" common-styling"
                                                                                style="margin-left: 7px;">
                                                                                Payment Method
                                                                            </h4>
                                                                        </h4>
                                                                        <div class="d-flex flex-row">
                                                                            <span class="edit-buttons ml-2"
                                                                                data-toggle="modal"
                                                                                data-target="#exampleModalSix">Create
                                                                                Credit
                                                                                Card</span>
                                                                            <span class="edit-buttons ml-2"
                                                                                data-toggle="modal"
                                                                                data-target="#exampleModalSeven">Create
                                                                                Gift
                                                                                Card</span>
                                                                            <span class="edit-buttons ml-2"
                                                                                data-toggle="modal"
                                                                                data-target="#exampleModalEight">Create
                                                                                EFT Account</span>
                                                                            <span class="edit-buttons ml-2"
                                                                                data-toggle="modal"
                                                                                data-target="#exampleModalNine">Add
                                                                                Check Account</span>
                                                                            <span class="edit-buttons ml-2"
                                                                                data-toggle="modal"
                                                                                data-target="#exampleModalTen">Create
                                                                                Billing Account</span>
                                                                        </div>
                                                                    </div>
                                                                </header>
    
                                                                <div class="w3-container">
                                                                    <div class="panel-body" style="    background: white;">
                                                                        <div style="padding: 15px 5px!important;"
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
    
    
    
                                                                            <div class="form-group"
                                                                                style="width: 100%!important;">
                                                                                <div class="card own-account-table">
                                                                                    <p-table [value]="paymentMethods"
                                                                                        [paginator]="false" [rows]="3"
                                                                                        scrollWidth="100%"
                                                                                        scrollHeight="100px"
                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                        [totalRecords]="total"
                                                                                        [scrollable]="true"
                                                                                        (sortFunction)="customSort($event)"
                                                                                        [customSort]="true">
                                                                                        <ng-template pTemplate="body"
                                                                                            let-product>
                                                                                            <tr>
                                                                                                <td
                                                                                                    style="padding-left:10px !important;">
                                                                                                    {{product.type}}</td>
                                                                                                <td>
                                                                                                    <ng-container
                                                                                                        *ngIf="product.paymentMethodTypeId.includes('ACCOUNT') || product.paymentMethodTypeId.includes('CHECK')">
                                                                                                        <span>{{product.nameOnAccount}},{{product.bankName}}({{product.branchCode}}),A/C
                                                                                                            {{product.accountNumber}},
                                                                                                            (Type:
                                                                                                            {{product.accountType
                                                                                                            ?
                                                                                                            product.accountType
                                                                                                            :''}}), (Updated
                                                                                                            At:
                                                                                                            {{product.lastUpdatedStamp
                                                                                                            |date
                                                                                                            :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                                                    </ng-container>
                                                                                                    <ng-container
                                                                                                        *ngIf="product.paymentMethodTypeId==='BILLING'">
                                                                                                        <span>({{product.billingAccountId}}),(Account
                                                                                                            Limit:${{product.accountLimit}}),(Available
                                                                                                            Balance:
                                                                                                            ${{product.accountBalance}}),
                                                                                                            (Updated At:
                                                                                                            {{product.lastUpdatedStamp
                                                                                                            |date
                                                                                                            :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                                                    </ng-container>
                                                                                                    <ng-container
                                                                                                        *ngIf="product.paymentMethodTypeId.includes('CARD')">
                                                                                                        <span>{{product.cardNumber
                                                                                                            ?
                                                                                                            product.cardNumber
                                                                                                            :''}}</span>,
                                                                                                        Expire :
                                                                                                        <span>{{product.expireDate?
                                                                                                            product.expireDate:''}}</span>,
                                                                                                        <span>
                                                                                                            {{product.companyNameOnCard?
                                                                                                            product.companyNameOnCard:''}}</span>,
                                                                                                        <span> ,(
                                                                                                            {{product.firstNameOnCard
                                                                                                            ?
                                                                                                            product.firstNameOnCard:''}}
                                                                                                            {{product.middleNameOnCard
                                                                                                            ?
                                                                                                            product.middleNameOnCard:''}}
                                                                                                            {{product.lastNameOnCard
                                                                                                            ?
                                                                                                            product.lastNameOnCard
                                                                                                            : ''}}),(
                                                                                                            {{product.cardType}}),
                                                                                                            (Updated At:
                                                                                                            {{product.lastUpdatedStamp
                                                                                                            |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                                                                                    </ng-container>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <fa-icon
                                                                                                         class="account-button" 
                                                                                                        (click)="paymentMethodNavigation(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)"
                                                                                                        [icon]="faEdit">
                                                                                                    </fa-icon>
                                                                                                    <fa-icon
                                                                                                        class="text-danger cursor-pointer"
                                                                                                        (click)="removePaymentMethod(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)"
                                                                                                        [icon]="faTrash">
                                                                                                    </fa-icon>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
    
                                                                                </div>
                                                                            </div>
    
    
    
                                                                        </div>
                                                                    </div>
                                                                </div>
    
    
                                                            </div>

                                                        <br>
                                                    </div>
                                                    <!--  -->
                                                    <div style="width: 49.8%; float:right">
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">

                                                                <h4 class=" common-styling hCard">
                                                                    Contact Information
                                                                </h4>
                                                                <div class="d-flex flex-row">
                                                                    <span class="edit-buttons"
                                                                        data-toggle="modal"
                                                                        data-target="#exampleModalTwo"
                                                                        style="margin-left:-3%!important ;">Create
                                                                        Address</span>
                                                                    <span
                                                                        class="edit-buttons ml-2 hCreate"
                                                                        data-toggle="modal"
                                                                        data-target="#exampleModalThree">Create
                                                                        Telecom</span>
                                                                </div>
                                                            </div>
                                                            <div class="panel-body">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                                    <div class="form-group" style="width: 100%;">
                                                                        <div class="card own-account-table borderTable">
                                                                            <p-table [value]="contactInformation"
                                                                                [rows]="3" scrollWidth="100%"
                                                                                scrollHeight="100px" [paginator]="false"
                                                                                [totalRecords]="total"
                                                                                [scrollable]="true"
                                                                                (sortFunction)="customSort($event)">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code">
                                                                                            <div class="checkbox-align"
                                                                                                style="color: white;">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Contact Type
                                                                                                <p-sortIcon
                                                                                                    field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Contact Information
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th pSortableColumn="category">
                                                                                            <div style="color: white;">
                                                                                                Soliciting OK?
                                                                                                <p-sortIcon
                                                                                                    field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Action</div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                style="visibility:hidden;"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body"
                                                                                    let-product>
                                                                                    <tr>
                                                                                        <td
                                                                                            style="width: 25%; padding-left:40px !important;">
                                                                                            <ng-container
                                                                                                *ngIf="product.contactMech.contactMechTypeId==='POSTAL_ADDRESS'">
                                                                                                {{product.contactMechType.description}}
                                                                                            </ng-container>
                                                                                            <ng-container
                                                                                                *ngIf="product.contactMech.contactMechTypeId==='EMAIL_ADDRESS'">
                                                                                                {{product.contactMechType.description}}
                                                                                            </ng-container>
                                                                                            <ng-container
                                                                                                *ngIf="product.contactMech.contactMechTypeId==='TELECOM_NUMBER'">
                                                                                                {{product.contactMechType.description}}
                                                                                            </ng-container>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div class="checkbox-align">
                                                                                                <ng-container
                                                                                                    *ngIf="product.contactMech.contactMechTypeId==='POSTAL_ADDRESS'">
                                                                                                    {{product.postalAddress.address1}}
                                                                                                    ,
                                                                                                    {{product.postalAddress.address2}},
                                                                                                    <br>{{product.postalAddress.stateProvinceGeoId}}
                                                                                                    ,
                                                                                                    {{product.postalAddress.city}},
                                                                                                    <br>{{product.postalAddress.postalCode}},{{product.postalAddress.countryGeoId}}
                                                                                                </ng-container>
                                                                                                <ng-container
                                                                                                    *ngIf="product.contactMech.contactMechTypeId==='EMAIL_ADDRESS'">
                                                                                                    {{product.contactMech.infoString}}
                                                                                                </ng-container>
                                                                                                <ng-container
                                                                                                    *ngIf="product.contactMech.contactMechTypeId==='TELECOM_NUMBER'">
                                                                                                    <!--   Area Code: {{product.telecomNumber.areaCode}}, Contact Number:
                                                    {{product.telecomNumber.contactNumber}}, Country Code:
                                                    {{product.telecomNumber.countryCode}} -->
                                                                                                    Contact Number:
                                                                                                    {{product.telecomNumber.contactNumber}}
                                                                                                </ng-container>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            {{product.partyContactMech.allowSolicitation
                                                                                            ?product.partyContactMech.allowSolicitation
                                                                                            :'N' }}
                                                                                        </td>
                                                                                        <td>
                                                                                            <button type="submit"
                                                                                                (click)="updateData(product.contactMech.contactMechId ,product.contactMech.contactMechTypeId)"
                                                                                                class="btn btn-secondary submit-btn">Update</button>


                                                                                        </td>

                                                                                    </tr>
                                                                                </ng-template>

                                                                            </p-table>



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCssShadow" style="border:1px solid;
                            border-color: #0d3769 !important;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs"
                                                                    style="background:#F5F7FA!important;">
                                                                    <h4 class=" common-styling"
                                                                        style="margin-left: 7px;">
                                                                        Notes
                                                                    </h4>
                                                                    <span style="margin-right: 2%;"
                                                                        class="edit-buttons"
                                                                        (click)="resetnote()" data-toggle="modal"
                                                                        data-target="#exampleModalFive">Create New Notes</span>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body" style="    background: white;">
                                                                    <div style="padding: 15px 5px!important;"
                                                                        class="all-users-infomation font-13 font-medium own-user-select">



                                                                        <div class="form-group"
                                                                            style="width: 100%!important;">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="accountData"
                                                                                    [paginator]="false" [rows]="3"
                                                                                    scrollWidth="100%"
                                                                                    scrollHeight="100px"
                                                                                    [rowsPerPageOptions]="[10,25,50,100,200,300,500]"
                                                                                    [totalRecords]="total"
                                                                                    [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr
                                                                                            style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'130px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Note Name
                                                                                                    <p-sortIcon
                                                                                                        field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'100px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Note
                                                                                                    <p-sortIcon
                                                                                                        field="price">
                                                                                                    </p-sortIcon>

                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'300px'}"
                                                                                                pSortableColumn="quantity">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    By
                                                                                                    <p-sortIcon
                                                                                                        field="quantity">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'100px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Created At
                                                                                                    <p-sortIcon
                                                                                                        field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Action


                                                                                                </div>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-product>
                                                                                        <tr>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'130px','padding-left':'40px'}">
                                                                                                <div
                                                                                                    class="checkbox-align">
                                                                                                    {{product.noteName}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'100px'}">
                                                                                                {{product.noteInfo}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'300px'}">
                                                                                                {{product.firstName}}
                                                                                                {{product.middleName}}
                                                                                                {{product.lastName}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'100px'}">
                                                                                                {{product.createdStamp |
                                                                                                date:'yyyy-MM-dd'}}
                                                                                            </td>

                                                                                            <td
                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                <button type="submit"
                                                                                                    data-toggle="modal"
                                                                                                    data-target="#exampleModalFive"
                                                                                                    (click)="updateNote(product)"
                                                                                                    class="btn btn-secondary submit-btn">
                                                                                                    Update</button>

                                                                                                <button type="submit"
                                                                                                    (click)="onDeleteNotes(product.noteId)"
                                                                                                    class="btn btn-secondary submit-btn">
                                                                                                    Delete</button>


                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>
                                                     


                                                        <br>




                                                    </div>

                                                   
                                                    <br>
                                                    <br>

                                                </div>

                                                <div *ngIf="activeCategory==9">
                                                    <div class="w3-card-4 classCard">
                                                        <header class="w3-container w3-blue">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling h4Margin px-1">
                                                                    Qualification

                                                                </h4>
                                                                <span class="edit-buttons create_skill">
                                                                    <a data-toggle="modal"
                                                                        data-target="#qualModal">Create New Qualification</a>
                                                                </span>

                                                            </div>
                                                        </header>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">


                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="EditPartyQualList"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Party ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Party Qual Type ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Qualification Desc
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Title
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Status ID
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Verif Status ID
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Through Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td routerLinkActive="active"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                                 class="account-button" 
                                                                                                (click)="qualificationDetail(product.EditPerfReview.partyId,product.EditPerfReview.partyQualTypeId,product.fromDate,
                                                                                     product.EditPerfReview.qualificationDesc, product.EditPerfReview.title, product.EditPerfReview.statusId,
                                                                                      product.EditPerfReview.verifStatusId ,product.thruDate)">
                                                                                                {{product.EditPerfReview.partyId}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.EditPerfReview.partyQualTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.fromDate }}</td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.EditPerfReview.qualificationDesc}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.EditPerfReview.title}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.EditPerfReview.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.EditPerfReview.verifStatusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.thruDate}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">


                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                            (click)="deletePartyQual(product)"
                                                                                            class="ml-20" width="16"
                                                                                            height="16"
                                                                                            viewBox="0 0 26 26">
                                                                                            <defs>
                                                                                                <style>
                                                                                                    .a {
                                                                                                        fill: #f44336;
                                                                                                    }

                                                                                                    .b {
                                                                                                        fill: #fafafa;
                                                                                                        margin-left: 20px;
                                                                                                    }
                                                                                                </style>
                                                                                            </defs>
                                                                                            <path class="a fill-color"
                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                            <path class="b"
                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                        </svg>
                                                                                    </td>



                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="main-submit-button">
                      <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
                  </div> -->
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalSkill" tabindex="-1" role="dialog" aria-labelledby="exampleModalSkillTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Create Party Skill</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Add Party Skill </a></li>
                                <!-- <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                      Update Party Skill </a></li> -->

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="add_skill_form" [formGroup]="add_skill_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Skill Type ID <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="skillTypeId" filter="true"
                                                            [options]="skillArray" optionlabel="label"
                                                            placeholder="Select Skill Type Id">

                                                        </p-dropdown>

                                                        <small
                                                            *ngIf="add_skill_form.controls['skillTypeId'].invalid && (add_skill_form.controls['skillTypeId'].dirty || add_skill_form.controls['skillTypeId'].touched)">
                                                            <p class="validate-field"
                                                                *ngIf="add_skill_form.controls['skillTypeId'].errors.required">
                                                                Skill Type ID is required!</p>
                                                        </small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Years Experience</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="yearsExperience"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter year of experience">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Rating </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="rating"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Rating">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Skill Level</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="skillLevel"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter skill Level">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Party ID
                                                            <span style="color:red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        {{this.partyId}}
                                                        <!-- <p-dropdown placeholder="Enter Party ID"
                                                          formControlName="PartyID" [options]="partyIdArray"
                                                          optionlabel="label" filter="true">

                                                      </p-dropdown> -->
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="main-submit-button" style="margin-right: 28%;">
                                            <button type="submit" (click)="onSubmitSkill()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                            <!-- <button type="submit" *ngIf="showSkill" (click)="updateSkills()"
                                              class="btn btn-secondary submit-btn">Update</button> -->

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalLeave" tabindex="-1" role="dialog" aria-labelledby="exampleModalLeaveTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Create New Leaves</span>
                <span *ngIf="show" class="color-black pl-1"> Update New Leaves</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Add New Employee Leaves</a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="add_leave_form" [formGroup]="add_leave_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">Leave Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="leaveId"
                                                            formControlName="leaveTypeId" optionlabel="label"
                                                            placeholder="Enter Leave Type ID">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Leave Reason Type<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="leaveReasonArray"
                                                            formControlName="emplLeaveReasonTypeId" optionlabel="label"
                                                            placeholder="Enter Leave Reason Type">
                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">From Date <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="From Date">
                                                        <small
                                                            *ngIf="add_leave_form.controls['fromDate'].invalid && (add_leave_form.controls['fromDate'].dirty || add_leave_form.controls['fromDate'].touched)">
                                                            <p class="validate-field"
                                                                *ngIf="add_leave_form.controls['fromDate'].errors.required">
                                                                From Date is required!</p>
                                                        </small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Through Date <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="thruDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Through Date">


                                                        <small
                                                            *ngIf="add_leave_form.controls['thruDate'].invalid && (add_leave_form.controls['thruDate'].dirty || add_leave_form.controls['thruDate'].touched)">
                                                            <p class="validate-field"
                                                                *ngIf="add_leave_form.controls['thruDate'].errors.required">
                                                                Through Date is required!</p>
                                                        </small>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">Approver Party <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="partyIdArray"
                                                            optionlabel="label" placeholder="Enter Approver Party"
                                                            formControlName="approverPartyId">

                                                        </p-dropdown>

                                                        <small
                                                            *ngIf="add_leave_form.controls['approverPartyId'].invalid && (add_leave_form.controls['approverPartyId'].dirty || add_leave_form.controls['approverPartyId'].touched)">
                                                            <p class="validate-field"
                                                                *ngIf="add_leave_form.controls['approverPartyId'].errors.required">
                                                                Approver Party is required!</p>
                                                        </small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="description"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter description">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">Party Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        {{this.partyId}}
                                                        <!-- <p-dropdown filter="true" [options]="partyIds"
                                                          formControlName="partyId" optionlabel="label"
                                                          placeholder="Enter Party Id">
                                                      </p-dropdown> -->
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes"
                                                        *ngIf="leave">
                                                        <label for="exampleInputEmail1">Leave</label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="leave">
                                                        <p-dropdown filter="true" [options]="leaveStatusReoortArray"
                                                            formControlName="leaveStatus" optionlabel="label"
                                                            placeholder="Enter Leave">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>


                                        </div>
                                        <div class="main-submit-button" style="margin-right: 26%;">
                                            <button type="submit" (click)="onSubmitLeave()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                            <!-- <span *ngIf="showLeave">
                                              <button type="submit" *ngIf="!leave" (click)="UpdateLeave()"
                                                  class="btn btn-secondary submit-btn">Update</button>
                                              <button type="submit" *ngIf="leave" (click)="onUpdateApproval()"
                                                  class="btn btn-secondary submit-btn">Update</button>
                                          </span> -->
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Update Info</span>
                <span *ngIf="show" class="color-black pl-1"> Create Info</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Edit Group Information
                                    </a></li>
                                <!-- <li [ngClass]="activeCategory==3?'active':''"><a>
                                      Additional Info</a></li>
                              <li [ngClass]="activeCategory==4?'active':''"><a>
                                      Job Info</a></li> -->




                            </ul>
                        </div>

                        <div *ngIf="activeCategory==2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="updateAccountForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputCD">
                                                        <label for="partyId">Party Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            [value]="partyId" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Number
                                                            of
                                                            Employees</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="numEmployees"
                                                            class="form-control small-input" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter No of Employess">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputCD">
                                                        <label for="exampleInputEmail1">Group Name<span
                                                                style="color:red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input formControlName="groupName" type="email"
                                                            class="form-control small-input" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Group Name">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Group Name Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input formControlName="GroupNameLocal" type="email"
                                                            class="form-control small-input" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Group Name LocalGroup Name">

                                                    </div>
                                                    <!-- <div class="col-lg-3 form-group rightFormA">
                                                      <label for="salutation">Salutation</label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <p-dropdown styleClass="small-dropdown"
                                                          [options]="salutationArray" placeholder="Salutation"
                                                          filter="true" formControlName="title">
                                                      </p-dropdown>
                                                  </div> -->


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputCD">
                                                        <label for="salutation">First Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            placeholder="First Name" formControlName="firstName">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="salutation">Last Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            placeholder="Last Name" formControlName="lastName">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputCD">
                                                        <label for="countryCode">Ticker symbol </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            placeholder="Ticker symbol	" formControlName="Tickersymbol">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="salutation">Comments</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            placeholder="Comments" formControlName="Comments">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputCD">
                                                        <label for="salutation">Title</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            placeholder="Title" formControlName="Title">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Office Site Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="officeSiteName"
                                                            class="form-control small-input" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Office Site Name">
                                                    </div>


                                                </div>
                                            </div>

                                            <!-- <div class="col-lg-12">
                                              <div class="row">
                                                  <div class="col-lg-3 form-group rightForm">
                                                      <label for="emailAddress">Email Address</label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <input type="text" class="form-control small-input"
                                                          placeholder="Email Address" formControlName="emailAddress">
                                                  </div>

                                                  <div class="col-lg-3 form-group rightFormA">
                                                      <label for="address1">Address 1<span
                                                              style="color:red">*</span></label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <input type="text" class="form-control small-input"
                                                          placeholder="Address 1" formControlName="address1">
                                                  </div>
                                              </div>
                                          </div>

                                          <div class="col-lg-12" *ngIf="!show">
                                              <div class="row">
                                                  <div class="col-lg-3 form-group rightForm">
                                                      <label for="address1">Address 2<span
                                                              style="color:red">*</span></label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <input type="text" class="form-control small-input"
                                                          placeholder="Address 2" formControlName="address2">
                                                  </div>

                                                  <div class="col-lg-3 form-group rightFormA">
                                                      <label for="city">City<span style="color:red">*</span></label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <input type="text" class="form-control small-input"
                                                          placeholder="city" formControlName="city">
                                                  </div>
                                              </div>
                                          </div>

                                          <div class="col-lg-12" *ngIf="!show">
                                              <div class="row">
                                                  <div class="col-lg-3 form-group rightForm">
                                                      <label for="city">Country<span
                                                              style="color:red">*</span></label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <p-dropdown styleClass="small-dropdown" [options]="array"
                                                          placeholder="Select Country" formControlName="countryGeoId"
                                                          filter="true">
                                                      </p-dropdown>
                                                  </div>
                                                  <div class="col-lg-3 form-group rightFormA">
                                                      <label for="city">State<span style="color:red">*</span></label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <p-dropdown styleClass="small-dropdown" [options]="stateArray"
                                                          placeholder="State" filter="true"
                                                          formControlName="stateProvinceGeoId">
                                                      </p-dropdown>
                                                  </div>

                                              </div>
                                          </div>

                                          <div class="col-lg-12">
                                              <div class="row">
                                                  <div class="col-lg-3 form-group rightForm">
                                                      <label for="pinCode">Pin Code<span
                                                              style="color:red">*</span></label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <input type="text" class="form-control small-input"
                                                          placeholder="Pin Code" formControlName="postalCode">
                                                  </div>
                                                  <div class="col-lg-3 form-group rightFormA">
                                                      <label for="city">Customer Type<span
                                                              style="color:red">*</span></label>
                                                  </div>
                                                  <div class="col-lg-3">
                                                      <p-dropdown styleClass="small-dropdown" [options]="customerType"
                                                          placeholder="Customer Type" filter="true"
                                                          formControlName="customerType">
                                                      </p-dropdown>
                                                  </div>

                                              </div>
                                          </div> -->
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputCD">
                                                        <label for="revenue">Annual Revenue</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            placeholder="Annual Revenue"
                                                            formControlName="annualRevenue">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA ">
                                                        <label for="desc">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            placeholder="Description" formControlName="description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputCD">
                                                        <label for="externalID">External Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            placeholder="External Id" formControlName="externalId">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="logoImage">Logo Image Url</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control small-input"
                                                            placeholder="Logo Image Url" formControlName="logoImageUrl">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputCD">
                                                        <label for="currency">Preferred Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown styleClass="small-dropdown" [options]="currencyList"
                                                            placeholder="Preferred Currency Uom ID"
                                                            formControlName="preferredCurrencyUomId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="statusId">Status ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown styleClass="small-dropdown" [options]="statusList"
                                                            placeholder="Status ID" formControlName="statusId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                        <div class="main-submit-button" style="margin-right:8%;">

                                            <button type="submit" (click)="updatePartyGroup()"
                                                class="btn btn-secondary submit-btn">Update</button>



                                        </div>

                                    </form>
                                </div>

                            </div>

                        </div>
                        <div *ngIf="activeCategory==3">
                            <div class="container-fluid">
                                <div class="row">

                                    <form class="w-100" [formGroup]="additionalDetailForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormD">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Type"
                                                            formControlName="infoType">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Terms</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Terms"
                                                            formControlName="terms">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormD">
                                                        <label for="exampleInputEmail1">Rep. </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Rep"
                                                            formControlName="rep">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Price Level </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Price Level" formControlName="priceLevel">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormD">
                                                        <label for="exampleInputEmail1">Preferred Send Method
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Preferred Send Method"
                                                            formControlName="prefeferredSendMethod">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Resale No. </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Resale No."
                                                            formControlName="resaleNo">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 81%;">
                                        <button type="submit" *ngIf="editMode" (click)="updateAdd()"
                                            class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submitAdd()"
                                            class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="activeCategory==4">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="jobInfoForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Job Status</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Job Status"
                                                            formControlName="jobStatus">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Start Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" placeholder="Start Date"
                                                            formControlName="startDate">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Projected End </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control"
                                                            placeholder="Projected End"
                                                            formControlName="projectedEndDate">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">End Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" placeholder="End Date"
                                                            formControlName="endDate">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Description" formControlName="description">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Job Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Job Type"
                                                            formControlName="jobType">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Product Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Product Type" formControlName="productType">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Service Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control"
                                                            placeholder="Service Type" formControlName="serviceType">
                                                    </div>


                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 74%;">
                                        <button type="submit" *ngIf="editMode" (click)="updateJob()"
                                            class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!editMode" (click)="submit()"
                                            class="btn btn-secondary submit-btn">Submit</button>

                                    </div>
                                </div>

                            </div>

                        </div>
                        <br> <br> <br> <br>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalTwo" tabindex="-1" role="dialog" aria-labelledby="exampleModalTwoTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Create Contact</span>
                <span *ngIf="show" class="color-black pl-1"> Update Contact</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4" style="border:1px solid;width: 100%;
          border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editMode? 'Update':'Create'}} Contact
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="contactForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: -8% !important;">
                                                            <label for="exampleInputEmail1">Allow Solicitation</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [options]="arrayA" placeholder="Select"
                                                                formControlName="allowSolicitation">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: 1%;">
                                                            <label for="exampleInputEmail1">Country Code</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Country Code"
                                                                formControlName="countryCode">
                                                        </div>



                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: -8% !important;">
                                                            <label for="exampleInputEmail1">Area Code</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Area Code" formControlName="areaCode">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: 1%;">
                                                            <label for="exampleInputEmail1">Contact Number</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Contact Number"
                                                                formControlName="contactNumber">
                                                        </div>




                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: -8% !important;">
                                                            <label for="exampleInputEmail1">Extension</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Extension" formControlName="extension">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: 1%;">
                                                            <label for="exampleInputEmail1">Mech Purpose Type Id<span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [options]="contactPurpose"
                                                                placeholder="Mech Purpose Type Id"
                                                                formControlName="contactMechPurposeTypeId"></p-dropdown>
                                                            <small
                                                                *ngIf="!!contactForm.controls.contactMechPurposeTypeId.invalid && !!contactForm.controls.contactMechPurposeTypeId.touched"
                                                                class="text-danger">Mech Purpose Type Id is
                                                                required</small>
                                                        </div>

                                                    </div>
                                                </div>



                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: -29%;">
                                            <!-- <button type="submit" *ngIf="editMode" (click)="updateContact()"
                                              class="btn btn-secondary submit-btn">Update</button> -->
                                            <button type="submit" (click)="submitContact()"
                                                class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalThree" tabindex="-1" role="dialog" aria-labelledby="exampleModalThreeTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Create Postal Address</span>
                <span *ngIf="show" class="color-black pl-1"> Update Postal Address</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">

                    <div class="w3-card-4 classCardPopUp">
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editMode? 'Update':'Create'}} Postal Address
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="addressForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                         padding-right: 9px!important;
                                         margin-left: 1%;">
                                                            <label for="exampleInputEmail1">Attn Name</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Attn Name" formControlName="attnName">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                         padding-right: 9px!important;
                                         margin-left: -2%;">
                                                            <label for="exampleInputEmail1">To Name</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="To Name" formControlName="toName">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                         padding-right: 9px!important;
                                         margin-left: 1%;">
                                                            <label for="exampleInputEmail1">Allow Solicitation </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Allow Solicitation"
                                                                [options]="solicitationArray" filter="true"
                                                                formControlName="allowSolicitation"></p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                         padding-right: 9px!important;
                                         margin-left: -2%;">
                                                            <label for="exampleInputEmail1">Address 1 </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Address 1" formControlName="address1">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                         padding-right: 9px!important;
                                         margin-left: 1%;">
                                                            <label for="exampleInputEmail1">Address 2</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Address 2" formControlName="address2">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                         padding-right: 9px!important;
                                         margin-left: -2%;">
                                                            <label for="exampleInputEmail1">City </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" placeholder="City"
                                                                formControlName="city">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                         padding-right: 9px!important;
                                         margin-left: 1%;">
                                                            <label for="exampleInputEmail1">Country </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [options]="countryArray" placeholder="Country"
                                                                formControlName="countryGeoId">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                         padding-right: 9px!important;
                                         margin-left: -2%;">
                                                            <label for="exampleInputEmail1">State </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [options]="stateArray" placeholder="State"
                                                                filter="true" formControlName="stateProvinceGeoId">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                         padding-right: 9px!important;
                                         margin-left: 1%;">
                                                            <label for="pinCode">Pin Code</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Pin Code" formControlName="postalCode">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                         padding-right: 9px!important;
                                         margin-left: -2%;">
                                                            <label for="mechId">Contact Mech Id</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Contact Mech Id"
                                                                formControlName="contactMechId">
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                         padding-right: 9px!important;
                                         margin-left: 1%;">
                                                            <label for="mechType">Contact Mech Purpose Type Id <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Contact Mech Purpose Type Id"
                                                                filter="true" [options]="contactMechTypeId"
                                                                formControlName="contactMechPurposeTypeId">
                                                            </p-dropdown>
                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: -24%;">
                                            <!-- <button type="submit" *ngIf="editMode" (click)="updateAddress()"
                                              class="btn btn-secondary submit-btn">Update</button> -->
                                            <button type="submit" (click)="submitAddress()"
                                                class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalFour" tabindex="-1" role="dialog" aria-labelledby="exampleModalFourTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Create Related Contacts</span>
                <span *ngIf="show" class="color-black pl-1"> Update Related Contacts</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li [ngClass]="activeCategoryA==1?'active':''"
                                    (click)="changeactiveCategoryFunctionA(1)"><a>
                                        Add Related Contacts </a></li>
                            </ul>
                        </div>
                        <div class="panel-group create-new-leade">

                            <div *ngIf="activeCategoryA==1">

                                <div class="panel-body">
                                    <form [formGroup]="fileUploadForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm"
                                                        style="margin-left: -9% !important;">
                                                        <label for="exampleInputEmail1">Contact Party Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="contactPartyId"
                                                            [options]="contactArray" optionlabel="label"
                                                            id="exampleInputEmail1" filter="true"
                                                            placeholder="Select Contact Id"></p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <textarea id="w3review" formControlName="comments" rows="8"
                                                            cols="50">
                                  </textarea>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="proper" style="margin-left: 71% !important;">
                                            <button type="submit" (click)="onSubmitBTN()"
                                                class="btn btn-secondary submit-btn mr-2">Save</button>
                                            <button type="submit" (click)="navigate()"
                                                class="btn btn-danger">Cancel</button>
                                        </div>
                                        <br>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalFive" tabindex="-1" role="dialog" aria-labelledby="exampleModalFiveTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Create Note</span>
                <span *ngIf="show" class="color-black pl-1"> Update Note</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li [ngClass]="activeCategoryB==1?'active':''"
                                    (click)="changeactiveCategoryFunctionValue(1)"><a>
                                        New Party Note</a></li>
                            </ul>
                        </div>
                        <div class="panel-group create-new-leade">

                            <div *ngIf="activeCategoryB==1">

                                <div class="panel-body">
                                    <form [formGroup]="fileUploadFormNote">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm"
                                                        style="margin-left: -13% !important;">
                                                        <label for="exampleInputEmail1">Note Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="noteName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Note Name">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm"
                                                        style="margin-left: -10% !important;">
                                                        <label for="exampleInputEmail1">Note</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="note" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Note">

                                                    </div>


                                                </div>
                                            </div>


                                        </div>

                                        <div class="proper" style="margin-right: 18%;">
                                            <div class="col-lg-12 col-12 main-submit-button">
                                                <button type="submit" (click)="onSubmitNote()"
                                                    *ngIf="!showUpdateNoteBtn"
                                                    class="btn btn-secondary submit-btn">Create</button>

                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateNoteBtn">
                                                <button type="submit" (click)="updateContactNote()"
                                                    class="btn btn-secondary submit-btn">Update</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalSix" tabindex="-1" role="dialog" aria-labelledby="exampleModalSixTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Create Credit Card</span>
                <span *ngIf="show" class="color-black pl-1"> Update Credit Card</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" >
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editModeA? 'Update':'Create'}} Credit Card
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="creditCardForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -6% !important;">
                                                            <label for="exampleInputEmail1">Company Name Card</label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="text" class="form-control"
                                                                placeholder="Company Name Card"
                                                                formControlName="companyNameOnCard">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Prefix Card</label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <p-dropdown placeholder="Prefix Card" filter="true"
                                                                [options]="prefixArray" formControlName="titleOnCard">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -6% !important;">
                                                            <label for="exampleInputEmail1">First Name</label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="text" class="form-control"
                                                                placeholder="First Name"
                                                                formControlName="firstNameOnCard">
                                                            <small class="text-danger"
                                                                *ngIf="!!creditCardForm.controls.firstNameOnCard.invalid && !!creditCardForm.controls.firstNameOnCard.touched">First
                                                                Name is Required</small>
                                                        </div>
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Middle Name Card</label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="text" class="form-control"
                                                                placeholder="Middle Name Card"
                                                                formControlName="middleNameOnCard">
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -6% !important;">
                                                            <label for="exampleInputEmail1">Last Name Card <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="text" class="form-control"
                                                                placeholder="Last Name Card"
                                                                formControlName="lastNameOnCard">
                                                            <small class="text-danger"
                                                                *ngIf="!!creditCardForm.controls.lastNameOnCard.invalid && !!creditCardForm.controls.lastNameOnCard.touched">Last
                                                                Name is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Suffix On Card
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <p-dropdown placeholder="Suffix On Card" filter="true"
                                                                [options]="suffixArray" formControlName="suffixOnCard">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -6% !important;">
                                                            <label for="exampleInputEmail1">Card Type
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <p-dropdown placeholder="Card Type" filter="true"
                                                                [options]="cardTypes" formControlName="cardType">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: 0%;">
                                                            <label for="exampleInputEmail1">Card Number</label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="email" class="form-control"
                                                                id="exampleInputEmail1" formControlName="cardNumber"
                                                                aria-describedby="emailHelp" placeholder="Card Number">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -6% !important;">
                                                            <label for="exampleInputEmail1">Expiration Date <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <p-dropdown placeholder="Month" filter="true"
                                                                [options]="expirationMonth" formControlName="expMonth">
                                                            </p-dropdown>
                                                            <small class="text-danger"
                                                                *ngIf="!!creditCardForm.controls.expMonth.invalid && !!creditCardForm.controls.expMonth.touched">Expiration
                                                                Month is Required</small>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <p-dropdown class="ml-2" placeholder="Year" filter="true"
                                                                [options]="expirationYear" formControlName="expYear">
                                                            </p-dropdown>


                                                            <small class="text-danger"
                                                                *ngIf="!!creditCardForm.controls.expYear.invalid && !!creditCardForm.controls.expYear.touched">Expiration
                                                                Year is Required</small>
                                                        </div>



                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -6% !important;">
                                                            <label for="exampleInputEmail1">Description</label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="email" class="form-control"
                                                                id="exampleInputEmail1" formControlName="description"
                                                                aria-describedby="emailHelp" placeholder="Description">
                                                        </div>
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: 0%;">
                                                            <label for="">Billing Address</label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <ng-container
                                                                *ngIf="accountDataCredit?.address;else noData">
                                                                <input type="checkbox" class="form-check-input"
                                                                    id="exampleCheck1"
                                                                    (change)="getAddress($event,accountDataCredit?.address.contactMechId)">
                                                                <label class="form-check-label" for="exampleCheck1">
                                                                    To:<span>{{accountDataCredit?.address.address1}}</span>,
                                                                    <span>{{accountDataCredit?.address.address2}}</span>,
                                                                    <span>{{accountDataCredit?.address.city}}</span>,
                                                                    <span>{{accountDataCredit?.address.stateProvinceGeoId}}</span>,
                                                                    <span>{{accountDataCredit?.address.countryId}}</span>,
                                                                    <span>{{accountDataCredit?.address.postalCode}}</span>
                                                                </label>
                                                            </ng-container>
                                                            <ng-template #noData>
                                                                No contact information on file.
                                                            </ng-template>
                                                        </div>


                                                    </div>
                                                </div>



                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: -10%;">
                                            <button type="submit" *ngIf="editModeA" (click)="updateCredit()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editModeA" (click)="submitCredit()"
                                                class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalSeven" tabindex="-1" role="dialog" aria-labelledby="exampleModalFiveTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Create Gift Card</span>
                <span *ngIf="show" class="color-black pl-1"> Update Gift Card</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton9>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" >
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editModeA? 'Update':'Create'}} Gift Card
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="giftForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -1% !important;">
                                                            <label for="exampleInputEmail1">Card Number <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control"
                                                                id="exampleInputEmail1" formControlName="cardNumber"
                                                                aria-describedby="emailHelp" placeholder="Card Number">
                                                            <small class="text-danger"
                                                                *ngIf="!!giftForm.controls.cardNumber.invalid && !!giftForm.controls.cardNumber.touched">Card
                                                                Number is Required</small>
                                                        </div>
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          ">
                                                            <label for="exampleInputEmail1">PIN number <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Pin Number" formControlName="pinNumber">
                                                            <small class="text-danger"
                                                                *ngIf="!!giftForm.controls.pinNumber.invalid && !!giftForm.controls.pinNumber.touched">PIN
                                                                Number is Required</small>
                                                        </div>



                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -1% !important;">
                                                            <label for="exampleInputEmail1">Expiration Date <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Month" filter="true"
                                                                [options]="expirationMonth" formControlName="expMonth">
                                                            </p-dropdown>
                                                            <small class="text-danger"
                                                                *ngIf="!!giftForm.controls.expMonth.invalid && !!giftForm.controls.expMonth.touched">Expiration
                                                                Month is Required</small>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown class="ml-2" placeholder="Year" filter="true"
                                                                [options]="expirationYear" formControlName="expYear">
                                                            </p-dropdown>


                                                            <small class="text-danger"
                                                                *ngIf="!!giftForm.controls.expYear.invalid && !!giftForm.controls.expYear.touched">Expiration
                                                                Year is Required</small>

                                                        </div>



                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 14px!important;
                                          margin-left: -37% !important;">
                                                            <label for="exampleInputEmail1">Description</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control"
                                                                id="exampleInputEmail1" formControlName="description"
                                                                aria-describedby="emailHelp" placeholder="Description">
                                                        </div>



                                                    </div>
                                                </div>


                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: -38%;">
                                            <button type="submit" *ngIf="editModeA" (click)="updateGift()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editModeA" (click)="submitGift()"
                                                class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalEight" tabindex="-1" role="dialog" aria-labelledby="exampleModalEightTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Create EFT Account</span>
                <span *ngIf="show" class="color-black pl-1"> Update EFT Account</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" >
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editModeA? 'Update':'Create'}} {{isEftAccount?'EFT':'Check'}} Account
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="eftAccountFormEFT">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -9% !important;">
                                                            <label for="exampleInputEmail1">Name Account <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Name Account"
                                                                formControlName="nameOnAccount">
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountFormEFT.controls.nameOnAccount.invalid && !!eftAccountFormEFT.controls.nameOnAccount.touched">Name
                                                                Account is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                       ">
                                                            <label for="exampleInputEmail1">Company Name On
                                                                Account</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Company Name On Account"
                                                                formControlName="companyNameOnAccount">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left:  -9% !important">
                                                            <label for="exampleInputEmail1">Bank Name <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Bank Name" formControlName="bankName">
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountFormEFT.controls.bankName.invalid && !!eftAccountFormEFT.controls.bankName.touched">Bank
                                                                Name is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        ">
                                                            <label for="exampleInputEmail1">Routing Number <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Routing Number"
                                                                formControlName="routingNumber">
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountFormEFT.controls.routingNumber.invalid && !!eftAccountFormEFT.controls.routingNumber.touched">Routing
                                                                Number is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -9% !important">
                                                            <label for="exampleInputEmail1">Account Type <span
                                                                    class="text-danger">*</span>
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Account Type" filter="true"
                                                                [options]="accountTypes" formControlName="accountType">
                                                            </p-dropdown>
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountFormEFT.controls.accountType.invalid && !!eftAccountFormEFT.controls.accountType.touched">Account
                                                                Type is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        ">
                                                            <label for="exampleInputEmail1">Account Number <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Account Number"
                                                                formControlName="accountNumber">
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountFormEFT.controls.accountNumber.invalid && !!eftAccountFormEFT.controls.accountNumber.touched">Account
                                                                Number is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -9% !important;">
                                                            <label for="exampleInputEmail1">Description <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control"
                                                                id="exampleInputEmail1" formControlName="description"
                                                                aria-describedby="emailHelp" placeholder="Description">
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountFormEFT.controls.description.invalid && !!eftAccountFormEFT.controls.description.touched">Description
                                                                is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                     ">
                                                            <label for="">Billing Address</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <ng-container *ngIf="accountDataEFT?.address;else noData">
                                                                <input type="checkbox" class="form-check-input"
                                                                    id="exampleCheck1"
                                                                    (change)="getAddress($event,accountDataEFT?.address.contactMechId)">
                                                                <label class="form-check-label" for="exampleCheck1">
                                                                    To:<span>{{accountDataEFT?.address.address1}}</span>,
                                                                    <span>{{accountDataEFT?.address.address2}}</span>,
                                                                    <span>{{accountDataEFT?.address.city}}</span>,
                                                                    <span>{{accountDataEFT?.address.stateProvinceGeoId}}</span>,
                                                                    <span>{{accountDataEFT?.address.countryId}}</span>,
                                                                    <span>{{accountDataEFT?.address.postalCode}}</span>
                                                                </label>
                                                            </ng-container>
                                                            <ng-template #noData>
                                                                No contact information on file.
                                                            </ng-template>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: -30%;">
                                            <button type="submit" *ngIf="editModeA" (click)="updateEFT()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editModeA" (click)="submitEFT()"
                                                class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalNine" tabindex="-1" role="dialog" aria-labelledby="exampleModalNineTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Create Check Account</span>
                <span *ngIf="show" class="color-black pl-1"> Update Check Account</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton11>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" >
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editModeA? 'Update':'Create'}} Check Account
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="eftAccountForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -7%;">
                                                            <label for="exampleInputEmail1">Name Account <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Name Account"
                                                                formControlName="nameOnAccount">
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountForm.controls.nameOnAccount.invalid && !!eftAccountForm.controls.nameOnAccount.touched">Name
                                                                Account is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                      ">
                                                            <label for="exampleInputEmail1">Company Name On
                                                                Account</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Company Name On Account"
                                                                formControlName="companyNameOnAccount">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -7%;">
                                                            <label for="exampleInputEmail1">Bank Name <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Bank Name" formControlName="bankName">
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountForm.controls.bankName.invalid && !!eftAccountForm.controls.bankName.touched">Bank
                                                                Name is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                      ">
                                                            <label for="exampleInputEmail1">Routing Number <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Routing Number"
                                                                formControlName="routingNumber">
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountForm.controls.routingNumber.invalid && !!eftAccountForm.controls.routingNumber.touched">Routing
                                                                Number is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -7%;">
                                                            <label for="exampleInputEmail1">Payment Method Type <span
                                                                    class="text-danger">*</span>
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Account Type" filter="true"
                                                                [options]="paymentMethodTypes"
                                                                formControlName="paymentMethodTypeId">
                                                            </p-dropdown>
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountForm.controls.paymentMethodTypeId.invalid && !!eftAccountForm.controls.paymentMethodTypeId.touched">Payment
                                                                Method
                                                                Type is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                         ">
                                                            <label for="exampleInputEmail1">Account Type <span
                                                                    class="text-danger">*</span>
                                                            </label>

                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Account Type" filter="true"
                                                                [options]="accountTypes" formControlName="accountType">
                                                            </p-dropdown>
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountForm.controls.accountType.invalid && !!eftAccountForm.controls.accountType.touched">Account
                                                                Type is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -7%;">
                                                            <label for="exampleInputEmail1">Account Number <span
                                                                    class="text-danger">*</span></label>

                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Account Number"
                                                                formControlName="accountNumber">
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountForm.controls.accountNumber.invalid && !!eftAccountForm.controls.accountNumber.touched">Account
                                                                Number is Required</small>
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                      ">
                                                            <label for="exampleInputEmail1">Description <span
                                                                    class="text-danger">*</span></label>

                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control"
                                                                id="exampleInputEmail1" formControlName="description"
                                                                aria-describedby="emailHelp" placeholder="Description">
                                                            <small class="text-danger"
                                                                *ngIf="!!eftAccountForm.controls.description.invalid && !!eftAccountForm.controls.description.touched">Description
                                                                is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="    text-align: right!important;
                                          padding-right: 17px!important;
                                          margin-left: -7%;">
                                                            <label for="">Billing Address</label>

                                                        </div>
                                                        <div class="col-lg-2">
                                                            <ng-container *ngIf="accountDataCheck?.address;else noData">
                                                                <input type="checkbox" class="form-check-input"
                                                                    id="exampleCheck1"
                                                                    (change)="getAddress($event,accountDataCheck?.address.contactMechId)">
                                                                <label class="form-check-label" for="exampleCheck1">
                                                                    To:<span>{{accountDataCheck?.address.address1}}</span>,
                                                                    <span>{{accountDataCheck?.address.address2}}</span>,
                                                                    <span>{{accountDataCheck?.address.city}}</span>,
                                                                    <span>{{accountDataCheck?.address.stateProvinceGeoId}}</span>,
                                                                    <span>{{accountDataCheck?.address.countryId}}</span>,
                                                                    <span>{{accountDataCheck?.address.postalCode}}</span>
                                                                </label>
                                                            </ng-container>
                                                            <ng-template #noData>
                                                                No contact information on file.
                                                            </ng-template>
                                                        </div>



                                                    </div>
                                                </div>


                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: -24%;">
                                            <button type="submit" *ngIf="editModeA" (click)="updateCheck()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editModeA" (click)="submitCheck()"
                                                class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalTen" tabindex="-1" role="dialog" aria-labelledby="exampleModalTenTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Create Check Account</span>
                <span *ngIf="show" class="color-black pl-1"> Update Check Account</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton12>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" >
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                    {{editModeA? 'Update':'Create'}} Billing Account
                                </h4>

                            </div>
                        </header>
                        <div class=" bg-white color-grey">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="billingAccountForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -5% !important;">
                                                            <label for="exampleInputEmail1">Account Limit</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Account Limit"
                                                                formControlName="accountLimit">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                       ">
                                                            <label for="exampleInputEmail1">Account Currency Uom
                                                                ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Account Currency Uom ID"
                                                                filter="true" [options]="currencyUomIds"
                                                                formControlName="accountCurrencyUomId">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -5% !important;;">
                                                            <label for="exampleInputEmail1">Contact Mech ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Contact Mech ID"
                                                                formControlName="contactMechId">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        ">
                                                            <label for="exampleInputEmail1">From Date</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="date" class="form-control"
                                                                placeholder="From Date" formControlName="fromDate">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -5% !important;">
                                                            <label for="exampleInputEmail1">Through Date </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="date" class="form-control"
                                                                placeholder="Through Date" formControlName="thruDate">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                            ">
                                                            <label for="exampleInputEmail1">Description
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="Description" formControlName="description">
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -5% !important;">
                                                            <label for="exampleInputEmail1">External Account ID
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                                placeholder="External Account ID"
                                                                formControlName="externalAccountId">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        ">
                                                            <label for="exampleInputEmail1">Party ID <span
                                                                    class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown placeholder="Party ID" filter="true"
                                                                [options]="partyIds" formControlName="partyId">
                                                            </p-dropdown>
                                                            <small class="text-danger"
                                                                *ngIf="!!billingAccountForm.controls.partyId.invalid && !!billingAccountForm.controls.partyId.touched">Party
                                                                ID is Required</small>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                        padding-right: 17px!important;
                                        margin-left: -5% !important;">
                                                            <label for="exampleInputEmail1">Available Balance</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p> ${{availableBalance}} <span> This is the amount which
                                                                    can be used
                                                                    for new orders.</span></p>
                                                        </div>



                                                    </div>
                                                </div>


                                            </div>
                                        </form>
                                        <div class="main-submit-button" style="margin-left: -26%;">
                                            <button type="submit" *ngIf="editModeA" (click)="updateBill()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editModeA" (click)="submitBill()"
                                                class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="qualModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalPayGrade Title"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Create Qualification</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton15>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li class="active">
                                    <a>
                                        Qualification
                                    </a>
                                </li>
                                <li>
                                    <!-- <a>
                                      Salary Steps
                                  </a> -->
                                </li>
                            </ul>
                        </div>
                        <form [formGroup]="createQualification" class="w-100">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputAD">
                                            <label for="exampleInputEmail1">
                                                Qualification Desc</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" formControlName="QualificationDesc" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Qualification Desc">
                                        </div>

                                        <div class="col-lg-3 form-group classInput__new_changes">
                                            <label for="exampleInputEmail1">Title</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" formControlName="Title" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Title">
                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputAD">
                                            <label for="exampleInputEmail1">Status ID
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown placeholder="Enter Status ID" formControlName="StatusID"
                                                [options]="VerifStatusIDArray" optionlabel="label" filter="true"
                                                optionlabel="label">

                                            </p-dropdown>
                                        </div>

                                        <div class="col-lg-3 form-group classInput__new_changes">
                                            <label for="exampleInputEmail1">Verif Status ID</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown placeholder="Enter Verif Status ID"
                                                formControlName="VerifStatusID" [options]="VerifStatusIDArray"
                                                optionlabel="label" filter="true">

                                            </p-dropdown>
                                        </div>

                                    </div>
                                </div>


                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputAD">
                                            <label for="exampleInputEmail1">Through Date</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="date" formControlName="ThroughDate" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Title">
                                        </div>

                                        <div class="col-lg-3 form-group classInput__new_changes">
                                            <label for="exampleInputEmail1">Party ID</label>
                                        </div>
                                        <div class="col-lg-3">
                                            {{this.partyId}}
                                            <!-- <p-dropdown placeholder="Enter Party ID" formControlName="PartyID"
                                              [options]="partyIdArray" optionlabel="label" filter="true">

                                          </p-dropdown> -->
                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputAD">
                                            <label for="exampleInputEmail1">Party Qual Type ID</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown placeholder="Enter Party Qual Type ID"
                                                formControlName="PartyQualTypeID" [options]="PartyQualTypeIdArray"
                                                optionlabel="label" filter="true">

                                            </p-dropdown>
                                        </div>

                                        <div class="col-lg-3 form-group classInput__new_changes">
                                            <label for="exampleInputEmail1">From Date
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="date" formControlName="fromDate" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Budget ID">

                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputAD">
                                            <label for="exampleInputEmail1">Comments</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" formControlName="Comments" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Comments">
                                        </div>


                                    </div>
                                </div>




                            </div>

                            <div class="main-submit-button" style="margin-right: 10%;">
                                <button type="submit" class="btn btn-secondary submit-btn"
                                    (click)="createQualifications()">Submit</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="profile1" tabindex="-1" role="dialog" aria-labelledby="profileUpdate" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a> Edit Personal Information</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateProfile">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" disabled
                                                            formControlName="partyID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Party ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Salutation
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="salutation" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Salutation">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">First Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="firstName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter First Name">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Middle Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="middleName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Middle Name">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Last Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="lastName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Last Name ">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Personal Title
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="personalTitle" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" value="Mr. "
                                                            placeholder="Enter Personal Title">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Suffix
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="suffix"
                                                            id="exampleInp utEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Suffix">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Nickname
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="nickname" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Nickname">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">First Name Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="firstNameLocal" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter First Name Local">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Middle Name Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="middleNameLocal" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Middle Name Local">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Last Name Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="lastNameLocal" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Name Local">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Other Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="otherLocal" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Other Local">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Member ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="memberID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Member ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Gender
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="gender"
                                                            [options]="genderArray" optionlabel="label"
                                                            placeholder="Enter Gender">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Birth Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control"
                                                            formControlName="birthDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Birth Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Decreased Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control"
                                                            formControlName="decreasedDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Decreased Date">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Height
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="height"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Height">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Weight
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="weight"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Weight">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Mother's Maiden Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="mothersMaidenName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Mother's Maiden Name">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Old Maritial Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="oldMaritialStatus"
                                                            [options]="yesNOArray" optionlabel="label"
                                                            placeholder="Enter Old Maritial Status">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Maritial Status Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="maritialStatusEnumID"
                                                            optionlabel="label" [options]="martialStatusArray"
                                                            placeholder="Enter Maritial Status Enum ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Social Security Number
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="socialSecurityNumber"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Social Securtiy Number">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Passport Number
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="passportNumber" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Passport Number">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Passport Expire Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control"
                                                            formControlName="passportExpiryDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Passport Expiry Date">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Total Years Work Experience
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="totalExperience" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Total Years Work Experience">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="comments" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Employee Status Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="employeeStatusEnumID"
                                                            optionlabel="label" [options]="EmploymentStatusEnumIdArray"
                                                            placeholder="Enter Employee Status Enum ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Residence Status Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" [options]="ResidenceStatusEnumIDArray"
                                                            formControlName="residenceStatusEnumID" optionlabel="label"
                                                            placeholder="Enter Residence Status Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Occupation
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="occupation" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Occupation">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Years With Employer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="yearswithEmployer" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Years With Employer">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Months With Employer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="monthsWithEmployer" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Months With Employer">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Existing Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="existingCustomer"
                                                            [options]="yesNOArray" optionlabel="label"
                                                            placeholder="Enter Existing Customer">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Card ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="cardID"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Card ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">US Citizen
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="usCitizen" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter US Citizenship">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Ethnicity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="ethnicity" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Ethnicity">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Account No.
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="accountNo" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Account Number">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Billing Rate Level
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="billingRateLevel" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Billing Rate Level">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">Preferred Currency Uom ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" [options]="currencyList"
                                                            formControlName="preferredCurrencyUomID" optionlabel="label"
                                                            placeholder="Enter Preferred Currency Uom ID">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="description" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Description">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput11">
                                                        <label for="exampleInputEmail1">External ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="externalID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter External ID">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="statusID"
                                                            [options]="crmArray" optionlabel="label"
                                                            placeholder="Enter Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button1" style="margin-left: 42%;">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="updateProfilePerson()">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>