import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'app-compdoc-detail-page',
  templateUrl: './compdoc-detail-page.component.html',
  styleUrls: ['./compdoc-detail-page.component.css']
})
export class CompdocDetailPageComponent implements OnInit {
 
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;


  activeCategory=1;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  surveyIdValue=true;
  show: boolean;
  contentId: any;
  contentRevisionSeqId: any;
  contentIdDocIns: any;
  contentRevisionSeqIdIns: any;
  updateCompocTemlateForm: FormGroup;
  createNewInstancesTemlateForm: FormGroup;
  ViewCompDocTemplateTreeAll: any;
  contentRevisionSeqIdTree: any;
  contentIdtree: any;
  AddChildCompDocArray: { label: string; value: string; }[];
  addChidForm: FormGroup;
  fromDateCate: string;
  throughDateCate: string;
  EditChildCompDocAll: any;



  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute:ActivatedRoute,
    private _location: Location,
  ) 
  {
    this.AddChildCompDocArray=[
      {
        label: 'MS Word',
        value: 'application/msword'
      },
      {
        label: 'PDF File',
        value: 'application/pdf'
      },
      {
        label: 'Survey',
        value: 'application/vnd.ofbiz.survey'
      },
      {
        label: 'Html Text',
        value: 'text/html'
      },
      {
        label: 'Plain Text',
        value: 'text/plain'
      },
      {
        label: 'JPEG',
        value: 'image/jpeg'
      },
      {
        label: 'GIF',
        value: 'image/gif'
      },
      {
        label: 'TIFF',
        value: 'image/tiff'
      },
      {
        label: 'PNG',
        value: 'document/pdf'
      },
      {
        label: 'Other',
        value: 'application/octet-stream'
      },
    ];
    this.addChidForm=this._FormBuilder.group({
      contentName:[''],
      parentContentId:[''],
      contentAssocTypeID:[''],
      fromDate:[''],
      throughDate:[''],
      sequenceNum:[''],
      documentType:[''],

    })
    this.updateCompocTemlateForm=this._FormBuilder.group({
      contentID:"",
      contentName:"",
    })

    this.createNewInstancesTemlateForm=this._FormBuilder.group({
      contentName:"",
    })
  }


  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.contentId = params["contentId"];
      this.contentRevisionSeqId = params["contentRevisionSeqId"];

    });
    this.show=true;
    this.ViewCompDocTemplateTreeNew();
    this.EditChildCompDoc();
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/hub/Dashboard']);
}
  
  EditChildCompDoc() {
    this.spinner.show();
    this.accountsService.EditChildCompDoc(this.contentId).subscribe(res => {
      this.EditChildCompDocAll = res.data;
      this.spinner.hide();
    })
  }

  createContentApprovalPatch() {
    this.addChidForm.reset();
    this.show=true;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.addChidForm;
        formValue.patchValue({
            parentContentId:this.contentId,
            contentAssocTypeID:"Composite Document Part"
        })
      }, 2000);
      this.spinner.hide();
  }
  createChildCompDocTemplate(): void {
    this.spinner.show();
    const fromDateCate=this.addChidForm.get('fromDate').value;
    this.fromDateCate=this.datePipe.transform(fromDateCate,"yyyy-MM-dd hh:mm:ss");

    const throughDateCate=this.addChidForm.get('throughDate').value;
    this.throughDateCate=this.datePipe.transform(throughDateCate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
    
      "caContentIdTo": this.addChidForm.value.parentContentId,
      "contentAssocTypeId": "COMPDOC_PART",
      "contentName": this.addChidForm.value.contentName,
      "contentTypeId": "TEMPLATE",
      "fromDate": this.fromDateCate,
      "mimeTypeId": this.addChidForm.value.documentType,
      "rootContentId": this.addChidForm.value.parentContentId,
      "rootContentRevisionSeqId": this.contentRevisionSeqId?this.contentRevisionSeqId:'',
      "sequenceNum": this.addChidForm.value.sequenceNum,
      "thruDate": this.throughDateCate
      }
     
    this.accountsService.createChildCompDocTemplate(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.EditChildCompDoc();
        this.closebutton4.nativeElement.click();
        this.addChidForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  referToCreateCompdoc(){
    this._Router.navigate(['/content/compDoc/new-root-compdoc-template']);
  }
  referToEditFromTree(){
    this.router.navigate(['/content/compDoc/edit'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  ViewCompDocTemplateTreeNew() {
    this.spinner.show();
    this.accountsService.ViewCompDocTemplateTree(this.contentId).subscribe(res => {
      this.ViewCompDocTemplateTreeAll = res.data;
      this.spinner.hide();
    })
  }

  openViewTree()
  {
    this._Router.navigate(['/content/compDoc/view-tree']);
  }
  openEdit()
  {
    this._Router.navigate(['/content/compDoc/edit']);
  }
  openApprovals()
  {
    this.router.navigate(['/content/compDoc/approvals'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openRevisions()
  {
    this.router.navigate(['/content/compDoc/revisions'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openCreateNewRoot()
  {
    this._Router.navigate(['/content/compDoc/new-root-compdoc-template']);
  }
  openViewWaitingApprovals()
  {
    this._Router.navigate(['/content/compDoc/view-waiting-approvals']);
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  
  }


}
