<div class="container-fluid main-container-wrapper">

    <div class="row">

        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                
                   <span class="color-black pl-1 titlePanels">Find Vendors</span>
                   <span>
                        <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                        <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>

                    </span>
 
            </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" style="padding-top: 9px!important;
                                                padding-bottom: 9px !important;" >
                                                        
                                                    <button type="button" data-toggle="modal" class="btn btn-outline-secondary " data-target="#exampleModalCenter">Create New Vendor</button>
                                                        <!-- <button type="button" routerLinkActive="active" routerLink="/vendor/list-vendor" class="btn btn-outline-secondary ">Vendors</button> -->
                                    
                                                        
                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 70%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                        </button>									
    
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="all-users-infomation font-13 font-medium  own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                            
                                                                            <div class="col-lg-3 form-group classInputC">
                                                                                <label for="exampleInputEmail1">Vendor ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown filter="true" name="partyId" [(ngModel)]="advanceSearch.partyIdSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" name="partyIdSearchType" [(ngModel)]="advanceSearch.partyId" class="form-control" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Vendor ID">
                                                                            </div>
                            
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Manifest Company Name</label>
                                                                            </div>
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown filter="true" name="manifestCompanyNameSearchType" [(ngModel)]="advanceSearch.manifestCompanyNameSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">
                            
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" name="manifestCompanyName" [(ngModel)]="advanceSearch.manifestCompanyName" class="form-control" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manifest Company Name">
                                                                            </div>
                            
                            
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                            
                                                                            <div class="col-lg-3 form-group classInputC">
                                                                                <label for="exampleInputEmail1">Manifest Company Title</label>
                                                                            </div>
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown filter="true" name="manifestCompanyTitleSearchType" [(ngModel)]="advanceSearch.manifestCompanyTitleSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">
                            
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" name="manifestCompanyTitle" [(ngModel)]="advanceSearch.manifestCompanyTitle" class="form-control" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manifest Company Title">
                                                                            </div>
                            
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Manifest Logo Url</label>
                                                                            </div>
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown name="manifestLogoUrlSearchType" [(ngModel)]="advanceSearch.manifestLogoUrlSearchType" [ngModelOptions]="{standalone:true}" filter="true" name="budgetIdSearchType" [options]="opportunityIdArray" optionlabel="label">
                            
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" name="manifestLogoUrl" [(ngModel)]="advanceSearch.manifestLogoUrl" class="form-control" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manifest Logo Url">
                                                                            </div>
                            
                            
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                            
                                     
                                                                            <div class="col-lg-3 form-group classInputC">
                                                                                <label for="exampleInputEmail1">Manifest Policies</label>
                                                                            </div>
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown name="manifestPoliciesSearchType" [(ngModel)]="advanceSearch.manifestPoliciesSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">
                            
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" name="manifestPolicies" [(ngModel)]="advanceSearch.manifestPolicies" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" placeholder="Enter Manifest Policies">
                                                                            </div>
                            
                            
                                                                        </div>
                                                                    </div>
                            
                            
                            
                            
                            
                                                                </div>
                    
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style="
                                                    margin-left: -27%;
                                                " class="col-lg-12 col-12 main-submit-button "><button type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts(false)">Find
                                                            </button>&nbsp;
                                                            <button type="button" (click)="reset()" class="btn btn-danger">Reset</button>
                                                        </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>

                                <!-- <div class="w3-card-4 classCard">


                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <button type="button" data-toggle="modal" class="btn btn-outline-secondary " data-target="#exampleModalCenter">Create New Vendor</button>
                                        <button type="button" routerLinkActive="active" routerLink="/vendor/list-vendor" class="btn btn-outline-secondary ">Vendors</button>
                                    
                                    </div><br>
                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInputC">
                                                    <label for="exampleInputEmail1">Vendor ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" name="partyId" [(ngModel)]="advanceSearch.partyIdSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" name="partyIdSearchType" [(ngModel)]="advanceSearch.partyId" class="form-control" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Vendor ID">
                                                </div>

                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Manifest Company Name</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" name="manifestCompanyNameSearchType" [(ngModel)]="advanceSearch.manifestCompanyNameSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" name="manifestCompanyName" [(ngModel)]="advanceSearch.manifestCompanyName" class="form-control" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manifest Company Name">
                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInputC">
                                                    <label for="exampleInputEmail1">Manifest Company Title</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" name="manifestCompanyTitleSearchType" [(ngModel)]="advanceSearch.manifestCompanyTitleSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" name="manifestCompanyTitle" [(ngModel)]="advanceSearch.manifestCompanyTitle" class="form-control" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manifest Company Title">
                                                </div>

                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Manifest Logo Url</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown name="manifestLogoUrlSearchType" [(ngModel)]="advanceSearch.manifestLogoUrlSearchType" [ngModelOptions]="{standalone:true}" filter="true" name="budgetIdSearchType" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" name="manifestLogoUrl" [(ngModel)]="advanceSearch.manifestLogoUrl" class="form-control" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Manifest Logo Url">
                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInputC">
                                                    <label for="exampleInputEmail1">Manifest Company Title</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" name="manifestCompanyTitleSearchType" [(ngModel)]="advanceSearch.manifestCompanyTitleSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" name="manifestCompanyTitle" [(ngModel)]="advanceSearch.manifestCompanyTitle" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" placeholder="Enter Manifest Company Title">
                                                </div>

                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Manifest Policies</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown name="manifestPoliciesSearchType" [(ngModel)]="advanceSearch.manifestPoliciesSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" name="manifestPolicies" [(ngModel)]="advanceSearch.manifestPolicies" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" class="form-control" placeholder="Enter Manifest Policies">
                                                </div>


                                            </div>
                                        </div>





                                    </div>

                                    <div style="
                                margin-left: -27%;
                            " class="col-lg-12 col-12 main-submit-button "><button type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts(false)">Find
                                        </button>&nbsp;
                                        <button type="button" (click)="reset()" class="btn btn-danger">Reset</button>
                                    </div>
                                </div> -->
                                <br>
                                
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Vendor List
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="listVendors" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>

                                                                        Party ID
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                    <div style="color: white;"> Manifest Company Name
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>

                                                                </th>

                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                    <div style="color: white;"> Manifest Company Title
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Manifest Logo Url
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Manifest Policies
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Action
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                    <div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span class="account-button" (click)="detailPage(product.partyId)">
                                                                            {{product.partyId}}</span>

                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{product.manifestCompanyName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{product.manifestCompanyTitle}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    {{product.manifestLogoUrl}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.manifestPolicies}}
                                                                </td>

                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <svg class="hover" (click)="update(product.partyId);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>

                                                                </td>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Vendor</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                <div class=" bg-white color-grey" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategory==2?'active':''"
                                                ><a>
                                                    General</a></li>
                                            <li [ngClass]="activeCategory==3?'active':''"
                                               ><a>
                                                    Contact</a></li>
                                            <li [ngClass]="activeCategory==4?'active':''"><a>
                                                    Address</a></li>
                                            <li [ngClass]="activeCategory==5?'active':''"
                                               ><a>
                                                    Financial</a></li>



                                        </ul>
                                    </div>

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="createVendor">
                                                    <div *ngIf="activeCategory==2">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Vendor
                                                                            ID<span style="color:red">*</span></label>
                                                                    </div>
                                                                    <!-- <div class="col-lg-3" *ngIf="!show">
                                                                        <p-dropdown formControlName="partyId" (onChange)="getVendorPartyIdListById($event.value)" placeholder="Enter Party ID" filter="true" [options]="listVendorsIntialArray" optionlabel="label">

                                                                        </p-dropdown>
                                                                    </div> -->
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown formControlName="partyId"
                                                                            placeholder="Choose Vendor ID" filter="true"
                                                                            [options]="partyIdArray"
                                                                            optionlabel="label">

                                                                        </p-dropdown>
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Name

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="name"
                                                                            formControlName="name" class="form-control"
                                                                            placeholder="Enter Name">
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Type of Product
                                                                            or Service Offered

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="type"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Type of Product or Service Offered"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Vendor Name

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="vendorName"
                                                                            placeholder="Enter Vendor Name"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Vendor
                                                                            Email

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="vendorEmail"
                                                                            placeholder="Enter Vendor Email"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Company Website

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="companyWebsite"
                                                                            id="exampleInputEmail1" placeholder="Enter Company Website "
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Vendor is
                                                                            Active

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="isActive"
                                                                            id="exampleInputEmail1" placeholder="Enter Vendor is Active"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Individual

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="text" formControlName="individual"
                                                                            id="exampleInputEmail1" placeholder="Enter Individual"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Vendor
                                                                            Forms

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="vendorForms"
                                                                            placeholder="Enter Vendor Forms" id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Map

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="map"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Map"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Manifest Company
                                                                            Title

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestCompanyTitle"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Company Title">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Manifest
                                                                            Policies

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestPolicies"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Policies">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Manifest Company
                                                                            Name

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestCompanyName"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Company Name">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Manifest Logo
                                                                            Url

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestLogoUrl"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Logo Url">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Billing Rate Level
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="billingRateLevel"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter billing Rate Level">
                                                                    </div>


                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Phone
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" class="form-control"
                                                                        formControlName="phone"
                                                                        placeholder="Enter Phone"
                                                                        id="exampleInputEmail1">
                                                                    </div>



                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Web Url Id
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="webUrlId"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter web Url Id">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Type Vendor
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="typeVendor"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter type Vendor">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Web Url
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="PrimaryWebUrl"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter primary Web Url">
                                                                    </div>



                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="main-submit-button" style="margin-right:11%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(3)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategory==3">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Vendor
                                                                            Phone

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="vendorPhone"
                                                                            placeholder="Enter Vendor Phone"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Vendor Fax

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="vendorFax"
                                                                            placeholder="Enter Vendor Fax"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Email
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryEmail"
                                                                            placeholder="Enter primary Email"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Primary Fax Area Code

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryFaxAreaCode"
                                                                            placeholder="Enter Primary Fax Area Code"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Fax Country Code
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryFaxCountryCode"
                                                                            placeholder="Enter Primary Fax Country Code"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Primary Fax Extension

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryFaxExtension"
                                                                            placeholder="Enter primary Fax Extension"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Fax Number
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryFaxNumber"
                                                                            placeholder="Enter Primary Fax Number"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Primary Phone Area Code

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryPhoneAreaCode"
                                                                            placeholder="Enter Primary Phone Area Code"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Phone Country Code
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryPhoneCountryCode"
                                                                            placeholder="Enter Primary Phone Country Code"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Primary Phone Extension

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryPhoneExtension"
                                                                            placeholder="Enter Primary Phone Extension"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Phone Number
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="primaryPhoneNumber"
                                                                            placeholder="Enter Primary Phone Number"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General Attn Name
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalAttnName"
                                                                            placeholder="Enter General Attn Name"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 13%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(2)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(4)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategory==4">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">General Address1
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalAddress1"
                                                                            placeholder="Enter General Address1"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General Address2

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalAddress2"
                                                                            placeholder="Enter General Address2"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">General State Province Geo Id
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                        formControlName="generalStateProvinceGeoId"
                                                                        placeholder="Enter General State Province Geo Id"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General City

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalCity"
                                                                            placeholder="Enter General City"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">General Country Geo Id
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalCountryGeoId"
                                                                            placeholder="Enter General Country Geo Id"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General Postal Code

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalPostalCode"
                                                                            placeholder="Enter general Postal Code"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                           


                                                        </div>
                 
                                                        <div class="main-submit-button" style="margin-right: 13%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(3)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(5)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategory==5">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Credit Limit

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="creditLimit"
                                                                            placeholder="Enter Credit Limit"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Terms
                                                                            


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Terms"
                                                                            formControlName="terms"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Info String

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="infoString"
                                                                            placeholder="Enter Info String"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">is Incorporated
                                                                            


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="isIncorporated"
                                                                            id="exampleInputEmail1" class="form-control"
                                                                            placeholder="Enter is Incorporated">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Group Name

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="groupName"
                                                                            placeholder="Enter group Name"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General To Name
                                                                            


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalToName"
                                                                            id="exampleInputEmail1" class="form-control"
                                                                            placeholder="Enter general To Name">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Company


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">

                                                                        <input type="email" formControlName="company"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Company"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Federal Tax ID

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="federalTaxId"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Company Tax ID"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Vendor
                                                                            Eligible
                                                                           

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Vendor Eligible"
                                                                            formControlName="eligibleFor"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Contact Mech Purpose Type Id
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter contact Mech Purpose Type Id"
                                                                            formControlName="contactMechPurposeTypeId"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Requires 1099
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="requires1099"
                                                                            placeholder="Enter Requires 1099"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Tax Id


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" placeholder="Enter Tax Id"
                                                                            formControlName="taxId"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
                                      


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">


                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Default Payables
                                                                            Account


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Default Payables Account"
                                                                            formControlName="accountNo"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Print Check As


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" placeholder="Enter Print Check As"
                                                                            formControlName="printCheckAs"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(4)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit" (click)="onSubmit()"
                                                                class="btn btn-secondary submit-btn">Save</button>

                                                        </div>
                                                    </div>



                                                </form>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>