import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import {HeaderService} from '../../../header/header.service';
import { SortEvent } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-return-view-order',
  templateUrl: './return-view-order.component.html',
  styleUrls: ['./return-view-order.component.css']
})
export class ReturnViewOrderComponent implements OnInit {
  products3: any;
  rowNumber=0;
  activeCategory = 1;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  orderValue:any;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  reasonType: any;
  itemStts: any;
  returnTypes: any;
  returnId: any;
  orderId: any;
  tabledata = [];
  mergedArray = [];
  orderTotal: any;
  morderAdjustmentId:any;
  mamount:any;
  mreturnTypeId:any;
  amountAlreadyCredited: any;
  amountAlreadyRefunded: any;
  descriptionOrderItemAdjustments = [];
  array: any[]=[];
  tableList = [];
  newArray = [];
  createRetrunAdjustmentList: any[]=[];
  createRetrunItemList: any[]=[];
  orderHeaderAdjustmentMap: any[]=[];
  constructor(readonly accountsService: AccountsService,
    readonly  HeaderService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router , public addSkillService:AddSkillService, 
    readonly _ActivatedRoute: ActivatedRoute,  readonly toastr:ToastrService,
    readonly datePipe: DatePipe) {
      
     }
     offerShowingLineData=[{
      des:'GZ-1006-1 ',
      order_qty:'1',
      return_qty:'Requested',
      unit_price:'US$1.99',
      return_price:'admin',
      reason_type:'2020-11-30 19:59:15.084	',
      item_status:'admin',
      includes:'2020-11-30 19:59:15.084	',
    }  ,
  
 ];
 valuedataManual=[{
  "amount":'0',
  "description":'',
  "orderAdjustmentId":'',
  "returnAdjustmentId": "",
  "returnAdjustmentTypeId": '',
  "returnItemSeqId": '',
  "returnItemTypeId": '',
  "returnTypeId": '',
  "id":"1"
 }]
 offerShowingLineColoumns = [
  { field: 'des', header: 'Description' },
  { field: 'order_qty', header: 'Order Qty' },
  { field: 'return_qty', header: 'Return Qty' },
  { field: 'unit_price', header: 'Unit Price' },
  { field: 'return_price', header: 'Return Price*' },
  { field: 'reason', header: 'Reason' },
  { field: 'type', header: 'Reason Type' },
  { field: 'item_status', header: 'Item Status' },
  { field: 'includes', header: 'Include ?' }, 
];
offerShowingLine = [
  { field: 'des', header: 'Description' },
  { field: 'order_qty', header: 'Amount' },
  { field: 'return_qty', header: 'Type' },
  { field: 'unit_price', header: 'Include?' },
  
];
ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.returnId = this._ActivatedRoute.snapshot.queryParams.returnID;
    this.orderId = this._ActivatedRoute.snapshot.queryParams.orderid;
    this.getReasonItemStatus();
    this.getOrderAnnualReturnHistory();
  }
  
  getOrderAnnualReturnHistory(): void {
    this.spinner.show();
    // mergedArray
    this.accountsService.getOrderAnnualReturnHistory( this.orderId, this.returnId).subscribe((res) => {
      this.tabledata = res.data.descriptionOrderItems;
      this.spinner.hide();
      this.descriptionOrderItemAdjustments=res.data.descriptionOrderItemAdjustments;
      this.orderHeaderAdjustmentMap=res.data.orderHeaderAdjustmentMap;
      for (let index = 0; index < this.tabledata.length; index++) {
        const element = this.tabledata[index];
        this.mergedArray.push(element);
        for (let index = 0; index < this.descriptionOrderItemAdjustments.length; index++) {
          const element = this.descriptionOrderItemAdjustments[index];
          this.mergedArray.push(element);
       
        }
     
      }
      this.getUniqueObjects();
      this.testFunction();
      this.orderTotal = res.data.orderTotal;
      this.amountAlreadyCredited = res.data.amountAlreadyCredited;
      this.amountAlreadyRefunded = res.data.amountAlreadyRefunded;
    
    });
   
  }
  getReasonItemStatus(): void {
    this.spinner.show();
    this.accountsService.getReasonItemStatus().subscribe((res) => {
      this.reasonType = res.data.returnReasons;
      this.itemStts = res.data.itemStts;
      this.returnTypes = res.data.returnTypes;
      this.spinner.hide();
    });
   
  }
  getUniqueObjects(){
    function getUnique(value, index, self){
      return self.indexOf(value) === index;
    }
    this.tableList = this.mergedArray.filter(getUnique);
   
  }
  testFunction(){
    
    let array = this.mergedArray;
    let tabledata = this.tabledata;
    let descriptionOrderItemAdjustments =  this.descriptionOrderItemAdjustments ;
    for (let index = 0; index < tabledata.length; index++) {
      const element = tabledata[index];
      const element1 = this.descriptionOrderItemAdjustments[index];
      this.newArray.push(element);
      this.newArray.push(element1);
     
    }
  }
  productSelect(value){
  
    if(value.amount!=null){
let data= {
  "amount": value.amount,
  "description": value.description,
  "orderAdjustmentId": value.orderAdjustmentId,
  "returnAdjustmentId": "",
  "returnAdjustmentTypeId": value.returnAdjustmentTypeId,
  "returnItemSeqId": value.returnItemSeqId==undefined?'':value.returnItemSeqId,
  "returnItemTypeId": "",
  "returnTypeId":value.returnTypeId
}
this.createRetrunAdjustmentList.push(data);
    }
    else{
let datavalue={
  "description": value.itemDescription,
  "expectedItemStatus":value.expectedItemStatus,
  "orderId": value.orderId,
  "orderItemSeqId": value.orderItemSeqId,
  "productId": value.productId,
  "returnItemTypeId": value.returnItemTypeId,
  "returnPrice": value.returnPrice,
  "returnQuantity": value.returnQuantity,
  "returnReasonId":value.returnReasonId,
  "returnTypeId":value.returnTypeId
}
this.createRetrunItemList.push(datavalue);
    }
  }
  
  
  adjustSelect(value){
 
    var index = this.createRetrunAdjustmentList.findIndex(x => x.orderAdjustmentId==value.orderAdjustmentId)
  if( index === -1){
    let data= {
      "amount": value.amount,
      "description": value.description,
      "orderAdjustmentId": value.orderAdjustmentId,
      "returnAdjustmentId": "",
      "returnAdjustmentTypeId": value.returnAdjustmentTypeId,
      "returnItemSeqId":value.returnItemSeqId,
      "returnItemTypeId": "",
      "returnTypeId": value.returnTypeId
    }
    this.createRetrunAdjustmentList.push(data);
          
  
  }
  else if(index === 0){
   
    this.createRetrunAdjustmentList.splice(index, 1);
  }
  else{
    this.createRetrunAdjustmentList.splice(index, 1);
   
  }

  }

  onSubmit(){
    this.spinner.show();
  let formData={
    "createRetrunAdjustmentList":this.createRetrunAdjustmentList,
    "createRetrunItemList":this.createRetrunItemList,
    "manualReturnAdjustmentForm":{
      "amount": this.mamount,
      "returnItemSeqId": "_NA_",
      "returnTypeId": this.mreturnTypeId.returnTypeId==undefined?'':this.mreturnTypeId.returnTypeId,
      "description": ""
    },
    "returnId":this.returnId

  }
   
     this.accountsService.postReturnItemList(formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.router.navigate(["/order/return-item"],{queryParams:{returnId:this.returnId}})
       
      }
      else if(res.success == false) {
        this.toastr.error("Error");
      }
    },(err) => {
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
   this.spinner.hide();
   }
   selectAll(e){
    
    this.createRetrunItemList=[]
    this.createRetrunAdjustmentList=[]
    if(e.target.ariaChecked=="true"){
     for(let v of this.tabledata){
      let datavalue={
        "description": v.itemDescription,
        "expectedItemStatus":v.expectedItemStatus,
        "orderId": v.orderId,
        "orderItemSeqId": v.orderItemSeqId,
        "productId": v.productId,
        "returnItemTypeId": v.returnItemTypeId,
        "returnPrice": v.returnPrice,
        "returnQuantity": v.returnQuantity,
        "returnReasonId":v.returnReasonId,
        "returnTypeId":v.returnTypeId
     }
     this.createRetrunItemList.push(datavalue);
   }
   for(let r of this.descriptionOrderItemAdjustments){
    let data= {
      "amount": r.amount,
      "description": r.description,
      "orderAdjustmentId": r.orderAdjustmentId,
      "returnAdjustmentId": "",
      "returnAdjustmentTypeId": r.returnAdjustmentTypeId,
      "returnItemSeqId":r.returnItemSeqId,
      "returnItemTypeId": "",
      "returnTypeId": r.returnTypeId
    }
    this.createRetrunAdjustmentList.push(data);
  }
  for(let t of this.orderHeaderAdjustmentMap){
    let data= {
      "amount": t.amount,
      "description": t.description,
      "orderAdjustmentId": t.orderAdjustmentId,
      "returnAdjustmentId": "",
      "returnAdjustmentTypeId": t.returnAdjustmentTypeId,
      "returnItemSeqId": t.returnItemSeqId,
      "returnItemTypeId": "",
      "returnTypeId": t.returnTypeId
    }
    this.createRetrunAdjustmentList.push(data);
  }
 }
  else{
    this.createRetrunItemList=[]
    this.createRetrunAdjustmentList=[]
  }
  
}

}
