import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from '../accounts/accounts.service';
import { MyContactsService } from '../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../events/create-events/create-event-service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  findMyTeam: { teamName: string; };
  findTeamList: any;
  createTeam: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService, 
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.findMyTeam = {
      teamName: ""
    };
    this.createTeam = this._FormBuilder.group({
      name: [''],
      comments: [''],
    })
   }

  ngOnInit(): void {
   this.getfindTeam();
  }
 
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }

  
  detailPage(id) {
    this._Router.navigate(["/crm/Teams/viewTeam"], { queryParams: { party: id } })
  }
  create(){
    this.spinner.show();
    const requestData = {
      "comments": this.createTeam.value.comments,
      "isDeactivated": "",
      "name":  this.createTeam.value.name,
      "partyId": ""
     
    }

    this.myContactsService.createTeam(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.createTeam.reset();
        this.getfindTeam();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  reset() {
    this.spinner.show();
    this.findMyTeam.teamName = "";

    this.myContactsService.getfindTeam().subscribe((res: any) => {
      this.findTeamList = res.data;
      this.spinner.hide();
    })
  }
  getfindTeam() {
    this.spinner.show();
    this.myContactsService.getfindTeam().subscribe(res => {
      this.findTeamList = res.data;
      this.spinner.hide();

    })
  }
  findTeam() {
    this.spinner.show();
    const req = {
      "name": this.findMyTeam.teamName ? this.findMyTeam.teamName : ' ',
    }
    this.myContactsService.findTeam(req.name).subscribe(res => {
      this.findTeamList = res.data;
      this.spinner.hide();

    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

}
