import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class CreateContactsService {

  constructor(readonly http: HttpClient) { }

  addContacts(): Observable<any> {
    return this.http.get(URLS.createContact);
  }
  getStatusCrm():Observable<any>{
    const statusCrm = URLS.getCRMStatus;
    return this.http.get(statusCrm);
  }
  createContact(data): Observable<any> {
    return this.http.post(URLS.createContact, data)
  }

  getCountry(): Observable<any> {
    return this.http.get(URLS.getCountry)
  }
  exportExcelProduct():Observable<Blob>{
    return this.http.get("/export/excel",{responseType:'blob'})
  }
  getContactList():Observable<any>{
    const getContactList = URLS.getContactList;
    return this.http.get(getContactList);
  }
  getState(data): Observable<any> {
    const getStateList = URLS.getState;
    const getStateLists = getStateList.replace('accState', data.trim())
    return this.http.get(getStateLists)
  }

  getContacts(contactDetails): Observable<any> {
    
    const getContacts = URLS.getDataByPartyId;
    const getContactsId = getContacts.replace('partyId', contactDetails)
    return this.http.get(getContactsId);
  }

  updateContacts(formData, partyId) {

    const getContacts = URLS.updateContact;
    const getContactsId = getContacts + "?partyId=" + partyId
    return this.http.put(getContactsId, formData);
  }

  importContact(formData):Observable<any>{
    const importAccount = URLS.importContact;
    return this.http.post(importAccount,formData);
  }

  
createBalanceSheet(formData):Observable<any>{
    const createBalanceSheet = URLS.createBalanceSheet;
    return this.http.post(createBalanceSheet,formData);
  }

  createTrialBalanceDetails(formData):Observable<any>{
    const createTrialBalanceDetails = URLS.createTrialBalanceDetails;
    return this.http.post(createTrialBalanceDetails,formData);
  }

  createTrialBalanceHeadingDetailsImport(formData):Observable<any>{
    const createTrialBalanceDetails = URLS.createTrialBalanceDetails;
    return this.http.post(createTrialBalanceDetails,formData);
  }

  createCashFlow(formData):Observable<any>{
    const createCashFlow = URLS.createCashFlow;
    return this.http.post(createCashFlow,formData);
  }

  createAccountGroupDetail(formData):Observable<any>{
    const createAccountGroupDetail = URLS.createAccountGroupDetail;
    return this.http.post(createAccountGroupDetail,formData);
  }

  CustomerDataToCSV(formData):Observable<any>{
    const CustomerDataToCSV = URLS.CustomerDataToCSV;
    return this.http.post(CustomerDataToCSV,formData);
  }


  CustomerSitesDataToCSV(formData):Observable<any>{
    const CustomerSitesDataToCSV = URLS.CustomerSitesDataToCSV;
    return this.http.post(CustomerSitesDataToCSV,formData);
  }


  SecurityGroupDataToCSV(formData):Observable<any>{
    const SecurityGroupDataToCSV = URLS.SecurityGroupDataToCSV;
    return this.http.post(SecurityGroupDataToCSV,formData);
  }



 
}
