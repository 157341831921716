import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-identification',
  templateUrl: './new-identification.component.html',
  styleUrls: ['./new-identification.component.css']
})
export class NewIdentificationComponent implements OnInit {
  show: boolean;
  activeCategory=2;
  createIdentification:FormGroup;
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
   
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
  ) {
    this.createIdentification = this._FormBuilder.group({
      identificationType: [''],
      idValue: [''],
     


    });
   }

  ngOnInit(): void {
    this.show=false
  }

}
