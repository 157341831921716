<div class="container-fluid main-container-wrapper pad_0 " style="padding: 0 !important;">
    <br>
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Data Resource</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>
    <div class=" bg-white color-grey">

        <div class="w3-card-4 classCard classCardType border_wdth_1">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                    <li [ngClass]="activeCategory==1?'active':''" (click)="findOpen()"><a>
                            Find </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" (click)="navigateOpen()"><a>
                            Navigate </a></li>

                </ul>
            </div>

            <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="panel-group">
                        <div>
                            <div class="panel-collapse">
                                <br>
                                <div class="w3-card-4 classCard classCardType" style="margin-top: -4px;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass"
                                            style="padding-left:9px">
                                            <li [ngClass]="activeCategoryMain==1?'active':''"
                                                (click)="changeactiveCategoryMain(1)"><a>
                                                    Edit</a></li>
                                            <li [ngClass]="activeCategoryMain==2?'active':''"
                                            *ngIf="this.dataResourceTypeID=='ELECTRONIC_TEXT'"
                                                (click)="changeactiveCategoryMain(2)"><a>
                                                    Text </a></li>

                                            <li [ngClass]="activeCategoryMain==3?'active':''"
                                               *ngIf="this.dataResourceTypeID=='ELECTRONIC_TEXT'"
                                                (click)="changeactiveCategoryMain(3)"><a>
                                                    HTML </a></li>

                                            <li [ngClass]="activeCategoryMain==7?'active':''"
                                            (click)="changeactiveCategoryMain(7)" 
                                            *ngIf="this.dataResourceTypeID=='LOCAL_FILE'||this.dataResourceTypeID=='OFBIZ_FILE'
                                            ||this.dataResourceTypeID=='CONTEXT_FILE'||this.dataResourceTypeID=='LOCAL_FILE_BIN'
                                            ||this.dataResourceTypeID=='OFBIZ_FILE_BIN'||this.dataResourceTypeID=='CONTEXT_FILE_BIN'
                                            ||this.dataResourceTypeID=='IMAGE_OBJECT'" ><a>
                                                    Upload</a></li>

                                            <li [ngClass]="activeCategoryMain==4?'active':''"
                                                (click)="changeactiveCategoryMain(4)"><a>
                                                    Attribute</a></li>

                                            <li [ngClass]="activeCategoryMain==5?'active':''"
                                                (click)="changeactiveCategoryMain(5)"><a>
                                                    Role</a></li>

                                            <li [ngClass]="activeCategoryMain==6?'active':''"
                                                (click)="changeactiveCategoryMain(6)"><a>
                                                    Product Feature</a></li>

                                            <button type="submit" class="btn btn-secondary submit-btn ml-2 mt-2"
                                                style="margin: 0;" data-toggle="modal"
                                                data-target="#creatContentDataResourcePopup">Create</button>
                                        </ul>
                                    </div>

                                    <div *ngIf="activeCategoryMain==1">
                                        <div class="create-new-leade mt-2">
                                            <div class="container-fluid">
                                                <div class="row" style="padding: 7px;">
                                                    <form class="w-100">
                                                        <div class="w3-card-4 classCard w-100">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                                style="justify-content: space-between;">
                                                                <p class="h-text">Summary</p>
                                                                <span>
                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                    (click)="generatePDFDataResource()">Download</button>

                                                                    <button type="submit"
                                                                        class="btn btn-secondary submit-btn ml-2"
                                                                        style="margin: 0;" data-toggle="modal"
                                                                        data-target="#updateSummaryPopup">Update</button>
                                                                </span>
                                                            </div>
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row" *ngIf="dataResourceSummary">
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Data Resource ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.dataResourceId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Data Resource
                                                                                        Type ID </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.dataResourceTypeId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Data Template
                                                                                        Type ID </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.dataTemplateTypeId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Data Category ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.dataCategoryId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Data Source ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.dataResourceId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Status ID </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.statusId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Data Resource
                                                                                        Name </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.dataResourceName}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Locale String
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.localeString}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Mime Type ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.mimeTypeId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Character Set ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.characterSetId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Object Info </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.objectInfo}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Survey ID </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.surveyId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Survey Response
                                                                                        ID </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.surveyResponseId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Related Detail
                                                                                        ID </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.relatedDetailId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Is Public </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.isPublic}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Created Date
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.createdStamp
                                                                                        | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Created By User
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.createdByUserLogin}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Last Modified
                                                                                        Date </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.lastUpdatedStamp
                                                                                        | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Last Modified By
                                                                                        User </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceSummary.dataNode.lastModifiedByUserLogin}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button _ngcontent-cta-c128="" type="submit" 
                                                        class="btn btn-secondary submit-btn" *ngIf="showButton">Download</button>

                                                        <div class="w3-card-4 classCard mt-3">

                                                            <div
                                                                class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="dataResourceById"
                                                                                [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%"
                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [responsive]="true"
                                                                                [totalRecords]="total"
                                                                                [scrollable]="true"
                                                                                styleClass="p-datatable-customers"
                                                                                (sortFunction)="customSort($event)"
                                                                                [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code"
                                                                                            [ngStyle]="{'width':'190px'}">
                                                                                            <div class="checkbox-align"
                                                                                                style="color: white;">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                Content ID <p-sortIcon
                                                                                                    field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Content Name<p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                                            pSortableColumn="category">
                                                                                            <div style="color: white;">
                                                                                                Description

                                                                                                <p-sortIcon
                                                                                                    field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>



                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body"
                                                                                    let-product>
                                                                                    <tr>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'200px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                <span class="account-button" (click)="referTocontent(product.dataNods.contentId)">{{product.dataNods.contentId}}</span>
                                                                                            </div>
                                                                                        </td>


                                                                                        <td
                                                                                            [ngStyle]="{'width':'190px'}">
                                                                                            {{product.dataNods.contentName}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'190px'}">
                                                                                            {{product.dataNods.description}}
                                                                                        </td>


                                                                                    </tr>

                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage"
                                                                                    let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">No
                                                                                                    Record Found</h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                    <div *ngIf="activeCategoryMain==2">
                                        <div class="create-new-leade mt-2">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <form class="w-100">
                                                        <div class="w3-card-4 classCard"
                                                            style="width:98% !important; margin-left: 1%;">
                                                            <div
                                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                Text
                                                            </div><br>

                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <form class="w-100" [formGroup]="electronicTextForm">

                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">
                                                                                    Data Resource ID</label>
                                                                            </div>

                                                                            <div class="col-lg-2">
                                                                                <input type="email" disabled
                                                                                    class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Data Resource ID"
                                                                                    formControlName="dataResourceID">


                                                                            </div>

                                                                            <div
                                                                                class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Text
                                                                                    Data
                                                                                </label>
                                                                            </div>

                                                                            <div class="col-lg-2">
                                                                                <textarea name="" id="exampleInputEmail1" class="form-control" cols="30"
                                                                                rows="10" formControlName="textData" placeholder="Enter Text Data" style="height: 71px !important;"></textarea>
                                                                                <!-- <input type="email" class="form-control"
                                                                                    id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Text Data"
                                                                                    formControlName="textData"> -->

                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>


                                                            <div class="col-lg-12 col-12 main-submit-button "
                                                                style="    margin-left:-25%;">
                                                                <button _ngcontent-cta-c128="" type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="updateElectronicText()">
                                                                    Update</button>&nbsp;
                                                            </div>
                                                        </div><br>


                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="activeCategoryMain==3">
                                        <div class="create-new-leade mt-2">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <form class="w-100">
                                                        <div class="w3-card-4 classCard"
                                                            style="width:98% !important; margin-left: 1%;">
                                                            <div
                                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                HTML
                                                            </div><br>

                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <form class="w-100" [formGroup]="htmlForm">

                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">
                                                                                    Data Resource ID</label>
                                                                            </div>

                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                 disabled   id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Data Resource ID"
                                                                                    formControlName="dataResourceID">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div
                                                                            class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Text
                                                                                Data
                                                                            </label>
                                                                        </div>
                                                                            <div class="col-lg-9">
                                                                                <ckeditor data="dataa" formControlName="textData"></ckeditor>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-10">
                                                                         <ckeditor editorUrl="https://your-website.example/ckeditor/ckeditor.js">
                                                                            
                                                                         </ckeditor>
                                                                         </div>
                                                                        </div>
                                                                    </div> -->
                                                                </form>
                                                            </div>


                                                            <div class="col-lg-12 col-12 main-submit-button "
                                                                style="    margin-left:-25%;">
                                                                <button _ngcontent-cta-c128="" type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="updateHTMLText()">Save</button>&nbsp;
                                                            </div>
                                                        </div><br>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="activeCategoryMain==4">

                                        <div class="create-new-leade">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="bg-white color-grey w-100 ">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="w3-card-4 classCardView mt-2 w-100">
                                                                            <div
                                                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                Data Resource Attribute

                                                                                <button type="submit"
                                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                                    style="margin-left:63%!important;"
                                                                                    (click)="reset()"
                                                                                    data-toggle="modal"
                                                                                    data-target="#addAnAttributePopup">Add
                                                                                    Data Resource Attribute

                                                                                </button>

                                                                            </div>
                                                                            
                                                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">

                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="addDataResourceAttributeById"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                                            pSortableColumn="code">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>

                                                                                                                Data
                                                                                                                Resource
                                                                                                                ID
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Attr
                                                                                                                Name
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Attr
                                                                                                                Value
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Attr
                                                                                                                Description
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Action
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>


                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                <span
                                                                                                                class="account-button">
                                                                                                                    {{product.dataResourceId}}
                                                                                                                </span>

                                                                                                            </div>
                                                                                                        </td>

                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                                            {{product.attrName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                                            {{product.attrValue}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                                            {{product.attrDescription}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                                            <button
                                                                                                                type="submit"
                                                                                                                class="btn btn-secondary submit-btn ml-2"
                                                                                                                (click)="updateAttribute(product)"
                                                                                                                data-toggle="modal"
                                                                                                                data-target="#addAnAttributePopup">Update</button>

                                                                                                            <button
                                                                                                                type="submit"
                                                                                                                class="btn btn-danger buttonclass ml-2"
                                                                                                                (click)="removeDataResourceAttribute(product)">Delete</button>


                                                                                                        </td>

                                                                                                    </tr>
                                                                                                </ng-template>

                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>

                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>




                                    </div>
                                    <div *ngIf="activeCategoryMain==5">
                                        <div class="create-new-leade">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="bg-white color-grey w-100 ">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="w3-card-4 classCardB"
                                                                            style="width: 100%;">
                                                                            <div
                                                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                Data Resource Role

                                                                                <button type="submit"
                                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                                    style="margin-left:71%!important;"
                                                                                    (click)="resetRole()"
                                                                                    data-toggle="modal"
                                                                                    data-target="#rolesPopup">Data
                                                                                    Resource Role

                                                                                </button>

                                                                            </div>
                                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select"
                                                                                style="display: block!important;">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">

                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="dataResourceRoleById"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </p-checkbox>

                                                                                                                Data
                                                                                                                Resource
                                                                                                                ID
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th pSortableColumn="name"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Party ID
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th pSortableColumn="category"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Role
                                                                                                                Type ID
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th pSortableColumn="price"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                From
                                                                                                                Date
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th pSortableColumn="price"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Through
                                                                                                                Date
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th pSortableColumn="price"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Action
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>


                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </p-checkbox>
                                                                                                                <span
                                                                                                                class="account-button">{{product.getAddDataResourceRoleById.dataResourceId}}</span>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.getAddDataResourceRoleById.partyId}}
                                                                                                        </td>

                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.getAddDataResourceRoleById.roleTypeId}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.fromDate}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.thruDate}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">

                                                                                                            <button
                                                                                                                type="submit"
                                                                                                                class="btn btn-secondary submit-btn"
                                                                                                                data-toggle="modal"
                                                                                                                data-target="#rolesPopup"
                                                                                                                (click)="updateRole(product)">Update</button>
                                                                                                            <button
                                                                                                                type="submit"
                                                                                                                class="btn btn-danger buttonclass"
                                                                                                                (click)="removeAddDataResourceRole(product)">Delete</button>


                                                                                                        </td>


                                                                                                    </tr>
                                                                                                    <ng-template
                                                                                                        pTemplate="emptymessage"
                                                                                                        let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span
                                                                                                                    class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <div *ngIf="activeCategoryMain==6">

                                        <div class="create-new-leade">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="bg-white color-grey w-100 ">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="w3-card-4 classCardView mt-2 w-100">
                                                                            <div
                                                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                Data Resource Product Features

                                                                                <button type="submit"
                                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                                    style="margin-left:52%!important;"
                                                                                    data-toggle="modal"
                                                                                    data-target="#dataResourceProductFeaturesPopup">Add
                                                                                    Data Resource Product Features

                                                                                </button>

                                                                            </div>



                                                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">

                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="dataResourceProductFeature"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                                            pSortableColumn="code">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>

                                                                                                                Data
                                                                                                                Resource
                                                                                                                ID
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Product
                                                                                                                Feature
                                                                                                                ID
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Action
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>


                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                <span
                                                                                                                class="account-button">
                                                                                                                    {{product.dataResourceId}}
                                                                                                                </span>

                                                                                                            </div>
                                                                                                        </td>

                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">
                                                                                                            {{product.productFeatureId}}
                                                                                                        </td>

                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'180px'}">

                                                                                                            <button
                                                                                                                type="submit"
                                                                                                                (click)="removeDataResourceProductFeature(product)"
                                                                                                                class="btn btn-danger  ml-2">Delete</button>


                                                                                                        </td>

                                                                                                    </tr>
                                                                                                </ng-template>

                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>

                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div *ngIf="activeCategoryMain==7">
                                        <div class="create-new-leade mt-2">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <form class="w-100">
                                                        <div class="w3-card-4 classCard w-100">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                                style="justify-content: space-between;">
                                                                <p class="h-text">Summary</p>
                                                                <span>
                                                                    <button type="submit"
                                                                        class="btn btn-secondary submit-btn ml-2"
                                                                        style="margin: 0;" data-toggle="modal"
                                                                        data-target="#uploadsPopup">Update</button>

                                                                </span>
                                                            </div>
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">

                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Data Resource ID
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.dataResourceId}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Uploaded File
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 font-weight-bold">
                                                                                        {{this.uploadImageData.objectInfo}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="creatContentDataResourcePopup" tabindex="-1" role="dialog"
    aria-labelledby="creatContentDataResourcePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Content Data Resource</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Content Data Resource</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createContentDataResourceForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Resource ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="dataResourceID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Data Resource ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Resource Type ID</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="dataResourceTypeID"
                                                            [options]="DataResourceTypeArray"
                                                            placeholder="Select Data Resource Type ID">

                                                        </p-dropdown>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Template Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="dataTemplateTypeID"
                                                            [options]="dataTemplateTypeArray"
                                                            placeholder="Select Data Template Type ID">

                                                        </p-dropdown>


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Category ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="dataCategoryID"
                                                            [options]="dataCategoryArray"
                                                            placeholder="Select Data Category ID">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Source ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="dataSourceID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Data Source ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Resource Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="dataResourceName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Data Resource Name">


                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Locale String

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="localeString" [options]="localStringArray"
                                                            placeholder="Select Locale String	                                  ">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Mime Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="mimeTypeID" [options]="mimeTypeIdDataArray"
                                                            placeholder="Select Mime Type ID ">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Character Set ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="characterSetID"
                                                            [options]="CharacterSetIdArray" placeholder="Select Character Set ID	
                                                        ">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Object Info </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="objectInfo"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Object Info">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Survey ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="surveyID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Survey ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Survey Response ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="surveyResponseID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Survey Response ID">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Related Detail ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="relatedDetailID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Related Detail ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Is Public
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="isPublic" [options]="yesNoArray"
                                                            placeholder="Select Is Public">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="createdDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" >

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Created By User </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="createdByUser"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Created By User">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="lastModifiedDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            >

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Last Modified By User</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="lastModifiedByUser"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Modified By User">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createDataResource()">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addAnAttributePopup" tabindex="-1" role="dialog" aria-labelledby="addAnAttributePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add an attribute


                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add an attribute
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAttributeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Data Resource ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" disabled class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Data Resource ID"
                                                            formControlName="dataResourceID">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Attr Name<span
                                                                style="color:red ;">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="attrName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Attr Name">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Attr Value </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="attrValue"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Attr Value">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Attr Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="attrDescription"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Attr Description">

                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!showAttri"
                                                    (click)="AddDataResourceAttribute()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="showAttri"
                                                    (click)="updateDataResourceAttribute()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="rolesPopup" tabindex="-1" role="dialog" aria-labelledby="rolesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add a Role

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a Role

                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="rolesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Data Resource ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" disabled class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Data Resource ID"
                                                            formControlName="dataResourceID">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Party ID<span
                                                                style="color: red;">*</span>
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" *ngIf="!showRole" optionlabel="label"
                                                            formControlName="partyID" placeholder="Select Party ID"
                                                            [options]="partyIdArray">
                                                        </p-dropdown>

                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" disabled *ngIf="showRole"
                                                            formControlName="partyID" placeholder="Enter Party ID">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="accountLimit">Role Type ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Select Role Type ID"
                                                            *ngIf="!showRole" optionlabel="label"
                                                            formControlName="roleTypeID"
                                                            [options]="ContentRoleTypeIdArray">
                                                        </p-dropdown>
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            disabled *ngIf="showRole" aria-describedby="emailHelp"
                                                            placeholder="Enter Role Type ID" formControlName="roleTypeID">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>


                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" 
                                                            *ngIf="!showRole" formControlName="fromDate">
                                                        <input type="text" disabled class="form-control" id="exampleInputEmail1"
                                                            disabled *ngIf="showRole" aria-describedby="emailHelp"
                                                             formControlName="fromDate">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" 
                                                            formControlName="throughDate">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!showRole" (click)="addDataResourceRole()">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="showRole"
                                                    (click)="updateAddDataResourceRole()">Update</button>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="dataResourceProductFeaturesPopup" tabindex="-1" role="dialog"
    aria-labelledby="dataResourceProductFeaturesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Data Resource Product Features

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Data Resource Product Features

                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="productFeaturesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Data Resource ID</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <input type="email" disabled formControlName="dataResourceID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Data Resource ID">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA"
                                                        style="margin-left:7%!important;">
                                                        <label for="exampleInputEmail1">Product Feature ID
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="productFeatureID"
                                                            [options]="ProductFeatureIdListArray"
                                                            placeholder="Select Product Feature ID">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>


                                            <div class="col-lg-5 main-submit-button" style="margin-left: 39%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createDataResourceProductFeature()">Create</button>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateSummaryPopup" tabindex="-1" role="dialog" aria-labelledby="updateSummaryPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Content Data Resource</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Content Data Resource</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateContentDataResourceForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Resource ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" disabled formControlName="dataResourceID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Data Resource ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Resource Type ID</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="dataResourceTypeID"
                                                            [options]="DataResourceTypeArray"
                                                            placeholder="Choose Data Resource Type ID">

                                                        </p-dropdown>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Template Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="dataTemplateTypeID"
                                                            [options]="dataTemplateTypeArray"
                                                            placeholder="Select Data Template Type ID">

                                                        </p-dropdown>


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Category ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="dataCategoryID"
                                                            [options]="dataCategoryArray"
                                                            placeholder="Select Data Category ID">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Source ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="dataSourceID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Data Source ID">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Status ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="statusID"
                                                            [options]="dataResourceStatusIdArray"
                                                            placeholder="Choose Status ID">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Resource Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="dataResourceName"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Data Resource Name">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Locale String

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="localeString" [options]="localStringArray"
                                                            placeholder="Choose Locale String	
                                                        ">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Mime Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="mimeTypeID" [options]="mimeTypeIdDataArray"
                                                            placeholder="Choose Mime Type ID	
                                                        ">

                                                        </p-dropdown>
                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Character Set ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="characterSetID"
                                                            [options]="CharacterSetIdArray" placeholder="Choose Character Set ID	
                                                        ">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Object Info </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="objectInfo"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Object Info">

                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Survey ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="surveyID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Survey ID">

                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Survey Response ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="surveyResponseID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Survey Response ID">

                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Related Detail ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="relatedDetailID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Related Detail ID">

                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Is Public
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="isPublic" [options]="yesNoArray"
                                                            placeholder="Choose Is Public	
                                                        ">

                                                        </p-dropdown>
                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="createdDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" >

                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Created By User </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="createdByUser"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Created By User">

                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="lastModifiedDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" >

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">



                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Last Modified By User</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="lastModifiedByUser"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Last Modified By User">

                                                    </div>




                                                </div>
                                            </div>



                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="updateDataResource()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="uploadsPopup" tabindex="-1" role="dialog" aria-labelledby="uploadsPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Content Data Resource</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Content Data Resource</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="uploadFileForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Data Resource ID</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <input type="email" formControlName="dataResourceID"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Data Resource ID">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Uploaded File

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="uploadedFile"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Uploaded File">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Upload File

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="file" class="form-control" id="exampleInputEmail1"
                                                        (change)="fileProgressCom($event)"  formControlName="uploadFile" aria-describedby="emailHelp"
                                                            placeholder="Enter Upload File">

                                                    </div>


                                                </div>
                                            </div>


                                            <div class="col-lg-5 main-submit-button" style="margin-left: 39%;">
                                            
                                                <button type="submit"
                                                    class="btn btn-secondary submit-btn" (click)="uploadImage()">Update</button>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ngx-spinner></ngx-spinner>