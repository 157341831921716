import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-time-sheet',
  templateUrl: './time-sheet.component.html',
  styleUrls: ['./time-sheet.component.css']
})
export class TimeSheetComponent implements OnInit {

  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) { }

  ngOnInit(): void {
  }

  toWorkFlowSettings()
  {
    this._Router.navigate(['/fsm/fsmSetting/work-flow-settings'])
  }

  toTimeSheetPeriod()
  {
    this._Router.navigate(['/fsm/fsmSetting/time-sheet-period'])

  }

  toOverTimeRules()
  {

    this._Router.navigate(['/fsm/fsmSetting/over-time-rules'])

  }
  

  toRoundingRules()
  {
    this._Router.navigate(['/fsm/fsmSetting/rounding-rules'])

  }


  toListViewConfiguration()
  {
    this._Router.navigate(['/fsm/fsmSetting/list-view-configuration'])

  }

  toReconcileFlagRules()
  {
    this._Router.navigate(['/fsm/fsmSetting/reconcile-flag-rules'])

  }

  toNewWorkOrder()
  {
    this._Router.navigate(['fsm/newWorkOrder'])
  }

  toCustomer()
  {
    this._Router.navigate(['fsm/customer'])
  }

  toReports()
  {
    this._Router.navigate(['/fsm/reports'])
  }

  toInvoices()
  {
    this._Router.navigate(['/fsm/invoices'])

  }

  toWorkOrder()
  {
    this._Router.navigate(['/fsm/main'])
  }

  toPeople()
  {
    this._Router.navigate(['/fsm/people'])
  }

  toTimeSheet()
  {
    this._Router.navigate(['/fsm/timesheet'])
  }

  toSetting()
  {
    this._Router.navigate(['/fsm/fsmSetting/work-order'])
  }


}

