import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { FacilityGroupService } from './facility-group.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-facility-group',
  templateUrl: './facility-group.component.html',
  styleUrls: ['./facility-group.component.css']
})
export class FacilityGroupComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  activeCategory = 2;
  total=0;
  activeCat = 2;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  parentGroupListDropdown: any = [];
  facilityGroupList: any = [];
  facilityGroupTypeList: any = [];
  facilityId: any = [];
  facilityGroup: FormGroup;
  facilityGroupName: any;
  editMode: boolean;

  success: any;
  primaryParentGroupId: any;
  facilityGroupId: any;
  facilityGroupTypeListArray: any[]=[];
  parentGroupListDropdownArray: any[]=[];

  constructor(readonly accountsService: AccountsService,
    readonly facilityGroupService: FacilityGroupService,
    readonly spinner: NgxSpinnerService,
  private _location: Location,
  readonly router: Router,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder) {
      this.facilityGroup = this._FormBuilder.group({
        facilityGroupTypeId: [null, [Validators.required]],
        facilityGroupName:[null, [Validators.required]],
        facilityGroupId: null,
        primaryParentGroupId: null,
        description: null,
       
      });
      this.editMode = false;
     }
     ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
      this.products3 = this.accountsService.customerDetails()
  
      this.getparentGroupList();
      this.getFacilityGroupList();
      this.getfacilityGroupTypeList();
    }
    Main(){
      this.router.navigate(["/facilities/warehouse"])
    }
    Facility(){
      this.router.navigate(["/facilities/facility-group"])
    }
    Inventory(){
      this.router.navigate(["/facilities/inventory-labels"])
    }
    Shipments(){
      this.router.navigate(["/facilities/warehouse-shipment"])
    }
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;
  
          if (value1 == null && value2 != null){
            result = -1;
          }
          else if (value1 != null && value2 == null){
            result = 1;
          }
          else if (value1 == null && value2 == null) {
              result = 0;
           }
          else if (typeof value1 === 'string' && typeof value2 === 'string') {
              result = value1.localeCompare(value2);
          }
          else {
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
      });
    }  
    
    getparentGroupList() {
      this.spinner.show();
      this.facilityGroupService.getparentGroupList().subscribe((responce) => {
        this.parentGroupListDropdown = responce.data;
        this.spinner.hide();
        for(const value of this.parentGroupListDropdown) {
          this.parentGroupListDropdownArray.push({
            label:value.facilityGroupId,
            value:value.facilityGroupId
          })
        }
      
      }, (error) => {
        return;
      });
     
    }
   
    getFacilityGroupList() {
      this.spinner.show();
      this.facilityGroupList = [];
      this.facilityGroupService.getFacilityGroupList().subscribe((responce) => {
        this.spinner.hide();
        this.facilityGroupList = responce.data;
      }, (error) => {
        return;
      });
     
    }

    getfacilityGroupTypeList() {
      this.spinner.show();
      this.facilityGroupService.getFacilityGroupTypeList().subscribe((responce) => {
        this.spinner.hide();
        this.facilityGroupTypeList = responce.data;
        for(const value of this.facilityGroupTypeList) {
          this.facilityGroupTypeListArray.push({
            label:value.description,
            value:value.facilityGroupTypeId
          })
        }
      }, (error) => {

        return
      });
     
    }
    
    onSubmit(): void {
      this.spinner.show();
      if (this.editMode) {
        const id = this.facilityGroupId;
        this.facilityGroupService.updateFacilityGroup(id,{...this.facilityGroup.value }).subscribe((res: any) => {
      
          if (res.success) {
            this.toastr.success(res.data.successMessage);
            this.getFacilityGroupList();
            this.spinner.hide();
            this.facilityGroup.reset();
           
            this.closebutton.nativeElement.click();
          }
        }, (err) => {
          this.spinner.hide();
          for (const value of err.error.error) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        });
       
      } else {
        this.facilityGroupService.createFacilityGroup(this.facilityGroup.value).subscribe((res: any) => {
        
          if (res.success) {
            this.toastr.success(res.data.successMessage);
            this.spinner.hide();
            this.getFacilityGroupList();
            this.facilityGroup.reset();
          }
        }, (err) => {
          this.spinner.hide();
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        });
      }
   
    }

    getData(data){
this.spinner.show();
          this.editMode = true;
          const primaryParentGroupId = data.primaryParentGroupId; 
          this.facilityGroupId = data.facilityGroupId,
          this.facilityGroup.patchValue({
            facilityGroupTypeId: data.facilityGroupTypeId,
            facilityGroupName: data.facilityGroupName,
            description: data.description,
            facilityGroupId: data.facilityGroupId,
            primaryParentGroupId: primaryParentGroupId
          });
          this.spinner.hide();
    }

    scroll(el: HTMLElement) {
      el.scrollIntoView({behavior: 'smooth'});
  
  }

  cancel(){
    if(this.editMode){
      this.editMode = false;
      this.facilityGroup.patchValue({
        facilityGroupTypeId: [''],
        facilityGroupName: [''],
        description: [''],
        facilityGroupId: [''],
        primaryParentGroupId: [''],
      });
    }
    else{
      this.facilityGroup.patchValue({
        facilityGroupTypeId: [''],
        facilityGroupName: [''],
        description: [''],
        facilityGroupId: [''],
        primaryParentGroupId: [''],
      });
    }
  }

    delete(data): void{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const id = data.facilityGroupId;
          this.facilityGroupService.deleteCatalogStore(id).subscribe((responce: any) => {
            if (responce.success) {
             
              this.getFacilityGroupList();
            }
          }, (err) => {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
     
     
    }
    homeButton(){
      this.router.navigate(['/facilities/Dashboard'])
    
    }
    cancelSubmit(){
      this._location.back();
    }
    dashboardOpen(){
      this.router.navigate(["/facilities/warehouse-dashborad-overview"])
    }
    mainOpen(){
      this.router.navigate(['/facilities/warehouse']);
    }
    ShippingOpen(){
      this.router.navigate(["/facilities/warehouse-shipment-config"])
    }
    facilityGroupOpen(){
      this.router.navigate(['/facilities/facility-group']);
    }

    inventoryItemOpen(){
      this.router.navigate(['/facilities/inventory-labels']);
    }
    detailPage(id,groupid,parentId,fGroupName,des) {
      this.router.navigate(["/facilities/facility-group/detail-facility-group-summary"], { queryParams: { id: id,groupid:groupid,parentId:parentId,fGroupName:fGroupName,des:des} })
    }
    ngOnDestroy(): void {
      this.spinner.hide();
        }
}
