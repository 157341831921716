import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class LeadPersonalLeadServices {

  constructor(readonly http: HttpClient) { }
 updateLead(id,formData):Observable<any>{
 
   const updateLead = URLS.updateEmployeeLead.replace(":partyId",id);
   return this.http.put(updateLead,formData)
 }
 updateContact(id,formData):Observable<any>{
 
  const updateLead = URLS.updateContactInfo + "?partyId="+id;
  return this.http.put(updateLead,formData)
}
 getLeadByPartyId(id):Observable<any>{
 
  const getLeadByPartyId = URLS.getLeadByPartyId.replace(":partyId",id);
  return this.http.get(getLeadByPartyId)
}

getStatusCrm(): Observable<any> {
  const statusCrm = URLS.getCRMStatus;
  return this.http.get(statusCrm);
}
getContacts(contactDetails): Observable<any> {
  const getContacts = URLS.getDataByPartyId;
  const getContactsId = getContacts.replace('partyId', contactDetails)
  return this.http.get(getContactsId);
}
 getCurrency():Observable<any> {
  const getCurrency = URLS.getCurrency;
  return this.http.get(getCurrency);
}

 }


