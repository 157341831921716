import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../../../crm/accounts/accounts.service';
import { Router } from '@angular/router';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'src/app/services/dashboard.service';

import { ViewChild } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  colors: any;
};

@Component({
  selector: 'app-ar-dashboard',
  templateUrl: './ar-dashboard.component.html',
  styleUrls: ['./ar-dashboard.component.css']
})

export class ArDashboardComponent implements OnInit {
  totalValue: number;
  chartValue: any[];
  activeCategory=2;
  color = {
    COMMISSION_INVOICE: 'red',
    PURCHASE_INVOICE: 'yellow',
    SALES_INVOICE: 'green',
    CUST_RTN_INVOICE: 'blue'
  };
  chartInvoice: any[];


  /// NEW CODE ///

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor(readonly _DashboardService: DashboardService,
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,

  ) {
   // this.chartInvoice = [];

   this.chartOptions = {
    series: [],
    chart: {
      width: 450,
      type: "pie"
    },
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ],
    colors:["#8e44ad", "#f1c40f", "#e67e22", "#ff4560" ,"#7cf7f1", "#140eb5" ,"#29ed13",]
  };
  }

  ngOnInit(): void {
   }
 

  ngAfterViewInit(){

    this.accountsService.getInvoiceCountPieChart().subscribe((res: any) => {
      this.spinner.show();
      this.chartOptions.labels = res.data.label;
      this.chartOptions.series =   res.data.value;
      this.spinner.hide();
    })


  }
  // ngAfterViewInit() {
  //   am4core.useTheme(am4themes_animated);
  //   am4core.useTheme(am4themes_material);




  //   am4core.useTheme(am4themes_animated);
  //   // Themes end

  //   /**
  //    * Source data
  //    */

  //   this._DashboardService.invoiceCount()
  //     .then(value => {
  //       value.data.map(invoice => {
  //         Object.keys(invoice).forEach(_ => {
  //           if (invoice[_].constructor.name === 'Array') {
  //             this.chartInvoice.push({
  //               category: _,
  //               value: invoice.invoiceTotalCount,
  //               color: this.color[_],
  //               breakdown: invoice[_].map(prog => {
  //                 return {
  //                   category: `${prog.statusId.split('_')[0]} ${prog.statusId.split('_')[1]}`,
  //                   value: prog.invoiceIdCount
  //                 };
  //               })
  //             });
  //           }
  //         })

  //       });

        
       

  //       /**
  //        * Chart container
  //        */

  //       // Create chart instance
  //       let chart = am4core.create("chartdivalpha", am4core.Container);
  //       chart.width = am4core.percent(100);
  //       chart.height = am4core.percent(100);
  //       chart.layout = "horizontal";


  //       /**
  //        * Column chart
  //        */

  //       // Create chart instance
  //       let columnChart = chart.createChild(am4charts.XYChart);

  //       // Create axes
  //       let categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
  //       categoryAxis.dataFields.category = "category";
  //       categoryAxis.renderer.grid.template.location = 0;
  //       categoryAxis.renderer.inversed = true;

  //       let valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());

  //       // Create series
  //       let columnSeries = columnChart.series.push(new am4charts.ColumnSeries());
  //       columnSeries.dataFields.valueX = "value";
  //       columnSeries.dataFields.categoryY = "category";
  //       columnSeries.columns.template.strokeWidth = 0;

  //       /**
  //        * Pie chart
  //        */

  //       // Create chart instance
  //       let pieChart = chart.createChild(am4charts.PieChart);
  //       pieChart.data = this.chartInvoice;
  //       pieChart.innerRadius = am4core.percent(50);

  //       // Add and configure Series
  //       let pieSeries = pieChart.series.push(new am4charts.PieSeries());
  //       pieSeries.dataFields.value = "value";
  //       pieSeries.dataFields.category = "category";
  //       pieSeries.slices.template.propertyFields.fill = "color";
  //       pieSeries.labels.template.disabled = true;

  //       // Set up labels
  //       let label1 = pieChart.seriesContainer.createChild(am4core.Label);
  //       label1.text = "";
  //       label1.horizontalCenter = "middle";
  //       label1.fontSize = 35;
  //       label1['fontWeight'] = 600 as any;
  //       label1.dy = -30;

  //       let label2 = pieChart.seriesContainer.createChild(am4core.Label);
  //       label2.text = "";
  //       label2.horizontalCenter = "middle";
  //       label2.fontSize = 12;
  //       label2.dy = 20;

  //       // Auto-select first slice on load
  //       pieChart.events.on("ready", function (ev) {
  //         pieSeries.slices.getIndex(0).isActive = true;
  //       });

  //       // Set up toggling events
  //       pieSeries.slices.template.events.on("toggled", function (ev) {
  //         if (ev.target.isActive) {

  //           // Untoggle other slices
  //           pieSeries.slices.each(function (slice) {
  //             if (slice != ev.target) {
  //               slice.isActive = false;
  //             }
  //           });

  //           // Update column chart
  //           columnSeries.appeared = false;
  //           columnChart.data = ev.target.dataItem.dataContext['breakdown'];
  //           columnSeries.fill = ev.target.fill;
  //           columnSeries.reinit();

  //           // Update labels
  //           label1.text = pieChart.numberFormatter.format(ev.target.dataItem.values.value.percent, "#.'%'");
  //           label1.fill = ev.target.fill;

  //           label2.text = ev.target.dataItem['category'];
  //         }
  //       });
  //     });



  //   //Gauge with bands
  //   var chartMin = -50;
  //   var chartMax = 100;





  //   this._DashboardService.paymentCount()
  //     .then(data => {
     
  //       this.totalValue = data.data.reduce((accumulator, currentValue) => accumulator + currentValue.paymentTotalCount, 0);
       

  //     });
  //   var data1 = {
  //     score: 52.7,
  //     gradingData: [
  //       {


  //         title: "CUSTOMER_REFUND",
  //         color: "#ee1f25",
  //         lowScore: -100,
  //         highScore: -20
  //       },
  //       {
  //         title: "VENDOR_PAYMENT",
  //         color: "#f04922",
  //         lowScore: -20,
  //         highScore: 0
  //       },
  //       {
  //         title: "VENDOR_PREPAY",
  //         color: "#fdae19",
  //         lowScore: 0,
  //         highScore: 20
  //       },
  //       {
  //         title: "COMMISSION_PAYMENT",
  //         color: "#f3eb0c",
  //         lowScore: 20,
  //         highScore: 40
  //       },
  //       {
  //         title: "PAYROL_PAYMENT",
  //         color: "#b0d136",
  //         lowScore: 40,
  //         highScore: 60
  //       },
  //       {
  //         title: "PAY_CHECK",
  //         color: "#54b947",
  //         lowScore: 60,
  //         highScore: 80
  //       },
  //       {
  //         title: "SALES_TAX_PAYMENT",
  //         color: "#0f9747",
  //         lowScore: 80,
  //         highScore: 100
  //       },

  //     ]
  //   };
   
  //   /**
  //   Grading Lookup
  //    */
  //   function lookUpGrade(lookupScore, grades) {
  //     // Only change code below this line
  //     for (var i = 0; i < grades.length; i++) {
  //       if (
  //         grades[i].lowScore < lookupScore &&
  //         grades[i].highScore >= lookupScore
  //       ) {
  //         return grades[i];
  //       }
  //     }
  //     return null;
  //   }

  //   // create chart
  //   var chart6: any = am4core.create("chartdiv6", am4charts.GaugeChart);
  //   chart6.hiddenState.properties.opacity = 0;
  //   chart6.fontSize = 11;
  //   chart6.innerRadius = am4core.percent(80);
  //   chart6.resizable = true;

  //   /**
  //    * Normal axis
  //    */

  //   var axis: any = chart6.xAxes.push(new am4charts.ValueAxis());
  //   axis.min = chartMin;
  //   axis.max = chartMax;
  //   axis.strictMinMax = true;
  //   axis.renderer.radius = am4core.percent(80);
  //   axis.renderer.inside = true;
  //   axis.renderer.line.strokeOpacity = 0.1;
  //   axis.renderer.ticks.template.disabled = false;
  //   axis.renderer.ticks.template.strokeOpacity = 1;
  //   axis.renderer.ticks.template.strokeWidth = 0.5;
  //   axis.renderer.ticks.template.length = 5;
  //   axis.renderer.grid.template.disabled = true;
  //   axis.renderer.labels.template.radius = am4core.percent(15);
  //   axis.renderer.labels.template.fontSize = "0.9em";

  //   /**
  //    * Axis for ranges
  //    */

  //   var axis2: any = chart6.xAxes.push(new am4charts.ValueAxis());
  //   axis2.min = chartMin;
  //   axis2.max = chartMax;
  //   axis2.strictMinMax = true;
  //   axis2.renderer.labels.template.disabled = true;
  //   axis2.renderer.ticks.template.disabled = true;
  //   axis2.renderer.grid.template.disabled = false;
  //   axis2.renderer.grid.template.opacity = 0.5;
  //   axis2.renderer.labels.template.bent = true;
  //   axis2.renderer.labels.template.fill = am4core.color("#000");
  //   axis2.renderer.labels.template.fontWeight = "bold";
  //   axis2.renderer.labels.template.fillOpacity = 0.3;



  //   /**
  //   Ranges
  //   */

  //   for (let grading of data1.gradingData) {
  //     var range1 = axis2.axisRanges.create();
  //     range1.axisFill.fill = am4core.color(grading.color);
  //     range1.axisFill.fillOpacity = 0.8;
  //     range1.axisFill.zIndex = -1;
  //     range1.value = grading.lowScore > chartMin ? grading.lowScore : chartMin;
  //     range1.endValue = grading.highScore < chartMax ? grading.highScore : chartMax;
  //     range1.grid.strokeOpacity = 0;
  //     range1.stroke = am4core.color(grading.color).lighten(-0.1);
  //     range1.label.inside = true;
  //     range1.label.text = grading.title.toUpperCase();
  //     range1.label.inside = true;
  //     range1.label.location = 0.5;
  //     range1.label.inside = true;
  //     range1.label.radius = am4core.percent(10);
  //     range1.label.paddingBottom = -5; // ~half font size
  //     range1.label.fontSize = "0.9em";
  //   }

  //   var matchingGrade = lookUpGrade(data1.score, data1.gradingData);

  //   /**
  //    * Label 1
  //    */

  //   var label = chart6.radarContainer.createChild(am4core.Label);
  //   label.isMeasured = false;
  //   label.fontSize = "6em";
  //   label.x = am4core.percent(50);
  //   label.paddingBottom = 15;
  //   label.horizontalCenter = "middle";
  //   label.verticalCenter = "bottom";
  //   //label.dataItem = data;
  //   label.text = data1.score.toFixed(1);
  //   //label.text = "{score}";
  //   label.fill = am4core.color(matchingGrade.color);

  //   /**
  //    * Label 2
  //    */

  //   var label4 = chart6.radarContainer.createChild(am4core.Label);
  //   label4.isMeasured = false;
  //   label4.fontSize = "2em";
  //   label4.horizontalCenter = "middle";
  //   label4.verticalCenter = "bottom";
  //   label4.text = matchingGrade.title.toUpperCase();
  //   label4.fill = am4core.color(matchingGrade.color);


  //   /**
  //    * Hand
  //    */

  //   var hand = chart6.hands.push(new am4charts.ClockHand());
  //   hand.axis = axis2;
  //   hand.innerRadius = am4core.percent(55);
  //   hand.startWidth = 8;
  //   hand.pin.disabled = true;
  //   hand.value = data1.score;
  //   hand.fill = am4core.color("#444");
  //   hand.stroke = am4core.color("#000");

  //   hand.events.on("positionchanged", function () {
  //     label.text = axis2.positionToValue(hand.currentPosition).toFixed(1);
  //     var value2 = axis.positionToValue(hand.currentPosition);
  //     var matchingGrade = lookUpGrade(axis.positionToValue(hand.currentPosition), data1.gradingData);
  //     label4.text = matchingGrade.title.toUpperCase();
  //     label4.fill = am4core.color(matchingGrade.color);
  //     label4.stroke = am4core.color(matchingGrade.color);
  //     label.fill = am4core.color(matchingGrade.color);
  //   })

  //   setInterval(function () {
  //     var value = chartMin + Math.random() * (chartMax - chartMin);
  //     hand.showValue(value, 1000, am4core.ease.cubicOut);
  //   }, 2000);
  // }

}