import { Component, OnInit, OnDestroy } from '@angular/core';

import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

import {DropdownModule} from 'primeng/dropdown';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-create-new-security-group',
  templateUrl: './create-new-security-group.component.html',
  styleUrls: ['./create-new-security-group.component.css']
})
export class CreateNewSecurityGroupComponent implements OnInit {
  total=0;
  rowNumber=0;
  pageSize= 100;
  rows = 50;
  pageNo=1;
  search="";
  activeCategory = 2;
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  createGroup: FormGroup;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  show: boolean;
  groupId: any;
 
  
  constructor(  readonly accountsService: AccountsService,
   
    public addSkillService:AddSkillService, readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly activatedRoute:ActivatedRoute,
    readonly router: Router  ) {
      this.createGroup = this._FormBuilder.group({
        description: [''],
        groupId: ['',[Validators.required]],
        groupName: [''],
        
        
      });}
      ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> [
      this.groupId = params["groupId"],
      
    ])
    if(this.groupId) {
      this.show=true;
      this.getUserDetails()
    
     
    }
    else {
      this.show = false;
    }
  }
  getUserDetails(){
    const req={
  "description": "",
  "descriptionSearchType": "Contains",
  "groupId": this.groupId,
  "groupIdSearchType": "Contains"
     
    }
    this.accountsService.getSecurityGroupList(this.user,req).subscribe((res:any)=> {
     setTimeout(() => {
      const formValue = this.createGroup;
    formValue.patchValue({
      description:res.data[0].description,
      groupId: res.data[0].groupId,
      groupName:res.data[0].groupName,
     
     
    })
    }, 1000); 
     
    })
   
   }
  submit(): void {
    this.spinner.show();
     const requestData ={
     
         "description": this.createGroup.value.description,
         "groupId": this.createGroup.value.groupId,
         "groupName":this.createGroup.value.groupName    
         
     }
     this.accountsService.saveSecurityGroup(requestData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.router.navigate(['/partyQuickLink/security'],)
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
   update(): void {
    this.spinner.show();
      const requestData ={
      
          "description": this.createGroup.value.description,
          "groupId": this.createGroup.value.groupId,
          "groupName":this.createGroup.value.groupName    
          
      }
      this.accountsService.putSecurityGroup(requestData).subscribe((res: any) => {
       
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.router.navigate(['/partyQuickLink/security'],)
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     
    }
   cancel(){
     this.router.navigate(['/partyQuickLink/security'],)
   }

}
