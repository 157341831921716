import { Component, OnInit } from '@angular/core';
//import { Router } from '@angular/router';
//import { Location } from '@angular/common';
@Component({
  selector: 'app-dashboard-ismgr',
  templateUrl: './dashboard-ismgr.component.html',
  styleUrls: ['./dashboard-ismgr.component.css']
})
export class DashboardIsmgrComponent implements OnInit {

  constructor(
    // readonly router: Router,
    // private _location: Location,
  ) { }

  ngOnInit(): void {
  }
//   backWindow(){
//     this._location.back();
//   }
//     toDashboard()
// {
//   this.router.navigate(['/ismgr/ismgr-Dashboard']);
// }
}
