import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-work-effort-time-entries',
  templateUrl: './work-effort-time-entries.component.html',
  styleUrls: ['./work-effort-time-entries.component.css']
})
export class WorkEffortTimeEntriesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  activeCategory = 12;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  addTimeEntry: FormGroup;
  addTimeToInvoice: any;
  addTimeToNewInvoice: any;
  MyRates: any;
  MyRatesArray: any[] = [];
  TimeSheetId: any;
  TimeSheetIdArray: any[] = [];
  InvoiceId: any;
  InvoiceIdArray: any[] = [];
  personId: any;
  partyIdArray: any[] = [];
  workEffortId: any;
  FromDate: string;
  ThroughDate: string;
  timeentrytable: FormGroup;
  TimeEntryListByIddata: any;
  show: boolean;
  updateFromDate: any;
  producttime: any;
  InvoiceIdNewArray: any[] = [];
  invoiceIdNew: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,
    readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addSkillService: AddSkillService,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
    
    private _Router: Router

  ) {
    this.addTimeEntry = this._FormBuilder.group({
      party: [''],
      fromDate: [''],
      throughDate: [''],
      rateType: [''],
      timesheetID: [''],
      hours: [''],
      comments: [''],
      planHours: [''],
    });
    this.timeentrytable = this._FormBuilder.group({
      TimesheetID: [''],
      Party: [''],
      RateType: [''],
      Comments: [''],
      InvoiceInfo: [''],
    });
    this.addTimeToInvoice = this._FormBuilder.group({
      invoiceID: [''],
    });
    this.addTimeToNewInvoice = this._FormBuilder.group({
      billFromParty: [''],
      billToParty: [''],
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
    });
    this.getMyRates();
    this.getTimeSheetId();
    this.getInvoiceId();
    this.getPartyId();
    this.getTimeEntryListById();
    this.addWorkEffortTimeToInvoiceFormSTATUS();
  }
  addWorkEffortTimeToInvoiceFormSTATUS() {
    this.spinner.show();
    this.addSkillService.addWorkEffortTimeToInvoiceFormSTATUS().subscribe(res => {
      this.invoiceIdNew = res.data[0].Data;
      this.spinner.hide();
      for (const value of this.invoiceIdNew) {
        this.InvoiceIdNewArray.push({
          label: value.invoiceId,
          value: value.invoiceId
        })
      }
    })
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  addWorkEffortTimeToInvoice(): void {
    this.spinner.show();
    const requestData = {
      "invoiceId": this.addTimeToInvoice.value.invoiceID,
      "workEffortId": this.workEffortId
    }
    this.myContactsService.addWorkEffortTimeToInvoice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.addTimeToInvoice.reset();
        this.getTimeEntryListById();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  addWorkEffortTimeToNewInvoice(): void {
    this.spinner.show();

    const requestData = {
      "combineInvoiceItem": "Y",
      "partyId": this.addTimeToNewInvoice.value.billToParty,
      "partyIdFrom": this.addTimeToNewInvoice.value.billFromParty,
      "workEffortId": this.workEffortId

    }

    this.myContactsService.addWorkEffortTimeToNewInvoice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addTimeToNewInvoice.reset();
        this.getTimeEntryListById();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteWorkEffortsTimeEntry(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "timeEntryId": product.timeEntryId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "workEffortId": this.workEffortId
        }
        this.myContactsService.deleteWorkEffortsTimeEntry(req).subscribe(res => {
          this.getTimeEntryListById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  createWorkEffortTimeEntry(): void {
    this.spinner.show();
    const fromDate = this.addTimeEntry.get('fromDate').value;
    this.FromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
    const throughDate = this.addTimeEntry.get('throughDate').value;
    this.ThroughDate = this.datePipe.transform(throughDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "comments": this.addTimeEntry.value.comments,
      "fromDate": this.FromDate,
      "hours": this.addTimeEntry.value.hours,
      "partyId": this.addTimeEntry.value.party,
      "planHours": this.addTimeEntry.value.planHours,
      "rateTypeId": this.addTimeEntry.value.rateType,
      "thruDate": this.ThroughDate,
      "timesheetId": this.addTimeEntry.value.timesheetID,
      "workEffortId": this.workEffortId

    }

    this.myContactsService.createWorkEffortTimeEntry(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.addTimeEntry.reset();
        this.getTimeEntryListById();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateWorkEffortsTimeEntry(): void {
    this.spinner.show();

    const requestData = {
      "comments": this.addTimeEntry.value.comments,
      "partyId": this.addTimeEntry.value.party,
      "rateTypeId": this.addTimeEntry.value.rateType,
      "timeEntryId": this.producttime.timeEntryId,
      "timesheetId": this.addTimeEntry.value.timesheetID,
      "workEffortId": this.workEffortId

    }

    this.myContactsService.updateWorkEffortsTimeEntry(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.addTimeEntry.reset();
        this.getTimeEntryListById();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateEvent(product) {
    this.show = true;
    this.spinner.show();
    this.updateFromDate = product.fromDate;
    this.producttime = product;

    setTimeout(() => {

      const formValue = this.addTimeEntry;
      formValue.patchValue({
        "comments": product.comments,
        "party": product.partyId,
        "rateType": product.rateTypeId,
        "timesheetID": product.timesheetId,
        "workEffortId": this.workEffortId,
      })

    }, 2000);

  }
  reset() {
    this.addTimeEntry.reset();
    this.show = false;
  }
  getTimeEntryListById() {
    this.spinner.show();
    this.myContactsService.getTimeEntryListById(this.workEffortId).subscribe((res: any) => {
      this.TimeEntryListByIddata = res.data[0].timeEntry;
      this.spinner.hide();

    })
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

  addWorkEffort() {
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  editWorkEffort1() {
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"]), { queryParams: { workEffortId: this.workEffortId } }
  }
  summary() {
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"], { queryParams: { workEffortId: this.workEffortId } })
  }

  childWorkEfforts() {
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"], { queryParams: { workEffortId: this.workEffortId } })
  }
  partyAssigns() {
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"], { queryParams: { workEffortId: this.workEffortId } })
  }
  rates() {
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"], { queryParams: { workEffortId: this.workEffortId } })
  }
  commEvents() {
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"], { queryParams: { workEffortId: this.workEffortId } })
  }
  shopLists() {
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"], { queryParams: { workEffortId: this.workEffortId } })
  }
  requests() {
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"], { queryParams: { workEffortId: this.workEffortId } })
  }
  requirements() {
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"], { queryParams: { workEffortId: this.workEffortId } })
  }
  quotes() {
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"], { queryParams: { workEffortId: this.workEffortId } })
  }
  orderHeaders() {
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"], { queryParams: { workEffortId: this.workEffortId } })
  }
  timeEntries() {
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"], { queryParams: { workEffortId: this.workEffortId } })
  }
  detailedPage(timesheetId) {
    this._Router.navigate(["/psa/work-effort/timesheet-view/EditTimesheet"], { queryParams: { timesheetId: timesheetId } })
  }
  notes() {
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"], { queryParams: { workEffortId: this.workEffortId } })
  }
  contents() {
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"], { queryParams: { workEffortId: this.workEffortId } })
  }
  products() {
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"], { queryParams: { workEffortId: this.workEffortId } })
  }
  reviews() {
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"], { queryParams: { workEffortId: this.workEffortId } })
  }
  keywords() {
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"], { queryParams: { workEffortId: this.workEffortId } })
  }
  contactMechs() {
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"], { queryParams: { workEffortId: this.workEffortId } })
  }
  agreementApplics() {
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"], { queryParams: { workEffortId: this.workEffortId } })
  }
  fixedAssets() {
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"], { queryParams: { workEffortId: this.workEffortId } })
  }
  attributes() {
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"], { queryParams: { workEffortId: this.workEffortId } })
  }
  eventReminders() {
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"], { queryParams: { workEffortId: this.workEffortId } })
  }

  getMyRates() {
    this.spinner.show();
    this.addSkillService.getMyRates().subscribe(res => {
      this.MyRates = res.data[0].mMyRatess;
      this.spinner.hide();
      for (const value of this.MyRates) {
        this.MyRatesArray.push({
          label: value.rateTypeId,
          value: value.rateTypeId
        })
      }
    })
  }
  getTimeSheetId() {
    this.spinner.show();
    this.addSkillService.getTimeSheetId().subscribe(res => {
      this.TimeSheetId = res.data[0].Timesheet;
      this.spinner.hide();
      for (const value of this.TimeSheetId) {
        this.TimeSheetIdArray.push({
          label: value.timesheetId,
          value: value.timesheetId
        })
      }
    })
  }
  getInvoiceId() {
    this.spinner.show();
    this.addSkillService.getInvoiceId().subscribe(res => {
      this.InvoiceId = res.data[0].Invoice;
      this.spinner.hide();
      for (const value of this.InvoiceId) {
        this.InvoiceIdArray.push({
          label: value.invoiceId,
          value: value.invoiceTypeId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
}
