<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Commission</span>
                <span class="color-black pl-1"> > New</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                   Create New Commission Invoices
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">From Party</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Currency</label>
                                            <div class="">
                                                <p-dropdown filter="true"
                                                    optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Invoice Date</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Due Date</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Ref Num</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Description</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Message</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Create</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>